import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { EmptyStateMode, ErrorMode, GlobalFeatures, GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { filter } from "rxjs";
import { combineLatestWith, map, startWith } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { OperatorPermitsState } from "../../state/operator-permits.state";

enum TabPaths {
    OWNED_PERMIT = "owned-permits",
    DRAFTS = "drafts",
}

enum TabIndex {
    OWNED_PERMIT = 0,
    DRAFTS = 1,
}

interface SelectedTab {
    path: TabPaths;
    labelKey: string;
    requiredPermission: GlobalOperatorPermissions;
}

interface OperatorPermitsContainerComponentState {
    selectedTab: SelectedTab | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-permits-container",
    templateUrl: "./operator-permits-container.component.html",
    styleUrls: ["./operator-permits-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorPermitsContainerComponent {
    protected readonly areSpecificPermitDraftsAvailable$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.SpecificPermitApplication)
    );
    protected readonly isPermittedToCreateApplication$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorSpecificPermitsCreateApplication)
    );
    protected readonly isPermittedToPlanMissions$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorMissionsPlan)
    );
    protected readonly ownedSpecificPermitsList$ = this.store.select(OperatorPermitsState.ownedSpecificPermitsList);
    protected readonly specificPermitDraftsList$ = this.store.select(OperatorPermitsState.specificPermitDraftsList);
    protected readonly isOwnedSpecificPermitsListProcessing$ = this.store.select(OperatorPermitsState.isOwnedSpecificPermitsListProcessing);
    protected readonly isSpecificPermitDraftsListProcessing$ = this.store.select(OperatorPermitsState.isSpecificPermitDraftsListProcessing);
    protected readonly areFiltersApplied$ = this.route.queryParams.pipe(map((params) => !!params.searchPhrase));
    private readonly selectedOperatorContext$ = this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy());

    protected readonly selectedTab$ = this.localStore.selectByKey("selectedTab");
    protected readonly tabs: SelectedTab[] = [
        {
            path: TabPaths.OWNED_PERMIT,
            labelKey: "dtmWebAppLibOperatorPermits.permitsContainer.ownedLabel",
            requiredPermission: GlobalOperatorPermissions.OperatorMissionsPlan,
        },
        {
            path: TabPaths.DRAFTS,
            labelKey: "dtmWebAppLibOperatorPermits.permitsContainer.draftsLabel",
            requiredPermission: GlobalOperatorPermissions.OperatorManage,
        },
    ];
    protected readonly ErrorMode = ErrorMode;
    protected readonly TabPaths = TabPaths;
    protected readonly TabIndex = TabIndex;
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<OperatorPermitsContainerComponentState>,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {
        localStore.setState({
            selectedTab: undefined,
        });
        this.watchSelectedOperatorContextChange();
    }

    protected navigateToSpecificPermits(): void {
        this.router.navigateByUrl("/specific-permit/application-creator");
    }

    private watchSelectedOperatorContextChange(): void {
        this.selectedOperatorContext$
            .pipe(
                combineLatestWith(
                    this.router.events.pipe(
                        filter((event) => event instanceof NavigationEnd),
                        startWith(true)
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const urlSegment = this.route.snapshot.firstChild;
                const isOwnedPermissionListAvailable = this.isOwnedPermissionListAvailable();
                const isDraftsListAvailable = this.isDraftsListAvailable();
                if (!urlSegment) {
                    if (isOwnedPermissionListAvailable) {
                        this.localStore.patchState({ selectedTab: this.tabs[TabIndex.OWNED_PERMIT] });
                        this.navigateToTab(TabPaths.OWNED_PERMIT);
                    } else if (isDraftsListAvailable) {
                        this.localStore.patchState({ selectedTab: this.tabs[TabIndex.DRAFTS] });
                        this.navigateToTab(TabPaths.DRAFTS);
                    }

                    return;
                }

                if (urlSegment.url[0].path === TabPaths.OWNED_PERMIT) {
                    if (isOwnedPermissionListAvailable) {
                        this.localStore.patchState({ selectedTab: this.tabs[TabIndex.OWNED_PERMIT] });
                    } else if (isDraftsListAvailable) {
                        this.localStore.patchState({ selectedTab: this.tabs[TabIndex.DRAFTS] });
                        this.navigateToTab(TabPaths.DRAFTS);
                    }

                    return;
                }

                if (urlSegment.url[0].path === TabPaths.DRAFTS) {
                    if (isDraftsListAvailable) {
                        this.localStore.patchState({ selectedTab: this.tabs[TabIndex.DRAFTS] });
                    } else if (isOwnedPermissionListAvailable) {
                        this.localStore.patchState({ selectedTab: this.tabs[TabIndex.OWNED_PERMIT] });
                        this.navigateToTab(TabPaths.OWNED_PERMIT);
                    }

                    return;
                }
                this.localStore.patchState({ selectedTab: undefined });
            });
    }

    private isOwnedPermissionListAvailable(): boolean | undefined {
        return this.store.selectSnapshot(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorMissionsPlan));
    }

    private isDraftsListAvailable(): boolean | undefined {
        return (
            this.store.selectSnapshot(OperatorContextState.isFeatureAvailable(GlobalFeatures.SpecificPermitApplication)) &&
            this.store.selectSnapshot(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorSpecificPermitsCreateApplication))
        );
    }

    private navigateToTab(tabPath: TabPaths) {
        this.router.navigate([`operator-permits/${tabPath}`], { queryParamsHandling: "merge" });
    }
}
