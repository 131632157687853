<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <dtm-ui-wizard-step-wrapper
        [title]="'dtmWebAppLibPilotOperatorRegistration.summaryHeader' | transloco"
        [stepNumber]="3"
        [stepsAmount]="3"
        [isBackButtonVisible]="true"
        [nextButtonLabel]="'dtmWebAppLibPilotOperatorRegistration.registerOperator.summary.registerOperatorButtonLabel' | transloco"
        (next)="saveSummaryData()"
        (back)="goToAdditionalDataStep.emit()"
        dtmUiInvalidFormScrollable
    >
        <div class="summary-main-container" *ngrxLet="operatorRegistrationFormValue$ as operatorRegistrationFormValue">
            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.summary.registrationInfoSectionHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="basicDataEditButtonTemplate"></ng-container>
                <div class="summary-section single-value">
                    <dtm-ui-label-value
                        [label]="
                            'dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.registrationInOtherCountryQuestion'
                                | transloco
                        "
                        [value]="
                            'dtmWebAppLibPilotOperatorRegistration.summary.trueFalseValueLabel'
                                | transloco : { value: !!operatorRegistrationFormValue.foreignOperator?.number }
                        "
                    ></dtm-ui-label-value>
                </div>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                [titleTile]="
                    (operatorRegistrationFormValue.address.country === DEFAULT_COUNTRY_CODE
                        ? 'dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.originCountryDetailsHeader'
                        : 'dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.headquartersDetailsHeader'
                    )
                        | transloco
                            : {
                                  registrationType:
                                      operatorRegistrationFormValue.associationRegistrationNumber ||
                                      operatorRegistrationFormValue.associationNationalCourtRegister
                                          ? OperatorRegistrationType.Association
                                          : OperatorRegistrationType.Enterprise,
                                  originCountry: originCountry$ | ngrxPush
                              }
                "
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="basicDataEditButtonTemplate"></ng-container>
                <div class="summary-section">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyNameLabel' | transloco"
                        [value]="operatorRegistrationFormValue.companyName"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyEmailLabel' | transloco"
                        [value]="operatorRegistrationFormValue.email"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        *ngIf="operatorRegistrationFormValue.companyNumber"
                        [label]="'dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyNumberLabel' | transloco"
                        [value]="operatorRegistrationFormValue.companyNumber"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.phoneNumberLabel' | transloco"
                        [value]="operatorRegistrationFormValue.phoneNumber | formatPhoneNumber"
                    ></dtm-ui-label-value>
                </div>
                <div class="summary-section single-value">
                    <dtm-ui-label-value
                        *ngIf="operatorRegistrationFormValue.associationNationalCourtRegister"
                        [label]="
                            'dtmWebAppLibPilotOperatorRegistration.registerOperator.summary.associationNationalCourtRegisterLabel'
                                | transloco
                        "
                        [value]="operatorRegistrationFormValue.associationNationalCourtRegister"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        *ngIf="operatorRegistrationFormValue.associationRegistrationNumber"
                        [label]="
                            'dtmWebAppLibPilotOperatorRegistration.registerOperator.summary.associationRegistrationNumberLabel' | transloco
                        "
                        [value]="operatorRegistrationFormValue.associationRegistrationNumber"
                    ></dtm-ui-label-value>
                    <dtm-ui-address-label-value [isCountryFlagVisible]="true" [address]="operatorRegistrationFormValue.address">
                    </dtm-ui-address-label-value>
                </div>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.basicData.identityConfirmationSectionHeader' | transloco"
                *ngIf="operatorRegistrationFormValue.identityDocument"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="basicDataEditButtonTemplate"></ng-container>
                <div class="summary-section">
                    <dtm-ui-uploaded-files-display [uploadedFiles]="operatorRegistrationFormValue.identityDocument">
                    </dtm-ui-uploaded-files-display>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.basicData.expirationDateLabel' | transloco"
                        [value]="operatorRegistrationFormValue.identityDocumentExpirationDate | localizeDate"
                    >
                    </dtm-ui-label-value>
                </div>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneySectionHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="additionalDataEditButtonTemplate"></ng-container>
                <div class="summary-section single-value">
                    <dtm-ui-label-value
                        *ngIf="operatorRegistrationFormValue.attorneyPower.type === AttorneyPowerType.Owner"
                        [label]="
                            (operatorRegistrationFormValue.associationRegistrationNumber ||
                            operatorRegistrationFormValue.associationNationalCourtRegister
                                ? 'dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.isAssociationRepresentativeLabel'
                                : 'dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.isCompanyOwnerLabel'
                            ) | transloco
                        "
                        [value]="'dtmWebAppLibPilotOperatorRegistration.summary.trueFalseValueLabel' | transloco : { value: true }"
                    ></dtm-ui-label-value>

                    <dtm-ui-label-value
                        *ngIf="operatorRegistrationFormValue.attorneyPower.type !== AttorneyPowerType.Owner"
                        [ngSwitch]="operatorRegistrationFormValue.attorneyPower.type"
                        [label]="
                            'dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.powerOfAttorneySectionHeader' | transloco
                        "
                    >
                        <ng-container *ngSwitchCase="AttorneyPowerType.NationalCourtNumber">
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.nationalCourtRegisterPowerOfAttorneyLabel"
                                    | transloco
                            }}
                            {{ operatorRegistrationFormValue.attorneyPower.nationalCourtRegister }}
                        </ng-container>
                        <ng-container *ngSwitchCase="AttorneyPowerType.ConfirmatoryDocument">
                            <dtm-ui-uploaded-files-display [uploadedFiles]="operatorRegistrationFormValue.attorneyPower.document ?? []">
                            </dtm-ui-uploaded-files-display>
                            <dtm-ui-uploaded-files-display
                                [uploadedFiles]="operatorRegistrationFormValue.attorneyPower.feeConfirmation ?? []"
                            >
                            </dtm-ui-uploaded-files-display>
                        </ng-container>
                    </dtm-ui-label-value>
                    <dtm-ui-label-value
                        *ngIf="operatorRegistrationFormValue.attorneyPower.isDeclarationOfExemption"
                        [label]="'dtmWebAppLibPilotOperatorRegistration.registerOperator.summary.isDeclarationOfExemption' | transloco"
                        [value]="'dtmWebAppLibPilotOperatorRegistration.summary.trueFalseValueLabel' | transloco : { value: true }"
                    ></dtm-ui-label-value>
                </div>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceSectionHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="additionalDataEditButtonTemplate"></ng-container>
                <dtm-web-app-lib-pilot-operator-registration-insurance-summary
                    [insurancePolicyNumber]="operatorRegistrationFormValue.insurancePolicyNumber"
                    [insurancePolicyExpirationDate]="operatorRegistrationFormValue.insurancePolicyExpirationDate"
                ></dtm-web-app-lib-pilot-operator-registration-insurance-summary>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                *ngIf="operatorRegistrationFormValue.legalGuardian"
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianSectionHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="additionalDataEditButtonTemplate"></ng-container>
                <dtm-web-app-lib-pilot-operator-registration-legal-guardian-summary
                    [legalGuardian]="operatorRegistrationFormValue.legalGuardian"
                ></dtm-web-app-lib-pilot-operator-registration-legal-guardian-summary>
            </dtm-ui-presentation-tile-with-action>
            <form [formGroup]="statementsForm">
                <h4 class="statements-header">
                    {{ "dtmWebAppLibPilotOperatorRegistration.summary.statementsHeader" | transloco }}
                </h4>
                <dtm-ui-checkbox-field class="with-validation" [formControl]="authenticityOfDataControl" required>
                    <span>
                        {{ "dtmWebAppLibPilotOperatorRegistration.summary.authenticityStatement" | transloco }}
                    </span>
                </dtm-ui-checkbox-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="authenticityOfDataControl; name: ['required']">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
                <dtm-ui-checkbox-field class="with-validation" [formControl]="generalDataProtectionRegulationControl" required>
                    <span
                        [innerHTML]="
                            'dtmWebAppLibPilotOperatorRegistration.summary.generalDataProtectionRegulationStatement'
                                | transloco
                                | urlToClickable
                                | emailToClickable
                        "
                    >
                    </span>
                </dtm-ui-checkbox-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="generalDataProtectionRegulationControl; name: ['required']">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </form>
        </div>
    </dtm-ui-wizard-step-wrapper>
</dtm-ui-loader-container>

<ng-template #basicDataEditButtonTemplate>
    <button class="button-secondary" type="button" (click)="goToBasicDataStep.emit()">
        {{ "dtmWebAppLibPilotOperatorRegistration.summary.editButtonLabel" | transloco }}
    </button>
</ng-template>

<ng-template #additionalDataEditButtonTemplate>
    <button class="button-secondary" type="button" (click)="goToAdditionalDataStep.emit()">
        {{ "dtmWebAppLibPilotOperatorRegistration.summary.editButtonLabel" | transloco }}
    </button>
</ng-template>
