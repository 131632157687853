import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ApplicationCreatorWizardSteps } from "../../../services/specific-permit-application.models";
import { APPLICATION_WIZARD_ID } from "../content/application-creator-wizard-content.component";

@Component({
    templateUrl: "./application-creator-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationCreatorWizardHeaderComponent {
    protected readonly wizardId = APPLICATION_WIZARD_ID;
    protected readonly ApplicationCreatorWizardSteps = ApplicationCreatorWizardSteps;
}
