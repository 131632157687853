import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { filter } from "rxjs";

interface LegalGuardianSignatureStatusComponentState {
    isSuccess: boolean | undefined;
}

@Component({
    selector: "dtm-web-app-lib-legal-guardian-signature-status[isSuccess]",
    templateUrl: "./legal-guardian-signature-status.component.html",
    styleUrls: ["./legal-guardian-signature-status.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LegalGuardianSignatureStatusComponent {
    @Input() public set isSuccess(value: BooleanInput) {
        this.localStore.patchState({ isSuccess: coerceBooleanProperty(value) });
    }

    @Output() protected readonly goBackToForm = new EventEmitter<void>();
    protected readonly isSuccess$ = this.localStore.selectByKey("isSuccess").pipe(filter((isSuccess) => isSuccess !== undefined));

    constructor(private readonly localStore: LocalComponentStore<LegalGuardianSignatureStatusComponentState>) {
        this.localStore.setState({ isSuccess: undefined });
    }
}
