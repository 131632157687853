<ng-container *ngIf="currentPlanDistanceAndTime$ | ngrxPush as currentPlanDistanceAndTime">
    <div
        class="distance"
        *ngIf="currentPlanDistanceAndTime.distance"
        [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.itineraryDistanceLabel' | transloco"
    >
        <dtm-ui-icon name="pin-distance"></dtm-ui-icon>
        <span dtmWebAppLibMwiesipDistanceAndTimeDisplay [distance]="currentPlanDistanceAndTime.distance"></span>
    </div>

    <div
        class="time"
        *ngIf="currentPlanDistanceAndTime.time"
        [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.itineraryDurationLabel' | transloco"
    >
        <dtm-ui-icon name="time"></dtm-ui-icon>
        <span dtmWebAppLibMwiesipDistanceAndTimeDisplay [time]="currentPlanDistanceAndTime.time"></span>
    </div>
</ng-container>
