import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { OperationScenario, StaffCompetencies } from "../../../services/specific-permit-application.models";

interface PilotCompetenciesComponentState {
    availableOperationScenarios: OperationScenario[];
    isExpanded: boolean;
    isEditMode: boolean;
    currentCompetencies: StaffCompetencies;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-pilot-competencies[availableOperationScenarios]",
    templateUrl: "./pilot-competencies.component.html",
    styleUrls: ["./pilot-competencies.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PilotCompetenciesComponent), multi: true },
    ],
})
export class PilotCompetenciesComponent implements ControlValueAccessor {
    @Input() public set availableOperationScenarios(value: OperationScenario[] | undefined) {
        this.localStore.patchState({ availableOperationScenarios: value ?? [] });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set value(value: StaffCompetencies) {
        this.localStore.patchState({ currentCompetencies: value });
    }

    protected readonly availableOperationScenarios$ = this.localStore.selectByKey("availableOperationScenarios");
    protected readonly currentCompetencies$ = this.localStore.selectByKey("currentCompetencies");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    protected readonly pilotCompetenciesForm = new FormGroup<{ competencies: FormControl<StaffCompetencies | null> }>({
        competencies: new FormControl<StaffCompetencies | null>(null, {
            nonNullable: true,
        }),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: StaffCompetencies | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<PilotCompetenciesComponentState>) {
        this.localStore.setState({
            availableOperationScenarios: [],
            isExpanded: true,
            isEditMode: false,
            currentCompetencies: {
                basic: [],
                additional: [],
                hasEuRegulationCompetency: false,
            },
        });
    }

    public registerOnChange(fn: (value: StaffCompetencies | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: StaffCompetencies | null): void {
        if (value) {
            this.pilotCompetenciesForm.setValue({ competencies: value }, { emitEvent: false });
        } else {
            this.pilotCompetenciesForm.reset();
        }

        this.persistCurrentCompetencies(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.pilotCompetenciesForm.disable();
        } else {
            this.pilotCompetenciesForm.enable();
        }
    }

    protected setEditMode(isEditMode: boolean): void {
        this.localStore.patchState(({ isExpanded }) => ({ isEditMode, isExpanded: isEditMode ? true : isExpanded }));
    }

    protected cancel(): void {
        const currentCompetencies = this.localStore.selectSnapshotByKey("currentCompetencies");
        this.pilotCompetenciesForm.reset({ competencies: currentCompetencies }, { emitEvent: false });
        this.setEditMode(false);
    }

    protected save(): void {
        this.pilotCompetenciesForm.markAllAsTouched();

        if (this.pilotCompetenciesForm.invalid) {
            return;
        }

        const currentCompetencies = this.pilotCompetenciesForm.controls.competencies.getRawValue();
        this.persistCurrentCompetencies(currentCompetencies);
        this.propagateChange(currentCompetencies);
        this.setEditMode(false);
    }

    private persistCurrentCompetencies(value: StaffCompetencies | null): void {
        this.localStore.patchState({
            currentCompetencies: {
                basic: value?.basic ?? [],
                additional: value?.additional ?? [],
                hasEuRegulationCompetency: value?.hasEuRegulationCompetency ?? false,
            },
        });
    }
}
