<ng-container *ngIf="shouldShowLink$ | ngrxPush">
    <dtm-ui-icon name="error-warning-fill" class="height-violation-icon"></dtm-ui-icon>
    <button
        type="button"
        class="button-link"
        (click)="$event.stopPropagation(); openDialog()"
        [class.collapsed]="!(isExpanded$ | ngrxPush)"
    >
        {{ "dtmWebAppLibMission.itineraryEditorStep.heightViolationAlert.imageLinkText" | transloco }}
    </button>
</ng-container>
