import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { dtmOperatorPermissionGuard } from "../../guards/dtm-operator-permission.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { MainComponent } from "./components/main/main.component";
import { OperationsManualGuard } from "./services/operations-manual.guard";

const routes: Routes = [
    {
        path: "operations-manual",
        component: MainComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            OperationsManualGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorOperationsManualRead]),
        ],
        data: {
            titleTranslationKey: "dtmWebAppLibOpsMan.routeTitles.mainHeader",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperationsManualRoutingModule {}
