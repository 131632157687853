<ng-container *ngrxLet="properties$ as properties">
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.numberOfEnginesLabel' | transloco"
        [isEmpty]="properties.numberOfEngines | invoke : isEmpty"
    >
        {{ properties.numberOfEngines }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.driveTypeLabel' | transloco"
        [isEmpty]="properties.driveType | invoke : isEmpty"
    >
        {{ "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.driveTypeValue" | transloco : { value: properties.driveType } }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.takeOffMassLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.takeOffMassDescription' | transloco"
        [isEmpty]="properties.takeOffMass | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.takeOffMassValue"
                | transloco : { value: properties.takeOffMass | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxTakeOffMassLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxTakeOffMassDescription' | transloco"
        [isEmpty]="properties.maxTakeOffMass | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxTakeOffMassValue"
                | transloco : { value: properties.maxTakeOffMass | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxDroneWidthLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxDroneWidthDescription' | transloco"
        [isEmpty]="properties.maxDroneWidth | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxDroneWidthValue"
                | transloco : { value: properties.maxDroneWidth! | convertToCentimeters | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxFlightTimeLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxFlightTimeDescription' | transloco"
        [isEmpty]="properties.maxFlightTime | invoke : isEmpty"
    >
        {{ properties.maxFlightTime | duration }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.flightSpeedLimitLabel' | transloco"
        [value]="properties.hasFlightSpeedLimit"
    >
    </dtm-web-app-lib-uav-property-boolean>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxFlightSpeedLabel' | transloco"
        [isEmpty]="properties.maxFlightSpeed | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxFlightSpeedValue"
                | transloco
                    : {
                          value:
                              properties.maxFlightSpeed
                              | convertToKmPerH
                              | localizeNumber : { maximumFractionDigits: MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS }
                      }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        *ngIf="properties.minFlightSpeed !== undefined"
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.minFlightSpeedLabel' | transloco"
        [isEmpty]="properties.minFlightSpeed === null"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.minFlightSpeedValue"
                | transloco
                    : {
                          value:
                              properties.minFlightSpeed
                              | convertToKmPerH
                              | localizeNumber : { maximumFractionDigits: MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS }
                      }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxClimbSpeedLabel' | transloco"
        [isEmpty]="properties.maxClimbSpeed | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxClimbSpeedValue"
                | transloco : { value: properties.maxClimbSpeed | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxDescentSpeedLabel' | transloco"
        [isEmpty]="properties.maxDescentSpeed | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxDescentSpeedValue"
                | transloco : { value: properties.maxDescentSpeed | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxWindLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxWindDescription' | transloco"
        [isEmpty]="properties.maxWind | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxWindValue"
                | transloco : { value: properties.maxWind | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.rainFlightPossibilityLabel' | transloco"
        [value]="properties.hasRainFlightPossibility"
    >
    </dtm-web-app-lib-uav-property-boolean>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.recommendedAmbientTemperatureLabel' | transloco"
        [isEmpty]="
            (properties.minRecommendedAmbientTemperature | invoke : isEmpty) ||
            (properties.maxRecommendedAmbientTemperature | invoke : isEmpty)
        "
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.recommendedAmbientTemperatureValue"
                | transloco : { value: properties.minRecommendedAmbientTemperature }
        }}
        -
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.recommendedAmbientTemperatureValue"
                | transloco : { value: properties.maxRecommendedAmbientTemperature }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxFlightAltitudeLabel' | transloco"
        [isEmpty]="properties.maxFlightAltitude | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.maxFlightAltitudeValue"
                | transloco : { value: properties.maxFlightAltitude | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
</ng-container>
