import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { tap } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { PermitsFilters } from "../../../../services/operator-permits.models";

interface PermitsOwnedFiltersComponentState {
    incomingFilters: PermitsFilters | undefined;
    filtersCount: number;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "searchPhrase",
        filterLabel: "dtmWebAppLibOperatorPermits.permitsFilters.searchPhraseLabel",
        type: FilterType.TextEllipsis,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-permits-owned-filters",
    templateUrl: "./permits-owned-filters.component.html",
    styleUrls: ["./permits-owned-filters.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermitsOwnedFiltersComponent {
    protected readonly FILTERS_MAP = FILTERS_MAP;

    @Input() public set incomingFilters(value: PermitsFilters | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }

    @Output() public filtersChange = new EventEmitter<PermitsFilters>();

    protected readonly filtersForm = new FormGroup({
        searchPhrase: new FormControl<string>(""),
    });
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");

    constructor(private readonly localStore: LocalComponentStore<PermitsOwnedFiltersComponentState>) {
        localStore.setState({ incomingFilters: undefined, filtersCount: 0 });
        this.watchFilterFormValueChanges();
        this.assignInitialFiltersAndWatchChanges();
    }

    protected clearFilters(): void {
        this.filtersForm.reset();
    }

    private watchFilterFormValueChanges(): void {
        this.filtersForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(equal),
                tap((value) => {
                    this.updateAppliedFiltersCount();
                    this.filtersChange.emit(value);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateAppliedFiltersCount(): void {
        const values = Object.values(this.filtersForm.value);
        const filtersCount = values.flat().filter(Boolean).length;

        this.localStore.patchState({ filtersCount });
    }

    private assignInitialFiltersAndWatchChanges() {
        this.localStore
            .selectByKey("incomingFilters")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((filters) => {
                if (!this.isIncomingFilterDifferentThanForm(filters)) {
                    return;
                }

                this.filtersForm.controls.searchPhrase.setValue(filters.searchPhrase, { emitEvent: false });
                this.updateAppliedFiltersCount();
            });
    }

    private isIncomingFilterDifferentThanForm(filters: PermitsFilters): boolean {
        return filters.searchPhrase !== this.filtersForm.controls.searchPhrase.value;
    }
}
