import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MEMBERSHIP_ENDPOINTS, MembershipEndpoints } from "../../membership/membership.tokens";
import {
    GetMembersCapabilitiesResponseBody,
    GetMembersListResponseBody,
    GetMembershipMissionsResponseBody,
    convertGetMembersListResponseToMembersWithPages,
    convertGetMembershipMissionsResponseBodyToMembershipMissionsWithPages,
    convertMembershipCapabilitiesResponseBodyToMembershipCapabilities,
    getMembersRequestParams,
    getMembershipMissionsListRequestParams,
    transformChangeInvitationStatusErrorResponse,
    transformChangeUserPermissionsErrorResponseToMembershipPermissionsError,
    transformErrorResponseToMembershipError,
    transformSendInvitationErrorResponseToMembershipError,
} from "./membership-api.converters";
import {
    MemberProfile,
    MembersQueryParams,
    MembersWithPages,
    MembershipCapabilities,
    MembershipCapabilitiesErrorType,
    MembershipErrorType,
    MembershipInvitation,
    MembershipInvitationStatus,
    MembershipMissionsFilter,
    MembershipMissionsWithPages,
    MembershipPermission,
    MembershipStatus,
    MembershipUserInvitation,
} from "./membership.models";

@Injectable({
    providedIn: "root",
})
export class MembershipApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(MEMBERSHIP_ENDPOINTS) private readonly endpoints: MembershipEndpoints) {}

    public getCapabilities(operatorId: string): Observable<MembershipCapabilities> {
        const requestParams = new HttpParams().set("operatorId", operatorId);

        return this.httpClient.get<GetMembersCapabilitiesResponseBody>(this.endpoints.getCapabilities, { params: requestParams }).pipe(
            map((response) => convertMembershipCapabilitiesResponseBodyToMembershipCapabilities(response)),
            catchError(() => throwError(() => ({ type: MembershipCapabilitiesErrorType.Unknown })))
        );
    }

    public inviteUser(invitation: MembershipUserInvitation): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.addInvitation, invitation, { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) })
            .pipe(catchError((error) => throwError(() => transformSendInvitationErrorResponseToMembershipError(error))));
    }

    public changeInvitedUserPermissions(operatorId: string, invitationId: string, permissions: MembershipPermission[]): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.invitedUserPermissions, { invitationId }), { operatorId, permissions })
            .pipe(catchError((error) => throwError(() => transformChangeUserPermissionsErrorResponseToMembershipPermissionsError(error))));
    }

    public changeMembershipUserPermissions(
        operatorId: string,
        membershipId: string | null,
        permissions: MembershipPermission[]
    ): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.membershipUserPermissions, { memberId: membershipId ?? "" }), {
                operatorId,
                permissions,
            })
            .pipe(catchError((error) => throwError(() => transformChangeUserPermissionsErrorResponseToMembershipPermissionsError(error))));
    }

    public resendInvitation(invitationId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resendInvitation, { invitationId }), null)
            .pipe(catchError((error) => throwError(() => transformErrorResponseToMembershipError(error))));
    }

    public resendInvitationToMember(membershipId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resendInvitationToMember, { membershipId }), null)
            .pipe(catchError((error) => throwError(() => transformErrorResponseToMembershipError(error))));
    }

    public getInvitationDetails(invitationId: string): Observable<MembershipInvitation> {
        return this.httpClient
            .get<MembershipInvitation>(StringUtils.replaceInTemplate(this.endpoints.invitationManagement, { invitationId }))
            .pipe(catchError((error) => throwError(() => transformErrorResponseToMembershipError(error))));
    }

    public changeInvitationStatus(invitationId: string, invitationStatus: MembershipInvitationStatus) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.changeInvitationStatus, { invitationId }), {
                status: invitationStatus,
            })
            .pipe(catchError((error) => throwError(() => transformChangeInvitationStatusErrorResponse(error))));
    }

    public getMembers(params: MembersQueryParams): Observable<MembersWithPages> {
        const requestParams = getMembersRequestParams(params);

        return this.httpClient.get<GetMembersListResponseBody>(this.endpoints.getMembers, { params: requestParams }).pipe(
            map((response) => convertGetMembersListResponseToMembersWithPages(response)),
            catchError(() => throwError(() => ({ type: MembershipErrorType.Unknown })))
        );
    }

    public getPilotsProfile(pilotId: string): Observable<MemberProfile> {
        return this.httpClient
            .get<MemberProfile>(StringUtils.replaceInTemplate(this.endpoints.getPilotsProfile, { pilotId }))
            .pipe(catchError((error) => throwError(() => transformErrorResponseToMembershipError(error))));
    }

    public changeMemberStatus(status: MembershipStatus, membershipId: string, operatorId: string, note?: string) {
        return this.httpClient
            .put(
                StringUtils.replaceInTemplate(this.endpoints.changeMemberStatus, { memberId: membershipId }),
                {
                    operatorId,
                    note,
                    status,
                },
                { context: new HttpContext().set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true) }
            )
            .pipe(catchError((error) => throwError(() => transformErrorResponseToMembershipError(error))));
    }

    public removeInvitation(invitationId: string) {
        return this.httpClient
            .delete(StringUtils.replaceInTemplate(this.endpoints.invitationManagement, { invitationId }))
            .pipe(catchError((error) => throwError(() => transformErrorResponseToMembershipError(error))));
    }

    public removeMember(membershipId: string) {
        return this.httpClient
            .delete(StringUtils.replaceInTemplate(this.endpoints.removeMember, { memberId: membershipId }))
            .pipe(catchError((error) => throwError(() => transformErrorResponseToMembershipError(error))));
    }

    public getMissionsList({
        operatorId,
        pilotId,
        filtersQuery,
        size,
        page,
    }: {
        operatorId: string;
        pilotId: string;
        filtersQuery: MembershipMissionsFilter;
        size: number;
        page: number;
    }): Observable<MembershipMissionsWithPages> {
        const params = getMembershipMissionsListRequestParams(operatorId, pilotId, filtersQuery, size, page);

        return this.httpClient
            .get<GetMembershipMissionsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getMissionsList, { operatorId, pilotId }),
                { params }
            )
            .pipe(
                map((response) => convertGetMembershipMissionsResponseBodyToMembershipMissionsWithPages(response)),
                catchError((error) => throwError(() => transformErrorResponseToMembershipError(error)))
            );
    }
}
