<ng-container *ngrxLet="{ documents: documents$, isManageButtonVisible: isManageButtonVisible$ } as vm">
    <dtm-ui-empty-state *ngIf="isEmptyState$ | ngrxPush; else uavModelDocumentsTemplate">
        <ng-container titleSlot>
            {{ "dtmWebAppLibUav.uavDocuments.model.headerLabel" | transloco }}
        </ng-container>
        <ng-container messageSlot>
            <ng-container *ngIf="vm.isManageButtonVisible">
                {{ "dtmWebAppLibUav.uavDocuments.model.emptyStateManageableMessage" | transloco }}
            </ng-container>
            <ng-container *ngIf="!vm.isManageButtonVisible">
                {{ "dtmWebAppLibUav.uavDocuments.model.emptyStateMessage" | transloco }}
            </ng-container>
        </ng-container>
        <img imageSlot src="assets/images/documents-empty.svg" alt="" />
        <button footerSlot type="button" class="button-primary" (click)="manage.emit()" *ngIf="vm.isManageButtonVisible">
            {{ "dtmWebAppLibUav.uavDocuments.model.emptyStateButtonLabel" | transloco }}
        </button>
    </dtm-ui-empty-state>

    <ng-template #uavModelDocumentsTemplate>
        <section class="header">
            <h2>{{ "dtmWebAppLibUav.uavDocuments.model.headerLabel" | transloco }}</h2>
            <button type="button" class="button-secondary" (click)="manage.emit()" *ngIf="vm.isManageButtonVisible">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibUav.uavDocuments.model.manageButtonLabel" | transloco }}
            </button>
        </section>
        <dtm-ui-files-groups-display [groups]="vm.documents"></dtm-ui-files-groups-display>
    </ng-template>
</ng-container>
