import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
} from "@dtm-frontend/shared/map/cesium";
import { ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import { GeoJSON, MissionPlanRoute, RouteAreaTypeId, RouteData, TimeRange } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import {
    Feature as GeoJSONFeature,
    Polygon,
    Properties,
    feature as createFeature,
    featureCollection as createFeatureCollection,
    feature,
    featureCollection,
} from "@turf/helpers";
import { Observable, first, map, startWith } from "rxjs";
import { MissionCategory, MissionPlanData } from "../../../../shared/models/mission.models";
import { MissionUtils } from "../../../../shared/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface MissionMapComponentState {
    route: MissionPlanRoute | undefined;
    shouldShowAreaBuffers: boolean;
}

@Component({
    selector: "dtm-mission-mission-search-preview-map[route]",
    templateUrl: "./mission-map.component.html",
    styleUrls: ["./mission-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionMapComponent {
    @Input() public set route(value: MissionPlanRoute | undefined) {
        if (value) {
            const bbox = turfBbox(this.getZoomArea(value));
            Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
            Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

            // NOTE: setTimeout is needed to make sure that the camera is set after the map is initialized
            setTimeout(() => this.flyToRoute(value));
        }

        this.localStore.patchState({ route: value });
    }

    @Input() public set shouldShowAreaBuffers(value: BooleanInput) {
        this.localStore.patchState({ shouldShowAreaBuffers: coerceBooleanProperty(value) });
    }

    protected readonly SceneMode = SceneMode;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly MissionCategory = MissionCategory;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly routeDrawableFeature: RouteAreaTypeId[] = ["flightArea", "waypoint"];

    protected readonly route$ = this.localStore.selectByKey("route").pipe(RxjsUtils.filterFalsy());
    protected readonly shouldShowAreaBuffers$ = this.localStore.selectByKey("shouldShowAreaBuffers");
    protected readonly routeData$ = this.route$.pipe(
        map(
            (route, uniqueRouteId) =>
                ({
                    isMain: true,
                    isPathBased: route.isPathBased,
                    route: route,
                    uniqueRouteId,
                    isOutsideDtm: route.stats?.flight.dtmNames && route.stats.flight.dtmNames.length === 0,
                } satisfies RouteData<MissionPlanData>)
        )
    );
    protected readonly isInitialized$ = this.route$.pipe(
        first(),
        startWith(false),
        map(() => true)
    );
    protected readonly missionTimeRange$: Observable<TimeRange> = this.route$.pipe(
        map(MissionUtils.convertRouteToWaypoints),
        map(MissionUtils.getTimeRangeFromWaypointsWithSection)
    );

    constructor(
        private readonly localStore: LocalComponentStore<MissionMapComponentState>,
        private readonly cameraHelperService: CameraHelperService,
        viewerConfiguration: ViewerConfiguration
    ) {
        this.localStore.setState({
            route: undefined,
            shouldShowAreaBuffers: false,
        });

        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D,
        };
    }

    protected flyToGeometry(geometry: GeoJSON) {
        this.cameraHelperService.flyToGeoJSON(geometry);
    }

    protected getRoutesFromRouteData(data?: RouteData<MissionPlanData>): MissionPlanRoute[] {
        return data?.route ? [data.route] : [];
    }

    private getZoomArea(route: MissionPlanRoute) {
        return featureCollection<Polygon, Properties>(
            route.sections
                .map((section) => section.segment?.safetyArea.volume.area ?? section.flightZone?.safetyArea.volume.area)
                .filter(FunctionUtils.isTruthy)
                .map((polygon) => feature(polygon))
        );
    }

    private flyToRoute(route: MissionPlanRoute) {
        const zoomArea = createFeatureCollection(
            route.sections.reduce<GeoJSONFeature[]>((features, section) => {
                const area = section.flightZone?.safetyArea.volume.area ?? section.segment?.safetyArea.volume.area;

                if (area) {
                    features.push(createFeature(area));
                }

                return features;
            }, [])
        );

        this.flyToGeometry(zoomArea);
    }
}

