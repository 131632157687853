import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Page, PermitType } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { OwnedPermitStatus, OwnedSpecificPermitListItem, PermitListItem } from "../../../../services/operator-permits.models";

interface PermitsOwnedListComponentState {
    ownedPermits: OwnedSpecificPermitListItem[];
    pagination: Page | undefined;
    expandedElement: OwnedSpecificPermitListItem | undefined;
    isPermittedToCreateApplication: boolean;
}

@Component({
    selector: "dtm-web-app-lib-operator-permits-owned-list[ownedPermits][pagination]",
    templateUrl: "./permits-owned-list.component.html",
    styleUrls: ["./permits-owned-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class PermitsOwnedListComponent {
    @Input() public set ownedPermits(value: OwnedSpecificPermitListItem[] | undefined) {
        this.localStore.patchState({ ownedPermits: value ?? [] });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }
    @Input() public set isPermittedToCreateApplication(value: boolean) {
        this.localStore.patchState({ isPermittedToCreateApplication: value });
    }

    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Output() public permitNameChange = new EventEmitter<PermitListItem>();

    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly ownedPermits$ = this.localStore.selectByKey("ownedPermits").pipe(RxjsUtils.filterFalsy());
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly isPermittedToCreateApplication$ = this.localStore.selectByKey("isPermittedToCreateApplication");
    protected readonly OwnedPermitStatus = OwnedPermitStatus;
    protected readonly PermitType = PermitType;

    protected readonly displayedColumns: string[] = [
        "expandHandle",
        "status",
        "permitNumber",
        "name",
        "submissionDate",
        "periodOfValidity",
        "actions",
    ];

    constructor(private readonly localStore: LocalComponentStore<PermitsOwnedListComponentState>) {
        localStore.setState({ ownedPermits: [], pagination: undefined, expandedElement: undefined, isPermittedToCreateApplication: false });
    }

    protected toggleExpandableRow(row: OwnedSpecificPermitListItem): void {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }

    protected getPermitDetailsRoute(permitType: PermitType): string {
        switch (permitType) {
            case PermitType.Association:
                return "/association-permits";
            case PermitType.CrossBorder:
                return "/cross-border-permits";
            default:
                return "/operator-permits";
        }
    }
}
