import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FILES_UPLOAD_API_PROVIDER, UploadedFile } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { BYTES_IN_MEGABYTE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { FOREIGN_COMPETENCIES_API_ENDPOINTS, PILOT_PROFILE_ENDPOINTS } from "../../../pilot-profile.tokens";
import { foreignCompetenciesUploadApiFactory } from "../../../services/foreign-competencies-upload-api.factory";
import { ForeignCompetenciesUploadApiService } from "../../../services/foreign-competencies-upload-api.service";
import {
    ForeignCompetencyDocument,
    ForeignCompetencyDocumentDraft,
    ForeignCompetencyDocumentEditOrAdd,
    ForeignCompetencyStatus,
    ForeignCompetencyType,
} from "../../../services/pilot-profile.models";

interface ForeignCompetenciesComponentState {
    isProcessing: boolean;
    isEditMode: boolean;
    competencies: ForeignCompetencyDocument[];
    editedCompetency: ForeignCompetencyDocument | undefined;
}

interface ForeignCompetenciesForm {
    type: FormControl<ForeignCompetencyType | null>;
    expirationDate: FormControl<Date | null>;
    file: FormControl<UploadedFile[]>;
}
const TOMORROW_DATE = new Date(new Date().setDate(new Date().getDate() + 1));
// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;
const UPLOAD_FILE_ALLOWED_MIME_TYPES = ["application/pdf"];

@Component({
    selector: "dtm-web-app-lib-foreign-competencies[competencies]",
    templateUrl: "./foreign-competencies.component.html",
    styleUrls: ["./foreign-competencies.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: ForeignCompetenciesUploadApiService },
        {
            provide: FOREIGN_COMPETENCIES_API_ENDPOINTS,
            useFactory: foreignCompetenciesUploadApiFactory,
            deps: [PILOT_PROFILE_ENDPOINTS],
        },
    ],
})
export class ForeignCompetenciesComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set competencies(value: ForeignCompetencyDocument[] | undefined) {
        this.localStore.patchState({ competencies: value ?? [], isEditMode: false, editedCompetency: undefined });
        this.foreignCompetenciesForm.reset();
    }
    @Output() public readonly foreignCompetencySave = new EventEmitter<ForeignCompetencyDocumentEditOrAdd>();
    @Output() public readonly delete = new EventEmitter<string>();

    protected readonly ForeignCompetencyType = ForeignCompetencyType;
    protected readonly ForeignCompetencyStatus = ForeignCompetencyStatus;
    protected readonly TOMORROW_DATE = TOMORROW_DATE;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    protected readonly UPLOAD_FILE_ALLOWED_MIME_TYPES = UPLOAD_FILE_ALLOWED_MIME_TYPES;

    protected readonly typeControl = new FormControl<ForeignCompetencyType | null>(null, Validators.required);
    protected readonly expirationDateControl = new FormControl<Date | null>(null, Validators.required);
    protected readonly uploadedFileControl = new FormControl<UploadedFile[]>([], {
        validators: [Validators.required, Validators.maxLength(1)],
        nonNullable: true,
    });

    protected readonly editedCompetency$ = this.localStore.selectByKey("editedCompetency");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly competencies$ = this.localStore.selectByKey("competencies");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly foreignCompetenciesForm = new FormGroup<ForeignCompetenciesForm>({
        type: this.typeControl,
        expirationDate: this.expirationDateControl,
        file: this.uploadedFileControl,
    });

    constructor(
        private readonly localStore: LocalComponentStore<ForeignCompetenciesComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        localStore.setState({ isProcessing: false, competencies: [], isEditMode: false, editedCompetency: undefined });
    }

    protected setEditMode(isEditMode: boolean) {
        this.localStore.patchState({ isEditMode, editedCompetency: undefined });
        this.foreignCompetenciesForm.reset();
    }

    protected isCompetencyTypeDisabled(competencies: ForeignCompetencyDocument[], type: ForeignCompetencyType) {
        return competencies.some((competency) => competency.type === type);
    }

    protected saveForm() {
        if (this.foreignCompetenciesForm.invalid) {
            this.foreignCompetenciesForm.markAllAsTouched();

            return;
        }
        const editedCompetency = this.localStore.selectSnapshotByKey("editedCompetency");
        this.foreignCompetencySave.emit({
            draft: this.foreignCompetenciesForm.value as ForeignCompetencyDocumentDraft,
            competencyId: editedCompetency?.id,
        });
    }

    protected editCompetency(competency: ForeignCompetencyDocument) {
        this.foreignCompetenciesForm.patchValue({
            type: competency.type,
            expirationDate: competency.expirationDate,
            file: [competency.file],
        });
        this.localStore.patchState({ isEditMode: true, editedCompetency: competency });
    }
}
