<mat-card class="posts-container" *ngrxLet="{ posts: posts$, language: language$, error: error$ } as vm">
    <dtm-ui-card-header>{{ "dtmWebAppLibDashboard.posts.header" | transloco }}</dtm-ui-card-header>
    <dtm-ui-error *ngIf="vm.error; else postsTemplate" [mode]="ErrorMode.Secondary"></dtm-ui-error>

    <ng-template #postsTemplate>
        <ng-container *ngIf="vm.posts && vm.posts.length > 0; else emptyStateTemplate">
            <dtm-ui-carousel *ngIf="vm.posts.length > 1; else singlePostTemplate">
                <ng-container *ngFor="let post of vm.posts">
                    <div class="slide" *dtmUiCarouselSlides>
                        <ng-container [ngTemplateOutlet]="postItemTemplate" [ngTemplateOutletContext]="{ $implicit: post }"></ng-container>
                    </div>
                </ng-container>
            </dtm-ui-carousel>
            <ng-template #singlePostTemplate>
                <ng-container [ngTemplateOutlet]="postItemTemplate" [ngTemplateOutletContext]="{ $implicit: vm.posts[0] }"></ng-container>
            </ng-template>
        </ng-container>

        <ng-template #postItemTemplate let-post>
            <dtm-web-app-lib-post-item
                [post]="vm.language === AvailableLanguages.English ? post.englishContent : post.nativeContent"
            ></dtm-web-app-lib-post-item>
        </ng-template>
    </ng-template>
</mat-card>

<ng-template #emptyStateTemplate>
    <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
        <ng-container titleSlot>
            {{ "dtmWebAppLibDashboard.posts.emptyStateHeader" | transloco }}
        </ng-container>
        <img imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
    </dtm-ui-empty-state>
</ng-template>
