import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NEW_UAV_WIZARD_ID, NewUavWizardSteps } from "../new-uav-wizard.models";

@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-header",
    templateUrl: "./new-uav-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewUavWizardHeaderComponent {
    protected readonly wizardId = NEW_UAV_WIZARD_ID;
    protected readonly NewUavWizardSteps = NewUavWizardSteps;
}
