import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { RouterModule } from "@angular/router";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { DashboardContainerComponent } from "./components/dashboard-container/dashboard-container.component";
import { DashboardMissionsFiltersComponent } from "./components/dashboard-missions-filters/dashboard-missions-filters.component";
import { DashboardMissionsListComponent } from "./components/dashboard-missions-list/dashboard-missions-list.component";
import { DashboardMissionsComponent } from "./components/dashboard-missions/dashboard-missions.component";
import { PostItemComponent } from "./components/post-item/post-item.component";
import { PostsComponent } from "./components/posts/posts.component";
import { WelcomeViewComponent } from "./components/welcome-view/welcome-view.component";
import { DashboardApiService } from "./services/dashboard-api.service";
import { DashboardState } from "./state/dashboard.state";

@NgModule({
    declarations: [
        DashboardContainerComponent,
        WelcomeViewComponent,
        PostsComponent,
        PostItemComponent,
        DashboardMissionsListComponent,
        DashboardMissionsFiltersComponent,
        DashboardMissionsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        LetModule,
        PushModule,
        SharedUiModule,
        TranslocoModule,
        SharedI18nModule,
        NgxsModule.forFeature([DashboardState]),
        MatCardModule,
        MatMenuModule,
        MatTableModule,
        MatInputModule,
        SharedWebAppModule,
        ReactiveFormsModule,
        SharedMissionModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibDashboard",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: DashboardApiService,
            useValue: undefined,
        },
    ],
    exports: [
        PostsComponent,
        PostItemComponent,
        DashboardMissionsListComponent,
        DashboardMissionsFiltersComponent,
        DashboardMissionsComponent,
        WelcomeViewComponent,
    ],
})
export class DashboardModule {
    public static forRoot(): ModuleWithProviders<DashboardModule> {
        return {
            ngModule: DashboardModule,
            providers: [DashboardApiService],
        };
    }

    public static forTest(): ModuleWithProviders<DashboardModule> {
        return {
            ngModule: DashboardModule,
            providers: [
                {
                    provide: DashboardApiService,
                    useValue: null,
                },
            ],
        };
    }
}
