import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { GeolocationService, WebGeolocationService } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ToastContainerModule } from "ngx-toastr";
import { MissionModule } from "../mission/mission.module";
import { MissionAPIService } from "../mission/services/mission-api.service";
import { SharedWebAppModule } from "../shared/index";
import { MissionMessagesComponent } from "./components/mission-messages/mission-messages.component";
import { MissionRouteComponent } from "./components/mission-route/mission-route.component";
import { AcceptedMissionTileComponent } from "./components/prepare-for-mission/accepted-mission-tile/accepted-mission-tile.component";
import { MissionActivationModalComponent } from "./components/prepare-for-mission/accepted-mission-tile/mission-activation-modal/mission-activation-modal.component";
import { PrepareForMissionComponent } from "./components/prepare-for-mission/prepare-for-mission.component";
import { TacticalEmergencyComponent } from "./components/tactical-emergency/tactical-emergency.component";
import { TacticalMissionDetailsComponent } from "./components/tactical-mission-details/tactical-mission-details.component";
import { TacticalMissionStatusChipComponent } from "./components/tactical-mission-view/tactical-mission-status-chip/tactical-mission-status-chip.component";
import { TacticalMissionViewComponent } from "./components/tactical-mission-view/tactical-mission-view.component";
import { TacticalNearbyMissionsComponent } from "./components/tactical-nearby-missions/tactical-nearby-missions.component";
import { TacticalViewsComponent } from "./components/tactical-views/tactical-views.component";
import { TacticalApiService } from "./services/tactical-api.service";
import { TacticalState } from "./state/tactical.state";

@NgModule({
    declarations: [
        PrepareForMissionComponent,
        MissionRouteComponent,
        AcceptedMissionTileComponent,
        MissionActivationModalComponent,
        TacticalMissionViewComponent,
        TacticalMissionDetailsComponent,
        TacticalMissionStatusChipComponent,
        TacticalViewsComponent,
        TacticalNearbyMissionsComponent,
        TacticalEmergencyComponent,
        MissionMessagesComponent,
    ],
    imports: [
        CommonModule,
        LetModule,
        PushModule,
        SharedI18nModule,
        MatExpansionModule,
        SharedUiModule,
        NgxsModule.forFeature([TacticalState]),
        MatCardModule,
        MatButtonModule,
        MatMenuModule,
        SharedWebAppModule,
        CesiumMapModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatInputModule,
        MatTooltipModule,
        RouterModule,
        ToastContainerModule,
        MatSlideToggleModule,
        MatTabsModule,
        MissionModule,
        SharedMissionModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibTactical",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: GeolocationService,
            useClass: WebGeolocationService,
        },
    ],
    exports: [
        AcceptedMissionTileComponent,
        PrepareForMissionComponent,
        TacticalMissionViewComponent,
        TacticalEmergencyComponent,
        TacticalNearbyMissionsComponent,
    ],
})
export class TacticalModule {
    public static forRoot(): ModuleWithProviders<TacticalModule> {
        return {
            ngModule: TacticalModule,
            providers: [],
        };
    }
    public static forTest(): ModuleWithProviders<TacticalModule> {
        return {
            ngModule: TacticalModule,
            providers: [
                {
                    provide: TacticalApiService,
                    useValue: null,
                },
                {
                    provide: MissionAPIService,
                    useValue: null,
                },
                {
                    provide: WebsocketService,
                    useValue: null,
                },
            ],
        };
    }
}
