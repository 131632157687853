import { InjectionToken } from "@angular/core";

export interface OperatorPermitsEndpoints {
    specificPermitDrafts: string;
    specificPermits: string;
    updatePermitName: string;
    getOperatorPermitsCapabilities: string;
    getOperatorPermitDetails: string;
    getCrossBorderPermitDetails: string;
    getAssociationPermitDetails: string;
    downloadKmlLocationFile: string;
    removeDraft: string;
}

export const OPERATOR_PERMITS_ENDPOINTS = new InjectionToken<OperatorPermitsEndpoints>("OperatorPermits endpoints");
