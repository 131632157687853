import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { GlobalOperatorPermissions, OperatorType } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { firstValueFrom } from "rxjs";
import { first } from "rxjs/operators";
import { OperatorContextState } from "../modules/shared/operator-context/state/operator-context.state";

export function dtmOperatorPermissionGuard(routePermissions: GlobalOperatorPermissions[]): CanActivateFn {
    return async () => {
        const store = inject(Store);
        const router = inject(Router);
        const context = await firstValueFrom(store.select(OperatorContextState.selectedContext).pipe(first(FunctionUtils.isTruthy)));

        if (context.type === OperatorType.Personal) {
            return true;
        }

        if (routePermissions.find((permission) => context.permissions.includes(permission))) {
            return true;
        }

        router.navigate(["/"]);

        return false;
    };
}
