<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <dtm-ui-wizard-step-wrapper
        [title]="'dtmWebAppLibPilotOperatorRegistration.summaryHeader' | transloco"
        [stepNumber]="3"
        [stepsAmount]="3"
        [isBackButtonVisible]="true"
        [nextButtonLabel]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.summary.registerPilotButtonLabel' | transloco"
        (next)="saveSummaryData()"
        (back)="goToAdditionalDataStep.emit()"
        dtmUiInvalidFormScrollable
    >
        <div class="summary-main-container" *ngrxLet="pilotRegistrationFormValue$ as pilotRegistrationFormValue">
            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.summary.registrationInfoSectionHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="basicDataEditButtonTemplate"></ng-container>
                <div class="summary-section single-value">
                    <dtm-ui-label-value
                        [label]="
                            'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.registrationInOtherCountryQuestion'
                                | transloco
                        "
                        [value]="
                            'dtmWebAppLibPilotOperatorRegistration.summary.trueFalseValueLabel'
                                | transloco : { value: !!pilotRegistrationFormValue.foreignOperator?.number }
                        "
                    ></dtm-ui-label-value>
                </div>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                *ngrxLet="capabilities$ as capabilities"
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.personalDataHeaderHeader' | transloco"
            >
                <div class="summary-section">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.firstNameLabel' | transloco"
                        [value]="capabilities.firstName"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.lastNameLabel' | transloco"
                        [value]="capabilities.lastName"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.dateOfBirthLabel' | transloco"
                        [value]="capabilities.dateOfBirth | localizeDate"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.nationalityLabel' | transloco"
                    >
                        <dtm-ui-country-display [country]="capabilities.nationality"></dtm-ui-country-display>
                    </dtm-ui-label-value>
                </div>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.addressHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="basicDataEditButtonTemplate"></ng-container>
                <dtm-web-app-lib-pilot-operator-registration-address-summary
                    [address]="pilotRegistrationFormValue.address"
                ></dtm-web-app-lib-pilot-operator-registration-address-summary>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.basicData.identityConfirmationSectionHeader' | transloco"
                *ngIf="pilotRegistrationFormValue.identityDocument"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="basicDataEditButtonTemplate"></ng-container>
                <div class="summary-section single-value">
                    <dtm-ui-uploaded-files-display [uploadedFiles]="pilotRegistrationFormValue.identityDocument">
                    </dtm-ui-uploaded-files-display>
                </div>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceSectionHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="additionalDataEditButtonTemplate"></ng-container>
                <dtm-web-app-lib-pilot-operator-registration-insurance-summary
                    [insurancePolicyNumber]="pilotRegistrationFormValue.insurancePolicyNumber"
                    [insurancePolicyExpirationDate]="pilotRegistrationFormValue.insurancePolicyExpirationDate"
                ></dtm-web-app-lib-pilot-operator-registration-insurance-summary>
            </dtm-ui-presentation-tile-with-action>

            <dtm-ui-presentation-tile-with-action
                *ngIf="pilotRegistrationFormValue.legalGuardian"
                [titleTile]="'dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianSectionHeader' | transloco"
            >
                <ng-container actionButtonSlot [ngTemplateOutlet]="additionalDataEditButtonTemplate"></ng-container>
                <dtm-web-app-lib-pilot-operator-registration-legal-guardian-summary
                    [legalGuardian]="pilotRegistrationFormValue.legalGuardian"
                ></dtm-web-app-lib-pilot-operator-registration-legal-guardian-summary>
            </dtm-ui-presentation-tile-with-action>
            <form [formGroup]="statementsForm">
                <h4 class="statements-header">
                    {{ "dtmWebAppLibPilotOperatorRegistration.summary.statementsHeader" | transloco }}
                </h4>
                <dtm-ui-checkbox-field class="with-validation" [formControl]="authenticityOfDataControl" required>
                    <span>
                        {{ "dtmWebAppLibPilotOperatorRegistration.summary.authenticityStatement" | transloco }}
                    </span>
                </dtm-ui-checkbox-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="authenticityOfDataControl; name: ['required']">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
                <dtm-ui-checkbox-field class="with-validation" [formControl]="generalDataProtectionRegulationControl" required>
                    <span>
                        {{ "dtmWebAppLibPilotOperatorRegistration.summary.generalDataProtectionRegulationStatement" | transloco }}
                    </span>
                </dtm-ui-checkbox-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="generalDataProtectionRegulationControl; name: ['required']">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </form>
        </div>
    </dtm-ui-wizard-step-wrapper>
</dtm-ui-loader-container>

<ng-template #basicDataEditButtonTemplate>
    <button class="button-secondary" type="button" (click)="goToBasicDataStep.emit()">
        {{ "dtmWebAppLibPilotOperatorRegistration.summary.editButtonLabel" | transloco }}
    </button>
</ng-template>

<ng-template #additionalDataEditButtonTemplate>
    <button class="button-secondary" type="button" (click)="goToAdditionalDataStep.emit()">
        {{ "dtmWebAppLibPilotOperatorRegistration.summary.editButtonLabel" | transloco }}
    </button>
</ng-template>
