import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import {
    CesiumPointerManagerService,
    CesiumPointerType,
    ChildEntitySubtype,
    CylinderPointProps,
    DraggableHeightEntityBase,
    HeightHelperService,
    MapEntityType,
    MapUtils,
} from "@dtm-frontend/shared/map/cesium";
import { ISO8601TimeDuration, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    AcEntity,
    AcLayerComponent,
    AcNotification,
    CameraService,
    Cartesian3,
    CesiumEvent,
    CesiumService,
    CoordinateConverter,
    PickOptions,
} from "@pansa/ngx-cesium";
import { Subject, combineLatest, combineLatestWith, distinctUntilChanged, fromEvent, map, pairwise, tap } from "rxjs";
import { ItineraryContentEntity, Polyline3DItineraryEntity } from "../../../models/itinerary.model";
import { MissionPlanItineraryConstraints } from "../../../models/mission.model";
import { ItineraryService } from "../../../services/itinerary.service";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface ChildEntityHeightPointsLayerComponentState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    labelsTemplate: TemplateRef<any> | null;
    isShown: boolean;
    constraints: MissionPlanItineraryConstraints | undefined;
}

class ChildEntityHeightPointAcEntity extends AcEntity implements DraggableHeightEntityBase {
    public id!: string;
    public position!: Cartesian3;
    public height!: number;
    public waypointIndex!: number;
    public parentId!: string;
    public isRunway!: boolean;
    public groundPoint!: Cartesian3;
    public childEntity!: ChildEntitySubtype;
    public maxHeight!: number;
    public minHeight!: number;
    public stopover: ISO8601TimeDuration | undefined;

    public get elevatedHeightPoint() {
        return this.position;
    }
}

const POINT_PROPS: CylinderPointProps = {
    color: Cesium.Color.fromCssColorString("#ff6f00"), // $color-primary-900
    outlineWidth: 0,
    pixelSize: 8,
};

const CHILD_ENTITY_HEIGHT_POINTS_LAYER_COMPONENT_POINTER_ID = "child-entity-height-points-layer-component-pointer-id";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-child-entity-height-points-layer[isShown][labelsTemplate]",
    templateUrl: "./child-entity-height-points-layer.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ChildEntityHeightPointsLayerComponent implements AfterContentInit {
    protected readonly POINT_PROPS = POINT_PROPS;
    protected readonly Cesium = Cesium;
    protected readonly Number = Number;

    protected readonly heightPoints$ = new Subject<AcNotification>();
    protected readonly labels$ = new Subject<AcNotification>();

    private readonly heightHelperService: HeightHelperService;

    constructor(
        private readonly localStore: LocalComponentStore<ChildEntityHeightPointsLayerComponentState>,
        private readonly itineraryService: ItineraryService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly cesiumPointerManager: CesiumPointerManagerService,
        private readonly coordinateConverter: CoordinateConverter,
        private readonly cesiumService: CesiumService,
        private readonly cameraService: CameraService
    ) {
        this.localStore.setState({
            isShown: true,
            labelsTemplate: null,
            constraints: undefined,
        });

        this.heightHelperService = new HeightHelperService(this.cesiumService);
    }

    @ViewChild("heightPointsLayer") private readonly heightPointsLayer: AcLayerComponent | undefined;
    @ViewChild("labelsLayer") private readonly labelsLayer: AcLayerComponent | undefined;

    @Input() public set isShown(value: BooleanInput) {
        this.localStore.patchState({ isShown: coerceBooleanProperty(value) });
    }
    @Input() public set labelsTemplate(value: TemplateRef<any> | null) {
        this.localStore.patchState({ labelsTemplate: value });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }

    @Output() public readonly entityUpdate = new EventEmitter<ItineraryContentEntity>();

    protected readonly labelsTemplate$ = this.localStore.selectByKey("labelsTemplate");
    protected readonly isShown$ = this.localStore.selectByKey("isShown");

    private readonly childEntityHeightPoints$ = this.prepareChildEntityHeightPointAcEntities();
    private currentlyHoveredChildEntityHeightPointId: string | undefined;
    private currentlyDraggedChildEntityHeightPointId: string | undefined;
    private readonly mouseMove$ = fromEvent(this.document, "mousemove");

    public ngAfterContentInit(): void {
        this.watchForItinararyContentChanges();
        this.registerHoverEvent();
        this.registerDragEvent();
    }

    private prepareChildEntityHeightPointAcEntities() {
        return combineLatest([
            this.itineraryService.itineraryContent$.pipe(RxjsUtils.filterFalsy()),
            this.localStore.selectByKey("constraints").pipe(RxjsUtils.filterFalsy()),
        ]).pipe(
            map(([itineraryContent, constraints]) => {
                const filteredContent = itineraryContent.filter(
                    (entity) => entity.type === MapEntityType.Polyline3D && Object.keys(entity.childEntities).length > 0
                ) as Polyline3DItineraryEntity[];

                return filteredContent.flatMap((polylineEntity) => this.getChildEntityHeightPointAcEntity(polylineEntity, constraints));
            })
        );
    }

    private getChildEntityHeightPointAcEntity(
        polylineEntity: Polyline3DItineraryEntity,
        constraints: MissionPlanItineraryConstraints
    ): ChildEntityHeightPointAcEntity[] {
        return Object.values(polylineEntity.childEntities).map((childEntity) => {
            const isRunway = childEntity.waypointIndex === 0 || childEntity.waypointIndex === polylineEntity.positions.length - 1;
            const groundPoint = polylineEntity.positions[childEntity.waypointIndex];

            let height = childEntity.entity.bottomHeight;

            if (isRunway) {
                const inletOutletPointIndex = childEntity.waypointIndex === 0 ? 1 : polylineEntity.positions.length - 2;

                const halfVerticalBuffer = Math.round(
                    Math.max(
                        constraints.default.runwayVerticalNavigationAccuracy * 2,
                        polylineEntity.bufferHeights[inletOutletPointIndex]
                    ) / 2
                );

                height = (childEntity.entity.topHeight ?? 0) - halfVerticalBuffer;
            }

            const position = MapUtils.getElevatedCesiumPoint(groundPoint, height);

            return new ChildEntityHeightPointAcEntity({
                id: childEntity.entity.id,
                position,
                height,
                waypointIndex: childEntity.waypointIndex,
                parentId: polylineEntity.id,
                isRunway,
                groundPoint,
                childEntity: childEntity.entity,
                maxHeight: constraints.max.height,
                minHeight: constraints.min.height,
                stopover: polylineEntity.metadata?.stopovers[childEntity.entity.id],
            });
        });
    }

    private watchForItinararyContentChanges() {
        this.childEntityHeightPoints$.pipe(pairwise(), untilDestroyed(this)).subscribe(([previousHeightPoints, heightPoints]) => {
            for (let index = previousHeightPoints.length - 1; index >= heightPoints.length; index--) {
                this.heightPointsLayer?.remove(index.toString());
                this.labelsLayer?.remove(index.toString());
            }

            heightPoints.forEach((point, index) => {
                this.heightPointsLayer?.update(point, index.toString());
            });

            this.refreshLabels(heightPoints);
        });
    }

    private refreshLabels(heightPoints: ChildEntityHeightPointAcEntity[]) {
        heightPoints.forEach((point, index) => {
            const isPointHeight =
                point.isRunway &&
                (this.currentlyHoveredChildEntityHeightPointId === point.id || this.currentlyDraggedChildEntityHeightPointId === point.id);
            this.labelsLayer?.update(
                {
                    id: isPointHeight ? "pointHeight" : "waypoint",
                    isChildEntityCenter: true,
                    position: point.position,
                    show: true,
                    halfVerticalBuffer: isPointHeight ? (point.childEntity.topHeight ?? 0) - point.height : undefined,
                    text: isPointHeight
                        ? this.itineraryService.getPointHeightLabel(point.parentId, point.waypointIndex, point.height)
                        : this.itineraryService.getWaypointLabel(point.parentId, point.waypointIndex),
                    stopover: point.stopover,
                    parentId: point.parentId,
                    waypointIndex: point.waypointIndex,
                },
                index.toString()
            );
        });
    }

    private registerHoverEvent() {
        const entityMouseHoverRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.PICK_ALL,
        });

        // NOTE: combination with event mousemove is needed to prevent laggy change detection
        this.mouseMove$
            .pipe(
                combineLatestWith(entityMouseHoverRegistration),
                map(([, result]) => result.entities),
                untilDestroyed(this)
            )
            .subscribe((entities) => {
                const heightPoint = entities.find((entity) => entity instanceof ChildEntityHeightPointAcEntity) as
                    | ChildEntityHeightPointAcEntity
                    | undefined;

                if (heightPoint && heightPoint.isRunway) {
                    this.cesiumPointerManager.setPointerType(
                        CHILD_ENTITY_HEIGHT_POINTS_LAYER_COMPONENT_POINTER_ID,
                        CesiumPointerType.HeightHandle
                    );
                    this.currentlyHoveredChildEntityHeightPointId = heightPoint.id;
                } else {
                    this.cesiumPointerManager.setPointerType(CHILD_ENTITY_HEIGHT_POINTS_LAYER_COMPONENT_POINTER_ID, CesiumPointerType.None);
                    this.currentlyHoveredChildEntityHeightPointId = undefined;
                }
            });
    }

    private registerDragEvent() {
        const heightPointDragRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.LEFT_CLICK_DRAG,
            entityType: ChildEntityHeightPointAcEntity,
            pick: PickOptions.PICK_ALL,
            pickFilter: (entity: ChildEntityHeightPointAcEntity) => entity.isRunway,
        });

        // NOTE: combination with mouse events is needed to prevent laggy change detection
        this.mouseMove$
            .pipe(
                combineLatestWith(heightPointDragRegistration),
                distinctUntilChanged(([, previousResult], [, currentResult]) => previousResult === currentResult),
                map(([, result]) => result),
                tap(({ movement: { drop } }) => drop !== undefined && this.cameraService.enableInputs(drop)),
                untilDestroyed(this)
            )
            .subscribe((event) => {
                const {
                    movement: { startPosition: cursorStartPosition, endPosition: cursorEndPosition, drop: isDrop },
                    entities,
                } = event;

                const startDragPosition = this.coordinateConverter.screenToCartesian3(cursorStartPosition);
                const endDragPosition = this.coordinateConverter.screenToCartesian3(cursorEndPosition);
                const draggedEntity = entities[0] as ChildEntityHeightPointAcEntity;

                if (!endDragPosition || !startDragPosition || entities.length === 0 || !draggedEntity) {
                    return;
                }

                const newHeight = this.heightHelperService.calculateHeightFromDragEvent(draggedEntity, cursorEndPosition, !!isDrop);
                const halfVerticalBuffer = (draggedEntity.childEntity.topHeight ?? 0) - draggedEntity.height;

                const minHeight = draggedEntity.minHeight + halfVerticalBuffer;
                const maxHeight = draggedEntity.maxHeight - halfVerticalBuffer;

                this.currentlyDraggedChildEntityHeightPointId = isDrop ? undefined : draggedEntity.id;

                this.entityUpdate.emit({
                    ...draggedEntity.childEntity,
                    topHeight: Math.max(Math.min(newHeight, maxHeight), minHeight) + halfVerticalBuffer,
                });
            });
    }
}
