<mat-card class="main-container" *ngrxLet="isSuccess$ as isSuccess" [ngSwitch]="isSuccess">
    <section *ngSwitchCase="true" class="status success">
        <div>
            <h3 class="header">
                {{ "dtmWebAppLegalGuardian.signatureStatus.successHeader" | transloco }}
            </h3>
            <p class="message">
                {{ "dtmWebAppLegalGuardian.signatureStatus.successMessage" | transloco }}
            </p>
        </div>
    </section>
    <section *ngSwitchCase="false" class="status failure">
        <div>
            <h3 class="header">
                {{ "dtmWebAppLegalGuardian.signatureStatus.failHeader" | transloco }}
            </h3>
            <p class="message">
                {{ "dtmWebAppLegalGuardian.signatureStatus.failMessage" | transloco }}
            </p>
            <button type="button" class="button-primary" (click)="goBackToForm.emit()">
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dtmWebAppLegalGuardian.signatureStatus.goBackToFormLabel" | transloco }}
            </button>
        </div>
    </section>
</mat-card>
