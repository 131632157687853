<div
    class="entity-card corridor"
    *ngrxLet="{ bufferWidth: bufferWidth$, title: title$, isProcessing: isProcessing$, displayMode: displayMode$ } as vm"
>
    <header>
        <dtm-ui-icon name="shape-line"></dtm-ui-icon>
        <h2 *ngIf="vm.title">{{ vm.title }}</h2>

        <button
            type="button"
            class="button-icon delete-button"
            (click)="entityDelete.emit()"
            [disabled]="vm.isProcessing"
            [class.hidden]="!vm.displayMode.canDelete.corridor"
        >
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
        </button>
    </header>
    <div class="card-content">
        <span class="width-label">{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.corridorWidth" | transloco }}</span>
        <dtm-ui-expanded-number-input
            [formControl]="corridorWidthFormControl"
            [minValue]="predefinedCorridorWidths[0]"
            [maxValue]="predefinedCorridorWidths[predefinedCorridorWidths.length - 1]"
            [stepValue]="5"
            [predefinedOptions]="predefinedCorridorWidths"
            [valueSuffix]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.widthUnitLabel' | transloco"
        >
            <div class="field-error" *dtmUiFieldHasError="corridorWidthFormControl">
                {{
                    "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.corridorWidthConstraintsError"
                        | transloco
                            : {
                                  min: predefinedCorridorWidths[0] | localizeNumber,
                                  max: predefinedCorridorWidths[predefinedCorridorWidths.length - 1] | localizeNumber
                              }
                }}
            </div>
        </dtm-ui-expanded-number-input>
        <ng-content></ng-content>
    </div>
</div>
