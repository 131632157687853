import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Event, NavigationEnd, Router } from "@angular/router";
import { Address, ButtonTheme, ConfirmationDialogComponent, DialogService, GlobalFeatures, OperatorType } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, filter, skip, switchMap, tap } from "rxjs";
import { distinctUntilChanged, first } from "rxjs/operators";
import { OPERATIONAL_AUTHORIZATIONS, PILOT_COMPETENCIES } from "../../../shared/models/mission.models";
import { OperatorInsurancePolicy } from "../../../shared/models/pilot-and-operator.models";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import {
    ForeignCompetencyDocumentEditOrAdd,
    PansaUtmCredentials,
    PansaUtmLink,
    PansaUtmLinkErrorType,
    PilotConfirmationDocumentType,
    PilotProfileError,
} from "../../services/pilot-profile.models";
import { PilotProfileActions } from "../../state/pilot-profile.actions";
import { PilotProfileState } from "../../state/pilot-profile.state";
import { EditPansaUtmLinkDialogComponent } from "./edit-pansa-utm-link-dialog/edit-pansa-utm-link-dialog.component";

enum PilotOperatorQualificationsType {
    Pilot = "PILOT",
    Operator = "OPERATOR",
}

const PILOT_DOCUMENT_EXTENSION = ".pdf";

interface PilotProfileContainerComponentState {
    qualificationsType: PilotOperatorQualificationsType | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-pilot-profile-container",
    templateUrl: "./pilot-profile-container.component.html",
    styleUrls: ["./pilot-profile-container.component.scss", "../../../shared/styles/qualifications-sidenav.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.foldAnimation()],
})
export class PilotProfileContainerComponent {
    private readonly selectedOperator$ = this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy());
    protected readonly pilotProfile$ = this.store.select(PilotProfileState.pilotProfile).pipe(RxjsUtils.filterFalsy());
    protected readonly isPilotProfileProcessing$ = this.store.select(PilotProfileState.isPilotProfileProcessing);
    protected readonly pilotProfileError$ = this.store.select(PilotProfileState.pilotProfileError);
    protected readonly qualificationsType$ = this.localStore.selectByKey("qualificationsType");
    protected readonly isMissionPlanningFeatureAvailable$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.MissionPlanner)
    );

    protected readonly OPERATIONAL_AUTHORIZATIONS = OPERATIONAL_AUTHORIZATIONS;
    protected readonly PILOT_COMPETENCIES = PILOT_COMPETENCIES;
    protected readonly PilotOperatorQualificationsType = PilotOperatorQualificationsType;

    constructor(
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly router: Router,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService,
        private readonly localStore: LocalComponentStore<PilotProfileContainerComponentState>
    ) {
        localStore.setState({ qualificationsType: undefined });
        this.watchForOperatorContextChanges();

        this.router.events
            .pipe(
                filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
                skip(1), // NOTE skip(1) because first request is in resolver
                switchMap(() => this.store.select(OperatorContextState.pilot)),
                RxjsUtils.filterFalsy(),
                switchMap(({ id }) => this.store.dispatch(new PilotProfileActions.GetPilotProfile(id))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected setPilotOperatorQualificationsType(qualificationsType?: PilotOperatorQualificationsType | undefined) {
        this.localStore.patchState({ qualificationsType });
    }

    protected redirectToGetQualifications() {
        const elearningPortalUrl = this.store.selectSnapshot(OperatorContextState.elearningPortalUrl);
        if (!elearningPortalUrl) {
            this.toastService.error(
                this.transloco.translate("dtmWebAppLibPilotProfile.pilotQualifications.redirectToELearningPlatformError")
            );

            return;
        }

        window.open(elearningPortalUrl);
    }

    protected editOperatorInsurancePolicy(policy: OperatorInsurancePolicy): void {
        this.store
            .dispatch(new PilotProfileActions.EditPilotInsurancePolicy(policy))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PilotProfileState.editPilotInsurancePolicyError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppLibPilotProfile.insurancePolicyEdit.cannotSavePilotInsurancePolicyMessage")
                    );

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmWebAppLibPilotProfile.insurancePolicyEdit.pilotInsurancePolicySuccessSaveMessage")
                );
            });
    }

    protected reloadPilotProfile(): void {
        const id = this.store.selectSnapshot(OperatorContextState.pilot)?.id;
        if (id) {
            this.store.dispatch(new PilotProfileActions.GetPilotProfile(id));
        }
    }

    protected downloadDocument(documentType: PilotConfirmationDocumentType) {
        const documentTypeKey =
            Object.keys(PilotConfirmationDocumentType)[Object.values(PilotConfirmationDocumentType).indexOf(documentType)];
        const fileName = this.transloco.translate("dtmWebAppLibPilotProfile.container.pilotsDocumentFileName", {
            documentType: documentTypeKey,
        });
        const fileNameWithExtension = `${fileName}${PILOT_DOCUMENT_EXTENSION}`;
        const downloadDocumentErrorMessage = this.transloco.translate("dtmWebAppLibPilotProfile.container.downloadDocumentError", {
            documentType: documentTypeKey,
        });

        this.saveDocument(documentType, fileNameWithExtension)
            .pipe(first(), untilDestroyed(this))
            .subscribe((error) => {
                if (!error) {
                    return;
                }
                this.toastService.error(downloadDocumentErrorMessage);
            });
    }

    protected editAddress(address: Address): void {
        this.store
            .dispatch(new PilotProfileActions.EditPilotAddress(address))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PilotProfileState.editPilotAddressError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppLibPilotProfile.addressDetails.cannotSavePilotAddressMessage")
                    );

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmWebAppLibPilotProfile.addressDetails.pilotAddressSuccessSaveMessage")
                );
            });
    }

    protected deleteInsurancePolicy(): void {
        this.store
            .dispatch(new PilotProfileActions.DeleteInsurancePolicy())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PilotProfileState.editPilotInsurancePolicyError);
                if (error) {
                    this.toastService.error(
                        this.transloco.translate(
                            "dtmWebAppLibPilotProfile.insurancePolicyEdit.cannotDeletePilotInsurancePolicyErrorMessage"
                        )
                    );
                } else {
                    this.toastService.success(
                        this.transloco.translate("dtmWebAppLibPilotProfile.insurancePolicyEdit.deletePilotInsurancePolicySuccessMessage")
                    );
                }
            });
    }

    protected saveForeignCompetency(value: ForeignCompetencyDocumentEditOrAdd): void {
        this.store
            .dispatch(new PilotProfileActions.AddOrEditForeignCompetency(value))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(PilotProfileState.addForeignCompetencySaveError);
                if (error) {
                    this.toastService.error(
                        this.transloco.translate(
                            value.competencyId
                                ? "dtmWebAppLibPilotProfile.foreignCompetencies.cannotEditForeignCompetency"
                                : "dtmWebAppLibPilotProfile.foreignCompetencies.cannotAddForeignCompetency"
                        )
                    );

                    return;
                }
                this.toastService.success(
                    this.transloco.translate(
                        value.competencyId
                            ? "dtmWebAppLibPilotProfile.foreignCompetencies.foreignCompetencyEdited"
                            : "dtmWebAppLibPilotProfile.foreignCompetencies.foreignCompetencyAdded"
                    )
                );
                this.refreshPilotProfile();
            });
    }

    protected deleteForeignCompetency(value: string): void {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("dtmWebAppLibPilotProfile.foreignCompetencies.confirmRemovalDialogTitle"),
                confirmationText: "",
                declineButtonLabel: this.transloco.translate(
                    "dtmWebAppLibPilotProfile.foreignCompetencies.confirmRemovalDialogCancelLabel"
                ),
                confirmButtonLabel: this.transloco.translate(
                    "dtmWebAppLibPilotProfile.foreignCompetencies.confirmRemovalDialogConfirmLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });
        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new PilotProfileActions.DeleteForeignCompetency(value))),
                switchMap(() => this.store.select(PilotProfileState.deleteForeignCompetencyError)),
                tap((error) => {
                    if (error) {
                        this.toastService.error(
                            this.transloco.translate("dtmWebAppLibPilotProfile.foreignCompetencies.competencyRemovalErrorMessage")
                        );

                        return;
                    }
                    this.toastService.success(
                        this.transloco.translate("dtmWebAppLibPilotProfile.foreignCompetencies.competencySuccessfullyRemovedMessage")
                    );
                    this.reloadPilotProfile();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected addPansaUtmLink(existingLink?: PansaUtmLink): void {
        const isProcessing$ = this.store.select(PilotProfileState.isPilotProfileProcessing);
        const error$ = this.store.select(PilotProfileState.pansaUtmLinkError);
        const dialogRef: MatDialogRef<EditPansaUtmLinkDialogComponent> = this.dialogService.open(EditPansaUtmLinkDialogComponent, {
            data: { isProcessing$, error$ },
        });
        dialogRef.componentInstance.newValue$
            .pipe(
                switchMap((credentials: PansaUtmCredentials) =>
                    this.store.dispatch(new PilotProfileActions.AddPansaUtmLink(credentials, existingLink))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(PilotProfileState.pansaUtmLinkError);
                if (!error) {
                    dialogRef.close();
                    this.toastService.success(this.transloco.translate("dtmWebAppLibPilotProfile.editPansaUtmLink.successMessage"));
                    this.refreshPilotProfile();
                } else if (error.type === PansaUtmLinkErrorType.Unknown) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibPilotProfile.editPansaUtmLink.unknownErrorMessage"));
                }
            });
    }

    protected deletePansaUtmLink(): void {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("dtmWebAppLibPilotProfile.pansaUtmLink.delete.titleText"),
                confirmationText: this.transloco.translate("dtmWebAppLibPilotProfile.pansaUtmLink.delete.confirmationText"),
                confirmButtonLabel: this.transloco.translate("dtmWebAppLibPilotProfile.pansaUtmLink.delete.confirmButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new PilotProfileActions.DeletePansaUtmLink())),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(PilotProfileState.pansaUtmLinkError);
                if (error) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibPilotProfile.pansaUtmLink.delete.errorMessage"));

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibPilotProfile.pansaUtmLink.delete.successMessage"));
                this.refreshPilotProfile();
            });
    }

    private watchForOperatorContextChanges(): void {
        this.selectedOperator$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((selectedContext) => {
            if (selectedContext?.type === OperatorType.Enterprise) {
                this.router.navigateByUrl("/operator-profile");
            }
        });
    }

    private refreshPilotProfile(): void {
        const pilotId = this.store.selectSnapshot(PilotProfileState.pilotProfile)?.id;
        if (pilotId) {
            this.store.dispatch(new PilotProfileActions.GetPilotProfile(pilotId));
        }
    }

    private saveDocument(
        documentType: PilotConfirmationDocumentType,
        fileNameWithExtension: string
    ): Observable<PilotProfileError | undefined> {
        switch (documentType) {
            case PilotConfirmationDocumentType.RegistrationConfirmation:
                return this.downloadRegistrationConfirmationDocument(fileNameWithExtension);
            case PilotConfirmationDocumentType.ConfirmationCompletingA1A3:
                return this.downloadConfirmationCompletingA1A3Document(fileNameWithExtension);
            case PilotConfirmationDocumentType.CertificateA2:
                return this.downloadCertificateA2Document(fileNameWithExtension);
            case PilotConfirmationDocumentType.CertificateSts:
                return this.downloadCertificateStsDocument(fileNameWithExtension);
        }
    }

    private downloadRegistrationConfirmationDocument(fileName: string): Observable<PilotProfileError | undefined> {
        return this.store
            .dispatch(new PilotProfileActions.DownloadRegistrationConfirmationDocument(fileName))
            .pipe(switchMap(() => this.store.select(PilotProfileState.downloadRegistrationConfirmationError)));
    }

    private downloadConfirmationCompletingA1A3Document(fileName: string): Observable<PilotProfileError | undefined> {
        return this.store
            .dispatch(new PilotProfileActions.DownloadConfirmationCompletingA1A3Document(fileName))
            .pipe(switchMap(() => this.store.select(PilotProfileState.downloadConfirmationCompletingA1A3Error)));
    }

    private downloadCertificateA2Document(fileName: string): Observable<PilotProfileError | undefined> {
        return this.store
            .dispatch(new PilotProfileActions.DownloadCertificateA2Document(fileName))
            .pipe(switchMap(() => this.store.select(PilotProfileState.downloadCertificateA2DocumentError)));
    }

    private downloadCertificateStsDocument(fileName: string): Observable<PilotProfileError | undefined> {
        return this.store
            .dispatch(new PilotProfileActions.DownloadCertificateStsDocument(fileName))
            .pipe(switchMap(() => this.store.select(PilotProfileState.downloadCertificateStsDocumentError)));
    }
}
