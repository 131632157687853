import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { RouterModule } from "@angular/router";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ConnectPansaUtmLinkDialogComponent } from "../shared/components/connect-pansa-utm-link-dialog/connect-pansa-utm-link-dialog.component";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { EditPansaUtmLinkDialogComponent } from "./components/pilot-profile-container/edit-pansa-utm-link-dialog/edit-pansa-utm-link-dialog.component";
import { ForeignCompetenciesComponent } from "./components/pilot-profile-container/foreign-competencies/foreign-competencies.component";
import { OperatorPilotNumberComponent } from "./components/pilot-profile-container/operator-pilot-number/operator-pilot-number.component";
import { PansaUtmLinkComponent } from "./components/pilot-profile-container/pansa-utm-link/pansa-utm-link.component";
import { PilotProfileContainerComponent } from "./components/pilot-profile-container/pilot-profile.container.component";
import { PilotQualificationsComponent } from "./components/pilot-profile-container/pilot-qualifications/pilot-qualifications.component";
import { CanEnterPilotProfileGuard } from "./guards/can-enter-on-pilot-profile.guard";
import { PilotProfileRoutingModule } from "./pilot-profile.routing.module";
import { PilotProfileApiService } from "./services/pilot-profile.api.service";
import { PilotProfileState } from "./state/pilot-profile.state";

@NgModule({
    declarations: [
        PilotProfileContainerComponent,
        OperatorPilotNumberComponent,
        PilotQualificationsComponent,
        PansaUtmLinkComponent,
        EditPansaUtmLinkDialogComponent,
        ConnectPansaUtmLinkDialogComponent,
        ForeignCompetenciesComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxsModule.forFeature([PilotProfileState]),
        RouterModule,
        SharedUiModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedAuthModule,
        SharedWebAppModule,
        PilotProfileRoutingModule,
        MatCardModule,
        ClipboardModule,
        MatLegacyMenuModule,
        MatDialogModule,
        MatInputModule,
        MatDatepickerModule,
        MatInputModule,
        MatLegacyTooltipModule,
    ],
    providers: [
        CanEnterPilotProfileGuard,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibPilotProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: PilotProfileApiService,
            useValue: undefined,
        },
    ],
    exports: [
        OperatorPilotNumberComponent,
        PilotQualificationsComponent,
        PansaUtmLinkComponent,
        EditPansaUtmLinkDialogComponent,
        ConnectPansaUtmLinkDialogComponent,
        ForeignCompetenciesComponent,
    ],
})
export class PilotProfileModule {
    public static forRoot(): ModuleWithProviders<PilotProfileModule> {
        return {
            ngModule: PilotProfileModule,
            providers: [
                {
                    provide: PilotProfileApiService,
                    useClass: PilotProfileApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<PilotProfileModule> {
        return {
            ngModule: PilotProfileModule,
            providers: [
                {
                    provide: [PilotProfileApiService],
                    useValue: null,
                },
            ],
        };
    }
}
