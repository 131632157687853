import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Post } from "../../services/dashboard.models";

interface PostItemComponentState {
    post: Post | undefined;
}

@Component({
    selector: "dtm-web-app-lib-post-item",
    templateUrl: "./post-item.component.html",
    styleUrls: ["./post-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PostItemComponent {
    @Input() public set post(value: Post | undefined) {
        this.localStore.patchState({ post: value });
    }

    protected readonly post$ = this.localStore.selectByKey("post").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<PostItemComponentState>) {
        this.localStore.setState({ post: undefined });
    }
}
