import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { PermissionUavDetails } from "../../services/operator-permissions.models";

@Component({
    selector: "dtm-web-app-lib-permission-uav-details",
    templateUrl: "./permission-uav-details.component.html",
    styleUrls: ["./permission-uav-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionUavDetailsComponent {
    constructor(@Inject(MAT_DIALOG_DATA) protected readonly permissionUavDetails: PermissionUavDetails) {}
}
