<mat-card *ngrxLet="{ setups: setups$, canManageUav: canManageUav$ } as vm">
    <section class="header">
        <h4>{{ "dtmWebAppLibUav.uavSetups.header" | transloco : { setupsAmount: vm.setups.length } }}</h4>
        <button *ngIf="vm.canManageUav" class="button-primary" type="button" (click)="add.emit()">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibUav.uavSetups.addButtonLabel" | transloco }}
        </button>
    </section>

    <ng-container *ngrxLet="selectedSetup$ as selectedSetup">
        <section class="setups-list" *ngIf="vm.setups.length; else noSetupsTemplate">
            <dtm-ui-select-field [value]="selectedSetup" (selectionChange)="showSetupPreview($event.value)" [isClearable]="false">
                <label>{{ "dtmWebAppLibUav.uavSetups.setupFieldLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let setup of vm.setups" [value]="setup">
                    {{ setup.name }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <button class="button-secondary" type="button" *ngIf="vm.canManageUav" (click)="edit.emit(selectedSetup)">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibUav.uavSetups.editButtonLabel" | transloco }}
            </button>
            <button
                *ngIf="!selectedSetup?.isPrimary && vm.canManageUav"
                class="button-secondary"
                type="button"
                (click)="tryToRemoveSetup(selectedSetup)"
            >
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                {{ "dtmWebAppLibUav.uavSetups.removeButtonLabel" | transloco }}
            </button>
        </section>

        <dtm-web-app-lib-uav-setup-preview
            [setup]="selectedSetup"
            [trackers]="trackers$ | ngrxPush"
            [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
            [isNarrowMode]="isNarrowMode$ | ngrxPush"
        ></dtm-web-app-lib-uav-setup-preview>

        <ng-template #noSetupsTemplate>
            <dtm-ui-no-results></dtm-ui-no-results>
        </ng-template>
    </ng-container>
</mat-card>
