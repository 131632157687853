import { UavType } from "@dtm-frontend/shared/ui";
import { UavClass } from "../../../shared";
import { Manufacturer, ManufacturerModel } from "../../services/uav.models";

export const NEW_UAV_WIZARD_ID = "new-uav-wizard";

export enum NewUavWizardSteps {
    BasicInfo = "BasicInfo",
    PrimarySetup = "PrimarySetup",
}

interface BasicInfo {
    isCustom: boolean;
    isSwarm: boolean;
    serialNumbers: string[];
    uavClasses: UavClass[];
    name: string;
}

export interface NewStandardUavBasicInfo extends BasicInfo {
    isCustom: false;
    manufacturer: Manufacturer;
    uavModel: ManufacturerModel;
}

export interface NewCustomUavBasicInfo extends BasicInfo {
    isCustom: true;
    manufacturerName: string;
    modelName: string;
    type: UavType;
    imageId: string | null;
    isCeCompliant: boolean;
}

export type NewUavBasicInfo = NewStandardUavBasicInfo | NewCustomUavBasicInfo;
