import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { AZURE_MAPS_SUBSCRIPTION_KEY, SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    AzureMapsImageryProvider,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
} from "@dtm-frontend/shared/map/cesium";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { SceneMode } from "@pansa/ngx-cesium";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface KmlPreviewDialogComponentState {
    isMapLoading: boolean;
}

@Component({
    selector: "dtm-web-app-lib-kml-preview-dialog",
    templateUrl: "./kml-preview-dialog.component.html",
    styleUrls: ["./kml-preview-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class KmlPreviewDialogComponent implements AfterViewInit {
    protected readonly isMapLoading$ = this.localStore.selectByKey("isMapLoading");

    constructor(
        @Inject(AZURE_MAPS_SUBSCRIPTION_KEY) private readonly azureMapsSubscriptionKey: string,
        @Inject(MAT_DIALOG_DATA) protected readonly kmlFile: Blob,
        @Inject(SHARED_MAP_ENDPOINTS) public readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly localStore: LocalComponentStore<KmlPreviewDialogComponentState>
    ) {
        localStore.setState({ isMapLoading: true });
    }

    public ngAfterViewInit() {
        this.prepareKmlFilePreview();
    }

    private prepareKmlFilePreview() {
        const imageryLayer = new Cesium.ImageryLayer(
            new AzureMapsImageryProvider({ ...AZURE_MAPS_LAYER_OPTIONS, subscriptionKey: this.azureMapsSubscriptionKey })
        );
        const additionalLayer = new Cesium.ImageryLayer(
            new Cesium.WebMapServiceImageryProvider({
                url: this.sharedMapEndpoints.geoServerEndpoint,
                layers: "mission-planner",
                enablePickFeatures: false,
                parameters: {
                    transparent: true,
                    format: "image/png",
                },
            })
        );

        const viewer = new Cesium.Viewer("kmlPreviewContainer", {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE2D,
        });
        viewer.imageryLayers.add(imageryLayer);
        viewer.imageryLayers.add(additionalLayer);
        viewer.dataSources.add(Cesium.KmlDataSource.load(this.kmlFile)).then((data: unknown | string | Document | Blob) => {
            viewer.zoomTo(data);
            this.localStore.patchState({ isMapLoading: false });
        });
    }
}
