import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, Validator, Validators } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { MissionOperator } from "../../../../../models/mission.model";

interface OperatorFormControlComponentState {
    operators: MissionOperator[];
}
@Component({
    selector: "dtm-web-app-lib-operator-form-control[operators]",
    templateUrl: "./operator-form-control.component.html",
    styleUrls: ["../mission-data-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => OperatorFormControlComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperatorFormControlComponent),
            multi: true,
        },
    ],
})
export class OperatorFormControlComponent implements ControlValueAccessor, Validator {
    @Input()
    public set operators(value: MissionOperator[] | undefined) {
        this.localStore.patchState({ operators: value });
    }

    public readonly allOperators$ = this.localStore.selectByKey("operators");
    public readonly validOperators$ = this.allOperators$.pipe(
        map((operators) => operators.filter((operator) => operator.pilots.length > 0 && operator.uavs.length > 0))
    );
    public readonly operatorsWithoutPilots$ = this.allOperators$.pipe(
        map((operators) => operators.filter((operator) => operator.pilots.length === 0))
    );
    public readonly operatorsWithoutUavs$ = this.allOperators$.pipe(
        map((operators) => operators.filter((operator) => operator.pilots.length > 0 && operator.uavs.length === 0))
    );

    public readonly operatorFormControl = new UntypedFormControl(null, [Validators.required]);

    constructor(private readonly localStore: LocalComponentStore<OperatorFormControlComponentState>) {
        this.localStore.setState({ operators: [] });
    }

    public onTouched: () => void = FunctionUtils.noop;
    public onChange: (value: MissionOperator) => void = FunctionUtils.noop;

    public registerOnChange(onChange: (value: MissionOperator) => void) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    public writeValue(operator: MissionOperator) {
        this.operatorFormControl.setValue(operator);
    }

    public validate() {
        return this.operatorFormControl.errors;
    }
}
