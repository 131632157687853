import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DateUtils, ISO8601TimeDuration, SECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";

@Component({
    selector: "dtm-web-app-lib-itinerary-panel-stopover-dialog",
    templateUrl: "./stopover-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryPanelStopoverDialogComponent {
    protected readonly dialogForm = new FormGroup({
        stopoverMinutes: new FormControl<number>(0, { nonNullable: true }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public readonly data: { stopover: ISO8601TimeDuration } | undefined,
        private readonly dialogRef: MatDialogRef<ItineraryPanelStopoverDialogComponent>
    ) {
        if (data?.stopover) {
            this.dialogForm.controls.stopoverMinutes.setValue(
                Math.round((DateUtils.convertISO8601DurationToSeconds(data.stopover) ?? 0) / SECONDS_IN_MINUTE)
            );
        }
    }

    public confirm() {
        this.dialogRef.close({
            stopover: DateUtils.convertSecondsToISO8601Duration(this.dialogForm.controls.stopoverMinutes.value * SECONDS_IN_MINUTE),
        });
    }
}
