import { MissionPlanRoute } from "@dtm-frontend/shared/ui";

const MAX_AGL_HEIGHT = 120;

export function checkIfMissionPlanRoutePossiblyViolateMaximumHeight(route: MissionPlanRoute | undefined): boolean {
    return (
        route !== undefined &&
        route.sections.some(
            (section) =>
                section.flightZone?.flightArea.elevationMin !== undefined &&
                section.flightZone.flightArea.volume.ceiling - section.flightZone.flightArea.elevationMin > MAX_AGL_HEIGHT
        )
    );
}
