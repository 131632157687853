import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { FILES_UPLOAD_API_PROVIDER, FilesGroup } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";
import { ChapterAttachmentUploadApiService } from "../../services/chapter-attachment-upload-api.service";
import { ChapterAttachmentType } from "../../services/operations-manual.models";

interface ManageAttachmentsDialogComponentState {
    isSaveButtonDisabled: boolean;
}

interface ManageAttachmentsDialogData {
    attachments: FilesGroup<ChapterAttachmentType>[];
    additionalPathParams: { chapterId: string };
    isProcessing$: Observable<boolean>;
}

interface AttachmentsForm {
    attachments: FormControl<FilesGroup<ChapterAttachmentType>[]>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-manage-attachments-dialog",
    templateUrl: "./manage-attachments-dialog.component.html",
    styleUrls: ["./manage-attachments-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: ChapterAttachmentUploadApiService,
        },
    ],
})
export class ManageAttachmentsDialogComponent {
    private readonly setAttachmentsSubject = new Subject<FilesGroup<ChapterAttachmentType>[]>();
    public readonly setAttachments$ = this.setAttachmentsSubject.asObservable();
    protected readonly isSaveButtonDisabled$ = this.localStore.selectByKey("isSaveButtonDisabled");

    protected readonly attachmentsForm = new FormGroup<AttachmentsForm>({
        attachments: new FormControl<FilesGroup<ChapterAttachmentType>[]>([], { nonNullable: true }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: ManageAttachmentsDialogData,
        private readonly localStore: LocalComponentStore<ManageAttachmentsDialogComponentState>
    ) {
        this.localStore.setState({ isSaveButtonDisabled: true });

        this.attachmentsForm.setValue({ attachments: data.attachments ?? [] }, { emitEvent: false });
        this.attachmentsForm.controls.attachments.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({ isSaveButtonDisabled: false });
        });
    }

    protected save(): void {
        if (this.attachmentsForm.invalid) {
            this.attachmentsForm.markAllAsTouched();

            return;
        }

        this.setAttachmentsSubject.next(this.attachmentsForm.getRawValue().attachments);
    }
}
