<ng-container *ngrxLet="isShown$ as isShown">
    <ac-layer acFor="let parabola of parabola$" [context]="this" #parabolaViewer [show]="isShown">
        <ac-polyline-desc
            props="{
              positions: parabola.positions,
              width: parabola.width,
              material: parabola.material

          }"
        >
        </ac-polyline-desc>
    </ac-layer>
    <ac-layer acFor="let label of labels$" [context]="this" #labelViewer [show]="isShown">
        <ac-html-desc props="{ position: label.position }">
            <ng-template let-label>
                <div class="label-pin">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.distanceLabel" | transloco : { value: label.distance } }}
                </div>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
</ng-container>
