import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { switchMap } from "rxjs";
import { AuthenticationDetails, OperatorMigrationError, OperatorMigrationErrorType } from "../../models/operator-migration.models";

interface OperatorMigrationAuthenticationStepComponentState {
    isProcessing: boolean;
    authenticationError: OperatorMigrationError | undefined;
}

interface AuthenticationFormGroup {
    email: FormControl<string>;
    password: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-migration-authentication-step",
    templateUrl: "./operator-migration-authentication-step.component.html",
    styleUrls: ["./operator-migration-authentication-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorMigrationAuthenticationStepComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set authenticationError(value: OperatorMigrationError | undefined) {
        this.localStore.patchState({ authenticationError: value });

        if (!value) {
            return;
        }
        this.resetAuthenticationErrors();

        const emailErrors = [
            OperatorMigrationErrorType.WrongCredentials,
            OperatorMigrationErrorType.OperatorAlreadyImported,
            OperatorMigrationErrorType.UnapprovedOperator,
            OperatorMigrationErrorType.NotFound,
        ];

        if (emailErrors.includes(value.type)) {
            this.authenticationForm.controls.email.setErrors({ [value.type]: true });
        }
        if (value.type === OperatorMigrationErrorType.WrongCredentials) {
            this.authenticationForm.controls.password.setErrors({ [value.type]: true });
        }
    }

    @Input() public set initialFormValue(value: AuthenticationDetails | undefined) {
        if (!value) {
            return;
        }

        this.authenticationForm.setValue(value);
    }

    @Output() public readonly authenticationDetailsSave = new EventEmitter<AuthenticationDetails>();
    @Output() public readonly resetPasswordStepRedirect = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly OperatorMigrationErrorType = OperatorMigrationErrorType;
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly authenticationError$ = this.localStore.selectByKey("authenticationError");
    protected readonly authenticationForm = new FormGroup<AuthenticationFormGroup>({
        email: new FormControl<string>("", { validators: [Validators.required, Validators.email], nonNullable: true }),
        password: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
    });

    constructor(private readonly localStore: LocalComponentStore<OperatorMigrationAuthenticationStepComponentState>) {
        localStore.setState({ isProcessing: false, authenticationError: undefined });

        this.authenticationError$
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.authenticationForm.valueChanges),
                untilDestroyed(this)
            )
            .subscribe(() => this.resetAuthenticationErrors());
    }

    protected validateFormAndSave() {
        this.authenticationForm.markAllAsTouched();
        if (this.authenticationForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.authenticationDetailsSave.emit(this.authenticationForm.getRawValue());
    }

    private resetAuthenticationErrors() {
        const mailErrors = this.authenticationForm.controls.email.errors;
        delete mailErrors?.[OperatorMigrationErrorType.OperatorAlreadyImported];
        delete mailErrors?.[OperatorMigrationErrorType.WrongCredentials];
        delete mailErrors?.[OperatorMigrationErrorType.UnapprovedOperator];
        delete mailErrors?.[OperatorMigrationErrorType.NotFound];
        if (mailErrors && Object.keys(mailErrors).length === 0) {
            this.authenticationForm.controls.email.setErrors(null);
        }

        const passwordErrors = this.authenticationForm.controls.password.errors;
        delete passwordErrors?.[OperatorMigrationErrorType.WrongCredentials];

        if (passwordErrors && Object.keys(passwordErrors).length === 0) {
            this.authenticationForm.controls.password.setErrors(null);
        }
    }
}
