import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { dtmOperatorPermissionGuard } from "../../guards/dtm-operator-permission.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { NewUavWizardContentComponent } from "./components/new-uav-wizard/content/new-uav-wizard-content.component";
import { NewUavWizardHeaderComponent } from "./components/new-uav-wizard/header/new-uav-wizard-header.component";
import { SetupEditorComponent } from "./components/setup/setup-editor/setup-editor.component";
import { UavCardComponent } from "./components/uav-card/uav-card.component";
import { UavListComponent } from "./components/uav-list/uav-list.component";
import {
    SETUP_ID_ROUTE_PARAM_NAME,
    UAV_ID_ROUTE_PARAM_NAME,
    UavAddCapabilitiesResolver,
    UavGeneralResolver,
    UavResolver,
} from "./services/uav.resolvers";

const routes: Routes = [
    {
        path: "uav/list",
        component: UavListComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorUavsRead])],
        resolve: {
            init: UavGeneralResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibUav.routeTitles.uavList",
        },
    },
    {
        path: "uav/new",
        component: NewUavWizardContentComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorUavsRead])],
        resolve: {
            init: UavAddCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibUav.routeTitles.newUavWizard",
            headerComponent: NewUavWizardHeaderComponent,
        },
    },
    {
        path: `uav/:${UAV_ID_ROUTE_PARAM_NAME}`,
        component: UavCardComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorUavsRead])],
        resolve: {
            uavId: UavResolver,
            init: UavGeneralResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibUav.routeTitles.uavCard",
        },
    },
    {
        path: `uav/:${UAV_ID_ROUTE_PARAM_NAME}/add-setup`,
        component: SetupEditorComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorUavsRead])],
        resolve: {
            uavId: UavResolver,
            init: UavGeneralResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibUav.routeTitles.newUavSetup",
            isEditMode: false,
        },
    },
    {
        path: `uav/:${UAV_ID_ROUTE_PARAM_NAME}/edit-setup/:${SETUP_ID_ROUTE_PARAM_NAME}`,
        component: SetupEditorComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorUavsRead])],
        resolve: {
            uavId: UavResolver,
            init: UavGeneralResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibUav.routeTitles.editUavSetup",
            isEditMode: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [UavResolver, UavGeneralResolver, UavAddCapabilitiesResolver],
})
export class UavRoutingModule {}
