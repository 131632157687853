<mat-card *ngrxLet="userProfile$ as userProfile">
    <dtm-ui-card-header>
        {{ "dtmWebAppUserProfile.userContact.contactDataHeader" | transloco }}
    </dtm-ui-card-header>
    <div class="user-data-edit-container">
        <dtm-ui-edit-email-address
            [email]="userProfile?.email"
            [isRequestedEmailChange]="isRequestedEmailChange$ | ngrxPush"
            [hasEmailAddressConflictError]="hasRequestEmailChangeError$ | ngrxPush"
            (requestDataChange)="userProfileDataUpdate($event)"
            (closePanel)="clearEmailAddressChange()"
        ></dtm-ui-edit-email-address>
        <dtm-ui-edit-phone-number
            [phoneNumber]="userProfile?.phoneNumber"
            [isRequestedPhoneChange]="isRequestedPhoneChange$ | ngrxPush"
            [hasPhoneNumberConflictError]="hasRequestPhoneChangeError$ | ngrxPush"
            (requestDataChange)="userProfileDataUpdate($event)"
            (closePanel)="clearPhoneNumberChange()"
        ></dtm-ui-edit-phone-number>
    </div>
</mat-card>
