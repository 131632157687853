import { HttpClient, HttpContext, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ImageConverterService, PhoneNumber } from "@dtm-frontend/shared/ui";
import { LOCALE_MAPPING, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { DateUtils, SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import {
    LegalGuardianConfirmationData,
    NationalNodeUserRegistration,
    SavedDocumentData,
    User,
    UserProfileErrorType,
} from "../models/user-profile.models";
import { USER_PROFILE_ENDPOINTS, UserProfileEndpoints } from "../user-profile.tokens";
import {
    GetUserResponseBody,
    NationalNodeUserRegistrationResponseBody,
    convertEmailChangeErrorResponse,
    convertGetNationalNodeUserRegistrationProfileErrorResponse,
    convertGetNationalNodeUserRegistrationResponseBodyToNationalNodeUserRegistration,
    convertGetProfileErrorResponse,
    convertGetUserResponseBodyToUser,
    convertPhoneNumberErrorResponse,
} from "./user-profile-api.converters";

interface UserDataChangeResponseBody {
    communicationChanelChangeRequestId: string;
}

@Injectable({
    providedIn: "root",
})
export class UserProfileApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly imageConverter: ImageConverterService,
        @Inject(USER_PROFILE_ENDPOINTS) private readonly endpoints: UserProfileEndpoints
    ) {}

    public getUserProfile(userId: string): Observable<User> {
        return this.httpClient.get<GetUserResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getUserProfile, { id: userId })).pipe(
            map((response) => convertGetUserResponseBodyToUser(response)),
            catchError((errorResponse: HttpErrorResponse) => throwError(() => convertGetProfileErrorResponse(errorResponse)))
        );
    }

    public getNationalNodeUserRegistration(id: string): Observable<NationalNodeUserRegistration> {
        return this.httpClient
            .get<NationalNodeUserRegistrationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getNationalNodeUserRegistration, { id })
            )
            .pipe(
                map((response) => convertGetNationalNodeUserRegistrationResponseBodyToNationalNodeUserRegistration(response)),
                catchError((errorResponse: HttpErrorResponse) =>
                    throwError(() => convertGetNationalNodeUserRegistrationProfileErrorResponse(errorResponse))
                )
            );
    }

    public requestNationalNodeUserRegistrationEmailChange(id: string, email: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.requestNationalNodeUserRegistrationEmailChange, { id }), {
                email,
            })
            .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => convertEmailChangeErrorResponse(errorResponse))));
    }

    public requestNationalNodeUserRegistrationPhoneNumberChange(id: string, phoneNumber: PhoneNumber): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.requestNationalNodeUserRegistrationPhoneNumberChange, { id }), {
                phoneNumber,
            })
            .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => convertPhoneNumberErrorResponse(errorResponse))));
    }

    public confirmNationalNodeUserRegistrationEmail(id: string, code: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.confirmNationalNodeUserRegistrationEmail, { id }), {
                code,
            })
            .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => convertEmailChangeErrorResponse(errorResponse))));
    }

    public confirmNationalNodeUserRegistrationPhoneNumber(id: string, code: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.confirmNationalNodeUserRegistrationPhoneNumber, { id }), {
                code,
            })
            .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(() => convertPhoneNumberErrorResponse(errorResponse))));
    }

    public resendNationalNodeRegistrationEmailVerificationCode(id: string): Observable<NationalNodeUserRegistration> {
        return this.httpClient
            .put<NationalNodeUserRegistration>(
                StringUtils.replaceInTemplate(this.endpoints.resendNationalNodeUserRegistrationEmailVerificationCode, { id }),
                {}
            )
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotResendVerificationCode }))));
    }

    public resendNationalNodeRegistrationPhoneNumberVerificationCode(id: string): Observable<NationalNodeUserRegistration> {
        return this.httpClient
            .put<NationalNodeUserRegistration>(
                StringUtils.replaceInTemplate(this.endpoints.resendNationalNodeUserRegistrationPhoneNumberVerificationCode, { id }),
                {}
            )
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    throwError(() => {
                        switch (error.status) {
                            case HttpStatusCode.TooManyRequests:
                                // eslint-disable-next-line no-case-declarations
                                const retryAfter = error.headers.get("retry-after");

                                return { type: UserProfileErrorType.TooManyRequests, date: retryAfter ? new Date(retryAfter) : undefined };
                            default:
                                return { type: UserProfileErrorType.CannotResendVerificationCode };
                        }
                    })
                )
            );
    }

    public registerNationalNodeUser(id: string, isTermsOfUseAcceptance: boolean): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.registerNationalNodeUser, { id }), {
                userAcceptsTermsOfService: isTermsOfUseAcceptance,
            })
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotRegisterNationalNodeUser }))));
    }

    public requestEmailChange(newEmailAddress: string, userId: string): Observable<string> {
        const payload = { email: newEmailAddress };

        return this.httpClient
            .post<UserDataChangeResponseBody>(StringUtils.replaceInTemplate(this.endpoints.requestEmailChange, { id: userId }), payload)
            .pipe(
                map((response) => response.communicationChanelChangeRequestId),
                catchError((error) => throwError(() => convertEmailChangeErrorResponse(error)))
            );
    }

    public requestPhoneNumberChange(phoneNumber: PhoneNumber, userId: string): Observable<string> {
        const payload = {
            phoneNumber: { ...phoneNumber },
        };

        return this.httpClient
            .post<UserDataChangeResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.requestPhoneNumberChange, { id: userId }),
                payload
            )
            .pipe(
                map((response) => response.communicationChanelChangeRequestId),
                catchError((error) =>
                    throwError(() =>
                        error.status === HttpStatusCode.Conflict
                            ? { type: UserProfileErrorType.PhoneConflict }
                            : { type: UserProfileErrorType.CannotSendVerificationCodeForPhoneChange }
                    )
                )
            );
    }

    public saveNewEmailAddress(userId: string, communicationChanelChangeRequestId: string, code: string): Observable<void> {
        const endpointValues = { id: userId, changeRequestId: communicationChanelChangeRequestId };

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.saveEmailAddress, endpointValues), { code })
            .pipe(catchError((error) => throwError(() => convertEmailChangeErrorResponse(error))));
    }

    public updateProfileLanguage(language: LanguageCode): Observable<void> {
        const payload = {
            languageTag: LOCALE_MAPPING[language],
        };

        return this.httpClient
            .put<void>(this.endpoints.updateProfileLanguage, payload)
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotSaveUserProfileLanguage }))));
    }

    public saveNewPhoneNumber(userId: string, communicationChanelChangeRequestId: string, code: string): Observable<void> {
        const endpointValues = { id: userId, changeRequestId: communicationChanelChangeRequestId };

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.savePhoneNumber, endpointValues), { code })
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.IncorrectVerificationCode }))));
    }

    public resetUserPassword(userId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resetUserPassword, { id: userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotResetUserPassword }))));
    }

    public getProfileAvatar(userId: string): Observable<string> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.manageProfileAvatar, { id: userId }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(switchMap((avatarFile: Blob) => this.imageConverter.convertBlobToBase64(avatarFile)));
    }

    public saveIdentityDocument(userId: string, savedDocumentData: SavedDocumentData): Observable<void> {
        const documentId = savedDocumentData.document[0].id;
        const endpointValues = { id: userId, documentId };

        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.saveIdentityDocument, endpointValues), {
                type: savedDocumentData.type,
                expirationDate: DateUtils.getISOStringDate(savedDocumentData.expirationDate),
            })
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotSaveIdentityDocument }))));
    }

    public requestAvatarChange(userId: string, base64Image: string): Observable<void> {
        return this.httpClient
            .get(base64Image, { responseType: "blob" })
            .pipe(switchMap((response: Blob) => this.saveUserAvatar(userId, response)));
    }

    public resendLegalGuardianEmailAddress(userId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.resendLegalGuardianEmailAddress, { id: userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotResendLegalGuardianEmailAddress }))));
    }

    public changeLegalGuardianData(
        userId: string,
        confirmationData: LegalGuardianConfirmationData,
        isDataEdited: boolean
    ): Observable<void> {
        if (isDataEdited) {
            return this.httpClient
                .put<void>(StringUtils.replaceInTemplate(this.endpoints.changeLegalGuardianData, { id: userId }), confirmationData)
                .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotChangeLegalGuardianConfirmationData }))));
        }

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.changeLegalGuardianData, { id: userId }), confirmationData)
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotChangeLegalGuardianConfirmationData }))));
    }

    public downloadIdentityDocument(userId: string, identityDocumentId: string, documentName: string): Observable<Blob> {
        const endpointValues = { userId, identityDocumentId };

        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadIdentityDocument, endpointValues), { responseType: "blob" })
            .pipe(
                tap((blob: Blob) => saveAs(blob, documentName)),
                catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotGetIdentityDocument })))
            );
    }

    public deleteIdentityDocument(userId: string, identityDocumentId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.deleteIdentityDocument, { userId, identityDocumentId }))
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotDeleteUserIdentityDocument }))));
    }

    private saveUserAvatar(userId: string, blob: Blob): Observable<void> {
        const formData: FormData = new FormData();
        formData.append("file", blob);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.manageProfileAvatar, { id: userId }), formData)
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotSaveProfileAvatar }))));
    }

    public deleteUserAvatar(id: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageProfileAvatar, { id }))
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotDeleteProfileAvatar }))));
    }
}
