<dtm-ui-expandable-panel [isExpanded]="false" [hasHeaderSeparator]="false" *ngrxLet="airspaceRisk$ as airspaceRisk">
    <h2 class="title" headerSlot>
        {{ "dtmWebAppLibMission.summaryStep.analysis.operationalAreaAirRiskMitigation.operationalAreaAirspaceRiskTitle" | transloco }}
    </h2>

    <table class="dtm-table" multiTemplateDataRows desktopTable mat-table [dataSource]="airspaceRisk">
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibMission.summaryStep.analysis.operationalAreaAirRiskMitigation.statusLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element" class="status">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.airRiskMitigationStatements"
                        | transloco : { value: element.category }
                }}
                <ng-container *ngIf="element.designator">{{ ": " + element.designator }} </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="initialArc">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibMission.summaryStep.analysis.operationalAreaAirRiskMitigation.initialArcLabel" | transloco }}
                <dtm-ui-popover
                    (click)="$event.preventDefault()"
                    [popoverText]="
                        'dtmWebAppLibMission.summaryStep.analysis.operationalAreaAirRiskMitigation.initialArcTooltip' | transloco
                    "
                ></dtm-ui-popover>
            </th>
            <td mat-cell *matCellDef="let element" class="initial-arc">
                <div class="initial-arc-container">
                    {{ element.initialArc | airRiskMitigationsArcAsNumber | arcLabel : true }}
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</dtm-ui-expandable-panel>
