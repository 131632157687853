import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import {
    DEFAULT_COMPANY_NUMBER_LENGTH,
    DEFAULT_COMPANY_NUMBER_MASK,
    DEFAULT_PHONE_COUNTRY_CODE,
    FunctionUtils,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";
import { BasicCompanyInfo } from "../../../models/operator-migration.models";

const COMPANY_NAME_MAX_LENGTH = 100;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-migration-basic-company-info",
    templateUrl: "./basic-company-info.component.html",
    styleUrls: ["./basic-company-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BasicCompanyInfoComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BasicCompanyInfoComponent),
            multi: true,
        },
    ],
})
export class BasicCompanyInfoComponent implements ControlValueAccessor, Validator {
    protected readonly COMPANY_NUMBER_MASK = DEFAULT_COMPANY_NUMBER_MASK;
    protected readonly basicCompanyInfoForm = new FormGroup({
        companyNumber: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.minLength(DEFAULT_COMPANY_NUMBER_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        name: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.maxLength(COMPANY_NAME_MAX_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        email: new FormControl<string>("", { validators: [Validators.required, Validators.email], nonNullable: true }),
        phone: new FormControl<PhoneNumber>(
            { countryCode: DEFAULT_PHONE_COUNTRY_CODE, number: "" },
            {
                validators: requiredValidForSmsPhoneNumberValidator,
                nonNullable: true,
            }
        ),
    });

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: BasicCompanyInfo) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor() {
        this.basicCompanyInfoForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.basicCompanyInfoForm.getRawValue() as BasicCompanyInfo);
            this.onValidationChange();
        });
    }

    public registerOnChange(fn: (value: BasicCompanyInfo) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.basicCompanyInfoForm.valid) {
            return null;
        }

        return this.basicCompanyInfoForm.invalid ? { invalidAddress: true } : null;
    }

    public writeValue(value: BasicCompanyInfo | null): void {
        if (value) {
            this.basicCompanyInfoForm.setValue(value);
        }
    }
}
