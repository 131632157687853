<mat-card>
    <div class="header">
        <h2>{{ "dtmWebAppMembership.missionsList.missionsListHeader" | transloco : { operatorType: operatorType$ | ngrxPush } }}</h2>
        <button class="button-secondary" type="button" (click)="missionWizardStart.emit()">
            <dtm-ui-icon name="add"></dtm-ui-icon> {{ "dtmWebAppMembership.missionsList.startMissionWizardLabel" | transloco }}
        </button>
    </div>
    <dtm-web-app-lib-membership-missions-filter
        [capabilities]="capabilities$ | ngrxPush"
        [incomingFilters]="incomingFilters$ | ngrxPush"
        (filtersChange)="filtersChange.emit($event)"
    ></dtm-web-app-lib-membership-missions-filter>
    <dtm-web-app-lib-membership-missions-list
        [missionsList]="missionsList$ | ngrxPush"
        [pagination]="pagination$ | ngrxPush"
        [isMissionsProcessing]="isMissionsProcessing$ | ngrxPush"
        [missionsListError]="missionsListError$ | ngrxPush"
        (missionsListRefresh)="missionsListRefresh.emit()"
        (pageChange)="pageChange.emit($event)"
    ></dtm-web-app-lib-membership-missions-list>
</mat-card>
