import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FunctionUtils } from "@dtm-frontend/shared/utils";

export function requiredAtLeastOnePermissionValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
        if (Object.values(group.getRawValue()).some(FunctionUtils.isTruthy)) {
            return null;
        } else {
            return { requiredAtLeastOnePermission: true };
        }
    };
}
