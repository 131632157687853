import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { ButtonTheme, ConfirmationDialogComponent, GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { FunctionUtils, LocalComponentStore, Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, OperatorFunction, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { OperatorContextState } from "../../../../shared";
import { EditableCustomUavSetup, EditableUavSetup, NewCustomUav, NewUav, UavErrorType } from "../../../services/uav.models";
import { UavActions } from "../../../state/uav.actions";
import { UavState } from "../../../state/uav.state";
import { NEW_UAV_WIZARD_ID, NewCustomUavBasicInfo, NewUavBasicInfo, NewUavWizardSteps } from "../new-uav-wizard.models";

interface NewUavWizardContentComponentState {
    basicInfo: NewUavBasicInfo | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-content",
    templateUrl: "./new-uav-wizard-content.component.html",
    styleUrls: ["./new-uav-wizard-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NewUavWizardContentComponent implements OnDestroy {
    protected readonly wizardId = NEW_UAV_WIZARD_ID;
    protected readonly NewUavWizardSteps = NewUavWizardSteps;
    protected readonly error$ = this.store.select(UavState.capabilitiesError);
    protected readonly manufacturers$ = this.store.select(UavState.manufacturers);
    protected readonly trackers$ = this.store.select(UavState.trackers);
    protected readonly navigationAccuracyItems$ = this.store.select(UavState.navigationAccuracyItems);
    protected readonly isProcessing$ = this.store.select(UavState.isProcessing);
    protected readonly newUavModel$ = this.store.select(UavState.newUavModel);
    protected readonly canManageUavs$ = this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorUavsManage));
    protected readonly basicInfoData$ = this.localStore.selectByKey("basicInfo");
    protected readonly customUavBasicInfo$ = this.localStore
        .selectByKey("basicInfo")
        .pipe(
            RxjsUtils.filterFalsy(),
            map((basicInfo) => (basicInfo?.isCustom ? basicInfo : undefined)) as OperatorFunction<
                NewUavBasicInfo,
                NewCustomUavBasicInfo | undefined
            >
        );

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<NewUavWizardContentComponentState>,
        private readonly matDialog: MatDialog,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            basicInfo: undefined,
        });

        this.goToAndEnableStep(NewUavWizardSteps.BasicInfo);
    }

    public ngOnDestroy() {
        this.store.dispatch(new WizardActions.CleanupWizard(this.wizardId));
    }

    protected goToAndEnableStep(step: NewUavWizardSteps) {
        this.store.dispatch([new WizardActions.SetActiveStep(this.wizardId, step), new WizardActions.EnableSteps(this.wizardId, [step])]);
    }

    protected goBackToStep(step: NewUavWizardSteps) {
        const allowGoBack = this.store.selectSnapshot(UavState.isDirty) ? this.confirmStepLeave() : of(true);

        allowGoBack
            .pipe(
                filter(FunctionUtils.isTruthy),
                switchMap(() => this.store.dispatch(new UavActions.MarkAsDirty(false))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.goToAndEnableStep(step);
            });
    }

    protected markValueChange() {
        this.store.dispatch(new UavActions.MarkAsDirty());
    }

    protected setBasicInfo(data: NewUavBasicInfo) {
        this.localStore.patchState({ basicInfo: data });

        if (!data.isCustom) {
            const currentUavModel = this.store.selectSnapshot(UavState.newUavModel);

            if (currentUavModel?.id !== data.uavModel.id) {
                this.store
                    .dispatch(
                        new UavActions.GetUavModel(
                            data.uavModel.id,
                            this.transloco.translate("dtmWebAppLibUav.uavCreation.defaultEmbeddedCameraName")
                        )
                    )
                    .pipe(untilDestroyed(this))
                    .subscribe(() => {
                        this.goToAndEnableStep(NewUavWizardSteps.PrimarySetup);
                    });

                return;
            }
        }

        this.goToAndEnableStep(NewUavWizardSteps.PrimarySetup);
    }

    protected setPrimarySetup(setup: EditableUavSetup) {
        const basicInfo = this.localStore.selectSnapshotByKey("basicInfo");
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContext)?.id;

        if (!operatorId || !basicInfo || !setup || basicInfo.isCustom) {
            Logger.captureMessage("NewUavWizardContent.setPrimarySetup: invalid data to set", {
                level: "warning",
                extra: { operatorId, basicInfo, setup, isCustom: basicInfo?.isCustom },
            });

            return;
        }

        this.addStandardUav({ operatorId, ...basicInfo, setup });
    }

    protected setCustomUavPrimarySetup(setup: EditableCustomUavSetup) {
        const basicInfo = this.localStore.selectSnapshotByKey("basicInfo");
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContext)?.id;

        if (!operatorId || !basicInfo || !setup || !basicInfo.isCustom) {
            Logger.captureMessage("NewUavWizardContent.setCustomUavPrimarySetup: invalid data to set", {
                level: "warning",
                extra: { operatorId, basicInfo, setup, isCustom: basicInfo?.isCustom },
            });

            return;
        }

        this.addCustomUav({ operatorId, ...basicInfo, setup });
    }

    private addStandardUav(data: NewUav): void {
        this.store
            .dispatch(new UavActions.CreateUav(data))
            .pipe(
                tap(() => this.manageUavCreationResult()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private addCustomUav(data: NewCustomUav): void {
        this.store
            .dispatch(new UavActions.CreateCustomUav(data))
            .pipe(
                tap(() => this.manageUavCreationResult()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private manageUavCreationResult(): void {
        const error = this.store.selectSnapshot(UavState.uavError);

        if (error?.type === UavErrorType.CannotCreateUavSerialNumberExists) {
            this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavCreation.serialNumbersAlreadyExistsErrorMessage"));

            return;
        }

        if (error) {
            this.toastService.error(this.transloco.translate("dtmWebAppLibUav.uavCreation.genericErrorMessage"));

            return;
        }

        const uavId = this.store.selectSnapshot(UavState.lastCreatedUavId);
        this.onUavCreationSuccessful(uavId);
    }

    private confirmStepLeave(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.newUavWizardSteps.confirmStepLeaveDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibUav.newUavWizardSteps.confirmStepLeaveDialog.contentText"),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.newUavWizardSteps.confirmStepLeaveDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.newUavWizardSteps.confirmStepLeaveDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private onUavCreationSuccessful(uavId: string | undefined) {
        const successMessage = this.transloco.translate("dtmWebAppLibUav.uavCreation.successMessage");

        this.toastService.success(successMessage);
        this.store.dispatch(new WizardActions.CleanupWizard(this.wizardId));
        this.router.navigate(["/uav", uavId]);
    }
}
