import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { ImportedDetails, OperatorMigrationError, OperatorMigrationErrorType } from "../models/operator-migration.models";
import { OperatorMigrationApiService } from "../services/operator-migration-api.service";
import { OperatorMigrationActions } from "./operator-migration.actions";

export interface OperatorMigrationStateModel {
    isProcessing: boolean;
    authenticationError: OperatorMigrationError | undefined;
    importedDetails: ImportedDetails | undefined;
    migrationError: OperatorMigrationError | undefined;
    migratedOperatorId: string | undefined;
    resetPasswordError: OperatorMigrationError | undefined;
}

const defaultState: OperatorMigrationStateModel = {
    isProcessing: false,
    authenticationError: undefined,
    importedDetails: undefined,
    migrationError: undefined,
    migratedOperatorId: undefined,
    resetPasswordError: undefined,
};

@State<OperatorMigrationStateModel>({
    name: "operatorMigration",
    defaults: defaultState,
})
@Injectable()
export class OperatorMigrationState {
    @Selector()
    public static importedDetails(state: OperatorMigrationStateModel): ImportedDetails | undefined {
        return state.importedDetails;
    }

    @Selector()
    public static isProcessing(state: OperatorMigrationStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static authenticationError(state: OperatorMigrationStateModel): OperatorMigrationError | undefined {
        return state.authenticationError;
    }

    @Selector()
    public static migrationError(state: OperatorMigrationStateModel): OperatorMigrationError | undefined {
        return state.migrationError;
    }

    @Selector()
    public static migratedOperatorId(state: OperatorMigrationStateModel): string | undefined {
        return state.migratedOperatorId;
    }

    @Selector()
    public static resetPasswordError(state: OperatorMigrationStateModel): OperatorMigrationError | undefined {
        return state.resetPasswordError;
    }

    constructor(private readonly operatorMigrationApiService: OperatorMigrationApiService) {}

    @Action(OperatorMigrationActions.ImportDetails)
    public importDetails(context: StateContext<OperatorMigrationStateModel>, action: OperatorMigrationActions.ImportDetails) {
        context.patchState({ isProcessing: true, importedDetails: undefined });

        return this.operatorMigrationApiService.importDetails(action.credentials).pipe(
            tap((response) => context.patchState({ importedDetails: response, authenticationError: undefined })),
            catchError((error) => {
                context.patchState({
                    authenticationError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorMigrationActions.ResetPassword)
    public resetPassword(context: StateContext<OperatorMigrationStateModel>, action: OperatorMigrationActions.ResetPassword) {
        context.patchState({ isProcessing: true, resetPasswordError: undefined });

        return this.operatorMigrationApiService.resetPassword(action.email).pipe(
            catchError((error) => {
                context.patchState({
                    resetPasswordError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorMigrationActions.MigrateEnterpriseOperator)
    public migrateEnterpriseOperator(
        context: StateContext<OperatorMigrationStateModel>,
        action: OperatorMigrationActions.MigrateEnterpriseOperator
    ) {
        context.patchState({ isProcessing: true });
        const importedOperatorId = context.getState().importedDetails?.id;

        if (!importedOperatorId) {
            context.patchState({ migrationError: { type: OperatorMigrationErrorType.Unknown } });

            return;
        }

        return this.operatorMigrationApiService.migrateEnterpriseOperator(action.migrationDetails, importedOperatorId).pipe(
            tap((response) => context.patchState({ migratedOperatorId: response, migrationError: undefined })),
            catchError((error) => {
                context.patchState({
                    migrationError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorMigrationActions.MigratePersonalOperator)
    public migratePersonalOperator(
        context: StateContext<OperatorMigrationStateModel>,
        action: OperatorMigrationActions.MigratePersonalOperator
    ) {
        context.patchState({ isProcessing: true });
        const importedOperatorId = context.getState().importedDetails?.id;

        if (!importedOperatorId) {
            context.patchState({ migrationError: { type: OperatorMigrationErrorType.Unknown } });

            return;
        }

        return this.operatorMigrationApiService.migratePersonalOperator(action.migrationDetails, importedOperatorId).pipe(
            tap((response) => context.patchState({ migratedOperatorId: response, migrationError: undefined })),
            catchError((error) => {
                context.patchState({
                    migrationError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
