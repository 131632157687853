import { AddNewMessage, NewThread, OperatorConversationFilterParams, OperatorThreadChange } from "../services/operator-conversations.model";

export namespace OperatorConversationsActions {
    export class GetThreadsList {
        public static readonly type = "[Operator conversations] Get threads list";
        constructor(public params: OperatorConversationFilterParams) {}
    }

    export class MarkAsRead {
        public static readonly type = "[Operator conversations] Mark as read";

        constructor(public threadId: string) {}
    }

    export class ChangeThread {
        public static readonly type = "[Operator conversations] Change thread";

        constructor(public payload: OperatorThreadChange, public threadId: string) {}
    }

    export class GetMessagesByThread {
        public static readonly type = "[Operator conversations] Get messages by thread";

        constructor(public threadId: string) {}
    }

    export class GetOperatorMessageCapabilities {
        public static readonly type = "[Operator conversations] Get operator message capabilities";

        constructor(public operatorId: string) {}
    }

    export class CreateNewThread {
        public static readonly type = "[Operator conversations] Create new thread";

        constructor(public newThread: NewThread) {}
    }

    export class AddNewMessageToThread {
        public static readonly type = "[Operator conversations] Add new message to thread";

        constructor(public message: AddNewMessage) {}
    }

    export class ClearConversations {
        public static readonly type = "[Operator conversations] Clear conversations";
    }
}
