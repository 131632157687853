<div
    class="mission-list card-box dtm-tabs"
    *ngrxLet="{ selectedMission: selectedMission$, partitionedMissions: partitionedMissions$ } as vm"
>
    <dtm-ui-loader [isShown]="isListProcessing$ | ngrxPush"></dtm-ui-loader>
    <mat-tab-group color="accent" [selectedIndex]="vm.selectedMission | invoke : selectedGroupIndex : vm.partitionedMissions">
        <mat-tab
            [label]="
                'dtmWebAppLibTactical.todayActiveMissionsTitle' | transloco : { count: vm.partitionedMissions.todayActiveMissions.length }
            "
        >
            <dtm-web-app-lib-accepted-mission-tile
                *ngFor="let mission of vm.partitionedMissions.todayActiveMissions; trackBy: trackMissionById"
                [mission]="mission"
                [isSelected]="mission.missionId === vm.selectedMission?.missionId"
                (click)="vm.selectedMission === mission || selectMission(mission)"
                [attr.data-mission-id]="mission.missionId"
            ></dtm-web-app-lib-accepted-mission-tile>
            <dtm-ui-no-results *ngIf="!vm.partitionedMissions.todayActiveMissions.length"></dtm-ui-no-results>
        </mat-tab>
        <mat-tab
            [label]="
                'dtmWebAppLibTactical.tomorrowAndLaterActiveMissionsTitle'
                    | transloco : { count: vm.partitionedMissions.futureActiveMissions.length }
            "
        >
            <dtm-web-app-lib-accepted-mission-tile
                *ngFor="let mission of vm.partitionedMissions.futureActiveMissions; trackBy: trackMissionById"
                [mission]="mission"
                [isSelected]="mission?.missionId === vm.selectedMission?.missionId"
                shouldShowExtendedInfo
                (click)="vm.selectedMission === mission || selectMission(mission)"
                [attr.data-mission-id]="mission.missionId"
            ></dtm-web-app-lib-accepted-mission-tile>
            <dtm-ui-no-results *ngIf="!vm.partitionedMissions.futureActiveMissions.length"></dtm-ui-no-results>
        </mat-tab>
    </mat-tab-group>
</div>
