import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "@dtm-frontend/shared/ui";
import { GlobalOutlets } from "../shared/models/router.models";
import { LegalGuardianContainerComponent } from "./components/legal-guardian-container/legal-guardian-container.component";
import { LegalGuardianSignatureStatusComponent } from "./components/legal-guardian-signature-status/legal-guardian-signature-status.component";
import { NOT_FOUND_PAGE, SUCCESS_PAGE } from "./services/legal-guardian.models";

const routes: Routes = [
    {
        pathMatch: "full",
        path: "legal-guardian-details",
        component: LegalGuardianContainerComponent,
        outlet: GlobalOutlets.Public,
    },
    {
        path: SUCCESS_PAGE,
        component: LegalGuardianSignatureStatusComponent,
        outlet: GlobalOutlets.Public,
    },
    {
        path: NOT_FOUND_PAGE,
        component: PageNotFoundComponent,
        outlet: GlobalOutlets.Public,
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LegalGuardianDetailsRoutingModule {}
