import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { OperationsManualApiService } from "../services/operations-manual-api.service";
import {
    Capabilities,
    Chapter,
    OperationsManualError,
    OperationsManualErrorType,
    OperationsManualVersion,
    TableOfContentsChapters,
} from "../services/operations-manual.models";
import { OperationsManualActions } from "./operations-manual.actions";

export interface OperationsManualStateModel {
    isProcessing: boolean;
    capabilities: Capabilities | undefined;
    capabilitiesError: OperationsManualError | undefined;
    currentOperationsManual: OperationsManualVersion | undefined;
    createOperationsManualError: OperationsManualError | undefined;
    lastUpdateDate: Date | undefined;
    tableOfContentsChapters: TableOfContentsChapters | undefined;
    getDataError: OperationsManualError | undefined;
    publishError: OperationsManualError | undefined;
    downloadPdfError: OperationsManualError | undefined;
    isChapterProcessing: boolean;
    chapter: Chapter | undefined;
    getChapterError: OperationsManualError | undefined;
    saveChapterError: OperationsManualError | undefined;
    setAttachmentsError: OperationsManualError | undefined;
    addSubchapterError: OperationsManualError | undefined;
    removeSubchapterError: OperationsManualError | undefined;
}

const defaultState: OperationsManualStateModel = {
    isProcessing: false,
    capabilities: undefined,
    capabilitiesError: undefined,
    currentOperationsManual: undefined,
    createOperationsManualError: undefined,
    lastUpdateDate: undefined,
    tableOfContentsChapters: undefined,
    getDataError: undefined,
    publishError: undefined,
    downloadPdfError: undefined,
    isChapterProcessing: false,
    chapter: undefined,
    getChapterError: undefined,
    saveChapterError: undefined,
    setAttachmentsError: undefined,
    addSubchapterError: undefined,
    removeSubchapterError: undefined,
};

@State<OperationsManualStateModel>({
    name: "operationsManual",
    defaults: defaultState,
})
@Injectable()
export class OperationsManualState {
    @Selector()
    public static isProcessing(state: OperationsManualStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static capabilities(state: OperationsManualStateModel): Capabilities | undefined {
        return state.capabilities;
    }

    @Selector()
    public static capabilitiesError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static currentOperationsManual(state: OperationsManualStateModel): OperationsManualVersion | undefined {
        return state.currentOperationsManual;
    }

    @Selector()
    public static createOperationsManualError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.createOperationsManualError;
    }

    @Selector()
    public static lastUpdateDate(state: OperationsManualStateModel): Date | undefined {
        return state.lastUpdateDate;
    }

    @Selector()
    public static tableOfContentsChapters(state: OperationsManualStateModel): TableOfContentsChapters | undefined {
        return state.tableOfContentsChapters;
    }

    @Selector()
    public static getDataError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.getDataError;
    }

    @Selector()
    public static publishError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.publishError;
    }

    @Selector()
    public static downloadPdfError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.downloadPdfError;
    }

    @Selector()
    public static isChapterProcessing(state: OperationsManualStateModel): boolean {
        return state.isChapterProcessing && !state.isProcessing;
    }

    @Selector()
    public static chapter(state: OperationsManualStateModel): Chapter | undefined {
        return state.chapter;
    }

    @Selector()
    public static getChapterError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.getChapterError;
    }

    @Selector()
    public static saveChapterError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.saveChapterError;
    }

    @Selector()
    public static setAttachmentsError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.setAttachmentsError;
    }

    @Selector()
    public static addSubchapterError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.addSubchapterError;
    }

    @Selector()
    public static removeSubchapterError(state: OperationsManualStateModel): OperationsManualError | undefined {
        return state.removeSubchapterError;
    }

    constructor(private readonly operationsManualApi: OperationsManualApiService, private readonly store: Store) {
        if (operationsManualApi === undefined) {
            throw new Error("Initialize OperationsManualModule with .forRoot()");
        }
    }

    @Action(OperationsManualActions.GetCapabilities)
    public getCapabilities(context: StateContext<OperationsManualStateModel>) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ capabilitiesError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        context.patchState({
            capabilitiesError: undefined,
            isProcessing: true,
        });

        return this.operationsManualApi.getCapabilities(operatorId).pipe(
            tap((capabilities) => {
                context.patchState({
                    capabilities,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({ isProcessing: false, capabilitiesError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.ResetData)
    public resetData(context: StateContext<OperationsManualStateModel>) {
        context.patchState({
            capabilities: undefined,
            lastUpdateDate: undefined,
            tableOfContentsChapters: undefined,
            currentOperationsManual: undefined,
            chapter: undefined,
        });
    }

    @Action(OperationsManualActions.CreateOperationsManual)
    public createOperationsManual(context: StateContext<OperationsManualStateModel>) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ createOperationsManualError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        context.patchState({
            createOperationsManualError: undefined,
            isProcessing: true,
        });

        return this.operationsManualApi.createOperationsManual(operatorId).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    isProcessing: false,
                    createOperationsManualError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.SetCurrentOperationsManual)
    public setCurrentOperationsManual(
        context: StateContext<OperationsManualStateModel>,
        action: OperationsManualActions.SetCurrentOperationsManual
    ) {
        context.patchState({ currentOperationsManual: action.operationsManual });
    }

    @Action(OperationsManualActions.GetTableOfContents)
    public getTableOfContents(context: StateContext<OperationsManualStateModel>, action: OperationsManualActions.GetTableOfContents) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ getDataError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ getDataError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            getDataError: undefined,
            isProcessing: true,
        });

        return this.operationsManualApi.getTableOfContents(operatorId, currentOperationsManual, action.filters).pipe(
            tap((tableOfContents) => {
                context.patchState({
                    lastUpdateDate: tableOfContents.lastUpdateDate,
                    tableOfContentsChapters: {
                        chapters: tableOfContents.chapters,
                        initiallySelectedChapterId: tableOfContents.chapters.length ? tableOfContents.chapters[0].id : undefined,
                    },
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({ isProcessing: false, getDataError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.Publish)
    public publish(context: StateContext<OperationsManualStateModel>) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ publishError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ publishError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            publishError: undefined,
            isProcessing: true,
        });

        return this.operationsManualApi.publish(operatorId, currentOperationsManual).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({ isProcessing: false, publishError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.DownloadPdf)
    public downloadPdf(context: StateContext<OperationsManualStateModel>, action: OperationsManualActions.DownloadPdf) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ downloadPdfError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ downloadPdfError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            downloadPdfError: undefined,
            isProcessing: true,
        });

        return this.operationsManualApi.downloadPdf(operatorId, currentOperationsManual, action.fileName).pipe(
            tap(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({ isProcessing: false, downloadPdfError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.GetChapter)
    public getChapter(context: StateContext<OperationsManualStateModel>, action: OperationsManualActions.GetChapter) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ getChapterError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ getChapterError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            getChapterError: undefined,
            isChapterProcessing: true,
        });

        return this.operationsManualApi.getChapter(operatorId, currentOperationsManual, action.chapterId, action.filters).pipe(
            tap((chapter) => {
                context.patchState({
                    chapter,
                    isChapterProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({ isChapterProcessing: false, getChapterError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.SaveChapter)
    public saveChapter(context: StateContext<OperationsManualStateModel>, action: OperationsManualActions.SaveChapter) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ saveChapterError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ saveChapterError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            saveChapterError: undefined,
            isChapterProcessing: true,
        });

        return this.operationsManualApi.saveChapterContent(operatorId, currentOperationsManual, action.chapterId, action.content).pipe(
            tap((lastUpdateDate) =>
                context.patchState({
                    lastUpdateDate,
                    isChapterProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({ isChapterProcessing: false, saveChapterError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.AddSubchapter)
    public addSubchapter(context: StateContext<OperationsManualStateModel>, action: OperationsManualActions.AddSubchapter) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ addSubchapterError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ addSubchapterError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            addSubchapterError: undefined,
            isChapterProcessing: true,
        });

        return this.operationsManualApi.addSubchapter(operatorId, currentOperationsManual, action.name).pipe(
            tap(({ lastUpdateDate, chapters }) =>
                context.patchState({
                    lastUpdateDate: lastUpdateDate,
                    tableOfContentsChapters: {
                        chapters,
                        initiallySelectedChapterId: action.chapterId,
                    },
                    isChapterProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({ isChapterProcessing: false, addSubchapterError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.RemoveSubchapter)
    public removeSubchapter(context: StateContext<OperationsManualStateModel>, action: OperationsManualActions.RemoveSubchapter) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ removeSubchapterError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ removeSubchapterError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            removeSubchapterError: undefined,
            isChapterProcessing: true,
        });

        return this.operationsManualApi.removeSubchapter(operatorId, currentOperationsManual, action.subchapterId).pipe(
            tap(({ lastUpdateDate, chapters }) =>
                context.patchState({
                    lastUpdateDate: lastUpdateDate,
                    tableOfContentsChapters: {
                        chapters,
                        initiallySelectedChapterId: action.chapterId,
                    },
                    isChapterProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({ isChapterProcessing: false, removeSubchapterError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperationsManualActions.SetChapterAttachments)
    public setChapterAttachments(context: StateContext<OperationsManualStateModel>, action: OperationsManualActions.SetChapterAttachments) {
        const operatorId = this.getOperatorId();
        if (!operatorId) {
            context.patchState({ setAttachmentsError: this.getInvalidOperatorError() });

            return EMPTY;
        }

        const currentOperationsManual = context.getState().currentOperationsManual;
        if (!currentOperationsManual) {
            context.patchState({ setAttachmentsError: this.getInvalidOperationsManualError() });

            return EMPTY;
        }

        context.patchState({
            setAttachmentsError: undefined,
            isChapterProcessing: true,
        });

        return this.operationsManualApi
            .setChapterAttachments(operatorId, currentOperationsManual, action.chapterId, action.attachments)
            .pipe(
                tap((lastUpdateDate) =>
                    context.patchState({
                        lastUpdateDate,
                        isChapterProcessing: false,
                    })
                ),
                catchError((error) => {
                    context.patchState({ isChapterProcessing: false, setAttachmentsError: error });

                    return EMPTY;
                })
            );
    }

    private getOperatorId() {
        return this.store.selectSnapshot(OperatorContextState.selectedContextId);
    }

    private getInvalidOperatorError() {
        return { type: OperationsManualErrorType.InvalidOperator };
    }

    private getInvalidOperationsManualError() {
        return { type: OperationsManualErrorType.InvalidOperationsManual };
    }
}
