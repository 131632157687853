<ng-container
    *ngrxLet="{
        entity: entity$,
        title: title$,
        constraints: constraints$,
        displayMode: displayMode$,
        settings: settings$,
        isCoordinatesEditMode: isCoordinatesEditMode$,
        isProcessing: isProcessing$,
    } as vm"
>
    <ng-template #cardIconTemplate let-entityType="entityType">
        <ng-container [ngSwitch]="entityType">
            <ng-container *ngSwitchCase="ViewItineraryEntityType.InternalCylinder">
                <dtm-ui-icon name="shape-cylinder"></dtm-ui-icon>
            </ng-container>
            <ng-container *ngSwitchCase="ViewItineraryEntityType.Cylinder">
                <dtm-ui-icon name="shape-cylinder"></dtm-ui-icon>
            </ng-container>
            <ng-container *ngSwitchCase="ViewItineraryEntityType.Landing">
                <dtm-ui-icon name="flight-land"></dtm-ui-icon>
            </ng-container>
            <ng-container *ngSwitchCase="ViewItineraryEntityType.Takeoff">
                <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <dtm-ui-icon name="shape-prism"></dtm-ui-icon>
            </ng-container>
        </ng-container>
    </ng-template>

    <div
        class="entity-card"
        *ngIf="vm.entity as shapeEntity"
        [ngClass]="{
            'internal-cylinder': shapeEntity.type === ViewItineraryEntityType.InternalCylinder,
            'internal-prism': shapeEntity.type === ViewItineraryEntityType.InternalPrism,
            'cylinder': shapeEntity.type === ViewItineraryEntityType.Cylinder,
            'prism': shapeEntity.type === ViewItineraryEntityType.Prism,
            'landing': shapeEntity.type === ViewItineraryEntityType.Landing,
            'takeoff': shapeEntity.type === ViewItineraryEntityType.Takeoff,
        }"
    >
        <header>
            <ng-template [ngTemplateOutlet]="cardIconTemplate" [ngTemplateOutletContext]="{ entityType: shapeEntity.type }"></ng-template>
            <h2>{{ vm.title }}</h2>
            <ng-container *ngIf="vm.displayMode.canEdit.shape.coordinates && shapeEntity.centerCoordinates">
                <span class="coordinates">
                    {{ shapeEntity.centerCoordinates.latitude | number : "1.0-4" | localizeNumber }};
                    {{ shapeEntity.centerCoordinates.longitude | number : "1.0-4" | localizeNumber }}
                </span>
                <button
                    *ngIf="!vm.isCoordinatesEditMode"
                    type="button"
                    class="coordinates-edit-button button-icon"
                    [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.centerCoordinatesEditButtonLabel' | transloco"
                    (click)="editCenterPoint.emit()"
                >
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                </button>
            </ng-container>

            <button
                type="button"
                class="button-icon delete-button"
                [disabled]="vm.isProcessing"
                (click)="entityDelete.emit()"
                [class.hidden]="
                    shapeEntity.type !== ViewItineraryEntityType.Takeoff &&
                    shapeEntity.type !== ViewItineraryEntityType.Landing &&
                    !vm.displayMode.canDelete.shape
                "
            >
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            </button>
        </header>
        <div class="card-content">
            <dtm-web-app-lib-mwiesip-point-controls
                *ngIf="vm.isCoordinatesEditMode && shapeEntity.centerCoordinates && vm.displayMode.canEdit.shape.coordinates"
                [isCoordinatesEditMode]="true"
                [coordinates]="shapeEntity.centerCoordinates"
                [displayMode]="vm.displayMode"
                type="waypoint"
                (pointCoordinatesChange)="centerCoordinatesChange.emit($event)"
            >
            </dtm-web-app-lib-mwiesip-point-controls>
            <ng-container *ngIf="vm.settings && vm.constraints && (!vm.isCoordinatesEditMode || !vm.displayMode.canEdit.shape.coordinates)">
                <ng-container
                    *ngIf="
                        shapeEntity.type === ViewItineraryEntityType.Landing ||
                        shapeEntity.type === ViewItineraryEntityType.InternalCylinder ||
                        shapeEntity.type === ViewItineraryEntityType.InternalPrism
                    "
                >
                    <dtm-web-app-lib-mwiesip-point-controls
                        *ngrxLet="shapeEntity.inletVerticalBuffer | invoke : getVerticalBufferOffset as inletVerticalBufferOffset"
                        [label]="
                            'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.inletPointLabel'
                                | transloco : { label: shapeEntity.inletPointLabel$ | ngrxPush }
                        "
                        [min]="
                            (shapeEntity.bottomHeight !== undefined ? shapeEntity.bottomHeight : vm.constraints.min.height) +
                            inletVerticalBufferOffset
                        "
                        [max]="
                            (shapeEntity.topHeight !== undefined ? shapeEntity.topHeight : vm.constraints.max.height) -
                            inletVerticalBufferOffset
                        "
                        [height]="shapeEntity.inletPointHeight"
                        [amslOffset]="shapeEntity.inletPointAmslOffset"
                        [heightEditMode]="vm.settings.heightType"
                        [displayMode]="vm.displayMode"
                        type="inlet"
                        (pointHeightInputChange)="inletPointHeightChange.emit($event)"
                    >
                    </dtm-web-app-lib-mwiesip-point-controls>
                </ng-container>

                <div class="shape-controls">
                    <dtm-web-app-lib-mwiesip-top-bottom-height-controls
                        [canEditBottomHeight]="
                            shapeEntity.type === ViewItineraryEntityType.InternalCylinder ||
                            shapeEntity.type === ViewItineraryEntityType.InternalPrism
                        "
                        [entity]="shapeEntity"
                        [constraints]="vm.constraints"
                        [settings]="vm.settings"
                        (topHeightChange)="shapeTopHeightChange.emit($event)"
                        [displayMode]="vm.displayMode"
                        (bottomHeightChange)="shapeBottomHeightChange.emit($event)"
                    >
                    </dtm-web-app-lib-mwiesip-top-bottom-height-controls>

                    <div class="sub-shape-controls">
                        <ng-content></ng-content>

                        <dtm-ui-input-field
                            class="stopover"
                            *ngIf="
                                vm.displayMode.canShow.shape.stopover &&
                                (shapeEntity.type === ViewItineraryEntityType.InternalCylinder ||
                                    shapeEntity.type === ViewItineraryEntityType.InternalPrism ||
                                    shapeEntity.type === ViewItineraryEntityType.Cylinder ||
                                    shapeEntity.type === ViewItineraryEntityType.Prism)
                            "
                        >
                            <dtm-ui-icon name="time" class="field-prefix"></dtm-ui-icon>
                            <label>{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.stopoverLabel" | transloco }}</label>
                            <input
                                matInput
                                dtmUiClampInput
                                type="number"
                                inputmode="numeric"
                                [value]="shapeEntity.stopoverMinutes | roundNumber"
                                [min]="0"
                                step="1"
                                required
                                #stopoverInput
                                (clampedInput)="stopoverChange.emit(stopoverInput.valueAsNumber)"
                            />
                            <div class="field-suffix">
                                {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.stopoverUnitLabel" | transloco }}
                            </div>
                        </dtm-ui-input-field>
                    </div>
                </div>

                <ng-container
                    *ngIf="
                        shapeEntity.type === ViewItineraryEntityType.InternalCylinder ||
                        shapeEntity.type === ViewItineraryEntityType.InternalPrism ||
                        shapeEntity.type === ViewItineraryEntityType.Takeoff
                    "
                >
                    <dtm-web-app-lib-mwiesip-point-controls
                        *ngrxLet="shapeEntity.outletVerticalBuffer | invoke : getVerticalBufferOffset as outletVerticalBufferOffset"
                        [label]="
                            'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.outletPointLabel'
                                | transloco : { label: shapeEntity.outletPointLabel$ | ngrxPush }
                        "
                        [min]="
                            (shapeEntity.bottomHeight !== undefined ? shapeEntity.bottomHeight : vm.constraints.min.height) +
                            outletVerticalBufferOffset
                        "
                        [max]="
                            (shapeEntity.topHeight !== undefined ? shapeEntity.topHeight : vm.constraints.max.height) -
                            outletVerticalBufferOffset
                        "
                        [height]="shapeEntity.outletPointHeight"
                        [amslOffset]="shapeEntity.outletPointAmslOffset"
                        [heightEditMode]="vm.settings.heightType"
                        [displayMode]="vm.displayMode"
                        type="outlet"
                        (pointHeightInputChange)="outletPointHeightChange.emit($event)"
                    >
                    </dtm-web-app-lib-mwiesip-point-controls>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
