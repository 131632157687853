import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import { AZURE_MAPS_LAYER_OPTIONS, DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS } from "@dtm-frontend/shared/map/cesium";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { Logger } from "@dtm-frontend/shared/utils";
import { AcMapComponent, SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

export interface AreaPreviewDialogData {
    title: string;
    description: string | undefined;
    area: GeoJSON;
}

@Component({
    selector: "dtm-web-app-lib-spec-perm-app-area-preview-dialog",
    templateUrl: "./area-preview-dialog.component.html",
    styleUrls: ["./area-preview-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaPreviewDialogComponent implements AfterViewInit {
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    @ViewChild(AcMapComponent) private acMap: AcMapComponent | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: AreaPreviewDialogData,
        viewerConfiguration: ViewerConfiguration,
        @Inject(SHARED_MAP_ENDPOINTS) protected readonly sharedMapEndpoints: SharedMapEndpoints
    ) {
        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE3D,
        };

        Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...turfBbox(data.area));
    }

    public async ngAfterViewInit() {
        const viewer = this.acMap?.getCesiumService().getViewer();
        if (!viewer) {
            Logger.captureMessage("AreaPreviewDialogComponent.ngAfterViewInit: viewer not found", {
                level: "error",
                extra: { viewer },
            });

            return;
        }

        const areaLayer = new Cesium.ImageryLayer(
            new Cesium.WebMapServiceImageryProvider({
                url: this.sharedMapEndpoints.geoServerEndpoint,
                layers: this.sharedMapEndpoints.layersPrefix + "area",
                enablePickFeatures: false,
                parameters: {
                    transparent: true,
                    format: "image/png",
                },
            })
        );
        viewer.imageryLayers.add(areaLayer);

        const geoJsonData = await Cesium.GeoJsonDataSource.load(this.data.area);

        for (const entity of geoJsonData.entities?.values ?? []) {
            let color = Cesium.Color.fromCssColorString("#1477d5"); // $color-secondary-700
            if (entity.id.startsWith("flightArea")) {
                color = Cesium.Color.fromCssColorString("#0849a4"); // $color-secondary-900
            } else if (entity.id.startsWith("safetyArea")) {
                color = Cesium.Color.fromCssColorString("#43577e"); // $color-gray-400
            }

            /* eslint-disable no-magic-numbers*/
            entity.polygon.material = color.withAlpha(0.4);
            entity.polygon.outlineColor = color.withAlpha(0.6);
            /* eslint-enable no-magic-numbers*/
        }

        await viewer.dataSources.add(geoJsonData);

        viewer.zoomTo(geoJsonData);
    }
}
