import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Sail } from "../../../../shared/models/operations-manual.models";

interface SailInfoComponentState {
    sail: Sail | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-sail-info[sail]",
    templateUrl: "./sail-info.component.html",
    styleUrls: ["../../common.scss", "./sail-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SailInfoComponent {
    @Input() public set sail(value: Sail | undefined) {
        this.localStore.patchState({ sail: value });
    }

    @Output() public readonly operationsManualGuideForSailOpen = new EventEmitter<number>();

    protected readonly sail$ = this.localStore.selectByKey("sail").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<SailInfoComponentState>) {
        this.localStore.setState({
            sail: undefined,
        });
    }
}
