import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { FormControl, FormGroup, Validators } from "@angular/forms";

import { LocalComponentStore, MAX_TEXT_LENGTH, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";

import { LegalGuardian, LegalGuardianConfirmationData, LegalGuardianConfirmationRequest } from "../../models/user-profile.models";

interface LegalGuardianComponentState {
    legalGuardianConfirmationRequest: LegalGuardianConfirmationRequest | undefined;
    legalGuardian: LegalGuardian | undefined;
    isEditButtonVisible: boolean;
    isEditModeOn: boolean;
}

interface LegalGuardianForm {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
}

@Component({
    selector: "dtm-web-app-lib-legal-guardian",
    templateUrl: "./legal-guardian.component.html",
    styleUrls: ["./legal-guardian.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LegalGuardianComponent {
    @Input() public set legalGuardianConfirmationRequest(value: LegalGuardianConfirmationRequest | undefined) {
        this.localStore.patchState({ legalGuardianConfirmationRequest: value, isEditModeOn: false });

        if (value) {
            this.localStore.patchState({ isEditButtonVisible: true });
        }
    }
    @Input() public set legalGuardian(value: LegalGuardian | undefined) {
        this.localStore.patchState({ legalGuardian: value, isEditModeOn: false });
    }

    @Output() public resend = new EventEmitter<void>();
    @Output() public saveData = new EventEmitter<LegalGuardianConfirmationData>();

    protected readonly legalGuardianConfirmationRequest$ = this.localStore.selectByKey("legalGuardianConfirmationRequest");
    protected readonly isEditModeOn$ = this.localStore.selectByKey("isEditModeOn");
    protected readonly legalGuardian$ = this.localStore.selectByKey("legalGuardian");
    protected readonly isEditButtonVisible$ = this.localStore.selectByKey("isEditButtonVisible");
    protected readonly legalGuardianForm = new FormGroup<LegalGuardianForm>({
        firstName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(MAX_TEXT_LENGTH),
            ],
            nonNullable: true,
        }),
        lastName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(MAX_TEXT_LENGTH),
            ],
            nonNullable: true,
        }),
        email: new FormControl("", {
            validators: [Validators.required, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN), Validators.email],
            nonNullable: true,
        }),
    });

    constructor(private readonly localStore: LocalComponentStore<LegalGuardianComponentState>) {
        this.localStore.setState({
            legalGuardianConfirmationRequest: undefined,
            legalGuardian: undefined,
            isEditButtonVisible: false,
            isEditModeOn: false,
        });
    }

    protected save(): void {
        this.legalGuardianForm.markAllAsTouched();

        if (this.legalGuardianForm.invalid) {
            return;
        }
        this.saveData.emit(this.legalGuardianForm.getRawValue());
    }

    protected manageFormState(isEditModeOn: boolean): void {
        const operatorAddressState = this.localStore.selectSnapshotByKey("legalGuardianConfirmationRequest");
        this.localStore.patchState({ isEditModeOn });

        if (!isEditModeOn) {
            this.legalGuardianForm.reset();

            return;
        }

        this.legalGuardianForm.patchValue({ ...operatorAddressState });
    }
}
