import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Member, MemberType, MembershipError, MembershipStatus } from "../../../services/membership.models";

interface MembershipOperatorsListComponentState {
    operatorsList: Member[];
    operatorsListError: MembershipError | undefined;
    isMemberProcessing: boolean;
    pagination: Page | undefined;
    expandedElement: Member | undefined;
}

@Component({
    selector: "dtm-web-app-lib-membership-operators-list[operatorsList][operatorsListError][pagination]",
    templateUrl: "./membership-operators-list.component.html",
    styleUrls: ["./membership-operators-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class MembershipOperatorsListComponent {
    @Input() public set operatorsList(value: Member[] | undefined) {
        this.localStore.patchState({ operatorsList: value ?? [] });
    }

    @Input() public set operatorsListError(value: MembershipError | undefined) {
        this.localStore.patchState({ operatorsListError: value });
    }

    @Input() public set isMemberProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isMemberProcessing: !!value });
    }

    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public operatorsListRefresh = new EventEmitter<void>();
    @Output() public invitationAccept = new EventEmitter<Member>();
    @Output() public invitationReject = new EventEmitter<Member>();
    @Output() public redirectToOperatorDetails = new EventEmitter<string>();
    @Output() public goToMissionPlanning = new EventEmitter<Member>();
    @Output() public membershipResignation = new EventEmitter<Member>();
    @Output() public pageChange = new EventEmitter<PageEvent>();

    protected readonly operatorsList$ = this.localStore.selectByKey("operatorsList");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly operatorsListError$ = this.localStore.selectByKey("operatorsListError");
    protected readonly isMemberProcessing$ = this.localStore.selectByKey("isMemberProcessing");
    protected readonly MembershipStatus = MembershipStatus;
    protected readonly MemberType = MemberType;
    protected readonly displayedColumns: string[] = ["expandHandle", "status", "invitationDate", "membershipOperatorName", "actions"];
    protected readonly ErrorMode = ErrorMode;

    constructor(private readonly localStore: LocalComponentStore<MembershipOperatorsListComponentState>) {
        localStore.setState({
            operatorsList: [],
            operatorsListError: undefined,
            isMemberProcessing: false,
            pagination: undefined,
            expandedElement: undefined,
        });
    }

    protected toggleExpandableRow(row: Member): void {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }
}
