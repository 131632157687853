import { ConversationCategoryCode } from "@dtm-frontend/shared/ui";

export interface ThreadsError {
    type: ThreadsErrorType;
}

export enum ThreadsErrorType {
    Unknown = "Unknown",
}

export interface OperatorThreadChange {
    isClosed: boolean;
    isRead: boolean;
}

export interface NewThread {
    content: string;
    attachmentIdsList?: string[];
    category: ConversationCategoryCode;
    subject: string;
    sender: NewMessageSender;
}

export interface NewMessageSender {
    id: string;
    name: string;
}

export enum ConversationFilterFormKeys {
    SearchByText = "searchByText",
    Categories = "categories",
}

export interface OperatorConversationFilters {
    [ConversationFilterFormKeys.SearchByText]?: string;
    [ConversationFilterFormKeys.Categories]?: ConversationCategoryCode[];
}

export interface OperatorConversationFilterParams {
    selectedOperatorContextId: string;
    [ConversationFilterFormKeys.SearchByText]?: string;
    [ConversationFilterFormKeys.Categories]?: string;
}

export interface AddNewMessage {
    content: string;
    attachmentIdsList?: string[];
    subject: string;
    sender: NewMessageSender;
    threadId: string;
}
