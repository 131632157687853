<form [formGroup]="competenciesForm">
    <dtm-ui-select-field
        [multiple]="true"
        [formControl]="competenciesForm.controls.basic"
        [placeholder]="'dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.selectCompetenciesPlaceholder' | transloco"
    >
        <label>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.selectCompetenciesLabel" | transloco }}</label>
        <dtm-ui-select-option
            *ngFor="let selectableCompetency of selectableCompetencies$ | ngrxPush"
            [value]="selectableCompetency"
            [ngSwitch]="selectableCompetency.type"
        >
            <ng-container *ngSwitchCase="CompetencyOptionType.OperationScenario">
                {{ $any(selectableCompetency).operationScenario.name }}
            </ng-container>
            <ng-container *ngSwitchCase="CompetencyOptionType.EuRegulation">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.euRegulationCompetencyLabel" | transloco }}
            </ng-container>
            <ng-container *ngSwitchCase="CompetencyOptionType.Additional">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.additionalCompetenciesOptionLabel" | transloco }}
            </ng-container>
        </dtm-ui-select-option>
        <div class="field-error" *dtmUiFieldHasError="competenciesForm.controls.basic; name: 'required'">
            {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
        </div>
    </dtm-ui-select-field>
    <section *ngIf="isAdditionalCompetenciesOptionSelected$ | ngrxPush">
        <dtm-web-app-lib-spec-perm-app-names-control
            [formControl]="competenciesForm.controls.additional"
            [inputFieldLabel]="'dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.additionalCompetencyNameLabel' | transloco"
            [addButtonNameLabel]="
                'dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.addAdditionalCompetencyButtonLabel' | transloco
            "
        >
        </dtm-web-app-lib-spec-perm-app-names-control>
    </section>
</form>
