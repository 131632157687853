<dtm-ui-expandable-panel
    *ngrxLet="{ soraResult: soraResult$, isExpanded: isExpanded$ } as vm"
    [isExpanded]="vm.isExpanded"
    [hasHeaderSeparator]="false"
    class="sora-panel"
>
    <h2 class="section-title" headerSlot>
        {{ "dtmWebAppLibMission.summaryStep.analysis.adjacentAreaAndSpaceTitle" | transloco }}
    </h2>
    <dtm-ui-label-value [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.averagePeopleDensityInAdjacentAreaLabel' | transloco">
        <div
            [innerHTML]="
                vm.soraResult?.adjacentArea?.populationDensity?.peoplePerSquareKilometerAvg
                    ? ('dtmWebAppLibMission.summaryStep.analysis.sora.averagePeopleDensityInAdjacentAreaValue'
                      | transloco
                          : {
                                value: vm.soraResult?.adjacentArea?.populationDensity?.peoplePerSquareKilometerAvg
                            })
                    : ('dtmWebAppLibMission.summaryStep.analysis.sora.noDataLabel' | transloco)
            "
        ></div>
    </dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.adjacentAreaTypeLabel' | transloco"
        [value]="
            vm.soraResult?.adjacentArea?.peopleAssembly
                ? ('dtmWebAppLibMission.summaryStep.analysis.sora.peopleGatheringLabel' | transloco)
                : (vm.soraResult?.adjacentArea?.intrinsicGrl | grlLevel)
        "
    ></dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.groundRiskClassInAdjacentAreaLabel' | transloco"
        [value]="
            (vm.soraResult?.adjacentArea?.intrinsicGrc | grcLevel) ??
            ('dtmWebAppLibMission.summaryStep.analysis.sora.noDataLabel' | transloco)
        "
    ></dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.adjacentAirspaceLabel' | transloco"
        [value]="
            'dtmWebAppLibMission.summaryStep.analysis.sora.airspaceCharacteristicLabelText'
                | transloco : { characteristics: airspaceCharacteristics$ | ngrxPush }
        "
    ></dtm-ui-label-value>

    <dtm-ui-label-value
        [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.airspaceCharacteristicsLabel' | transloco"
        [value]="
            (vm.soraResult?.adjacentArea?.initialArc | arcLabel) ??
            ('dtmWebAppLibMission.summaryStep.analysis.sora.noDataLabel' | transloco)
        "
    ></dtm-ui-label-value>

    <ng-container *ngIf="vm.soraResult?.flightTerminationSystem as flightTerminationSystem">
        <dtm-ui-label-value
            *ngIf="flightTerminationSystem.isAvailable || flightTerminationSystem.isRequired"
            [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.technicalRequirementsLabel' | transloco"
            [value]="'dtmWebAppLibMission.summaryStep.analysis.sora.reinforcedLabel' | transloco"
        ></dtm-ui-label-value>

        <dtm-ui-label-value
            *ngIf="!flightTerminationSystem.isAvailable && !flightTerminationSystem.isRequired"
            [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.technicalRequirementsLabel' | transloco"
            [value]="'dtmWebAppLibMission.summaryStep.analysis.sora.basicLabel' | transloco"
        >
        </dtm-ui-label-value>

        <dtm-ui-label-value
            *ngIf="!flightTerminationSystem.isAvailable && flightTerminationSystem.isRequired"
            [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.securityTypeLabel' | transloco"
            [value]="'dtmWebAppLibMission.summaryStep.analysis.sora.noRequiredSecurityLabel' | transloco"
        ></dtm-ui-label-value>

        <dtm-ui-label-value
            *ngIf="flightTerminationSystem.isAvailable || !flightTerminationSystem.isRequired"
            [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.securityTypeLabel' | transloco"
        >
            <ng-container *ngIf="technicalRequirementsText$ | ngrxPush as text; else noDataTemplate">
                {{ text }}
            </ng-container>
        </dtm-ui-label-value>
    </ng-container>
</dtm-ui-expandable-panel>

<ng-template #noDataTemplate>{{ "dtmWebAppLibMission.summaryStep.analysis.sora.noDataLabel" | transloco }}</ng-template>
