import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

export interface RiskMitigationLevelHeaderComponentState {
    isSelected: boolean;
    isDisabled: boolean;
}

@Component({
    selector: "dtm-web-app-lib-mission-risk-mitigation-level-header",
    templateUrl: "./risk-mitigation-level-header.component.html",
    styleUrls: ["./risk-mitigation-level-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RiskMitigationLevelHeaderComponent {
    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }
    @Output() public readonly isSelectedChange = new EventEmitter<boolean>();

    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    constructor(private readonly localStore: LocalComponentStore<RiskMitigationLevelHeaderComponentState>) {
        this.localStore.setState({
            isSelected: false,
            isDisabled: false,
        });
    }
}
