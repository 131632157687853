import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MIDDLE_PAGE_SIZE_VALUE, OperatorType, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import {
    MemberProfile,
    MembershipErrorType,
    MembershipMissionListQueryParams,
    MembershipMissionsFilter,
} from "../../../services/membership.models";
import { MembershipActions } from "../../../state/membership.actions";
import { MembershipState } from "../../../state/membership.state";

interface MembershipPilotProfileComponentState {
    filtersQuery: MembershipMissionsFilter;
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-membership-pilot-profile",
    templateUrl: "./membership-pilot-profile.component.html",
    styleUrls: ["./membership-pilot-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembershipPilotProfileComponent implements OnInit {
    protected readonly memberProfile$ = this.store.select(MembershipState.memberProfile);
    protected readonly missionsList$ = this.store.select(MembershipState.missionsList);
    protected readonly missionsListError$ = this.store.select(MembershipState.missionsListError);
    protected readonly isMissionsProcessing$ = this.store.select(MembershipState.isMissionsProcessing);
    protected readonly isMemberProfileProcessing$ = this.store.select(MembershipState.isMemberProfileProcessing);
    protected readonly memberProfileError$ = this.store.select(MembershipState.memberProfileError);
    protected readonly MembershipErrorType = MembershipErrorType;
    protected readonly capabilities$ = this.store.select(MembershipState.capabilities);
    protected readonly filtersQuery$ = this.localStore.selectByKey("filtersQuery");
    protected readonly missionsListPages$ = this.store.select(MembershipState.missionsListPages);
    protected readonly isSelectedContextEnterprise$ = this.store.select(OperatorContextState.selectedContext).pipe(
        RxjsUtils.filterFalsy(),
        map((context) => context.type === OperatorType.Enterprise)
    );
    protected readonly OperatorType = OperatorType;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<MembershipPilotProfileComponentState>
    ) {
        localStore.setState({
            filtersQuery: this.assignFiltersQuery(this.route.snapshot.queryParams),
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
        });
    }

    public ngOnInit() {
        this.handleContextChange();
        this.handleRouteParamsChange();
    }

    protected refreshPilotsProfile() {
        const membershipPilotId = this.route.snapshot.params.pilotId;

        if (!membershipPilotId) {
            return;
        }

        this.store.dispatch(new MembershipActions.GetPilotsProfile(membershipPilotId));
    }

    protected startMissionWizard(pilot: MemberProfile) {
        this.router.navigate(["mission/new"], {
            queryParams: {
                preferredPilotId: pilot.id,
            },
        });
    }

    protected refreshMissionsList() {
        const membershipOperatorId = this.route.snapshot.params.membershipOperatorId;

        if (!membershipOperatorId) {
            return;
        }

        this.store.dispatch(
            new MembershipActions.GetMissionsList({
                operatorId: membershipOperatorId,
                pilotId: this.route.snapshot.params.pilotId,
                filtersQuery: this.localStore.selectSnapshotByKey("filtersQuery"),
                size: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
                page: this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM),
            })
        );
    }

    protected changeFilters(formValue: MembershipMissionsFilter) {
        this.localStore.patchState({ filtersQuery: formValue });

        this.navigateByParams(true);
    }

    protected changePage(event: PageEvent) {
        this.localStore.patchState({
            [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event.pageSize,
        });

        this.navigateByParams();
    }

    private navigateByParams(shouldResetPage: boolean = false) {
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM);
        const { name, status, dateFrom, dateTo } = this.localStore.selectSnapshotByKey("filtersQuery");

        if (shouldResetPage) {
            this.localStore.patchState({ [PAGE_NUMBER_QUERY_PARAM]: pageIndex });
        }

        let params: MembershipMissionListQueryParams = {
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
        };

        if (name?.length) {
            params = { ...params, missionName: name };
        }

        if (status?.length) {
            params = { ...params, missionStatus: status.join(",") };
        }

        if (dateFrom) {
            params = { ...params, missionDateFrom: dateFrom };
        }

        if (dateTo) {
            params = { ...params, missionDateTo: dateTo };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private assignFiltersQuery(queryParams: Params): MembershipMissionsFilter {
        return {
            status: queryParams.missionStatus?.length ? queryParams?.missionStatus.split(",") : null,
            dateFrom: queryParams.missionDateFrom ?? null,
            dateTo: queryParams.missionDateTo ?? null,
            name: queryParams.missionName ?? null,
        };
    }

    private handleRouteParamsChange() {
        combineLatest([this.route.queryParams, this.route.params])
            .pipe(untilDestroyed(this))
            .subscribe(([queryParams, params]) => {
                this.localStore.patchState({
                    filtersQuery: this.assignFiltersQuery(queryParams),
                    [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
                    [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
                });

                this.refreshMissionsList();
            });
    }

    private handleContextChange() {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy())
            .pipe(untilDestroyed(this))
            .subscribe((selectedContext) => {
                if (selectedContext.type === OperatorType.Personal) {
                    return;
                }

                this.router.navigate(
                    [
                        "membership/pilot-profile",
                        this.route.snapshot.params.pilotId,
                        selectedContext.id,
                        this.route.snapshot.params.membershipId,
                    ],
                    {
                        replaceUrl: true,
                        queryParams: null,
                    }
                );
            });
    }
}
