import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { OperatorMigrationAuthenticationStepComponent } from "./components/operator-migration-authentication-step/operator-migration-authentication-step.component";
import { BasicCompanyInfoComponent } from "./components/operator-migration-details-step/basic-company-info/basic-company-info.component";
import { LegalGuardianInfoComponent } from "./components/operator-migration-details-step/legal-guardian-info/legal-guardian-info.component";
import { OperatorDetailsPreviewComponent } from "./components/operator-migration-details-step/operator-details-preview/operator-details-preview.component";
import { OperatorMigrationCompanyInfoComponent } from "./components/operator-migration-details-step/operator-migration-company-info/operator-migration-company-info.component";
import { OperatorMigrationDetailsStepComponent } from "./components/operator-migration-details-step/operator-migration-details-step.component";
import { OperatorMigrationPersonalOperatorInfoComponent } from "./components/operator-migration-details-step/operator-migration-personal-operator-info/operator-migration-personal-operator-info.component";
import { OperatorMigrationPilotInfoComponent } from "./components/operator-migration-details-step/operator-migration-pilot-info/operator-migration-pilot-info.component";
import { OperatorMigrationHeaderComponent } from "./components/operator-migration-header/operator-migration-header.component";
import { OperatorMigrationOperationalAuthorizationsComponent } from "./components/operator-migration-operational-authorizations/operator-migration-operational-authorizations.component";
import { OperatorMigrationResetPasswordStepComponent } from "./components/operator-migration-reset-password-step/operator-migration-reset-password-step.component";
import { CompanyDetailsSummaryComponent } from "./components/operator-migration-summary-step/company-details-summary/company-details-summary.component";
import { LegalGuardianSummaryComponent } from "./components/operator-migration-summary-step/legal-guardian-summary/legal-guardian-summary.component";
import { LegalOperatorSummaryComponent } from "./components/operator-migration-summary-step/legal-operator-summary/legal-operator-summary.component";
import { OperatorDetailsSummaryComponent } from "./components/operator-migration-summary-step/operator-details-summary/operator-details-summary.component";
import { OperatorMigrationSummaryStepComponent } from "./components/operator-migration-summary-step/operator-migration-summary-step.component";
import { PersonalOperatorSummaryComponent } from "./components/operator-migration-summary-step/personal-operator-summary/personal-operator-summary.component";
import { PilotSummaryComponent } from "./components/operator-migration-summary-step/pilot-summary/pilot-summary.component";
import { OperatorMigrationWizardContentComponent } from "./components/operator-migration-wizard-content/operator-migration-wizard-content.component";
import { OperatorMigrationRoutingModule } from "./operator-migration.routing.module";
import { FormatImportedPhoneNumberPipe } from "./pipes/format-imported-phone-number.pipe";
import { OperatorMigrationApiService } from "./services/operator-migration-api.service";
import { OperatorMigrationState } from "./state/operator-migration.state";

@NgModule({
    declarations: [
        OperatorMigrationWizardContentComponent,
        OperatorMigrationHeaderComponent,
        OperatorMigrationAuthenticationStepComponent,
        OperatorMigrationDetailsStepComponent,
        OperatorDetailsPreviewComponent,
        OperatorMigrationCompanyInfoComponent,
        OperatorMigrationSummaryStepComponent,
        LegalOperatorSummaryComponent,
        CompanyDetailsSummaryComponent,
        OperatorDetailsSummaryComponent,
        OperatorMigrationPersonalOperatorInfoComponent,
        BasicCompanyInfoComponent,
        PersonalOperatorSummaryComponent,
        OperatorMigrationOperationalAuthorizationsComponent,
        OperatorMigrationPilotInfoComponent,
        PilotSummaryComponent,
        LegalGuardianInfoComponent,
        LegalGuardianSummaryComponent,
        FormatImportedPhoneNumberPipe,
        OperatorMigrationResetPasswordStepComponent,
    ],
    imports: [
        CommonModule,
        NgxsModule.forFeature([OperatorMigrationState]),
        OperatorMigrationRoutingModule,
        WizardModule,
        TranslocoModule,
        SharedUiModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        NgxMaskModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibOperatorMigration",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        OperatorMigrationAuthenticationStepComponent,
        OperatorDetailsPreviewComponent,
        OperatorMigrationCompanyInfoComponent,
        CompanyDetailsSummaryComponent,
        LegalOperatorSummaryComponent,
        OperatorDetailsSummaryComponent,
        OperatorMigrationSummaryStepComponent,
        OperatorMigrationPersonalOperatorInfoComponent,
        PersonalOperatorSummaryComponent,
        OperatorMigrationPilotInfoComponent,
        PilotSummaryComponent,
        LegalGuardianInfoComponent,
        OperatorMigrationResetPasswordStepComponent,
    ],
})
export class OperatorMigrationModule {
    public static forRoot(): ModuleWithProviders<OperatorMigrationModule> {
        return {
            ngModule: OperatorMigrationModule,
            providers: [OperatorMigrationApiService],
        };
    }

    public static forTest(): ModuleWithProviders<OperatorMigrationModule> {
        return {
            ngModule: OperatorMigrationModule,
            providers: [
                {
                    provide: OperatorMigrationApiService,
                    useValue: null,
                },
            ],
        };
    }
}
