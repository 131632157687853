<ng-container
    *ngrxLet="{
        specificPermitDraftsList: specificPermitDraftsList$,
        isSpecificPermitsApplicationDraftListProcessing: isSpecificPermitDraftsListProcessing$,
        areSpecificPermitDraftsFiltersApplied: areSpecificPermitsDraftsFiltersApplied$,
        isSpecificPermitDraftsAvailable: areSpecificPermitsDraftsFiltersApplied$
    } as vm"
>
    <ng-container *ngIf="!(specificPermitApplicationDraftListError$ | ngrxPush); else errorTemplate">
        <ng-container
            *ngIf="
                vm.specificPermitDraftsList?.length ||
                    vm.areSpecificPermitDraftsFiltersApplied ||
                    vm.isSpecificPermitsApplicationDraftListProcessing;
                else specificPermitDraftsEmptyStateTemplate
            "
        >
            <dtm-web-app-lib-operator-specific-permit-drafts-filters
                class="filters"
                [incomingFilters]="specificPermitDraftsFilters$ | ngrxPush"
                (filtersChange)="specificPermitDraftsFiltersChange($event)"
            ></dtm-web-app-lib-operator-specific-permit-drafts-filters>
            <dtm-web-app-lib-operator-specific-permit-drafts-list
                [pagination]="specificPermitDraftsListPages$ | ngrxPush"
                [permitDrafts]="vm.specificPermitDraftsList"
                (pageChange)="specificPermitDraftsPageChange($event)"
                (draftRemove)="removeDraft($event)"
            ></dtm-web-app-lib-operator-specific-permit-drafts-list>
        </ng-container>
    </ng-container>
    <ng-template #errorTemplate>
        <dtm-ui-error [mode]="ErrorMode.Secondary"></dtm-ui-error>
    </ng-template>

    <ng-template #specificPermitDraftsEmptyStateTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
            <ng-container titleSlot>
                {{ "dtmWebAppLibOperatorPermits.permitsContainer.specificPermitDraftsEmptyState.title" | transloco }}
            </ng-container>
            <p messageSlot>
                {{ "dtmWebAppLibOperatorPermits.permitsContainer.specificPermitDraftsEmptyState.message" | transloco }}
            </p>
            <img alt="" imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
            <button
                *ngIf="vm.isSpecificPermitDraftsAvailable"
                footerSlot
                type="button"
                class="button-primary"
                (click)="navigateToSpecificPermits()"
            >
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibOperatorPermits.permitsContainer.specificPermitDraftsEmptyState.buttonLabel" | transloco }}
            </button>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>
