import { InjectionToken } from "@angular/core";

export interface OperatorPermissionsEndpoints {
    ownedPermissionsList: string;
    statementsList: string;
    capabilities: string;
    saveStatement: string;
    payForStatement: string;
    getSavedStatement: string;
    createPaymentRedirectUrl: string;
    createSignatureRedirectUrl: string;
    getUavDetailsForPermission: string;
    getStatementInPdf: string;
    uploadAdministrativeFeeFile: string;
    downloadAdministrativeFeeFile: string;
    getPermissionSignatureStatus: string;
}

export const OPERATOR_PERMISSIONS_ENDPOINTS = new InjectionToken<OperatorPermissionsEndpoints>("Operator Permissions endpoints");
