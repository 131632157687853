import { InjectionToken } from "@angular/core";

export interface MembershipEndpoints {
    getCapabilities: string;
    addInvitation: string;
    changeInvitationStatus: string;
    invitationManagement: string;
    getMembers: string;
    getPilotsProfile: string;
    changeMemberStatus: string;
    resendInvitation: string;
    resendInvitationToMember: string;
    removeMember: string;
    getMissionsList: string;
    membershipUserPermissions: string;
    invitedUserPermissions: string;
}

export const MEMBERSHIP_ENDPOINTS = new InjectionToken<MembershipEndpoints>("Membership endpoints");
