import { InjectionToken } from "@angular/core";

export interface OperatorRegistrationEndpoints {
    getOperatorRegistrationCapabilities: string;
    verifyForeignOperatorNumber: string;
    uploadIdentityFile: string;
    downloadIdentityFile: string;
    registerEnterpriseOperator: string;
    registerAssociationOperator: string;
    downloadAttorneyTemplate: string;
    uploadFeeConfirmation: string;
    downloadAttorneyPowerFile: string;
    uploadAttorneyPowerFile: string;
}

export const OPERATOR_REGISTRATION_ENDPOINTS = new InjectionToken<OperatorRegistrationEndpoints>("Operator registration endpoints");
