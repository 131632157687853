<ng-container *ngrxLet="areAvailableRoutesLoading$ as areAvailableRoutesLoading">
    <ng-container *ngrxLet="isProcessing$ as isProcessing">
        <dtm-web-app-lib-mission-wizard-step-wrapper
            [stepNumber]="3"
            [stepsAmount]="5"
            [title]="'dtmWebAppLibMission.missionWizardSteps.routeSelector.header' | transloco"
            class="step-wrapper"
            (next)="next.emit()"
            (back)="back.emit()"
            [isNextButtonEnabled]="!isProcessing && !areAvailableRoutesLoading"
            [nextButtonLabel]="'dtmWebAppLibMission.routeSelectorStep.nextButtonLabel' | transloco"
            [isLoading]="isProcessing || areAvailableRoutesLoading"
            [stepId]="MissionWizardSteps.RouteSelector"
            *ngrxLet="selectedRouteId$ as selectedRouteId"
        >
            <mat-tab-group *ngIf="routes$ | ngrxPush as routes" class="dtm-tabs header-tabs" color="accent" dynamicHeight>
                <mat-tab [label]="'dtmWebAppLibMission.routeSelectorStep.generatedRoutesTabLabel' | transloco">
                    <ng-container *ngrxLet="routes$ as routes">
                        <dtm-ui-select-field
                            class="field-container"
                            [isClearable]="false"
                            [ngModel]="sorting$ | ngrxPush"
                            (valueChange)="updateSorting($event)"
                        >
                            <label>{{ "dtmWebAppLibMission.routeSelectorStep.sortByLabel" | transloco }}</label>
                            <dtm-ui-select-option [value]="Sorting.SafetyDescending">
                                {{ "dtmWebAppLibMission.routeSelectorStep.sortingOptionsLabels.safety" | transloco : { order: "desc" } }}
                            </dtm-ui-select-option>
                            <dtm-ui-select-option [value]="Sorting.SafetyAscending">
                                {{ "dtmWebAppLibMission.routeSelectorStep.sortingOptionsLabels.safety" | transloco : { order: "asc" } }}
                            </dtm-ui-select-option>
                            <dtm-ui-select-option [value]="Sorting.DistanceDescending">
                                {{ "dtmWebAppLibMission.routeSelectorStep.sortingOptionsLabels.distance" | transloco : { order: "desc" } }}
                            </dtm-ui-select-option>
                            <dtm-ui-select-option [value]="Sorting.DistanceAscending">
                                {{ "dtmWebAppLibMission.routeSelectorStep.sortingOptionsLabels.distance" | transloco : { order: "asc" } }}
                            </dtm-ui-select-option>
                        </dtm-ui-select-field>
                        <dtm-ui-loader [isShown]="areAvailableRoutesLoading"> </dtm-ui-loader>
                        <ng-container *ngFor="let route of routes">
                            <div
                                class="selectable-tile"
                                [class.selected]="route.routeId === selectedRouteId"
                                [class.hovered]="route.routeId === (previewRouteId$ | ngrxPush)"
                                (click)="selectRoute(route.routeId)"
                                (keyup.enter)="selectRoute(route.routeId)"
                                (mouseenter)="selectPreviewRoute(route.routeId)"
                                (mouseleave)="selectPreviewRoute()"
                                (focusin)="selectPreviewRoute(route.routeId)"
                                (focusout)="selectPreviewRoute()"
                                [attr.data-routeId]="route.routeId"
                                #routeTile
                                tabindex="0"
                            >
                                <div class="header">
                                    <h5>
                                        {{ "dtmWebAppLibMission.routeSelectorStep.nameLabel" | transloco : { value: route.orderIndex } }}
                                    </h5>
                                    <dtm-ui-icon
                                        name="road-map"
                                        class="button-icon"
                                        (click)="$event.stopPropagation(); toggleZoom(route)"
                                        (keyup.enter)="$event.stopPropagation(); toggleZoom(route)"
                                        tabindex="0"
                                    ></dtm-ui-icon>
                                </div>
                                <div class="content">
                                    <dtm-ui-icon name="flight-takeoff" class="takeoff-icon"></dtm-ui-icon>
                                    <div class="takeoff-info">
                                        <h6>{{ "dtmWebAppLibMission.routeSelectorStep.takeoffLabel" | transloco }}</h6>
                                        <span *ngIf="route | invoke : getTakeoffTimeRange as takeoffTimeRange" class="time-info">
                                            {{ takeoffTimeRange?.min | localizeDate : { timeStyle: "short" } }}
                                            -
                                            {{ takeoffTimeRange?.max | localizeDate : { timeStyle: "short" } }}
                                        </span>
                                    </div>
                                    <div class="connector"></div>
                                    <div class="info">
                                        <dtm-ui-icon name="pin-distance"></dtm-ui-icon>
                                        <span>
                                            {{
                                                "dtmWebAppLibMission.routeSelectorStep.distanceLabel"
                                                    | transloco
                                                        : {
                                                              value:
                                                                  route
                                                                  | invoke : getDistance
                                                                  | localizeNumber : { maximumFractionDigits: 2 }
                                                          }
                                            }}
                                        </span>
                                        <ng-container *ngIf="route | invoke : getDuration as duration">
                                            <dtm-ui-icon name="time"></dtm-ui-icon>
                                            <span>
                                                {{ duration.start | datesDiff : duration.end }}
                                            </span>
                                        </ng-container>
                                    </div>
                                    <dtm-ui-icon name="flight-land" class="ladning-icon"></dtm-ui-icon>
                                    <div class="landing-info">
                                        <h6>{{ "dtmWebAppLibMission.routeSelectorStep.landingLabel" | transloco }}</h6>
                                        <span *ngIf="route | invoke : getLandingTimeRange as landingTimeRange" class="time-info">
                                            {{ landingTimeRange?.min | localizeDate : { timeStyle: "short" } }}
                                            -
                                            {{ landingTimeRange?.max | localizeDate : { timeStyle: "short" } }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #routeSideViewTemplate>
                            <dtm-mission-route-side-view
                                [route]="selectedRoute"
                                *ngIf="selectedRouteId | invoke : getRouteById : routes as selectedRoute"
                            >
                                <div headerSlot class="side-view-title">
                                    <h5>
                                        {{
                                            "dtmWebAppLibMission.routeSelectorStep.sideViewNameLabel"
                                                | transloco : { value: selectedRoute.orderIndex }
                                        }}
                                    </h5>
                                    <span>
                                        {{
                                            "dtmWebAppLibMission.routeSelectorStep.distanceLabel"
                                                | transloco
                                                    : {
                                                          value:
                                                              selectedRoute
                                                              | invoke : getDistance
                                                              | localizeNumber : { maximumFractionDigits: 2 }
                                                      }
                                        }},
                                    </span>
                                    <span *ngIf="selectedRoute | invoke : getDuration as duration">
                                        {{ duration.start | datesDiff : duration.end }}
                                    </span>
                                </div>
                            </dtm-mission-route-side-view>
                        </ng-template>
                    </ng-container>
                </mat-tab>

                <mat-tab [label]="'dtmWebAppLibMission.routeSelectorStep.routeDetailsTabLabel' | transloco">
                    <ng-content select="[itineraryPanel]"></ng-content>
                </mat-tab>
            </mat-tab-group>
        </dtm-web-app-lib-mission-wizard-step-wrapper>
    </ng-container>
</ng-container>
