import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SavedOperationScenario } from "../../../../services/operator-permissions.models";

interface SummaryPermissionComponentState {
    permission: SavedOperationScenario | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-permission-signature-permission[permission]",
    templateUrl: "./signature-permission.component.html",
    styleUrls: ["./signature-permission.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SignaturePermissionComponent {
    @Input() public set permission(value: SavedOperationScenario | undefined) {
        this.localStore.patchState({ permission: value });
    }

    protected readonly permission$ = this.localStore.selectByKey("permission").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<SummaryPermissionComponentState>) {
        localStore.setState({ permission: undefined });
    }
}
