import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ScrollHookDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Chapter, ChapterWithSubchapters, ContentEditData } from "../../../services/operations-manual.models";

interface SubchaptersComponentState {
    chapter: ChapterWithSubchapters | undefined;
    editedChapterId: string | null;
    areActionsBlocked: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-opsman-chapter-subchapters[chapter]",
    templateUrl: "./subchapters.component.html",
    styleUrls: ["../chapter.scss", "./subchapters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SubchaptersComponent {
    @Input() public set chapter(value: Chapter | undefined) {
        this.scrollHooksRegistry.clear();

        this.localStore.patchState({ chapter: value as ChapterWithSubchapters });
    }

    @Input() public set editedChapterId(value: string | null | undefined) {
        this.localStore.patchState({ editedChapterId: value ?? null });
    }

    @Input() public set areActionsBlocked(value: BooleanInput) {
        this.localStore.patchState({ areActionsBlocked: coerceBooleanProperty(value) });
    }

    @Output() public readonly editModeChange = new EventEmitter<string | null>();
    @Output() public readonly otherActionsBlock = new EventEmitter<boolean>();
    @Output() public readonly contentSave = new EventEmitter<ContentEditData>();
    @Output() public readonly attachmentsManage = new EventEmitter<Chapter>();
    @Output() public readonly guideShow = new EventEmitter<void>();

    protected readonly chapter$ = this.localStore.selectByKey("chapter").pipe(RxjsUtils.filterFalsy());
    protected readonly editedChapterId$ = this.localStore.selectByKey("editedChapterId");
    protected readonly areActionsBlocked$ = this.localStore.selectByKey("areActionsBlocked");
    private readonly scrollHooksRegistry = new Map<string, ScrollHookDirective>();

    constructor(private readonly localStore: LocalComponentStore<SubchaptersComponentState>) {
        this.localStore.setState({
            chapter: undefined,
            editedChapterId: null,
            areActionsBlocked: false,
        });
    }

    public scrollToSubchapter(subchapterId: string) {
        const scrollHook = this.scrollHooksRegistry.get(subchapterId);

        if (!scrollHook) {
            return;
        }

        this.highlightWhenVisible(scrollHook.getElement());
        scrollHook.scrollTo();
    }

    protected registerSubchapterScrollHook(hook: ScrollHookDirective, subchapterId: string): void {
        this.scrollHooksRegistry.set(subchapterId, hook);
    }

    private highlightSubchapter(subchapterElement: HTMLElement) {
        subchapterElement.classList.add("highlight");
        setTimeout(() => subchapterElement.classList.remove("highlight"), 1000);
    }

    private highlightWhenVisible(subchapterElement: HTMLElement): void {
        const intersectionObserver = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                this.highlightSubchapter(subchapterElement);
                intersectionObserver.unobserve(subchapterElement);
            }
        });

        intersectionObserver.observe(subchapterElement);
    }
}
