import { MapEntityType } from "@dtm-frontend/shared/map/cesium";
import { AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanRoute, Page, SearchControlOption } from "@dtm-frontend/shared/ui";
import { FormType } from "@dtm-frontend/shared/utils";
import { Feature, Geometry } from "@turf/helpers";
import { MissionPlanAnalysisStatus } from "../../shared/models/mission-plan-verification.models";
import {
    AuthorityAcceptation,
    FormalJustification,
    MissionPlanOperationCategoryOption,
    MissionProcessingPhase,
    MissionProcessingPhaseExtended,
    MissionType,
    PopulationDensity,
} from "../../shared/models/mission.models";

export enum MissionSearchErrorType {
    Unknown = "UavErrorUnknown",
    CannotGetMissions = "CannotGetMissions",
    CannotGetMission = "CannotGetMission",
    CannotGetMissionRoute = "CannotGetMissionRoute",
    CannotGetPublicMissionData = "CannotGetPublicMissionData",
}

export enum Sorting {
    StartDateAsc = "start_date_asc",
    StartDateDesc = "start_date_desc",
}

export enum FilterConditionSearchType {
    Operator = "OPERATOR",
    Pilot = "PILOT",
    Mission = "MISSION",
    Uav = "UAV",
    Zone = "ZONE",
}

export enum SearchArea {
    Country = "COUNTRY",
    Zone = "ZONE",
    Custom = "CUSTOM",
}

export const MAX_RESULTS_SIZE = 99;
export const DEFAULT_SORTING = Sorting.StartDateDesc;

export interface FilterConditions {
    operator: SearchControlOption | null;
    pilot: SearchControlOption | null;
    missionId: SearchControlOption | null;
    status: MissionProcessingPhaseExtended | null;
    uav: SearchControlOption | null;
    missionType: MissionType | null;
    fromDate: Date | null;
    toDate: Date | null;
    area: SearchArea | null;
    zone: SearchControlOption | null;
    customArea: AreaGeoJSON | null;
}

export interface Mission {
    id: string;
    flightStartAt: Date;
    flightStartAtMin?: Date;
    flightStartAtMax?: Date;
    flightFinishAt: Date;
    flightFinishAtMin?: Date;
    flightFinishAtMax?: Date;
    isPathBased: boolean;
    flightType: MissionType;
    status: MissionProcessingPhaseExtended;
    dtmNames: string[];
    operatorNumber: string;
    operatorName: string;
    pilotNumber: string;
    pilotName: string;
    uavName: string;
    uavSerialNumbers: string[];
}

export interface MissionWithRoute {
    id: string;
    route: MissionPlanRoute;
}

export type MissionFilterForm = FormType<FilterConditions>;

export interface FilterConditionSearchState {
    isProcessing: boolean;
    options: SearchControlOption[];
}

export interface FilterConditionSearchText {
    type: FilterConditionSearchType;
    text: string;
}

export interface CylinderAreaProperties {
    type: MapEntityType.Cylinder;
    center: [number, number];
    radius: number;
}

export interface PrismAreaProperties {
    type: MapEntityType.Prism;
    center?: [number, number];
}

export type AreaGeoJSON<P = CylinderAreaProperties | PrismAreaProperties> = Feature<Geometry, P>;

export interface MissionDetails {
    id: string;
    routeId: string;
    flightType: MissionType;
    status: MissionProcessingPhaseExtended;
    dtmNames: string[];
    flightStartAtMin: Date;
    flightStartAtMax: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    phase: MissionProcessingPhase;
    distance: number;
    operatorName: string;
    pilotName: string;
    uavName: string;
    uavSerialNumbers: string[];
    trackersIdentifiers: string[];
    category: MissionPlanOperationCategoryOption;
    isRoutePathBased: boolean;
    flightPurposeId: string | undefined;
    flightPurposeComment: string | undefined;
    flightRequirements: {
        zones: AirspaceElementsInfo | undefined;
        authorityAcceptation: AuthorityAcceptation | undefined;
        currentPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
        formalJustification: FormalJustification | undefined;
    };
    isSoraRequired: boolean;
    populationDensity: PopulationDensity | undefined;
}

export interface MissionSearchError {
    type: MissionSearchErrorType;
}

export interface Paging {
    size: number;
    page: number;
}

export interface MissionsListWithPages {
    pages: Page;
    content: Mission[];
}
