<dtm-ui-wizard-step-wrapper
    (next)="validateFormAndSave()"
    (back)="cancelPasswordReset()"
    [title]="'dtmWebAppLibOperatorMigration.resetPasswordStep.header' | transloco"
    [stepsAmount]="3"
    [stepNumber]="1"
    [isBackButtonVisible]="true"
    [nextButtonLabel]="'dtmWebAppLibOperatorMigration.resetPasswordStep.submitLabel' | transloco"
    dtmUiInvalidFormScrollable
>
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush" class="container">
        <p>{{ "dtmWebAppLibOperatorMigration.resetPasswordStep.temporaryPasswordInformationPartOne" | transloco }}</p>
        <p>{{ "dtmWebAppLibOperatorMigration.resetPasswordStep.temporaryPasswordInformationPartTwo" | transloco }}</p>
        <dtm-ui-input-field>
            <label>{{ "dtmWebAppLibOperatorMigration.resetPasswordStep.emailLabel" | transloco }}</label>
            <input [formControl]="emailControl" matInput type="text" />
            <div class="field-error" *dtmUiFieldHasError="emailControl; name: 'email'">
                {{ "dtmWebAppLibOperatorMigration.resetPasswordStep.incorrectEmailError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="emailControl; name: ['required', 'pattern']">
                {{ "dtmWebAppLibOperatorMigration.resetPasswordStep.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-input-field>
    </dtm-ui-loader-container>
</dtm-ui-wizard-step-wrapper>
