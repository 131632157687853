import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MissionType } from "@dtm-frontend/shared/mission";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { FlightPurpose, Operation } from "../../../services/specific-permit-application.models";

interface OperationInfoComponentState {
    operation: Operation | undefined;
    flightPurposes: FlightPurpose[];
    isExpanded: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-operation-info[operation][flightPurposes]",
    templateUrl: "./operation-info.component.html",
    styleUrls: ["../../common.scss", "./operation-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationInfoComponent {
    @Input() public set operation(value: Operation | undefined) {
        this.localStore.patchState({ operation: value });
    }

    @Input() public set flightPurposes(value: FlightPurpose[] | undefined) {
        this.localStore.patchState({ flightPurposes: value ?? [] });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly operation$ = this.localStore.selectByKey("operation").pipe(RxjsUtils.filterFalsy());
    protected readonly purposeName$ = combineLatest([this.operation$, this.localStore.selectByKey("flightPurposes")]).pipe(
        map(
            ([operation, flightPurposes]) =>
                flightPurposes.find((flightPurpose) => flightPurpose.codename === operation.flight.purposeCode)?.name ?? null
        )
    );
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    protected readonly MissionType = MissionType;

    constructor(private readonly localStore: LocalComponentStore<OperationInfoComponentState>) {
        this.localStore.setState({
            operation: undefined,
            flightPurposes: [],
            isExpanded: true,
        });
    }
}
