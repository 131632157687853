<ng-container
    *ngrxLet="{
        error: error$,
        collisionMission: collisionMissions$,
        currentPlanDataAndCapabilities: currentPlanDataAndCapabilities$,
        nearbyMissions: nearbyMissions$,
        operators: operators$,
        route: route$,
        collisionZones: collisionZones$,
        isProcessing: isProcessing$,
        selectedZoneId: selectedZoneId$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        routeData: routeData$,
        soraSettings: soraSettings$
    } as vm"
>
    <dtm-ui-error
        class="error"
        *ngIf="vm.error; else noErrorTemplate"
        [errorMessage]="'dtmWebAppLibMission.cannotGetMissionErrorMessage' | transloco"
        (reload)="reload()"
    ></dtm-ui-error>
    <ng-template #noErrorTemplate>
        <div class="mission-planner-container">
            <div class="mission-planner-data-container" *ngIf="vm.currentPlanDataAndCapabilities?.plan as missionPlan">
                <div class="content-container card-box scroll-shadows">
                    <h2 class="mission-name">
                        {{ missionPlan?.information?.name || ("dtmWebAppLibShared.missionTile.noNameTitle" | transloco) }}
                    </h2>
                    <!-- TODO replace it with <dtm-mission-basic-details> component - REJ-2976 -->
                    <dtm-web-app-lib-mission-basic-details
                        [missionPlan]="missionPlan"
                        [isDetailsMode]="true"
                        (mainMissionZoom)="flyToMainMissionRoute()"
                    ></dtm-web-app-lib-mission-basic-details>
                    <dtm-mission-flight-requirements
                        [zones]="vm.collisionZones"
                        [authorityAcceptation]="missionPlan.remarks.authorityAcceptation"
                        [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                        [isProcessing]="vm.isProcessing"
                        [selectedZoneId]="vm.selectedZoneId"
                        [formalJustification]="missionPlan.remarks.justification"
                        [missionContext]="MissionContextType.Operator"
                        [rejectionJustification]="vm.currentPlanDataAndCapabilities?.plan?.mission?.rejectionJustification"
                        [rejectionDate]="vm.currentPlanDataAndCapabilities?.plan?.mission?.rejectedAt"
                        [missionPhase]="missionPlan.phase"
                        (zoneSelect)="selectZone($event)"
                        (messageFromManagerReadingConfirmed)="messageAcknowledged()"
                    ></dtm-mission-flight-requirements>
                    <!-- TODO replace it with <dtm-mission-mission-details> component - REJ-2976 -->
                    <dtm-ui-expandable-panel>
                        <dtm-ui-expandable-simple-header
                            headerSlot
                            [label]="'dtmWebAppLibShared.mission.missionDataHeaderLabel' | transloco"
                        >
                        </dtm-ui-expandable-simple-header>
                        <dtm-mission-mission-data
                            [operators]="vm.operators"
                            [missionPlanDataAndCapabilities]="vm.currentPlanDataAndCapabilities"
                            [route]="vm.route"
                        ></dtm-mission-mission-data>
                    </dtm-ui-expandable-panel>

                    <!-- TODO replace it with <dtm-mission-traffic-analysis> component - REJ-2976 -->
                    <dtm-web-app-lib-traffic-analysis
                        *ngIf="vm.currentPlanAnalysisStatus?.traffic"
                        [nearbyMissions]="vm.nearbyMissions"
                        [collisionMission]="vm.collisionMission"
                        (flyToRoute)="flyToRouteById($event)"
                        (flyToMainMissionRoute)="flyToMainMissionRoute()"
                        (otherMissionSelected)="handleOtherMissionSelection($event)"
                    ></dtm-web-app-lib-traffic-analysis>
                    <dtm-ui-expandable-panel>
                        <dtm-ui-expandable-simple-header
                            headerSlot
                            [label]="'dtmWebAppLibShared.mission.routeDetailsHeaderLabel' | transloco"
                        >
                        </dtm-ui-expandable-simple-header>
                        <dtm-ui-route-details [route]="vm.route"></dtm-ui-route-details>
                    </dtm-ui-expandable-panel>
                    <ng-container *ngIf="vm.currentPlanAnalysisStatus && vm.currentPlanAnalysisStatus.sora">
                        <!-- TODO replace it with <dtm-mission-operational-area-geometry> component - REJ-2976 -->
                        <dtm-web-app-lib-operational-area-geometry
                            [operationalGeometryData]="operationalGeometryData$ | ngrxPush"
                        ></dtm-web-app-lib-operational-area-geometry>
                        <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="false">
                            <dtm-ui-expandable-simple-header
                                headerSlot
                                [label]="'dtmWebAppLibShared.mission.analysis.groundRiskTitle' | transloco"
                            >
                            </dtm-ui-expandable-simple-header>
                            <dtm-mission-population-density
                                [populationDensity]="vm.currentPlanAnalysisStatus.sora.result?.populationDensity"
                            ></dtm-mission-population-density>
                        </dtm-ui-expandable-panel>

                        <!-- TODO replace it with <dtm-mission-operational-area-airspace-risk> component - REJ-2976 -->
                        <dtm-web-app-lib-operational-area-airspace-risk
                            [airRiskMitigations]="vm.soraSettings?.airRiskMitigations"
                            [airRisk]="vm.currentPlanAnalysisStatus.sora.result?.airRisk"
                        ></dtm-web-app-lib-operational-area-airspace-risk>
                        <!-- TODO replace it with <dtm-mission-initial-sora-issues> component - REJ-2976 -->
                        <dtm-web-app-lib-initial-sora-issues
                            [soraSettings]="vm.soraSettings"
                            [soraResult]="vm.currentPlanAnalysisStatus.sora.result"
                            [airspaceAnalysis]="vm.currentPlanAnalysisStatus.airspace"
                            [isExpanded]="false"
                        >
                        </dtm-web-app-lib-initial-sora-issues>
                        <!-- TODO replace it with <dtm-mission-risk-mitigation-measures> component - REJ-2976 -->
                        <dtm-web-app-lib-analysis-sora-settings
                            *ngIf="vm.soraSettings?.riskMitigations"
                            [airRiskInfoData]="vm.currentPlanAnalysisStatus.sora.result?.airRisk"
                            [populationDensity]="vm.currentPlanAnalysisStatus.sora.result?.populationDensity"
                            [criticalArea]="vm.currentPlanAnalysisStatus.sora.result?.criticalArea"
                            [soraSettings]="vm.soraSettings"
                            [missionType]="vm.currentPlanDataAndCapabilities?.plan?.flightType"
                        ></dtm-web-app-lib-analysis-sora-settings>
                        <!-- TODO replace it with <dtm-mission-tactical-risk-mitigation-measures> component - REJ-2976 -->
                        <dtm-web-app-lib-tactical-risk-mitigation
                            *ngIf="
                                vm.currentPlanDataAndCapabilities?.plan?.flightType === MissionType.BVLOS &&
                                !!vm.currentPlanAnalysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty &&
                                vm.currentPlanAnalysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty !==
                                    TacticalMitigationPerformanceRequirementProperty.VLOS
                            "
                            [tacticalMitigationPerformanceRequirementProperty]="
                                vm.currentPlanAnalysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty
                            "
                            [tacticalMitigationPerformanceRequirements]="vm.soraSettings?.tmpr"
                        ></dtm-web-app-lib-tactical-risk-mitigation>
                        <!-- TODO replace it with <dtm-mission-final-sora-issues> component - REJ-2976 -->
                        <dtm-web-app-lib-final-sora-issues
                            [soraResult]="vm.currentPlanAnalysisStatus.sora.result"
                        ></dtm-web-app-lib-final-sora-issues>
                        <!-- TODO replace it with <dtm-mission-adjacent-area-and-space> component - REJ-2976 -->
                        <dtm-web-app-lib-adjacent-area-and-space
                            *ngIf="vm.currentPlanAnalysisStatus.sora"
                            [soraResult]="vm.currentPlanAnalysisStatus.sora.result"
                            [airspaceAnalysis]="vm.currentPlanAnalysisStatus.airspace"
                        ></dtm-web-app-lib-adjacent-area-and-space>
                    </ng-container>
                </div>
            </div>
            <div class="mission-planner-map-container">
                <div class="map-container">
                    <ac-map [sceneMode]="SceneMode.SCENE3D" *ngIf="initialViewBox$ | ngrxPush" dtmMapPointerManager>
                        <dtm-web-app-lib-height-violation-alert-container></dtm-web-app-lib-height-violation-alert-container>
                        <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
                        <dtm-map-route-viewer-layer
                            [routeData]="vm.routeData"
                            [drawableFeatures]="routeDrawableFeature"
                            [nearbyMissionsDrawableFeatures]="nearbyMissionsRouteDrawableFeature"
                            [isShown]="true"
                            (entityZoom)="flyToGeometry($event)"
                        ></dtm-map-route-viewer-layer>
                        <div class="layers-controls">
                            <dtm-map-layers-with-controls
                                isGeoZonesFiltersFullMode
                                [missionTimeRange]="missionTimeRange$ | ngrxPush"
                                [shouldShowAreaBuffers]="vm.currentPlanDataAndCapabilities?.plan?.category?.type !== MissionCategory.Open"
                                [routes]="vm.routeData | invoke : getRoutesFromRouteData"
                                [timeSettingOptions]="{ available: [ZoneTimesSetting.MissionTime], default: ZoneTimesSetting.MissionTime }"
                            ></dtm-map-layers-with-controls>
                        </div>
                    </ac-map>
                    <dtm-map-controls></dtm-map-controls>
                </div>
                <dtm-mission-route-side-view
                    [route]="vm.route"
                    [isSimpleMode]="vm.currentPlanDataAndCapabilities?.plan?.itinerary?.editorType === ItineraryEditorType.Standard"
                    [areBuffersVisible]="vm.currentPlanDataAndCapabilities?.plan?.flightType === MissionType.BVLOS"
                    (expandedChange)="heightViolationDialogLink.isExpanded = $event"
                >
                    <div headerSlot class="side-view-title">
                        <h2>{{ "dtmWebAppLibMission.routeSideView.sideViewHeader" | transloco }}</h2>
                        <dtm-web-app-lib-height-violation-alert-dialog-link
                            #heightViolationDialogLink
                            [route]="vm.route"
                        ></dtm-web-app-lib-height-violation-alert-dialog-link>
                    </div>
                </dtm-mission-route-side-view>
            </div>
        </div>
    </ng-template>
</ng-container>
