import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { OperatorType, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MembershipCapabilities, MembershipError, MembershipMission, MembershipMissionsFilter } from "../../../services/membership.models";

interface MembershipPilotMissionsComponentState {
    missionsList: MembershipMission[];
    isMissionsProcessing: boolean;
    missionsListError: MembershipError | undefined;
    capabilities: MembershipCapabilities | undefined;
    incomingFilters: MembershipMissionsFilter | undefined;
    pagination: Page | undefined;
    operatorType: OperatorType | undefined;
}

@Component({
    selector: "dtm-web-app-lib-membership-missions[missionsList][capabilities][operatorType]",
    templateUrl: "./membership-missions.component.html",
    styleUrls: ["./membership-missions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembershipMissionsComponent {
    @Input() public set missionsList(value: MembershipMission[] | undefined) {
        this.localStore.patchState({ missionsList: value ?? [] });
    }
    @Input() public set isMissionsProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isMissionsProcessing: !!value });
    }
    @Input() public set missionsListError(value: MembershipError | undefined) {
        this.localStore.patchState({ missionsListError: value });
    }
    @Input() public set capabilities(value: MembershipCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }
    @Input() public set incomingFilters(value: MembershipMissionsFilter | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }
    @Input() public set operatorType(value: OperatorType | undefined) {
        this.localStore.patchState({ operatorType: value });
    }

    @Output() public missionWizardStart = new EventEmitter<void>();
    @Output() public missionsListRefresh = new EventEmitter<void>();
    @Output() public filtersChange = new EventEmitter<MembershipMissionsFilter>();
    @Output() public pageChange = new EventEmitter<PageEvent>();

    protected readonly missionsList$ = this.localStore.selectByKey("missionsList");
    protected readonly isMissionsProcessing$ = this.localStore.selectByKey("isMissionsProcessing");
    protected readonly missionsListError$ = this.localStore.selectByKey("missionsListError");
    protected readonly capabilities$ = this.localStore.selectByKey("capabilities");
    protected readonly incomingFilters$ = this.localStore.selectByKey("incomingFilters");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly operatorType$ = this.localStore.selectByKey("operatorType").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<MembershipPilotMissionsComponentState>) {
        localStore.setState({
            missionsList: [],
            isMissionsProcessing: false,
            missionsListError: undefined,
            capabilities: undefined,
            incomingFilters: undefined,
            pagination: undefined,
            operatorType: undefined,
        });
    }
}
