import { Injectable } from "@angular/core";
import { ConversationCategoryCode, MessagesError, OperatorsThread, ReceivedMessage, StoredAvatarPictures } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, lastValueFrom, tap } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { OperatorConversationsApiService } from "../services/operator-conversations-api.service";
import { ThreadsError } from "../services/operator-conversations.model";
import { OperatorConversationsActions } from "./operator-conversations.actions";

export interface OperatorConversationsStateModel {
    threadsList: OperatorsThread[] | undefined;
    threadsListError: ThreadsError | undefined;
    selectedContextId: string | undefined;
    changeThreadError: ThreadsError | undefined;
    messages: ReceivedMessage[] | undefined;
    messagesError: MessagesError | undefined;
    isMessagesProcessing: boolean | undefined;
    isThreadsProcessing: boolean;
    conversationCategories: ConversationCategoryCode[] | undefined;
    capabilitiesError: ThreadsError | undefined;
    sendMessageStatusError: ThreadsError | undefined;
    storedAvatarPictures: StoredAvatarPictures;
}

const defaultState: OperatorConversationsStateModel = {
    threadsList: undefined,
    threadsListError: undefined,
    selectedContextId: undefined,
    changeThreadError: undefined,
    messages: undefined,
    messagesError: undefined,
    isMessagesProcessing: undefined,
    isThreadsProcessing: false,
    conversationCategories: undefined,
    capabilitiesError: undefined,
    sendMessageStatusError: undefined,
    storedAvatarPictures: {},
};

@State<OperatorConversationsStateModel>({
    name: "operatorConversations",
    defaults: defaultState,
})
@Injectable()
export class OperatorConversationsState {
    @Selector()
    public static threadsList(state: OperatorConversationsStateModel): OperatorsThread[] | undefined {
        return state.threadsList;
    }

    @Selector()
    public static threadsListError(state: OperatorConversationsStateModel): ThreadsError | undefined {
        return state.threadsListError;
    }

    @Selector()
    public static messagesList(state: OperatorConversationsStateModel): ReceivedMessage[] | undefined {
        return state.messages;
    }

    @Selector()
    public static messagesListError(state: OperatorConversationsStateModel): MessagesError | undefined {
        return state.messagesError;
    }

    @Selector()
    public static isMessagesProcessing(state: OperatorConversationsStateModel): boolean | undefined {
        return state.isMessagesProcessing;
    }

    @Selector()
    public static isThreadsProcessing(state: OperatorConversationsStateModel): boolean {
        return state.isThreadsProcessing;
    }

    @Selector()
    public static conversationCategories(state: OperatorConversationsStateModel): ConversationCategoryCode[] | undefined {
        return state.conversationCategories;
    }

    @Selector()
    public static capabilitiesError(state: OperatorConversationsStateModel): ThreadsError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static sendMessageStatusError(state: OperatorConversationsStateModel): ThreadsError | undefined {
        return state.sendMessageStatusError;
    }

    @Selector()
    public static storedAvatarPictures(state: OperatorConversationsStateModel): StoredAvatarPictures {
        return state.storedAvatarPictures;
    }

    constructor(private readonly operatorConversationsApiService: OperatorConversationsApiService) {}

    @Action(OperatorConversationsActions.GetThreadsList)
    public getThreadsList(context: StateContext<OperatorConversationsStateModel>, action: OperatorConversationsActions.GetThreadsList) {
        context.patchState({ threadsList: undefined, isThreadsProcessing: true, threadsListError: undefined });

        return this.operatorConversationsApiService.getThreadsList(action.params).pipe(
            map((threadsList: OperatorsThread[]) => {
                context.patchState({
                    threadsList,
                    selectedContextId: action.params.selectedOperatorContextId,
                    isThreadsProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    threadsListError: error,
                    isThreadsProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorConversationsActions.MarkAsRead)
    public markAsRead(context: StateContext<OperatorConversationsStateModel>, action: OperatorConversationsActions.MarkAsRead) {
        const selectedThread = context.getState().threadsList?.find((thread: OperatorsThread) => thread.id === action.threadId);

        if (!selectedThread || selectedThread.isRead) {
            return;
        }

        context.dispatch(
            new OperatorConversationsActions.ChangeThread({ isClosed: selectedThread.isClosed, isRead: true }, action.threadId)
        );
    }

    @Action(OperatorConversationsActions.ChangeThread)
    public changeThread(context: StateContext<OperatorConversationsStateModel>, action: OperatorConversationsActions.ChangeThread) {
        context.patchState({ changeThreadError: undefined });

        return this.operatorConversationsApiService.changeThread(action.threadId, action.payload).pipe(
            tap(() => {
                const operatorId = context.getState().selectedContextId;

                if (!operatorId) {
                    return;
                }

                if (!context.getState().threadsList?.length) {
                    return;
                }

                const threadList = [...(context.getState().threadsList ?? [])];
                const updatedListItemIndex = threadList.findIndex((listItem) => listItem.id === action.threadId);
                threadList[updatedListItemIndex] = {
                    ...threadList[updatedListItemIndex],
                    isRead: action.payload.isRead,
                    isClosed: action.payload.isClosed,
                };
                context.patchState({ threadsList: threadList });
            }),
            catchError((error) => {
                context.patchState({ changeThreadError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperatorConversationsActions.GetMessagesByThread)
    public getMessagesByThread(
        context: StateContext<OperatorConversationsStateModel>,
        action: OperatorConversationsActions.GetMessagesByThread
    ) {
        const threadId: string | undefined = action.threadId;
        context.patchState({ isMessagesProcessing: true });

        if (!threadId) {
            return context.patchState({ messages: undefined, isMessagesProcessing: false });
        }

        return this.operatorConversationsApiService.getMessagesByThread(threadId).pipe(
            tap((result) => {
                context.patchState({
                    messages: result,
                    messagesError: undefined,
                });

                context.dispatch(new OperatorConversationsActions.MarkAsRead(threadId));
                context.patchState({ isMessagesProcessing: false });

                return result;
            }),
            tap((messages: ReceivedMessage[]) => {
                const storedAvatarPictures = context.getState().storedAvatarPictures;

                const uniqueAvatarUrls = [...new Set(messages.map((item) => item.sender.avatarUrl))];
                uniqueAvatarUrls.forEach(async (avatarUrl) => {
                    if (storedAvatarPictures[avatarUrl]) {
                        return;
                    }

                    return await lastValueFrom(
                        this.operatorConversationsApiService.getAvatarPicture(avatarUrl).pipe(
                            tap((avatar) => {
                                const currentlyStoredAvatarPictures = context.getState().storedAvatarPictures;

                                context.patchState({
                                    storedAvatarPictures: { ...currentlyStoredAvatarPictures, [avatarUrl]: avatar },
                                });
                            })
                        )
                    );
                });
            }),
            catchError((error) => {
                context.patchState({ messages: undefined, messagesError: error, isMessagesProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorConversationsActions.GetOperatorMessageCapabilities)
    public getOperatorMessageCapabilities(
        context: StateContext<OperatorConversationsStateModel>,
        action: OperatorConversationsActions.GetOperatorMessageCapabilities
    ) {
        if (context.getState().conversationCategories) {
            return EMPTY;
        }

        return this.operatorConversationsApiService.getOperatorMessageCapabilities(action.operatorId).pipe(
            tap((result) => {
                context.patchState({
                    conversationCategories: result,
                    capabilitiesError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ conversationCategories: undefined, capabilitiesError: error });

                return EMPTY;
            })
        );
    }
    @Action(OperatorConversationsActions.CreateNewThread)
    public createNewThread(context: StateContext<OperatorConversationsStateModel>, action: OperatorConversationsActions.CreateNewThread) {
        context.patchState({ sendMessageStatusError: undefined });

        return this.operatorConversationsApiService.createNewThread(action.newThread).pipe(
            tap(() => {
                context.patchState({ sendMessageStatusError: undefined });

                context.dispatch(
                    new OperatorConversationsActions.GetThreadsList({ selectedOperatorContextId: action.newThread.sender.id })
                );
            }),
            catchError((error) => {
                context.patchState({
                    sendMessageStatusError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorConversationsActions.AddNewMessageToThread)
    public addNewMessageToThread(
        context: StateContext<OperatorConversationsStateModel>,
        action: OperatorConversationsActions.AddNewMessageToThread
    ) {
        context.patchState({ sendMessageStatusError: undefined });

        return this.operatorConversationsApiService.addNewMessageToThread(action.message).pipe(
            tap(() => {
                context.patchState({ sendMessageStatusError: undefined });
            }),
            catchError((error) => {
                context.patchState({
                    sendMessageStatusError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorConversationsActions.ClearConversations)
    public clearConversations(context: StateContext<OperatorConversationsStateModel>) {
        context.patchState(defaultState);

        return EMPTY;
    }
}
