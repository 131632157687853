import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface UavPropertyBooleanComponentState {
    description: string | undefined;
    label: string | undefined;
    value: boolean | undefined;
}

@Component({
    selector: "dtm-web-app-lib-uav-property-boolean[label][value]",
    templateUrl: "./uav-property-boolean.component.html",
    styleUrls: ["./uav-property-boolean.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavPropertyBooleanComponent {
    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly description$ = this.localStore.selectByKey("description");
    protected readonly value$ = this.localStore.selectByKey("value");

    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set description(value: string | undefined) {
        this.localStore.patchState({ description: value });
    }

    @Input() public set value(value: boolean | null | undefined) {
        this.localStore.patchState({ value: value ?? undefined });
    }

    constructor(private readonly localStore: LocalComponentStore<UavPropertyBooleanComponentState>) {
        this.localStore.setState({
            description: undefined,
            label: undefined,
            value: undefined,
        });
    }
}
