import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import {
    DeviceSize,
    DeviceSizeService,
    RouteDataService,
    SwipeDirection,
    TouchScreenService,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { GlobalOutlets, OperatorContextActions, OperatorContextState, UserContextService } from "@dtm-frontend/web-app-lib/shared";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { combineLatestWith, filter, map, switchMap } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    protected readonly isRequiredToAcceptTermsOfService$ = this.store.select(OperatorContextState.isRequiredToAcceptTermsOfService);
    protected readonly isSidebarCollapsed$ = this.store.select(UIState.isMenuCollapsed).pipe(
        combineLatestWith(this.isRequiredToAcceptTermsOfService$),
        map(([isSidebarCollapsed, isRequiredToAcceptTermsOfService]) => isSidebarCollapsed || isRequiredToAcceptTermsOfService)
    );
    protected readonly GlobalOutlets = GlobalOutlets;
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly routeOutlet$ = this.routeDataService.currentRouteOutlet$;
    protected readonly className$ = this.routeDataService.routeData$.pipe(map((routeData) => routeData?.className));
    protected readonly isProcessing$ = this.store.select(OperatorContextState.isProcessing);

    constructor(
        private readonly store: Store,
        private readonly touchScreenService: TouchScreenService,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly routeDataService: RouteDataService,
        private readonly toastr: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly userContextService: UserContextService
    ) {}

    public ngOnInit(): void {
        this.observeOnDeviceResize();
        this.observeOnSwipe();
        this.userContextService.init();
    }

    public toggleSidebar() {
        this.setMenuCollapsedState(!this.store.selectSnapshot(UIState.isMenuCollapsed));
    }

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.Smartphone, DeviceSize.SmartphoneWide)) {
            this.setMenuCollapsedState(true);
        }
    }

    protected acceptTerms() {
        const userId = this.store.selectSnapshot(AuthState.userId);

        if (!userId) {
            return;
        }

        this.store
            .dispatch(new OperatorContextActions.AcceptTermsOfService(userId))
            .pipe(
                switchMap(() => this.store.select(OperatorContextState.acceptTermsOfServiceError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (error) {
                    this.toastr.error(this.transloco.translate("webApp.errors.termsOfServiceAcceptanceError"));

                    return;
                }

                this.store.dispatch(OperatorContextActions.GetGlobalCapabilities);
            });
    }

    protected logout() {
        this.store.dispatch(new AuthActions.Logout());
    }

    private observeOnDeviceResize() {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide)
            .pipe(untilDestroyed(this))
            .subscribe((isDesktopDevice) => {
                this.setMenuCollapsedState(!isDesktopDevice);
            });
    }

    private observeOnSwipe() {
        this.touchScreenService.swipe$
            .pipe(
                filter(() => this.deviceSizeService.isSize(DeviceSize.Smartphone, DeviceSize.SmartphoneWide)),
                untilDestroyed(this)
            )
            .subscribe((direction: SwipeDirection) => {
                if (direction === SwipeDirection.LEFT) {
                    this.setMenuCollapsedState(true);
                } else if (direction === SwipeDirection.RIGHT) {
                    this.setMenuCollapsedState(false);
                }
            });
    }

    private setMenuCollapsedState(isCollapsed: boolean) {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(isCollapsed));
    }
}
