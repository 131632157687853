import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, TranslationHelperService, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { SharedWebAppModule } from "../../modules/shared";
import { RegisterOperatorAdditionalDataComponent } from "./operator-registration/components/register-operator-additional-data/register-operator-additional-data.component";
import { RegisterOperatorBasicDataComponent } from "./operator-registration/components/register-operator-basic-data/register-operator-basic-data.component";
import { RegisterOperatorSummaryComponent } from "./operator-registration/components/register-operator-summary/register-operator-summary.component";
import { RegisterOperatorWizardContentComponent } from "./operator-registration/components/register-operator-wizard-content/register-operator-wizard-content.component";
import { RegisterOperatorWizardHeaderComponent } from "./operator-registration/components/register-operator-wizard-header/register-operator-wizard-header.component";
import { OperatorRegistrationAttorneyFeeConfirmationUploadDirective } from "./operator-registration/directives/attorney-confirmation-fee-document-upload.directive";
import { OPERATOR_REGISTRATION_ENDPOINTS } from "./operator-registration/operator-registration.tokens";
import { OperatorRegistrationApiService } from "./operator-registration/services/operator-registration-api.service";
import { OperatorRegistrationState } from "./operator-registration/state/operator-registration.state";
import { PilotOperatorRegistrationRoutingModule } from "./pilot-operator-registration-routing.module";
import { PilotRegistrationSummaryComponent } from "./pilot-registration/components/pilot-registration-summary/pilot-registration-summary.component";
import { RegisterPilotAdditionalDataComponent } from "./pilot-registration/components/register-pilot-additional-data/register-pilot-additional-data.component";
import { RegisterPilotBasicDataComponent } from "./pilot-registration/components/register-pilot-basic-data/register-pilot-basic-data.component";
import { RegisterPilotWizardContentComponent } from "./pilot-registration/components/register-pilot-wizard-content/register-pilot-wizard-content.component";
import { RegisterPilotWizardHeaderComponent } from "./pilot-registration/components/register-pilot-wizard-header/register-pilot-wizard-header.component";
import { PilotRegistrationApiService } from "./pilot-registration/services/pilot-registration-api.service";
import { PilotRegistrationState } from "./pilot-registration/state/pilot-registration.state";
import { ForeignOperatorValidationFieldsComponent } from "./shared/components/foreign-operator-validation-fields/foreign-operator-validation-fields.component";
import { PilotOperatorRegistrationInsuranceFormComponent } from "./shared/components/pilot-operator-registration-insurance-form/pilot-operator-registration-insurance-form.component";
import { PilotOperatorRegistrationLegalGuardianFormComponent } from "./shared/components/pilot-operator-registration-legal-guardian-form/pilot-operator-registration-legal-guardian-form.component";
import { PilotOperatorRegistrationAddressSummaryComponent } from "./shared/components/summary/pilot-operator-registration-address-summary/pilot-operator-registration-address-summary.component";
import { PilotOperatorRegistrationInsuranceSummaryComponent } from "./shared/components/summary/pilot-operator-registration-insurance-summary/pilot-operator-registration-insurance-summary.component";
import { PilotOperatorRegistrationLegalGuardianSummaryComponent } from "./shared/components/summary/pilot-operator-registration-legal-guardian-summary/pilot-operator-registration-legal-guardian-summary.component";

@NgModule({
    declarations: [
        RegisterPilotWizardHeaderComponent,
        RegisterPilotWizardContentComponent,
        RegisterPilotBasicDataComponent,
        RegisterOperatorWizardContentComponent,
        RegisterOperatorWizardHeaderComponent,
        RegisterOperatorBasicDataComponent,
        RegisterOperatorAdditionalDataComponent,
        RegisterOperatorSummaryComponent,
        ForeignOperatorValidationFieldsComponent,
        PilotOperatorRegistrationLegalGuardianFormComponent,
        RegisterPilotAdditionalDataComponent,
        PilotOperatorRegistrationInsuranceFormComponent,
        PilotRegistrationSummaryComponent,
        PilotOperatorRegistrationAddressSummaryComponent,
        PilotOperatorRegistrationLegalGuardianSummaryComponent,
        PilotOperatorRegistrationInsuranceSummaryComponent,
        OperatorRegistrationAttorneyFeeConfirmationUploadDirective,
    ],
    imports: [
        CommonModule,
        PilotOperatorRegistrationRoutingModule,
        WizardModule,
        TranslocoModule,
        LetModule,
        PushModule,
        SharedUiModule,
        NgxsModule.forFeature([PilotRegistrationState, OperatorRegistrationState]),
        SharedToastModule.forRoot(),
        SharedWebAppModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        MatInputModule,
        SharedI18nModule,
        MatDatepickerModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibPilotOperatorRegistration",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: PilotRegistrationApiService,
            useValue: undefined,
        },
        {
            provide: OperatorRegistrationApiService,
            useValue: undefined,
        },
        TranslationHelperService,
    ],
    exports: [
        RegisterOperatorBasicDataComponent,
        RegisterOperatorAdditionalDataComponent,
        RegisterOperatorSummaryComponent,
        RegisterPilotAdditionalDataComponent,
        RegisterPilotBasicDataComponent,
        PilotRegistrationSummaryComponent,
        PilotOperatorRegistrationAddressSummaryComponent,
        PilotOperatorRegistrationLegalGuardianSummaryComponent,
        PilotOperatorRegistrationInsuranceSummaryComponent,
    ],
})
export class PilotOperatorRegistrationModule {
    public static forRoot(): ModuleWithProviders<PilotOperatorRegistrationModule> {
        return {
            ngModule: PilotOperatorRegistrationModule,
            providers: [
                {
                    provide: PilotRegistrationApiService,
                    useClass: PilotRegistrationApiService,
                },
                {
                    provide: OperatorRegistrationApiService,
                    useClass: OperatorRegistrationApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<PilotOperatorRegistrationModule> {
        class FilesUploadApiServiceMock {
            public uploadFile() {}
            public getFile() {}
            public getFilesCompressed() {}
            public manageUploadError() {}
        }

        return {
            ngModule: PilotOperatorRegistrationModule,
            providers: [
                {
                    provide: PilotRegistrationApiService,
                    useValue: null,
                },
                {
                    provide: OperatorRegistrationApiService,
                    useValue: null,
                },

                { provide: FILES_UPLOAD_API_PROVIDER, useClass: FilesUploadApiServiceMock },
                {
                    provide: OPERATOR_REGISTRATION_ENDPOINTS,
                    useValue: { uploadPassportFile: "", uploadIdFile: "", downloadIdentityFile: "" },
                },
            ],
        };
    }
}
