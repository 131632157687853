import { Address } from "@dtm-frontend/shared/ui";
import { OperatorInsurancePolicy } from "../../shared/models/pilot-and-operator.models";
import { ForeignCompetencyDocumentEditOrAdd, PansaUtmCredentials, PansaUtmLink } from "../services/pilot-profile.models";

export namespace PilotProfileActions {
    export class GetPilotProfile {
        public static readonly type = "[Pilot profile] Get pilot profile data";
        constructor(public pilotId: string) {}
    }

    export class EditPilotInsurancePolicy {
        public static readonly type = "[Pilot profile] Edit pilot insurance policy";
        constructor(public policy: OperatorInsurancePolicy) {}
    }

    export class DeleteInsurancePolicy {
        public static readonly type = "[Pilot Profile] Delete pilot insurance policy";
    }

    export class EditPilotAddress {
        public static readonly type = "[Pilot profile] Edit pilot address";
        constructor(public address: Address) {}
    }

    export class DownloadRegistrationConfirmationDocument {
        public static readonly type = "[Pilot profile] Download registration confirmation document";
        constructor(public fileName: string) {}
    }

    export class DownloadConfirmationCompletingA1A3Document {
        public static readonly type = "[Pilot profile] Download confirmation completing A1 A3 document";
        constructor(public fileName: string) {}
    }

    export class DownloadCertificateA2Document {
        public static readonly type = "[Pilot profile] Download certificate A2 document";
        constructor(public fileName: string) {}
    }

    export class DownloadCertificateStsDocument {
        public static readonly type = "[Pilot profile] Download certificate STS document";
        constructor(public fileName: string) {}
    }

    export class AddPansaUtmLink {
        public static readonly type = "[Pilot Profile] Add Pansa UTM link";
        constructor(public credentials: PansaUtmCredentials, public existingLink?: PansaUtmLink, public pilotId?: string) {}
    }

    export class DeletePansaUtmLink {
        public static readonly type = "[Pilot Profile] Delete Pansa UTM link";
    }

    export class AddOrEditForeignCompetency {
        public static readonly type = "[Pilot Profile] Add or edit foreign competency";
        constructor(public competency: ForeignCompetencyDocumentEditOrAdd) {}
    }

    export class DeleteForeignCompetency {
        public static readonly type = "[Pilot Profile] Delete foreign competency";
        constructor(public competencyId: string) {}
    }
}
