<ng-container *ngrxLet="isAttachmentsControlVisible$ as isAttachmentsControlVisible">
    <div class="dialog-header">
        <h2 class="header" mat-dialog-title>
            {{ "dtmWebAppLibOperatorConversations.operatorConversationsContainer.newThreadHeader" | transloco }}
        </h2>
        <button class="button-icon" type="button" (click)="close()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <form [formGroup]="conversationForm" *ngIf="!(capabilitiesError$ | ngrxPush); else capabilitiesErrorTemplate" mat-dialog-content>
        <dtm-ui-new-thread-category-subject-form
            [categories]="conversationCategories$ | ngrxPush"
            formControlName="categoryAndSubject"
            dtmUiMarkValueAccessorControlsAsTouched
        >
        </dtm-ui-new-thread-category-subject-form>
        <dtm-ui-new-message-editor
            dtmUiMarkValueAccessorControlsAsTouched
            formControlName="editor"
            [isAttachmentsControlVisible]="isAttachmentsControlVisible"
        >
        </dtm-ui-new-message-editor>
    </form>

    <dtm-ui-new-message-actions
        mat-dialog-actions
        [isAttachmentsButtonVisible]="!isAttachmentsControlVisible"
        (attachmentsButtonVisibilityChange)="changeAttachmentControlVisibility()"
        (messageDecline)="close()"
        (messageSend)="createThread()"
    ></dtm-ui-new-message-actions>
</ng-container>

<ng-template #capabilitiesErrorTemplate>
    <dtm-ui-error
        (reload)="getCapabilities()"
        [errorMessage]="'dtmWebAppLibOperatorConversations.operatorConversationsContainer.newThreadCapabilitiesDownloadError' | transloco"
    ></dtm-ui-error>
</ng-template>
