import { InjectionToken } from "@angular/core";

export interface UserProfileEndpoints {
    getUserProfile: string;
    getNationalNodeUserRegistration: string;
    requestNationalNodeUserRegistrationEmailChange: string;
    requestNationalNodeUserRegistrationPhoneNumberChange: string;
    resendNationalNodeUserRegistrationEmailVerificationCode: string;
    resendNationalNodeUserRegistrationPhoneNumberVerificationCode: string;
    registerNationalNodeUser: string;
    confirmNationalNodeUserRegistrationEmail: string;
    confirmNationalNodeUserRegistrationPhoneNumber: string;
    manageProfileAvatar: string;
    requestEmailChange: string;
    requestPhoneNumberChange: string;
    savePhoneNumber: string;
    saveEmailAddress: string;
    updateProfileLanguage: string;
    resetUserPassword: string;
    getIdentityFile: string;
    uploadIdFile: string;
    saveIdentityDocument: string;
    downloadIdentityDocument: string;
    resendLegalGuardianEmailAddress: string;
    changeLegalGuardianData: string;
    deleteIdentityDocument: string;
    getAttorneyPowerDocument: string;
}

export const USER_PROFILE_ENDPOINTS = new InjectionToken<UserProfileEndpoints>("User Profile endpoints");
