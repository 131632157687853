<dtm-ui-expandable-panel *ngrxLet="legalGuardian$ as legalGuardian" [hasHeaderSeparator]="false" isExpanded>
    <h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.detailsStep.legalGuardian.header" | transloco }}</h2>
    <div class="details-container">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.legalGuardian.nameLabel' | transloco"
            [value]="legalGuardian.name + ' ' + legalGuardian.lastName"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.legalGuardian.emailLabel' | transloco"
            [value]="legalGuardian.email"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.legalGuardian.birthDateLabel' | transloco"
            [value]="legalGuardian.dateOfBirth | localizeDate"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.legalGuardian.phoneNumberLabel' | transloco"
            [value]="legalGuardian.phone | formatImportedPhoneNumber"
        ></dtm-ui-label-value>
    </div>
    <form class="form-container" [formGroup]="legalGuardianInfoForm">
        <dtm-ui-country-select-field dtmUiMarkValueAccessorControlsAsTouched [formControl]="legalGuardianInfoForm.controls.country">
            <label>
                {{ "dtmWebAppLibOperatorMigration.detailsStep.legalGuardian.countryLabel" | transloco }}
            </label>
        </dtm-ui-country-select-field>
        <dtm-ui-address-form
            dtmUiMarkValueAccessorControlsAsTouched
            [formControl]="legalGuardianInfoForm.controls.address"
            [isSystemDefaultCountrySelected]="legalGuardianInfoForm.controls.country.value"
        ></dtm-ui-address-form>
    </form>
</dtm-ui-expandable-panel>
