import { DOCUMENT } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, NgZone, Output, ViewChild } from "@angular/core";
import { CameraHelperService, CesiumPointerManagerService, MapLabelsUtils, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { MissionPlanRoute, MissionPlanRouteFlightZone, MissionPlanRouteSegment, Waypoint } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcEntity, AcLayerComponent, CesiumEvent, CesiumService, MapEventsManagerService, PickOptions } from "@pansa/ngx-cesium";
import bearing from "@turf/bearing";
import turfDestination from "@turf/destination";
import turfDistance from "@turf/distance";
import { lineString, feature as turfFeature, point as turfPoint } from "@turf/helpers";
import lineIntersect from "@turf/line-intersect";
import { Subject, auditTime, combineLatest, from, fromEvent, map, mergeMap, of, switchMap, tap, throttleTime } from "rxjs";
import { combineLatestWith, distinctUntilChanged } from "rxjs/operators";
import { MissionWizardSteps } from "../content/mission-wizard-content.component";
import { ROUTES_VISUAL_DATA, RouteVisualState } from "./assisted-editor-map.data";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface RouteSelectorMapFeaturesComponentState {
    isShown: boolean;
    routes: MissionPlanRoute[] | undefined;
    selectedRouteId: string | undefined;
    previewRouteId: string | undefined;
    is3DEnabled: boolean;
    is2DEnabled: boolean;
}

class RouteAcEntity extends AcEntity {}

const FULL_CIRCLE_ANGLE = 360;
const LABELS_SHOWING_DISTANCE_IN_METERS = 60000;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-route-selector-map-features[isShown]",
    templateUrl: "./route-selector-map-features.component.html",
    styleUrls: ["./route-selector-map-features.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RouteSelectorMapFeaturesComponent implements AfterViewInit {
    @ViewChild("flightAreaEntitiesLayer") private flightAreaEntitiesLayer!: AcLayerComponent;
    @ViewChild("flightSpaceEntitiesLayer") private flightSpaceEntitiesLayer!: AcLayerComponent;
    @ViewChild("labelViewer") private labelViewer: AcLayerComponent | undefined;
    @ViewChild("segmentLabelViewer") private segmentLabelViewer: AcLayerComponent | undefined;

    @Input() public set isShown(value: boolean | undefined) {
        this.localStore.patchState({ isShown: value });
    }
    @Input() public set routes(value: MissionPlanRoute[] | undefined) {
        this.localStore.patchState({ routes: value });
    }
    @Input() public set selectedRouteId(value: string | undefined) {
        this.localStore.patchState({ selectedRouteId: value });
    }
    @Input() public set previewRouteId(value: string | undefined) {
        this.localStore.patchState({ previewRouteId: value });
    }

    @Output() public readonly routeSelected = new EventEmitter<string>();

    @Output() public previewRoute = this.prepareRoutePreviewStreamOnMouseHover();

    protected readonly flightAreaEntities$ = new Subject();
    protected readonly flightSpaceEntities$ = new Subject();
    protected readonly labels$ = new Subject();
    protected readonly segmentLabels$ = new Subject();
    protected readonly is2DEnabled$ = this.localStore.selectByKey("is2DEnabled");
    protected readonly is3DEnabled$ = this.localStore.selectByKey("is3DEnabled");
    protected readonly isShown$ = this.localStore.selectByKey("isShown");

    protected readonly Cesium = Cesium;
    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly ROUTES_VISUAL_DATA = ROUTES_VISUAL_DATA;

    protected readonly areLabelsVisible$ = combineLatest([
        this.localStore.selectByKey("isShown"),
        this.localStore.selectByKey("routes"),
        this.localStore.selectByKey("selectedRouteId"),
        this.cameraHelperService.postRender$,
    ]).pipe(
        auditTime(DEFAULT_DEBOUNCE_TIME),
        map(([isShown, routes, selectedRouteId]) => {
            if (!isShown) {
                return false;
            }
            const selectedRoute = routes?.find(({ routeId }) => routeId === selectedRouteId);

            if (!selectedRoute) {
                return false;
            }

            return (
                this.cesiumService.getViewer().camera.positionCartographic.height <=
                LABELS_SHOWING_DISTANCE_IN_METERS / selectedRoute.sections.length
            );
        })
    );

    constructor(
        private readonly localStore: LocalComponentStore<RouteSelectorMapFeaturesComponentState>,
        private readonly eventManager: MapEventsManagerService,
        private readonly cesiumService: CesiumService,
        private readonly cameraHelperService: CameraHelperService,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly cesiumPointerManager: CesiumPointerManagerService,
        private readonly zone: NgZone
    ) {
        localStore.setState({
            routes: undefined,
            selectedRouteId: undefined,
            isShown: false,
            is3DEnabled: true,
            is2DEnabled: true,
            previewRouteId: undefined,
        });
    }

    public ngAfterViewInit(): void {
        this.zone.runOutsideAngular(() => {
            this.generateAndUpdateFlightAreaEntities();
            this.registerClickEventForRouteSelection();
            this.generateAndUpdateLabelEntities();
            this.watchLabelsOverlapping();
        });
    }

    protected toggle2D(value: boolean) {
        this.localStore.patchState({ is2DEnabled: value });
        if (!value) {
            this.localStore.patchState({ is3DEnabled: true });
        }
        this.cesiumService.getScene().requestRender();
    }

    protected toggle3D(value: boolean) {
        this.localStore.patchState({ is3DEnabled: value });
        if (!value) {
            this.localStore.patchState({ is2DEnabled: true });
        }
        this.cesiumService.getScene().requestRender();
    }

    private generateAndUpdateFlightAreaEntities() {
        this.localStore
            .selectByKey("routes")
            .pipe(
                tap(() => {
                    this.flightAreaEntitiesLayer.removeAll();
                    this.flightSpaceEntitiesLayer.removeAll();
                }),
                RxjsUtils.filterFalsy(),
                combineLatestWith(
                    this.localStore.selectByKey("selectedRouteId"),
                    this.previewRoute,
                    this.localStore.selectByKey("previewRouteId"),
                    this.localStore.selectByKey("is3DEnabled")
                ),
                mergeMap(([routes, selectedRouteId, previewRouteId, externalPreviewRouteId, is3DEnabled]) =>
                    from(routes).pipe(
                        map((route, index) => ({
                            route,
                            index,
                            selectedRouteId,
                            previewRouteId: externalPreviewRouteId ?? previewRouteId,
                            is3DEnabled,
                        }))
                    )
                ),
                untilDestroyed(this)
            )
            .subscribe(({ route, index, selectedRouteId, previewRouteId, is3DEnabled }) => {
                const isSelected = selectedRouteId === route.routeId;
                const isSelectedForPreview = previewRouteId === route.routeId;

                const sections = route.sections.flatMap((section) => [section.flightZone, section.segment]).filter(FunctionUtils.isTruthy);

                sections.forEach((section, sectionIndex) => {
                    const flightAreaId = "flightArea" + sectionIndex + index;

                    let state: RouteVisualState = RouteVisualState.Default;
                    let zIndex = 0;

                    if (isSelectedForPreview) {
                        state = RouteVisualState.Preview;
                        zIndex = 1;
                    }

                    if (isSelected) {
                        state = RouteVisualState.Selected;
                        zIndex = 1;
                    }
                    if (sectionIndex === 0) {
                        state = RouteVisualState.TakeOff;
                        zIndex = 2;
                    }
                    if (sectionIndex === sections.length - 1) {
                        state = RouteVisualState.Landing;
                        zIndex = 2;
                    }

                    // draw takeoff and landing only for selected route
                    if (!isSelected && [RouteVisualState.Landing, RouteVisualState.TakeOff].includes(state)) {
                        this.flightAreaEntitiesLayer.remove(flightAreaId);
                        this.flightSpaceEntitiesLayer.remove(flightAreaId);

                        this.cesiumService.getScene().requestRender();

                        return;
                    }

                    const area = section.flightArea;
                    const coordinates = area.volume.area.coordinates;
                    const floor = (area?.volume?.floor ?? 0) - (area?.elevationMin ?? 0);
                    const ceiling = (area?.volume?.ceiling ?? 0) - (area?.elevationMax ?? 0);

                    const positions = Cesium.Cartesian3.fromDegreesArray(coordinates.flat(2));

                    const entity = new RouteAcEntity({
                        positions,
                        routeId: route.routeId,
                        ceiling,
                        floor,
                        state,
                        zIndex,
                    });

                    if (!is3DEnabled || !isSelected) {
                        this.flightAreaEntitiesLayer.update(entity, flightAreaId);
                    } else {
                        this.flightAreaEntitiesLayer.remove(flightAreaId);
                    }

                    if (isSelected) {
                        this.flightSpaceEntitiesLayer.update(entity, flightAreaId);
                    } else {
                        this.flightSpaceEntitiesLayer.remove(flightAreaId);
                    }
                    this.cesiumService.getScene().requestRender();
                });
            });
    }

    private generateAndUpdateLabelEntities() {
        this.localStore
            .selectByKey("routes")
            .pipe(
                RxjsUtils.filterFalsy(),
                combineLatestWith(this.localStore.selectByKey("selectedRouteId"), this.is3DEnabled$),
                mergeMap(([routes, selectedRouteId, is3DEnabled]) =>
                    from(routes).pipe(map((route, index) => ({ route, index, selectedRouteId, is3DEnabled })))
                ),
                untilDestroyed(this)
            )
            .subscribe(({ route, index, selectedRouteId, is3DEnabled }) => {
                const isSelected = selectedRouteId === route.routeId;

                const sections = route.sections.flatMap((section) => [section.flightZone, section.segment]).filter(FunctionUtils.isTruthy);

                this.generateAndUpdateLabels(sections, isSelected, index, is3DEnabled);
                if (isSelected) {
                    // NOTE: setTimeout prevents calculation before cesium labels are in place
                    setTimeout(() => this.removeOverlapping());
                }
            });
    }

    private registerClickEventForRouteSelection() {
        this.cesiumPointerManager
            .addEventHandler({
                event: CesiumEvent.LEFT_CLICK,
                entityType: RouteAcEntity,
                pick: PickOptions.PICK_FIRST,
            })
            .pipe(untilDestroyed(this))
            .subscribe((result) => this.routeSelected.next(result.entities[0]?.routeId));
    }

    private prepareRoutePreviewStreamOnMouseHover() {
        const entityMouseHoverRegistration = this.cesiumPointerManager.addEventHandler({
            event: CesiumEvent.MOUSE_MOVE,
            pick: PickOptions.PICK_FIRST,
        });

        // NOTE: combination with event mousemove is needed to prevent laggy change detection
        return fromEvent(this.document, "mousemove")
            .pipe(combineLatestWith(entityMouseHoverRegistration))
            .pipe(
                map(([, result]) => result?.entities?.[0] as MissionPlanRoute),
                distinctUntilChanged((previous, current) => previous?.routeId === current?.routeId),
                map((route) => route?.routeId)
            );
    }

    private isSegmentType(entity: MissionPlanRouteSegment | MissionPlanRouteFlightZone): entity is MissionPlanRouteSegment {
        return (entity as MissionPlanRouteSegment).fromWaypoint !== undefined;
    }

    private isZoneType(entity: MissionPlanRouteSegment | MissionPlanRouteFlightZone): entity is MissionPlanRouteFlightZone {
        return (entity as MissionPlanRouteFlightZone).center !== undefined;
    }

    private generateAndUpdateLabels(
        sections: (MissionPlanRouteFlightZone | MissionPlanRouteSegment)[],
        isSelected: boolean,
        index: number,
        is3DEnabled: boolean
    ) {
        const segments = sections.filter(this.isSegmentType);
        const waypoints =
            segments.length > 1
                ? sections.filter(this.isSegmentType).reduce<Waypoint[]>((accumulator, section, currentIndex, filterSections) => {
                      accumulator.push(section.fromWaypoint);
                      if (currentIndex === filterSections.length - 1) {
                          accumulator.push(section.toWaypoint);
                      }

                      return accumulator;
                  }, [])
                : [segments[0].fromWaypoint, segments[0].toWaypoint];

        waypoints.forEach((waypoint, sectionIndex) => {
            const point = { ...waypoint.point };
            if (!is3DEnabled) {
                point.height = 0;
            }
            const position = MapUtils.convertSerializableCartographicToCartesian3(point);

            const labelEntity = {
                position,
                name: waypoint.name,
                altitude: Math.round(waypoint.point.altitude),
                height: Math.round(waypoint.point.height),
                shouldShowHeight: sectionIndex !== 0 && sectionIndex < waypoints.length - 1,
            };

            const entityId = "Label" + sectionIndex + index;
            if (isSelected) {
                this.labelViewer?.update(labelEntity, entityId);
            } else {
                this.labelViewer?.remove(entityId);
            }
            this.cesiumService.getScene().requestRender();
        });

        this.generateSegmentLabels(segments, isSelected, is3DEnabled, index);
        const takeOffZone = sections.find(this.isZoneType);
        const nextPoint = segments[0].toWaypoint;
        const landingZone = sections.reverse().find(this.isZoneType);
        const penultimatePoint = segments[segments.length - 1].fromWaypoint;

        if (!takeOffZone || !landingZone) {
            return;
        }

        this.generateZoneLabels(
            [
                { ...takeOffZone, nextPoint },
                { ...landingZone, nextPoint: penultimatePoint },
            ],
            isSelected,
            index,
            is3DEnabled
        );
    }

    private generateSegmentLabels(segments: MissionPlanRouteSegment[], isSelected: boolean, is3DEnabled: boolean, index: number) {
        const points = segments.map((segment) => {
            const fromPoint = { ...segment.fromWaypoint.point };
            const toPoint = { ...segment.toWaypoint.point };

            if (!is3DEnabled) {
                fromPoint.height = 0;
                toPoint.height = 0;
            } else {
                const maxPointHeight = Math.max(fromPoint.height, toPoint.height);
                fromPoint.height = maxPointHeight;
                toPoint.height = maxPointHeight;
            }
            const midPointCartesian3 = Cesium.Cartesian3.lerp(
                MapUtils.convertSerializableCartographicToCartesian3(fromPoint),
                MapUtils.convertSerializableCartographicToCartesian3(toPoint),
                1 / 2,
                new Cesium.Cartesian3()
            );

            return {
                position: midPointCartesian3,
                fromTime: segment.fromWaypoint.estimatedArriveAt.min,
                toTime: segment.toWaypoint.estimatedArriveAt.max,
            };
        });

        points.forEach((labelEntity, sectionIndex) => {
            const entityId = "SegmentLabel" + sectionIndex + index;
            if (isSelected) {
                this.segmentLabelViewer?.update(labelEntity, entityId);
            } else {
                this.segmentLabelViewer?.remove(entityId);
            }
            this.cesiumService.getScene().requestRender();
        });
    }

    private generateZoneLabels(
        zones: (MissionPlanRouteFlightZone & { nextPoint: Waypoint })[],
        isSelected: boolean,
        index: number,
        is3DEnabled: boolean
    ) {
        const zonesLabelEntities = zones.map((zone, zoneIndex) => {
            const firstPointsCoords = [zone.center.point.longitude, zone.center.point.latitude];
            const secondPointsCoords = [zone.nextPoint.point.longitude, zone.nextPoint.point.latitude];
            const centerPoint = turfPoint(firstPointsCoords);
            const line = lineString([firstPointsCoords, secondPointsCoords]);
            const intersection = lineIntersect(turfFeature(zone.flightArea.volume.area), line);
            let angle = 0;
            let distance = 0;
            if (intersection.features.length) {
                angle = bearing(centerPoint, intersection.features[0]);
                distance = turfDistance(centerPoint, intersection.features[0], { units: "meters" });
            }

            const destinationPoint = turfDestination(centerPoint, distance, angle - FULL_CIRCLE_ANGLE / 2, { units: "meters" });

            return {
                position: MapUtils.convertSerializableCartographicToCartesian3({
                    longitude: destinationPoint.geometry.coordinates[0],
                    latitude: destinationPoint.geometry.coordinates[1],
                    height: is3DEnabled ? (zone.flightArea.volume.ceiling - (zone.flightArea.elevationMax ?? 0)) / 2 : 0,
                }),
                fromTime: zone.center.estimatedArriveAt.min,
                toTime: zone.center.estimatedArriveAt.max,
                isZoneLabel: true,
                isTakeoff: zoneIndex === 0,
                altitude: Math.round(zone.flightArea.volume.ceiling),
                angle,
            };
        });

        zonesLabelEntities.forEach((labelEntity, sectionIndex) => {
            const entityId = "zoneLabel" + sectionIndex + index;
            if (isSelected) {
                this.segmentLabelViewer?.update(labelEntity, entityId);
            } else {
                this.segmentLabelViewer?.remove(entityId);
            }
            this.cesiumService.getScene().requestRender();
        });
    }

    private watchLabelsOverlapping() {
        this.isShown$
            .pipe(
                switchMap((isShown) => {
                    if (!isShown) {
                        return of(undefined);
                    }

                    return this.cameraHelperService.postRender$.pipe(
                        throttleTime(DEFAULT_DEBOUNCE_TIME, undefined, { leading: true, trailing: true }),
                        tap(() => this.zone.runOutsideAngular(() => this.removeOverlapping()))
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private removeOverlapping() {
        const elements = [...(this.document?.querySelectorAll(".waypoint-label, .segment-label").values() ?? [])] as HTMLDivElement[];

        MapLabelsUtils.removeOverlapping(elements, {
            svgLineSelector: ".line-connector line",
            buffer: 4,
        });
    }
}
