import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MissionProcessingPhase, MissionType } from "@dtm-frontend/shared/mission";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, ObjectUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subscription } from "rxjs";
import { debounceTime, map, tap } from "rxjs/operators";
import { FlightPurpose } from "../../../../shared";
import { MissionGroupByPeriod, MissionPlanListQuery, SortOption } from "../../../models/mission.model";
import { DEFAULT_MISSION_LIST_FILTERS_AND_SORTING } from "../../../services/mission.resolvers";

interface MissionPlanListFilterComponentState {
    filtersCount: number;
    flightPurposes: FlightPurpose[];
}

const FILTER_UPDATE_DEBOUNCE_TIME = 500;
const TEXT_SEARCH_MAX_LENGTH = 200;

const FILTERS_MAP: FiltersMap[] = [
    {
        type: FilterType.Text,
        key: "textSearch",
        filterLabel: "dtmWebAppMembership.filters.searchByTextLabel",
        filterValueLabel: "dtmWebAppMembership.filters.confirmLabel",
    },
    {
        type: FilterType.Text,
        key: "flightType",
        filterLabel: "dtmWebAppLibMission.missionFilters.selectedFiltersLabels.flightType",
        filterValueLabel: "dtmWebAppLibShared.missionTypeLabel",
    },
    {
        type: FilterType.Text,
        key: "processingPhase",
        filterLabel: "dtmWebAppLibMission.missionFilters.selectedFiltersLabels.processingPhase",
        filterValueLabel: "dtmWebAppLibMission.missionTile.phaseLabel",
    },
    {
        type: FilterType.Date,
        key: "flightDate.from",
        filterLabel: "dtmWebAppLibMission.missionFilters.selectedFiltersLabels.flightDatesFrom",
    },
    {
        type: FilterType.Date,
        key: "flightDate.to",
        filterLabel: "dtmWebAppLibMission.missionFilters.selectedFiltersLabels.flightDatesTo",
    },
    {
        type: FilterType.Dictionary,
        key: "flightPurpose",
        filterLabel: "dtmWebAppLibMission.missionFilters.selectedFiltersLabels.flightPurpose",
    },
];

const SORT_OPTIONS_MAP: { [key in SortOption]?: string } = {
    "modified,desc": "modifiedDesc",
    "modified,asc": "modifiedAsc",
    "flightStartAtMin,desc": "flightStartAtDesc",
    "flightStartAtMin,asc": "flightStartAtAsc",
};

// NOTE: We can't use enum directly if we need specific order
const MISSION_GROUPING_OPTIONS: MissionGroupByPeriod[] = [
    MissionGroupByPeriod.Day,
    MissionGroupByPeriod.Week,
    MissionGroupByPeriod.Month,
    MissionGroupByPeriod.Year,
];

const MIN_TEXT_SEARCH_LENGTH = 3;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-plan-list-filter",
    templateUrl: "./mission-plan-list-filter.component.html",
    styleUrls: ["./mission-plan-list-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionPlanListFilterComponent {
    @Output()
    public filtersQueryChange: EventEmitter<MissionPlanListQuery> = new EventEmitter();

    @Output()
    public groupByChange: EventEmitter<MissionPlanListQuery["groupBy"]> = new EventEmitter();

    @Output()
    public filtersAppliedChange: EventEmitter<boolean> = new EventEmitter();

    @Input()
    public set filters(value: MissionPlanListQuery) {
        const formValue = {
            textSearch: value.textSearch ?? null,
            flightType: this.getFlightTypesFromQuery(value),
            sort: value.sort ?? DEFAULT_MISSION_LIST_FILTERS_AND_SORTING.sort,
            groupBy: value.groupBy ?? null,
            flightPurpose: value.flightPurpose ? [value.flightPurpose].flat() : null,
            flightDate: {
                from: value.flightDateFrom ? new Date(value.flightDateFrom) : null,
                to: value.flightDateTo ? new Date(value.flightDateTo) : null,
            },
        };

        // NOTE: Workaround for Mat Range Picker ignoring emitEvent false
        // https://github.com/angular/components/issues/20218
        this.valueChangesSubscription?.unsubscribe();

        this.filtersForm.setValue(formValue, { emitEvent: false });
        this.checkAndUpdateGroupBy();
        this.groupByChange.emit(this.groupByControl.value);
        this.updateFilterCount();

        this.watchValueChanges();
    }

    @Input()
    public set flightPurposes(value: FlightPurpose[] | undefined) {
        this.localeStore.patchState({
            flightPurposes: value ?? [],
        });
    }

    protected readonly textSearchControl = new FormControl<MissionPlanListQuery["textSearch"]>(
        null,
        Validators.minLength(MIN_TEXT_SEARCH_LENGTH)
    );
    protected readonly flightTypeControl = new FormControl<MissionPlanListQuery["flightType"]>([MissionType.VLOS, MissionType.BVLOS]);
    protected readonly flightDateFromControl = new FormControl<MissionPlanListQuery["flightDateFrom"]>(null);
    protected readonly flightDateToControl = new FormControl<MissionPlanListQuery["flightDateTo"]>(null);
    protected readonly sortByControl = new FormControl<MissionPlanListQuery["sort"]>(null);
    protected readonly groupByControl = new FormControl<MissionPlanListQuery["groupBy"]>(null);
    protected readonly flightPurposesControl = new FormControl<MissionPlanListQuery["flightPurpose"]>(null);

    protected readonly MissionPlanPhase = MissionProcessingPhase;
    protected readonly MissionType = MissionType;
    protected readonly FilterType = FilterType;
    protected readonly MISSION_GROUPING_OPTIONS = MISSION_GROUPING_OPTIONS;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly SORT_OPTIONS_MAP = SORT_OPTIONS_MAP;
    protected readonly TEXT_SEARCH_MAX_LENGTH = TEXT_SEARCH_MAX_LENGTH;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    protected readonly filterCount$ = this.localeStore.selectByKey("filtersCount");
    protected readonly flightPurposes$ = this.localeStore.selectByKey("flightPurposes");
    protected readonly flightPurposesLabelsMap$ = this.flightPurposes$.pipe(
        map((flightPurposes) => ObjectUtils.convertToMap(flightPurposes, "id", (el) => el.name))
    );

    private readonly flightDateForm = new FormGroup({
        from: this.flightDateFromControl,
        to: this.flightDateToControl,
    });

    protected readonly filtersForm = new FormGroup({
        textSearch: this.textSearchControl,
        flightType: this.flightTypeControl,
        flightDate: this.flightDateForm,
        sort: this.sortByControl,
        groupBy: this.groupByControl,
        flightPurpose: this.flightPurposesControl,
    });

    private valueChangesSubscription: Subscription | undefined;

    constructor(
        private localeStore: LocalComponentStore<MissionPlanListFilterComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localeStore.setState({
            filtersCount: 0,
            flightPurposes: [],
        });
        this.watchValueChanges();
    }

    private watchValueChanges() {
        this.valueChangesSubscription?.unsubscribe();
        this.valueChangesSubscription = this.filtersForm.valueChanges
            .pipe(
                tap(() => this.updateFilterCount()),
                tap(() => this.checkAndUpdateGroupBy()),
                debounceTime(FILTER_UPDATE_DEBOUNCE_TIME),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (!this.filtersForm.valid) {
                    return;
                }
                const { flightDate, ...value } = this.filtersForm.getRawValue();
                this.filtersQueryChange.emit({
                    ...value,
                    flightDateTo: flightDate.to,
                    flightDateFrom: flightDate.from,
                });
            });
    }

    protected checkAndUpdateGroupBy(): void {
        const value = this.sortByControl.value;
        const disallowedGroupingForSortOptions: SortOption[] = ["modified,desc", "modified,asc"];
        if (value && disallowedGroupingForSortOptions.includes(value)) {
            this.groupByControl.setValue(null, { emitEvent: false });
            this.groupByControl.disable({ emitEvent: false });
        } else {
            this.groupByControl.setValue(this.groupByControl.value ?? MissionGroupByPeriod.Month, { emitEvent: false });
            this.groupByControl.enable({ emitEvent: false });
        }
    }

    protected getFilterCount = (): number => {
        const filters = this.filtersForm.value;

        return [filters.textSearch, filters.flightType, filters.flightDate?.from, filters.flightDate?.to, filters.flightPurpose]
            .flat()
            .filter(Boolean).length;
    };

    private updateFilterCount(): void {
        const filtersCount = this.getFilterCount();
        this.localeStore.patchState({
            filtersCount,
        });
        this.filtersAppliedChange.emit(!!filtersCount);
    }

    protected clearFilters(): void {
        this.filtersForm.reset({
            groupBy: this.groupByControl.value,
            sort: this.sortByControl.value,
        });
    }

    private getFlightTypesFromQuery(value: MissionPlanListQuery) {
        if (!value.flightType) {
            return null;
        }

        return Array.isArray(value.flightType) ? value.flightType : [value.flightType];
    }
}
