<ng-container *ngrxLet="distanceValues$ as distanceValues">
    <ng-container [ngSwitch]="type$ | ngrxPush">
        <div
            *ngSwitchCase="NavigationAccuracyType.HorizontalFlight"
            [innerHTML]="
                'dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.horizontalFlight.description' | transloco : distanceValues
            "
        ></div>
        <div
            *ngSwitchCase="NavigationAccuracyType.TakeoffAndLanding"
            [innerHTML]="
                'dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.takeoffAndLanding.description' | transloco : distanceValues
            "
        ></div>
    </ng-container>
</ng-container>
