import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SinglePermission } from "../../../../services/operator-permissions.models";

interface SummaryPermissionComponentState {
    permission: SinglePermission | undefined;
    isPaymentFeatureAvailable: boolean;
}

@Component({
    selector: "dtm-web-app-lib-operator-permission-summary-permission[permission]",
    templateUrl: "./summary-permission.component.html",
    styleUrls: ["./summary-permission.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SummaryPermissionComponent {
    @Input() public set permission(value: SinglePermission | undefined) {
        this.localStore.patchState({ permission: value });
    }
    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }

    protected readonly permission$ = this.localStore.selectByKey("permission").pipe(RxjsUtils.filterFalsy());
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");

    constructor(private readonly localStore: LocalComponentStore<SummaryPermissionComponentState>) {
        localStore.setState({ permission: undefined, isPaymentFeatureAvailable: false });
    }
}
