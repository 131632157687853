import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";

const CONTACT_MAIL = "drony@pansa.pl";
const missionParametersList = {
    weight: 25,
    speed: 30,
    height: 120,
};

interface MessageToPansaComponentState {
    planId: string | undefined;
}

@Component({
    selector: "dtm-web-app-lib-message-to-pansa",
    templateUrl: "./message-to-pansa.component.html",
    styleUrls: ["./message-to-pansa.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MessageToPansaComponent {
    @Input() public set planId(value: string | undefined) {
        this.localStore.patchState({ planId: value });
    }

    protected readonly CONTACT_MAIL = CONTACT_MAIL;
    protected readonly missionParametersList = missionParametersList;

    protected readonly planId$ = this.localStore.selectByKey("planId");

    constructor(
        private readonly localStore: LocalComponentStore<MessageToPansaComponentState>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            planId: undefined,
        });
    }

    protected handleValueCopy(isSuccess: boolean): void {
        if (isSuccess) {
            this.toastService.success(this.transloco.translate("dtmUi.copyToClipboard.successMessage"));
        } else {
            this.toastService.error(this.transloco.translate("dtmUi.copyToClipboard.errorMessage"));
        }
    }
}
