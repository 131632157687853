import { OperatorPermissionsStatementToSign, StatementsListQueryParams } from "../services/operator-permissions.models";

export namespace OperatorPermissionsActions {
    export class GetOwnedPermissionsList {
        public static readonly type = "[Operator permissions] Get owned permissions list";
        constructor(public operatorId: string) {}
    }

    export class GetStatementsList {
        public static readonly type = "[Operator permissions] Get statements list";
        constructor(public params: StatementsListQueryParams) {}
    }

    export class GetOperatorPermissionsCapabilities {
        public static readonly type = "[Operator permissions] Get owned and possible permissions";
        constructor(public operatorId: string) {}
    }

    export class SetUavsRequiredState {
        public static readonly type = "[Operator permissions] Set uavs required state";
        constructor(public areUavsRequired: boolean) {}
    }

    export class SaveStatementAndPay {
        public static readonly type = "[Operator permissions] Save statement and pay";
        constructor(public operatorId: string, public statement: OperatorPermissionsStatementToSign) {}
    }

    export class SaveStatement {
        public static readonly type = "[Operator permissions] Save statement";
        constructor(public operatorId: string, public statement: OperatorPermissionsStatementToSign) {}
    }

    export class GetStatementInPdf {
        public static readonly type = "[Operator permissions] Get statement in pdf";
        constructor(public operatorId: string, public statements: OperatorPermissionsStatementToSign, public fileName: string) {}
    }

    export class GetSavedStatement {
        public static readonly type = "[Operator permissions] Get saved statement";
        constructor(public operatorId: string, public statementId: string) {}
    }

    export class GetOperatorStatementAdministrativeFeeDocument {
        public static readonly type = "[Operator permissions] Get operator statement administrative fee document";
        constructor(public feeDocumentId: string) {}
    }

    export class CreatePaymentRedirectUrl {
        public static readonly type = "[Operator permissions] Create payment redirect url";
        constructor(public operatorId: string, public statementId: string) {}
    }

    export class CreateSignatureRedirectUrl {
        public static readonly type = "[Operator permissions] Create signature redirect url";
        constructor(public operatorId: string, public statementId: string) {}
    }

    export class GetUavDetailsForPermission {
        public static readonly type = "[Operator permissions] Get uav details for permission";
        constructor(public operatorId: string, public permissionId: string) {}
    }

    export class GetPermissionSignatureStatus {
        public static readonly type = "[Operator permissions] Get permissions signature status";
        constructor(public statementId: string, public operatorId: string) {}
    }
}
