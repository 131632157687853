<ng-container *ngrxLet="isPaymentFeatureAvailable$ as isPaymentFeatureAvailable">
    <dtm-ui-responsive-table *ngrxLet="statementsList$ as statementsList" [data]="statementsList">
        <table
            class="dtm-table no-box-shadow"
            *ngIf="statementsList.length; else emptyListTemplate"
            mat-table
            multiTemplateDataRows
            desktopTable
            [dataSource]="statementsList"
        >
            <ng-container *ngrxLet="expandedElement$ as expandedElement">
                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            type="button"
                            class="button-icon"
                            (click)="$event.stopPropagation(); toggleExpandableRow(element)"
                            [attr.aria-label]="'dtmUi.expandButtonArialLabel' | transloco : { isExpanded: element === expandedElement }"
                            [attr.aria-expanded]="element === expandedElement"
                        >
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.statementsList.statusColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container
                            [ngTemplateOutlet]="statusesTemplate"
                            [ngTemplateOutletContext]="{ $implicit: element }"
                        ></ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="statementDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.statementsList.statementDateColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.createdAt | localizeDate }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="operatorNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.statementsList.operatorNumberColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.operatorNumber }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="attorneyPower">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.statementsList.attorneyPowerColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container
                            [ngTemplateOutlet]="attorneyPowerTemplate"
                            [ngTemplateOutletContext]="{ $implicit: element.attorneyPowerAdministrativeFee }"
                        ></ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.statementsList.actionsColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="
                                (isPaymentFeatureAvailable && element.payment.status !== StatementStatus.Completed) ||
                                element.signature.status !== StatementStatus.Completed ||
                                element.attorneyPowerAdministrativeFee?.isDeclarationOfExemption === false
                            "
                            type="button"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: element }"
                            (click)="$event.stopPropagation()"
                            class="button-icon"
                            [attr.aria-label]="'dtmUi.actionButtonAriaLabel' | transloco"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                            <ng-container
                                [ngTemplateOutlet]="additionalInfoTemplate"
                                [ngTemplateOutletContext]="{ $implicit: element }"
                            ></ng-container>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="expandedElement === row"
                    (click)="toggleExpandableRow(row)"
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['pagination']"
                    [class.hide-footer]="!statementsList.length"
                    class="pagination-row"
                ></tr>
            </ng-container>
        </table>
        <div *dtmUiMobileTable="let row">
            <div class="mobile-table-actions-row">
                <dtm-ui-label-value [label]="'dtmWebAppLibOperatorPermissions.statementsList.statusColumnLabel' | transloco">
                    <ng-container [ngTemplateOutlet]="statusesTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                </dtm-ui-label-value>
                <button
                    *ngIf="
                        (isPaymentFeatureAvailable && row.payment.status !== StatementStatus.Completed) ||
                        row.signature.status !== StatementStatus.Completed ||
                        row.attorneyPowerAdministrativeFee?.isDeclarationOfExemption === false
                    "
                    type="button"
                    class="button-icon"
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="rowActionsMenu"
                    [matMenuTriggerData]="{ $implicit: row }"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </div>
            <div class="mobile-table-content">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.statementsList.statementDateColumnLabel' | transloco"
                    [value]="row.createdAt | localizeDate"
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.statementsList.operatorNumberColumnLabel' | transloco"
                    [value]="row.operatorNumber"
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmWebAppLibOperatorPermissions.statementsList.attorneyPowerColumnLabel' | transloco">
                    <ng-container
                        [ngTemplateOutlet]="attorneyPowerTemplate"
                        [ngTemplateOutletContext]="{ $implicit: row.attorneyPowerAdministrativeFee }"
                    ></ng-container>
                </dtm-ui-label-value>
                <ng-container [ngTemplateOutlet]="additionalInfoTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
            </div>
        </div>
        <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
        <ng-container mobileTableNoData [ngTemplateOutlet]="emptyListTemplate"></ng-container>
    </dtm-ui-responsive-table>

    <ng-template #emptyListTemplate>
        <dtm-ui-no-results></dtm-ui-no-results>
    </ng-template>

    <mat-menu #rowActionsMenu="matMenu">
        <ng-template matMenuContent let-element>
            <button
                mat-menu-item
                type="button"
                (click)="signatureRedirect.emit(element.id)"
                *ngIf="isPaymentFeatureAvailable && element.payment.status !== StatementStatus.Completed"
            >
                <dtm-ui-icon name="coins"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibOperatorPermissions.statementsList.goToPaymentActionLabel" | transloco }}</span>
            </button>
            <button
                mat-menu-item
                type="button"
                (click)="signatureRedirect.emit(element.id)"
                *ngIf="
                    element.signature.status !== StatementStatus.Completed &&
                    (element.payment.status === StatementStatus.Completed || !isPaymentFeatureAvailable)
                "
            >
                <dtm-ui-icon name="information"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibOperatorPermissions.statementsList.goToSignatureActionLabel" | transloco }}</span>
            </button>
            <button
                mat-menu-item
                type="button"
                (click)="administrativeFeeDocumentDownload.emit(element.attorneyPowerAdministrativeFee.feeDocumentId)"
                *ngIf="element.attorneyPowerAdministrativeFee?.isDeclarationOfExemption === false"
            >
                <dtm-ui-icon name="download"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibOperatorPermissions.statementsList.downloadAdministrativeFeeDocumentLabel" | transloco }}</span>
            </button>
        </ng-template>
    </mat-menu>

    <ng-template #paginationTemplate>
        <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
        </dtm-ui-pagination>
    </ng-template>

    <ng-template #statusesTemplate let-element>
        <div class="statuses">
            <ng-container *ngIf="isPaymentFeatureAvailable" [ngSwitch]="element.payment.status">
                <span *ngSwitchCase="StatementStatus.Completed" class="chip success">
                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus" | transloco : { value: element.payment.status } }}
                </span>
                <span *ngSwitchCase="StatementStatus.Error" class="chip error">
                    <dtm-ui-icon name="information"></dtm-ui-icon>
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus" | transloco : { value: element.payment.status } }}
                </span>
                <span *ngSwitchDefault class="chip secondary">
                    <dtm-ui-icon name="coins"></dtm-ui-icon>
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus" | transloco : { value: element.payment.status } }}
                </span>
            </ng-container>
            <ng-container [ngSwitch]="element.signature.status">
                <span *ngSwitchCase="StatementStatus.Completed" class="chip success">
                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus" | transloco : { value: element.signature.status } }}
                </span>
                <span *ngSwitchCase="StatementStatus.Error" class="chip error">
                    <dtm-ui-icon name="information"></dtm-ui-icon>
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus" | transloco : { value: element.signature.status } }}
                </span>
                <span *ngSwitchDefault class="chip secondary">
                    <dtm-ui-icon name="ball-pen"></dtm-ui-icon>
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus" | transloco : { value: element.signature.status } }}
                </span>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #attorneyPowerTemplate let-attorneyPower>
        {{
            (attorneyPower
                ? "dtmWebAppLibOperatorPermissions.statementsList.attorneyPowerYesStatus"
                : "dtmWebAppLibOperatorPermissions.statementsList.attorneyPowerNoStatus"
            ) | transloco
        }}
    </ng-template>

    <ng-template #additionalInfoTemplate let-element>
        <div class="sub-cell">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorPermissions.statementsList.permissionsListLabel' | transloco"
                [value]="element.operationScenarioNames | invoke : joinOperationScenarioNames"
            >
            </dtm-ui-label-value>
        </div>
        <div class="sub-cell" *ngIf="element.attorneyPowerAdministrativeFee?.isDeclarationOfExemption">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorPermissions.statementsList.attorneyPowerCommentsLabel' | transloco"
                [value]="'dtmWebAppLibOperatorPermissions.statementsList.attorneyPowerCommentsValue' | transloco"
            >
            </dtm-ui-label-value>
        </div>
        <div
            class="sub-cell"
            *ngIf="
                isPaymentFeatureAvailable &&
                element.payment.status === StatementStatus.Error &&
                element.payment.info !== PaymentStatusInfoCode.Unknown
            "
        >
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorPermissions.statementsList.paymentErrorLabel' | transloco"
                [value]="
                    'dtmWebAppLibOperatorPermissions.statementsList.paymentErrorValue' | transloco : { infoCode: element.payment.info }
                "
            >
            </dtm-ui-label-value>
        </div>
    </ng-template>
</ng-container>
