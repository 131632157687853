import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { Uav, UavInfo } from "../../services/uav.models";

interface UavHeaderComponentState {
    uav: Uav | undefined;
    canShareUav: boolean;
    isShortMode: boolean;
    canManageUav: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-header[uav]",
    templateUrl: "./uav-header.component.html",
    styleUrls: ["./uav-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavHeaderComponent {
    @Input() public set uav(value: Uav | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set canShareUav(value: BooleanInput) {
        this.localStore.patchState({ canShareUav: coerceBooleanProperty(value) });
    }

    @Input() public set isShortMode(value: BooleanInput) {
        this.localStore.patchState({ isShortMode: coerceBooleanProperty(value) });
    }
    @Input() public set canManageUav(value: BooleanInput) {
        this.localStore.patchState({ canManageUav: coerceBooleanProperty(value) });
    }

    @Output() public edit = new EventEmitter<void>();
    @Output() public share = new EventEmitter<void>();
    @Output() public remove = new EventEmitter<void>();
    @Output() public manageDocuments = new EventEmitter<void>();

    protected readonly uav$ = this.localStore.selectByKey("uav").pipe(RxjsUtils.filterFalsy());
    protected readonly uavInfo$ = this.uav$.pipe(map((uav) => this.prepareUavInfo(uav)));
    protected readonly canShareUav$ = this.localStore.selectByKey("canShareUav");
    protected readonly isShortMode$ = this.localStore.selectByKey("isShortMode");
    protected readonly canManageUav$ = this.localStore.selectByKey("canManageUav");

    constructor(private readonly localStore: LocalComponentStore<UavHeaderComponentState>) {
        this.localStore.setState({
            uav: undefined,
            canShareUav: false,
            isShortMode: false,
            canManageUav: false,
        });
    }

    private prepareUavInfo(uav: Uav): UavInfo {
        return {
            name: uav.name,
            manufacturerName: uav.model.manufacturer,
            modelName: uav.model.name,
            modelType: uav.model.type,
            serialNumbers: uav.serialNumbers,
            uavClasses: uav.uavClasses,
            imageUrl: uav.model.imageUrl,
            ownerName: uav.ownerName,
            isShared: uav.isShared,
            isCustom: uav.isCustom,
            isCeCompliant: uav.isCeCompliant,
            isSwarm: uav.isSwarm,
        };
    }
}
