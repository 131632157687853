import { InjectionToken } from "@angular/core";

export interface PilotProfileEndpoints {
    getPilotProfile: string;
    managePilotInsurancePolicy: string;
    editPilotAddress: string;
    downloadDocument: string;
    managePansaUtmLink: string;
    addPilotCompetency: string;
    managePilotCompetency: string;
    uploadForeignCompetency: string;
    downloadForeignCompetency: string;
}

export const PILOT_PROFILE_ENDPOINTS = new InjectionToken<PilotProfileEndpoints>("PilotProfile endpoints");

export interface ForeignCompetenciesApiEndpoints {
    uploadFile: string;
    downloadFile: string;
}

export const FOREIGN_COMPETENCIES_API_ENDPOINTS = new InjectionToken<ForeignCompetenciesApiEndpoints>(
    "Foreign competencies upload api endpoints"
);
