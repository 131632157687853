import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import {
    Address,
    Alpha3CountryCode,
    IdentityDocument,
    IdentityDocumentStatus,
    IdentityDocumentType,
    PhoneNumber,
} from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    NationalNodeUserRegistration,
    User,
    UserProfileError,
    UserProfileErrorType,
    UsersAttorneyPower,
} from "../models/user-profile.models";

export interface GetUserResponseBody {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: Alpha3CountryCode;
    phoneNumber: PhoneNumber;
    languageTag: string;
    email: string;
    legalGuardian?: {
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber: PhoneNumber;
        email: string;
        address: Address;
        nationality: string;
        dateOfBirth: string;
    };
    legalGuardianConfirmationRequest?: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    };
    identityDocument: {
        id: string;
        type: IdentityDocumentType;
        filename: string;
        approved: boolean;
        expirationDate: string;
        status: IdentityDocumentStatus;
        statusReason: string;
        imageMediaType: string;
    };
    easaMember: boolean;
    attorneyPowers?: UsersAttorneyPower[];
    legalAge: boolean;
    avatarId?: string;
}

export interface NationalNodeUserRegistrationResponseBody {
    userId: string;
    emailVerified: boolean;
    phoneNumberVerified: boolean;
    email: string;
    phoneNumber: PhoneNumber;
}

export const convertIdentityDocumentResponseBodyToIdentityDocument = ({ identityDocument }: GetUserResponseBody): IdentityDocument =>
    identityDocument && {
        id: identityDocument.id,
        type: identityDocument.type,
        filename: identityDocument.filename,
        isApproved: identityDocument.approved,
        expirationDate: identityDocument.expirationDate,
        status: identityDocument.status,
        statusReason: identityDocument.statusReason,
        imageMediaType: identityDocument.imageMediaType,
    };

export const convertGetUserResponseBodyToUser = (response: GetUserResponseBody): User => ({
    id: response.id,
    firstName: response.firstName,
    lastName: response.lastName,
    dateOfBirth: DateUtils.convertStringDateToDateWithoutTimeZone(response.dateOfBirth),
    nationality: response.nationality,
    phoneNumber: response.phoneNumber,
    languageTag: response.languageTag,
    email: response.email,
    legalGuardian: response.legalGuardian
        ? {
              id: response.legalGuardian.id,
              firstName: response.legalGuardian.firstName,
              lastName: response.legalGuardian.lastName,
              phoneNumber: response.legalGuardian.phoneNumber,
              email: response.legalGuardian.email,
              address: response.legalGuardian.address,
              nationality: response.legalGuardian.nationality,
              dateOfBirth: DateUtils.convertStringDateToDateWithoutTimeZone(response.legalGuardian.dateOfBirth),
          }
        : undefined,
    legalGuardianConfirmationRequest: response?.legalGuardianConfirmationRequest,
    identityDocument: convertIdentityDocumentResponseBodyToIdentityDocument(response),
    isEasaMember: response.easaMember,
    attorneyPowers: response.attorneyPowers,
    isLegalAge: response.legalAge,
    avatarId: response.avatarId,
});

export const convertGetNationalNodeUserRegistrationResponseBodyToNationalNodeUserRegistration = (
    response: NationalNodeUserRegistrationResponseBody
): NationalNodeUserRegistration => ({
    userId: response.userId,
    email: response.email,
    phoneNumber: response.phoneNumber,
    isEmailVerified: response.emailVerified,
    isPhoneNumberVerified: response.phoneNumberVerified,
});

export const convertGetProfileErrorResponse = (errorResponse: HttpErrorResponse): UserProfileError => {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: UserProfileErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: UserProfileErrorType.NotFound };
        default:
            return { type: UserProfileErrorType.Unknown };
    }
};

export const convertGetNationalNodeUserRegistrationProfileErrorResponse = (errorResponse: HttpErrorResponse): UserProfileError => {
    if (
        errorResponse.status === HttpStatusCode.BadRequest &&
        errorResponse.error?.generalMessage === "user_directory.user.min_age_required"
    ) {
        return { type: UserProfileErrorType.NationalNodeUserMinAgeRequired };
    }

    return convertGetProfileErrorResponse(errorResponse);
};

export const convertEmailChangeErrorResponse = (errorResponse: HttpErrorResponse): UserProfileError => {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: UserProfileErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: UserProfileErrorType.CannotSendVerificationCodeForEmailChange };
        case HttpStatusCode.BadRequest:
            return { type: UserProfileErrorType.IncorrectVerificationCode };
        case HttpStatusCode.Conflict:
            return { type: UserProfileErrorType.EmailConflict };
        default:
            return { type: UserProfileErrorType.Unknown };
    }
};

export const convertPhoneNumberErrorResponse = (errorResponse: HttpErrorResponse): UserProfileError => {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: UserProfileErrorType.NotAuthorized };
        case HttpStatusCode.NotFound:
            return { type: UserProfileErrorType.CannotSendVerificationCodeForPhoneChange };
        case HttpStatusCode.BadRequest:
            return { type: UserProfileErrorType.IncorrectVerificationCode };
        case HttpStatusCode.Conflict:
            return { type: UserProfileErrorType.PhoneConflict };
        default:
            return { type: UserProfileErrorType.Unknown };
    }
};
