import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

const WarningPriority = 1;
const ErrorPriority = 2;
const InfoPriority = 3;

enum Priority {
    Warning = WarningPriority,
    Error = ErrorPriority,
    Info = InfoPriority,
}

interface WarningData {
    priority: Priority;
    tooltip: string;
}

const GRC_ERRORS: Record<string, WarningData> = {
    grc5: { priority: Priority.Warning, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.GRC.firstText" },
    grc6: { priority: Priority.Error, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.GRC.secondText" },
    grc7: { priority: Priority.Error, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.GRC.secondText" },
    grc8AndHigher: { priority: Priority.Info, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.GRC.thirdText" },
};

const ARC_ERRORS: Record<string, WarningData> = {
    arcC: { priority: Priority.Warning, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.ARC.firstText" },
    arcD: { priority: Priority.Error, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.ARC.secondText" },
};

const SAIL_ERRORS: Record<string, WarningData> = {
    sailIV: { priority: Priority.Warning, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.SAIL.firstText" },
    sailV: { priority: Priority.Error, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.SAIL.secondText" },
    sailVI: { priority: Priority.Error, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.SAIL.secondText" },
    sailCertifiedCategory: { priority: Priority.Info, tooltip: "dtmWebAppLibMission.summaryStep.analysis.sora.warnings.SAIL.thirdText" },
};

const GRC_HIGH_NUMBER = 8;
const GRC_5 = 5;
const GRC_6 = 6;
const GRC_7 = 7;
const ARC_3 = 3;
const ARC_4 = 4;
const SAIL_4 = 4;
const SAIL_5 = 5;
const SAIL_6 = 6;

interface SoraIssuesWarningComponentState {
    grc: number | undefined;
    arc: number | undefined;
    sail: number | undefined;
    isCertifiedCategory: boolean;
}

@Component({
    selector: "dtm-web-app-lib-sora-issues-warning",
    templateUrl: "./sora-issues-warning.component.html",
    styleUrls: ["./sora-issues-warning.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SoraIssuesWarningComponent {
    @Input() public set grc(value: number | undefined) {
        this.localStore.patchState({ grc: value });
    }
    @Input() public set arc(value: number | undefined) {
        this.localStore.patchState({ arc: value });
    }
    @Input() public set sail(value: number | undefined) {
        this.localStore.patchState({ sail: value });
    }
    @Input() public set isCertifiedCategory(value: BooleanInput) {
        this.localStore.patchState({ isCertifiedCategory: coerceBooleanProperty(value) });
    }

    protected readonly error$ = combineLatest([
        this.localStore.selectByKey("grc"),
        this.localStore.selectByKey("arc"),
        this.localStore.selectByKey("sail"),
        this.localStore.selectByKey("isCertifiedCategory"),
    ]).pipe(map(([grc, arc, sail, isCertifiedCategory]) => this.getError(grc, arc, sail, isCertifiedCategory)));

    constructor(private readonly localStore: LocalComponentStore<SoraIssuesWarningComponentState>) {
        this.localStore.setState({
            grc: undefined,
            arc: undefined,
            sail: undefined,
            isCertifiedCategory: false,
        });
    }

    private getError(
        grc: number | undefined,
        arc: number | undefined,
        sail: number | undefined,
        isCertifiedCategory: boolean
    ): { color: string; tooltip: string } | undefined {
        const error = [this.getGrcError(grc), this.getArcError(arc), this.getSailError(sail, isCertifiedCategory)]
            .filter((data) => data?.priority)
            .sort((left, right) => (right?.priority ?? 0) - (left?.priority ?? 0))?.[0];

        if (!error) {
            return undefined;
        }

        return {
            tooltip: error.tooltip,
            color: error.priority === Priority.Warning ? "warning" : error.priority === Priority.Error ? "error" : "info",
        };
    }

    private getGrcError(grc: number | undefined) {
        switch (grc) {
            case GRC_5:
                return GRC_ERRORS.grc5;
            case GRC_6:
                return GRC_ERRORS.grc6;
            case GRC_7:
                return GRC_ERRORS.grc7;
            default:
                return grc && grc >= GRC_HIGH_NUMBER ? GRC_ERRORS.grc8AndHigher : undefined;
        }
    }

    private getArcError(arc: number | undefined) {
        switch (arc) {
            case ARC_3:
                return ARC_ERRORS.arcC;
            case ARC_4:
                return ARC_ERRORS.arcD;
            default:
                return undefined;
        }
    }

    private getSailError(sail: number | undefined, isCertifiedCategory: boolean) {
        if (isCertifiedCategory) {
            return SAIL_ERRORS.sailCertifiedCategory;
        }

        switch (sail) {
            case SAIL_4:
                return SAIL_ERRORS.sailIV;
            case SAIL_5:
                return SAIL_ERRORS.sailV;
            case SAIL_6:
                return SAIL_ERRORS.sailVI;
            default:
                return undefined;
        }
    }
}
