<dtm-ui-wizard-step-wrapper
    [stepNumber]="2"
    [stepsAmount]="4"
    [title]="'dtmWebAppLibSpecPermApp.operationDetailsStep.header' | transloco"
    [isSaveDraftProcessing]="isSaveDraftProcessing$ | ngrxPush"
    [nextButtonLabel]="'dtmWebAppLibSpecPermApp.nextButtonLabel' | transloco"
    (next)="goToNextStep()"
    (saveDraft)="saveDataToDraft()"
    (back)="back.emit()"
    dtmUiInvalidFormScrollable
    *ngrxLet="{
        availableTrainings: availableTrainings$,
        activeLanguage: activeLanguage$,
        availableCrewMembers: availableCrewMembers$,
        availableOperationScenarios: availableOperationScenarios$,
    } as vm"
>
    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.basicData.header" | transloco }}
    </h2>
    <section class="basic-data">
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.basicData.operationsManualVersionLabel" | transloco }}
            </label>
            <input matInput type="text" [formControl]="operationDetailsForm.controls.operationsManualVersion" />
            <div
                class="field-error"
                *dtmUiFieldHasError="operationDetailsForm.controls.operationsManualVersion; name: ['required', 'pattern']"
            >
                {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
            </div>
            <div
                class="field-error"
                *dtmUiFieldHasError="operationDetailsForm.controls.operationsManualVersion; name: 'maxlength'; error as error"
            >
                {{
                    "dtmWebAppLibSpecPermApp.operationDetailsStep.basicData.operationsManualVersionMaxLengthValueError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
        </dtm-ui-input-field>
        <div class="operations-manual-chapter">
            <label>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.basicData.operationsManualChapter.label" | transloco }}
            </label>
            <span class="prefix">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.basicData.operationsManualChapter.prefixLabel" | transloco }}
            </span>
            <dtm-ui-input-field
                [isClearable]="false"
                [hasErrors]="
                    operationDetailsForm.controls.operationsManualChapter.touched &&
                    operationDetailsForm.controls.operationsManualChapter.invalid
                "
            >
                <input
                    matInput
                    type="number"
                    inputmode="numeric"
                    [formControl]="operationDetailsForm.controls.operationsManualChapter"
                    [placeholder]="'dtmWebAppLibSpecPermApp.operationDetailsStep.basicData.operationsManualChapter.placeholder' | transloco"
                />
            </dtm-ui-input-field>
            <dtm-ui-form-errors>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.operationsManualChapter; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.operationsManualChapter; name: 'pattern'">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.basicData.operationsManualChapter.invalidValueError" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </div>
    </section>
    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.header" | transloco }}
    </h2>
    <dtm-web-app-lib-spec-perm-app-operation-area-control
        [formControl]="operationDetailsForm.controls.operationArea"
        [isProcessing]="isProcessing$ | ngrxPush"
        [predefinedAreas]="predefinedAreas$ | ngrxPush"
        [customAreaInitialViewbox]="customAreaInitialViewbox$ | ngrxPush"
        [isCustomAreaDisabled]="isCustomAreaDisabled$ | ngrxPush"
        (areaPreviewShow)="areaPreviewShow.emit($event)"
        dtmUiMarkValueAccessorControlsAsTouched
    >
    </dtm-web-app-lib-spec-perm-app-operation-area-control>
    <dtm-ui-form-errors>
        <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.operationArea; name: 'requiredTouched'">
            {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.operationArea; name: 'areaNotSet'">
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaNotDefinedError" | transloco }}
        </div>
    </dtm-ui-form-errors>
    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.pilotCompetenciesAndTrainings.header" | transloco }}
    </h2>
    <dtm-web-app-lib-spec-perm-app-pilot-competencies
        [availableOperationScenarios]="vm.availableOperationScenarios"
        [formControl]="operationDetailsForm.controls.pilotCompetencies"
        dtmUiMarkValueAccessorControlsAsTouched
    >
    </dtm-web-app-lib-spec-perm-app-pilot-competencies>
    <dtm-web-app-lib-spec-perm-app-pilot-trainings
        [availableTrainings]="vm.availableTrainings"
        [value]="requiredPilotTrainings$ | ngrxPush"
        [isEditable]="false"
        [headerLabel]="'dtmWebAppLibSpecPermApp.operationDetailsStep.pilotCompetenciesAndTrainings.requiredTrainingsHeader' | transloco"
        [subheaderLabel]="
            'dtmWebAppLibSpecPermApp.operationDetailsStep.pilotCompetenciesAndTrainings.requiredTrainingsSubheader' | transloco
        "
        [activeLanguage]="vm.activeLanguage"
    >
    </dtm-web-app-lib-spec-perm-app-pilot-trainings>
    <dtm-web-app-lib-spec-perm-app-pilot-trainings
        [availableTrainings]="optionalAvailablePilotTrainings$ | ngrxPush"
        [formControl]="operationDetailsForm.controls.pilotOptionalTrainings"
        [headerLabel]="'dtmWebAppLibSpecPermApp.operationDetailsStep.pilotCompetenciesAndTrainings.optionalTrainingsHeader' | transloco"
        [subheaderLabel]="
            'dtmWebAppLibSpecPermApp.operationDetailsStep.pilotCompetenciesAndTrainings.optionalTrainingsSubheader' | transloco
        "
        [activeLanguage]="vm.activeLanguage"
        dtmUiMarkValueAccessorControlsAsTouched
    >
    </dtm-web-app-lib-spec-perm-app-pilot-trainings>
    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.additionalCrewHeader" | transloco }}
    </h2>
    <dtm-web-app-lib-spec-perm-app-additional-crew
        [availableCrewMembers]="vm.availableCrewMembers"
        [formControl]="operationDetailsForm.controls.additionalCrewMembers"
        dtmUiMarkValueAccessorControlsAsTouched
    >
    </dtm-web-app-lib-spec-perm-app-additional-crew>
    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.competenciesAndTrainingsHeader" | transloco }}
    </h2>
    <dtm-web-app-lib-spec-perm-app-crew-competencies
        [availableOperationScenarios]="vm.availableOperationScenarios"
        [additionalCrewMembers]="operationDetailsForm.controls.additionalCrewMembers.value"
        [formControl]="operationDetailsForm.controls.crewCompetencies"
        dtmUiMarkValueAccessorControlsAsTouched
    >
    </dtm-web-app-lib-spec-perm-app-crew-competencies>
    <dtm-web-app-lib-spec-perm-app-crew-trainings
        [availableTrainings]="vm.availableTrainings"
        [activeLanguage]="vm.activeLanguage"
        [additionalCrewMembers]="operationDetailsForm.controls.additionalCrewMembers.value"
        [formControl]="operationDetailsForm.controls.crewTrainings"
        dtmUiMarkValueAccessorControlsAsTouched
    >
    </dtm-web-app-lib-spec-perm-app-crew-trainings>

    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.header" | transloco }}
    </h2>
    <section>
        <dtm-web-app-lib-time-of-the-day-control
            [formControl]="operationDetailsForm.controls.timeOfDay"
            dtmUiMarkValueAccessorControlsAsTouched
        ></dtm-web-app-lib-time-of-the-day-control>
        <div class="environment-conditions-inputs">
            <dtm-ui-input-field [isClearable]="false">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.maxWindSpeedLabel" | transloco }}
                </label>
                <input
                    matInput
                    type="number"
                    [formControl]="operationDetailsForm.controls.maxWindSpeed"
                    inputmode="decimal"
                    autocomplete="off"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.maxWindSpeedUnitLabel" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.maxWindSpeed; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.maxWindSpeed; name: 'min'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.maxWindSpeedLessThanMinError"
                            | transloco
                                : {
                                      minValue: error.min | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.maxWindSpeedUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.maxWindSpeed; name: 'max'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.maxWindSpeedGreaterThanMaxError"
                            | transloco
                                : {
                                      maxValue: error.max | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.maxWindSpeedUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field [isClearable]="false">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minVisibilityLabel" | transloco }}
                </label>
                <input
                    matInput
                    type="number"
                    [formControl]="operationDetailsForm.controls.minVisibility"
                    inputmode="decimal"
                    autocomplete="off"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minVisibilityUnitLabel" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.minVisibility; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.minVisibility; name: 'min'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minVisibilityLessThanMinError"
                            | transloco
                                : {
                                      minValue: error.min | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minVisibilityUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.minVisibility; name: 'max'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minVisibilityGreaterThanMaxError"
                            | transloco
                                : {
                                      maxValue: error.max | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minVisibilityUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field [isClearable]="false">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minTemperatureLabel" | transloco }}
                </label>
                <input
                    matInput
                    type="number"
                    [formControl]="operationDetailsForm.controls.minTemperature"
                    inputmode="decimal"
                    autocomplete="off"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureUnitLabel" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.minTemperature; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *ngIf="
                        operationDetailsForm.errors?.minTemperatureLowerThanMaxTemperature &&
                        operationDetailsForm.controls.maxTemperature.touched &&
                        operationDetailsForm.controls.minTemperature.touched
                    "
                >
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minTemperatureLowerThanMaxTemperatureError"
                            | transloco
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.minTemperature; name: 'min'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureLessThanMinError"
                            | transloco
                                : {
                                      minValue: error.min | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.minTemperature; name: 'max'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureGreaterThanMaxError"
                            | transloco
                                : {
                                      maxValue: error.max | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field [isClearable]="false">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.maxTemperatureLabel" | transloco }}
                </label>
                <input
                    matInput
                    type="number"
                    [formControl]="operationDetailsForm.controls.maxTemperature"
                    inputmode="decimal"
                    autocomplete="off"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureUnitLabel" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.maxTemperature; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *ngIf="
                        operationDetailsForm.errors?.minTemperatureLowerThanMaxTemperature &&
                        operationDetailsForm.controls.maxTemperature.touched &&
                        operationDetailsForm.controls.minTemperature.touched
                    "
                >
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.minTemperatureLowerThanMaxTemperatureError"
                            | transloco
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.maxTemperature; name: 'min'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureLessThanMinError"
                            | transloco
                                : {
                                      minValue: error.min | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="operationDetailsForm.controls.maxTemperature; name: 'max'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureGreaterThanMaxError"
                            | transloco
                                : {
                                      maxValue: error.max | localizeNumber,
                                      unitLabel:
                                          ("dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.temperatureUnitLabel"
                                          | transloco)
                                  }
                    }}
                </div>
            </dtm-ui-input-field>
        </div>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.isFlightDuringRainLabel' | transloco"
        >
            <div class="tiles">
                <div
                    class="selectable-tile"
                    tabindex="0"
                    [class.selected]="operationDetailsForm.controls.isFlightDuringRain.value === true"
                    (click)="setFlightDuringRain(true)"
                    (keyup.enter)="setFlightDuringRain(true)"
                >
                    <span class="selectable-tile-label">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{
                            "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.isFlightDuringRainValue"
                                | transloco : { value: true }
                        }}
                    </span>
                </div>
                <div
                    class="selectable-tile"
                    tabindex="0"
                    [class.selected]="operationDetailsForm.controls.isFlightDuringRain.value === false"
                    (click)="setFlightDuringRain(false)"
                    (keyup.enter)="setFlightDuringRain(false)"
                >
                    <span class="selectable-tile-label">
                        <dtm-ui-icon name="close-circle"></dtm-ui-icon>

                        {{
                            "dtmWebAppLibSpecPermApp.operationDetailsStep.environmentConditions.isFlightDuringRainValue"
                                | transloco : { value: false }
                        }}
                    </span>
                </div>
            </div>
        </dtm-ui-label-value>
    </section>
</dtm-ui-wizard-step-wrapper>
