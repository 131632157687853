<dtm-ui-lib-wizard-header [wizardId]="wizardId">
    <dtm-ui-lib-wizard-step-header [stepId]="ApplicationCreatorWizardSteps.OperationInfo">
        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="ApplicationCreatorWizardSteps.OperationDetails">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="ApplicationCreatorWizardSteps.Statements">
        {{ "dtmWebAppLibSpecPermApp.statementsStep.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="ApplicationCreatorWizardSteps.ApplicationInfo">
        {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
