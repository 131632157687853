import { ItineraryEditorType, PhoneNumber, UploadedFile } from "@dtm-frontend/shared/ui";
import { FailSafe } from "./uav.models";

export enum MissionContextType {
    Supervisor = "Supervisor",
    Operator = "Operator",
}

export enum MissionType {
    BVLOS = "BVLOS",
    VLOS = "VLOS",
}

export enum AirRiskMitigationsCategory {
    CtrCore = "CTR_CORE",
    Ctr = "CTR",
    MctrCore = "MCTR_CORE",
    Mctr = "MCTR",
    Rmz = "RMZ",
    AtzCore = "ATZ_CORE",
    Atz = "ATZ",
    Hlz = "HLZ",
    EmptyAirspace = "EMPTY_AIRSPACE",
    Tma = "TMA",
    Mtma = "MTMA",
    Other = "OTHER",
}

export enum AirRiskMitigationsArc {
    B = "B",
    C = "C",
    D = "D",
}

export enum DensityBox {
    Lowest = "LOWEST",
    Low = "LOW",
    Medium = "MEDIUM",
    High = "HIGH",
    Highest = "HIGHEST",
    Unknown = "UNKNOWN",
}

export enum MissionProcessingPhase {
    Accepted = "ACCEPTED",
    Rejected = "REJECTED",
    Waiting = "WAITING",
    Unsubmitted = "UNSUBMITTED",
    MissionRealized = "MISSION_REALIZED",
    MissionAbandoned = "MISSION_ABANDONED",
    CaaPermitApplication = "CAA_PERMIT_APPLICATION",
}

export enum MissionPhaseExtensions {
    AcceptedConditionally = "ACCEPTED_CONDITIONALLY",
}

export type MissionProcessingPhaseExtended = MissionProcessingPhase | MissionPhaseExtensions;

export enum MissionPlanSpecificPermitType {
    Sts = "STS",
    Individual = "INDIVIDUAL",
    Luc = "LUC",
}

export enum MissionCategory {
    Specific = "SPECIFIC",
    Open = "OPEN",
    Certified = "CERTIFIED",
}

export enum MissionPlanStatus {
    AnalysisInterrupted = "ANALYSIS_INTERRUPTED",
    Analyzed = "ANALYZED",
    Canceled = "CANCELED",
    Draft = "DRAFT",
    MissionForbidden = "MISSION_FORBIDDEN",
    MissionReady = "MISSION_READY",
    MissionSubmissionStarted = "MISSION_SUBMISSION_STARTED",
    MissionSubmitted = "MISSION_SUBMITTED",
    Unverified = "UNVERIFIED",
    WaitingForManualAcceptations = "WAITING_FOR_MANUAL_ACCEPTATIONS",
}

export enum PlanVerificationStatus {
    None = "NONE",
    Completed = "COMPLETED",
    Failure = "FAILURE",
    Hold = "HOLD",
    Reverted = "REVERTED",
    Canceled = "CANCELED",
}

export enum RobustnessLevel {
    None = "NONE",
    Low = "LOW",
    Medium = "MEDIUM",
    High = "HIGH",
    HighPlus = "HIGH_PLUS",
}

export enum TacticalRiskMitigationMeasuresCategory {
    DETECT = "detect",
    DECIDE = "decide",
    COMMAND = "command",
    EXECUTE = "execute",
    FEEDBACK_LOOP = "feedbackLoop",
}

export enum AirspaceCharacteristics {
    Unknown = "UNKNOWN",
    Controlled = "CONTROLLED",
    Uncontrolled = "UNCONTROLLED",
    Both = "BOTH",
}

export enum M1AStatementsKeys {
    lowerPeopleDensity = "dtmSharedMission.groundRiskMitigationsStatements.m1A.lowerPeopleDensity",
    bystandersShielded = "dtmSharedMission.groundRiskMitigationsStatements.m1A.bystandersShielded",
}

export enum M1BStatementsKeys {
    ableToIdentifyRiskOnTheGround = "dtmSharedMission.groundRiskMitigationsStatements.m1B.ableToIdentifyRiskOnTheGround",
    ableToReduceNumberOfBystandersAtRisk = "dtmSharedMission.groundRiskMitigationsStatements.m1B.ableToReduceNumberOfBystandersAtRisk",
    abnormalSituationAlert = "dtmSharedMission.groundRiskMitigationsStatements.m1B.abnormalSituationAlert",
    abnormalSituationCommand = "dtmSharedMission.groundRiskMitigationsStatements.m1B.abnormalSituationCommand",
    bystandersSafeDistance = "dtmSharedMission.groundRiskMitigationsStatements.m1B.bystandersSafeDistance",
    operationalProceduresDocumented = "dtmSharedMission.groundRiskMitigationsStatements.m1B.operationalProceduresDocumented",
}

export enum M2StatementsKeys {
    parachute = "dtmSharedMission.groundRiskMitigationsStatements.m2.parachute",
    criticalAreaShielded = "dtmSharedMission.groundRiskMitigationsStatements.m2.criticalAreaShielded",
    other = "dtmSharedMission.groundRiskMitigationsStatements.m2.other",
    operatorHasDevelopedProcedures = "dtmSharedMission.groundRiskMitigationsStatements.m2.operatorHasDevelopedProcedures",
    operatorProvidesPracticalAndTheoreticalStaffTraining = "dtmSharedMission.groundRiskMitigationsStatements.m2.operatorProvidesPracticalAndTheoreticalStaffTraining",
    parachuteOperatingInstructionsIsAttached = "dtmSharedMission.groundRiskMitigationsStatements.m2.parachuteOperatingInstructionsIsAttached",
}

export enum AirRiskMitigationSectionKeys {
    commonFlightRules = "dtmSharedMission.airRiskMitigationSectionKey.commonFlightRules",
    commonAirspaceStructures = "dtmSharedMission.airRiskMitigationSectionKey.commonAirspaceStructures",
    timeSlotScheduledFlight = "dtmSharedMission.airRiskMitigationSectionKey.timeSlotScheduledFlight",
    geographicAreaLimitation = "dtmSharedMission.airRiskMitigationSectionKey.geographicAreaLimitation",
    limitationOfFlightTime = "dtmSharedMission.airRiskMitigationSectionKey.limitationOfFlightTime",
}

export interface AirRiskElement {
    category: AirRiskMitigationsCategory;
    designator: string;
    initialArc: AirRiskMitigationsArc;
}

export interface AirRiskAdjacentEmpty {
    initialArc?: AirRiskMitigationsArc;
    residualArc?: AirRiskMitigationsArc;
}

export interface AirRiskOperationalEmpty {
    initialArc: AirRiskMitigationsArc;
    residualArc: AirRiskMitigationsArc;
}

export interface AirRisk {
    elements: AirRiskElement[];
    safetyVolumeHeight: number;
    operationalEmpty?: AirRiskOperationalEmpty;
    adjacentEmpty: AirRiskAdjacentEmpty;
    operationalHeight: number;
}

export interface PopulationDensity {
    boxes?: Partial<Record<DensityBox, number>>;
    peoplePerSquareKilometerAvg?: number;
    peoplePerSquareKilometerMax?: number;
}

export enum AuthorityAcceptationStatus {
    Open = "OPEN",
    Resolved = "RESOLVED",
    Rejected = "REJECTED",
}

export interface MissionPlanDataAndCapabilities {
    plan: MissionPlanData & {
        uav: {
            serialNumbers: string[];
            name: string;
            model: string;
            manufacturer: string;
        };
    };
    flightPurposes?: {
        id: string;
        name: string;
        description: string;
        codename: string;
        loadWeightRequired: boolean;
        commentRequired: boolean;
        emergency: boolean;
    }[];
    operationCategories: MissionPlanOperationCategoryOption[];
    editors: {
        options: ItineraryEditorType[];
        suggested: ItineraryEditorType;
    };
}

export interface MissionPlanStsOperationCategoryOption {
    type: MissionCategory.Specific;
    scenarioName: string;
    specificPermitType: MissionPlanSpecificPermitType.Sts;
    isDisabled: boolean;
    issues: MissionPlanOperationCategoryOptionIssue[];
}

export interface AdditionalCrewMember {
    type: string;
    name: string | undefined;
    phoneNumber: PhoneNumber | undefined;
}

export type AdditionalCrewMemberWatcher = Omit<AdditionalCrewMember, "type">;
export const WATCHER_ID = "UAV_OBSERVER";

export interface MissionPlanResponseBodyCapabilitiesEntity {
    flightType: MissionType;
    operator: string;
    pilot: string;
    uavSetup: string;
    additionalCrew: AdditionalCrewMember[];
    pilotName?: string;
    uavName?: string;
    uavSerialNumbers?: string[];
    setupName?: string;
    trackersIdentifiers?: string[];
}

export interface MissionPlanOperationCategoryOptionIssue {
    code: string;
    args: Record<string, string>;
}

export interface MissionPlanOpenOperationCategoryOption {
    type: MissionCategory.Open;
    scenarioName: string;
    isDisabled: boolean;
    issues: MissionPlanOperationCategoryOptionIssue[];
}

export interface MissionPlanLucOperationCategoryOption {
    type: MissionCategory.Specific;
    shouldSkipSora: boolean;
    specificPermitType: MissionPlanSpecificPermitType.Luc;
    isDisabled: boolean;
}

export interface MissionPlanIndividualOperationCategoryOption {
    type: MissionCategory.Specific;
    specificPermitType: MissionPlanSpecificPermitType.Individual;
    isDisabled: boolean;
}

export type MissionPlanOperationCategoryOption =
    | (
          | MissionPlanOpenOperationCategoryOption
          | MissionPlanStsOperationCategoryOption
          | MissionPlanLucOperationCategoryOption
          | MissionPlanIndividualOperationCategoryOption
          | MissionPlanCertifiedOperationCategoryOption
      ) & { specificCaaPermitId?: string; name?: string };

export interface MissionPlanCertifiedOperationCategoryOption {
    type: MissionCategory.Certified;
    scenarioName: string;
    isDisabled: boolean;
}

export interface MissionPlanInformation {
    name?: string;
    isNameDirty?: boolean;
    description?: string;
    notes?: string;
}

export interface MissionPlanFlightPurpose {
    id: string;
    loadWeight: number | null;
    comment: string | null;
}

export interface MissionPlanContentActions {
    editable: boolean;
    deletable: boolean;
    cloneable: boolean;
    submittable: boolean;
    cancelable: boolean;
}

export interface FormalJustification {
    shouldRequestForPriority?: boolean;
    reason?: string | null;
    attachmentIds: string[];
    attachments?: UploadedFile[];
}

export interface AuthorityAcceptation {
    dtm?: AuthorityAcceptationItem;
    utm?: AuthorityAcceptationItem;
    dss?: AuthorityAcceptationItem;
}

export interface PartialAuthorityAcceptation {
    authorityType: string;
    authorityUnitId: string;
    authorityUnitName: string;
    authorityUnitType: string;
    comment: string;
    status: AuthorityAcceptationStatus;
    updatedAt: Date;
}

export interface AuthorityAcceptationItem {
    acceptationStatus: AuthorityAcceptationStatus;
    comment: string;
    updatedAt: Date;
    isConfirmedByUser: boolean;
}

export interface MissionPlanData {
    id: string;
    thumbnail: string;
    created: Date;
    modified: Date;
    name: string;
    status: MissionPlanStatus;
    phase: MissionProcessingPhaseExtended;
    availableActions: MissionPlanContentActions;
    specificCaaPermitId: string;
    permitBusinessId: string;
    uavId: string;
    uavName?: string;
    uavSetupName: string;
    pilotId: string;
    pilotName?: string;
    operatorId: string;
    operatorName?: string;
    missionSubmittedAt: Date;
    flightStartAtMin: Date;
    flightStartAtMax: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    flightType: MissionType;
    operationCategoryType: MissionCategory;
    flightPurposeComment: string;
    soraSail: number;
    description: string;
    capabilities: MissionPlanResponseBodyCapabilitiesEntity;
    flightPurpose?: MissionPlanFlightPurpose;
    information?: MissionPlanInformation;
    itinerary?: {
        id: string;
        editorType: ItineraryEditorType;
    };
    route?: {
        id: string;
        dtmNames: string[];
        isPathBased?: boolean;
        creationDate: Date;
        modificationDate: Date;
        isDtmOnly: boolean;
    };
    mission?: {
        id: string;
        routeId: string;
        status: MissionPlanStatus;
        startAt: string;
        rejectionJustification?: string;
        rejectedAt?: Date;
        timeOverrideJustification?: string;
    };
    category?: MissionPlanOperationCategoryOption;
    remarks: MissionPlanRemarks;
}

export interface MissionPreviewData {
    id: string;
    phase: MissionProcessingPhaseExtended;
    pilotName?: string;
    operatorName?: string;
    flightStartAtMin: Date;
    flightStartAtMax: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    flightType: MissionType;
    additionalCrew: AdditionalCrewMember[];
    uavName?: string;
    uavSerialNumbers?: string[];
    setupName?: string;
    trackersIdentifiers?: string[];
    flightPurpose?: MissionPlanFlightPurpose;
    routeId?: string;
    itineraryEditorType: ItineraryEditorType;
    rejectionJustification?: string;
    rejectedAt?: Date;
    category?: MissionPlanOperationCategoryOption;
    remarks: MissionPlanRemarks;
}

export interface MissionPlanRemarks {
    authorityAcceptation: AuthorityAcceptation;
    justification?: FormalJustification;
    partialAcceptations?: PartialAuthorityAcceptation[];
}

export interface MissionDataSimple {
    isRoutePathBased: boolean;
    flightStartAtMin: Date;
    flightStartAtMax: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    phase: MissionProcessingPhaseExtended;
    distance: number | undefined;
    operatorName: string | undefined;
    pilotName: string | undefined;
    uavName: string | undefined;
    uavSerialNumbers: string[];
    trackersIdentifiers: string[];
    category: MissionPlanOperationCategoryOption | undefined;
    flightPurpose: {
        nameTranslationKey: string;
        comment: string | undefined;
    };
    additionalCrew: AdditionalCrewMember[];
}

export const AIR_RISK_MITIGATIONS_CATEGORIES = [
    AirRiskMitigationsCategory.CtrCore,
    AirRiskMitigationsCategory.Ctr,
    AirRiskMitigationsCategory.MctrCore,
    AirRiskMitigationsCategory.Mctr,
    AirRiskMitigationsCategory.Rmz,
    AirRiskMitigationsCategory.AtzCore,
    AirRiskMitigationsCategory.Atz,
    AirRiskMitigationsCategory.Hlz,
    AirRiskMitigationsCategory.EmptyAirspace,
    AirRiskMitigationsCategory.Mtma,
    AirRiskMitigationsCategory.Other,
    AirRiskMitigationsCategory.Tma,
];

export interface AirRiskMitigations {
    mitigatingMeasures: Array<{
        category: AirRiskMitigationsCategory;
        arc: AirRiskMitigationsArc;
        methods: Array<{
            title: string;
            details: string;
        }>;
        userStatements: string[];
    }>;
}

export interface SoraSettings {
    customGroundRiskBuffer?: number;
    peopleAssemblyComment?: string;
    controlledGroundAreaComment?: string;
    flightAroundObstacleComment?: string;
    flightSegregatedAirspaceComment?: string;
    flightLowHeightComment?: string;
    isArcAirspaceRiskDecreased?: boolean;
    maxOperationalSpace?: number;
    adjacentPeopleAssemblyComment?: string;
    riskMitigations?: {
        m1A?: RiskMitigationM1A;
        m1B?: RiskMitigationM1B;
        m2?: RiskMitigationM2;
    };
    airRiskMitigations?: AirRiskMitigations;
    tmpr?: TacticalMitigationPerformanceRequirements;
}

interface LowerMitigationLevelEntity<T extends string> {
    userStatements: T[];
    comment?: string;
    attachments?: {
        fileId: string;
        name: string;
        contentType?: string;
        length?: number;
    }[];
}

export interface RiskMitigationM1A {
    robustnessLevel: RobustnessLevel;
    userStatements?: string[];
    lowerPeopleDensity?: LowerMitigationLevelEntity<M1AStatementsKeys>;
    bystandersShielded?: LowerMitigationLevelEntity<M1AStatementsKeys>;
}

export interface RiskMitigationM1B {
    robustnessLevel: RobustnessLevel.Low;
    userStatements?: M1BStatementsKeys[];
    abnormalSituationAlert?: LowerMitigationLevelEntity<M1BStatementsKeys>;
    abnormalSituationCommand?: LowerMitigationLevelEntity<M1BStatementsKeys>;
    bystandersSafeDistance?: LowerMitigationLevelEntity<M1BStatementsKeys>;
}

export interface RiskMitigationM2 {
    robustnessLevel: RobustnessLevel;
    parachute?: LowerMitigationLevelEntity<M2StatementsKeys>;
    criticalAreaShielded?: LowerMitigationLevelEntity<M2StatementsKeys>;
    other?: LowerMitigationLevelEntity<M2StatementsKeys>;
}

export interface TacticalMitigationPerformanceRequirements {
    [TacticalRiskMitigationMeasuresCategory.DETECT]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.DECIDE]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.COMMAND]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.EXECUTE]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP]: { comment: string };
}

export interface MissionUAVSetup {
    id: string;
    displayName: string;
    isPrimary: boolean;
    technicalSpecification: {
        maxFlightSpeed: number;
        maxDroneWidth: number;
        flightSpeedLimit: number;
        failSafe: FailSafe[];
        hasGeofencing: boolean;
        hasGeocage: boolean;
        takeOffMass: number;
    };
}
