import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FILES_UPLOAD_API_PROVIDER, FilesGroup } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { DocumentsHelperService } from "../../services/documents-helper.service";
import { UavDocumentUploadApiService } from "../../services/uav-document-upload-api.service";
import { Uav, UavModelDocumentType } from "../../services/uav.models";

interface UavModelDocumentsComponentState {
    uav: Uav | undefined;
    isManageButtonVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-model-documents[uav]",
    templateUrl: "./uav-model-documents.component.html",
    styleUrls: ["./uav-model-documents.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: UavDocumentUploadApiService,
        },
    ],
})
export class UavModelDocumentsComponent {
    protected readonly documents$ = this.localStore.selectByKey("uav").pipe(map((uav) => (uav ? this.prepareDocuments(uav) : [])));
    protected readonly isManageButtonVisible$ = this.localStore.selectByKey("isManageButtonVisible");
    protected readonly isEmptyState$ = this.documents$.pipe(map((documents) => this.isEmptyState(documents)));

    @Input() public set uav(value: Uav | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set isManageButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isManageButtonVisible: coerceBooleanProperty(value) });
    }

    @Output() public manage = new EventEmitter<void>();

    constructor(
        private readonly localStore: LocalComponentStore<UavModelDocumentsComponentState>,
        private readonly documentsHelperService: DocumentsHelperService
    ) {
        this.localStore.setState({
            uav: undefined,
            isManageButtonVisible: false,
        });
    }

    private prepareDocuments(uav: Uav): FilesGroup<UavModelDocumentType>[] {
        const manual = uav.documents[UavModelDocumentType.Manual];
        const projectDocumentation = uav.documents[UavModelDocumentType.ProjectDocumentation];

        return this.documentsHelperService.convertUavDocumentFilesToUavModelDocumentsFilesGroups(manual, projectDocumentation);
    }

    private isEmptyState(documents: FilesGroup<UavModelDocumentType>[]): boolean {
        return documents.every((document) => document.files.length === 0);
    }
}
