import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NationalNodeUserVerifiedGuard } from "../../guards/national-node-user-verified.guard";
import { OperatorMigrationHeaderComponent } from "./components/operator-migration-header/operator-migration-header.component";
import { OperatorMigrationWizardContentComponent } from "./components/operator-migration-wizard-content/operator-migration-wizard-content.component";

const routes: Routes = [
    {
        path: "operator-migration",
        component: OperatorMigrationWizardContentComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard],
        data: {
            titleTranslationKey: "dtmWebAppLibOperatorMigration.routeTitles.operatorMigrationTitle",
            headerComponent: OperatorMigrationHeaderComponent,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperatorMigrationRoutingModule {}
