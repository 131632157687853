import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ReadonlyCardValueComponentState {
    icon: string | undefined;
    value: string | number | null | undefined;
    suffix: string;
    iconTooltip: string;
    isDisabled: boolean;
}

@Component({
    selector: "dtm-web-app-lib-mwiesip-readonly-card-value",
    templateUrl: "./readonly-card-value.component.html",
    styleUrls: ["./readonly-card-value.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReadonlyCardValueComponent {
    @Input() public set icon(value: IconName | undefined) {
        this.localStore.patchState({ icon: value });
    }
    @Input() public set value(value: string | number | undefined) {
        this.localStore.patchState({ value });
    }
    @Input() public set suffix(value: string) {
        this.localStore.patchState({ suffix: value });
    }
    @Input() public set iconTooltip(value: string) {
        this.localStore.patchState({ iconTooltip: value });
    }
    @Input() public set isDisabled(value: boolean) {
        this.localStore.patchState({ isDisabled: value });
    }

    protected readonly icon$ = this.localStore.selectByKey("icon");
    protected readonly value$ = this.localStore.selectByKey("value");
    protected readonly suffix$ = this.localStore.selectByKey("suffix");
    protected readonly iconTooltip$ = this.localStore.selectByKey("iconTooltip");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    constructor(private readonly localStore: LocalComponentStore<ReadonlyCardValueComponentState>) {
        localStore.setState({
            icon: undefined,
            value: null,
            suffix: "",
            iconTooltip: "",
            isDisabled: false,
        });
    }
}
