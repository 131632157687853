import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, distinctUntilChanged, firstValueFrom } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { atLeastOneMarkedValidator } from "../../../../../shared/validators/at-least-one-marked.validator";
import { AvailableUav, PossiblePermissions, SinglePermission } from "../../../../services/operator-permissions.models";

const STEPS_AMOUNT = 4;
const DEFAULT_POSSIBLE_PERMISSIONS = { operationalAuthorizations: [], availableOperationScenarios: [] };

interface GainingPermissionsStepComponentState {
    possiblePermissions: PossiblePermissions;
    price: number;
    areUavsAvailable: boolean;
    availableUavs: AvailableUav[] | undefined;
    areUavsRequired: boolean;
    stepsAmount: number;
    isPaymentFeatureAvailable: boolean;
    isNextButtonEnabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-permissions-gaining-permissions-step[possiblePermissions][stepsAmount][areUavsAvailable]",
    templateUrl: "./gaining-permissions-step.component.html",
    styleUrls: ["./gaining-permissions-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class GainingPermissionsStepComponent {
    protected readonly price$ = this.localStore.selectByKey("price");
    protected readonly possiblePermissions$ = this.localStore.selectByKey("possiblePermissions");
    protected readonly areUavsRequired$ = this.localStore.selectByKey("areUavsRequired");
    protected readonly availableUavs$ = this.localStore.selectByKey("availableUavs");
    protected readonly areUavsRequiredButUnavailable$ = this.areUavsRequired$.pipe(
        combineLatestWith(this.localStore.selectByKey("areUavsAvailable")),
        map(([areUavsRequired, areUavsAvailable]) => areUavsRequired && !areUavsAvailable)
    );
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount").pipe(RxjsUtils.filterFalsy());
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");
    protected readonly isNextButtonEnabled$ = this.localStore.selectByKey("isNextButtonEnabled");
    protected readonly today = new Date();
    protected readonly nextButtonLabel$ = combineLatest([this.areUavsRequired$, this.isPaymentFeatureAvailable$]).pipe(
        map(([areUavsRequired, isPaymentFeatureAvailable]) => {
            if (areUavsRequired) {
                return this.transloco.translate(
                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.completeUavInformationButtonLabel"
                );
            } else if (isPaymentFeatureAvailable) {
                return this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.paymentButtonLabel");
            }

            return this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.goToSummaryButtonLabel");
        })
    );

    protected statementsForm = new FormGroup({
        statements: new FormArray<FormGroup>([], [atLeastOneMarkedValidator("isSelected")]),
        euRegulationAgreement: new FormControl<boolean>(false, [Validators.requiredTrue]),
        insuranceAgreement: new FormControl<boolean>(false, [Validators.requiredTrue]),
    });

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    @Input() public set possiblePermissions(value: PossiblePermissions | undefined) {
        if (value) {
            this.localStore.patchState({ possiblePermissions: value });

            return;
        }
        this.localStore.patchState({ possiblePermissions: DEFAULT_POSSIBLE_PERMISSIONS });
    }

    @Input() public set stepsAmount(value: number) {
        this.localStore.patchState({ stepsAmount: value });
    }
    @Input() public set areUavsAvailable(value: BooleanInput) {
        this.localStore.patchState({ areUavsAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set availableUavs(value: AvailableUav[] | undefined) {
        this.localStore.patchState({ availableUavs: value});
    }
    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }

    @Output() public next = new EventEmitter<SinglePermission[]>();
    @Output() public areUavsRequiredStateChange = new EventEmitter<boolean>();

    constructor(
        private readonly localStore: LocalComponentStore<GainingPermissionsStepComponentState>,
        private readonly router: Router,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            possiblePermissions: DEFAULT_POSSIBLE_PERMISSIONS,
            price: 0,
            areUavsRequired: false,
            areUavsAvailable: false,
            availableUavs: undefined,
            isPaymentFeatureAvailable: false,
            stepsAmount: STEPS_AMOUNT,
            isNextButtonEnabled: true
        });
        this.initStatementForm();
        this.watchStatementsStateChange();
    }

    protected async goToNextStep(): Promise<void> {
        if (this.statementsForm.valid && !(await firstValueFrom(this.areUavsRequiredButUnavailable$.pipe(untilDestroyed(this))))) {
            this.next.emit(this.selectedPossiblePermissions);
        } else {
            this.statementsForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();
        }
    }

    protected goToPreviousView(): void {
        this.router.navigateByUrl("/operator-permissions");
    }

    private get selectedPossiblePermissions(): SinglePermission[] {
        const selectedPossiblePermissions = this.statementsForm.controls.statements.value.filter((statement) => statement.isSelected);
        const possiblePermissions = this.localStore.selectSnapshotByKey("possiblePermissions");

        return possiblePermissions.operationalAuthorizations
            .filter((operationalAuthorization) =>
                selectedPossiblePermissions.some((possiblePermission) => possiblePermission.id === operationalAuthorization.scenarioId)
            )
            .concat(
                possiblePermissions.availableOperationScenarios.filter((operationScenario) =>
                    selectedPossiblePermissions.some((possiblePermission) => possiblePermission.id === operationScenario.scenarioId)
                )
            );
    }

    private get areUavsRequired(): boolean {
        return this.selectedPossiblePermissions.some((permission) => permission.areUavsRequired);
    }

    private get currentPrice(): number {
        return this.selectedPossiblePermissions.reduce((sum, permission) => sum + permission.price, 0);
    }

    private buildStatementsForm(possiblePermissions: PossiblePermissions): void {
        this.statementsForm.controls.statements.clear();
        possiblePermissions.availableOperationScenarios.forEach((availableOperationScenario: SinglePermission) => {
            this.statementsForm.controls.statements.push(
                new FormGroup({
                    id: new FormControl<string>(availableOperationScenario.scenarioId, { nonNullable: true }),
                    isSelected: new FormControl<boolean>(false, { nonNullable: true }),
                })
            );
        });
        possiblePermissions.operationalAuthorizations.forEach((operationalAuthorization: SinglePermission) => {
            this.statementsForm.controls.statements.push(
                new FormGroup({
                    id: new FormControl<string>(operationalAuthorization.scenarioId, { nonNullable: true }),
                    isSelected: new FormControl<boolean>(false, { nonNullable: true }),
                })
            );
        });
    }

    private watchStatementsStateChange(): void {
        this.statementsForm.controls.statements.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(() => {
            this.localStore.patchState({
                price: this.currentPrice,
                areUavsRequired: this.areUavsRequired,
            });
            this.areUavsRequiredStateChange.emit(this.areUavsRequired);
            this.statementsForm.controls.statements.markAllAsTouched();
            this.localStore.patchState({
                isNextButtonEnabled: this.statementsForm.controls.statements.controls.every((control) => control.valid)
            });
        });
    }

    private initStatementForm(): void {
        this.localStore
            .selectByKey("possiblePermissions")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((possiblePermissions) => {
                this.statementsForm.reset();
                this.buildStatementsForm(possiblePermissions);
            });
    }
}
