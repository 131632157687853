<dtm-ui-filters-container *ngrxLet="{ filterCount: filterCount$, flightPurposes: flightPurposes$ } as vm" [filtersCount]="vm.filterCount">
    <form [formGroup]="filtersForm">
        <div class="filters-container">
            <dtm-ui-input-field>
                <input
                    matInput
                    type="text"
                    formControlName="textSearch"
                    [placeholder]="'dtmWebAppLibMission.missionFilters.searchFieldsPlaceholder' | transloco"
                    [maxlength]="TEXT_SEARCH_MAX_LENGTH"
                />
                <dtm-ui-icon name="search" class="field-suffix"></dtm-ui-icon>
                <div class="field-error" *dtmUiFieldHasError="textSearchControl; name: 'minlength'; error as error">
                    {{ "dtmWebAppLibMission.missionFilters.searchByTextMinLengthError" | transloco : { minLength: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-select-field formControlName="sort" [isClearable]="false">
                <label>{{ "dtmWebAppLibMission.missionFilters.fieldLabels.sorting" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let option of SORT_OPTIONS_MAP | keyvalue" [value]="option.key">
                    {{ "dtmWebAppLibMission.missionFilters.sortByLabel" | transloco : { value: option.value } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-select-field formControlName="groupBy" [isClearable]="false">
                <label>{{ "dtmWebAppLibMission.missionFilters.fieldLabels.grouping" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let period of MISSION_GROUPING_OPTIONS" [value]="period">
                    {{ "dtmWebAppLibMission.missionFilters.groupByLabel" | transloco : { value: period } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-select-field
                formControlName="flightPurpose"
                [placeholder]="'dtmUi.genericInputLabels.allOptionsLabel' | transloco"
                multiple
            >
                <label>{{ "dtmWebAppLibMission.missionFilters.fieldLabels.flightPurpose" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let value of vm.flightPurposes" [value]="value.id">
                    {{ value.name | systemTranslation }}
                    <span class="description" *ngIf="value.description | hasSystemTranslation">
                        &mdash; {{ value.description | systemTranslation }}
                    </span>
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-date-field>
                <label>{{ "dtmWebAppLibMission.missionFilters.fieldLabels.missionDates" | transloco }}</label>
                <mat-date-range-input
                    [rangePicker]="picker"
                    formGroupName="flightDate"
                    *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                >
                    <input matStartDate formControlName="from" [placeholder]="datePickerPlaceholder" />
                    <input matEndDate formControlName="to" [placeholder]="datePickerPlaceholder" />
                </mat-date-range-input>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </dtm-ui-date-field>
        </div>
        <dtm-web-app-lib-mission-type-select formControlName="flightType"></dtm-web-app-lib-mission-type-select>
    </form>
    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [formGroup]="filtersForm"
        [filtersValue]="filtersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="vm.filterCount"
        [dictionaryMap]="flightPurposesLabelsMap$ | ngrxPush"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</dtm-ui-filters-container>
