<dtm-web-app-lib-public-profile-details *ngIf="pilotData$ | ngrxPush as pilotData" [type]="PublicProfileType.Pilot" [details]="pilotData">
</dtm-web-app-lib-public-profile-details>
<div *ngIf="error$ | ngrxPush as error" class="error-container">
    <ng-container [ngSwitch]="error.type">
        <dtm-web-app-lib-public-profile-not-found
            *ngSwitchCase="PublicProfileErrorType.NotFound"
            [type]="PublicProfileType.Pilot"
        ></dtm-web-app-lib-public-profile-not-found>
        <dtm-ui-error
            *ngSwitchDefault
            [errorMessage]="'dtmWebAppLibPublicProfile.errors.cannotGetPilotData' | transloco"
            (reload)="refreshPilotDetails()"
        ></dtm-ui-error>
    </ng-container>
</div>
