import { InjectionToken } from "@angular/core";

export interface OperatorProfileEndpoints {
    getOperatorProfile: string;
    editOperatorCompanyInfo: string;
    manageOperatorInsurancePolicy: string;
    manageOperatorAvatar: string;
    downloadOperatorConfirmationDocument: string;
    getAttorneyPowerCapabilities: string;
    downloadAttorneyTemplate: string;
    addAttorneyPower: string;
    removeAttorneyPower: string;
    attorneyPowerFeeConfirmationUpload: string;
    attorneyPowerDocumentUpload: string;
    getAttorneyPowerDocument: string;
}

export const OPERATOR_PROFILE_ENDPOINTS = new InjectionToken<OperatorProfileEndpoints>("Operator Profile endpoints");
