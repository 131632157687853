import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { DefaultGlobalConfig, ToastContainerDirective, ToastrService } from "ngx-toastr";
import { FtsAlertToastComponent } from "./fts-alert-toast/fts-alert-toast.component";

@Component({
    selector: "dtm-web-app-lib-fts-alert-container",
    templateUrl: "./fts-alert-container.component.html",
    styleUrls: ["./fts-alert-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtsAlertContainerComponent implements OnDestroy {
    @ViewChild(ToastContainerDirective, { static: false }) protected set boundaryViolationToastContainer(value: ToastContainerDirective) {
        this.initToastContainer(value);
    }

    constructor(private readonly toastrService: ToastrService) {}

    public ngOnDestroy(): void {
        this.toastrService.clear();
    }

    private initToastContainer(value: ToastContainerDirective | undefined) {
        if (!value || this.toastrService.overlayContainer === value) {
            return;
        }

        this.toastrService.overlayContainer = value;
        this.toastrService.toastrConfig = {
            ...DefaultGlobalConfig,
            autoDismiss: false,
            closeButton: true,
            tapToDismiss: false,
            disableTimeOut: true,
            maxOpened: 1,
            toastComponent: FtsAlertToastComponent,
        };

        this.toastrService.warning();
    }
}
