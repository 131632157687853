import { Injectable } from "@angular/core";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { PhoneNumber, UIActions } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, switchMap, tap, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { OperatorContextActions } from "../../shared/operator-context/state/operator-context.actions";
import {
    LegalGuardian,
    LegalGuardianConfirmationRequest,
    NationalNodeUserRegistration,
    User,
    UserProfileError,
    UserProfileErrorType,
} from "../models/user-profile.models";
import { UserProfileApiService } from "../services/user-profile-api-service";
import { UserProfileActions } from "./user-profile.actions";

export interface UserProfileStateModel {
    isProcessing: boolean;
    userProfileError: UserProfileError | undefined;
    requestEmailChangeError: UserProfileError | undefined;
    requestPhoneNumberChangeError: UserProfileError | undefined;
    emailAddressSaveError: UserProfileError | undefined;
    updateProfileLanguageError: UserProfileError | undefined;
    phoneNumberSaveError: UserProfileError | undefined;
    resetUserPasswordError: UserProfileError | undefined;
    saveProfileAvatarError: UserProfileError | undefined;
    saveIdentityDocumentError: UserProfileError | undefined;
    downloadDocumentError: UserProfileError | undefined;
    legalGuardianResendConfirmationError: UserProfileError | undefined;
    legalGuardianDataSaveError: UserProfileError | undefined;
    user: User | undefined;
    profileAvatar: string | undefined;
    userId: string | undefined;
    isRequestedEmailChange: boolean;
    isRequestedPhoneChange: boolean;
    changeEmailRequestId: string | undefined;
    changePhoneRequestId: string | undefined;
    newEmailAddress: string | undefined;
    newPhoneNumber: PhoneNumber | undefined;
    deleteIdentityDocumentError: UserProfileError | undefined;
    deleteProfileAvatarError: UserProfileError | undefined;
    nationalNodeUserRegistration: NationalNodeUserRegistration | undefined;
    nationalNodeUserRegistrationError: UserProfileError | undefined;
    requestNationalNodeUserRegistrationEmailChangeError: UserProfileError | undefined;
    requestNationalNodeUserRegistrationPhoneNumberChangeError: UserProfileError | undefined;
    confirmNationalNodeUserRegistrationEmailAddressError: UserProfileError | undefined;
    confirmNationalNodeUserRegistrationPhoneNumberError: UserProfileError | undefined;
    resendNationalNodeUserRegistrationEmailAddressError: UserProfileError | undefined;
    resendNationalNodeUserRegistrationPhoneNumberError: UserProfileError | undefined;
    registerNationalNodeUserError: UserProfileError | undefined;
}

const defaultState: UserProfileStateModel = {
    isProcessing: false,
    userProfileError: undefined,
    requestEmailChangeError: undefined,
    requestPhoneNumberChangeError: undefined,
    legalGuardianResendConfirmationError: undefined,
    legalGuardianDataSaveError: undefined,
    emailAddressSaveError: undefined,
    resetUserPasswordError: undefined,
    updateProfileLanguageError: undefined,
    saveProfileAvatarError: undefined,
    saveIdentityDocumentError: undefined,
    downloadDocumentError: undefined,
    phoneNumberSaveError: undefined,
    user: undefined,
    profileAvatar: undefined,
    userId: undefined,
    isRequestedEmailChange: false,
    isRequestedPhoneChange: false,
    changeEmailRequestId: undefined,
    changePhoneRequestId: undefined,
    newEmailAddress: undefined,
    newPhoneNumber: undefined,
    deleteProfileAvatarError: undefined,
    deleteIdentityDocumentError: undefined,
    nationalNodeUserRegistration: undefined,
    nationalNodeUserRegistrationError: undefined,
    requestNationalNodeUserRegistrationEmailChangeError: undefined,
    requestNationalNodeUserRegistrationPhoneNumberChangeError: undefined,
    confirmNationalNodeUserRegistrationEmailAddressError: undefined,
    confirmNationalNodeUserRegistrationPhoneNumberError: undefined,
    resendNationalNodeUserRegistrationEmailAddressError: undefined,
    resendNationalNodeUserRegistrationPhoneNumberError: undefined,
    registerNationalNodeUserError: undefined,
};

@State<UserProfileStateModel>({
    name: "userProfile",
    defaults: defaultState,
})
@Injectable()
export class UserProfileState {
    constructor(private readonly userProfileApi: UserProfileApiService) {}

    @Selector()
    public static user(state: UserProfileStateModel): User | undefined {
        return state.user;
    }

    @Selector()
    public static legalGuardianConfirmationRequest(state: UserProfileStateModel): LegalGuardianConfirmationRequest | undefined {
        return state.user?.legalGuardianConfirmationRequest;
    }

    @Selector()
    public static legalGuardianDataSaveError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.legalGuardianResendConfirmationError;
    }

    @Selector()
    public static legalGuardian(state: UserProfileStateModel): LegalGuardian | undefined {
        return state.user?.legalGuardian;
    }

    @Selector()
    public static userProfileError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.userProfileError;
    }

    @Selector()
    public static isRequestedEmailChange(state: UserProfileStateModel): boolean {
        return state.isRequestedEmailChange;
    }

    @Selector()
    public static isRequestedPhoneChange(state: UserProfileStateModel): boolean {
        return state.isRequestedPhoneChange;
    }

    @Selector()
    public static requestEmailChangeError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.requestEmailChangeError;
    }

    @Selector()
    public static requestPhoneNumberChangeError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.requestPhoneNumberChangeError;
    }

    @Selector()
    public static emailAddressSaveError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.emailAddressSaveError;
    }

    @Selector()
    public static updateProfileLanguageError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.updateProfileLanguageError;
    }

    @Selector()
    public static saveProfileAvatarError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.saveProfileAvatarError;
    }

    @Selector()
    public static saveIdentityDocumentError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.saveIdentityDocumentError;
    }

    @Selector()
    public static downloadDocumentError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.downloadDocumentError;
    }

    @Selector()
    public static legalGuardianResendConfirmationError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.legalGuardianResendConfirmationError;
    }

    @Selector()
    public static resetUserPasswordError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.resetUserPasswordError;
    }

    @Selector()
    public static phoneNumberSaveError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.phoneNumberSaveError;
    }

    @Selector()
    public static profileAvatar(state: UserProfileStateModel): string | undefined {
        return state.profileAvatar;
    }

    @Selector()
    public static deleteProfileAvatarError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.deleteProfileAvatarError;
    }

    @Selector()
    public static deleteIdentityDocumentError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.deleteIdentityDocumentError;
    }

    @Selector()
    public static isProcessing(state: UserProfileStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static nationalNodeUserRegistration(state: UserProfileStateModel): NationalNodeUserRegistration | undefined {
        return state.nationalNodeUserRegistration;
    }

    @Selector()
    public static nationalNodeUserRegistrationError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.nationalNodeUserRegistrationError;
    }

    @Selector()
    public static requestNationalNodeUserRegistrationEmailChangeError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.requestNationalNodeUserRegistrationEmailChangeError;
    }

    @Selector()
    public static requestNationalNodeUserRegistrationPhoneNumberChangeError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.requestNationalNodeUserRegistrationPhoneNumberChangeError;
    }

    @Selector()
    public static confirmNationalNodeUserRegistrationEmailAddressError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.confirmNationalNodeUserRegistrationEmailAddressError;
    }

    @Selector()
    public static confirmNationalNodeUserRegistrationPhoneNumberError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.confirmNationalNodeUserRegistrationPhoneNumberError;
    }

    @Selector()
    public static resendNationalNodeUserRegistrationEmailAddressError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.resendNationalNodeUserRegistrationEmailAddressError;
    }

    @Selector()
    public static resendNationalNodeUserRegistrationPhoneNumberError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.resendNationalNodeUserRegistrationPhoneNumberError;
    }

    @Selector()
    public static registerNationalNodeUserError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.registerNationalNodeUserError;
    }

    @Action(UserProfileActions.GetUserProfile)
    public getUserProfile(context: StateContext<UserProfileStateModel>, action: UserProfileActions.GetUserProfile) {
        context.patchState({
            isProcessing: true,
            userProfileError: undefined,
        });

        return this.userProfileApi.getUserProfile(action.userId).pipe(
            map((result: User) =>
                context.patchState({
                    user: result,
                    userProfileError: undefined,
                    isProcessing: false,
                    userId: action.userId,
                })
            ),
            catchError((error) => {
                context.patchState({ userProfileError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.GetNationalNodeUserRegistration)
    public getNationalNodeUserRegistration(
        context: StateContext<UserProfileStateModel>,
        action: UserProfileActions.GetNationalNodeUserRegistration
    ) {
        context.patchState({
            isProcessing: true,
            nationalNodeUserRegistrationError: undefined,
        });

        return this.userProfileApi.getNationalNodeUserRegistration(action.userId).pipe(
            map((result) =>
                context.patchState({
                    nationalNodeUserRegistrationError: undefined,
                    isProcessing: false,
                    nationalNodeUserRegistration: result,
                    userId: action.userId,
                })
            ),
            catchError((error) => {
                context.patchState({ nationalNodeUserRegistrationError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.RequestNationalNodeUserEmailChange)
    public requestNationalNodeUserRegistrationEmailChange(
        context: StateContext<UserProfileStateModel>,
        action: UserProfileActions.RequestNationalNodeUserEmailChange
    ) {
        const userId = context.getState().userId;
        if (!userId) {
            return;
        }

        context.patchState({
            isProcessing: true,
            requestNationalNodeUserRegistrationEmailChangeError: undefined,
        });

        return this.userProfileApi.requestNationalNodeUserRegistrationEmailChange(userId, action.email).pipe(
            catchError((error) => {
                context.patchState({ requestNationalNodeUserRegistrationEmailChangeError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.RequestNationalNodeUserPhoneNumberChange)
    public requestNationalNodeUserRegistrationPhoneNumberChange(
        context: StateContext<UserProfileStateModel>,
        action: UserProfileActions.RequestNationalNodeUserPhoneNumberChange
    ) {
        const userId = context.getState().userId;
        if (!userId) {
            return;
        }

        context.patchState({
            isProcessing: true,
            requestNationalNodeUserRegistrationPhoneNumberChangeError: undefined,
        });

        return this.userProfileApi.requestNationalNodeUserRegistrationPhoneNumberChange(userId, action.phoneNumber).pipe(
            catchError((error) => {
                context.patchState({ requestNationalNodeUserRegistrationPhoneNumberChangeError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.ConfirmNationalNodeUserEmail)
    public confirmNationalNodeUserRegistrationEmail(
        context: StateContext<UserProfileStateModel>,
        action: UserProfileActions.ConfirmNationalNodeUserEmail
    ) {
        const userId = context.getState().userId;
        if (!userId) {
            return;
        }

        context.patchState({
            isProcessing: true,
            confirmNationalNodeUserRegistrationEmailAddressError: undefined,
        });

        return this.userProfileApi.confirmNationalNodeUserRegistrationEmail(userId, action.verificationCode).pipe(
            tap(() => context.dispatch(new UserProfileActions.GetNationalNodeUserRegistration(userId))),
            catchError((error) => {
                context.patchState({ confirmNationalNodeUserRegistrationEmailAddressError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.ConfirmNationalNodeUserPhoneNumber)
    public confirmNationalNodeUserRegistrationPhoneNumber(
        context: StateContext<UserProfileStateModel>,
        action: UserProfileActions.ConfirmNationalNodeUserPhoneNumber
    ) {
        const userId = context.getState().userId;
        if (!userId) {
            return;
        }

        context.patchState({
            isProcessing: true,
            confirmNationalNodeUserRegistrationPhoneNumberError: undefined,
        });

        return this.userProfileApi.confirmNationalNodeUserRegistrationPhoneNumber(userId, action.verificationCode).pipe(
            tap(() => context.dispatch(new UserProfileActions.GetNationalNodeUserRegistration(userId))),
            catchError((error) => {
                context.patchState({ confirmNationalNodeUserRegistrationPhoneNumberError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.ResendNationalNodeUserRegistrationEmailVerificationCode)
    public resendNationalNodeUserRegistrationEmailVerificationCode(context: StateContext<UserProfileStateModel>) {
        const userId = context.getState().userId;
        if (!userId) {
            return;
        }

        context.patchState({
            isProcessing: true,
            resendNationalNodeUserRegistrationEmailAddressError: undefined,
        });

        return this.userProfileApi.resendNationalNodeRegistrationEmailVerificationCode(userId).pipe(
            catchError((error) => {
                context.patchState({ resendNationalNodeUserRegistrationEmailAddressError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.RegisterNationalNodeUser)
    public registerNationalNodeUser(context: StateContext<UserProfileStateModel>, action: UserProfileActions.RegisterNationalNodeUser) {
        const userId = context.getState().userId;
        if (!userId) {
            return;
        }

        context.patchState({
            isProcessing: true,
            registerNationalNodeUserError: undefined,
        });

        return this.userProfileApi.registerNationalNodeUser(userId, action.isTermsOfUseAcceptance).pipe(
            switchMap(() => context.dispatch(new OperatorContextActions.GetGlobalCapabilities())),
            catchError((error) => {
                context.patchState({ registerNationalNodeUserError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.ResendNationalNodeUserRegistrationPhoneNumberVerificationCode)
    public resendNationalNodeUserRegistrationPhoneNumberVerificationCode(context: StateContext<UserProfileStateModel>) {
        const userId = context.getState().userId;
        if (!userId) {
            return;
        }

        context.patchState({
            isProcessing: true,
            resendNationalNodeUserRegistrationPhoneNumberError: undefined,
        });

        return this.userProfileApi.resendNationalNodeRegistrationPhoneNumberVerificationCode(userId).pipe(
            catchError((error) => {
                context.patchState({ resendNationalNodeUserRegistrationPhoneNumberError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.RequestEmailAddressChange)
    public requestEmailAddressChange(context: StateContext<UserProfileStateModel>, action: UserProfileActions.RequestEmailAddressChange) {
        context.patchState({ requestEmailChangeError: undefined, isProcessing: true });
        const state = context.getState();

        if (!state.userId) {
            return;
        }

        return this.userProfileApi.requestEmailChange(action.newEmailAddress, state.userId).pipe(
            tap((changeEmailRequestId) => {
                context.patchState({
                    changeEmailRequestId,
                    isRequestedEmailChange: true,
                    newEmailAddress: action.newEmailAddress,
                    isProcessing: false,
                });
            }),
            catchError((error: UserProfileError) => {
                context.patchState({ requestEmailChangeError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.RequestPhoneNumberChange)
    public requestPhoneNumberChange(context: StateContext<UserProfileStateModel>, action: UserProfileActions.RequestPhoneNumberChange) {
        context.patchState({ requestPhoneNumberChangeError: undefined, isProcessing: true });
        const state = context.getState();

        if (!state.userId) {
            return;
        }

        return this.userProfileApi.requestPhoneNumberChange(action.phoneNumber, state.userId).pipe(
            tap((changePhoneRequestId) => {
                context.patchState({
                    changePhoneRequestId,
                    isRequestedPhoneChange: true,
                    newPhoneNumber: action.phoneNumber,
                    isProcessing: false,
                });
            }),
            catchError((error: UserProfileError) => {
                context.patchState({ requestPhoneNumberChangeError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.SaveNewEmailAddress)
    public saveNewEmailAddress(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveNewEmailAddress) {
        context.patchState({ isProcessing: true });

        const { userId, changeEmailRequestId } = context.getState();

        if (!userId || !changeEmailRequestId) {
            return;
        }

        return this.userProfileApi.saveNewEmailAddress(userId, changeEmailRequestId, action.verificationCode).pipe(
            tap(() => {
                const newEmailAddress = context.getState().newEmailAddress;

                if (!newEmailAddress) {
                    return;
                }

                context.dispatch(new UserProfileActions.UpdateUserEmail(newEmailAddress));
            }),
            catchError((error: UserProfileError) => {
                context.patchState({ emailAddressSaveError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.SaveNewPhoneNumber)
    public saveNewPhoneNumber(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveNewPhoneNumber) {
        context.patchState({ isProcessing: true });

        const { userId, changePhoneRequestId } = context.getState();

        if (!userId || !changePhoneRequestId) {
            return;
        }

        return this.userProfileApi.saveNewPhoneNumber(userId, changePhoneRequestId, action.verificationCode).pipe(
            tap(() => {
                const { user, newPhoneNumber } = context.getState();

                if (!newPhoneNumber || !user) {
                    return;
                }

                context.dispatch(new UserProfileActions.UpdateUserPhoneNumber(newPhoneNumber));
            }),
            catchError((error: UserProfileError) => {
                context.patchState({ phoneNumberSaveError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.UpdateUserEmail)
    public updateEmail(context: StateContext<UserProfileStateModel>, action: UserProfileActions.UpdateUserEmail) {
        const user = context.getState().user;

        if (!user) {
            return;
        }

        context.patchState({ user: { ...user, email: action.newEmailAddress }, isProcessing: false });
        context.dispatch(new UserProfileActions.ClearEmailAddressRequestChange());
    }

    @Action(UserProfileActions.UpdateUserPhoneNumber)
    public updatePhoneNumber(context: StateContext<UserProfileStateModel>, action: UserProfileActions.UpdateUserPhoneNumber) {
        const user = context.getState().user;

        if (!user) {
            return;
        }

        context.patchState({ user: { ...user, phoneNumber: action.newPhoneNumber }, isProcessing: false });
        context.dispatch(new UserProfileActions.ClearPhoneRequestChange());
    }

    @Action(UserProfileActions.ClearEmailAddressRequestChange)
    public clearEmailAddressRequestChange(context: StateContext<UserProfileStateModel>) {
        context.patchState({
            isRequestedEmailChange: false,
            changeEmailRequestId: undefined,
            emailAddressSaveError: undefined,
            requestEmailChangeError: undefined,
            newEmailAddress: undefined,
        });
    }

    @Action(UserProfileActions.UpdateProfileLanguage)
    public updateProfileLanguage(context: StateContext<UserProfileStateModel>, action: UserProfileActions.UpdateProfileLanguage) {
        context.dispatch(new UIActions.SetActiveLanguage(action.language));
        context.patchState({ updateProfileLanguageError: undefined });

        return this.userProfileApi.updateProfileLanguage(action.language).pipe(
            catchError((error: UserProfileError) => {
                context.patchState({ updateProfileLanguageError: error });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.ClearPhoneRequestChange)
    public resetPhoneRequestChange(context: StateContext<UserProfileStateModel>) {
        context.patchState({
            isRequestedPhoneChange: false,
            changePhoneRequestId: undefined,
            phoneNumberSaveError: undefined,
            requestPhoneNumberChangeError: undefined,
            newPhoneNumber: undefined,
        });
    }

    @Action(UserProfileActions.ResetUserPassword)
    public resetUserPassword(context: StateContext<UserProfileStateModel>) {
        context.patchState({ resetUserPasswordError: undefined });
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        return this.userProfileApi.resetUserPassword(userId).pipe(
            tap(() => context.dispatch(new AuthActions.Logout())),
            catchError((error: UserProfileError) => {
                context.patchState({ resetUserPasswordError: error });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.GetProfileAvatar)
    public getProfileAvatar(context: StateContext<UserProfileStateModel>, action: UserProfileActions.GetProfileAvatar) {
        return this.userProfileApi.getProfileAvatar(action.userId).pipe(
            tap((profileAvatar: string) => context.patchState({ profileAvatar })),
            catchError(() => EMPTY)
        );
    }

    @Action(UserProfileActions.SaveProfileAvatar)
    public saveProfileAvatar(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveProfileAvatar) {
        context.patchState({ saveProfileAvatarError: undefined, isProcessing: true });
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        return this.userProfileApi.requestAvatarChange(userId, action.base64Image).pipe(
            tap(() => {
                context.patchState({ isProcessing: false });
                context.dispatch([new UserProfileActions.GetProfileAvatar(userId), new OperatorContextActions.GetGlobalCapabilities()]);
            }),
            catchError((error) => {
                context.patchState({ saveProfileAvatarError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.SaveIdentityDocument)
    public saveIdentityDocument(context: StateContext<UserProfileStateModel>, action: UserProfileActions.SaveIdentityDocument) {
        context.patchState({ saveIdentityDocumentError: undefined, isProcessing: true });
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        return this.userProfileApi.saveIdentityDocument(userId, action.savedDocumentData).pipe(
            tap(() => context.dispatch(new UserProfileActions.GetUserProfile(userId))),
            catchError((error) => {
                context.patchState({ saveIdentityDocumentError: error, isProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.DownloadIdentityDocument)
    public downloadIdentityDocument(context: StateContext<UserProfileStateModel>) {
        context.patchState({ downloadDocumentError: undefined });
        const { user, userId } = context.getState();

        if (!userId || !user?.identityDocument.id) {
            return;
        }

        return this.userProfileApi.downloadIdentityDocument(userId, user?.identityDocument.id, user.identityDocument.filename).pipe(
            catchError((error) => {
                context.patchState({ downloadDocumentError: error });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.ResendLegalGuardianEmailAddress)
    public resendLegalGuardianEmailAddress(context: StateContext<UserProfileStateModel>) {
        context.patchState({ legalGuardianResendConfirmationError: undefined });
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        return this.userProfileApi.resendLegalGuardianEmailAddress(userId).pipe(
            catchError((error) => {
                context.patchState({ legalGuardianResendConfirmationError: error });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.ChangeLegalGuardianData)
    public changeLegalGuardianData(context: StateContext<UserProfileStateModel>, action: UserProfileActions.ChangeLegalGuardianData) {
        const { userId } = context.getState();

        if (!userId) {
            return;
        }

        return this.userProfileApi.changeLegalGuardianData(userId, action.confirmationData, action.isDataEdited).pipe(
            tap(() => context.dispatch(new UserProfileActions.GetUserProfile(userId))),
            catchError(() => throwError(() => ({ type: UserProfileErrorType.CannotChangeLegalGuardianConfirmationData })))
        );
    }

    @Action(UserProfileActions.DeleteIdentityDocument)
    public deleteIdentityDocument(context: StateContext<UserProfileStateModel>) {
        context.patchState({ deleteIdentityDocumentError: undefined, isProcessing: true });
        const { user } = context.getState();

        if (!user?.id || !user?.identityDocument.id) {
            return;
        }

        return this.userProfileApi.deleteIdentityDocument(user.id, user.identityDocument.id).pipe(
            tap(() => context.dispatch(new UserProfileActions.GetUserProfile(user.id))),
            catchError((deleteIdentityDocumentError) => {
                context.patchState({ deleteIdentityDocumentError, isProcessing: true });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.DeleteAvatar)
    public deleteAvatar(context: StateContext<UserProfileStateModel>) {
        context.patchState({ deleteProfileAvatarError: undefined, isProcessing: true });
        const user = context.getState().user;

        if (!user?.id) {
            return;
        }

        return this.userProfileApi.deleteUserAvatar(user.id).pipe(
            tap(() => {
                context.patchState({ profileAvatar: undefined, isProcessing: false });
                context.dispatch(new OperatorContextActions.GetGlobalCapabilities());
            }),
            catchError((deleteProfileAvatarError) => {
                context.patchState({ deleteProfileAvatarError, isProcessing: false });

                return EMPTY;
            })
        );
    }
}
