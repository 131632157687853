<ng-container
    *ngrxLet="{
        constraints: constraints$,
        entity: entity$,
        settings: settings$,
        canEditCenterPoint: canEditCenterPoint$,
        canEditTopHeight: canEditTopHeight$,
        canEditBottomHeight: canEditBottomHeight$,
        isCylinder: isCylinder$,
        displayMode: displayMode$,
    } as vm"
>
    <ng-container *ngIf="vm.entity && vm.settings && vm.constraints">
        <ng-container *ngrxLet="vm.entity | invoke : getVerticalBufferOffset as verticalBufferOffset">
            <div class="image-container">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="288"
                    height="144"
                    style="fill: var(--shape-top-bottom-fill-color)"
                    *ngrxLet="vm.entity | invoke : getPointTransform : 'center' as centerPointTransform"
                >
                    <g *ngIf="!vm.isCylinder" style="transform: translateX(101px) scaleX(0.95)" class="prism">
                        <rect
                            class="shape-background"
                            x="6"
                            y="14"
                            width="81"
                            height="83"
                            style="fill: var(--shape-background-color)"
                        ></rect>
                        <path
                            class="shape-bottom-cover cover"
                            [class.editable-cover]="vm.canEditBottomHeight && vm.displayMode.canEdit.shape.bottomHeight"
                            style="stroke: var(--shape-borders-color); stroke-width: 2"
                            d="M72.6848,107H21.3228l-14.09308-9.6887L46.7527,84.0554l39.119,13.2743z"
                        />
                        <line
                            style="stroke: var(--shape-sides-color); stroke-width: 2; stroke-dasharray: 4, 4"
                            x1="46.512199"
                            y1="6"
                            x2="46.512199"
                            y2="86"
                        />
                        <path
                            class="shape-top-cover-fill cover"
                            [class.editable-cover]="vm.canEditTopHeight && vm.displayMode.canEdit.shape.topHeight"
                            style="stroke: var(--shape-borders-color); stroke-width: 2"
                            d="M72.5257,24H21.6676l-14.01-10.6277L46.7564,1.04792L86.3721,13.3811z"
                        />
                        <rect
                            class="shape-background"
                            style="fill: none; stroke: var(--shape-borders-color); stroke-width: 2"
                            x="22.012199"
                            y="24"
                            width="50"
                            height="83"
                        />
                        <line
                            style="stroke: var(--shape-borders-color); stroke-width: 2"
                            x1="87.512199"
                            y1="13.0062"
                            x2="86.993698"
                            y2="97.006104"
                        />
                        <line
                            style="stroke: var(--shape-borders-color); stroke-width: 2"
                            x1="6.5121899"
                            y1="13.0061"
                            x2="5.99999"
                            y2="97.005997"
                        />
                    </g>

                    <g *ngIf="vm.isCylinder" class="cylinder">
                        <path
                            class="shape-background"
                            style="fill: var(--shape-background-color); stroke: var(--shape-sides-color); stroke-width: 2"
                            d="M107.5 12h76v83h-76z"
                        />
                        <path
                            class="shape-top-cover cover"
                            [class.editable-cover]="vm.canEditTopHeight && vm.displayMode.canEdit.shape.topHeight"
                            style="stroke: var(--shape-borders-color); stroke-width: 2"
                            d="M183.5 11.5c0 1.1-.76 2.35-2.64 3.66-1.85 1.29-4.59 2.49-8.07 3.51C165.85 20.72 156.2 22 145.5 22s-20.35-1.28-27.3-3.33c-3.47-1.02-6.21-2.22-8.06-3.51-1.88-1.31-2.64-2.57-2.64-3.66 0-1.1.76-2.35 2.64-3.66 1.85-1.29 4.59-2.49 8.07-3.51C125.15 2.28 134.8 1 145.5 1s20.35 1.28 27.3 3.33c3.47 1.02 6.21 2.22 8.06 3.51 1.88 1.31 2.64 2.57 2.64 3.66z"
                        />
                        <path
                            class="shape-center-point-circle"
                            [style.transform]="centerPointTransform"
                            *ngIf="vm.canEditCenterPoint && vm.displayMode.canEdit.cylinder.centerPoint"
                            d="M183.2 95.96c0 1.1-.77 2.35-2.65 3.66-1.85 1.29-4.59 2.49-8.07 3.51-6.94 2.05-16.59 3.33-27.29 3.33s-20.35-1.28-27.3-3.33c-3.47-1.02-6.21-2.22-8.06-3.51-1.88-1.31-2.64-2.56-2.64-3.66s.76-2.35 2.64-3.66c1.85-1.29 4.59-2.49 8.07-3.51 6.94-2.05 16.59-3.33 27.3-3.33 10.7 0 20.34 1.28 27.28 3.33 3.48 1.02 6.22 2.22 8.07 3.51 1.88 1.31 2.64 2.57 2.64 3.66z"
                            style="
                                fill: none;
                                stroke: var(--shape-sides-color);
                                stroke-dasharray: 4, 2;
                                stroke-dashoffset: 0;
                                stroke-opacity: 1;
                                stroke-width: 2;
                            "
                        />
                        <path
                            class="shape-bottom-cover-fill cover"
                            [class.editable-cover]="vm.canEditBottomHeight && vm.displayMode.canEdit.shape.bottomHeight"
                            d="M183.5 96.5c0 1.1-.76 2.35-2.64 3.66-1.85 1.29-4.59 2.49-8.07 3.51-6.94 2.05-16.59 3.33-27.29 3.33s-20.35-1.28-27.3-3.33c-3.47-1.02-6.21-2.23-8.06-3.51-1.88-1.31-2.64-2.56-2.64-3.66s.76-2.35 2.64-3.66c1.85-1.29 4.59-2.49 8.07-3.51C125.15 87.28 134.8 86 145.5 86s20.35 1.28 27.3 3.33c3.47 1.02 6.21 2.22 8.06 3.51 1.88 1.31 2.64 2.56 2.64 3.66z"
                        />
                        <path
                            class="shape-bottom-cover-stroke"
                            style="fill: none; stroke: var(--shape-borders-color); stroke-width: 2"
                            d="M183.5 96.5c0 1.1-.76 2.35-2.64 3.66-1.85 1.29-4.59 2.49-8.07 3.51-6.94 2.05-16.59 3.33-27.29 3.33s-20.35-1.28-27.3-3.33c-3.47-1.02-6.21-2.23-8.06-3.51-1.88-1.31-2.64-2.56-2.64-3.66s.76-2.35 2.64-3.66c1.85-1.29 4.59-2.49 8.07-3.51C125.15 87.28 134.8 86 145.5 86s20.35 1.28 27.3 3.33c3.47 1.02 6.21 2.22 8.06 3.51 1.88 1.31 2.64 2.56 2.64 3.66z"
                        />
                    </g>

                    <path
                        class="agl-legend-top-height"
                        style="stroke: var(--disabled-label-hint-lines-color); stroke-dasharray: 4, 4"
                        d="M82.5 10.5h24"
                    />
                    <path
                        class="agl-legend-bottom-height"
                        style="stroke: var(--disabled-label-hint-lines-color); stroke-dasharray: 4, 4"
                        d="M82.5 95.5h24"
                    />
                    <path
                        class="amsl-legend-top-height"
                        style="stroke: var(--disabled-label-hint-lines-color); stroke-dasharray: 4, 4"
                        d="M186.5 10.5h24"
                    />
                    <path
                        class="amsl-legend-bottom-height"
                        style="stroke: var(--disabled-label-hint-lines-color); stroke-dasharray: 4, 4"
                        d="M186.5 95.5h24"
                    />

                    <g class="radius" *ngIf="vm.isCylinder">
                        <path style="stroke: var(--shape-borders-color); stroke-width: 2" d="M145.5 96h37" />
                        <text class="enabled-label">
                            <tspan x="163" y="92">r</tspan>
                        </text>
                    </g>

                    <g class="shape-center-point-with-label" [style.transform]="centerPointTransform">
                        <circle cx="145.5" cy="95.96" r="2.5" style="fill: var(--center-point-color); stroke: var(--center-point-color)" />
                        <text class="enabled-label">
                            <tspan x="138.5" y="109.5">{{ vm.entity.pointLabel$ | ngrxPush }}</tspan>
                        </text>
                    </g>

                    <g
                        class="inlet-point"
                        *ngIf="
                            vm.entity.type === ViewItineraryEntityType.InternalCylinder ||
                            vm.entity.type === ViewItineraryEntityType.InternalPrism ||
                            vm.entity.type === ViewItineraryEntityType.Landing
                        "
                        [style.transform]="vm.entity | invoke : getPointTransform : 'inlet'"
                    >
                        <circle cx="106.63" cy="95.96" r="3" style="fill: var(--external-points-color)" />
                        <text class="enabled-label">
                            <tspan x="108" y="111" text-anchor="middle">
                                {{ vm.entity.inletPointLabel$ | ngrxPush }}
                            </tspan>
                        </text>
                    </g>

                    <g
                        class="outlet-point"
                        *ngIf="
                            vm.entity.type === ViewItineraryEntityType.InternalCylinder ||
                            vm.entity.type === ViewItineraryEntityType.InternalPrism ||
                            vm.entity.type === ViewItineraryEntityType.Takeoff
                        "
                        [style.transform]="vm.entity | invoke : getPointTransform : 'outlet'"
                    >
                        <circle cx="182.9" cy="95.96" r="3" style="fill: var(--external-points-color)" />
                        <text class="enabled-label">
                            <tspan x="182" y="111" text-anchor="middle">
                                {{ vm.entity.outletPointLabel$ | ngrxPush }}
                            </tspan>
                        </text>
                    </g>

                    <g class="agl-labels">
                        <text
                            class="disabled-label"
                            *ngIf="
                                !vm.canEditTopHeight ||
                                vm.settings.heightType === HeightType.AMSL ||
                                !vm.displayMode.canEdit.shape.topHeight
                            "
                        >
                            <tspan x="75" y="13.6" text-anchor="end">
                                {{ vm.entity.topHeight | number : "1.0-0" | localizeNumber }}
                            </tspan>
                        </text>
                        <text
                            class="disabled-label"
                            *ngIf="
                                vm.canEditCenterPoint &&
                                vm.settings.heightType === HeightType.AMSL &&
                                vm.displayMode.canEdit.cylinder.centerPoint
                            "
                        >
                            <tspan x="75" y="57.6" text-anchor="end">
                                {{
                                    vm.entity
                                        | invoke : getCenterPointHeight : vm.entity.topHeight - verticalBufferOffset
                                        | number : "1.0-0"
                                        | localizeNumber
                                }}
                            </tspan>
                        </text>
                        <text
                            class="disabled-label"
                            *ngIf="
                                !vm.canEditBottomHeight ||
                                vm.settings.heightType === HeightType.AMSL ||
                                !vm.displayMode.canEdit.shape.bottomHeight
                            "
                        >
                            <tspan x="75" y="98.6" text-anchor="end">
                                {{
                                    +((vm.entity.bottomHeight | number : "1.0-0" | localizeNumber) ?? 0) ||
                                        ("dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.groundLevelAcronym" | transloco)
                                }}
                            </tspan>
                        </text>
                        <text class="disabled-label">
                            <tspan x="75" y="139.6" text-anchor="end">
                                {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.shapeViewAglLegend" | transloco }}
                            </tspan>
                        </text>
                    </g>

                    <g class="amsl-labels">
                        <text
                            class="disabled-label"
                            *ngIf="
                                !vm.canEditTopHeight || vm.settings.heightType === HeightType.AGL || !vm.displayMode.canEdit.shape.topHeight
                            "
                        >
                            <tspan x="215.5" y="13.6">
                                <ng-container *ngIf="vm.entity.amslOffset; else amslOffsetLoadingTemplate">
                                    {{ vm.entity.topHeight + vm.entity.amslOffset | number : "1.0-0" | localizeNumber }}
                                </ng-container>
                            </tspan>
                        </text>
                        <text
                            class="disabled-label"
                            *ngIf="
                                vm.canEditCenterPoint &&
                                vm.settings.heightType === HeightType.AGL &&
                                vm.displayMode.canEdit.cylinder.centerPoint &&
                                (vm.entity.type === ViewItineraryEntityType.Landing || vm.entity.type === ViewItineraryEntityType.Takeoff)
                            "
                        >
                            <tspan x="215.5" y="57.6">
                                <ng-container *ngIf="vm.entity.amslOffset; else amslOffsetLoadingTemplate">
                                    {{
                                        (vm.entity.centerPointHeight ?? vm.entity.topHeight - verticalBufferOffset) + vm.entity.amslOffset
                                            | number : "1.0-0"
                                            | localizeNumber
                                    }}
                                </ng-container>
                            </tspan>
                        </text>
                        <text
                            class="disabled-label"
                            *ngIf="
                                !vm.canEditBottomHeight ||
                                vm.settings.heightType === HeightType.AGL ||
                                !vm.displayMode.canEdit.shape.bottomHeight
                            "
                        >
                            <tspan x="215.5" y="98.6">
                                <ng-container *ngIf="vm.entity.amslOffset; else amslOffsetLoadingTemplate">
                                    {{ vm.entity.bottomHeight + vm.entity.amslOffset | number : "1.0-0" | localizeNumber }}
                                </ng-container>
                            </tspan>
                        </text>
                        <text class="disabled-label">
                            <tspan x="215.5" y="139.6">
                                {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.shapeViewAmslLegend" | transloco }}
                            </tspan>
                        </text>
                    </g>
                </svg>

                <ng-container
                    *ngIf="
                        vm.canEditCenterPoint &&
                        vm.displayMode.canEdit.cylinder.centerPoint &&
                        (vm.entity.type === ViewItineraryEntityType.Landing || vm.entity.type === ViewItineraryEntityType.Takeoff)
                    "
                >
                    <dtm-ui-input-field
                        class="point-height"
                        [class.agl]="vm.settings.heightType === HeightType.AGL"
                        [class.amsl]="vm.settings.heightType === HeightType.AMSL"
                    >
                        <dtm-ui-icon
                            name="complex-cylinder-point-center"
                            class="field-prefix"
                            [matTooltip]="vm.settings.heightType | invoke : getPointHeightLabelTranslationKey | transloco"
                        ></dtm-ui-icon>
                        <ng-container [ngSwitch]="true">
                            <input
                                *ngSwitchCase="vm.settings.heightType === HeightType.AGL"
                                matInput
                                dtmUiClampInput
                                type="number"
                                inputmode="numeric"
                                [value]="vm.entity.centerPointHeight ?? vm.entity.topHeight - verticalBufferOffset | roundNumber"
                                [min]="
                                    vm.entity.centerPointHeight ?? vm.constraints.min.height + verticalBufferOffset
                                        | invoke : convertToRoundedValue
                                "
                                [max]="
                                    vm.entity.centerPointHeight ?? vm.constraints.max.height - verticalBufferOffset
                                        | invoke : convertToRoundedValue
                                "
                                step="1"
                                required
                                #pointHeightAglInput
                                (clampedInput)="topHeightChange.emit(pointHeightAglInput.valueAsNumber + verticalBufferOffset)"
                            />
                            <ng-container *ngSwitchCase="vm.settings.heightType === HeightType.AMSL && vm.entity.amslOffset !== undefined">
                                <input
                                    matInput
                                    dtmUiClampInput
                                    *ngIf="vm.entity.amslOffset !== undefined"
                                    type="number"
                                    inputmode="numeric"
                                    [value]="vm.entity.topHeight - verticalBufferOffset + vm.entity.amslOffset | roundNumber"
                                    [min]="
                                        vm.constraints.min.height + verticalBufferOffset + vm.entity.amslOffset
                                            | invoke : convertToRoundedValue
                                    "
                                    [max]="
                                        vm.constraints.max.height - verticalBufferOffset + vm.entity.amslOffset
                                            | invoke : convertToRoundedValue
                                    "
                                    step="1"
                                    required
                                    #pointHeightAmslInput
                                    (clampedInput)="
                                        emitRoundedValue(
                                            pointHeightAmslInput.valueAsNumber + verticalBufferOffset - vm.entity.amslOffset,
                                            topHeightChange
                                        )
                                    "
                                />
                            </ng-container>
                            <!-- TODO: DTM-4845 implement loaders when designed -->
                            <input *ngSwitchDefault matInput value="ładuję..." disabled />
                        </ng-container>
                        <div class="field-suffix">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightUnitLabel" | transloco }}
                        </div>
                    </dtm-ui-input-field>
                </ng-container>

                <dtm-ui-icon
                    *ngIf="
                        vm.settings.heightType === HeightType.AGL && vm.canEditCenterPoint && vm.displayMode.canEdit.cylinder.centerPoint
                    "
                    name="complex-cylinder-point-center"
                    class="point-height-agl-icon"
                >
                </dtm-ui-icon>
                <dtm-ui-icon
                    *ngIf="
                        vm.settings.heightType === HeightType.AMSL && vm.canEditCenterPoint && vm.displayMode.canEdit.cylinder.centerPoint
                    "
                    name="complex-cylinder-point-center"
                    class="point-height-amsl-icon"
                >
                </dtm-ui-icon>
                <ng-container *ngIf="vm.canEditTopHeight && vm.displayMode.canEdit.shape.topHeight">
                    <dtm-ui-input-field
                        class="top-height"
                        [class.agl]="vm.settings.heightType === HeightType.AGL"
                        [class.amsl]="vm.settings.heightType === HeightType.AMSL"
                    >
                        <dtm-ui-icon
                            [name]="vm.isCylinder ? 'complex-cylinder-top' : 'complex-prism-top'"
                            class="field-prefix"
                            [matTooltip]="vm.settings.heightType | invoke : getTopHeightLabelTranslationKey | transloco"
                        ></dtm-ui-icon>
                        <ng-container [ngSwitch]="true">
                            <input
                                *ngSwitchCase="vm.settings.heightType === HeightType.AGL"
                                matInput
                                dtmUiClampInput
                                type="number"
                                inputmode="numeric"
                                [value]="vm.entity.topHeight | roundNumber"
                                [min]="vm.entity.bottomHeight + verticalBufferOffset * 2 | invoke : convertToRoundedValue"
                                [max]="vm.constraints.max.height | invoke : convertToRoundedValue"
                                step="1"
                                required
                                #topHeightAglInput
                                (clampedInput)="topHeightChange.emit(topHeightAglInput.valueAsNumber)"
                            />
                            <ng-container *ngSwitchCase="vm.settings.heightType === HeightType.AMSL && vm.entity.amslOffset !== undefined">
                                <input
                                    matInput
                                    dtmUiClampInput
                                    *ngIf="vm.entity.amslOffset !== undefined"
                                    type="number"
                                    inputmode="numeric"
                                    [value]="
                                        vm.entity | invoke : getCenterPointHeight : vm.entity.topHeight + vm.entity.amslOffset | roundNumber
                                    "
                                    [min]="
                                        vm.entity.bottomHeight + vm.entity.amslOffset + verticalBufferOffset * 2
                                            | invoke : convertToRoundedValue
                                    "
                                    [max]="vm.constraints.max.height + vm.entity.amslOffset | invoke : convertToRoundedValue"
                                    step="1"
                                    required
                                    #topHeightAglInput
                                    (clampedInput)="
                                        emitRoundedValue(topHeightAglInput.valueAsNumber - vm.entity.amslOffset, topHeightChange)
                                    "
                                />
                            </ng-container>
                            <!-- TODO: DTM-4845 implement loaders when designed -->
                            <input *ngSwitchDefault matInput value="ładuję..." disabled />
                        </ng-container>
                        <div class="field-suffix">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightUnitLabel" | transloco }}
                        </div>
                    </dtm-ui-input-field>
                </ng-container>

                <ng-container *ngIf="vm.canEditBottomHeight && vm.displayMode.canEdit.shape.bottomHeight">
                    <dtm-ui-input-field
                        class="bottom-height"
                        [class.agl]="vm.settings.heightType === HeightType.AGL"
                        [class.amsl]="vm.settings.heightType === HeightType.AMSL"
                    >
                        <dtm-ui-icon
                            [name]="vm.isCylinder ? 'complex-cylinder-bottom' : 'complex-prism-bottom'"
                            class="field-prefix"
                            [matTooltip]="vm.settings.heightType | invoke : getBottomHeightLabelTranslationKey | transloco"
                        ></dtm-ui-icon>
                        <ng-container [ngSwitch]="true">
                            <input
                                *ngSwitchCase="vm.settings.heightType === HeightType.AGL"
                                matInput
                                dtmUiClampInput
                                type="number"
                                inputmode="numeric"
                                [value]="vm.entity.bottomHeight | roundNumber"
                                [min]="vm.constraints.min.height"
                                [max]="vm.entity.topHeight - verticalBufferOffset * 2 | invoke : convertToRoundedValue"
                                step="1"
                                required
                                #bottomHeightAglInput
                                (clampedInput)="bottomHeightChange.emit(bottomHeightAglInput.valueAsNumber)"
                            />
                            <ng-container *ngSwitchCase="vm.settings.heightType === HeightType.AMSL && vm.entity.amslOffset !== undefined">
                                <input
                                    matInput
                                    dtmUiClampInput
                                    *ngIf="vm.entity.amslOffset !== undefined"
                                    type="number"
                                    inputmode="numeric"
                                    [value]="vm.entity.bottomHeight + vm.entity.amslOffset | roundNumber"
                                    [min]="vm.constraints.min.height + vm.entity.amslOffset | invoke : convertToRoundedValue"
                                    [max]="
                                        vm.entity.topHeight + vm.entity.amslOffset - verticalBufferOffset * 2
                                            | invoke : convertToRoundedValue
                                    "
                                    step="1"
                                    required
                                    #bottomHeightAglInput
                                    (clampedInput)="
                                        emitRoundedValue(bottomHeightAglInput.valueAsNumber - vm.entity.amslOffset, bottomHeightChange)
                                    "
                                />
                            </ng-container>
                            <!-- TODO: DTM-4845 implement loaders when designed -->
                            <input *ngSwitchDefault matInput value="ładuję..." disabled />
                        </ng-container>
                        <div class="field-suffix">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightUnitLabel" | transloco }}
                        </div>
                    </dtm-ui-input-field>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #amslOffsetLoadingTemplate>
    ładuję...
    <!-- TODO: DTM-4845 implement loaders when designed -->
</ng-template>
