<dtm-ui-expandable-panel
    *ngrxLet="{ heightConstraints: heightConstraints$, isComplexScenario: isComplexScenario$ } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [hasHeaderSeparator]="false"
>
    <div headerSlot>
        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.header" | transloco }}
    </div>
    <form class="content" *ngIf="formControls$ | ngrxPush as formControls">
        <ng-container *ngrxLet="formControls.zoneHeightType.value as heightType">
            <ng-container *ngrxLet="missionType$ as missionType">
                <!-- TODO: uncomment when DTM-4182 is done -->
                <!-- <dtm-ui-select-field
                    [isClearable]="false"
                    [formControl]="formControls.zoneHeightType"
                    class="height-type-select-field"
                    *ngIf="missionType === MissionType.BVLOS"
                >
                    <label>
                        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.heightTypeSelectLabel" | transloco }}
                    </label>
                    <dtm-ui-select-option [value]="heightType" *ngFor="let heightType of heightTypeOptions">
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.heightTypeSelectOptionLabel"
                                | transloco : { type: heightType }
                        }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field> -->

                <div class="max-height-label">
                    {{
                        "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.maxAglHeightLabel"
                            | transloco : { value: vm.heightConstraints.top.max }
                    }}
                    <dtm-ui-popover
                        *ngIf="vm.isComplexScenario"
                        (click)="$event.preventDefault()"
                        [popoverText]="
                            'dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.complexScenarioTooltip' | transloco
                        "
                    >
                    </dtm-ui-popover>
                </div>

                <dtm-ui-expanded-number-input
                    class="zone-top-height"
                    [formControl]="formControls.zoneTopHeight"
                    [minValue]="vm.heightConstraints.top.min"
                    [maxValue]="vm.heightConstraints.top.max"
                    [stepValue]="HEIGHT_STEP"
                    [predefinedOptions]="TOP_HEIGHT_PREDEFINDED_VALUES"
                    [valueSuffix]="
                        'dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.heightUnitLabel'
                            | transloco : { type: formControls.zoneHeightType.value }
                    "
                >
                    <label>
                        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.topHeightLabel" | transloco }}
                    </label>
                    <div class="field-error" *dtmUiFieldHasError="formControls.zoneTopHeight">
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.topHeightConstraintsError"
                                | transloco : { min: vm.heightConstraints.top.min, max: vm.heightConstraints.top.max }
                        }}
                    </div>
                </dtm-ui-expanded-number-input>

                <dtm-ui-expanded-number-input
                    class="zone-bottom-height"
                    *ngIf="missionType === MissionType.BVLOS"
                    [formControl]="formControls.zoneBottomHeight"
                    [minValue]="vm.heightConstraints.bottom.min"
                    [maxValue]="vm.heightConstraints.bottom.max"
                    [stepValue]="HEIGHT_STEP"
                    [valueSuffix]="
                        'dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.heightUnitLabel'
                            | transloco : { type: formControls.zoneHeightType.value }
                    "
                >
                    <label>
                        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.bottomHeightLabel" | transloco }}
                    </label>
                    <div class="field-error" *dtmUiFieldHasError="formControls.zoneBottomHeight">
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.bottomHeightConstraintsError"
                                | transloco : { min: vm.heightConstraints.bottom.min, max: vm.heightConstraints.bottom.max }
                        }}
                    </div>
                </dtm-ui-expanded-number-input>

                <ng-container *ngIf="!vm.isComplexScenario">
                    <mat-slide-toggle labelPosition="before" [formControl]="formControls.isFlightAroundObstacle">
                        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightAroundObstacleLabel" | transloco }}
                        <dtm-ui-popover
                            (click)="$event.preventDefault()"
                            [popoverText]="
                                'dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightObstacleTooltip' | transloco
                            "
                        >
                        </dtm-ui-popover>
                    </mat-slide-toggle>
                    <div [class.hidden]="!formControls.isFlightAroundObstacle.value">
                        <dtm-ui-checkbox-field
                            class="conditions-content"
                            [required]="formControls.isFlightAroundObstacle.value"
                            [formControl]="formControls.isFlightAroundObstacleStatementAccepted"
                        >
                            <span>
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightAroundObstacleStatementText.header"
                                        | transloco
                                }}
                            </span>
                            <p>
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightAroundObstacleStatementText.subheader"
                                        | transloco
                                }}
                            </p>
                            <ul>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightAroundObstacleStatementText.firstCondition"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightAroundObstacleStatementText.secondCondition"
                                            | transloco
                                    }}
                                </li>
                            </ul>
                            <span>
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightAroundObstacleStatementText.substatement"
                                        | transloco
                                }}
                            </span>
                            <p>
                                {{
                                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneHeight.flightAroundObstacleStatementText.additionalCondition"
                                        | transloco
                                }}
                            </p>
                        </dtm-ui-checkbox-field>
                        <dtm-ui-form-errors>
                            <div
                                class="field-error"
                                *dtmUiFieldHasError="formControls.isFlightAroundObstacleStatementAccepted; name: 'required'"
                            >
                                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                            </div>
                        </dtm-ui-form-errors>
                    </div>
                </ng-container>

                <div class="deferred-form-actions" *ngIf="formStateController$ | ngrxPush as formStateController">
                    <ng-container *ngIf="formStateController.isEditing$ | ngrxPush">
                        <button class="button-secondary" type="button" (click)="formStateController.restore()">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.restoreFormButtonLabel" | transloco }}
                        </button>
                        <button
                            class="button-primary next-button"
                            type="button"
                            (click)="saveFormValues(formStateController, formControls)"
                            [disabled]="!formStateController.isValid || (isDisabled$ | ngrxPush)"
                        >
                            {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveFormButtonLabel" | transloco }}
                        </button>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </form>
</dtm-ui-expandable-panel>
