import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { ErrorMode, UIState } from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { EMPTY, filter, skip } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";
import { LegalGuardianConfirmationData, SavedDocumentData } from "../../models/user-profile.models";
import { UserProfileActions } from "../../state/user-profile.actions";
import { UserProfileState } from "../../state/user-profile.state";
import { getI18nUpdateProfileError } from "../../utils/update-error.translations";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-user-profile-container",
    templateUrl: "./user-profile-container.component.html",
    styleUrls: ["./user-profile-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileContainerComponent {
    protected readonly userProfile$ = this.store.select(UserProfileState.user);
    protected readonly legalGuardianConfirmationRequest$ = this.store.select(UserProfileState.legalGuardianConfirmationRequest);
    protected readonly legalGuardian$ = this.store.select(UserProfileState.legalGuardian);
    protected readonly error$ = this.store.select(UserProfileState.userProfileError);
    protected readonly currentLanguage$ = this.store.select(UIState.activeLanguage).pipe(RxjsUtils.filterFalsy());
    protected readonly profileAvatar$ = this.store.select(UserProfileState.profileAvatar);
    protected readonly isProcessing$ = this.store.select(UserProfileState.isProcessing);
    protected readonly fullName$ = this.userProfile$.pipe(
        RxjsUtils.filterFalsy(),
        map((user) => `${user.firstName} ${user.lastName}`)
    );
    protected readonly ErrorMode = ErrorMode;

    constructor(
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private router: Router
    ) {
        this.store
            .select(AuthState.userId)
            .pipe(
                RxjsUtils.filterFalsy(),
                first(),
                switchMap((userId) =>
                    this.store.dispatch([new UserProfileActions.GetUserProfile(userId), new UserProfileActions.GetProfileAvatar(userId)])
                ),
                untilDestroyed(this)
            )
            .subscribe();

        this.router.events
            .pipe(
                filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
                skip(1),
                switchMap(() => this.refreshProfile()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected updateProfileLanguage(lang: LanguageCode): void {
        this.store
            .dispatch(new UserProfileActions.UpdateProfileLanguage(lang))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.updateProfileLanguageError);

                if (error) {
                    this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));
                }
                /* TODO: [REJ-714] Need find solution to fire markForCheck() after translations files be fetched.
                   Randomly app doesn't refresh translations after language change
                */
            });
    }

    protected resetPassword(): void {
        this.store
            .dispatch(new UserProfileActions.ResetUserPassword())
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.resetUserPasswordError);

                if (error) {
                    this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));
                }
            });
    }

    protected saveProfileAvatar(croppedBase64Image: string): void {
        this.store
            .dispatch(new UserProfileActions.SaveProfileAvatar(croppedBase64Image))
            .pipe(
                map(() => {
                    const error = this.store.selectSnapshot(UserProfileState.saveProfileAvatarError);
                    if (error) {
                        this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));
                    } else {
                        this.toastService.success(this.transloco.translate("dtmWebAppUserProfile.notifications.saveProfileAvatarSuccess"));
                    }

                    return !!error;
                }),
                switchMap((isError) => {
                    if (isError) {
                        return EMPTY;
                    }

                    return this.refreshProfile();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected identityDocumentSave(savedDocumentData: SavedDocumentData): void {
        this.store
            .dispatch(new UserProfileActions.SaveIdentityDocument(savedDocumentData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.saveIdentityDocumentError);

                if (error) {
                    this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppUserProfile.notifications.saveIdentityDocumentSuccess"));
            });
    }

    protected documentDownload(): void {
        this.store
            .dispatch(new UserProfileActions.DownloadIdentityDocument())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.downloadDocumentError);

                if (error) {
                    this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));
                }
            });
    }

    protected resendLegalGuardianEmailAddress(): void {
        this.store
            .dispatch(new UserProfileActions.ResendLegalGuardianEmailAddress())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.legalGuardianResendConfirmationError);

                if (error) {
                    this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmWebAppUserProfile.notifications.resendLegalGuardianConfirmationSuccess")
                );
            });
    }

    protected legalGuardianDataSave(confirmationData: LegalGuardianConfirmationData): void {
        const user = this.store.selectSnapshot(UserProfileState.user);
        const isDataEdited = !!user?.legalGuardian || !!user?.legalGuardianConfirmationRequest;

        this.store
            .dispatch(new UserProfileActions.ChangeLegalGuardianData(confirmationData, isDataEdited))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.legalGuardianDataSaveError);

                if (error) {
                    this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmWebAppUserProfile.notifications.saveLegalGuardianConfirmationDataSuccess")
                );
            });
    }

    protected deleteIdentityDocument(): void {
        this.store
            .dispatch(new UserProfileActions.DeleteIdentityDocument())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.deleteIdentityDocumentError);
                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppUserProfile.identityCard.cannotDeleteIdentityCardErrorMessage")
                    );
                } else {
                    this.toastService.success(
                        this.transloco.translate("dtmWebAppUserProfile.identityCard.deleteIdentityCardSuccessMessage")
                    );
                }
            });
    }

    protected deleteUserAvatar(): void {
        this.store
            .dispatch(new UserProfileActions.DeleteAvatar())
            .pipe(
                map(() => {
                    const error = this.store.selectSnapshot(UserProfileState.deleteProfileAvatarError);
                    if (error) {
                        this.toastService.error(
                            this.transloco.translate("dtmWebAppUserProfile.editAvatar.cannotDeleteUserAvatarErrorMessage")
                        );
                    } else {
                        this.toastService.success(
                            this.transloco.translate("dtmWebAppUserProfile.editAvatar.deleteUserAvatarSuccessMessage")
                        );
                    }

                    return !!error;
                }),
                switchMap((isError) => {
                    if (isError) {
                        return EMPTY;
                    }

                    return this.refreshProfile();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private refreshProfile() {
        return this.store.select(AuthState.userId).pipe(
            RxjsUtils.filterFalsy(),
            first(),
            switchMap((userId) => this.store.dispatch(new UserProfileActions.GetUserProfile(userId)))
        );
    }
}
