import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { PublicProfileDetailsComponent } from "./components/public-profile-details/public-profile-details.component";
import { PublicProfileNotFoundComponent } from "./components/public-profile-not-found/public-profile-not-found.component";
import { PublicProfileOperatorContainerComponent } from "./components/public-profile-operator-container/public-profile-operator-container.component";
import { PublicProfilePilotContainerComponent } from "./components/public-profile-pilot-container/public-profile-pilot-container.component";
import { PublicProfileRoutingModule } from "./public-profile.routing.module";
import { PublicProfileApiService } from "./services/public-profile-api.service";
import { PublicProfileState } from "./state/public-profile.state";

@NgModule({
    declarations: [
        PublicProfileOperatorContainerComponent,
        PublicProfilePilotContainerComponent,
        PublicProfileDetailsComponent,
        PublicProfileNotFoundComponent,
    ],
    imports: [
        CommonModule,
        PublicProfileRoutingModule,
        NgxsModule.forFeature([PublicProfileState]),
        MatCardModule,
        SharedUiModule,
        TranslocoModule,
        LetModule,
        SharedWebAppModule,
        PushModule,
        SharedI18nModule,
    ],
    providers: [
        PublicProfileApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibPublicProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [PublicProfileDetailsComponent, PublicProfileNotFoundComponent],
})
export class PublicProfileModule {
    public static forRoot(): ModuleWithProviders<PublicProfileModule> {
        return {
            ngModule: PublicProfileModule,
            providers: [PublicProfileApiService],
        };
    }

    public static forTest(): ModuleWithProviders<PublicProfileModule> {
        return {
            ngModule: PublicProfileModule,
            providers: [
                {
                    provide: PublicProfileApiService,
                    useValue: null,
                },
            ],
        };
    }
}
