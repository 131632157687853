<dtm-ui-filters-container *ngrxLet="filtersCount$ as filtersCount" [filtersCount]="filtersCount">
    <form [formGroup]="filtersForm">
        <dtm-ui-input-field>
            <input
                matInput
                type="text"
                [formControl]="filtersForm.controls.textSearch"
                [placeholder]="'dtmWebAppLibUav.uavList.filters.textSearchPlaceholder' | transloco"
            />
        </dtm-ui-input-field>
        <dtm-ui-select-field [formControl]="sortByControl" [isClearable]="false">
            <label>{{ "dtmWebAppLibUav.uavList.filters.sortByLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let sortBy of sortingOptions" [value]="sortBy">
                {{ "dtmWebAppLibUav.uavList.filters.sortByValueLabel" | transloco : { value: sortBy } }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>
    </form>

    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [formGroup]="filtersForm"
        [filtersValue]="filtersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="filtersCount"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</dtm-ui-filters-container>
