import { HttpClient, HttpContext, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PUBLIC_PROFILE_ENDPOINTS, PublicProfileEndpoints } from "../public-profile.tokens";
import {
    GetPublicProfileOperatorResponseBody,
    GetPublicProfilePilotResponseBody,
    convertPublicProfileOperatorResponseBodyToPublicProfileDetails,
    convertPublicProfilePilotResponseBodyToPublicProfileDetails,
} from "./public-profile.converters";
import { PublicProfileDetails, PublicProfileErrorType } from "./public-profile.models";

@Injectable()
export class PublicProfileApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(PUBLIC_PROFILE_ENDPOINTS) private readonly endpoints: PublicProfileEndpoints
    ) {}

    public getPublicProfileOperatorData(operatorId: string): Observable<PublicProfileDetails> {
        return this.httpClient
            .get<GetPublicProfileOperatorResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getOperatorDetails, { operatorId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response: GetPublicProfileOperatorResponseBody) =>
                    convertPublicProfileOperatorResponseBodyToPublicProfileDetails(response)
                ),
                catchError((error) =>
                    throwError(() => ({
                        type:
                            error.status === HttpStatusCode.NotFound
                                ? PublicProfileErrorType.NotFound
                                : PublicProfileErrorType.CannotGetOperatorData,
                    }))
                )
            );
    }

    public getPublicProfilePilotData(pilotId: string): Observable<PublicProfileDetails> {
        return this.httpClient
            .get<GetPublicProfilePilotResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getPilotDetails, { pilotId }), {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response: GetPublicProfilePilotResponseBody) => convertPublicProfilePilotResponseBodyToPublicProfileDetails(response)),
                catchError((error) =>
                    throwError(() => ({
                        type:
                            error.status === HttpStatusCode.NotFound
                                ? PublicProfileErrorType.NotFound
                                : PublicProfileErrorType.CannotGetPilotData,
                    }))
                )
            );
    }
}
