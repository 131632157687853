import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NewUserGuard } from "../../guards/new-user.guard";
import { PilotProfileContainerComponent } from "./components/pilot-profile-container/pilot-profile.container.component";
import { CanEnterPilotProfileGuard } from "./guards/can-enter-on-pilot-profile.guard";
import { PilotProfileResolver } from "./services/pilot-profile.resolver";

const routes: Routes = [
    {
        path: "pilot-profile",
        component: PilotProfileContainerComponent,
        canActivate: [DtmRoleGuard, CanEnterPilotProfileGuard, NewUserGuard],
        resolve: {
            initData: PilotProfileResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibPilotProfile.routeTitles.pilotProfileContainer",
            shouldSkipReloadOnOperatorChange: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PilotProfileRoutingModule {}
