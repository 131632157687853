<ng-container
    *ngrxLet="{
        ownedPermits: ownedSpecificPermitsList$,
        areOwnedPermitsFiltersApplied: areOwnedPermitsFiltersApplied$,
        isOwnedPermitsProcessing: isOwnedPermitsProcessing$,
        areSpecificPermitDraftsAvailable: areSpecificPermitDraftsAvailable$,
        isPermittedToCreateApplication: isPermittedToCreateApplication$,
    } as vm"
>
    <ng-container *ngIf="!(ownedSpecificPermitsListError$ | ngrxPush); else errorTemplate">
        <ng-container
            *ngIf="
                vm.ownedPermits?.length || vm.areOwnedPermitsFiltersApplied || vm.isOwnedPermitsProcessing;
                else ownedPermitsEmptyStateTemplate
            "
        >
            <dtm-web-app-lib-operator-permits-owned-filters
                class="filters"
                [incomingFilters]="ownedSpecificPermitListFilters$ | ngrxPush"
                (filtersChange)="ownedFiltersChange($event)"
            ></dtm-web-app-lib-operator-permits-owned-filters>
            <dtm-web-app-lib-operator-permits-owned-list
                [pagination]="ownedSpecificPermitsListPages$ | ngrxPush"
                [ownedPermits]="vm.ownedPermits"
                [isPermittedToCreateApplication]="!!vm.isPermittedToCreateApplication"
                (pageChange)="ownedPageChange($event)"
                (permitNameChange)="changePermitName($event)"
            ></dtm-web-app-lib-operator-permits-owned-list>
        </ng-container>
    </ng-container>

    <ng-template #errorTemplate>
        <dtm-ui-error [mode]="ErrorMode.Secondary"></dtm-ui-error>
    </ng-template>
    <ng-template #ownedPermitsEmptyStateTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
            <ng-container titleSlot>
                {{ "dtmWebAppLibOperatorPermits.permitsContainer.ownedPermitsEmptyState.title" | transloco }}
            </ng-container>
            <img imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
            <p messageSlot>
                {{ "dtmWebAppLibOperatorPermits.permitsContainer.ownedPermitsEmptyState.message" | transloco }}
            </p>
            <button
                footerSlot
                type="button"
                class="button-primary"
                (click)="navigateToSpecificPermits()"
                *ngIf="vm.areSpecificPermitDraftsAvailable && vm.isPermittedToCreateApplication"
            >
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibOperatorPermits.permitsContainer.ownedPermitsEmptyState.buttonLabel" | transloco }}
            </button>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>
