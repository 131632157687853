import { Address, Alpha3CountryCode, IdentityDocument, IdentityDocumentType, PhoneNumber, UploadedFile } from "@dtm-frontend/shared/ui";

export enum UserProfileErrorType {
    Unknown = "UserProfileErrorUnknown",
    NotFound = "UserProfileErrorNotFound",
    NotAuthorized = "UserProfileErrorNotAuthorized",
    CannotSendVerificationCodeForEmailChange = "CannotSendVerificationForEmailChangeCode",
    CannotSendVerificationCodeForPhoneChange = "CannotSendVerificationForPhoneChangeCode",
    IncorrectVerificationCode = "IncorrectVerificationCode",
    EmailConflict = "EmailConflict",
    PhoneConflict = "PhoneConflict",
    CannotSaveUserProfileLanguage = "CannotSaveUserProfileLanguage",
    CannotResetUserPassword = "CannotResetUserPassword",
    CannotSaveProfileAvatar = "CannotSaveProfileAvatar",
    CannotDeleteProfileAvatar = "CannotDeleteProfileAvatar",
    CannotSaveIdentityDocument = "CannotSaveIdentityDocument",
    CannotGetIdentityDocument = "CannotGetIdentityDocument",
    CannotResendLegalGuardianEmailAddress = "CannotResendLegalGuardianEmailAddress",
    CannotChangeLegalGuardianConfirmationData = "CannotChangeLegalGuardianConfirmationData",
    CannotDeleteUserIdentityDocument = "CannotDeleteUserIdentityDocument",
    CannotResendVerificationCode = "CannotResendVerificationCode",
    CannotRegisterNationalNodeUser = "CannotRegisterNationalNodeUser",
    NationalNodeUserMinAgeRequired = "NationalNodeUserMinAgeRequired",
    TooManyRequests = "TooManyRequests",
}

export interface UserProfileError {
    type: UserProfileErrorType;
    date?: Date;
}

export interface LegalGuardianConfirmationRequest {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface LegalGuardian extends LegalGuardianConfirmationRequest {
    phoneNumber: PhoneNumber;
    address: Address;
    dateOfBirth: Date;
    nationality: string;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    nationality: Alpha3CountryCode;
    phoneNumber: PhoneNumber;
    languageTag: string;
    email: string;
    legalGuardian?: LegalGuardian;
    legalGuardianConfirmationRequest?: LegalGuardianConfirmationRequest;
    identityDocument: IdentityDocument;
    isEasaMember: boolean;
    attorneyPowers?: UsersAttorneyPower[];
    isLegalAge: boolean;
    avatarId?: string;
}

export interface NationalNodeUserRegistration {
    userId: string;
    isEmailVerified: boolean;
    isPhoneNumberVerified: boolean;
    email: string;
    phoneNumber: PhoneNumber;
}

export type LegalGuardianConfirmationData = Omit<LegalGuardianConfirmationRequest, "id">;

export interface SavedDocumentData {
    document: UploadedFile[];
    expirationDate: string;
    type: IdentityDocumentType;
}

export interface UsersAttorneyPower {
    status: AttorneyPowerStatus;
    operatorName: string;
    type: AttorneyPowerType;
    permissions: AttorneyPowerPermission[];
    documentId?: string;
    feeDocumentId?: string;
    nationalCourtRegister?: string;
}

export enum AttorneyPowerStatus {
    PendingApproval = "PENDING_APPROVAL",
    Approved = "APPROVED",
}

export enum AttorneyPowerType {
    Owner = "OWNER",
    NationalCourtNumber = "NATIONAL_COURT_NUMBER",
    ConfirmatoryDocument = "CONFIRMATORY_DOCUMENT",
}

export enum AttorneyPowerPermission {
    MakingStatements = "MAKING_STATEMENTS",
    SpecificPermitApply = "SPECIFIC_PERMIT_APPLY",
}
