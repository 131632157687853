<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <ng-container *ngIf="error$ | ngrxPush; else uavsTemplate">
        <dtm-ui-error [errorMessage]="'dtmWebAppLibUav.uavList.uavListUnknownErrorMessage' | transloco" (reload)="refresh()"></dtm-ui-error>
    </ng-container>

    <ng-template #uavsTemplate>
        <ng-container *ngrxLet="{ uavs: uavs$, canManageUavs: canManageUavs$, canReadUavs: canReadUavs$ } as vm">
            <ng-container *ngIf="vm.canReadUavs; else notAuthorizedTemplate">
                <section class="grid" *ngIf="vm.uavs.length || areFiltersApplied$ | ngrxPush; else noUavsTemplate">
                    <div class="add-button" *ngIf="vm.canManageUavs">
                        <button class="button-primary" routerLink="/uav/new" type="button">
                            <dtm-ui-icon name="add"></dtm-ui-icon>
                            {{ "dtmWebAppLibUav.uavList.addUavButtonLabel" | transloco }}
                        </button>
                    </div>

                    <dtm-web-app-lib-uav-list-filters
                        [initialFilters]="initialFilters$ | ngrxPush"
                        [initialSorting]="initialSorting$ | ngrxPush"
                        (filtersChange)="changeFilters($event)"
                        (sortChange)="changeSorting($event)"
                    ></dtm-web-app-lib-uav-list-filters>

                    <ng-container *ngIf="vm.uavs.length; else emptyUavListTemplate">
                        <ng-container *ngrxLet="{ isOwnerVisible: isOwnerVisible$, canShareUavs: canShareUavs$ } as uavsManagement">
                            <dtm-web-app-lib-uav-list-item
                                class="grid-col-4 grid-col-tablet-6"
                                *ngFor="let uav of vm.uavs"
                                [canShareUav]="uavsManagement.canShareUavs"
                                [canManageUavs]="vm.canManageUavs"
                                [uav]="uav"
                                [isOwnerVisible]="uavsManagement.isOwnerVisible"
                                [routerLink]="['/uav', uav.id]"
                                (showDetails)="showDetails($event)"
                                (edit)="editUav($event)"
                                (share)="openShareUavDialog($event)"
                                (remove)="removeUav($event)"
                            ></dtm-web-app-lib-uav-list-item>
                        </ng-container>
                    </ng-container>

                    <ng-template #emptyUavListTemplate>
                        <dtm-ui-no-results></dtm-ui-no-results>
                    </ng-template>

                    <dtm-ui-pagination *ngrxLet="pagination$ as pagination" [pagination]="pagination" (page)="changePage($event)">
                    </dtm-ui-pagination>
                </section>
            </ng-container>

            <ng-template #noUavsTemplate>
                <div class="grid grid-maxsize-6">
                    <dtm-ui-empty-state>
                        <ng-container titleSlot>
                            {{ "dtmWebAppLibUav.uavList.noUavs.header" | transloco }}
                        </ng-container>
                        <img imageSlot src="assets/images/uav-empty-list.svg" alt="" />
                        <p messageSlot>
                            {{
                                (vm.canManageUavs
                                    ? "dtmWebAppLibUav.uavList.noUavs.message"
                                    : "dtmWebAppLibUav.uavList.noUavs.messageOperator"
                                ) | transloco
                            }}
                        </p>
                        <button *ngIf="vm.canManageUavs" footerSlot type="button" class="button-primary" (click)="redirectToUavWizard()">
                            <dtm-ui-icon name="add"></dtm-ui-icon>
                            {{ "dtmWebAppLibUav.uavList.noUavs.addUavButtonLabel" | transloco }}
                        </button>
                    </dtm-ui-empty-state>
                </div>
            </ng-template>
        </ng-container>
    </ng-template>
</dtm-ui-loader-container>

<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
