<ng-container *ngrxLet="types$ as types">
    <ng-container [formGroup]="communicationsForm">
        <ng-container formArrayName="communications" dtmUiInvalidFormScrollable>
            <!-- async used because ngrxPush didn't work properly (is executed too late) -->
            <dtm-ui-expandable-panel
                *ngFor="let communication of communicationsControls$ | async; index as communicationIndex"
                [formGroupName]="communicationIndex"
                [hasHeaderSeparator]="false"
                [isExpanded]="true"
            >
                <div class="section-header" headerSlot>
                    <h4 class="section-title">
                        {{ "dtmWebAppLibUav.uavProperties.communicationProperties.sectionHeader" | transloco }}
                    </h4>
                    <button
                        type="button"
                        class="button-icon"
                        (click)="tryToRemoveCommunication(communicationIndex); $event.stopPropagation()"
                    >
                        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    </button>
                </div>
                <div class="properties">
                    <dtm-ui-select-field
                        [formControl]="communication.controls.type"
                        [isClearable]="false"
                        [placeholder]="'dtmWebAppLibUav.setupForm.communications.typePlaceholder' | transloco"
                    >
                        <label>
                            {{ "dtmWebAppLibUav.uavProperties.communicationProperties.typeLabel" | transloco }}
                        </label>
                        <dtm-ui-select-option *ngFor="let type of types" [value]="type">
                            {{ "dtmWebAppLibUav.uavProperties.communicationProperties.typeValue" | transloco : { value: type } }}
                        </dtm-ui-select-option>
                        <div class="field-error" *dtmUiFieldHasError="communication.controls.type; name: ['required', 'pattern']">
                            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="communication.controls.type; name: 'notUnique'">
                            {{ "dtmWebAppLibUav.setupForm.communicationTypeNotUniqueErrorHint" | transloco }}
                        </div>
                    </dtm-ui-select-field>
                    <dtm-ui-input-field>
                        <label>
                            {{ "dtmWebAppLibUav.uavProperties.communicationProperties.transmissionLinkRangeLabel" | transloco }}
                        </label>
                        <input
                            matInput
                            type="number"
                            [formControl]="communication.controls.transmissionLinkRange"
                            [min]="MIN_TRANSMISSION_LINK_RANGE_VALUE"
                            inputmode="numeric"
                            autocomplete="off"
                        />
                        <div class="field-suffix">
                            {{ "dtmWebAppLibUav.uavProperties.communicationProperties.transmissionLinkRangeUnitLabel" | transloco }}
                        </div>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="communication.controls.transmissionLinkRange; name: ['required', 'pattern']"
                        >
                            {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
                        </div>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="communication.controls.transmissionLinkRange; name: 'min'; error as error"
                        >
                            {{
                                "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint"
                                    | transloco
                                        : {
                                              min: error.min | localizeNumber
                                          }
                            }}
                        </div>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="communication.controls.transmissionLinkRange; name: 'max'; error as error"
                        >
                            {{
                                "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint"
                                    | transloco
                                        : {
                                              max: error.max | localizeNumber
                                          }
                            }}
                        </div>
                    </dtm-ui-input-field>
                    <div class="link-type">
                        <p class="field-label">
                            {{ "dtmWebAppLibUav.uavProperties.communicationProperties.linkTypeLabel" | transloco }}
                        </p>
                        <dtm-ui-radio-group [formControl]="communication.controls.linkType">
                            <dtm-ui-radio-field [value]="CommunicationLinkType.C2">
                                {{
                                    "dtmWebAppLibUav.uavProperties.communicationProperties.linkTypeValue"
                                        | transloco : { type: CommunicationLinkType.C2 }
                                }}
                            </dtm-ui-radio-field>
                            <dtm-ui-radio-field [value]="CommunicationLinkType.C3">
                                {{
                                    "dtmWebAppLibUav.uavProperties.communicationProperties.linkTypeValue"
                                        | transloco : { type: CommunicationLinkType.C3 }
                                }}
                            </dtm-ui-radio-field>
                            <dtm-ui-radio-field [value]="CommunicationLinkType.Unknown">
                                {{
                                    "dtmWebAppLibUav.uavProperties.communicationProperties.linkTypeValue"
                                        | transloco : { type: CommunicationLinkType.Unknown }
                                }}
                            </dtm-ui-radio-field>
                        </dtm-ui-radio-group>
                    </div>
                    <dtm-ui-input-field>
                        <label>
                            {{
                                "dtmWebAppLibUav.uavProperties.communicationProperties.linkDelayLabel"
                                    | transloco : { type: communication.controls.linkType.value }
                            }}
                            {{ "dtmWebAppLibUav.setupForm.optionalLabel" | transloco }}
                        </label>
                        <input
                            matInput
                            type="number"
                            [formControl]="communication.controls.linkDelay"
                            inputmode="numeric"
                            autocomplete="off"
                        />
                        <div class="field-suffix">
                            {{ "dtmWebAppLibUav.uavProperties.communicationProperties.linkDelayUnitLabel" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="communication.controls.linkDelay; name: 'min'; error as error">
                            {{
                                "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint"
                                    | transloco
                                        : {
                                              min: error.min | localizeNumber
                                          }
                            }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="communication.controls.linkDelay; name: 'max'; error as error">
                            {{
                                "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint"
                                    | transloco
                                        : {
                                              max: error.max | localizeNumber
                                          }
                            }}
                        </div>
                    </dtm-ui-input-field>
                    <div class="switcher" *ngIf="isCustomUavMode$ | ngrxPush">
                        <label>
                            {{ "dtmWebAppLibUav.setupForm.communications.isEmbeddedLabel" | transloco }}
                        </label>
                        <mat-slide-toggle [formControl]="communication.controls.isEmbedded"></mat-slide-toggle>
                    </div>
                </div>
                <dtm-web-app-lib-frequencies-control
                    *ngIf="communication.controls.type.value | invoke : areFrequenciesRequired"
                    dtmUiMarkValueAccessorControlsAsTouched
                    [formControl]="communication.controls.frequencies"
                ></dtm-web-app-lib-frequencies-control>
            </dtm-ui-expandable-panel>
        </ng-container>
    </ng-container>

    <div
        class="section-header add-communication"
        *ngIf="types.length"
        dtmUiScrollHook
        [dtmUiScrollHookOptions]="{ behavior: 'smooth', block: 'end' }"
    >
        <h4 class="section-title">
            {{ "dtmWebAppLibUav.uavProperties.communicationProperties.sectionHeader" | transloco }}
        </h4>
        <button type="button" class="button-secondary" (click)="addCommunication()">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibUav.setupForm.communications.addCommunicationButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
