import { AttachmentFile, ConversationCategoryCode, MessageRecipient, OperatorsThread, ReceivedMessage } from "@dtm-frontend/shared/ui";
import { AddNewMessage, NewMessageSender, NewThread } from "./operator-conversations.model";

export interface GetOperatorsThreadsListResponseBody {
    totalElements: number;
    totalPages: number;
    size: number;
    content: OperatorsThreadResponseBody[];
    number: number;
    sort: {
        empty: boolean;
        unsorted: boolean;
        sorted: boolean;
    };
    pageable: {
        size: number;
        page: number;
        sort: string;
    };
    numberOfElements: number;
    first: boolean;
    last: boolean;
    empty: boolean;
}

export interface OperatorsThreadResponseBody {
    id: string;
    name: string;
    createdAt: string;
    modifiedAt: string;
    category: ConversationCategoryCode;
    stakeholder: {
        id: string;
        name: string;
        owner: {
            userId: string;
            firstName: string;
            lastName: string;
        };
    };
    assignee: {
        userId: string;
        firstName: string;
        lastName: string;
    };
    read: boolean;
    closed: boolean;
}

export interface GetMessagesByThreadResponseBody {
    id: string;
    subject: string;
    content: string;
    createdAt: string;
    sender: {
        userId: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
    };
    recipient: MessageRecipient | null;
    threadId: string;
    attachments?: AttachmentFile[];
}

export interface GetOperatorMessageCapabilitiesResponseBody {
    categories: ConversationCategoryCode[];
}

export interface NewThreadRequestPayload {
    subject: string;
    content: string;
    category: ConversationCategoryCode;
    attachmentIds?: string[];
    sender: NewMessageSender;
}

export interface AddNewMessageToThreadRequestPayload {
    subject: string;
    content: string;
    sender: NewMessageSender;
    attachmentIds?: string[];
}

export function convertGetOperatorsThreadsListResponseBodyToGetOperatorsThreadsList(
    response: GetOperatorsThreadsListResponseBody
): OperatorsThread[] {
    const content = response.content;

    return content.map((thread: OperatorsThreadResponseBody) => ({
        id: thread.id,
        modifiedAt: thread.modifiedAt,
        isRead: thread.read,
        isClosed: thread.closed,
        name: thread.name,
        assignee: thread.assignee,
        stakeholderId: thread.stakeholder.id,
        category: thread.category,
    }));
}

export function convertGetMessagesByThreadResponseBodyToReceivedMessages(response: GetMessagesByThreadResponseBody[]): ReceivedMessage[] {
    return response.map((message) => ({
        id: message.id,
        subject: message.subject,
        content: message.content,
        createdAt: message.createdAt,
        sender: message.sender,
        recipient: message.recipient,
        threadId: message.threadId,
        attachments: message.attachments,
    }));
}

export function convertNewThreadToNewThreadRequestPayload(message: NewThread): NewThreadRequestPayload {
    const attachmentIds: string[] | undefined = message.attachmentIdsList;

    const body: NewThreadRequestPayload = {
        subject: message.subject,
        content: message.content,
        category: message.category,
        sender: message.sender,
    };

    if (attachmentIds?.length) {
        body.attachmentIds = attachmentIds;
    }

    return body;
}

export function convertNewMessageToAddNewMessageToThreadRequestPayload(message: AddNewMessage): AddNewMessageToThreadRequestPayload {
    const attachmentIds: string[] | undefined = message.attachmentIdsList;
    const body: AddNewMessageToThreadRequestPayload = {
        subject: message.subject,
        content: message.content,
        sender: message.sender,
    };

    if (attachmentIds?.length) {
        body.attachmentIds = attachmentIds;
    }

    return body;
}
