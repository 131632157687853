<dtm-ui-lib-wizard-header [wizardId]="OPERATOR_MIGRATION_WIZARD_ID">
    <dtm-ui-lib-wizard-step-header [stepId]="OperatorMigrationWizardSteps.Authentication">
        {{ "dtmWebAppLibOperatorMigration.operatorMigrationWizard.authenticationTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="OperatorMigrationWizardSteps.DataImport">
        {{ "dtmWebAppLibOperatorMigration.operatorMigrationWizard.dataImportTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="OperatorMigrationWizardSteps.Summary">
        {{ "dtmWebAppLibOperatorMigration.operatorMigrationWizard.summaryTitle" | transloco }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
