import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { PublicProfileDetails, PublicProfileType } from "../../services/public-profile.models";

interface PublicProfileDetailsComponentState {
    type: PublicProfileType | undefined;
    details: PublicProfileDetails | undefined;
}

@Component({
    selector: "dtm-web-app-lib-public-profile-details[type][details]",
    templateUrl: "./public-profile-details.component.html",
    styleUrls: ["./public-profile-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PublicProfileDetailsComponent {
    @Input() public set type(value: PublicProfileType | undefined) {
        this.localStore.patchState({ type: value });
    }

    @Input() public set details(value: PublicProfileDetails | undefined) {
        this.localStore.patchState({ details: value });
    }

    public readonly type$ = this.localStore.selectByKey("type");
    public readonly details$ = this.localStore.selectByKey("details").pipe(RxjsUtils.filterFalsy());
    public readonly publicProfileType = PublicProfileType;

    constructor(private readonly localStore: LocalComponentStore<PublicProfileDetailsComponentState>) {
        this.localStore.setState({
            type: undefined,
            details: undefined,
        });
    }
}
