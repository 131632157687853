import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MissionStatus } from "../../../models/tactical.models";

interface DisplaySettings {
    iconName: IconName | undefined;
    className: string;
}

interface TacticalMissionStatusChipComponentState {
    chip:
        | ({
              status: MissionStatus;
          } & DisplaySettings)
        | undefined;
}

@Component({
    selector: "dtm-web-app-lib-tactical-mission-status-chip[status]",
    templateUrl: "./tactical-mission-status-chip.component.html",
    styleUrls: ["./tactical-mission-status-chip.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TacticalMissionStatusChipComponent {
    @Input()
    public set status(value: MissionStatus) {
        this.localStore.patchState({ chip: { status: value, ...this.getDisplaySettings(value) } });
    }

    public readonly chip$ = this.localStore.selectByKey("chip").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<TacticalMissionStatusChipComponentState>) {
        this.localStore.setState({
            chip: undefined,
        });
    }

    private getDisplaySettings(status: MissionStatus): DisplaySettings {
        switch (status) {
            case MissionStatus.Activated:
            case MissionStatus.Accepted:
                return { iconName: "play-circle", className: "active" };
            case MissionStatus.Started:
                return { iconName: "play-circle", className: "in-realization" };
            case MissionStatus.Canceled:
            case MissionStatus.Rejected:
                return { iconName: "close-circle", className: "canceled" };
            case MissionStatus.Finished:
                return { iconName: "focus", className: "ended" };
            default:
                return { iconName: undefined, className: "" };
        }
    }
}
