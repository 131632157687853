<dtm-ui-expandable-panel *ngrxLet="sail$ as sail" isExpanded isDisabled [hasHeaderSeparator]="false">
    <dtm-ui-expandable-simple-header
        headerSlot
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.sailInfo.sailHeaderLabel' | transloco"
        [value]="sail | sailLevel"
    >
    </dtm-ui-expandable-simple-header>
    <a class="link guide-link" (click)="operationsManualGuideForSailOpen.emit(sail)">
        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.sailInfo.checkSailRequirementsLinkLabel" | transloco }}
    </a>
</dtm-ui-expandable-panel>
