<ng-container *ngrxLet="qualifications$ as qualifications">
    <mat-card *ngIf="qualifications?.length; else noQualificationsTemplate">
        <dtm-ui-card-header>
            {{ "dtmWebAppLibPilotProfile.pilotQualifications.pilotCompetenciesHeader" | transloco }}

            <ng-container additionalActions>
                <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
                <mat-menu #actions="matMenu">
                    <button type="button" mat-menu-item (click)="getQualificationsRedirect.next()">
                        <dtm-ui-icon name="external-link"></dtm-ui-icon>
                        <span>{{ "dtmWebAppLibPilotProfile.pilotQualifications.goToTrainingsLabel" | transloco }}</span>
                    </button>
                    <button type="button" mat-menu-item (click)="pilotQualificationsShow.next()">
                        <dtm-ui-icon name="information"></dtm-ui-icon>
                        <span>{{ "dtmWebAppLibPilotProfile.pilotQualifications.moreInformationLabel" | transloco }}</span>
                    </button>
                </mat-menu>
            </ng-container>
        </dtm-ui-card-header>
        <div class="qualifications">
            <dtm-ui-qualification-badge *ngFor="let qualification of qualifications" [qualification]="qualification">
            </dtm-ui-qualification-badge>
        </div>
    </mat-card>
</ng-container>

<ng-template #noQualificationsTemplate>
    <dtm-web-app-lib-pilot-operator-empty-card
        [headerText]="'dtmWebAppLibPilotProfile.pilotQualifications.pilotCompetenciesHeader' | transloco"
        [headerButtonText]="'dtmWebAppLibShared.operatorQualifications.moreInformationLabel' | transloco"
        [actionButtonText]="'dtmWebAppLibPilotProfile.pilotQualifications.goToTrainingsLabel' | transloco"
        (headerButtonClicked)="pilotQualificationsShow.next()"
        (actionButtonClicked)="getQualificationsRedirect.emit()"
        isButtonVisible
    >
        <div textSlot class="info">
            <p>
                {{ "dtmWebAppLibPilotProfile.pilotQualifications.emptyState.competenciesInfo" | transloco }}
            </p>
            <p>
                <ng-container>{{ "dtmWebAppLibPilotProfile.pilotQualifications.emptyState.interestsInfo" | transloco }}</ng-container
                >&nbsp;
                <a [href]="CIVIL_AVIATION_AUTHORITY_GENERAL_INFO_URL" target="_blank" class="link">
                    {{ "dtmWebAppLibPilotProfile.pilotQualifications.emptyState.learnMoreLinkText" | transloco }}
                </a>
            </p>
        </div>
        <img imageSlot src="assets/images/pilot-competencies-empty-state.svg" />
    </dtm-web-app-lib-pilot-operator-empty-card>
</ng-template>
