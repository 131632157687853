<dtm-ui-loader-container
    *ngrxLet="{
        isExtendedMode: isExtendedMode$,
        isProcessing: isProcessing$,
        groupedMissionPlanList: groupedMissionPlanList$,
        areFiltersApplied: areFiltersApplied$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <div
        class="grid"
        *ngIf="vm.groupedMissionPlanList?.length && vm.groupedMissionPlanList as missionPlanList; else noMissionPlansTemplate"
    >
        <ng-container *ngFor="let missionPlan of missionPlanList; let index = index; trackBy: trackMission">
            <div
                class="mission-period"
                *ngIf="missionPlan.grouped.type && (index === 0 || missionPlan.grouped.firstDate.getTime() !== missionPlanList?.[index - 1]?.grouped?.firstDate?.getTime())"
            >
                <ng-container [ngSwitch]="missionPlan.grouped.type">
                    <ng-container *ngSwitchCase="MissionGroupByPeriod.Day">
                        {{ missionPlan.grouped.firstDate | localizeDate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="MissionGroupByPeriod.Week">
                        {{ missionPlan.grouped.firstDate | localizeDate }} - {{ missionPlan.grouped.secondDate | localizeDate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="MissionGroupByPeriod.Month">
                        {{ missionPlan.grouped.firstDate | localizeDate : { month: "long", year: "numeric" } }}
                    </ng-container>
                    <ng-container *ngSwitchCase="MissionGroupByPeriod.Year">
                        {{ missionPlan.grouped.firstDate | localizeDate : { year: "numeric" } }}
                    </ng-container>
                </ng-container>
            </div>
            <dtm-web-app-lib-mission-plan-tile
                [missionPlan]="missionPlan"
                [isExtendedMode]="vm.isExtendedMode"
                (missionPlanDelete)="missionPlanDelete.emit($event)"
                (missionPlanClone)="missionPlanClone.emit($event)"
                (missionCancel)="missionCancel.emit($event)"
                (missionPlanDownloadKml)="missionPlanDownloadKml.emit($event)"
                class="grid-col-4 grid-col-tablet-6"
            ></dtm-web-app-lib-mission-plan-tile>
        </ng-container>
    </div>
    <ng-template #noMissionPlansTemplate>
        <dtm-ui-no-results [areFiltersApplied]="vm.areFiltersApplied" [class.in-progress]="vm.isProcessing"></dtm-ui-no-results>
    </ng-template>
</dtm-ui-loader-container>
