<div class="form" [formGroup]="basicCompanyInfoForm">
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyNumberLabel" | transloco }}
        </label>
        <input matInput formControlName="companyNumber" [mask]="COMPANY_NUMBER_MASK" [showMaskTyped]="true" />
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.companyNumber; name: 'required'">
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.requiredFieldError" | transloco }}
        </div>

        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.companyNumber; name: 'minlength'; error as error">
            {{
                "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyNumberTooShortError"
                    | transloco : { minLength: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>{{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.nameLabel" | transloco }}</label>
        <input matInput type="text" formControlName="name" />
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.name; name: ['required', 'pattern']">
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.name; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyNameMaxLengthError"
                    | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>{{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.emailLabel" | transloco }}</label>
        <input matInput type="text" formControlName="email" />
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.email; name: 'required'">
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.email; name: 'email'">
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyEmailError" | transloco }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-phone-number-field formControlName="phone">
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.phone; name: 'required'">
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.phone; name: 'invalidNumber'">
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.invalidPhoneNumberErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="basicCompanyInfoForm.controls.phone; name: 'invalidNumberType'">
            {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.invalidNumberTypeErrorHint" | transloco }}
        </div>
    </dtm-ui-phone-number-field>
</div>
