import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest, map } from "rxjs";
import {
    ImportedDetails,
    ImportedEnterpriseOperatorDetails,
    ImportedPersonalOperatorDetails,
    LegalOperatorFormValue,
} from "../../../models/operator-migration.models";

interface EnterpriseOperatorSummaryPreviewComponentStep {
    editedOperatorDetails: LegalOperatorFormValue | undefined;
    importedDetails: ImportedDetails | undefined;
}

@Component({
    selector: "dtm-web-app-lib-legal-operator-summary[editedOperatorDetails][importedDetails]",
    templateUrl: "./legal-operator-summary.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LegalOperatorSummaryComponent {
    @Input() public set editedOperatorDetails(value: LegalOperatorFormValue | undefined) {
        this.localStore.patchState({ editedOperatorDetails: value });
    }
    @Input() public set importedDetails(value: ImportedDetails | undefined) {
        this.localStore.patchState({ importedDetails: value });
    }

    protected readonly editedOperatorDetails$ = this.localStore.selectByKey("editedOperatorDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly importedDetails$ = this.localStore.selectByKey("importedDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly importedOperatorDetails$ = this.importedDetails$.pipe(
        map((details) => {
            if (details?.migrationData.legalOperator) {
                return this.getOperatorImportedDetails(details.migrationData.legalOperator);
            } else if (details?.migrationData.personalOperator) {
                return this.getOperatorImportedDetails(details.migrationData.personalOperator);
            }

            return;
        })
    );
    protected readonly companyInfo$ = combineLatest([
        this.editedOperatorDetails$,
        this.importedDetails$.pipe(
            map((details) => details.migrationData.legalOperator),
            RxjsUtils.filterFalsy()
        ),
    ]).pipe(
        map(([editedOperatorDetails, legalOperatorDetails]) => ({
            name: legalOperatorDetails.companyData.name,
            companyNumber: editedOperatorDetails.companyNumber,
            address: editedOperatorDetails.address,
            phone: legalOperatorDetails.companyData.phone,
            email: legalOperatorDetails.companyData.email,
        }))
    );

    constructor(private readonly localStore: LocalComponentStore<EnterpriseOperatorSummaryPreviewComponentStep>) {
        localStore.setState({ editedOperatorDetails: undefined, importedDetails: undefined });
    }

    private getOperatorImportedDetails(details: ImportedEnterpriseOperatorDetails | ImportedPersonalOperatorDetails) {
        const { type, isApproved, number, secret, isForeign, operationalAuthorizations } = details;

        return { type, isApproved, number, secret, isForeign, operationalAuthorizations };
    }
}
