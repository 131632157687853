import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NationalNodeUserVerifiedGuard } from "../../guards/national-node-user-verified.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { MembershipOperatorProfileComponent } from "./components/operators/membership-operator-profile/membership-operator-profile.component";
import { MembershipOperatorsComponent } from "./components/operators/membership-operators/membership-operators.component";
import { InvitationAcceptanceComponent } from "./components/users/invitation-acceptance/invitation-acceptance.component";
import { MembershipPilotProfileComponent } from "./components/users/membership-pilot-profile/membership-pilot-profile.component";
import { MembershipUsersComponent } from "./components/users/membership-users/membership-users.component";
import { InitPilotProfileResolver } from "./services/init-pilot-profile.resolver";
import { InvitationAcceptanceResolver } from "./services/invitation-acceptance.resolver";
import { MembershipGuard } from "./services/membership.guard";

const routes: Routes = [
    {
        path: "membership/operators-list",
        component: MembershipOperatorsComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard, MembershipGuard],
        data: {
            titleTranslationKey: "dtmWebAppMembership.routeTitles.membershipOperators",
            shouldSkipReloadOnOperatorChange: true,
        },
    },
    {
        path: "membership/users-list",
        component: MembershipUsersComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, MembershipGuard],
        data: {
            titleTranslationKey: "dtmWebAppMembership.routeTitles.membershipUsers",
            shouldSkipReloadOnOperatorChange: true,
        },
    },
    {
        path: "membership/invitations/:id",
        component: InvitationAcceptanceComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard, MembershipGuard],
        data: {
            titleTranslationKey: "dtmWebAppMembership.routeTitles.invitationAcceptance",
        },
        resolve: {
            init: InvitationAcceptanceResolver,
        },
    },
    {
        path: "membership/pilot-profile/:pilotId/:membershipOperatorId/:membershipId",
        component: MembershipPilotProfileComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, MembershipGuard],
        data: {
            titleTranslationKey: "dtmWebAppMembership.routeTitles.pilotDetails",
        },
        resolve: {
            init: InitPilotProfileResolver,
        },
    },
    {
        path: "membership/operator-profile/:operatorId",
        component: MembershipOperatorProfileComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard, MembershipGuard],
        data: {
            titleTranslationKey: "dtmWebAppMembership.routeTitles.pilotDetails",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [InvitationAcceptanceResolver],
})
export class MembershipRoutingModule {}
