import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { ErrorPageComponent, NotAuthorizedComponent, PageNotFoundComponent, RELOAD_COMPONENT_ROUTE_CONFIG } from "@dtm-frontend/shared/ui";
import { ERROR_PAGE_ROUTE, NOT_FOUND_ROUTE } from "@dtm-frontend/shared/utils";
import { DashboardRoutingModule } from "@dtm-frontend/web-app-lib/dashboard";
import { HelpCenterRoutingModule } from "@dtm-frontend/web-app-lib/help-center";
import { MembershipRoutingModule } from "@dtm-frontend/web-app-lib/membership";
import { MissionRoutingModule } from "@dtm-frontend/web-app-lib/mission";
import { OperationsManualRoutingModule } from "@dtm-frontend/web-app-lib/operations-manual";
import { OperatorConversationsRoutingModule } from "@dtm-frontend/web-app-lib/operator-conversations";
import { OperatorMigrationRoutingModule } from "@dtm-frontend/web-app-lib/operator-migration";
import { OperatorPermissionsRoutingModule } from "@dtm-frontend/web-app-lib/operator-permissions";
import { OperatorPermitsRoutingModule } from "@dtm-frontend/web-app-lib/operator-permits";
import { OperatorProfileRoutingModule } from "@dtm-frontend/web-app-lib/operator-profile";
import { PilotOperatorRegistrationRoutingModule } from "@dtm-frontend/web-app-lib/pilot-operator-registration";
import { PilotProfileRoutingModule } from "@dtm-frontend/web-app-lib/pilot-profile";
import { SpecificPermitApplicationRoutingModule } from "@dtm-frontend/web-app-lib/specific-permit-application";
import { TacticalRoutingModule } from "@dtm-frontend/web-app-lib/tactical";
import { TrainingExamResultsRoutingModule } from "@dtm-frontend/web-app-lib/training-exam-results";
import { UavRoutingModule } from "@dtm-frontend/web-app-lib/uav";
import { UserProfileRoutingModule } from "@dtm-frontend/web-app-lib/user-profile";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/dashboard",
    },
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    {
        path: NOT_FOUND_ROUTE,
        component: PageNotFoundComponent,
        canActivate: [DtmAuthGuard],
    },
    {
        path: ERROR_PAGE_ROUTE,
        component: ErrorPageComponent,
    },
    RELOAD_COMPONENT_ROUTE_CONFIG,
    { path: "**", redirectTo: `/${NOT_FOUND_ROUTE}` },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" }),
        MissionRoutingModule,
        UavRoutingModule,
        SpecificPermitApplicationRoutingModule,
        TacticalRoutingModule,
        OperatorConversationsRoutingModule,
        MembershipRoutingModule,
        UserProfileRoutingModule,
        OperatorProfileRoutingModule,
        PilotOperatorRegistrationRoutingModule,
        OperatorPermitsRoutingModule,
        OperatorPermissionsRoutingModule,
        PilotProfileRoutingModule,
        OperationsManualRoutingModule,
        OperatorMigrationRoutingModule,
        TrainingExamResultsRoutingModule,
        DashboardRoutingModule,
        HelpCenterRoutingModule,
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: [],
        },
    ],
})
export class AppRoutingModule {}
