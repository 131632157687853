<ng-container *ngrxLet="{ messages: messages$ } as vm">
    <dtm-ui-expandable-panel [hasHeaderSeparator]="false" class="messages" *ngIf="vm.messages.length">
        <h2 class="sub-header" headerSlot>
            <dtm-ui-icon name="mail-send" />
            {{ "dtmSharedMission.flightRequirements.messageFromManagerTitle" | transloco }}
        </h2>
        <div class="message" *ngFor="let message of vm.messages">
            <p class="message-date">
                {{ message.time | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
            </p>
            <p class="message-text">{{ message.text }}</p>
        </div>
    </dtm-ui-expandable-panel>
</ng-container>
