<dtm-ui-expandable-panel
    *ngrxLet="{
        availableOperationScenarios: availableOperationScenarios$,
        currentCompetencies: currentCompetencies$,
        isCompetenciesSetEmpty: isCompetenciesSetEmpty$,
        isEditMode: isEditMode$,
    } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [isDisabled]="vm.isEditMode || vm.isCompetenciesSetEmpty"
    [hasHeaderSeparator]="false"
>
    <div class="panel-header" headerSlot>
        <h2>
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.headerLabel" | transloco }}
        </h2>
        <button
            *ngIf="!vm.isEditMode"
            type="button"
            class="button-secondary"
            [class]="!vm.isCompetenciesSetEmpty ? 'edit-button' : 'add-button'"
            (click)="$event.stopPropagation(); setEditMode(true)"
        >
            <ng-container *ngIf="!vm.isCompetenciesSetEmpty">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.editButtonLabel" | transloco }}
            </ng-container>
            <ng-container *ngIf="vm.isCompetenciesSetEmpty">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.addButtonLabel" | transloco }}
            </ng-container>
        </button>
        <button *ngIf="vm.isEditMode" type="button" class="button-icon close-button" (click)="$event.stopPropagation(); cancel()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <section *ngIf="!vm.isEditMode">
        <ng-container *ngFor="let item of vm.currentCompetencies | keyvalue">
            <dtm-web-app-lib-spec-perm-app-competencies
                *ngIf="item.value"
                [basicCompetencies]="item.value.basic"
                [additionalCompetencies]="item.value.additional"
            >
                <h3 basicHeaderSlot class="member-header">
                    {{ "mission.additional_crew_members." + item.key + ".name" | systemTranslation }}
                </h3>
                <span additionalHeaderSlot class="additional-header">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.additionalCompetenciesLabel" | transloco }}
                </span>
            </dtm-web-app-lib-spec-perm-app-competencies>
        </ng-container>
        <div class="no-members" *ngIf="vm.isCompetenciesSetEmpty">
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.noCrewCompetenciesLabel" | transloco }}
        </div>
    </section>
    <section class="edit-mode-container" *ngIf="vm.isEditMode">
        <ng-container [formGroup]="crewCompetenciesForm">
            <dtm-web-app-lib-spec-perm-app-crew-competencies-form
                formControlName="competencies"
                [availableOperationScenarios]="vm.availableOperationScenarios"
            >
            </dtm-web-app-lib-spec-perm-app-crew-competencies-form>
        </ng-container>
        <footer class="actions">
            <button type="button" class="button-secondary" (click)="cancel()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="save()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.saveButtonLabel" | transloco }}
            </button>
        </footer>
    </section>
</dtm-ui-expandable-panel>
