import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from "@angular/forms";
import { DEFAULT_LANG, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { FormType, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, distinctUntilChanged, map } from "rxjs/operators";
import { StaffTrainings, Training } from "../../../services/specific-permit-application.models";

interface TrainingsFormComponentState {
    availableTrainings: Training[];
    selectableTrainings: TrainingOption[];
    isAdditionalTrainingsOptionSelected: boolean;
    activeLanguage: LanguageCode;
}

enum TrainingOptionType {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Training = "TRAINING",
    Additional = "ADDITIONAL",
}

type TrainingOption = { type: TrainingOptionType.Training; training: Training; name: string } | { type: TrainingOptionType.Additional };

interface TrainingsFormValues {
    basic: TrainingOption[] | null;
    additional: string[];
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-trainings-form[availableTrainings]",
    templateUrl: "./trainings-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TrainingsFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TrainingsFormComponent),
            multi: true,
        },
    ],
})
export class TrainingsFormComponent implements ControlValueAccessor, Validator {
    @Input() public set availableTrainings(value: Training[] | undefined) {
        this.localStore.patchState({ availableTrainings: value ?? [] });
    }

    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value ?? DEFAULT_LANG });
    }

    protected readonly trainingsForm = new FormGroup<FormType<TrainingsFormValues>>({
        basic: new FormControl<TrainingOption[]>([], {
            nonNullable: true,
        }),
        additional: new FormControl<string[]>([], {
            nonNullable: true,
        }),
    });

    protected readonly selectableTrainings$ = this.localStore.selectByKey("selectableTrainings");
    protected readonly isAdditionalTrainingsOptionSelected$ = this.localStore.selectByKey("isAdditionalTrainingsOptionSelected");
    protected readonly activeLanguage$ = this.localStore.selectByKey("activeLanguage");

    protected readonly TrainingOptionType = TrainingOptionType;

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: StaffTrainings | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<TrainingsFormComponentState>) {
        this.localStore.setState({
            availableTrainings: [],
            selectableTrainings: [],
            isAdditionalTrainingsOptionSelected: false,
            activeLanguage: DEFAULT_LANG,
        });

        this.buildSelectableOptions();
        this.handleAdditionalTrainingsChange();

        this.trainingsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.propagateChange(this.convertFormValuesToTrainings(values as TrainingsFormValues));
        });
    }

    public registerOnChange(fn: (value: StaffTrainings | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: StaffTrainings | null): void {
        if (value) {
            this.trainingsForm.setValue(this.convertTrainingsToFormValues(value), { emitEvent: false });
            this.localStore.patchState({ isAdditionalTrainingsOptionSelected: !!value.additional.length });
        } else {
            this.trainingsForm.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.trainingsForm.disable();
        } else {
            this.trainingsForm.enable();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.trainingsForm.invalid) {
            return { required: true };
        }

        return null;
    }

    private buildSelectableOptions(): void {
        this.localStore
            .selectByKey("availableTrainings")
            .pipe(
                combineLatestWith(this.localStore.selectByKey("activeLanguage")),
                map(([availableTrainings, activeLanguage]) => this.createTrainingOptions(availableTrainings, activeLanguage)),
                untilDestroyed(this)
            )
            .subscribe((selectableTrainings) => this.localStore.patchState({ selectableTrainings }));
    }

    private handleAdditionalTrainingsChange(): void {
        this.trainingsForm.controls.basic.valueChanges
            .pipe(
                map((value) => (value ?? []).some((option) => option.type === TrainingOptionType.Additional)),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((isAdditionalTrainingsOptionSelected) => {
                if (!isAdditionalTrainingsOptionSelected) {
                    this.trainingsForm.controls.additional.reset();
                }

                this.localStore.patchState({ isAdditionalTrainingsOptionSelected });
            });
    }

    private convertTrainingsToFormValues(trainings: StaffTrainings): TrainingsFormValues {
        return {
            basic: this.getTrainingOptions(trainings.basic, trainings.additional.length > 0),
            additional: trainings.additional,
        };
    }

    private createTrainingOptions(trainings: Training[], language: LanguageCode): TrainingOption[] {
        const trainingOptions: TrainingOption[] = [
            ...trainings.map((training) => ({
                type: TrainingOptionType.Training,
                training,
                name: training.name[language] ?? training.name[DEFAULT_LANG],
            })),
        ];

        trainingOptions.push({ type: TrainingOptionType.Additional });

        return trainingOptions;
    }

    private getTrainingOptions(trainings: Training[], isAdditionalTrainingsOptionEnabled: boolean = true): TrainingOption[] {
        return this.localStore.selectSnapshotByKey("selectableTrainings").filter((option) => {
            if (option.type === TrainingOptionType.Training) {
                return trainings.some((training) => training.id === option.training.id);
            } else if (option.type === TrainingOptionType.Additional) {
                return isAdditionalTrainingsOptionEnabled;
            }

            return false;
        });
    }

    private convertFormValuesToTrainings(values: TrainingsFormValues): StaffTrainings | null {
        const basicOptions = values.basic ?? [];

        return {
            basic: basicOptions.reduce<Training[]>(
                (trainings, option) => (option.type === TrainingOptionType.Training ? [...trainings, option.training] : trainings),
                []
            ),
            additional: basicOptions.some((option) => option.type === TrainingOptionType.Additional) ? values.additional : [],
        };
    }
}
