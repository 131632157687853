<ng-container *ngrxLet="availableOperationScenarios$ as availableOperationScenarios" [formGroup]="crewCompetenciesForm">
    <ng-container formArrayName="crewMembers">
        <section
            *ngFor="let crewMember of crewFormArray.controls; index as crewMemberIndex"
            [formGroupName]="crewMemberIndex"
            class="crew-member"
        >
            <div class="header-wrapper">
                <h2>{{ "mission.additional_crew_members." + crewMember.value.role + ".name" | systemTranslation }}</h2>
                <button
                    *ngIf="crewMember.controls.areCompetenciesSet.value"
                    type="button"
                    class="button-secondary"
                    (click)="removeCompetencies(crewMemberIndex)"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.removeCrewMemberCompetenciesButtonLabel" | transloco }}
                </button>
                <button
                    *ngIf="!crewMember.controls.areCompetenciesSet.value"
                    type="button"
                    class="button-secondary"
                    (click)="addCompetencies(crewMemberIndex)"
                >
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.addCrewMemberCompetenciesButtonLabel" | transloco }}
                </button>
            </div>
            <dtm-web-app-lib-spec-perm-app-competencies-form
                *ngIf="crewMember.controls.areCompetenciesSet.value"
                [formControl]="crewMember.controls.competencies"
                [availableOperationScenarios]="availableOperationScenarios"
            >
            </dtm-web-app-lib-spec-perm-app-competencies-form>
        </section>
    </ng-container>
</ng-container>
