<ng-container *ngIf="error$ | ngrxPush; else noErrorTemplate">
    <dtm-ui-error
        class="grid grid-maxsize-6"
        [errorMessage]="'dtmWebAppLibUav.newUavWizardSteps.cannotGetCapabilitiesErrorMessage' | transloco"
    ></dtm-ui-error>
</ng-container>

<ng-template #noErrorTemplate>
    <ng-container *ngIf="canManageUavs$ | ngrxPush; else notAuthorizedTemplate">
        <dtm-ui-lib-wizard-content
            [wizardId]="wizardId"
            *ngrxLet="{ isProcessing: isProcessing$, navigationAccuracyItems: navigationAccuracyItems$, trackers: trackers$ } as vm"
        >
            <dtm-web-app-lib-new-uav-wizard-basic-info-step
                *dtmUiLibWizardStepContent="NewUavWizardSteps.BasicInfo"
                [initialValues]="basicInfoData$ | ngrxPush"
                [manufacturers]="manufacturers$ | ngrxPush"
                [isProcessing]="vm.isProcessing"
                (next)="setBasicInfo($event)"
            >
            </dtm-web-app-lib-new-uav-wizard-basic-info-step>
            <ng-container *dtmUiLibWizardStepContent="NewUavWizardSteps.PrimarySetup">
                <dtm-web-app-lib-new-uav-wizard-custom-uav-primary-setup-step
                    *ngIf="customUavBasicInfo$ | ngrxPush as customUavBasicInfo; else standardUavSetupTemplate"
                    [basicInfo]="customUavBasicInfo"
                    [trackers]="vm.trackers"
                    [navigationAccuracyItems]="vm.navigationAccuracyItems"
                    [isProcessing]="vm.isProcessing"
                    (back)="goBackToStep(NewUavWizardSteps.BasicInfo)"
                    (next)="setCustomUavPrimarySetup($event)"
                    (valueChange)="markValueChange()"
                >
                </dtm-web-app-lib-new-uav-wizard-custom-uav-primary-setup-step>
                <ng-template #standardUavSetupTemplate>
                    <dtm-web-app-lib-new-uav-wizard-primary-setup-step
                        [newUavModel]="newUavModel$ | ngrxPush"
                        [trackers]="vm.trackers"
                        [navigationAccuracyItems]="vm.navigationAccuracyItems"
                        [isProcessing]="vm.isProcessing"
                        (back)="goBackToStep(NewUavWizardSteps.BasicInfo)"
                        (next)="setPrimarySetup($event)"
                        (valueChange)="markValueChange()"
                    >
                    </dtm-web-app-lib-new-uav-wizard-primary-setup-step>
                </ng-template>
            </ng-container>
        </dtm-ui-lib-wizard-content>
    </ng-container>
</ng-template>
<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
