import {
    MembershipInvitationError,
    MembershipMissionsFilter,
    MembershipPermission,
    MembershipUserInvitation,
    MembersQueryParams,
} from "../services/membership.models";

export namespace MembershipActions {
    export class GetCapabilities {
        public static readonly type = "[Membership] Get invited pilots capabilities";

        constructor(public operatorId: string) {}
    }

    export class InviteUser {
        public static readonly type = "[Membership] Invite user";

        constructor(public invitation: MembershipUserInvitation) {}
    }

    export class ChangeMemberUserPermissions {
        public static readonly type = "[Membership] Change membership user permissions";

        constructor(public operatorId: string, public membershipId: string | null, public permissions: MembershipPermission[]) {}
    }

    export class ChangeInvitedUserPermissions {
        public static readonly type = "[Membership] Change invited user permissions";

        constructor(public operatorId: string, public invitationId: string, public permissions: MembershipPermission[]) {}
    }

    export class GetInvitationDetails {
        public static readonly type = "[Membership] Get invitation details";

        constructor(public invitationId: string) {}
    }

    export class AcceptInvitation {
        public static readonly type = "[Membership] Accept invitation";

        constructor(public invitationId: string) {}
    }

    export class RejectInvitation {
        public static readonly type = "[Membership] Reject invitation";

        constructor(public invitationId: string) {}
    }

    export class SetMembershipInvitationError {
        public static readonly type = "[Membership] Set membership invitation error";

        constructor(public invitationError: MembershipInvitationError) {}
    }

    export class GetMembers {
        public static readonly type = "[Membership] Get members";

        constructor(public params: MembersQueryParams) {}
    }

    export class ClearMembersList {
        public static readonly type = "[Membership] Clear membersList";
    }

    export class GetPilotsProfile {
        public static readonly type = "[Membership] Get pilots profile";

        constructor(public membershipPilotId: string) {}
    }

    export class DeactivateMember {
        public static readonly type = "[Membership] Deactivate member";

        constructor(public membershipId: string, public membershipOperatorId: string, public note?: string) {}
    }

    export class RemoveInvitation {
        public static readonly type = "[Membership] Remove invitation";

        constructor(public invitationId: string) {}
    }

    export class ResendInvitation {
        public static readonly type = "[Membership] Resend invitation";

        constructor(public invitationId: string) {}
    }

    export class ResendInvitationToMember {
        public static readonly type = "[Membership] Resend invitation to member";

        constructor(public membershipId: string) {}
    }

    export class RemoveMember {
        public static readonly type = "[Membership] Remove member";

        constructor(public membershipId: string) {}
    }

    export class GetMissionsList {
        public static readonly type = "[Membership] Get missions list";

        constructor(
            public params: { operatorId: string; pilotId: string; filtersQuery: MembershipMissionsFilter; size: number; page: number }
        ) {}
    }
}
