import { Injectable } from "@angular/core";

import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { PilotProfileActions } from "../state/pilot-profile.actions";

@Injectable({
    providedIn: "root",
})
export class PilotProfileResolver {
    constructor(private readonly store: Store) {}

    public resolve(): Observable<void> {
        return this.store.select(OperatorContextState.pilot).pipe(
            RxjsUtils.filterFalsy(),
            first(),
            switchMap(({ id }) => this.store.dispatch(new PilotProfileActions.GetPilotProfile(id)))
        );
    }
}
