import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AssociationPermit, MIN_PAGE_SIZE_VALUE, UploadedFile } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { OPERATOR_PERMITS_ENDPOINTS, OperatorPermitsEndpoints } from "../operator-permits.tokens";
import {
    AssociationPermitResponseBody,
    CrossBorderPermitResponseBody,
    OperatorPermitWithUavModelResponseBody,
    SpecificPermitDraftsListResponseBody,
    SpecificPermitListResponseBody,
    convertAssociationPermitResponseBodyToAssociationPermit,
    convertCrossBorderPermitResponseBodyToRawPermitDetails,
    convertOperatorPermitResponseBodyToRawPermitDetails,
    convertOwnedSpecificPermitListResponseBodyToOwnedSpecificPermitListWithPages,
    convertSpecificPermitDraftsListResponseBodyToSpecificPermitDraftsListWithPages,
} from "./operator-permits.converters";
import {
    OperatorPermitsCapabilities,
    OperatorPermitsErrorType,
    OwnedSpecificPermitListWithPages,
    PermitListItem,
    SpecificPermitDraftsListWithPages,
    SpecificPermitListQueryParams,
} from "./operator-permits.models";
import { RawPermitDetails } from "./operator-permits.utils";

@Injectable({
    providedIn: "root",
})
export class OperatorPermitsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATOR_PERMITS_ENDPOINTS) private readonly endpoints: OperatorPermitsEndpoints
    ) {}

    public getSpecificPermitsList(params: SpecificPermitListQueryParams): Observable<OwnedSpecificPermitListWithPages> {
        const requestParams = this.getSpecificPermitsListRequestParams(params);

        return this.httpClient
            .get<SpecificPermitListResponseBody>(StringUtils.replaceInTemplate(this.endpoints.specificPermits, { id: params.operatorId }), {
                params: requestParams,
            })
            .pipe(
                map((response) => convertOwnedSpecificPermitListResponseBodyToOwnedSpecificPermitListWithPages(response)),
                catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotGetSpecificPermitsList })))
            );
    }

    public getSpecificPermitsDraftList(params: SpecificPermitListQueryParams): Observable<SpecificPermitDraftsListWithPages> {
        const requestParams = this.getSpecificPermitsDraftsListRequestParams(params);

        return this.httpClient
            .get<SpecificPermitDraftsListResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.specificPermitDrafts, { id: params.operatorId }),
                { params: requestParams }
            )
            .pipe(
                map((response) => convertSpecificPermitDraftsListResponseBodyToSpecificPermitDraftsListWithPages(response)),
                catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotGetSpecificPermitsList })))
            );
    }

    public updatePermitName(permit: PermitListItem, name: string): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updatePermitName, { id: permit.id, operatorId: permit.operatorId }), {
                name,
            })
            .pipe(catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotUpdateSpecificPermitName }))));
    }

    public getKmlFile(file: UploadedFile, operatorId: string): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadKmlLocationFile, { kmlFileId: file.id, operatorId }), {
                responseType: "blob",
            })
            .pipe(catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotGetKmlFile }))));
    }

    public removeDraft(draftId: string, operatorId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeDraft, { draftId, operatorId }))
            .pipe(catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotRemoveDraft }))));
    }

    public getOperatorPermitsCapabilities(operatorId: string): Observable<OperatorPermitsCapabilities> {
        return this.httpClient
            .get<OperatorPermitsCapabilities>(
                StringUtils.replaceInTemplate(this.endpoints.getOperatorPermitsCapabilities, { id: operatorId })
            )
            .pipe(catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotGetCapabilities }))));
    }

    public getSpecificPermitDetails(permitId: string, operatorId: string): Observable<RawPermitDetails> {
        return this.httpClient
            .get<OperatorPermitWithUavModelResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getOperatorPermitDetails, { id: permitId, operatorId })
            )
            .pipe(
                map((response) => convertOperatorPermitResponseBodyToRawPermitDetails(response)),
                catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotGetPermitDetails })))
            );
    }

    public getCrossBorderPermitDetails(permitId: string, operatorId: string): Observable<RawPermitDetails> {
        return this.httpClient
            .get<CrossBorderPermitResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getCrossBorderPermitDetails, { id: permitId, operatorId })
            )
            .pipe(
                map((response) => convertCrossBorderPermitResponseBodyToRawPermitDetails(response)),
                catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotGetPermitDetails })))
            );
    }

    public getAssociationPermitDetails(permitId: string, operatorId: string): Observable<AssociationPermit> {
        return this.httpClient
            .get<AssociationPermitResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getAssociationPermitDetails, { id: permitId, operatorId })
            )
            .pipe(
                map((response) => convertAssociationPermitResponseBodyToAssociationPermit(response)),
                catchError(() => throwError(() => ({ type: OperatorPermitsErrorType.CannotGetPermitDetails })))
            );
    }

    private getSpecificPermitsListRequestParams(params: SpecificPermitListQueryParams): HttpParams {
        let requestParams = new HttpParams()
            .set("size", params.pageSize ?? MIN_PAGE_SIZE_VALUE)
            .set("page", params.pageNumber ?? 0)
            .set("operatorId", params.operatorId);

        if (params.searchPhrase) {
            requestParams = requestParams.set("searchPhrase", params.searchPhrase);
        }

        if (params.status?.length) {
            requestParams = requestParams.set("statuses", params.status.toString());
        }

        return requestParams;
    }

    private getSpecificPermitsDraftsListRequestParams(params: SpecificPermitListQueryParams): HttpParams {
        let requestParams = new HttpParams()
            .set("size", params.pageSize ?? MIN_PAGE_SIZE_VALUE)
            .set("page", params.pageNumber ?? 0)
            .set("operatorId", params.operatorId);

        if (params.searchPhrase) {
            requestParams = requestParams.set("searchText", params.searchPhrase);
        }

        return requestParams;
    }
}
