import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ConfirmationDialogComponent, DialogService, FILES_UPLOAD_API_PROVIDER, getAttachmentIdsList } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { first, switchMap } from "rxjs/operators";
import { OperatorContextState } from "../../../shared";
import { OperatorConversationsFileUploadApiService } from "../../services/operator-conversations-file-upload-api.service";
import { OperatorConversationsActions } from "../../state/operator-conversations.actions";
import { OperatorConversationsState } from "../../state/operator-conversations.state";

interface NewThreadComponentState {
    isAttachmentsControlVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-new-thread",
    templateUrl: "./new-thread.component.html",
    styleUrls: ["./new-thread.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: OperatorConversationsFileUploadApiService }],
})
export class NewThreadComponent implements OnInit {
    public readonly editorControl = new UntypedFormControl(null);
    public readonly categoryAndSubjectControl = new UntypedFormControl();

    public readonly conversationForm = new UntypedFormGroup({
        categoryAndSubject: this.categoryAndSubjectControl,
        editor: this.editorControl,
    });

    public readonly conversationCategories$ = this.store.select(OperatorConversationsState.conversationCategories);
    public readonly capabilitiesError$ = this.store.select(OperatorConversationsState.capabilitiesError);
    public readonly isAttachmentsControlVisible$ = this.localStore.selectByKey("isAttachmentsControlVisible");

    constructor(
        private readonly store: Store,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService,
        private readonly localStore: LocalComponentStore<NewThreadComponentState>
    ) {
        localStore.setState({
            isAttachmentsControlVisible: false,
        });
    }

    public ngOnInit(): void {
        this.getCapabilities();
    }

    public close() {
        if (this.conversationForm.touched) {
            this.confirmAndClose();

            return;
        }
        this.dialogService.closeAll();
    }

    public getCapabilities() {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(
                RxjsUtils.filterFalsy(),
                first(),
                switchMap((context) => this.store.dispatch(new OperatorConversationsActions.GetOperatorMessageCapabilities(context?.id))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private confirmAndClose() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageTitleText"
                ),
                confirmationText: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageConfirmText"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageCancelLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageConfirmLabel"
                ),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }
                this.dialogService.closeAll();
            });
    }

    public async createThread() {
        this.conversationForm.markAllAsTouched();

        if (!this.conversationForm.valid) {
            return;
        }

        const operator = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );

        const { subject, category } = this.categoryAndSubjectControl.value;

        this.store
            .dispatch(
                new OperatorConversationsActions.CreateNewThread({
                    content: this.conversationForm.controls.editor.value.content,
                    attachmentIdsList: getAttachmentIdsList(this.conversationForm.controls.editor.value.attachments),
                    subject,
                    category,
                    sender: {
                        id: operator.id,
                        name: operator.name,
                    },
                })
            )
            .pipe(
                switchMap(() => this.store.select(OperatorConversationsState.sendMessageStatusError)),
                first()
            )
            .subscribe((sendMessageStatusError) => {
                if (sendMessageStatusError) {
                    this.handleSendMessageError();

                    return;
                }

                this.handleSendMessageSuccess();
            });
    }

    protected changeAttachmentControlVisibility() {
        this.localStore.patchState({ isAttachmentsControlVisible: true });
    }

    private handleSendMessageError() {
        this.toastrService.error(
            this.translocoService.translate("dtmWebAppLibOperatorConversations.operatorConversationsContainer.messageSendErrorMessage")
        );
    }

    private handleSendMessageSuccess() {
        this.dialogService.closeAll();
        this.toastrService.success(
            this.translocoService.translate("dtmWebAppLibOperatorConversations.operatorConversationsContainer.messageSendSuccessMessage")
        );
    }
}
