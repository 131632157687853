import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { AttorneyDocumentsApiService } from "../../../shared/services/attorney-documents/attorney-documents-api.service";
import {
    ATTORNEY_DOCUMENT_ENDPOINTS,
    AttorneyDocumentEndpoints,
} from "../../../shared/services/attorney-documents/attorney-documents-api.token";
import {
    AttorneyPowerType,
    NewAttorneyPower,
    OperatorAttorneyPower,
    OperatorAttorneyPowerCapabilities,
    OperatorProfileError,
} from "../../models/operator.models";
import { OPERATOR_PROFILE_ENDPOINTS, OperatorProfileEndpoints } from "../../operator-profile.tokens";

interface AttorneyPowerComponentState {
    attorneyPowers: OperatorAttorneyPower[] | undefined;
    companyName: string | undefined;
    showForm: boolean;
    isCapabilitiesProcessing: boolean;
    capabilities: OperatorAttorneyPowerCapabilities | undefined;
    capabilitiesError: OperatorProfileError | undefined;
    isAttorneyPowerProcessing: boolean;
    userId: string | undefined;
}

function attorneyPowerDocumentFactory(endpoints: OperatorProfileEndpoints): AttorneyDocumentEndpoints {
    return {
        uploadFile: endpoints.attorneyPowerDocumentUpload,
        downloadFile: endpoints.getAttorneyPowerDocument,
    };
}

@Component({
    selector: "dtm-web-app-lib-attorney-power[attorneyPowers][companyName][capabilities][userId]",
    templateUrl: "./attorney-power.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: AttorneyDocumentsApiService },
        {
            provide: ATTORNEY_DOCUMENT_ENDPOINTS,
            useFactory: attorneyPowerDocumentFactory,
            deps: [OPERATOR_PROFILE_ENDPOINTS],
        },
    ],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AttorneyPowerComponent {
    @Input()
    public set attorneyPowers(value: OperatorAttorneyPower[] | undefined) {
        this.localStore.patchState({ attorneyPowers: value, showForm: false });
    }

    @Input()
    public set companyName(value: string | undefined) {
        this.localStore.patchState({ companyName: value });
    }

    @Input()
    public set capabilities(value: OperatorAttorneyPowerCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }

    @Input()
    public set isCapabilitiesProcessing(value: BooleanInput) {
        this.localStore.patchState({ isCapabilitiesProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set capabilitiesError(value: OperatorProfileError | undefined) {
        this.localStore.patchState({ capabilitiesError: value });
    }

    @Input()
    public set isAttorneyPowerProcessing(value: BooleanInput) {
        this.localStore.patchState({ isAttorneyPowerProcessing: coerceBooleanProperty(value) });
    }

    @Input()
    public set userId(value: string | undefined) {
        this.localStore.patchState({ userId: value });
    }

    @Output() public getCapabilities = new EventEmitter<void>();
    @Output() public attorneyPowerTemplateDownload = new EventEmitter<void>();
    @Output() public formSave = new EventEmitter<NewAttorneyPower>();
    @Output() public attorneyPowerRemove = new EventEmitter<string>();

    protected readonly AttorneyPowerType = AttorneyPowerType;
    protected readonly isAttorneyPowerProcessing$ = this.localStore.selectByKey("isAttorneyPowerProcessing");
    protected readonly capabilitiesError$ = this.localStore.selectByKey("capabilitiesError");
    protected readonly capabilities$ = this.localStore.selectByKey("capabilities").pipe(RxjsUtils.filterFalsy());
    protected readonly isCapabilitiesProcessing$ = this.localStore.selectByKey("isCapabilitiesProcessing");
    protected readonly attorneyPowers$ = this.localStore.selectByKey("attorneyPowers");
    protected readonly companyName$ = this.localStore.selectByKey("companyName").pipe(RxjsUtils.filterFalsy());
    protected readonly showForm$ = this.localStore.selectByKey("showForm");
    protected readonly userId$ = this.localStore.selectByKey("userId").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<AttorneyPowerComponentState>) {
        localStore.setState({
            attorneyPowers: undefined,
            companyName: undefined,
            showForm: false,
            isCapabilitiesProcessing: false,
            capabilities: undefined,
            capabilitiesError: undefined,
            userId: undefined,
            isAttorneyPowerProcessing: false,
        });
    }

    protected changeAttorneyPowerFormVisibility(showForm: boolean) {
        if (!this.localStore.selectSnapshotByKey("capabilities")?.members) {
            this.getCapabilities.emit();
        }

        this.localStore.patchState({ showForm });
    }
}
