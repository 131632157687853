import { UserProfileErrorType } from "../models/user-profile.models";

type ObjectType = { [k in string]: string };

export const getI18nUpdateProfileError = (errorType: UserProfileErrorType): string => {
    const translation: ObjectType = {
        [UserProfileErrorType.IncorrectVerificationCode]: "dtmWebAppUserProfile.notifications.incorrectVerificationCode",
        [UserProfileErrorType.NotFound]: "dtmWebAppUserProfile.notifications.cannotUpdateUserData",
        [UserProfileErrorType.CannotSendVerificationCodeForEmailChange]: "dtmWebAppUserProfile.notifications.cannotRequestAddressChange",
        [UserProfileErrorType.CannotSendVerificationCodeForPhoneChange]: "dtmWebAppUserProfile.notifications.cannotRequestPhoneChange",
        [UserProfileErrorType.Unknown]: "dtmWebAppUserProfile.notifications.unknownError",
        [UserProfileErrorType.CannotSaveUserProfileLanguage]: "dtmWebAppUserProfile.notifications.cannotSaveUserProfileLanguage",
        [UserProfileErrorType.CannotResetUserPassword]: "dtmWebAppUserProfile.notifications.cannotResetUserPassword",
        [UserProfileErrorType.CannotSaveProfileAvatar]: "dtmWebAppUserProfile.notifications.cannotSaveProfileAvatar",
        [UserProfileErrorType.CannotSaveIdentityDocument]: "dtmWebAppUserProfile.notifications.cannotSaveIdentityDocument",
        [UserProfileErrorType.CannotGetIdentityDocument]: "dtmWebAppUserProfile.notifications.cannotGetIdentityDocument",
        [UserProfileErrorType.CannotResendLegalGuardianEmailAddress]:
            "dtmWebAppUserProfile.notifications.cannotResendLegalGuardianEmailAddress",
        [UserProfileErrorType.CannotChangeLegalGuardianConfirmationData]:
            "dtmWebAppUserProfile.notifications.cannotChangeLegalGuardianConfirmationData",
        [UserProfileErrorType.CannotResendVerificationCode]: "dtmWebAppUserProfile.notifications.cannotResendVerificationCode",
    };

    return translation[errorType] || translation[UserProfileErrorType.Unknown];
};
