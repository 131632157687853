import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { OperatorContextActions } from "../../../shared/operator-context/state/operator-context.actions";
import { ForeignOperatorValidation, OperatorRegistrationCapabilities, PilotOperatorRegistrationError } from "../../shared/models";
import { OperatorRegistrationError, RegisteredOperator } from "../services/models";
import { OperatorRegistrationApiService } from "../services/operator-registration-api.service";
import { OperatorRegistrationActions } from "./operator-registration.actions";

export interface OperatorRegistrationStateModel {
    operatorRegistrationCapabilities: OperatorRegistrationCapabilities | undefined;
    operatorRegistrationCapabilitiesError: OperatorRegistrationError | undefined;
    isOperatorRegistrationCapabilitiesProcessing: boolean;
    foreignOperatorValidation: ForeignOperatorValidation | undefined;
    operatorRegistrationError: PilotOperatorRegistrationError | undefined;
    lastRegisteredOperator: RegisteredOperator | undefined;
    isProcessing: boolean;
    downloadAttorneyTemplateError: PilotOperatorRegistrationError | undefined;
}

const defaultState: OperatorRegistrationStateModel = {
    operatorRegistrationCapabilities: undefined,
    operatorRegistrationCapabilitiesError: undefined,
    isOperatorRegistrationCapabilitiesProcessing: false,
    foreignOperatorValidation: undefined,
    operatorRegistrationError: undefined,
    lastRegisteredOperator: undefined,
    isProcessing: false,
    downloadAttorneyTemplateError: undefined,
};

@State<OperatorRegistrationStateModel>({
    name: "operatorRegistration",
    defaults: defaultState,
})
@Injectable()
export class OperatorRegistrationState {
    constructor(private readonly operatorRegistrationApiService: OperatorRegistrationApiService, private readonly store: Store) {
        if (operatorRegistrationApiService === undefined) {
            throw new Error("Initialize PilotOperatorRegistrationModule with .forRoot()");
        }
    }

    @Selector()
    public static operatorRegistrationCapabilities(state: OperatorRegistrationStateModel): OperatorRegistrationCapabilities | undefined {
        return state.operatorRegistrationCapabilities;
    }

    @Selector()
    public static operatorRegistrationCapabilitiesError(state: OperatorRegistrationStateModel): OperatorRegistrationError | undefined {
        return state.operatorRegistrationCapabilitiesError;
    }

    @Selector()
    public static isOperatorRegistrationCapabilitiesProcessing(state: OperatorRegistrationStateModel): boolean {
        return state.isOperatorRegistrationCapabilitiesProcessing;
    }

    @Selector()
    public static foreignOperatorValidation(state: OperatorRegistrationStateModel): ForeignOperatorValidation | undefined {
        return state.foreignOperatorValidation;
    }

    @Selector()
    public static lastRegisteredOperator(state: OperatorRegistrationStateModel): RegisteredOperator | undefined {
        return state.lastRegisteredOperator;
    }

    @Selector()
    public static operatorRegistrationError(state: OperatorRegistrationStateModel): PilotOperatorRegistrationError | undefined {
        return state.operatorRegistrationError;
    }

    @Selector()
    public static isProcessing(state: OperatorRegistrationStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static downloadAttorneyTemplateError(state: OperatorRegistrationStateModel): PilotOperatorRegistrationError | undefined {
        return state.downloadAttorneyTemplateError;
    }

    @Action(OperatorRegistrationActions.GetOperatorRegistrationCapabilities)
    public getOperatorRegistrationCapabilities(
        context: StateContext<OperatorRegistrationStateModel>,
        action: OperatorRegistrationActions.GetOperatorRegistrationCapabilities
    ) {
        context.patchState({
            operatorRegistrationCapabilities: undefined,
            operatorRegistrationCapabilitiesError: undefined,
            isOperatorRegistrationCapabilitiesProcessing: true,
        });

        return this.operatorRegistrationApiService.getOperatorRegistrationCapabilities(action.userId).pipe(
            tap((result) =>
                context.patchState({
                    operatorRegistrationCapabilities: result,
                })
            ),
            catchError((error) => {
                context.patchState({
                    operatorRegistrationCapabilitiesError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isOperatorRegistrationCapabilitiesProcessing: false }))
        );
    }

    @Action(OperatorRegistrationActions.VerifyForeignOperator)
    public verifyForeignOperator(
        context: StateContext<OperatorRegistrationStateModel>,
        action: OperatorRegistrationActions.VerifyForeignOperator
    ) {
        context.patchState({
            foreignOperatorValidation: undefined,
        });

        return this.operatorRegistrationApiService.verifyForeignOperator(action.foreignOperator).pipe(
            tap(() =>
                context.patchState({
                    foreignOperatorValidation: { ...action.foreignOperator, isValid: true },
                })
            ),
            catchError((error) => {
                context.patchState({
                    foreignOperatorValidation: { ...action.foreignOperator, isValid: false, error },
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorRegistrationActions.RegisterOperator)
    public registerOperator(context: StateContext<OperatorRegistrationStateModel>, action: OperatorRegistrationActions.RegisterOperator) {
        context.patchState({
            isProcessing: true,
            operatorRegistrationError: undefined,
        });

        return this.operatorRegistrationApiService.registerOperator(action.operatorRegistrationFormValue).pipe(
            tap((response) => {
                this.store.dispatch(new OperatorContextActions.GetGlobalCapabilities());

                context.patchState({ lastRegisteredOperator: response });
            }),
            catchError((error) => {
                context.patchState({
                    operatorRegistrationError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorRegistrationActions.DownloadAttorneyTemplate)
    public downloadAttorneyTemplate(
        context: StateContext<OperatorRegistrationStateModel>,
        action: OperatorRegistrationActions.DownloadAttorneyTemplate
    ) {
        context.patchState({ downloadAttorneyTemplateError: undefined });

        return this.operatorRegistrationApiService.downloadAttorneyTemplate().pipe(
            catchError((error) => {
                context.patchState({
                    downloadAttorneyTemplateError: error,
                });

                return EMPTY;
            })
        );
    }
}
