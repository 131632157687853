import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { FILES_UPLOAD_API_PROVIDER, GlobalFeatures } from "@dtm-frontend/shared/ui";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, race } from "rxjs";
import { combineLatestWith, first, map, switchMap } from "rxjs/operators";
import { OperatorContextActions } from "../../../../shared/operator-context/state/operator-context.actions";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { IDENTITY_DOCUMENT_API_ENDPOINTS } from "../../../../shared/services/identity-document-upload/identity-document-api.token";
import { IdentityDocumentUploadApiService } from "../../../../shared/services/identity-document-upload/identity-document-upload-api.service";
import { ForeignOperatorVerificationDetails } from "../../../shared/models";
import { PILOT_REGISTRATION_ENDPOINTS } from "../../pilot-registration.tokens";
import { RegisterPilotAdditionalData, RegisterPilotBasicData, RegisterPilotSummaryData } from "../../services/models";
import { isRegisteredPilot } from "../../services/typeguards";
import { PilotRegistrationActions } from "../../state/pilot-registration.actions";
import { PilotRegistrationState } from "../../state/pilot-registration.state";
import { identityDocumentApiFactory } from "../../utils/identity-document-api-factory";

export const REGISTER_PILOT_WIZARD_ID = "register-pilot-wizard";

export enum RegisterPilotWizardSteps {
    BasicData = "BasicData",
    AdditionalData = "AdditionalData",
    Summary = "Summary",
}

interface RegisterPilotWizardContentComponentState {
    basicData: RegisterPilotBasicData | undefined;
    additionalData: RegisterPilotAdditionalData | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-register-pilot-wizard-content",
    templateUrl: "./register-pilot-wizard-content.component.html",
    styles: [".loader { margin: auto; height: 100%; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: IdentityDocumentUploadApiService },
        {
            provide: IDENTITY_DOCUMENT_API_ENDPOINTS,
            useFactory: identityDocumentApiFactory,
            deps: [PILOT_REGISTRATION_ENDPOINTS],
        },
    ],
})
export class RegisterPilotWizardContentComponent implements OnDestroy {
    protected readonly RegisterPilotWizardSteps = RegisterPilotWizardSteps;
    protected readonly wizardId = REGISTER_PILOT_WIZARD_ID;

    protected readonly capabilities$ = this.store.select(PilotRegistrationState.pilotRegistrationCapabilities);
    protected readonly basicData$ = this.localStore.selectByKey("basicData");
    protected readonly additionalData$ = this.localStore.selectByKey("additionalData");
    protected readonly isPilotRegistrationCapabilitiesProcessing$ = this.store.select(
        PilotRegistrationState.isPilotRegistrationCapabilitiesProcessing
    );
    protected readonly foreignOperatorValidation$ = this.store.select(PilotRegistrationState.foreignOperatorValidation);
    protected readonly isMissionPlanningFeatureAvailable$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.MissionPlanner)
    );
    protected readonly isProcessing$ = this.store.select(PilotRegistrationState.isProcessing).pipe(
        combineLatestWith(this.store.select(OperatorContextState.isProcessing)),
        map(([isOperatorProcessing, isCapabilitiesProcessing]) => isOperatorProcessing || isCapabilitiesProcessing)
    );
    protected readonly isPilotRegistrationCapabilitiesError$ = this.store.select(PilotRegistrationState.pilotRegistrationCapabilitiesError);
    protected readonly pilotRegistrationFormValue$ = this.localStore.selectByKey("basicData").pipe(
        RxjsUtils.filterFalsy(),
        combineLatestWith(this.localStore.selectByKey("additionalData").pipe(RxjsUtils.filterFalsy())),
        map(([basicData, additionalData]) => ({ ...basicData, ...additionalData }))
    );

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<RegisterPilotWizardContentComponentState>,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {
        this.goToBasicDataStep();

        localStore.setState({ basicData: undefined, additionalData: undefined });
    }

    public ngOnDestroy() {
        this.store.dispatch(new WizardActions.CleanupWizard(this.wizardId));
    }

    protected refreshCapabilities() {
        this.store.dispatch(new PilotRegistrationActions.GetPilotRegistrationCapabilities(this.store.selectSnapshot(AuthState.userId)));
    }

    protected checkForeignOperator(foreignOperator: ForeignOperatorVerificationDetails) {
        this.store.dispatch(new PilotRegistrationActions.VerifyForeignOperator(foreignOperator));
    }

    protected saveBasicDataForm(basicData: RegisterPilotBasicData) {
        this.localStore.patchState({ basicData });

        this.goToAdditionalDataStep();
    }

    protected goToAdditionalDataStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, RegisterPilotWizardSteps.AdditionalData),
            new WizardActions.EnableSteps(this.wizardId, [RegisterPilotWizardSteps.BasicData, RegisterPilotWizardSteps.AdditionalData]),
        ]);
    }

    protected saveAdditionalDataForm(additionalData: RegisterPilotAdditionalData) {
        this.localStore.patchState({ additionalData });

        this.goToSummaryStep();
    }

    protected goToSummaryStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, RegisterPilotWizardSteps.Summary),
            new WizardActions.EnableSteps(this.wizardId, [
                RegisterPilotWizardSteps.BasicData,
                RegisterPilotWizardSteps.AdditionalData,
                RegisterPilotWizardSteps.Summary,
            ]),
        ]);
    }

    protected goToBasicDataStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, RegisterPilotWizardSteps.BasicData),
            new WizardActions.EnableSteps(this.wizardId, [RegisterPilotWizardSteps.BasicData]),
        ]);
    }

    protected registerPilot(value: RegisterPilotSummaryData) {
        const basicData = this.localStore.selectSnapshotByKey("basicData");
        const additionalData = this.localStore.selectSnapshotByKey("additionalData");
        if (!basicData || !additionalData) {
            return;
        }
        this.store
            .dispatch(
                new PilotRegistrationActions.RegisterPilot({
                    ...basicData,
                    ...additionalData,
                })
            )
            .pipe(
                switchMap(() =>
                    race([
                        this.store.select(PilotRegistrationState.pilotRegistrationError).pipe(RxjsUtils.filterFalsy()),
                        this.store.select(PilotRegistrationState.lastRegisteredPilot).pipe(RxjsUtils.filterFalsy()),
                    ])
                ),
                first(),
                untilDestroyed(this)
            )
            .subscribe(async (result) => {
                if (isRegisteredPilot(result)) {
                    this.toastrService.success(
                        this.translocoService.translate("dtmWebAppLibPilotOperatorRegistration.pilotRegistrationSuccessMessage", {
                            pilotName: `${result.firstName} ${result.lastName}`,
                        })
                    );

                    await lastValueFrom(this.store.dispatch(OperatorContextActions.GetGlobalCapabilities));

                    const userContext = this.store.selectSnapshot(OperatorContextState.personalOperator);
                    if (userContext) {
                        this.store.dispatch(new OperatorContextActions.TrySelectContext(userContext));
                    }

                    this.router.navigate(["/pilot-profile"]);

                    return;
                }
                this.toastrService.error(
                    this.translocoService.translate("dtmWebAppLibPilotOperatorRegistration.pilotRegistrationErrorMessage")
                );
            });
    }
}
