<ng-container *ngIf="constraints$ | ngrxPush as constraints">
    <dtm-ui-expandable-panel [isExpanded]="true" [hasHeaderSeparator]="false" [isDisabled]="true">
        <div class="header" headerSlot>
            <dtm-ui-icon name="flight-takeoff" class="zone-icon"></dtm-ui-icon>
            <h6 class="label">{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.takeoffRunwayLabel" | transloco }}</h6>
            <button
                type="button"
                class="button-icon zoom-action"
                (click)="toggleZoom(AssistedEntityId.Takeoff)"
                *ngIf="isTakeOffAdded$ | ngrxPush"
            >
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
        </div>
        <ng-container [ngTemplateOutlet]="placeSettingsTemplate" [ngTemplateOutletContext]="{ $implicit: takeoffFormGroup }"></ng-container>
    </dtm-ui-expandable-panel>
    <dtm-ui-expandable-panel [isExpanded]="true" [hasHeaderSeparator]="false" [isDisabled]="true">
        <div class="header" headerSlot>
            <dtm-ui-icon name="flight-land" class="zone-icon"></dtm-ui-icon>
            <h6 class="label">{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.landingRunwayLabel" | transloco }}</h6>
            <button
                type="button"
                class="button-icon zoom-action"
                (click)="toggleZoom(AssistedEntityId.Landing)"
                *ngIf="isLandingAdded$ | ngrxPush"
            >
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
        </div>
        <ng-container [ngTemplateOutlet]="placeSettingsTemplate" [ngTemplateOutletContext]="{ $implicit: landingFormGroup }"></ng-container>
    </dtm-ui-expandable-panel>
    <ng-template #placeSettingsTemplate let-formGroup>
        <div [formGroup]="formGroup">
            <div class="coordinates-container">
                <dtm-ui-dms-coordinates-input
                    formControlName="latitude"
                    [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
                    [fixedDirection]="GeographicCoordinatesDirection.North"
                    [inputLabel]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.coordinatesLabel' | transloco"
                ></dtm-ui-dms-coordinates-input>
                <dtm-ui-dms-coordinates-input
                    formControlName="longitude"
                    [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
                    [fixedDirection]="GeographicCoordinatesDirection.East"
                ></dtm-ui-dms-coordinates-input>
                <div class="save-location-action">
                    <button
                        type="button"
                        class="button-icon"
                        [matTooltip]="
                            ('dtmWebAppLibMission.itineraryEditorStep.assistedEditor.saveLocationButtonTooltip' | transloco) +
                            ': NOT IMPLEMENTED YET'
                        "
                    >
                        <!-- TODO: DTM-2538: no backend to handle this -->
                        <dtm-ui-icon name="bookmark"></dtm-ui-icon>
                    </button>
                </div>
            </div>
            <p class="text-separator">{{ "dtmWebAppLibMission.itineraryEditorStep.assistedEditor.orLabel" | transloco }}</p>
            <dtm-ui-select-field
                [placeholder]="'dtmWebAppLibMission.itineraryEditorStep.assistedEditor.locationSelectPlaceholder' | transloco"
            >
                <label>{{ "dtmWebAppLibMission.itineraryEditorStep.assistedEditor.savedLocationLabel" | transloco }}</label>
                <!-- TODO: DTM-2538: no backend to handle this -->
                <dtm-ui-select-option>NOT IMPLEMENTED YET</dtm-ui-select-option>
            </dtm-ui-select-field>
            <dtm-ui-input-field class="radius" [isClearable]="false">
                <label> {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.cylinderRadiusLabel" | transloco }}</label>
                <dtm-ui-icon
                    name="complex-radius"
                    class="field-prefix"
                    [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.radiusLabel' | transloco"
                ></dtm-ui-icon>
                <input
                    matInput
                    dtmUiClampInput
                    type="number"
                    inputmode="numeric"
                    step="1"
                    required
                    [min]="0"
                    [max]="constraints.max.size"
                    formControlName="radius"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.radiusUnitLabel" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="formGroup.controls.radius; name: 'min'; error as error">
                    {{
                        "dtmWebAppLibMission.itineraryEditorStep.assistedEditor.minimumRequiredRadiusErrorMessage"
                            | transloco : { min: error.min | localizeNumber }
                    }}
                </div>
            </dtm-ui-input-field>
        </div>
    </ng-template>
</ng-container>
