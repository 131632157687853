import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DEFAULT_LANG, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { StaffTrainings, Training } from "../../../services/specific-permit-application.models";

interface PilotTrainingsComponentState {
    availableTrainings: Training[];
    isExpanded: boolean;
    isEditMode: boolean;
    isEditable: boolean;
    headerLabel: string | undefined;
    subheaderLabel: string | undefined;
    activeLanguage: LanguageCode;
    currentTrainings: StaffTrainings;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-pilot-trainings[availableTrainings]",
    templateUrl: "./pilot-trainings.component.html",
    styleUrls: ["./pilot-trainings.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PilotTrainingsComponent), multi: true }],
})
export class PilotTrainingsComponent implements ControlValueAccessor {
    @Input() public set availableTrainings(value: Training[] | undefined) {
        this.localStore.patchState({ availableTrainings: value ?? [] });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set isEditable(value: BooleanInput) {
        this.localStore.patchState({ isEditable: coerceBooleanProperty(value) });
    }

    @Input() public set headerLabel(value: string | undefined) {
        this.localStore.patchState({ headerLabel: value });
    }

    @Input() public set subheaderLabel(value: string | undefined) {
        this.localStore.patchState({ subheaderLabel: value });
    }

    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value ?? DEFAULT_LANG });
    }

    @Input() public set value(value: StaffTrainings | undefined) {
        this.localStore.patchState({ currentTrainings: value ?? { basic: [], additional: [] } });
    }

    protected readonly availableTrainings$ = this.localStore.selectByKey("availableTrainings");
    protected readonly currentTrainings$ = this.localStore.selectByKey("currentTrainings");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly isEditable$ = this.localStore.selectByKey("isEditable");
    protected readonly headerLabel$ = this.localStore.selectByKey("headerLabel");
    protected readonly subheaderLabel$ = this.localStore.selectByKey("subheaderLabel");
    protected readonly activeLanguage$ = this.localStore.selectByKey("activeLanguage");

    protected readonly pilotTrainingsForm = new FormGroup<{ trainings: FormControl<StaffTrainings | null> }>({
        trainings: new FormControl<StaffTrainings | null>(null, {
            nonNullable: true,
        }),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: StaffTrainings | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<PilotTrainingsComponentState>) {
        this.localStore.setState({
            availableTrainings: [],
            isExpanded: true,
            isEditMode: false,
            isEditable: true,
            headerLabel: undefined,
            subheaderLabel: undefined,
            activeLanguage: DEFAULT_LANG,
            currentTrainings: {
                basic: [],
                additional: [],
            },
        });
    }

    public registerOnChange(fn: (value: StaffTrainings | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: StaffTrainings | null): void {
        if (value) {
            this.pilotTrainingsForm.setValue({ trainings: value }, { emitEvent: false });
        } else {
            this.pilotTrainingsForm.reset();
        }

        this.persistCurrentTrainings(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.pilotTrainingsForm.disable();
        } else {
            this.pilotTrainingsForm.enable();
        }
    }

    protected setEditMode(isEditMode: boolean): void {
        this.localStore.patchState(({ isExpanded }) => ({ isEditMode, isExpanded: isEditMode ? true : isExpanded }));
    }

    protected cancel(): void {
        const currentTrainings = this.localStore.selectSnapshotByKey("currentTrainings");
        this.pilotTrainingsForm.reset({ trainings: currentTrainings }, { emitEvent: false });
        this.setEditMode(false);
    }

    protected save(): void {
        this.pilotTrainingsForm.markAllAsTouched();

        if (this.pilotTrainingsForm.invalid) {
            return;
        }

        const currentTrainings = this.pilotTrainingsForm.controls.trainings.getRawValue();
        this.persistCurrentTrainings(currentTrainings);
        this.propagateChange(currentTrainings);
        this.setEditMode(false);
    }

    private persistCurrentTrainings(value: StaffTrainings | null): void {
        this.localStore.patchState({
            currentTrainings: {
                basic: value?.basic ?? [],
                additional: value?.additional ?? [],
            },
        });
    }
}
