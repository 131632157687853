import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { requiredAtLeastOnePermissionValidator } from "../../../services/member-permissions-validator";
import {
    FleetManagementDescriptionItem,
    Member,
    MemberType,
    MembershipPermission,
    MissionPlanningDescriptionItem,
    OperatorManageDescriptionItem,
    UavPilotingDescriptionItem,
} from "../../../services/membership.models";
import { MembershipActions } from "../../../state/membership.actions";

interface PermissionsFormGroup {
    [key: string]: FormControl<boolean | null>;
}

interface ManagePermissionsDialogData {
    user: Member;
    operatorId: string;
    isProcessing$: Observable<boolean>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-manage-permissions-dialog",
    templateUrl: "./manage-permissions-dialog.component.html",
    styleUrls: ["./manage-permissions-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagePermissionsDialogComponent {
    @Output() public changePermissionsSubmit = new EventEmitter<
        MembershipActions.ChangeMemberUserPermissions | MembershipActions.ChangeInvitedUserPermissions
    >();

    protected readonly FleetManagementDescriptionItem = FleetManagementDescriptionItem;
    protected readonly MissionPlanningDescriptionItem = MissionPlanningDescriptionItem;
    protected readonly UavPilotingDescriptionItem = UavPilotingDescriptionItem;
    protected readonly OperatorManageDescriptionItem = OperatorManageDescriptionItem;
    protected readonly MembershipPermission = MembershipPermission;

    protected readonly permissionsForm = new FormGroup<PermissionsFormGroup>({}, { validators: requiredAtLeastOnePermissionValidator() });

    constructor(
        @Inject(MAT_DIALOG_DATA)
        protected readonly data: ManagePermissionsDialogData,
        private readonly dialogRef: MatDialogRef<ManagePermissionsDialogComponent>
    ) {
        this.assignFormValues();
    }

    protected cancel() {
        this.dialogRef.close();
    }

    protected keepOrder(): number {
        return 0;
    }

    protected saveChanges() {
        this.permissionsForm.markAllAsTouched();
        if (this.permissionsForm.invalid) {
            return;
        }
        this.changeUserPermissions();
    }

    private changeUserPermissions() {
        const permissions = Object.entries(this.permissionsForm.getRawValue())
            .filter(([, value]) => value)
            .map(([key]) => key as MembershipPermission);

        const changeUserPermissions =
            this.data.user.type === MemberType.MEMBER
                ? new MembershipActions.ChangeMemberUserPermissions(this.data.operatorId, this.data.user.membershipId, permissions)
                : new MembershipActions.ChangeInvitedUserPermissions(this.data.operatorId, this.data.user.invitationId, permissions);

        this.changePermissionsSubmit.emit(changeUserPermissions);
    }

    private assignFormValues() {
        const canUserManageOperator = this.data.user.permissions.includes(MembershipPermission.OperatorManage);
        Object.values(MembershipPermission).forEach((permission) => {
            this.permissionsForm.addControl(
                permission,
                new FormControl<boolean>({
                    value: this.data.user.permissions.includes(permission) || canUserManageOperator,
                    disabled: permission !== MembershipPermission.OperatorManage && canUserManageOperator,
                })
            );
        });

        this.permissionsForm.controls[MembershipPermission.OperatorManage].valueChanges
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value) => {
                const permissionsFormValues = Object.keys(this.permissionsForm.getRawValue());
                if (value) {
                    permissionsFormValues.forEach((permission) => {
                        if (permission !== MembershipPermission.OperatorManage) {
                            const control = this.permissionsForm.controls[permission];
                            control.setValue(true, { emitEvent: false });
                            control.disable();
                        }
                    });
                } else {
                    permissionsFormValues.forEach((permission) => {
                        const control = this.permissionsForm.controls[permission];
                        control.enable();
                    });
                }
            });
    }
}
