import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EmptyStateMode, ErrorMode } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs";
import { MembershipInvitationError, MembershipInvitationErrorType } from "../../../services/membership.models";
import { MembershipActions } from "../../../state/membership.actions";
import { MembershipState } from "../../../state/membership.state";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-invitation-acceptance",
    templateUrl: "./invitation-acceptance.component.html",
    styleUrls: ["./invitation-acceptance.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationAcceptanceComponent {
    public readonly invitation$ = this.store.select(MembershipState.invitation).pipe(RxjsUtils.filterFalsy());
    public readonly getInvitationDetailsError$ = this.store.select(MembershipState.getInvitationDetailsError);
    public readonly MembershipInvitationErrorType = MembershipInvitationErrorType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly ErrorMode = ErrorMode;

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {}

    public async acceptInvitation() {
        const invitation = this.store.selectSnapshot(MembershipState.invitation);

        if (!invitation) {
            return;
        }

        this.store
            .dispatch(new MembershipActions.AcceptInvitation(invitation.id))
            .pipe(map(() => this.store.selectSnapshot(MembershipState.invitationAcceptanceError), untilDestroyed(this)))
            .subscribe((error) => {
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmWebAppMembership.invitationAcceptance.acceptanceErrorMessage")
                    );

                    this.setInvitationNotFound(error);

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dtmWebAppMembership.invitationAcceptance.acceptanceSuccessMessage")
                );

                this.redirectToOperatorsList();
            });
    }

    public async rejectInvitation() {
        const invitation = this.store.selectSnapshot(MembershipState.invitation);

        if (!invitation) {
            return;
        }

        this.store
            .dispatch(new MembershipActions.RejectInvitation(invitation.id))
            .pipe(map(() => this.store.selectSnapshot(MembershipState.invitationRejectionError), untilDestroyed(this)))
            .subscribe((error) => {
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("dtmWebAppMembership.invitationAcceptance.rejectErrorMessage")
                    );

                    this.setInvitationNotFound(error);

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("dtmWebAppMembership.invitationAcceptance.rejectSuccessMessage")
                );

                this.router.navigate(["/"]);
            });
    }

    public redirectToOperatorsList() {
        this.router.navigate(["/membership/operators-list"]);
    }

    private setInvitationNotFound(error: MembershipInvitationError) {
        if (error.type !== MembershipInvitationErrorType.NotFound) {
            return;
        }

        this.store.dispatch(new MembershipActions.SetMembershipInvitationError({ type: MembershipInvitationErrorType.NotFound }));
    }
}
