import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface PilotOperatorRegistrationInsuranceSummaryComponentState {
    insurancePolicyExpirationDate: Date | undefined;
    insurancePolicyNumber: string | undefined;
}

@Component({
    selector: "dtm-web-app-lib-pilot-operator-registration-insurance-summary[insurancePolicyNumber]",
    templateUrl: "./pilot-operator-registration-insurance-summary.component.html",
    styleUrls: ["../../../styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotOperatorRegistrationInsuranceSummaryComponent {
    @Input()
    public set insurancePolicyNumber(value: string | undefined) {
        this.localStore.patchState({ insurancePolicyNumber: value });
    }

    @Input()
    public set insurancePolicyExpirationDate(value: Date | undefined) {
        this.localStore.patchState({ insurancePolicyExpirationDate: value });
    }

    protected readonly insurancePolicyExpirationDate$ = this.localStore.selectByKey("insurancePolicyExpirationDate");
    protected readonly insurancePolicyNumber$ = this.localStore.selectByKey("insurancePolicyNumber");

    constructor(private readonly localStore: LocalComponentStore<PilotOperatorRegistrationInsuranceSummaryComponentState>) {
        localStore.setState({ insurancePolicyExpirationDate: undefined, insurancePolicyNumber: undefined });
    }
}
