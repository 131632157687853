import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MemberProfile } from "../../../services/membership.models";

interface PilotMemberDetailsComponentState {
    userProfile: MemberProfile | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-web-app-lib-user-member-details[userProfile]",
    templateUrl: "./user-member-details.component.html",
    styleUrls: ["./user-member-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserMemberDetailsComponent {
    @Input()
    public set userProfile(value: MemberProfile | undefined) {
        this.localStore.patchState({ userProfile: value });
    }

    @Input()
    public set isExpanded(value: boolean | undefined) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly userProfile$ = this.localStore.selectByKey("userProfile").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<PilotMemberDetailsComponentState>) {
        localStore.setState({ userProfile: undefined, isExpanded: false });
    }
}
