<ng-container
    *ngrxLet="{
        isEditModeOn: isEditModeOn$,
        additionalCrewMembers: additionalCrewMembers$,
        isLimitedEditMode: isLimitedEditMode$,
        savedWatchers: savedWatchers$
    } as vm"
>
    <button
        *ngIf="!vm.isEditModeOn && !vm.additionalCrewMembers?.length"
        type="button"
        class="button-secondary add-crew"
        (click)="setEditMode(true)"
    >
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "dtmWebAppLibMission.dataFormStep.crew.addCrewLabel" | transloco }}
    </button>

    <div *ngIf="vm.isEditModeOn" class="card">
        <div class="header">
            <div class="title">{{ "dtmWebAppLibMission.dataFormStep.crew.addingCrewLabel" | transloco }}</div>
            <button *ngIf="!vm.isEditModeOn" type="button" class="button-secondary edit" (click)="setEditMode(true)">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.dataFormStep.crew.editLabel" | transloco }}
            </button>
        </div>

        <dtm-ui-info-message type="info" *ngIf="vm.isLimitedEditMode">
            <span class="info-message-text">
                {{ "dtmWebAppLibMission.dataFormStep.crew.limitedEditModeInfoBoxMessage" | transloco }}
            </span>
        </dtm-ui-info-message>

        <div [formGroup]="formGroup">
            <dtm-ui-select-field
                formControlName="additionalCrewMembers"
                multiple
                [placeholder]="'dtmWebAppLibMission.dataFormStep.crew.additionalCrewPlaceholder' | transloco"
            >
                <dtm-ui-select-option
                    *ngFor="let member of additionalCrewMembersOptions$ | ngrxPush"
                    [value]="member"
                    [disabled]="vm.isLimitedEditMode && member === WATCHER_ID && vm.savedWatchers.length > 0"
                >
                    {{ "mission.additional_crew_members." + member + ".name" | systemTranslation }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <ng-container formArrayName="watchers">
                <div
                    *ngFor="let watcher of watchersFormArray.controls; let index = index; let count = count"
                    [formGroupName]="index"
                    class="watcher"
                >
                    <div class="watcher-header">
                        <div>{{ "dtmWebAppLibMission.dataFormStep.crew.watcherBSPLabel" | transloco }}</div>
                        <button *ngIf="count > 1" type="button" class="button-icon close-button" (click)="removeWatcher(index)">
                            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                        </button>
                    </div>
                    <dtm-ui-input-field>
                        <label>{{ "dtmWebAppLibMission.dataFormStep.crew.nameAndSurnameLabel" | transloco }}</label>
                        <input matInput type="text" [formControl]="watcher.controls.name" />
                        <div class="field-error" *dtmUiFieldHasError="watcher.controls.name; name: 'maxlength'; error as error">
                            {{ "dtmWebAppLibMission.dataFormStep.crew.nameTooLongError" | transloco : { max: error.requiredLength } }}
                        </div>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="watcher.controls.name; name: ['required', 'pattern']"
                            [ngTemplateOutlet]="requiredErrorTemplate"
                        ></div>
                    </dtm-ui-input-field>

                    <dtm-ui-phone-number-field [formControl]="watcher.controls.phoneNumber">
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="watcher.controls.phoneNumber; name: ['required', 'pattern']"
                            [ngTemplateOutlet]="requiredErrorTemplate"
                        ></div>
                        <div class="field-error" *dtmUiFieldHasError="watcher.controls.phoneNumber; name: 'invalidNumber'">
                            {{ "dtmWebAppLibMission.dataFormStep.crew.phoneInvalidValueError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="watcher.controls.phoneNumber; name: 'invalidNumberType'">
                            {{ "dtmWebAppLibMission.dataFormStep.crew.phoneInvalidTypeError" | transloco }}
                        </div>
                    </dtm-ui-phone-number-field>
                </div>
            </ng-container>

            <button
                *ngIf="watchersFormArray.controls.length > 0 && watchersFormArray.controls.length < MAX_WATCHERS_COUNT"
                type="button"
                class="button-secondary add-crew"
                (click)="addWatcher()"
            >
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.dataFormStep.crew.addWatcherText" | transloco }}
            </button>
        </div>

        <div class="actions">
            <button type="button" class="button-secondary" (click)="cancelForm()">
                {{ "dtmWebAppLibMission.dataFormStep.crew.cancelLabel" | transloco }}
            </button>
            <button
                type="button"
                class="button-primary"
                (click)="saveForm()"
                [disabled]="
                    (!vm.additionalCrewMembers.length && additionalCrewMembersField.value && !additionalCrewMembersField.value.length) ||
                    formGroup.invalid
                "
            >
                {{ "dtmWebAppLibMission.dataFormStep.crew.saveLabel" | transloco }}
            </button>
        </div>
    </div>
    <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="true" *ngIf="!vm.isEditModeOn && vm.additionalCrewMembers?.length">
        <div class="section-header" headerSlot>
            <h4 class="section-title">
                {{ "dtmWebAppLibMission.dataFormStep.crew.additionalCrewLabel" | transloco }}
            </h4>
            <button type="button" class="button-secondary" (click)="setEditMode(true); $event.stopPropagation()">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.dataFormStep.crew.editLabel" | transloco }}
            </button>
        </div>

        <dtm-mission-additional-crew-members-preview
            [additionalCrewMembers]="vm.additionalCrewMembers"
        ></dtm-mission-additional-crew-members-preview>
    </dtm-ui-expandable-panel>
</ng-container>

<ng-template #requiredErrorTemplate>
    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
</ng-template>
