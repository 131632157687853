import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { OPERATOR_PERMISSIONS_ENDPOINTS, OperatorPermissionsEndpoints } from "../operator-permissions.tokens";
import {
    OperatorPermissionsCapabilitiesResponseBody,
    OperatorPermissionsListResponseBody,
    OperatorStatementsListResponseBody,
    PayForStatementResponseBody,
    PermissionUavDetailsResponseBody,
    RepeatPaymentResponseBody,
    SavedStatementResponseBody,
    convertOperatorPermissionsListResponseBodyToOwnedOperatorPermissions,
    convertOperatorPermissionsStatementToSignToOperatorPermissionsStatementToSignRequest,
    convertOperatorStatementsListResponseBodyToOperatorStatementListWithPages,
    convertPayForStatementErrorResponseToOperatorPermissionsError,
    convertPermissionUavDetailsResponseBodyToPermissionUavDetails,
    convertPossiblePermissionsResponseBodyToOperatorPermissionsCapabilities,
    convertSavedStatementResponseBodyToSavedStatement,
} from "./operator-permissions-api.converters";
import {
    OperatorPermissionsCapabilities,
    OperatorPermissionsErrorType,
    OperatorPermissionsStatementToSign,
    OperatorStatementListWithPages,
    OwnedOperatorPermission,
    PermissionUavDetails,
    SavedStatement,
    StatementsListQueryParams,
} from "./operator-permissions.models";

@Injectable({
    providedIn: "root",
})
export class OperatorPermissionsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATOR_PERMISSIONS_ENDPOINTS) private readonly endpoints: OperatorPermissionsEndpoints
    ) {}

    public getPermissionsList(operatorId: string): Observable<OwnedOperatorPermission[]> {
        return this.httpClient
            .get<OperatorPermissionsListResponseBody[]>(
                StringUtils.replaceInTemplate(this.endpoints.ownedPermissionsList, {
                    operatorId,
                })
            )
            .pipe(
                map((response: OperatorPermissionsListResponseBody[]) =>
                    convertOperatorPermissionsListResponseBodyToOwnedOperatorPermissions(response)
                ),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.CannotGetOwnedPermissions })))
            );
    }

    public getStatementsList(params: StatementsListQueryParams): Observable<OperatorStatementListWithPages> {
        let httpParams = new HttpParams().set("page", params.page).set("size", params.size);

        if (params?.signStatus?.length) {
            httpParams = httpParams.set("signatureStatuses", params.signStatus.join(","));
        }
        if (params?.paymentStatus?.length) {
            httpParams = httpParams.set("paymentStatuses", params.paymentStatus.join(","));
        }

        return this.httpClient
            .get<OperatorStatementsListResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.statementsList, { operatorId: params.operatorId }),
                {
                    params: httpParams,
                }
            )
            .pipe(
                map((response: OperatorStatementsListResponseBody) =>
                    convertOperatorStatementsListResponseBodyToOperatorStatementListWithPages(response)
                ),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.CannotGetStatementsList })))
            );
    }

    public getOperatorPermissionsCapabilities(operatorId: string): Observable<OperatorPermissionsCapabilities> {
        return this.httpClient
            .get<OperatorPermissionsCapabilitiesResponseBody>(StringUtils.replaceInTemplate(this.endpoints.capabilities, { operatorId }))
            .pipe(
                map((response: OperatorPermissionsCapabilitiesResponseBody) =>
                    convertPossiblePermissionsResponseBodyToOperatorPermissionsCapabilities(response)
                ),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.CannotGetAllPossiblePermissions })))
            );
    }

    public getSavedStatement(operatorId: string, statementId: string): Observable<SavedStatement> {
        return this.httpClient
            .get<SavedStatementResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getSavedStatement, { statementId, operatorId }))
            .pipe(
                map((response) => convertSavedStatementResponseBodyToSavedStatement(response)),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.CannotGetSavedStatement })))
            );
    }

    public saveStatement(operatorId: string, statement: OperatorPermissionsStatementToSign): Observable<SavedStatement> {
        const body = convertOperatorPermissionsStatementToSignToOperatorPermissionsStatementToSignRequest(statement);

        return this.httpClient
            .post<SavedStatementResponseBody>(StringUtils.replaceInTemplate(this.endpoints.saveStatement, { operatorId }), {
                ...body,
            })
            .pipe(
                map((response) => convertSavedStatementResponseBodyToSavedStatement(response)),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.CannotSaveStatement })))
            );
    }

    public payForStatement(operatorId: string, statementId: string): Observable<string> {
        return this.httpClient
            .post<PayForStatementResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.payForStatement, { operatorId, statementId }),
                null
            )
            .pipe(
                map((response) => `${response.redirectUrl}?hashtrans=${response.hashtrans}`),
                catchError((error: HttpErrorResponse) =>
                    throwError(() => convertPayForStatementErrorResponseToOperatorPermissionsError(error))
                )
            );
    }

    public createPaymentRedirectUrl(operatorId: string, statementId: string): Observable<string> {
        return this.httpClient
            .post<RepeatPaymentResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.createPaymentRedirectUrl, { operatorId, statementId }),
                null
            )
            .pipe(
                map((response) => response.redirectUrl),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.Unknown })))
            );
    }

    public createSignatureRedirectUrl(operatorId: string, statementId: string): Observable<string> {
        return this.httpClient
            .post<{ redirectUrl: string }>(
                StringUtils.replaceInTemplate(this.endpoints.createSignatureRedirectUrl, { operatorId, statementId }),
                null
            )
            .pipe(
                map((response) => response.redirectUrl),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.Unknown })))
            );
    }

    public getUavDetailsForPermission(operatorId: string, permissionId: string): Observable<PermissionUavDetails> {
        return this.httpClient
            .get<PermissionUavDetailsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getUavDetailsForPermission, { operatorId, permissionId })
            )
            .pipe(
                map((response) => convertPermissionUavDetailsResponseBodyToPermissionUavDetails(response)),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.Unknown })))
            );
    }

    public getPermissionSignatureStatus(operatorId: string, statementId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.getPermissionSignatureStatus, { operatorId, statementId }), {})
            .pipe(catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.Unknown }))));
    }

    public getStatementInPdf(operatorId: string, statement: OperatorPermissionsStatementToSign, fileName: string): Observable<Blob> {
        return this.httpClient
            .post(StringUtils.replaceInTemplate(this.endpoints.getStatementInPdf, { operatorId }), statement, {
                responseType: "blob",
            })
            .pipe(
                tap((blob: Blob) => saveAs(blob, fileName)),
                catchError(() => throwError(() => ({ type: OperatorPermissionsErrorType.Unknown })))
            );
    }
}
