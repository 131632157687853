<ng-container *ngrxLet="isShown$ as isShown">
    <ac-layer #heightPointsLayer acFor="let point of heightPoints$" [context]="this" [show]="isShown" [zIndex]="1">
        <ac-point-desc
            props="{
                position: point.position,
                color: POINT_PROPS.color,
                outlineWidth: POINT_PROPS.outlineWidth,
                pixelSize: POINT_PROPS.pixelSize,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                heightReference: Cesium.HeightReference.NONE,
                zIndex: 1
            }"
        >
        </ac-point-desc>
    </ac-layer>
    <ac-layer
        #labelsLayer
        acFor="let label of labels$"
        [context]="this"
        [show]="isShown"
        [zIndex]="2"
        *ngrxLet="labelsTemplate$ as labelTemplate"
    >
        <ac-html-desc
            props="{
                position: label.position,
                show: label.show
            }"
        >
            <ng-template let-label>
                <ng-container *ngIf="isShown">
                    <ng-container *ngTemplateOutlet="labelTemplate; context: { $implicit: label }"></ng-container>
                </ng-container>
            </ng-template>
        </ac-html-desc>
    </ac-layer>
</ng-container>
