import { InjectionToken } from "@angular/core";

export interface OperationsManualEndpoints {
    getCapabilities: string;
    createOperationsManual: string;
    getTableOfContents: string;
    publish: string;
    downloadPdf: string;
    getChapter: string;
    saveChapter: string;
    getAttachment: string;
    uploadAttachment: string;
    setChapterAttachments: string;
    addSubchapter: string;
    removeSubchapter: string;
}
export const OPERATIONS_MANUAL_ENDPOINTS = new InjectionToken<OperationsManualEndpoints>("Operations Manual endpoints");
