import { A11yModule } from "@angular/cdk/a11y";
import { CdkTreeModule } from "@angular/cdk/tree";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { RouterModule } from "@angular/router";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { QuillModule } from "ngx-quill";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { AttachmentsListComponent } from "./components/attachments-list/attachments-list.component";
import { ExtendableComponent } from "./components/chapters/extendable/extendable.component";
import { SimpleComponent } from "./components/chapters/simple/simple.component";
import { SubchaptersComponent } from "./components/chapters/subchapters/subchapters.component";
import { HeaderComponent } from "./components/header/header.component";
import { MainComponent } from "./components/main/main.component";
import { ManageAttachmentsDialogComponent } from "./components/manage-attachments-dialog/manage-attachments-dialog.component";
import { TableOfContentsComponent } from "./components/table-of-contents/table-of-contents.component";
import { ChapterAttachmentUploadApiService } from "./services/chapter-attachment-upload-api.service";
import { OperationsManualApiService } from "./services/operations-manual-api.service";
import { OperationsManualState } from "./state/operations-manual.state";

@NgModule({
    declarations: [
        MainComponent,
        HeaderComponent,
        TableOfContentsComponent,
        SimpleComponent,
        SubchaptersComponent,
        ExtendableComponent,
        AttachmentsListComponent,
        ManageAttachmentsDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatCardModule,
        MatDialogModule,
        MatInputModule,
        CdkTreeModule,
        LetModule,
        PushModule,
        NgxsModule.forFeature([OperationsManualState]),
        SharedUiModule,
        SharedI18nModule,
        QuillModule,
        A11yModule,
        SharedWebAppModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibOpsMan",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: OperationsManualApiService,
            useValue: undefined,
        },
    ],
    exports: [
        HeaderComponent,
        TableOfContentsComponent,
        SimpleComponent,
        SubchaptersComponent,
        ExtendableComponent,
        AttachmentsListComponent,
    ],
})
export class OperationsManualModule {
    public static forRoot(): ModuleWithProviders<OperationsManualModule> {
        return {
            ngModule: OperationsManualModule,
            providers: [
                {
                    provide: OperationsManualApiService,
                    useClass: OperationsManualApiService,
                },
                {
                    provide: FILES_UPLOAD_API_PROVIDER,
                    useClass: ChapterAttachmentUploadApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<OperationsManualModule> {
        class FilesUploadApiServiceMock {
            public uploadFile() {}
            public getFile() {}
            public getFilesCompressed() {}
            public manageUploadError() {}
        }

        return {
            ngModule: OperationsManualModule,
            providers: [
                {
                    provide: OperationsManualApiService,
                    useValue: null,
                },
                {
                    provide: FILES_UPLOAD_API_PROVIDER,
                    useClass: FilesUploadApiServiceMock,
                },
            ],
        };
    }
}
