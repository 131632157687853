import { HttpClient, HttpContext } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { catchError, throwError } from "rxjs";
import { FLIGHT_TRACKING_ENDPOINTS, FlightTrackingEndpoints } from "../flight-tracking.tokens";

export enum FlightTrackingErrorType {
    CannotGetHeightInformation = "CannotGetHeightInformation",
}

@Injectable({
    providedIn: "root",
})
export class FlightTrackingApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(FLIGHT_TRACKING_ENDPOINTS) private readonly endpoints: FlightTrackingEndpoints
    ) {}

    public enrichFlightPositionUpdates(identifier: string) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.enrichFlightPositionUpdates, { identifier }), undefined, {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_GLOBAL_HTTP_ERRORS_INTERCEPTOR, true),
            })
            .pipe(catchError(() => throwError(() => ({ type: FlightTrackingErrorType.CannotGetHeightInformation }))));
    }
}
