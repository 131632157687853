<div
    class="wrapper"
    [class.edit-mode]="vm.isEditMode"
    *ngrxLet="{ isOpen: isOpen$, isEditMode: isEditMode$, currentAreaProperties: currentAreaProperties$ } as vm"
>
    <div class="header" (click)="togglePanel()">
        <dtm-ui-icon name="shape-cylinder"></dtm-ui-icon>
        <h2>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.header" | transloco }}</h2>
        <button
            *ngIf="vm.isOpen && !vm.isEditMode && vm.currentAreaProperties"
            type="button"
            class="button-icon"
            (click)="setEditMode(true); $event.stopPropagation()"
        >
            <dtm-ui-icon name="edit"></dtm-ui-icon>
        </button>
        <button *ngIf="!vm.isEditMode" type="button" class="button-icon">
            <dtm-ui-icon [name]="vm.isOpen ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
        </button>
    </div>
    <div *ngIf="vm.isOpen" class="container" @slideIn>
        <div *ngIf="!vm.isEditMode && vm.currentAreaProperties" class="view-panel">
            <dtm-ui-label-value
                [label]="
                    'dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.centerPointCoordinatesLabel'
                        | transloco
                "
            >
                <ng-container *ngIf="!vm.currentAreaProperties.center">-</ng-container>
                <ng-container *ngIf="vm.currentAreaProperties.center">
                    {{ vm.currentAreaProperties.center[1] | localizeNumber : { maximumFractionDigits: 4 } }};
                    {{ vm.currentAreaProperties.center[0] | localizeNumber : { maximumFractionDigits: 4 } }}
                </ng-container>
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.currentAreaProperties.type === MapEntityType.Cylinder"
                [label]="
                    'dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.radiusLabel' | transloco
                "
                [value]="
                    'dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.radiusValue'
                        | transloco : { value: vm.currentAreaProperties.radius | localizeNumber : { maximumFractionDigits: 2 } }
                "
            ></dtm-ui-label-value>
        </div>
        <dtm-ui-empty-state *ngIf="!vm.isEditMode && !vm.currentAreaProperties" [mode]="EmptyStateMode.ListView">
            <img imageSlot src="assets/images/custom-area-empty.svg" alt="" />
            <p messageSlot>
                {{
                    "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.emptyStateMessage"
                        | transloco
                }}
            </p>
        </dtm-ui-empty-state>
        <form *ngIf="vm.isEditMode" [formGroup]="areaPropertiesForm" class="edit-panel">
            <dtm-ui-dms-coordinates-input
                [inputLabel]="
                    'dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.centerPointLatitudeLabel'
                        | transloco
                "
                [formControl]="areaPropertiesForm.controls.latitude"
                [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
            ></dtm-ui-dms-coordinates-input>
            <dtm-ui-dms-coordinates-input
                [inputLabel]="
                    'dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.centerPointLongitudeLabel'
                        | transloco
                "
                [formControl]="areaPropertiesForm.controls.longitude"
                [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
            ></dtm-ui-dms-coordinates-input>
            <dtm-ui-input-field *ngIf="vm.currentAreaProperties?.type === MapEntityType.Cylinder" [isClearable]="false">
                <dtm-ui-icon name="complex-radius" class="field-prefix"></dtm-ui-icon>
                <label>
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.radiusLabel"
                            | transloco
                    }}
                </label>
                <input matInput [formControl]="areaPropertiesForm.controls.radius" type="number" inputmode="numeric" step="1" />
                <div class="field-suffix">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.radiusUnitLabel"
                            | transloco
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="areaPropertiesForm.controls.radius; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="areaPropertiesForm.controls.radius; name: 'min'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.minRadiusErrorMessage"
                            | transloco : { min: error.min | localizeNumber }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="areaPropertiesForm.controls.radius; name: 'max'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.maxRadiusErrorMessage"
                            | transloco : { max: error.max | localizeNumber }
                    }}
                </div>
            </dtm-ui-input-field>

            <div class="footer">
                <button type="button" class="button-secondary" (click)="setEditMode(false)">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.cancelButtonLabel"
                            | transloco
                    }}
                </button>
                <button type="submit" class="button-primary" (click)="save()">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.areaDetailsPanel.saveButtonLabel"
                            | transloco
                    }}
                </button>
            </div>
        </form>
    </div>
</div>
