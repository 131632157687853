import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AuthorityAcceptationItem, MissionPlanRemarks, MissionProcessingPhaseExtended, MissionType } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface PlanBadgeGroupComponentState {
    missionType: MissionType | undefined;
    remarks: MissionPlanRemarks | undefined;
    phase: MissionProcessingPhaseExtended | undefined;
}

@Component({
    selector: "dtm-web-app-lib-plan-badge-group",
    templateUrl: "./plan-badge-group.component.html",
    styleUrls: ["./plan-badge-group.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PlanBadgeGroupComponent {
    @Input()
    public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });
    }
    @Input()
    public set remarks(value: MissionPlanRemarks | undefined) {
        this.localStore.patchState({ remarks: value });
    }
    @Input()
    public set phase(value: MissionProcessingPhaseExtended | undefined) {
        this.localStore.patchState({ phase: value });
    }

    protected readonly remarks$ = this.localStore.selectByKey("remarks");
    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly phase$ = this.localStore.selectByKey("phase");

    protected readonly MissionType = MissionType;

    constructor(private readonly localStore: LocalComponentStore<PlanBadgeGroupComponentState>) {
        localStore.setState({ remarks: undefined, missionType: undefined, phase: undefined });
    }

    protected getAuthorityAcceptationItemWithMessage(remarks?: MissionPlanRemarks): AuthorityAcceptationItem | undefined {
        const authorityAcceptation = remarks?.authorityAcceptation;

        if (!authorityAcceptation) {
            return;
        }

        return Object.values(authorityAcceptation).find((acceptation: AuthorityAcceptationItem | undefined) => acceptation?.comment);
    }
}
