<dtm-ui-wizard-step-wrapper
    [stepNumber]="4"
    [stepsAmount]="4"
    [title]="'dtmWebAppLibSpecPermApp.applicationInfoStep.header' | transloco"
    [isSaveDraftProcessing]="isSaveDraftProcessing$ | ngrxPush"
    [isNextStepProcessing]="isProcessing$ | ngrxPush"
    [nextButtonLabel]="'dtmWebAppLibSpecPermApp.applicationInfoStep.generateApplicationButtonLabel' | transloco"
    (next)="generateApplication()"
    (saveDraft)="saveDataToDraft()"
    (back)="back.emit()"
    dtmUiInvalidFormScrollable
>
    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.contactDataHeader" | transloco }}
    </h2>
    <h3 class="subsection-header">
        {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.responsibleManagerHeader" | transloco }}
    </h3>

    <dtm-ui-input-field>
        <label>{{ "dtmWebAppLibSpecPermApp.applicationInfoStep.fullNameLabel" | transloco }}</label>
        <input matInput type="text" [formControl]="applicationInfoForm.controls.responsibleManager" autocomplete="off" />
        <div class="field-error" *dtmUiFieldHasError="applicationInfoForm.controls.responsibleManager; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibSpecPermApp.applicationInfoStep.fullNameMaxLengthValueError" | transloco : { maxLength: error.requiredLength }
            }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="applicationInfoForm.controls.responsibleManager; name: ['required', 'pattern']">
            {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
        </div>
    </dtm-ui-input-field>

    <h3 class="subsection-header">
        {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.contactPersonHeader" | transloco }}
    </h3>
    <section class="contact-person">
        <dtm-ui-input-field>
            <label>{{ "dtmWebAppLibSpecPermApp.applicationInfoStep.fullNameLabel" | transloco }}</label>
            <input matInput type="text" [formControl]="applicationInfoForm.controls.contactPersonFullName" autocomplete="off" />
            <div
                class="field-error"
                *dtmUiFieldHasError="applicationInfoForm.controls.contactPersonFullName; name: 'maxlength'; error as error"
            >
                {{
                    "dtmWebAppLibSpecPermApp.applicationInfoStep.fullNameMaxLengthValueError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
            <div
                class="field-error"
                *dtmUiFieldHasError="applicationInfoForm.controls.contactPersonFullName; name: ['required', 'pattern']"
            >
                {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-phone-number-field [formControl]="applicationInfoForm.controls.contactPersonPhone">
            <div class="field-error" *dtmUiFieldHasError="applicationInfoForm.controls.contactPersonPhone; name: 'required'">
                {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="applicationInfoForm.controls.contactPersonPhone; name: 'invalidNumber'">
                {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.contactPersonPhoneInvalidValueError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="applicationInfoForm.controls.contactPersonPhone; name: 'invalidNumberType'">
                {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.contactPersonPhoneInvalidTypeError" | transloco }}
            </div>
        </dtm-ui-phone-number-field>

        <dtm-ui-input-field>
            <label>{{ "dtmWebAppLibSpecPermApp.applicationInfoStep.contactPersonEmailLabel" | transloco }}</label>
            <input matInput [formControl]="applicationInfoForm.controls.contactPersonEmail" type="email" inputmode="email" />
            <div class="field-error" *dtmUiFieldHasError="applicationInfoForm.controls.contactPersonEmail; name: 'required'">
                {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="applicationInfoForm.controls.contactPersonEmail; name: 'email'">
                {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.contactPersonEmailInvalidValueError" | transloco }}
            </div>
        </dtm-ui-input-field>
    </section>

    <h2 class="section-header">
        {{ "dtmWebAppLibSpecPermApp.applicationInfoStep.additionalNoteHeader" | transloco }}
    </h2>
    <dtm-ui-textarea-field [maxLength]="MAX_ADDITIONAL_INFO_LENGTH" class="additional-note">
        <label>{{ "dtmWebAppLibSpecPermApp.applicationInfoStep.additionalNoteLabel" | transloco }}</label>
        <textarea matInput [formControl]="applicationInfoForm.controls.additionalNote"></textarea>
    </dtm-ui-textarea-field>
</dtm-ui-wizard-step-wrapper>
