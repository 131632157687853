<dtm-ui-loader-container [isShown]="data.isProcessing$ | ngrxPush">
    <div class="dialog-header">
        <div mat-dialog-title>{{ "dtmWebAppLibUav.uavDocuments.model.manageDialog.manageHeader" | transloco }}</div>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="uavDocumentsForm">
            <dtm-ui-files-groups-upload
                [formControl]="uavDocumentsForm.controls.uavDocuments"
                [addButtonLabel]="'dtmWebAppLibUav.uavDocuments.addDocumentButtonLabel' | transloco"
                [uploadFieldLabel]="'dtmWebAppLibUav.uavDocuments.uploadFieldLabel' | transloco"
            ></dtm-ui-files-groups-upload>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="false">
            {{ "dtmWebAppLibUav.uavDocuments.model.manageDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [disabled]="isSaveButtonDisabled$ | ngrxPush" (click)="save()">
            {{ "dtmWebAppLibUav.uavDocuments.model.manageDialog.saveButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>
