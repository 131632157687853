import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NationalNodeUserVerifiedGuard } from "../../guards/national-node-user-verified.guard";
import { RegisterOperatorWizardContentComponent } from "./operator-registration/components/register-operator-wizard-content/register-operator-wizard-content.component";
import { RegisterOperatorWizardHeaderComponent } from "./operator-registration/components/register-operator-wizard-header/register-operator-wizard-header.component";
import { OperatorRegistrationWizardResolver } from "./operator-registration/services/operator-registration-wizard.resolver";
import { RegisterPilotWizardContentComponent } from "./pilot-registration/components/register-pilot-wizard-content/register-pilot-wizard-content.component";
import { RegisterPilotWizardHeaderComponent } from "./pilot-registration/components/register-pilot-wizard-header/register-pilot-wizard-header.component";
import { PilotRegistrationWizardResolver } from "./pilot-registration/services/pilot-registration-wizard.resolver";

const routes: Routes = [
    {
        path: "register/pilot",
        component: RegisterPilotWizardContentComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard],
        resolve: {
            init: PilotRegistrationWizardResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibPilotOperatorRegistration.routeTitles.pilotRegisterWizard",
            headerComponent: RegisterPilotWizardHeaderComponent,
            shouldSkipReloadOnOperatorChange: true,
        },
        // TODO: add canDeactivate warn for wizards:
        // https://github1s.com/juristr/egghead-learn-angular-router/blob/21-can-deactivate/src/app/people/can-deactivate.guard.ts
    },
    {
        path: "register/operator",
        component: RegisterOperatorWizardContentComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard],
        resolve: {
            init: OperatorRegistrationWizardResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibPilotOperatorRegistration.routeTitles.newOperatorWizard",
            headerComponent: RegisterOperatorWizardHeaderComponent,
            shouldSkipReloadOnOperatorChange: true,
        },
        // TODO: add canDeactivate warn for wizards:
        // https://github1s.com/juristr/egghead-learn-angular-router/blob/21-can-deactivate/src/app/people/can-deactivate.guard.ts
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [PilotRegistrationWizardResolver, OperatorRegistrationWizardResolver],
})
export class PilotOperatorRegistrationRoutingModule {}
