import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Operation } from "../../../services/specific-permit-application.models";

interface ZoneGeometryInfoComponentState {
    operation: Operation | undefined;
    isExpanded: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-zone-geometry-info[operation]",
    templateUrl: "./zone-geometry-info.component.html",
    styleUrls: ["../../common.scss", "./zone-geometry-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneGeometryInfoComponent {
    @Input() public set operation(value: Operation | undefined) {
        this.localStore.patchState({ operation: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly operation$ = this.localStore.selectByKey("operation").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<ZoneGeometryInfoComponentState>) {
        this.localStore.setState({
            operation: undefined,
            isExpanded: false,
        });
    }
}
