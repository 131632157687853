import { Directive, inject } from "@angular/core";
import { Router } from "@angular/router";
import { SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { CameraHelperService, DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS } from "@dtm-frontend/shared/map/cesium";
import { AirspaceElement, GeoZonesActions, GeoZonesState } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanData, MissionUtils } from "@dtm-frontend/shared/mission";
import { GeoJSON, MissionPlanRoute, RouteAreaTypeId, RouteData, TimeRange, reloadComponent } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { ViewerConfiguration } from "@pansa/ngx-cesium";
import { Feature as GeoJSONFeature, feature as createFeature, featureCollection as createFeatureCollection } from "@turf/helpers";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { PilotProfileState } from "../../pilot-profile/state/pilot-profile.state";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { PublicMissionPlanData } from "../models/mission.model";
import { MissionActions } from "../state/mission.actions";
import { MissionState } from "../state/mission.state";

export interface MissionBaseComponentState {
    selectedOtherMission: MissionPlanData | undefined;
}

@Directive()
export abstract class MissionBaseComponent<T extends MissionBaseComponentState> {
    protected readonly cameraHelperService = inject(CameraHelperService);
    protected readonly sharedMapEndpoints = inject(SHARED_MAP_ENDPOINTS);
    protected readonly store = inject(Store);
    protected readonly viewerConfiguration = inject(ViewerConfiguration);
    protected readonly router = inject(Router);

    protected readonly areOtherMissionsProcessing$ = this.store.select(MissionState.areOtherMissionsProcessing);
    protected readonly collisionZones$ = this.store.select(GeoZonesState.customZonesLayersData);
    protected readonly currentPlanAnalysisStatus$ = this.store.select(MissionState.currentPlanAnalysisStatus);
    protected readonly currentPlanDataAndCapabilities$ = this.store.select(MissionState.currentPlanDataAndCapabilities);
    protected readonly error$ = this.store.select(MissionState.missionError);
    protected readonly isProcessing$ = combineLatest([
        this.store.select(MissionState.isProcessing),
        this.store.select(PilotProfileState.isPilotProfileProcessing),
    ]).pipe(map(([isProcessing, isPilotProfileProcessing]) => isProcessing || isPilotProfileProcessing));
    protected readonly operators$ = this.store.select(OperatorContextState.operators);
    protected readonly route$ = this.store.select(MissionState.currentPlanRoute);
    protected readonly soraSettings$ = this.store.select(MissionState.missionSoraSettings);
    protected readonly selectedZoneId$ = this.store.select(GeoZonesState.selectedZoneId);
    protected readonly operationalGeometryData$ = this.store.select(MissionState.operationalGeometryData);

    protected readonly currentPlanRouteWaypoints$ = combineLatest([
        this.isProcessing$,
        this.store.select(MissionState.currentPlanRoute),
    ]).pipe(
        map(([isProcessing, route]) => {
            if (isProcessing || !route) {
                return undefined;
            }

            return MissionUtils.convertRouteToWaypoints(route);
        })
    );
    protected readonly collisionMissions$ = this.store
        .select(MissionState.nearbyMissionsRouteData)
        .pipe(map((missionData) => missionData?.map((data) => (data.isCollision ? data.data : undefined)).filter(FunctionUtils.isTruthy)));
    protected readonly nearbyMissions$ = this.store
        .select(MissionState.nearbyMissionsRouteData)
        .pipe(map((missionData) => missionData?.map((data) => (!data.isCollision ? data.data : undefined)).filter(FunctionUtils.isTruthy)));
    protected readonly routeData$ = this.initializeRouteData();
    protected missionTimeRange$: Observable<TimeRange> = this.currentPlanRouteWaypoints$.pipe(
        RxjsUtils.filterFalsy(),
        map(MissionUtils.getTimeRangeFromWaypointsWithSection)
    );

    protected routeDrawableFeature: RouteAreaTypeId[] = ["flightArea", "waypoint"];
    protected nearbyMissionsRouteDrawableFeature: RouteAreaTypeId[] = ["flightArea", "waypoint"];

    protected constructor(protected readonly localStore: LocalComponentStore<T>) {
        this.viewerConfiguration.viewerOptions = DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS;
    }

    protected flyToRoute(route: MissionPlanRoute) {
        const zoomArea = createFeatureCollection(
            route.sections.reduce<GeoJSONFeature[]>((features, section) => {
                const area = section.flightZone?.safetyArea.volume.area ?? section.segment?.safetyArea.volume.area;

                if (area) {
                    features.push(createFeature(area));
                }

                return features;
            }, [])
        );

        this.cameraHelperService.flyToGeoJSON(zoomArea);
    }

    protected flyToMainMissionRoute() {
        const route = this.store.selectSnapshot(MissionState.currentPlanRoute);

        if (!route) {
            return;
        }

        this.flyToRoute(route);
    }

    protected flyToRouteById(routeId: string) {
        const route = this.store
            .selectSnapshot(MissionState.nearbyMissionsRouteData)
            ?.find((routeData) => routeData.data?.route?.routeId === routeId)?.route as MissionPlanRoute;

        if (!route) {
            return;
        }

        this.flyToRoute(route);
    }

    protected transformMissionRouteToRouteData(
        missionRoute: MissionPlanRoute,
        nearbyMissionsData: RouteData<PublicMissionPlanData>[] | undefined,
        uniqueRouteId: number,
        uniqueNearbyRoutesId: number,
        selectedOtherMission: MissionPlanData | undefined
    ): RouteData<MissionPlanData | PublicMissionPlanData> {
        return {
            isMain: true,
            isPathBased: missionRoute.isPathBased,
            route: missionRoute,
            nearbyMissionsData: nearbyMissionsData?.map((mission) => ({
                ...mission,
                isSelected: mission.data?.id === selectedOtherMission?.id,
            })),
            uniqueNearbyRoutesId,
            uniqueRouteId,
            isOutsideDtm: missionRoute.stats?.flight.dtmNames && missionRoute.stats.flight.dtmNames.length === 0,
        };
    }

    protected flyToGeometry(geometry: GeoJSON) {
        this.cameraHelperService.flyToGeoJSON(geometry);
    }

    protected selectZone(zone: AirspaceElement) {
        if (this.store.selectSnapshot(GeoZonesState.selectedZoneId) === zone?.id) {
            this.flyToMainMissionRoute();
            this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(undefined));

            return;
        }

        this.flyToGeometry(zone.geometry);
        this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(zone?.id));
    }

    protected getRoutesFromRouteData(
        data?: RouteData<MissionPlanData | PublicMissionPlanData>,
        shouldIncludeNearbyMissions = true
    ): MissionPlanRoute[] {
        if (!shouldIncludeNearbyMissions) {
            return data?.route ? [data.route] : [];
        }

        return [data?.route, data?.nearbyMissionsData?.map(({ route }) => route)].flat().filter(FunctionUtils.isTruthy);
    }

    protected reload() {
        reloadComponent(this.router);
    }

    private initializeRouteData() {
        return combineLatest([
            this.route$.pipe(map((route, uniqueRouteId) => ({ route, uniqueRouteId }))),
            this.store
                .select(MissionState.nearbyMissionsRouteData)
                .pipe(map((nearbyMissions, uniqueNearbyRoutesId) => ({ nearbyMissions, uniqueNearbyRoutesId }))),
            this.localStore.selectByKey("selectedOtherMission"),
        ]).pipe(
            map(([{ route, uniqueRouteId }, { nearbyMissions, uniqueNearbyRoutesId }, selectedOtherMission]) =>
                route
                    ? this.transformMissionRouteToRouteData(
                          route,
                          nearbyMissions,
                          uniqueRouteId,
                          uniqueNearbyRoutesId,
                          selectedOtherMission
                      )
                    : undefined
            )
        );
    }

    protected messageAcknowledged() {
        this.store.dispatch(MissionActions.MissionPlanMessageAcknowledgement);
    }
}
