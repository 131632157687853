import { FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import { AttorneyDocumentsApiService } from "../../../shared/services/attorney-documents/attorney-documents-api.service";
import {
    ATTORNEY_DOCUMENT_ENDPOINTS,
    AttorneyDocumentEndpoints,
} from "../../../shared/services/attorney-documents/attorney-documents-api.token";
import { OPERATOR_REGISTRATION_ENDPOINTS, OperatorRegistrationEndpoints } from "../operator-registration.tokens";

function attorneyPowerDocumentsApiFactory(endpoints: OperatorRegistrationEndpoints): AttorneyDocumentEndpoints {
    return {
        uploadFile: endpoints.uploadAttorneyPowerFile,
        downloadFile: endpoints.downloadAttorneyPowerFile,
    };
}

export const attorneyPowerDocumentsProviders = [
    { provide: FILES_UPLOAD_API_PROVIDER, useClass: AttorneyDocumentsApiService },
    {
        provide: ATTORNEY_DOCUMENT_ENDPOINTS,
        useFactory: attorneyPowerDocumentsApiFactory,
        deps: [OPERATOR_REGISTRATION_ENDPOINTS],
    },
];
