<div class="dialog-header">
    <div mat-dialog-title>
        {{ data.title }}
        <dtm-ui-popover *ngIf="data.description" [popoverText]="data.description"></dtm-ui-popover>
    </div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <ac-map dtmMapCesiumCleanupOnDestroy>
        <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
    </ac-map>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-primary" [matDialogClose]="false">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaPreviewDialog.closeButtonLabel" | transloco }}
    </button>
</div>
