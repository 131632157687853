import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn, Router } from "@angular/router";
import { MissionType } from "@dtm-frontend/shared/mission";
import { Store } from "@ngxs/store";
import { forkJoin, lastValueFrom, of, switchMap } from "rxjs";
import { MissionGroupByPeriod, MissionPlanListQuery } from "../models/mission.model";
import { MissionActions } from "../state/mission.actions";
import { MissionState } from "../state/mission.state";

export const PAGE_NUMBER_QUERY_PARAM = "page";
export const PAGE_SIZE_QUERY_PARAM = "pageSize";
export const MISSION_PLAN_ID_PARAM = "id";

export const DEFAULT_MISSION_LIST_FILTERS_AND_SORTING: MissionPlanListQuery = {
    sort: "flightStartAtMin,asc",
    groupBy: MissionGroupByPeriod.Day,
    flightType: [MissionType.BVLOS, MissionType.VLOS],
    [PAGE_SIZE_QUERY_PARAM]: 12,
};

@Injectable()
export class MissionCapabilitiesResolver {
    constructor(private readonly store: Store) {}

    public async resolve(): Promise<void> {
        this.store.dispatch(MissionActions.CleanupMissionWizard);

        await Promise.all([
            lastValueFrom(this.store.dispatch(MissionActions.GetMissionCapabilities)),
            lastValueFrom(this.store.dispatch(MissionActions.GetActivePermitsList)),
        ]);
    }
}

@Injectable()
export class MissionsListResolvers {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        if (Object.keys(route.queryParams).length === 0) {
            const startOfDay = new Date();
            startOfDay.setHours(0, 0, 0, 0);

            const queryParams = {
                ...DEFAULT_MISSION_LIST_FILTERS_AND_SORTING,
                flightDateFrom: startOfDay.toISOString(),
            };

            this.router.navigate([route.routeConfig?.path], {
                queryParams,
            });

            // NOTE: We are changing navigation so current one shouldn't continue
            return Promise.reject();
        }

        const flightPurposes = this.store.selectSnapshot(MissionState.flightPurposes);

        if (flightPurposes) {
            return Promise.resolve();
        }

        return lastValueFrom(this.store.dispatch(MissionActions.GetFlightPurposes));
    }
}

@Injectable()
export class EditMissionResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        const planId = route.paramMap.get(MISSION_PLAN_ID_PARAM);
        if (!planId) {
            return Promise.reject();
        }

        this.store.dispatch(MissionActions.CleanupMissionWizard);

        await lastValueFrom(
            forkJoin([
                this.store.dispatch(MissionActions.GetMissionCapabilities),
                this.store.dispatch(new MissionActions.GetMissionPlanItinerary(planId)),
                this.store.dispatch(MissionActions.GetActivePermitsList),
            ]).pipe(switchMap(() => this.store.dispatch(new MissionActions.GetMissionData(planId, true))))
        );
    }
}

export const missionDetailsResolver: ResolveFn<void> = async (route: ActivatedRouteSnapshot) => {
    const planId = route.paramMap.get(MISSION_PLAN_ID_PARAM);
    if (!planId) {
        return;
    }

    const store = inject(Store);
    await lastValueFrom(
        store
            .dispatch([
                new MissionActions.GetMissionData(route.paramMap.get(MISSION_PLAN_ID_PARAM) ?? "", false),
                new MissionActions.GetMissionPlanItinerary(planId),
                new MissionActions.GetOperationalGeometryData(planId),
            ])
            .pipe(
                switchMap(() => {
                    if (!store.selectSnapshot(MissionState.currentPlan)) {
                        return of(null);
                    }

                    return store.dispatch(MissionActions.GetMissionPlanAnalysis);
                })
            )
    );
};
