import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Toast } from "ngx-toastr";

@Component({
    selector: "dtm-web-app-lib-grc-alert-toast",
    templateUrl: "./grc-alert-toast.component.html",
    styleUrls: ["./grc-alert-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrcAlertToastComponent extends Toast {}
