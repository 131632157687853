import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { EmptyStateMode, QualificationStatus } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { OwnedOperatorPermission } from "../../services/operator-permissions.models";

interface OwnedPermissionsListComponentState {
    ownedPermissions: OwnedOperatorPermission[];
    isProcessing: boolean;
    isMakingStatementAvailable: boolean;
    expandedElement: OwnedOperatorPermission | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-permissions-owned-list[ownedPermissions]",
    templateUrl: "./owned-permissions-list.component.html",
    styleUrls: ["./owned-permissions-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class OwnedPermissionsListComponent {
    @Input() public set ownedPermissions(value: OwnedOperatorPermission[] | undefined) {
        this.localStore.patchState({ ownedPermissions: value ?? [] });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isMakingStatementAvailable(value: BooleanInput) {
        this.localStore.patchState({ isMakingStatementAvailable: coerceBooleanProperty(value) });
    }
    @Output() public readonly uavDetailsShow = new EventEmitter<string>();
    @Output() public readonly permissionGet = new EventEmitter<void>();

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isMakingStatementAvailable$ = this.localStore.selectByKey("isMakingStatementAvailable");
    protected readonly ownedPermissions$ = this.localStore.selectByKey("ownedPermissions").pipe(RxjsUtils.filterFalsy());
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly displayedColumns: string[] = ["expandHandle", "status", "permissions", "applicationSubmitted", "uavs", "actions"];
    protected readonly QualificationStatus = QualificationStatus;
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(private readonly localStore: LocalComponentStore<OwnedPermissionsListComponentState>) {
        localStore.setState({ ownedPermissions: [], isProcessing: false, isMakingStatementAvailable: false, expandedElement: undefined });
    }

    protected toggleExpandableRow(row: OwnedOperatorPermission): void {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }
}
