import { FilesGroup } from "@dtm-frontend/shared/ui";
import { ChapterAttachmentType, OperationsManualVersion } from "../services/operations-manual.models";

export namespace OperationsManualActions {
    export class GetCapabilities {
        public static readonly type = "[Operations Manual] Get Capabilities";
    }

    export class ResetData {
        public static readonly type = "[Operations Manual] Reset data";
    }

    export class CreateOperationsManual {
        public static readonly type = "[Operations Manual] Create Operations Manual";
    }

    export class SetCurrentOperationsManual {
        public static readonly type = "[Operations Manual] Set Current Operations Manual";

        constructor(public operationsManual: OperationsManualVersion) {}
    }

    export class GetTableOfContents {
        public static readonly type = "[Operations Manual] Get Operations Manual's table of contents";

        constructor(public filters: string[]) {}
    }

    export class Publish {
        public static readonly type = "[Operations Manual] Publish Operations Manual";
    }

    export class DownloadPdf {
        public static readonly type = "[Operations Manual] Download Operations Manual as PDF";

        constructor(public fileName: string) {}
    }

    export class GetChapter {
        public static readonly type = "[Operations Manual] Get Operations Manual's chapter";

        constructor(public chapterId: string, public filters: string[]) {}
    }

    export class SaveChapter {
        public static readonly type = "[Operations Manual] Save chapter's content";

        constructor(public chapterId: string, public content: string | null) {}
    }

    export class SetChapterAttachments {
        public static readonly type = "[Operations Manual] Set chapter's attachments";

        constructor(public chapterId: string, public attachments: FilesGroup<ChapterAttachmentType>[]) {}
    }

    export class AddSubchapter {
        public static readonly type = "[Operations Manual] Add subchapter";

        constructor(public chapterId: string, public name: string) {}
    }

    export class RemoveSubchapter {
        public static readonly type = "[Operations Manual] Remove subchapter";

        constructor(public chapterId: string, public subchapterId: string) {}
    }
}
