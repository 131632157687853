<ng-container *ngrxLet="properties$ as properties">
    <ng-container *ngrxLet="navigationAccuracyItems$ as navigationAccuracyItems">
        <dtm-web-app-lib-uav-property
            [label]="'dtmWebAppLibUav.uavProperties.trackings.trackerLabel' | transloco"
            [isEmpty]="properties.name === null"
        >
            {{ properties.name }}
        </dtm-web-app-lib-uav-property>
        <dtm-web-app-lib-uav-property [label]="'dtmWebAppLibUav.uavProperties.trackings.identifierLabel' | transloco">
            <span class="identifier-value">{{ properties.identifier }}</span>
        </dtm-web-app-lib-uav-property>
        <dtm-web-app-lib-uav-property
            *ngIf="!properties.isEmbedded"
            [label]="'dtmWebAppLibUav.uavProperties.trackings.weightLabel' | transloco"
        >
            {{
                "dtmWebAppLibUav.uavProperties.trackings.weightValue"
                    | transloco : { value: properties.weight | convertToGrams | localizeNumber }
            }}
        </dtm-web-app-lib-uav-property>
        <strong class="accuracy-header">
            {{ "dtmWebAppLibUav.setupForm.trackings.uavPositionAccuracyLabel" | transloco }}
            <dtm-ui-popover [popoverText]="'dtmWebAppLibUav.setupForm.trackings.uavPositionAccuracyTooltip' | transloco"></dtm-ui-popover>
        </strong>
        <dtm-web-app-lib-uav-property
            [label]="'dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.horizontalFlight.label' | transloco"
        >
            <span class="navigation-accuracy">
                {{
                    "dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.value"
                        | transloco : { value: properties.flightNavigationAccuracy }
                }}
                <dtm-ui-popover>
                    <dtm-web-app-lib-navigation-accuracy-class-description
                        [items]="navigationAccuracyItems"
                        [classValue]="properties.flightNavigationAccuracy"
                        [type]="NavigationAccuracyType.HorizontalFlight"
                    ></dtm-web-app-lib-navigation-accuracy-class-description>
                </dtm-ui-popover>
            </span>
        </dtm-web-app-lib-uav-property>
        <dtm-web-app-lib-uav-property
            [label]="'dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.takeoffAndLanding.label' | transloco"
        >
            <span class="navigation-accuracy">
                {{
                    "dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.value"
                        | transloco : { value: properties.takeoffAndLandingNavigationAccuracy }
                }}
                <dtm-ui-popover>
                    <dtm-web-app-lib-navigation-accuracy-class-description
                        [items]="navigationAccuracyItems"
                        [classValue]="properties.takeoffAndLandingNavigationAccuracy"
                        [type]="NavigationAccuracyType.TakeoffAndLanding"
                    ></dtm-web-app-lib-navigation-accuracy-class-description>
                </dtm-ui-popover>
            </span>
        </dtm-web-app-lib-uav-property>
    </ng-container>
</ng-container>
