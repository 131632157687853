import { HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { MissionProcessingPhaseExtended } from "@dtm-frontend/shared/mission";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { OPERATOR_CONTEXT_PARAM } from "../../shared/operator-context/models/operator-context.models";
import {
    Member,
    MemberType,
    MembersQueryParams,
    MembersWithPages,
    MembershipCapabilities,
    MembershipError,
    MembershipErrorType,
    MembershipInvitationError,
    MembershipInvitationErrorType,
    MembershipMission,
    MembershipMissionsFilter,
    MembershipMissionsWithPages,
    MembershipPermission,
    MembershipPermissionsError,
    MembershipPermissionsErrorType,
    MembershipStatus,
    PilotCompetency,
} from "./membership.models";

const userInWaitingStatusErrorKey = "user_directory.membership.recipient_user_in_waiting_status";

export interface MemberResponseBody {
    id: string;
    type: MemberType;
    status: MembershipStatus;
    firstName: string;
    lastName: string;
    phoneNumber: PhoneNumber | null;
    email: string | null;
    invitationDate: string;
    invitationId: string;
    me: boolean;
    pilot: boolean;
    membershipId: string | null;
    membershipPilotId?: string;
    membershipOperatorId: string;
    membershipOperatorName: string;
    competencies: PilotCompetency[];
    permissions: MembershipPermission[];
    userId: string;
}

export interface GetMembersListResponseBody {
    content: MemberResponseBody[];
    first: boolean;
    hasContent: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export interface GetMembersCapabilitiesResponseBody {
    membershipStatuses: MembershipStatus[];
    operationScenarios: PilotCompetency[];
    pilotId: string;
    missionPlanPhases: MissionProcessingPhaseExtended[];
    availablePermissions: MembershipPermission[];
}

export interface GetMembershipMissionsResponseBody {
    content: MembershipMission[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export function transformMembersResponseBodyToMember(response: MemberResponseBody): Member {
    return {
        id: response.id,
        type: response.type,
        status: response.status,
        firstName: response.firstName,
        lastName: response.lastName,
        phoneNumber: response.phoneNumber,
        email: response.email,
        invitationDate: response.invitationDate,
        invitationId: response.invitationId,
        isMe: response.me,
        isPilot: response.pilot,
        membershipId: response.membershipId,
        membershipPilotId: response.membershipPilotId,
        membershipOperatorId: response.membershipOperatorId,
        membershipOperatorName: response.membershipOperatorName,
        competencies: response.competencies,
        permissions: response.permissions,
        userId: response.userId,
    };
}

export function convertGetMembersListResponseToMembersWithPages(response: GetMembersListResponseBody): MembersWithPages {
    return {
        pages: {
            totalElements: response.totalElements,
            pageNumber: response.number,
            pageSize: response.size,
        },
        content: response.content.map((pilot) => transformMembersResponseBodyToMember(pilot)),
    };
}

export function convertMembershipCapabilitiesResponseBodyToMembershipCapabilities(
    response: GetMembersCapabilitiesResponseBody
): MembershipCapabilities {
    return {
        membershipStatuses: response.membershipStatuses,
        operationScenarios: response.operationScenarios,
        pilotId: response.pilotId,
        missionPlanPhases: response.missionPlanPhases,
    };
}

export function getMembersRequestParams(params: MembersQueryParams): HttpParams {
    const defaultPageSize = 10;
    let requestParams = new HttpParams().set("size", params.size ?? defaultPageSize).set("page", params.page ?? 0);

    const isRequestedForPilots = !!params[OPERATOR_CONTEXT_PARAM];

    if (isRequestedForPilots && params[OPERATOR_CONTEXT_PARAM]) {
        requestParams = requestParams.set("operatorId", params[OPERATOR_CONTEXT_PARAM]);
    } else {
        requestParams = requestParams.set("onlyMyMemberships", true);
    }

    if (params.searchByText) {
        requestParams = isRequestedForPilots
            ? requestParams.set("pilotName", params.searchByText)
            : requestParams.set("operatorName", params.searchByText);
    }

    if (params.status) {
        requestParams = requestParams.set("statuses", params.status);
    }

    if (params.competencies) {
        requestParams = requestParams.set("competencies", params.competencies);
    }

    return requestParams;
}

export function getMembershipMissionsListRequestParams(
    operatorId: string,
    pilotId: string,
    filtersQuery: MembershipMissionsFilter,
    size: number,
    page: number
): HttpParams {
    let params = new HttpParams().set("size", size).set("page", page ?? 0);

    if (filtersQuery.name?.length) {
        params = params.append("name", filtersQuery.name);
    }

    if (filtersQuery.dateFrom) {
        params = params.append("flightDateFrom", filtersQuery.dateFrom);
    }

    if (filtersQuery.dateTo) {
        params = params.append("flightDateTo", filtersQuery.dateTo);
    }

    if (filtersQuery.status) {
        params = params.append("processingPhase", filtersQuery.status.join(","));
    }

    return params;
}

export function transformErrorResponseToMembershipError(errorResponse: HttpErrorResponse): MembershipError {
    switch (errorResponse.status) {
        case HttpStatusCode.NotFound:
            return { type: MembershipErrorType.NotFound };
        case HttpStatusCode.Forbidden:
            return {
                type: errorResponse.error.generalMessage.includes("cannot_deactivate_last_manager")
                    ? MembershipErrorType.CannotDeactivateLastManager
                    : MembershipErrorType.Forbidden,
            };
        default: {
            return { type: MembershipErrorType.Unknown };
        }
    }
}

export function transformSendInvitationErrorResponseToMembershipError(errorResponse: HttpErrorResponse): MembershipError {
    switch (errorResponse.status) {
        case HttpStatusCode.NotFound:
            return { type: MembershipErrorType.NotFound };
        case HttpStatusCode.Forbidden:
            return { type: MembershipErrorType.Forbidden };
        case HttpStatusCode.BadRequest:
            if (errorResponse.error.generalMessage === userInWaitingStatusErrorKey) {
                return { type: MembershipErrorType.MemberWaiting };
            }

            return { type: MembershipErrorType.MemberAdded };
        default: {
            return { type: MembershipErrorType.Unknown };
        }
    }
}

export function transformChangeUserPermissionsErrorResponseToMembershipPermissionsError(
    errorResponse: HttpErrorResponse
): MembershipPermissionsError {
    const lastOperatorRemovalErrorMessage = "user_directory.membership.cannot_remove_last_operator_manage_permission";
    if (errorResponse.status === HttpStatusCode.Conflict && errorResponse.error.generalMessage === lastOperatorRemovalErrorMessage) {
        return { type: MembershipPermissionsErrorType.Conflict };
    }

    return { type: MembershipPermissionsErrorType.Unknown };
}

export function transformChangeInvitationStatusErrorResponse(errorResponse: HttpErrorResponse): MembershipInvitationError {
    switch (errorResponse.status) {
        case HttpStatusCode.NotFound:
            return { type: MembershipInvitationErrorType.NotFound };
        default: {
            return { type: MembershipInvitationErrorType.CouldNotAccept };
        }
    }
}

export function convertGetMembershipMissionsResponseBodyToMembershipMissionsWithPages(
    response: GetMembershipMissionsResponseBody
): MembershipMissionsWithPages {
    return {
        content: response.content,
        pages: {
            totalElements: response.totalElements,
            pageNumber: response.number,
            pageSize: response.size,
        },
    };
}
