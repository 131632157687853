<div
    class="wrapper"
    *ngrxLet="{
        isProcessing: isProcessing$,
        operationArea: operationArea$,
        customArea: customArea$,
        predefinedAreas: predefinedAreas$,
        predefinedArea: predefinedArea$,
        isCustomAreaDisabled: isCustomAreaDisabled$
    } as vm"
>
    <div class="selectable-tile" [class.selected]="vm.operationArea" (click)="selectType(OperationAreaType.Operation)">
        <span class="selectable-tile-icon"><dtm-ui-icon name="todo"></dtm-ui-icon></span>
        <div class="selectable-tile-wrapper">
            <h2 class="selectable-tile-label">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.byOperationLabel" | transloco }}
            </h2>
            <button *ngIf="vm.operationArea" type="button" class="button-secondary" (click)="previewArea()" [disabled]="vm.isProcessing">
                <mat-spinner diameter="20" *ngIf="vm.isProcessing" color="accent"></mat-spinner>
                <dtm-ui-icon name="eye" *ngIf="!vm.isProcessing"></dtm-ui-icon>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.previewButton" | transloco }}
            </button>
        </div>
    </div>
    <div
        class="selectable-tile"
        [class.selected]="vm.customArea"
        [class.disabled]="vm.isCustomAreaDisabled"
        (click)="vm.isCustomAreaDisabled ? undefined : selectType(OperationAreaType.CustomArea)"
    >
        <span class="selectable-tile-icon"><dtm-ui-icon name="shapes"></dtm-ui-icon></span>
        <div class="selectable-tile-wrapper">
            <h2 class="selectable-tile-label">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.customAreaLabel" | transloco }}
            </h2>
            <ng-container *ngIf="vm.customArea; else customAreaDescriptionTemplate">
                <button
                    *ngIf="isCustomAreaDefined$ | ngrxPush; else defineCustomAreaTemplate"
                    type="button"
                    class="button-secondary"
                    (click)="defineCustomArea(vm.customArea)"
                >
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.editAreaButton" | transloco }}
                </button>
                <ng-template #defineCustomAreaTemplate>
                    <button type="button" class="button-secondary" (click)="defineCustomArea(vm.customArea)">
                        <dtm-ui-icon name="add"></dtm-ui-icon>
                        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.defineAreaButton" | transloco }}
                    </button>
                </ng-template>
            </ng-container>
            <ng-template #customAreaDescriptionTemplate>
                <span class="selectable-tile-description">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.customAreaDescription" | transloco }}
                </span>
            </ng-template>
        </div>
    </div>
    <div
        class="selectable-tile"
        [class.selected]="vm.predefinedArea"
        (click)="selectType(OperationAreaType.PredefinedArea)"
        *ngIf="vm.predefinedAreas.length > 0"
    >
        <span class="selectable-tile-icon"><dtm-ui-icon name="map"></dtm-ui-icon></span>
        <div class="selectable-tile-wrapper">
            <h2 class="selectable-tile-label">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.predefinedAreaLabel" | transloco }}
            </h2>
            <ng-container *ngIf="vm.predefinedArea; else predefinedAreaDescriptionTemplate">
                <dtm-ui-select-field
                    [isClearable]="false"
                    [placeholder]="'dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.predefinedAreaSelectPlaceholder' | transloco"
                    [value]="vm.predefinedArea.predefinedAreaId"
                    (valueChange)="selectPredefinedArea($event)"
                    #predefinedAreaSelect
                >
                    <label>
                        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.predefinedAreaSelectLabel" | transloco }}
                    </label>
                    <dtm-ui-select-option *ngFor="let area of vm.predefinedAreas" [value]="area.id">
                        {{ area.name }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>
                <button
                    *ngIf="predefinedAreaSelect.value"
                    type="button"
                    class="button-secondary"
                    (click)="previewArea()"
                    [disabled]="vm.isProcessing"
                >
                    <mat-spinner diameter="20" *ngIf="vm.isProcessing" color="accent"></mat-spinner>
                    <dtm-ui-icon name="eye" *ngIf="!vm.isProcessing"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.predefinedAreaPreviewButton" | transloco }}
                </button>
            </ng-container>
            <ng-template #predefinedAreaDescriptionTemplate>
                <span class="selectable-tile-description">{{ vm.predefinedAreas | invoke : getPredefinedAreasNames }}</span>
            </ng-template>
        </div>
    </div>
</div>
