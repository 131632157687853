<div class="grid">
    <div class="wrapper">
        <dtm-ui-wizard-step-wrapper
            [stepNumber]="2"
            [stepsAmount]="2"
            [title]="'dtmWebAppLibUav.newUavWizardSteps.primarySetup.header' | transloco"
            (next)="goToNext()"
            (back)="back.emit()"
            [isNextStepProcessing]="isProcessing$ | ngrxPush"
            [nextButtonLabel]="'dtmWebAppLibUav.newUavWizardSteps.primarySetup.addUavButtonLabel' | transloco"
        >
            <div headerSlot>
                <button type="button" class="preview-factory-params-button button-secondary" (click)="openFactoryParametersPreview()">
                    <dtm-ui-icon name="drawer-open"></dtm-ui-icon>
                    {{ "dtmWebAppLibUav.newUavWizardSteps.primarySetup.showFactoryParametersButtonLabel" | transloco }}
                </button>
            </div>

            <div class="intro-message" *ngIf="isIntroMessageVisible$ | ngrxPush" @fade>
                <dtm-ui-info-message type="info">
                    <div>
                        <p>{{ "dtmWebAppLibUav.newUavWizardSteps.primarySetup.introMessage" | transloco }}</p>
                        <button type="button" class="close-intro-message-button button-icon" (click)="closeIntroMessage()">
                            <dtm-ui-icon name="close"></dtm-ui-icon>
                        </button>
                    </div>
                </dtm-ui-info-message>
            </div>

            <dtm-web-app-lib-setup-form
                [setupDefaults]="setupDefaults$ | ngrxPush"
                [factoryCommunicationTypes]="factoryCommunicationTypes$ | ngrxPush"
                [trackers]="trackers$ | ngrxPush"
                [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
                [isNameDisabled]="true"
                [uavModel]="newUavModel$ | ngrxPush"
                [uavTechnicalSpecification]="uavTechnicalSpecification$ | ngrxPush"
                (setupChange)="primarySetupChange($event)"
            ></dtm-web-app-lib-setup-form>
        </dtm-ui-wizard-step-wrapper>

        <div
            class="factory-params-preview-panel scroll-shadows"
            *ngrxLet="isFactoryParamsPreviewVisible$ as isFactoryParamsPreviewVisible"
            [@foldFarRight]="isFactoryParamsPreviewVisible ? 'open' : 'closed'"
        >
            <header>
                <button
                    type="button"
                    class="button-icon close-button"
                    (click)="closeFactoryParametersPreview()"
                    [attr.tabindex]="isFactoryParamsPreviewVisible ? undefined : -1"
                >
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
                <h4>{{ "dtmWebAppLibUav.newUavWizardSteps.primarySetup.factoryParamsHeader" | transloco }}</h4>
            </header>
            <section>
                <dtm-web-app-lib-uav-setup-preview
                    [setup]="primarySetupProperties$ | ngrxPush"
                    [trackers]="trackers$ | ngrxPush"
                    [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
                    [isNarrowMode]="true"
                    [isEmptyEquipmentVisible]="false"
                ></dtm-web-app-lib-uav-setup-preview>
            </section>
        </div>
    </div>
</div>
