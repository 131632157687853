import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { ConfirmationDialogComponent, DialogService, GlobalOperatorPermissions, OperatorType } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { OPERATOR_CONTEXT_PARAM } from "../../../../shared/operator-context/models/operator-context.models";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "../../../services/consts";
import {
    Member,
    MembersListFilter,
    MembersListFilterFormKeys,
    MembersQueryParams,
    MembershipPermissionsError,
    MembershipPermissionsErrorType,
    PilotCompetency,
} from "../../../services/membership.models";
import { MembershipActions } from "../../../state/membership.actions";
import { MembershipState } from "../../../state/membership.state";
import { ResignDialogComponent } from "../../shared/resign-dialog/resign-dialog.component";
import { ManagePermissionsDialogComponent } from "../manage-permissions-dialog/manage-permissions-dialog.component";
import { UserInvitationDialogComponent } from "../user-invitation-dialog/user-invitation-dialog.component";

enum UsersListActions {
    DeactivateMember = "DEACTIVATE_MEMBER",
    RemoveInvitation = "REMOVE_INVITATION",
    ResendInvitation = "RESEND_INVITATION",
    RemoveMember = "REMOVE_MEMBER",
}

interface MembershipUsersComponentState {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    filtersQuery: MembersListFilter | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-membership-users",
    templateUrl: "./membership-users.component.html",
    styleUrls: ["./membership-users.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembershipUsersComponent implements OnInit, OnDestroy {
    public readonly OperatorType = OperatorType;
    public readonly initialFilters$ = this.localStore.selectByKey("filtersQuery").pipe(RxjsUtils.filterFalsy());
    public readonly invitedUsers$ = this.store.select(MembershipState.membersList);
    public readonly isOperatorPermittedToRead$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorMembersRead)
    );
    public readonly isOperatorPermittedToManage$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorManage)
    );
    public readonly pagination$ = this.store.select(MembershipState.membersPages);
    public readonly capabilities$ = this.store.select(MembershipState.capabilities);
    public readonly capabilitiesError$ = this.store.select(MembershipState.capabilitiesError);
    public readonly isMemberProcessing$ = this.store.select(MembershipState.isMemberProcessing);
    public readonly isCapabilitiesProcessing$ = this.store.select(MembershipState.isCapabilitiesProcessing);
    public readonly selectedOperatorContext$ = this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy());

    constructor(
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<MembershipUsersComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        localStore.setState({
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM],
            filtersQuery: this.getFiltersQueryFromParams(this.route.snapshot.queryParams),
        });
    }

    public ngOnInit() {
        this.selectedOperatorContext$.pipe(distinctUntilChanged(), RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe((context) => {
            if (context.type === OperatorType.Enterprise) {
                this.refreshCapabilities();
                this.navigateByParams();
            } else {
                this.router.navigate(["/membership/operators-list"], { replaceUrl: true });
            }
        });

        this.refreshListInit();
    }

    public ngOnDestroy() {
        this.store.dispatch(MembershipActions.ClearMembersList);
    }

    public async refreshCapabilities() {
        const operator = await firstValueFrom(this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy()));
        this.store.dispatch(new MembershipActions.GetCapabilities(operator.id));
    }

    public changePage(event: PageEvent) {
        this.localStore.patchState({
            [PAGE_NUMBER_QUERY_PARAM]: event?.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event?.pageSize,
        });

        this.navigateByParams();
    }

    public openInvitationDialog() {
        this.dialogService
            .open<UserInvitationDialogComponent>(UserInvitationDialogComponent, { disableClose: true })
            .afterClosed()
            .pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe(() => this.refreshListWithParams(this.route.snapshot.queryParams));
    }

    public deactivateMember({ membershipId, membershipOperatorId }: Member) {
        if (!membershipId || !membershipOperatorId) {
            return;
        }

        const dialogRef = this.dialogService.open(ResignDialogComponent, {
            data: {
                isResignProcessing$: this.isMemberProcessing$,
                deactivateMemberNoteLabel: this.translocoService.translate(
                    "dtmWebAppMembership.invitedUsersList.deactivateMemberNoteLabel"
                ),
                deactivateMemberInformationText: this.translocoService.translate(
                    "dtmWebAppMembership.invitedUsersList.deactivateMemberInformationText"
                ),
            },
        });

        dialogRef.componentInstance.setNote$
            .pipe(
                switchMap((reason) =>
                    this.store.dispatch(new MembershipActions.DeactivateMember(membershipId, membershipOperatorId, reason))
                ),
                map(() => this.store.selectSnapshot(MembershipState.memberProcessingError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.showActionSuccessMessage(UsersListActions.DeactivateMember);
                    this.refreshListWithParams(this.route.snapshot.queryParams);
                    dialogRef.close();

                    return;
                }

                this.showActionErrorMessage(UsersListActions.DeactivateMember);
            });
    }

    public removeMember({ membershipId, firstName, lastName }: Member) {
        if (!membershipId) {
            return;
        }

        const dialogRef = this.getMemberRemovalDialog(firstName, lastName);

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new MembershipActions.RemoveMember(membershipId))),
                switchMap(() => this.store.select(MembershipState.memberProcessingError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.showActionSuccessMessage(UsersListActions.RemoveMember);
                    this.refreshListWithParams(this.route.snapshot.queryParams);
                    dialogRef.close();

                    return;
                }

                this.showActionErrorMessage(UsersListActions.RemoveMember);
            });
    }

    public resendInvitationToMember({ membershipId, firstName, lastName }: Member) {
        if (!membershipId) {
            return;
        }

        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                userName: `${firstName} ${lastName}`,
                titleText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.resendMemberInvitationTitle"),
                confirmationText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.resendMemberInvitationText", {
                    userName: `${firstName} ${lastName}`,
                }),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new MembershipActions.ResendInvitationToMember(membershipId))),
                switchMap(() => this.store.select(MembershipState.memberProcessingError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.showActionSuccessMessage(UsersListActions.ResendInvitation);
                    this.refreshListWithParams(this.route.snapshot.queryParams);
                    dialogRef.close();

                    return;
                }

                this.showActionErrorMessage(UsersListActions.ResendInvitation);
            });
    }

    public removeInvitation(invitationId: string) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.removeMemberInvitationTitle"),
                confirmationText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.removeMemberInvitationText"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(FunctionUtils.isTruthy),
                switchMap(() => this.store.dispatch(new MembershipActions.RemoveInvitation(invitationId))),
                switchMap(() => this.store.select(MembershipState.removeInvitationError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.showActionSuccessMessage(UsersListActions.RemoveInvitation);
                    this.refreshListWithParams(this.route.snapshot.queryParams);

                    return;
                }

                this.showActionErrorMessage(UsersListActions.RemoveInvitation);
            });
    }

    public resendInvitation(invitationId: string) {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.resendMemberInvitationTitle"),
                confirmationText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.resendMemberInvitationText"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter(FunctionUtils.isTruthy),
                switchMap(() => this.store.dispatch(new MembershipActions.ResendInvitation(invitationId))),
                map(() => this.store.selectSnapshot(MembershipState.userInvitationError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.showActionSuccessMessage(UsersListActions.ResendInvitation);
                    this.refreshListWithParams(this.route.snapshot.queryParams);

                    return;
                }

                this.showActionErrorMessage(UsersListActions.ResendInvitation);
            });
    }

    public navigateByParams(shouldResetPage?: boolean) {
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM);
        const searchByText = this.localStore.selectSnapshotByKey("filtersQuery")?.[MembersListFilterFormKeys.SearchByText];
        const status = this.localStore.selectSnapshotByKey("filtersQuery")?.[MembersListFilterFormKeys.Status];
        const competencies = this.localStore.selectSnapshotByKey("filtersQuery")?.[MembersListFilterFormKeys.Competencies];

        if (shouldResetPage) {
            this.localStore.patchState({ [PAGE_NUMBER_QUERY_PARAM]: pageIndex });
        }

        let params: MembersQueryParams = {
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
            [OPERATOR_CONTEXT_PARAM]: this.store.selectSnapshot(OperatorContextState.selectedContext)?.id as string,
        };

        if (searchByText?.length) {
            params = { ...params, searchByText };
        }

        if (status?.length) {
            params = { ...params, status: status.join(",") };
        }

        if (competencies?.length) {
            params = { ...params, competencies: competencies.join(",") };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    public changeFilters(formValue: MembersListFilter) {
        this.localStore.patchState({ filtersQuery: formValue });
        this.navigateByParams(true);
    }

    private getMemberRemovalDialog(firstName: string, lastName: string) {
        return this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                pilotName: `${firstName} ${lastName}`,
                titleText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.removeMemberTitle"),
                confirmationText: this.translocoService.translate("dtmWebAppMembership.invitedUsersList.removeMemberText", {
                    userName: `${firstName} ${lastName}`,
                }),
            },
        });
    }

    private refreshListInit() {
        this.router.events
            .pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const queryParams = this.route.snapshot.queryParams;
                if (
                    !queryParams?.[OPERATOR_CONTEXT_PARAM] ||
                    this.store.selectSnapshot(OperatorContextState.selectedContext)?.type !== OperatorType.Enterprise
                ) {
                    return;
                }

                // NOTE it is to change filters form value when queryParams change from other place like notifications
                const currentFiltersValue = this.getFiltersQueryFromParams(queryParams);

                this.localStore.patchState({ filtersQuery: currentFiltersValue });

                this.refreshListWithParams(queryParams);
            });
    }

    private getFiltersQueryFromParams(queryParams: Params) {
        return {
            searchByText: queryParams.searchByText ?? "",
            status: queryParams.status?.split(",") ?? [],
            competencies: queryParams.competencies?.split(",") ?? [],
        };
    }

    private async refreshListWithParams(params: Params) {
        const capabilities = await firstValueFrom(this.capabilities$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)));
        if (!params?.[OPERATOR_CONTEXT_PARAM]) {
            return;
        }
        const competencies = this.getCompetenciesForRefreshList(params.competencies, capabilities.operationScenarios);

        this.store.dispatch(
            new MembershipActions.GetMembers({
                [OPERATOR_CONTEXT_PARAM]: params[OPERATOR_CONTEXT_PARAM],
                size: params[PAGE_SIZE_QUERY_PARAM],
                page: params[PAGE_NUMBER_QUERY_PARAM],
                searchByText: params.searchByText,
                status: params.status,
                competencies,
            })
        );
    }

    private getCompetenciesForRefreshList(competencies: string, operationScenarios: PilotCompetency[]): string | undefined {
        if (!competencies) {
            return undefined;
        }

        return competencies
            .split(",")
            .map((competencyName) => {
                const operationScenario = operationScenarios.find(
                    (searchedOperationScenario) => searchedOperationScenario.name === competencyName
                );

                return `${operationScenario?.name},${operationScenario?.category}`;
            })
            .join(";");
    }

    private showActionErrorMessage(action: UsersListActions) {
        let message;

        switch (action) {
            case UsersListActions.DeactivateMember:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.couldNotDeactivateMemberErrorMessage");
                break;
            case UsersListActions.RemoveInvitation:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.couldNotRemoveInvitationErrorMessage");
                break;
            case UsersListActions.ResendInvitation:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.couldNotResendInvitationErrorMessage");
                break;
            case UsersListActions.RemoveMember:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.couldNotRemoveMemberErrorMessage");
                break;
        }

        this.toastrService.error(message);
    }

    private showActionSuccessMessage(action: UsersListActions) {
        let message;
        switch (action) {
            case UsersListActions.DeactivateMember:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.deactivateMemberSuccessMessage");
                break;
            case UsersListActions.RemoveInvitation:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.removeInvitationSuccessMessage");
                break;
            case UsersListActions.ResendInvitation:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.resendInvitationSuccessMessage");
                break;
            case UsersListActions.RemoveMember:
                message = this.translocoService.translate("dtmWebAppMembership.invitedUsersList.removeMemberSuccessMessage");
                break;
        }

        this.toastrService.success(message);
    }

    protected openPermissionsDialog(user: Member) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);
        const isProcessing$ = this.store.select(MembershipState.isUserPermissionsChangeProcessing);
        const managePermissionsDialogRef: MatDialogRef<ManagePermissionsDialogComponent> = this.dialogService.open(
            ManagePermissionsDialogComponent,
            {
                data: { user, operatorId, isProcessing$ },
                disableClose: true,
            }
        );

        managePermissionsDialogRef.componentInstance.changePermissionsSubmit
            .pipe(
                switchMap(
                    (
                        changeUserPermissionsAction:
                            | MembershipActions.ChangeMemberUserPermissions
                            | MembershipActions.ChangeInvitedUserPermissions
                    ) => this.store.dispatch(changeUserPermissionsAction)
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const changePermissionsError = this.store.selectSnapshot(MembershipState.changeUserPermissionsError);
                if (changePermissionsError) {
                    this.showPermissionsChangeError(changePermissionsError);
                } else {
                    this.refreshListWithParams(this.route.snapshot.queryParams);
                    this.handlePermissionChangeSuccess();
                    managePermissionsDialogRef.close();
                }
            });
    }

    private showPermissionsChangeError(error: MembershipPermissionsError) {
        switch (error.type) {
            case MembershipPermissionsErrorType.Conflict:
                this.toastrService.error(
                    this.translocoService.translate("dtmWebAppMembership.managePermissions.permissionsChangeConflictErrorMessageLabel")
                );
                break;
            default:
                this.toastrService.error(
                    this.translocoService.translate("dtmWebAppMembership.managePermissions.permissionsChangeDefaultErrorMessageLabel")
                );
        }
    }

    private handlePermissionChangeSuccess() {
        this.toastrService.success(
            this.translocoService.translate("dtmWebAppMembership.managePermissions.permissionsChangeSuccessMessageLabel")
        );
    }
}
