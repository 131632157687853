import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Communication, CommunicationType } from "../../../../services/uav.models";

export interface CommunicationPropertiesComponentState {
    properties: Communication | undefined;
}

@Component({
    selector: "dtm-web-app-lib-communication-properties[properties]",
    templateUrl: "./communication-properties.component.html",
    styleUrls: ["./communication-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CommunicationPropertiesComponent {
    protected readonly CommunicationType = CommunicationType;
    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(RxjsUtils.filterFalsy());

    @Input() public set properties(value: Communication | undefined) {
        this.localStore.patchState({ properties: value });
    }

    constructor(private readonly localStore: LocalComponentStore<CommunicationPropertiesComponentState>) {
        this.localStore.setState({
            properties: undefined,
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }
}
