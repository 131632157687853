<div class="grid">
    <div class="new-account-message">
        <h2>{{ "dtmWebAppLibDashboard.welcomeView.newAccountHeader" | transloco }}</h2>
        <p>{{ "dtmWebAppLibDashboard.welcomeView.newAccountMessage" | transloco }}</p>
    </div>
    <!-- TODO: Change each card to individual component REJ-1556-->
    <div class="grid-col-4 widget-card">
        <h2 class="widget-header">{{ "dtmWebAppLibDashboard.welcomeView.dataImportWidgetHeader" | transloco }}</h2>
        <img src="assets/images/data-import.svg" alt="" />
        <p>
            <!--                TODO: Update text REJ-1555-->
            {{ "dtmWebAppLibDashboard.welcomeView.dataImportWidgetDescription" | transloco }}
        </p>
        <p>
            {{ "dtmWebAppLibDashboard.welcomeView.dataImportWidgetImportedItemsDescription" | transloco }}
        </p>
        <div class="button-container">
            <button type="button" class="button-primary" routerLink="/operator-migration">
                {{ "dtmWebAppLibDashboard.welcomeView.dataImportButtonLabel" | transloco }} <dtm-ui-icon name="download"></dtm-ui-icon>
            </button>
        </div>
    </div>
    <div class="grid-col-4 widget-card">
        <h2 class="widget-header">{{ "dtmWebAppLibDashboard.welcomeView.pilotProfileWidgetHeader" | transloco }}</h2>
        <img src="assets/images/pilot-profile.svg" alt="" />
        <h2>
            {{ "dtmWebAppLibDashboard.welcomeView.pilotProfileWidgetDescription" | transloco }}
        </h2>
        <ul>
            <li>
                <dtm-ui-icon name="user"></dtm-ui-icon>
                {{ "dtmWebAppLibDashboard.welcomeView.pilotProfileWidgetIndividualStatement" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="gamepad"></dtm-ui-icon>
                {{ "dtmWebAppLibDashboard.welcomeView.pilotProfileWidgetPurposeStatement" | transloco }}
            </li>
        </ul>
        <p class="pansa-utm-link-info" *ngIf="isPansaUtmLinkInfoVisible$ | ngrxPush">
            <dtm-ui-icon name="information"></dtm-ui-icon>
            <span>
                {{ "dtmWebAppLibDashboard.welcomeView.pilotProfileWidgetPansaUtmLinkInfo" | transloco }}
                <dtm-ui-popover>
                    <span class="pansa-utm-link-popover">{{
                        "dtmWebAppLibDashboard.welcomeView.pilotProfileWidgetPansaUtmLinkPopoverText" | transloco
                    }}</span>
                </dtm-ui-popover>
            </span>
        </p>
        <div class="button-container">
            <button type="button" class="button-primary" (click)="tryAddingPilot()">
                {{ "dtmWebAppLibDashboard.welcomeView.registerPilotProfileButtonLabel" | transloco }}
                <dtm-ui-icon name="add"></dtm-ui-icon>
            </button>
        </div>
    </div>

    <div class="grid-col-4 widget-card">
        <h2 class="widget-header">{{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetHeader" | transloco }}</h2>
        <img src="assets/images/operator-widget-cover.svg" alt="" />
        <h2>
            {{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetDescription" | transloco }}
        </h2>
        <ul>
            <li>
                <dtm-ui-icon name="community"></dtm-ui-icon>
                <span
                    >{{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetCompanyStatement" | transloco }}
                    <dtm-ui-popover>
                        <div class="popover-content">
                            <p>{{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetCompanyPopover.infoHeader" | transloco }}</p>
                            <p>{{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetCompanyPopover.infoOne" | transloco }}</p>
                            <p>{{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetCompanyPopover.infoTwo" | transloco }}</p>
                            <p>{{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetCompanyPopover.infoThree" | transloco }}</p>
                        </div>
                    </dtm-ui-popover>
                </span>
            </li>
            <li>
                <dtm-ui-icon name="group"></dtm-ui-icon>
                {{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetCooperationStatement" | transloco }}
            </li>
            <li>
                <dtm-ui-icon name="markup"></dtm-ui-icon>
                {{ "dtmWebAppLibDashboard.welcomeView.operatorProfileWidgetClubStatement" | transloco }}
            </li>
        </ul>

        <div class="button-container">
            <button type="button" class="button-primary" routerLink="/register/operator">
                {{ "dtmWebAppLibDashboard.welcomeView.registerOperatorProfileButtonLabel" | transloco }}
                <dtm-ui-icon name="add"></dtm-ui-icon>
            </button>
        </div>
    </div>
</div>
