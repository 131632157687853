import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { PilotConfirmationDocumentType, PilotProfile } from "../../../services/pilot-profile.models";

interface OperatorPilotNumberComponentState {
    pilot: PilotProfile | undefined;
    isSecretCodeVisible: boolean;
}

@Component({
    selector: "dtm-web-app-lib-pilot-profile-operator-pilot-number[pilot]",
    templateUrl: "./operator-pilot-number.component.html",
    styleUrls: ["./operator-pilot-number.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorPilotNumberComponent {
    @Input() public set pilot(value: PilotProfile | undefined) {
        this.localStore.patchState({ pilot: value });
    }

    @Output() public readonly documentDownload = new EventEmitter<PilotConfirmationDocumentType>();
    protected readonly pilot$ = this.localStore.selectByKey("pilot").pipe(RxjsUtils.filterFalsy());
    protected readonly isSecretCodeVisible$ = this.localStore.selectByKey("isSecretCodeVisible");
    protected readonly PilotConfirmationDocumentType = PilotConfirmationDocumentType;

    constructor(
        private readonly localStore: LocalComponentStore<OperatorPilotNumberComponentState>,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService
    ) {
        this.localStore.setState({
            pilot: undefined,
            isSecretCodeVisible: false,
        });
    }

    protected secretCodeVisibilityChange(): void {
        this.localStore.patchState(({ isSecretCodeVisible }) => ({ isSecretCodeVisible: !isSecretCodeVisible }));
    }

    protected handleValueCopy(isSuccess: boolean): void {
        if (isSuccess) {
            this.toastService.success(this.transloco.translate("dtmUi.copyToClipboard.successMessage"));
        } else {
            this.toastService.error(this.transloco.translate("dtmUi.copyToClipboard.errorMessage"));
        }
    }
}
