import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { Logger, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { UAV_ENDPOINTS, UavEndpoints } from "../uav.tokens";

@Injectable()
export class CustomUavImageUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store,
        @Inject(UAV_ENDPOINTS) private readonly endpoints: UavEndpoints
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(this.endpoints.customUavPhotoUpload, formData, {
                reportProgress: true,
                responseType: "json",
                observe: "events",
            })
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    Logger.captureException(error);

                    return throwError(() => this.manageUploadError(error));
                })
            );
    }

    public getFile(fileId: string) {
        return this.httpClient.get(this.getFileUrl(fileId), {
            responseType: "blob",
            context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
        });
    }

    public getFileUrl(fileId: string) {
        return StringUtils.replaceInTemplate(this.endpoints.getModelPhoto, {
            photoId: fileId,
        });
    }

    public getFilesCompressed() {
        console.error("Not implemented");

        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
