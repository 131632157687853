import { FilesGroup, UavType } from "@dtm-frontend/shared/ui";
import { ISO8601TimeDuration, StringUtils } from "@dtm-frontend/shared/utils";
import { FailSafe, UavClass } from "../../shared";
import {
    CapabilitiesToShare,
    Communication,
    CommunicationFrequency,
    CommunicationLinkType,
    CommunicationType,
    DriveType,
    Equipment,
    EquipmentItem,
    EquipmentParachute,
    EquipmentType,
    Manufacturer,
    NavigationAccuracy,
    NavigationAccuracyClass,
    NavigationAccuracyType,
    Operator,
    ShareableOperator,
    TechnicalSpecification,
    Tracker,
    Tracking,
    Uav,
    UavCapabilities,
    UavListItem,
    UavModelDocumentType,
    UavModelDocuments,
    UavModelProperties,
    UavSetup,
    UavSetupDocumentType,
    UavSetupDocuments,
} from "./uav.models";

export interface UavListItemResponseBody {
    id: string;
    name: string;
    model: {
        id: string;
        type: UavType;
        name: string;
        manufacturer: string;
        imageId: string | null;
        custom: boolean;
        ceCompliant: boolean;
        uavClasses: UavClass[] | undefined;
    };
    operatorId: string;
    ownerName: string;
    serialNumbers: string[];
    uavClasses: UavClass[] | undefined;
    swarm: boolean;
    shared: boolean;
}

interface UavDocumentBody {
    id: string;
    name: string;
    size: number;
    editable: boolean;
}

interface SetupDocumentBody {
    id: string;
    name: string;
    size: number;
    mediaType: string;
    type: UavSetupDocumentType;
}

export interface UavResponseBody {
    id: string;
    name: string;
    model: {
        id: string;
        name: string;
        manufacturer: string;
        type: UavType;
        custom: boolean;
        photoId: string | null;
        manual?: UavDocumentBody;
        projectDocumentation?: UavDocumentBody;
        ceCompliant: boolean;
        uavClasses: UavClass[] | undefined;
    };
    operator: Operator;
    serialNumbers: string[];
    uavClasses: UavClass[] | undefined;
    swarm: boolean;
    setups: UavSetupResponseBody[];
    ownerName: string;
    shared: boolean;
}

export interface UavSetupResponseBody {
    id: string;
    name: string;
    primary: boolean;
    technicalSpecification: TechnicalSpecificationResponseBody;
    communications: CommunicationResponseBody[];
    documents?: Partial<Record<UavSetupDocumentType, SetupDocumentBody[]>>;
    trackings: TrackingResponseBody[];
    equipment: {
        parachute: ParachuteEquipmentResponseBody | null;
        items: EquipmentItemResponseBody[];
    };
}

export type ShareableUavSetupResponseBody = Pick<UavSetupResponseBody, "id" | "name" | "primary">;
export interface CapabilitiesToShareResponseBody {
    operators: ShareableOperator[];
    setups: ShareableUavSetupResponseBody[];
}

interface TechnicalSpecificationResponseBody {
    numberOfEngines?: number;
    driveType: DriveType;
    minRecommendedAmbientTemperature?: number;
    maxRecommendedAmbientTemperature?: number;
    takeOffMass: number;
    maxTakeOffMass: number;
    maxDroneWidth: number;
    maxFlightTime: ISO8601TimeDuration;
    flightSpeedLimit?: boolean;
    maxFlightSpeed: number;
    minFlightSpeed?: number;
    maxClimbSpeed?: number;
    maxDescentSpeed?: number;
    maxWind?: number;
    maxFlightAltitude?: number;
    rainFlightPossibility?: boolean;
    failSafe?: FailSafe[];
    geofencing?: boolean;
    detectAndAvoid?: boolean;
    proximitySensors?: boolean;
    moduleRedundancy?: boolean;
    geocage?: boolean;
}

interface CommunicationResponseBody {
    id: string;
    type: CommunicationType;
    transmissionLinkRange: number;
    linkType?: Exclude<CommunicationLinkType, CommunicationLinkType.Unknown>;
    linkDelay?: number;
    modelOrigin: boolean;
    frequencies?: CommunicationFrequency[];
}

interface TrackingResponseBody {
    id?: string;
    identifier: string;
    weight: number;
    trackerId: string;
    flightNavigationAccuracy: NavigationAccuracyClass;
    takeoffAndLandingNavigationAccuracy: NavigationAccuracyClass;
}

interface ParachuteEquipmentResponseBody {
    id: string;
    name: string;
    weight: number;
    minHeight: number;
    ceCertificate: boolean;
    modelOrigin: boolean;
    descentSpeed: number;
    maxWindSpeed: number;
    minOperatingTemperature: number;
    maxOperatingTemperature: number;
    astmF332218Compliant: boolean;
}

interface EquipmentItemResponseBody {
    id: string;
    name: string;
    type: EquipmentType;
    weight: number;
}

interface EquipmentRequestPayload {
    parachute: {
        id?: string;
        name?: string;
        weight: number;
        minHeight: number;
        ceCertificate: boolean;
        modelOrigin: boolean;
        descentSpeed: number;
        minOperatingTemperature: number;
        maxOperatingTemperature: number;
        astmF332218Compliant: boolean;
    } | null;
    items: {
        id?: string;
        name: string;
        type: EquipmentType;
        weight: number;
    }[];
}

type CommunicationsRequestPayload = Array<{
    id?: string;
    type: CommunicationType;
    transmissionLinkRange: number;
    linkType: Exclude<CommunicationLinkType, CommunicationLinkType.Unknown> | null;
    linkDelay: number | null;
    modelOrigin: boolean;
    frequencies: CommunicationFrequency[];
}>;

type TrackingsRequestPayload = Array<{
    id?: string;
    identifier: string;
    weight: number;
    trackerId: string;
    flightNavigationAccuracy: NavigationAccuracyClass;
    takeoffAndLandingNavigationAccuracy: NavigationAccuracyClass;
}>;

export interface UavCapabilitiesResponseBody {
    operators: Operator[];
    canShareUavs: boolean;
    navigationAccuracyClasses: {
        [k in NavigationAccuracyClass]: {
            flightNavigationAccuracyHorizontal: string;
            flightNavigationAccuracyVertical: string;
            takeoffAndLandingNavigationAccuracyHorizontal: string;
            takeoffAndLandingNavigationAccuracyVertical: string;
        };
    };
}

export type ManufacturersResponseBody = Array<{
    name: string;
    models: Array<{
        id: string;
        name: string;
        certificationRequired: boolean;
        uavClasses: UavClass[];
        photoId: string | null;
    }>;
}>;

export interface UavModelPropertiesResponseBody {
    id: string;
    name: string;
    manufacturer: string;
    type: UavType;
    custom: boolean;
    photoId: string | null;
    uavClasses: UavClass[] | undefined;
    technicalSpecification: TechnicalSpecificationResponseBody;
    communications: CommunicationResponseBody[];
    equipment: {
        camera: boolean;
        parachute: ParachuteEquipmentResponseBody | null;
    };
}

export interface CreateUavResponseBody {
    id: string;
    name: string;
    modelId: string;
    operatorId: string;
    serialNumbers: string[];
    uavClasses: UavClass[] | undefined;
    setups: UavSetupResponseBody[];
    shared: boolean;
    basedOnCustomModel: boolean;
}

interface UavDocumentsRequestPayload {
    documents: {
        [UavModelDocumentType.Manual]: string | undefined;
        [UavModelDocumentType.ProjectDocumentation]: string | undefined;
    };
}

function getUavImageUrl(getModelImageEndpoint: string, modelId: string, photoId?: string | null): string | undefined {
    if (!photoId) {
        return undefined;
    }

    return StringUtils.replaceInTemplate(getModelImageEndpoint, { modelId, photoId });
}

function prepareSetupDocuments(documents: Partial<Record<UavSetupDocumentType, SetupDocumentBody[]>> = {}): UavSetupDocuments {
    const result: UavSetupDocuments = {};

    for (const [documentType, documentFiles] of Object.entries(documents)) {
        result[documentType as UavSetupDocumentType] = (documentFiles ?? []).map((document) => ({
            id: document.id,
            name: document.name,
            size: document.size,
            isEditable: true,
        }));
    }

    return result;
}

function convertTechnicalSpecificationResponseBodyToTechnicalSpecification(
    techSpec: TechnicalSpecificationResponseBody
): TechnicalSpecification {
    return {
        numberOfEngines: techSpec.numberOfEngines ?? null,
        driveType: techSpec.driveType,
        minRecommendedAmbientTemperature: techSpec.minRecommendedAmbientTemperature ?? null,
        maxRecommendedAmbientTemperature: techSpec.maxRecommendedAmbientTemperature ?? null,
        takeOffMass: techSpec.takeOffMass,
        maxTakeOffMass: techSpec.maxTakeOffMass,
        maxDroneWidth: techSpec.maxDroneWidth,
        maxFlightTime: techSpec.maxFlightTime,
        hasFlightSpeedLimit: techSpec.flightSpeedLimit ?? false,
        maxFlightSpeed: techSpec.maxFlightSpeed,
        minFlightSpeed: techSpec.minFlightSpeed,
        maxClimbSpeed: techSpec.maxClimbSpeed ?? null,
        maxDescentSpeed: techSpec.maxDescentSpeed ?? null,
        maxWind: techSpec.maxWind ?? null,
        maxFlightAltitude: techSpec.maxFlightAltitude ?? null,
        hasRainFlightPossibility: techSpec.rainFlightPossibility ?? false,
        failSafe: techSpec.failSafe ?? [],
        hasGeofencing: techSpec.geofencing ?? false,
        hasDetectAndAvoid: techSpec.detectAndAvoid ?? false,
        hasProximitySensors: techSpec.proximitySensors ?? false,
        hasModuleRedundancy: techSpec.moduleRedundancy ?? false,
        hasGeocage: techSpec.geocage ?? false,
    };
}

function convertCommunicationResponseBodyToCommunication(communicationResponse: CommunicationResponseBody): Communication {
    return {
        id: communicationResponse.id,
        type: communicationResponse.type,
        transmissionLinkRange: communicationResponse.transmissionLinkRange,
        linkType: communicationResponse.linkType ?? CommunicationLinkType.Unknown,
        linkDelay: communicationResponse.linkDelay ?? null,
        isEmbedded: communicationResponse.modelOrigin,
        frequencies: communicationResponse.frequencies ?? [],
    };
}

function convertTrackingResponseBodyToTracking(trackingResponse: TrackingResponseBody): Tracking {
    return {
        id: trackingResponse.id,
        identifier: trackingResponse.identifier,
        weight: trackingResponse.weight,
        trackerId: trackingResponse.trackerId,
        flightNavigationAccuracy: trackingResponse.flightNavigationAccuracy,
        takeoffAndLandingNavigationAccuracy: trackingResponse.takeoffAndLandingNavigationAccuracy,
        isEmbedded: trackingResponse.weight === 0,
    };
}

function convertUavModelEquipmentResponseBodyToEquipment(
    equipment: {
        camera: boolean;
        parachute: ParachuteEquipmentResponseBody | null;
    },
    defaultCameraName: string
): Equipment {
    const camera: EquipmentItem[] = [];
    const parachute: EquipmentParachute[] = [];

    if (equipment.camera) {
        camera.push({
            name: defaultCameraName,
            weight: 0,
            isEmbedded: true,
        });
    }

    if (equipment.parachute) {
        parachute.push({
            id: equipment.parachute.id,
            name: equipment.parachute.name,
            weight: equipment.parachute.weight,
            isEmbedded: equipment.parachute.modelOrigin,
            minHeight: equipment.parachute.minHeight,
            hasCeCertificate: equipment.parachute.ceCertificate,
            descentSpeed: equipment.parachute.descentSpeed,
            maxWindSpeed: Math.round(equipment.parachute.maxWindSpeed),
            minOperatingTemperature: equipment.parachute.minOperatingTemperature,
            maxOperatingTemperature: equipment.parachute.maxOperatingTemperature,
            isAstmF332218Compliant: equipment.parachute.astmF332218Compliant,
        });
    }

    return {
        camera,
        parachute,
        navigationLighting: [],
        nightLighting: [],
        strobeLighting: [],
        propellersGuards: [],
        fts: [],
    };
}

function convertSetupEquipmentResponseBodyToEquipment(equipment: {
    parachute: ParachuteEquipmentResponseBody | null;
    items: EquipmentItemResponseBody[];
}): Equipment {
    const camera: EquipmentItem[] = [];
    const parachute: EquipmentParachute[] = [];
    const navigationLighting: EquipmentItem[] = [];
    const nightLighting: EquipmentItem[] = [];
    const strobeLighting: EquipmentItem[] = [];
    const propellersGuards: EquipmentItem[] = [];
    const fts: EquipmentItem[] = [];

    if (equipment.parachute) {
        parachute.push({
            id: equipment.parachute.id,
            name: equipment.parachute.name,
            weight: equipment.parachute.weight,
            isEmbedded: equipment.parachute.modelOrigin,
            minHeight: equipment.parachute.minHeight,
            hasCeCertificate: equipment.parachute.ceCertificate,
            descentSpeed: equipment.parachute.descentSpeed,
            maxWindSpeed: equipment.parachute.maxWindSpeed,
            minOperatingTemperature: equipment.parachute.minOperatingTemperature,
            maxOperatingTemperature: equipment.parachute.maxOperatingTemperature,
            isAstmF332218Compliant: equipment.parachute.astmF332218Compliant,
        });
    }

    for (const equipmentItem of equipment.items) {
        let equipmentCollection: EquipmentItem[] = [];

        switch (equipmentItem.type) {
            case EquipmentType.Camera:
                equipmentCollection = camera;
                break;
            case EquipmentType.PropellersGuards:
                equipmentCollection = propellersGuards;
                break;
            case EquipmentType.NavigationLighting:
                equipmentCollection = navigationLighting;
                break;
            case EquipmentType.StrobeLighting:
                equipmentCollection = strobeLighting;
                break;
            case EquipmentType.NightLighting:
                equipmentCollection = nightLighting;
                break;
            case EquipmentType.Fts:
                equipmentCollection = fts;
                break;
            case EquipmentType.Parachute:
            default:
                break;
        }

        equipmentCollection.push({
            id: equipmentItem.id,
            name: equipmentItem.name,
            weight: equipmentItem.weight,
            isEmbedded: equipmentItem.weight === 0,
        });
    }

    return {
        camera,
        parachute,
        navigationLighting,
        nightLighting,
        strobeLighting,
        propellersGuards,
        fts,
    };
}

function convertUavSetupResponseBodyToUavSetup(setupResponse: UavSetupResponseBody): UavSetup {
    return {
        id: setupResponse.id,
        name: setupResponse.name,
        isPrimary: setupResponse.primary,
        technicalSpecification: convertTechnicalSpecificationResponseBodyToTechnicalSpecification(setupResponse.technicalSpecification),
        communications: setupResponse.communications.map((communicationResponse) =>
            convertCommunicationResponseBodyToCommunication(communicationResponse)
        ),
        documents: prepareSetupDocuments(setupResponse.documents),
        trackings: setupResponse.trackings.map((trackingResponse) => convertTrackingResponseBodyToTracking(trackingResponse)),
        equipment: convertSetupEquipmentResponseBodyToEquipment(setupResponse.equipment),
    };
}

function prepareUavDocuments(manual: UavDocumentBody | undefined, projectDocumentation: UavDocumentBody | undefined): UavModelDocuments {
    const result: UavModelDocuments = {};

    if (manual) {
        result[UavModelDocumentType.Manual] = {
            id: manual.id,
            name: manual.name,
            size: manual.size,
            isEditable: manual.editable,
        };
    }

    if (projectDocumentation) {
        result[UavModelDocumentType.ProjectDocumentation] = {
            id: projectDocumentation.id,
            name: projectDocumentation.name,
            size: projectDocumentation.size,
            isEditable: projectDocumentation.editable,
        };
    }

    return result;
}

export function convertUavListItemsResponseBodyToUavListItems(
    listResponse: UavListItemResponseBody[],
    getModelImageEndpoint: string
): UavListItem[] {
    return listResponse.map((item) => ({
        id: item.id,
        name: item.name,
        manufacturerName: item.model.manufacturer,
        modelName: item.model.name,
        modelType: item.model.type,
        serialNumbers: item.serialNumbers.sort(),
        uavClasses: item.uavClasses ?? [],
        isSwarm: item.swarm,
        imageId: item.model.imageId ?? undefined,
        imageUrl: getUavImageUrl(getModelImageEndpoint, item.model.id, item.model.imageId),
        ownerName: item.ownerName,
        isShared: item.shared,
        isCustom: item.model.custom,
        isCeCompliant: item.model.ceCompliant,
    }));
}

export function convertUavResponseBodyToUav(response: UavResponseBody, getModelImageEndpoint: string): Uav {
    return {
        id: response.id,
        name: response.name,
        model: {
            id: response.model.id,
            name: response.model.name,
            manufacturer: response.model.manufacturer,
            type: response.model.type,
            isCustom: response.model.custom,
            imageId: response.model.photoId ?? undefined,
            imageUrl: getUavImageUrl(getModelImageEndpoint, response.model.id, response.model.photoId),
        },
        operator: response.operator,
        serialNumbers: response.serialNumbers.sort(),
        uavClasses: response.uavClasses ?? [],
        isSwarm: response.swarm,
        setups: response.setups.map((setupResponse) => convertUavSetupResponseBodyToUavSetup(setupResponse)),
        ownerName: response.ownerName,
        isShared: response.shared,
        isCustom: response.model.custom,
        isCeCompliant: response.model.ceCompliant,
        documents: prepareUavDocuments(response.model.manual, response.model.projectDocumentation),
    };
}

export function convertUavCapabilitiesResponseBodyAndTrackersToUavCapabilities(
    capabilities: UavCapabilitiesResponseBody,
    trackers: Tracker[]
): UavCapabilities {
    const navigationAccuracyItems: NavigationAccuracy[] = (
        Object.keys(capabilities.navigationAccuracyClasses) as NavigationAccuracyClass[]
    ).reduce(
        (result: NavigationAccuracy[], classValue) => [
            ...result,
            {
                classValue: classValue,
                type: NavigationAccuracyType.HorizontalFlight,
                horizontal: capabilities.navigationAccuracyClasses[classValue].flightNavigationAccuracyHorizontal,
                vertical: capabilities.navigationAccuracyClasses[classValue].flightNavigationAccuracyVertical,
            },
            {
                classValue: classValue,
                type: NavigationAccuracyType.TakeoffAndLanding,
                horizontal: capabilities.navigationAccuracyClasses[classValue].takeoffAndLandingNavigationAccuracyHorizontal,
                vertical: capabilities.navigationAccuracyClasses[classValue].takeoffAndLandingNavigationAccuracyVertical,
            },
        ],
        []
    );

    return {
        trackers,
        navigationAccuracyItems,
        canShareUavs: capabilities.canShareUavs,
    };
}

export function convertManufacturersResponseBodyToManufacturers(
    manufacturers: ManufacturersResponseBody,
    getModelImageEndpoint: string
): Manufacturer[] {
    return manufacturers.map((manufacturer) => ({
        ...manufacturer,
        models: manufacturer.models.map((model) => ({
            id: model.id,
            name: model.name,
            isCertificationRequired: model.certificationRequired,
            uavClasses: model.uavClasses ?? [],
            imageUrl: getUavImageUrl(getModelImageEndpoint, model.id, model.photoId),
        })),
    }));
}

export function convertUavModelPropertiesResponseBodyToUavModelProperties(
    uavModel: UavModelPropertiesResponseBody,
    getModelImageEndpoint: string,
    defaultCameraName: string
): UavModelProperties {
    return {
        id: uavModel.id,
        name: uavModel.name,
        manufacturer: uavModel.manufacturer,
        type: uavModel.type,
        uavClasses: uavModel.uavClasses ?? [],
        isCustom: uavModel.custom,
        imageId: uavModel.photoId ?? undefined,
        imageUrl: getUavImageUrl(getModelImageEndpoint, uavModel.id, uavModel.photoId),
        technicalSpecification: convertTechnicalSpecificationResponseBodyToTechnicalSpecification(uavModel.technicalSpecification),
        communications: uavModel.communications.map((communicationResponse) =>
            convertCommunicationResponseBodyToCommunication(communicationResponse)
        ),
        equipment: convertUavModelEquipmentResponseBodyToEquipment(uavModel.equipment, defaultCameraName),
    };
}

export function convertCommunicationsToCommunicationsRequestPayload(
    communications: Communication[],
    shouldSetId: boolean = false
): CommunicationsRequestPayload {
    return communications.map((communication) => ({
        id: shouldSetId ? communication.id ?? undefined : undefined,
        type: communication.type,
        transmissionLinkRange: communication.transmissionLinkRange,
        linkType: communication.linkType === CommunicationLinkType.Unknown ? null : communication.linkType,
        linkDelay: communication.linkDelay,
        modelOrigin: communication.isEmbedded,
        frequencies: communication.frequencies,
    }));
}

export function convertTrackingsToTrackingsRequestPayload(trackings: Tracking[]): TrackingsRequestPayload {
    return trackings.map((tracking) => ({
        id: tracking.id,
        identifier: tracking.identifier,
        weight: tracking.weight,
        trackerId: tracking.trackerId,
        flightNavigationAccuracy: tracking.flightNavigationAccuracy,
        takeoffAndLandingNavigationAccuracy: tracking.takeoffAndLandingNavigationAccuracy,
    }));
}

export function convertDocumentsToDocumentsRequestPayload(documents: UavSetupDocuments): {
    documents: Partial<Record<UavSetupDocumentType, string[]>>;
} {
    const result: Partial<Record<UavSetupDocumentType, string[]>> = {};

    for (const [type, files] of Object.entries(documents)) {
        if (!files.length) {
            continue;
        }

        result[type as UavSetupDocumentType] = files.map((file) => file.id);
    }

    return { documents: result };
}

export function convertEquipmentToEquipmentRequestPayload(equipment: Equipment): EquipmentRequestPayload {
    const payload: EquipmentRequestPayload = {
        parachute: null,
        items: [],
    };

    if (equipment.parachute.length) {
        payload.parachute = {
            id: equipment.parachute[0].id,
            name: equipment.parachute[0].name,
            weight: equipment.parachute[0].weight,
            modelOrigin: equipment.parachute[0].isEmbedded,
            ceCertificate: equipment.parachute[0].hasCeCertificate,
            minHeight: equipment.parachute[0].minHeight,
            descentSpeed: equipment.parachute[0].descentSpeed,
            minOperatingTemperature: equipment.parachute[0].minOperatingTemperature,
            maxOperatingTemperature: equipment.parachute[0].maxOperatingTemperature,
            astmF332218Compliant: equipment.parachute[0].isAstmF332218Compliant,
        };
    }

    payload.items.push(
        ...equipment.camera.map((camera) => ({
            id: camera.id,
            name: camera.name ?? "",
            type: EquipmentType.Camera,
            weight: camera.weight,
        })),
        ...equipment.propellersGuards.map((propellersGuard) => ({
            id: propellersGuard.id,
            name: propellersGuard.name ?? "",
            type: EquipmentType.PropellersGuards,
            weight: propellersGuard.weight,
        })),
        ...equipment.navigationLighting.map((navigationLighting) => ({
            id: navigationLighting.id,
            name: navigationLighting.name ?? "",
            type: EquipmentType.NavigationLighting,
            weight: navigationLighting.weight,
        })),
        ...equipment.strobeLighting.map((strobeLighting) => ({
            id: strobeLighting.id,
            name: strobeLighting.name ?? "",
            type: EquipmentType.StrobeLighting,
            weight: strobeLighting.weight,
        })),
        ...equipment.nightLighting.map((nightLighting) => ({
            id: nightLighting.id,
            name: nightLighting.name ?? "",
            type: EquipmentType.NightLighting,
            weight: nightLighting.weight,
        })),
        ...equipment.fts.map((fts) => ({
            id: fts.id,
            name: fts.name ?? "",
            type: EquipmentType.Fts,
            weight: fts.weight,
        }))
    );

    return payload;
}

export const convertUavCapabilitiesResponseBodyToCapabilitiesToShare = (model: CapabilitiesToShareResponseBody): CapabilitiesToShare => ({
    operators: model.operators,
    setups: model.setups.map((setup) => ({
        id: setup.id,
        name: setup.name,
        isPrimary: setup.primary,
    })),
});

export function convertFilesGroupsToUavDocumentsRequestPayload(documents: FilesGroup<UavModelDocumentType>[]): UavDocumentsRequestPayload {
    const manual = documents.find((document) => document.groupId === UavModelDocumentType.Manual)?.files?.[0]?.id;
    const projectDocumentation = documents.find((document) => document.groupId === UavModelDocumentType.ProjectDocumentation)?.files?.[0]
        ?.id;

    return {
        documents: {
            [UavModelDocumentType.Manual]: manual,
            [UavModelDocumentType.ProjectDocumentation]: projectDocumentation,
        },
    };
}
