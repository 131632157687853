export namespace PublicProfileActions {
    export class GetPublicProfilePilotData {
        public static readonly type = "[Public Profile] Get public profile pilot data";
        constructor(public pilotId: string) {}
    }

    export class GetPublicProfileOperatorData {
        public static readonly type = "[Public Profile] Get public profile operator data";
        constructor(public operatorId: string) {}
    }
}
