<ng-container *ngrxLet="importedOperatorDetails$ as importedOperatorDetails">
    <h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.summaryStep.operatorDetails.header" | transloco }}</h2>
    <div class="details-container">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.summaryStep.operatorDetails.operatorNumberLabel' | transloco"
            [value]="importedOperatorDetails.number"
        ></dtm-ui-label-value>
    </div>
    <dtm-web-app-lib-operator-migration-operational-authorizations
        *ngIf="importedOperatorDetails.operationalAuthorizations.length"
        [operationalAuthorizations]="importedOperatorDetails.operationalAuthorizations"
        class="operational-authorizations"
    >
    </dtm-web-app-lib-operator-migration-operational-authorizations>
</ng-container>
