import { ChangeDetectionStrategy, Component } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { MIN_PAGE_SIZE_VALUE, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import {
    TrainingExamResultsFilterFormKeys,
    TrainingExamResultsFilterParams,
    TrainingExamResultsFiltersTabKeys,
    TrainingExamResultsTabType,
} from "../../models/training-exam-results.models";
import { TrainingExamResultsActions } from "../../state/training-exam-results.actions";
import { TrainingExamResultsState } from "../../state/training-exam-results.state";

interface TrainingExamResultsContainerComponentState {
    [TrainingExamResultsFilterFormKeys.ActiveTabIndex]: TrainingExamResultsTabType;
    [TrainingExamResultsFiltersTabKeys.ExamResultsFilters]: TrainingExamResultsFilterParams;
    [TrainingExamResultsFiltersTabKeys.TrainingResultsFilters]: TrainingExamResultsFilterParams;
}

const DEFAULT_ACTIVE_TAB = TrainingExamResultsTabType.Trainings;
const DEFAULT_FILTERS_VALUE = {
    [TrainingExamResultsFilterFormKeys.SearchPhrase]: "",
    [PAGE_NUMBER_QUERY_PARAM]: 0,
    [PAGE_SIZE_QUERY_PARAM]: MIN_PAGE_SIZE_VALUE,
};

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-training-exam-results-container",
    templateUrl: "./training-exam-results-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TrainingExamResultsContainerComponent {
    protected readonly activeTabIndex$ = this.localStore.selectByKey(TrainingExamResultsFilterFormKeys.ActiveTabIndex);
    protected readonly trainingResultsFilters$ = this.localStore.selectByKey(TrainingExamResultsFiltersTabKeys.TrainingResultsFilters);
    protected readonly examResultsFilters$ = this.localStore.selectByKey(TrainingExamResultsFiltersTabKeys.ExamResultsFilters);
    protected readonly elearningPortalUrl$ = this.store.select(OperatorContextState.elearningPortalUrl);
    protected readonly trainingResultsList$ = this.store.select(TrainingExamResultsState.trainingResultsList);
    protected readonly examResultsList$ = this.store.select(TrainingExamResultsState.examResultsList);
    protected readonly trainingResultsPages$ = this.store.select(TrainingExamResultsState.trainingResultsPages);
    protected readonly examResultsPages$ = this.store.select(TrainingExamResultsState.examResultsPages);
    protected readonly trainingResultsListError$ = this.store.select(TrainingExamResultsState.trainingResultsListError);
    protected readonly examResultsListError$ = this.store.select(TrainingExamResultsState.examResultsListError);
    protected readonly isTrainingResultsListProcessing$ = this.store.select(TrainingExamResultsState.isTrainingResultsListProcessing);
    protected readonly isExamResultsListProcessing$ = this.store.select(TrainingExamResultsState.isExamResultsListProcessing);
    private readonly selectedOperatorContextId$ = this.store
        .select(OperatorContextState.selectedOperatorContextId)
        .pipe(RxjsUtils.filterFalsy());

    protected TrainingExamResultsTabType = TrainingExamResultsTabType;
    protected TrainingExamResultsFiltersTabKeys = TrainingExamResultsFiltersTabKeys;

    constructor(
        private readonly localStore: LocalComponentStore<TrainingExamResultsContainerComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store
    ) {
        const snapshotParams = this.route.snapshot.queryParams;
        const snapShotData = {
            [PAGE_NUMBER_QUERY_PARAM]: snapshotParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: snapshotParams[PAGE_SIZE_QUERY_PARAM] ?? MIN_PAGE_SIZE_VALUE,
            searchPhrase: snapshotParams.searchPhrase,
        };
        const snapshotTabIndex = +snapshotParams[TrainingExamResultsFilterFormKeys.ActiveTabIndex];

        this.localStore.setState({
            [TrainingExamResultsFiltersTabKeys.ExamResultsFilters]:
                snapshotTabIndex === TrainingExamResultsTabType.Exams ? snapShotData : DEFAULT_FILTERS_VALUE,
            [TrainingExamResultsFiltersTabKeys.TrainingResultsFilters]:
                snapshotTabIndex === TrainingExamResultsTabType.Trainings ? snapShotData : DEFAULT_FILTERS_VALUE,
            [TrainingExamResultsFilterFormKeys.ActiveTabIndex]: snapshotTabIndex,
        });

        this.watchSelectedContextAndTabChange();
    }

    protected updateTabIndex(activeTabIndex: TrainingExamResultsTabType): void {
        this.localStore.patchState({ activeTabIndex });
        this.applyFilters(activeTabIndex);
    }

    protected changeResultsPage({ pageIndex, pageSize }: PageEvent, filterTabKey: TrainingExamResultsFiltersTabKeys): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(TrainingExamResultsFilterFormKeys.ActiveTabIndex);
        const previousFilters = this.localStore.selectSnapshotByKey(filterTabKey);
        this.localStore.patchState({
            [filterTabKey]: {
                ...previousFilters,
                [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
                [PAGE_SIZE_QUERY_PARAM]: pageSize,
            },
        });

        this.applyFilters(activeTabIndex);
    }

    protected navigateByParams(filters: Partial<TrainingExamResultsFilterParams>, index: TrainingExamResultsTabType): void {
        const activeTabIndex = this.localStore.selectSnapshotByKey(TrainingExamResultsFilterFormKeys.ActiveTabIndex) ?? DEFAULT_ACTIVE_TAB;

        if (activeTabIndex !== index) {
            return;
        }

        const key = this.getFilterKeyByIndex(index);
        const previousFilterState = this.localStore.selectSnapshotByKey(key);
        this.localStore.patchState({ [key]: { ...previousFilterState, ...filters } });
        this.applyFilters(activeTabIndex);
    }

    protected getTrainingResultsList(): void {
        const trainingCentersFilters = this.localStore.selectSnapshotByKey(TrainingExamResultsFiltersTabKeys.TrainingResultsFilters);
        const contextId = this.store.selectSnapshot(OperatorContextState.selectedContextId) as string;
        this.store.dispatch(new TrainingExamResultsActions.GetTrainingResultsList(trainingCentersFilters, contextId));
    }

    protected getExamResultsList(): void {
        const examinationCentersFilters = this.localStore.selectSnapshotByKey(TrainingExamResultsFiltersTabKeys.ExamResultsFilters);
        const contextId = this.store.selectSnapshot(OperatorContextState.selectedContextId) as string;
        this.store.dispatch(new TrainingExamResultsActions.GetExamResultsList(examinationCentersFilters, contextId));
    }

    private applyFilters(activeTabIndex: TrainingExamResultsTabType = DEFAULT_ACTIVE_TAB): void {
        const filters = this.localStore.selectSnapshotByKey(this.getFilterKeyByIndex(+activeTabIndex));
        let params: TrainingExamResultsFilterParams = {
            [TrainingExamResultsFilterFormKeys.ActiveTabIndex]: activeTabIndex,
            [PAGE_NUMBER_QUERY_PARAM]: filters[PAGE_NUMBER_QUERY_PARAM],
            [PAGE_SIZE_QUERY_PARAM]: filters[PAGE_SIZE_QUERY_PARAM],
        };

        if (filters[TrainingExamResultsFilterFormKeys.SearchPhrase]) {
            params = {
                ...params,
                [TrainingExamResultsFilterFormKeys.SearchPhrase]: filters[TrainingExamResultsFilterFormKeys.SearchPhrase],
            };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private getFilterKeyByIndex(tabIndex: TrainingExamResultsTabType): TrainingExamResultsFiltersTabKeys {
        switch (tabIndex) {
            case TrainingExamResultsTabType.Trainings:
                return TrainingExamResultsFiltersTabKeys.TrainingResultsFilters;
            case TrainingExamResultsTabType.Exams:
                return TrainingExamResultsFiltersTabKeys.ExamResultsFilters;
        }
    }

    private refreshData(activeTabIndex: TrainingExamResultsTabType) {
        if (activeTabIndex === TrainingExamResultsTabType.Trainings) {
            this.getTrainingResultsList();

            return;
        }

        this.getExamResultsList();
    }

    private watchSelectedContextAndTabChange(): void {
        combineLatest([this.selectedOperatorContextId$, this.route.queryParams])
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe(([, queryParams]) => {
                const index = queryParams?.[TrainingExamResultsFilterFormKeys.ActiveTabIndex];
                const activeTabIndex = !index ? DEFAULT_ACTIVE_TAB : +index;
                this.updateTabIndex(activeTabIndex);
                this.refreshData(activeTabIndex);
            });
    }
}
