import { HttpClient, HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { OPERATIONS_MANUAL_ENDPOINTS, OperationsManualEndpoints } from "../operations-manual.tokens";
import { OperationsManualState } from "../state/operations-manual.state";

export interface AdditionalPathParams extends Record<string, string> {
    operatorId: string;
    operationsManualId: string;
    chapterId: string;
}

@Injectable()
export class ChapterAttachmentUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store,
        @Inject(OPERATIONS_MANUAL_ENDPOINTS) private readonly endpoints: OperationsManualEndpoints
    ) {}

    public uploadFile(file: File, additionalPathParams?: Partial<AdditionalPathParams>): Observable<HttpEvent<UploadedFileBody>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(
                StringUtils.replaceInTemplate(this.endpoints.uploadAttachment, this.getAdditionalPathParams(additionalPathParams)),
                formData,
                {
                    reportProgress: true,
                    responseType: "json",
                    observe: "events",
                }
            )
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }

    public getFile(fileId: string, additionalPathParams?: Partial<AdditionalPathParams>) {
        return this.httpClient.get(
            StringUtils.replaceInTemplate(this.endpoints.getAttachment, {
                ...this.getAdditionalPathParams(additionalPathParams),
                attachmentId: fileId,
            }),
            {
                responseType: "blob",
            }
        );
    }

    public getFilesCompressed() {
        console.error("Not implemented");

        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }

    private getAdditionalPathParams(additionalPathParams: Partial<AdditionalPathParams> = {}): AdditionalPathParams {
        return {
            operatorId: this.store.selectSnapshot(OperatorContextState.selectedContextId) ?? "",
            operationsManualId: this.store.selectSnapshot(OperationsManualState.currentOperationsManual)?.id ?? "",
            chapterId: "",
            ...additionalPathParams,
        };
    }
}
