import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import {
    AnimationUtils,
    DEFAULT_COMPANY_NUMBER_LENGTH,
    DEFAULT_COMPANY_NUMBER_MASK,
    DEFAULT_COUNTRY_CODE,
    DEFAULT_PHONE_COUNTRY_CODE,
    LocalComponentStore,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
    POST_CODE_MASK,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { AssociationOperatorDetails, AssociationRegistrationIdType } from "../../../shared/models/pilot-and-operator.models";
import {
    MAX_APARTMENT_NUMBER_LENGTH,
    MAX_CITY_LENGTH,
    MAX_HOUSE_NUMBER_LENGTH,
    MAX_STREET_LENGTH,
} from "../../../shared/utils/forms/address-controls-limits";
import { optionalDefaultCountryCompanyNumberValidator } from "../../../shared/validators/optional-default-country-company-number.validator";
import { CompanyInfo, Operator, OperatorAssociationRegistration } from "../../models/operator.models";
import { MAX_COMPANY_NAME_LENGTH } from "../../utils/form-controls-limits";

interface OperatorCompanyInfoComponentState {
    operator: Operator | undefined;
    isEditModeOn: boolean;
    canManageOperator: boolean;
}

interface CompanyInfoForm {
    name: FormControl<string>;
    companyNumber: FormControl<string>;
    streetName: FormControl<string>;
    houseNumber: FormControl<string>;
    apartmentNumber: FormControl<string | null>;
    postCode: FormControl<string>;
    city: FormControl<string>;
    email: FormControl<string>;
    phoneNumber: FormControl<PhoneNumber>;
    associationId: FormControl<AssociationOperatorDetails | null>;
}

@Component({
    selector: "dtm-web-app-lib-operator-company-info[operator]",
    templateUrl: "./operator-company-info.component.html",
    styleUrls: ["./operator-company-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class OperatorCompanyInfoComponent {
    @Input() public set operator(value: Operator | undefined) {
        this.localStore.patchState({ operator: value, isEditModeOn: false });

        if (value?.isAssociation) {
            this.companyInfoFormGroup.controls.companyNumber.addValidators(this.companyNumberOptionalValidator);
            this.companyInfoFormGroup.controls.associationId.enable();
        } else {
            this.companyInfoFormGroup.controls.companyNumber.addValidators(this.companyNumberRequiredValidator);
            this.companyInfoFormGroup.controls.associationId.disable();
        }
    }

    @Input() public set canManageOperator(value: BooleanInput) {
        this.localStore.patchState({ canManageOperator: coerceBooleanProperty(value) });
    }

    @Output() public save = new EventEmitter<CompanyInfo>();

    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly operator$ = this.localStore.selectByKey("operator").pipe(RxjsUtils.filterFalsy());
    protected readonly isEditModeOn$ = this.localStore.selectByKey("isEditModeOn");
    protected readonly canManageOperator$ = this.localStore.selectByKey("canManageOperator");
    protected readonly companyInfoFormGroup = new FormGroup<CompanyInfoForm>({
        companyNumber: new FormControl<string>("", {
            nonNullable: true,
        }),
        email: new FormControl<string>("", {
            validators: [Validators.required, Validators.email],
            nonNullable: true,
        }),
        phoneNumber: new FormControl<PhoneNumber>(
            { countryCode: DEFAULT_PHONE_COUNTRY_CODE, number: "" },
            {
                validators: requiredValidForSmsPhoneNumberValidator,
                nonNullable: true,
            }
        ),
        name: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_COMPANY_NAME_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        streetName: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_STREET_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        houseNumber: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_HOUSE_NUMBER_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        apartmentNumber: new FormControl("", { validators: Validators.maxLength(MAX_APARTMENT_NUMBER_LENGTH), nonNullable: true }),
        postCode: new FormControl("", { validators: Validators.required, nonNullable: true }),
        city: new FormControl("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_CITY_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
        associationId: new FormControl<AssociationOperatorDetails | null>(null, Validators.required),
    });
    protected readonly POST_CODE_MASK = POST_CODE_MASK;
    protected readonly COMPANY_NUMBER_MASK = DEFAULT_COMPANY_NUMBER_MASK;
    private readonly companyNumberRequiredValidator = [
        Validators.required,
        Validators.minLength(DEFAULT_COMPANY_NUMBER_LENGTH),
        Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
    ];
    private readonly companyNumberOptionalValidator = optionalDefaultCountryCompanyNumberValidator;

    constructor(private readonly localStore: LocalComponentStore<OperatorCompanyInfoComponentState>) {
        this.localStore.setState({
            operator: undefined,
            isEditModeOn: false,
            canManageOperator: false,
        });
    }

    protected saveForm(): void {
        if (this.companyInfoFormGroup.invalid) {
            this.companyInfoFormGroup.markAllAsTouched();

            return;
        }

        this.emitFormValue();
    }

    private emitFormValue(): void {
        const operatorCountry = this.localStore.selectSnapshotByKey("operator")?.address.country;

        if (!operatorCountry) {
            return;
        }

        const { name, companyNumber, email, phoneNumber, ...companyAddress } = this.companyInfoFormGroup.getRawValue();
        const companyInfo: CompanyInfo = {
            name,
            email,
            phoneNumber,
            companyNumber: companyNumber ?? undefined,
            address: {
                ...companyAddress,
                country: operatorCountry,
            },
            associationRegistration: this.getAssociationRegistrationId(this.companyInfoFormGroup.controls.associationId.value),
        };

        this.save.emit(companyInfo);
    }

    protected manageFormState(isEditModeOn: boolean): void {
        const operator = this.localStore.selectSnapshotByKey("operator");
        this.localStore.patchState({ isEditModeOn });

        if (!isEditModeOn) {
            this.companyInfoFormGroup.reset();

            return;
        }

        if (!operator) {
            return;
        }

        if (operator.isAssociation) {
            this.companyInfoFormGroup.patchValue({
                associationId: {
                    associationRegistrationId:
                        operator.associationRegistration?.registrationNumber ?? operator.associationRegistration?.nationalCourtRegister,
                    associationRegistrationIdType: operator.associationRegistration?.registrationNumber
                        ? AssociationRegistrationIdType.RegistrationNumber
                        : AssociationRegistrationIdType.NationalCourtRegister,
                },
            });
        }

        this.companyInfoFormGroup.patchValue({
            ...operator.address,
            name: operator.name,
            email: operator.email,
            phoneNumber: operator.phoneNumber,
            companyNumber: operator.companyNumber ?? "",
        });
    }

    private getAssociationRegistrationId(
        associationRegistrationFormValue: AssociationOperatorDetails | null
    ): OperatorAssociationRegistration | undefined {
        if (!associationRegistrationFormValue?.associationRegistrationId) {
            return;
        }

        if (associationRegistrationFormValue.associationRegistrationIdType === AssociationRegistrationIdType.RegistrationNumber) {
            return {
                registrationNumber: associationRegistrationFormValue.associationRegistrationId,
            };
        }

        return { nationalCourtRegister: associationRegistrationFormValue.associationRegistrationId };
    }
}
