import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface DetachedSerialNumbersComponentState {
    detachedSerialNumbers: string[];
}

@Component({
    selector: "dtm-web-app-lib-detached-serial-numbers[detachedSerialNumbers]",
    templateUrl: "./detached-serial-numbers.component.html",
    styleUrls: ["./detached-serial-numbers.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DetachedSerialNumbersComponent {
    @Input() public set detachedSerialNumbers(value: string[] | undefined) {
        this.localStore.patchState({ detachedSerialNumbers: value ?? [] });
    }

    protected readonly detachedSerialNumbers$ = this.localStore.selectByKey("detachedSerialNumbers").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<DetachedSerialNumbersComponentState>) {
        localStore.setState({ detachedSerialNumbers: [] });
    }
}
