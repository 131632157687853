import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TrainingExamResultsFilterFormKeys, TrainingExamResultsTabType } from "../../models/training-exam-results.models";

interface TrainingExamResultsHeaderComponentState {
    activeTabIndex: TrainingExamResultsTabType | undefined;
    elearningPortalUrl: string | undefined;
}

@Component({
    selector: "dtm-web-app-lib-training-exam-results-header[activeTabIndex][elearningPortalUrl]",
    templateUrl: "./training-exam-results-header.component.html",
    styleUrls: ["./training-exam-results-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TrainingExamResultsHeaderComponent {
    @Input() public set activeTabIndex(value: TrainingExamResultsTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value || TrainingExamResultsTabType.Trainings });
    }
    @Input() public set elearningPortalUrl(value: string | undefined) {
        this.localStore.patchState({ elearningPortalUrl: value });
    }
    @Output() public readonly tabIndexUpdate = new EventEmitter<TrainingExamResultsTabType>();

    protected readonly activeTabIndex$ = this.localStore.selectByKey(TrainingExamResultsFilterFormKeys.ActiveTabIndex);
    protected readonly elearningPortalUrl$ = this.localStore.selectByKey("elearningPortalUrl").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<TrainingExamResultsHeaderComponentState>) {
        this.localStore.setState({
            activeTabIndex: undefined,
            elearningPortalUrl: undefined,
        });
    }

    protected onUpdateTabIndex(activeTabIndex: TrainingExamResultsTabType): void {
        this.localStore.patchState({ activeTabIndex });
        this.tabIndexUpdate.next(activeTabIndex);
    }
}
