import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { MembershipActions } from "../state/membership.actions";

@Injectable()
export class InvitationAcceptanceResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot) {
        return await lastValueFrom(this.store.dispatch([new MembershipActions.GetInvitationDetails(route.params.id)]));
    }
}
