<mat-card *ngIf="uav$ | ngrxPush as uav" class="no-box-shadow">
    <div class="header">
        <mat-card-title class="title" #uavTitle [matTooltip]="uav.name" [matTooltipDisabled]="isUavTitleTooltipDisabled$ | ngrxPush">
            {{ uav.name }}
        </mat-card-title>
        <span *ngIf="uav.isCustom" class="chip light">
            <dtm-ui-icon name="pencil-ruler"></dtm-ui-icon>
            {{ "dtmWebAppLibUav.uavList.customUavLabel" | transloco }}
        </span>
        <span *ngIf="uav.isSwarm" class="chip light">
            <dtm-ui-icon name="drone-swarm"></dtm-ui-icon>
            {{ "dtmWebAppLibUav.uavList.swarmLabel" | transloco : { amount: uav.serialNumbers.length } }}
        </span>
        <button [matMenuTriggerFor]="uavMenu" class="button-icon menu-button" type="button" (click)="$event.stopImmediatePropagation()">
            <dtm-ui-icon name="more"></dtm-ui-icon>
        </button>
        <mat-menu #uavMenu="matMenu" xPosition="before">
            <ng-container *ngrxLet="canManageUavs$ as canManageUavs">
                <button mat-menu-item (click)="showDetails.emit(uav)" type="button">
                    <dtm-ui-icon name="information"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavList.uavActions.details" | transloco }}</span>
                </button>
                <button mat-menu-item *ngIf="canManageUavs" (click)="edit.emit(uav)" type="button">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavList.uavActions.editUav" | transloco }}</span>
                </button>
                <button
                    *ngIf="!uav.isShared && canManageUavs && (canShareUav$ | ngrxPush)"
                    mat-menu-item
                    (click)="share.emit(uav)"
                    type="button"
                >
                    <dtm-ui-icon name="share"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavList.uavActions.share" | transloco }}</span>
                </button>
                <button mat-menu-item *ngIf="canManageUavs" (click)="tryToRemoveUav(uav)" type="button">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavList.uavActions.delete" | transloco }}</span>
                </button>
            </ng-container>
        </mat-menu>
    </div>
    <mat-card-content>
        <dtm-ui-label-value [label]="'dtmWebAppLibUav.uavList.manufacturerAndModelLabel' | transloco">
            <p
                class="limited-height"
                #manufacturerModel
                [matTooltip]="uav.manufacturerName + ' ' + uav.modelName"
                [matTooltipDisabled]="isManufacturerModelTooltipDisabled$ | ngrxPush"
            >
                {{ uav.manufacturerName + " " + uav.modelName }}
            </p>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibUav.uavList.uavTypeLabel' | transloco"
            [value]="'dtmWebAppLibUav.uavTypeValue' | transloco : { value: uav.modelType }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="!uav.isSwarm"
            [label]="'dtmWebAppLibUav.uavList.serialNumberLabel' | transloco"
            [value]="uav.serialNumbers[0]"
            [canCopy]="true"
            (click)="$event.stopImmediatePropagation()"
        ></dtm-ui-label-value>
        <dtm-ui-label-value *ngIf="uav.isSwarm" [label]="'dtmWebAppLibUav.uavList.serialNumbersLabel' | transloco">
            <a class="serial-numbers-button" (click)="showSerialNumbers(uav); $event.stopImmediatePropagation()">
                {{ "dtmWebAppLibUav.uavList.showSerialNumbersButtonLabel" | transloco }}
            </a>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmWebAppLibUav.uavList.uavClassLabel' | transloco">
            <ng-container *ngIf="!uav.uavClasses.length">{{ "dtmWebAppLibUav.uavList.noUavClassesLabel" | transloco }}</ng-container>
            <span class="uav-class" *ngFor="let uavClass of uav.uavClasses">{{
                "dtmWebAppLibUav.uavClassValue" | transloco : { value: uavClass }
            }}</span>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="isOwnerVisible$ | ngrxPush"
            [label]="'dtmWebAppLibUav.uavList.ownerLabel' | transloco"
            [value]="uav.ownerName"
        ></dtm-ui-label-value>
    </mat-card-content>
    <div class="uav-image">
        <img *ngIf="uav.imageUrl" [dtmUiImgSecuredSrc]="uav.imageUrl" [alt]="uav.manufacturerName + ' ' + uav.modelName" />
        <img *ngIf="!uav.imageUrl" src="assets/images/uav-no-photo.svg" alt="" />
    </div>
</mat-card>
