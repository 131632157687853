import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { AdditionalCrew, CrewCompetencies, CrewMember, OperationScenario } from "../../../services/specific-permit-application.models";

interface CrewCompetenciesComponentState {
    availableOperationScenarios: OperationScenario[];
    additionalCrewMembers: CrewMember[];
    isExpanded: boolean;
    isEditMode: boolean;
    currentCompetencies: CrewCompetencies;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-crew-competencies[availableOperationScenarios][additionalCrewMembers]",
    templateUrl: "./crew-competencies.component.html",
    styleUrls: ["./crew-competencies.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CrewCompetenciesComponent), multi: true }],
})
export class CrewCompetenciesComponent implements ControlValueAccessor {
    @Input() public set availableOperationScenarios(value: OperationScenario[] | undefined) {
        this.localStore.patchState({ availableOperationScenarios: value ?? [] });
    }

    @Input() public set additionalCrewMembers(value: AdditionalCrew | null | undefined) {
        const additionalCrewMembers = (value ?? []).map((member) => member.member);
        const currentCompetencies = this.localStore.selectSnapshotByKey("currentCompetencies");
        const newCurrentCompetencies = this.prepareCrewCompetencies(additionalCrewMembers, currentCompetencies);

        this.localStore.patchState({ additionalCrewMembers, currentCompetencies: newCurrentCompetencies });

        this.crewCompetenciesForm.setValue({ competencies: newCurrentCompetencies }, { emitEvent: false });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set value(value: CrewCompetencies) {
        this.localStore.patchState({ currentCompetencies: value });
    }

    protected readonly availableOperationScenarios$ = this.localStore.selectByKey("availableOperationScenarios");
    protected readonly currentCompetencies$ = this.localStore.selectByKey("currentCompetencies");
    protected readonly isCompetenciesSetEmpty$ = this.currentCompetencies$.pipe(
        map((currentCompetencies) => !Object.values(currentCompetencies).some((competencies) => competencies !== null))
    );
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    protected readonly crewCompetenciesForm = new FormGroup<{ competencies: FormControl<CrewCompetencies> }>({
        competencies: new FormControl<CrewCompetencies>(
            {},
            {
                nonNullable: true,
            }
        ),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: CrewCompetencies) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<CrewCompetenciesComponentState>) {
        this.localStore.setState({
            availableOperationScenarios: [],
            additionalCrewMembers: [],
            isExpanded: true,
            isEditMode: false,
            currentCompetencies: {},
        });
    }

    public registerOnChange(fn: (value: CrewCompetencies) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: CrewCompetencies): void {
        const additionalCrewMembers = this.localStore.selectSnapshotByKey("additionalCrewMembers");
        if (value) {
            this.crewCompetenciesForm.setValue(
                { competencies: this.prepareCrewCompetencies(additionalCrewMembers, value) },
                { emitEvent: false }
            );
        }

        this.persistCurrentCompetencies(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.crewCompetenciesForm.disable();
        } else {
            this.crewCompetenciesForm.enable();
        }
    }

    protected setEditMode(isEditMode: boolean): void {
        this.localStore.patchState(({ isExpanded }) => ({ isEditMode, isExpanded: isEditMode ? true : isExpanded }));
    }

    protected cancel(): void {
        const currentCompetencies = this.localStore.selectSnapshotByKey("currentCompetencies");
        const additionalCrewMembers = this.localStore.selectSnapshotByKey("additionalCrewMembers");
        this.crewCompetenciesForm.reset(
            { competencies: this.prepareCrewCompetencies(additionalCrewMembers, currentCompetencies) },
            { emitEvent: false }
        );
        this.setEditMode(false);
    }

    protected save(): void {
        this.crewCompetenciesForm.markAllAsTouched();

        if (this.crewCompetenciesForm.invalid) {
            return;
        }

        const currentCompetencies = this.crewCompetenciesForm.controls.competencies.getRawValue();
        this.persistCurrentCompetencies(currentCompetencies);
        this.propagateChange(currentCompetencies);
        this.setEditMode(false);
    }

    private persistCurrentCompetencies(value: CrewCompetencies): void {
        this.localStore.patchState({
            currentCompetencies: value ? value : {},
        });
    }

    private prepareCrewCompetencies(crewMembers: CrewMember[], value: CrewCompetencies): CrewCompetencies {
        if (!crewMembers.length) {
            return {};
        }

        const result: CrewCompetencies = {};

        for (const crewMember of crewMembers) {
            result[crewMember] = value[crewMember] ? value[crewMember] : null;
        }

        return result;
    }
}
