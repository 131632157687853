export function getImportedPostCodeFormValue(importedPostCode: string) {
    const defaultCountryPostCodeRegexp = /^([0-9]{5})$/;
    const defaultCountryPostCodeWithHyphen = /^([0-9]{2}-[0-9]{3})$/;

    if (defaultCountryPostCodeRegexp.test(importedPostCode)) {
        return importedPostCode;
    }

    if (defaultCountryPostCodeWithHyphen.test(importedPostCode)) {
        return importedPostCode.replace("-", "");
    }

    return "";
}
