<div class="content">
    <h2 mat-dialog-title>{{ "dtmWebAppLibShared.connectPansaUtmLinkDialog.header" | transloco }}</h2>
    <p>{{ "dtmWebAppLibShared.connectPansaUtmLinkDialog.message" | transloco }}</p>
</div>

<mat-dialog-actions>
    <button type="button" [mat-dialog-close]="false" class="button-secondary">
        {{ "dtmWebAppLibShared.connectPansaUtmLinkDialog.discardLinkLabel" | transloco }}
    </button>

    <div class="main-actions">
        <a class="button-secondary" [href]="pansaUtmUrl" target="_blank" rel="noopener norefer">
            {{ "dtmWebAppLibShared.connectPansaUtmLinkDialog.registerLinkLabel" | transloco }}
        </a>
        <button type="button" class="button-primary" [mat-dialog-close]="true" cdkFocusInitial>
            <dtm-ui-icon name="refresh"></dtm-ui-icon>
            {{ "dtmWebAppLibShared.connectPansaUtmLinkDialog.linkAccountButtonLabel" | transloco }}
        </button>
    </div>
</mat-dialog-actions>
