import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import {
    ConfirmationDialogComponent,
    DeviceSize,
    DeviceSizeService,
    DialogService,
    EmptyStateMode,
    FILES_UPLOAD_API_PROVIDER,
    ReceivedMessage,
    getAttachmentIdsList,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { ToastrService } from "ngx-toastr";
import { combineLatest, filter, firstValueFrom, lastValueFrom, switchMap } from "rxjs";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import { OperatorContextState } from "../../../shared";
import { OperatorConversationsFileUploadApiService } from "../../services/operator-conversations-file-upload-api.service";
import { AddNewMessage, ConversationFilterFormKeys, OperatorConversationFilters } from "../../services/operator-conversations.model";
import { OperatorConversationsActions } from "../../state/operator-conversations.actions";
import { OperatorConversationsState } from "../../state/operator-conversations.state";
import { NewThreadComponent } from "../new-thread/new-thread.component";

interface OperatorConversationsComponentState {
    isMessageEditorVisible: boolean;
    currentFilters: OperatorConversationFilters;
    areFiltersApplied: boolean;
    isAttachmentsControlVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-conversations",
    templateUrl: "./operator-conversations.component.html",
    styleUrls: ["./operator-conversations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: OperatorConversationsFileUploadApiService }],
})
export class OperatorConversationsComponent implements OnInit, OnDestroy {
    protected readonly threadsList$ = this.store.select(OperatorConversationsState.threadsList).pipe(RxjsUtils.filterFalsy());
    protected readonly categories$ = this.store.select(OperatorConversationsState.conversationCategories);
    protected readonly messagesList$ = this.store.select(OperatorConversationsState.messagesList);
    protected readonly messagesListError$ = this.store.select(OperatorConversationsState.messagesListError);
    protected readonly isProcessing$ = combineLatest([
        this.store.select(OperatorConversationsState.isThreadsProcessing),
        this.store.select(OperatorConversationsState.isMessagesProcessing),
    ]).pipe(map(([isThreadsListProcessing, isMessagesListProcessing]) => isMessagesListProcessing || isThreadsListProcessing));
    protected readonly threadsListError$ = this.store.select(OperatorConversationsState.threadsListError);
    protected readonly storedAvatarPictures$ = this.store.select(OperatorConversationsState.storedAvatarPictures);
    protected readonly currentFilters$ = this.localStore.selectByKey("currentFilters").pipe(RxjsUtils.filterFalsy());
    protected readonly selectedThread$ = combineLatest([
        this.threadsList$.pipe(RxjsUtils.filterFalsy()),
        this.route.queryParams.pipe(map((params) => params.threadId)),
    ]).pipe(map(([list, threadId]) => list.find((item) => item.id === threadId)));
    protected readonly isMessageEditorVisible$ = this.localStore.selectByKey("isMessageEditorVisible");
    protected readonly areFiltersApplied$ = this.localStore.selectByKey("areFiltersApplied");
    protected readonly isAttachmentsControlVisible$ = this.localStore.selectByKey("isAttachmentsControlVisible");
    protected readonly isSmartphone$ = this.deviceSizeService.getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide);

    protected readonly editorControl = new UntypedFormControl(null);
    protected readonly conversationForm = new UntypedFormGroup({
        editor: this.editorControl,
    });
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<OperatorConversationsComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        localStore.setState({
            isMessageEditorVisible: false,
            currentFilters: {
                searchByText: "",
                categories: [],
            },
            areFiltersApplied: false,
            isAttachmentsControlVisible: false,
        });
    }

    public ngOnInit() {
        this.initOperatorCapabilities();

        this.router.events
            .pipe(
                filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.clearAndHideForm();
                this.getThreadsList();
                this.getMessages(this.route.snapshot.queryParams.threadId);
            });
    }

    public ngOnDestroy() {
        this.store.dispatch(new OperatorConversationsActions.ClearConversations());
    }

    public changeThread(threadId: string) {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { threadId },
            queryParamsHandling: "merge",
        });
    }

    public refreshThreadsList() {
        this.getThreadsList();
    }

    public refreshMessagesList() {
        const threadId = this.route.snapshot.queryParams.threadId;

        if (!threadId) {
            return;
        }

        this.getMessages(threadId);
    }

    public openNewThreadContainer() {
        this.dialogService.open(NewThreadComponent, {
            hasBackdrop: true,
            disableClose: true,
            closeOnNavigation: true,
            panelClass: "conversation-sheet",
        });
    }

    protected changeAttachmentControlVisibility() {
        this.localStore.patchState({ isAttachmentsControlVisible: true });
    }

    public changeEditorVisibility(isVisible: boolean) {
        if (!isVisible && this.conversationForm.touched) {
            this.confirmAndClose();

            return;
        }

        this.localStore.patchState({ isMessageEditorVisible: isVisible });
    }

    public async sendMessage(receivedMessage?: ReceivedMessage) {
        if (!receivedMessage) {
            Logger.captureMessage("OperatorConversations.sendMessage: no received message selected", {
                level: "warning",
            });

            return;
        }

        this.conversationForm.markAllAsTouched();

        if (this.conversationForm.invalid) {
            return;
        }

        const operatorDetails = await lastValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
        );

        const attachments = this.conversationForm.controls.editor.value.attachments;
        const messageToSend: AddNewMessage = {
            content: this.conversationForm.controls.editor.value.content,
            subject: receivedMessage.subject,
            sender: {
                id: operatorDetails.id,
                name: operatorDetails.name,
            },
            attachmentIdsList: getAttachmentIdsList(attachments),
            threadId: receivedMessage.threadId,
        };

        this.store
            .dispatch(new OperatorConversationsActions.AddNewMessageToThread(messageToSend))
            .pipe(
                switchMap(() => this.store.select(OperatorConversationsState.sendMessageStatusError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((sendMessageStatusError) =>
                sendMessageStatusError ? this.handleSendMessageError() : this.handleSendMessageSuccess(messageToSend)
            );
    }

    public handleFiltersAppliedChange(areFiltersApplied: boolean) {
        this.localStore.patchState({ areFiltersApplied });
    }

    public applyFiltersFormForm(filters: OperatorConversationFilters) {
        this.localStore.patchState({
            currentFilters: filters,
        });
        this.getThreadsList();
    }

    private async getThreadsList() {
        const selectedOperatorContextId = await firstValueFrom(
            this.store.select(OperatorContextState.selectedOperatorContextId).pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );

        this.store.dispatch(
            new OperatorConversationsActions.GetThreadsList({
                selectedOperatorContextId,
                [ConversationFilterFormKeys.SearchByText]:
                    this.localStore.selectSnapshotByKey("currentFilters")[ConversationFilterFormKeys.SearchByText] ?? "",
                [ConversationFilterFormKeys.Categories]: this.localStore
                    .selectSnapshotByKey("currentFilters")
                    [ConversationFilterFormKeys.Categories]?.join(","),
            })
        );
    }

    private getMessages(threadId: string) {
        this.store.dispatch(new OperatorConversationsActions.GetMessagesByThread(threadId));
    }

    private initOperatorCapabilities() {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy(), distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((selectedContext) => {
                this.store.dispatch(new OperatorConversationsActions.ClearConversations());

                this.store.dispatch(new OperatorConversationsActions.GetOperatorMessageCapabilities(selectedContext.id));
                this.router.navigate(["."], {
                    relativeTo: this.route,
                    queryParams: { operatorContext: selectedContext.id },
                    queryParamsHandling: "merge",
                });
            });
    }

    private confirmAndClose() {
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageTitleText"
                ),
                confirmationText: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageConfirmText"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageCancelLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibOperatorConversations.operatorConversationsContainer.declineMessageConfirmLabel"
                ),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }
                this.clearAndHideForm();
            });
    }

    private clearAndHideForm() {
        this.conversationForm.reset();
        this.localStore.patchState({ isMessageEditorVisible: false, isAttachmentsControlVisible: false });
    }

    private handleSendMessageSuccess(newMessage: AddNewMessage) {
        this.toastrService.success(
            this.translocoService.translate("dtmWebAppLibOperatorConversations.operatorConversationsContainer.messageSendSuccessMessage")
        );
        this.clearAndHideForm();
        this.store.dispatch(new OperatorConversationsActions.GetMessagesByThread(newMessage.threadId));
    }

    private handleSendMessageError() {
        this.toastrService.error(
            this.translocoService.translate("dtmWebAppLibOperatorConversations.operatorConversationsContainer.messageSendErrorMessage")
        );
    }
}
