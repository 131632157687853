import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NationalNodeUserUnverifiedGuard } from "../../guards/national-node-user-unverified.guard";
import { NationalNodeUserVerifiedGuard } from "../../guards/national-node-user-verified.guard";
import { NationalNodeUserProfileComponent } from "./components/national-node-user-profile/national-node-user-profile.component";
import { UserProfileContainerComponent } from "./components/user-profile-container/user-profile-container.component";
import { NationalNodeUserResolver } from "./services/national-node-user.resolver";

const routes: Routes = [
    {
        path: "user",
        component: UserProfileContainerComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard],
        data: {
            titleTranslationKey: "dtmWebAppUserProfile.routeTitle",
        },
    },
    {
        path: "national-node-user",
        component: NationalNodeUserProfileComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserUnverifiedGuard],
        resolve: {
            init: NationalNodeUserResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppUserProfile.routeTitle",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [NationalNodeUserResolver],
})
export class UserProfileRoutingModule {}
