import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MIDDLE_PAGE_SIZE_VALUE, OperatorType, PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextActions } from "../../../../shared/operator-context/state/operator-context.actions";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { MembershipMissionListQueryParams, MembershipMissionsFilter } from "../../../services/membership.models";
import { MembershipActions } from "../../../state/membership.actions";
import { MembershipState } from "../../../state/membership.state";

interface MembershipOperatorProfileComponentState {
    filtersQuery: MembershipMissionsFilter;
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-membership-operator-profile",
    templateUrl: "./membership-operator-profile.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembershipOperatorProfileComponent implements OnInit {
    protected readonly missionsList$ = this.store.select(MembershipState.missionsList);
    protected readonly missionsListError$ = this.store.select(MembershipState.missionsListError);
    protected readonly isMissionsProcessing$ = this.store.select(MembershipState.isMissionsProcessing);
    protected readonly capabilities$ = this.store.select(MembershipState.capabilities);
    protected readonly filtersQuery$ = this.localStore.selectByKey("filtersQuery");
    protected readonly missionsListPages$ = this.store.select(MembershipState.missionsListPages);
    protected readonly selectedContext$ = this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy());
    protected readonly isSelectedContextPersonal$ = this.store.select(OperatorContextState.selectedContext).pipe(
        RxjsUtils.filterFalsy(),
        map((context) => context.type === OperatorType.Personal)
    );

    protected readonly OperatorType = OperatorType;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<MembershipOperatorProfileComponentState>
    ) {
        localStore.setState({
            filtersQuery: this.assignFiltersQuery(this.route.snapshot.queryParams),
            [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
            [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
        });
    }

    public ngOnInit() {
        combineLatest([this.selectedContext$, this.route.queryParams])
            .pipe(untilDestroyed(this))
            .subscribe(([context, queryParams]) => {
                if (context.type === OperatorType.Personal) {
                    const pilotId = this.store.selectSnapshot(OperatorContextState.pilot)?.id;

                    if (!pilotId) {
                        return;
                    }

                    this.localStore.patchState({
                        filtersQuery: this.assignFiltersQuery(queryParams),
                        [PAGE_NUMBER_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_NUMBER_QUERY_PARAM] ?? 0,
                        [PAGE_SIZE_QUERY_PARAM]: this.route.snapshot.queryParams[PAGE_SIZE_QUERY_PARAM] ?? MIDDLE_PAGE_SIZE_VALUE,
                    });

                    this.store.dispatch(new MembershipActions.GetCapabilities(context.id));
                    this.refreshMissionsList();
                }
            });
    }

    protected startMissionWizard() {
        const pilotId = this.store.selectSnapshot(OperatorContextState.pilot)?.id;
        const operatorId = this.route.snapshot.params.operatorId;
        const contextToSelect = this.store.selectSnapshot(OperatorContextState.operator(operatorId));

        if (!contextToSelect || !pilotId) {
            return;
        }

        this.store
            .dispatch(new OperatorContextActions.TrySelectContext(contextToSelect))
            .pipe(untilDestroyed(this))
            .subscribe(() =>
                this.router.navigate(["mission/new"], {
                    queryParams: { preferredPilotId: pilotId },
                })
            );
    }

    protected refreshMissionsList() {
        const pilotId = this.store.selectSnapshot(OperatorContextState.pilot)?.id;
        const operatorId = this.route.snapshot.params.operatorId;

        if (!pilotId || !operatorId) {
            return;
        }

        this.store.dispatch(
            new MembershipActions.GetMissionsList({
                operatorId,
                pilotId,
                filtersQuery: this.assignFiltersQuery(this.route.snapshot.queryParams),
                page: this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM),
                size: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
            })
        );
    }

    protected changeFilters(formValue: MembershipMissionsFilter) {
        this.localStore.patchState({ filtersQuery: formValue });

        this.navigateByParams(true);
    }

    protected changePage(event: PageEvent) {
        this.localStore.patchState({
            [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: event.pageSize,
        });

        this.navigateByParams();
    }

    private navigateByParams(shouldResetPage: boolean = false) {
        const pageIndex = shouldResetPage ? 0 : this.localStore.selectSnapshotByKey(PAGE_NUMBER_QUERY_PARAM);
        const { name, status, dateFrom, dateTo } = this.localStore.selectSnapshotByKey("filtersQuery");

        if (shouldResetPage) {
            this.localStore.patchState({ [PAGE_NUMBER_QUERY_PARAM]: pageIndex });
        }

        let params: MembershipMissionListQueryParams = {
            [PAGE_NUMBER_QUERY_PARAM]: pageIndex,
            [PAGE_SIZE_QUERY_PARAM]: this.localStore.selectSnapshotByKey(PAGE_SIZE_QUERY_PARAM),
        };

        if (name?.length) {
            params = { ...params, missionName: name };
        }

        if (status?.length) {
            params = { ...params, missionStatus: status.join(",") };
        }

        if (dateFrom) {
            params = { ...params, missionDateFrom: dateFrom };
        }

        if (dateTo) {
            params = { ...params, missionDateTo: dateTo };
        }

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: params,
            replaceUrl: true,
        });
    }

    private assignFiltersQuery(queryParams: Params): MembershipMissionsFilter {
        return {
            status: queryParams.missionStatus?.length ? queryParams?.missionStatus.split(",") : null,
            dateFrom: queryParams.missionDateFrom ?? null,
            dateTo: queryParams.missionDateTo ?? null,
            name: queryParams.missionName ?? null,
        };
    }
}
