import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    FlightPositionUpdate,
    FlightPositionUpdateResponseBody,
    FlightPositionUpdateType,
    HemsDataResponseBody,
} from "@dtm-frontend/shared/ui";
import {
    Checkin,
    CheckinMessageEvent,
    CheckinsErrorType,
    CheckinsParams,
    DeactivationEventResponseBody,
    DeviceHistoryResponseBody,
    EmergencyRequestBody,
    EmergencyResponseBody,
    EmergencyType,
    FlightViolationEvent,
    Mission,
    MissionData,
    MissionDataResponseBody,
    MissionListResponseBody,
    MissionMapDataListResponseBody,
    MissionStatus,
    TacticalErrorType,
    ViolationNotification,
    convertCheckinEventBodyToCheckin,
    convertCheckinResponseBodyToCheckinList,
    convertEmergencyResponseBodyToEmergencyType,
    covertHemsDataResponseBodyToHemsEventData,
    getCheckinListParams,
    transformFlightPositionUpdateResponseBodyToFlightPositionUpdate,
    transformMissionDataResponse,
    transformMissionListResponse,
    transformMissionMapItemListResponse,
    transformTacticalErrorResponse,
} from "@dtm-frontend/shared/ui/tactical";
import { Logger, RxjsUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { BBox } from "@turf/helpers";
import { Observable, throwError } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { TACTICAL_ENDPOINTS, TacticalEndpoints } from "../tactical.tokens";

export interface NearbyMissionsSettings {
    missionId: string;
    areOnlyActive?: boolean;
    horizontalBuffer?: boolean;
    verticalBuffer?: boolean;
    areAllEnabled?: boolean;
}

const enum FlightControlEvent {
    FlightViolationCanceledEvent = "FlightViolationCanceledEvent",
    FlightViolationOccurredEvent = "FlightViolationOccurredEvent",
    MissionActivatedEvent = "MissionActivatedEvent",
    MissionCanceledByTimeoutEvent = "MissionCanceledByTimeoutEvent",
    MissionCanceledEvent = "MissionCanceledEvent",
    MissionEndedByTimeoutEvent = "MissionEndedByTimeoutEvent",
    MissionEndedEvent = "MissionEndedEvent",
    MissionRealizationStartedEvent = "MissionRealizationStartedEvent",
    SectionDeactivated = "SectionDeactivated",
}

export const enum DroneTrafficEvent {
    EndFlight = "EndFlight",
    FlightConnectionLost = "FlightConnectionLost",
    StartFlight = "StartFlight",
    UpdateFlightPosition = "UpdateFlightPosition",
    EmergencyRegisteredEvent = "EmergencyRegisteredEvent",
}

export enum CheckinEvent {
    CheckinSubmittedEvent = "CheckinSubmittedEvent",
    CheckinRealizationStartedEvent = "CheckinRealizationStartedEvent",
    CheckinExpiredEvent = "CheckinExpiredEvent",
    CheckinCompletedEvent = "CheckinCompletedEvent",
}

interface CheckinMessage {
    type: CheckinEvent;
    payload: Checkin;
}

const MISSION_LIST_ACCEPT_HEADER = "application/vnd.pansa.mission-planner.mission-list-item.api.v1+json";
const MISSION_LIST_WITH_ROUTES_ACCEPT_HEADER = "application/vnd.pansa.mission-planner.mission-map-item.api.v1+json";
const MISSION_DETAILS_WITH_ROUTE_ACCEPT_HEADER = "application/vnd.pansa.mission-planner.mission.api.v1+json";
const DEFAULT_HORIZONTAL_BUFFER = 2000;
const DEFAULT_VERTICAL_BUFFER = 100;
const VERY_LARGE_PAGE_SIZE = 1000;

@Injectable({
    providedIn: "root",
})
export class TacticalApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(TACTICAL_ENDPOINTS) private readonly endpoints: TacticalEndpoints,
        private readonly websocketService: WebsocketService
    ) {}

    public getAcceptedMissions(operatorId: string): Observable<Mission[]> {
        const params: HttpParams = new HttpParams()
            .set("startFrom", new Date().toISOString())
            .set("missionStatus", "ACCEPTED,ACTIVATED")
            .set("operatorId", operatorId);

        return this.httpClient
            .get<MissionListResponseBody>(this.endpoints.getTacticalMissions, {
                params,
                headers: {
                    accept: MISSION_LIST_ACCEPT_HEADER,
                },
            })
            .pipe(
                map((response) => transformMissionListResponse(response)),
                catchError((error) => throwError(() => transformTacticalErrorResponse(error)))
            );
    }

    public activateMission(missionId: string, missionStartTime: Date) {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.missionActivationManagement, { missionId }), null, {
                params: {
                    missionStart: missionStartTime.toISOString(),
                },
            })
            .pipe(catchError((error) => throwError(() => transformTacticalErrorResponse(error, TacticalErrorType.CannotActivateMission))));
    }

    public getMissionDetails(missionId: string): Observable<MissionData> {
        return this.httpClient
            .get<MissionDataResponseBody>(StringUtils.replaceInTemplate(this.endpoints.missionManagement, { missionId }), {
                headers: {
                    accept: MISSION_DETAILS_WITH_ROUTE_ACCEPT_HEADER,
                },
            })
            .pipe(
                map((response) => transformMissionDataResponse(response)),
                catchError((error) => throwError(() => transformTacticalErrorResponse(error)))
            );
    }

    public getActiveMissions(operatorId: string): Observable<Mission[]> {
        const params: HttpParams = new HttpParams().set("missionStatus", "ACTIVATED,STARTED").set("operatorId", operatorId);

        return this.httpClient
            .get<MissionListResponseBody>(this.endpoints.getTacticalMissions, {
                params,
                headers: {
                    accept: MISSION_LIST_ACCEPT_HEADER,
                },
            })
            .pipe(
                map((response) => transformMissionListResponse(response)),
                catchError((error) => throwError(() => transformTacticalErrorResponse(error)))
            );
    }

    public startActiveMissionsUpdatesWatch(userId: string): Observable<unknown> {
        return this.websocketService.watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsFlightControlTopicName, { userId }), [
            FlightControlEvent.MissionActivatedEvent,
            FlightControlEvent.MissionRealizationStartedEvent,
            FlightControlEvent.MissionEndedByTimeoutEvent,
            FlightControlEvent.MissionEndedEvent,
        ]);
    }

    public startAcceptedMissionsUpdatesWatch(userId: string): Observable<unknown> {
        return this.websocketService.watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsFlightControlTopicName, { userId }), [
            FlightControlEvent.MissionActivatedEvent,
            FlightControlEvent.MissionRealizationStartedEvent,
            FlightControlEvent.MissionEndedEvent,
            FlightControlEvent.MissionEndedByTimeoutEvent,
            FlightControlEvent.MissionCanceledEvent,
            FlightControlEvent.MissionCanceledByTimeoutEvent,
        ]);
    }

    public cancelMission(missionId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.missionManagement, { missionId }))
            .pipe(
                catchError(({ error }) => throwError(() => ({ type: TacticalErrorType.CannotCancelMission, code: error.generalMessage })))
            );
    }

    public deactivateMission(missionId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.missionActivationManagement, { missionId }))
            .pipe(
                catchError(({ error }) =>
                    throwError(() => ({ type: TacticalErrorType.CannotDeactivateMission, code: error.generalMessage }))
                )
            );
    }

    public startMissionUpdatesWatch(userId: string, missionId: string): Observable<unknown> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsFlightControlTopicName, { userId }), [
                FlightControlEvent.MissionCanceledEvent,
                FlightControlEvent.MissionActivatedEvent,
                FlightControlEvent.MissionCanceledByTimeoutEvent,
                FlightControlEvent.MissionCanceledEvent,
                FlightControlEvent.MissionEndedByTimeoutEvent,
                FlightControlEvent.MissionEndedEvent,
                FlightControlEvent.MissionRealizationStartedEvent,
            ])
            .pipe(
                filter((message) => {
                    try {
                        return JSON.parse(message.body).missionId === missionId;
                    } catch {
                        return false;
                    }
                })
            );
    }

    public startSectionDeactivatedEventWatch(userId: string): Observable<DeactivationEventResponseBody | undefined> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsFlightControlTopicName, { userId }), [
                FlightControlEvent.SectionDeactivated,
            ])
            .pipe(
                map((message) => {
                    try {
                        return JSON.parse(message.body);
                    } catch (error) {
                        Logger.captureException(error);

                        return;
                    }
                })
            );
    }

    public startFlightPositionUpdatesWatch(bbox: BBox): Observable<FlightPositionUpdate> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsFlightPositionTopicName, { bbox: bbox?.join(",") ?? "" }), [
                DroneTrafficEvent.StartFlight,
                DroneTrafficEvent.EndFlight,
                DroneTrafficEvent.UpdateFlightPosition,
                DroneTrafficEvent.FlightConnectionLost,
            ])
            .pipe(
                map((message) => {
                    const updateType = this.mapEventTypeToUpdateType(message.headers["event-type"] as DroneTrafficEvent);
                    if (!updateType) {
                        return;
                    }

                    try {
                        const response: FlightPositionUpdateResponseBody = JSON.parse(message.body);

                        return transformFlightPositionUpdateResponseBodyToFlightPositionUpdate(response, updateType);
                    } catch (error) {
                        Logger.captureException(error);

                        return;
                    }
                }),
                RxjsUtils.filterFalsy()
            );
    }

    private mapEventTypeToUpdateType(event: DroneTrafficEvent): FlightPositionUpdateType | undefined {
        switch (event) {
            case DroneTrafficEvent.StartFlight:
                return FlightPositionUpdateType.Start;
            case DroneTrafficEvent.EndFlight:
                return FlightPositionUpdateType.End;
            case DroneTrafficEvent.UpdateFlightPosition:
                return FlightPositionUpdateType.Update;
            case DroneTrafficEvent.FlightConnectionLost:
                return FlightPositionUpdateType.ConnectionLost;
            default:
                return;
        }
    }

    public getNearbyMissions({
        areOnlyActive,
        horizontalBuffer,
        missionId,
        verticalBuffer,
    }: NearbyMissionsSettings): Observable<MissionData[]> {
        const missionStatuses = [MissionStatus.Activated, MissionStatus.Started];
        if (!areOnlyActive) {
            missionStatuses.push(MissionStatus.Accepted);
        }

        const params: HttpParams = new HttpParams()
            .set("missionId", missionId)
            .set("missionStatuses", missionStatuses.join(","))
            .set("horizontalBuffer", horizontalBuffer ?? DEFAULT_HORIZONTAL_BUFFER)
            .set("verticalBuffer", verticalBuffer ?? DEFAULT_VERTICAL_BUFFER)
            .set("pageSize", VERY_LARGE_PAGE_SIZE) // NOTE: we shouldn't use pagination, but backend requires this
            .set("pageIndex", 0);

        return this.httpClient
            .get<MissionMapDataListResponseBody>(this.endpoints.getNearbyMissions, {
                params,
                headers: {
                    accept: MISSION_LIST_WITH_ROUTES_ACCEPT_HEADER,
                },
            })
            .pipe(
                map(({ content }) => content.map((mission) => transformMissionMapItemListResponse(mission))),
                catchError((error) => throwError(() => transformTacticalErrorResponse(error)))
            );
    }

    public confirmTrackerReadings(missionId: string, trackerIdentifier: string) {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.confirmTrackerReadings, { missionId, trackerIdentifier }), {})
            .pipe(catchError((error) => throwError(() => transformTacticalErrorResponse(error, TacticalErrorType.CannotConfirmReadings))));
    }

    public getHemsData() {
        const params: HttpParams = new HttpParams().set("pageSize", VERY_LARGE_PAGE_SIZE * 2);

        return this.httpClient
            .get<HemsDataResponseBody>(this.endpoints.getHemsData, {
                params,
            })
            .pipe(map(covertHemsDataResponseBodyToHemsEventData));
    }

    public startViolationUpdatesWatch(userId: string): Observable<ViolationNotification | undefined> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsFlightControlTopicName, { userId }), [
                FlightControlEvent.FlightViolationOccurredEvent,
                FlightControlEvent.FlightViolationCanceledEvent,
            ])
            .pipe(
                map((message) => {
                    try {
                        return {
                            type: message.headers["event-type"] as FlightViolationEvent,
                            payload: JSON.parse(message.body),
                        };
                    } catch (error) {
                        Logger.captureException(error);

                        return;
                    }
                })
            );
    }

    public startDtmCheckinUpdatesWatch(dtmName: string): Observable<CheckinMessage | undefined> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsDtmMissionsWatch, { dtmName }), Object.values(CheckinEvent))
            .pipe(
                map((message) => {
                    try {
                        const response: CheckinMessageEvent = JSON.parse(message.body);

                        return {
                            type: message.headers["event-type"] as CheckinEvent,
                            payload: convertCheckinEventBodyToCheckin(response),
                        };
                    } catch (error) {
                        Logger.captureException(error);

                        return undefined;
                    }
                })
            );
    }

    public getMissionEmergency(missionId: string): Observable<EmergencyType | undefined> {
        return this.httpClient
            .get<EmergencyResponseBody>(StringUtils.replaceInTemplate(this.endpoints.emergencyManagement, { missionId }))
            .pipe(
                map(convertEmergencyResponseBodyToEmergencyType),
                catchError((error) => throwError(() => transformTacticalErrorResponse(error)))
            );
    }

    public reportMissionEmergency(missionId: string, emergencyType: EmergencyType | undefined): Observable<EmergencyType | undefined> {
        const requestBody: EmergencyRequestBody = {
            emergency: {
                type: emergencyType,
            },
        };

        return this.httpClient
            .post<EmergencyResponseBody>(StringUtils.replaceInTemplate(this.endpoints.emergencyManagement, { missionId }), requestBody)
            .pipe(
                map(convertEmergencyResponseBodyToEmergencyType),
                catchError((error) => throwError(() => transformTacticalErrorResponse(error, TacticalErrorType.CannotReportEmergency)))
            );
    }

    public getCheckinList(listRequests: CheckinsParams["listRequest"]): Observable<Checkin[]> {
        return this.httpClient
            .post<DeviceHistoryResponseBody>(this.endpoints.getCheckins, getCheckinListParams(listRequests, VERY_LARGE_PAGE_SIZE))
            .pipe(
                map((response) => convertCheckinResponseBodyToCheckinList(response.content)),
                catchError(() => throwError(() => ({ type: CheckinsErrorType.CannotGetCheckins })))
            );
    }
}
