<div class="dialog-header">
    <div mat-dialog-title>
        {{ "dtmWebAppLibMission.itineraryEditorStep.heightViolationAlert.dialog.title" | transloco }}
    </div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<mat-dialog-content>
    <img class="height-violation-image" src="assets/images/height-violation.svg" />
    <p class="instruction">{{ "dtmWebAppLibMission.itineraryEditorStep.heightViolationAlert.dialog.instructionOne" | transloco }}</p>
    <p class="instruction">{{ "dtmWebAppLibMission.itineraryEditorStep.heightViolationAlert.dialog.instructionTwo" | transloco }}</p>
    <p class="instruction">{{ "dtmWebAppLibMission.itineraryEditorStep.heightViolationAlert.dialog.instructionThree" | transloco }}</p>
    <p class="instruction">{{ "dtmWebAppLibMission.itineraryEditorStep.heightViolationAlert.dialog.instructionFour" | transloco }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="button-primary" type="button" [matDialogClose]="false">
        {{ "dtmWebAppLibMission.itineraryEditorStep.heightViolationAlert.dialog.closeButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
