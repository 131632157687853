import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";

const MIN_NAME_LENGTH = 3;
const MAX_NAME_LENGTH = 100;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-change-name-dialog",
    templateUrl: "./change-name-dialog.component.html",
    styleUrls: ["./change-name-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeNameDialogComponent {
    private readonly setNameSubject = new Subject<string>();
    public readonly setName$ = this.setNameSubject.asObservable();

    protected nameControl = new FormControl("", {
        validators: [Validators.required, Validators.minLength(MIN_NAME_LENGTH), Validators.maxLength(MAX_NAME_LENGTH)],
        nonNullable: true,
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: { permitName: string; titleText: string; isNameProcessing$: Observable<boolean> }) {
        if (this.data.permitName) {
            this.nameControl.setValue(this.data.permitName);
        }

        this.listenOnNameProcessing();
    }

    protected confirm(): void {
        if (this.nameControl.invalid) {
            return;
        }

        this.setNameSubject.next(this.nameControl.value);
    }

    private listenOnNameProcessing(): void {
        this.data.isNameProcessing$.pipe(untilDestroyed(this)).subscribe((isProcessing: boolean) => {
            if (isProcessing) {
                this.nameControl.disable();

                return;
            }

            this.nameControl.enable();
        });
    }
}
