import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, filter, tap } from "rxjs/operators";

export interface UavListFilter {
    textSearch: string;
}

export enum Sorting {
    NameAsc = "name_asc",
    NameDesc = "name_desc",
}

interface UavListFilterForm {
    textSearch: FormControl<string>;
}

interface UavListFiltersComponentState {
    filtersCount: number;
}

const MIN_SEARCH_LENGTH = 3;

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "textSearch",
        filterLabel: "dtmWebAppLibUav.uavList.filters.textSearchLabel",
        type: FilterType.TextEllipsis,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-list-filters",
    templateUrl: "./uav-list-filters.component.html",
    styleUrls: ["./uav-list-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavListFiltersComponent implements OnInit {
    @Input() public set initialFilters(value: UavListFilter | undefined) {
        if (!value) {
            return;
        }

        this.filtersForm.setValue(value);
        this.updateAppliedFiltersCount(value);
    }

    @Input() public set initialSorting(value: Sorting | undefined) {
        this.sortByControl.setValue(value ?? Sorting.NameAsc);
    }

    @Output() public filtersChange = new EventEmitter<UavListFilter>();
    @Output() public sortChange = new EventEmitter<Sorting>();

    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly sortingOptions = Object.values(Sorting);
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");

    protected readonly filtersForm = new FormGroup<UavListFilterForm>({
        textSearch: new FormControl<string>("", { validators: Validators.minLength(MIN_SEARCH_LENGTH), nonNullable: true }),
    });
    protected readonly sortByControl = new FormControl<Sorting>(Sorting.NameAsc, { nonNullable: true });

    constructor(private readonly localStore: LocalComponentStore<UavListFiltersComponentState>) {
        localStore.setState({
            filtersCount: 0,
        });
    }

    public ngOnInit() {
        this.watchFormValueChanges();
    }

    protected clearFilters(): void {
        this.filtersForm.reset();
    }

    private watchFormValueChanges() {
        this.filtersForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                filter(() => this.filtersForm.valid),
                tap((value) => {
                    this.updateAppliedFiltersCount(value as UavListFilter);

                    this.filtersChange.emit(value as UavListFilter);
                }),
                untilDestroyed(this)
            )
            .subscribe();

        this.sortByControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.sortChange.emit(value);
        });
    }

    private updateAppliedFiltersCount(value: UavListFilter): void {
        this.localStore.patchState({
            filtersCount: Object.values(value).flat().filter(FunctionUtils.isTruthy).length,
        });
    }
}
