<div class="form-entry" *ngrxLet="flightPurposes$; let flightPurposes">
    <dtm-ui-select-field
        class="flight-purpose-select"
        [isClearable]="false"
        [formControl]="flightPurposeFormControl"
        data-testid="flight-purpose-option"
    >
        <label>{{ "dtmWebAppLibMission.dataFormStep.flightPurposeLabel" | transloco }}</label>
        <dtm-ui-select-option
            class="flight-purpose-option"
            [value]="flightPurpose"
            *ngFor="let flightPurpose of flightPurposes"
            [attr.data-testid]="'flight-purpose-' + flightPurpose.codename.toLowerCase()"
        >
            <ng-template
                [ngTemplateOutlet]="flightPurposeLabelTemplate"
                [ngTemplateOutletContext]="{ $implicit: flightPurpose }"
            ></ng-template>

            <!-- TODO: this should be uncommented when thare will be meaningful descriptions coming from backend -->
            <!-- <span hint *ngIf="flightPurpose.description | hasSystemTranslation">
                {{ flightPurpose.description | systemTranslation }}
            </span> -->
        </dtm-ui-select-option>
        <mat-select-trigger class="flight-purpose-option">
            <ng-template
                [ngTemplateOutlet]="flightPurposeLabelTemplate"
                [ngTemplateOutletContext]="{ $implicit: flightPurposeFormControl.value }"
            ></ng-template>
        </mat-select-trigger>
        <div class="field-error" *dtmUiFieldHasError="flightPurposeFormControl; name: 'required'">
            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-select-field>
</div>

<ng-template #flightPurposeLabelTemplate let-flightPurpose>
    <div class="flight-purpose-label" *ngIf="flightPurpose">
        {{ flightPurpose.displayName | systemTranslation }}
        <span class="chip-parent">
            <mat-chip
                class="chip emergency-chip"
                [matTooltip]="'dtmWebAppLibMission.dataFormStep.flightPurposeEmergencyTooltip' | transloco"
                *ngIf="flightPurpose.isEmergency"
            >
                <dtm-ui-icon name="alarm-warning-fill"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.dataFormStep.flightPurposeEmergencyLabel" | transloco }}
            </mat-chip>
        </span>
    </div>
</ng-template>
