import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FormUtils, FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PilotOperatorRegistrationInsurance } from "../../models";

const INSURANCE_NUMBER_MAX_LENGTH = 50;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-pilot-operator-registration-insurance-form",
    templateUrl: "./pilot-operator-registration-insurance-form.component.html",
    styleUrls: ["../../styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        TranslationHelperService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PilotOperatorRegistrationInsuranceFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PilotOperatorRegistrationInsuranceFormComponent),
            multi: true,
        },
    ],
})
export class PilotOperatorRegistrationInsuranceFormComponent implements OnInit, ControlValueAccessor, Validator {
    protected readonly todayDate = new Date();

    protected readonly insuranceNumberControl = new FormControl<string>("", {
        validators: [FormUtils.trimmedRequiredValidator, FormUtils.trimmedMaxLengthValidator(INSURANCE_NUMBER_MAX_LENGTH)],
        nonNullable: true,
    });
    protected readonly insuranceExpirationDateControl = new FormControl<Date | null>(null, {
        validators: [Validators.required],
        nonNullable: true,
    });

    protected readonly insuranceForm = new FormGroup({
        expirationDate: this.insuranceExpirationDateControl,
        number: this.insuranceNumberControl,
    });
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: PilotOperatorRegistrationInsurance) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly translocoHelper: TranslationHelperService) {}

    public ngOnInit(): void {
        this.insuranceForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.insuranceForm.getRawValue());
        });
    }

    public registerOnChange(fn: (value: PilotOperatorRegistrationInsurance) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.insuranceForm.valid) {
            return null;
        }

        return this.insuranceForm.invalid ? { invalidInsurance: true } : null;
    }

    public writeValue(value: PilotOperatorRegistrationInsurance): void {
        this.insuranceForm.setValue(value, { emitEvent: false });

        if (!this.validate()) {
            this.propagateChange(this.insuranceForm.getRawValue());
        }
        this.onValidationChange();
    }
}
