<ng-container *ngrxLet="{ competencies: competencies$, isEditMode: isEditMode$ } as vm">
    <dtm-ui-editable-card
        *ngIf="vm.competencies.length || vm.isEditMode; else foreignCompetenciesEmptyStateTemplate"
        [label]="'dtmWebAppLibPilotProfile.foreignCompetencies.header' | transloco"
        [isEditModeOn]="vm.isEditMode"
        [editButtonLabel]="'dtmWebAppLibPilotProfile.foreignCompetencies.addDocumentButtonLabel' | transloco"
        [editButtonIcon]="'add'"
        (editModeChange)="setEditMode($event)"
        (actionSave)="saveForm()"
        [canLeaveEditModeOnSave]="false"
        [isEditButtonVisible]="!vm.isEditMode && vm.competencies.length < 2"
    >
        <div class="competencies-preview" *ngIf="vm.competencies.length" previewContent>
            <div class="competency" *ngFor="let competency of vm.competencies">
                <div class="competency-header">
                    <p class="competency-type">
                        {{
                            (competency.type === ForeignCompetencyType.A1_A3
                                ? "dtmWebAppLibPilotProfile.foreignCompetencies.a1a3confirmatoryDocument"
                                : "dtmWebAppLibPilotProfile.foreignCompetencies.a2confirmatoryDocument"
                            ) | transloco
                        }}
                    </p>
                    <div class="competency-status-with-action">
                        <ng-container [ngSwitch]="competency.status">
                            <span *ngSwitchCase="ForeignCompetencyStatus.Accepted" class="chip success">
                                <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                                {{
                                    "dtmWebAppLibPilotProfile.foreignCompetencies.competencyStatusLabel"
                                        | transloco : { status: competency.status }
                                }}
                            </span>
                            <dtm-ui-content-popover *ngSwitchCase="ForeignCompetencyStatus.Rejected">
                                <span class="chip error">
                                    <dtm-ui-icon name="error-warning"></dtm-ui-icon>
                                    <span>
                                        {{
                                            "dtmWebAppLibPilotProfile.foreignCompetencies.competencyStatusLabel"
                                                | transloco : { status: competency.status }
                                        }}
                                    </span>
                                </span>
                                <div popoverContent *ngIf="competency.statusReason">
                                    <strong>{{ "dtmWebAppLibPilotProfile.foreignCompetencies.rejectionReasonLabel" | transloco }}:</strong>
                                    <p>{{ competency.statusReason }}</p>
                                </div>
                            </dtm-ui-content-popover>
                            <span *ngSwitchCase="ForeignCompetencyStatus.Waiting" class="chip warning">
                                <dtm-ui-icon name="information"></dtm-ui-icon>
                                {{
                                    "dtmWebAppLibPilotProfile.foreignCompetencies.competencyStatusLabel"
                                        | transloco : { status: competency.status }
                                }}
                            </span>
                        </ng-container>
                        <button
                            *ngIf="competency.status !== ForeignCompetencyStatus.Accepted; else emptyButtonTemplate"
                            [matMenuTriggerFor]="actions"
                            class="button-icon"
                            type="button"
                            (click)="$event.stopPropagation()"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                        <ng-template #emptyButtonTemplate>
                            <div class="empty-button-template"></div>
                        </ng-template>
                    </div>
                    <mat-menu #actions="matMenu">
                        <button type="button" mat-menu-item (click)="editCompetency(competency)">
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "dtmWebAppLibPilotProfile.foreignCompetencies.editLabel" | transloco }}
                        </button>
                        <button type="button" mat-menu-item (click)="delete.next(competency.id)">
                            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                            {{ "dtmWebAppLibPilotProfile.foreignCompetencies.deleteLabel" | transloco }}
                        </button>
                    </mat-menu>
                </div>
                <div class="competency-details">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppLibPilotProfile.foreignCompetencies.confirmatoryDocumentExpirationDateLabel' | transloco"
                        [value]="competency.expirationDate | localizeDate"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value [label]="'dtmWebAppLibPilotProfile.foreignCompetencies.attachmentLabel' | transloco">
                        <dtm-ui-uploaded-files-display [uploadedFiles]="[competency.file]"></dtm-ui-uploaded-files-display>
                    </dtm-ui-label-value>
                </div>
            </div>
        </div>
        <form [formGroup]="foreignCompetenciesForm" editableContent>
            <div class="form-fields">
                <dtm-ui-radio-group [formControl]="typeControl">
                    <label class="label">{{ "dtmWebAppLibPilotProfile.foreignCompetencies.documentTypeLabel" | transloco }}</label>
                    <div class="horizontal-radio-buttons" *ngrxLet="editedCompetency$ as editedCompetency">
                        <dtm-ui-radio-field
                            [value]="ForeignCompetencyType.A1_A3"
                            [disabled]="
                                (vm.competencies | invoke : isCompetencyTypeDisabled : ForeignCompetencyType.A1_A3) &&
                                editedCompetency?.type !== ForeignCompetencyType.A1_A3
                            "
                        >
                            {{ "dtmWebAppLibPilotProfile.foreignCompetencies.a1a3confirmatoryDocument" | transloco }}
                        </dtm-ui-radio-field>
                        <dtm-ui-radio-field
                            [value]="ForeignCompetencyType.A2"
                            [disabled]="
                                (vm.competencies | invoke : isCompetencyTypeDisabled : ForeignCompetencyType.A2) &&
                                editedCompetency?.type !== ForeignCompetencyType.A2
                            "
                        >
                            {{ "dtmWebAppLibPilotProfile.foreignCompetencies.a2confirmatoryDocument" | transloco }}
                        </dtm-ui-radio-field>
                    </div>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <div class="field-error" *dtmUiFieldHasError="typeControl; name: ['required', 'requiredTouched']; error as error">
                            {{ "dtmWebAppLibPilotProfile.foreignCompetencies.requiredFieldError" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </dtm-ui-radio-group>

                <dtm-ui-date-field>
                    <label for="documentExpirationDate">
                        {{ "dtmWebAppLibPilotProfile.foreignCompetencies.confirmatoryDocumentExpirationDateLabel" | transloco }}
                    </label>
                    <input
                        id="documentExpirationDate"
                        [formControl]="expirationDateControl"
                        matInput
                        [min]="TOMORROW_DATE"
                        [matDatepicker]="expirationDatepicker"
                        *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                        [placeholder]="datePickerPlaceholder"
                    />
                    <mat-datepicker #expirationDatepicker></mat-datepicker>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="expirationDateControl; name: ['required', 'requiredTouched']; error as error"
                    >
                        {{ "dtmWebAppLibPilotProfile.foreignCompetencies.requiredFieldError" | transloco }}
                    </div>
                    <div class="field-error" *ngIf="expirationDateControl.errors?.matDatepickerMin">
                        {{
                            "dtmWebAppLibPilotProfile.foreignCompetencies.minConfirmatoryDocumentExpirationDateError"
                                | transloco : { minDate: expirationDateControl.errors?.matDatepickerMin.min | localizeDate }
                        }}
                    </div>
                </dtm-ui-date-field>
                <dtm-ui-files-upload-field
                    [isDownloadAllButtonVisible]="false"
                    [formControl]="uploadedFileControl"
                    [maxFileSize]="MAX_FILE_SIZE_BYTES"
                    [allowedTypes]="UPLOAD_FILE_ALLOWED_MIME_TYPES"
                >
                    <label>{{ "dtmWebAppLibPilotProfile.foreignCompetencies.uploadDocumentLabel" | transloco }}</label>
                    <div class="field-error" *dtmUiFieldHasError="uploadedFileControl; name: 'maxlength'">
                        {{ "dtmWebAppLibPilotProfile.foreignCompetencies.uploadMultipleFilesError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="uploadedFileControl; name: 'required'">
                        {{ "dtmWebAppLibPilotProfile.foreignCompetencies.requiredFieldError" | transloco }}
                    </div>
                    <div class="field-hint">
                        {{
                            "dtmWebAppLibPilotProfile.foreignCompetencies.uploadAllowedExtensionsHint"
                                | transloco : { allowedFileSize: MAX_FILE_SIZE_BYTES | formatFileSize }
                        }}
                    </div>
                </dtm-ui-files-upload-field>
            </div>
        </form>
    </dtm-ui-editable-card>
</ng-container>

<ng-template #foreignCompetenciesEmptyStateTemplate>
    <dtm-ui-empty-state>
        <ng-container titleSlot>
            {{ "dtmWebAppLibPilotProfile.foreignCompetencies.header" | transloco }}
        </ng-container>
        <div messageSlot class="empty-state-message">
            <p>{{ "dtmWebAppLibPilotProfile.foreignCompetencies.emptyStateText" | transloco }}</p>
            <img src="assets/images/foreign-competencies.svg" alt="" />
        </div>
        <button footerSlot type="button" class="button-primary" (click)="setEditMode(true)">
            {{ "dtmWebAppLibPilotProfile.foreignCompetencies.addDocumentButtonLabel" | transloco }}
        </button>
    </dtm-ui-empty-state>
</ng-template>
