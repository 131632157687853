<dtm-ui-expandable-panel [isExpanded]="false" *ngrxLet="permission$ as permission" [hasHeaderSeparator]="false">
    <div class="header-container" headerSlot>
        <p class="permission-name">{{ permission.scenarioName }}</p>
        <span class="chip light">
            <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
            {{
                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.periodOfValidityInMonthsValue"
                    | transloco : { monthsAmount: permission.grantValidityInMonths }
            }}
        </span>
        <span class="chip light" *ngIf="permission.uavs?.length">
            <dtm-ui-icon name="drone"></dtm-ui-icon>
            {{ permission.uavs?.length }}
        </span>
    </div>
    <ol *ngIf="permission.uavs?.length" class="bsp">
        <li *ngFor="let uav of permission.uavs; let index = index">
            <p class="bsp-label">
                {{
                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.uavsUsedForPermissionLabel"
                        | transloco : { index: index + 1 }
                }}
            </p>
            <p>{{ uav.name }}, {{ uav.serialNumber }}</p>
        </li>
    </ol>
</dtm-ui-expandable-panel>
