<div class="container" *ngrxLet="{ planId: planId$ } as vm">
    <h2 class="header">
        {{ "dtmWebAppLibMission.summaryStep.messagePansa.header" | transloco }}
        <dtm-ui-popover>
            <p class="pansa-popover-text" [innerHTML]="'dtmWebAppLibMission.summaryStep.messagePansa.headerPopover' | transloco"></p>
        </dtm-ui-popover>
    </h2>
    <section>
        <p class="sub-header">
            {{ "dtmWebAppLibMission.summaryStep.messagePansa.subHeader" | transloco }}
        </p>

        <div class="mail">
            <a class="link" [href]="'mailto:' + CONTACT_MAIL">
                {{ CONTACT_MAIL }}
            </a>
            <button
                type="button"
                class="button-icon"
                [cdkCopyToClipboard]="CONTACT_MAIL"
                (cdkCopyToClipboardCopied)="handleValueCopy($event)"
            >
                <dtm-ui-icon name="file-copy"></dtm-ui-icon>
            </button>
        </div>
    </section>
    <div class="mission-parameters">
        <ul>
            <li
                [innerHTML]="
                    'dtmWebAppLibMission.summaryStep.messagePansa.messageParameters.uavWeightElement'
                        | transloco : { value: missionParametersList.weight | localizeNumber }
                "
            ></li>
            <li
                [innerHTML]="
                    'dtmWebAppLibMission.summaryStep.messagePansa.messageParameters.uavSpeedElement'
                        | transloco : { value: missionParametersList.speed | localizeNumber }
                "
            ></li>
            <li
                [innerHTML]="
                    'dtmWebAppLibMission.summaryStep.messagePansa.messageParameters.heightAirSpaceElement'
                        | transloco : { value: missionParametersList.height | localizeNumber }
                "
            ></li>
            <li>{{ "dtmWebAppLibMission.summaryStep.messagePansa.messageParameters.droneSwarmElement" | transloco }}</li>
            <li>{{ "dtmWebAppLibMission.summaryStep.messagePansa.messageParameters.bvlosOperationElement" | transloco }}</li>
        </ul>
    </div>
    <section>
        <p>{{ "dtmWebAppLibMission.summaryStep.messagePansa.titleDescription" | transloco }}</p>
        <p class="mail-title">
            {{ "dtmWebAppLibMission.summaryStep.messagePansa.mailTitle" | transloco : { value: vm.planId } }}
        </p>
    </section>
    <button
        type="button"
        class="link"
        [cdkCopyToClipboard]="'dtmWebAppLibMission.summaryStep.messagePansa.mailTitle' | transloco : { value: vm.planId }"
        (cdkCopyToClipboardCopied)="handleValueCopy($event)"
    >
        {{ "dtmWebAppLibMission.summaryStep.messagePansa.copyTitleLabel" | transloco }}
    </button>
    <p>{{ "dtmWebAppLibMission.summaryStep.messagePansa.confirmationDescription" | transloco }}</p>
</div>
