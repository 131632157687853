import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewContainerRef } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { TacticalMitigationPerformanceRequirementProperty } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    SoraSettings,
    TacticalMitigationPerformanceRequirements,
    TacticalRiskMitigationMeasuresCategory,
} from "../../../../../../models/mission.model";
import { TacticalRiskMitigationModalComponent } from "./tactical-risk-mitigation-modal/tactical-risk-mitigation-modal.component";

const MAXIMUM_RESIDUAL_ARC = 2;

export interface TacticalRiskMitigationComponentState {
    tacticalMitigationPerformanceRequirementProperty: TacticalMitigationPerformanceRequirementProperty | undefined;
    tacticalMitigationPerformanceRequirements: TacticalMitigationPerformanceRequirements | undefined;
    isEditable: boolean;
    isExpanded: boolean;
    residualArc: number;
}

@UntilDestroy()
@Component({
    selector:
        "dtm-web-app-lib-tactical-risk-mitigation[tacticalMitigationPerformanceRequirements][tacticalMitigationPerformanceRequirementProperty]",
    templateUrl: "./tactical-risk-mitigation.component.html",
    styleUrls: ["./tactical-risk-mitigation.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticalRiskMitigationComponent {
    @Input() public set tacticalMitigationPerformanceRequirementProperty(
        value: TacticalMitigationPerformanceRequirementProperty | undefined
    ) {
        this.localStore.patchState({
            tacticalMitigationPerformanceRequirementProperty: value ?? TacticalMitigationPerformanceRequirementProperty.B,
        });
    }
    @Input() public set tacticalMitigationPerformanceRequirements(value: TacticalMitigationPerformanceRequirements | undefined) {
        this.localStore.patchState({
            tacticalMitigationPerformanceRequirements: value,
        });
    }
    @Input() public set isEditable(value: BooleanInput) {
        this.localStore.patchState({ isEditable: coerceBooleanProperty(value) });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set residualArc(value: number | undefined) {
        this.localStore.patchState({ residualArc: value ?? 0 });
    }
    @Output() public readonly tacticalRiskMitigationChange = new EventEmitter<SoraSettings>();

    protected readonly MAXIMUM_RESIDUAL_ARC = MAXIMUM_RESIDUAL_ARC;

    protected readonly tacticalMitigationPerformanceRequirementProperty$ = this.localStore.selectByKey(
        "tacticalMitigationPerformanceRequirementProperty"
    );
    protected readonly tacticalMitigationPerformanceRequirements$ = this.localStore.selectByKey(
        "tacticalMitigationPerformanceRequirements"
    );
    protected readonly residualArc$ = this.localStore.selectByKey("residualArc");
    protected readonly isEditable$ = this.localStore.selectByKey("isEditable");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    protected readonly TacticalMitigationPerformanceRequirement = TacticalMitigationPerformanceRequirementProperty;
    protected readonly categoryKeys = [
        TacticalRiskMitigationMeasuresCategory.DETECT,
        TacticalRiskMitigationMeasuresCategory.DECIDE,
        TacticalRiskMitigationMeasuresCategory.COMMAND,
        TacticalRiskMitigationMeasuresCategory.EXECUTE,
        TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP,
    ];

    constructor(
        private readonly dialog: MatDialog,
        private readonly localStore: LocalComponentStore<TacticalRiskMitigationComponentState>,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        localStore.setState({
            tacticalMitigationPerformanceRequirementProperty: undefined,
            tacticalMitigationPerformanceRequirements: undefined,
            isEditable: false,
            isExpanded: false,
            residualArc: 0,
        });
    }

    protected addOrEdit() {
        this.dialog
            .open(TacticalRiskMitigationModalComponent, {
                data: {
                    tacticalMitigationPerformanceRequirementProperty: this.localStore.selectSnapshotByKey(
                        "tacticalMitigationPerformanceRequirementProperty"
                    ),
                    tacticalMitigationPerformanceRequirements: this.localStore.selectSnapshotByKey(
                        "tacticalMitigationPerformanceRequirements"
                    ),
                },
                viewContainerRef: this.viewContainerRef,
            })
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((tacticalMitigationPerformanceRequirements: TacticalMitigationPerformanceRequirements) => {
                if (!tacticalMitigationPerformanceRequirements) {
                    return;
                }

                this.tacticalRiskMitigationChange.next({
                    tmpr: tacticalMitigationPerformanceRequirements,
                });
            });
    }
}
