import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { debounceTime, distinctUntilChanged, first, tap } from "rxjs/operators";
import { ForeignOperatorValidation, ForeignOperatorValidationErrorType, ForeignOperatorVerificationDetails } from "../../models";

const FOREIGN_COMPANY_NUMBER_LENGTH = 16;
const FOREIGN_OPERATOR_NUMBER_MASK = "A A A A A A A A A A A A A A A A";
const VERIFICATION_CODE_LENGTH = 3;

interface ForeignOperatorValidationFieldsComponentState {
    foreignOperatorValidation: ForeignOperatorValidation | undefined;
}

export function foreignOperatorFormValidator(control: AbstractControl): ValidationErrors | null {
    if (!!control.value && !!control.value.number && !!control.value.secret) {
        return null;
    }

    return { invalidForeignOperator: true };
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-foreign-operator-validation-fields",
    templateUrl: "./foreign-operator-validation-fields.component.html",
    styleUrls: ["./foreign-operator-validation-fields.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ForeignOperatorValidationFieldsComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ForeignOperatorValidationFieldsComponent),
            multi: true,
        },
    ],
})
export class ForeignOperatorValidationFieldsComponent implements OnInit, ControlValueAccessor, Validator {
    @Input()
    public set foreignOperatorVerificationDetails(value: ForeignOperatorVerificationDetails) {
        this.foreignOperatorForm.patchValue(value);

        if (!this.validate(this.foreignOperatorForm)) {
            this.propagateChange(value);
        }

        this.onValidationChange();
    }

    @Input()
    public set foreignOperatorValidation(value: ForeignOperatorValidation | undefined) {
        this.localStore.patchState({ foreignOperatorValidation: value });
    }

    @Output() public foreignOperatorCheck = new EventEmitter<ForeignOperatorVerificationDetails>();

    protected readonly foreignOperatorNumberControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(FOREIGN_COMPANY_NUMBER_LENGTH)],
        nonNullable: true,
    });
    protected readonly secretCodeControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(VERIFICATION_CODE_LENGTH), Validators.maxLength(VERIFICATION_CODE_LENGTH)],
        nonNullable: true,
    });

    protected readonly foreignOperatorForm = new FormGroup({
        number: this.foreignOperatorNumberControl,
        secret: this.secretCodeControl,
    });
    protected readonly FOREIGN_OPERATOR_NUMBER_MASK = FOREIGN_OPERATOR_NUMBER_MASK;
    protected readonly foreignOperatorValidation$ = this.localStore.selectByKey("foreignOperatorValidation");

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: ForeignOperatorVerificationDetails) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(
        private readonly localStore: LocalComponentStore<ForeignOperatorValidationFieldsComponentState>,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {
        localStore.setState({ foreignOperatorValidation: undefined });
    }

    public ngOnInit() {
        this.foreignOperatorForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.foreignOperatorForm.getRawValue());
            this.propagateTouch();
        });

        this.initForeignOperatorWatch();
    }

    public registerOnChange(fn: (value: ForeignOperatorVerificationDetails) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        if (this.foreignOperatorForm.valid) {
            return null;
        }

        return this.foreignOperatorForm.invalid ? { invalidForeignOperator: true } : null;
    }

    public writeValue(value: ForeignOperatorVerificationDetails): void {
        if (value) {
            this.foreignOperatorVerificationDetails = value;
        }
    }

    private initForeignOperatorWatch() {
        return this.foreignOperatorForm.valueChanges
            .pipe(
                distinctUntilChanged(equal),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                tap((value) => {
                    const currentForeignValidationValue = this.localStore.selectSnapshotByKey("foreignOperatorValidation");
                    if (
                        this.foreignOperatorForm.valid &&
                        (value.number !== currentForeignValidationValue?.number || value.secret !== currentForeignValidationValue?.secret)
                    ) {
                        this.foreignOperatorCheck.emit(this.foreignOperatorForm.getRawValue());
                        this.foreignOperatorForm.disable();
                    }

                    this.localStore.patchState({ foreignOperatorValidation: undefined });
                }),
                switchMap(() => this.foreignOperatorValidation$.pipe(RxjsUtils.filterFalsy(), first())),
                untilDestroyed(this)
            )
            .subscribe((foreignOperatorValidation) => {
                this.foreignOperatorForm.enable();

                if (foreignOperatorValidation.isValid) {
                    return;
                }

                if (foreignOperatorValidation.error?.type === ForeignOperatorValidationErrorType.OperatorInvalid) {
                    return;
                }

                this.toastrService.error(
                    this.translocoService.translate("dtmWebAppLibPilotOperatorRegistration.basicData.foreignOperatorValidationUnknownError")
                );
            });
    }
}
