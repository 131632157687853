import { TrainingExamResultsFilterParams } from "../models/training-exam-results.models";

export namespace TrainingExamResultsActions {
    export class GetTrainingResultsList {
        public static readonly type = "[Results] Get training results list";
        constructor(public filtersQuery: TrainingExamResultsFilterParams, public contextId: string) {}
    }

    export class GetExamResultsList {
        public static readonly type = "[Results] Get exam results list";
        constructor(public filtersQuery: TrainingExamResultsFilterParams, public contextId: string) {}
    }
}
