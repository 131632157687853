<dtm-ui-select-field [isClearable]="false" [formControl]="operationManualControl">
    <dtm-ui-select-option *ngFor="let operationsManual of operationsManuals$ | ngrxPush" [value]="operationsManual">
        {{
            "dtmWebAppLibOpsMan.header.selectVersionLabel"
                | transloco : { version: operationsManual.version, isPublished: operationsManual.isPublished }
        }}
        <span hint>
            {{
                "dtmWebAppLibOpsMan.header.selectVersionHintLabel"
                    | transloco
                        : {
                              isPublished: operationsManual.isPublished,
                              date: operationsManual.lastUpdate | ngrxPush | localizeDate : { dateStyle: "short", timeStyle: "short" }
                          }
            }}
        </span>
    </dtm-ui-select-option>
</dtm-ui-select-field>
<div class="actions">
    <button type="button" class="button-secondary download-pdf-button" (click)="tryPdfDownload()">
        <dtm-ui-icon name="download"></dtm-ui-icon>
        {{ "dtmWebAppLibOpsMan.header.downloadPdfButtonLabel" | transloco }}
    </button>
    <button *ngIf="isCreateNewVersionButtonVisible$ | ngrxPush" type="button" class="button-primary" (click)="tryNewVersionCreate()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "dtmWebAppLibOpsMan.header.createNewVersionButtonLabel" | transloco }}
    </button>
    <button *ngIf="isPublishButtonVisible$ | ngrxPush" type="button" class="button-primary" (click)="tryPublish()">
        {{ "dtmWebAppLibOpsMan.header.publishButtonLabel" | transloco }}
    </button>
</div>
