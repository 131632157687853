import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from "@angular/material/legacy-autocomplete";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime } from "rxjs";
import { tap } from "rxjs/operators";
import { OperationSearchItem } from "../../services/specific-permit-application.models";

interface OperationSearchControlComponentState {
    options: OperationSearchItem[];
    isProcessing: boolean;
    hasErrors: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-operation-search-control[options]",
    templateUrl: "./operation-search-control.component.html",
    styleUrls: ["./operation-search-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperationSearchControlComponent), multi: true },
    ],
})
export class OperationSearchControlComponent implements ControlValueAccessor {
    @Input() public set options(value: OperationSearchItem[] | undefined) {
        this.localStore.patchState({ options: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public readonly searchTextChange = new EventEmitter<string>();

    protected readonly options$ = this.localStore.selectByKey("options");
    protected readonly hasErrors$ = this.localStore.selectByKey("hasErrors");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    protected readonly searchControl = new FormControl<OperationSearchItem | string>("", {
        nonNullable: true,
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: OperationSearchItem | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<OperationSearchControlComponentState>) {
        this.localStore.setState({
            options: [],
            isProcessing: false,
            hasErrors: false,
        });

        this.searchControl.valueChanges
            .pipe(
                tap((value) => {
                    if (!value || this.isSearchQueryValue(value)) {
                        this.propagateChange(null);
                    }
                }),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                tap((value) => {
                    if (this.isSearchQueryValue(value)) {
                        this.searchTextChange.next(value);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public registerOnChange(fn: (value: OperationSearchItem | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: OperationSearchItem | null): void {
        if (value) {
            this.searchControl.reset(value, { emitEvent: false });
        } else {
            this.searchControl.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchControl.disable();
        } else {
            this.searchControl.enable();
        }
    }

    protected displayAutocompleteValueFunction(option: OperationSearchItem): string {
        return option.name;
    }

    protected selectOperation({ option: { value } }: MatAutocompleteSelectedEvent): void {
        this.propagateChange(value);
    }

    protected setHasErrors(hasErrors: boolean): void {
        this.localStore.patchState({ hasErrors });
    }

    private isSearchQueryValue(value: OperationSearchItem | string): value is string {
        return typeof value === "string";
    }
}
