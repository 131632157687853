import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DEFAULT_LANG, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Training } from "../../../services/specific-permit-application.models";

interface TrainingsComponentState {
    basicTrainings: Training[];
    additionalTrainings: string[];
    activeLanguage: LanguageCode;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-trainings",
    templateUrl: "./trainings.component.html",
    styleUrls: ["../../common.scss", "./trainings.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TrainingsComponent {
    @Input() public set basicTrainings(value: Training[] | undefined) {
        this.localStore.patchState({ basicTrainings: value ?? [] });
    }

    @Input() public set additionalTrainings(value: string[] | undefined) {
        this.localStore.patchState({ additionalTrainings: value ?? [] });
    }

    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value ?? DEFAULT_LANG });
    }

    protected readonly trainings$ = combineLatest([
        this.localStore.selectByKey("basicTrainings"),
        this.localStore.selectByKey("additionalTrainings"),
        this.localStore.selectByKey("activeLanguage"),
    ]).pipe(
        map(([basicTrainings, additionalTrainings, activeLanguage]) => [
            ...basicTrainings.map((training) => training.name[activeLanguage] ?? training.name[DEFAULT_LANG]),
            ...additionalTrainings,
        ])
    );

    constructor(private readonly localStore: LocalComponentStore<TrainingsComponentState>) {
        this.localStore.setState({
            basicTrainings: [],
            additionalTrainings: [],
            activeLanguage: DEFAULT_LANG,
        });
    }
}
