import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { AttorneyPowerEmptyStateComponent } from "./components/attorney-power-empty-state/attorney-power-empty-state.component";
import { AttorneyPowerFormComponent } from "./components/attorney-power-form/attorney-power-form.component";
import { AttorneyPowerListComponent } from "./components/attorney-power-list/attorney-power-list.component";
import { AttorneyPowerComponent } from "./components/attorney-power/attorney-power.component";
import { OperatorCompanyInfoComponent } from "./components/operator-address/operator-company-info.component";
import { OperatorAvatarComponent } from "./components/operator-avatar/operator-avatar.component";
import { OperatorNumberComponent } from "./components/operator-number/operator-number.component";
import { OperatorProfileContainerComponent } from "./components/operator-profile-container/operator-profile-container.component";
import { CanEnterOperatorProfileGuard } from "./guards/can-enter-on-operator-profile.guard";
import { OperatorProfileApiService } from "./services/operator-profile.api.service";
import { OperatorProfileState } from "./state/operator-profile.state";

@NgModule({
    imports: [
        CommonModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        SharedUiModule,
        MatCardModule,
        NgxsModule.forFeature([OperatorProfileState]),
        SharedWebAppModule,
        ClipboardModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatMenuModule,
        NgxMaskModule,
    ],
    providers: [
        CanEnterOperatorProfileGuard,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppOperatorProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: OperatorProfileApiService,
            useValue: undefined,
        },
    ],
    declarations: [
        OperatorProfileContainerComponent,
        OperatorNumberComponent,
        OperatorAvatarComponent,
        OperatorCompanyInfoComponent,
        AttorneyPowerComponent,
        AttorneyPowerEmptyStateComponent,
        AttorneyPowerFormComponent,
        AttorneyPowerListComponent,
    ],
    exports: [OperatorCompanyInfoComponent, AttorneyPowerComponent, AttorneyPowerEmptyStateComponent, AttorneyPowerListComponent],
})
export class OperatorProfileModule {
    public static forRoot(): ModuleWithProviders<OperatorProfileModule> {
        return {
            ngModule: OperatorProfileModule,
            providers: [
                {
                    provide: OperatorProfileApiService,
                    useClass: OperatorProfileApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<OperatorProfileModule> {
        class FilesUploadApiServiceMock {
            public uploadFile() {}
            public getFile() {}
            public getFileUrl() {}
            public getFilesCompressed() {}
            public manageUploadError() {}
        }

        return {
            ngModule: OperatorProfileModule,
            providers: [
                {
                    provide: OperatorProfileApiService,
                    useValue: null,
                },
                { provide: FILES_UPLOAD_API_PROVIDER, useClass: FilesUploadApiServiceMock },
            ],
        };
    }
}
