import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MissionCategory } from "@dtm-frontend/shared/mission";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { OperationOperator } from "../../../services/specific-permit-application.models";

interface OperatorInfoComponentState {
    operator: OperationOperator | undefined;
    isExpanded: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-operator-info[operator]",
    templateUrl: "./operator-info.component.html",
    styleUrls: ["../../common.scss", "./operator-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorInfoComponent {
    @Input() public set operator(value: OperationOperator | undefined) {
        this.localStore.patchState({ operator: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly MissionCategory = MissionCategory;
    protected readonly operator$ = this.localStore.selectByKey("operator").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<OperatorInfoComponentState>) {
        this.localStore.setState({
            operator: undefined,
            isExpanded: true,
        });
    }
}
