import { AuthenticationDetails, LegalOperatorFormValue, OperatorMigrationDetails } from "../models/operator-migration.models";

export namespace OperatorMigrationActions {
    export class ImportDetails {
        public static readonly type = "[OperatorMigration] Import operator detail";

        constructor(public credentials: AuthenticationDetails) {}
    }

    export class MigrateEnterpriseOperator {
        public static readonly type = "[OperatorMigration] Migrate enterprise operator";

        constructor(public migrationDetails: LegalOperatorFormValue) {}
    }

    export class MigratePersonalOperator {
        public static readonly type = "[OperatorMigration] Migrate personal operator";

        constructor(public migrationDetails: OperatorMigrationDetails) {}
    }

    export class ResetPassword {
        public static readonly type = "[OperatorMigration] Reset password";

        constructor(public email: string) {}
    }
}
