<ng-container *ngrxLet="chapter$ as chapter">
    <section class="heading">
        <h2 class="header">{{ chapter.name }}</h2>
        <button type="button" class="button-secondary" (click)="guideShow.emit()">
            <dtm-ui-icon name="question"></dtm-ui-icon>
            {{ "dtmWebAppLibOpsMan.chapter.guideButtonLabel" | transloco }}
        </button>
    </section>
    <section class="content-wrapper" *ngrxLet="editedChapterId$ as editedChapterId">
        <dtm-web-app-lib-opsman-attachments-list [chapter]="chapter"></dtm-web-app-lib-opsman-attachments-list>
        <ng-container *ngrxLet="areActionsBlocked$ as areActionsBlocked">
            <dtm-web-app-lib-opsman-chapter-simple
                *ngFor="let subchapter of chapter.subchapters"
                [chapter]="subchapter"
                [isGuideButtonVisible]="false"
                [isEditMode]="editedChapterId === subchapter.id"
                [areActionsBlocked]="areActionsBlocked && editedChapterId !== subchapter.id"
                isNested
                (editModeChange)="editModeChange.emit($event)"
                (otherActionsBlock)="otherActionsBlock.emit($event)"
                (contentSave)="contentSave.emit($event)"
                (attachmentsManage)="attachmentsManage.emit($event)"
                (guideShow)="guideShow.emit()"
                (dtmUiScrollHook)="registerSubchapterScrollHook($event, subchapter.id)"
                [dtmUiScrollHookOptions]="{ behavior: 'smooth', block: 'start' }"
            ></dtm-web-app-lib-opsman-chapter-simple>
        </ng-container>
    </section>
</ng-container>
