<dtm-ui-expandable-panel
    *ngrxLet="{ soraResult: soraResult$, isExpanded: isExpanded$ } as vm"
    [isExpanded]="vm.isExpanded"
    [hasHeaderSeparator]="false"
    class="sora-panel"
>
    <h2 headerSlot>{{ "dtmWebAppLibMission.summaryStep.analysis.finalSoraIssuesTitle" | transloco }}</h2>

    <dtm-ui-label-value [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.finalGrcLabel' | transloco">
        {{ (vm.soraResult?.finalGrc | grcLevel) ?? ("dtmWebAppLibMission.summaryStep.analysis.sora.noDataLabel" | transloco) }}
        <dtm-web-app-lib-sora-issues-warning [grc]="vm.soraResult?.finalGrc"></dtm-web-app-lib-sora-issues-warning>
    </dtm-ui-label-value>

    <dtm-ui-label-value [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.residualArcLabel' | transloco">
        {{
            (vm.soraResult?.residualArc ?? vm.soraResult?.initialArc | arcLabel) ??
                ("dtmWebAppLibMission.summaryStep.analysis.sora.noDataLabel" | transloco)
        }}
        <dtm-web-app-lib-sora-issues-warning
            [arc]="vm.soraResult?.residualArc ?? vm.soraResult?.initialArc"
        ></dtm-web-app-lib-sora-issues-warning>
    </dtm-ui-label-value>

    <dtm-ui-label-value [label]="'dtmWebAppLibMission.summaryStep.analysis.sora.sailLabel' | transloco">
        <span [class.sail-info]="vm.soraResult?.sail">
            {{
                (vm.soraResult?.finalGrc | invoke : isCertifiedCategory)
                    ? ("dtmWebAppLibMission.summaryStep.analysis.sora.certifiedCategoryLabel" | transloco)
                    : ((vm.soraResult?.sail | sailLevel) ?? "dtmWebAppLibMission.summaryStep.analysis.sora.noDataLabel" | transloco)
            }}
        </span>
        <dtm-web-app-lib-sora-issues-warning
            [sail]="vm.soraResult?.sail"
            [isCertifiedCategory]="vm.soraResult?.finalGrc | invoke : isCertifiedCategory"
        ></dtm-web-app-lib-sora-issues-warning>
    </dtm-ui-label-value>
</dtm-ui-expandable-panel>
