import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { FilesGroup } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import { AttachmentsHelperService } from "../../services/attachments-helper.service";
import { Chapter, ChapterAttachmentType } from "../../services/operations-manual.models";

interface AttachmentsListComponentState {
    chapter: Chapter | undefined;
}

@Component({
    selector: "dtm-web-app-lib-opsman-attachments-list[chapter]",
    templateUrl: "./attachments-list.component.html",
    styleUrls: ["./attachments-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AttachmentsListComponent {
    @Input() public set chapter(value: Chapter | undefined) {
        this.localStore.patchState({ chapter: value });
    }

    @HostBinding("class.empty") protected get isEmpty() {
        return !this.localStore.selectSnapshotByKey("chapter")?.attachments.length;
    }

    private readonly chapter$ = this.localStore.selectByKey("chapter").pipe(RxjsUtils.filterFalsy());
    protected readonly attachments$ = this.chapter$.pipe(map((chapter) => this.prepareAttachments(chapter)));
    protected readonly hasAttachments$ = this.chapter$.pipe(map((chapter) => chapter.attachments.length > 0));
    protected readonly additionalPathParams$ = this.chapter$.pipe(map((chapter) => ({ chapterId: chapter.id })));

    constructor(
        private readonly localStore: LocalComponentStore<AttachmentsListComponentState>,
        private readonly attachmentsHelperService: AttachmentsHelperService
    ) {
        this.localStore.setState({
            chapter: undefined,
        });
    }

    private prepareAttachments(chapter: Chapter): FilesGroup<ChapterAttachmentType>[] {
        return this.attachmentsHelperService.convertAttachmentsToFilesGroups(chapter.attachments, chapter.attachmentsFeature.config);
    }
}
