import { InjectionToken } from "@angular/core";

export interface OperatorMigrationEndpoints {
    importOperatorDetails: string;
    migrateEnterpriseOperator: string;
    migratePersonalOperator: string;
    resetPassword: string;
}

export const OPERATOR_MIGRATION_ENDPOINTS = new InjectionToken<OperatorMigrationEndpoints>("Operator migration endpoints");
