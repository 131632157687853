import { GRAMS_IN_KILOGRAM, METERS_IN_KILOMETER, SECONDS_IN_HOUR } from "@dtm-frontend/shared/utils";
import {
    EditableCustomUavInitialSetup,
    EditableUavSetup,
    Equipment,
    EquipmentItem,
    EquipmentParachute,
    EquipmentType,
    MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS,
    NavigationAccuracyClass,
    Tracking,
} from "../../../../services/uav.models";

export interface Accessories {
    equipment: EditableEquipment[];
    parachuteEquipment: EditableParachuteEquipment | null;
    trackings: EditableTracking[];
}

export interface EditableEquipment {
    id?: string;
    type: EquipmentType;
    name: string;
    isEmbedded: boolean;
    weight: number;
}

export interface EditableParachuteEquipment {
    id?: string;
    name: string;
    isEmbedded: boolean;
    weight: number;
    minHeight: number;
    hasCeCertificate: boolean;
    descentSpeed: number;
    maxWindSpeed: number;
    minOperatingTemperature: number;
    maxOperatingTemperature: number;
    isAstmF332218Compliant: boolean;
}

export interface EditableTracking {
    id?: string;
    identifier: string;
    trackerId: string;
    isEmbedded: boolean;
    weight: number;
    flightNavigationAccuracy: NavigationAccuracyClass;
    takeoffAndLandingNavigationAccuracy: NavigationAccuracyClass;
}

export class AccessoriesUtils {
    public static getFormValuesBySetupDefaults({ equipment, trackings }: EditableUavSetup): Accessories {
        const equipmentFormValues: EditableEquipment[] = [
            ...equipment.camera.map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.Camera)),
            ...equipment.propellersGuards.map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.PropellersGuards)),
            ...equipment.navigationLighting.map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.NavigationLighting)),
            ...equipment.nightLighting.map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.NightLighting)),
            ...equipment.strobeLighting.map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.StrobeLighting)),
            ...equipment.fts.map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.Fts)),
        ];

        const customParachute = equipment.parachute.length ? equipment.parachute[0] : undefined;
        const parachuteEquipmentFormValues = this.mapEquipmentParachuteToEditableParachuteEquipment(customParachute);
        const trackingsFormValues: EditableTracking[] = trackings.map((tracking) => this.mapTrackingToEditableTracking(tracking));

        return {
            equipment: equipmentFormValues,
            parachuteEquipment: parachuteEquipmentFormValues,
            trackings: trackingsFormValues,
        };
    }

    public static getCustomUavFormValuesBySetupDefaults({ equipment, trackings }: EditableCustomUavInitialSetup): Accessories {
        const equipmentFormValues: EditableEquipment[] = [
            ...(equipment?.camera ?? []).map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.Camera)),
            ...(equipment?.propellersGuards ?? []).map((item) =>
                this.mapEquipmentItemToEditableEquipment(item, EquipmentType.PropellersGuards)
            ),
            ...(equipment?.navigationLighting ?? []).map((item) =>
                this.mapEquipmentItemToEditableEquipment(item, EquipmentType.NavigationLighting)
            ),
            ...(equipment?.nightLighting ?? []).map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.NightLighting)),
            ...(equipment?.strobeLighting ?? []).map((item) =>
                this.mapEquipmentItemToEditableEquipment(item, EquipmentType.StrobeLighting)
            ),
            ...(equipment?.fts ?? []).map((item) => this.mapEquipmentItemToEditableEquipment(item, EquipmentType.Fts)),
        ];

        const parachuteEquipmentFormValues = this.mapEquipmentParachuteToEditableParachuteEquipment(equipment?.parachute?.[0]);
        const trackingsFormValues: EditableTracking[] = trackings.map((tracking) => this.mapTrackingToEditableTracking(tracking));

        return {
            equipment: equipmentFormValues,
            parachuteEquipment: parachuteEquipmentFormValues,
            trackings: trackingsFormValues,
        };
    }

    public static prepareResultEquipmentSetup({ equipment, parachuteEquipment }: Accessories): Equipment {
        const result: Equipment = {
            camera: [],
            propellersGuards: [],
            navigationLighting: [],
            nightLighting: [],
            strobeLighting: [],
            fts: [],
            parachute: parachuteEquipment ? [this.mapEditableParachuteEquipmentToEquipmentParachute(parachuteEquipment)] : [],
        };

        for (const equipmentItem of equipment) {
            if (equipmentItem.type === EquipmentType.Camera) {
                result.camera.push(this.mapEditableEquipmentToEquipmentItem(equipmentItem));
            }
            if (equipmentItem.type === EquipmentType.PropellersGuards) {
                result.propellersGuards.push(this.mapEditableEquipmentToEquipmentItem(equipmentItem));
            }
            if (equipmentItem.type === EquipmentType.NavigationLighting) {
                result.navigationLighting.push(this.mapEditableEquipmentToEquipmentItem(equipmentItem));
            }
            if (equipmentItem.type === EquipmentType.NightLighting) {
                result.nightLighting.push(this.mapEditableEquipmentToEquipmentItem(equipmentItem));
            }
            if (equipmentItem.type === EquipmentType.StrobeLighting) {
                result.strobeLighting.push(this.mapEditableEquipmentToEquipmentItem(equipmentItem));
            }
            if (equipmentItem.type === EquipmentType.Fts) {
                result.fts.push(this.mapEditableEquipmentToEquipmentItem(equipmentItem));
            }
        }

        return result;
    }

    public static prepareResultTrackingSetup(trackings: EditableTracking[]): Tracking[] {
        return trackings.map((tracking) => this.mapEditableTrackingToTracking(tracking));
    }

    private static mapEquipmentItemToEditableEquipment(item: EquipmentItem, type: EquipmentType): EditableEquipment {
        return {
            id: item.id,
            type,
            name: item.name ?? "",
            isEmbedded: item.isEmbedded,
            weight: item.weight * GRAMS_IN_KILOGRAM,
        };
    }

    private static mapEditableEquipmentToEquipmentItem(item: EditableEquipment): EquipmentItem {
        return {
            id: item.id,
            name: item.name,
            weight: item.weight / GRAMS_IN_KILOGRAM,
            isEmbedded: item.isEmbedded,
        };
    }

    private static mapEquipmentParachuteToEditableParachuteEquipment(parachute?: EquipmentParachute): EditableParachuteEquipment | null {
        if (!parachute) {
            return null;
        }

        return {
            id: parachute.id,
            name: parachute.name ?? "",
            weight: parachute.weight * GRAMS_IN_KILOGRAM,
            isEmbedded: parachute.isEmbedded,
            minHeight: parachute.minHeight,
            hasCeCertificate: parachute.hasCeCertificate,
            descentSpeed: parachute.descentSpeed,
            maxWindSpeed: +AccessoriesUtils.convertMetersPerSecondToKilometersPerHour(parachute.maxWindSpeed).toFixed(
                MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS
            ),
            minOperatingTemperature: parachute.minOperatingTemperature,
            maxOperatingTemperature: parachute.maxOperatingTemperature,
            isAstmF332218Compliant: parachute.isAstmF332218Compliant,
        };
    }

    private static mapEditableParachuteEquipmentToEquipmentParachute(parachute: EditableParachuteEquipment): EquipmentParachute {
        return {
            id: parachute.id,
            name: parachute.name,
            weight: parachute.weight / GRAMS_IN_KILOGRAM,
            minHeight: parachute.minHeight,
            hasCeCertificate: parachute.hasCeCertificate,
            isEmbedded: parachute.isEmbedded,
            descentSpeed: parachute.descentSpeed,
            maxWindSpeed: parachute.maxWindSpeed
                ? AccessoriesUtils.convertKilometersPerHourToMetersPerSecond(
                      +parachute.maxWindSpeed.toFixed(MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS)
                  )
                : 0,
            minOperatingTemperature: parachute.minOperatingTemperature,
            maxOperatingTemperature: parachute.maxOperatingTemperature,
            isAstmF332218Compliant: parachute.isAstmF332218Compliant,
        };
    }

    private static mapTrackingToEditableTracking(tracking: Tracking): EditableTracking {
        return {
            ...tracking,
            weight: tracking.weight * GRAMS_IN_KILOGRAM,
        };
    }

    private static mapEditableTrackingToTracking(tracking: EditableTracking): Tracking {
        return {
            ...tracking,
            weight: tracking.isEmbedded ? 0 : tracking.weight / GRAMS_IN_KILOGRAM,
        };
    }

    private static convertMetersPerSecondToKilometersPerHour(value: number) {
        return (value * SECONDS_IN_HOUR) / METERS_IN_KILOMETER;
    }

    private static convertKilometersPerHourToMetersPerSecond(value: number) {
        return (value * METERS_IN_KILOMETER) / SECONDS_IN_HOUR;
    }
}
