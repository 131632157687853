<ng-template #issueStatusIconTemplate let-status="status">
    <ng-container [ngSwitch]="status">
        <dtm-ui-icon
            *ngSwitchCase="MissionPlanAnalysisIssueStatus.Success"
            [matTooltip]="'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.success' | transloco"
            name="checkbox-circle"
            class="issue-status-success"
        ></dtm-ui-icon>
        <dtm-ui-icon
            *ngSwitchCase="MissionPlanAnalysisIssueStatus.Error"
            [matTooltip]="'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.error' | transloco"
            name="error-warning-fill"
            class="issue-status-error"
        ></dtm-ui-icon>
        <dtm-ui-icon
            *ngSwitchCase="MissionPlanAnalysisIssueStatus.Hold"
            [matTooltip]="'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.hold' | transloco"
            name="error-warning-fill"
            class="issue-status-error"
        ></dtm-ui-icon>
        <dtm-ui-icon
            *ngSwitchCase="MissionPlanAnalysisIssueStatus.Warning"
            [matTooltip]="'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.warning' | transloco"
            name="information"
            class="issue-status-warn"
        ></dtm-ui-icon>
        <dtm-ui-icon
            *ngSwitchCase="MissionPlanAnalysisIssueStatus.Info"
            [matTooltip]="'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.info' | transloco"
            name="information"
            class="issue-status-info"
        ></dtm-ui-icon>
        <dtm-ui-icon
            *ngSwitchCase="MissionPlanAnalysisIssueStatus.FatalError"
            [matTooltip]="'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.error' | transloco"
            name="error-warning-fill"
            class="issue-status-error"
        ></dtm-ui-icon>
    </ng-container>
</ng-template>

<ng-template #issuesListTemplate let-issues="issues">
    <ul *ngIf="issues?.length" class="issues-container">
        <li *ngFor="let issue of issues">
            <p class="issue-item">
                <ng-template
                    [ngTemplateOutlet]="issueStatusIconTemplate"
                    [ngTemplateOutletContext]="{ status: issue.status }"
                ></ng-template>
                {{ issue | translateAnalysisIssue }}
            </p>
        </li>
    </ul>
</ng-template>

<ng-template #statusBadgeTemplate let-status="status" let-successLabel="successLabel" let-failureLabel="failureLabel">
    <ng-container [ngSwitch]="status">
        <div class="chip-container">
            <div class="chip success" *ngSwitchCase="MissionPlanAnalysisOptionStatus.Success">
                <dtm-ui-icon name="checkbox-circle" class="status-icon"></dtm-ui-icon>
                <p class="statusLabel" data-testid="success-status-icon">{{ successLabel }}</p>
            </div>
            <div class="chip error" *ngSwitchCase="MissionPlanAnalysisOptionStatus.Hold">
                <dtm-ui-icon name="close-circle" class="status-icon"></dtm-ui-icon>
                <p class="statusLabel">{{ "dtmWebAppLibMission.summaryStep.analysis.statusTooltip.hold" | transloco }}</p>
            </div>
            <mat-spinner
                *ngSwitchCase="MissionPlanAnalysisOptionStatus.Pending"
                diameter="24"
                color="accent"
                class="issue-status-pending"
                [matTooltip]="'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.pending' | transloco"
            ></mat-spinner>
            <div class="chip error" *ngSwitchDefault>
                <dtm-ui-icon name="close-circle" class="status-icon"></dtm-ui-icon>
                <p class="statusLabel">{{ failureLabel }}</p>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-container
    *ngrxLet="{
        analysisStatus: analysisStatus$,
        zones: zones$,
        selectedZoneId: selectedZoneId$,
        soraSettings: soraSettings$,
        collisionMission: collisionMission$,
        nearbyMissions: nearbyMissions$,
        areOtherMissionsProcessing: areOtherMissionsProcessing$
    } as vm"
>
    <ng-template #loaderTemplate>
        <div class="analysis-loader">
            <mat-spinner diameter="50"></mat-spinner>
            <span>{{ "dtmWebAppLibMission.summaryStep.analysis.loadingMessage" | transloco }}</span>
        </div>
    </ng-template>

    <div class="analysis-panel" *ngIf="vm.analysisStatus; else loaderTemplate">
        <dtm-ui-expandable-panel *ngIf="vm.analysisStatus.caaPermit" [isExpanded]="true" [hasHeaderSeparator]="false">
            <h2 headerSlot>
                {{ "dtmWebAppLibMission.summaryStep.analysis.caaPermitIssuesTitle" | transloco }}
            </h2>
            <ng-template
                [ngTemplateOutlet]="statusBadgeTemplate"
                [ngTemplateOutletContext]="{
                    status: vm.analysisStatus.caaPermit.status,
                    successLabel: 'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.caaPermitSuccess' | transloco,
                    failureLabel: 'dtmWebAppLibMission.summaryStep.analysis.statusTooltip.caaPermitFailure' | transloco
                }"
            ></ng-template>
            <ng-template
                [ngTemplateOutlet]="issuesListTemplate"
                [ngTemplateOutletContext]="{ issues: vm.analysisStatus.caaPermit.issues }"
            ></ng-template>
        </dtm-ui-expandable-panel>
        <dtm-web-app-lib-zone-analysis
            [zones]="vm.zones"
            [airspaceAnalysis]="vm.analysisStatus.airspace"
            [evaluationIssues]="vm.analysisStatus.evaluation"
            [selectedZoneId]="vm.selectedZoneId"
            (zoneSelect)="zoneSelect.emit($event)"
        >
            <dtm-mission-additional-analysis-info
                [additionalZones]="vm.zones.additionalZones"
                [selectedZoneId]="vm.selectedZoneId"
                [evaluation]="vm.analysisStatus.evaluation"
                (zoneSelect)="zoneSelect.emit($event)"
            ></dtm-mission-additional-analysis-info>
        </dtm-web-app-lib-zone-analysis>

        <!-- TODO replace it with <dtm-mission-operational-area-geometry> component - REJ-2976 -->
        <dtm-web-app-lib-operational-area-geometry
            *ngIf="vm.analysisStatus.sora"
            [operationalGeometryData]="operationalGeometryData$ | ngrxPush"
        ></dtm-web-app-lib-operational-area-geometry>

        <ng-container *ngIf="vm.analysisStatus.traffic">
            <!-- TODO replace it with <dtm-mission-traffic-analysis> component - REJ-2976 -->
            <dtm-web-app-lib-traffic-analysis
                [nearbyMissions]="vm.nearbyMissions"
                [collisionMission]="vm.collisionMission"
                [isProcessing]="vm.areOtherMissionsProcessing"
                (flyToRoute)="flyToRoute.emit($event)"
                (flyToMainMissionRoute)="flyToMainMissionRoute.emit()"
                (otherMissionSelected)="otherMissionSelected.emit($event)"
            ></dtm-web-app-lib-traffic-analysis>
        </ng-container>

        <dtm-ui-expandable-panel [isExpanded]="false" *ngIf="vm.analysisStatus.sora" [hasHeaderSeparator]="false">
            <h2 class="section-title" headerSlot>
                {{ "dtmWebAppLibMission.summaryStep.analysis.groundRiskTitle" | transloco }}
            </h2>
            <dtm-mission-population-density
                [populationDensity]="vm.analysisStatus.sora.result?.populationDensity"
            ></dtm-mission-population-density>
        </dtm-ui-expandable-panel>

        <ng-container *ngIf="vm.analysisStatus.sora">
            <!-- TODO replace it with <dtm-mission-operational-area-airspace-risk> component - REJ-2976 -->
            <dtm-web-app-lib-operational-area-airspace-risk
                [airRiskMitigations]="vm.soraSettings?.airRiskMitigations"
                [airRisk]="vm.analysisStatus.sora.result?.airRisk"
            ></dtm-web-app-lib-operational-area-airspace-risk>
            <!-- TODO replace it with <dtm-mission-initial-sora-issues> component - REJ-2976 -->
            <dtm-web-app-lib-initial-sora-issues
                isExpanded
                [soraSettings]="vm.soraSettings"
                [soraResult]="vm.analysisStatus.sora.result"
                [airspaceAnalysis]="vm.analysisStatus.airspace"
            ></dtm-web-app-lib-initial-sora-issues>
        </ng-container>
    </div>
</ng-container>
