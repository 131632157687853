<ng-container *ngrxLet="identityDocument$ as identityDocument">
    <ng-container *ngrxLet="isEditModeOn$ as isEditModeOn">
        <dtm-ui-editable-card
            *ngIf="identityDocument || isEditModeOn; else noIdentityDocumentTemplate"
            [editionLabel]="'dtmWebAppUserProfile.identityCard.identityCardHeader' | transloco"
            [isSaveButtonDisabled]="identityDocumentFormGroup.controls.document.invalid"
            (editModeChange)="clearForm($event)"
            (actionSave)="saveDocument()"
            [isEditModeOn]="isEditModeOn"
            [isEditButtonVisible]="!identityDocument"
            [canLeaveEditModeOnSave]="false"
        >
            <ng-container labelSlot>
                {{ "dtmWebAppUserProfile.identityCard.identityCardHeader" | transloco }}
                <dtm-ui-popover [ngClass]="{ hidden: !identityDocument?.statusReason }" class="popover">
                    <div class="popover-header">{{ "dtmWebAppUserProfile.identityCard.statusReasonLabel" | transloco }}</div>
                    <p>{{ identityDocument?.statusReason }}</p>
                </dtm-ui-popover>
            </ng-container>
            <ng-container additionalActions>
                <dtm-ui-multiple-actions (edit)="setEditMode(true)" (delete)="tryDeleteIdentityDocument()"></dtm-ui-multiple-actions>
            </ng-container>
            <ng-container *ngIf="identityDocument" previewContent>
                <dtm-ui-identity-document-status-badge [status]="identityDocument.status"></dtm-ui-identity-document-status-badge>
                <div class="document-info">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppUserProfile.identityCard.documentTypeLabel' | transloco"
                        [value]="identityDocument.type | identityDocumentType"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'dtmWebAppUserProfile.identityCard.expirationDateLabel' | transloco"
                        [value]="identityDocument.expirationDate | localizeDate"
                    ></dtm-ui-label-value>
                </div>
                <dtm-ui-label-value [label]="'dtmWebAppUserProfile.identityCard.attachmentLabel' | transloco">
                    <div class="attachment">
                        <dtm-ui-icon name="attachment"></dtm-ui-icon>
                        <span>{{ identityDocument.filename }}</span>
                        <button type="button" class="button-icon" (click)="download.emit()">
                            <dtm-ui-icon name="download"></dtm-ui-icon>
                        </button>
                    </div>
                </dtm-ui-label-value>
            </ng-container>
            <ng-container editableContent [formGroup]="identityDocumentFormGroup">
                <dtm-ui-radio-group formControlName="documentType">
                    <dtm-ui-label-value [label]="'dtmWebAppUserProfile.identityCard.chooseDocumentTypeLabel' | transloco">
                        <div class="radio-content">
                            <dtm-ui-radio-field [value]="IdentityDocumentType.IdCard">
                                {{ "dtmWebAppUserProfile.identityCard.idCardLabel" | transloco }}
                            </dtm-ui-radio-field>
                            <dtm-ui-radio-field [value]="IdentityDocumentType.Passport">
                                {{ "dtmWebAppUserProfile.identityCard.passportLabel" | transloco }}
                            </dtm-ui-radio-field>
                        </div>
                    </dtm-ui-label-value>
                </dtm-ui-radio-group>
                <dtm-ui-date-field class="expiration-date-control">
                    <label for="expirationDateControl">
                        {{ "dtmWebAppUserProfile.identityCard.documentExpirationDateLabel" | transloco }}
                    </label>
                    <input
                        id="expirationDateControl"
                        formControlName="expirationDate"
                        matInput
                        [min]="TOMORROW_DATE"
                        [matDatepicker]="expirationDatepicker"
                        *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                        [placeholder]="datePickerPlaceholder"
                    />
                    <mat-datepicker #expirationDatepicker></mat-datepicker>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="identityDocumentFormGroup.controls.expirationDate; name: ['required', 'requiredTouched']"
                    >
                        {{ "dtmWebAppUserProfile.identityCard.requiredFieldError" | transloco }}
                    </div>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="
                            identityDocumentFormGroup.controls.expirationDate;
                            name: ['matDatepickerMin', 'requiredTouched']
                        "
                    >
                        <ng-container *ngIf="!identityDocumentFormGroup.controls.expirationDate.hasError('required')">
                            {{
                                "dtmWebAppUserProfile.identityCard.minDocumentExpirationDateError"
                                    | transloco
                                        : {
                                              minDate:
                                                  identityDocumentFormGroup.controls.expirationDate.errors?.matDatepickerMin?.min ??
                                                      TOMORROW_DATE | localizeDate
                                          }
                            }}
                        </ng-container>
                    </div>
                </dtm-ui-date-field>
                <dtm-ui-files-upload-field
                    [isDownloadAllButtonVisible]="false"
                    [allowedTypes]="ALLOWED_MIME_TYPES"
                    formControlName="document"
                >
                    <label>{{
                        identityDocumentFormGroup.controls.documentType.value === IdentityDocumentType.IdCard
                            ? ("dtmWebAppUserProfile.identityCard.idCardUploadLabel" | transloco)
                            : ("dtmWebAppUserProfile.identityCard.passportUploadLabel" | transloco)
                    }}</label>
                    <span class="field-hint">
                        {{
                            "dtmWebAppUserProfile.identityCard.authorizationDocumentHint"
                                | transloco : { maxFileSize: MAX_ATTACHMENT_SIZE_BYTES | formatFileSize }
                        }}
                    </span>
                    <div class="field-error" *dtmUiFieldHasError="identityDocumentFormGroup.controls.document; name: 'maxlength'">
                        {{ "dtmWebAppUserProfile.identityCard.identityFileUploadMultipleFilesError" | transloco }}
                    </div>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="identityDocumentFormGroup.controls.document; name: ['required', 'requiredTouched']"
                    >
                        {{ "dtmWebAppUserProfile.identityCard.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-files-upload-field>
            </ng-container>
        </dtm-ui-editable-card>
        <ng-template #noIdentityDocumentTemplate>
            <dtm-ui-empty-state>
                <ng-container titleSlot>
                    {{ "dtmWebAppUserProfile.identityCard.identityCardHeader" | transloco }}
                </ng-container>
                <img imageSlot src="assets/images/identity-card-empty-state.svg" />
                <p messageSlot>
                    {{ "dtmWebAppUserProfile.identityCard.noIdentityCardMessage" | transloco }}
                </p>
                <button footerSlot type="button" class="button-primary" (click)="setEditMode(true)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    {{ "dtmWebAppUserProfile.identityCard.addIdentityCardButtonLabel" | transloco }}
                </button>
            </dtm-ui-empty-state>
        </ng-template>
    </ng-container>
</ng-container>
