import { Address, Alpha3CountryCode, IdentityDocumentType, PhoneNumber, UploadedFile } from "@dtm-frontend/shared/ui";
import { ForeignOperatorVerificationDetails, RegisterPilotOperatorLegalGuardian } from "../../shared/models";

export interface RegisterPilotBasicData {
    address: Address;
    isRegisteredInOtherCountry: boolean;
    foreignOperator?: ForeignOperatorVerificationDetails;
    identityDocument?: UploadedFile[];
    identityDocumentExpirationDate?: Date;
    identityDocumentType?: IdentityDocumentType;
    isOriginCountryFlyingDestination?: boolean;
}

export interface RegisterPilotAdditionalData {
    legalGuardian?: RegisterPilotOperatorLegalGuardian;
    insurancePolicyNumber?: string;
    insurancePolicyExpirationDate?: Date;
}

export interface PilotRegistrationError {
    type: PilotRegistrationErrorType;
}

export enum PilotRegistrationErrorType {
    Unknown = "Unknown",
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: Alpha3CountryCode;
    phoneNumber: PhoneNumber;
    languageTag: string;
    email: string;
    legalGuardian?: LegalGuardian;
}

export interface LegalGuardian {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: PhoneNumber;
    email: string;
    address: Address;
}

export interface PilotRegistrationFormValue extends RegisterPilotBasicData, RegisterPilotAdditionalData {}

export interface RegisterPilotSummaryData {
    isAuthenticityOfData: boolean;
    isGeneralDataProtectionRegulation: boolean;
}

export interface RegisteredPilot {
    id: string;
    userId: string;
    pilotNumber: string;
    firstName: string;
    lastName: string;
    insurancePolicyNumber: string;
    address: Address;
}
