import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Validators } from "@angular/forms";
import {
    MissionCategory,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { combineLatest, map, startWith, switchMap } from "rxjs";
import { combineLatestWith, distinctUntilChanged } from "rxjs/operators";
import { HeightType, ItineraryEditorMissionZoneHeightFormData, ZoneHeightLimits } from "../../../../../../models/mission.model";
import {
    MissionWizardItineraryEditorParametersPanelBaseComponent,
    MissionWizardItineraryEditorParametersPanelBaseComponentState,
    POLAND_AMSL_MAX_HEIGHT,
} from "../mission-parameters-panel-base.component";

interface MissionWizardItineraryEditorZoneHeightPanelComponentState
    extends MissionWizardItineraryEditorParametersPanelBaseComponentState<ItineraryEditorMissionZoneHeightFormData> {
    topHeightConstraints: { min: number; max: number };
    missionType: MissionType;
    operationCategory: MissionPlanOperationCategoryOption | undefined;
}

const HEIGHT_STEP = 10;
// eslint-disable-next-line no-magic-numbers
const TOP_HEIGHT_PREDEFINDED_VALUES = [30, 50, 100];

@UntilDestroy()
@Component({
    selector:
        "dtm-web-app-lib-mission-wizard-itinerary-editor-step-zone-height-panel[constraints][formControls][missionType][operationCategory]",
    templateUrl: "./zone-height-panel.component.html",
    styleUrls: ["./zone-height-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardItineraryEditorZoneHeightPanelComponent extends MissionWizardItineraryEditorParametersPanelBaseComponent<
    ItineraryEditorMissionZoneHeightFormData,
    MissionWizardItineraryEditorZoneHeightPanelComponentState
> {
    protected readonly heightTypeOptions = Object.values(HeightType);
    protected readonly MissionType = MissionType;
    protected readonly HeightType = HeightType;
    protected readonly HEIGHT_STEP = HEIGHT_STEP;
    protected readonly TOP_HEIGHT_PREDEFINDED_VALUES = TOP_HEIGHT_PREDEFINDED_VALUES;

    @Input() public set constraints(value: ZoneHeightLimits | undefined) {
        this.localStore.patchState({
            topHeightConstraints: {
                min: value?.min ?? 1,
                max: value?.max ?? 1,
            },
        });
    }

    @Input() public set missionType(value: MissionType) {
        this.localStore.patchState({ missionType: value });

        if (value === MissionType.VLOS) {
            this.localStore.selectSnapshotByKey("formControls")?.zoneHeightType.setValue(HeightType.AGL);
        }
    }

    @Input() public set operationCategory(value: MissionPlanOperationCategoryOption | undefined) {
        this.localStore.patchState({ operationCategory: value });
    }

    protected readonly heightConstraints$ = this.initHeightConstraints();
    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly isComplexScenario$ = this.localStore.selectByKey("operationCategory").pipe(
        map((operationCategory) => {
            if (!operationCategory || operationCategory.type !== MissionCategory.Specific) {
                return false;
            }

            return (
                operationCategory.specificPermitType === MissionPlanSpecificPermitType.Individual ||
                operationCategory.specificPermitType === MissionPlanSpecificPermitType.Luc
            );
        })
    );

    constructor(localStore: LocalComponentStore<MissionWizardItineraryEditorZoneHeightPanelComponentState>) {
        super(localStore);
        this.localStore.setState({
            originalFormControls: undefined,
            formStateController: undefined,
            isDeferred: false,
            isExpanded: true,
            topHeightConstraints: { min: 1, max: 1 },
            formControls: undefined,
            missionType: MissionType.VLOS,
            isDisabled: false,
            operationCategory: undefined,
        });

        this.heightConstraints$
            .pipe(distinctUntilChanged(equal), combineLatestWith(this.formControls$.pipe(RxjsUtils.filterFalsy())), untilDestroyed(this))
            .subscribe(([constraints, form]) => {
                form.zoneTopHeight.setValidators([Validators.max(constraints.top.max)]);
                form.zoneTopHeight.updateValueAndValidity();

                if (form.zoneTopHeight.valid) {
                    form.zoneTopHeight.setErrors(null);

                    return;
                }

                form.zoneTopHeight.setErrors({
                    max: constraints.top.max,
                    actual: form.zoneTopHeight.value,
                });
            });

        this.formControls$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe((controls) => {
            controls.isFlightAroundObstacleStatementAccepted.setValidators((control) =>
                controls.isFlightAroundObstacle.value ? Validators.requiredTrue(control) : null
            );
        });
    }

    protected initHeightConstraints() {
        return this.formControls$.pipe(
            map((formControls) => formControls),
            RxjsUtils.filterFalsy(),
            switchMap((formControls) =>
                combineLatest([
                    formControls.zoneTopHeight.valueChanges.pipe(
                        startWith(formControls.zoneTopHeight.value),
                        map((value) => value ?? 1)
                    ),
                    formControls.zoneHeightType.valueChanges.pipe(startWith(formControls.zoneHeightType.value)),
                    this.localStore.selectByKey("topHeightConstraints"),
                ])
            ),
            map(([zoneTopHeight, zoneHeightType, topHeightConstraints]) => {
                const maxTopHeight = zoneHeightType === HeightType.AGL ? topHeightConstraints?.max ?? 0 : POLAND_AMSL_MAX_HEIGHT;

                const top = {
                    min: Math.max(1, topHeightConstraints.min),
                    max: maxTopHeight,
                };
                const bottom = {
                    min: Math.max(0, topHeightConstraints.min),
                    max: Math.min(maxTopHeight, zoneTopHeight - 1),
                };

                return {
                    top,
                    bottom,
                };
            })
        );
    }
}
