import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import {
    CERTIFIED_CATEGORY_FINAL_GRC,
    MissionCategory,
    MissionPlanAnalysisStatus,
    MissionPlanData,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionType,
    PlanVerificationStatus,
    SoraSettings,
    TacticalMitigationPerformanceRequirementProperty,
} from "@dtm-frontend/shared/mission";
import { DialogService, GeoJSON, InvalidFormScrollableDirective, ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
    MissionDataFormData,
    MissionPlanItineraryWithoutConstraints,
    MissionUAVSetup,
    SoraSettings as OldSoraSettings,
    OperationalGeometryData,
    PublicMissionPlanData,
} from "../../../../models/mission.model";
import { AdditionalInformationSettings } from "../../../mission-notes-and-description/personal-notes.component";
import { MissionWizardSteps } from "../../content/mission-wizard-content.component";
import {
    AnalysisSoraSettingsModalComponent,
    AnalysisSoraSettingsModalComponentData,
} from "./mission-plan-analysis-panel/sora-settings/sora-settings-modal/analysis-sora-settings-modal.component";

interface MissionWizardSummaryStepComponentState {
    analysisStatus: MissionPlanAnalysisStatus | undefined;
    isProcessing: boolean;
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    nearbyMissions: PublicMissionPlanData[];
    collisionMission: PublicMissionPlanData[];
    areOtherMissionsProcessing: boolean;
    missionDataFormData: Partial<MissionDataFormData> | undefined;
    soraSettings: SoraSettings | undefined;
    collisionZones: AirspaceElementsInfo | undefined;
    missionType: MissionType | undefined;
    selectedZoneId: string | undefined;
    setup: MissionUAVSetup | undefined;
    areSoraSettingsEnabled: boolean;
    operationalGeometryData: OperationalGeometryData | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-analysis-step[analysisStatus]",
    templateUrl: "./analysis-step.component.html",
    styles: [".sora-settings-button { display: flex; margin: -4px -8px -4px auto; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardAnalysisStepComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    @Input() public set analysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({
            analysisStatus: value,
        });
    }
    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }
    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }
    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }
    @Input() public set nearbyMissions(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ nearbyMissions: value ?? [] });
    }
    @Input() public set collisionMission(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ collisionMission: value ?? [] });
    }
    @Input() public set areOtherMissionsProcessing(value: BooleanInput) {
        this.localStore.patchState({ areOtherMissionsProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set missionDataFormData(value: Partial<MissionDataFormData> | undefined) {
        this.localStore.patchState({
            missionDataFormData: value,
            areSoraSettingsEnabled: this.getAreSoraSettingsEnabled(value?.category),
        });
    }
    @Input() public set currentPlanItinerary(value: MissionPlanItineraryWithoutConstraints | undefined) {
        if (value?.type !== ItineraryEditorType.None) {
            const soraSettings = value?.soraSettings;
            if (!!soraSettings?.controlledGroundAreaComment && (soraSettings.riskMitigations?.m1A || soraSettings.riskMitigations?.m1B)) {
                soraSettings.riskMitigations = {
                    ...soraSettings.riskMitigations,
                    m1A: undefined,
                    m1B: undefined,
                };

                this.soraSettingsUpdate.next(soraSettings);
            } else if (
                Object.keys(soraSettings?.airRiskMitigations ?? {}).length &&
                (soraSettings?.flightAroundObstacleComment ||
                    soraSettings?.flightLowHeightComment ||
                    soraSettings?.flightSegregatedAirspaceComment)
            ) {
                soraSettings.airRiskMitigations = undefined;

                this.soraSettingsUpdate.next(soraSettings);
            }

            this.localStore.patchState({ soraSettings });
        }
    }
    @Input() public set collisionZones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ collisionZones: value });
    }
    @Input() public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });

        const soraSettings = this.localStore.selectSnapshotByKey("soraSettings");
        if (value !== MissionType.VLOS && soraSettings?.riskMitigations?.m1B) {
            this.soraSettingsUpdate.next({
                ...soraSettings,
                riskMitigations: {
                    ...soraSettings.riskMitigations,
                    m1B: undefined,
                },
            });
        }
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set setup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ setup: value });
    }
    @Input() public set operationalGeometryData(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometryData: value });
    }
    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly next = new EventEmitter<void>();
    @Output() public readonly flyToRoute = new EventEmitter<string>();
    @Output() public readonly flyToMainMissionRoute = new EventEmitter<void>();
    @Output() public readonly flyToZone = new EventEmitter<GeoJSON>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    @Output() public readonly additionalInformationChange = new EventEmitter<AdditionalInformationSettings>();
    @Output() public readonly otherMissionSelected = new EventEmitter<MissionPlanData>();
    @Output() public readonly soraSettingsUpdate = new EventEmitter<OldSoraSettings>();

    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly analysisStatus$ = this.localStore.selectByKey("analysisStatus");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly stepNumber$ = this.localStore.selectByKey("stepNumber");
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount");
    protected readonly nearbyMissions$ = this.localStore.selectByKey("nearbyMissions");
    protected readonly collisionMission$ = this.localStore.selectByKey("collisionMission");
    protected readonly areOtherMissionsProcessing$ = this.localStore.selectByKey("areOtherMissionsProcessing");
    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly oldSoraSettings$: Observable<OldSoraSettings | undefined> = this.soraSettings$; // TODO remove it after refactoring - REJ-3094
    protected readonly isRiskMitigationAdded$ = this.soraSettings$.pipe(map((soraSettings) => this.isRiskMitigationAdded(soraSettings)));
    protected readonly collisionZones$ = this.localStore.selectByKey("collisionZones");
    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly setup$ = this.localStore.selectByKey("setup");
    protected readonly areSoraSettingsEnabled$ = this.localStore.selectByKey("areSoraSettingsEnabled");
    protected readonly operationalGeometryData$ = this.localStore.selectByKey("operationalGeometryData");
    protected readonly MissionCategory = MissionCategory;
    protected readonly MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;
    protected readonly MissionType = MissionType;
    protected readonly TacticalMitigationPerformanceRequirement = TacticalMitigationPerformanceRequirementProperty;
    protected readonly PlanVerificationStatus = PlanVerificationStatus;

    constructor(
        private readonly localStore: LocalComponentStore<MissionWizardSummaryStepComponentState>,
        private readonly dialogService: DialogService,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.localStore.setState({
            analysisStatus: undefined,
            isProcessing: false,
            stepNumber: undefined,
            stepsAmount: undefined,
            nearbyMissions: [],
            collisionMission: [],
            areOtherMissionsProcessing: false,
            missionDataFormData: undefined,
            soraSettings: undefined,
            collisionZones: undefined,
            missionType: undefined,
            selectedZoneId: undefined,
            setup: undefined,
            areSoraSettingsEnabled: false,
            operationalGeometryData: undefined,
        });
    }

    protected goToSummaryStep() {
        const analysisStatus = this.localStore.selectSnapshotByKey("analysisStatus");
        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");

        if (
            !analysisStatus ||
            isProcessing ||
            (analysisStatus.sora?.result?.finalGrc && analysisStatus.sora.result.finalGrc >= CERTIFIED_CATEGORY_FINAL_GRC)
        ) {
            return;
        }

        const { applyForSpecificPermit, isPlanSubmittable } = analysisStatus;

        if (applyForSpecificPermit || isPlanSubmittable) {
            this.next.emit();
        }
    }

    protected getAreSoraSettingsEnabled(category: MissionPlanOperationCategoryOption | undefined): boolean {
        return category?.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Individual;
    }

    protected addOrEdit() {
        const soraSettings = this.localStore.selectSnapshotByKey("soraSettings");
        const analysisStatus = this.localStore.selectSnapshotByKey("analysisStatus");
        const shouldDisabledAirRiskMitigation = !!(
            soraSettings &&
            (soraSettings.flightAroundObstacleComment ||
                soraSettings.flightSegregatedAirspaceComment ||
                soraSettings.flightLowHeightComment)
        );

        this.dialogService
            .open<AnalysisSoraSettingsModalComponent, AnalysisSoraSettingsModalComponentData>(AnalysisSoraSettingsModalComponent, {
                data: {
                    soraSettings,
                    missionType: this.localStore.selectSnapshotByKey("missionType"),
                    airRiskInfoData: analysisStatus?.sora?.result?.airRisk,
                    populationDensity: analysisStatus?.sora?.result?.populationDensity,
                    criticalArea: analysisStatus?.sora?.result?.criticalArea,
                    shouldDisabledAirRiskMitigation,
                    missionUAVSetup: this.localStore.selectSnapshotByKey("missionDataFormData")?.capabilities?.uavWithSetup?.setup,
                },
                viewContainerRef: this.viewContainerRef,
            })
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                if (!value) {
                    return;
                }

                this.soraSettingsUpdate.next(value);
            });
    }

    private isRiskMitigationAdded(settings?: SoraSettings) {
        return !!(
            settings?.riskMitigations?.m1A ??
            settings?.riskMitigations?.m1B ??
            settings?.riskMitigations?.m2 ??
            settings?.airRiskMitigations?.mitigatingMeasures?.length
        );
    }
}
