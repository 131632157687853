import { Injectable } from "@angular/core";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { Logger } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { OperationalGeometryData } from "../../mission/models/mission.model";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { SpecificPermitApplicationApiService } from "../services/specific-permit-application-api.service";
import {
    ApplicationCreatorWizardSteps,
    ApplicationData,
    ApplicationInfoData,
    Capabilities,
    DocumentTemplate,
    DraftData,
    FinalApplicationInfoData,
    FinalOperationDetailsData,
    FinalOperationInfoData,
    Operation,
    OperationBaseInformation,
    OperationDetailsData,
    OperationInfoData,
    OperationOperator,
    OperationPilot,
    OperationSearchItem,
    OperationUav,
    PredefinedAreaDescription,
    SpecificPermitApplicationError,
    SpecificPermitApplicationErrorType,
    StaffTrainings,
    StatementsData,
} from "../services/specific-permit-application.models";
import { SpecificPermitApplicationActions } from "./specific-permit-application.actions";

export interface SpecificPermitApplicationStateModel {
    isProcessing: boolean;
    isProcessingCapabilities: boolean;
    capabilitiesError: SpecificPermitApplicationError | undefined;
    capabilities: Capabilities | undefined;
    isDirty: boolean;
    operationsError: SpecificPermitApplicationError | undefined;
    operations: OperationSearchItem[] | undefined;
    operationError: SpecificPermitApplicationError | undefined;
    operation: Operation | undefined;
    operationOperator: OperationOperator | undefined;
    operationPilot: OperationPilot | undefined;
    operationUav: OperationUav | undefined;
    draftId: string | undefined;
    draftName: string | undefined;
    saveDraftError: SpecificPermitApplicationError | undefined;
    isSaveDraftProcessing: boolean;
    isOperationSearchProcessing: boolean;
    isOperationSelectProcessing: boolean;
    operationInfoData: OperationInfoData | undefined;
    operationDetailsData: OperationDetailsData | undefined;
    statementsData: StatementsData | undefined;
    applicationInfoData: ApplicationInfoData | undefined;
    operationArea: GeoJSON | undefined;
    predefinedArea: PredefinedAreaDescription | undefined;
    areaError: SpecificPermitApplicationError | undefined;
    requiredPilotTrainings: StaffTrainings | undefined;
    requiredPilotTrainingsError: SpecificPermitApplicationError | undefined;
    loadDraftError: SpecificPermitApplicationError | undefined;
    generateApplicationError: SpecificPermitApplicationError | undefined;
    downloadDocumentTemplateError: SpecificPermitApplicationError | undefined;
    operationalGeometryData: OperationalGeometryData | undefined;
    operationGeometryError: SpecificPermitApplicationError | undefined;
}

const defaultState: SpecificPermitApplicationStateModel = {
    isProcessing: false,
    isProcessingCapabilities: false,
    capabilitiesError: undefined,
    capabilities: undefined,
    isDirty: false,
    operationsError: undefined,
    operations: undefined,
    operationError: undefined,
    operation: undefined,
    operationOperator: undefined,
    operationPilot: undefined,
    operationUav: undefined,
    draftId: undefined,
    draftName: undefined,
    saveDraftError: undefined,
    isSaveDraftProcessing: false,
    isOperationSearchProcessing: false,
    isOperationSelectProcessing: false,
    operationInfoData: undefined,
    operationDetailsData: undefined,
    statementsData: undefined,
    applicationInfoData: undefined,
    operationArea: undefined,
    predefinedArea: undefined,
    areaError: undefined,
    requiredPilotTrainings: undefined,
    requiredPilotTrainingsError: undefined,
    loadDraftError: undefined,
    generateApplicationError: undefined,
    downloadDocumentTemplateError: undefined,
    operationalGeometryData: undefined,
    operationGeometryError: undefined,
};

@State<SpecificPermitApplicationStateModel>({
    name: "specificPermitApplication",
    defaults: defaultState,
})
@Injectable()
export class SpecificPermitApplicationState {
    @Selector()
    public static isProcessing(state: SpecificPermitApplicationStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static isProcessingCapabilities(state: SpecificPermitApplicationStateModel): boolean {
        return state.isProcessingCapabilities;
    }

    @Selector()
    public static capabilitiesError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static capabilities(state: SpecificPermitApplicationStateModel): Capabilities | undefined {
        return state.capabilities;
    }

    @Selector()
    public static isDirty(state: SpecificPermitApplicationStateModel): boolean {
        return state.isDirty;
    }

    @Selector()
    public static operationsError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.operationsError;
    }

    @Selector()
    public static operations(state: SpecificPermitApplicationStateModel): OperationSearchItem[] | undefined {
        return state.operations;
    }

    @Selector()
    public static operationError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.operationError;
    }

    @Selector()
    public static operationGeometryError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.operationGeometryError;
    }

    @Selector()
    public static operation(state: SpecificPermitApplicationStateModel): Operation | undefined {
        return state.operation;
    }

    @Selector()
    public static operationOperator(state: SpecificPermitApplicationStateModel): OperationOperator | undefined {
        return state.operationOperator;
    }

    @Selector()
    public static operationPilot(state: SpecificPermitApplicationStateModel): OperationPilot | undefined {
        return state.operationPilot;
    }

    @Selector()
    public static operationUav(state: SpecificPermitApplicationStateModel): OperationUav | undefined {
        return state.operationUav;
    }

    @Selector()
    public static draftName(state: SpecificPermitApplicationStateModel): string | undefined {
        return state.draftName;
    }

    @Selector()
    public static saveDraftError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.saveDraftError;
    }

    @Selector()
    public static isSaveDraftProcessing(state: SpecificPermitApplicationStateModel): boolean {
        return state.isSaveDraftProcessing;
    }

    @Selector()
    public static isOperationSearchProcessing(state: SpecificPermitApplicationStateModel): boolean {
        return state.isOperationSearchProcessing;
    }

    @Selector()
    public static isOperationSelectProcessing(state: SpecificPermitApplicationStateModel): boolean {
        return state.isOperationSelectProcessing;
    }

    @Selector()
    public static operationInfoData(state: SpecificPermitApplicationStateModel): OperationInfoData | undefined {
        return state.operationInfoData;
    }

    @Selector()
    public static operationDetailsData(state: SpecificPermitApplicationStateModel): OperationDetailsData | undefined {
        return state.operationDetailsData;
    }

    @Selector()
    public static statementsData(state: SpecificPermitApplicationStateModel): StatementsData | undefined {
        return state.statementsData;
    }

    @Selector()
    public static applicationInfoData(state: SpecificPermitApplicationStateModel): ApplicationInfoData | undefined {
        return state.applicationInfoData;
    }

    @Selector()
    public static operationArea(state: SpecificPermitApplicationStateModel): GeoJSON | undefined {
        return state.operationArea;
    }

    @Selector()
    public static predefinedArea(state: SpecificPermitApplicationStateModel): PredefinedAreaDescription | undefined {
        return state.predefinedArea;
    }

    @Selector()
    public static areaError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.areaError;
    }

    @Selector()
    public static requiredPilotTrainings(state: SpecificPermitApplicationStateModel): StaffTrainings | undefined {
        return state.requiredPilotTrainings;
    }

    @Selector()
    public static requiredPilotTrainingsError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.requiredPilotTrainingsError;
    }

    @Selector()
    public static loadDraftError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.loadDraftError;
    }

    @Selector()
    public static generateApplicationError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.generateApplicationError;
    }

    @Selector()
    public static downloadDocumentTemplateError(state: SpecificPermitApplicationStateModel): SpecificPermitApplicationError | undefined {
        return state.downloadDocumentTemplateError;
    }

    @Selector()
    public static operationalGeometryData(state: SpecificPermitApplicationStateModel): OperationalGeometryData | undefined {
        return state.operationalGeometryData;
    }

    private static getApplicationDraft(state: SpecificPermitApplicationStateModel): DraftData {
        return {
            [ApplicationCreatorWizardSteps.OperationInfo]: state.operationInfoData,
            [ApplicationCreatorWizardSteps.OperationDetails]: state.operationDetailsData,
            [ApplicationCreatorWizardSteps.Statements]: state.statementsData,
            [ApplicationCreatorWizardSteps.ApplicationInfo]: state.applicationInfoData,
        };
    }

    private static getApplicationData(state: SpecificPermitApplicationStateModel): ApplicationData | null {
        if (!state.operationInfoData || !state.operationDetailsData || !state.statementsData || !state.applicationInfoData) {
            return null;
        }

        return {
            [ApplicationCreatorWizardSteps.OperationInfo]: state.operationInfoData as FinalOperationInfoData,
            [ApplicationCreatorWizardSteps.OperationDetails]: state.operationDetailsData as FinalOperationDetailsData,
            [ApplicationCreatorWizardSteps.Statements]: {
                isStatementChecked: state.statementsData.isStatementChecked,
                osoNumbers: state.statementsData.osoNumbers,
            },
            [ApplicationCreatorWizardSteps.ApplicationInfo]: state.applicationInfoData as FinalApplicationInfoData,
        };
    }

    constructor(private readonly specificPermitApplicationApi: SpecificPermitApplicationApiService, private readonly store: Store) {
        if (specificPermitApplicationApi === undefined) {
            throw new Error("Initialize SpecificPermitApplicationModule with .forRoot()");
        }
    }

    @Action(SpecificPermitApplicationActions.GetCapabilities)
    public getCapabilities(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.GetCapabilities
    ) {
        context.patchState({
            isProcessingCapabilities: true,
        });

        return this.specificPermitApplicationApi.getCapabilities(action.operatorId).pipe(
            tap((capabilities: Capabilities) => {
                context.patchState({
                    ...defaultState,
                    capabilities,
                });
            }),
            catchError((error) => {
                context.patchState({
                    capabilitiesError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessingCapabilities: false,
                });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.MarkAsDirty)
    public markAsDirty(context: StateContext<SpecificPermitApplicationStateModel>, action: SpecificPermitApplicationActions.MarkAsDirty) {
        context.patchState({
            isDirty: action.isDirty,
        });
    }

    @Action(SpecificPermitApplicationActions.GetOperations)
    public getOperations(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.GetOperations
    ) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ operationsError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.getOperations: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            isOperationSearchProcessing: true,
        });

        return this.specificPermitApplicationApi.getOperations(operatorId, action.query).pipe(
            tap((operations: OperationSearchItem[]) => {
                context.patchState({
                    operations,
                    operationsError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ operationsError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isOperationSearchProcessing: false });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.GetOperation)
    public getOperation(context: StateContext<SpecificPermitApplicationStateModel>, action: SpecificPermitApplicationActions.GetOperation) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ operationError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.getOperation: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            isOperationSelectProcessing: true,
        });

        return this.specificPermitApplicationApi.getOperation(operatorId, action.operationId).pipe(
            tap((operationBaseInformation: OperationBaseInformation) => {
                context.patchState({
                    operation: operationBaseInformation.operation,
                    operationOperator: operationBaseInformation.operator,
                    operationPilot: operationBaseInformation.pilot,
                    operationUav: operationBaseInformation.uav,
                    operationError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ operationError: error });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isOperationSelectProcessing: false });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.GetOperationalGeometryData)
    public getOperationalGeometryData(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.GetOperationalGeometryData
    ) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ operationError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.getOperation: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({ isProcessing: true, operationGeometryError: undefined });

        return this.specificPermitApplicationApi.getOperationalGeometryData(action.operationId, operatorId).pipe(
            tap((operationalGeometryData) => {
                context.patchState({
                    operationalGeometryData,
                    isProcessing: false,
                });
            }),
            catchError((operationGeometryError) => {
                context.patchState({
                    isProcessing: false,
                    operationGeometryError,
                });

                return EMPTY;
            })
        );
    }

    @Action(SpecificPermitApplicationActions.GetOperationArea)
    public getOperationRoute(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.GetOperationArea
    ) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ areaError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.getOperationRoute: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            areaError: undefined,
            isProcessing: true,
        });

        return this.specificPermitApplicationApi.getOperationArea(operatorId, action.operationId).pipe(
            tap((area: GeoJSON) => {
                context.patchState({
                    operationArea: area,
                });
            }),
            catchError((error) => {
                context.patchState({
                    operationArea: undefined,
                    areaError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.GetPredefinedArea)
    public getPredefinedAreaRoute(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.GetPredefinedArea
    ) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ areaError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.getPredefinedAreaRoute: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            areaError: undefined,
            isProcessing: true,
        });

        return this.specificPermitApplicationApi.getPredefinedArea(operatorId, action.areaId).pipe(
            tap(({ area, name, description }) => {
                context.patchState({
                    predefinedArea: { area, name, description },
                });
            }),
            catchError((error) => {
                context.patchState({
                    predefinedArea: undefined,
                    areaError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.GetRequiredPilotTrainings)
    public getRequiredPilotTrainings(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.GetRequiredPilotTrainings
    ) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ requiredPilotTrainingsError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.getRequiredPilotTrainings: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            requiredPilotTrainingsError: undefined,
            isProcessing: true,
        });

        return this.specificPermitApplicationApi.getRequiredPilotTrainings(operatorId, action.sail, action.pilotCompetencies.basic).pipe(
            tap((trainings) => {
                context.patchState({
                    requiredPilotTrainings: {
                        basic: trainings,
                        additional: [],
                    },
                });
            }),
            catchError((error) => {
                context.patchState({
                    requiredPilotTrainings: undefined,
                    requiredPilotTrainingsError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.ClearForm)
    public clearForm(context: StateContext<SpecificPermitApplicationStateModel>) {
        context.patchState({
            operationInfoData: undefined,
            operationDetailsData: undefined,
            statementsData: undefined,
            applicationInfoData: undefined,
            isDirty: false,
        });
    }

    @Action(SpecificPermitApplicationActions.SetOperationInfoData)
    public setOperationInfoData(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.SetOperationInfoData
    ) {
        context.patchState({
            operationInfoData: action.data,
            isDirty: false,
        });
    }

    @Action(SpecificPermitApplicationActions.SetOperationDetailsData)
    public setOperationDetailsData(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.SetOperationDetailsData
    ) {
        context.patchState({
            operationDetailsData: action.data,
            isDirty: false,
        });
    }

    @Action(SpecificPermitApplicationActions.SetStatementsData)
    public setStatementsData(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.SetStatementsData
    ) {
        context.patchState({
            statementsData: action.data,
            isDirty: false,
        });
    }

    @Action(SpecificPermitApplicationActions.SetApplicationInfoData)
    public setApplicationInfoData(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.SetApplicationInfoData
    ) {
        context.patchState({
            applicationInfoData: action.data,
            isDirty: false,
        });
    }

    @Action(SpecificPermitApplicationActions.SetDraftName)
    public setDraftName(context: StateContext<SpecificPermitApplicationStateModel>, action: SpecificPermitApplicationActions.SetDraftName) {
        context.patchState({
            draftName: action.name,
        });
    }

    @Action(SpecificPermitApplicationActions.SaveDraft)
    public saveDraft(context: StateContext<SpecificPermitApplicationStateModel>) {
        const state = context.getState();
        const applicationDraft = SpecificPermitApplicationState.getApplicationDraft(state);
        const draftName = state.draftName;

        if (!draftName) {
            Logger.captureMessage("SpecificPermitApplicationState.saveDraft: trying to save draft with no draft name set", {
                level: "error",
                extra: { draftName },
            });

            return;
        }

        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ saveDraftError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.saveDraft: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        const draftId = state.draftId;

        context.patchState({
            saveDraftError: undefined,
            isSaveDraftProcessing: true,
        });

        const saveDraft = draftId
            ? this.specificPermitApplicationApi.updateDraft(draftId, operatorId, applicationDraft)
            : this.specificPermitApplicationApi.createDraft(draftName, operatorId, applicationDraft);

        return saveDraft.pipe(
            tap((newDraftId: string) => {
                context.patchState({
                    draftId: newDraftId,
                    isDirty: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    draftId: undefined,
                    saveDraftError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isSaveDraftProcessing: false,
                });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.GetDraft)
    public getDraft(context: StateContext<SpecificPermitApplicationStateModel>, action: SpecificPermitApplicationActions.GetDraft) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ loadDraftError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.getDraft: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            isProcessing: true,
            draftId: undefined,
            draftName: undefined,
            operationInfoData: undefined,
            operationDetailsData: undefined,
            statementsData: undefined,
            applicationInfoData: undefined,
            loadDraftError: undefined,
        });

        return this.specificPermitApplicationApi.getDraft(operatorId, action.draftId).pipe(
            tap((draft) => {
                context.patchState({
                    draftId: draft.id,
                    draftName: draft.name,
                    operationInfoData: draft.data[ApplicationCreatorWizardSteps.OperationInfo],
                    operationDetailsData: draft.data[ApplicationCreatorWizardSteps.OperationDetails],
                    statementsData: draft.data[ApplicationCreatorWizardSteps.Statements],
                    applicationInfoData: draft.data[ApplicationCreatorWizardSteps.ApplicationInfo],
                    isDirty: false,
                    loadDraftError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({
                    loadDraftError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.GenerateApplication)
    public generateApplication(context: StateContext<SpecificPermitApplicationStateModel>) {
        const state = context.getState();
        const applicationData = SpecificPermitApplicationState.getApplicationData(state);
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!applicationData) {
            context.patchState({ generateApplicationError: { type: SpecificPermitApplicationErrorType.InvalidApplicationData } });

            Logger.captureMessage(
                // eslint-disable-next-line max-len
                "SpecificPermitApplicationState.generateApplication: trying to generate specific permit application but no application data",
                {
                    level: "warning",
                    extra: { applicationData },
                }
            );

            return EMPTY;
        }

        if (!operatorId) {
            context.patchState({ generateApplicationError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.generateApplication: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            generateApplicationError: undefined,
            isProcessing: true,
        });

        return this.specificPermitApplicationApi.generateApplication(operatorId, applicationData, "wniosek.zip").pipe(
            catchError((error) => {
                context.patchState({
                    generateApplicationError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(SpecificPermitApplicationActions.DownloadOperationsManualTemplate)
    public downloadOperationsManualTemplate(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.DownloadOperationsManualTemplate
    ) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ downloadDocumentTemplateError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.downloadOperationsManualTemplate: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            downloadDocumentTemplateError: undefined,
            isProcessing: true,
        });

        return this.specificPermitApplicationApi
            .downloadDocumentTemplate(operatorId, DocumentTemplate.OperationsManual, action.fileName)
            .pipe(
                catchError((error) => {
                    context.patchState({
                        downloadDocumentTemplateError: error,
                    });

                    return EMPTY;
                }),
                finalize(() => {
                    context.patchState({
                        isProcessing: false,
                    });
                })
            );
    }

    @Action(SpecificPermitApplicationActions.DownloadAttorneyPowerTemplate)
    public downloadAttorneyPowerTemplate(
        context: StateContext<SpecificPermitApplicationStateModel>,
        action: SpecificPermitApplicationActions.DownloadAttorneyPowerTemplate
    ) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ downloadDocumentTemplateError: this.getInvalidOperatorError() });

            Logger.captureMessage("SpecificPermitApplicationState.downloadAttorneyPowerTemplate: no operator selected", {
                level: "warning",
                extra: { operatorId },
            });

            return EMPTY;
        }

        context.patchState({
            downloadDocumentTemplateError: undefined,
            isProcessing: true,
        });

        return this.specificPermitApplicationApi.downloadDocumentTemplate(operatorId, DocumentTemplate.AttorneyPower, action.fileName).pipe(
            catchError((error) => {
                context.patchState({
                    downloadDocumentTemplateError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    private getInvalidOperatorError(): SpecificPermitApplicationError {
        return { type: SpecificPermitApplicationErrorType.InvalidOperator };
    }
}
