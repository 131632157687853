import { ChangeDetectionStrategy, Component, Inject, Input } from "@angular/core";
import { SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import {
    AZURE_MAPS_LAYER_OPTIONS,
    CameraHelperService,
    DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
} from "@dtm-frontend/shared/map/cesium";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MapLayerProviderOptions, SceneMode, ViewerConfiguration } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-editor-map[initialViewbox]",
    templateUrl: "./editor-map.component.html",
    styleUrls: ["./editor-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorMapComponent {
    @Input() public set initialViewbox(value: GeoJSON) {
        this.initView(value);
        // eslint-disable-next-line no-magic-numbers
        this.cameraHelperService.flyToGeoJSON(value, 0.1);
    }

    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;

    constructor(
        viewerConfiguration: ViewerConfiguration,
        @Inject(SHARED_MAP_ENDPOINTS) protected readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly cameraHelperService: CameraHelperService
    ) {
        viewerConfiguration.viewerOptions = {
            ...DEFAULT_CESIUM_VIEWER_CONFIGURATION_OPTIONS,
            sceneMode: SceneMode.SCENE2D,
        };
    }

    private initView(viewbox: GeoJSON): void {
        Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
        Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...turfBbox(viewbox));
    }
}
