<dtm-ui-expandable-panel [isExpanded]="false" *ngrxLet="detachedSerialNumbers$ as detachedSerialNumbers" [hasHeaderSeparator]="false">
    <div class="header-container" headerSlot>
        <p class="header">{{ "dtmWebAppLibOperatorPermissions.detachedSerialNumbers.header" | transloco }}</p>
        <span class="chip warning">
            <dtm-ui-icon name="drone"></dtm-ui-icon>
            {{ detachedSerialNumbers.length }}
        </span>
    </div>
    <ul class="serial-numbers">
        <li *ngFor="let serialNumber of detachedSerialNumbers">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorPermissions.detachedSerialNumbers.serialNumberLabel' | transloco"
                [value]="serialNumber"
                [canCopy]="true"
            >
            </dtm-ui-label-value>
        </li>
    </ul>
    <div class="add-uavs-info">
        <div class="add-uavs-info-text">
            <dtm-ui-icon name="error-warning-fill"></dtm-ui-icon>
            <p>
                {{ "dtmWebAppLibOperatorPermissions.detachedSerialNumbers.addUavInfoText" | transloco }}
            </p>
        </div>
        <button type="button" class="button-secondary add-uav-button" routerLink="/uav/new" [mat-dialog-close]="null">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibOperatorPermissions.detachedSerialNumbers.addUavLabel" | transloco }}
        </button>
    </div>
</dtm-ui-expandable-panel>
