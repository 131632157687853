import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SerializableCartographic } from "@dtm-frontend/shared/map/cesium";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ItineraryPanelSettings, ViewItineraryEntityType, ViewItineraryShapeEntity } from "../../itinerary-panel.models";
import { DEFAULT_TRACK_DETAILS_DISPLAY_MODE, TrackDetailsDisplayMode } from "../track-details/track-details-display-mode";
import { MissionPlanItineraryConstraints } from "./../../../../../../../models/mission.model";

interface ShapeViewEntityCardState {
    entity: ViewItineraryShapeEntity | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    title: string;
    settings: ItineraryPanelSettings | undefined;
    displayMode: TrackDetailsDisplayMode;
    isCoordinatesEditMode: boolean;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-mwiesip-shape-view-entity-card[settings][entity][constraints][title][displayMode][isProcessing]",
    templateUrl: "./shape-view-entity-card.component.html",
    styleUrls: ["../itinerary-panel/itinerary-panel.component.scss", "./shape-view-entity-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ShapeViewEntityCardComponent {
    protected readonly ViewItineraryEntityType = ViewItineraryEntityType;

    @Input() public set entity(value: ViewItineraryShapeEntity | undefined) {
        this.localStore.patchState({ entity: value });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value });
    }
    @Input() public set settings(value: ItineraryPanelSettings) {
        this.localStore.patchState({ settings: value });
    }
    @Input() public set displayMode(value: TrackDetailsDisplayMode) {
        this.localStore.patchState({ displayMode: value });
    }
    @Input() public set isCoordinatesEditMode(value: BooleanInput) {
        this.localStore.patchState({ isCoordinatesEditMode: coerceBooleanProperty(value) });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public readonly inletPointHeightChange = new EventEmitter<number>();
    @Output() public readonly outletPointHeightChange = new EventEmitter<number>();
    @Output() public readonly shapeTopHeightChange = new EventEmitter<number>();
    @Output() public readonly shapeBottomHeightChange = new EventEmitter<number>();
    @Output() public readonly stopoverChange = new EventEmitter<number>();
    @Output() public readonly entityDelete = new EventEmitter<void>();
    @Output() public readonly editCenterPoint = new EventEmitter<void>();
    @Output() public readonly centerCoordinatesChange = new EventEmitter<SerializableCartographic>();

    protected readonly entity$ = this.localStore.selectByKey("entity");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly title$ = this.localStore.selectByKey("title");
    protected readonly settings$ = this.localStore.selectByKey("settings");
    protected readonly displayMode$ = this.localStore.selectByKey("displayMode");
    protected readonly isCoordinatesEditMode$ = this.localStore.selectByKey("isCoordinatesEditMode");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(private readonly localStore: LocalComponentStore<ShapeViewEntityCardState>) {
        localStore.setState({
            entity: undefined,
            constraints: undefined,
            title: "",
            settings: undefined,
            displayMode: DEFAULT_TRACK_DETAILS_DISPLAY_MODE,
            isCoordinatesEditMode: false,
            isProcessing: false,
        });
    }

    protected getVerticalBufferOffset(verticalBuffer: number): number {
        return Math.round(verticalBuffer / 2);
    }
}
