import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { GlobalFeatures } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { Observable, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";

@Injectable({
    providedIn: "root",
})
export class SpecificPermitApplicationGuard {
    constructor(private router: Router, private readonly store: Store) {}

    public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.select(OperatorContextState.user).pipe(
            RxjsUtils.filterFalsy(),
            switchMap(() => this.store.select(OperatorContextState.isFeatureAvailable(GlobalFeatures.SpecificPermitApplication))),
            map((isFeatureAvailable) => {
                if (!isFeatureAvailable) {
                    return this.router.parseUrl("/");
                }

                return true;
            })
        );
    }
}
