<ng-container
    *ngrxLet="{
        basicCompetencies: basicCompetencies$,
        additionalCompetencies: additionalCompetencies$,
        hasEuRegulationCompetency: hasEuRegulationCompetency$
    } as vm"
>
    <ng-content select="[basicHeaderSlot]"></ng-content>
    <div class="basic-competencies">
        <ul class="basic-competencies-list">
            <li *ngFor="let competency of vm.basicCompetencies">
                <span [ngSwitch]="competency.category" class="icon shield">
                    <dtm-ui-icon name="shield" *ngSwitchCase="MissionCategory.Open"></dtm-ui-icon>
                    <dtm-ui-icon name="shield-cross" *ngSwitchDefault></dtm-ui-icon>
                </span>
                <strong class="name">{{ competency.name }}</strong>
            </li>
        </ul>
        <div class="no-basic-competencies" *ngIf="!vm.basicCompetencies.length">
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.noCompetenciesSelectedLabel" | transloco }}
        </div>
    </div>
    <ng-container *ngIf="vm.additionalCompetencies.length || vm.hasEuRegulationCompetency">
        <ng-content select="[additionalHeaderSlot]"></ng-content>
        <ul class="additional-competencies">
            <li class="feature-item" *ngIf="vm.hasEuRegulationCompetency">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <span class="feature-value">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.euRegulationCompetencyLabel" | transloco }}
                </span>
            </li>
            <li class="feature-item" *ngFor="let additionalCompetency of vm.additionalCompetencies">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <span class="feature-value">{{ additionalCompetency }}</span>
            </li>
        </ul>
    </ng-container>
</ng-container>
