import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { HelpCenterContainerComponent } from "./help-center-container/help-center-container.component";
import { HelpCenterRoutingModule } from "./help-center-routing.module";

@NgModule({
    declarations: [HelpCenterContainerComponent],
    imports: [CommonModule, HelpCenterRoutingModule, SharedUiModule, MatCardModule, TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibHelpCentre",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class HelpCenterModule {}
