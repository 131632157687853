import { SerializableCartographic } from "@dtm-frontend/shared/map/cesium";
import { Observable } from "rxjs";
import {
    CylinderItineraryEntity,
    ItineraryContentEntity,
    Polyline3DItineraryEntity,
    PrismItineraryEntity,
} from "../../../../../models/itinerary.model";
import { FlightSpeedType, HeightType } from "../../../../../models/mission.model";

export interface ItineraryPanelSettings {
    heightType: HeightType | undefined;
    flightSpeedType: FlightSpeedType;
}

export enum ViewItineraryEntityType {
    Takeoff = "takeoff",
    Landing = "landing",
    Cylinder = "cylinder",
    Prism = "prism",
    InternalCylinder = "internalCylinder",
    InternalPrism = "internalPrism",
    Point = "point",
    Segment = "segment",
    Corridor = "corridor",
}

export interface ViewItineraryEntityCommon<I extends ItineraryContentEntity = ItineraryContentEntity> {
    id: string;
    contentHash: string;
    type: ViewItineraryEntityType;
    itineraryIndex: [I, number];
    label$: Observable<string>;
}

export interface ViewItineraryCylinderEntity<I extends ItineraryContentEntity = CylinderItineraryEntity>
    extends ViewItineraryEntityCommon<I> {
    type: ViewItineraryEntityType.Cylinder;
    radius: number;
    topHeight: number;
    amslOffset: number | undefined;
    bottomHeight: 0;
    pointLabel$: Observable<string>;
    stopoverMinutes: number;
    centerCoordinates: SerializableCartographic;
}

export interface ViewItineraryCorridorEntity extends ViewItineraryEntityCommon<Polyline3DItineraryEntity> {
    type: ViewItineraryEntityType.Corridor;
    bufferWidths: number[];
}

export interface ViewItineraryPrismEntity<I extends ItineraryContentEntity = PrismItineraryEntity> extends ViewItineraryEntityCommon<I> {
    type: ViewItineraryEntityType.Prism;
    topHeight: number;
    amslOffset: number | undefined;
    bottomHeight: 0;
    pointLabel$: Observable<string>;
    stopoverMinutes: number;
    centerCoordinates: SerializableCartographic | undefined;
}

export interface ViewItineraryTakeoffEntity<I extends ItineraryContentEntity = ItineraryContentEntity>
    extends ViewItineraryEntityCommon<I> {
    type: ViewItineraryEntityType.Takeoff;
    radius: number;
    topHeight: number;
    amslOffset: number | undefined;
    bottomHeight: 0;
    outletPointHeight: number;
    outletPointAmslOffset: number | undefined;
    outletPointLabel$: Observable<string>;
    outletVerticalBuffer: number;
    pointLabel$: Observable<string>;
    centerPointHeight?: number;
    centerCoordinates: SerializableCartographic;
}

export interface ViewItineraryLandingEntity<I extends ItineraryContentEntity = ItineraryContentEntity>
    extends ViewItineraryEntityCommon<I> {
    type: ViewItineraryEntityType.Landing;
    inletPointHeight: number;
    inletPointAmslOffset: number | undefined;
    inletPointLabel$: Observable<string>;
    inletVerticalBuffer: number;
    radius: number;
    topHeight: number;
    amslOffset: number | undefined;
    bottomHeight: 0;
    pointLabel$: Observable<string>;
    centerPointHeight?: number;
    centerCoordinates: SerializableCartographic;
}

export interface ViewItineraryInternalCylinderEntity<I extends ItineraryContentEntity = ItineraryContentEntity>
    extends ViewItineraryEntityCommon<I> {
    type: ViewItineraryEntityType.InternalCylinder;
    inletPointHeight: number;
    inletPointAmslOffset: number | undefined;
    inletPointLabel$: Observable<string>;
    inletVerticalBuffer: number;
    radius: number;
    topHeight: number;
    amslOffset: number | undefined;
    bottomHeight: number;
    outletPointHeight: number;
    outletPointAmslOffset: number | undefined;
    outletPointLabel$: Observable<string>;
    outletVerticalBuffer: number;
    pointLabel$: Observable<string>;
    stopoverMinutes: number;
    centerCoordinates: SerializableCartographic;
}

export interface ViewItineraryInternalPrismEntity<I extends ItineraryContentEntity = ItineraryContentEntity>
    extends ViewItineraryEntityCommon<I> {
    type: ViewItineraryEntityType.InternalPrism;
    inletPointHeight: number;
    inletPointAmslOffset: number | undefined;
    inletPointLabel$: Observable<string>;
    inletVerticalBuffer: number;
    topHeight: number;
    amslOffset: number | undefined;
    bottomHeight: number;
    outletPointHeight: number;
    outletPointAmslOffset: number | undefined;
    outletPointLabel$: Observable<string>;
    outletVerticalBuffer: number;
    pointLabel$: Observable<string>;
    stopoverMinutes: number;
    centerCoordinates: SerializableCartographic | undefined;
}

export interface ViewItineraryPointEntity extends ViewItineraryEntityCommon<Polyline3DItineraryEntity> {
    type: ViewItineraryEntityType.Point;
    height: number;
    amslOffset: number | undefined;
    coordinates: SerializableCartographic;
    canDelete: boolean;
}

export interface ViewItinerarySegmentEntity<I extends ItineraryContentEntity = ItineraryContentEntity>
    extends ViewItineraryEntityCommon<I> {
    type: ViewItineraryEntityType.Segment;
    distance: number | undefined;
    inletPointHeightAmsl: number | undefined;
    inletPointLabel$: Observable<string>;
    outletPointHeightAmsl: number | undefined;
    outletPointLabel$: Observable<string>;
    speed: number | undefined;
    horizontalBuffer: number;
    verticalBuffer: number;
}

export type ViewItineraryEntity<I extends ItineraryContentEntity = ItineraryContentEntity> =
    | ViewItineraryCylinderEntity<I>
    | ViewItineraryPrismEntity<I>
    | ViewItineraryTakeoffEntity<I>
    | ViewItineraryLandingEntity<I>
    | ViewItineraryInternalCylinderEntity<I>
    | ViewItineraryInternalPrismEntity<I>
    | ViewItineraryPointEntity
    | ViewItinerarySegmentEntity<I>
    | ViewItineraryCorridorEntity;

export type ViewItineraryShapeEntity =
    | ViewItineraryCylinderEntity<Polyline3DItineraryEntity | CylinderItineraryEntity>
    | ViewItineraryPrismEntity<Polyline3DItineraryEntity | PrismItineraryEntity>
    | ViewItineraryTakeoffEntity<Polyline3DItineraryEntity>
    | ViewItineraryLandingEntity<Polyline3DItineraryEntity>
    | ViewItineraryInternalCylinderEntity<Polyline3DItineraryEntity>
    | ViewItineraryInternalPrismEntity<Polyline3DItineraryEntity>;

export interface ManualCoordinatesInputData {
    latitude: number | null;
    longitude: number | null;
    size: number | null;
}
