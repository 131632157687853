<ng-container
    *ngrxLet="{
        activeMapAction: activeMapAction$,
        activeStepId: activeStepId$,
        additionalInformation: additionalInformation$,
        areAvailableRoutesLoading: areAvailableRoutesLoading$,
        areCapabilitiesLoading: areCapabilitiesLoading$,
        areLabelsVisible: areLabelsVisible$,
        areOtherMissionsProcessing: areOtherMissionsProcessing$,
        areRouteEntitiesVisible: areRouteEntitiesVisible$,
        activePermitsList: activePermitsList$,
        collisionMissions: collisionMissions$,
        collisionZones: collisionZones$,
        currentPlanDataAndCapabilities: currentPlanDataAndCapabilities$,
        currentPlan: currentPlan$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        currentPlanItinerary: currentPlanItinerary$,
        constraints: constraints$,
        currentPlanRoute: currentPlanRoute$,
        currentPlanRouteWaypoints: currentPlanRouteWaypoints$,
        enabledSteps: enabledSteps$,
        error: error$,
        isDataPanelFolded: isDataPanelFolded$,
        isEditingExisitingItinerary: isEditingExisitingItinerary$,
        isMapActionsBarVisible: isMapActionsBarVisible$,
        isProcessing: isProcessing$,
        itineraryContent: itineraryContent$,
        itineraryEditorFormData: itineraryEditorFormData$,
        areMissionParametersFormsValid: areMissionParametersFormsValid$,
        itineraryPanelSettings: itineraryPanelSettings$,
        missionCapabilities: missionCapabilities$,
        missionDataFormData: missionDataFormData$,
        missionPlanInformation: missionPlanInformation$,
        missionPreferences: missionPreferences$,
        nearbyMissions: nearbyMissions$,
        previewRouteId: previewRouteId$,
        route: route$,
        routeSuggestions: routeSuggestions$,
        selectedEditorType: selectedEditorType$,
        selectedMapActionsPanelMode: selectedMapActionsPanelMode$,
        selectedZoneId: selectedZoneId$,
        sourceUrl: sourceUrl$,
        operators: operators$,
        activeEntityStatus: activeEntityStatus$,
        isSelectedEnterpriseOperator: isSelectedEnterpriseOperator$,
        selectedCaaPermit: selectedCaaPermit$,
        operationalGeometryData: operationalGeometryData$,
        isCircularBoundaryViolated: isCircularBoundaryViolated$,
        areEditorsDisabled: areEditorsDisabled$,
        isSpecificPermitUsageEnabled: isSpecificPermitUsageEnabled$,
        isRouteInDtm: isRouteInDtm$,
        heightConstraints: heightConstraints$,
        isPilotUTMConnected: isPilotUTMConnected$,
        isMeasureToolActive: isMeasureToolActive$,
        isMeasureToolEnabled: isMeasureToolEnabled$,
    } as vm"
>
    <div class="actions" *ngIf="vm.sourceUrl">
        <button type="button" class="button-secondary" (click)="goBack(vm.sourceUrl)">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "dtmWebAppLibMission.backButtonLabel" | transloco }}
            <ng-container [ngSwitch]="vm.sourceUrl | invoke : getSource">
                <ng-container *ngSwitchCase="Source.PlanList">
                    {{ "dtmWebAppLibMission.backButtonConnectorLabel" | transloco }}
                    {{ "webApp.menu.missionListMenuItemLabel" | transloco }}
                </ng-container>
                <ng-container *ngSwitchCase="Source.PrepareForMission">
                    {{ "dtmWebAppLibMission.backButtonConnectorLabel" | transloco }}
                    {{ "webApp.menu.prepareForMissionItemLabel" | transloco }}
                </ng-container>
            </ng-container>
        </button>
    </div>
    <div class="error" *ngIf="vm.error && TEMPLATE_HANDLED_ERRORS.includes(vm.error.type); else noErrorTemplate">
        <dtm-ui-error
            *ngIf="vm.error.type === MissionErrorType.CannotGetCapabilities || vm.error.type === MissionErrorType.CannotCreateMissionPlan"
            [errorMessage]="'dtmWebAppLibMission.cannotGetCapabilitiesErrorMessage' | transloco"
            (reload)="reload()"
        >
        </dtm-ui-error>
        <dtm-ui-error
            *ngIf="vm.error.type === MissionErrorType.CannotGetMission"
            [errorMessage]="'dtmWebAppLibMission.cannotGetMissionErrorMessage' | transloco"
            (reload)="reload()"
        >
        </dtm-ui-error>
    </div>

    <ng-template #noErrorTemplate>
        <div class="mission-planner-container">
            <div class="mission-planner-data-container" [class.folded]="vm.isDataPanelFolded">
                <dtm-ui-lib-wizard-content
                    class="content-container"
                    [wizardId]="wizardId"
                    (stepChangeActivate)="handleActiveStepChanged($event)"
                    #wizard
                    [attr.inert]="vm.isDataPanelFolded || null"
                    *ngrxLet="vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Assisted ? 5 : 4 as stepsAmount"
                >
                    <ng-container *dtmUiLibWizardStepContent="MissionWizardSteps.MissionData">
                        <dtm-web-app-lib-mission-wizard-mission-data-form-step
                            [missionCapabilities]="vm.missionCapabilities"
                            [isProcessing]="vm.isProcessing"
                            [currentPlan]="vm.currentPlan"
                            [isEditMode]="vm.isEditingExisitingItinerary"
                            [activePermitsList]="vm.activePermitsList"
                            [areCapabilitiesLoading]="vm.areCapabilitiesLoading"
                            [additionalInformation]="vm.additionalInformation"
                            [missionPreferences]="vm.missionPreferences"
                            [isSelectedEnterpriseOperator]="vm.isSelectedEnterpriseOperator"
                            [stepNumber]="1"
                            [stepsAmount]="stepsAmount"
                            [areDangerousMaterialsTransported]="areDangerousMaterialsTransported$ | ngrxPush"
                            [canManageUavs]="canManageUavs$ | ngrxPush"
                            [isSpecificPermitUsageEnabled]="vm.isSpecificPermitUsageEnabled"
                            [isPilotUTMConnected]="vm.isPilotUTMConnected"
                            (capabilitiesUpdateOrCreate)="handleCreateOrUpdateCapabilities($event)"
                            (formUpdate)="updateMissionFormData($event)"
                            (flightPurposeUpdate)="handleUpdateFlightPurpose($event)"
                            (operationCategoryUpdate)="handleUpdateOperationCategory($event)"
                            (next)="goToItineraryEditorStep()"
                            (additionalInformationChange)="updateAdditionalInformation($event)"
                            (areDangerousMaterialsTransportedChange)="updateAreDangerousMaterialsTransported($event)"
                            (itineraryClear)="clearItineraryContent()"
                        >
                        </dtm-web-app-lib-mission-wizard-mission-data-form-step>
                    </ng-container>

                    <ng-container *dtmUiLibWizardStepContent="MissionWizardSteps.ItineraryEditor">
                        <dtm-web-app-lib-mission-wizard-itinerary-editor-step
                            *ngIf="vm.missionCapabilities"
                            [availableEditors]="vm.missionCapabilities.availableEditors"
                            [suggestedEditor]="vm.selectedEditorType || vm.missionCapabilities.suggestedEditor"
                            [itineraryContent]="vm.itineraryContent"
                            [isEditMode]="vm.isEditingExisitingItinerary"
                            [currentPlanItinerary]="vm.currentPlanItinerary"
                            [constraints]="vm.constraints"
                            [currentPlanRoute]="vm.currentPlanRoute"
                            [isProcessing]="vm.isProcessing"
                            [enabledSteps]="vm.enabledSteps"
                            [capabilities]="vm.missionDataFormData?.capabilities"
                            [stepNumber]="2"
                            [isBoundaryViolated]="vm.isCircularBoundaryViolated"
                            [stepsAmount]="stepsAmount"
                            [activeMapAction]="vm.activeMapAction"
                            [heightConstraints]="vm.heightConstraints"
                            [operationCategory]="vm.missionDataFormData?.category"
                            [initialItineraryFormData]="vm.itineraryEditorFormData"
                            [selectedCaaPermit]="vm.selectedCaaPermit"
                            [areEditorsDisabled]="vm.areEditorsDisabled"
                            (back)="goToMissionDataStep()"
                            (next)="
                                vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Assisted
                                    ? gotToRouteSelectorStep()
                                    : goToAnalysisStep()
                            "
                            (itineraryClear)="clearItineraryContent()"
                            (entityUpdate)="updateEntity($event)"
                            (editorTypeChange)="changeEditorType($event)"
                            (itinerarySubmit)="handleItineraryCompleted($event)"
                            (formUpdate)="updateItineraryFormData($event)"
                            (missionParametersFormsValidationStatusChange)="updateMissionParametersFormsValidationStatus($event)"
                            [additionalInformation]="vm.additionalInformation"
                            (additionalInformationChange)="updateAdditionalInformation($event)"
                            (sideViewTemplate)="setBottomPanelTemplate($event, MissionWizardSteps.ItineraryEditor)"
                            (missionParametersChange)="updateMissionParameters($event)"
                            (heightPanelParametersChange)="updateHeightPanelParameters($event)"
                        >
                            <ng-container stepTitleContent *ngIf="vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Custom">
                                <button
                                    class="button-secondary-small select-height-button"
                                    type="button"
                                    [matMenuTriggerFor]="heightTypeMenu"
                                >
                                    {{
                                        "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightTypeSelectOptionLabel"
                                            | transloco : { type: vm.itineraryPanelSettings.heightType }
                                    }}
                                    <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
                                </button>
                                <mat-menu #heightTypeMenu="matMenu" xPosition="after" yPosition="below">
                                    <button
                                        *ngFor="let heightType of heightTypeOptions"
                                        type="button"
                                        class="menu-button"
                                        [class.active]="vm.itineraryPanelSettings.heightType === heightType"
                                        (click)="changeHeightType(heightType)"
                                    >
                                        {{
                                            "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightTypeSelectOptionLabel"
                                                | transloco : { type: heightType }
                                        }}
                                    </button>
                                </mat-menu>
                            </ng-container>
                            <dtm-web-app-lib-mission-wizard-itinerary-editor-step-itinerary-panel
                                itineraryPanel
                                *ngIf="vm.constraints"
                                [itineraryContent]="vm.itineraryContent"
                                [itineraryEditorFormData]="vm.itineraryEditorFormData"
                                [currentPlanRoute]="vm.currentPlanRoute"
                                [isProcessing]="vm.isProcessing"
                                [isDisabled]="vm.areEditorsDisabled || vm.activeMapAction !== MapActionType.None"
                                [constraints]="vm.constraints"
                                [settings]="vm.itineraryPanelSettings"
                                (entityUpdate)="updateEntity($event)"
                                (entityMetadataUpdate)="updateEntityMetadata($event)"
                                (entityAdd)="addItineraryEntity($event, vm.constraints)"
                                (entityDelete)="deleteItineraryEntity($event)"
                                (pointDelete)="deletePolyline3DWaypoint($event)"
                                (zoomToEntity)="zoomToEntity($event)"
                            >
                            </dtm-web-app-lib-mission-wizard-itinerary-editor-step-itinerary-panel>
                        </dtm-web-app-lib-mission-wizard-itinerary-editor-step>
                    </ng-container>
                    <ng-container *dtmUiLibWizardStepContent="MissionWizardSteps.RouteSelector">
                        <dtm-web-app-lib-mission-wizard-route-selector-step
                            [routes]="vm.routeSuggestions"
                            [isProcessing]="vm.isProcessing"
                            [areAvailableRoutesLoading]="vm.areAvailableRoutesLoading"
                            [activeStepId]="vm.activeStepId"
                            [selectedRouteId]="vm.route?.routeId"
                            [previewRouteId]="vm.previewRouteId"
                            (next)="saveSelectedRouteAndGoToNextStep()"
                            (routeSelected)="selectRoute($event)"
                            (previewRoute)="previewRoute($event)"
                            (flyToRoute)="flyToRoute($event)"
                            (back)="goToItineraryEditorStep()"
                            (sideViewTemplate)="setBottomPanelTemplate($event, MissionWizardSteps.RouteSelector)"
                            (refreshRoutes)="refreshRoutes($event)"
                        >
                            <dtm-web-app-lib-route-panel
                                itineraryPanel
                                *ngIf="vm.currentPlanItinerary"
                                [itineraryContent]="vm.itineraryContent"
                                [constraints]="vm.constraints"
                                [currentPlanRoute]="vm.route"
                                [settings]="vm.itineraryPanelSettings"
                            ></dtm-web-app-lib-route-panel>
                        </dtm-web-app-lib-mission-wizard-route-selector-step>
                    </ng-container>

                    <ng-container *dtmUiLibWizardStepContent="MissionWizardSteps.Analysis">
                        <dtm-web-app-lib-mission-wizard-analysis-step
                            [analysisStatus]="vm.currentPlanAnalysisStatus"
                            [isProcessing]="vm.isProcessing"
                            [stepNumber]="vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Assisted ? 4 : 3"
                            [stepsAmount]="stepsAmount"
                            [nearbyMissions]="vm.nearbyMissions"
                            [collisionMission]="vm.collisionMissions"
                            [areOtherMissionsProcessing]="vm.areOtherMissionsProcessing"
                            [missionDataFormData]="vm.missionDataFormData"
                            [currentPlanItinerary]="vm.currentPlanItinerary"
                            [collisionZones]="vm.collisionZones"
                            [selectedZoneId]="vm.selectedZoneId"
                            [missionType]="vm.missionDataFormData?.capabilities?.missionType"
                            [setup]="vm.missionDataFormData?.capabilities?.uavWithSetup?.setup"
                            [operationalGeometryData]="vm.operationalGeometryData"
                            (back)="
                                vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Assisted
                                    ? gotToRouteSelectorStep()
                                    : goToItineraryEditorStep()
                            "
                            (next)="goToSummaryStep()"
                            (additionalInformationChange)="updateAdditionalInformation($event)"
                            (flyToRoute)="flyToRouteById($event)"
                            (flyToMainMissionRoute)="flyToMainMissionRoute()"
                            (flyToZone)="flyToGeometry($event)"
                            (otherMissionSelected)="handleOtherMissionSelection($event)"
                            (soraSettingsUpdate)="handleSoraSettingsUpdates($event)"
                            (zoneSelect)="selectZone($event)"
                        >
                        </dtm-web-app-lib-mission-wizard-analysis-step>
                    </ng-container>

                    <ng-container *dtmUiLibWizardStepContent="MissionWizardSteps.Summary">
                        <dtm-web-app-lib-mission-wizard-summary-step
                            [currentPlanDataAndCapabilities]="vm.currentPlanDataAndCapabilities"
                            [missionDataFormData]="vm.missionDataFormData"
                            [itineraryEditorFormData]="vm.itineraryEditorFormData"
                            [analysisStatus]="vm.currentPlanAnalysisStatus"
                            [missionInformation]="vm.missionPlanInformation"
                            [isProcessing]="vm.isProcessing"
                            [stepNumber]="vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Assisted ? 5 : 4"
                            [stepsAmount]="stepsAmount"
                            [route]="vm.route"
                            [nearbyMissions]="vm.nearbyMissions"
                            [collisionMission]="vm.collisionMissions"
                            [collisionZones]="vm.collisionZones"
                            [operators]="vm.operators"
                            [selectedZoneId]="vm.selectedZoneId"
                            [currentPlanItinerary]="vm.currentPlanItinerary"
                            [missionType]="vm.missionDataFormData?.capabilities?.missionType"
                            [setup]="vm.missionDataFormData?.capabilities?.uavWithSetup?.setup"
                            [operationalGeometryData]="vm.operationalGeometryData"
                            (missionSubmit)="submitMission($event)"
                            (specificPermitApply)="applyForSpecificPermit()"
                            (back)="goToAnalysisStep()"
                            (edit)="edit()"
                            [additionalInformation]="vm.additionalInformation"
                            (additionalInformationChange)="updateAdditionalInformation($event)"
                            (formalJustificationChange)="updateOrCreateFormalJustification($event)"
                            (flyToRoute)="flyToRouteById($event)"
                            (flyToMainMissionRoute)="flyToMainMissionRoute()"
                            (otherMissionSelected)="handleOtherMissionSelection($event)"
                            (zoneSelect)="selectZone($event)"
                            (sideViewTemplate)="setBottomPanelTemplate($event, MissionWizardSteps.Summary)"
                        >
                        </dtm-web-app-lib-mission-wizard-summary-step>
                    </ng-container>
                </dtm-ui-lib-wizard-content>
                <div class="view-switcher">
                    <button type="button" (click)="togglePanelFold(vm.isDataPanelFolded)">
                        <dtm-ui-icon
                            [name]="vm.isDataPanelFolded ? 'menu-unfold' : 'menu-fold'"
                            [matTooltip]="'dtmWebAppLibMission.foldingButtonLabel' | transloco : { isFolded: vm.isDataPanelFolded }"
                            [attr.aria-label]="'dtmWebAppLibMission.foldingButtonLabel' | transloco : { isFolded: vm.isDataPanelFolded }"
                            [attr.aria-expanded]="!vm.isDataPanelFolded"
                            matTooltipPosition="right"
                        ></dtm-ui-icon>
                    </button>
                </div>
            </div>

            <div class="mission-planner-map-container">
                <div
                    class="map-container"
                    [class.show-outside-dtm-segment-pin]="vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Custom"
                >
                    <ac-map
                        [sceneMode]="SceneMode.SCENE3D"
                        *ngIf="initialViewbox$ | ngrxPush"
                        dtmMapPointerManager
                        dtmMapCesiumCleanupOnDestroy
                    >
                        <dtm-web-app-lib-analysis-alert
                            [analysisStatus]="vm.currentPlanAnalysisStatus"
                            *ngIf="vm.activeStepId === MissionWizardSteps.Analysis"
                        ></dtm-web-app-lib-analysis-alert>
                        <dtm-web-app-lib-height-violation-alert-container
                            *ngIf="vm.activeStepId === MissionWizardSteps.ItineraryEditor || vm.activeStepId === MissionWizardSteps.Summary"
                        ></dtm-web-app-lib-height-violation-alert-container>
                        <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
                        <dtm-web-app-lib-fts-alert-container
                            *ngIf="
                                vm.activeStepId === MissionWizardSteps.Analysis &&
                                (vm.currentPlanAnalysisStatus?.sora?.issues
                                    | invoke : hasAnalysisStatusError : missionAnalysisCodes.soraFts)
                            "
                        ></dtm-web-app-lib-fts-alert-container>
                        <dtm-web-app-lib-grc-alert-container
                            *ngIf="
                                vm.activeStepId === MissionWizardSteps.Analysis &&
                                (vm.currentPlanAnalysisStatus?.sora?.issues
                                    | invoke : hasAnalysisStatusError : missionAnalysisCodes.failGrc)
                            "
                        ></dtm-web-app-lib-grc-alert-container>
                        <dtm-web-app-lib-assisted-editor-map-features
                            [isShown]="
                                !vm.areRouteEntitiesVisible &&
                                vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Assisted &&
                                vm.activeStepId === MissionWizardSteps.ItineraryEditor
                            "
                        ></dtm-web-app-lib-assisted-editor-map-features>
                        <dtm-map-cylinders-editor
                            [labelsTemplate]="cylinderLabelsTemplate"
                            [show]="!vm.areRouteEntitiesVisible"
                        ></dtm-map-cylinders-editor>
                        <dtm-map-prisms-editor
                            [labelsTemplate]="prismLabelsTemplate"
                            [show]="!vm.areRouteEntitiesVisible"
                        ></dtm-map-prisms-editor>
                        <dtm-map-polylines3d-editor [show]="!vm.areRouteEntitiesVisible" [labelTemplate]="polylineLabelTemplate">
                        </dtm-map-polylines3d-editor>
                        <dtm-map-route-viewer-layer
                            [routeData]="routeData$ | ngrxPush"
                            [drawableFeatures]="routeDrawableFeature"
                            [shouldDisplayTimeRange]="!(vm.missionDataFormData?.category | invoke : shouldDisplayTimeRangeLabel)"
                            [nearbyMissionsDrawableFeatures]="
                                vm.activeStepId === MissionWizardSteps.Analysis || vm.activeStepId === MissionWizardSteps.Summary
                                    ? nearbyMissionsRouteDrawableFeature
                                    : []
                            "
                            [isShown]="!!vm.areRouteEntitiesVisible"
                            (entityZoom)="flyToMainMissionRoute()"
                        ></dtm-map-route-viewer-layer>
                        <dtm-web-app-lib-child-entity-height-points-layer
                            *ngIf="vm.itineraryEditorFormData?.editorType === ItineraryEditorType.Custom"
                            [isShown]="!vm.areRouteEntitiesVisible"
                            [labelsTemplate]="polylineLabelTemplate"
                            [constraints]="vm.constraints"
                            (entityUpdate)="updateEntity($event)"
                        >
                        </dtm-web-app-lib-child-entity-height-points-layer>
                        <dtm-web-app-lib-circular-boundary-violation
                            *ngIf="circularBoundaryLayerMaxRadius$ | ngrxPush as circularBoundaryLayerMaxRadius"
                            [isShown]="!vm.areRouteEntitiesVisible"
                            [maxRadius]="circularBoundaryLayerMaxRadius"
                            (violationChange)="changeCircularBoundaryViolation($event)"
                        >
                        </dtm-web-app-lib-circular-boundary-violation>
                        <dtm-web-app-lib-route-selector-map-features
                            [isShown]="vm.activeStepId === MissionWizardSteps.RouteSelector"
                            [routes]="routeSuggestions$ | ngrxPush"
                            [selectedRouteId]="vm.route?.routeId"
                            [previewRouteId]="previewRouteId$ | ngrxPush"
                            (routeSelected)="selectRoute($event)"
                            (previewRoute)="previewRoute($event)"
                        ></dtm-web-app-lib-route-selector-map-features>
                        <div class="layers-controls" [class.actions-visible]="vm.isMapActionsBarVisible">
                            <dtm-map-layers-with-controls
                                isGeoZonesFiltersFullMode
                                isKmlImportEnabled
                                [missionTimeRange]="missionTimeRange$ | ngrxPush"
                                [routes]="
                                    routeData$
                                        | ngrxPush
                                        | invoke
                                            : getRoutesFromRouteData
                                            : !(
                                                  vm.activeStepId &&
                                                  [MissionWizardSteps.MissionData, MissionWizardSteps.ItineraryEditor].includes(
                                                      vm.activeStepId
                                                  )
                                              )
                                "
                                [shouldShowAreaBuffers]="
                                    vm.activeStepId !== MissionWizardSteps.MissionData &&
                                    vm.missionDataFormData?.category?.type !== MissionCategory.Open &&
                                    !(vm.itineraryContent | isEmptyArray)
                                "
                                [areAreaBuffersProcessing]="vm.activeStepId === MissionWizardSteps.ItineraryEditor && vm.isProcessing"
                                [mapLayersValue]="vm.missionDataFormData?.capabilities?.missionType | invoke : getMapLayersForMissionType"
                                [timeSettingOptions]="{
                                    available: [ZoneTimesSetting.MissionTime, ZoneTimesSetting.Soon, ZoneTimesSetting.CustomTimeRange]
                                }"
                            ></dtm-map-layers-with-controls>
                        </div>
                        <dtm-map-measures-cesium [isMeasureEnabled]="vm.isMeasureToolActive"></dtm-map-measures-cesium>
                    </ac-map>
                    <div class="map-actions" [@foldDown]="vm.isMapActionsBarVisible ? 'open' : 'closed'">
                        <ng-container *ngIf="vm.isMapActionsBarVisible">
                            <dtm-web-app-lib-mission-wizard-itinerary-editor-step-map-actions-header
                                [editorType]="vm.selectedEditorType"
                                [constraints]="vm.constraints"
                                [isEnlargedZoneStatementAccepted]="vm.itineraryEditorFormData?.isEnlargedZoneStatementAccepted"
                                [missionType]="vm.missionDataFormData?.capabilities?.missionType"
                                [category]="vm.missionDataFormData?.category"
                                [maxEntities]="
                                    vm.selectedEditorType | invoke : getMaxEditorEntities : vm.constraints : vm.missionDataFormData
                                "
                            >
                            </dtm-web-app-lib-mission-wizard-itinerary-editor-step-map-actions-header>
                            <dtm-map-actions-panel
                                [mode]="vm.selectedMapActionsPanelMode"
                                [activeMapAction]="vm.activeMapAction"
                                [entitiesCount]="vm.itineraryContent.length"
                                [maxEntities]="
                                    vm.selectedEditorType | invoke : getMaxEditorEntities : vm.constraints : vm.missionDataFormData
                                "
                                [activeEntityStatus]="vm.activeEntityStatus"
                                [isDisabled]="vm.isProcessing || vm.areEditorsDisabled || !vm.areMissionParametersFormsValid"
                                (selectMapAction)="processMapActionChange($event, vm.constraints)"
                                *ngIf="vm.activeStepId === MissionWizardSteps.ItineraryEditor"
                            >
                            </dtm-map-actions-panel>
                        </ng-container>
                    </div>
                    <dtm-map-controls
                        [isMeasureToolEnabled]="vm.isMeasureToolEnabled"
                        [isMeasureToolActive]="vm.isMeasureToolActive"
                        (measureToolStatusChange)="updateMeasureToolStatus($event)"
                    ></dtm-map-controls>
                </div>
                <ng-template [cdkPortalOutlet]="bottomPanelTemplate$ | ngrxPush"></ng-template>
            </div>
        </div>
        <div class="bottom-actions" *ngIf="vm.activeStepId">
            <ng-template *ngIf="footerTemplate" [cdkPortalOutlet]="footerTemplate.get(vm.activeStepId)"></ng-template>
        </div>
    </ng-template>

    <ng-template #polylineLabelTemplate let-label>
        <ng-container [ngSwitch]="label.id" *ngIf="label.show && vm.areLabelsVisible">
            <ng-container *ngSwitchCase="'segment'">
                <svg width="100" height="100" viewBox="-50 -50 100 100" class="segment line-connector">
                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="0" />
                </svg>
                <div class="label-pin segment-label" [class.outside-dtm-segment-pin]="!vm.isRouteInDtm">
                    <div class="header" *ngIf="label.text.type !== SegmentLabelType.Segment">
                        <ng-container [ngSwitch]="label.text.type">
                            <dtm-ui-icon name="flight-takeoff" *ngSwitchCase="SegmentLabelType.FirstChild"></dtm-ui-icon>
                            <dtm-ui-icon name="flight-land" *ngSwitchCase="SegmentLabelType.LastChild"></dtm-ui-icon>
                        </ng-container>

                        <span
                            *ngIf="
                                vm.currentPlanRouteWaypoints | invoke : getSegmentAmslHeights : label.text as heights;
                                else segmentContentLoadingTemplate
                            "
                        >
                            {{
                                "dtmWebAppLibMission.itineraryEditorStep.customEditor.childEntityAmslMapLabel"
                                    | transloco
                                        : {
                                              topHeight: heights.topHeight | number : "1.0-0",
                                              bottomHeight: heights.bottomHeight | number : "1.0-0"
                                          }
                            }}
                        </span>
                    </div>
                    <p class="content">
                        <dtm-ui-icon name="time"></dtm-ui-icon>
                        <ng-container
                            *ngIf="
                                vm.currentPlanRouteWaypoints | invoke : getSegmentDuration : label.text.waypointIndex as duration;
                                else segmentContentLoadingTemplate
                            "
                        >
                            <!-- TODO: DTM-2460 - localizeDate (not pure) has performance issues when used within @pansa/ngx-cesium -->
                            {{ duration.fromTime | localizeDatePure : { timeStyle: "short" } }}
                            -
                            {{ duration.toTime | localizeDatePure : { timeStyle: "short" } }}
                        </ng-container>
                    </p>
                    <ng-template #segmentContentLoadingTemplate>
                        <!-- TODO: DTM-4845 implement loaders when designed -->
                        ładuję...
                    </ng-template>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'pointHeight'">
                <svg
                    width="100"
                    height="100"
                    viewBox="-50 -50 100 100"
                    class="point-height line-connector"
                    [class.child-entity-center]="label.isChildEntityCenter"
                >
                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="0" />
                </svg>
                <div class="label-pin point-height-label" [class.child-entity-center]="label.isChildEntityCenter">
                    <div class="header">
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.customEditor.pointHeightMapLabel"
                                | transloco
                                    : {
                                          waypointLabel: label.text.waypointLabel,
                                          aglHeight: label.text.height | number : "1.0-0",
                                          amslHeight:
                                              vm.currentPlanRouteWaypoints
                                              | invoke : getAmslHeightForWaypoint : label.text.waypointIndex : label.halfVerticalBuffer
                                      }
                        }}
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <svg
                    width="100"
                    height="100"
                    viewBox="-50 -50 100 100"
                    class="point-height line-connector"
                    [class.child-entity-center]="label.isChildEntityCenter"
                >
                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="0" />
                </svg>
                <div class="label-pin point-height-label" [class.child-entity-center]="label.isChildEntityCenter">
                    <div class="header">{{ label.text }}</div>
                    <p *ngIf="label.stopover" class="stopover-label" (click)="editStopover(label.parentId, label.waypointIndex)">
                        <dtm-ui-icon class="stopover-icon" name="time"></dtm-ui-icon>
                        <span>
                            {{
                                label.stopover
                                    | duration : "short" : ("dtmWebAppLibMission.itineraryEditorStep.zeroDurationLabel" | transloco)
                            }}
                        </span>
                        <dtm-ui-icon class="stopover-edit-icon" name="edit"></dtm-ui-icon>
                    </p>
                </div>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #cylinderLabelsTemplate let-label>
        <ng-container [ngSwitch]="label.entityId" *ngIf="label.show">
            <dtm-ui-icon name="flight-takeoff" class="flight-pin" *ngSwitchCase="AssistedEntityId.Takeoff"></dtm-ui-icon>
            <dtm-ui-icon name="flight-land" class="flight-pin" *ngSwitchCase="AssistedEntityId.Landing"></dtm-ui-icon>
            <ng-container *ngSwitchDefault>
                <svg width="100" height="100" viewBox="-50 -50 100 100" class="line-connector" [class.radius]="label.id === 'radius'">
                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="0" />
                </svg>
                <div class="label-pin default-label" *ngIf="label.text" [class.radius-label]="label.id === 'radius'">
                    {{ label.text }}
                </div>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #prismLabelsTemplate let-label>
        <ng-container [ngSwitch]="label.entityId" *ngIf="label.show">
            <ng-container *ngSwitchDefault>
                <svg width="100" height="100" viewBox="-50 -50 100 100" class="line-connector">
                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="0" />
                </svg>
                <div class="label-pin default-label" *ngIf="label.text">
                    {{ label.text }}
                </div>
            </ng-container>
        </ng-container>
    </ng-template>
</ng-container>
