import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MissionPlanAirspaceAnalysisOption, SoraResult } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { combineLatest, map } from "rxjs";
import { AirspaceCharacteristics, MissionUAVSetup } from "../../../../../../models/mission.model";

interface AdjacentAreaAndSpaceComponentState {
    soraResult: SoraResult | undefined;
    uavSetup: MissionUAVSetup | undefined;
    airspaceAnalysis: MissionPlanAirspaceAnalysisOption | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-web-app-lib-adjacent-area-and-space[soraResult][airspaceAnalysis]",
    templateUrl: "./adjacent-area-and-space.component.html",
    styleUrls: ["./adjacent-area-and-space.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdjacentAreaAndSpaceComponent {
    @Input() public set soraResult(value: SoraResult | undefined) {
        this.localStore.patchState({ soraResult: value });
    }
    @Input() public set setup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ uavSetup: value });
    }
    @Input() public set airspaceAnalysis(value: MissionPlanAirspaceAnalysisOption | undefined) {
        this.localStore.patchState({ airspaceAnalysis: value });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly soraResult$ = this.localStore.selectByKey("soraResult");
    protected readonly airspaceAnalysis$ = this.localStore.selectByKey("airspaceAnalysis");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly technicalRequirementsText$ = combineLatest([this.localStore.selectByKey("uavSetup"), this.soraResult$]).pipe(
        map(([uavSetup, soraResult]) => this.getTechnicalRequirementsText(uavSetup, soraResult))
    );
    protected readonly airspaceCharacteristics$ = this.airspaceAnalysis$.pipe(map((airspace) => this.getAirspaceCharacteristics(airspace)));

    constructor(
        private readonly localStore: LocalComponentStore<AdjacentAreaAndSpaceComponentState>,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            soraResult: undefined,
            uavSetup: undefined,
            airspaceAnalysis: undefined,
            isExpanded: false,
        });
    }

    private getTechnicalRequirementsText(data: MissionUAVSetup | undefined, soraResult: SoraResult | undefined): string | undefined {
        const technicalSpecification = data?.technicalSpecification;
        if (!technicalSpecification) {
            return undefined;
        }

        const text: string[] = [];

        if (technicalSpecification.failSafe.some((value) => !!value)) {
            text.push(this.transloco.translate("dtmWebAppLibMission.summaryStep.analysis.sora.failsafeLabel"));
        }

        if (technicalSpecification.hasGeofencing) {
            text.push(this.transloco.translate("dtmWebAppLibMission.summaryStep.analysis.sora.geofencingLabel"));
        }

        if (technicalSpecification.hasGeocage) {
            text.push(this.transloco.translate("dtmWebAppLibMission.summaryStep.analysis.sora.geocageLabel"));
        }

        if (soraResult?.flightTerminationSystem.isAvailable) {
            text.push(this.transloco.translate("dtmWebAppLibMission.summaryStep.analysis.sora.FTSSystemLabel"));
        }

        return text.join(", ") || undefined;
    }

    private getAirspaceCharacteristics(airspace: MissionPlanAirspaceAnalysisOption | undefined): AirspaceCharacteristics {
        if (!airspace) {
            return AirspaceCharacteristics.Unknown;
        }
        if (airspace.isAdjacentAreaControlled && airspace.isAdjacentAreaUncontrolled) {
            return AirspaceCharacteristics.Both;
        }
        if (airspace.isAdjacentAreaControlled && !airspace.isAdjacentAreaUncontrolled) {
            return AirspaceCharacteristics.Controlled;
        }
        if (!airspace.isAdjacentAreaControlled && airspace.isAdjacentAreaUncontrolled) {
            return AirspaceCharacteristics.Uncontrolled;
        }

        return AirspaceCharacteristics.Unknown;
    }
}
