import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { dtmOperatorPermissionGuard } from "../../guards/dtm-operator-permission.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { MissionDetailsComponent } from "./components/mission-details/mission-details.component";
import { MissionPlanListContentComponent } from "./components/mission-plan-list/content/mission-plan-list-content.component";
import { MissionWizardContentComponent } from "./components/mission-wizard/content/mission-wizard-content.component";
import { MissionWizardHeaderComponent } from "./components/mission-wizard/header/mission-wizard-header.component";
import { MissionPlanningGuard } from "./services/mission-planning.guard";
import {
    EditMissionResolver,
    MissionCapabilitiesResolver,
    MissionsListResolvers,
    missionDetailsResolver,
} from "./services/mission.resolvers";

const routes: Routes = [
    {
        path: "mission/new",
        component: MissionWizardContentComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            MissionPlanningGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsPlan]),
        ],
        canDeactivate: [(component: MissionWizardContentComponent) => component.canDeactivate()],
        resolve: {
            init: MissionCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibMission.routeTitles.missionWizardNew",
            headerComponent: MissionWizardHeaderComponent,
            className: "no-padding",
        },
    },
    {
        path: "plan/edit/:id",
        component: MissionWizardContentComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            MissionPlanningGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsPlan]),
        ],
        resolve: {
            init: EditMissionResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibMission.routeTitles.missionWizardEdit",
            headerComponent: MissionWizardHeaderComponent,
            isEditMode: true,
            className: "no-padding",
        },
    },
    {
        path: "plan/list/:id",
        component: MissionDetailsComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            MissionPlanningGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsRead]),
        ],
        resolve: {
            init: missionDetailsResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibMission.routeTitles.missionWizardDetails",
            isDetailsMode: true,
            className: "no-padding",
        },
    },
    {
        path: "plan/list",
        component: MissionPlanListContentComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            MissionPlanningGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsRead]),
        ],
        resolve: {
            init: MissionsListResolvers,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibMission.routeTitles.missionList",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [MissionCapabilitiesResolver, MissionsListResolvers, EditMissionResolver],
})
export class MissionRoutingModule {}
