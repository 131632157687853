<ng-container *ngrxLet="data.isNameProcessing$ as isProcessing">
    <div class="dialog-header">
        <h4 mat-dialog-title>{{ data.titleText }}</h4>
        <button type="button" class="button-icon" [matDialogClose]="false" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <dtm-ui-input-field>
            <label>{{ "dtmWebAppLibOperatorPermits.changeNameDialog.inputLabel" | transloco }} </label>
            <input matInput type="text" [formControl]="nameControl" />
            <div class="field-error" *dtmUiFieldHasError="nameControl; name: 'required'">
                {{ "dtmWebAppLibOperatorPermits.changeNameDialog.requiredFieldError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="nameControl; name: 'minlength'; error as error">
                {{
                    "dtmWebAppLibOperatorPermits.changeNameDialog.reasonMinLengthValueError"
                        | transloco : { minLength: error.requiredLength }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="nameControl; name: 'maxlength'; error as error">
                {{
                    "dtmWebAppLibOperatorPermits.changeNameDialog.reasonMaxLengthValueError"
                        | transloco : { maxLength: error.requiredLength }
                }}
            </div>
        </dtm-ui-input-field>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false" [disabled]="isProcessing">
            {{ "dtmWebAppLibOperatorPermits.changeNameDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="confirm()" [disabled]="isProcessing || nameControl.pristine">
            {{ "dtmWebAppLibOperatorPermits.changeNameDialog.saveButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
