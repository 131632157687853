<ng-container
    *ngrxLet="{
        operationCategoryFormGroupValue: operationCategoryFormGroupValue$,
        openCategoryOptions: openCategoryOptions$,
        stsCategoryOptions: stsCategoryOptions$,
        enabledOpenCategoryOptions: enabledOpenCategoryOptions$,
        disabledOpenCategoryOptions: disabledOpenCategoryOptions$,
        enabledStsCategoryOptions: enabledStsCategoryOptions$,
        disabledStsCategoryOptions: disabledStsCategoryOptions$,
        isCaaPermit: isCaaPermit$,
        lucCategoryOptions: lucCategoryOptions$,
        individualCategoryOption: individualCategoryOption$,
    } as vm"
>
    <div class="form-entry">
        <p class="field-label">{{ "dtmWebAppLibMission.dataFormStep.operationCategoryLabel" | transloco }}</p>
        <div class="cards mission-plan-cards">
            <div
                *ngIf="!vm.isCaaPermit"
                class="selectable-tile"
                (click)="vm.enabledOpenCategoryOptions.length > 0 && setCategoryTypeOption(MissionCategory.Open)"
                [ngClass]="{
                    selected: vm.operationCategoryFormGroupValue.category === MissionCategory.Open,
                    disabled: vm.enabledOpenCategoryOptions.length === 0 || operationCategoryFormGroup.disabled
                }"
                [matTooltip]="
                    (vm.openCategoryOptions.length > 0
                        ? 'dtmWebAppLibMission.dataFormStep.openCategoryPopoverText'
                        : 'dtmWebAppLibMission.dataFormStep.noSubcategoriesTooltipMessage'
                    ) | transloco
                "
                data-testid="operation-open-category-tile"
            >
                <dtm-ui-icon name="information-fill" class="icon selectable-tile-info-icon"></dtm-ui-icon>
                <div class="selectable-tile-wrapper">
                    <h2 class="selectable-tile-label">
                        {{ "dtmWebAppLibMission.dataFormStep.openCategoryLabel" | transloco }}
                    </h2>
                </div>
            </div>
            <div
                class="selectable-tile"
                (click)="setCategoryTypeOption(MissionCategory.Specific)"
                [ngClass]="{
                    selected: vm.operationCategoryFormGroupValue.category === MissionCategory.Specific,
                    disabled: operationCategoryFormGroup.disabled
                }"
                [matTooltip]="'dtmWebAppLibMission.dataFormStep.specificPermitPopoverText' | transloco"
            >
                <dtm-ui-icon name="information-fill" class="selectable-tile-info-icon"></dtm-ui-icon>
                <div class="selectable-tile-wrapper">
                    <h6 class="selectable-tile-label">
                        {{ "dtmWebAppLibMission.dataFormStep.specificPermitLabel" | transloco }}
                    </h6>
                </div>
            </div>
            <div class="selectable-tile disabled" [matTooltip]="'dtmWebAppLibMission.dataFormStep.certificatedPopoverText' | transloco">
                <!-- TODO: dtmWebAppLibMission.dataFormStep.certificatedPopoverText is empty -->
                <dtm-ui-icon name="information-fill" class="selectable-tile-info-icon"></dtm-ui-icon>
                <div class="selectable-tile-wrapper">
                    <h6 class="selectable-tile-label">
                        {{ "dtmWebAppLibMission.dataFormStep.certificatedLabel" | transloco }}
                    </h6>
                </div>
            </div>
        </div>
        <dtm-ui-form-errors class="preserve-error-space">
            <div class="field-error" *dtmUiFieldHasError="categoryFormControl; name: 'required'">
                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-form-errors>
    </div>
    <ng-container *ngIf="!vm.isCaaPermit">
        <div class="form-entry" *ngIf="vm.operationCategoryFormGroupValue.category === MissionCategory.Open">
            <dtm-ui-select-field
                [isClearable]="false"
                (selectionChange)="setOpenScenarioOption($event.value)"
                [formControl]="openScenarioFormControl"
            >
                <label>{{ "dtmWebAppLibMission.dataFormStep.subcategoryLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let option of vm.enabledOpenCategoryOptions" [value]="option">
                    {{ option.scenarioName }}
                </dtm-ui-select-option>
                <span *ngIf="vm.disabledOpenCategoryOptions.length > 0" class="disabled-select-options-group">
                    {{ "dtmWebAppLibMission.dataFormStep.disabledSubcategoriesLabel" | transloco }}
                </span>
                <dtm-ui-select-option *ngFor="let option of vm.disabledOpenCategoryOptions" disabled class="disabled-operation-scenario">
                    {{ option.scenarioName }}
                    <p hint *ngFor="let issue of option.issues">{{ issue.code | systemTranslation : issue.args }}</p>
                </dtm-ui-select-option>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="openScenarioFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-select-field>
        </div>
        <ng-container *ngIf="vm.operationCategoryFormGroupValue.category === MissionCategory.Specific">
            <div class="form-entry">
                <p class="field-label">{{ "dtmWebAppLibMission.dataFormStep.legalBasisLabel" | transloco }}</p>
                <div class="cards mission-plan-cards">
                    <div
                        class="selectable-tile"
                        (click)="vm.enabledStsCategoryOptions.length > 0 && setSpecificPermitTypeOption(MissionPlanSpecificPermitType.Sts)"
                        [ngClass]="{
                            selected: vm.operationCategoryFormGroupValue.specificPermit === MissionPlanSpecificPermitType.Sts,
                            disabled: vm.enabledStsCategoryOptions.length === 0 || operationCategoryFormGroup.disabled
                        }"
                        [matTooltip]="'dtmWebAppLibMission.dataFormStep.specificPermitPermit.stsScenarioBasedPopoverText' | transloco"
                    >
                        <dtm-ui-icon name="information-fill" class="selectable-tile-info-icon"></dtm-ui-icon>
                        <div class="selectable-tile-wrapper">
                            <h6 class="selectable-tile-label">
                                {{ "dtmWebAppLibMission.dataFormStep.specificPermitPermit.stsScenarioBasedLabel" | transloco }}
                            </h6>
                        </div>
                    </div>
                    <div
                        class="selectable-tile"
                        (click)="
                            vm.individualCategoryOption &&
                                setSpecificPermitTypeOption(MissionPlanSpecificPermitType.Individual, vm.individualCategoryOption)
                        "
                        [ngClass]="{
                            selected: vm.operationCategoryFormGroupValue.specificPermit === MissionPlanSpecificPermitType.Individual,
                            disabled: !vm.individualCategoryOption || operationCategoryFormGroup.disabled
                        }"
                        [matTooltip]="'dtmWebAppLibMission.dataFormStep.specificPermitPermit.individualBasedPopoverText' | transloco"
                    >
                        <dtm-ui-icon name="information-fill" class="selectable-tile-info-icon"></dtm-ui-icon>
                        <div class="selectable-tile-wrapper">
                            <h6 class="selectable-tile-label">
                                {{ "dtmWebAppLibMission.dataFormStep.specificPermitPermit.individualBasedLabel" | transloco }}
                            </h6>
                            <span class="selectable-tile-description">
                                {{ "dtmWebAppLibMission.dataFormStep.specificPermitPermit.individualBasedDescriptionLabel" | transloco }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="selectable-tile"
                        (click)="
                            vm.lucCategoryOptions &&
                                setSpecificPermitTypeOption(
                                    MissionPlanSpecificPermitType.Luc,
                                    vm.operationCategoryFormGroupValue.skipSora
                                        ? vm.lucCategoryOptions.skipSora
                                        : vm.lucCategoryOptions.withSora
                                )
                        "
                        [ngClass]="{
                            selected: vm.operationCategoryFormGroupValue.specificPermit === MissionPlanSpecificPermitType.Luc,
                            disabled: true || !vm.lucCategoryOptions || operationCategoryFormGroup.disabled
                        }"
                        [matTooltip]="'dtmWebAppLibMission.dataFormStep.specificPermitPermit.lucBasedPopoverText' | transloco"
                    >
                        <!-- TODO: the `disabled: true` is because currently we don't have LUC permi type enabled in the system at all  -->
                        <!-- remove `true ||` if you're working on enbaling it -->
                        <dtm-ui-icon name="information-fill" class="selectable-tile-info-icon"></dtm-ui-icon>
                        <div class="selectable-tile-wrapper">
                            <h6 class="selectable-tile-label">
                                {{ "dtmWebAppLibMission.dataFormStep.specificPermitPermit.lucBasedLabel" | transloco }}
                            </h6>
                        </div>
                    </div>
                </div>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="specificPermitFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </div>
            <div class="form-entry" *ngIf="vm.operationCategoryFormGroupValue.specificPermit === MissionPlanSpecificPermitType.Luc">
                <dtm-ui-checkbox-field
                    [formControl]="lucSkipSoraFormControl"
                    (change)="
                        vm.lucCategoryOptions &&
                            setSpecificPermitTypeOption(
                                MissionPlanSpecificPermitType.Luc,
                                $event.checked ? vm.lucCategoryOptions.skipSora : vm.lucCategoryOptions.withSora
                            )
                    "
                >
                    {{ "dtmWebAppLibMission.dataFormStep.lucSkipSoraScenarioLabel" | transloco }}
                </dtm-ui-checkbox-field>
            </div>
            <div class="form-entry" *ngIf="vm.operationCategoryFormGroupValue.specificPermit === MissionPlanSpecificPermitType.Sts">
                <dtm-ui-select-field
                    [isClearable]="false"
                    (selectionChange)="setSpecificScenarioOption($event.value)"
                    [formControl]="specificScenarioFormControl"
                >
                    <label>{{ "dtmWebAppLibMission.dataFormStep.scenarioLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let option of vm.enabledStsCategoryOptions" [value]="option">
                        {{ option.scenarioName }}
                    </dtm-ui-select-option>
                    <span *ngIf="vm.disabledStsCategoryOptions.length > 0" class="disabled-select-options-group">
                        {{ "dtmWebAppLibMission.dataFormStep.disabledScenariosLabel" | transloco }}
                    </span>
                    <dtm-ui-select-option *ngFor="let option of vm.disabledStsCategoryOptions" disabled class="disabled-operation-scenario">
                        {{ option.scenarioName }}
                        <p hint *ngFor="let issue of option.issues">{{ issue.code | systemTranslation : issue.args }}</p>
                    </dtm-ui-select-option>
                    <dtm-ui-form-errors>
                        <div class="field-error" *dtmUiFieldHasError="specificScenarioFormControl; name: 'required'">
                            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </dtm-ui-select-field>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
