import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { DefaultValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";

@Component({
    selector: "dtm-web-app-lib-uav-swarm-control",
    templateUrl: "./uav-swarm-control.component.html",
    styleUrls: ["./uav-swarm-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useClass: DefaultValueAccessor,
            multi: true,
        },
    ],
})
export class UavSwarmControlComponent {
    protected get isSwarm(): boolean {
        return !!this.control?.value;
    }

    private get control() {
        return this.ngControl.control;
    }

    constructor(@Inject(NgControl) private readonly ngControl: NgControl) {}

    protected setIsSwarm(value: boolean) {
        this.control?.setValue(value);
    }
}
