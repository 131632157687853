import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { GlobalOperatorPermissions, PermitType } from "@dtm-frontend/shared/ui";
import { dtmOperatorPermissionGuard } from "../../guards/dtm-operator-permission.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { AssociationPermitDetailsComponent } from "./components/operator-permits-container/components/association-permit-details/association-permit-details.component";
import { OwnedPermitsContainerComponent } from "./components/operator-permits-container/components/owned-permits-container/owned-permits-container.component";
import { PermitPreviewComponent } from "./components/operator-permits-container/components/permit-preview/permit-preview.component";
import { PermitsDraftsContainerComponent } from "./components/operator-permits-container/components/permits-drafts-container/permits-drafts-container.component";
import { OperatorPermitsContainerComponent } from "./components/operator-permits-container/operator-permits-container.component";
import { AssociationPermitsResolver } from "./services/association-permits.resolver";
import { CrossBorderPermitsResolver, OperatorPermitsResolver } from "./services/operator-permits.resolver";

const routes: Routes = [
    {
        path: "operator-permits",
        component: OperatorPermitsContainerComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsPlan, GlobalOperatorPermissions.OperatorManage]),
        ],
        data: {
            titleTranslationKey: "dtmWebAppLibOperatorPermits.routeTitles.operationsPermitsContainer",
            shouldSkipReloadOnOperatorChange: true,
        },
        children: [
            {
                path: "owned-permits",
                component: OwnedPermitsContainerComponent,
            },
            {
                path: "drafts",
                component: PermitsDraftsContainerComponent,
            },
        ],
    },
    {
        path: "operator-permits/:permitId/:operatorId",
        component: PermitPreviewComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsPlan, GlobalOperatorPermissions.OperatorManage]),
        ],
        resolve: {
            init: OperatorPermitsResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibOperatorPermits.routeTitles.specificPermitFlight",
            permitType: PermitType.OperationPermission,
        },
    },
    {
        path: "association-permits/:permitId/:operatorId",
        component: AssociationPermitDetailsComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsPlan, GlobalOperatorPermissions.OperatorManage]),
        ],
        resolve: {
            init: AssociationPermitsResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibOperatorPermits.routeTitles.associationPermitRequest",
        },
    },
    {
        path: "cross-border-permits/:permitId/:operatorId",
        component: PermitPreviewComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsPlan, GlobalOperatorPermissions.OperatorManage]),
        ],
        resolve: {
            init: CrossBorderPermitsResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibOperatorPermits.routeTitles.crossBorderPermitRequest",
            permitType: PermitType.CrossBorder,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperatorPermitsRoutingModule {}
