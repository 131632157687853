<div
    class="nearby-mission-panel"
    *ngrxLet="{ isNearbyMissionDataProcessing: isNearbyMissionDataProcessing$, nearbyMissions: nearbyMissions$ } as vm"
>
    <ng-container *ngrxLet="nearbyMissions$ as nearbyMissions">
        <div class="nearby-mission-settings" [formGroup]="nearbyMissionSettingFormGroup">
            <mat-slide-toggle [formControl]="areAllNearbyMissionsEnabledControl" labelPosition="before" class="spread">
                {{ "dtmWebAppLibTactical.nearbyMissions.allNearbyMissionsLabel" | transloco }}
            </mat-slide-toggle>

            <mat-slide-toggle [formControl]="areOnlyActiveNearbyMissionsControl" labelPosition="before" class="spread">
                {{ "dtmWebAppLibTactical.nearbyMissions.onlyActiveMissionsLabel" | transloco }}
            </mat-slide-toggle>
        </div>

        <ng-container
            *ngIf="
                nearbyMissionSettingFormGroup.controls.dataSettings.controls.areAllEnabled.value ||
                nearbyMissionSettingFormGroup.controls.dataSettings.controls.areOnlyActive.value
            "
        >
            <div class="mission-list-container" *ngrxLet="selectedNearbyMission$ as selectedNearbyMission">
                <dtm-ui-loader [shouldSetParentPositionRelative]="true" [isShown]="vm.isNearbyMissionDataProcessing"></dtm-ui-loader>
                <div
                    *ngFor="let mission of nearbyMissions; trackBy: trackByMissionId"
                    class="mission-data"
                    (click)="toggleSelectionOnNearbyMission(mission)"
                    [class.selected]="mission.missionId === selectedNearbyMission?.missionId"
                >
                    <div class="header">
                        <span class="title">
                            {{ mission.name || ("dtmWebAppLibShared.missionTile.noNameTitle" | transloco) }}
                        </span>
                        <div class="chip light" *ngIf="mission.flightType == MissionType.BVLOS">
                            <dtm-ui-icon name="eye-off"></dtm-ui-icon>
                            {{ "dtmWebAppLibShared.missionTypeLabel" | transloco : { value: MissionType.BVLOS } }}
                        </div>
                    </div>
                    <div class="data-container">
                        <div
                            class="indicator"
                            [ngClass]="{
                                active: mission.status === MissionStatus.Started,
                                waiting: mission.status === MissionStatus.Activated
                            }"
                        ></div>
                        <span class="time-container">
                            {{ mission.startTime.min | localizeDate : { timeStyle: "short" } }} -
                            {{ mission.endTime.max | localizeDate : { timeStyle: "short" } }}
                        </span>
                        <button
                            type="button"
                            class="button-icon action"
                            (click)="$event.stopImmediatePropagation(); zoomOnSelectedMission(mission)"
                        >
                            <dtm-ui-icon name="road-map"></dtm-ui-icon>
                        </button>
                        <dtm-ui-icon name="phone" class="phone-icon"></dtm-ui-icon>
                        <span class="phone-container">
                            {{
                                (mission.pilotPhoneNumber | formatPhoneNumber) ?? "dtmWebAppLibMission.missionTile.noDataLabel" | transloco
                            }}
                        </span>
                    </div>
                </div>
                <div class="no-data" *ngIf="!vm.isNearbyMissionDataProcessing && nearbyMissions?.length === 0">
                    {{ "dtmUi.noResults.message" | transloco }}
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
