<ng-container *ngrxLet="{ isPostSignature: isPostSignature$, isSignatureSuccess: isSignatureSuccess$ } as vm">
    <div *ngIf="vm.isPostSignature; else formTemplate">
        <dtm-ui-loader-container [isShown]="vm.isSignatureSuccess === undefined">
            <dtm-web-app-lib-legal-guardian-signature-status
                [isSuccess]="vm.isSignatureSuccess"
                (goBackToForm)="goBackToForm()"
            ></dtm-web-app-lib-legal-guardian-signature-status>
        </dtm-ui-loader-container>
    </div>
</ng-container>

<ng-template #formTemplate>
    <dtm-web-app-lib-legal-guardian-details-form
        [legalGuardianDetails]="legalGuardianDetails$ | ngrxPush"
        [legalGuardianDetailsError]="legalGuardianGeneralDataError$ | ngrxPush"
        (dataForSignatureSend)="sendDataForSignature($event)"
        (pdfGenerate)="generatePdf($event)"
    ></dtm-web-app-lib-legal-guardian-details-form>
</ng-template>
