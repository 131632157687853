<dtm-ui-expandable-panel
    *ngrxLet="{
        availableTrainings: availableTrainings$,
        currentTrainings: currentTrainings$,
        isTrainingsSetEmpty: isTrainingsSetEmpty$,
        activeLanguage: activeLanguage$,
        isEditMode: isEditMode$,
    } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [isDisabled]="vm.isEditMode || vm.isTrainingsSetEmpty"
    [hasHeaderSeparator]="false"
>
    <div class="panel-header" headerSlot>
        <div class="wrapper">
            <h2>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.trainingsHeader" | transloco }}</h2>
            <span>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.trainingsSubheader" | transloco }}</span>
        </div>
        <button
            *ngIf="!vm.isEditMode"
            type="button"
            class="button-secondary"
            [class]="!vm.isTrainingsSetEmpty ? 'edit-button' : 'add-button'"
            (click)="$event.stopPropagation(); setEditMode(true)"
        >
            <ng-container *ngIf="!vm.isTrainingsSetEmpty">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.editButtonLabel" | transloco }}
            </ng-container>
            <ng-container *ngIf="vm.isTrainingsSetEmpty">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.addButtonLabel" | transloco }}
            </ng-container>
        </button>
        <button *ngIf="vm.isEditMode" type="button" class="button-icon close-button" (click)="$event.stopPropagation(); cancel()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <section *ngIf="!vm.isEditMode">
        <ng-container *ngFor="let item of vm.currentTrainings | keyvalue">
            <ng-container *ngIf="item.value">
                <h3 class="member-header">
                    {{ "mission.additional_crew_members." + item.key + ".name" | systemTranslation }}
                </h3>
                <dtm-web-app-lib-spec-perm-app-trainings
                    [basicTrainings]="item.value.basic"
                    [additionalTrainings]="item.value.additional"
                    [activeLanguage]="vm.activeLanguage"
                >
                </dtm-web-app-lib-spec-perm-app-trainings>
            </ng-container>
        </ng-container>
        <div class="no-members" *ngIf="vm.isTrainingsSetEmpty">
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.noCrewTrainingsLabel" | transloco }}
        </div>
    </section>
    <section class="edit-mode-container" *ngIf="vm.isEditMode">
        <ng-container [formGroup]="crewTrainingsForm">
            <dtm-web-app-lib-spec-perm-app-crew-trainings-form
                formControlName="trainings"
                [availableTrainings]="vm.availableTrainings"
                [activeLanguage]="vm.activeLanguage"
            >
            </dtm-web-app-lib-spec-perm-app-crew-trainings-form>
        </ng-container>
        <footer class="actions">
            <button type="button" class="button-secondary" (click)="cancel()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="save()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.saveButtonLabel" | transloco }}
            </button>
        </footer>
    </section>
</dtm-ui-expandable-panel>
