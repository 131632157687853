import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AdditionalCrewMember, AdditionalCrewMemberWatcher, WATCHER_ID } from "@dtm-frontend/shared/mission";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    ConfirmationDialogConfig,
    PhoneNumber,
    requiredValidForSmsPhoneNumberValidator,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

const MAX_WATCHERS_COUNT = 9;

interface AdditionalCrewMembersEditComponentState {
    isEditModeOn: boolean;
    additionalCrewMembers: AdditionalCrewMember[];
    additionalCrewMembersOptions: string[];
    isLimitedEditMode: boolean;
    savedWatchers: AdditionalCrewMemberWatcher[];
}

interface WatcherForm {
    name: FormControl<string>;
    phoneNumber: FormControl<PhoneNumber | undefined>;
}

interface AdditionalCrewMembersForm {
    additionalCrewMembers: FormControl<string[]>;
    watchers: FormArray<FormGroup<WatcherForm>>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-additional-crew-members-edit[additionalCrewMembers][additionalCrewMembersOptions]",
    templateUrl: "./additional-crew-members-edit.component.html",
    styleUrls: ["./additional-crew-members-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdditionalCrewMembersEditComponent implements OnInit {
    protected readonly WATCHER_ID = WATCHER_ID;

    @Input() public set additionalCrewMembers(value: AdditionalCrewMember[] | undefined | null) {
        const additionalCrewMembers = !value ? [] : value;
        this.localStore.patchState({ additionalCrewMembers });
        this.buildCrewMembers(additionalCrewMembers);
    }
    @Input() public set additionalCrewMembersOptions(value: string[] | undefined) {
        const parsedValue = !value ? [] : value;
        this.localStore.patchState({ additionalCrewMembersOptions: parsedValue });
    }
    @Input() public set isLimitedEditMode(value: BooleanInput) {
        this.localStore.patchState({ isLimitedEditMode: coerceBooleanProperty(value) });
    }
    @Output() public save = new EventEmitter<AdditionalCrewMember[]>();

    protected readonly formGroup = new FormGroup<AdditionalCrewMembersForm>({
        additionalCrewMembers: new FormControl(
            { value: [], disabled: true },
            {
                nonNullable: true,
            }
        ),
        watchers: new FormArray<FormGroup<WatcherForm>>([]),
    });

    protected readonly additionalCrewMembersField = this.formGroup.controls.additionalCrewMembers;
    protected readonly watchersFormArray = this.formGroup.controls.watchers;

    protected readonly additionalCrewMembers$ = this.localStore.selectByKey("additionalCrewMembers");
    protected readonly additionalCrewMembersOptions$ = this.localStore.selectByKey("additionalCrewMembersOptions");
    protected readonly isEditModeOn$ = this.localStore.selectByKey("isEditModeOn");
    protected readonly isLimitedEditMode$ = this.localStore.selectByKey("isLimitedEditMode");
    protected readonly savedWatchers$ = this.localStore.selectByKey("savedWatchers");

    protected readonly MAX_WATCHERS_COUNT = MAX_WATCHERS_COUNT;

    constructor(
        private readonly localStore: LocalComponentStore<AdditionalCrewMembersEditComponentState>,
        private readonly formBuilder: FormBuilder,
        private readonly matDialog: MatDialog,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            isEditModeOn: false,
            additionalCrewMembers: [],
            additionalCrewMembersOptions: [],
            isLimitedEditMode: false,
            savedWatchers: [],
        });
    }

    public ngOnInit() {
        const watchers = this.watchersFormArray;
        this.additionalCrewMembersField.valueChanges.subscribe((crewMembers) => {
            if (crewMembers?.some((data) => data === WATCHER_ID)) {
                if (!watchers.length) {
                    this.addWatcher();
                }
            } else {
                watchers.controls = [];
                watchers.reset();
            }
        });
    }

    protected setEditMode(isEditModeOn: boolean): void {
        this.localStore.patchState({ isEditModeOn });

        if (isEditModeOn) {
            this.additionalCrewMembersField.enable();
        } else {
            this.additionalCrewMembersField.disable();
        }
    }

    protected addWatcher(name?: string, phone?: PhoneNumber) {
        this.watchersFormArray.push(
            this.formBuilder.nonNullable.group({
                name: [name ?? "", [Validators.required, Validators.maxLength(100)]],
                phoneNumber: [phone, [requiredValidForSmsPhoneNumberValidator as ValidatorFn]],
            })
        );
    }

    protected removeWatcher(index: number) {
        const data: ConfirmationDialogConfig = {
            titleText: this.translocoService.translate("dtmWebAppLibMission.dataFormStep.crew.deleteConfirmationDialog.title"),
            confirmationText: this.translocoService.translate("dtmWebAppLibMission.dataFormStep.crew.deleteConfirmationDialog.contentText"),
            confirmButtonLabel: this.translocoService.translate(
                "dtmWebAppLibMission.dataFormStep.crew.deleteConfirmationDialog.confirmButtonLabel"
            ),
            theme: ButtonTheme.Warn,
        };

        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                dialogRef.close();
                this.watchersFormArray.removeAt(index);
                this.setEditMode(true);
            });
    }

    protected cancelForm() {
        this.buildCrewMembers(this.localStore.selectSnapshotByKey("additionalCrewMembers"));
        this.setEditMode(false);
    }

    protected saveForm() {
        const watchers = this.watchersFormArray.value as AdditionalCrewMemberWatcher[];
        const additionalCrewMembers: AdditionalCrewMember[] =
            this.additionalCrewMembersField.value
                ?.filter((data) => data !== WATCHER_ID)
                .map((type: string) => ({
                    type,
                    name: "",
                    phoneNumber: undefined,
                })) ?? [];

        const watchersData: AdditionalCrewMember[] = watchers.map((data) => ({ ...data, type: WATCHER_ID }));
        this.save.next([...watchersData, ...additionalCrewMembers]);
        this.localStore.patchState({ isEditModeOn: false });
        this.additionalCrewMembersField.disable();
    }

    private buildCrewMembers(additionalCrewMembers: AdditionalCrewMember[]): void {
        this.additionalCrewMembersField.setValue(additionalCrewMembers.map((data) => data.type));
        const watchers = additionalCrewMembers.filter((data) => data.type === WATCHER_ID);

        this.localStore.patchState({ savedWatchers: watchers });

        if (watchers.length) {
            this.watchersFormArray.controls = [];
            this.watchersFormArray.reset();
            this.formBuilder.nonNullable.group({
                name: ["", [Validators.required, Validators.maxLength(100)]],
                phoneNumber: [undefined, [requiredValidForSmsPhoneNumberValidator]],
            });
            watchers.forEach(({ name, phoneNumber }) => this.addWatcher(name, phoneNumber));
        }
    }
}
