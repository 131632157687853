<mat-card *ngrxLet="{ isShortMode: isShortMode$, canManageUav: canManageUav$ } as vm" [class.short-mode]="vm.isShortMode">
    <dtm-web-app-lib-uav-info
        *ngIf="vm.canManageUav; else readOnlyTemplate"
        [canShareUav]="canShareUav$ | ngrxPush"
        [uav]="uavInfo$ | ngrxPush"
        [isShortMode]="vm.isShortMode"
        (remove)="remove.emit($event)"
        (share)="share.emit($event)"
        (edit)="edit.emit($event)"
    ></dtm-web-app-lib-uav-info>
    <ng-template #readOnlyTemplate>
        <dtm-web-app-lib-uav-info [uav]="uavInfo$ | ngrxPush" [isShortMode]="vm.isShortMode"></dtm-web-app-lib-uav-info>
    </ng-template>
    <ng-container *ngIf="!vm.isShortMode">
        <dtm-web-app-lib-uav-model-documents
            *ngrxLet="uav$ as uav"
            [uav]="uav"
            [isManageButtonVisible]="uav.isCustom && manageDocuments.observed && vm.canManageUav"
            (manage)="manageDocuments.emit()"
        ></dtm-web-app-lib-uav-model-documents>
    </ng-container>
</mat-card>
