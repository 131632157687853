import { HttpClient, HttpContext, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ImageConverterService } from "@dtm-frontend/shared/ui";
import { SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { OperatorInsurancePolicy } from "../../shared/models/pilot-and-operator.models";
import {
    CompanyInfo,
    NewAttorneyPower,
    Operator,
    OperatorAttorneyPowerCapabilities,
    OperatorConfirmationDocumentType,
    OperatorProfileErrorType,
} from "../models/operator.models";
import { OPERATOR_PROFILE_ENDPOINTS, OperatorProfileEndpoints } from "../operator-profile.tokens";
import {
    GetOperatorResponseBody,
    convertEditOperatorCompanyInfoErrorResponseToOperatorProfileError,
    convertGetOperatorProfileErrorResponse,
    convertGetResponseBodyToOperator,
} from "./operator-profile.converters";

@Injectable({
    providedIn: "root",
})
export class OperatorProfileApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly imageConverter: ImageConverterService,
        private readonly transloco: TranslocoService,
        @Inject(OPERATOR_PROFILE_ENDPOINTS) private readonly endpoints: OperatorProfileEndpoints
    ) {}

    public getOperatorProfile(id: string): Observable<Operator> {
        return this.httpClient.get<GetOperatorResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getOperatorProfile, { id })).pipe(
            map((response: GetOperatorResponseBody) => convertGetResponseBodyToOperator(response)),
            catchError((errorResponse: HttpErrorResponse) => throwError(() => convertGetOperatorProfileErrorResponse(errorResponse)))
        );
    }

    public editOperatorCompanyInfo(id: string, payload: CompanyInfo): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.editOperatorCompanyInfo, { id }), payload)
            .pipe(catchError((error) => throwError(() => convertEditOperatorCompanyInfoErrorResponseToOperatorProfileError(error))));
    }

    public getOperatorAvatar(operatorId: string): Observable<string> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.manageOperatorAvatar, { id: operatorId }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(switchMap((avatarFile: Blob) => this.imageConverter.convertBlobToBase64(avatarFile)));
    }

    public requestAvatarChange(userId: string, base64Image: string): Observable<void> {
        return this.httpClient
            .get(base64Image, { responseType: "blob" })
            .pipe(switchMap((response: Blob) => this.saveOperatorAvatar(userId, response)));
    }

    private saveOperatorAvatar(userId: string, blob: Blob): Observable<void> {
        const formData: FormData = new FormData();
        formData.append("file", blob);

        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.manageOperatorAvatar, { id: userId }), formData)
            .pipe(catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotSaveOperatorAvatar }))));
    }

    public editOperatorInsurancePolicy(id: string, policy: OperatorInsurancePolicy): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.manageOperatorInsurancePolicy, { id }), policy)
            .pipe(catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotSaveOperatorInsurancePolicy }))));
    }

    public deleteOperatorInsurancePolicy(id: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageOperatorInsurancePolicy, { id }))
            .pipe(catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotDeleteOperatorInsurancePolicy }))));
    }

    public deleteOperatorAvatar(id: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.manageOperatorAvatar, { id }))
            .pipe(catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotDeleteOperatorAvatar }))));
    }

    public downloadOperatorConfirmationDocument(
        operatorId: string,
        fileName: string,
        documentType: OperatorConfirmationDocumentType
    ): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadOperatorConfirmationDocument, { id: operatorId, documentType }), {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                tap((response) => saveAs(response, fileName)),
                catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotDownloadOperatorConfirmationDocument })))
            );
    }

    public getAttorneyPowerCapabilities(operatorId: string): Observable<OperatorAttorneyPowerCapabilities> {
        return this.httpClient
            .get<OperatorAttorneyPowerCapabilities>(
                StringUtils.replaceInTemplate(this.endpoints.getAttorneyPowerCapabilities, { operatorId })
            )
            .pipe(catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotGetAttorneyPowerCapabilities }))));
    }

    public downloadAttorneyTemplate() {
        return this.httpClient.get(this.endpoints.downloadAttorneyTemplate, { responseType: "blob" }).pipe(
            tap((blob: Blob) => saveAs(blob, this.transloco.translate("dtmWebAppOperatorProfile.attorneyPowers.attorneyTemplateFileName"))),
            catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotGetAttorneyPowerModelDocument })))
        );
    }

    public addAttorneyPower(operatorId: string, attorneyPower: NewAttorneyPower): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.addAttorneyPower, { operatorId }), attorneyPower)
            .pipe(catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotAddAttorneyPower }))));
    }

    public removeAttorneyPower(operatorId: string, attorneyPowerId: string): Observable<void> {
        return this.httpClient
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeAttorneyPower, { operatorId, attorneyPowerId }))
            .pipe(catchError(() => throwError(() => ({ type: OperatorProfileErrorType.CannotRemoveAttorneyPower }))));
    }
}
