<div class="container">
    <mat-dialog-content dtmUiInvalidFormScrollable>
        <h2 class="header" mat-dialog-title>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.addingRiskMitigationMeasureLabel" | transloco }}
        </h2>
        <dtm-web-app-lib-risk-mitigation-m1a
            dtmUiMarkValueAccessorControlsAsTouched
            [formControl]="riskMitigationM1AFormControl"
            [isFlightOverControlledArea]="!!data.soraSettings?.controlledGroundAreaComment"
            [populationDensity]="data.populationDensity"
            [missionUavSetup]="missionUAVSetup$ | ngrxPush"
        ></dtm-web-app-lib-risk-mitigation-m1a>
        <dtm-web-app-lib-risk-mitigation-m1b
            dtmUiMarkValueAccessorControlsAsTouched
            [formControl]="riskMitigationM1BFormControl"
            [isFlightOverControlledArea]="!!data.soraSettings?.controlledGroundAreaComment"
            *ngIf="(missionType$ | ngrxPush) === MissionType.VLOS"
        ></dtm-web-app-lib-risk-mitigation-m1b>
        <dtm-web-app-lib-risk-mitigation-m2
            dtmUiMarkValueAccessorControlsAsTouched
            [criticalArea]="data.criticalArea"
            [missionUavSetup]="data.missionUAVSetup"
            [formControl]="riskMitigationM2FormControl"
        ></dtm-web-app-lib-risk-mitigation-m2>
        <dtm-web-app-lib-air-risk-mitigation
            [isArcAirspaceRiskDecreased]="!!data.soraSettings?.isArcAirspaceRiskDecreased"
            [airRisk]="data.airRiskInfoData"
            dtmUiMarkValueAccessorControlsAsTouched
            [formControl]="airRiskMitigationFormControl"
            dtmUiSkipInvalidFormScroll
        ></dtm-web-app-lib-air-risk-mitigation>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button class="button-secondary" type="button" (click)="close()">
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.cancelButtonLabel" | transloco }}
        </button>
        <button class="button-primary" type="button" color="primary" (click)="saveAndRefresh()">
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.saveAndRecalculateButtonLabel" | transloco }}
        </button>
    </mat-dialog-actions>
</div>
