import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "dtm-web-app-lib-spec-perm-app-manual",
    templateUrl: "./manual.component.html",
    styleUrls: ["./manual.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualComponent {
    @Output() public readonly operationsManualGuideOpen = new EventEmitter<void>();
    @Output() public readonly osoGuideOpen = new EventEmitter<void>();
    @Output() public readonly operationsManualTemplateDownload = new EventEmitter<void>();
    @Output() public readonly attorneyPowerTemplateDownload = new EventEmitter<void>();
}
