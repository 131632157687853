import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

import {
    ImportedDetails,
    LegalGuardianFormValue,
    LegalOperatorFormValue,
    OperatorMigrationDetails,
    PersonalOperatorFormValue,
    PilotFormValue,
} from "../../models/operator-migration.models";

interface OperatorMigrationDetailsStepComponentState {
    importedDetails: ImportedDetails | undefined;
}

interface OperatorMigrationDetailsForm {
    legalGuardianAddress: FormControl<LegalGuardianFormValue | null>;
    legalOperator: FormControl<LegalOperatorFormValue | null>;
    personalOperator: FormControl<PersonalOperatorFormValue | null>;
    pilot: FormControl<PilotFormValue | null>;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-details-step[importedDetails]",
    templateUrl: "./operator-migration-details-step.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorMigrationDetailsStepComponent {
    @Input() public set importedDetails(value: ImportedDetails | undefined) {
        this.localStore.patchState({ importedDetails: value });

        this.prepareForm(value);
    }

    @Output() public readonly operatorMigrationDetailsSave = new EventEmitter<OperatorMigrationDetails>();
    @Output() public readonly back = new EventEmitter<OperatorMigrationDetails>();

    protected readonly importedDetails$ = this.localStore.selectByKey("importedDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly operatorMigrationForm = new FormGroup<OperatorMigrationDetailsForm>({
        legalGuardianAddress: new FormControl<LegalGuardianFormValue | null>(
            { value: null, disabled: true },
            { validators: Validators.required }
        ),
        legalOperator: new FormControl<LegalOperatorFormValue | null>({ value: null, disabled: true }, { validators: Validators.required }),
        personalOperator: new FormControl<PersonalOperatorFormValue | null>(
            { value: null, disabled: true },
            { validators: Validators.required }
        ),
        pilot: new FormControl<PilotFormValue | null>({ value: null, disabled: true }, Validators.required),
    });

    constructor(private readonly localStore: LocalComponentStore<OperatorMigrationDetailsStepComponentState>) {
        localStore.setState({ importedDetails: undefined });
    }

    protected validateFormAndSave() {
        this.operatorMigrationForm.markAllAsTouched();
        if (this.operatorMigrationForm.invalid) {
            return;
        }

        this.operatorMigrationDetailsSave.emit(this.operatorMigrationForm.getRawValue());
    }

    private prepareForm(value: ImportedDetails | undefined) {
        this.operatorMigrationForm.controls.legalOperator.disable();
        this.operatorMigrationForm.controls.legalGuardianAddress.disable();
        this.operatorMigrationForm.controls.personalOperator.disable();
        this.operatorMigrationForm.controls.pilot.disable();
        this.operatorMigrationForm.reset();

        if (!value) {
            return;
        }

        const migrationData = value.migrationData;

        if (migrationData.legalOperator && !migrationData.personalOperator && !migrationData.pilot) {
            this.operatorMigrationForm.controls.legalOperator.enable();

            return;
        }

        if (migrationData.personalOperator && !migrationData.pilot) {
            this.operatorMigrationForm.controls.personalOperator.enable();
        }

        if (migrationData.personalOperator && migrationData.pilot) {
            this.operatorMigrationForm.controls.pilot.enable();
        }

        if (migrationData.legalGuardian) {
            this.operatorMigrationForm.controls.legalGuardianAddress.enable();
        }
    }
}
