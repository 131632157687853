import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

export interface RiskMitigationLevelComponentState {
    isSelected: boolean;
    popupText: string | undefined;
    text: string | undefined;
    description: string | undefined;
    isDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-risk-mitigation-level[text]",
    templateUrl: "./risk-mitigation-level.component.html",
    styleUrls: ["./risk-mitigation-level.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RiskMitigationLevelComponent {
    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });
    }
    @Input() public set popupText(value: string | undefined) {
        this.localStore.patchState({ popupText: value });
    }
    @Input() public set text(value: string | undefined) {
        this.localStore.patchState({ text: value });
    }
    @Input() public set description(value: string | undefined) {
        this.localStore.patchState({ description: value });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }
    @Output() public readonly riskMitigationLevelSelect = new EventEmitter<void>();

    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");
    protected readonly popupText$ = this.localStore.selectByKey("popupText");
    protected readonly text$ = this.localStore.selectByKey("text");
    protected readonly description$ = this.localStore.selectByKey("description");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    constructor(private readonly localStore: LocalComponentStore<RiskMitigationLevelComponentState>) {
        this.localStore.setState({
            isSelected: false,
            popupText: undefined,
            text: undefined,
            description: undefined,
            isDisabled: false,
        });
    }
}
