import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { OperatorAndPilotQualification } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface PilotQualificationsComponentState {
    qualifications: OperatorAndPilotQualification[];
}
const CIVIL_AVIATION_AUTHORITY_GENERAL_INFO_URL = "https://ulc.gov.pl/pl/drony/informacje-ogolne";

@Component({
    selector: "dtm-web-app-lib-pilot-profile-pilot-qualifications[qualifications]",
    templateUrl: "./pilot-qualifications.component.html",
    styleUrls: ["./pilot-qualifications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotQualificationsComponent {
    @Input() public set qualifications(value: OperatorAndPilotQualification[] | undefined) {
        this.localStore.patchState({ qualifications: value ?? [] });
    }
    @Output() public readonly pilotQualificationsShow = new EventEmitter<void>();
    @Output() public readonly getQualificationsRedirect = new EventEmitter<void>();
    protected readonly qualifications$ = this.localStore.selectByKey("qualifications");
    protected readonly CIVIL_AVIATION_AUTHORITY_GENERAL_INFO_URL = CIVIL_AVIATION_AUTHORITY_GENERAL_INFO_URL;

    constructor(private readonly localStore: LocalComponentStore<PilotQualificationsComponentState>) {
        this.localStore.setState({
            qualifications: [],
        });
    }
}
