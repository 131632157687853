import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { SavedStatement, StatementStatus } from "../../../../services/operator-permissions.models";

interface PaymentComponentState {
    savedStatement: SavedStatement | undefined;
    isPaymentFeatureAvailable: boolean;
}

@Component({
    selector: "dtm-web-app-lib-operator-statement-signature[savedStatement]",
    templateUrl: "./statement-signature.component.html",
    styleUrls: ["./statement-signature.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatementSignatureComponent {
    @Input() public set savedStatement(value: SavedStatement | undefined) {
        this.localStore.patchState({ savedStatement: value });
    }

    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }

    @Output() public readonly refresh = new EventEmitter<void>();
    @Output() public readonly paymentRepeat = new EventEmitter<void>();
    @Output() public readonly statementSignAndSend = new EventEmitter<void>();

    protected readonly savedStatement$ = this.localStore.selectByKey("savedStatement").pipe(RxjsUtils.filterFalsy());
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");
    protected readonly StatementStatus = StatementStatus;

    constructor(
        private readonly localStore: LocalComponentStore<PaymentComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        localStore.setState({ savedStatement: undefined, isPaymentFeatureAvailable: false });
    }

    protected goToSignAndSend() {
        const isPaymentCompleted = this.localStore.selectSnapshotByKey("savedStatement")?.payment.status === StatementStatus.Completed;
        const isPaymentFeatureAvailable = this.localStore.selectSnapshotByKey("isPaymentFeatureAvailable");
        if (!isPaymentCompleted && isPaymentFeatureAvailable) {
            this.toastrService.error(
                this.translocoService.translate(
                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.uncompletedPaymentError"
                )
            );

            return;
        }

        this.statementSignAndSend.emit();
    }
}
