import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DateUtils, ISO8601TimeDuration, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Observable, map } from "rxjs";
import { ViewItinerarySegmentEntity } from "../../itinerary-panel.models";
import { DEFAULT_TRACK_DETAILS_DISPLAY_MODE, TrackDetailsDisplayMode } from "../track-details/track-details-display-mode";
import { FlightSpeedType, HeightType, MissionPlanItineraryConstraints } from "./../../../../../../../models/mission.model";

interface SegmentViewEntityCardComponentState {
    entity: ViewItinerarySegmentEntity | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    flightSpeedType: FlightSpeedType;
    displayMode: TrackDetailsDisplayMode;
}

@Component({
    selector: "dtm-web-app-lib-mwiesip-segment-view-entity-card[entity][constraints][displayMode]",
    templateUrl: "./segment-view-entity-card.component.html",
    styleUrls: ["./segment-view-entity-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SegmentViewEntityCardComponent {
    protected readonly FlightSpeedType = FlightSpeedType;
    protected readonly HeightType = HeightType;

    @Input() public set entity(value: ViewItinerarySegmentEntity) {
        this.localStore.patchState({ entity: value });
    }

    @Input() public set constraints(value: MissionPlanItineraryConstraints) {
        this.localStore.patchState({ constraints: value });
    }

    @Input() public set flightSpeedType(value: FlightSpeedType) {
        this.localStore.patchState({ flightSpeedType: value });
    }

    @Input() public set displayMode(value: TrackDetailsDisplayMode) {
        this.localStore.patchState({ displayMode: value });
    }

    @Output() public readonly bufferWidthChange = new EventEmitter<number>();
    @Output() public readonly bufferHeightChange = new EventEmitter<number>();
    @Output() public readonly addInternalCylinder = new EventEmitter<void>();
    @Output() public readonly addInternalPrism = new EventEmitter<void>();

    protected readonly entity$ = this.localStore.selectByKey("entity");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly flightSpeedType$ = this.localStore.selectByKey("flightSpeedType");
    protected readonly displayMode$ = this.localStore.selectByKey("displayMode");

    protected readonly heightLimits$: Observable<{ min: number; max: number } | undefined> = this.entity$.pipe(
        RxjsUtils.filterFalsy(),
        map((entity) => {
            if (!entity.inletPointHeightAmsl || !entity.outletPointHeightAmsl) {
                return undefined;
            }

            return {
                min: Math.min(entity.inletPointHeightAmsl, entity.outletPointHeightAmsl) - entity.verticalBuffer / 2,
                max: Math.max(entity.inletPointHeightAmsl, entity.outletPointHeightAmsl) + entity.verticalBuffer / 2,
            };
        })
    );

    constructor(private readonly localStore: LocalComponentStore<SegmentViewEntityCardComponentState>) {
        localStore.setState({
            entity: undefined,
            constraints: undefined,
            flightSpeedType: FlightSpeedType.KilometersPerHour,
            displayMode: DEFAULT_TRACK_DETAILS_DISPLAY_MODE,
        });
    }

    protected getSegmentDurationMinutes(entity: ViewItinerarySegmentEntity): ISO8601TimeDuration | undefined {
        if (!entity.distance || !entity.speed) {
            return undefined;
        }

        const durationSeconds = entity.distance / entity.speed;

        return DateUtils.convertSecondsToISO8601Duration(durationSeconds);
    }

    protected getVerticalBufferMaxValue(constraints: MissionPlanItineraryConstraints) {
        return Math.min(constraints.max.verticalNavigationAccuracy * 2, Math.round(constraints.max.height - constraints.min.height));
    }
}
