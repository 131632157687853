import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MissionType, TacticalMitigationPerformanceRequirementProperty } from "@dtm-frontend/shared/mission";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
    TacticalMitigationPerformanceRequirements,
    TacticalRiskMitigationMeasuresCategory,
} from "../../../../../../../models/mission.model";

type TacticalRiskMitigationMeasuresSectionGroup = FormGroup<{
    isExpanded: FormControl<boolean>;
    isLongDescriptionVisible: FormControl<boolean>;
    riskMitigationTextarea: FormControl<string>;
}>;

export interface TacticalRiskMitigationDialogData {
    tacticalMitigationPerformanceRequirementProperty: TacticalMitigationPerformanceRequirementProperty;
    tacticalMitigationPerformanceRequirements: TacticalMitigationPerformanceRequirements | undefined;
}

export interface TacticalRiskMitigationMeasuresForm {
    [TacticalRiskMitigationMeasuresCategory.DETECT]: TacticalRiskMitigationMeasuresSectionGroup;
    [TacticalRiskMitigationMeasuresCategory.DECIDE]: TacticalRiskMitigationMeasuresSectionGroup;
    [TacticalRiskMitigationMeasuresCategory.COMMAND]: TacticalRiskMitigationMeasuresSectionGroup;
    [TacticalRiskMitigationMeasuresCategory.EXECUTE]: TacticalRiskMitigationMeasuresSectionGroup;
    [TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP]: TacticalRiskMitigationMeasuresSectionGroup;
}

interface TacticalRiskMitigationModalComponentState {
    tacticalMitigationPerformanceRequirementProperty: TacticalMitigationPerformanceRequirementProperty;
}

const MAX_TEXT_LENGTH = 1000;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-tactical-risk-mitigation-modal",
    templateUrl: "./tactical-risk-mitigation-modal.component.html",
    styleUrls: ["./tactical-risk-mitigation-modal.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TacticalRiskMitigationModalComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    protected tacticalRiskMitigationMeasuresFormGroup: FormGroup<TacticalRiskMitigationMeasuresForm> = this.buildForm();

    protected readonly tacticalMitigationPerformanceRequirementProperty$ = this.localStore.selectByKey(
        "tacticalMitigationPerformanceRequirementProperty"
    );

    protected readonly MissionType = MissionType;
    protected readonly TacticalRiskMitigationMeasuresCategory = TacticalRiskMitigationMeasuresCategory;
    protected readonly TacticalMitigationPerformanceRequirement = TacticalMitigationPerformanceRequirementProperty;
    protected readonly MAX_TEXT_LENGTH = MAX_TEXT_LENGTH;

    protected readonly categoryKeys = [
        TacticalRiskMitigationMeasuresCategory.DETECT,
        TacticalRiskMitigationMeasuresCategory.DECIDE,
        TacticalRiskMitigationMeasuresCategory.COMMAND,
        TacticalRiskMitigationMeasuresCategory.EXECUTE,
        TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP,
    ];

    constructor(
        public dialogRef: MatDialogRef<TacticalRiskMitigationModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: TacticalRiskMitigationDialogData,
        private readonly localStore: LocalComponentStore<TacticalRiskMitigationModalComponentState>
    ) {
        this.localStore.setState({
            tacticalMitigationPerformanceRequirementProperty: data.tacticalMitigationPerformanceRequirementProperty,
        });

        if (!data.tacticalMitigationPerformanceRequirements) {
            return;
        }

        Object.entries(data.tacticalMitigationPerformanceRequirements).forEach(([key, { comment }]: [string, { comment: string }]) => {
            const controls = this.tacticalRiskMitigationMeasuresFormGroup.controls[key as TacticalRiskMitigationMeasuresCategory].controls;

            if (comment) {
                controls.riskMitigationTextarea.setValue(comment);
                controls.isExpanded.setValue(true);
            }
        });
    }

    protected saveAndRefresh() {
        this.tacticalRiskMitigationMeasuresFormGroup.markAllAsTouched();

        Object.entries(this.tacticalRiskMitigationMeasuresFormGroup.value).forEach(([key, data]) => {
            if (!data.isExpanded && !data.riskMitigationTextarea) {
                this.tacticalRiskMitigationMeasuresFormGroup.controls[
                    key as TacticalRiskMitigationMeasuresCategory
                ].controls.isExpanded.setValue(true);
            }
        });
        this.invalidFormScrollable?.scrollToFirstInvalidField();

        if (!this.tacticalRiskMitigationMeasuresFormGroup.valid) {
            return;
        }

        const tacticalMitigationPerformanceRequirements: TacticalMitigationPerformanceRequirements = {
            [TacticalRiskMitigationMeasuresCategory.DETECT]: { comment: "" },
            [TacticalRiskMitigationMeasuresCategory.DECIDE]: { comment: "" },
            [TacticalRiskMitigationMeasuresCategory.COMMAND]: { comment: "" },
            [TacticalRiskMitigationMeasuresCategory.EXECUTE]: { comment: "" },
            [TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP]: { comment: "" },
        };

        Object.entries(this.tacticalRiskMitigationMeasuresFormGroup.value).forEach(
            ([key, { riskMitigationTextarea }]: [string, Partial<{ riskMitigationTextarea: string }>]) =>
                (tacticalMitigationPerformanceRequirements[key as TacticalRiskMitigationMeasuresCategory] = {
                    comment: riskMitigationTextarea as string,
                })
        );

        this.dialogRef.close(tacticalMitigationPerformanceRequirements);
    }

    protected close() {
        this.dialogRef.close();
    }

    private buildForm(): FormGroup<TacticalRiskMitigationMeasuresForm> {
        return new FormGroup<TacticalRiskMitigationMeasuresForm>({
            [TacticalRiskMitigationMeasuresCategory.DETECT]: this.buildSection(),
            [TacticalRiskMitigationMeasuresCategory.DECIDE]: this.buildSection(),
            [TacticalRiskMitigationMeasuresCategory.COMMAND]: this.buildSection(),
            [TacticalRiskMitigationMeasuresCategory.EXECUTE]: this.buildSection(),
            [TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP]: this.buildSection(),
        });
    }

    private buildSection(): TacticalRiskMitigationMeasuresSectionGroup {
        return new FormGroup({
            riskMitigationTextarea: new FormControl("", {
                validators: [
                    Validators.maxLength(MAX_TEXT_LENGTH),
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                    Validators.required,
                ],
                nonNullable: true,
            }),
            isLongDescriptionVisible: new FormControl(false, { nonNullable: true }),
            isExpanded: new FormControl(false, { nonNullable: true }),
        });
    }
}
