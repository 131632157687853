<dtm-ui-expandable-panel *ngrxLet="operation$ as operation" [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <dtm-ui-expandable-simple-header
        headerSlot
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.zoneGeometryHeaderLabel' | transloco"
    >
    </dtm-ui-expandable-simple-header>
    <h2 class="area-header">
        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.groundAreaCharacteristicHeaderLabel" | transloco }}
    </h2>
    <div class="properties">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.flightAreaLengthLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.flightAreaLengthValue'
                    | transloco : { value: operation.flightArea.estimatedDistance | localizeNumber : { maximumFractionDigits: 2 } }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.flightAreaWidthLabel' | transloco"
            [value]="
                operation.flightArea.maxWidth
                    ? ('dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.flightAreaWidthValue'
                      | transloco : { value: operation.flightArea.maxWidth | localizeNumber : { maximumFractionDigits: 2 } })
                    : '-'
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.flightAreaMaxHeightLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.flightAreaMaxHeightValue'
                    | transloco : { value: operation.flightArea.maxHeight | localizeNumber : { maximumFractionDigits: 2 } }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.safetyAreaWidthLabel' | transloco"
            [value]="
                operation.flightArea.safetyAreaWidth
                    ? ('dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.safetyAreaWidthValue'
                      | transloco : { value: operation.flightArea.safetyAreaWidth | localizeNumber : { maximumFractionDigits: 2 } })
                    : '-'
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.safetyAreaMaxHeightLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.safetyAreaMaxHeightValue'
                    | transloco : { value: operation.flightArea.safetyAreaHeight | localizeNumber : { maximumFractionDigits: 2 } }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.groundRiskBufferLabel' | transloco"
            [value]="
                operation.flightArea.groundRiskBuffer
                    ? ('dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.groundRiskBufferValue'
                      | transloco : { value: operation.flightArea.groundRiskBuffer | localizeNumber : { maximumFractionDigits: 2 } })
                    : '-'
            "
        ></dtm-ui-label-value>
    </div>
    <h2 class="area-header">
        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.adjacentGroundAreaHeaderLabel" | transloco }}
    </h2>
    <div class="properties">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.adjacentGroundAreaWidthLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.adjacentGroundAreaWidthValue'
                    | transloco : { value: operation.adjacentArea.bufferHorizontal | localizeNumber : { maximumFractionDigits: 2 } }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.adjacentGroundAreaHeightLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.zoneGeometryInfo.adjacentGroundAreaHeightValue'
                    | transloco : { value: operation.adjacentArea.bufferVertical | localizeNumber : { maximumFractionDigits: 2 } }
            "
        ></dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
