import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { AttorneyPowerStatus, AttorneyPowerType, UserProfileError, UsersAttorneyPower } from "../../models/user-profile.models";
import { UserProfileApiService } from "../../services/user-attorney-powers-api.service";

interface UserAttorneyPowersComponentState {
    attorneyPowers: UsersAttorneyPower[];
    attorneyPowersError: UserProfileError | undefined;
}

@Component({
    selector: "dtm-web-app-lib-user-attorney-powers[attorneyPowers]",
    templateUrl: "./user-attorney-powers.component.html",
    styleUrls: ["./user-attorney-powers.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: UserProfileApiService }],
})
export class UserAttorneyPowersComponent {
    @Input() public set attorneyPowers(value: UsersAttorneyPower[] | undefined) {
        this.localStore.patchState({ attorneyPowers: value ?? [] });
    }

    protected readonly AttorneyPowerStatus = AttorneyPowerStatus;
    protected readonly AttorneyPowerType = AttorneyPowerType;
    public readonly slides$ = this.localStore
        .selectByKey("attorneyPowers")
        .pipe(map((slides) => ArrayUtils.groupByCount(slides, 1).map((cards) => ({ cards }))));

    constructor(private readonly localStore: LocalComponentStore<UserAttorneyPowersComponentState>) {
        localStore.setState({ attorneyPowers: [], attorneyPowersError: undefined });
    }
}
