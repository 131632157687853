<ng-container *ngrxLet="foreignOperatorValidation$ as foreignOperatorValidation">
    <dtm-ui-input-field [class.invalid-operator-error]="foreignOperatorValidation && !foreignOperatorValidation.isValid">
        <label>
            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.operatorNumberLabel" | transloco }}
        </label>
        <input matInput [formControl]="foreignOperatorNumberControl" [mask]="FOREIGN_OPERATOR_NUMBER_MASK" [showMaskTyped]="true" />
        <div class="field-error" *dtmUiFieldHasError="foreignOperatorNumberControl; name: 'required'">
            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="foreignOperatorNumberControl; name: 'minlength'; error as error">
            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.operatorNumberTooShortError" | transloco : { min: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.verificationCodeLabel" | transloco }}
        </label>
        <input matInput [formControl]="secretCodeControl" placeholder="***" type="password" />
        <div class="field-hint">
            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.verificationCodeHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="secretCodeControl; name: 'required'">
            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="secretCodeControl; name: 'minlength'; error as error">
            {{
                "dtmWebAppLibPilotOperatorRegistration.basicData.verificationCodeTooShortError" | transloco : { min: error.requiredLength }
            }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="secretCodeControl; name: 'maxlength'; error as error">
            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.verificationCodeTooLongError" | transloco : { max: error.requiredLength } }}
        </div>
        <div class="field-error" *ngIf="foreignOperatorValidation && !foreignOperatorValidation.isValid">
            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.foreignOperatorInvalidError" | transloco }}
        </div>
    </dtm-ui-input-field>
</ng-container>
