<section *ngrxLet="isEditMode$ as isEditMode" [className]="isEditMode ? 'edit-mode-container' : 'view-mode-container'">
    <ng-container *ngrxLet="uavImageUrl$ as uavImageUrl">
        <ng-container *ngrxLet="isDisabled$ as isDisabled">
            <ng-container *ngIf="!isEditMode; else editModeTemplate">
                <div class="image-wrapper">
                    <dtm-ui-loader-container
                        class="uav-image"
                        *ngrxLet="isUavImageUrlProcessing$ as isUavImageUrlProcessing"
                        [isShown]="isUavImageUrlProcessing"
                    >
                        <img
                            *ngIf="uavImageUrl"
                            [dtmUiImgSecuredSrc]="uavImageUrl"
                            (loadingChange)="setUavImageUrlProcessing($event)"
                            alt=""
                        />
                        <img *ngIf="!uavImageUrl" src="assets/images/uav-no-photo.svg" alt="" />
                    </dtm-ui-loader-container>
                </div>
                <button type="button" class="button-secondary" (click)="setEditMode(true)" [disabled]="isDisabled">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmWebAppLibUav.uavImageControl.editButtonLabel" | transloco }}
                </button>
            </ng-container>
            <ng-template #editModeTemplate>
                <div class="header">
                    <span class="label">
                        {{ "dtmWebAppLibUav.uavImageControl.editImageLabel" | transloco }}
                    </span>
                    <button type="button" class="button-icon" (click)="tryLeaveEditMode()" [disabled]="isDisabled">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                </div>
                <dtm-ui-image-picker
                    [isDisabled]="isImagePickerDisabled$ | ngrxPush"
                    [maxFileSize]="MAX_UPLOADED_FILE_SIZE_BYTES"
                    [initialPreviewImageUrl]="uavImageUrl"
                    [processingProgress]="uploadProgress$ | ngrxPush"
                    (file)="setFile($event)"
                >
                    <span class="field-hint">
                        {{
                            "dtmWebAppLibUav.uavImageControl.controlHint"
                                | transloco : { maxFileSize: MAX_UPLOADED_FILE_SIZE_BYTES | formatFileSize }
                        }}
                    </span>
                    <div class="field-error" *ngIf="uploadError$ | ngrxPush as uploadError">
                        {{ uploadError }}
                    </div>
                </dtm-ui-image-picker>
                <div class="buttons">
                    <button type="button" class="button-primary" (click)="saveImage()" [disabled]="isDisabled">
                        {{ "dtmWebAppLibUav.uavImageControl.saveButtonLabel" | transloco }}
                    </button>
                    <button type="button" class="button-secondary" (click)="tryLeaveEditMode()" [disabled]="isDisabled">
                        {{ "dtmWebAppLibUav.uavImageControl.cancelButtonLabel" | transloco }}
                    </button>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</section>
