import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { PublicProfileType } from "../../services/public-profile.models";

interface PublicProfileNotFoundComponentState {
    type: PublicProfileType | undefined;
}

@Component({
    selector: "dtm-web-app-lib-public-profile-not-found[type]",
    templateUrl: "./public-profile-not-found.component.html",
    styleUrls: ["./public-profile-not-found.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PublicProfileNotFoundComponent {
    @Input() public set type(value: PublicProfileType | undefined) {
        this.localStore.patchState({ type: value });
    }

    public readonly type$ = this.localStore.selectByKey("type");

    constructor(private readonly localStore: LocalComponentStore<PublicProfileNotFoundComponentState>) {
        this.localStore.setState({
            type: undefined,
        });
    }
}
