import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { OperatorType } from "@dtm-frontend/shared/ui";
import { Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { Observable, map } from "rxjs";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";

@Injectable()
export class CanEnterOperatorProfileGuard {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.store.select(OperatorContextState.selectedContext).pipe(
            RxjsUtils.filterFalsy(),
            map((selectedContext) => {
                if (selectedContext?.type !== OperatorType.Enterprise) {
                    Logger.captureMessage("Operator profile is not available for this context");

                    return this.router.createUrlTree(["/pilot-profile"]);
                }

                return true;
            })
        );
    }
}
