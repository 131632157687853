import { InjectionToken } from "@angular/core";

export interface UavEndpoints {
    getCapabilities: string;
    getManufacturers: string;
    getModel: string;
    getModelPhoto: string;
    getTrackers: string;
    getShareCapabilities: string;
    shareSetups: string;
    uavsManagement: string;
    customUavsManagement: string;
    uavManagement: string;
    customUavManagement: string;
    customUavPhotoUpload: string;
    uavSetupsManagement: string;
    uavSetupManagement: string;
    customUavSetupManagement: string;
    uavDocumentUpload: string;
    uavDocumentDownload: string;
    attachUavDocuments: string;
    setupDocumentUpload: string;
    setupDocumentDownload: string;
}
export const UAV_ENDPOINTS = new InjectionToken<UavEndpoints>("UAV endpoints");
