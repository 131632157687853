<h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.summaryStep.legalGuardian.header" | transloco }}</h2>
<div
    class="details-container"
    *ngrxLet="{ legalGuardianDetails: legalGuardianDetails$, legalGuardianAddress: legalGuardianAddress$ } as vm"
>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibOperatorMigration.summaryStep.legalGuardian.nameLabel' | transloco"
        [value]="vm.legalGuardianDetails.name + ' ' + vm.legalGuardianDetails.lastName"
    ></dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibOperatorMigration.summaryStep.legalGuardian.emailLabel' | transloco"
        [value]="vm.legalGuardianDetails.email"
    ></dtm-ui-label-value>
    <dtm-ui-address-label-value [address]="vm.legalGuardianAddress"></dtm-ui-address-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibOperatorMigration.summaryStep.legalGuardian.phoneNumberLabel' | transloco"
        [value]="vm.legalGuardianDetails.phone | formatImportedPhoneNumber"
    ></dtm-ui-label-value>
</div>
