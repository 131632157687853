<dtm-ui-loader-container
    *ngrxLet="{
        isCapabilitiesProcessing: isCapabilitiesProcessing$,
        isOperatorPermittedToRead: isOperatorPermittedToRead$,
        isOperatorPermittedToManage: isOperatorPermittedToManage$
    } as vm"
    [isShown]="vm.isCapabilitiesProcessing"
>
    <div class="grid" *ngIf="vm.isOperatorPermittedToManage || vm.isOperatorPermittedToRead; else notAuthorizedTemplate">
        <div class="invitation-buttons" *ngIf="vm.isOperatorPermittedToManage">
            <button class="button-secondary" type="button" (click)="openInvitationDialog()">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppMembership.inviteUserButtonLabel" | transloco }}
            </button>
        </div>
        <dtm-web-app-lib-invited-pilots-filters
            *ngIf="!(capabilitiesError$ | ngrxPush); else capabilitiesErrorTemplate"
            [initialFilters]="initialFilters$ | ngrxPush"
            [capabilities]="capabilities$ | ngrxPush"
            (filtersChange)="changeFilters($event)"
        ></dtm-web-app-lib-invited-pilots-filters>
        <dtm-web-app-lib-invited-users-list
            [invitedUsers]="invitedUsers$ | ngrxPush"
            [isOperatorPermittedToManage]="vm.isOperatorPermittedToManage"
            (memberDeactivation)="deactivateMember($event)"
            (invitationRemove)="removeInvitation($event)"
            (invitationResend)="resendInvitation($event)"
            (invitationToMemberResend)="resendInvitationToMember($event)"
            (memberRemove)="removeMember($event)"
            (manageUserPermissions)="openPermissionsDialog($event)"
            (pageChange)="changePage($event)"
            [pagination]="pagination$ | ngrxPush"
            [isMemberProcessing]="isMemberProcessing$ | ngrxPush"
        ></dtm-web-app-lib-invited-users-list>

        <ng-template #capabilitiesErrorTemplate>
            <dtm-ui-error (reload)="refreshCapabilities()"></dtm-ui-error>
        </ng-template>
    </div>
</dtm-ui-loader-container>

<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
