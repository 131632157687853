import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AirspaceElement, AirspaceElementsInfo, GeoZoneType, GeoZonesUtils, TextWithTooltip } from "@dtm-frontend/shared/map/geo-zones";
import { IconName } from "@dtm-frontend/shared/ui";
import { DEFAULT_LANG, I18nService, LOCALE_MAPPING } from "@dtm-frontend/shared/ui/i18n";
import { ArrayUtils, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import {
    AuthorityAcceptation,
    AuthorityAcceptationItem,
    AuthorityAcceptationStatus,
    FlightRules,
    FormalJustification,
    MissionContextType,
    MissionPhaseExtensions,
    MissionPlanAirspaceAnalysisOption,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanAnalysisIssue,
    MissionPlanAnalysisStatus,
    MissionPlanEvaluationOption,
    MissionProcessingPhase,
    MissionProcessingPhaseExtended,
    PartialAuthorityAcceptation,
    Requirement,
} from "../../models";

interface FlightRequirementsComponentState {
    activeZonesMain: AirspaceElement[];
    activeZonesRest: AirspaceElement[];
    waitingZones: AirspaceElement[];
    additionalZones: AirspaceElement[];
    selectedZoneId: string | undefined;
    issues: Record<string, MissionPlanAirspaceAnalysisOption> | undefined;
    doesAupExist: boolean;
    authorityAcceptation: AuthorityAcceptation | undefined;
    partialAuthorityAcceptation: PartialAuthorityAcceptation[];
    isProcessing: boolean;
    currentPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    formalJustification: FormalJustification | undefined;
    isExpanded: boolean;
    areRulesExpanded: boolean;
    areMessagesVisible: boolean;
    missionContext: MissionContextType | undefined;
    shouldDisplayMessageConfirmation: boolean;
    missionPhase: MissionProcessingPhaseExtended | undefined;
    areZonesEmpty: boolean;
    airacEndTime: Date | undefined;
    rejectionJustification: string | undefined;
    rejectionDate: Date | undefined;
    isUtmSupervisorIntegrated: boolean;
}

const ADDITIONAL_ZONES_TYPES: GeoZoneType[] = [GeoZoneType.DroneAirspaceInformation];
const ISSUE_CODENAME_IGNORE_LIST = ["analysis.airspace.element.issue.fa.message"];

const DYNAMIC_SAFETY_AND_SECURITY_SUPERVISOR_NAME = "DSS";

const ACTIVE_ZONES_TYPES = ["RPA", "CTR1KM", "CTR"];

const CONSENT_LABELS = {
    dtm: "dtmSharedMission.flightRequirements.pansaConsentLabel",
    utm: "dtmSharedMission.flightRequirements.pansaConsentLabel",
    dss: "dtmSharedMission.flightRequirements.pansaDssConsentLabel",
    other: "dtmSharedMission.flightRequirements.otherConsentLabel",
};

// TODO: DTM-4577 this should be filtered based on something provided by backend
const RULES_TO_REMOVE_REGEXP = /^(Zgoda od PAŻP|Consent from PANSA)/;

@Component({
    selector: "dtm-mission-flight-requirements[missionContext]",
    templateUrl: "./flight-requirements.component.html",
    styleUrls: ["./flight-requirements.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightRequirementsComponent {
    @Input() public set zones(value: AirspaceElementsInfo | undefined) {
        const [mainZones, additionalZones] = ArrayUtils.partition(
            GeoZonesUtils.sortZones(value?.airspaceElements ?? []),
            (zone) => !ADDITIONAL_ZONES_TYPES.includes(zone.geoZoneType)
        );
        const [activeZones, waitingZones] = ArrayUtils.partition(mainZones, (zone) => zone.isActive);
        const [activeZonesMain, activeZonesRest] = ArrayUtils.partition(
            activeZones,
            (zone) => ACTIVE_ZONES_TYPES.includes(zone.type) || zone.geoZoneType === GeoZoneType.DroneTrafficManagement
        );

        this.localStore.patchState({
            additionalZones,
            activeZonesMain,
            activeZonesRest,
            waitingZones,
            doesAupExist: !!value?.doesAupExist,
            areZonesEmpty: !value?.airspaceElements.length,
            airacEndTime: value?.airacEndTime,
        });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set authorityAcceptation(value: AuthorityAcceptation | undefined) {
        this.localStore.patchState({ authorityAcceptation: value });

        if (value?.dtm?.isConfirmedByUser || value?.utm?.isConfirmedByUser) {
            this.isMessageFromManagerReadingConfirmedFormControl.setValue(true, { emitEvent: false });
            this.isMessageFromManagerReadingConfirmedFormControl.disable({ emitEvent: false });
        }
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set currentPlanAnalysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ currentPlanAnalysisStatus: value });
    }
    @Input() public set formalJustification(value: FormalJustification | undefined) {
        this.localStore.patchState({ formalJustification: value });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set areMessagesVisible(value: BooleanInput) {
        this.localStore.patchState({ areMessagesVisible: coerceBooleanProperty(value) });
    }
    @Input() public set missionContext(value: MissionContextType | undefined) {
        this.localStore.patchState({ missionContext: value });
    }
    @Input() public set shouldDisplayMessageConfirmation(value: BooleanInput) {
        this.localStore.patchState({ shouldDisplayMessageConfirmation: coerceBooleanProperty(value) });
    }
    @Input() public set missionPhase(value: MissionProcessingPhaseExtended | undefined) {
        this.localStore.patchState({ missionPhase: value });
    }
    @Input() public set rejectionJustification(value: string | undefined) {
        this.localStore.patchState({ rejectionJustification: value });
    }
    @Input() public set rejectionDate(value: Date | undefined) {
        this.localStore.patchState({ rejectionDate: value });
    }
    @Input() public set partialAuthorityAcceptation(value: PartialAuthorityAcceptation[] | undefined) {
        this.localStore.patchState({ partialAuthorityAcceptation: value ?? [] });
    }
    @Input() public set isUtmSupervisorIntegrated(value: BooleanInput) {
        this.localStore.patchState({ isUtmSupervisorIntegrated: coerceBooleanProperty(value) });
    }

    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly areMessagesVisible$ = this.localStore.selectByKey("areMessagesVisible");
    protected readonly waitingZones$ = this.localStore.selectByKey("waitingZones");
    protected readonly additionalZones$ = this.localStore.selectByKey("additionalZones");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly doesAupExist$ = this.localStore.selectByKey("doesAupExist");
    protected readonly activeZonesMain$ = this.localStore.selectByKey("activeZonesMain");
    protected readonly activeZonesRest$ = this.localStore.selectByKey("activeZonesRest");
    protected readonly authorityAcceptation$ = this.localStore.selectByKey("authorityAcceptation");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly currentPlanAnalysisStatus$ = this.localStore.selectByKey("currentPlanAnalysisStatus");
    protected readonly formalJustification$ = this.localStore.selectByKey("formalJustification");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly areRulesExpanded$ = this.localStore.selectByKey("areRulesExpanded");
    protected readonly missionContext$ = this.localStore.selectByKey("missionContext");
    protected readonly shouldDisplayMessageConfirmation$ = this.localStore.selectByKey("shouldDisplayMessageConfirmation");
    protected readonly missionPhase$ = this.localStore.selectByKey("missionPhase");
    protected readonly isEmpty$ = combineLatest([
        this.waitingZones$,
        this.activeZonesMain$,
        this.activeZonesRest$,
        this.additionalZones$,
        this.isProcessing$,
        this.currentPlanAnalysisStatus$,
        this.missionPhase$,
    ]).pipe(
        map(
            ([waiting, activeRpa, activeRest, additional, isProcessing, plan, phase]) =>
                !isProcessing &&
                waiting.length +
                    activeRpa.length +
                    activeRest.length +
                    additional.length +
                    ((phase !== MissionProcessingPhase.Waiting && plan?.flightRules.length) || 0) ===
                    0 &&
                !plan?.evaluation.issues.length
        )
    );
    protected readonly activeLanguageCode$ = this.i18nService.currentLanguage$.pipe(
        map((language) => LOCALE_MAPPING[language] ?? LOCALE_MAPPING[DEFAULT_LANG])
    );
    protected readonly areZonesEmpty$ = this.localStore.selectByKey("areZonesEmpty");
    protected readonly airacEndTime$ = this.localStore.selectByKey("airacEndTime");
    protected readonly rejectionJustification$ = this.localStore.selectByKey("rejectionJustification");
    protected readonly rejectionDate$ = this.localStore.selectByKey("rejectionDate");
    protected readonly partialAuthorityAcceptation$ = this.localStore.selectByKey("partialAuthorityAcceptation");
    protected readonly isUtmSupervisorIntegrated$ = this.localStore.selectByKey("isUtmSupervisorIntegrated");

    protected readonly isMessageFromManagerReadingConfirmedFormControl = new FormControl();

    @Output() public readonly messageFromManagerReadingConfirmed = this.isMessageFromManagerReadingConfirmedFormControl.valueChanges.pipe(
        RxjsUtils.filterFalsy()
    );

    protected readonly DYNAMIC_SAFETY_AND_SECURITY_SUPERVISOR_NAME = DYNAMIC_SAFETY_AND_SECURITY_SUPERVISOR_NAME;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;

    constructor(
        protected readonly localStore: LocalComponentStore<FlightRequirementsComponentState>,
        private readonly transloco: TranslocoService,
        private readonly i18nService: I18nService
    ) {
        localStore.setState({
            activeZonesMain: [],
            activeZonesRest: [],
            waitingZones: [],
            additionalZones: [],
            selectedZoneId: undefined,
            issues: undefined,
            doesAupExist: false,
            authorityAcceptation: undefined,
            isProcessing: false,
            currentPlanAnalysisStatus: undefined,
            formalJustification: undefined,
            isExpanded: false,
            areRulesExpanded: false,
            areMessagesVisible: true,
            missionContext: undefined,
            shouldDisplayMessageConfirmation: true,
            missionPhase: undefined,
            areZonesEmpty: false,
            airacEndTime: undefined,
            rejectionJustification: undefined,
            rejectionDate: undefined,
            partialAuthorityAcceptation: [],
            isUtmSupervisorIntegrated: false,
        });
    }

    protected getAirspaceWarningsForZone(
        zone: AirspaceElement,
        airspaceAnalysis?: MissionPlanAirspaceAnalysisOption
    ): MissionPlanAnalysisIssue[] {
        return zone.designator && airspaceAnalysis?.zoneIssues
            ? airspaceAnalysis.zoneIssues[zone.designator]?.filter(({ codename }) => !ISSUE_CODENAME_IGNORE_LIST.includes(codename)) ?? []
            : [];
    }

    protected getEvaluationIssuesForZone(
        zone: AirspaceElement,
        evaluationIssues?: MissionPlanEvaluationOption
    ): MissionPlanAnalysisEvaluationIssue[] {
        return zone.designator && evaluationIssues?.issues ? evaluationIssues.zoneIssues[zone.designator] ?? [] : [];
    }

    protected getAirspaceWarningsForZones = (
        zones: AirspaceElement[],
        airspaceAnalysis?: MissionPlanAirspaceAnalysisOption
    ): MissionPlanAnalysisIssue[] =>
        zones.reduce<MissionPlanAnalysisIssue[]>(
            (issues, zone) => [...issues, ...this.getAirspaceWarningsForZone(zone, airspaceAnalysis)],
            []
        );

    protected getEvaluationIssuesForZones = (
        zones: AirspaceElement[],
        evaluationIssues?: MissionPlanEvaluationOption
    ): MissionPlanAnalysisEvaluationIssue[] =>
        zones.reduce<MissionPlanAnalysisEvaluationIssue[]>(
            (issues, zone) => [...issues, ...this.getEvaluationIssuesForZone(zone, evaluationIssues)],
            []
        );

    protected getZonesFlightConditions = (zones: AirspaceElement[], currentLangCode: string): TextWithTooltip[] =>
        zones.reduce<TextWithTooltip[]>((flightConditions, zone) => {
            const conditions =
                zone.information?.[currentLangCode]?.flightConditions?.filter(
                    (condition) =>
                        !RULES_TO_REMOVE_REGEXP.test(condition.text) && !flightConditions.some((item) => item.text === condition.text)
                ) ?? [];

            return [...flightConditions, ...conditions];
        }, []);

    protected getRequirementsStatusOptions(
        status: AuthorityAcceptationStatus | undefined
    ): { icon: IconName; className: string } | undefined {
        switch (status) {
            case AuthorityAcceptationStatus.Open:
                return { icon: "information-fill", className: "open" };
            case AuthorityAcceptationStatus.Rejected:
                return { icon: "close-circle-fill", className: "rejected" };
            case AuthorityAcceptationStatus.Resolved:
                return { icon: "checkbox-circle-fill", className: "resolved" };
            default:
                return;
        }
    }

    protected getDssRequirements = (zone: AirspaceElement, authorityAcceptationItem?: AuthorityAcceptationItem): Requirement[] => {
        if (zone.supervisor !== DYNAMIC_SAFETY_AND_SECURITY_SUPERVISOR_NAME) {
            return [];
        }

        return [this.getRequirements(authorityAcceptationItem, CONSENT_LABELS.dss)].filter(FunctionUtils.isTruthy);
    };

    protected getAdditionalRequirements(
        authorityAcceptation?: AuthorityAcceptation,
        missionPhase?: MissionProcessingPhaseExtended
    ): Requirement[] {
        const dtmRequirements = this.getRequirements(authorityAcceptation?.dtm, CONSENT_LABELS.dtm);
        const utmRequirements = this.getRequirements(authorityAcceptation?.utm, CONSENT_LABELS.utm);

        if (
            dtmRequirements &&
            utmRequirements &&
            missionPhase &&
            [
                MissionProcessingPhase.Accepted,
                MissionProcessingPhase.MissionAbandoned,
                MissionProcessingPhase.MissionRealized,
                MissionPhaseExtensions.AcceptedConditionally,
            ].includes(missionPhase)
        ) {
            const requirementsStatusOptions = this.getRequirementsStatusOptions(AuthorityAcceptationStatus.Resolved);
            if (!requirementsStatusOptions) {
                return [];
            }

            return [
                {
                    text: this.transloco.translate(CONSENT_LABELS.utm, { status: AuthorityAcceptationStatus.Resolved }),
                    className: requirementsStatusOptions.className,
                    iconName: requirementsStatusOptions.icon,
                },
            ];
        }

        return [dtmRequirements, utmRequirements].filter(FunctionUtils.isTruthy);
    }

    protected getAdditionalRequirementsForPartialAcceptations(partialAcceptations?: PartialAuthorityAcceptation[]): Requirement[] {
        return (partialAcceptations ?? [])
            ?.map((partialAuthorityAcceptation) => {
                const requirementsStatusOptions = this.getRequirementsStatusOptions(partialAuthorityAcceptation.status);

                if (!requirementsStatusOptions) {
                    return;
                }

                return {
                    text: this.transloco.translate(CONSENT_LABELS.other, {
                        status: partialAuthorityAcceptation.status,
                        manager: partialAuthorityAcceptation.authorityUnitName,
                    }),
                    className: requirementsStatusOptions.className,
                    iconName: requirementsStatusOptions.icon,
                };
            })
            .filter(FunctionUtils.isTruthy);
    }

    protected getFlightRulesForZones(rules?: FlightRules[], zones?: AirspaceElement[]): FlightRules[] {
        return (
            zones?.reduce<FlightRules[]>((flightRules, zone) => {
                const zoneRules = rules?.filter((rule) => rule.translationArgs.designators?.includes(zone.designator ?? "")) ?? [];

                return [...flightRules, ...zoneRules];
            }, []) ?? []
        );
    }

    protected shouldDisplayPartialAcceptationMessages(partialAcceptations: PartialAuthorityAcceptation[]): boolean {
        return partialAcceptations.some((partialAcceptation) => partialAcceptation.comment);
    }

    private getRequirements(authorityAcceptationItem?: AuthorityAcceptationItem, translationKey?: string): Requirement | undefined {
        if (!authorityAcceptationItem || !translationKey) {
            return;
        }
        const requirementsStatusOptions = this.getRequirementsStatusOptions(authorityAcceptationItem.acceptationStatus);

        if (!requirementsStatusOptions) {
            return;
        }

        return {
            text: this.transloco.translate(translationKey, { status: authorityAcceptationItem.acceptationStatus }),
            className: requirementsStatusOptions.className,
            iconName: requirementsStatusOptions.icon,
        };
    }
}
