import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { GuardsCheckEnd } from "@angular/router";
import { AZURE_MAPS_LAYER_OPTIONS } from "@dtm-frontend/shared/map/cesium";
import { GeoZonesActions, ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import {
    MissionCategory,
    MissionContextType,
    MissionPlanData,
    MissionProcessingPhase,
    MissionType,
    TacticalMitigationPerformanceRequirementProperty,
} from "@dtm-frontend/shared/mission";
import { FILES_UPLOAD_API_PROVIDER, ItineraryEditorType, MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MapLayerProviderOptions, SceneMode } from "@pansa/ngx-cesium";
import turfBbox from "@turf/bbox";
import { Polygon, Properties, feature, featureCollection } from "@turf/helpers";
import { filter, first, map } from "rxjs";
import { PlanFilesUploadApiService } from "../../services/plan-files-upload-api.service";
import { MissionActions } from "../../state/mission.actions";
import { MissionBaseComponent } from "../mission.base.component";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface MissionDetailsComponentState {
    selectedOtherMission: MissionPlanData | undefined;
    selectedZoneId: string | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-details",
    templateUrl: "./mission-details.component.html",
    styleUrls: ["../../styles/mission.common.scss", "./mission-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: PlanFilesUploadApiService }],
})
export class MissionDetailsComponent extends MissionBaseComponent<MissionDetailsComponentState> implements OnDestroy {
    protected readonly initialViewBox$ = this.getInitialViewBoxObservable();

    protected readonly MissionCategory = MissionCategory;
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    protected readonly MissionContextType = MissionContextType;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;

    constructor(protected readonly localStore: LocalComponentStore<MissionDetailsComponentState>) {
        super(localStore);

        localStore.setState({
            selectedOtherMission: undefined,
            selectedZoneId: undefined,
        });

        this.store.dispatch([new GeoZonesActions.SetCustomZonesVisibility(true), MissionActions.StartMissionStatusUpdateWatch]);

        this.cleanupOnRouteNavigation();
    }

    protected readonly MissionType = MissionType;
    protected readonly AZURE_MAPS_LAYER_OPTIONS = AZURE_MAPS_LAYER_OPTIONS;
    protected readonly SceneMode = SceneMode;
    protected readonly MapLayerProviderOptions = MapLayerProviderOptions;
    protected readonly TacticalMitigationPerformanceRequirementProperty = TacticalMitigationPerformanceRequirementProperty;

    public ngOnDestroy(): void {
        this.store.dispatch(new GeoZonesActions.SetCustomZonesVisibility(false));
    }

    protected getInitialViewBoxObservable() {
        return this.route$.pipe(
            RxjsUtils.filterFalsy(),
            first(),
            map((routeData) => {
                const bbox = turfBbox(this.getZoomArea(routeData));
                Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
                Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(...bbox);

                // NOTE: setTimeout is needed to make sure that the camera is set after the map is initialized
                setTimeout(() => this.flyToMainMissionRoute());

                return true;
            })
        );
    }

    private getZoomArea(route: MissionPlanRoute) {
        return featureCollection<Polygon, Properties>(
            route.sections
                .map((section) => section.segment?.safetyArea.volume.area ?? section.flightZone?.safetyArea.volume.area)
                .filter(FunctionUtils.isTruthy)
                .map((polygon) => feature(polygon))
        );
    }

    protected handleOtherMissionSelection(mission: MissionPlanData | undefined) {
        this.localStore.patchState({ selectedOtherMission: mission });
    }

    private cleanupOnRouteNavigation() {
        this.router.events
            .pipe(
                filter((event): event is GuardsCheckEnd => event instanceof GuardsCheckEnd),
                untilDestroyed(this)
            )
            .subscribe((event) => {
                if (event.url === this.router.url || event.shouldActivate === false) {
                    return;
                }

                this.store.dispatch([
                    MissionActions.CleanupMissionWizard,
                    MissionActions.StopMissionStatusUpdateWatch,
                    MissionActions.StopMissionPlanAnalysisStatusWatch,
                ]);
            });
    }
}
