<dtm-ui-input-field
    [hasErrors]="hasErrors$ | ngrxPush"
    matAutocompleteOrigin
    #autocompleteOrigin="matAutocompleteOrigin"
    [isClearable]="false"
>
    <label>{{ "dtmWebAppLibSpecPermApp.operationInfoStep.operationSearch.label" | transloco }}</label>
    <input
        matInput
        [placeholder]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationSearch.placeholder' | transloco"
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        [matAutocompleteConnectedTo]="autocompleteOrigin"
        (focusout)="propagateTouch()"
    />
    <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
    <mat-autocomplete (optionSelected)="selectOperation($event)" [displayWith]="displayAutocompleteValueFunction" #auto="matAutocomplete">
        <ng-container *ngrxLet="{ options: options$, isProcessing: isProcessing$ } as vm">
            <ng-container *ngIf="!vm.isProcessing; else loaderTemplate">
                <mat-option *ngIf="!vm.options.length" class="empty-options" disabled>
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.operationSearch.noOperationsLabel" | transloco }}
                </mat-option>
                <mat-option *ngFor="let option of vm.options" [value]="option">
                    {{ option.name }}
                </mat-option>
            </ng-container>
            <ng-template #loaderTemplate>
                <mat-option class="options-loader" disabled>
                    <mat-spinner diameter="20" color="accent"></mat-spinner>
                </mat-option>
            </ng-template>
        </ng-container>
    </mat-autocomplete>
</dtm-ui-input-field>
<dtm-ui-form-errors (hasErrors)="setHasErrors($event)">
    <ng-content select=".field-error"></ng-content>
</dtm-ui-form-errors>
