import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Address, IdentityDocumentType, PhoneNumber } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    ForeignOperatorValidationError,
    ForeignOperatorValidationErrorType,
    OperatorRegistrationCapabilities,
    PilotOperatorRegistrationError,
    PilotOperatorRegistrationErrorType,
} from "../../shared/models";
import { AttorneyPowerStatus, AttorneyPowerType, OperatorRegistrationFormValue, RegisteredOperator } from "./models";

export interface EnterpriseOperatorRegistrationRequestBody {
    identityDocumentId?: string;
    identityDocumentExpirationDate?: string;
    identityDocumentType?: IdentityDocumentType;
    name: string;
    companyNumber?: string;
    email: string;
    phoneNumber: PhoneNumber;
    insurancePolicyNumber?: string;
    insurancePolicyExpirationDate?: string;
    address: Address;
    legalGuardian?: {
        firstName: string;
        lastName: string;
        email: string;
    };
    attorneyPower: {
        type: AttorneyPowerType;
        nationalCourtRegister?: string;
        documentId?: string;
        attorneyPowerAdministrativeFee?: {
            feeConfirmationId?: string;
            declarationOfExemption: boolean;
        };
    };
    registeredInOtherCountry: boolean;
    operatorNumber?: {
        number: string;
        secret: string;
    };
}

export interface AssociationOperatorRequestBody extends EnterpriseOperatorRegistrationRequestBody {
    associationNationalCourtRegister?: string;
    associationRegistrationNumber?: string;
}

export interface EnterpriseOperatorRegistrationResponseBody {
    id: string;
    status: string;
    operatorNumber: string;
    ownerUserId: string;
    name: string;
    email: string;
    phoneNumber: PhoneNumber;
    companyNumber?: string;
    insurancePolicyNumber: string;
    insurancePolicyExpirationDate: string;
    address: {
        streetName: string;
        houseNumber: string;
        apartmentNumber: string;
        city: string;
        postCode: string;
        country: string;
    };
    attorneyPower: {
        id: string;
        type: AttorneyPowerType;
        status: AttorneyPowerStatus;
    };
}
export interface AssociationOperatorRegistrationResponseBody extends EnterpriseOperatorRegistrationResponseBody {
    associationNationalCourtRegister?: string;
    associationRegistrationNumber?: string;
}

export function getOperatorRegistrationConflictError(errorResponse: HttpErrorResponse): PilotOperatorRegistrationErrorType {
    if (errorResponse.error.generalMessage.includes("Company number already used")) {
        return PilotOperatorRegistrationErrorType.AlreadyRegisteredCompanyNumber;
    }

    return PilotOperatorRegistrationErrorType.Unknown;
}

export function transformOperatorRegistrationErrorResponse(errorResponse: HttpErrorResponse): PilotOperatorRegistrationError {
    switch (errorResponse.status) {
        case HttpStatusCode.Forbidden:
            return { type: PilotOperatorRegistrationErrorType.NotAuthorized };
        case HttpStatusCode.Conflict:
            return {
                type: getOperatorRegistrationConflictError(errorResponse),
            };
        default:
            return { type: PilotOperatorRegistrationErrorType.Unknown };
    }
}

export interface GetOperatorRegistrationCapabilitiesResponseBody {
    legalGuardianRequired: boolean;
    identityDocumentRequired: boolean;
    easaCountries: string[];
    originCountry: string;
}

export function convertHttpErrorResponseToForeignOperatorVerificationError(error: HttpErrorResponse): ForeignOperatorValidationError {
    switch (error.status) {
        case HttpStatusCode.BadRequest:
            return { type: ForeignOperatorValidationErrorType.OperatorInvalid };
        default:
            return { type: ForeignOperatorValidationErrorType.Unknown };
    }
}

export function transformGetOperatorRegistrationCapabilitiesResponseBodyToOperatorRegistrationCapabilities(
    response: GetOperatorRegistrationCapabilitiesResponseBody
): OperatorRegistrationCapabilities {
    return {
        isIdentityDocumentRequired: response.identityDocumentRequired,
        isLegalGuardianRequired: response.legalGuardianRequired,
        easaCountries: response.easaCountries,
        originCountry: response.originCountry,
    };
}

export function transformOperatorRegistrationToEntepriseOperatorRegistrationBody(
    formValue: OperatorRegistrationFormValue
): EnterpriseOperatorRegistrationRequestBody {
    const attorneyPowerAdministrativeFee =
        formValue.attorneyPower.type === AttorneyPowerType.ConfirmatoryDocument
            ? {
                  feeConfirmationId: formValue.attorneyPower.feeConfirmation?.length
                      ? formValue.attorneyPower.feeConfirmation[0].id
                      : undefined,
                  declarationOfExemption: !!formValue.attorneyPower.isDeclarationOfExemption,
              }
            : undefined;

    const body: EnterpriseOperatorRegistrationRequestBody = {
        email: formValue.email,
        phoneNumber: formValue.phoneNumber,
        name: formValue.companyName,
        address: formValue.address,
        attorneyPower: {
            type: formValue.attorneyPower.type,
            documentId: formValue.attorneyPower.document?.length ? formValue.attorneyPower.document[0].id : undefined,
            attorneyPowerAdministrativeFee: attorneyPowerAdministrativeFee,
            nationalCourtRegister: formValue.attorneyPower.nationalCourtRegister,
        },
        registeredInOtherCountry: formValue.isRegisteredInOtherCountry,
    };

    if (formValue.identityDocument?.length && formValue.identityDocumentExpirationDate && formValue.identityDocumentType) {
        body.identityDocumentId = formValue.identityDocument[0].id;
        body.identityDocumentExpirationDate = DateUtils.getISOStringDate(formValue.identityDocumentExpirationDate.toISOString());
        body.identityDocumentType = formValue.identityDocumentType;
    }

    if (formValue.insurancePolicyNumber) {
        body.insurancePolicyNumber = formValue.insurancePolicyNumber;
    }

    if (formValue.insurancePolicyExpirationDate) {
        body.insurancePolicyExpirationDate = DateUtils.getISOStringDate(formValue.insurancePolicyExpirationDate.toISOString());
    }

    if (formValue.legalGuardian) {
        body.legalGuardian = formValue.legalGuardian;
    }

    if (formValue.isRegisteredInOtherCountry && formValue.foreignOperator) {
        body.operatorNumber = formValue.foreignOperator;
    }

    if (formValue.companyNumber) {
        body.companyNumber = formValue.companyNumber;
    }

    return body;
}

export function transformOperatorRegistrationToAssociationOperatorRegistrationBody(
    formValue: OperatorRegistrationFormValue
): AssociationOperatorRequestBody {
    return {
        ...transformOperatorRegistrationToEntepriseOperatorRegistrationBody(formValue),
        associationRegistrationNumber: formValue.associationRegistrationNumber,
        associationNationalCourtRegister: formValue.associationNationalCourtRegister,
    };
}

export function convertOperatorRegistrationResponseBodyToRegisteredOperator(
    response: EnterpriseOperatorRegistrationResponseBody | AssociationOperatorRegistrationResponseBody
): RegisteredOperator {
    return {
        id: response.id,
        status: response.status,
        operatorNumber: response.operatorNumber,
        ownerUserId: response.ownerUserId,
        name: response.name,
        email: response.email,
        phoneNumber: response.phoneNumber,
        companyNumber: response.companyNumber,
        insurancePolicyNumber: response.insurancePolicyNumber,
        insurancePolicyExpirationDate: response.insurancePolicyExpirationDate,
        address: response.address,
        attorneyPower: response.attorneyPower,
    };
}
