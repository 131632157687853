<ng-container
    *ngrxLet="{
        soraResult: soraResult$,
        soraSettings: soraSettings$,
        airspaceCharacteristics: airspaceCharacteristics$,
        isExpanded: isExpanded$
    } as vm"
>
    <dtm-ui-expandable-panel [isExpanded]="vm.isExpanded" [hasHeaderSeparator]="false" class="sora-panel">
        <h2 headerSlot>
            {{ "dtmWebAppLibMission.summaryStep.analysis.initialSoraIssuesTitle" | transloco }}
        </h2>
        <div class="row">
            <div class="column">
                <p class="title">{{ "dtmWebAppLibMission.summaryStep.analysis.sora.finalGrlLabel" | transloco }}</p>
                <span *ngIf="vm.soraResult?.finalGrl as finalGrl; else noGrlData">
                    {{
                        !!vm.soraSettings?.controlledGroundAreaComment
                            ? (finalGrl | controlledAreaGrlLevel)
                            : (vm.soraResult.effectiveGrl | grlLevel)
                    }}
                </span>
                <ng-template #noGrlData>
                    {{ "dtmUi.noDataLabel" | transloco }}
                </ng-template>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <p class="title">{{ "dtmWebAppLibMission.summaryStep.analysis.sora.intrinsicGrcLabel" | transloco }}</p>
                <div class="value">
                    {{ (vm.soraResult.intrinsicGrc | grcLevel : true) ?? ("dtmUi.noDataLabel" | transloco) }}

                    <dtm-web-app-lib-sora-issues-warning [grc]="vm.soraResult.finalGrc"></dtm-web-app-lib-sora-issues-warning>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <p class="title">{{ "dtmWebAppLibMission.summaryStep.analysis.sora.airspaceCharacteristicLabel" | transloco }}</p>
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.sora.airspaceCharacteristicLabelText"
                        | transloco : { characteristics: vm.airspaceCharacteristics }
                }}
            </div>
        </div>
        <div class="row">
            <div class="column">
                <p class="title">{{ "dtmWebAppLibMission.summaryStep.analysis.sora.initialResidualArcLabel" | transloco }}</p>
                {{ (vm.soraResult.initialArc | arcLabel : true) ?? ("dtmUi.noDataLabel" | transloco) }}
                <dtm-web-app-lib-sora-issues-warning
                    [arc]="vm.soraResult.residualArc ?? vm.soraResult.initialArc"
                ></dtm-web-app-lib-sora-issues-warning>
            </div>
        </div>
    </dtm-ui-expandable-panel>
</ng-container>
