import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { MissionType } from "@dtm-frontend/shared/mission";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Mission, MissionStatus } from "../../../models/tactical.models";

interface AcceptedMissionTileComponentState {
    mission: Mission | undefined;
    isSelected: boolean;
    shouldShowExtendedInfo: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-accepted-mission-tile[mission]",
    templateUrl: "./accepted-mission-tile.component.html",
    styleUrls: ["./accepted-mission-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AcceptedMissionTileComponent {
    @Input()
    public set mission(value: Mission) {
        this.localStore.patchState({
            mission: value,
        });
    }

    @Input()
    public set isSelected(value: boolean) {
        this.localStore.patchState({ isSelected: value });
    }

    @Input()
    public set shouldShowExtendedInfo(value: BooleanInput) {
        this.localStore.patchState({ shouldShowExtendedInfo: coerceBooleanProperty(value) });
    }

    public readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    public readonly isSelected$ = this.localStore.selectByKey("isSelected");
    public readonly shouldShowExtendedInfo$ = this.localStore.selectByKey("shouldShowExtendedInfo");

    public MissionType = MissionType;
    public MissionStatus = MissionStatus;

    constructor(private readonly localStore: LocalComponentStore<AcceptedMissionTileComponentState>, private readonly router: Router) {
        this.localStore.setState({
            mission: undefined,
            isSelected: false,
            shouldShowExtendedInfo: false,
        });
    }

    protected gotoDetails(mission: Mission) {
        return this.router.navigate(["/tactical/mission", mission.missionId]);
    }
}
