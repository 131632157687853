import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FormType, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged, map } from "rxjs/operators";
import { CrewCompetencies, CrewMember, OperationScenario, StaffCompetencies } from "../../../services/specific-permit-application.models";

interface CrewCompetenciesFormComponentState {
    availableOperationScenarios: OperationScenario[];
}

interface CrewMemberCompetenciesFormValues {
    role: CrewMember;
    areCompetenciesSet: boolean;
    competencies: StaffCompetencies | null;
}

type CrewMemberCompetenciesForm = FormGroup<FormType<CrewMemberCompetenciesFormValues>>;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-crew-competencies-form[availableOperationScenarios]",
    templateUrl: "./crew-competencies-form.component.html",
    styleUrls: ["./crew-competencies-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CrewCompetenciesFormComponent),
            multi: true,
        },
    ],
})
export class CrewCompetenciesFormComponent implements ControlValueAccessor {
    @Input() public set availableOperationScenarios(value: OperationScenario[] | undefined) {
        this.localStore.patchState({ availableOperationScenarios: value ?? [] });
    }

    protected readonly availableOperationScenarios$ = this.localStore.selectByKey("availableOperationScenarios");

    protected readonly crewFormArray = new FormArray<CrewMemberCompetenciesForm>([]);
    protected readonly crewCompetenciesForm = new FormGroup<{ crewMembers: FormArray<CrewMemberCompetenciesForm> }>({
        crewMembers: this.crewFormArray,
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: CrewCompetencies | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<CrewCompetenciesFormComponentState>) {
        this.localStore.setState({
            availableOperationScenarios: [],
        });

        this.crewFormArray.valueChanges
            .pipe(
                map((values) => this.prepareCrewCompetenciesValue(values as CrewMemberCompetenciesFormValues[])),
                distinctUntilChanged(equal),
                untilDestroyed(this)
            )
            .subscribe((values) => {
                this.propagateChange(values);
            });
    }

    public registerOnChange(fn: (value: CrewCompetencies | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: CrewCompetencies | null): void {
        this.initCrewCompetencies(value);
    }

    protected addCompetencies(index: number) {
        const competenciesForm = this.crewFormArray.at(index);
        competenciesForm.controls.areCompetenciesSet.setValue(true);
        competenciesForm.controls.competencies.setValue({
            basic: [],
            additional: [],
            hasEuRegulationCompetency: false,
        });
    }

    protected removeCompetencies(index: number) {
        const competenciesForm = this.crewFormArray.at(index);
        competenciesForm.controls.areCompetenciesSet.setValue(false);
        competenciesForm.controls.competencies.setValue(null);
    }

    private initCrewCompetencies(crewCompetencies: CrewCompetencies | null): void {
        this.crewFormArray.clear({ emitEvent: false });

        if (!crewCompetencies) {
            return;
        }

        for (const [role, crewMemberCompetencies] of Object.entries(crewCompetencies)) {
            this.crewFormArray.push(this.createCrewMemberCompetenciesForm(role as CrewMember, crewMemberCompetencies), {
                emitEvent: false,
            });
        }
    }

    private createCrewMemberCompetenciesForm(role: CrewMember, competencies: StaffCompetencies | null): CrewMemberCompetenciesForm {
        return new FormGroup({
            role: new FormControl<CrewMember>(role, { nonNullable: true }),
            areCompetenciesSet: new FormControl<boolean>(!!competencies, { nonNullable: true }),
            competencies: new FormControl<StaffCompetencies | null>(competencies),
        });
    }

    private prepareCrewCompetenciesValue(values: CrewMemberCompetenciesFormValues[]): CrewCompetencies {
        const result: CrewCompetencies = {};

        for (const crewMemberCompetencies of values) {
            result[crewMemberCompetencies.role] = crewMemberCompetencies.areCompetenciesSet ? crewMemberCompetencies.competencies : null;
        }

        return result;
    }
}
