import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DashboardApiService } from "../services/dashboard-api.service";
import { DashboardError, DashboardMission, PostWithLanguages } from "../services/dashboard.models";
import { DashboardActions } from "./dashboard.actions";

export interface DashboardStateModel {
    posts: PostWithLanguages[] | undefined;
    postsError: DashboardError | undefined;
    isProcessing: boolean;
    missions: DashboardMission[] | undefined;
    totalMissionElements: number | undefined;
    missionsError: DashboardError | undefined;
    areMissionsProcessing: boolean;
}

const defaultState: DashboardStateModel = {
    posts: undefined,
    postsError: undefined,
    isProcessing: false,
    missions: undefined,
    totalMissionElements: undefined,
    missionsError: undefined,
    areMissionsProcessing: false,
};

@State<DashboardStateModel>({
    name: "dashboard",
    defaults: defaultState,
})
@Injectable()
export class DashboardState {
    constructor(private readonly dashboardApi: DashboardApiService) {}

    @Selector()
    public static posts(state: DashboardStateModel): PostWithLanguages[] | undefined {
        return state.posts;
    }

    @Selector()
    public static postsError(state: DashboardStateModel): DashboardError | undefined {
        return state.postsError;
    }

    @Selector()
    public static isProcessing(state: DashboardStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static missions(state: DashboardStateModel): DashboardMission[] | undefined {
        return state.missions;
    }
    @Selector()
    public static totalMissionElements(state: DashboardStateModel): number | undefined {
        return state.totalMissionElements;
    }

    @Selector()
    public static missionsError(state: DashboardStateModel): DashboardError | undefined {
        return state.missionsError;
    }

    @Selector()
    public static areMissionsProcessing(state: DashboardStateModel): boolean {
        return state.areMissionsProcessing;
    }

    @Action(DashboardActions.GetPosts)
    public getPosts(context: StateContext<DashboardStateModel>) {
        context.patchState({ isProcessing: true, postsError: undefined });

        return this.dashboardApi.getPosts().pipe(
            tap((posts: PostWithLanguages[]) => {
                context.patchState({
                    posts,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    posts: undefined,
                    postsError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(DashboardActions.GetMissions, { cancelUncompleted: true })
    public getMissions(context: StateContext<DashboardStateModel>, action: DashboardActions.GetMissions) {
        context.patchState({ areMissionsProcessing: true, missionsError: undefined });

        return this.dashboardApi.getMissions(action.operatorId, action.params).pipe(
            tap((response) => {
                context.patchState({
                    missions: response.missions,
                    totalMissionElements: response.totalElements,
                    areMissionsProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missions: undefined,
                    totalMissionElements: undefined,
                    missionsError: error,
                    areMissionsProcessing: false,
                });

                return EMPTY;
            })
        );
    }
}
