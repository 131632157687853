import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
    FlightLevelType,
    ItineraryEditorMissionPreferencesFormData,
    MissionPlanItineraryConstraints,
} from "../../../../../../models/mission.model";
import {
    MissionWizardItineraryEditorParametersPanelBaseComponent,
    MissionWizardItineraryEditorParametersPanelBaseComponentState,
    POLAND_AMSL_MAX_HEIGHT,
    POLAND_AMSL_MIN_HEIGHT,
} from "../mission-parameters-panel-base.component";

interface MissionWizardItineraryEditorPreferencesPanelComponentState
    extends MissionWizardItineraryEditorParametersPanelBaseComponentState<ItineraryEditorMissionPreferencesFormData> {
    constraints: MissionPlanItineraryConstraints | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-itinerary-editor-step-preferences-panel[constraints][formControls]",
    templateUrl: "./preferences-panel.component.html",
    styleUrls: ["./preferences-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardItineraryEditorPreferencesPanelComponent extends MissionWizardItineraryEditorParametersPanelBaseComponent<
    ItineraryEditorMissionPreferencesFormData,
    MissionWizardItineraryEditorPreferencesPanelComponentState
> {
    protected readonly FlightLevelType = FlightLevelType;
    protected readonly POLAND_AMSL_MAX_HEIGHT = POLAND_AMSL_MAX_HEIGHT;
    protected readonly POLAND_AMSL_MIN_HEIGHT = POLAND_AMSL_MIN_HEIGHT;

    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }

    protected readonly constraints$ = this.localStore.selectByKey("constraints");

    constructor(localStore: LocalComponentStore<MissionWizardItineraryEditorPreferencesPanelComponentState>) {
        super(localStore);
        localStore.setState({
            isExpanded: true,
            isDeferred: false,
            constraints: undefined,
            formControls: undefined,
            originalFormControls: undefined,
            formStateController: undefined,
            isDisabled: false,
        });
    }
}
