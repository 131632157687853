import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { LegalGuardianConfirmationData, SavedDocumentData } from "../models/user-profile.models";

export namespace UserProfileActions {
    export class GetUserProfile {
        public static readonly type = "[User Profile] Get profile";
        constructor(public userId: string) {}
    }

    export class GetNationalNodeUserRegistration {
        public static readonly type = "[User Profile] Get national node user registration";
        constructor(public userId: string) {}
    }

    export class RequestNationalNodeUserEmailChange {
        public static readonly type = "[User Profile] Request national node user email change";
        constructor(public email: string) {}
    }

    export class RequestNationalNodeUserPhoneNumberChange {
        public static readonly type = "[User Profile] Request national node user phone number change";
        constructor(public phoneNumber: PhoneNumber) {}
    }

    export class ConfirmNationalNodeUserEmail {
        public static readonly type = "[User Profile] Confirm national node user email";
        constructor(public verificationCode: string) {}
    }

    export class ConfirmNationalNodeUserPhoneNumber {
        public static readonly type = "[User Profile] Confirm national node user phone number";
        constructor(public verificationCode: string) {}
    }

    export class ResendNationalNodeUserRegistrationPhoneNumberVerificationCode {
        public static readonly type = "[User Profile] Resend national node user phone number verification code";
    }

    export class ResendNationalNodeUserRegistrationEmailVerificationCode {
        public static readonly type = "[User Profile] Resend national node user email verification code";
    }

    export class RegisterNationalNodeUser {
        public static readonly type = "[User Profile] Register national node user";
        constructor(public isTermsOfUseAcceptance: boolean) {}
    }

    export class RequestEmailAddressChange {
        public static readonly type = "[User Profile] Request address email change";
        constructor(public newEmailAddress: string) {}
    }

    export class RequestPhoneNumberChange {
        public static readonly type = "[User Profile] Request phone number change";
        constructor(public phoneNumber: PhoneNumber) {}
    }

    export class SaveNewEmailAddress {
        public static readonly type = "[User Profile] Save new address email";
        constructor(public verificationCode: string) {}
    }

    export class SaveNewPhoneNumber {
        public static readonly type = "[User Profile] Save new phone number";
        constructor(public verificationCode: string) {}
    }

    export class UpdateUserEmail {
        public static readonly type = "[User Profile] Update user email";
        constructor(public newEmailAddress: string) {}
    }

    export class GetProfileAvatar {
        public static readonly type = "[User Profile] Get profile avatar";
        constructor(public userId: string) {}
    }

    export class UpdateUserPhoneNumber {
        public static readonly type = "[User Profile] Update user phone number";
        constructor(public newPhoneNumber: PhoneNumber) {}
    }

    export class UpdateProfileLanguage {
        public static readonly type = "[User Profile] Update profile language";
        constructor(public language: LanguageCode) {}
    }

    export class SaveProfileAvatar {
        public static readonly type = "[User Profile] Save profile avatar";
        constructor(public base64Image: string) {}
    }

    export class SaveIdentityDocument {
        public static readonly type = "[User Profile] Save identity document";
        constructor(public savedDocumentData: SavedDocumentData) {}
    }

    export class ChangeLegalGuardianData {
        public static readonly type = "[User Profile] Change legal guardian confirmation data";
        constructor(public confirmationData: LegalGuardianConfirmationData, public isDataEdited: boolean) {}
    }

    export class ClearEmailAddressRequestChange {
        public static readonly type = "[User Profile] Clear address request change";
    }

    export class ClearPhoneRequestChange {
        public static readonly type = "[User Profile] Clear phone number request change";
    }

    export class ResetUserPassword {
        public static readonly type = "[User Profile] Reset user password";
    }

    export class DownloadIdentityDocument {
        public static readonly type = "[User Profile] Download identity document";
    }

    export class ResendLegalGuardianEmailAddress {
        public static readonly type = "[User Profile] Resend legal guardian email address";
    }

    export class DeleteIdentityDocument {
        public static readonly type = "[User Profile] Delete identity card";
    }

    export class DeleteAvatar {
        public static readonly type = "[User Profile] Delete user avatar";
    }
}
