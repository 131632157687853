<ng-container
    *ngrxLet="{
        analysisStatus: analysisStatus$,
        stepNumber: stepNumber$,
        stepsAmount: stepsAmount$,
        isProcessing: isProcessing$,
        missionPlanInformation: missionPlanInformation$,
        additionalInformation: additionalInformation$,
        missionPlan: missionPlan$,
        route: route$,
        nearbyMissions: nearbyMissions$,
        collisionMission: collisionMission$,
        operators: operators$,
        currentPlanDataAndCapabilities: currentPlanDataAndCapabilities$,
        zones: zones$,
        selectedZoneId: selectedZoneId$,
        soraSettings: soraSettings$,
        missionType: missionType$,
        isDtmOnly: isDtmOnly$
    } as vm"
>
    <dtm-web-app-lib-mission-wizard-step-wrapper
        [stepNumber]="vm.stepNumber"
        [stepsAmount]="vm.stepsAmount"
        [title]="'dtmWebAppLibMission.missionWizardSteps.summary.header' | transloco"
        class="step-wrapper"
        (next)="next()"
        (back)="back.emit()"
        [isNextButtonEnabled]="!vm.isProcessing && (vm.analysisStatus?.isPlanSubmittable || vm.analysisStatus?.applyForSpecificPermit)"
        [isLoading]="vm.isProcessing"
        [nextButtonLabel]="vm.analysisStatus | invoke : nextButtonLabelKey | transloco"
        [stepId]="MissionWizardSteps.Summary"
    >
        <!-- TODO replace it with <dtm-mission-basic-details> component - REJ-2976 -->
        <dtm-web-app-lib-mission-basic-details
            [formControl]="missionNameFormControl"
            [missionPlan]="vm.missionPlan"
            (mainMissionZoom)="flyToMainMissionRoute.emit()"
        ></dtm-web-app-lib-mission-basic-details>

        <dtm-web-app-lib-message-to-pansa
            *ngIf="vm.analysisStatus?.sora && !vm.currentPlanDataAndCapabilities?.plan?.category?.specificCaaPermitId"
            [planId]="vm.missionPlan?.id"
        ></dtm-web-app-lib-message-to-pansa>

        <dtm-web-app-lib-message-to-manager
            *ngIf="!vm.analysisStatus?.sora || vm.currentPlanDataAndCapabilities?.plan?.category?.specificCaaPermitId"
            [justification]="vm.missionPlan?.remarks?.justification"
            [planId]="vm.missionPlan?.id"
            [isPriorityEnabled]="vm.isDtmOnly"
            (valueChanges)="formalJustificationChange.emit($event)"
        ></dtm-web-app-lib-message-to-manager>

        <!-- TODO replace it with <dtm-mission-mission-details> component - REJ-2976 -->
        <dtm-ui-expandable-panel [hasHeaderSeparator]="false">
            <dtm-ui-expandable-simple-header headerSlot [label]="'dtmWebAppLibShared.mission.missionDataHeaderLabel' | transloco">
            </dtm-ui-expandable-simple-header>
            <dtm-mission-mission-data
                [operators]="vm.operators"
                [missionPlanDataAndCapabilities]="vm.currentPlanDataAndCapabilities"
                [route]="vm.route"
            ></dtm-mission-mission-data>
        </dtm-ui-expandable-panel>

        <dtm-ui-expandable-panel [hasHeaderSeparator]="false">
            <dtm-ui-expandable-simple-header headerSlot [label]="'dtmWebAppLibShared.mission.routeDetailsHeaderLabel' | transloco">
            </dtm-ui-expandable-simple-header>
            <dtm-ui-route-details [route]="vm.route"></dtm-ui-route-details>
        </dtm-ui-expandable-panel>

        <dtm-web-app-lib-zone-analysis
            [airspaceAnalysis]="vm.analysisStatus?.airspace"
            [zones]="vm.zones"
            [selectedZoneId]="vm.selectedZoneId"
            (zoneSelect)="zoneSelect.emit($event)"
        >
            <dtm-mission-additional-analysis-info
                [additionalZones]="vm.zones.additionalZones"
                [evaluation]="vm.analysisStatus?.evaluation"
                [selectedZoneId]="vm.selectedZoneId"
                (zoneSelect)="zoneSelect.emit($event)"
            ></dtm-mission-additional-analysis-info>
        </dtm-web-app-lib-zone-analysis>

        <!-- TODO replace it with <dtm-mission-operational-area-geometry> component - REJ-2976 -->
        <dtm-web-app-lib-operational-area-geometry
            *ngIf="vm.analysisStatus?.sora"
            [operationalGeometryData]="operationalGeometryData$ | ngrxPush"
        ></dtm-web-app-lib-operational-area-geometry>

        <!-- TODO replace it with <dtm-mission-traffic-analysis> component - REJ-2976 -->
        <dtm-web-app-lib-traffic-analysis
            *ngIf="vm.analysisStatus?.traffic"
            [nearbyMissions]="vm.nearbyMissions"
            [collisionMission]="vm.collisionMission"
            (flyToRoute)="flyToRoute.emit($event)"
            (flyToMainMissionRoute)="flyToMainMissionRoute.emit()"
            (otherMissionSelected)="otherMissionSelected.emit($event)"
        ></dtm-web-app-lib-traffic-analysis>

        <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="false" *ngIf="vm.analysisStatus?.sora">
            <dtm-ui-expandable-simple-header headerSlot [label]="'dtmWebAppLibShared.mission.analysis.groundRiskTitle' | transloco">
            </dtm-ui-expandable-simple-header>
            <dtm-mission-population-density
                [populationDensity]="vm.analysisStatus?.sora?.result?.populationDensity"
            ></dtm-mission-population-density>
        </dtm-ui-expandable-panel>

        <ng-container *ngIf="vm.analysisStatus && vm.analysisStatus.sora">
            <!-- TODO replace it with <dtm-mission-operational-area-airspace-risk> component - REJ-2976 -->
            <dtm-web-app-lib-operational-area-airspace-risk
                [airRiskMitigations]="vm.soraSettings?.airRiskMitigations"
                [airRisk]="vm.analysisStatus.sora.result?.airRisk"
            ></dtm-web-app-lib-operational-area-airspace-risk>
            <!-- TODO replace it with <dtm-mission-initial-sora-issues> component - REJ-2976 -->
            <dtm-web-app-lib-initial-sora-issues
                [soraSettings]="vm.soraSettings"
                [soraResult]="vm.analysisStatus.sora.result"
                [airspaceAnalysis]="vm.analysisStatus.airspace"
                [isExpanded]="false"
            >
            </dtm-web-app-lib-initial-sora-issues>
            <!-- TODO replace it with <dtm-mission-risk-mitigation-measures> component - REJ-2976 -->
            <dtm-web-app-lib-analysis-sora-settings
                *ngIf="vm.analysisStatus.sora && vm.soraSettings?.riskMitigations"
                [airRiskInfoData]="vm.analysisStatus.sora.result?.airRisk"
                [populationDensity]="vm.analysisStatus.sora.result?.populationDensity"
                [criticalArea]="vm.analysisStatus.sora.result?.criticalArea"
                [soraSettings]="vm.soraSettings"
                [missionType]="vm.missionType"
            ></dtm-web-app-lib-analysis-sora-settings>
            <!-- TODO replace it with <dtm-mission-tactical-risk-mitigation-measures> component - REJ-2976 -->
            <dtm-web-app-lib-tactical-risk-mitigation
                *ngIf="
                    vm.missionType === MissionType.BVLOS &&
                    !!vm.analysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty &&
                    vm.analysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty !==
                        TacticalMitigationPerformanceRequirement.VLOS
                "
                [tacticalMitigationPerformanceRequirementProperty]="
                    vm.analysisStatus.sora.result?.tacticalMitigationPerformanceRequirementProperty
                "
                [tacticalMitigationPerformanceRequirements]="vm.soraSettings?.tmpr"
            ></dtm-web-app-lib-tactical-risk-mitigation>
            <!-- TODO replace it with <dtm-mission-final-sora-issues> component - REJ-2976 -->
            <dtm-web-app-lib-final-sora-issues [soraResult]="vm.analysisStatus.sora.result"></dtm-web-app-lib-final-sora-issues>
            <!-- TODO replace it with <dtm-mission-adjacent-area-and-space> component - REJ-2976 -->
            <dtm-web-app-lib-adjacent-area-and-space
                *ngIf="vm.analysisStatus.sora"
                [soraResult]="vm.analysisStatus.sora.result"
                [airspaceAnalysis]="vm.analysisStatus.airspace"
                [setup]="setup$ | ngrxPush"
            ></dtm-web-app-lib-adjacent-area-and-space>
        </ng-container>
        <dtm-web-app-lib-personal-notes
            (valueChanges)="additionalInformationChange.emit($event)"
            [information]="vm.additionalInformation"
            [isProcessing]="vm.isProcessing"
        ></dtm-web-app-lib-personal-notes>
    </dtm-web-app-lib-mission-wizard-step-wrapper>

    <ng-template #routeSideViewTemplate>
        <dtm-mission-route-side-view
            [isExpanded]="false"
            [isLoading]="vm.isProcessing"
            [isSimpleMode]="vm.currentPlanDataAndCapabilities?.plan?.itinerary?.editorType === ItineraryEditorType.Standard"
            [areBuffersVisible]="vm.currentPlanDataAndCapabilities?.plan?.flightType === MissionType.BVLOS"
            [route]="vm.route"
            (expandedChange)="heightViolationDialogLink.isExpanded = $event"
        >
            <div headerSlot class="side-view-title">
                <h2>{{ "dtmWebAppLibMission.routeSideView.sideViewHeader" | transloco }}</h2>
                <dtm-web-app-lib-height-violation-alert-dialog-link
                    [route]="vm.route"
                    #heightViolationDialogLink
                ></dtm-web-app-lib-height-violation-alert-dialog-link>
            </div>
        </dtm-mission-route-side-view>
    </ng-template>
</ng-container>
