<ng-container *ngrxLet="isMissionsProcessing$ as isMissionsListProcessing">
    <dtm-ui-loader-container [isShown]="isMissionsListProcessing">
        <dtm-ui-responsive-table *ngIf="missionsList$ | ngrxPush as missionsList" [data]="missionsList">
            <table mat-table multiTemplateDataRows desktopTable [dataSource]="missionsList" class="dtm-table">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppMembership.missionsList.statusHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let mission">
                        <dtm-mission-mission-status-badge [status]="mission.phase"></dtm-mission-mission-status-badge>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppMembership.missionsList.dateHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let mission">{{ mission.flightStartAt | localizeFriendlyDate }}</td>
                </ng-container>

                <ng-container matColumnDef="hour">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppMembership.missionsList.hourHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let mission">
                        <ng-container [ngTemplateOutlet]="hourTemplate" [ngTemplateOutletContext]="{ $implicit: mission }"></ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="uav">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppMembership.missionsList.uavHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let mission">
                        <ng-container [ngTemplateOutlet]="uavTemplate" [ngTemplateOutletContext]="{ $implicit: mission }"></ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppMembership.missionsList.missionNameHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let mission">
                        <p class="mission-name">{{ mission.name }}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="weather">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppMembership.missionsList.weatherHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let mission">
                        <ng-container
                            [ngTemplateOutlet]="weatherTemplate"
                            [ngTemplateOutletContext]="{ $implicit: mission.weatherConditions }"
                        ></ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppMembership.missionsList.actionsHeader" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let mission">
                        <button
                            type="button"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: mission }"
                            (click)="$event.stopPropagation()"
                            class="button-icon"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <ng-container
                            *ngIf="missionsListError$ | ngrxPush; else emptyListTemplate"
                            [ngTemplateOutlet]="errorTemplate"
                        ></ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                        <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['noResults']"
                    [class.hide-footer]="missionsList.length || isMissionsListProcessing"
                    class="no-results-row"
                ></tr>
                <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!missionsList.length" class="pagination-row"></tr>
            </table>
            <div *dtmUiMobileTable="let row">
                <div class="mobile-table-actions-row">
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.missionsList.statusHeader' | transloco">
                        <dtm-mission-mission-status-badge [status]="row.phase"></dtm-mission-mission-status-badge>
                    </dtm-ui-label-value>
                    <button
                        type="button"
                        [matMenuTriggerFor]="rowActionsMenu"
                        [matMenuTriggerData]="{ $implicit: row }"
                        (click)="$event.stopPropagation()"
                        class="button-icon"
                    >
                        <dtm-ui-icon name="more"></dtm-ui-icon>
                    </button>
                </div>
                <div class="mobile-table-content">
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.missionsList.dateHeader' | transloco">
                        <span *ngIf="row.flightStartAt; else missingDataTemplate">{{ row.flightStartAt | localizeFriendlyDate }}</span>
                    </dtm-ui-label-value>
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.missionsList.hourHeader' | transloco">
                        <ng-container
                            *ngIf="row.flightStartAt && row.flightFinishAt; else missingDataTemplate"
                            [ngTemplateOutlet]="hourTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row }"
                        ></ng-container>
                    </dtm-ui-label-value>
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.missionsList.uavHeader' | transloco">
                        <ng-container
                            *ngIf="row.uavName; else missingDataTemplate"
                            [ngTemplateOutlet]="uavTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row }"
                        ></ng-container>
                    </dtm-ui-label-value>
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.missionsList.missionNameHeader' | transloco">
                        <span *ngIf="row.name; else missingDataTemplate">{{ row.name }}</span>
                    </dtm-ui-label-value>
                    <dtm-ui-label-value [label]="'dtmWebAppMembership.missionsList.weatherHeader' | transloco">
                        <ng-container
                            *ngIf="row.weatherConditions; else missingDataTemplate"
                            [ngTemplateOutlet]="weatherTemplate"
                            [ngTemplateOutletContext]="{ $implicit: row.weatherConditions }"
                        ></ng-container>
                    </dtm-ui-label-value>
                </div>
            </div>
            <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
            <ng-container
                *ngIf="missionsListError$ | ngrxPush; else emptyListTemplate"
                mobileTableNoData
                [ngTemplateOutlet]="errorTemplate"
            ></ng-container>
        </dtm-ui-responsive-table>
    </dtm-ui-loader-container>
</ng-container>

<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>

<ng-template #paginationTemplate>
    <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
    </dtm-ui-pagination>
</ng-template>

<ng-template #errorTemplate>
    <dtm-ui-error [mode]="ErrorMode.Secondary" (reload)="missionsListRefresh.emit()"> </dtm-ui-error>
</ng-template>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-mission>
        <button mat-menu-item type="button" [routerLink]="['/plan/list', mission.id]">
            <dtm-ui-icon name="information"></dtm-ui-icon>
            {{ "dtmWebAppMembership.missionsList.missionDetailsLabel" | transloco }}
        </button>
    </ng-template>
</mat-menu>

<ng-template #hourTemplate let-mission>
    <div>
        {{ mission.flightStartAt | localizeDate : { timeStyle: "short" } }}
        -
        {{ mission.flightFinishAt | localizeDate : { timeStyle: "short" } }}
    </div>
    <p class="sub-value">
        <dtm-ui-time-diff-display [firstDate]="mission.flightStartAt" [secondDate]="mission.flightFinishAt"></dtm-ui-time-diff-display>
    </p>
</ng-template>

<ng-template #uavTemplate let-mission>
    <div>{{ mission.uavName }}</div>
    <div class="sub-value">{{ mission.uavSetupName }}</div>
</ng-template>

<ng-template #weatherTemplate let-weatherConditions>
    <div class="weather-conditions">
        <span>
            <dtm-ui-icon name="windy"></dtm-ui-icon>
            {{ "dtmWebAppMembership.missionsList.windSpeedLabel" | transloco : { speed: weatherConditions.windSpeed } }}
        </span>
        <span>
            <dtm-ui-icon name="temp-cold"></dtm-ui-icon>
            {{
                "dtmWebAppMembership.missionsList.airTemperatureInCelsiusLabel"
                    | transloco : { temperature: weatherConditions.airTemperature }
            }}
        </span>
        <span>
            <dtm-ui-icon name="rainy"></dtm-ui-icon>
            {{ weatherConditions.precipitationProbability }}%
        </span>
    </div>
</ng-template>

<ng-template #missingDataTemplate>
    <span class="no-data">{{ "dtmWebAppMembership.missionsList.missingDataLabel" | transloco }}</span>
</ng-template>
