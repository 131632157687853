<ng-container *ngrxLet="{ maxEntities: maxEntities$ } as vm">
    <h2>
        {{ "dtmWebAppLibMission.missionActionsHeader.routeCreatorTitle" | transloco }}
    </h2>
    <dtm-ui-popover *ngIf="helpTextVisibility$ | ngrxPush as helpTextVisibility">
        <p
            *ngIf="vm.maxEntities === 1"
            [innerHTML]="
            'dtmWebAppLibMission.missionActionsHeader.singleRegionPopoverText' | transloco : { radius: helpTextVisibility.defaultRadius,  }
        "
        ></p>
        <p
            *ngIf="vm.maxEntities > 1"
            [innerHTML]="
                'dtmWebAppLibMission.missionActionsHeader.multiRegionPopoverText'
                    | transloco : { radius: helpTextVisibility.defaultRadius, maxEntities: vm.maxEntities }
            "
        ></p>
        <p
            [innerHTML]="
                'dtmWebAppLibMission.missionActionsHeader.enlargedZonePopoverText'
                    | transloco : { radius: ENLARGED_RADIUS_WITH_STATEMENT_ACCEPTED }
            "
            *ngIf="helpTextVisibility.shouldShowEnlarged"
        ></p>
        <p
            [innerHTML]="
                'dtmWebAppLibMission.missionActionsHeader.bvlosObserverPopoverText' | transloco : { radius: MAX_RADIUS_WITH_UAV_OBSERVER }
            "
            *ngIf="helpTextVisibility.shouldShowMax"
        ></p>
    </dtm-ui-popover>
</ng-container>
