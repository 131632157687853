<mat-card *ngrxLet="pilot$ as pilot">
    <div class="pilot-operator-number-header">
        <dtm-ui-card-header>
            {{ "dtmWebAppLibPilotProfile.pilotNumber.pilotOperatorNumberHeader" | transloco }}
        </dtm-ui-card-header>
        <div class="header-badges">
            <span *ngIf="pilot.isSuperPilot" class="chip super-pilot">
                <dtm-ui-icon name="star"></dtm-ui-icon>
                {{ "dtmWebAppLibPilotProfile.pilotNumber.superPilotLabel" | transloco }}
            </span>
            <dtm-ui-operator-status-badge [status]="pilot.operator.status"></dtm-ui-operator-status-badge>
        </div>
    </div>
    <h6 class="header">{{ "dtmWebAppLibPilotProfile.pilotNumber.operatorSectionTitle" | transloco }}</h6>
    <div class="operator-number-details">
        <ng-container *ngrxLet="isSecretCodeVisible$ as isCodeVisible">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibPilotProfile.pilotNumber.operatorNumberLabel' | transloco"
                [labelHint]="'dtmWebAppLibPilotProfile.pilotNumber.operatorNumberLabelHint' | transloco"
                [value]="pilot.operator.operatorNumber.number"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmWebAppLibPilotProfile.pilotNumber.secureCodeLabel' | transloco"
                [labelHint]="'dtmWebAppLibPilotProfile.pilotNumber.secureCodeLabelHint' | transloco"
                [value]="!isCodeVisible ? '***' : pilot.operator.operatorNumber.secret"
            >
            </dtm-ui-label-value>
            <button
                type="button"
                class="action-button button-secondary"
                [cdkCopyToClipboard]="pilot.operator.operatorNumber.number"
                (cdkCopyToClipboardCopied)="handleValueCopy($event)"
            >
                <dtm-ui-icon [name]="'file-copy'"></dtm-ui-icon>
                {{ "dtmWebAppLibPilotProfile.pilotNumber.copyButtonLabel" | transloco }}
            </button>
            <button type="button" class="action-button button-secondary" (click)="secretCodeVisibilityChange()">
                <dtm-ui-icon [name]="!isCodeVisible ? 'eye' : 'eye-off'"></dtm-ui-icon>
                {{
                    !isCodeVisible
                        ? ("dtmWebAppLibPilotProfile.pilotNumber.showButtonLabel" | transloco)
                        : ("dtmWebAppLibPilotProfile.pilotNumber.hideButtonLabel" | transloco)
                }}
            </button>
        </ng-container>
    </div>
    <h6 class="header">{{ "dtmWebAppLibPilotProfile.pilotNumber.pilotSectionTitle" | transloco }}</h6>
    <div class="pilot-number-details" *ngIf="pilot.competencies?.length; else pilotNumberHintTemplate">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibPilotProfile.pilotNumber.pilotNumberLabel' | transloco"
            [labelHint]="'dtmWebAppLibPilotProfile.pilotNumber.pilotNumberLabelHint' | transloco"
            [value]="pilot.pilotNumber"
        >
        </dtm-ui-label-value>
        <button
            type="button"
            class="action-button button-secondary"
            [cdkCopyToClipboard]="pilot.pilotNumber"
            (cdkCopyToClipboardCopied)="handleValueCopy($event)"
        >
            <dtm-ui-icon [name]="'file-copy'"></dtm-ui-icon>
            {{ "dtmWebAppLibPilotProfile.pilotNumber.copyButtonLabel" | transloco }}
        </button>
    </div>
    <ng-container *ngIf="pilot.documentsToDownload.length">
        <h6 class="header documents-header">{{ "dtmWebAppLibPilotProfile.pilotNumber.documentsToDownloadLabel" | transloco }}</h6>

        <div class="documents-to-download" *ngFor="let document of pilot.documentsToDownload">
            <dtm-ui-icon class="diploma-icon" name="diploma"></dtm-ui-icon>

            <ng-container *ngFor="let documentDownloadType of PilotConfirmationDocumentType | keyvalue">
                <p class="download-confirmation" *ngIf="document === documentDownloadType.value">
                    {{
                        "dtmWebAppLibPilotProfile.pilotNumber.downloadDocumentLabel"
                            | transloco : { documentType: documentDownloadType.key }
                    }}
                </p>
            </ng-container>
            <button type="button" class="button-icon" (click)="documentDownload.emit(document)">
                <dtm-ui-icon name="download"></dtm-ui-icon>
            </button>
        </div>
    </ng-container>
</mat-card>

<ng-template #pilotNumberHintTemplate>
    <p class="pilot-number-hint">
        {{ "dtmWebAppLibPilotProfile.pilotNumber.pilotNumberHint" | transloco }}
    </p>
</ng-template>
