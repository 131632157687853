import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, first } from "rxjs";
import { AvailableUav, SinglePermission } from "../../../../../services/operator-permissions.models";

function requiredUavsValidator(possiblePermission: SinglePermission | undefined, availableUavs: AvailableUav[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || !possiblePermission) {
            return null;
        }

        const missingClasses = possiblePermission.allowedUavClasses.filter(
            (allowedClass) => !availableUavs.some(({ uavClasses }) => uavClasses?.includes(allowedClass))
        );
        const hasRequiredUavs = missingClasses.length === 0;

        return !hasRequiredUavs ? { requiredUavsMissing: missingClasses } : null;
    };
}

interface SinglePermissionControlComponentState {
    singlePermission: SinglePermission | undefined;
    availableUavs: AvailableUav[] | undefined;
    isPaymentFeatureAvailable: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-permissions-single-permission-control",
    templateUrl: "./single-permission-control.component.html",
    styleUrls: ["./single-permission-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SinglePermissionControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SinglePermissionControlComponent),
            multi: true,
        },
    ],
})
export class SinglePermissionControlComponent implements ControlValueAccessor, AfterViewInit, Validator {
    protected readonly singlePermission$ = this.localStore.selectByKey("singlePermission").pipe(RxjsUtils.filterFalsy());

    @Input() public set singlePermission(value: SinglePermission | undefined) {
        this.localStore.patchState({ singlePermission: value });
    }

    @Input() public set availableUavs(value: AvailableUav[] | undefined) {
        this.localStore.patchState({ availableUavs: value });
    }

    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: boolean) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    protected formControl = new FormControl<boolean>(false, { nonNullable: true });
    protected isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");

    constructor(private readonly localStore: LocalComponentStore<SinglePermissionControlComponentState>) {
        this.localStore.setState({
            singlePermission: undefined,
            isPaymentFeatureAvailable: false,
            availableUavs: undefined,
        });

        this.setupUavRequiredValidator();
    }

    public validate(): ValidationErrors | null {
        return this.formControl.errors;
    }

    public registerOnValidatorChange?(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public ngAfterViewInit(): void {
        this.listenToFormValueChanges();
    }

    public writeValue(value: boolean) {
        this.formControl.setValue(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    public toggleFormValue(event: MouseEvent): void {
        if (!this.formControl.disabled) {
            this.formControl.setValue(!this.formControl.value);
        }
        event.stopPropagation();
    }

    public registerOnChange(fn: (value: boolean) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    private listenToFormValueChanges(): void {
        this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.propagateChange(value);
        });
    }

    private setupUavRequiredValidator(): void {
        combineLatest([this.singlePermission$, this.localStore.selectByKey("availableUavs").pipe(RxjsUtils.filterFalsy())])
            .pipe(first(), untilDestroyed(this))
            .subscribe(([singlePermission, availableUavs]) => {
                this.formControl.setValidators([requiredUavsValidator(singlePermission, availableUavs)]);
            });
    }
}
