import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ConversationCategoryCode, ImageConverterService, OperatorsThread, ReceivedMessage } from "@dtm-frontend/shared/ui";
import { SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, switchMap, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { OPERATOR_CONVERSATIONS_ENDPOINTS, OperatorConversationsEndpoints } from "../operator-conversations.tokens";
import {
    AddNewMessageToThreadRequestPayload,
    GetMessagesByThreadResponseBody,
    GetOperatorMessageCapabilitiesResponseBody,
    GetOperatorsThreadsListResponseBody,
    NewThreadRequestPayload,
    convertGetMessagesByThreadResponseBodyToReceivedMessages,
    convertGetOperatorsThreadsListResponseBodyToGetOperatorsThreadsList,
    convertNewMessageToAddNewMessageToThreadRequestPayload,
    convertNewThreadToNewThreadRequestPayload,
} from "./operator-conversations-api.converters";
import {
    AddNewMessage,
    ConversationFilterFormKeys,
    NewThread,
    OperatorConversationFilterParams,
    OperatorThreadChange,
    ThreadsErrorType,
} from "./operator-conversations.model";

@Injectable({
    providedIn: "root",
})
export class OperatorConversationsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly imageConverterService: ImageConverterService,
        @Inject(OPERATOR_CONVERSATIONS_ENDPOINTS) private readonly endpoints: OperatorConversationsEndpoints
    ) {}

    public getThreadsList(filterParams: OperatorConversationFilterParams): Observable<OperatorsThread[]> {
        let params = new HttpParams().set("stakeholderId", filterParams.selectedOperatorContextId);

        if (filterParams[ConversationFilterFormKeys.SearchByText]?.length) {
            params = params.append("searchPhrase", filterParams[ConversationFilterFormKeys.SearchByText] as string);
        }

        if (filterParams[ConversationFilterFormKeys.Categories]?.length) {
            params = params.append("categories", filterParams[ConversationFilterFormKeys.Categories] as string);
        }

        return this.httpClient.get<GetOperatorsThreadsListResponseBody>(this.endpoints.getOrAddThreads, { params }).pipe(
            map((response) => convertGetOperatorsThreadsListResponseBodyToGetOperatorsThreadsList(response)),
            catchError(() => throwError(() => ({ type: ThreadsErrorType.Unknown })))
        );
    }

    public changeThread(threadId: string, payload: OperatorThreadChange) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.manageThread, { threadId }), {
                closed: payload.isClosed,
                read: payload.isRead,
            })
            .pipe(catchError(() => throwError(() => ({ type: ThreadsErrorType.Unknown }))));
    }

    public getMessagesByThread(threadId: string): Observable<ReceivedMessage[]> {
        return this.httpClient
            .get<GetMessagesByThreadResponseBody[]>(StringUtils.replaceInTemplate(this.endpoints.getOrAddMessages, { threadId }))
            .pipe(
                map((messages) => convertGetMessagesByThreadResponseBodyToReceivedMessages(messages)),
                catchError(() => throwError(() => ({ type: ThreadsErrorType.Unknown })))
            );
    }

    public getAvatarPicture(sourceUrl: string): Observable<string> {
        return this.httpClient
            .get(sourceUrl, {
                responseType: "blob",
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(switchMap((response) => this.imageConverterService.convertBlobToBase64(response)));
    }

    public getOperatorMessageCapabilities(operatorId: string): Observable<ConversationCategoryCode[]> {
        return this.httpClient
            .get<GetOperatorMessageCapabilitiesResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getOperatorMessageCapabilities, { operatorId })
            )
            .pipe(
                map((capabilities) => capabilities.categories),
                catchError(() => throwError({ type: ThreadsErrorType.Unknown }))
            );
    }

    public createNewThread(thread: NewThread): Observable<void> {
        const body: NewThreadRequestPayload = convertNewThreadToNewThreadRequestPayload(thread);

        return this.httpClient
            .post<void>(this.endpoints.getOrAddThreads, body)
            .pipe(catchError(() => throwError({ type: ThreadsErrorType.Unknown })));
    }

    public addNewMessageToThread(message: AddNewMessage): Observable<void> {
        const body: AddNewMessageToThreadRequestPayload = convertNewMessageToAddNewMessageToThreadRequestPayload(message);

        return this.httpClient.post<void>(
            StringUtils.replaceInTemplate(this.endpoints.getOrAddMessages, { threadId: message.threadId }),
            body
        );
    }
}
