<dtm-ui-filters-container *ngrxLet="filtersCount$ as filtersCount" [filtersCount]="filtersCount">
    <form [formGroup]="filtersForm">
        <div class="filters-container">
            <dtm-ui-input-field>
                <label>{{ "dtmWebAppMembership.filters.searchByTextLabel" | transloco }}</label>
                <input
                    matInput
                    type="text"
                    [formControl]="searchTextControl"
                    [placeholder]="'dtmWebAppMembership.filters.operatorsSearchByTextPlaceholder' | transloco"
                />
            </dtm-ui-input-field>
            <dtm-ui-select-field multiple [formControl]="statusControl">
                <label>{{ "dtmWebAppMembership.filters.statusLabel" | transloco }}</label>
                <dtm-ui-select-option [value]="MembershipStatus.Active">
                    {{ "dtmWebAppMembership.filters.statusValue" | transloco : { value: MembershipStatus.Active } }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="MembershipStatus.Pending">
                    {{ "dtmWebAppMembership.filters.statusValue" | transloco : { value: MembershipStatus.Pending } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
        </div>
    </form>

    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [formGroup]="filtersForm"
        [filtersValue]="filtersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="filtersCount"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</dtm-ui-filters-container>
