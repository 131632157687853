<h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.summaryStep.companyInfo.header" | transloco }}</h2>
<div class="details-container" *ngrxLet="companyInfo$ as companyInfo">
    <dtm-ui-label-value
        [label]="'dtmWebAppLibOperatorMigration.summaryStep.companyInfo.nameLabel' | transloco"
        [value]="companyInfo.name"
    ></dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibOperatorMigration.summaryStep.companyInfo.emailLabel' | transloco"
        [value]="companyInfo.email"
    ></dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibOperatorMigration.summaryStep.companyInfo.companyNumberLabel' | transloco"
        [value]="companyInfo.companyNumber"
    ></dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibOperatorMigration.summaryStep.companyInfo.phoneNumberLabel' | transloco"
        [value]="companyInfo.phone | formatImportedPhoneNumber"
    ></dtm-ui-label-value>
    <dtm-ui-address-label-value [address]="companyInfo.address"></dtm-ui-address-label-value>
</div>
