<mat-card *ngIf="account$ | ngrxPush as account; else emptyStateTemplate">
    <dtm-ui-card-header [isEditButtonVisible]="false">
        <div class="header">
            {{ "dtmWebAppLibPilotProfile.pansaUtmLink.header" | transloco }}
        </div>
        <ng-container additionalActions>
            <div class="status-info" [ngSwitch]="account.status">
                <span *ngSwitchCase="PansaUtmLinkStatus.Active" class="chip success">
                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                    {{ "dtmWebAppLibPilotProfile.pansaUtmLink.statusActiveLabel" | transloco }}
                </span>
                <ng-container *ngSwitchCase="PansaUtmLinkStatus.Error">
                    <dtm-ui-popover>
                        <div class="popover-header">{{ "dtmWebAppLibPilotProfile.pansaUtmLink.errorHeader" | transloco }}</div>
                        <p class="popover-content">{{ "dtmWebAppLibPilotProfile.pansaUtmLink.errorMessage" | transloco }}</p>
                    </dtm-ui-popover>
                    <span class="chip error">
                        <dtm-ui-icon name="error-warning"></dtm-ui-icon>
                        {{ "dtmWebAppLibPilotProfile.pansaUtmLink.statusErrorLabel" | transloco }}
                    </span>
                </ng-container>
            </div>
            <button [matMenuTriggerFor]="actions" class="button-icon" type="button" (click)="$event.stopPropagation()">
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
            <mat-menu #actions="matMenu">
                <button type="button" mat-menu-item (click)="edit.emit()">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmWebAppLibPilotProfile.pansaUtmLink.editButtonLabel" | transloco }}
                </button>
                <button type="button" mat-menu-item (click)="delete.emit()">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dtmWebAppLibPilotProfile.pansaUtmLink.deleteButtonLabel" | transloco }}
                </button>
            </mat-menu>
        </ng-container>
    </dtm-ui-card-header>
</mat-card>

<ng-template #emptyStateTemplate>
    <dtm-ui-empty-state>
        <ng-container titleSlot>
            {{ "dtmWebAppLibPilotProfile.pansaUtmLink.header" | transloco }}
        </ng-container>
        <p messageSlot>{{ "dtmWebAppLibPilotProfile.pansaUtmLink.emptyStateMessage" | transloco }}</p>
        <div footerSlot class="empty-state-footer">
            <a class="button-secondary" [href]="pansaUtmUrl" target="_blank" rel="noopener norefer">
                {{ "dtmWebAppLibPilotProfile.pansaUtmLink.registerLinkLabel" | transloco }}
            </a>
            <button type="button" class="button-primary" (click)="add.emit()">
                <dtm-ui-icon name="refresh"></dtm-ui-icon>
                {{ "dtmWebAppLibPilotProfile.pansaUtmLink.linkAccountButtonLabel" | transloco }}
            </button>
        </div>
    </dtm-ui-empty-state>
</ng-template>
