import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DEFAULT_LANG, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { AdditionalCrew, CrewMember, CrewTrainings, Training } from "../../../services/specific-permit-application.models";

interface CrewTrainingsComponentState {
    availableTrainings: Training[];
    additionalCrewMembers: CrewMember[];
    activeLanguage: LanguageCode;
    isExpanded: boolean;
    isEditMode: boolean;
    currentTrainings: CrewTrainings;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-crew-trainings[availableTrainings][additionalCrewMembers]",
    templateUrl: "./crew-trainings.component.html",
    styleUrls: ["./crew-trainings.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CrewTrainingsComponent), multi: true }],
})
export class CrewTrainingsComponent implements ControlValueAccessor {
    @Input() public set availableTrainings(value: Training[] | undefined) {
        this.localStore.patchState({ availableTrainings: value ?? [] });
    }

    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value ?? DEFAULT_LANG });
    }

    @Input() public set additionalCrewMembers(value: AdditionalCrew | null | undefined) {
        const additionalCrewMembers = (value ?? []).map((member) => member.member);
        const currentTrainings = this.localStore.selectSnapshotByKey("currentTrainings");
        const newCurrentTrainings = this.prepareCrewTrainings(additionalCrewMembers, currentTrainings);

        this.localStore.patchState({ additionalCrewMembers, currentTrainings: newCurrentTrainings });

        this.crewTrainingsForm.setValue({ trainings: newCurrentTrainings }, { emitEvent: false });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set value(value: CrewTrainings) {
        this.localStore.patchState({ currentTrainings: value });
    }

    protected readonly availableTrainings$ = this.localStore.selectByKey("availableTrainings");
    protected readonly activeLanguage$ = this.localStore.selectByKey("activeLanguage");
    protected readonly currentTrainings$ = this.localStore.selectByKey("currentTrainings");
    protected readonly isTrainingsSetEmpty$ = this.currentTrainings$.pipe(
        map((currentTrainings) => !Object.values(currentTrainings).some((trainings) => trainings !== null))
    );
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    protected readonly crewTrainingsForm = new FormGroup<{ trainings: FormControl<CrewTrainings> }>({
        trainings: new FormControl<CrewTrainings>({}, { nonNullable: true }),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: CrewTrainings) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<CrewTrainingsComponentState>) {
        this.localStore.setState({
            availableTrainings: [],
            additionalCrewMembers: [],
            activeLanguage: DEFAULT_LANG,
            isExpanded: true,
            isEditMode: false,
            currentTrainings: {},
        });
    }

    public registerOnChange(fn: (value: CrewTrainings) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: CrewTrainings): void {
        const additionalCrewMembers = this.localStore.selectSnapshotByKey("additionalCrewMembers");
        if (value) {
            this.crewTrainingsForm.setValue({ trainings: this.prepareCrewTrainings(additionalCrewMembers, value) }, { emitEvent: false });
        }

        this.persistCurrentTrainings(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.crewTrainingsForm.disable();
        } else {
            this.crewTrainingsForm.enable();
        }
    }

    protected setEditMode(isEditMode: boolean): void {
        this.localStore.patchState(({ isExpanded }) => ({ isEditMode, isExpanded: isEditMode ? true : isExpanded }));
    }

    protected cancel(): void {
        const currentTrainings = this.localStore.selectSnapshotByKey("currentTrainings");
        const additionalCrewMembers = this.localStore.selectSnapshotByKey("additionalCrewMembers");
        this.crewTrainingsForm.reset(
            { trainings: this.prepareCrewTrainings(additionalCrewMembers, currentTrainings) },
            { emitEvent: false }
        );
        this.setEditMode(false);
    }

    protected save(): void {
        this.crewTrainingsForm.markAllAsTouched();

        if (this.crewTrainingsForm.invalid) {
            return;
        }

        const currentTrainings = this.crewTrainingsForm.controls.trainings.getRawValue();
        this.persistCurrentTrainings(currentTrainings);
        this.propagateChange(currentTrainings);
        this.setEditMode(false);
    }

    private persistCurrentTrainings(value: CrewTrainings): void {
        this.localStore.patchState({
            currentTrainings: value ? value : {},
        });
    }

    private prepareCrewTrainings(crewMembers: CrewMember[], value: CrewTrainings): CrewTrainings {
        if (!crewMembers.length) {
            return {};
        }

        const result: CrewTrainings = {};

        for (const crewMember of crewMembers) {
            result[crewMember] = value[crewMember] ? value[crewMember] : null;
        }

        return result;
    }
}
