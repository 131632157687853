import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { OperatorPermitsActions } from "../state/operator-permits.actions";

@Injectable()
export class AssociationPermitsResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<void> {
        return this.store.dispatch(new OperatorPermitsActions.GetAssociationPermitDetails(route.params.permitId, route.params.operatorId));
    }
}
