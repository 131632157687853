<div class="header">
    <h2 class="title">
        <ng-content select="[titleSlot]"></ng-content>
    </h2>
    <button
        type="button"
        class="button-secondary action-button"
        *ngIf="!(isSelected$ | ngrxPush); else removeButtonTemplate"
        [disabled]="isDisabled$ | ngrxPush"
        (click)="isSelectedChange.next(true)"
    >
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.addButtonLabel" | transloco }}
    </button>

    <ng-template #removeButtonTemplate>
        <button type="button" class="button-secondary action-button" (click)="isSelectedChange.next(false)">
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.removeButtonLabel" | transloco }}
        </button>
    </ng-template>
</div>
