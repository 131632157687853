import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { saveAs } from "file-saver";
import { EMPTY, switchMap } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { AdministrativeFeeInStatementApiService } from "../services/administrative-fee-in-statement-api.service";
import { OperatorPermissionsApiService } from "../services/operator-permissions.api.service";
import {
    AvailableUav,
    OperatorPermissionsError,
    OperatorPermissionsErrorType,
    OperatorPermissionsOperatorDetails,
    OperatorStatement,
    OwnedOperatorPermission,
    PermissionUavDetails,
    PossiblePermissions,
    SavedStatement,
} from "../services/operator-permissions.models";
import { OperatorPermissionsActions } from "./operator-permissions.actions";

export interface OperatorPermissionsStateModel {
    ownedPermissionsListError: OperatorPermissionsError | undefined;
    ownedPermissionsList: OwnedOperatorPermission[] | undefined;
    isOwnedPermissionsListProcessing: boolean;
    possiblePermissions: PossiblePermissions | undefined;
    statementsWizardError: OperatorPermissionsError | undefined;
    isStatementProcessing: boolean;
    areUavsRequired: boolean;
    isAttorneyPowerAdministrativeFeeRequired: boolean;
    operatorDetails: OperatorPermissionsOperatorDetails | undefined;
    availableUavs: AvailableUav[] | undefined;
    availableUavsError: OperatorPermissionsError | undefined;
    statementsListPagination: Page | undefined;
    statementsList: OperatorStatement[] | undefined;
    statementsListError: OperatorPermissionsError | undefined;
    isStatementsListProcessing: boolean;
    savedStatement: SavedStatement | undefined;
    saveStatementError: OperatorPermissionsError | undefined;
    paymentRedirectUrl: string | undefined;
    savedStatementId: string | undefined;
    repeatPaymentError: OperatorPermissionsError | undefined;
    signatureRedirectUrl: string | undefined;
    signatureRedirectError: OperatorPermissionsError | undefined;
    permissionUavDetails: PermissionUavDetails | undefined;
    permissionUavDetailsError: OperatorPermissionsError | undefined;
    getStatementInPdfError: OperatorPermissionsError | undefined;
    operatorStatementAdministrativeFeeDocumentError: OperatorPermissionsError | undefined;
    isStatementSignSuccess: boolean | undefined;
}

const defaultState: OperatorPermissionsStateModel = {
    ownedPermissionsListError: undefined,
    ownedPermissionsList: undefined,
    isOwnedPermissionsListProcessing: false,
    possiblePermissions: undefined,
    statementsWizardError: undefined,
    isStatementProcessing: false,
    areUavsRequired: false,
    isAttorneyPowerAdministrativeFeeRequired: false,
    operatorDetails: undefined,
    availableUavs: undefined,
    availableUavsError: undefined,
    statementsListPagination: undefined,
    statementsList: undefined,
    statementsListError: undefined,
    isStatementsListProcessing: false,
    savedStatement: undefined,
    saveStatementError: undefined,
    paymentRedirectUrl: undefined,
    savedStatementId: undefined,
    repeatPaymentError: undefined,
    signatureRedirectUrl: undefined,
    signatureRedirectError: undefined,
    permissionUavDetails: undefined,
    permissionUavDetailsError: undefined,
    getStatementInPdfError: undefined,
    operatorStatementAdministrativeFeeDocumentError: undefined,
    isStatementSignSuccess: undefined,
};

@State<OperatorPermissionsStateModel>({
    name: "operatorPermissions",
    defaults: defaultState,
})
@Injectable()
export class OperatorPermissionsState {
    constructor(
        private readonly operatorPermissionsApi: OperatorPermissionsApiService,
        private readonly administrativeFeeInStatementApiService: AdministrativeFeeInStatementApiService,
        private readonly translocoService: TranslocoService
    ) {}

    @Selector()
    public static ownedPermissionsListError(state: OperatorPermissionsStateModel): OperatorPermissionsError | undefined {
        return state.ownedPermissionsListError;
    }

    @Selector()
    public static ownedPermissionsList(state: OperatorPermissionsStateModel): OwnedOperatorPermission[] | undefined {
        return state.ownedPermissionsList;
    }

    @Selector()
    public static isOwnedPermissionsListProcessing(state: OperatorPermissionsStateModel): boolean {
        return state.isOwnedPermissionsListProcessing;
    }

    @Selector()
    public static statementsListError(state: OperatorPermissionsStateModel): OperatorPermissionsError | undefined {
        return state.statementsListError;
    }

    @Selector()
    public static statementsList(state: OperatorPermissionsStateModel): OperatorStatement[] | undefined {
        return state.statementsList;
    }
    @Selector()
    public static statementsListPagination(state: OperatorPermissionsStateModel): Page | undefined {
        return state.statementsListPagination;
    }

    @Selector()
    public static isStatementsListProcessing(state: OperatorPermissionsStateModel): boolean {
        return state.isStatementsListProcessing;
    }

    @Selector()
    public static possiblePermissions(state: OperatorPermissionsStateModel): PossiblePermissions | undefined {
        return state.possiblePermissions;
    }

    @Selector()
    public static statementsWizardError(state: OperatorPermissionsStateModel): OperatorPermissionsError | undefined {
        return state.statementsWizardError;
    }

    @Selector()
    public static isStatementProcessing(state: OperatorPermissionsStateModel): boolean {
        return state.isStatementProcessing;
    }

    @Selector()
    public static areUavsRequired(state: OperatorPermissionsStateModel): boolean {
        return state.areUavsRequired;
    }

    @Selector()
    public static operatorDetails(state: OperatorPermissionsStateModel): OperatorPermissionsOperatorDetails | undefined {
        return state.operatorDetails;
    }

    @Selector()
    public static availableUavs(state: OperatorPermissionsStateModel): AvailableUav[] | undefined {
        return state.availableUavs;
    }

    @Selector()
    public static savedStatement(state: OperatorPermissionsStateModel): SavedStatement | undefined {
        return state.savedStatement;
    }

    @Selector()
    public static saveStatementError(state: OperatorPermissionsStateModel): OperatorPermissionsError | undefined {
        return state.saveStatementError;
    }

    @Selector()
    public static paymentRedirectUrl(state: OperatorPermissionsStateModel): string | undefined {
        return state.paymentRedirectUrl;
    }

    @Selector()
    public static signatureRedirectUrl(state: OperatorPermissionsStateModel): string | undefined {
        return state.signatureRedirectUrl;
    }
    @Selector()
    public static permissionUavDetails(state: OperatorPermissionsStateModel): PermissionUavDetails | undefined {
        return state.permissionUavDetails;
    }

    @Selector()
    public static permissionUavDetailsError(state: OperatorPermissionsStateModel): OperatorPermissionsError | undefined {
        return state.permissionUavDetailsError;
    }

    @Selector()
    public static getStatementInPdfError(state: OperatorPermissionsStateModel): OperatorPermissionsError | undefined {
        return state.getStatementInPdfError;
    }

    @Selector()
    public static isAttorneyPowerAdministrativeFeeRequired(state: OperatorPermissionsStateModel): boolean {
        return state.isAttorneyPowerAdministrativeFeeRequired;
    }

    @Selector()
    public static operatorStatementAdministrativeFeeDocumentError(
        state: OperatorPermissionsStateModel
    ): OperatorPermissionsError | undefined {
        return state.operatorStatementAdministrativeFeeDocumentError;
    }
    @Selector()
    public static isStatementSignSuccess(state: OperatorPermissionsStateModel): boolean | undefined {
        return state.isStatementSignSuccess;
    }

    @Action(OperatorPermissionsActions.GetOwnedPermissionsList, { cancelUncompleted: true })
    public getOwnedPermissionsList(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.GetOwnedPermissionsList
    ) {
        context.patchState({
            isOwnedPermissionsListProcessing: true,
            ownedPermissionsList: undefined,
        });

        return this.operatorPermissionsApi.getPermissionsList(action.operatorId).pipe(
            tap((result) =>
                context.patchState({
                    ownedPermissionsList: result,
                    ownedPermissionsListError: undefined,
                    isOwnedPermissionsListProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({ ownedPermissionsListError: error, isOwnedPermissionsListProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorPermissionsActions.GetStatementsList, { cancelUncompleted: true })
    public getStatementsList(context: StateContext<OperatorPermissionsStateModel>, action: OperatorPermissionsActions.GetStatementsList) {
        context.patchState({
            isStatementsListProcessing: true,
        });

        return this.operatorPermissionsApi.getStatementsList(action.params).pipe(
            tap((result) =>
                context.patchState({
                    statementsList: result.content,
                    statementsListError: undefined,
                    isStatementsListProcessing: false,
                    statementsListPagination: result.pages,
                })
            ),
            catchError((error) => {
                context.patchState({ statementsListError: error, isStatementsListProcessing: false, statementsListPagination: undefined });

                return EMPTY;
            })
        );
    }

    @Action(OperatorPermissionsActions.GetOperatorPermissionsCapabilities, { cancelUncompleted: true })
    public getOperatorPermissionsCapabilities(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.GetOperatorPermissionsCapabilities
    ) {
        context.patchState({
            isStatementProcessing: true,
            possiblePermissions: undefined,
            operatorDetails: undefined,
            statementsWizardError: undefined,
            isAttorneyPowerAdministrativeFeeRequired: false,
        });

        return this.operatorPermissionsApi.getOperatorPermissionsCapabilities(action.operatorId).pipe(
            tap((result) =>
                context.patchState({
                    possiblePermissions: {
                        operationalAuthorizations: result.operationalAuthorizations,
                        availableOperationScenarios: result.availableOperationScenarios,
                    },
                    operatorDetails: result.operator,
                    availableUavs: result.operatorUavs,
                    isAttorneyPowerAdministrativeFeeRequired: result.isAttorneyPowerAdministrativeFeeRequired,
                    isStatementProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({ statementsWizardError: error, isStatementProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorPermissionsActions.SetUavsRequiredState)
    public setUavsRequired(context: StateContext<OperatorPermissionsStateModel>, action: OperatorPermissionsActions.SetUavsRequiredState) {
        context.patchState({
            areUavsRequired: action.areUavsRequired,
        });
    }

    @Action(OperatorPermissionsActions.SaveStatementAndPay)
    public saveStatementAndPay(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.SaveStatementAndPay
    ) {
        context.patchState({
            paymentRedirectUrl: undefined,
            savedStatement: undefined,
            saveStatementError: undefined,
            isStatementProcessing: true,
        });

        return this.operatorPermissionsApi.saveStatement(action.operatorId, action.statement).pipe(
            tap((savedStatement) => context.patchState({ savedStatement })),
            switchMap((savedStatement) => this.operatorPermissionsApi.payForStatement(action.operatorId, savedStatement.id)),
            tap((paymentRedirectUrl) => context.patchState({ paymentRedirectUrl })),
            catchError((error) => {
                context.patchState({ saveStatementError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isStatementProcessing: false }))
        );
    }

    @Action(OperatorPermissionsActions.SaveStatement)
    public saveStatement(context: StateContext<OperatorPermissionsStateModel>, action: OperatorPermissionsActions.SaveStatement) {
        context.patchState({
            savedStatement: undefined,
            saveStatementError: undefined,
            isStatementProcessing: true,
        });

        return this.operatorPermissionsApi.saveStatement(action.operatorId, action.statement).pipe(
            tap((savedStatement) => context.patchState({ savedStatement })),
            catchError((error) => {
                context.patchState({ saveStatementError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isStatementProcessing: false }))
        );
    }

    @Action(OperatorPermissionsActions.GetStatementInPdf)
    public getStatementInPdf(context: StateContext<OperatorPermissionsStateModel>, action: OperatorPermissionsActions.GetStatementInPdf) {
        context.patchState({ getStatementInPdfError: undefined });

        return this.operatorPermissionsApi.getStatementInPdf(action.operatorId, action.statements, action.fileName).pipe(
            catchError((error) => {
                context.patchState({ getStatementInPdfError: error });

                return EMPTY;
            })
        );
    }

    @Action(OperatorPermissionsActions.GetSavedStatement)
    public getSavedStatement(context: StateContext<OperatorPermissionsStateModel>, action: OperatorPermissionsActions.GetSavedStatement) {
        context.patchState({ savedStatement: undefined, saveStatementError: undefined, isStatementProcessing: true });

        return this.operatorPermissionsApi.getSavedStatement(action.operatorId, action.statementId).pipe(
            tap((savedStatement) => context.patchState({ savedStatement })),
            catchError((error) => {
                context.patchState({ saveStatementError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isStatementProcessing: false }))
        );
    }

    @Action(OperatorPermissionsActions.GetOperatorStatementAdministrativeFeeDocument)
    public getOperatorStatementAdministrativeFeeDocument(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.GetOperatorStatementAdministrativeFeeDocument
    ) {
        context.patchState({ operatorStatementAdministrativeFeeDocumentError: undefined, isStatementProcessing: true });

        return this.administrativeFeeInStatementApiService.getFile(action.feeDocumentId).pipe(
            tap((administrativeFeeDocument) =>
                saveAs(
                    administrativeFeeDocument,
                    this.translocoService.translate("dtmWebAppLibOperatorPermissions.statementsList.administrativeFeeDocumentName")
                )
            ),
            catchError(() => {
                context.patchState({
                    operatorStatementAdministrativeFeeDocumentError: {
                        type: OperatorPermissionsErrorType.CannotGetOperatorStatementAdministrativeFeeDocument,
                    },
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isStatementProcessing: false }))
        );
    }

    @Action(OperatorPermissionsActions.CreatePaymentRedirectUrl)
    public createPaymentRedirectUrl(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.CreatePaymentRedirectUrl
    ) {
        context.patchState({ repeatPaymentError: undefined, isStatementProcessing: true, paymentRedirectUrl: undefined });

        return this.operatorPermissionsApi.createPaymentRedirectUrl(action.operatorId, action.statementId).pipe(
            tap((paymentRedirectUrl) => context.patchState({ paymentRedirectUrl })),
            catchError((error) => {
                context.patchState({ repeatPaymentError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isStatementProcessing: false }))
        );
    }

    @Action(OperatorPermissionsActions.CreateSignatureRedirectUrl)
    public createSignatureRedirectUrl(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.CreatePaymentRedirectUrl
    ) {
        context.patchState({ signatureRedirectError: undefined, isStatementProcessing: true, signatureRedirectUrl: undefined });

        return this.operatorPermissionsApi.createSignatureRedirectUrl(action.operatorId, action.statementId).pipe(
            tap((signatureRedirectUrl) => context.patchState({ signatureRedirectUrl })),
            catchError((error) => {
                context.patchState({ signatureRedirectError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isStatementProcessing: false }))
        );
    }

    @Action(OperatorPermissionsActions.GetUavDetailsForPermission)
    public getUavDetailsForPermission(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.GetUavDetailsForPermission
    ) {
        context.patchState({
            permissionUavDetails: undefined,
            permissionUavDetailsError: undefined,
            isOwnedPermissionsListProcessing: true,
        });

        return this.operatorPermissionsApi.getUavDetailsForPermission(action.operatorId, action.permissionId).pipe(
            tap((uavDetailsForPermission) => context.patchState({ permissionUavDetails: uavDetailsForPermission })),
            catchError((error) => {
                context.patchState({ permissionUavDetailsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isOwnedPermissionsListProcessing: false }))
        );
    }

    @Action(OperatorPermissionsActions.GetPermissionSignatureStatus)
    public getPermissionSignatureStatus(
        context: StateContext<OperatorPermissionsStateModel>,
        action: OperatorPermissionsActions.GetPermissionSignatureStatus
    ) {
        context.patchState({
            isStatementSignSuccess: undefined,
        });

        return this.operatorPermissionsApi.getPermissionSignatureStatus(action.operatorId, action.statementId).pipe(
            tap(() => context.patchState({ isStatementSignSuccess: true })),
            catchError(() => {
                context.patchState({ isStatementSignSuccess: false });

                return EMPTY;
            })
        );
    }
}
