<div class="section" [class.details-mode]="vm.isDetailsMode" *ngrxLet="{ missionPlan: missionPlan$, isDetailsMode: isDetailsMode$ } as vm">
    <dtm-web-app-lib-plan-badge-group
        class="badge-group"
        [remarks]="vm.missionPlan?.remarks"
        [missionType]="vm.missionPlan?.flightType"
        [phase]="vm.isDetailsMode ? vm.missionPlan?.phase : undefined"
    ></dtm-web-app-lib-plan-badge-group>

    <dtm-ui-input-field *ngIf="!vm.isDetailsMode" [isClearable]="false">
        <label class="mission-name">{{ "dtmWebAppLibShared.mission.nameLabel" | transloco }}</label>
        <input matInput type="text" required [formControl]="missionNameFormControl" />
        <div class="field-error" *dtmUiFieldHasError="missionNameFormControl; name: 'required'">
            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="missionNameFormControl; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibShared.mission.nameMaxLengthError"
                    | transloco
                        : {
                              maxLength: error.requiredLength,
                              exceedValue: error.actualLength - error.requiredLength
                          }
            }}
        </div>
    </dtm-ui-input-field>
    <div class="row" *ngIf="vm.missionPlan">
        <ng-container
            *ngTemplateOutlet="
                (vm.missionPlan.category | invoke : isSoraApplication) ||
                vm.missionPlan.phase === MissionProcessingPhase.CaaPermitApplication
                    ? caaPermitTemplate
                    : defaultTemplate
            "
        ></ng-container>
        <ng-template #caaPermitTemplate>
            <div class="column">
                <dtm-web-app-lib-mission-date-range
                    [missionDates]="{ startDate: vm.missionPlan.flightStartAtMin, endDate: vm.missionPlan.flightFinishAtMax }"
                ></dtm-web-app-lib-mission-date-range>
            </div>
        </ng-template>
        <ng-template #defaultTemplate>
            <div class="column">
                <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                <span class="time-label">
                    {{ vm.missionPlan.flightStartAtMin | localizeDate : { day: "numeric", month: "numeric" } }}
                </span>
                <div class="additional-info">{{ vm.missionPlan.flightStartAtMin | localizeDate : { weekday: "long" } }}</div>
            </div>
            <div class="column">
                <dtm-ui-icon name="time"></dtm-ui-icon>
                <p class="time-label">
                    {{ vm.missionPlan.flightStartAtMin | localizeDate : { timeStyle: "short" } }}
                    -
                    {{ vm.missionPlan.flightFinishAtMax | localizeDate : { timeStyle: "short" } }}
                </p>
                <p class="additional-info">
                    <dtm-ui-time-diff-display
                        [firstDate]="vm.missionPlan.flightStartAtMin"
                        [secondDate]="vm.missionPlan.flightFinishAtMax"
                    ></dtm-ui-time-diff-display>
                </p>
            </div>
        </ng-template>
        <button class="button-icon" type="button" (click)="mainMissionZoom.emit()">
            <dtm-ui-icon name="road-map"></dtm-ui-icon>
        </button>
    </div>
</div>
