import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { DateUtils, ISO8601TimeDuration, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable, map } from "rxjs";

interface DistanceAndTimeDisplayComponentState {
    currentPlanRoute: MissionPlanRoute | undefined;
}

@Component({
    selector: "dtm-web-app-lib-mwiesip-distance-and-time-display[currentPlanRoute]",
    templateUrl: "./distance-and-time-display.component.html",
    styleUrls: ["./distance-and-time-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DistanceAndTimeDisplayComponent {
    @Input() public set currentPlanRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ currentPlanRoute: value });
    }

    protected readonly currentPlanDistanceAndTime$ = this.initCurrentPlanDistanceAndTimeObservable();

    constructor(private readonly localStore: LocalComponentStore<DistanceAndTimeDisplayComponentState>) {
        localStore.setState({
            currentPlanRoute: undefined,
        });
    }

    private initCurrentPlanDistanceAndTimeObservable(): Observable<{ distance: number; time?: ISO8601TimeDuration } | undefined> {
        return this.localStore.selectByKey("currentPlanRoute").pipe(
            map((currentPlanRoute) => {
                if (!currentPlanRoute) {
                    return undefined;
                }

                let distance = 0;
                let timeSeconds = 0;

                for (let index = 0; index < currentPlanRoute.sections.length; index++) {
                    const section = currentPlanRoute.sections[index];
                    if (!section.segment) {
                        continue;
                    }

                    const durationSeconds = section.segment.duration
                        ? DateUtils.convertISO8601DurationToSeconds(section.segment.duration.min)
                        : 0;

                    distance += section.segment.distance ?? 0;
                    timeSeconds += durationSeconds ?? 0;
                }

                return {
                    distance,
                    time: timeSeconds ? DateUtils.convertSecondsToISO8601Duration(timeSeconds) : undefined,
                };
            })
        );
    }
}
