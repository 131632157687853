<div class="grid wrapper">
    <dtm-ui-wizard-step-wrapper
        [stepNumber]="2"
        [stepsAmount]="2"
        [title]="'dtmWebAppLibUav.newUavWizardSteps.primarySetup.header' | transloco"
        (next)="goToNext()"
        (back)="back.emit()"
        [isNextStepProcessing]="isProcessing$ | ngrxPush"
        [nextButtonLabel]="'dtmWebAppLibUav.newUavWizardSteps.primarySetup.addUavButtonLabel' | transloco"
    >
        <div class="intro-message" *ngIf="isIntroMessageVisible$ | ngrxPush" @fade>
            <dtm-ui-info-message type="info">
                <div>
                    <p>{{ "dtmWebAppLibUav.newUavWizardSteps.primarySetup.introMessage" | transloco }}</p>
                    <button type="button" class="close-intro-message-button button-icon" (click)="closeIntroMessage()">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                </div>
            </dtm-ui-info-message>
        </div>

        <dtm-web-app-lib-custom-uav-setup-form
            [setupDefaults]="setupDefaults$ | ngrxPush"
            [trackers]="trackers$ | ngrxPush"
            [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
            [isNameDisabled]="true"
            [uavType]="uavType$ | ngrxPush"
            (setupChange)="primarySetupChange($event)"
        ></dtm-web-app-lib-custom-uav-setup-form>
    </dtm-ui-wizard-step-wrapper>
</div>
