import { TemplatePortal } from "@angular/cdk/portal";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Optional,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { DeviceSizeService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MissionWizardContentComponent } from "../../content/mission-wizard-content.component";

interface MissionWizardStepWrapperComponentState {
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    title: string | undefined;
    nextButtonLabel: string;
    isNextButtonEnabled: boolean;
    isBackButtonEnabled: boolean;
    isBackButtonVisible: boolean;
    isLoading: boolean;
    stepId: string;
}

@Component({
    selector: "dtm-web-app-lib-mission-wizard-step-wrapper[stepNumber][title][nextButtonLabel][stepsAmount]",
    templateUrl: "./step-wrapper.component.html",
    styleUrls: ["./step-wrapper.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardStepWrapperComponent implements AfterViewInit {
    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }

    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }

    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value });
    }

    @Input() public set nextButtonLabel(value: string | undefined) {
        this.localStore.patchState({ nextButtonLabel: value ?? "" });
    }

    @Input() public set isNextButtonEnabled(value: boolean | undefined) {
        this.localStore.patchState({ isNextButtonEnabled: value ?? true });
    }

    @Input() public set isBackButtonEnabled(value: boolean | undefined) {
        this.localStore.patchState({ isBackButtonEnabled: value ?? true });
    }

    @Input() public set isBackButtonVisible(value: boolean) {
        this.localStore.patchState({ isBackButtonVisible: value });
    }

    @Input() public set isLoading(value: boolean) {
        this.localStore.patchState({ isLoading: value });
    }

    @Input() public set stepId(value: string) {
        this.localStore.patchState({ stepId: value });
    }

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly next = new EventEmitter<void>();

    @HostBinding("class.card-box") public cardBox = true;

    public readonly stepNumber$ = this.localStore.selectByKey("stepNumber").pipe(RxjsUtils.filterFalsy());
    public readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount").pipe(RxjsUtils.filterFalsy());
    public readonly title$ = this.localStore.selectByKey("title").pipe(RxjsUtils.filterFalsy());
    public readonly nextButtonLabel$ = this.localStore.selectByKey("nextButtonLabel");
    public readonly isNextButtonEnabled$ = this.localStore.selectByKey("isNextButtonEnabled");
    public readonly isBackButtonEnabled$ = this.localStore.selectByKey("isBackButtonEnabled");
    public readonly isBackButtonVisible$ = this.localStore.selectByKey("isBackButtonVisible");
    public readonly isLoading$ = this.localStore.selectByKey("isLoading");

    @ViewChild("footerTemplate") public footerTemplate!: TemplateRef<unknown>;

    constructor(
        private readonly localStore: LocalComponentStore<MissionWizardStepWrapperComponentState>,
        private readonly deviceSizeService: DeviceSizeService,
        private viewContainerRef: ViewContainerRef,
        @Optional() private readonly missionWizardContentComponent: MissionWizardContentComponent
    ) {
        this.localStore.setState({
            stepNumber: undefined,
            stepsAmount: 5,
            title: undefined,
            nextButtonLabel: "",
            isNextButtonEnabled: true,
            isBackButtonEnabled: true,
            isBackButtonVisible: true,
            isLoading: false,
            stepId: "",
        });
    }

    public ngAfterViewInit() {
        const templatePortal = new TemplatePortal(this.footerTemplate, this.viewContainerRef);
        this.missionWizardContentComponent?.footerTemplate.set(this.localStore.selectSnapshotByKey("stepId"), templatePortal);
    }
}
