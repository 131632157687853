import { BasicQualification, OperatorStatus } from "@dtm-frontend/shared/ui";
import { OperatorPilotOtherInformation } from "../../shared/models/pilot-and-operator.models";

export enum PublicProfileErrorType {
    CannotGetPilotData = "CannotGetPilotData",
    CannotGetOperatorData = "CannotGetOperatorData",
    NotFound = "NotFound",
}

export interface PublicProfileError {
    type: PublicProfileErrorType;
}

export interface PublicProfileBasicDataModel {
    status: OperatorStatus;
    number: string;
    otherInformation: OperatorPilotOtherInformation[];
}

export interface PublicProfileDetails extends PublicProfileBasicDataModel {
    qualifications: BasicQualification[];
    certificates?: PublicProfileCertificate[];
}

export interface PublicProfileCertificate {
    name: string;
    expirationDate: Date;
}

export enum PublicProfileType {
    Pilot = "PILOT",
    Operator = "OPERATOR",
}
