import { Address, IdentityDocumentType, PhoneNumber, UploadedFile } from "@dtm-frontend/shared/ui";
import { ForeignOperatorVerificationDetails, RegisterPilotOperatorLegalGuardian } from "../../shared/models";

export interface RegisterOperatorBasicData {
    address: Address;
    isRegisteredInOtherCountry: boolean;
    companyName: string;
    email: string;
    phoneNumber: PhoneNumber;
    companyNumber?: string;
    foreignOperator?: ForeignOperatorVerificationDetails;
    identityDocument?: UploadedFile[];
    identityDocumentType?: IdentityDocumentType;
    identityDocumentExpirationDate?: Date;
    associationNationalCourtRegister?: string;
    associationRegistrationNumber?: string;
}

export interface RegisterOperatorAdditionalData {
    attorneyPower: AttorneyPowerInOperatorRegistration;
    insurancePolicyNumber?: string;
    insurancePolicyExpirationDate?: Date;
    legalGuardian?: RegisterPilotOperatorLegalGuardian;
}

export interface AttorneyPowerInOperatorRegistration {
    type: AttorneyPowerType;
    nationalCourtRegister?: string;
    document?: UploadedFile[];
    feeConfirmation?: UploadedFile[];
    isDeclarationOfExemption?: boolean;
}

export interface RegisterOperatorSummaryData {
    isAuthenticityOfData: boolean;
    isGeneralDataProtectionRegulation: boolean;
}

export interface OperatorRegistrationError {
    type: OperatorRegistrationErrorType;
}

export enum OperatorRegistrationErrorType {
    Unknown = "Unknown",
}

export interface OperatorRegistrationFormValue extends RegisterOperatorBasicData, RegisterOperatorAdditionalData {}

export interface RegisteredOperator {
    id: string;
    status: string;
    operatorNumber: string;
    ownerUserId: string;
    name: string;
    email: string;
    phoneNumber: PhoneNumber;
    companyNumber?: string;
    insurancePolicyNumber: string;
    insurancePolicyExpirationDate: string;
    address: Address;
    attorneyPower: {
        id: string;
        type: AttorneyPowerType;
        status: AttorneyPowerStatus;
        attorneyPowerAdministrativeFee?: {
            isDeclarationOfExemption: boolean;
            feeConfirmationId?: string;
        };
    };
}

export enum AttorneyPowerStatus {
    PendingApproval = "PENDING_APPROVAL",
    Approved = "APPROVED",
}

export enum AttorneyPowerType {
    Owner = "OWNER",
    NationalCourtNumber = "NATIONAL_COURT_NUMBER",
    ConfirmatoryDocument = "CONFIRMATORY_DOCUMENT",
}

export enum OperatorRegistrationType {
    Association = "Association",
    Enterprise = "Enterprise",
}
