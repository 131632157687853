<dtm-ui-wizard-step-wrapper
    (next)="validateFormAndSave()"
    [title]="'dtmWebAppLibOperatorMigration.authenticationStep.header' | transloco"
    [stepsAmount]="3"
    [stepNumber]="1"
    [isBackButtonVisible]="false"
    dtmUiInvalidFormScrollable
>
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
        <dtm-ui-info-message type="warning">
            {{ "dtmWebAppLibOperatorMigration.authenticationStep.infoMessage" | transloco }}
        </dtm-ui-info-message>
        <div class="form" [formGroup]="authenticationForm">
            <dtm-ui-input-field>
                <label>{{ "dtmWebAppLibOperatorMigration.authenticationStep.emailLabel" | transloco }}</label>
                <input formControlName="email" matInput type="text" />
                <div class="field-error" *dtmUiFieldHasError="authenticationForm.controls.email; name: 'email'">
                    {{ "dtmWebAppLibOperatorMigration.authenticationStep.incorrectEmailError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="authenticationForm.controls.email; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibOperatorMigration.authenticationStep.requiredFieldError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="authenticationForm.controls.email; name: OperatorMigrationErrorType.OperatorAlreadyImported"
                >
                    {{ "dtmWebAppLibOperatorMigration.authenticationStep.isOperatorAlreadyImportedError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="authenticationForm.controls.email; name: OperatorMigrationErrorType.WrongCredentials"
                >
                    {{ "dtmWebAppLibOperatorMigration.authenticationStep.wrongCredentialsError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="authenticationForm.controls.email; name: OperatorMigrationErrorType.UnapprovedOperator"
                >
                    {{ "dtmWebAppLibOperatorMigration.authenticationStep.unapprovedOperatorError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="authenticationForm.controls.email; name: OperatorMigrationErrorType.NotFound">
                    {{ "dtmWebAppLibOperatorMigration.authenticationStep.operatorNotFoundError" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field [isClearable]="false" [class.password-error]="authenticationForm.controls.password.errors">
                <label>{{ "dtmWebAppLibOperatorMigration.authenticationStep.passwordLabel" | transloco }}</label>
                <input #password matInput type="password" formControlName="password" />
                <dtm-ui-password-visibility-switch [inputParent]="password" class="field-suffix"> </dtm-ui-password-visibility-switch>
                <div class="field-error" *dtmUiFieldHasError="authenticationForm.controls.password; name: 'required'">
                    {{ "dtmWebAppLibOperatorMigration.authenticationStep.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-input-field>
        </div>
        <div class="reset-password-container">
            <button type="button" class="button-tertiary" (click)="resetPasswordStepRedirect.emit()">
                {{ "dtmWebAppLibOperatorMigration.authenticationStep.resetPasswordButtonLabel" | transloco }}
            </button>
        </div>
    </dtm-ui-loader-container>
</dtm-ui-wizard-step-wrapper>
