import { DashboardMissionsFilters } from "../services/dashboard.models";

export namespace DashboardActions {
    export class GetPosts {
        public static readonly type = "[Dashboard] Get posts";
    }

    export class GetMissions {
        public static readonly type = "[Dashboard] Get missions";

        constructor(public operatorId: string, public params?: DashboardMissionsFilters) {}
    }
}
