<ng-container *ngrxLet="{ availableTrainings: availableTrainings$, activeLanguage: activeLanguage$ } as vm" [formGroup]="crewTrainingsForm">
    <ng-container formArrayName="crewMembers">
        <section
            *ngFor="let crewMember of crewFormArray.controls; index as crewMemberIndex"
            [formGroupName]="crewMemberIndex"
            class="crew-member"
        >
            <div class="header-wrapper">
                <h2>{{ "mission.additional_crew_members." + crewMember.value.role + ".name" | systemTranslation }}</h2>
                <button
                    *ngIf="crewMember.controls.areTrainingsSet.value"
                    type="button"
                    class="button-secondary"
                    (click)="removeTrainings(crewMemberIndex)"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.removeCrewMemberTrainingsButtonLabel" | transloco }}
                </button>
                <button
                    *ngIf="!crewMember.controls.areTrainingsSet.value"
                    type="button"
                    class="button-secondary"
                    (click)="addTrainings(crewMemberIndex)"
                >
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.addCrewMemberTrainingsButtonLabel" | transloco }}
                </button>
            </div>
            <dtm-web-app-lib-spec-perm-app-trainings-form
                *ngIf="crewMember.controls.areTrainingsSet.value"
                [formControl]="crewMember.controls.trainings"
                [availableTrainings]="vm.availableTrainings"
                [activeLanguage]="vm.activeLanguage"
            >
            </dtm-web-app-lib-spec-perm-app-trainings-form>
        </section>
    </ng-container>
</ng-container>
