<span class="last-update">
    {{
        "dtmWebAppLibOpsMan.tableOfContents.lastUpdateDateLabel"
            | transloco : { date: lastUpdateDate$ | ngrxPush | localizeDate : { dateStyle: "short", timeStyle: "short" } }
    }}
</span>
<cdk-tree class="tree" [dataSource]="treeDataSource$" [treeControl]="treeControl">
    <cdk-nested-tree-node *cdkTreeNodeDef="let chapter" class="node" [class.selected]="isChapterSelected(chapter)">
        <button type="button" class="node-action" (click)="trySelectChapter(chapter)">
            {{ chapter.name }}
        </button>
    </cdk-nested-tree-node>
    <cdk-nested-tree-node *cdkTreeNodeDef="let chapter; when: hasSubchapters" class="node" [class.selected]="isChapterSelected(chapter)">
        <button type="button" class="node-action" (click)="trySelectChapter(chapter)">
            {{ chapter.name }}
        </button>
        <div *ngIf="treeControl.isExpanded(chapter)" @slideIn>
            <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
    </cdk-nested-tree-node>
</cdk-tree>
