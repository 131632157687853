import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { FILES_UPLOAD_API_PROVIDER, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { DetachedSerialNumbersComponent } from "./components/detached-serial-numbers/detached-serial-numbers.component";
import { OwnedPermissionsListComponent } from "./components/owned-permissions-list/owned-permissions-list.component";
import { PermissionUavDetailsComponent } from "./components/permission-uav-details/permission-uav-details.component";
import { PermissionsAndStatementsContainerComponent } from "./components/permissions-and-statements-container/permissions-and-statements-container.component";
import { StatementsFilterComponent } from "./components/statements-filter/statements-filter.component";
import { StatementsListComponent } from "./components/statements-list/statements-list.component";
import { StatementsWizardContentComponent } from "./components/statements-wizard/content/statements-wizard-content.component";
import { StatementsWizardHeaderComponent } from "./components/statements-wizard/header/statements-wizard-header.component";
import { AllowedUavClassesNamesPipe } from "./components/statements-wizard/pipes/allowed-uav-classes-names.pipe";
import { GainingPermissionsStepComponent } from "./components/statements-wizard/steps/gaining-permissions-step/gaining-permissions-step.component";
import { SinglePermissionControlComponent } from "./components/statements-wizard/steps/gaining-permissions-step/single-permission-control/single-permission-control.component";
import { PaymentForStatementComponent } from "./components/statements-wizard/steps/payment-for-statement/payment-for-statement.component";
import { ScenarioItemComponent } from "./components/statements-wizard/steps/scenario-item/scenario-item.component";
import { SignStatementStatusComponent } from "./components/statements-wizard/steps/sign-statement-status/sign-statement-status.component";
import { SignaturePermissionComponent } from "./components/statements-wizard/steps/signature-permission/signature-permission.component";
import { StatementSignatureComponent } from "./components/statements-wizard/steps/signature/statement-signature.component";
import { SummaryPermissionComponent } from "./components/statements-wizard/steps/summary-permission/summary-permission.component";
import { SummaryComponent } from "./components/statements-wizard/steps/summary/summary.component";
import { UavInformationStepComponent } from "./components/statements-wizard/steps/uav-information-step/uav-information-step.component";
import { OPERATOR_PERMISSIONS_ENDPOINTS } from "./operator-permissions.tokens";
import { AdministrativeFeeInStatementApiService } from "./services/administrative-fee-in-statement-api.service";
import { OperatorPermissionsApiService } from "./services/operator-permissions.api.service";
import { OperatorPermissionsState } from "./state/operator-permissions.state";

@NgModule({
    declarations: [
        PermissionsAndStatementsContainerComponent,
        OwnedPermissionsListComponent,
        StatementsWizardContentComponent,
        StatementsWizardHeaderComponent,
        GainingPermissionsStepComponent,
        SinglePermissionControlComponent,
        UavInformationStepComponent,
        ScenarioItemComponent,
        StatementsListComponent,
        SummaryComponent,
        SummaryPermissionComponent,
        StatementSignatureComponent,
        SignaturePermissionComponent,
        PaymentForStatementComponent,
        SignStatementStatusComponent,
        StatementsFilterComponent,
        PermissionUavDetailsComponent,
        DetachedSerialNumbersComponent,
        AllowedUavClassesNamesPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgxsModule.forFeature([OperatorPermissionsState]),
        RouterModule,
        SharedUiModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedAuthModule,
        SharedWebAppModule,
        MatCardModule,
        MatChipsModule,
        MatTabsModule,
        MatTableModule,
        MatMenuModule,
        BrowserAnimationsModule,
        MatInputModule,
        WizardModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatDialogModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibOperatorPermissions",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: OperatorPermissionsApiService,
            useValue: undefined,
        },
        AdministrativeFeeInStatementApiService,
    ],
    exports: [
        OwnedPermissionsListComponent,
        StatementsWizardContentComponent,
        StatementsWizardHeaderComponent,
        GainingPermissionsStepComponent,
        SinglePermissionControlComponent,
        UavInformationStepComponent,
        ScenarioItemComponent,
        StatementsListComponent,
        SummaryComponent,
        StatementSignatureComponent,
        SignaturePermissionComponent,
        SignStatementStatusComponent,
        StatementsFilterComponent,
        PermissionUavDetailsComponent,
    ],
})
export class OperatorPermissionsModule {
    public static forRoot(): ModuleWithProviders<OperatorPermissionsModule> {
        return {
            ngModule: OperatorPermissionsModule,
            providers: [
                {
                    provide: OperatorPermissionsApiService,
                    useClass: OperatorPermissionsApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<OperatorPermissionsModule> {
        return {
            ngModule: OperatorPermissionsModule,
            providers: [
                {
                    provide: OperatorPermissionsApiService,
                    useValue: null,
                },
                { provide: FILES_UPLOAD_API_PROVIDER, useValue: null },
                {
                    provide: OPERATOR_PERMISSIONS_ENDPOINTS,
                    useValue: { uploadPassportFile: "", uploadAdministrativeFeeFile: "" },
                },
            ],
        };
    }
}
