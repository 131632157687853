<ng-container
    *ngrxLet="{
        settings: settings$,
        isProcessing: isProcessing$,
        currentPlanRoute: currentPlanRoute$,
        itineraryContent: itineraryContent$,
        itineraryEditorFormData: itineraryEditorFormData$,
        constraints: constraints$,
        isDisabled: isDisabled$
    } as vm"
>
    <ng-container [ngSwitch]="itineraryEditorType$ | ngrxPush" *ngIf="vm.settings">
        <dtm-web-app-lib-track-details
            *ngSwitchCase="ItineraryEditorType.Custom"
            [itineraryContent]="vm.itineraryContent"
            [constraints]="vm.constraints"
            [currentPlanRoute]="vm.currentPlanRoute"
            [settings]="vm.settings"
            [isProcessing]="vm.isProcessing"
            [isDisabled]="vm.isDisabled"
            [displayMode]="DEFAULT_TRACK_DETAILS_DISPLAY_MODE"
            (entityAdd)="entityAdd.emit($event)"
            (entityDelete)="entityDelete.emit($event)"
            (entityUpdate)="entityUpdate.emit($event)"
            (pointDelete)="pointDelete.emit($event)"
            (entityMetadataUpdate)="entityMetadataUpdate.emit($event)"
        ></dtm-web-app-lib-track-details>
        <dtm-web-app-lib-assisted-editor-itinerary-card
            *ngSwitchCase="ItineraryEditorType.Assisted"
            [constraints]="vm.constraints"
            [itineraryContent]="vm.itineraryContent"
            (entityUpdate)="entityUpdate.emit($event)"
            (entityAdd)="entityAdd.emit($event)"
            (zoomToEntity)="zoomToEntity.emit($event)"
        ></dtm-web-app-lib-assisted-editor-itinerary-card>
        <dtm-web-app-lib-standard-editor-itinerary-card
            *ngSwitchCase="ItineraryEditorType.Standard"
            (entityAdd)="entityAdd.emit($event)"
            (entityDelete)="entityDelete.emit($event)"
            (pointDelete)="pointDelete.emit($event)"
            (entityUpdate)="entityUpdate.emit($event)"
            [constraints]="vm.constraints"
            [isProcessing]="vm.isProcessing"
            [currentPlanRoute]="vm.currentPlanRoute"
            [settings]="vm.settings"
            [itineraryContent]="vm.itineraryContent"
            [itineraryEditorFormData]="itineraryEditorFormData$ | ngrxPush"
            [isDisabled]="vm.isDisabled"
        ></dtm-web-app-lib-standard-editor-itinerary-card>
        <ng-container *ngSwitchDefault>
            <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
                <img imageSlot src="assets/images/itinerary-panel-empty.svg" alt="" />
                <p messageSlot>
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.emptyStateMessage" | transloco }}
                </p>
            </dtm-ui-empty-state>
        </ng-container>
    </ng-container>
</ng-container>
