import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngxs/store";
import { OperatorContextState } from "../../../../shared";
import {
    REGISTER_PILOT_WIZARD_ID,
    RegisterPilotWizardSteps,
} from "../register-pilot-wizard-content/register-pilot-wizard-content.component";

@Component({
    selector: "dtm-web-app-lib-register-pilot-wizard-header",
    templateUrl: "./register-pilot-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPilotWizardHeaderComponent {
    public readonly wizardId = REGISTER_PILOT_WIZARD_ID;
    public readonly RegisterPilotWizardSteps = RegisterPilotWizardSteps;
    public readonly isProcessing$ = this.store.select(OperatorContextState.isProcessing);

    constructor(private readonly store: Store) {}
}
