export enum OperationsManualErrorType {
    Unknown = "OperationsManualErrorUnknown",
    InvalidOperator = "InvalidOperator",
    InvalidOperationsManual = "InvalidOperationsManual",
    CannotGetCapabilities = "CannotGetCapabilities",
    CannotCreateOperationsManual = "CannotCreateOperationsManual",
    CannotGetTableOfContents = "CannotGetTableOfContents",
    CannotPublish = "CannotPublish",
    CannotDownloadPdf = "CannotDownloadPdf",
    CannotGetChapter = "CannotGetChapter",
    CannotSaveChapter = "CannotSaveChapter",
    CannotSetAttachments = "CannotSetAttachments",
    CannotAddSubchapter = "CannotAddSubchapter",
    CannotRemoveSubchapter = "CannotRemoveSubchapter",
}

export enum ChapterType {
    Html = "HTML",
    SubchaptersContent = "SUBCHAPTERS_CONTENT",
    ExtendableSubchaptersList = "EXTENDABLE_SUBCHAPTERS_LIST",
}

export enum ChapterAttachmentType {
    // TODO: Final types list will be implemented in REJ-1396
    SomeDocument = "SOME_DOCUMENT",
    FooBar = "FOO_BAR",
    Other = "OTHER",
}

export interface OperationsManualError {
    type: OperationsManualErrorType;
}

export interface OperationsManualVersion {
    id: string;
    version: number;
    isPublished: boolean;
    lastUpdate: Date;
}

export interface Capabilities {
    permissions: {
        canCreate: boolean;
        canEdit: boolean;
        canPublish: boolean;
    };
    availableContentsTableFilters: string[];
    operationsManuals: OperationsManualVersion[];
}

export interface TableOfContentsChapter {
    id: string;
    name: string;
    subchapters: TableOfContentsChapter[];
}

export interface TableOfContents {
    lastUpdateDate: Date;
    chapters: TableOfContentsChapter[];
}

export interface TableOfContentsChapters {
    chapters: TableOfContentsChapter[];
    initiallySelectedChapterId?: string;
}

export interface ChapterAttachmentsConfig {
    type: ChapterAttachmentType;
    formats: string[];
    maxAmount: number;
}

export interface ChapterAttachment {
    id: string;
    type: ChapterAttachmentType;
    name: string;
    size: number;
}

export interface Chapter {
    id: string;
    name: string;
    guide: string;
    type: ChapterType;
    content: string | undefined;
    subchapters: Chapter[];
    isEditable: boolean;
    attachmentsFeature: {
        isEnabled: boolean;
        config: ChapterAttachmentsConfig[];
    };
    attachments: ChapterAttachment[];
}

export interface HtmlChapter extends Chapter {
    type: ChapterType.Html;
}

export interface ChapterWithSubchapters extends Chapter {
    type: ChapterType.SubchaptersContent;
    subchapters: HtmlChapter[];
}

export interface ExtendableChapter extends Chapter {
    type: ChapterType.ExtendableSubchaptersList;
}

export interface ContentEditData {
    chapter: Chapter;
    content: string | null;
}
