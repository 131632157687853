<div class="grid">
    <mat-card *ngrxLet="activeTabIndex$ as activeTabIndex">
        <dtm-web-app-lib-training-exam-results-header
            [activeTabIndex]="activeTabIndex"
            (tabIndexUpdate)="updateTabIndex($event)"
            [elearningPortalUrl]="elearningPortalUrl$ | ngrxPush"
        >
            <ng-container trainingList>
                <dtm-web-app-lib-training-exam-results-filters
                    [initialFilters]="trainingResultsFilters$ | ngrxPush"
                    (filtersChange)="navigateByParams($event, TrainingExamResultsTabType.Trainings)"
                ></dtm-web-app-lib-training-exam-results-filters>

                <dtm-web-app-lib-training-exam-results-list
                    [resultsList]="trainingResultsList$ | ngrxPush"
                    [pagination]="trainingResultsPages$ | ngrxPush"
                    [resultsListError]="trainingResultsListError$ | ngrxPush"
                    [isResultsListProcessing]="isTrainingResultsListProcessing$ | ngrxPush"
                    [activeTabIndex]="activeTabIndex"
                    (pageChange)="changeResultsPage($event, TrainingExamResultsFiltersTabKeys.TrainingResultsFilters)"
                    (resultsListRefresh)="getTrainingResultsList()"
                ></dtm-web-app-lib-training-exam-results-list>
            </ng-container>

            <ng-container examList>
                <dtm-web-app-lib-training-exam-results-filters
                    [initialFilters]="examResultsFilters$ | ngrxPush"
                    (filtersChange)="navigateByParams($event, TrainingExamResultsTabType.Exams)"
                ></dtm-web-app-lib-training-exam-results-filters>

                <dtm-web-app-lib-training-exam-results-list
                    [resultsList]="examResultsList$ | ngrxPush"
                    [pagination]="examResultsPages$ | ngrxPush"
                    [resultsListError]="examResultsListError$ | ngrxPush"
                    [isResultsListProcessing]="isExamResultsListProcessing$ | ngrxPush"
                    [activeTabIndex]="activeTabIndex"
                    (pageChange)="changeResultsPage($event, TrainingExamResultsFiltersTabKeys.ExamResultsFilters)"
                    (resultsListRefresh)="getExamResultsList()"
                ></dtm-web-app-lib-training-exam-results-list>
            </ng-container>
        </dtm-web-app-lib-training-exam-results-header>
    </mat-card>
</div>
