<mat-card *ngrxLet="attorneyPowers$ as attorneyPowers">
    <ng-container *ngrxLet="companyName$ as companyName">
        <ng-container *ngIf="showForm$ | ngrxPush; else listTemplate">
            <ng-container *ngIf="!(capabilitiesError$ | ngrxPush); else capabilitiesErrorTemplate">
                <dtm-web-app-lib-attorney-power-form
                    [attorneyPowers]="attorneyPowers"
                    [isCapabilitiesProcessing]="isCapabilitiesProcessing$ | ngrxPush"
                    [capabilities]="capabilities$ | ngrxPush"
                    [userId]="userId$ | ngrxPush"
                    (formSave)="formSave.emit($event)"
                    (attorneyPowerTemplateDownload)="attorneyPowerTemplateDownload.emit()"
                    (formClose)="changeAttorneyPowerFormVisibility(false)"
                >
                </dtm-web-app-lib-attorney-power-form>
            </ng-container>
        </ng-container>

        <ng-template #listTemplate>
            <dtm-web-app-lib-attorney-power-list
                *ngIf="attorneyPowers$ | ngrxPush as attorneyPowers; else emptyStateTemplate"
                [attorneyPowers]="attorneyPowers"
                [companyName]="companyName"
                (formInit)="changeAttorneyPowerFormVisibility(true)"
                (remove)="attorneyPowerRemove.emit($event)"
            >
            </dtm-web-app-lib-attorney-power-list>
        </ng-template>

        <ng-template #emptyStateTemplate>
            <dtm-ui-loader-container
                *ngrxLet="isAttorneyPowerProcessing$ as isAttorneyPowerProcessing"
                [isShown]="isAttorneyPowerProcessing"
            >
                <dtm-web-app-lib-attorney-power-empty-state
                    [companyName]="companyName"
                    (attorneyPowerFormVisibilityChange)="changeAttorneyPowerFormVisibility($event)"
                >
                </dtm-web-app-lib-attorney-power-empty-state>
            </dtm-ui-loader-container>
        </ng-template>
    </ng-container>
</mat-card>

<ng-template #capabilitiesErrorTemplate>
    <dtm-ui-error (reload)="getCapabilities.emit()"> </dtm-ui-error>
</ng-template>
