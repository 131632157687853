import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { EmptyStateMode, ErrorMode, GlobalOperatorPermissions, MIN_PAGE_SIZE_VALUE } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../../../../shared/operator-context/state/operator-context.state";
import { PermitsFilters } from "../../../../services/operator-permits.models";
import { OperatorPermitsActions } from "../../../../state/operator-permits.actions";
import { OperatorPermitsState } from "../../../../state/operator-permits.state";

interface PermitsDraftsContainerComponentState {
    specificPermitDraftsFilters: PermitsFilters;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-permits-drafts-container",
    templateUrl: "./permits-drafts-container.component.html",
    styleUrls: ["./permits-drafts-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermitsDraftsContainerComponent {
    protected readonly specificPermitDraftsList$ = this.store.select(OperatorPermitsState.specificPermitDraftsList);
    protected readonly specificPermitDraftsListPages$ = this.store.select(OperatorPermitsState.specificPermitDraftsListPages);
    protected readonly specificPermitApplicationDraftListError$ = this.store.select(OperatorPermitsState.specificPermitDraftsListError);
    protected readonly specificPermitDraftsFilters$ = this.localStore.selectByKey("specificPermitDraftsFilters");
    protected readonly areSpecificPermitsDraftsFiltersApplied$ = this.localStore
        .selectByKey("specificPermitDraftsFilters")
        .pipe(map((filters) => !!filters.searchPhrase));
    protected readonly isSpecificPermitDraftsListProcessing$ = this.store.select(OperatorPermitsState.isSpecificPermitDraftsListProcessing);
    private readonly selectedOperatorContext$ = this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy());

    protected readonly ErrorMode = ErrorMode;
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<PermitsDraftsContainerComponentState>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {
        localStore.setState({
            specificPermitDraftsFilters: {
                searchPhrase: route.snapshot.queryParams.searchPhrase ?? null,
                pageNumber: 0,
                pageSize: MIN_PAGE_SIZE_VALUE,
            },
        });
        this.watchSelectedOperatorContextChange();

        this.specificPermitDraftsFilters$.pipe(untilDestroyed(this)).subscribe((filters) => {
            this.router.navigate(["operator-permits/drafts"], {
                queryParams: { searchPhrase: filters.searchPhrase ?? null },
                queryParamsHandling: "merge",
            });
            this.fetchSpecificPermitsDraftsList();
        });
    }

    protected specificPermitDraftsFiltersChange(filters: PermitsFilters) {
        this.localStore.patchState({ specificPermitDraftsFilters: { ...filters, pageNumber: 0 } });
    }

    protected specificPermitDraftsPageChange(pageEvent: PageEvent) {
        this.localStore.patchState(({ specificPermitDraftsFilters }) => ({
            specificPermitDraftsFilters: {
                ...specificPermitDraftsFilters,
                pageNumber: pageEvent.pageIndex,
                pageSize: pageEvent.pageSize,
            },
        }));
    }

    private clearFilters(): void {
        const specificPermitDraftsFilters = {
            searchPhrase: null,
            pageNumber: 0,
            pageSize: MIN_PAGE_SIZE_VALUE,
        };
        this.localStore.patchState({ specificPermitDraftsFilters });
    }

    protected navigateToSpecificPermits(): void {
        this.router.navigateByUrl("/specific-permit/application-creator");
    }

    protected removeDraft(draftId: string) {
        this.store
            .dispatch(new OperatorPermitsActions.RemoveDraft(draftId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorPermitsState.draftRemoveError);

                if (error) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.removeDraftError"));

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.removeDraftSuccess"));
                this.fetchSpecificPermitsDraftsList();
            });
    }

    private fetchSpecificPermitsDraftsList(): void {
        const specificPermitDraftsFilters = this.localStore.selectSnapshotByKey("specificPermitDraftsFilters");
        const params = {
            ...specificPermitDraftsFilters,
            operatorId: this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId),
        };
        this.store.dispatch(new OperatorPermitsActions.GetSpecificPermitDraftsList(params));
    }

    private watchSelectedOperatorContextChange(): void {
        this.selectedOperatorContext$.pipe(untilDestroyed(this)).subscribe(() => {
            this.clearFilters();
            if (
                this.store.selectSnapshot(
                    OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorSpecificPermitsCreateApplication)
                )
            ) {
                this.fetchSpecificPermitsDraftsList();
            }
        });
    }
}
