<dtm-web-app-lib-uav-property
    *ngrxLet="value$ as value"
    [label]="label$ | ngrxPush"
    [description]="description$ | ngrxPush"
    [isEmpty]="value === undefined"
>
    <span class="wrapper">
        <dtm-ui-icon [name]="value ? 'checkbox-circle' : 'close-circle'"></dtm-ui-icon>
        {{ "dtmWebAppLibUav.uavProperties.booleanValue" | transloco : { value } }}
    </span>
</dtm-web-app-lib-uav-property>
