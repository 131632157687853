import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { LEGAL_GUARDIAN_DETAILS_ENDPOINTS, LegalGuardianDetailsEndpoints } from "../legal-guardian-details.tokens";
import {
    GetLegalGuardianGeneralDataResponseBody,
    convertGetLegalGuardianGeneralDataResponseBodyToLegalGuardianGeneralData,
    generatePdfRequestPayload,
    saveLegalGuardianFormRequestPayload,
} from "./legal-guardian-api.converters";
import { LegalGuardianDetailsErrorType, LegalGuardianFormValue, LegalGuardianGeneralData } from "./legal-guardian.models";

@Injectable()
export class LegalGuardianDetailsApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(LEGAL_GUARDIAN_DETAILS_ENDPOINTS) private readonly endpoints: LegalGuardianDetailsEndpoints
    ) {}

    public getLegalGuardianGeneralData(requestId: string): Observable<LegalGuardianGeneralData> {
        return this.httpClient
            .get<GetLegalGuardianGeneralDataResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getLegalGuardianGeneralData, { requestId })
            )
            .pipe(
                map((response) => convertGetLegalGuardianGeneralDataResponseBodyToLegalGuardianGeneralData(response)),
                catchError(() => throwError(() => ({ type: LegalGuardianDetailsErrorType.Unknown })))
            );
    }

    public generatePdf(formValue: LegalGuardianFormValue, legalGuardian: LegalGuardianGeneralData): Observable<Blob> {
        return this.httpClient
            .post(this.endpoints.generatePdf, generatePdfRequestPayload(formValue, legalGuardian), { responseType: "blob" })
            .pipe(
                tap((blob: Blob) => saveAs(blob, "file")),
                catchError(() => throwError(() => ({ type: LegalGuardianDetailsErrorType.CannotGeneratePdf })))
            );
    }

    public sendForElectronicSignature(model: LegalGuardianFormValue, requestId: string): Observable<string> {
        return this.httpClient
            .post<{ redirectUrl: string }>(this.endpoints.sendForSignature, saveLegalGuardianFormRequestPayload(model, requestId))
            .pipe(
                map((response) => response.redirectUrl),
                catchError(() => throwError(() => ({ type: LegalGuardianDetailsErrorType.CannotSendForElectronicSignature })))
            );
    }

    public verifyLegalGuardianSignature(requestId: string): Observable<void> {
        return this.httpClient
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.verifyLegalGuardianSignature, { requestId }), {})
            .pipe(catchError(() => throwError(() => ({ type: LegalGuardianDetailsErrorType.CannotVerifyElectronicSignature }))));
    }
}
