<p class="field-label"><ng-content select="[titleSlot]"></ng-content></p>

<div
    class="cards"
    *ngrxLet="{ robustnessLevels: robustnessLevels$, robustnessLevelValue: robustnessLevelValue$, disabledLevels: disabledLevels$ } as vm"
>
    <dtm-web-app-lib-mission-risk-mitigation-level
        [isSelected]="vm.robustnessLevelValue === vm.robustnessLevels[0]"
        [popupText]="(robustnessLevelFirstPopupText$ | ngrxPush) ?? ''"
        [isDisabled]="vm.disabledLevels | invoke : isLevelDisabled : vm.robustnessLevels[0]"
        [description]="robustnessLevelFirstDescription$ | ngrxPush"
        [text]="
            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel'
                | transloco : { value: vm.robustnessLevels[0] }
        "
        (riskMitigationLevelSelect)="setRobustnessLevel(vm.robustnessLevels[0])"
    >
    </dtm-web-app-lib-mission-risk-mitigation-level>

    <dtm-web-app-lib-mission-risk-mitigation-level
        [isSelected]="vm.robustnessLevelValue === vm.robustnessLevels[1]"
        [popupText]="(robustnessLevelSecondPopupText$ | ngrxPush) ?? ''"
        [isDisabled]="vm.disabledLevels | invoke : isLevelDisabled : vm.robustnessLevels[1]"
        [description]="robustnessLevelSecondDescription$ | ngrxPush"
        [text]="
            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel'
                | transloco : { value: vm.robustnessLevels[1] }
        "
        (riskMitigationLevelSelect)="setRobustnessLevel(vm.robustnessLevels[1])"
    >
    </dtm-web-app-lib-mission-risk-mitigation-level>

    <dtm-web-app-lib-mission-risk-mitigation-level
        [isSelected]="vm.robustnessLevelValue === vm.robustnessLevels[2]"
        [popupText]="(robustnessLevelThirdPopupText$ | ngrxPush) ?? ''"
        [isDisabled]="vm.disabledLevels | invoke : isLevelDisabled : vm.robustnessLevels[2]"
        [description]="robustnessLevelThirdDescription$ | ngrxPush"
        [text]="
            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel'
                | transloco : { value: vm.robustnessLevels[2] }
        "
        (riskMitigationLevelSelect)="setRobustnessLevel(vm.robustnessLevels[2])"
    >
    </dtm-web-app-lib-mission-risk-mitigation-level>
</div>
