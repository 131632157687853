import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { EMPTY } from "rxjs";
import { USER_PROFILE_ENDPOINTS, UserProfileEndpoints } from "../user-profile.tokens";

@Injectable()
export class UserProfileApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(USER_PROFILE_ENDPOINTS) private readonly endpoints: UserProfileEndpoints
    ) {}

    public uploadFile() {
        return EMPTY;
    }

    public getFile(documentId: string) {
        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.getAttorneyPowerDocument, { documentId }), {
            responseType: "blob",
        });
    }

    public getFilesCompressed() {
        return EMPTY;
    }

    public manageUploadError(): { type: FileUploadErrorType } {
        return { type: FileUploadErrorType.Unknown };
    }
}
