import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { WeatherActions } from "@dtm-frontend/shared/map/geo-weather";
import {
    ArrayUtils,
    HOURS_IN_DAY,
    LocalComponentStore,
    MILLISECONDS_IN_SECOND,
    MINUTES_IN_HOUR,
    RxjsUtils,
    SECONDS_IN_HOUR,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Observable, skip, tap } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { Mission } from "../../models/tactical.models";
import { TacticalActions } from "../../state/tactical.actions";
import { TacticalState } from "../../state/tactical.state";
import { AcceptedMissionTileComponent } from "./accepted-mission-tile/accepted-mission-tile.component";

interface PrepareForMissionComponentState {
    selectedMission: Mission | undefined;
}

interface PartitionedMissionsList {
    todayActiveMissions: Mission[];
    futureActiveMissions: Mission[];
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-prepare-for-mission",
    templateUrl: "./prepare-for-mission.component.html",
    styleUrls: ["./prepare-for-mission.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PrepareForMissionComponent implements OnDestroy, OnInit, AfterViewInit {
    @ViewChildren(AcceptedMissionTileComponent, { read: ElementRef })
    private readonly missionTiles!: QueryList<ElementRef<HTMLElement>>;

    private readonly acceptedMissions$ = this.store.select(TacticalState.acceptedMissions).pipe(
        map((missions) => [...missions].sort((left, right) => left.startTime.min.getTime() - right.startTime.max.getTime())),
        tap((missions) => {
            const selectedMissionId = this.route.snapshot.queryParams.id;
            if (selectedMissionId) {
                this.selectMission(missions.find(({ missionId }) => missionId === selectedMissionId));
            }
        })
    );
    protected readonly partitionedMissions$ = this.initPartitionMissionList();
    protected readonly selectedMission$ = this.localStore.selectByKey("selectedMission");
    protected readonly isListProcessing$ = this.store.select(TacticalState.isListProcessing);

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<PrepareForMissionComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {
        this.localStore.setState({ selectedMission: undefined });
        this.watchOperatorChangesAndUpdateMissionList();
        this.store.dispatch(TacticalActions.StartMissionsUpdatesWatch);
    }

    public ngOnInit(): void {
        this.store.dispatch(TacticalActions.StartAcceptedMissionsUpdatesWatch);
        this.initMissionIdChangeHandle();
    }

    public ngAfterViewInit(): void {
        const missionId = this.route.snapshot.queryParams.id;
        if (missionId) {
            const missionElement = this.missionTiles.find((element) => element.nativeElement.dataset.missionId === missionId);
            missionElement?.nativeElement.scrollIntoView({ block: "center" });
        }
    }

    public ngOnDestroy(): void {
        this.store.dispatch([
            TacticalActions.CleanupAcceptedMissions,
            TacticalActions.StopAcceptedMissionsUpdatesWatch,
            TacticalActions.StopMissionsUpdatesWatch,
            WeatherActions.ResetWeatherState,
        ]);
    }
    protected selectMission(selectedMission?: Mission) {
        this.router.navigate(["/tactical/prepare-for-mission"], {
            queryParams: { id: selectedMission?.missionId },
            replaceUrl: true,
        });
    }

    protected selectedGroupIndex(
        mission: Mission | undefined,
        { todayActiveMissions, futureActiveMissions }: { todayActiveMissions: Mission[]; futureActiveMissions: Mission[] }
    ): number {
        if (mission && todayActiveMissions.findIndex((item) => item.missionId === mission.missionId) > -1) {
            return 0;
        }

        if (mission && futureActiveMissions.findIndex((item) => item.missionId === mission.missionId) > -1) {
            return 1;
        }

        if (todayActiveMissions.length) {
            return 0;
        }

        if (futureActiveMissions.length) {
            return 1;
        }

        return 0;
    }

    protected trackMissionById(index: number, mission: Mission) {
        return mission.missionId;
    }

    private initMissionIdChangeHandle() {
        this.route.queryParams
            .pipe(
                map(({ id }) => {
                    const missionList = this.store.selectSnapshot(TacticalState.acceptedMissions);
                    const error = this.store.selectSnapshot(TacticalState.tacticalError);

                    if (error) {
                        return;
                    }

                    return {
                        selectedMission: missionList.find((mission) => mission.missionId === id),
                        missionList,
                    };
                }),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe(({ selectedMission, missionList }) => this.handleMissionSelection(selectedMission, missionList));
    }

    private handleMissionSelection(selectedMission: Mission | undefined, missionList: Mission[]) {
        this.localStore.patchState({ selectedMission });
        this.store.dispatch([TacticalActions.CleanupMissionRoute, TacticalActions.CleanupMissionPlanData]);

        if (selectedMission && missionList?.length) {
            this.store.dispatch([
                new TacticalActions.GetMissionWithRoute(selectedMission.missionId),
                new TacticalActions.GetMissionPlanData(selectedMission.planId),
            ]);

            return;
        }

        const sortedMissionList = [...missionList].sort((left, right) => left.startTime.min.getTime() - right.startTime.max.getTime());
        this.selectMission(sortedMissionList[0]);
    }

    private initPartitionMissionList(): Observable<PartitionedMissionsList> {
        return this.acceptedMissions$.pipe(
            map((missions) => ArrayUtils.partition<Mission>(missions, this.checkIsMissionToday)),
            map(([todayActiveMissions, futureActiveMissions]: Mission[][]) => ({
                todayActiveMissions,
                futureActiveMissions,
            }))
        );
    }

    private checkIsMissionToday(mission: Mission): boolean {
        const endOfDay = new Date();
        endOfDay.setUTCHours(HOURS_IN_DAY - 1, MINUTES_IN_HOUR - 1, SECONDS_IN_HOUR - 1, MILLISECONDS_IN_SECOND - 1);

        return mission.startTime.min < endOfDay;
    }

    private watchOperatorChangesAndUpdateMissionList() {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy(), skip(1), untilDestroyed(this))
            .subscribe(() => this.store.dispatch(TacticalActions.GetAcceptedMissions));
    }
}
