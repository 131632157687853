import { MissionProcessingPhaseExtended } from "@dtm-frontend/shared/mission";
import { Address, Alpha3CountryCode, Page, PhoneNumber } from "@dtm-frontend/shared/ui";
import { PilotCompetencies } from "../../shared/models/mission.models";
import { OPERATOR_CONTEXT_PARAM } from "../../shared/operator-context/models/operator-context.models";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "./consts";

export interface MembershipUserInvitation {
    operatorId: string;
    note?: string;
    recipient: {
        email?: string;
        phoneNumber?: PhoneNumber;
    };
    permissions: MembershipPermission[];
}

export interface MembershipError {
    type: MembershipErrorType;
}

export enum MembershipErrorType {
    Unknown = "Unknown",
    CouldNotGet = "CouldNotGet",
    NotFound = "NotFound",
    Forbidden = "Forbidden",
    MemberAdded = "MemberAdded",
    MemberWaiting = "MemberWaiting",
    CannotDeactivateLastManager = "CannotDeactivateLastManager",
}

export enum InvitationMean {
    MAIL = "MAIL",
    PHONE = "PHONE",
}

export interface MembershipInvitation {
    role: string;
    id: string;
    status: MembershipStatus;
    createdAt: string;
    sender: InvitationSender;
    recipient: InvitationRecipient;
    note?: string;
}

export interface InvitationSender {
    userId: string;
    operatorId: string;
    operatorName: string;
}

export interface InvitationRecipient {
    userId: string;
    email?: string;
    phoneNumber?: PhoneNumber;
}

export interface MembershipInvitationError {
    type: MembershipInvitationErrorType;
}

export enum MembershipInvitationErrorType {
    NotFound = "NotFound",
    CouldNotAccept = "CouldNotAccept",
    Unknown = "Unknown",
}

export interface MembershipPermissionsError {
    type: MembershipPermissionsErrorType;
}

export enum MembershipPermissionsErrorType {
    Conflict = "Conflict",
    Unknown = "Unknown",
}

export interface Member {
    id: string;
    type: MemberType;
    status: MembershipStatus;
    firstName: string;
    lastName: string;
    phoneNumber: PhoneNumber | null;
    email: string | null;
    invitationDate: string;
    invitationId: string;
    isMe: boolean;
    isPilot: boolean;
    membershipId: string | null;
    membershipPilotId?: string;
    membershipOperatorId: string;
    membershipOperatorName?: string;
    competencies: PilotCompetency[];
    permissions: MembershipPermission[];
    userId: string;
}

export enum MemberType {
    MEMBER = "MEMBER",
    INVITATION = "INVITATION",
}

export interface PilotCompetency {
    category: OperationScenarioCategory;
    name: PilotCompetencies;
}

export enum OperationScenarioCategory {
    Open = "OPEN",
    Specific = "SPECIFIC",
    Certified = "CERTIFIED",
}

export interface MembersWithPages {
    pages: Page;
    content: Member[];
}

export enum MembershipStatus {
    Active = "ACTIVE",
    Deactivated = "DEACTIVATED",
    Pending = "PENDING",
    Rejected = "REJECTED",
}

export enum MembershipInvitationStatus {
    Accepted = "ACCEPTED",
    Rejected = "REJECTED",
}

export interface MemberProfile {
    id: string;
    pilotNumber?: string;
    firstName: string;
    lastName: string;
    phoneNumber: PhoneNumber;
    email: string;
    address?: Address;
    nationality: Alpha3CountryCode;
    competencies?: PilotMemberCompetency[];
}

export interface PilotMemberCompetency {
    id: string;
    operationScenarioId: string;
    operationScenarioName: PilotCompetencies;
    operationScenarioCategory: OperationScenarioCategory;
    expirationDate: Date;
    status: PilotsCompetencyStatus;
    statusReason: string;
}

export enum PilotsCompetencyStatus {
    Active = "ACTIVE",
    Suspended = "SUSPENDED",
    Withdrawn = "WITHDRAWN",
}

export enum MembersListFilterFormKeys {
    SearchByText = "searchByText",
    Status = "status",
    Competencies = "competencies",
}

export interface MembersListFilter {
    [MembersListFilterFormKeys.SearchByText]: string;
    [MembersListFilterFormKeys.Status]: MembershipStatus[];
    [MembersListFilterFormKeys.Competencies]?: PilotCompetencies[];
}

export interface MembershipCapabilitiesError {
    type: MembershipCapabilitiesErrorType;
}

export enum MembershipCapabilitiesErrorType {
    Unknown = "Unknown",
}

export interface MembershipCapabilities {
    membershipStatuses: MembershipStatus[];
    operationScenarios: PilotCompetency[];
    pilotId: string;
    missionPlanPhases: MissionProcessingPhaseExtended[];
}

export enum MembershipPermission {
    OperatorManage = "OPERATOR_MANAGE",
    MissionPlanning = "MISSION_PLANNING",
    UavPiloting = "UAV_PILOTING",
    FleetManagement = "FLEET_MANAGEMENT",
}

export interface MembersQueryParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    [OPERATOR_CONTEXT_PARAM]?: string;
    searchByText?: string;
    status?: string;
    competencies?: string;
}

export enum MembershipMissionStatus {
    Draft = "DRAFT",
    Unverified = "UNVERIFIED",
    Analyzed = "ANALYZED",
    MissionReady = "MISSION_READY",
    MissionForbidden = "MISSION_FORBIDDEN",
    AnalysisInterrupted = "ANALYSIS_INTERRUPTED",
}

export enum MembershipMissionAcceptanceStatus {
    Accepted = "ACCEPTED",
    Rejected = "REJECTED",
    Canceled = "CANCELED",
}

export interface MembershipWeatherConditions {
    windSpeed: string; // meters per second
    airTemperature: string; // celsius degrees
    precipitationProbability: string; // percent
}

export interface MembershipMission {
    id: string;
    missionId: string;
    name: string;
    status: MembershipMissionStatus;
    missionStatus: MembershipMissionAcceptanceStatus;
    phase: MissionProcessingPhaseExtended;
    flightStartAt: Date;
    flightFinishAt: Date;
    uavId: string;
    uavName: string;
    uavSetupName: string;
    weatherConditions: MembershipWeatherConditions;
}

export interface MembershipMissionsWithPages {
    content: MembershipMission[];
    pages: Page;
}

export interface MembershipMissionsFilter {
    status: MissionProcessingPhaseExtended[] | null;
    dateFrom: string | null;
    dateTo: string | null;
    name: string | null;
}

export interface MembershipMissionListQueryParams {
    missionStatus?: string;
    missionDateFrom?: string;
    missionDateTo?: string;
    missionName?: string;
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
}

export enum OperatorManageDescriptionItem {
    MissionPlanning = "MissionPlanning",
    UavPiloting = "UavPiloting",
    FleetManagement = "FleetManagement ",
    OperatorManagement = "OperatorManagement",
}

export enum MissionPlanningDescriptionItem {
    MissionPlanning = "MissionPlanning",
    UavList = "UavList",
    MembersList = "MembersList",
    PlannedMissionsList = "PlannedMissionsList",
    PermitsList = "PermitsList",
    BasicOperatorDetails = "BasicOperatorDetails",
}

export enum UavPilotingDescriptionItem {
    UavPiloting = "UavPiloting",
    PlannedMissionsList = "PlannedMissionsList",
    BasicOperatorDetails = "BasicOperatorDetails",
}

export enum FleetManagementDescriptionItem {
    UavManagement = "UavManagement",
    BasicOperatorDetails = "BasicOperatorDetails",
}
