import { BasicQualification } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { PublicProfileBasicDataModel, PublicProfileDetails } from "./public-profile.models";

export interface GetPublicProfilePilotResponseBody extends PublicProfileBasicDataModel {
    competencies: BasicQualification[];
    certificates?: PublicProfileCertificateResponseBody[];
}

interface PublicProfileCertificateResponseBody {
    validUntil: string;
    name: string;
}

export interface GetPublicProfileOperatorResponseBody extends PublicProfileBasicDataModel {
    operationalAuthorizations: BasicQualification[];
}

export const convertPublicProfilePilotResponseBodyToPublicProfileDetails = (
    response: GetPublicProfilePilotResponseBody
): PublicProfileDetails => ({
    status: response.status,
    number: response.number,
    qualifications: response.competencies,
    certificates: response.certificates?.map((certificate) => ({
        name: certificate.name,
        expirationDate: DateUtils.convertStringDateToDateWithoutTimeZone(certificate.validUntil),
    })),
    otherInformation: response.otherInformation ?? [],
});

export const convertPublicProfileOperatorResponseBodyToPublicProfileDetails = (
    response: GetPublicProfileOperatorResponseBody
): PublicProfileDetails => ({
    status: response.status,
    number: response.number,
    qualifications: response.operationalAuthorizations,
    otherInformation: response.otherInformation ?? [],
});
