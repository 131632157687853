<ng-container *ngIf="shouldShowWizardHeader$ | ngrxPush">
    <dtm-ui-lib-wizard-header [wizardId]="wizardId">
        <dtm-ui-lib-wizard-step-header [stepId]="RegisterOperatorWizardSteps.BasicData">
            {{ "dtmWebAppLibPilotOperatorRegistration.basicDataHeader" | transloco }}
        </dtm-ui-lib-wizard-step-header>
        <dtm-ui-lib-wizard-step-header [stepId]="RegisterOperatorWizardSteps.AdditionalData">
            {{ "dtmWebAppLibPilotOperatorRegistration.additionalDataHeader" | transloco }}
        </dtm-ui-lib-wizard-step-header>
        <dtm-ui-lib-wizard-step-header [stepId]="RegisterOperatorWizardSteps.Summary">
            {{ "dtmWebAppLibPilotOperatorRegistration.summaryHeader" | transloco }}
        </dtm-ui-lib-wizard-step-header>
    </dtm-ui-lib-wizard-header>
</ng-container>
