<mat-card class="grid grid-maxsize-6">
    <section>
        <h2>{{ "dtmWebAppLibHelpCentre.issuesList.technicalContactHeader" | transloco }}</h2>
        <p
            [innerHtml]="
                'dtmWebAppLibHelpCentre.issuesList.technicalContactMessage'
                    | transloco : { emailAddress: TECHNICAL_EMAIL_ADDRESS | emailToClickable : 'button-link-small' }
            "
        ></p>
    </section>
    <section>
        <h2>{{ "dtmWebAppLibHelpCentre.issuesList.caaContactHeader" | transloco }}</h2>
        <p
            [innerHtml]="
                'dtmWebAppLibHelpCentre.issuesList.caaContactMessage'
                    | transloco : { emailAddress: CAA_EMAIL_ADDRESS | emailToClickable : 'button-link-small' }
            "
        ></p>
    </section>
    <section>
        <h2>{{ "dtmWebAppLibHelpCentre.issuesList.userGuideHeader" | transloco }}</h2>
        <p [innerHtml]="'dtmWebAppLibHelpCentre.issuesList.userGuideMessage' | transloco : { userGuideFileUrl: USER_GUIDE_FILE_URL }"></p>
    </section>
    <section>
        <h2>{{ "dtmWebAppLibHelpCentre.issuesList.soraGuideHeader" | transloco }}</h2>
        <p [innerHtml]="'dtmWebAppLibHelpCentre.issuesList.soraGuideMessage' | transloco : { soraGuideFileUrl: SORA_GUIDE_FILE_URL }"></p>
    </section>
    <section>
        <h2>{{ "dtmWebAppLibHelpCentre.issuesList.eLearningUserGuideHeader" | transloco }}</h2>
        <p
            [innerHtml]="
                'dtmWebAppLibHelpCentre.issuesList.eLearningUserGuideMessage'
                    | transloco : { eLearningUserGuideFileUrl: ELEARNING_USER_GUIDE_FILE_URL }
            "
        ></p>
    </section>
</mat-card>

<footer>
    <a class="button-secondary" [href]="termsOfUseUrl" rel="noopener norefer" target="_blank">
        {{ "dtmWebAppLibHelpCentre.footer.termsOfUseLabel" | transloco }}
        <dtm-ui-icon name="external-link"></dtm-ui-icon>
    </a>
    <a class="button-secondary" [href]="accessibilityStatementUrl" rel="noopener norefer" target="_blank">
        {{ "dtmWebAppLibHelpCentre.footer.accessibilityStatementLabel" | transloco }}
        <dtm-ui-icon name="external-link"></dtm-ui-icon>
    </a>
</footer>
