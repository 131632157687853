import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { CompanyInfo } from "../../../models/operator-migration.models";

interface CompanyDetailsSummaryComponentState {
    companyInfo: CompanyInfo | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-company-details-summary[companyInfo]",
    templateUrl: "./company-details-summary.component.html",
    styleUrls: ["./company-details-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CompanyDetailsSummaryComponent {
    @Input() public set companyInfo(value: CompanyInfo | undefined) {
        this.localStore.patchState({ companyInfo: value });
    }

    protected readonly companyInfo$ = this.localStore.selectByKey("companyInfo").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<CompanyDetailsSummaryComponentState>) {
        localStore.setState({ companyInfo: undefined });
    }
}
