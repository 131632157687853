<ng-container *ngrxLet="filtersCount$ as filtersCount">
    <dtm-ui-filters-container class="filters-container" [filtersCount]="filtersCount">
        <form [formGroup]="filtersForm">
            <div class="filters-content">
                <dtm-ui-input-field class="control-search">
                    <input
                        matInput
                        type="text"
                        formControlName="searchPhrase"
                        [placeholder]="'dtmWebAppLibOperatorPermits.permitsFilters.searchSpecificPermitDraftPlaceholder' | transloco"
                    />
                </dtm-ui-input-field>
            </div>
        </form>
    </dtm-ui-filters-container>
    <dtm-ui-filter-chips-display
        class="filters-chips"
        selectedFiltersDisplaySlot
        [formGroup]="filtersForm"
        [filtersValue]="filtersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="filtersCount"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</ng-container>
