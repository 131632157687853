<dtm-ui-loader-container *ngrxLet="data.isProcessing$ as isProcessing" [isShown]="isProcessing">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ "dtmWebAppLibPilotProfile.editPansaUtmLink.header" | transloco }}</h2>
        <button type="button" class="button-icon" [matDialogClose]="null" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <p class="dialog-message">
            {{ "dtmWebAppLibPilotProfile.editPansaUtmLink.message" | transloco }}
        </p>
        <form [formGroup]="form" dtmUiInvalidFormScrollable>
            <dtm-ui-input-field>
                <label>{{ "dtmWebAppLibPilotProfile.editPansaUtmLink.loginLabel" | transloco }}</label>
                <input formControlName="login" matInput type="text" />
                <div class="field-error" *dtmUiFieldHasError="form.controls.login; name: 'required'">
                    {{ "dtmWebAppLibPilotProfile.editPansaUtmLink.requiredFieldError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="form.controls.login; name: PansaUtmLinkErrorType.WrongCredentials">
                    {{ "dtmWebAppLibPilotProfile.editPansaUtmLink.wrongCredentialsError" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field [isClearable]="false">
                <label>{{ "dtmWebAppLibPilotProfile.editPansaUtmLink.passwordLabel" | transloco }}</label>
                <input #password matInput type="password" formControlName="password" />
                <dtm-ui-password-visibility-switch [inputParent]="password" class="field-suffix"> </dtm-ui-password-visibility-switch>
                <div class="field-error" *dtmUiFieldHasError="form.controls.password; name: 'required'">
                    {{ "dtmWebAppLibPilotProfile.editPansaUtmLink.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-input-field>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="false">
            {{ "dtmWebAppLibPilotProfile.editPansaUtmLink.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="validateAndSave()">
            {{ "dtmWebAppLibPilotProfile.editPansaUtmLink.saveButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>
