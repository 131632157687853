<dtm-ui-loader-container *ngrxLet="isMemberProfileProcessing$ as isProcessing" [isShown]="isProcessing">
    <ng-container *ngIf="memberProfileError$ | ngrxPush as memberProfileError; else profileDetailsTemplate">
        <ng-container [ngSwitch]="memberProfileError.type">
            <dtm-ui-error
                *ngSwitchCase="MembershipErrorType.Forbidden"
                [errorMessage]="'dtmWebAppMembership.pilotProfile.forbiddenErrorMessage' | transloco"
            ></dtm-ui-error>
            <dtm-ui-error
                *ngSwitchCase="MembershipErrorType.NotFound"
                [errorMessage]="'dtmWebAppMembership.pilotProfile.notFoundErrorMessage' | transloco"
            ></dtm-ui-error>
            <dtm-ui-error *ngSwitchDefault (reload)="refreshPilotsProfile()"></dtm-ui-error>
        </ng-container>
    </ng-container>

    <ng-template #profileDetailsTemplate>
        <div class="grid" *ngIf="isSelectedContextEnterprise$ | ngrxPush; else pageNotFoundTemplate">
            <div class="actions">
                <button type="button" routerLink="/membership/users-list" class="button-secondary">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.pilotProfile.goBackToUsersListLabel" | transloco }}
                </button>
            </div>
            <ng-container *ngIf="memberProfile$ | ngrxPush as memberProfile">
                <dtm-web-app-lib-user-member-details
                    [userProfile]="memberProfile"
                    [isExpanded]="false"
                ></dtm-web-app-lib-user-member-details>
                <dtm-web-app-lib-pilot-member-competencies
                    *ngIf="memberProfile.competencies?.length"
                    [competencies]="memberProfile.competencies"
                ></dtm-web-app-lib-pilot-member-competencies>
                <dtm-web-app-lib-membership-missions
                    (missionWizardStart)="startMissionWizard(memberProfile)"
                    (missionsListRefresh)="refreshMissionsList()"
                    (filtersChange)="changeFilters($event)"
                    (pageChange)="changePage($event)"
                    [missionsList]="missionsList$ | ngrxPush"
                    [isMissionsProcessing]="isMissionsProcessing$ | ngrxPush"
                    [missionsListError]="missionsListError$ | ngrxPush"
                    [capabilities]="capabilities$ | ngrxPush"
                    [pagination]="missionsListPages$ | ngrxPush"
                    [incomingFilters]="filtersQuery$ | ngrxPush"
                    [operatorType]="OperatorType.Personal"
                ></dtm-web-app-lib-membership-missions>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #pageNotFoundTemplate>
        <dtm-ui-page-not-found></dtm-ui-page-not-found>
    </ng-template>
</dtm-ui-loader-container>
