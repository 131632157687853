<div class="grid grid-maxsize-8 grid-maxsize-tablet-10">
    <div>
        <button type="button" [routerLink]="['/operator-permits']" class="button-secondary redirect-button">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "dtmWebAppLibOperatorPermits.permitPreview.goBackToPermitsListButtonLabel" | transloco }}
        </button>

        <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
            <ng-container *ngrxLet="permit$ as permit">
                <mat-card *ngIf="!(permitError$ | ngrxPush) && permit; else errorTemplate">
                    <h2>{{ "dtmWebAppLibOperatorPermits.permitPreview.title" | transloco : { operatorPermitType } }}</h2>
                    <dtm-ui-permit-details-preview
                        [permit]="permit"
                        (kmlFilePreview)="previewKmlFile($event)"
                        isRestrictionsTileExpanded
                        isPermitDetailsExpanded
                    ></dtm-ui-permit-details-preview>
                </mat-card>
                <ng-template #errorTemplate>
                    <dtm-ui-error
                        [errorMessage]="'dtmWebAppLibOperatorPermits.permitPreview.cannotGetPermitDetailsError' | transloco"
                        (reload)="retryFetchData()"
                    ></dtm-ui-error>
                </ng-template>
            </ng-container>
        </dtm-ui-loader-container>
    </div>
</div>
