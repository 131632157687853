import { InjectionToken } from "@angular/core";

export interface SpecificPermitApplicationEndpoints {
    getCapabilities: string;
    getOperations: string;
    getOperation: string;
    draftsManagement: string;
    draftManagement: string;
    getOperationArea: string;
    getPredefinedArea: string;
    getRequiredPilotTrainings: string;
    generateApplication: string;
    downloadDocumentTemplate: string;
    getCaaPermitDetails: string;
}

export const SPECIFIC_PERMIT_APPLICATION_ENDPOINTS = new InjectionToken<SpecificPermitApplicationEndpoints>(
    "Specific Permit Application endpoints"
);
