<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <dtm-ui-info-message>
        <div
            [innerHTML]="
                'dtmWebAppLibOperatorConversations.operatorConversationsContainer.droneTowerInfoMessage'
                    | transloco
                    | emailToClickable : 'button-link-small'
            "
        ></div>
    </dtm-ui-info-message>
    <dtm-ui-error
        *ngIf="threadsListError$ | ngrxPush; else listTemplate"
        (reload)="refreshThreadsList()"
        [errorMessage]="'dtmWebAppLibOperatorConversations.operatorConversationsContainer.threadsListDownloadError' | transloco"
        class="grid grid-maxsize-6"
    ></dtm-ui-error>

    <ng-template #listTemplate>
        <ng-container
            *ngrxLet="{
                threadsList: threadsList$,
                isSmartphone: isSmartphone$,
                messagesList: messagesList$,
                isMessageEditorVisible: isMessageEditorVisible$
            } as vm"
        >
            <section *ngIf="vm.threadsList?.length || areFiltersApplied$ | ngrxPush; else emptyStateTemplate" class="grid conversations">
                <ng-container *ngIf="vm.isSmartphone" [ngTemplateOutlet]="newThreadButtonTemplate"></ng-container>
                <dtm-web-app-lib-operator-conversation-filters
                    [initialFilters]="currentFilters$ | ngrxPush"
                    [conversationCategories]="categories$ | ngrxPush"
                    (filtersChange)="applyFiltersFormForm($event)"
                    (filtersAppliedChange)="handleFiltersAppliedChange($event)"
                ></dtm-web-app-lib-operator-conversation-filters>
                <mat-card *ngrxLet="{ selectedThread: selectedThread$, messagesListError: messagesListError$ } as vmSelectedThread">
                    <div class="main-container" *ngIf="vm.threadsList?.length; else emptyListTemplate">
                        <div class="threads-container">
                            <div class="threads-header" *ngIf="!vm.isSmartphone">
                                {{ "dtmWebAppLibOperatorConversations.operatorConversationsContainer.threadsHeader" | transloco }}
                            </div>
                            <ng-container
                                *ngIf="vm.isSmartphone && !vmSelectedThread.selectedThread && !vmSelectedThread.messagesListError"
                                [ngTemplateOutlet]="selectThreadHintTemplate"
                            ></ng-container>
                            <dtm-ui-operators-thread-list
                                [selectedThread]="vmSelectedThread.selectedThread?.id"
                                [threadsList]="vm.threadsList"
                                [canDisplayAssignment]="false"
                                (threadChange)="changeThread($event)"
                            >
                                <div class="mobile-messages-container" *ngIf="vm.isSmartphone">
                                    <ng-container [ngTemplateOutlet]="messagesTemplate"></ng-container>
                                </div>
                            </dtm-ui-operators-thread-list>
                        </div>

                        <div class="desktop-messages-container" *ngIf="!vm.isSmartphone">
                            <ng-container [ngTemplateOutlet]="newThreadButtonTemplate"> </ng-container>
                            <ng-container [ngTemplateOutlet]="messagesTemplate"> </ng-container>
                        </div>

                        <ng-template #messagesTemplate>
                            <dtm-ui-error
                                *ngIf="vmSelectedThread.messagesListError; else messagesListTemplate"
                                (reload)="refreshMessagesList()"
                                [errorMessage]="
                                    'dtmWebAppLibOperatorConversations.operatorConversationsContainer.messagesListDownloadError' | transloco
                                "
                            ></dtm-ui-error>

                            <ng-template #messagesListTemplate>
                                <ng-container
                                    *ngrxLet="{
                                        isMessageEditorVisible: isMessageEditorVisible$,
                                        messagesList: messagesList$
                                    } as vmMessages"
                                >
                                    <div *ngIf="vmSelectedThread.selectedThread && vmMessages.messagesList; else selectThreadHintTemplate">
                                        <div class="messages-actions">
                                            <p *ngIf="!vm.isSmartphone" class="messages-subject">
                                                {{ vmSelectedThread.selectedThread.name }}
                                            </p>
                                            <ng-container [ngSwitch]="true">
                                                <button
                                                    *ngSwitchCase="!vm.isMessageEditorVisible && !vmSelectedThread.selectedThread.isClosed"
                                                    type="button"
                                                    class="button-secondary reply-button"
                                                    (click)="changeEditorVisibility(true)"
                                                >
                                                    <dtm-ui-icon name="reply"></dtm-ui-icon>
                                                    {{
                                                        "dtmWebAppLibOperatorConversations.operatorConversationsContainer.replyInThreadButtonLabel"
                                                            | transloco
                                                    }}
                                                </button>

                                                <ng-containear
                                                    *ngSwitchDefault
                                                    [ngTemplateOutlet]="closedThreadTemplate"
                                                    [ngTemplateOutletContext]="{
                                                        isSelectedThreadClosed: vmSelectedThread.selectedThread.isClosed
                                                    }"
                                                >
                                                </ng-containear>
                                            </ng-container>
                                        </div>
                                        <form *ngIf="vmMessages.isMessageEditorVisible" [formGroup]="conversationForm">
                                            <dtm-ui-new-message-editor
                                                *ngrxLet="isAttachmentsControlVisible$ as isAttachmentsControlVisible"
                                                isJoinedView
                                                dtmUiMarkValueAccessorControlsAsTouched
                                                class="editor-container"
                                                formControlName="editor"
                                                [isAttachmentsControlVisible]="isAttachmentsControlVisible"
                                            >
                                                <dtm-ui-new-message-actions
                                                    [isAttachmentsButtonVisible]="!isAttachmentsControlVisible"
                                                    (attachmentsButtonVisibilityChange)="changeAttachmentControlVisibility()"
                                                    (messageDecline)="changeEditorVisibility(false)"
                                                    (messageSend)="sendMessage(vmMessages.messagesList[0])"
                                                ></dtm-ui-new-message-actions>
                                            </dtm-ui-new-message-editor>
                                        </form>
                                        <dtm-ui-messages-in-thread
                                            [messages]="vmMessages.messagesList"
                                            [storedAvatarPictures]="storedAvatarPictures$ | ngrxPush"
                                        ></dtm-ui-messages-in-thread>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </div>
                </mat-card>
            </section>

            <ng-template #newThreadButtonTemplate>
                <div class="new-thread-button-container">
                    <button type="button" class="button-primary" (click)="openNewThreadContainer()">
                        <dtm-ui-icon name="add"></dtm-ui-icon>
                        {{ "dtmWebAppLibOperatorConversations.operatorConversationsContainer.createNewThreadButtonLabel" | transloco }}
                    </button>
                </div>
            </ng-template>

            <ng-template #selectThreadHintTemplate>
                <dtm-ui-error
                    class="no-message-selected"
                    [errorMessage]="'dtmWebAppLibOperatorConversations.operatorConversationsContainer.selectThreadHint' | transloco"
                    [imageSrc]="vm.isSmartphone ? 'assets/images/select-thread-mobile.svg' : 'assets/images/select-thread-desktop.svg'"
                ></dtm-ui-error>
            </ng-template>

            <ng-template #closedThreadTemplate let-isSelectedThreadClosed="isSelectedThreadClosed">
                <dtm-ui-icon
                    *ngIf="isSelectedThreadClosed"
                    name="lock"
                    [matTooltip]="'dtmWebAppLibOperatorConversations.operatorConversationsContainer.closedThreadHint' | transloco"
                ></dtm-ui-icon>
            </ng-template>

            <ng-template #emptyListTemplate>
                <div class="empty-state-button">
                    <button type="button" class="button-primary" (click)="openNewThreadContainer()">
                        <dtm-ui-icon name="add"></dtm-ui-icon>
                        {{ "dtmWebAppLibOperatorConversations.operatorConversationsContainer.createNewThreadButtonLabel" | transloco }}
                    </button>
                </div>
                <dtm-ui-no-results></dtm-ui-no-results>
            </ng-template>
        </ng-container>

        <ng-template #emptyStateTemplate>
            <div *ngIf="!isProcessing" class="grid grid-maxsize-6">
                <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
                    <ng-container titleSlot>
                        {{ "dtmWebAppLibOperatorConversations.operatorConversationsContainer.emptyStateTitle" | transloco }}
                    </ng-container>
                    <p messageSlot>
                        {{ "dtmWebAppLibOperatorConversations.operatorConversationsContainer.emptyStateMessage" | transloco }}
                    </p>
                    <img imageSlot src="assets/images/conversations-empty-state.svg" />
                    <button footerSlot type="button" class="button-primary" (click)="openNewThreadContainer()">
                        <dtm-ui-icon name="add"></dtm-ui-icon>
                        {{ "dtmWebAppLibOperatorConversations.operatorConversationsContainer.emptyStateButtonLabel" | transloco }}
                    </button>
                </dtm-ui-empty-state>
            </div>
        </ng-template>
    </ng-template>
</dtm-ui-loader-container>
