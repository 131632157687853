import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NewUserGuard } from "../../guards/new-user.guard";
import { OperatorProfileContainerComponent } from "./components/operator-profile-container/operator-profile-container.component";
import { CanEnterOperatorProfileGuard } from "./guards/can-enter-on-operator-profile.guard";

const routes: Routes = [
    {
        path: "operator-profile",
        component: OperatorProfileContainerComponent,
        canActivate: [DtmRoleGuard, CanEnterOperatorProfileGuard, NewUserGuard],
        data: {
            titleTranslationKey: "dtmWebAppOperatorProfile.routeTitle",
            shouldSkipReloadOnOperatorChange: true,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperatorProfileRoutingModule {}
