import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, GuardsCheckEnd, Params, Router } from "@angular/router";
import { KmlExporterService } from "@dtm-frontend/shared/map";
import { MissionProcessingPhase } from "@dtm-frontend/shared/mission";
import { FunctionUtils, LocalComponentStore, RxjsUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Polygon, Properties, feature as turfFeature, featureCollection as turfFeatureCollection } from "@turf/helpers";
import equal from "fast-deep-equal";
import { ToastrService } from "ngx-toastr";
import { filter, skip, tap } from "rxjs";
import { distinctUntilChanged, first, map, switchMap } from "rxjs/operators";
import { OperatorContextState } from "../../../../shared";
import { MISSION_ENDPOINTS, MissionEndpoints } from "../../../mission.tokens";
import {
    MissionError,
    MissionErrorType,
    MissionGroupByPeriod,
    MissionPlanContent,
    MissionPlanListQuery,
} from "../../../models/mission.model";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "../../../services/mission.resolvers";
import { MissionActions } from "../../../state/mission.actions";
import { MissionState } from "../../../state/mission.state";

interface MissionListContentComponentState {
    groupBy: MissionGroupByPeriod | undefined;
    areFiltersApplied: boolean;
}
const MIN_PAGE_SIZE_VALUE = 12;
const MIDDLE_PAGE_SIZE_VALUE = 24;
const MAX_PAGE_SIZE_VALUE = 36;
const PAGE_SIZE_OPTIONS: number[] = [MIN_PAGE_SIZE_VALUE, MIDDLE_PAGE_SIZE_VALUE, MAX_PAGE_SIZE_VALUE];
const THUMBNAIL_PLACEHOLDER_PATH = "/assets/images/map-image-placeholder.svg";
const SCROLL_CONTAINER_SELECTOR = ".router-outlet";
const LIST_CONTAINER = ".list-container";

const PHASE_FILTERS: (MissionProcessingPhase | undefined)[] = [
    undefined,
    MissionProcessingPhase.Accepted,
    MissionProcessingPhase.Waiting,
    MissionProcessingPhase.Rejected,
    MissionProcessingPhase.Unsubmitted,
    MissionProcessingPhase.MissionRealized,
    MissionProcessingPhase.MissionAbandoned,
    MissionProcessingPhase.CaaPermitApplication,
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-plan-list-content",
    templateUrl: "./mission-plan-list-content.component.html",
    styleUrls: ["./mission-plan-list-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionPlanListContentComponent implements OnInit {
    protected readonly pageSizeOptions: number[] = PAGE_SIZE_OPTIONS;

    protected readonly missionPlanList$ = this.store
        .select(MissionState.getMissionPlanList)
        .pipe(map((missions) => this.mapMissionThumbnails(missions)));
    protected readonly error$ = this.store.select(MissionState.missionError).pipe(map((error) => this.handleMissionError(error)));
    protected readonly isProcessing$ = this.store.select(MissionState.isProcessing);
    protected readonly pagination$ = this.store.select(MissionState.getPagination);
    protected readonly isMoreThanOneOperator$ = this.store
        .select(OperatorContextState.operators)
        .pipe(map((operators) => !!operators && Object.keys(operators).length > 1));
    protected readonly queries$ = this.route.queryParams.pipe(distinctUntilChanged(equal));
    protected readonly groupBy$ = this.localStore.selectByKey("groupBy");
    protected readonly areFiltersApplied$ = this.localStore.selectByKey("areFiltersApplied");
    protected readonly flightPurposes$ = this.store.select(MissionState.flightPurposes);

    protected readonly PHASE_FILTERS = PHASE_FILTERS;

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        @Inject(MISSION_ENDPOINTS) private readonly endpoints: MissionEndpoints,
        private readonly localStore: LocalComponentStore<MissionListContentComponentState>,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly kmlExporterService: KmlExporterService
    ) {
        this.localStore.setState({ groupBy: undefined, areFiltersApplied: false });

        this.cleanupOnRouteNavigation();
    }

    public ngOnInit(): void {
        this.missionPlanList$.pipe(untilDestroyed(this)).subscribe(() => this.scrollListTop());
        this.route.queryParams
            .pipe(untilDestroyed(this))
            .subscribe((queries: MissionPlanListQuery) => this.store.dispatch(new MissionActions.GetMissionList(queries)));

        this.watchOperatorChangesAndUpdateMissionResultList();
    }

    protected handlePageChange(event: PageEvent): void {
        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams: { [PAGE_NUMBER_QUERY_PARAM]: event?.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event?.pageSize },
            queryParamsHandling: "merge",
        });
    }

    protected handleFilterQuery(filters: MissionPlanListQuery): void {
        const queryParams: Params = { ...this.parseFiltersToQueryParams(filters), [PAGE_NUMBER_QUERY_PARAM]: 0 };

        this.router.navigate(["."], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    protected handleFilterAppliedChange(filtersApplied: boolean): void {
        this.localStore.patchState({ areFiltersApplied: filtersApplied });
    }

    protected handleGroupByChange(groupBy: MissionGroupByPeriod | undefined | null): void {
        this.localStore.patchState({ groupBy: groupBy ?? undefined });
    }

    protected deleteMissionPlan(missionPlanId: string): void {
        this.store
            .dispatch(new MissionActions.DeleteMissionPlan(missionPlanId))
            .pipe(
                tap(() => this.refreshListWithCurrentFilters()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected cloneMissionPlan(sourceMissionPlanId: string): void {
        this.store.dispatch(new MissionActions.CloneAndEditMissionPlan(sourceMissionPlanId));
    }

    protected cancelMission(missionId: string) {
        this.store
            .dispatch(new MissionActions.CancelMission(missionId))
            .pipe(
                tap(() => this.refreshListWithCurrentFilters()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected downloadMissionPlanKml(missionPlanId: string): void {
        this.store
            .dispatch(new MissionActions.GetMissionData(missionPlanId, false))
            .pipe(
                switchMap(() => this.store.select(MissionState.currentPlanRoute)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((route) => {
                if (!route) {
                    const errorMessage = this.transloco.translate("dtmWebAppLibMission.cannotDownloadMissionPlanKmlErrorMessage");
                    this.toastService.error(errorMessage);

                    return;
                }

                const geoJson = turfFeatureCollection<Polygon, Properties>(
                    route.sections
                        .map((section) => section.flightZone?.flightArea.volume.area ?? section.segment?.flightArea.volume.area)
                        .filter(FunctionUtils.isTruthy)
                        .map((polygon) => turfFeature(polygon))
                );

                this.kmlExporterService.geoJsonToKml(geoJson, `mission-plan-${missionPlanId}`, {
                    documentName: `mission-plan-${missionPlanId}`,
                    documentDescription: `mission-plan-${missionPlanId}`,
                });
            });
    }

    private watchOperatorChangesAndUpdateMissionResultList() {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy(), skip(1), untilDestroyed(this))
            .subscribe(() => this.refreshListWithCurrentFilters(true));
    }

    private parseFiltersToQueryParams(inputObject: MissionPlanListQuery): Params {
        const queryParams: Params = {};

        return Object.entries(inputObject).reduce((params, [key, value]) => {
            params[key] = value instanceof Date ? value.toISOString() : value;

            return params;
        }, queryParams);
    }

    private scrollListTop(): void {
        const mainContainer = this.document.querySelector(SCROLL_CONTAINER_SELECTOR);
        const listContainer = this.document.querySelector(LIST_CONTAINER);

        if (mainContainer && listContainer && listContainer.getBoundingClientRect().top < 0) {
            listContainer.scrollIntoView({ behavior: "smooth" });
        }
    }

    private mapMissionThumbnails(missions?: MissionPlanContent[]): MissionPlanContent[] | undefined {
        return missions?.map((mission) => ({
            ...mission,
            thumbnail: mission.thumbnail
                ? StringUtils.replaceInTemplate(this.endpoints.missionThumbnailManagement, { planId: mission.id })
                : THUMBNAIL_PLACEHOLDER_PATH,
        }));
    }

    protected refreshListWithCurrentFilters(shouldResetPageNumber = false) {
        let queryParams = this.route.snapshot.queryParams;

        if (shouldResetPageNumber && queryParams[PAGE_NUMBER_QUERY_PARAM] !== "0") {
            queryParams = { ...queryParams, [PAGE_NUMBER_QUERY_PARAM]: 0 };

            this.router.navigate(["."], {
                relativeTo: this.route,
                queryParams,
            });

            return;
        }

        this.store.dispatch(new MissionActions.GetMissionList(queryParams));
    }

    protected getMissionPhaseIndexFromQuery(filterQuery: MissionPlanListQuery): number {
        const phase = filterQuery.processingPhase;
        if (!phase) {
            return 0;
        }

        return PHASE_FILTERS.indexOf(phase);
    }

    private handleMissionError(error?: MissionError): MissionError | undefined {
        if (!error) {
            return undefined;
        }

        let message: string;
        switch (error.type) {
            case MissionErrorType.CannotCloneMission:
                message = this.transloco.translate("dtmWebAppLibMission.cannotCloneMissionPlanErrorMessage");
                this.toastService.error(message);

                return undefined;
            case MissionErrorType.CannotCancelMission:
                message = this.transloco.translate("dtmWebAppLibMission.cannotCancelMissionErrorMessage");
                this.toastService.error(message);

                return undefined;
            default:
                return error;
        }
    }

    private cleanupOnRouteNavigation(): void {
        this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof GuardsCheckEnd && event.url.split("?")[0] !== this.router.url.split("?")[0] && event.shouldActivate
                ),
                untilDestroyed(this)
            )
            .subscribe(() => this.store.dispatch(new MissionActions.CleanupMissionList()));
    }
}
