import { HttpClient, HttpErrorResponse, HttpEvent, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { OPERATOR_CONVERSATIONS_ENDPOINTS, OperatorConversationsEndpoints } from "../../operator-conversations";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";

@Injectable()
export class OperatorConversationsFileUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATOR_CONVERSATIONS_ENDPOINTS) private readonly endpoints: OperatorConversationsEndpoints,
        private store: Store
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContext)?.id;

        if (!operatorId) {
            return throwError(() => ({
                type: FileUploadErrorType.Unknown,
            }));
        }

        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(StringUtils.replaceInTemplate(this.endpoints.uploadFile, { operatorId }), formData, {
                reportProgress: true,
                responseType: "json",
                observe: "events",
            })
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }

    public getFile(fileId: string) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContext)?.id;

        if (!operatorId) {
            return throwError(() => ({
                type: FileUploadErrorType.Unknown,
            }));
        }

        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.downloadFile, { fileId, operatorId }), {
            responseType: "blob",
        });
    }

    public getFilesCompressed(fileIds: string[], fileName: string) {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContext)?.id;

        if (!operatorId) {
            return throwError(() => ({
                type: FileUploadErrorType.Unknown,
            }));
        }

        const params = new HttpParams().append("fileName", fileName).append("attachmentIds", fileIds.join(","));

        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.downloadCompressedFiles, { operatorId }), {
            responseType: "blob",
            params,
        });
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
