<ng-container *ngrxLet="{ importedDetails: importedDetails$, companyData: companyData$, editedPilotDetails: editedPilotDetails$ } as vm">
    <dtm-ui-info-message> {{ "dtmWebAppLibOperatorMigration.summaryStep.infoMessage" | transloco }} </dtm-ui-info-message>
    <dtm-web-app-lib-operator-migration-company-details-summary
        *ngIf="vm.companyData"
        [companyInfo]="vm.companyData"
    ></dtm-web-app-lib-operator-migration-company-details-summary>
    <dtm-web-app-lib-operator-migration-operator-details-summary
        *ngIf="vm.importedDetails?.migrationData?.personalOperator as personalOperator"
        [importedOperatorDetails]="personalOperator"
    ></dtm-web-app-lib-operator-migration-operator-details-summary>
    <div class="pilot-details" *ngIf="vm.importedDetails?.migrationData?.pilot as pilot">
        <h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.summaryStep.pilotDetailsHeader" | transloco }}</h2>
        <div class="details-container">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorMigration.summaryStep.pilotNumberLabel' | transloco"
                [value]="pilot.number"
            ></dtm-ui-label-value>
        </div>
        <dtm-web-app-lib-operator-migration-operational-authorizations
            *ngIf="pilot.competencies.length"
            [operationalAuthorizations]="pilot.competencies"
            [header]="'dtmWebAppLibOperatorMigration.summaryStep.competenciesHeader' | transloco"
        >
        </dtm-web-app-lib-operator-migration-operational-authorizations>

        <dtm-ui-label-value
            *ngIf="vm.companyData"
            class="operator-number"
            [label]="'dtmWebAppLibOperatorMigration.summaryStep.operatorDetails.operatorNumberLabel' | transloco"
            [value]="'dtmWebAppLibOperatorMigration.summaryStep.numberToBeGeneratedInfo' | transloco"
        ></dtm-ui-label-value>

        <dtm-ui-address-label-value
            *ngIf="pilotAddress$ | ngrxPush as address"
            class="pilot-address"
            [address]="address"
        ></dtm-ui-address-label-value>
    </div>
</ng-container>
