<div class="grid grid-maxsize-8 grid-maxsize-tablet-10">
    <div>
        <button type="button" [routerLink]="['/operator-permits']" class="button-secondary redirect-button">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "dtmWebAppLibOperatorPermits.permitPreview.goBackToPermitsListButtonLabel" | transloco }}
        </button>

        <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
            <dtm-ui-error *ngIf="permitError$ | ngrxPush; else permitDetailsTemplate" (reload)="refreshPermit()"></dtm-ui-error>
            <ng-template #permitDetailsTemplate>
                <mat-card>
                    <h2>{{ "dtmWebAppLibOperatorPermits.permitPreview.title" | transloco : { operatorPermitType } }}</h2>
                    <dtm-ui-association-permit-preview
                        [permit]="permit$ | ngrxPush"
                        [isPermitDetailsTileExpanded]="true"
                    ></dtm-ui-association-permit-preview>
                </mat-card>
            </ng-template>
        </dtm-ui-loader-container>
    </div>
</div>
