import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { BasicAddress } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ImportedLegalGuardianDetails, LegalGuardianFormValue } from "../../../models/operator-migration.models";
import { getImportedPostCodeFormValue } from "../../../utils/get-imported-post-code-value";

interface LegalGuardianInfoComponentState {
    legalGuardian: ImportedLegalGuardianDetails | undefined;
}

interface LegalGuardianInfoForm {
    country: FormControl<string>;
    address: FormControl<BasicAddress | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-migration-legal-guardian-info[legalGuardian]",
    templateUrl: "./legal-guardian-info.component.html",
    styleUrls: ["./legal-guardian-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LegalGuardianInfoComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => LegalGuardianInfoComponent),
            multi: true,
        },
    ],
})
export class LegalGuardianInfoComponent implements ControlValueAccessor, Validator {
    @Input() public set legalGuardian(value: ImportedLegalGuardianDetails | undefined) {
        this.localStore.patchState({ legalGuardian: value });
        if (!value) {
            this.legalGuardianInfoForm.reset();

            return;
        }

        this.assignFormValues(value);
        this.propagateFormValueChange();
    }

    protected readonly legalGuardian$ = this.localStore.selectByKey("legalGuardian").pipe(RxjsUtils.filterFalsy());
    protected readonly legalGuardianInfoForm = new FormGroup<LegalGuardianInfoForm>({
        address: new FormControl<BasicAddress | null>(null, Validators.required),
        country: new FormControl<string>(DEFAULT_COUNTRY_CODE, { validators: Validators.required, nonNullable: true }),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: LegalGuardianFormValue | null) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<LegalGuardianInfoComponentState>) {
        localStore.setState({ legalGuardian: undefined });

        this.legalGuardianInfoForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.propagateFormValueChange());
    }

    public registerOnChange(fn: (value: LegalGuardianFormValue | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: LegalGuardianFormValue | null): void {
        if (value) {
            this.legalGuardianInfoForm.patchValue(value);
        }
    }

    public validate(): ValidationErrors | null {
        if (this.legalGuardianInfoForm.valid) {
            return null;
        }

        return { legalGuardianInfoFormInvalid: true };
    }

    private propagateFormValueChange(): void {
        const { address, country } = this.legalGuardianInfoForm.getRawValue();

        this.propagateChange({
            address: { ...(address as BasicAddress), country },
        });
        this.onValidationChange();
    }

    private assignFormValues(legalGuardianDetails: ImportedLegalGuardianDetails): void {
        this.legalGuardianInfoForm.patchValue({
            address: {
                streetName: legalGuardianDetails.address.streetName,
                houseNumber: legalGuardianDetails.address.houseNumber,
                apartmentNumber: legalGuardianDetails.address.apartmentNumber,
                city: legalGuardianDetails.address.city,
                postCode: getImportedPostCodeFormValue(legalGuardianDetails.address.postCode),
            },
        });
    }
}
