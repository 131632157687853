import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PermitType } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";
import { OperatorPermitsActions } from "../../../../state/operator-permits.actions";
import { OperatorPermitsState } from "../../../../state/operator-permits.state";

@Component({
    selector: "dtm-web-app-lib-association-permit-details",
    templateUrl: "./association-permit-details.component.html",
    styleUrls: ["./association-permit-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociationPermitDetailsComponent {
    protected readonly permit$ = this.store.select(OperatorPermitsState.associationPermit);
    protected readonly isProcessing$ = this.store.select(OperatorPermitsState.isProcessing);
    protected readonly permitError$ = this.store.select(OperatorPermitsState.permitError);
    protected readonly operatorPermitType = PermitType.Association;

    constructor(private readonly store: Store, private readonly route: ActivatedRoute) {}

    protected refreshPermit() {
        this.store.dispatch(
            new OperatorPermitsActions.GetAssociationPermitDetails(
                this.route.snapshot.params.permitId,
                this.route.snapshot.params.operatorId
            )
        );
    }
}
