import { Address, BasicAddress, OperationScenarioCategory, OperatorType, PhoneNumber } from "@dtm-frontend/shared/ui";

export const OPERATOR_MIGRATION_WIZARD_ID = "operator-migration-wizard";

export enum OperatorMigrationWizardSteps {
    Authentication = "Authentication",
    ResetPassword = "ResetPassword",
    DataImport = "DataImport",
    Summary = "Summary",
}

export interface AuthenticationDetails {
    email: string;
    password: string;
}

export interface OperatorMigrationError {
    type: OperatorMigrationErrorType;
}

export enum OperatorMigrationErrorType {
    Unknown = "unknown",
    NotFound = "notFound",
    WrongCredentials = "wrongCredentials",
    OperatorAlreadyImported = "operatorAlreadyImported",
    PersonalOperatorAlreadyExists = "personalOperatorAlreadyExists",
    CompanyNumberAlreadyUsed = "companyNumberAlreadyUsed",
    DetailsAlreadyImportedByOtherUser = "detailsAlreadyImportedByOtherUser",
    UnapprovedOperator = "unapprovedOperator",
    ResetPasswordError = "resetPasswordError",
}

export interface ImportedDetails {
    id: string;
    isMigrated: boolean;
    migrationData: {
        legalOperator?: ImportedEnterpriseOperatorDetails;
        personalOperator?: ImportedPersonalOperatorDetails;
        pilot?: ImportedPilotDetails;
        legalGuardian?: ImportedLegalGuardianDetails;
    };
}

export interface ImportedOperatorDetails {
    isApproved: boolean;
    number: string;
    secret: string;
    isForeign: boolean;
    operationalAuthorizations: ImportedQualification[];
    type: OperatorType;
}

export interface ImportedEnterpriseOperatorDetails extends ImportedOperatorDetails {
    companyData: {
        name: string;
        nipRegon: string;
        email: string;
        phone: PhoneNumber;
        address: ImportedDetailsAddress;
    };
}

export interface ImportedPersonalOperatorDetails extends ImportedOperatorDetails {
    personalData: {
        name: string;
        lastName: string;
        dateOfBirth: Date;
        email: string;
        phone: PhoneNumber;
        address: ImportedDetailsAddress;
    };
}

export interface ImportedPilotDetails {
    number: string;
    isApproved: boolean;
    competencies: ImportedQualification[];
}

export interface ImportedLegalGuardianDetails {
    name: string;
    lastName: string;
    dateOfBirth: Date;
    email: string;
    phone: PhoneNumber;
    address: ImportedDetailsAddress;
}

export interface ImportedQualification {
    name: string;
    expirationDate: Date;
    category: OperationScenarioCategory;
}

export interface ImportedDetailsAddress extends Omit<Address, "country"> {
    country?: string;
}

export interface LegalOperatorFormValue {
    companyNumber: string;
    address: Address;
}

// NOTE Migrated personal operator can assign company data that's why there is such info available for edition
export interface PersonalOperatorFormValue {
    companyData: BasicCompanyInfo | null;
    address: Address | BasicAddress;
}

export interface PilotFormValue {
    companyData: CompanyInfo | null;
    address: Address | BasicAddress;
}

export interface OperatorMigrationDetails {
    legalOperator: LegalOperatorFormValue | null;
    personalOperator: PersonalOperatorFormValue | null;
    pilot: PilotFormValue | null;
    legalGuardianAddress: LegalGuardianFormValue | null;
}

export interface CompanyInfo extends BasicCompanyInfo {
    address: Address;
}

export interface BasicCompanyInfo {
    name: string;
    companyNumber: string;
    phone: PhoneNumber;
    email: string;
}

export interface LegalGuardianFormValue {
    address: Address;
}
