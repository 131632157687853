import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NationalNodeUserVerifiedGuard } from "../../guards/national-node-user-verified.guard";
import { DashboardContainerComponent } from "./components/dashboard-container/dashboard-container.component";

const routes: Routes = [
    {
        path: "dashboard",
        component: DashboardContainerComponent,
        canActivate: [DtmRoleGuard, NationalNodeUserVerifiedGuard],
        data: {
            titleTranslationKey: "dtmWebAppLibDashboard.routeTitle",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [],
})
export class DashboardRoutingModule {}
