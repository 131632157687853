import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AirspaceRisk } from "../../../services/specific-permit-application.models";

interface AirspaceRiskInfoComponentState {
    airspaceRisk: AirspaceRisk | undefined;
    isExpanded: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-airspace-risk-info[airspaceRisk]",
    templateUrl: "./airspace-risk-info.component.html",
    styleUrls: ["../../common.scss", "./airspace-risk-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AirspaceRiskInfoComponent {
    @Input() public set airspaceRisk(value: AirspaceRisk | undefined) {
        this.localStore.patchState({ airspaceRisk: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly airspaceRisk$ = this.localStore.selectByKey("airspaceRisk").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<AirspaceRiskInfoComponentState>) {
        this.localStore.setState({
            airspaceRisk: undefined,
            isExpanded: false,
        });
    }
}
