<div class="grid grid-maxsize-8" *ngIf="capabilities$ | ngrxPush as capabilities; else noCapabilitiesTemplate">
    <dtm-ui-lib-wizard-content [wizardId]="wizardId">
        <dtm-web-app-lib-register-operator-basic-data
            *dtmUiLibWizardStepContent="RegisterOperatorWizardSteps.BasicData"
            (basicDataAssignAndGoToNextStep)="assignBasicDataAndGoToNextStep($event)"
            (foreignOperatorCheck)="checkForeignOperator($event)"
            [foreignOperatorValidation]="foreignOperatorValidation$ | ngrxPush"
            [isIdentityDocumentRequired]="capabilities.isIdentityDocumentRequired"
            [originCountry]="capabilities.originCountry"
            [initialValues]="basicData$ | ngrxPush"
        ></dtm-web-app-lib-register-operator-basic-data>
        <dtm-web-app-lib-register-operator-additional-data
            *dtmUiLibWizardStepContent="RegisterOperatorWizardSteps.AdditionalData"
            (additionalDataFormSave)="assignAdditionalDataAndGoToNextStep($event)"
            (back)="goBackToFirstStep()"
            (attorneyPowerTemplateDownload)="downloadAttorneyPowerTemplate()"
            [isLegalGuardianRequired]="capabilities.isLegalGuardianRequired"
            [isAssociation]="isAssociation$ | ngrxPush"
            [initialValues]="additionalData$ | ngrxPush"
        ></dtm-web-app-lib-register-operator-additional-data>
        <dtm-web-app-lib-register-operator-summary
            *dtmUiLibWizardStepContent="RegisterOperatorWizardSteps.Summary"
            [isProcessing]="isProcessing$ | ngrxPush"
            [operatorRegistrationFormValue]="operatorRegistrationFormValue$ | ngrxPush"
            (summaryDataSave)="registerOperator($event)"
            (goToBasicDataStep)="assignBasicDataStep()"
            (goToAdditionalDataStep)="goToAdditionalDataStep()"
        ></dtm-web-app-lib-register-operator-summary>
    </dtm-ui-lib-wizard-content>
</div>

<ng-template #noCapabilitiesTemplate>
    <dtm-ui-error
        *ngIf="isOperatorRegistrationCapabilitiesError$ | ngrxPush; else loaderTemplate"
        (reload)="refreshCapabilities()"
    ></dtm-ui-error>
</ng-template>
<ng-template #loaderTemplate>
    <div class="loader">
        <dtm-ui-loader
            [shouldSetParentPositionRelative]="true"
            [isShown]="isOperatorRegistrationCapabilitiesProcessing$ | ngrxPush"
        ></dtm-ui-loader>
    </div>
</ng-template>
