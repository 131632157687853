import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { DASHBOARD_ENDPOINTS, DashboardEndpoints } from "../dashboard.tokens";
import {
    DashboardMissionResponseBodyWithPages,
    PostResponseBody,
    convertDashboardMissionResponseBodyToDashboardMissionWithTotalElementsNumber,
    convertPostResponseBodyToPostWithLanguages,
} from "./dashboard-api.converters";
import {
    DashboardErrorType,
    DashboardMissionsFilters,
    DashboardMissionsWithTotalElementsNumber,
    PostWithLanguages,
} from "./dashboard.models";

const maxMissionRecordsNumber = 10;

@Injectable()
export class DashboardApiService {
    constructor(private readonly httpClient: HttpClient, @Inject(DASHBOARD_ENDPOINTS) private readonly endpoints: DashboardEndpoints) {}

    public getPosts(): Observable<PostWithLanguages[]> {
        return this.httpClient.get<PostResponseBody[]>(this.endpoints.getPosts).pipe(
            map((posts: PostResponseBody[]) => posts.map((post: PostResponseBody) => convertPostResponseBodyToPostWithLanguages(post))),
            catchError(() => throwError(() => ({ type: DashboardErrorType.CannotGetPosts })))
        );
    }

    public getMissions(operatorId: string, filters?: DashboardMissionsFilters): Observable<DashboardMissionsWithTotalElementsNumber> {
        let params = new HttpParams().set("operatorId", operatorId);
        if (filters?.searchByText) {
            params = params.append("freeText", filters.searchByText);
        }

        return this.httpClient.get<DashboardMissionResponseBodyWithPages>(this.endpoints.getMissions, { params }).pipe(
            map((response: DashboardMissionResponseBodyWithPages) =>
                convertDashboardMissionResponseBodyToDashboardMissionWithTotalElementsNumber(response)
            ),
            catchError(() => throwError(() => ({ type: DashboardErrorType.CannotGetMissions })))
        );
    }
}
