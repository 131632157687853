<div class="mission-type-container" [formGroup]="missionTypeFormGroup">
    <mat-slide-toggle labelPosition="before" [formControlName]="MissionType.VLOS">
        <p class="toggle-content">
            <dtm-ui-icon name="eye"></dtm-ui-icon>
            {{ "dtmWebAppLibShared.missionTypeLabel" | transloco : { value: MissionType.VLOS } }}
        </p>
    </mat-slide-toggle>
    <mat-slide-toggle labelPosition="before" [formControlName]="MissionType.BVLOS">
        <p class="toggle-content">
            <dtm-ui-icon name="eye-off"></dtm-ui-icon>
            {{ "dtmWebAppLibShared.missionTypeLabel" | transloco : { value: MissionType.BVLOS } }}
        </p>
    </mat-slide-toggle>
</div>
