<ng-container
    *ngrxLet="{
        companyInfo: companyInfo$,
        importedOperatorDetails: importedOperatorDetails$,
        operatorAddress: operatorAddress$
    } as vm"
>
    <dtm-ui-info-message> {{ "dtmWebAppLibOperatorMigration.summaryStep.infoMessage" | transloco }} </dtm-ui-info-message>
    <ng-container *ngIf="vm.companyInfo; else personalOperatorTemplate">
        <dtm-web-app-lib-operator-migration-company-details-summary
            [companyInfo]="vm.companyInfo"
        ></dtm-web-app-lib-operator-migration-company-details-summary>

        <dtm-web-app-lib-operator-migration-operator-details-summary
            [importedOperatorDetails]="vm.importedOperatorDetails"
        ></dtm-web-app-lib-operator-migration-operator-details-summary>
    </ng-container>

    <ng-template #personalOperatorTemplate>
        <div class="personal-operator-container">
            <h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.summaryStep.operatorDetails.header" | transloco }}</h2>
            <div class="details-container">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorMigration.summaryStep.operatorDetails.operatorNumberLabel' | transloco"
                    [value]="vm.importedOperatorDetails.number"
                ></dtm-ui-label-value>
            </div>
            <dtm-web-app-lib-operator-migration-operational-authorizations
                *ngIf="vm.importedOperatorDetails.operationalAuthorizations.length"
                [operationalAuthorizations]="vm.importedOperatorDetails.operationalAuthorizations"
            ></dtm-web-app-lib-operator-migration-operational-authorizations>

            <dtm-ui-label-value
                class="pilot-number"
                [label]="'dtmWebAppLibOperatorMigration.summaryStep.pilotNumberLabel' | transloco"
                [value]="'dtmWebAppLibOperatorMigration.summaryStep.numberToBeGeneratedInfo' | transloco"
            >
            </dtm-ui-label-value>
            <dtm-ui-address-label-value [address]="vm.operatorAddress"></dtm-ui-address-label-value>
        </div>
    </ng-template>
</ng-container>
