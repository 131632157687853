import { HttpClient, HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, UploadedFile, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { OPERATOR_PERMISSIONS_ENDPOINTS, OperatorPermissionsEndpoints } from "../operator-permissions.tokens";

@Injectable()
export class AdministrativeFeeInStatementApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATOR_PERMISSIONS_ENDPOINTS) private readonly endpoints: OperatorPermissionsEndpoints,
        private readonly store: Store
    ) {}

    public getFile(fileId: string): Observable<Blob> {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);
        if (!operatorId) {
            return throwError(() => ({ type: FileUploadErrorType.Unknown }));
        }

        return this.httpClient.get(
            StringUtils.replaceInTemplate(this.endpoints.downloadAdministrativeFeeFile, {
                documentId: fileId,
                operatorId,
            }),
            {
                responseType: "blob",
            }
        );
    }

    public getFilesCompressed(): Observable<Blob> {
        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }

        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }

    public uploadFile(file: File): Observable<HttpEvent<UploadedFile>> {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);
        if (!operatorId) {
            return throwError(() => ({ type: FileUploadErrorType.Unknown }));
        }

        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(StringUtils.replaceInTemplate(this.endpoints.uploadAdministrativeFeeFile, { operatorId }), formData, {
                reportProgress: true,
                responseType: "json",
                observe: "events",
            })
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }
}
