import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OperationScenarioCategory } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { ImportedOperatorDetails } from "../../../models/operator-migration.models";

interface OperatorDetailsPreviewComponentState {
    importedOperatorDetails: ImportedOperatorDetails | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-details-preview[importedOperatorDetails]",
    templateUrl: "./operator-details-preview.component.html",
    styleUrls: ["./operator-details-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorDetailsPreviewComponent {
    @Input() public set importedOperatorDetails(value: ImportedOperatorDetails | undefined) {
        this.localStore.patchState({ importedOperatorDetails: value });
    }

    protected readonly importedOperatorDetails$ = this.localStore.selectByKey("importedOperatorDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly OperationScenarioCategory = OperationScenarioCategory;

    constructor(private readonly localStore: LocalComponentStore<OperatorDetailsPreviewComponentState>) {
        localStore.setState({ importedOperatorDetails: undefined });
    }
}
