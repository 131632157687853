import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DAYS_IN_WEEK, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { MissionGroupByPeriod, MissionPlanContent } from "../../../models/mission.model";

interface MissionListComponentState {
    missionPlanList: MissionPlanContent[] | undefined;
    isProcessing: boolean;
    isExtendedMode: boolean;
    groupBy: MissionGroupByPeriod | undefined;
    areFiltersApplied: boolean;
}

interface Grouped {
    type?: MissionGroupByPeriod;
    firstDate: Date;
    secondDate?: Date;
}

type MissionWithGroupInfo = MissionPlanContent & { grouped: Grouped };

@Component({
    selector: "dtm-web-app-lib-mission-plan-list[missionPlanList]",
    templateUrl: "./mission-plan-list.component.html",
    styleUrls: ["./mission-plan-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionPlanListComponent {
    @Output() public missionPlanDelete: EventEmitter<string> = new EventEmitter();
    @Output() public missionPlanClone: EventEmitter<string> = new EventEmitter();
    @Output() public missionCancel: EventEmitter<string> = new EventEmitter();
    @Output() public missionPlanDownloadKml: EventEmitter<string> = new EventEmitter();

    @Input() public set missionPlanList(value: MissionPlanContent[] | undefined) {
        this.localStore.patchState({ missionPlanList: value ?? [] });
    }
    @Input() public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }
    @Input() public set isExtendedMode(value: boolean | undefined) {
        this.localStore.patchState({ isExtendedMode: !!value });
    }
    @Input() public set groupBy(value: MissionGroupByPeriod | undefined) {
        this.localStore.patchState({ groupBy: value });
    }
    @Input() public set areFiltersApplied(value: boolean | undefined) {
        this.localStore.patchState({ areFiltersApplied: !!value });
    }

    protected readonly missionPlanList$ = this.localStore.selectByKey("missionPlanList");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isExtendedMode$ = this.localStore.selectByKey("isExtendedMode");
    protected readonly groupBy$ = this.localStore.selectByKey("groupBy");
    protected readonly groupedMissionPlanList$: Observable<MissionWithGroupInfo[] | undefined> = combineLatest([
        this.missionPlanList$,
        this.groupBy$,
    ]).pipe(map(([missions, groupBy]) => this.getGroupedMissionList(missions, groupBy)));
    protected readonly areFiltersApplied$ = this.localStore.selectByKey("areFiltersApplied");

    protected readonly MissionGroupByPeriod = MissionGroupByPeriod;

    constructor(private readonly localStore: LocalComponentStore<MissionListComponentState>) {
        this.localStore.setState({
            missionPlanList: undefined,
            isProcessing: false,
            groupBy: undefined,
            isExtendedMode: false,
            areFiltersApplied: false,
        });
    }

    protected trackMission(index: number, mission: MissionWithGroupInfo): string {
        return mission.id;
    }

    private getGroupedMissionList(
        missions?: MissionPlanContent[],
        groupBy?: MissionGroupByPeriod
    ): (MissionPlanContent & { grouped: Grouped })[] | undefined {
        return missions?.map((mission) => {
            let firstDate: Date;
            let secondDate: Date | undefined;
            const sortDate = mission.flightStartAtMin;
            switch (groupBy) {
                case MissionGroupByPeriod.Day: {
                    const day = new Date(sortDate);
                    day.setHours(0, 0, 0, 0);
                    firstDate = day;
                    break;
                }
                case MissionGroupByPeriod.Week: {
                    const weekStart = new Date(sortDate);
                    weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);
                    weekStart.setHours(0, 0, 0, 0);
                    const weekEnd = new Date(weekStart);
                    weekEnd.setDate(weekStart.getDate() + DAYS_IN_WEEK - 1);
                    firstDate = weekStart;
                    secondDate = weekEnd;
                    break;
                }
                case MissionGroupByPeriod.Month: {
                    const month = new Date(sortDate);
                    month.setDate(1);
                    month.setHours(0, 0, 0, 0);
                    firstDate = month;
                    break;
                }
                case MissionGroupByPeriod.Year: {
                    const year = new Date(sortDate);
                    year.setMonth(0);
                    year.setDate(1);
                    year.setHours(0, 0, 0, 0);
                    firstDate = year;
                    break;
                }
                default:
                    firstDate = new Date(sortDate);
            }

            return {
                ...mission,
                grouped: {
                    type: groupBy,
                    firstDate,
                    secondDate,
                },
            };
        });
    }
}
