<ng-container
    *ngrxLet="{ heightLimits: heightLimits$, entity: entity$, flightSpeedType: flightSpeedType$, displayMode: displayMode$ } as vm"
>
    <dtm-ui-expandable-panel
        class="segment"
        [hasHeaderSeparator]="false"
        [isExpanded]="false"
        *ngIf="vm.entity && vm.displayMode.canShow.segment !== false"
    >
        <dtm-ui-expandable-extended-header
            headerSlot
            [label]="
                'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.segmentHeader' | transloco : { value: vm.entity.label$ | ngrxPush }
            "
        >
            <span suffix class="segment-heights-summary">
                <ng-container *ngIf="vm.heightLimits; else amslOffsetLoadingTemplate">
                    {{
                        "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.segmentHeightSummaryLabel"
                            | transloco
                                : {
                                      min: vm.heightLimits.min | number : "1.0-0" | localizeNumber,
                                      max: vm.heightLimits.max | number : "1.0-0" | localizeNumber
                                  }
                    }}
                </ng-container>
            </span>
            <button
                suffix
                type="button"
                class="button-icon"
                [matMenuTriggerFor]="shapeMenu"
                (click)="$event.stopPropagation()"
                *ngIf="vm.displayMode.canEdit.segment.addShape"
            >
                <dtm-ui-icon name="add-circle"></dtm-ui-icon>
            </button>
            <mat-menu #shapeMenu="matMenu">
                <button mat-menu-item (click)="addInternalCylinder.emit()" type="button">
                    <dtm-ui-icon name="shape-cylinder"></dtm-ui-icon>
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.addInternalCylinder" | transloco }}
                </button>
                <button mat-menu-item (click)="addInternalPrism.emit()" type="button">
                    <dtm-ui-icon name="shape-prism"></dtm-ui-icon>
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.addInternalPrism" | transloco }}
                </button>
            </mat-menu>
        </dtm-ui-expandable-extended-header>

        <div class="segment-summary">
            <svg xmlns="http://www.w3.org/2000/svg" width="295" height="100" fill="none">
                <g class="shape-body">
                    <path
                        class="shape-back-plane"
                        style="fill: var(--shape-sides-color); stroke: var(--shape-borders-color); stroke-width: 2"
                        d="M36.4 63.3v-60h177v60z"
                    />
                    <path
                        class="shape-top-plane"
                        style="stroke: var(--shape-borders-color); stroke-width: 2"
                        d="m8.4 12.3 27.6-9h176.4l-27.7 9z"
                    />
                    <path
                        class="shape-bottom-plane"
                        style="stroke: var(--shape-borders-color); stroke-width: 2"
                        d="m9.4 72.3 27.6-9h176.4l-27.7 9z"
                    />
                    <path
                        class="shape-top-plane"
                        style="fill: var(--shape-sides-color); stroke: var(--shape-borders-color); stroke-width: 2"
                        d="M8.4 72.3v-60h177v60z"
                    />
                </g>

                <g class="distance-and-time" *ngIf="vm.displayMode.canShow.segment.distanceAndTime">
                    <path
                        class="distance-arrow"
                        style="fill: var(--external-points-color)"
                        d="M8 80c-.2.1-.2.5 0 .7l3.2 3.1a.5.5 0 1 0 .7-.7L9 80.3l2.8-2.8a.5.5 0 1 0-.7-.7zm160.7.7c.2-.2.2-.6 0-.7l-3.2-3.2a.5.5 0 0 0-.7.7l2.9 2.8-2.9 2.8a.5.5 0 0 0 .7.7zm-160.3.1h160v-1H8.4Z"
                    />
                    <text
                        class="disabled-label"
                        *ngrxLet="vm.entity | invoke : getSegmentDurationMinutes as segmentDurationMinutes"
                        [ngSwitch]="true"
                    >
                        <tspan
                            *ngSwitchCase="!!segmentDurationMinutes"
                            x="36.9"
                            y="97.9"
                            dtmWebAppLibMwiesipDistanceAndTimeDisplay
                            [distance]="vm.entity.distance"
                            [time]="segmentDurationMinutes"
                        ></tspan>
                        <tspan *ngSwitchCase="!segmentDurationMinutes" x="36.9" y="97.9">
                            <ng-container [ngTemplateOutlet]="amslOffsetLoadingTemplate"></ng-container>
                        </tspan>
                    </text>
                </g>

                <g class="amsl-labels">
                    <text class="disabled-label">
                        <tspan x="237.4" y="8.9" *ngIf="vm.heightLimits">
                            {{ vm.heightLimits.max | number : "1.0-0" | localizeNumber }}
                        </tspan>
                    </text>
                    <text class="disabled-label">
                        <tspan x="237.4" y="62.9" *ngIf="vm.heightLimits">
                            {{ vm.heightLimits.min | number : "1.0-0" | localizeNumber }}
                        </tspan>
                    </text>
                    <text class="disabled-label">
                        <tspan x="225.4" y="88.9">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.shapeViewAmslLegend" | transloco }}
                        </tspan>
                    </text>
                    <path
                        class="buffer-height-arrow"
                        style="fill: var(--buffer-height-color)"
                        d="M225.7 4a.5.5 0 0 0-.7 0l-3.2 3a.5.5 0 0 0 .7.7l2.9-2.8 2.8 2.8a.5.5 0 0 0 .7-.7zm-.7 59.7c.2.2.5.2.7 0l3.2-3.2a.5.5 0 0 0-.7-.7l-2.8 2.8-2.9-2.8a.5.5 0 0 0-.7.7zm0-59.4v59h1v-59z"
                    />
                </g>

                <path
                    class="buffer-width-arrow"
                    style="fill: var(--buffer-width-color)"
                    d="M211.8 21.5c.1-.2 0-.5-.2-.6l-4-2a.5.5 0 0 0-.5.8l3.6 1.8-1.8 3.6a.5.5 0 0 0 .9.5zM187 29.1c-.1.2 0 .5.2.7l4 2a.5.5 0 0 0 .5-1l-3.6-1.7 1.8-3.6a.5.5 0 0 0-.9-.4zm24.3-8.3-24 8 .3 1 24-8z"
                />

                <g class="inlet-point">
                    <circle style="fill: var(--external-points-color)" cx="7.4" cy="43.3" r="3" />
                    <text class="enabled-label">
                        <tspan x=".9" y="58.9">{{ vm.entity.inletPointLabel$ | ngrxPush }}</tspan>
                    </text>
                </g>
                <g class="outlet-point">
                    <circle style="fill: var(--external-points-color)" cx="184.4" cy="43.3" r="3" />
                    <text class="enabled-label">
                        <tspan x="176.9" y="59.9">{{ vm.entity.outletPointLabel$ | ngrxPush }}</tspan>
                    </text>
                </g>
            </svg>
        </div>
        <div class="segment-controls" *ngIf="constraints$ | ngrxPush as constraints">
            <dtm-ui-input-field class="segment-buffer-height">
                <dtm-ui-icon
                    name="arrow-up-down-2"
                    class="field-prefix"
                    [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.segmentBufferHeightLabel' | transloco"
                ></dtm-ui-icon>
                <input
                    matInput
                    dtmUiClampInput
                    type="number"
                    inputmode="numeric"
                    [disabled]="!vm.displayMode.canEdit.segment.bufferHeight"
                    [value]="vm.entity.verticalBuffer | roundNumber"
                    [min]="constraints.min.verticalNavigationAccuracy * 2"
                    [max]="constraints | invoke : getVerticalBufferMaxValue"
                    step="2"
                    required
                    #segmentBufferHeightInput
                    (clampedInput)="bufferHeightChange.emit(segmentBufferHeightInput.valueAsNumber)"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightUnitLabel" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field class="segment-buffer-width">
                <dtm-ui-icon
                    name="arrow-left-right-2"
                    class="field-prefix"
                    [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.segmentBufferWidthLabel' | transloco"
                ></dtm-ui-icon>
                <input
                    matInput
                    dtmUiClampInput
                    type="number"
                    inputmode="numeric"
                    [disabled]="!vm.displayMode.canEdit.segment.bufferWidth"
                    [value]="vm.entity.horizontalBuffer | roundNumber"
                    [min]="constraints.min.horizontalNavigationAccuracy * 2"
                    [max]="constraints.max.horizontalNavigationAccuracy * 2"
                    step="2"
                    required
                    #segmentBufferWidthInput
                    (clampedInput)="bufferWidthChange.emit(segmentBufferWidthInput.valueAsNumber)"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.widthUnitLabel" | transloco }}
                </div>
            </dtm-ui-input-field>

            <dtm-ui-input-field class="segment-speed" *ngIf="vm.displayMode.canShow.segment.speed">
                <dtm-ui-icon
                    name="dashboard-2"
                    class="field-prefix"
                    [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.segmentSpeedLabel' | transloco"
                ></dtm-ui-icon>
                <input
                    *ngIf="vm.entity.speed !== undefined; else amslOffsetLoadingTemplate"
                    matInput
                    dtmUiClampInput
                    type="number"
                    inputmode="numeric"
                    [value]="
                        (vm.flightSpeedType === FlightSpeedType.KilometersPerHour ? (vm.entity.speed | convertToKmPerH) : vm.entity.speed)
                            | roundNumber
                    "
                    step="1"
                    required
                    disabled
                    #segmentSpeedInput
                />
                <div class="field-suffix">
                    {{ vm.flightSpeedType }}
                </div>
            </dtm-ui-input-field>
        </div>
    </dtm-ui-expandable-panel>
</ng-container>

<ng-template #amslOffsetLoadingTemplate>
    ładuję...
    <!-- TODO: DTM-4845 implement loaders when designed -->
</ng-template>
