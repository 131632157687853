import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FormType, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { OperationScenario, StaffCompetencies } from "../../../services/specific-permit-application.models";

interface CompetenciesFormComponentState {
    availableOperationScenarios: OperationScenario[];
    isEuRegulationCompetencyEnabled: boolean;
    selectableCompetencies: CompetencyOption[];
    isAdditionalCompetenciesOptionSelected: boolean;
}

enum CompetencyOptionType {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    OperationScenario = "OPERATION_SCENARIO",
    EuRegulation = "EU_REGULATION",
    Additional = "ADDITIONAL",
}

type CompetencyOption =
    | { type: CompetencyOptionType.OperationScenario; operationScenario: OperationScenario }
    | { type: CompetencyOptionType.EuRegulation }
    | { type: CompetencyOptionType.Additional };

interface CompetenciesFormValues {
    basic: CompetencyOption[] | null;
    additional: string[];
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-competencies-form[availableOperationScenarios]",
    templateUrl: "./competencies-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CompetenciesFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CompetenciesFormComponent),
            multi: true,
        },
    ],
})
export class CompetenciesFormComponent implements ControlValueAccessor, Validator {
    @Input() public set availableOperationScenarios(value: OperationScenario[] | undefined) {
        this.localStore.patchState({ availableOperationScenarios: value ?? [] });
    }

    @Input() public set isEuRegulationCompetencyEnabled(value: BooleanInput) {
        this.localStore.patchState({ isEuRegulationCompetencyEnabled: coerceBooleanProperty(value) });
    }

    protected readonly competenciesForm = new FormGroup<FormType<CompetenciesFormValues>>({
        basic: new FormControl<CompetencyOption[]>([], {
            validators: Validators.required,
            nonNullable: true,
        }),
        additional: new FormControl<string[]>([], {
            nonNullable: true,
        }),
    });

    protected readonly selectableCompetencies$ = this.localStore.selectByKey("selectableCompetencies");
    protected readonly isAdditionalCompetenciesOptionSelected$ = this.localStore.selectByKey("isAdditionalCompetenciesOptionSelected");

    protected readonly CompetencyOptionType = CompetencyOptionType;

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: StaffCompetencies | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<CompetenciesFormComponentState>) {
        this.localStore.setState({
            availableOperationScenarios: [],
            isEuRegulationCompetencyEnabled: false,
            selectableCompetencies: [],
            isAdditionalCompetenciesOptionSelected: false,
        });

        this.buildSelectableOptions();
        this.handleAdditionalCompetenciesChange();

        this.competenciesForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.propagateChange(this.convertFormValuesToCompetencies(values as CompetenciesFormValues));
        });
    }

    public registerOnChange(fn: (value: StaffCompetencies | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: StaffCompetencies | null): void {
        if (value) {
            this.competenciesForm.setValue(this.convertCompetenciesToFormValues(value), { emitEvent: false });
            this.localStore.patchState({ isAdditionalCompetenciesOptionSelected: !!value.additional.length });
        } else {
            this.competenciesForm.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.competenciesForm.disable();
        } else {
            this.competenciesForm.enable();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.competenciesForm.invalid) {
            return { required: true };
        }

        return null;
    }

    private buildSelectableOptions(): void {
        combineLatest([
            this.localStore.selectByKey("availableOperationScenarios"),
            this.localStore.selectByKey("isEuRegulationCompetencyEnabled"),
        ])
            .pipe(
                map(([availableOperationScenarios, isEuRegulationCompetencyEnabled]) =>
                    this.createCompetencyOptions(availableOperationScenarios, isEuRegulationCompetencyEnabled)
                ),
                untilDestroyed(this)
            )
            .subscribe((selectableCompetencies) => this.localStore.patchState({ selectableCompetencies }));
    }

    private handleAdditionalCompetenciesChange(): void {
        this.competenciesForm.controls.basic.valueChanges
            .pipe(
                map((value) => (value ?? []).some((option) => option.type === CompetencyOptionType.Additional)),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((isAdditionalCompetenciesOptionSelected) => {
                if (!isAdditionalCompetenciesOptionSelected) {
                    this.competenciesForm.controls.additional.reset();
                }

                this.localStore.patchState({ isAdditionalCompetenciesOptionSelected });
            });
    }

    private convertCompetenciesToFormValues(competencies: StaffCompetencies): CompetenciesFormValues {
        return {
            basic: this.getCompetencyOptions(
                competencies.basic,
                competencies.hasEuRegulationCompetency,
                competencies.additional.length > 0
            ),
            additional: competencies.additional,
        };
    }

    private createCompetencyOptions(
        operationScenarios: OperationScenario[],
        isEuRegulationCompetencyOptionEnabled: boolean = true,
        isAdditionalCompetenciesOptionEnabled: boolean = true
    ) {
        const competencyOptions: CompetencyOption[] = [
            ...operationScenarios.map((operationScenario) => ({
                type: CompetencyOptionType.OperationScenario,
                operationScenario,
            })),
        ];

        if (isEuRegulationCompetencyOptionEnabled) {
            competencyOptions.push({ type: CompetencyOptionType.EuRegulation });
        }

        if (isAdditionalCompetenciesOptionEnabled) {
            competencyOptions.push({ type: CompetencyOptionType.Additional });
        }

        return competencyOptions;
    }

    private getCompetencyOptions(
        operationScenarios: OperationScenario[],
        isEuRegulationCompetencyOptionEnabled: boolean = true,
        isAdditionalCompetenciesOptionEnabled: boolean = true
    ) {
        return this.localStore.selectSnapshotByKey("selectableCompetencies").filter((competency) => {
            if (competency.type === CompetencyOptionType.OperationScenario) {
                return operationScenarios.some((operationScenario) => operationScenario.id === competency.operationScenario.id);
            } else if (competency.type === CompetencyOptionType.EuRegulation) {
                return isEuRegulationCompetencyOptionEnabled;
            } else if (competency.type === CompetencyOptionType.Additional) {
                return isAdditionalCompetenciesOptionEnabled;
            }

            return false;
        });
    }

    private convertFormValuesToCompetencies(values: CompetenciesFormValues): StaffCompetencies | null {
        const basicOptions = values.basic ?? [];

        return {
            basic: basicOptions.reduce<OperationScenario[]>(
                (competencies, option) =>
                    option.type === CompetencyOptionType.OperationScenario ? [...competencies, option.operationScenario] : competencies,
                []
            ),
            additional: basicOptions.some((option) => option.type === CompetencyOptionType.Additional) ? values.additional : [],
            hasEuRegulationCompetency: basicOptions.some((option) => option.type === CompetencyOptionType.EuRegulation),
        };
    }
}
