<ng-container *ngrxLet="properties$ as properties">
    <ng-container *ngrxLet="isNarrowMode$ as isNarrowMode">
        <section>
            <dtm-ui-expandable-panel *ngIf="properties.technicalSpecification" [hasHeaderSeparator]="false" [isExpanded]="true">
                <h4 class="section-title" headerSlot>
                    {{ "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.sectionHeader" | transloco }}
                </h4>
                <dtm-web-app-lib-flight-technical-properties
                    class="properties"
                    [properties]="properties.technicalSpecification"
                    [class.narrow]="isNarrowMode"
                ></dtm-web-app-lib-flight-technical-properties>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel *ngIf="properties.technicalSpecification" [hasHeaderSeparator]="false" [isExpanded]="true">
                <h4 class="section-title" headerSlot>
                    {{ "dtmWebAppLibUav.uavProperties.generalTechnicalProperties.sectionHeader" | transloco }}
                </h4>
                <dtm-web-app-lib-other-technical-properties
                    class="properties"
                    [properties]="properties.technicalSpecification"
                    [class.narrow]="isNarrowMode"
                ></dtm-web-app-lib-other-technical-properties>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel
                *ngFor="let communication of properties.communications"
                [hasHeaderSeparator]="false"
                [isExpanded]="true"
            >
                <div headerSlot>
                    <h4 class="section-title">
                        {{ "dtmWebAppLibUav.uavProperties.communicationProperties.sectionHeader" | transloco }}
                    </h4>
                    <span class="chip light">
                        {{
                            communication.isEmbedded
                                ? ("dtmWebAppLibUav.uavProperties.communicationProperties.isEmbeddedLabel" | transloco)
                                : ("dtmWebAppLibUav.uavProperties.communicationProperties.isCustomLabel" | transloco)
                        }}
                    </span>
                </div>
                <dtm-web-app-lib-communication-properties
                    class="properties"
                    [properties]="communication"
                    [class.narrow]="isNarrowMode"
                ></dtm-web-app-lib-communication-properties>
            </dtm-ui-expandable-panel>
            <dtm-ui-expandable-panel *ngIf="properties.documents" [hasHeaderSeparator]="false" [isExpanded]="true">
                <div headerSlot>
                    <h2 class="section-title">
                        {{ "dtmWebAppLibUav.uavDocuments.setup.sectionHeader" | transloco }}
                    </h2>
                </div>
                <dtm-ui-files-groups-display [groups]="properties.documents"></dtm-ui-files-groups-display>
            </dtm-ui-expandable-panel>
        </section>
        <section class="equipment">
            <ng-container *ngIf="properties.trackings?.length || properties.equipment.length; else emptyEquipmentTemplate">
                <ng-container *ngrxLet="trackers$ as trackers">
                    <dtm-ui-expandable-panel *ngFor="let tracking of properties.trackings" [hasHeaderSeparator]="false" [isExpanded]="true">
                        <div headerSlot>
                            <h4 class="section-title">
                                {{ "dtmWebAppLibUav.uavProperties.trackings.sectionHeader" | transloco }}
                            </h4>
                            <span class="chip light">
                                {{
                                    tracking.isEmbedded
                                        ? ("dtmWebAppLibUav.uavProperties.trackings.isEmbeddedLabel" | transloco)
                                        : ("dtmWebAppLibUav.uavProperties.trackings.isCustomLabel" | transloco)
                                }}
                            </span>
                        </div>
                        <dtm-web-app-lib-tracking-properties
                            class="properties narrow"
                            [properties]="tracking"
                            [trackers]="trackers"
                            [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
                        ></dtm-web-app-lib-tracking-properties>
                    </dtm-ui-expandable-panel>
                </ng-container>

                <dtm-ui-expandable-panel *ngFor="let equipmentItem of properties.equipment" [hasHeaderSeparator]="false" isExpanded>
                    <div headerSlot>
                        <h4 class="section-title">
                            <ng-container *ngIf="equipmentItem.item.name">
                                {{
                                    "dtmWebAppLibUav.uavProperties.equipment.typeWithNameLabel"
                                        | transloco
                                            : {
                                                  type:
                                                      ("dtmWebAppLibUav.uavProperties.equipment.typeValue"
                                                      | transloco : { value: equipmentItem.type }),
                                                  name: equipmentItem.item.name
                                              }
                                }}
                            </ng-container>
                            <ng-container *ngIf="!equipmentItem.item.name">
                                {{ "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: equipmentItem.type } }}
                            </ng-container>
                        </h4>
                        <span class="chip light" *ngIf="equipmentItem.type === EquipmentType.Parachute && equipmentItem.item.isEmbedded">
                            {{ "dtmWebAppLibUav.uavProperties.equipment.parachute.isEmbeddedLabel" | transloco }}
                        </span>
                    </div>
                    <dtm-web-app-lib-equipment-properties
                        class="properties narrow"
                        [properties]="equipmentItem"
                    ></dtm-web-app-lib-equipment-properties>
                </dtm-ui-expandable-panel>
            </ng-container>

            <ng-template #emptyEquipmentTemplate>
                <dtm-ui-empty-state *ngIf="isEmptyEquipmentVisible$ | ngrxPush" [mode]="EmptyStateMode.Secondary">
                    <ng-container messageSlot>
                        {{ "dtmWebAppLibUav.uavProperties.equipment.noEquipmentLabel" | transloco }}
                    </ng-container>
                    <img imageSlot src="assets/images/empty-accessories.svg" alt="" />
                </dtm-ui-empty-state>
            </ng-template>
        </section>
    </ng-container>
</ng-container>
