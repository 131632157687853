import { ComponentPortal } from "@angular/cdk/portal";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { Notification } from "@dtm-frontend/shared/notifications";
import { ContextOperator, DeviceSize, DeviceSizeService, RouteDataService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { OperatorContextActions, OperatorContextState, WebAppNotificationsService } from "@dtm-frontend/web-app-lib/shared";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

interface HeaderComponentState {
    notificationsList: Notification[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderComponent {
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly operatorContexts$ = this.store.select(OperatorContextState.operatorsSortedByName);
    protected readonly isAllowedToRegisterOperator$ = this.store.select(OperatorContextState.isAllowedToRegisterOperator);
    protected readonly isRequiredToAcceptTermsOfService$ = this.store.select(OperatorContextState.isRequiredToAcceptTermsOfService);
    protected readonly isAllowedToRegisterPilot$ = this.store.select(OperatorContextState.isAllowedToRegisterPilot);
    protected readonly pilot$ = this.store.select(OperatorContextState.pilot);
    protected readonly selectedContext$ = this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy());
    protected readonly isRequiredRegisterNationalNodeAuthenticatedUser$ = this.store.select(
        OperatorContextState.isRequiredRegisterNationalNodeAuthenticatedUser
    );
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly userId$ = this.store.select(AuthState.userId);
    protected readonly userName$ = combineLatest([
        this.store.select(AuthState.userFirstName),
        this.store.select(AuthState.userLastName),
    ]).pipe(map(([firstName, lastName]) => `${firstName} ${lastName}`));
    protected readonly componentPortalItem$ = this.routeDataService.routeData$.pipe(
        map((data) => data?.headerComponent),
        distinctUntilChanged(),
        map((headerComponent) => {
            if (!headerComponent) {
                return;
            }

            return new ComponentPortal(headerComponent);
        })
    );
    protected readonly allowedNotificationTypes = this.webAppNotificationsService.getAllowedNotifications();
    protected readonly notificationsList$ = this.localStore.selectByKey("notificationsList");
    protected readonly avatarUrl$ = this.store.select(OperatorContextState.user).pipe(
        RxjsUtils.filterFalsy(),
        map((data) => data.avatarUrl)
    );
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly routeDataService: RouteDataService,
        private readonly localStore: LocalComponentStore<HeaderComponentState>,
        private readonly webAppNotificationsService: WebAppNotificationsService,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        localStore.setState({
            notificationsList: undefined,
        });
    }

    protected logout() {
        this.store.dispatch(new AuthActions.Logout());
    }

    protected redirect(url: string) {
        this.router.navigate([url]);
    }

    protected selectContext(operator: ContextOperator) {
        this.store.dispatch(new OperatorContextActions.TrySelectContext(operator));
    }

    protected changeNotificationsList(notificationsList: Notification[] | undefined) {
        this.localStore.patchState({ notificationsList });
    }
}
