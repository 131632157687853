import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { AdjacentAreaRisk } from "../../../services/specific-permit-application.models";

interface AdjacentAreaRiskInfoComponentState {
    adjacentAreaRisk: AdjacentAreaRisk | undefined;
    isExpanded: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-adjacent-area-risk-info[adjacentAreaRisk]",
    templateUrl: "./adjacent-area-risk-info.component.html",
    styleUrls: ["../../common.scss", "./adjacent-area-risk-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AdjacentAreaRiskInfoComponent {
    @Input() public set adjacentAreaRisk(value: AdjacentAreaRisk | undefined) {
        this.localStore.patchState({ adjacentAreaRisk: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly adjacentAreaRisk$ = this.localStore.selectByKey("adjacentAreaRisk").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<AdjacentAreaRiskInfoComponentState>) {
        this.localStore.setState({
            adjacentAreaRisk: undefined,
            isExpanded: false,
        });
    }
}
