<dtm-ui-expandable-panel
    *ngrxLet="{
        tacticalMitigationPerformanceRequirementProperty: tacticalMitigationPerformanceRequirementProperty$,
        tacticalMitigationPerformanceRequirements: tacticalMitigationPerformanceRequirements$,
        residualArc: residualArc$,
        isEditable: isEditable$,
        isExpanded: isExpanded$
    } as vm"
    [hasHeaderSeparator]="false"
    [isDisabled]="vm.isEditable"
    [isExpanded]="vm.isExpanded"
>
    <div class="header" headerSlot>
        <h2 headerSlot>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.header" | transloco }}
            <dtm-ui-popover
                (click)="$event.preventDefault()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.tacticalRiskMitigationMeasuresTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </h2>
        <button
            *ngIf="vm.isEditable && vm.tacticalMitigationPerformanceRequirementProperty !== TacticalMitigationPerformanceRequirement.A"
            type="button"
            class="button-secondary add-button"
            (click)="addOrEdit()"
        >
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.addButtonLabel" | transloco }}
        </button>
    </div>

    <div
        class="tmpr-error"
        *ngIf="vm.isEditable && !vm.tacticalMitigationPerformanceRequirements && vm.residualArc >= MAXIMUM_RESIDUAL_ARC"
    >
        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.tmprErrorLabel" | transloco }}
    </div>

    <div class="content">
        <div class="levels">
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.levelsLabel"
                    | transloco : { value: vm.tacticalMitigationPerformanceRequirementProperty }
            }}
        </div>

        <div class="level-description" *ngIf="!vm.tacticalMitigationPerformanceRequirements; else requirementsMetTemplate">
            <dtm-ui-icon name="information-fill"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.levelsDescription"
                    | transloco : { value: vm.tacticalMitigationPerformanceRequirementProperty }
            }}
        </div>

        <ng-template #requirementsMetTemplate>
            <p class="requirements-met-header">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.requirementsMetDescriptionLabel"
                        | transloco : { value: vm.tacticalMitigationPerformanceRequirementProperty }
                }}
            </p>

            <ng-container *ngFor="let categoryKey of categoryKeys">
                <div class="requirement-met" *ngIf="vm.tacticalMitigationPerformanceRequirements?.[categoryKey] as requirement">
                    <p class="title">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.tacticalRiskMitigationMeasuresStatements"
                                | transloco : { value: categoryKey }
                        }}
                    </p>
                    <p class="user-measures">
                        {{ requirement.comment }}
                    </p>
                </div>
            </ng-container>
        </ng-template>
    </div>
</dtm-ui-expandable-panel>
