<div class="grid grid-maxsize-8 grid-maxsize-tablet-10">
    <dtm-ui-wizard-step-wrapper
        [stepNumber]="2"
        [stepsAmount]="stepsAmount$ | ngrxPush"
        [title]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.cardHeader' | transloco"
        [nextButtonLabel]="
            isPaymentFeatureAvailable
                ? ('dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.nextButtonLabel' | transloco)
                : ('dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.goToSummaryButtonLabel' | transloco)
        "
        (back)="back.emit()"
        (next)="submitForm()"
        dtmUiInvalidFormScrollable
        *ngrxLet="isPaymentFeatureAvailable$ as isPaymentFeatureAvailable"
    >
        <div *ngIf="uavsRequiredPermissions$ | ngrxPush as uavsRequiredPermissions">
            <form [formGroup]="permissionsForm">
                <div class="permissions-container">
                    <dtm-web-app-lib-scenario-item
                        *ngFor="let permission of uavsRequiredPermissions"
                        [scenario]="permission"
                        [isPaymentFeatureAvailable]="isPaymentFeatureAvailable"
                    >
                        <div class="uav-permission" [formGroupName]="permission.scenarioId">
                            <ol *ngrxLet="availableUavs$ as availableUavs" class="uav-list">
                                <li *ngFor="let control of permissionsForm.controls[permission.scenarioId].controls; let index = index">
                                    <div class="uav-permission-label">
                                        {{
                                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavsUsedForPermissionLabel"
                                                | transloco : { index: index + 1 }
                                        }}
                                        <button
                                            type="button"
                                            class="button-icon"
                                            [disabled]="permissionsForm.controls[permission.scenarioId].controls.length === 1"
                                            (click)="removeUav(permission.scenarioId, index)"
                                        >
                                            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                                        </button>
                                    </div>
                                    <dtm-ui-select-field [formControlName]="index" [isClearable]="false" class="uav-permission-field">
                                        <label>{{
                                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavLabel" | transloco
                                        }}</label>
                                        <dtm-ui-select-option
                                            *ngFor="let availableUav of availableUavs | invoke : getAvailableUavsForPermission : permission"
                                            [value]="availableUav"
                                        >
                                            {{ availableUav.name }}
                                        </dtm-ui-select-option>
                                        <!--NOTE: cannot use dtmUiFieldHasError as it does not refresh after changes in other control-->
                                        <div class="field-error" *ngIf="control.errors?.notUnique">
                                            {{
                                                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavAlreadySelectedError"
                                                    | transloco
                                            }}
                                        </div>
                                        <div class="field-error" *dtmUiFieldHasError="control; name: 'required'">
                                            {{
                                                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavRequiredError"
                                                    | transloco
                                            }}
                                            <ng-container *ngIf="permission.allowedUavClasses.length">
                                                {{
                                                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.uavRequiredClassError"
                                                        | transloco
                                                            : {
                                                                  allowedUavClassesLength: permission.allowedUavClasses.length,
                                                                  allowedUavClasses: permission.allowedUavClasses | allowedUavClassesNames
                                                              }
                                                }}
                                            </ng-container>
                                        </div>
                                    </dtm-ui-select-field>
                                </li>
                            </ol>
                            <button
                                type="button"
                                (click)="addUavToPermission(permission.scenarioId)"
                                class="button-secondary add-uav-button"
                            >
                                <dtm-ui-icon name="edit"></dtm-ui-icon>
                                {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.addUavLabel" | transloco }}
                            </button>
                        </div>
                    </dtm-web-app-lib-scenario-item>
                </div>
            </form>
        </div>
        <ng-container *ngrxLet="otherPossiblePermissions$ as otherPossiblePermissions">
            <div *ngIf="otherPossiblePermissions.length">
                <h4 class="permissions-header">
                    {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.otherPermissionsHeader" | transloco }}
                </h4>
                <div class="permissions-container">
                    <dtm-web-app-lib-scenario-item
                        *ngFor="let permission of otherPossiblePermissions"
                        [scenario]="permission"
                        [isPaymentFeatureAvailable]="isPaymentFeatureAvailable"
                    >
                        <p>{{ permission.description }}</p>
                    </dtm-web-app-lib-scenario-item>
                </div>
            </div>
        </ng-container>
    </dtm-ui-wizard-step-wrapper>
</div>
