import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Address, IdentityDocumentType } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    ForeignOperatorValidationError,
    ForeignOperatorValidationErrorType,
    ForeignOperatorVerificationDetails,
    PilotRegistrationCapabilities,
    RegisterPilotOperatorLegalGuardian,
} from "../../shared/models";
import { PilotRegistrationError, PilotRegistrationErrorType, PilotRegistrationFormValue } from "./models";

export interface GetPilotRegistrationCapabilitiesResponseBody {
    legalGuardianRequired: boolean;
    identityDocumentRequired: boolean;
    easaCountries: string[];
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: string;
    originCountry: string;
}

export interface PilotRegistrationRequestPayload {
    identityDocumentId?: string;
    identityDocumentExpirationDate?: string;
    identityDocumentType?: IdentityDocumentType;
    insurancePolicyNumber?: string;
    insurancePolicyExpirationDate?: string;
    address: Address;
    legalGuardian?: RegisterPilotOperatorLegalGuardian;
    registeredInOtherCountry: boolean;
    operatorNumber?: ForeignOperatorVerificationDetails;
}

export function transformGetPilotRegistrationCapabilitiesResponseBodyToPilotRegistrationCapabilities(
    response: GetPilotRegistrationCapabilitiesResponseBody
): PilotRegistrationCapabilities {
    return {
        isLegalGuardianRequired: response.legalGuardianRequired,
        isIdentityDocumentRequired: response.identityDocumentRequired,
        easaCountries: response.easaCountries,
        firstName: response.firstName,
        lastName: response.lastName,
        dateOfBirth: DateUtils.convertStringDateToDateWithoutTimeZone(response.dateOfBirth),
        nationality: response.nationality,
        originCountry: response.originCountry,
    };
}

export function transformPilotRegistrationsErrorResponse(errorResponse: HttpErrorResponse): PilotRegistrationError {
    switch (errorResponse.status) {
        default:
            return { type: PilotRegistrationErrorType.Unknown };
    }
}

export function convertHttpErrorResponseToForeignOperatorVerificationError(error: HttpErrorResponse): ForeignOperatorValidationError {
    switch (error.status) {
        case HttpStatusCode.BadRequest:
            return { type: ForeignOperatorValidationErrorType.OperatorInvalid };
        default:
            return { type: ForeignOperatorValidationErrorType.Unknown };
    }
}

export function transformPilotRegistrationToOPilotRegistrationBody(formValue: PilotRegistrationFormValue): PilotRegistrationRequestPayload {
    const body: PilotRegistrationRequestPayload = {
        address: formValue.address,
        registeredInOtherCountry: formValue.isRegisteredInOtherCountry,
    };

    if (formValue.identityDocument?.length && formValue.identityDocumentExpirationDate && formValue.identityDocumentType) {
        body.identityDocumentId = formValue.identityDocument[0].id;
        body.identityDocumentExpirationDate = DateUtils.getISOStringDate(formValue.identityDocumentExpirationDate.toISOString());
        body.identityDocumentType = formValue.identityDocumentType;
    }
    if (formValue.insurancePolicyNumber) {
        body.insurancePolicyNumber = formValue.insurancePolicyNumber;
    }
    if (formValue.insurancePolicyExpirationDate) {
        body.insurancePolicyExpirationDate = DateUtils.getISOStringDate(formValue.insurancePolicyExpirationDate.toISOString());
    }
    if (formValue.legalGuardian) {
        body.legalGuardian = formValue.legalGuardian;
    }
    if (formValue.isRegisteredInOtherCountry && formValue.foreignOperator) {
        body.operatorNumber = formValue.foreignOperator;
    }

    return body;
}
