<ng-container *ngrxLet="trainings$ as trainings">
    <ng-content select="[headerSlot]"></ng-content>
    <ul>
        <li class="feature-item" *ngFor="let training of trainings">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <span class="feature-value">{{ training }}</span>
        </li>
    </ul>
    <div class="no-basic-trainings" *ngIf="!trainings.length">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.noTrainingsSelectedLabel" | transloco }}
    </div>
</ng-container>
