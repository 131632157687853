import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { LegacyDateAdapter as DateAdapter } from "@angular/material/legacy-core";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterLink } from "@angular/router";
import { CustomDateAdapter, IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoTestingModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { TrainingExamResultsContainerComponent } from "./components/training-exam-results-container/training-exam-results-container.component";
import { TrainingExamResultsFiltersComponent } from "./components/training-exam-results-filters/training-exam-results-filters.component";
import { TrainingExamResultsHeaderComponent } from "./components/training-exam-results-header/training-exam-results-header.component";
import { TrainingExamResultsListComponent } from "./components/training-exam-results-list/training-exam-results-list.component";
import { TrainingExamResultsApiService } from "./services/training-exam-results-api.service";
import { TrainingExamResultsState } from "./state/training-exam-results.state";
import { TRAINING_EXAM_RESULTS_ENDPOINTS } from "./training-exam-results.tokens";

@NgModule({
    declarations: [
        TrainingExamResultsContainerComponent,
        TrainingExamResultsHeaderComponent,
        TrainingExamResultsFiltersComponent,
        TrainingExamResultsListComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NgxsModule.forFeature([TrainingExamResultsState]),
        TranslocoTestingModule,
        IconDirective,
        SharedUiModule,
        SharedI18nModule,
        ClipboardModule,
        PushModule,
        RouterLink,
        MatTabsModule,
        MatMenuModule,
        MatCardModule,
        MatInputModule,
        LetModule,
        ReactiveFormsModule,
        MatLegacyTableModule,
    ],
    exports: [
        TrainingExamResultsContainerComponent,
        TrainingExamResultsHeaderComponent,
        TrainingExamResultsFiltersComponent,
        TrainingExamResultsListComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibTrainingExamResults",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: TrainingExamResultsApiService,
            useValue: undefined,
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
    ],
})
export class TrainingExamResultsModule {
    public static forRoot(): ModuleWithProviders<TrainingExamResultsModule> {
        return {
            ngModule: TrainingExamResultsModule,
            providers: [TrainingExamResultsApiService],
        };
    }
    public static forTest(): ModuleWithProviders<TrainingExamResultsModule> {
        return {
            ngModule: TrainingExamResultsModule,
            providers: [
                {
                    provide: TrainingExamResultsApiService,
                    useValue: null,
                },
                {
                    provide: TRAINING_EXAM_RESULTS_ENDPOINTS,
                    useValue: {},
                },
            ],
        };
    }
}
