import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FilesGroup } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { OPERATIONS_MANUAL_ENDPOINTS, OperationsManualEndpoints } from "../operations-manual.tokens";
import {
    AddSubchapterResponseBody,
    CapabilitiesResponseBody,
    ChapterResponseBody,
    OperationsManualInfoResponseBody,
    RemoveSubchapterResponseBody,
    SaveChapterResponseBody,
    SetAttachmentsResponseBody,
    TableOfContentsResponseBody,
    convertAddSubchapterResponseBodyToTableOfContents,
    convertCapabilitiesResponseBodyToCapabilities,
    convertChapterAttachmentsToAttachmentsRequestPayload,
    convertChapterResponseBodyToChapter,
    convertCreateOperationsManualResponseBodyToOperationsManualVersion,
    convertRemoveSubchapterResponseBodyToTableOfContents,
    convertSaveChapterResponseBodyToDate,
    convertSetChapterAttachmentsResponseBodyToDate,
    convertTableOfContentsResponseBodyToTableOfContents,
} from "./operations-manual-api.converters";
import {
    Capabilities,
    Chapter,
    ChapterAttachmentType,
    OperationsManualErrorType,
    OperationsManualVersion,
    TableOfContents,
} from "./operations-manual.models";

@Injectable({
    providedIn: "root",
})
export class OperationsManualApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATIONS_MANUAL_ENDPOINTS) private readonly endpoints: OperationsManualEndpoints
    ) {}

    public getCapabilities(operatorId: string): Observable<Capabilities> {
        return this.httpClient
            .get<CapabilitiesResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getCapabilities, { operatorId }))
            .pipe(
                map((capabilities) => convertCapabilitiesResponseBodyToCapabilities(capabilities)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotGetCapabilities })))
            );
    }

    public createOperationsManual(operatorId: string): Observable<OperationsManualVersion> {
        return this.httpClient
            .post<OperationsManualInfoResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.createOperationsManual, { operatorId }),
                {}
            )
            .pipe(
                map((response) => convertCreateOperationsManualResponseBodyToOperationsManualVersion(response)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotCreateOperationsManual })))
            );
    }

    public getTableOfContents(
        operatorId: string,
        currentOperationsManual: OperationsManualVersion,
        filters: string[]
    ): Observable<TableOfContents> {
        return this.httpClient
            .get<TableOfContentsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getTableOfContents, {
                    operatorId,
                    operationsManualId: currentOperationsManual.id,
                }),
                {
                    params: new HttpParams().set("contentsTableFilters", filters.join(",")),
                }
            )
            .pipe(
                map((response) => convertTableOfContentsResponseBodyToTableOfContents(response, currentOperationsManual)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotGetTableOfContents })))
            );
    }

    public publish(operatorId: string, operationsManual: OperationsManualVersion): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.publish, { operatorId, operationsManualId: operationsManual.id }), {})
            .pipe(catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotPublish }))));
    }

    public downloadPdf(operatorId: string, operationsManual: OperationsManualVersion, fileName: string): Observable<Blob> {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.downloadPdf, { operatorId, operationsManualId: operationsManual.id }), {
                responseType: "blob",
            })
            .pipe(
                tap((blob: Blob) => saveAs(blob, fileName)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotDownloadPdf })))
            );
    }

    public getChapter(
        operatorId: string,
        operationsManual: OperationsManualVersion,
        chapterId: string,
        filters: string[]
    ): Observable<Chapter> {
        return this.httpClient
            .get<ChapterResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getChapter, {
                    operatorId,
                    operationsManualId: operationsManual.id,
                    chapterId,
                }),
                {
                    params: new HttpParams().set("contentsTableFilters", filters.join(",")),
                }
            )
            .pipe(
                map((response) => convertChapterResponseBodyToChapter(response)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotGetChapter })))
            );
    }

    public saveChapterContent(
        operatorId: string,
        operationsManual: OperationsManualVersion,
        chapterId: string,
        content: string | null
    ): Observable<Date> {
        return this.httpClient
            .put<SaveChapterResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.saveChapter, {
                    operatorId,
                    operationsManualId: operationsManual.id,
                    chapterId,
                }),
                { value: content }
            )
            .pipe(
                map((response) => convertSaveChapterResponseBodyToDate(response)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotSaveChapter })))
            );
    }

    public setChapterAttachments(
        operatorId: string,
        operationsManual: OperationsManualVersion,
        chapterId: string,
        attachments: FilesGroup<ChapterAttachmentType>[]
    ): Observable<Date> {
        return this.httpClient
            .put<SetAttachmentsResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.setChapterAttachments, {
                    operatorId,
                    operationsManualId: operationsManual.id,
                    chapterId,
                }),
                convertChapterAttachmentsToAttachmentsRequestPayload(attachments)
            )
            .pipe(
                map((response) => convertSetChapterAttachmentsResponseBodyToDate(response)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotSetAttachments })))
            );
    }

    public addSubchapter(operatorId: string, operationsManual: OperationsManualVersion, name: string): Observable<TableOfContents> {
        return this.httpClient
            .post<AddSubchapterResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.addSubchapter, {
                    operatorId,
                    operationsManualId: operationsManual.id,
                }),
                { name }
            )
            .pipe(
                map((response) => convertAddSubchapterResponseBodyToTableOfContents(response)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotAddSubchapter })))
            );
    }

    public removeSubchapter(
        operatorId: string,
        operationsManual: OperationsManualVersion,
        subchapterId: string
    ): Observable<TableOfContents> {
        return this.httpClient
            .delete<RemoveSubchapterResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.removeSubchapter, {
                    operatorId,
                    operationsManualId: operationsManual.id,
                    subchapterId,
                })
            )
            .pipe(
                map((response) => convertRemoveSubchapterResponseBodyToTableOfContents(response)),
                catchError(() => throwError(() => ({ type: OperationsManualErrorType.CannotRemoveSubchapter })))
            );
    }
}
