export namespace FlightTrackingActions {
    export class EnrichFlightPositionUpdates {
        public static readonly type = "[Flight Tracking] Enrich flight position updates";
        constructor(public identifier: string) {}
    }

    export class CleanUpFlightTrackingState {
        public static readonly type = "[Flight Tracking] Clean up flight tracking state";
    }
}
