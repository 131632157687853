import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MapEntityType, Polyline3DEntity } from "@dtm-frontend/shared/map/cesium";
import { MissionPlanRoute, Point } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { mergeMap } from "rxjs";
import { map } from "rxjs/operators";
import { CylinderItineraryEntity, ItineraryContent } from "../../../../../models/itinerary.model";
import { MissionPlanItineraryConstraints } from "../../../../../models/mission.model";
import {
    DISABLED_TRACK_DETAILS_DISPLAY_MODE_CAN_EDIT,
    TrackDetailsDisplayMode,
} from "../../itinerary-editor-step/itinerary-panel/components/track-details/track-details-display-mode";
import { ItineraryPanelSettings } from "../../itinerary-editor-step/itinerary-panel/itinerary-panel.models";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface RoutePanelComponentComponentState {
    itineraryContent: ItineraryContent;
    settings: ItineraryPanelSettings | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    currentPlanRoute: MissionPlanRoute | undefined;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-route-panel",
    templateUrl: "./route-panel.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class RoutePanelComponent {
    protected readonly displayMode: TrackDetailsDisplayMode = {
        canEdit: DISABLED_TRACK_DETAILS_DISPLAY_MODE_CAN_EDIT,
        canShow: {
            segment: {
                distanceAndTime: true,
                speed: false,
            },
            shape: {
                stopover: false,
            },
            point: {
                height: true,
            },
            corridor: false,
        },
        canDelete: {
            point: false,
            shape: false,
            corridor: false,
        },
    };

    @Input() public set itineraryContent(value: ItineraryContent | undefined) {
        const itineraryContent = Array.isArray(value) ? value : [];
        this.localStore.patchState({ itineraryContent });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set currentPlanRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ currentPlanRoute: value });
    }
    @Input() public set settings(value: ItineraryPanelSettings | undefined) {
        this.localStore.patchState({ settings: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    protected readonly settings$ = this.localStore.selectByKey("settings").pipe(
        RxjsUtils.filterFalsy(),
        map((settings) => ({
            ...settings,
            heightType: undefined,
        }))
    );
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly currentPlanRoute$ = this.localStore.selectByKey("currentPlanRoute");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly itineraryContent$ = this.localStore.selectByKey("itineraryContent").pipe(
        mergeMap((itinerary) =>
            this.currentPlanRoute$.pipe(
                RxjsUtils.filterFalsy(),
                map((route) => this.enrichAssistedItineraryFromRoute(itinerary, route))
            )
        )
    );

    constructor(private readonly localStore: LocalComponentStore<RoutePanelComponentComponentState>) {
        this.localStore.setState({
            itineraryContent: [],
            settings: undefined,
            constraints: undefined,
            currentPlanRoute: undefined,
            isProcessing: false,
        });
    }

    private enrichAssistedItineraryFromRoute(itinerary: ItineraryContent, route: MissionPlanRoute): ItineraryContent {
        const takeOffEntity = itinerary[0] as CylinderItineraryEntity;
        const landingEntity = itinerary[1] as CylinderItineraryEntity;

        if (!takeOffEntity || !landingEntity) {
            return itinerary;
        }

        const polylineEntity: Polyline3DEntity = {
            id: "Assisted",
            childEntities: {},
            type: MapEntityType.Polyline3D,
            heights: [],
            bufferHeights: [],
            bufferWidths: [],
            positions: [],
        };

        const waypoints = new Map<string, { point: Point; buffer: number }>();

        route.sections?.forEach(({ segment, flightZone }, waypointIndex, array) => {
            if (flightZone) {
                const center = flightZone.center;
                const buffer = flightZone.flightArea.volume.ceiling - flightZone.flightArea.volume.floor;

                waypoints.set(center.name, { point: center.point, buffer });
            }

            if (flightZone && waypointIndex === 0) {
                takeOffEntity.centerPointHeight = flightZone.center.point.height;
                takeOffEntity.outletPointHeight = array[waypointIndex + 1]?.segment?.fromWaypoint.point.height;
                takeOffEntity.topHeight = flightZone.flightArea.volume.ceiling - (flightZone.flightArea.elevationMax ?? 0);
                takeOffEntity.bottomHeight = flightZone.flightArea.volume.floor - (flightZone.flightArea.elevationMin ?? 0);
            }

            if (flightZone && waypointIndex === array.length - 1) {
                landingEntity.centerPointHeight = flightZone.center.point.height;
                landingEntity.inletPointHeight = array[waypointIndex - 1]?.segment?.toWaypoint.point.height;
                landingEntity.topHeight = flightZone.flightArea.volume.ceiling - (flightZone.flightArea.elevationMax ?? 0);
                landingEntity.bottomHeight = flightZone.flightArea.volume.floor - (flightZone.flightArea.elevationMin ?? 0);
            }

            if (segment) {
                const { fromWaypoint, toWaypoint, flightArea } = segment;
                const buffer = flightArea.volume.ceiling - flightArea.volume.floor;

                waypoints.set(fromWaypoint.name, { point: fromWaypoint.point, buffer });
                waypoints.set(toWaypoint.name, { point: toWaypoint.point, buffer });
            }
        });

        polylineEntity.positions = [...waypoints.values()].map(({ point }) =>
            Cesium.Cartesian3.fromDegrees(point.longitude, point.latitude)
        );
        polylineEntity.heights = [...waypoints.values()].map(({ point: { height } }) => height);
        polylineEntity.bufferHeights = [...waypoints.values()].map(({ buffer }) => buffer);

        polylineEntity.childEntities[takeOffEntity.id] = {
            entity: takeOffEntity,
            waypointIndex: 0,
        };
        polylineEntity.childEntities[landingEntity.id] = {
            entity: landingEntity,
            waypointIndex: waypoints.size - 1,
        };

        return [polylineEntity];
    }
}
