<dtm-ui-error
    *ngIf="operatorsListError$ | ngrxPush; else operatorsListTemplate"
    [mode]="ErrorMode.Secondary"
    (reload)="operatorsListRefresh.emit()"
    [errorMessage]="'dtmWebAppMembership.operatorsList.operatorsListDownloadError' | transloco"
></dtm-ui-error>
<ng-template #operatorsListTemplate>
    <mat-card *ngrxLet="operatorsList$; let operatorsList" class="dtm-table">
        <div class="table-header">
            <p>{{ "dtmWebAppMembership.operatorsList.header" | transloco }}</p>
        </div>

        <dtm-ui-loader-container
            *ngrxLet="{ isProcessing: isMemberProcessing$, expandedElement: expandedElement$ } as vm"
            [isShown]="vm.isProcessing"
        >
            <dtm-ui-responsive-table [data]="operatorsList">
                <table
                    class="dtm-table"
                    *ngIf="operatorsList?.length; else emptyListTemplate"
                    mat-table
                    [dataSource]="operatorsList"
                    desktopTable
                    multiTemplateDataRows
                >
                    <ng-container matColumnDef="expandHandle">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button type="button" class="button-icon" (click)="$event.stopPropagation(); toggleExpandableRow(element)">
                                <dtm-ui-icon
                                    name="arrow-right"
                                    class="expand-handle"
                                    [class.expanded]="element === vm.expandedElement"
                                ></dtm-ui-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.operatorsList.statusLabel" | transloco }}</th>
                        <td mat-cell *matCellDef="let operator">
                            <ng-container
                                [ngTemplateOutlet]="statusTemplate"
                                [ngTemplateOutletContext]="{ $implicit: operator.status }"
                            ></ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="invitationDate">
                        <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.operatorsList.invitationDateLabel" | transloco }}</th>
                        <td mat-cell *matCellDef="let operator">
                            <p>{{ operator.invitationDate | localizeDate }}</p>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="membershipOperatorName">
                        <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.operatorsList.operatorName" | transloco }}</th>
                        <td mat-cell *matCellDef="let operator">
                            <p *ngIf="operator.membershipOperatorName; else missingDataTemplate">{{ operator.membershipOperatorName }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>{{ "dtmWebAppMembership.operatorsList.actionsLabel" | transloco }}</th>
                        <td mat-cell *matCellDef="let operator">
                            <button
                                [matMenuTriggerFor]="actions"
                                [matMenuTriggerData]="{ $implicit: operator }"
                                class="button-icon"
                                type="button"
                                (click)="$event.stopPropagation()"
                            >
                                <dtm-ui-icon name="more"></dtm-ui-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <div class="expanded-row-content" *ngIf="element === vm.expandedElement" @slideIn>
                                <div class="sub-cell">
                                    <span class="permissions-label">{{
                                        "dtmWebAppMembership.operatorsList.permissionsLabel" | transloco
                                    }}</span>
                                    <ng-container
                                        [ngTemplateOutlet]="permissionsTemplate"
                                        [ngTemplateOutletContext]="{ $implicit: element.permissions }"
                                    ></ng-container>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="pagination">
                        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                            <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        [class.expanded]="vm.expandedElement === row"
                        (click)="toggleExpandableRow(row)"
                    ></tr>
                    <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
                    <tr mat-footer-row *matFooterRowDef="['pagination']" class="pagination-row"></tr>
                </table>
                <div *dtmUiMobileTable="let row">
                    <div class="mobile-table-actions-row">
                        <dtm-ui-label-value [label]="'dtmWebAppMembership.operatorsList.statusLabel' | transloco">
                            <ng-container
                                [ngTemplateOutlet]="statusTemplate"
                                [ngTemplateOutletContext]="{ $implicit: row.status }"
                            ></ng-container>
                        </dtm-ui-label-value>
                        <button
                            [matMenuTriggerFor]="actions"
                            [matMenuTriggerData]="{ $implicit: row }"
                            class="button-icon"
                            type="button"
                            (click)="$event.stopPropagation()"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </div>
                    <div class="mobile-table-content">
                        <dtm-ui-label-value
                            [label]="'dtmWebAppMembership.operatorsList.invitationDateLabel' | transloco"
                            [value]="row.invitationDate | localizeDate"
                        >
                        </dtm-ui-label-value>
                        <dtm-ui-label-value [label]="'dtmWebAppMembership.operatorsList.operatorName' | transloco">
                            <p *ngIf="row.membershipOperatorName; else missingDataTemplate">{{ row.membershipOperatorName }}</p>
                        </dtm-ui-label-value>
                        <dtm-ui-label-value
                            class="permissions-container"
                            [label]="'dtmWebAppMembership.operatorsList.permissionsLabel' | transloco"
                        >
                            <ng-container
                                [ngTemplateOutlet]="permissionsTemplate"
                                [ngTemplateOutletContext]="{ $implicit: row.permissions }"
                            ></ng-container>
                        </dtm-ui-label-value>
                    </div>
                </div>
                <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
                <ng-container mobileTableNoData [ngTemplateOutlet]="emptyListTemplate"></ng-container>
            </dtm-ui-responsive-table>
        </dtm-ui-loader-container>
    </mat-card>
</ng-template>

<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>

<ng-template #paginationTemplate>
    <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
    </dtm-ui-pagination>
</ng-template>

<mat-menu #actions="matMenu">
    <ng-template matMenuContent let-operator>
        <ng-container [ngSwitch]="operator.type">
            <ng-container *ngSwitchCase="MemberType.INVITATION">
                <button type="button" mat-menu-item (click)="invitationAccept.emit(operator)">
                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.operatorsList.acceptInvitationLabel" | transloco }}
                </button>
                <button type="button" mat-menu-item (click)="invitationReject.emit(operator)">
                    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.operatorsList.rejectInvitationLabel" | transloco }}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="MemberType.MEMBER">
                <!-- TODO: REJ-2987  revert changes from REJ-2980 to restore ability to display details and create mission when time come -->
                <!--    <button type="button" mat-menu-item (click)="redirectToOperatorDetails.emit(operator.membershipOperatorId)">
                    <dtm-ui-icon name="information"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.operatorsList.operatorDetailsLabel" | transloco }}
                </button>

                <button type="button" mat-menu-item (click)="goToMissionPlanning.emit(operator)">
                    <dtm-ui-icon name="focus-2"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.operatorsList.goToMissionPlanningLabel" | transloco }}
                </button>-->
                <button type="button" mat-menu-item (click)="membershipResignation.emit(operator)">
                    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.operatorsList.membershipResignationLabel" | transloco }}
                </button>
            </ng-container>
        </ng-container>
    </ng-template>
</mat-menu>

<ng-template #missingDataTemplate>
    <p class="no-data">{{ "dtmWebAppMembership.operatorsList.missingDetailsLabel" | transloco }}</p>
</ng-template>

<ng-template #statusTemplate let-status>
    <ng-container [ngSwitch]="status">
        <span *ngSwitchCase="MembershipStatus.Active" class="chip success">
            <dtm-ui-icon name="checkbox-circle" class="status-icon"></dtm-ui-icon>
            {{ "dtmWebAppMembership.operatorsList.statusValueLabel" | transloco : { status } }}
        </span>
        <span *ngSwitchCase="MembershipStatus.Pending" class="chip warning">
            <dtm-ui-icon name="rest-time" class="status-icon"></dtm-ui-icon>
            {{ "dtmWebAppMembership.operatorsList.statusValueLabel" | transloco : { status } }}
        </span>
    </ng-container>
</ng-template>

<ng-template #permissionsTemplate let-permissions>
    <ul class="user-roles-list">
        <li *ngFor="let permission of permissions">
            {{ "dtmWebAppMembership.operatorsList.permissionValueLabel" | transloco : { permission } }}
        </li>
    </ul>
</ng-template>
