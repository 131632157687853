import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Address } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { ImportedDetails, PilotFormValue } from "../../../models/operator-migration.models";

interface PilotMigrationSummaryComponentState {
    editedPilotDetails: PilotFormValue | undefined;
    importedDetails: ImportedDetails | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-pilot-summary[editedPilotDetails][importedDetails]",
    templateUrl: "./pilot-summary.component.html",
    styleUrls: ["./pilot-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotSummaryComponent {
    @Input() public set editedPilotDetails(value: PilotFormValue | undefined) {
        this.localStore.patchState({ editedPilotDetails: value });
    }
    @Input() public set importedDetails(value: ImportedDetails | undefined) {
        this.localStore.patchState({ importedDetails: value });
    }

    protected readonly editedPilotDetails$ = this.localStore.selectByKey("editedPilotDetails");
    protected readonly importedDetails$ = this.localStore.selectByKey("importedDetails");
    protected readonly companyData$ = this.editedPilotDetails$.pipe(map((details) => details?.companyData));
    protected readonly pilotAddress$ = this.editedPilotDetails$.pipe(map((details) => details?.address as Address));

    constructor(private readonly localStore: LocalComponentStore<PilotMigrationSummaryComponentState>) {
        localStore.setState({ editedPilotDetails: undefined, importedDetails: undefined });
    }
}
