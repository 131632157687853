import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { UavClass } from "../../../../shared";

@Pipe({
    name: "allowedUavClassesNames",
})
export class AllowedUavClassesNamesPipe implements PipeTransform {
    constructor(private readonly transloco: TranslocoService) {}

    public transform(allowedClasses: UavClass[]): string {
        if (allowedClasses.length > 1) {
            return this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.allowedClassesLink", {
                left: allowedClasses.slice(0, allowedClasses.length - 1).join(", "),
                right: allowedClasses[allowedClasses.length - 1],
            });
        } else {
            return allowedClasses[0] ?? "";
        }
    }
}
