import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { legalGuardianFormValidator } from "../../../shared/components/pilot-operator-registration-legal-guardian-form/pilot-operator-registration-legal-guardian-form.component";
import { PilotOperatorRegistrationInsurance, RegisterPilotOperatorLegalGuardian } from "../../../shared/models";
import { RegisterPilotAdditionalData } from "../../services/models";

interface RegisterPilotAdditionalDataComponentState {
    isLegalGuardianRequired: boolean;
    initialValues: RegisterPilotAdditionalData | undefined;
}

interface AdditionalDataForm {
    legalGuardian: FormControl<RegisterPilotOperatorLegalGuardian>;
    isInsuranceOwner: FormControl<boolean>;
    insuranceDetails: FormControl<PilotOperatorRegistrationInsurance>;
}

@Component({
    selector: "dtm-web-app-lib-register-pilot-additional-data",
    templateUrl: "./register-pilot-additional-data.component.html",
    styleUrls: ["../../../shared/styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class RegisterPilotAdditionalDataComponent {
    @Input()
    public set isLegalGuardianRequired(value: boolean | undefined) {
        this.localStore.patchState({ isLegalGuardianRequired: !!value });

        if (value) {
            this.additionalDataForm.controls.legalGuardian.enable();
        }
    }
    @Input()
    public set initialValues(value: RegisterPilotAdditionalData | undefined) {
        this.localStore.patchState({ initialValues: value });

        if (value) {
            this.assignInitialValues(value);
        }
    }

    @Output() public additionalDataFormSave = new EventEmitter<RegisterPilotAdditionalData>();
    @Output() public goToPreviousStep = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    protected readonly insuranceDetailsControl = new FormControl<PilotOperatorRegistrationInsurance>(
        {
            value: {
                expirationDate: null,
                number: "",
            },
            disabled: true,
        },
        { nonNullable: true }
    );
    protected readonly isInsuranceOwnerControl = new FormControl<boolean>(false, { nonNullable: true });
    protected readonly legalGuardianControl = new FormControl<RegisterPilotOperatorLegalGuardian>(
        {
            value: {
                lastName: "",
                firstName: "",
                email: "",
            },
            disabled: true,
        },
        { validators: legalGuardianFormValidator, nonNullable: true }
    );
    protected readonly additionalDataForm = new FormGroup<AdditionalDataForm>({
        isInsuranceOwner: this.isInsuranceOwnerControl,
        legalGuardian: this.legalGuardianControl,
        insuranceDetails: this.insuranceDetailsControl,
    });
    protected readonly isLegalGuardianRequired$ = this.localStore.selectByKey("isLegalGuardianRequired");

    constructor(private readonly localStore: LocalComponentStore<RegisterPilotAdditionalDataComponentState>) {
        localStore.setState({
            isLegalGuardianRequired: false,
            initialValues: undefined,
        });
    }

    protected validateFormAndSave() {
        this.additionalDataForm.markAllAsTouched();

        if (this.additionalDataForm.valid) {
            this.additionalDataFormSave.emit(this.getAdditionalData());

            return;
        }

        this.invalidFormScrollable.scrollToFirstInvalidField();
    }

    protected updateInsuranceDetailsControl(insuranceOwnership: MatCheckboxChange) {
        if (insuranceOwnership.checked) {
            this.additionalDataForm.controls.insuranceDetails.enable();

            return;
        }

        this.additionalDataForm.controls.insuranceDetails.disable();
        this.additionalDataForm.controls.insuranceDetails.reset();
    }

    private getAdditionalData(): RegisterPilotAdditionalData {
        const additionalData: RegisterPilotAdditionalData = {
            insurancePolicyNumber: this.isInsuranceOwnerControl.value ? this.insuranceDetailsControl.value.number : undefined,
            insurancePolicyExpirationDate: this.insuranceDetailsControl.value.expirationDate ?? undefined,
        };

        if (this.additionalDataForm.controls.legalGuardian.enabled) {
            additionalData.legalGuardian = this.additionalDataForm.controls.legalGuardian.value;
        }

        return additionalData;
    }

    private assignInitialValues(value: RegisterPilotAdditionalData) {
        this.additionalDataForm.setValue({
            isInsuranceOwner: !!value.insurancePolicyNumber,
            legalGuardian: value.legalGuardian
                ? { ...value.legalGuardian }
                : {
                      lastName: "",
                      firstName: "",
                      email: "",
                  },
            insuranceDetails: {
                expirationDate: value.insurancePolicyExpirationDate ?? null,
                number: value.insurancePolicyNumber ?? "",
            },
        });

        if (value.insurancePolicyNumber) {
            this.additionalDataForm.controls.insuranceDetails.enable();
        }
    }
}
