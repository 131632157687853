import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { CameraHelperService, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActionType, CesiumService } from "@pansa/ngx-cesium";
import turfCenter from "@turf/center";
import { Feature, MultiPolygon, Polygon, featureCollection, feature as turFeature, polygon as turfPolygon } from "@turf/helpers";
import union from "@turf/union";
import { Subject, distinctUntilChanged, map, shareReplay } from "rxjs";
import { combineLatestWith } from "rxjs/operators";
import { PermitLocationData } from "../../../models/mission.model";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface CaaPermitLocationComponentState {
    locationData: PermitLocationData | undefined;
    permitLocationGeometry: Feature<Polygon | MultiPolygon> | undefined;
    isZoomButtonEnabled: boolean;
}

interface CesiumKmlEntityData {
    show: boolean;
    [key: string]: any;
}

type PolygonHierarchy = typeof Cesium.PolygonHierarchy;

// NOTE: Cesium doesn't handle big polygons well, so we need to split the mask into smaller polygons
// vertical overlapping of polygons causes rendering issues when it's on other side of the globe, prevented by 180 => 179.9999
/* eslint-disable no-magic-numbers */
const mainMaskPolygon = [0, 0, 0, 90, 90, 0];
const otherMaskPolygons = [
    [0, 0, 0, -90, 90, 0],
    [0, 0, 0, 90, -90, 0],
    [0, 0, 0, -90, -90, 0],
    [-90, 0, -90, 90, -179.9999, 0],
    [-90, 0, -90, -90, -179.9999, 0],
    [90, 0, 90, 90, 179.9999, 0],
    [90, 0, 90, -90, 179.9999, 0],
];
/* eslint-enable no-magic-numbers */

const MASK_OPACITY = 0.7;
const BOUNDARY_NORMAL_OUTLINE_COLOR = Cesium.Color.fromCssColorString("#007544"); // $color-status-success
const DEFAULT_OUTLINE_DASH_LENGTH = 20;
const DEFAULT_OUTLINE_DASH_PATTERN = MapUtils.createCesiumDashPattern("---------  --");
const DEFAULT_FILL_OPACITY = 0.2;
const BOUNDARY_NORMAL_FILL_COLOR = BOUNDARY_NORMAL_OUTLINE_COLOR.withAlpha(DEFAULT_FILL_OPACITY);
const DEFAULT_OUTLINE_WIDTH = 2;
const LABELS_SHOWING_DISTANCE_IN_METERS = 20000;

const OUTLINE_MATERIAL = new Cesium.PolylineDashMaterialProperty({
    color: BOUNDARY_NORMAL_OUTLINE_COLOR,
    dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
    dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
});

const LOCATION_FILL_MATERIAL = new Cesium.ImageMaterialProperty({
    transparent: false,
    image: "/assets/images/boundary_fill_pattern.png",
    color: BOUNDARY_NORMAL_FILL_COLOR,
});

const LOCATION_PIN_ID = "caa-permit-location-pin";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-caa-permit-location",
    templateUrl: "./caa-permit-location.component.html",
    styleUrls: ["./caa-permit-location.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CaaPermitLocationComponent implements AfterViewInit {
    @Input() public set locationData(value: PermitLocationData | undefined) {
        this.localStore.patchState({ locationData: value });
    }
    @Input() public set isZoomButtonEnabled(value: BooleanInput) {
        this.localStore.patchState({ isZoomButtonEnabled: coerceBooleanProperty(value) });
    }
    @Output() public readonly locationGeometry = this.localStore.selectByKey("permitLocationGeometry");

    protected readonly Cesium = Cesium;
    private masksEntity: typeof Cesium.CustomDataSource | undefined;
    private zoneEntities: (typeof Cesium.Entity)[] = [];

    protected readonly pinEntities$ = new Subject();

    protected readonly isZoomButtonVisible$ = this.cameraHelperService.postRender$.pipe(
        combineLatestWith(this.localStore.selectByKey("isZoomButtonEnabled")),
        map(
            ([, isEnabled]) =>
                isEnabled && this.cesiumService.getViewer().camera.positionCartographic.height > LABELS_SHOWING_DISTANCE_IN_METERS
        ),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    constructor(
        private readonly cesiumService: CesiumService,
        private readonly cameraHelperService: CameraHelperService,
        private readonly localStore: LocalComponentStore<CaaPermitLocationComponentState>
    ) {
        localStore.setState({ locationData: undefined, permitLocationGeometry: undefined, isZoomButtonEnabled: false });
    }

    public ngAfterViewInit(): void {
        const viewer = this.cesiumService.getViewer();

        this.localStore
            .selectByKey("locationData")
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.zoneEntities.forEach((entity) => viewer.entities.remove(entity));
                viewer.dataSources.remove(this.masksEntity);
                this.pinEntities$.next({
                    actionType: ActionType.DELETE,
                    id: LOCATION_PIN_ID,
                });

                this.cesiumService.getScene().requestRender();

                if (data?.kmlFile) {
                    this.handleKmlFile(data?.kmlFile);

                    return;
                }

                if (data?.zoneGeometry) {
                    this.createZone(
                        this.convertGeoJsonToPolygonHierarchy(turFeature(data.zoneGeometry)) ?? [],
                        turFeature(data.zoneGeometry)
                    );
                }
            });
    }

    protected zoomToLocation(area: Feature): void {
        this.cameraHelperService.flyToGeoJSON(area);
    }

    private handleKmlFile(kmlFile: Blob) {
        Cesium.KmlDataSource.load(kmlFile).then((dataSource: CesiumKmlEntityData) => {
            const hierarchies = dataSource.entities.values
                .map((entity: CesiumKmlEntityData) => entity.polygon?.hierarchy.getValue())
                .filter(FunctionUtils.isTruthy);

            this.createZone(hierarchies);
        });
    }

    private convertKmlDataToGeoJsonPolygons(hierarchies: PolygonHierarchy[]) {
        return hierarchies.map((hierarchy) => {
            const coordinates = [
                hierarchy.positions.map((position: string[]) => {
                    const cartographic = Cesium.Cartographic.fromCartesian(position);

                    return [Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude)];
                }),
                hierarchy.holes[0]?.positions.map((hole: string[]) => {
                    const cartographic = Cesium.Cartographic.fromCartesian(hole);

                    return [Cesium.Math.toDegrees(cartographic.longitude), Cesium.Math.toDegrees(cartographic.latitude)];
                }),
            ].filter(FunctionUtils.isTruthy);

            return turfPolygon(coordinates);
        });
    }

    private createZone(hierarchies: PolygonHierarchy[], originalGeometry?: Feature<Polygon | MultiPolygon>) {
        const polygons = originalGeometry ? [originalGeometry] : this.convertKmlDataToGeoJsonPolygons(hierarchies);
        const combinedPolygon = polygons.length > 1 ? union(featureCollection(polygons)) : polygons[0];
        if (!combinedPolygon) {
            return;
        }
        this.prepareMasks(hierarchies);

        this.localStore.patchState({ permitLocationGeometry: combinedPolygon });

        const center = turfCenter(combinedPolygon).geometry.coordinates;
        const cartesianCenter = MapUtils.convertSerializableCartographicToCartesian3({
            longitude: center[0],
            latitude: center[1],
            height: 0,
        });

        this.zoneEntities =
            hierarchies?.map((hole) =>
                this.cesiumService.getViewer().entities.add({
                    polygon: {
                        hierarchy: hole,
                        material: LOCATION_FILL_MATERIAL,
                        outline: false,
                    },
                })
            ) ?? [];

        this.prepareOutlines(hierarchies);

        this.pinEntities$.next({
            actionType: ActionType.ADD_UPDATE,
            id: LOCATION_PIN_ID,
            entity: { position: cartesianCenter, zoomArea: combinedPolygon },
        });

        this.cesiumService.getScene().requestRender();
        this.cameraHelperService.flyToGeoJSON(combinedPolygon, 0);
    }

    private prepareOutlines(polygonHierarchies?: PolygonHierarchy[]) {
        polygonHierarchies?.forEach((hierarchy) => {
            this.zoneEntities.push(
                this.cesiumService.getViewer().entities.add({
                    polyline: {
                        positions: hierarchy.positions,
                        material: OUTLINE_MATERIAL,
                        width: DEFAULT_OUTLINE_WIDTH,
                    },
                })
            );

            this.prepareOutlines(hierarchy.holes);
        });
    }

    private prepareMasks(hierarchies: PolygonHierarchy[]) {
        this.masksEntity = new Cesium.CustomDataSource("masks");
        this.cesiumService.getViewer().dataSources.add(this.masksEntity);

        this.masksEntity.entities.add({
            polygon: {
                hierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(mainMaskPolygon), hierarchies),
                material: Cesium.Color.GREY.withAlpha(MASK_OPACITY),
            },
        });

        otherMaskPolygons.forEach((polygon) => {
            this.masksEntity.entities.add({
                polygon: {
                    hierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray(polygon)),
                    material: Cesium.Color.GREY.withAlpha(MASK_OPACITY),
                },
            });
        });
    }

    private readonly convertGeoJsonToPolygonHierarchy = (geoJson: Feature<Polygon | MultiPolygon>): PolygonHierarchy[] | undefined => {
        const convertCoordinatesToPolygonHierarchy = (coordinates: number[][][]): typeof Cesium.PolygonHierarchy => {
            const outerBoundary = coordinates[0].map(([longitude, latitude]) =>
                MapUtils.convertSerializableCartographicToCartesian3({ longitude, latitude, height: 0 })
            );

            const innerHoles = coordinates.slice(1).map((hole) => convertCoordinatesToPolygonHierarchy([hole]));

            return new Cesium.PolygonHierarchy(outerBoundary, innerHoles);
        };

        if (geoJson.geometry.type === "Polygon") {
            return [convertCoordinatesToPolygonHierarchy(geoJson.geometry.coordinates)];
        }
        if (geoJson.geometry.type === "MultiPolygon") {
            return geoJson.geometry.coordinates.map((polygonCoords) => convertCoordinatesToPolygonHierarchy(polygonCoords));
        }

        return;
    };
}
