import { InjectionToken } from "@angular/core";

export interface OperatorConversationsEndpoints {
    getOrAddThreads: string;
    manageThread: string;
    getOrAddMessages: string;
    uploadFile: string;
    downloadFile: string;
    downloadCompressedFiles: string;
    getOperatorMessageCapabilities: string;
}
export const OPERATOR_CONVERSATIONS_ENDPOINTS = new InjectionToken<OperatorConversationsEndpoints>("Operator conversations endpoints");
