export interface TrackDetailsDisplayMode {
    canEdit: {
        point: {
            height: boolean;
            coordinates: boolean;
        };
        cylinder: {
            radius: boolean;
            centerPoint: boolean;
        };
        shape: {
            topHeight: boolean;
            bottomHeight: boolean;
            coordinates: boolean;
        };
        corridor: {
            width: boolean;
            points: boolean;
        };
        segment: {
            bufferHeight: boolean;
            bufferWidth: boolean;
            addShape: boolean;
        };
    };
    canShow: {
        segment:
            | false
            | {
                  distanceAndTime: boolean;
                  speed: boolean;
              };
        shape: {
            stopover: boolean;
        };
        point: {
            height: boolean;
        };
        corridor: boolean;
    };
    canDelete: {
        point: boolean;
        shape: boolean;
        corridor: boolean;
    };
}

export const DISABLED_TRACK_DETAILS_DISPLAY_MODE_CAN_EDIT: TrackDetailsDisplayMode["canEdit"] = {
    point: {
        height: false,
        coordinates: false,
    },
    cylinder: {
        radius: false,
        centerPoint: false,
    },
    shape: {
        topHeight: false,
        bottomHeight: false,
        coordinates: false,
    },
    corridor: {
        width: false,
        points: false,
    },
    segment: {
        bufferHeight: false,
        bufferWidth: false,
        addShape: false,
    },
};

export const DEFAULT_TRACK_DETAILS_DISPLAY_MODE: TrackDetailsDisplayMode = {
    canEdit: {
        point: {
            height: true,
            coordinates: true,
        },
        cylinder: {
            radius: true,
            centerPoint: true,
        },
        shape: {
            topHeight: true,
            bottomHeight: true,
            coordinates: true,
        },
        corridor: {
            width: true,
            points: true,
        },
        segment: {
            bufferHeight: true,
            bufferWidth: true,
            addShape: true,
        },
    },
    canShow: {
        segment: {
            distanceAndTime: true,
            speed: true,
        },
        shape: {
            stopover: true,
        },
        point: {
            height: true,
        },
        corridor: false,
    },
    canDelete: {
        point: true,
        shape: true,
        corridor: true,
    },
};
