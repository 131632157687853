import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Address } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface PilotOperatorRegistrationAddressSummaryComponentState {
    address: Address | undefined;
}

@Component({
    selector: "dtm-web-app-lib-pilot-operator-registration-address-summary[address]",
    templateUrl: "./pilot-operator-registration-address-summary.component.html",
    styleUrls: ["../../../styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotOperatorRegistrationAddressSummaryComponent {
    @Input()
    public set address(value: Address | undefined) {
        this.localStore.patchState({ address: value });
    }

    protected readonly address$ = this.localStore.selectByKey("address").pipe(RxjsUtils.filterFalsy());
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;

    constructor(private readonly localStore: LocalComponentStore<PilotOperatorRegistrationAddressSummaryComponentState>) {
        localStore.setState({ address: undefined });
    }
}
