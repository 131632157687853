import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Validators } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ItineraryEditorMissionZoneRadiusFormData } from "../../../../../../models/mission.model";
import {
    MissionWizardItineraryEditorParametersPanelBaseComponent,
    MissionWizardItineraryEditorParametersPanelBaseComponentState,
} from "../mission-parameters-panel-base.component";

type MissionWizardItineraryEditorZoneRadiusPanelComponentState =
    MissionWizardItineraryEditorParametersPanelBaseComponentState<ItineraryEditorMissionZoneRadiusFormData>;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-itinerary-editor-step-zone-radius-panel[formControls]",
    templateUrl: "./zone-radius-panel.component.html",
    styleUrls: ["./zone-radius-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardItineraryEditorZoneRadiusPanelComponent extends MissionWizardItineraryEditorParametersPanelBaseComponent<
    ItineraryEditorMissionZoneRadiusFormData,
    MissionWizardItineraryEditorZoneRadiusPanelComponentState
> {
    constructor(localStore: LocalComponentStore<MissionWizardItineraryEditorZoneRadiusPanelComponentState>) {
        super(localStore);
        this.localStore.setState({
            originalFormControls: undefined,
            formStateController: undefined,
            isDeferred: false,
            isExpanded: true,
            formControls: undefined,
            isDisabled: false,
        });

        this.localStore
            .selectByKey("formControls")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((formControls) => {
                formControls.isEnlargedZone.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
                    if (!value) {
                        formControls.isEnlargedZoneStatementAccepted.reset();
                        formControls.isEnlargedZoneStatementAccepted.removeValidators(Validators.requiredTrue);
                    } else {
                        formControls.isEnlargedZoneStatementAccepted.setValidators(Validators.requiredTrue);
                    }
                    formControls.isEnlargedZoneStatementAccepted.updateValueAndValidity();
                });
            });
    }
}
