<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <dtm-ui-wizard-step-wrapper
        (next)="save.emit()"
        (back)="back.emit()"
        [title]="'dtmWebAppLibOperatorMigration.summaryStep.header' | transloco"
        [stepsAmount]="3"
        [stepNumber]="3"
        [isBackButtonVisible]="true"
        [nextButtonLabel]="'dtmWebAppLibOperatorMigration.summaryStep.saveButtonLabel' | transloco"
        *ngrxLet="{ migrationDetails: migrationDetails$, importedDetails: importedDetails$ } as vm"
    >
        <dtm-web-app-lib-legal-operator-summary
            *ngIf="vm.migrationDetails.legalOperator"
            [editedOperatorDetails]="vm.migrationDetails.legalOperator"
            [importedDetails]="vm.importedDetails"
        ></dtm-web-app-lib-legal-operator-summary>
        <dtm-web-app-lib-operator-migration-personal-operator-summary
            *ngIf="vm.migrationDetails.personalOperator"
            [importedDetails]="vm.importedDetails"
            [editedOperatorDetails]="vm.migrationDetails.personalOperator"
        ></dtm-web-app-lib-operator-migration-personal-operator-summary>
        <dtm-web-app-lib-operator-migration-pilot-summary
            *ngIf="vm.migrationDetails.pilot"
            [importedDetails]="vm.importedDetails"
            [editedPilotDetails]="vm.migrationDetails.pilot"
        ></dtm-web-app-lib-operator-migration-pilot-summary>
        <dtm-web-app-lib-operator-migration-legal-guardian-summary
            *ngIf="vm.migrationDetails.legalGuardianAddress?.address as legalGuardianAddress"
            [legalGuardianAddress]="legalGuardianAddress"
            [legalGuardianDetails]="vm.importedDetails.migrationData.legalGuardian"
        ></dtm-web-app-lib-operator-migration-legal-guardian-summary>
    </dtm-ui-wizard-step-wrapper>
</dtm-ui-loader-container>
