import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { FunctionUtils, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged, map } from "rxjs/operators";

interface NamesControlComponentState {
    inputFieldLabel: string | undefined;
    addButtonNameLabel: string | undefined;
}

const MAX_NAME_LENGTH = 250;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-names-control",
    templateUrl: "./names-control.component.html",
    styleUrls: ["./names-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NamesControlComponent),
            multi: true,
        },
    ],
})
export class NamesControlComponent implements ControlValueAccessor {
    @Input() public set inputFieldLabel(value: string | undefined) {
        this.localStore.patchState({ inputFieldLabel: value });
    }

    @Input() public set addButtonNameLabel(value: string | undefined) {
        this.localStore.patchState({ addButtonNameLabel: value });
    }

    protected readonly inputFieldLabel$ = this.localStore.selectByKey("inputFieldLabel");
    protected readonly addButtonNameLabel$ = this.localStore.selectByKey("addButtonNameLabel");
    protected readonly namesFormArray = new FormArray<FormControl<string>>([]);

    private onTouched = FunctionUtils.noop;
    private propagateChange: (value: string[] | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<NamesControlComponentState>) {
        this.localStore.setState({
            inputFieldLabel: undefined,
            addButtonNameLabel: undefined,
        });

        this.namesFormArray.valueChanges
            .pipe(
                map((values) => this.prepareNamesValue(values)),
                distinctUntilChanged(equal),
                untilDestroyed(this)
            )
            .subscribe((values) => {
                this.propagateChange(values);
            });
    }

    public registerOnChange(fn: (value: string[] | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: string[] | undefined): void {
        this.initNamesArray(value ?? []);
    }

    protected addName(): void {
        this.namesFormArray.push(this.createNameControl());
    }

    protected removeFrequency(index: number): void {
        this.namesFormArray.removeAt(index);
    }

    private initNamesArray(names: string[]): void {
        this.namesFormArray.clear({ emitEvent: false });

        if (!names.length) {
            this.namesFormArray.push(this.createNameControl(), { emitEvent: false });

            return;
        }

        for (const name of names) {
            this.namesFormArray.push(this.createNameControl(name), { emitEvent: false });
        }
    }

    private createNameControl(name?: string): FormControl<string> {
        return new FormControl<string>(name ?? "", {
            validators: [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(MAX_NAME_LENGTH),
            ],
            nonNullable: true,
        });
    }

    private prepareNamesValue(names: string[]): string[] {
        return names.filter(FunctionUtils.isTruthy);
    }
}
