import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";

interface AttorneyPowerEmptyStateComponentState {
    companyName: string | undefined;
}

@Component({
    selector: "dtm-web-app-lib-attorney-power-empty-state[companyName]",
    templateUrl: "./attorney-power-empty-state.component.html",
    styleUrls: ["./attorney-power-empty-state.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AttorneyPowerEmptyStateComponent {
    @Input() public set companyName(value: string | undefined) {
        this.localStore.patchState({ companyName: value });
    }
    @Output() public readonly attorneyPowerFormVisibilityChange = new EventEmitter<boolean>();

    protected readonly companyName$ = this.localStore.selectByKey("companyName").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<AttorneyPowerEmptyStateComponentState>) {
        localStore.setState({ companyName: undefined });
    }
}
