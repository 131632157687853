<ng-container
    *ngrxLet="{
        displayMode: displayMode$,
        entities: entities$,
        entityInEditMode: entityInEditMode$,
        settings: settings$,
        constraints: constraints$,
        isProcessing: isProcessing$
    } as vm"
>
    <div class="basic-itinerary-content" *ngIf="!(vm.entities | isEmptyArray) && vm.settings && vm.constraints; else emptyTemplate">
        <ng-container *ngFor="let entity of vm.entities; trackBy: trackByEntity">
            <ng-container
                *ngrxLet="
                    'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.waypointTitleLabel'
                        | transloco
                            : {
                                  type: entity.type,
                                  value: entity.label$ | ngrxPush
                              } as title
                "
            >
                <ng-template
                    *ngIf="entity.type === ViewItineraryEntityType.Prism"
                    [ngTemplateOutlet]="prismViewEntityCardTemplate"
                    [ngTemplateOutletContext]="{ title: title }"
                ></ng-template>

                <ng-template
                    *ngIf="entity.type === ViewItineraryEntityType.InternalPrism"
                    [ngTemplateOutlet]="prismViewEntityCardTemplate"
                    [ngTemplateOutletContext]="{ title: title }"
                ></ng-template>

                <ng-template
                    *ngIf="
                        entity.type === ViewItineraryEntityType.Cylinder ||
                        entity.type === ViewItineraryEntityType.Takeoff ||
                        entity.type === ViewItineraryEntityType.Landing ||
                        entity.type === ViewItineraryEntityType.InternalCylinder
                    "
                    [ngTemplateOutlet]="cylinderViewEntityCardTemplate"
                    [ngTemplateOutletContext]="{ title: title }"
                ></ng-template>

                <ng-template
                    *ngIf="entity.type === ViewItineraryEntityType.Point && !vm.displayMode.canShow.corridor"
                    [ngTemplateOutlet]="pointViewEntityCardTemplate"
                    [ngTemplateOutletContext]="{ title: title, pointEntity: entity }"
                ></ng-template>

                <dtm-web-app-lib-mwiesip-segment-view-entity-card
                    *ngIf="entity.type === ViewItineraryEntityType.Segment && entity.itineraryIndex[0].type === MapEntityType.Polyline3D"
                    [entity]="entity"
                    [constraints]="vm.constraints"
                    [flightSpeedType]="vm.settings.flightSpeedType"
                    [displayMode]="vm.displayMode"
                    (bufferWidthChange)="
                        updateArrayPropertyOfEntity(entity.itineraryIndex[0], 'bufferWidths', entity.itineraryIndex[1], $event)
                    "
                    (bufferHeightChange)="
                        updateArrayPropertyOfEntity(entity.itineraryIndex[0], 'bufferHeights', entity.itineraryIndex[1], $event)
                    "
                    (addInternalCylinder)="
                        tryAddInternalEntity({
                            type: MapEntityType.Cylinder,
                            waypointIndex: entity.itineraryIndex[1],
                            polyline3DEntityId: entity.itineraryIndex[0].id
                        })
                    "
                    (addInternalPrism)="
                        tryAddInternalEntity({
                            type: MapEntityType.Prism,
                            waypointIndex: entity.itineraryIndex[1],
                            polyline3DEntityId: entity.itineraryIndex[0].id
                        })
                    "
                >
                </dtm-web-app-lib-mwiesip-segment-view-entity-card>

                <dtm-web-app-lib-mwiesip-corridor-view-entity-card
                    *ngIf="entity.type === ViewItineraryEntityType.Corridor && vm.displayMode.canShow.corridor"
                    [bufferWidths]="entity.bufferWidths"
                    [title]="title"
                    [isProcessing]="vm.isProcessing"
                    [displayMode]="vm.displayMode"
                    (bufferWidthsInputChange)="updateEntity(entity.id, { bufferWidths: $event })"
                    (entityDelete)="tryDeleteEntity(entity)"
                >
                    <ng-template
                        *ngFor="let pointEntity of vm.entities | invoke : getCorridorPointEntities : entity; trackBy: trackByEntity"
                        [ngTemplateOutlet]="pointViewEntityCardTemplate"
                        [ngTemplateOutletContext]="{
                            title:
                                'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.waypointTitleLabel'
                                | transloco
                                    : {
                                          type: pointEntity.type,
                                          value: pointEntity.label$ | ngrxPush
                                      },
                            class: 'corridor-point',
                            pointEntity: pointEntity,
                            disableEdit: !vm.displayMode.canEdit.corridor.points,
                        }"
                    ></ng-template>
                </dtm-web-app-lib-mwiesip-corridor-view-entity-card>
            </ng-container>

            <ng-template #cylinderViewEntityCardTemplate let-title="title">
                <dtm-web-app-lib-mwiesip-shape-view-entity-card
                    *ngIf="entity | invoke : ensureIsShapeEntity as shapeEntity"
                    [entity]="shapeEntity"
                    [constraints]="vm.constraints"
                    [title]="title"
                    [settings]="vm.settings"
                    [isProcessing]="vm.isProcessing"
                    [displayMode]="vm.displayMode"
                    [isCoordinatesEditMode]="vm.entityInEditMode?.id === entity.id"
                    (inletPointHeightChange)="
                        shapeEntity.itineraryIndex[0].type === MapEntityType.Polyline3D &&
                            updateArrayPropertyOfEntity(shapeEntity.itineraryIndex[0], 'heights', shapeEntity.itineraryIndex[1] - 1, $event)
                    "
                    (outletPointHeightChange)="
                        shapeEntity.itineraryIndex[0].type === MapEntityType.Polyline3D &&
                            updateArrayPropertyOfEntity(shapeEntity.itineraryIndex[0], 'heights', shapeEntity.itineraryIndex[1] + 1, $event)
                    "
                    (shapeTopHeightChange)="updateEntity(shapeEntity.id, { topHeight: $event })"
                    (shapeBottomHeightChange)="updateEntity(shapeEntity.id, { bottomHeight: $event })"
                    (stopoverChange)="updateStopover(shapeEntity, $event)"
                    (entityDelete)="tryDeleteEntity(shapeEntity)"
                    (editCenterPoint)="showPointCoordinatesEditForm(shapeEntity)"
                    (centerCoordinatesChange)="updateShapeCenterCoordinates(shapeEntity, $event)"
                >
                    <dtm-ui-input-field
                        class="radius"
                        *ngIf="
                            shapeEntity.type === ViewItineraryEntityType.Takeoff ||
                            shapeEntity.type === ViewItineraryEntityType.Landing ||
                            shapeEntity.type === ViewItineraryEntityType.InternalCylinder ||
                            shapeEntity.type === ViewItineraryEntityType.Cylinder
                        "
                    >
                        <dtm-ui-icon
                            name="complex-radius"
                            class="field-prefix"
                            [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.radiusLabel' | transloco"
                        ></dtm-ui-icon>
                        <label>{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.radiusLabel" | transloco }}</label>
                        <input
                            matInput
                            dtmUiClampInput
                            type="number"
                            inputmode="numeric"
                            [disabled]="!vm.displayMode.canEdit.cylinder.radius"
                            [value]="shapeEntity.radius | roundNumber"
                            [min]="
                                shapeEntity.type === ViewItineraryEntityType.Takeoff || shapeEntity.type === ViewItineraryEntityType.Landing
                                    ? vm.constraints.min.runwayHorizontalNavigationAccuracy
                                    : vm.constraints.min.horizontalNavigationAccuracy
                            "
                            [max]="vm.constraints.max.size"
                            step="1"
                            required
                            #radiusInput
                            (clampedInput)="updateEntity(shapeEntity.id, { radius: radiusInput.valueAsNumber })"
                        />
                        <div class="field-suffix">
                            {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.radiusUnitLabel" | transloco }}
                        </div>
                    </dtm-ui-input-field>
                </dtm-web-app-lib-mwiesip-shape-view-entity-card>
            </ng-template>

            <ng-template #prismViewEntityCardTemplate let-title="title">
                <dtm-web-app-lib-mwiesip-shape-view-entity-card
                    *ngIf="entity | invoke : ensureIsShapeEntity as shapeEntity"
                    [entity]="shapeEntity"
                    [constraints]="vm.constraints"
                    [title]="title"
                    [settings]="vm.settings"
                    [isProcessing]="vm.isProcessing"
                    [displayMode]="vm.displayMode"
                    [isCoordinatesEditMode]="vm.entityInEditMode?.id === entity.id"
                    (inletPointHeightChange)="
                        shapeEntity.itineraryIndex[0].type === MapEntityType.Polyline3D &&
                            updateArrayPropertyOfEntity(shapeEntity.itineraryIndex[0], 'heights', shapeEntity.itineraryIndex[1] - 1, $event)
                    "
                    (outletPointHeightChange)="
                        shapeEntity.itineraryIndex[0].type === MapEntityType.Polyline3D &&
                            updateArrayPropertyOfEntity(shapeEntity.itineraryIndex[0], 'heights', shapeEntity.itineraryIndex[1] + 1, $event)
                    "
                    (shapeTopHeightChange)="updateEntity(shapeEntity.id, { topHeight: $event })"
                    (shapeBottomHeightChange)="updateEntity(shapeEntity.id, { bottomHeight: $event })"
                    (stopoverChange)="updateStopover(shapeEntity, $event)"
                    (entityDelete)="tryDeleteEntity(shapeEntity)"
                    (editCenterPoint)="showPointCoordinatesEditForm(shapeEntity)"
                    (centerCoordinatesChange)="updateShapeCenterCoordinates(shapeEntity, $event)"
                >
                </dtm-web-app-lib-mwiesip-shape-view-entity-card>
            </ng-template>
        </ng-container>

        <ng-template
            #pointViewEntityCardTemplate
            let-title="title"
            let-class="class"
            let-pointEntity="pointEntity"
            let-disableEdit="disableEdit"
        >
            <div
                class="entity-card point-card"
                *ngIf="pointEntity.type === ViewItineraryEntityType.Point"
                [ngClass]="[class ?? '']"
                [class.is-editing]="vm.entityInEditMode === pointEntity"
            >
                <header>
                    <dtm-ui-icon name="dot-middle"></dtm-ui-icon>
                    <h2>{{ title }}</h2>
                    <span class="coordinates">
                        {{ pointEntity.coordinates.latitude | number : "1.0-4" | localizeNumber }};
                        {{ pointEntity.coordinates.longitude | number : "1.0-4" | localizeNumber }}
                    </span>
                    <button
                        *ngIf="vm.entityInEditMode !== pointEntity"
                        [class.hidden]="!vm.displayMode.canEdit.point.coordinates || disableEdit"
                        type="button"
                        class="coordinates-edit-button button-icon"
                        [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.coordinatesEditButtonLabel' | transloco"
                        (click)="showPointCoordinatesEditForm(pointEntity)"
                    >
                        <dtm-ui-icon name="edit"></dtm-ui-icon>
                    </button>

                    <button
                        type="button"
                        class="button-icon delete-button"
                        (click)="tryDeletePoint(pointEntity)"
                        *ngIf="pointEntity.canDelete && vm.displayMode.canDelete.point && !disableEdit"
                        [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.deletePointButtonLabel' | transloco"
                        [disabled]="vm.isProcessing"
                        [class.hidden]="!vm.displayMode.canDelete.point || disableEdit"
                    >
                        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    </button>
                </header>
                <div class="card-content">
                    <dtm-web-app-lib-mwiesip-point-controls
                        *ngIf="pointEntity.itineraryIndex[0].type === MapEntityType.Polyline3D"
                        [min]="vm.constraints.min.height"
                        [max]="vm.constraints.max.height"
                        [height]="pointEntity.height"
                        [amslOffset]="pointEntity.amslOffset"
                        [heightEditMode]="vm.settings.heightType"
                        [isCoordinatesEditMode]="vm.entityInEditMode?.id === pointEntity.id"
                        [coordinates]="pointEntity.coordinates"
                        [displayMode]="vm.displayMode"
                        type="waypoint"
                        (pointHeightInputChange)="
                            updateArrayPropertyOfEntity(pointEntity.itineraryIndex[0], 'heights', pointEntity.itineraryIndex[1], $event)
                        "
                        (pointCoordinatesChange)="updatePointCoordinates(pointEntity, $event)"
                    >
                    </dtm-web-app-lib-mwiesip-point-controls>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>

<ng-template #emptyTemplate>
    <dtm-ui-empty-state class="empty-state" [mode]="EmptyStateMode.Tertiary">
        <img imageSlot src="assets/images/itinerary-panel-empty.svg" alt="" />
        <ng-container messageSlot>{{
            "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.emptyStateMessage" | transloco
        }}</ng-container>
    </dtm-ui-empty-state>
</ng-template>
