import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective, PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { distinctUntilChanged, first, map, switchMap } from "rxjs/operators";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { requiredAtLeastOnePermissionValidator } from "../../../services/member-permissions-validator";
import {
    FleetManagementDescriptionItem,
    InvitationMean,
    MembershipError,
    MembershipErrorType,
    MembershipPermission,
    MembershipUserInvitation,
    MissionPlanningDescriptionItem,
    OperatorManageDescriptionItem,
    UavPilotingDescriptionItem,
} from "../../../services/membership.models";
import { MembershipActions } from "../../../state/membership.actions";
import { MembershipState } from "../../../state/membership.state";

const MAX_NOTE_LENGTH = 255;

interface PilotInvitationDialogComponentState {
    isProcessing: boolean;
}

interface InvitationFormGroup {
    invitationMean: FormControl<InvitationMean>;
    mail?: FormControl<string | null>;
    phoneNumber?: FormControl<PhoneNumber | null>;
    showNote: FormControl<boolean>;
    note?: FormControl<string>;
    permissions: FormGroup<MembershipPermissionFrom>;
}

interface MembershipPermissionFrom {
    [key: string]: FormControl<boolean | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-user-invitation-dialog",
    templateUrl: "./user-invitation-dialog.component.html",
    styleUrls: ["./user-invitation-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserInvitationDialogComponent implements OnInit {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly MembershipPermission = MembershipPermission;
    protected readonly OperatorManageDescriptionItem = OperatorManageDescriptionItem;
    protected readonly MissionPlanningDescriptionItem = MissionPlanningDescriptionItem;
    protected readonly UavPilotingDescriptionItem = UavPilotingDescriptionItem;
    protected readonly FleetManagementDescriptionItem = FleetManagementDescriptionItem;
    protected readonly MAX_NOTE_LENGTH = MAX_NOTE_LENGTH;
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly invitationMeanControl = new FormControl<InvitationMean>(InvitationMean.MAIL, { nonNullable: true });
    protected readonly permissionsControl = new FormGroup<MembershipPermissionFrom>(
        {},
        { validators: requiredAtLeastOnePermissionValidator() }
    );
    protected readonly InvitationMean = InvitationMean;
    protected readonly phoneNumberControl = new FormControl<PhoneNumber | null>(null, requiredValidForSmsPhoneNumberValidator);
    protected readonly mailControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
    });
    protected readonly noteControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(MAX_NOTE_LENGTH)],
        nonNullable: true,
    });
    protected readonly showNoteControl = new FormControl<boolean>(false, { nonNullable: true });
    protected readonly invitationForm = new FormGroup<InvitationFormGroup>({
        invitationMean: this.invitationMeanControl,
        mail: this.mailControl,
        showNote: this.showNoteControl,
        permissions: this.permissionsControl,
    });

    constructor(
        private readonly dialogRef: MatDialogRef<UserInvitationDialogComponent>,
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly localStore: LocalComponentStore<PilotInvitationDialogComponentState>
    ) {
        localStore.setState({ isProcessing: false });
        Object.values(MembershipPermission).forEach((permission) => {
            this.invitationForm.controls.permissions.addControl(permission, new FormControl<boolean>(false));
        });
    }

    public ngOnInit() {
        this.invitationMeanControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value: InvitationMean) => {
            if (value === InvitationMean.PHONE) {
                this.handlePhoneSelection();

                return;
            }
            this.handleMailSelection();
        });

        this.isProcessing$.pipe(untilDestroyed(this)).subscribe((isProcessing) => {
            if (isProcessing) {
                this.invitationForm.disable();

                return;
            }

            this.invitationForm.enable();
        });

        this.invitationForm.controls.permissions.controls[MembershipPermission.OperatorManage].valueChanges
            .pipe(distinctUntilChanged(), untilDestroyed(this))
            .subscribe((value) => {
                if (value) {
                    Object.values(MembershipPermission).forEach((permission) => {
                        if (permission !== MembershipPermission.OperatorManage) {
                            const control = this.invitationForm.controls.permissions.controls[permission];
                            control.setValue(true, { emitEvent: false });
                            control.disable();
                        }
                    });
                } else {
                    Object.values(MembershipPermission).forEach((permission) => {
                        const control = this.invitationForm.controls.permissions.controls[permission];
                        control.enable();
                    });
                }
            });
    }

    protected showNote(isChecked: boolean): void {
        if (isChecked && !this.invitationForm.controls["note"]) {
            this.invitationForm.addControl("note", this.noteControl);

            return;
        }

        this.noteControl.reset();

        if (this.invitationForm.controls["note"]) {
            this.invitationForm.removeControl("note");
        }
    }

    protected keepOrder(): number {
        return 0;
    }

    protected cancel() {
        this.dialogRef.close();
    }

    protected async prepareInvitation() {
        this.invitationForm.markAllAsTouched();
        if (this.invitationForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        const saveValue = {
            recipient: this.invitationForm.controls["mail"]
                ? { email: this.mailControl.value }
                : { phoneNumber: this.phoneNumberControl.value as PhoneNumber },
            note: this.noteControl.value,
            permissions: Object.entries(this.permissionsControl.getRawValue())
                .filter(([, value]) => value)
                .map(([key]) => key as MembershipPermission),
        };

        const operatorId = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(
                RxjsUtils.filterFalsy(),
                map((operator) => operator.id),
                untilDestroyed(this)
            )
        );

        this.localStore.patchState({ isProcessing: true });

        this.sendInvitation({ ...saveValue, operatorId });
    }

    private handlePhoneSelection() {
        if (this.invitationForm.controls["phoneNumber"]) {
            return;
        }
        if (this.invitationForm.controls["mail"]) {
            this.mailControl.reset();
            this.invitationForm.removeControl("mail");
        }

        this.invitationForm.addControl("phoneNumber", this.phoneNumberControl);
    }

    private handleMailSelection() {
        if (this.invitationForm.controls["mail"]) {
            return;
        }
        if (this.invitationForm.controls["phoneNumber"]) {
            this.phoneNumberControl.reset();
            this.invitationForm.removeControl("phoneNumber");
        }

        this.invitationForm.addControl("mail", this.mailControl);
    }

    private sendInvitation(invitation: MembershipUserInvitation) {
        this.store
            .dispatch(new MembershipActions.InviteUser(invitation))
            .pipe(
                switchMap(() => this.store.select(MembershipState.userInvitationError).pipe(first())),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    this.handleUsersInvitationSuccess();
                    this.dialogRef.close(true);
                } else {
                    this.localStore.patchState({ isProcessing: false });
                    this.showUsersInvitationError(error);
                }
            });
    }

    private showUsersInvitationError(error: MembershipError) {
        switch (error.type) {
            case MembershipErrorType.NotFound:
                this.toastrService.error(
                    this.translocoService.translate("dtmWebAppMembership.userInvitation.userNotFoundInvitationErrorMessage", {
                        mean: this.invitationMeanControl.value,
                    })
                );
                break;
            case MembershipErrorType.MemberWaiting:
                this.toastrService.error(
                    this.translocoService.translate("dtmWebAppMembership.userInvitation.memberWaitingStatusErrorMessage")
                );
                break;
            case MembershipErrorType.MemberAdded:
                this.toastrService.error(
                    this.translocoService.translate("dtmWebAppMembership.userInvitation.memberAddedErrorMessage", {
                        resendInvitationButtonLabel: this.translocoService.translate(
                            "dtmWebAppMembership.invitedUsersList.resendInvitationLabel"
                        ),
                    }),
                    "",
                    { extendedTimeOut: 5000 }
                );
                break;
            default:
                this.toastrService.error(this.translocoService.translate("dtmWebAppMembership.userInvitation.invitationErrorMessage"));
        }
    }

    private handleUsersInvitationSuccess() {
        this.toastrService.success(this.translocoService.translate("dtmWebAppMembership.userInvitation.invitationSuccessfullySendMessage"));
    }
}
