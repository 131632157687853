<dtm-ui-loader-container
    *ngrxLet="{ userProfile: userProfile$, error: error$, isProcessing: isProcessing$ } as vm"
    [isShown]="vm.isProcessing"
>
    <dtm-ui-error
        *ngIf="vm.error; else profileTemplate"
        [mode]="ErrorMode.Secondary"
        [errorMessage]="'dtmWebAppUserProfile.profileContainer.profileUnknownErrorMessage' | transloco"
    ></dtm-ui-error>
    <ng-template #profileTemplate>
        <div class="grid" *ngIf="vm.userProfile">
            <div class="grid-col-6 column">
                <dtm-web-app-lib-personal-data [userProfile]="vm.userProfile"></dtm-web-app-lib-personal-data>
                <dtm-web-app-lib-user-contact></dtm-web-app-lib-user-contact>
                <dtm-web-app-lib-identity-card
                    *ngIf="!vm.userProfile.isEasaMember"
                    [identityDocument]="vm.userProfile.identityDocument"
                    (download)="documentDownload()"
                    (save)="identityDocumentSave($event)"
                    (delete)="deleteIdentityDocument()"
                ></dtm-web-app-lib-identity-card>
                <dtm-web-app-lib-legal-guardian
                    *ngIf="!vm.userProfile.isLegalAge"
                    [legalGuardianConfirmationRequest]="legalGuardianConfirmationRequest$ | ngrxPush"
                    [legalGuardian]="legalGuardian$ | ngrxPush"
                    (resend)="resendLegalGuardianEmailAddress()"
                    (saveData)="legalGuardianDataSave($event)"
                ></dtm-web-app-lib-legal-guardian>
            </div>
            <div class="grid-col-6 column">
                <dtm-ui-profile-avatar
                    [canRemove]="vm.userProfile.avatarId"
                    [fullName]="fullName$ | ngrxPush"
                    [avatar]="profileAvatar$ | ngrxPush"
                    (saveAvatar)="saveProfileAvatar($event)"
                    (delete)="deleteUserAvatar()"
                ></dtm-ui-profile-avatar>
                <dtm-ui-profile-language
                    [currentLanguage]="currentLanguage$ | ngrxPush"
                    (updateLanguage)="updateProfileLanguage($event)"
                ></dtm-ui-profile-language>
                <dtm-ui-reset-password (resetPassword)="resetPassword()"></dtm-ui-reset-password>
                <dtm-web-app-lib-user-attorney-powers
                    *ngIf="vm.userProfile.attorneyPowers?.length"
                    [attorneyPowers]="vm.userProfile.attorneyPowers"
                ></dtm-web-app-lib-user-attorney-powers>
            </div>
        </div>
    </ng-template>
</dtm-ui-loader-container>
