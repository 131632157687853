import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import { WizardActions } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, race } from "rxjs";
import { combineLatestWith, first, map, switchMap } from "rxjs/operators";
import { OperatorContextActions } from "../../../../shared/operator-context/state/operator-context.actions";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { IDENTITY_DOCUMENT_API_ENDPOINTS } from "../../../../shared/services/identity-document-upload/identity-document-api.token";
import { IdentityDocumentUploadApiService } from "../../../../shared/services/identity-document-upload/identity-document-upload-api.service";
import { identityDocumentApiFactory } from "../../../operator-registration/utils/identity-document-api-factory";
import {
    ForeignOperatorVerificationDetails,
    PilotOperatorRegistrationError,
    PilotOperatorRegistrationErrorType,
} from "../../../shared/models";
import { OPERATOR_REGISTRATION_ENDPOINTS } from "../../operator-registration.tokens";
import { RegisterOperatorAdditionalData, RegisterOperatorBasicData, RegisterOperatorSummaryData } from "../../services/models";
import { isRegisteredOperator } from "../../services/typeguards";
import { OperatorRegistrationActions } from "../../state/operator-registration.actions";
import { OperatorRegistrationState } from "../../state/operator-registration.state";

export const REGISTER_OPERATOR_WIZARD_ID = "register-operator-wizard";

export enum RegisterOperatorWizardSteps {
    BasicData = "BasicData",
    AdditionalData = "AdditionalData",
    Summary = "Summary",
}

interface RegisterOperatorWizardContentComponentState {
    basicData: RegisterOperatorBasicData | undefined;
    additionalData: RegisterOperatorAdditionalData | undefined;
    summaryData: RegisterOperatorSummaryData | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-register-operator-wizard-content",
    templateUrl: "./register-operator-wizard-content.component.html",
    styles: [".loader { margin: auto; height: 100%; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: IdentityDocumentUploadApiService },
        {
            provide: IDENTITY_DOCUMENT_API_ENDPOINTS,
            useFactory: identityDocumentApiFactory,
            deps: [OPERATOR_REGISTRATION_ENDPOINTS],
        },
    ],
})
export class RegisterOperatorWizardContentComponent implements OnDestroy {
    public readonly RegisterOperatorWizardSteps = RegisterOperatorWizardSteps;
    public readonly wizardId = REGISTER_OPERATOR_WIZARD_ID;

    public readonly isProcessing$ = this.store.select(OperatorRegistrationState.isProcessing).pipe(
        combineLatestWith(this.store.select(OperatorContextState.isProcessing)),
        map(([isOperatorProcessing, isCapabilitiesProcessing]) => isOperatorProcessing || isCapabilitiesProcessing)
    );
    public readonly foreignOperatorValidation$ = this.store.select(OperatorRegistrationState.foreignOperatorValidation);
    public readonly capabilities$ = this.store.select(OperatorRegistrationState.operatorRegistrationCapabilities);
    public readonly basicData$ = this.localStore.selectByKey("basicData");
    public readonly additionalData$ = this.localStore.selectByKey("additionalData");
    public readonly operatorRegistrationFormValue$ = this.localStore.selectByKey("basicData").pipe(
        RxjsUtils.filterFalsy(),
        combineLatestWith(this.localStore.selectByKey("additionalData").pipe(RxjsUtils.filterFalsy())),
        map(([basicData, additionalData]) => ({ ...basicData, ...additionalData }))
    );
    protected readonly isAssociation$ = this.localStore.selectByKey("basicData").pipe(
        RxjsUtils.filterFalsy(),
        map((basicData) => !!basicData.associationNationalCourtRegister || !!basicData.associationRegistrationNumber)
    );
    public readonly isOperatorRegistrationCapabilitiesProcessing$ = this.store.select(
        OperatorRegistrationState.isOperatorRegistrationCapabilitiesProcessing
    );
    public readonly isOperatorRegistrationCapabilitiesError$ = this.store.select(
        OperatorRegistrationState.operatorRegistrationCapabilitiesError
    );

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<RegisterOperatorWizardContentComponentState>,
        private readonly router: Router,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {
        this.assignBasicDataStep();

        localStore.setState({ basicData: undefined, additionalData: undefined, summaryData: undefined });
    }

    public ngOnDestroy() {
        this.store.dispatch(new WizardActions.CleanupWizard(this.wizardId));
    }

    public assignBasicDataAndGoToNextStep(basicDataValue: RegisterOperatorBasicData) {
        this.localStore.patchState({ basicData: basicDataValue });

        this.goToAdditionalDataStep();
    }

    public checkForeignOperator(foreignOperator: ForeignOperatorVerificationDetails) {
        this.store.dispatch(new OperatorRegistrationActions.VerifyForeignOperator(foreignOperator));
    }

    public goBackToFirstStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, RegisterOperatorWizardSteps.BasicData),
            new WizardActions.EnableSteps(this.wizardId, [RegisterOperatorWizardSteps.BasicData]),
        ]);
    }

    public assignAdditionalDataAndGoToNextStep(additionalDataValue: RegisterOperatorAdditionalData) {
        this.localStore.patchState({ additionalData: additionalDataValue });

        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, RegisterOperatorWizardSteps.Summary),
            new WizardActions.EnableSteps(this.wizardId, [
                RegisterOperatorWizardSteps.BasicData,
                RegisterOperatorWizardSteps.AdditionalData,
                RegisterOperatorWizardSteps.Summary,
            ]),
        ]);
    }

    public refreshCapabilities() {
        this.store.dispatch(
            new OperatorRegistrationActions.GetOperatorRegistrationCapabilities(this.store.selectSnapshot(AuthState.userId))
        );
    }

    public downloadAttorneyPowerTemplate() {
        this.store
            .dispatch(new OperatorRegistrationActions.DownloadAttorneyTemplate())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorRegistrationState.downloadAttorneyTemplateError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate(
                            "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.cannotDownloadAttorneyTemplate"
                        )
                    );
                }
            });
    }

    public registerOperator(summaryFormValue: RegisterOperatorSummaryData) {
        this.localStore.patchState({ summaryData: summaryFormValue });

        const basicData = this.localStore.selectSnapshotByKey("basicData");
        const additionalData = this.localStore.selectSnapshotByKey("additionalData");

        if (!basicData || !additionalData) {
            return;
        }

        this.store
            .dispatch(
                new OperatorRegistrationActions.RegisterOperator({
                    ...basicData,
                    ...additionalData,
                })
            )
            .pipe(
                switchMap(() =>
                    race([
                        this.store.select(OperatorRegistrationState.operatorRegistrationError).pipe(RxjsUtils.filterFalsy()),
                        this.store.select(OperatorRegistrationState.lastRegisteredOperator).pipe(RxjsUtils.filterFalsy()),
                    ])
                ),
                first(),
                untilDestroyed(this)
            )
            .subscribe(async (result) => {
                if (isRegisteredOperator(result)) {
                    this.toastrService.success(
                        this.translocoService.translate("dtmWebAppLibPilotOperatorRegistration.operatorRegistrationSuccessMessage", {
                            operatorName: result.name,
                        })
                    );
                    await lastValueFrom(this.store.dispatch(OperatorContextActions.GetGlobalCapabilities));

                    const userContext = this.store.selectSnapshot(OperatorContextState.operator(result.id));
                    if (userContext) {
                        this.store.dispatch(new OperatorContextActions.TrySelectContext(userContext));
                    }

                    this.router.navigate(["/operator-profile"]);

                    return;
                }

                const error = this.store.selectSnapshot(OperatorRegistrationState.operatorRegistrationError);
                this.showRegisterOperatorError(error);
            });
    }

    public assignBasicDataStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, RegisterOperatorWizardSteps.BasicData),
            new WizardActions.EnableSteps(this.wizardId, [RegisterOperatorWizardSteps.BasicData]),
        ]);
    }

    public goToAdditionalDataStep() {
        this.store.dispatch([
            new WizardActions.SetActiveStep(this.wizardId, RegisterOperatorWizardSteps.AdditionalData),
            new WizardActions.EnableSteps(this.wizardId, [
                RegisterOperatorWizardSteps.BasicData,
                RegisterOperatorWizardSteps.AdditionalData,
            ]),
        ]);
    }

    private showRegisterOperatorError(error: PilotOperatorRegistrationError | undefined) {
        if (!error) {
            return;
        }

        let message;
        switch (error.type) {
            case PilotOperatorRegistrationErrorType.AlreadyRegisteredCompanyNumber:
                message = this.translocoService.translate(
                    "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.summary.alreadyRegisteredCompanyNumberError"
                );
                break;
            default:
                message = this.translocoService.translate("dtmWebAppLibPilotOperatorRegistration.operatorRegistrationErrorMessage");
        }
        this.toastrService.error(message);
    }
}
