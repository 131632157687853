import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { merge, tap } from "rxjs";
import { PublicProfileErrorType, PublicProfileType } from "../../services/public-profile.models";
import { PublicProfileActions } from "../../state/public-profile.actions";
import { PublicProfileState } from "../../state/public-profile.state";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-public-profile-operator-container",
    templateUrl: "./public-profile-operator-container.component.html",
    styleUrls: ["./public-profile-operator-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicProfileOperatorContainerComponent {
    protected readonly operatorData$ = this.store.select(PublicProfileState.publicProfileOperatorData);
    protected readonly error$ = this.store.select(PublicProfileState.publicProfileOperatorError);
    protected readonly PublicProfileType = PublicProfileType;
    protected readonly PublicProfileErrorType = PublicProfileErrorType;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.handleLoader();
    }

    private handleLoader(): void {
        merge([this.operatorData$, this.error$])
            .pipe(
                tap(() => {
                    this.document.getElementById("loader")?.remove();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected refreshOperatorDetails() {
        const operatorId = this.route.snapshot.params.operatorId;

        if (operatorId) {
            this.store.dispatch(new PublicProfileActions.GetPublicProfileOperatorData(operatorId));
        }
    }
}
