<div *ngrxLet="mission$; let mission">
    <div *ngIf="mission.startTime.min" class="row">
        <div class="column">
            <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
            <p>
                <ng-container *ngIf="mission.isPathBased; else nonPathBasedMissionStartTimeTemplate">
                    <dtm-ui-time-range-display
                        [startTime]="mission.realizationTime ?? mission.startTime.min"
                        [endTime]="mission.startTime.max"
                    ></dtm-ui-time-range-display>
                    <dtm-ui-time-range-display
                        class="previous-time"
                        *ngIf="
                            mission.plannedStartTime &&
                            (mission.plannedStartTime.min.getTime() !== mission.startTime.min.getTime() ||
                                mission.plannedStartTime.max.getTime() !== mission.startTime.max.getTime())
                        "
                        [startTime]="mission.plannedStartTime.min"
                        [endTime]="mission.plannedStartTime.max"
                    ></dtm-ui-time-range-display>
                </ng-container>
                <ng-template #nonPathBasedMissionStartTimeTemplate>
                    {{
                        "dtmWebAppLibTactical.missionTile.timeFrom"
                            | transloco : { time: mission.realizationTime ?? mission.startTime.min | localizeDate : { timeStyle: "short" } }
                    }}
                    <span
                        class="previous-time"
                        *ngIf="mission.plannedStartTime && mission.plannedStartTime.min.getTime() !== mission.startTime.min.getTime()"
                    >
                        {{ mission.plannedStartTime.min | localizeDate : { timeStyle: "short" } }}
                    </span>
                </ng-template>
            </p>
        </div>
        <div class="column">
            <dtm-ui-icon name="flight-land"></dtm-ui-icon>
            <ng-container *ngIf="mission.isPathBased; else nonPathBasedMissionEndTimeTemplate">
                <dtm-ui-time-range-display [startTime]="mission.endTime.min" [endTime]="mission.endTime.max"></dtm-ui-time-range-display>
                <dtm-ui-time-range-display
                    class="previous-time"
                    *ngIf="
                        mission.plannedEndTime &&
                        (mission.plannedEndTime.min.getTime() !== mission.endTime.min.getTime() ||
                            mission.plannedEndTime.max.getTime() !== mission.endTime.max.getTime())
                    "
                    [startTime]="mission.plannedEndTime.min"
                    [endTime]="mission.plannedEndTime.max"
                ></dtm-ui-time-range-display>
            </ng-container>
            <ng-template #nonPathBasedMissionEndTimeTemplate>
                {{
                    "dtmWebAppLibTactical.missionTile.timeTo"
                        | transloco : { time: mission.endTime.max | localizeDate : { timeStyle: "short" } }
                }}
                <span
                    class="previous-time"
                    *ngIf="mission.plannedEndTime && mission.plannedEndTime.min.getTime() !== mission.endTime.min.getTime()"
                >
                    {{ mission.plannedEndTime.min | localizeDate : { timeStyle: "short" } }}
                </span>
            </ng-template>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <dtm-ui-icon name="drone"></dtm-ui-icon>

            <p>{{ mission.uav.name }},</p>
            <p>{{ mission.uav.setupName }}</p>
        </div>
        <div class="column">
            <dtm-ui-icon name="tracker"></dtm-ui-icon>
            <ng-container *ngIf="mission.uav.trackersIdentifiers?.length; else noDataTemplate">
                <p *ngFor="let trackerId of mission.uav.trackersIdentifiers">{{ trackerId }}</p>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #noDataTemplate>
    <p>{{ "dtmWebAppLibMission.missionTile.noDataLabel" | transloco }}</p>
</ng-template>
