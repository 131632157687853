import { Address, PhoneNumber } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { LegalGuardianFormValue, LegalGuardianGeneralData } from "./legal-guardian.models";

export interface GetLegalGuardianGeneralDataResponseBody {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth?: string;
    nationality?: string;
    phoneNumber?: PhoneNumber;
    address?: Address;
}

export const saveLegalGuardianFormRequestPayload = (formValue: LegalGuardianFormValue, requestId: string) => ({
    requestId,
    phoneNumber: formValue.phoneNumber,
    address: formValue.address,
    dateOfBirth: formValue.dateOfBirth,
    nationality: formValue.nationality,
});

export const generatePdfRequestPayload = (formValue: LegalGuardianFormValue, legalGuardian: LegalGuardianGeneralData) => ({
    firstName: legalGuardian.firstName,
    lastName: legalGuardian.lastName,
    email: legalGuardian.email,
    dateOfBirth: formValue.dateOfBirth,
    nationality: formValue.nationality,
    phoneNumber: formValue.phoneNumber,
    address: formValue.address,
});

export const convertGetLegalGuardianGeneralDataResponseBodyToLegalGuardianGeneralData = (
    legalGuardian: GetLegalGuardianGeneralDataResponseBody
): LegalGuardianGeneralData => ({
    firstName: legalGuardian.firstName,
    lastName: legalGuardian.lastName,
    email: legalGuardian.email,
    dateOfBirth: legalGuardian.dateOfBirth ? DateUtils.convertStringDateToDateWithoutTimeZone(legalGuardian.dateOfBirth) : undefined,
    nationality: legalGuardian.nationality,
    phoneNumber: legalGuardian.phoneNumber,
    address: legalGuardian.address,
    residenceCountry: legalGuardian.address?.country,
});
