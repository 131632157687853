import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { ExamResult } from "./exam-results.models";
import { TrainingResult } from "./training-results.models";

export enum TrainingExamResultsFilterFormKeys {
    SearchPhrase = "searchPhrase",
    ActiveTabIndex = "activeTabIndex",
}

export enum TrainingExamResultsTabType {
    Trainings = 0,
    Exams = 1,
}

export interface TrainingExamResultsFilterParams {
    [PAGE_NUMBER_QUERY_PARAM]: number;
    [PAGE_SIZE_QUERY_PARAM]: number;
    [TrainingExamResultsFilterFormKeys.ActiveTabIndex]?: number;
    [TrainingExamResultsFilterFormKeys.SearchPhrase]?: string;
}

export enum TrainingExamResultsMode {
    Online = "ONLINE",
    Stationary = "STATIONARY",
}

export enum TrainingExamResultsErrorType {
    CannotGetTrainingExamResultsReportDetails = "CannotGetTrainingExamResultsReportDetails",
}

export enum TrainingExamResultsFiltersTabKeys {
    ExamResultsFilters = "examResultsFilters",
    TrainingResultsFilters = "trainingResultsFilters",
}

export type TrainingExamResultsListData = ExamResult | TrainingResult;
