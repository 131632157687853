<div class="badge-group" *ngrxLet="{ remarks: remarks$, missionType: missionType$, phase: phase$ } as vm">
    <dtm-mission-mission-status-badge class="status-badge" *ngIf="vm.phase" [status]="vm.phase"></dtm-mission-mission-status-badge>
    <div class="chip light message" *ngIf="vm.remarks | invoke : getAuthorityAcceptationItemWithMessage as authorityAcceptationItem">
        <dtm-ui-icon [name]="authorityAcceptationItem.isConfirmedByUser ? 'mail-open' : 'complex-mail-notification'"></dtm-ui-icon>
    </div>
    <div class="chip light mission-type">
        <dtm-ui-icon [name]="vm.missionType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
        {{ "dtmWebAppLibShared.missionTypeLabel" | transloco : { value: vm.missionType } }}
    </div>
</div>
