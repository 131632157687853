import { Address } from "@dtm-frontend/shared/ui";

export interface OperatorRegistrationCapabilities {
    isLegalGuardianRequired: boolean;
    isIdentityDocumentRequired: boolean;
    easaCountries: string[];
    originCountry: string;
}

export interface PilotRegistrationCapabilities extends OperatorRegistrationCapabilities {
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    nationality: string;
}

export interface PilotOperatorRegistrationError {
    type: PilotOperatorRegistrationErrorType;
}

export enum PilotOperatorRegistrationErrorType {
    Unknown = "PilotAndOperatorErrorUnknown",
    CannotGetUserRegistrationData = "PilotAndOperatorErrorCannotGetUserRegistrationData",
    CannotGetOperationScenarios = "PilotAndOperatorErrorCannotGetOperationScenarios",
    NotFound = "PilotAndOperatorErrorNotFound",
    NotAuthorized = "PilotAndOperatorErrorNotAuthorized",
    AlreadyRegisteredAsPersonalOperator = "PilotAndOperatorErrorAlreadyRegisteredAsPersonalOperator",
    AlreadyRegisteredAsPilot = "PilotAndOperatorErrorAlreadyRegisteredAsPilot",
    AlreadyRegisteredAsEnterpriseOperator = "PilotAndOperatorErrorAlreadyRegisteredAsEnterpriseOperator",
    AlreadyRegisteredCompanyNumber = "AlreadyRegisteredCompanyNumber",
}

export enum OperatorType {
    Personal = "PERSONAL",
    Enterprise = "ENTERPRISE",
}

export interface ForeignOperatorVerificationDetails {
    number: string;
    secret: string;
}

export interface ForeignOperatorValidation extends ForeignOperatorVerificationDetails {
    isValid: boolean;
    error?: ForeignOperatorValidationError;
}

export enum ForeignOperatorValidationErrorType {
    Unknown = "Unknown",
    OperatorInvalid = "OperatorInvalid",
}

export interface ForeignOperatorValidationError {
    type: ForeignOperatorValidationErrorType;
}

export interface RegisterPilotOperatorLegalGuardian {
    firstName: string;
    lastName: string;
    email: string;
}

export interface PilotOperatorRegistrationInsurance {
    expirationDate: Date | null;
    number: string;
}

export type PilotOperatorAddressForm = Omit<Address, "country">;
