import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { Observable, map } from "rxjs";
import { OperatorContextActions } from "../../shared/operator-context/state/operator-context.actions";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";

@Injectable()
export class CanEnterPilotProfileGuard {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.store.select(OperatorContextState.selectedContext).pipe(
            RxjsUtils.filterFalsy(),
            map(() => {
                const pilot = this.store.selectSnapshot(OperatorContextState.pilot);
                if (!pilot) {
                    return this.router.createUrlTree(["/operator-profile"]);
                }

                const context = this.store.selectSnapshot(OperatorContextState.operators)[pilot.operatorId];
                this.store.dispatch(new OperatorContextActions.SelectContext(context));

                return true;
            })
        );
    }
}
