import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { ImportedDetails, OperatorMigrationDetails } from "../../models/operator-migration.models";

interface OperatorMigrationSummaryStepComponentState {
    migrationDetails: OperatorMigrationDetails | undefined;
    importedDetails: ImportedDetails | undefined;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-summary-step[migrationDetails][importedDetails]",
    templateUrl: "./operator-migration-summary-step.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorMigrationSummaryStepComponent implements AfterViewInit {
    @Input() public set migrationDetails(value: OperatorMigrationDetails | undefined) {
        this.localStore.patchState({ migrationDetails: value });
    }
    @Input() public set importedDetails(value: ImportedDetails | undefined) {
        this.localStore.patchState({ importedDetails: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public readonly save = new EventEmitter<void>();
    @Output() public readonly back = new EventEmitter<void>();

    protected readonly migrationDetails$ = this.localStore.selectByKey("migrationDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly importedDetails$ = this.localStore.selectByKey("importedDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(
        private readonly localStore: LocalComponentStore<OperatorMigrationSummaryStepComponentState>,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        localStore.setState({ migrationDetails: undefined, importedDetails: undefined, isProcessing: false });
    }

    public ngAfterViewInit() {
        this.document.scrollingElement?.scrollTo(0, 0);
    }
}
