<dtm-ui-filters-container [isExpanded]="isInitiallyExpanded$ | ngrxPush">
    <form [formGroup]="filtersForm" class="filters-container">
        <dtm-ui-input-field>
            <input
                matInput
                type="text"
                formControlName="searchByText"
                [placeholder]="'dtmWebAppLibDashboard.missionsListFilters.searchByTextPlaceholder' | transloco"
            />
            <dtm-ui-icon name="search" class="field-suffix"></dtm-ui-icon>
            <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.searchByText; name: 'minlength'; error as error">
                {{
                    "dtmWebAppLibDashboard.missionsListFilters.searchByTextMinLengthError" | transloco : { minLength: error.requiredLength }
                }}
            </div>
        </dtm-ui-input-field>
    </form>
</dtm-ui-filters-container>
<dtm-ui-filter-chips-display
    [filtersCount]="filtersCount$ | ngrxPush"
    [filtersMap]="FILTERS_MAP"
    [formGroup]="filtersForm"
    [filtersValue]="filtersForm.value"
    (allFiltersReset)="filtersForm.reset()"
>
</dtm-ui-filter-chips-display>
