<div class="summary-section" *ngrxLet="legalGuardian$ as legalGuardian">
    <dtm-ui-label-value
        [label]="'dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianFirstNameLabel' | transloco"
        [value]="legalGuardian.firstName"
    ></dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianLastNameLabel' | transloco"
        [value]="legalGuardian.lastName"
    ></dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianEmailLabel' | transloco"
        [value]="legalGuardian.email"
    ></dtm-ui-label-value>
</div>
