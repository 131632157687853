import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MissionPlanAirspaceAnalysisOption, SoraResult } from "@dtm-frontend/shared/mission";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import { AirspaceCharacteristics, SoraSettings } from "../../../../../../models/mission.model";

interface InitialSoraIssuesComponentState {
    soraSettings: SoraSettings | undefined;
    soraResult: SoraResult | undefined;
    airspaceAnalysis: MissionPlanAirspaceAnalysisOption | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-web-app-lib-initial-sora-issues[soraSettings][soraResult][airspaceAnalysis]",
    templateUrl: "./initial-sora-issues.component.html",
    styleUrls: ["./initial-sora-issues.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InitialSoraIssuesComponent {
    @Input() public set soraSettings(value: SoraSettings | undefined) {
        this.localStore.patchState({ soraSettings: value });
    }
    @Input() public set soraResult(value: SoraResult | undefined) {
        this.localStore.patchState({ soraResult: value });
    }
    @Input() public set airspaceAnalysis(value: MissionPlanAirspaceAnalysisOption | undefined) {
        this.localStore.patchState({ airspaceAnalysis: value });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly soraResult$ = this.localStore.selectByKey("soraResult").pipe(RxjsUtils.filterFalsy());
    protected readonly airspaceAnalysis$ = this.localStore.selectByKey("airspaceAnalysis");
    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly airspaceCharacteristics$ = this.airspaceAnalysis$.pipe(map((airspace) => this.getAirspaceCharacteristics(airspace)));

    constructor(private readonly localStore: LocalComponentStore<InitialSoraIssuesComponentState>) {
        this.localStore.setState({
            soraSettings: undefined,
            soraResult: undefined,
            airspaceAnalysis: undefined,
            isExpanded: false,
        });
    }

    private getAirspaceCharacteristics(airspace: MissionPlanAirspaceAnalysisOption | undefined): AirspaceCharacteristics {
        if (!airspace) {
            return AirspaceCharacteristics.Unknown;
        }
        if (airspace.isOperationalAreaControlled && airspace.isOperationalAreaUncontrolled) {
            return AirspaceCharacteristics.Both;
        }
        if (airspace.isOperationalAreaControlled && !airspace.isOperationalAreaUncontrolled) {
            return AirspaceCharacteristics.Controlled;
        }
        if (!airspace.isOperationalAreaControlled && airspace.isOperationalAreaUncontrolled) {
            return AirspaceCharacteristics.Uncontrolled;
        }

        return AirspaceCharacteristics.Unknown;
    }
}
