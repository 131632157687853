import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { LegalGuardianDetailsApiService } from "../services/legal-guardian-details-api.service";
import { LegalGuardianDetailsError, LegalGuardianGeneralData } from "../services/legal-guardian.models";
import { LegalGuardianDetailsActions } from "./legal-guardian-details.actions";

export interface LegalGuardianDetailsStateModel {
    legalGuardian: LegalGuardianGeneralData | undefined;
    legalGuardianGeneralDataError: LegalGuardianDetailsError | undefined;
    signLegalGuardianDataError: LegalGuardianDetailsError | undefined;
    isLegalGuardianFormSigned: boolean | undefined;
    signatureRedirectUrl: string | undefined;
    generatePdfError: LegalGuardianDetailsError | undefined;
}

const defaultState: LegalGuardianDetailsStateModel = {
    legalGuardian: undefined,
    legalGuardianGeneralDataError: undefined,
    signLegalGuardianDataError: undefined,
    isLegalGuardianFormSigned: undefined,
    signatureRedirectUrl: undefined,
    generatePdfError: undefined,
};

@State<LegalGuardianDetailsStateModel>({
    name: "legalGuardianDetails",
    defaults: defaultState,
})
@Injectable()
export class LegalGuardianDetailsState {
    constructor(private readonly legalGuardianDetailsApi: LegalGuardianDetailsApiService) {}

    @Selector()
    public static legalGuardianGeneralData(state: LegalGuardianDetailsStateModel): LegalGuardianGeneralData | undefined {
        return state.legalGuardian;
    }

    @Selector()
    public static legalGuardianGeneralDataError(state: LegalGuardianDetailsStateModel): LegalGuardianDetailsError | undefined {
        return state.legalGuardianGeneralDataError;
    }

    @Selector()
    public static signLegalGuardianDataError(state: LegalGuardianDetailsStateModel): LegalGuardianDetailsError | undefined {
        return state.signLegalGuardianDataError;
    }

    @Selector()
    public static signatureRedirectUrl(state: LegalGuardianDetailsStateModel): string | undefined {
        return state.signatureRedirectUrl;
    }

    @Selector()
    public static generatePdfError(state: LegalGuardianDetailsStateModel): LegalGuardianDetailsError | undefined {
        return state.generatePdfError;
    }

    @Selector()
    public static isLegalGuardianFormSigned(state: LegalGuardianDetailsStateModel): boolean | undefined {
        return state.isLegalGuardianFormSigned;
    }

    @Action(LegalGuardianDetailsActions.GetLegalGuardianGeneralData)
    public getLegalGuardianGeneralData(
        context: StateContext<LegalGuardianDetailsStateModel>,
        action: LegalGuardianDetailsActions.GetLegalGuardianGeneralData
    ) {
        context.patchState({ legalGuardianGeneralDataError: undefined });

        return this.legalGuardianDetailsApi.getLegalGuardianGeneralData(action.requestId).pipe(
            tap((legalGuardian) => {
                context.patchState({
                    legalGuardian,
                });
            }),
            catchError((error) => {
                context.patchState({ legalGuardianGeneralDataError: error });

                return EMPTY;
            })
        );
    }

    @Action(LegalGuardianDetailsActions.GeneratePdf)
    public generatePdf(context: StateContext<LegalGuardianDetailsStateModel>, action: LegalGuardianDetailsActions.GeneratePdf) {
        context.patchState({ generatePdfError: undefined });
        const legalGuardian = context.getState().legalGuardian;

        if (!legalGuardian) {
            return;
        }

        return this.legalGuardianDetailsApi.generatePdf(action.formValue, legalGuardian).pipe(
            catchError((generatePdfError) => {
                context.patchState({ generatePdfError });

                return EMPTY;
            })
        );
    }

    @Action(LegalGuardianDetailsActions.SignLegalGuardianData)
    public signLegalGuardian(
        context: StateContext<LegalGuardianDetailsStateModel>,
        action: LegalGuardianDetailsActions.SignLegalGuardianData
    ) {
        context.patchState({ signLegalGuardianDataError: undefined, signatureRedirectUrl: undefined });

        return this.legalGuardianDetailsApi.sendForElectronicSignature(action.formValue, action.requestId).pipe(
            tap((signatureRedirectUrl) => context.patchState({ signatureRedirectUrl })),
            catchError((signLegalGuardianDataError) => {
                context.patchState({ signLegalGuardianDataError });

                return EMPTY;
            })
        );
    }

    @Action(LegalGuardianDetailsActions.VerifyLegalGuardianSignature)
    public verifyLegalGuardianSignature(
        context: StateContext<LegalGuardianDetailsStateModel>,
        action: LegalGuardianDetailsActions.VerifyLegalGuardianSignature
    ) {
        context.patchState({ isLegalGuardianFormSigned: undefined });

        return this.legalGuardianDetailsApi.verifyLegalGuardianSignature(action.requestId).pipe(
            tap(() => context.patchState({ isLegalGuardianFormSigned: true })),
            catchError(() => {
                context.patchState({ isLegalGuardianFormSigned: false });

                return EMPTY;
            })
        );
    }
}
