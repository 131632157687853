import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { OperatorContextActions } from "../../../shared/operator-context/state/operator-context.actions";
import { ForeignOperatorValidation, PilotOperatorRegistrationError, PilotRegistrationCapabilities } from "../../shared/models";
import { PilotRegistrationError, RegisteredPilot } from "../services/models";
import { PilotRegistrationApiService } from "../services/pilot-registration-api.service";
import { PilotRegistrationActions } from "./pilot-registration.actions";

export interface PilotRegistrationStateModel {
    pilotRegistrationCapabilities: PilotRegistrationCapabilities | undefined;
    pilotRegistrationCapabilitiesError: PilotRegistrationError | undefined;
    isPilotRegistrationCapabilitiesProcessing: boolean;
    foreignOperatorValidation: ForeignOperatorValidation | undefined;
    lastRegisteredPilot: RegisteredPilot | undefined;
    pilotRegistrationError: PilotOperatorRegistrationError | undefined;
    isProcessing: boolean;
}

const defaultState: PilotRegistrationStateModel = {
    pilotRegistrationCapabilities: undefined,
    pilotRegistrationCapabilitiesError: undefined,
    isPilotRegistrationCapabilitiesProcessing: false,
    foreignOperatorValidation: undefined,
    lastRegisteredPilot: undefined,
    pilotRegistrationError: undefined,
    isProcessing: false,
};

@State<PilotRegistrationStateModel>({
    name: "pilotRegistration",
    defaults: defaultState,
})
@Injectable()
export class PilotRegistrationState {
    constructor(private readonly pilotRegistrationApiService: PilotRegistrationApiService, private readonly store: Store) {
        if (pilotRegistrationApiService === undefined) {
            throw new Error("Initialize PilotRegistrationModule with .forRoot()");
        }
    }

    @Selector()
    public static pilotRegistrationCapabilities(state: PilotRegistrationStateModel): PilotRegistrationCapabilities | undefined {
        return state.pilotRegistrationCapabilities;
    }

    @Selector()
    public static pilotRegistrationCapabilitiesError(state: PilotRegistrationStateModel): PilotRegistrationError | undefined {
        return state.pilotRegistrationCapabilitiesError;
    }

    @Selector()
    public static isPilotRegistrationCapabilitiesProcessing(state: PilotRegistrationStateModel): boolean {
        return state.isPilotRegistrationCapabilitiesProcessing;
    }

    @Selector()
    public static foreignOperatorValidation(state: PilotRegistrationStateModel): ForeignOperatorValidation | undefined {
        return state.foreignOperatorValidation;
    }

    @Selector()
    public static lastRegisteredPilot(state: PilotRegistrationStateModel): RegisteredPilot | undefined {
        return state.lastRegisteredPilot;
    }

    @Selector()
    public static pilotRegistrationError(state: PilotRegistrationStateModel): PilotOperatorRegistrationError | undefined {
        return state.pilotRegistrationError;
    }

    @Selector()
    public static isProcessing(state: PilotRegistrationStateModel): boolean {
        return state.isProcessing;
    }

    @Action(PilotRegistrationActions.GetPilotRegistrationCapabilities)
    public getOperatorRegistrationCapabilities(
        context: StateContext<PilotRegistrationStateModel>,
        action: PilotRegistrationActions.GetPilotRegistrationCapabilities
    ) {
        context.patchState({
            pilotRegistrationCapabilities: undefined,
            pilotRegistrationCapabilitiesError: undefined,
            isPilotRegistrationCapabilitiesProcessing: true,
        });

        return this.pilotRegistrationApiService.getPilotRegistrationCapabilities(action.userId).pipe(
            tap((result) =>
                context.patchState({
                    pilotRegistrationCapabilities: result,
                })
            ),
            catchError((error) => {
                context.patchState({
                    pilotRegistrationCapabilitiesError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isPilotRegistrationCapabilitiesProcessing: false }))
        );
    }

    @Action(PilotRegistrationActions.VerifyForeignOperator)
    public verifyForeignOperator(
        context: StateContext<PilotRegistrationStateModel>,
        { foreignOperator }: PilotRegistrationActions.VerifyForeignOperator
    ) {
        context.patchState({
            foreignOperatorValidation: undefined,
        });

        return this.pilotRegistrationApiService.verifyForeignOperator(foreignOperator).pipe(
            tap(() =>
                context.patchState({
                    foreignOperatorValidation: { ...foreignOperator, isValid: true },
                })
            ),
            catchError((error) => {
                context.patchState({
                    foreignOperatorValidation: { ...foreignOperator, isValid: false, error },
                });

                return EMPTY;
            })
        );
    }

    @Action(PilotRegistrationActions.RegisterPilot)
    public registerPilot(
        context: StateContext<PilotRegistrationStateModel>,
        { pilotRegistrationFormValue }: PilotRegistrationActions.RegisterPilot
    ) {
        context.patchState({
            isProcessing: true,
            pilotRegistrationError: undefined,
        });

        return this.pilotRegistrationApiService.registerPilot(pilotRegistrationFormValue).pipe(
            tap((response) => {
                this.store.dispatch(new OperatorContextActions.GetGlobalCapabilities());
                context.patchState({ lastRegisteredPilot: response });
            }),
            catchError((error) => {
                context.patchState({
                    pilotRegistrationError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
