<dtm-ui-card-header>
    {{ "dtmWebAppLibTrainingExamResults.header.title" | transloco }}
    <a additionalActions class="button-secondary" [href]="elearningPortalUrl$ | ngrxPush" target="_blank" rel="noopener norefer">
        <dtm-ui-icon name="external-link"></dtm-ui-icon>
        {{ "dtmWebAppLibTrainingExamResults.header.goToElearningLabel" | transloco }}</a
    >
</dtm-ui-card-header>

<mat-tab-group
    [selectedIndex]="activeTabIndex$ | ngrxPush"
    (selectedIndexChange)="onUpdateTabIndex($event)"
    class="dtm-tabs"
    color="accent"
    dynamicHeight
>
    <mat-tab [label]="'dtmWebAppLibTrainingExamResults.tabs.trainingsLabel' | transloco">
        <ng-content select="[trainingList]"></ng-content>
    </mat-tab>

    <mat-tab [label]="'dtmWebAppLibTrainingExamResults.tabs.examsLabel' | transloco">
        <ng-content select="[examList]"></ng-content>
    </mat-tab>
</mat-tab-group>
