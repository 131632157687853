import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";
import { lastValueFrom } from "rxjs";
import { OperatorRegistrationActions } from "../state/operator-registration.actions";

@Injectable()
export class OperatorRegistrationWizardResolver {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public async resolve(): Promise<void> {
        const userData = this.store.selectSnapshot(AuthState.userId);

        return lastValueFrom(this.store.dispatch([new OperatorRegistrationActions.GetOperatorRegistrationCapabilities(userData)]));
    }
}
