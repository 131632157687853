import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { MILLISECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { interval, map, startWith } from "rxjs";
import { Mission } from "../../../../models/tactical.models";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-activation-modal",
    templateUrl: "./mission-activation-modal.component.html",
    styleUrls: ["./mission-activation-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionActivationModalComponent {
    protected readonly selectedTimeControl = new FormControl<Date | null>(null);
    protected maxEndTime: Date;
    // TODO: DTM-1659 - time should be synchronized with backend
    public readonly currentTime$ = interval(MILLISECONDS_IN_SECOND).pipe(
        startWith(null),
        map(() => new Date())
    );

    constructor(@Inject(MAT_DIALOG_DATA) public mission: Mission) {
        this.selectedTimeControl.setValue(new Date(mission.startTime.min));
        this.maxEndTime = this.calculateMaxEndTime(mission);

        // TODO: DTM-1659 - time should be synchronized with backend
        if (mission.startTime.min < new Date()) {
            this.selectedTimeControl.setValue(new Date());
        }
    }

    private calculateMaxEndTime(mission: Mission): Date {
        return mission.isPathBased ? mission.startTime.max : new Date(mission.endTime.max.getTime() - MILLISECONDS_IN_MINUTE);
    }

    protected getMinStartTime(missionMinStartTime: Date, localTime: Date): Date {
        return localTime > missionMinStartTime ? localTime : missionMinStartTime;
    }
}
