<div
    class="selectable-tile"
    [class.selected]="isSelected$ | ngrxPush"
    [class.disabled]="isDisabled$ | ngrxPush"
    (click)="riskMitigationLevelSelect.next()"
    (keyup.enter)="riskMitigationLevelSelect.next()"
    tabindex="0"
>
    <p class="selectable-tile-label">
        {{ text$ | ngrxPush }}
        <span class="description" *ngIf="description$ | ngrxPush as description" [innerHTML]="description"></span>
    </p>
    <dtm-ui-popover
        *ngIf="popupText$ | ngrxPush as popupText"
        class="selectable-tile-info-icon"
        (click)="$event.preventDefault()"
        shouldOpenOnHover
    >
        <div [innerHTML]="popupText"></div>
    </dtm-ui-popover>
</div>
