<dtm-ui-expandable-panel
    *ngrxLet="{
        uav: uav$,
        selectableUavs: selectableUavs$,
        isEditMode: isEditMode$,
        loadWeight: loadWeight$,
        allSerialNumbers: allSerialNumbers$
    } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [hasHeaderSeparator]="false"
>
    <div class="panel-header" headerSlot>
        <div class="label-wrapper">
            <strong>
                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.uavHeaderLabel" | transloco }}
            </strong>
            <span>
                {{
                    "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.uavNameLabel"
                        | transloco
                            : {
                                  name: vm.uav.name,
                                  amount: vm.allSerialNumbers.length
                              }
                }}
            </span>
        </div>
        <button
            *ngIf="!vm.isEditMode"
            type="button"
            class="button-secondary"
            [disabled]="vm.selectableUavs.length === 0"
            [matTooltip]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.addButtonDisabledTooltip' | transloco"
            [matTooltipDisabled]="!!vm.selectableUavs.length"
            (click)="$event.stopPropagation(); setEditMode(true)"
        >
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.addButtonLabel" | transloco }}
        </button>
        <button *ngIf="vm.isEditMode" type="button" class="button-icon" (click)="$event.stopPropagation(); removeAdditionalUavs()">
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
        </button>
    </div>
    <section class="properties" *ngIf="!vm.isEditMode">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.serialNumberLabel' | transloco"
            class="whole-line-property"
        >
            <ul class="serial-numbers">
                <li *ngFor="let serialNumber of vm.allSerialNumbers">{{ serialNumber }}</li>
            </ul>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.uavTypeLabel' | transloco"
            [value]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.uavTypeValue' | transloco : { value: vm.uav.model.type }"
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.takeOffMassLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.takeOffMassValue'
                    | transloco : { value: vm.uav.technicalSpecification.maxTakeOffMass | localizeNumber }
            "
            [class.whole-line-property]="!vm.loadWeight"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="vm.loadWeight"
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.loadWeightLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.loadWeightValue' | transloco : { value: vm.loadWeight | localizeNumber }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.maxDroneWidthLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.maxDroneWidthValue'
                    | transloco : { value: vm.uav.technicalSpecification.maxDroneWidth | localizeNumber }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.maxFlightSpeedLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.maxFlightSpeedValue'
                    | transloco : { value: vm.uav.technicalSpecification.maxFlightSpeed | localizeNumber }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.noiseCertificateNumberLabel' | transloco"
            [value]="
                vm.uav.certificate.noiseCertificateNumber === undefined
                    ? ('dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.certificateNotApplicableValue' | transloco)
                    : vm.uav.certificate.noiseCertificateNumber
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.airworthinessCertificateNumberLabel' | transloco"
            [value]="
                vm.uav.certificate.airworthinessCertificateNumber === undefined
                    ? ('dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.certificateNotApplicableValue' | transloco)
                    : vm.uav.certificate.airworthinessCertificateNumber
            "
        ></dtm-ui-label-value>
    </section>
    <section class="edit-mode-container" *ngIf="vm.isEditMode">
        <form [formGroup]="uavInfoForm">
            <dtm-ui-select-field
                [multiple]="true"
                [formControl]="uavInfoForm.controls.uavs"
                [placeholder]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.myUavsPlaceholder' | transloco"
            >
                <label>{{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.myUavsLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let selectableUav of selectableUavs$ | ngrxPush" [value]="selectableUav">
                    {{ selectableUav.name }}
                    <span hint>
                        {{
                            "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.myUavSerialNumberLabel"
                                | transloco
                                    : {
                                          serialNumbersAmount: selectableUav.serialNumbers.length,
                                          serialNumbers: selectableUav.serialNumbers | join
                                      }
                        }}
                    </span>
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <div class="statement">
                <dtm-ui-checkbox-field
                    [formControl]="uavInfoForm.controls.isStatementChecked"
                    [required]="uavInfoForm.controls.uavs.value | invoke : isUavsListNotEmpty"
                >
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.myUavsAppropriateConfigurationStatementLabel"
                            | transloco
                    }}
                </dtm-ui-checkbox-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="uavInfoForm.controls.isStatementChecked; name: 'required'">
                        {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </div>
        </form>
        <footer class="actions">
            <button type="button" class="button-secondary" (click)="cancel()">
                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="save()">
                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavInfo.addAdditionalUav.saveButtonLabel" | transloco }}
            </button>
        </footer>
    </section>
</dtm-ui-expandable-panel>
