import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { CylinderEntity, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AcEntity, AcLayerComponent, Cartesian3 } from "@pansa/ngx-cesium";
import turfDistance from "@turf/distance";
import { point as turfPoint } from "@turf/helpers";
import turfMidpoint from "@turf/midpoint";
import { Subject } from "rxjs";
import { combineLatestWith } from "rxjs/operators";
import { ItineraryService } from "../../../services/itinerary.service";
import { AssistedEntityId } from "../../../services/mission-api.converters";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

interface AssistedEditorMapFeaturesComponentState {
    isShown: boolean;
}

const CONNECTING_PARABOLA_ENTITY_NAME = "connecting-parabola";
const CONNECTING_PARABOLA_LABEL_ENTITY_NAME = "connecting-parabola-label";
const PARABOLA_STEP_SIZE = 0.01;
const PARABOLA_HEIGHT_MULTIPLIER = 1.2;
const PARABOLA_SETTINGS = {
    width: 3,
    material: new Cesium.PolylineDashMaterialProperty({
        color: Cesium.Color.fromCssColorString("#43577e"), // $color-gray-400:
        dashLength: 50,
        dashPattern: MapUtils.createCesiumDashPattern("-----     -     "),
    }),
};

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-assisted-editor-map-features[isShown]",
    templateUrl: "./assisted-editor-map-features.component.html",
    styleUrls: ["./assisted-editor-map-features.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssistedEditorMapFeaturesComponent implements AfterViewInit {
    @ViewChild("parabolaViewer") private parabolaViewer!: AcLayerComponent;
    @ViewChild("labelViewer") private labelViewer!: AcLayerComponent;

    @Input() public set isShown(value: boolean) {
        this.localStore.patchState({ isShown: value });
    }

    protected readonly parabola$ = new Subject();
    protected readonly labels$ = new Subject();
    protected readonly isShown$ = this.localStore.selectByKey("isShown");

    private parabolaPoints: Cartesian3[] = [];

    constructor(
        private readonly itineraryService: ItineraryService,
        private readonly localStore: LocalComponentStore<AssistedEditorMapFeaturesComponentState>
    ) {
        localStore.setState({ isShown: false });
    }

    public ngAfterViewInit(): void {
        this.watchItineraryContentAndUpdateConnectingParabola();
    }

    private watchItineraryContentAndUpdateConnectingParabola() {
        const parabolaEntity: AcEntity = {
            // NOTE: this approach allows updating polyline without flickering
            positions: new Cesium.CallbackProperty(() => this.parabolaPoints, false),
            width: PARABOLA_SETTINGS.width,
            material: PARABOLA_SETTINGS.material,
        };

        this.parabolaViewer.update(parabolaEntity, CONNECTING_PARABOLA_ENTITY_NAME);

        this.itineraryService.itineraryContent$
            .pipe(combineLatestWith(this.isShown$), untilDestroyed(this))
            .subscribe(([content, isShown]) => {
                if (!isShown) {
                    this.parabolaPoints = [];
                    this.labelViewer?.remove(CONNECTING_PARABOLA_LABEL_ENTITY_NAME);

                    return;
                }

                const takeOffEntity = content.find((entity) => entity.id === AssistedEntityId.Takeoff) as CylinderEntity;
                const landingEntity = content.find((entity) => entity.id === AssistedEntityId.Landing) as CylinderEntity;

                if (takeOffEntity && landingEntity) {
                    this.createOrUpdateConnectingParabola(takeOffEntity.center, landingEntity.center, takeOffEntity.topHeight ?? 0);

                    return;
                }

                this.parabolaPoints = [];
                this.labelViewer?.remove(CONNECTING_PARABOLA_LABEL_ENTITY_NAME);
            });
    }

    private createOrUpdateConnectingParabola(start: Cartesian3, end: Cartesian3, height: number) {
        const cartesianStartPoint = MapUtils.convertCartesian3ToSerializableCartographic(start);
        const cartesianEndPoint = MapUtils.convertCartesian3ToSerializableCartographic(end);

        const startPoint = turfPoint([cartesianStartPoint.longitude, cartesianStartPoint.latitude]);
        const endPoint = turfPoint([cartesianEndPoint.longitude, cartesianEndPoint.latitude]);

        const distance = turfDistance(startPoint, endPoint, {
            units: "kilometers",
        }).toFixed(2);

        const midpoint = turfMidpoint(startPoint, endPoint);

        const cartesianMidpoint = MapUtils.convertSerializableCartographicToCartesian3({
            longitude: midpoint.geometry.coordinates[0],
            latitude: midpoint.geometry.coordinates[1],
        });

        const midpointTransform = Cesium.Transforms.eastNorthUpToFixedFrame(cartesianMidpoint);
        const elevatedMidpoint = Cesium.Matrix4.multiplyByPoint(
            midpointTransform,
            new Cesium.Cartesian3(0, 0, height * PARABOLA_HEIGHT_MULTIPLIER),
            new Cesium.Cartesian3()
        );

        const spline = new Cesium.CatmullRomSpline({
            times: [0, 1 / 2, 1],
            points: [start, elevatedMidpoint, end],
        });
        const points: Cartesian3[] = [];

        for (let i = 0; i < 1; i += PARABOLA_STEP_SIZE) {
            points.push(spline.evaluate(i));
        }

        this.parabolaPoints = points;

        const labelEntity: AcEntity = {
            position: elevatedMidpoint,
            distance,
        };

        this.labelViewer.update(labelEntity, CONNECTING_PARABOLA_LABEL_ENTITY_NAME);
    }
}
