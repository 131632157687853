import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    FILES_UPLOAD_API_PROVIDER,
    IdentityDocument,
    IdentityDocumentType,
    MAX_UPLOADED_FILE_SIZE_BYTES,
    UploadedFile,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IDENTITY_DOCUMENT_API_ENDPOINTS } from "../../../shared/services/identity-document-upload/identity-document-api.token";
import { IdentityDocumentUploadApiService } from "../../../shared/services/identity-document-upload/identity-document-upload-api.service";
import { SavedDocumentData } from "../../models/user-profile.models";
import { USER_PROFILE_ENDPOINTS } from "../../user-profile.tokens";
import { identityDocumentApiFactory } from "../../utils/identity-document-api-factory";
import { ALLOWED_MIME_TYPES } from "../../utils/user-profile.constants";

const TOMORROW_DATE = new Date();
TOMORROW_DATE.setDate(TOMORROW_DATE.getDate() + 1);

interface IdentityDocumentFormType {
    documentType: FormControl<IdentityDocumentType>;
    document: FormControl<UploadedFile[]>;
    expirationDate: FormControl<string>;
}

interface IdentityCardComponentState {
    identityDocument: IdentityDocument | undefined;
    isEditModeOn: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-identity-card",
    templateUrl: "./identity-card.component.html",
    styleUrls: ["./identity-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: IdentityDocumentUploadApiService },
        {
            provide: IDENTITY_DOCUMENT_API_ENDPOINTS,
            useFactory: identityDocumentApiFactory,
            deps: [USER_PROFILE_ENDPOINTS],
        },
    ],
})
export class IdentityCardComponent {
    @Input() public set identityDocument(value: IdentityDocument | undefined) {
        this.localStore.patchState({ identityDocument: value });
    }

    @Output() public save = new EventEmitter<SavedDocumentData>();
    @Output() public download = new EventEmitter<void>();
    @Output() public delete = new EventEmitter<void>();

    protected readonly MAX_ATTACHMENT_SIZE_BYTES = MAX_UPLOADED_FILE_SIZE_BYTES;
    protected readonly ALLOWED_MIME_TYPES = ALLOWED_MIME_TYPES;
    protected readonly identityDocument$ = this.localStore.selectByKey("identityDocument");
    protected readonly IdentityDocumentType = IdentityDocumentType;
    protected readonly identityDocumentFormGroup = new FormGroup<IdentityDocumentFormType>({
        documentType: new FormControl(IdentityDocumentType.IdCard, { nonNullable: true }),
        expirationDate: new FormControl("", {
            validators: [Validators.required],
            nonNullable: true,
        }),
        document: new FormControl([], { validators: [Validators.required, Validators.maxLength(1)], nonNullable: true }),
    });

    protected readonly TOMORROW_DATE = TOMORROW_DATE;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly isEditModeOn$ = this.localStore.selectByKey("isEditModeOn");

    constructor(
        private readonly localStore: LocalComponentStore<IdentityCardComponentState>,
        private readonly translocoHelper: TranslationHelperService,
        private readonly transloco: TranslocoService,
        private readonly dialog: MatDialog
    ) {
        this.localStore.setState({
            identityDocument: undefined,
            isEditModeOn: false,
        });
    }

    protected clearForm(isEditModeOpen: boolean): void {
        if (!isEditModeOpen) {
            this.identityDocumentFormGroup.reset();
            this.identityDocumentFormGroup.markAsPristine();
        }
        this.setEditMode(isEditModeOpen);
    }

    protected saveDocument(): void {
        if (this.identityDocumentFormGroup.valid) {
            this.save.emit({
                document: this.identityDocumentFormGroup.controls.document.value,
                expirationDate: this.identityDocumentFormGroup.controls.expirationDate.value,
                type: this.identityDocumentFormGroup.controls.documentType.value as IdentityDocumentType,
            });
            this.clearForm(false);
            this.setEditMode(false);
        } else {
            this.identityDocumentFormGroup.markAllAsTouched();
        }
    }

    protected setEditMode(editMode: boolean): void {
        this.localStore.patchState({ isEditModeOn: editMode });
    }

    protected tryDeleteIdentityDocument(): void {
        this.dialog
            .open(ConfirmationDialogComponent, this.getRemoveConfirmationDialogContext())
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.delete.emit());
    }

    private getRemoveConfirmationDialogContext(): MatDialogConfig {
        return {
            data: {
                titleText: this.transloco.translate("dtmWebAppUserProfile.identityCard.dialogTitleText"),
                confirmationText: this.transloco.translate("dtmWebAppUserProfile.identityCard.dialogConfirmationText"),
                confirmButtonLabel: this.transloco.translate("dtmWebAppUserProfile.identityCard.dialogConfirmationButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        };
    }
}
