import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Toast } from "ngx-toastr";

@Component({
    selector: "dtm-web-app-lib-fts-toast",
    templateUrl: "./fts-alert-toast.component.html",
    styleUrls: ["./fts-alert-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtsAlertToastComponent extends Toast {}
