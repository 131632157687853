import { OperatorInsurancePolicy } from "../../shared/models/pilot-and-operator.models";
import { CompanyInfo, NewAttorneyPower } from "../models/operator.models";

export namespace OperatorProfileActions {
    export class GetOperatorProfile {
        public static readonly type = "[Operator profile] Get operator profile";
        constructor(public operatorId: string) {}
    }

    export class EditOperatorCompanyInfo {
        public static readonly type = "[Operator profile] Edit operator address";
        constructor(public companyInfo: CompanyInfo) {}
    }

    export class EditOperatorInsurancePolicy {
        public static readonly type = "[Operator profile] Edit operator insurance policy";
        constructor(public policy: OperatorInsurancePolicy) {}
    }

    export class SaveOperatorAvatar {
        public static readonly type = "[Operator Profile] Save operator avatar";
        constructor(public base64Image: string) {}
    }

    export class GetOperatorAvatar {
        public static readonly type = "[Operator profile] Get operator avatar";
        constructor(public operatorId: string) {}
    }

    export class ClearOperatorState {
        public static readonly type = "[Operator Profile] Clear operator state";
    }

    export class DownloadOperatorRegistrationConfirmation {
        public static readonly type = "[Operator Profile] Download operator registration confirmation";

        constructor(public fileName: string) {}
    }

    export class DeleteInsurancePolicy {
        public static readonly type = "[Operator Profile] Delete operator insurance policy";
    }

    export class DeleteAvatar {
        public static readonly type = "[Operator Profile] Delete operator avatar";
    }

    export class GetAttorneyPowerCapabilities {
        public static readonly type = "[Operator Profile] Get attorney power capabilities";
    }

    export class DownloadAttorneyTemplate {
        public static readonly type = "[Operator Profile] Download attorney template";

        constructor() {}
    }

    export class AddAttorneyPower {
        public static readonly type = "[Operator Profile] Add attorney power";

        constructor(public value: NewAttorneyPower) {}
    }

    export class RemoveAttorneyPower {
        public static readonly type = "[Operator Profile] Remove attorney power";

        constructor(public attorneyPowerId: string) {}
    }
}
