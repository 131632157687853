<dtm-ui-empty-state>
    <ng-container titleSlot>
        {{ "dtmWebAppOperatorProfile.attorneyPowers.emptyState.header" | transloco }}
    </ng-container>
    <img imageSlot src="assets/images/attorney-powers-empty-state.svg" />
    <p messageSlot class="empty-state-info">
        {{ "dtmWebAppOperatorProfile.attorneyPowers.emptyState.info" | transloco }}
        <span class="empty-state-company-name">{{ companyName$ | ngrxPush }}</span>
    </p>
    <button footerSlot type="button" class="button-primary" (click)="attorneyPowerFormVisibilityChange.emit(true)">
        {{ "dtmWebAppOperatorProfile.attorneyPowers.emptyState.addAttorneyPowerLabel" | transloco }}
    </button>
</dtm-ui-empty-state>
