<dtm-ui-card-header
    [isEditButtonVisible]="true"
    icon="add"
    (edit)="formInit.emit()"
    [buttonLabel]="'dtmWebAppOperatorProfile.attorneyPowers.addAttorneyPowerLabel' | transloco"
>
    {{ "dtmWebAppOperatorProfile.attorneyPowers.header" | transloco }}
</dtm-ui-card-header>
<p>
    {{ "dtmWebAppOperatorProfile.attorneyPowers.list.infoText" | transloco : { companyName: companyName$ | ngrxPush } }}
</p>
<ng-container *ngrxLet="slides$ as slides" [ngSwitch]="true">
    <ng-container
        *ngSwitchCase="slides.length > 1"
        [ngTemplateOutlet]="attorneyPowersCarouselTemplate"
        [ngTemplateOutletContext]="{ $implicit: slides }"
    ></ng-container>
    <ng-container
        *ngSwitchDefault
        [ngTemplateOutlet]="singleAttorneyPowerTemplate"
        [ngTemplateOutletContext]="{ $implicit: slides[0].cards[0] }"
    ></ng-container>
</ng-container>

<ng-template #attorneyPowersCarouselTemplate let-slides>
    <dtm-ui-carousel>
        <ng-container *ngFor="let slide of slides">
            <div class="slide" *dtmUiCarouselSlides>
                <ng-template
                    *ngFor="let attorneyPowers of slide.cards"
                    [ngTemplateOutlet]="singleAttorneyPowerTemplate"
                    [ngTemplateOutletContext]="{ $implicit: attorneyPowers }"
                ></ng-template>
            </div>
        </ng-container>
    </dtm-ui-carousel>
</ng-template>

<ng-template #singleAttorneyPowerTemplate let-attorneyPowers>
    <div class="attorney-tile">
        <div class="attorney-power-tile-header">
            <dtm-ui-label-value
                [label]="'dtmWebAppOperatorProfile.attorneyPowers.list.granteeNameLabel' | transloco"
                [value]="attorneyPowers.grantee.firstName + ' ' + attorneyPowers.grantee.lastName"
            ></dtm-ui-label-value>
            <div class="attorney-power-tile-header">
                <ng-container [ngSwitch]="attorneyPowers.status">
                    <span *ngSwitchCase="AttorneyPowerStatus.Approved" class="chip success">
                        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                        {{ "dtmWebAppOperatorProfile.attorneyPowers.list.attorneyPowerAcceptedStatusLabel" | transloco }}
                    </span>
                    <span *ngSwitchCase="AttorneyPowerStatus.PendingApproval" class="chip warning">
                        <dtm-ui-icon name="rest-time"></dtm-ui-icon>
                        {{ "dtmWebAppOperatorProfile.attorneyPowers.list.attorneyPowerAcceptedPendingApprovalLabel" | transloco }}
                    </span>
                </ng-container>
                <button class="button-icon" type="button" [matMenuTriggerFor]="menu">
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button type="button" (click)="remove.emit(attorneyPowers.id)" mat-menu-item>
                        <dtm-ui-icon name="bin"></dtm-ui-icon>
                        {{ "dtmWebAppOperatorProfile.attorneyPowers.list.removeAttorneyPowerLabel" | transloco }}
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="attorney-power-documents" [ngSwitch]="attorneyPowers.type">
            <dtm-ui-label-value
                [label]="'dtmWebAppOperatorProfile.attorneyPowers.list.isOwnerLabel' | transloco"
                [value]="
                    'dtmWebAppOperatorProfile.attorneyPowers.list.isOwnerValue'
                        | transloco : { isOwner: attorneyPowers.type === AttorneyPowerType.Owner }
                "
            ></dtm-ui-label-value>

            <ng-container *ngSwitchCase="AttorneyPowerType.NationalCourtNumber">
                <dtm-ui-label-value
                    [label]="'dtmWebAppOperatorProfile.attorneyPowers.list.nationalCourtNumberLabel' | transloco"
                    [value]="attorneyPowers.nationalCourtRegister"
                ></dtm-ui-label-value>
            </ng-container>

            <ng-container *ngSwitchCase="AttorneyPowerType.ConfirmatoryDocument">
                <dtm-ui-label-value
                    [label]="'dtmWebAppOperatorProfile.attorneyPowers.list.attachmentsLabel' | transloco"
                    *ngIf="attorneyPowers.documentId"
                >
                    <dtm-ui-uploaded-files-display
                        [uploadedFiles]="[
                            {
                                id: attorneyPowers.documentId,
                                name: 'dtmWebAppOperatorProfile.attorneyPowers.list.attorneyPowerDocumentFileName' | transloco
                            }
                        ]"
                    ></dtm-ui-uploaded-files-display>
                </dtm-ui-label-value>
            </ng-container>
        </div>
        <dtm-ui-label-value class="permissions" [label]="'dtmWebAppOperatorProfile.attorneyPowers.list.permissionsLabel' | transloco">
            <ng-container *ngIf="attorneyPowers.permissions.length; else emptyPermissionsTemplate">
                <span *ngFor="let permission of attorneyPowers.permissions" class="permission">{{
                    "dtmWebAppOperatorProfile.attorneyPowers.list.permissionsValue" | transloco : { permission }
                }}</span>
            </ng-container>

            <ng-template #emptyPermissionsTemplate>
                <span class="permission">{{ "dtmWebAppOperatorProfile.attorneyPowers.list.emptyPermissionsValue" | transloco }}</span>
            </ng-template>
        </dtm-ui-label-value>
    </div>
</ng-template>
