import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatest, map } from "rxjs";
import { DEFAULT_TRACK_DETAILS_DISPLAY_MODE, TrackDetailsDisplayMode } from "../track-details/track-details-display-mode";
import { HeightType, MissionPlanItineraryConstraints } from "./../../../../../../../models/mission.model";
import { ItineraryPanelSettings, ViewItineraryEntityType, ViewItineraryShapeEntity } from "./../../itinerary-panel.models";

export type TopBottomHeightControlsType = "cylinder" | "prism";

const SVG_MAX_POINT_HEIGHT = 84;

interface TopBottomHeightControlsState {
    canEditBottomHeight: boolean;
    entity: ViewItineraryShapeEntity | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    settings: ItineraryPanelSettings | undefined;
    displayMode: TrackDetailsDisplayMode;
}

function getVerticalBufferOffset(entity: ViewItineraryShapeEntity): number {
    if (entity.type === ViewItineraryEntityType.Landing) {
        return Math.round(entity.inletVerticalBuffer / 2);
    } else if (entity.type === ViewItineraryEntityType.Takeoff) {
        return Math.round(entity.outletVerticalBuffer / 2);
    } else if (entity.type === ViewItineraryEntityType.InternalCylinder || entity.type === ViewItineraryEntityType.InternalPrism) {
        return Math.round(Math.max(entity.inletVerticalBuffer, entity.outletVerticalBuffer) / 2);
    }

    return 0;
}

@Component({
    selector: "dtm-web-app-lib-mwiesip-top-bottom-height-controls[settings][entity][constraints][displayMode]",
    templateUrl: "./top-bottom-height-controls.component.html",
    styleUrls: ["./top-bottom-height-controls.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TopBottomHeightControlsComponent {
    protected readonly HeightType = HeightType;
    protected readonly ViewItineraryEntityType = ViewItineraryEntityType;

    @Input() public set canEditBottomHeight(value: boolean | undefined) {
        this.localStore.patchState({ canEditBottomHeight: !!value });
    }
    @Input() public set entity(value: ViewItineraryShapeEntity | undefined) {
        this.localStore.patchState({ entity: value });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set settings(value: ItineraryPanelSettings | undefined) {
        this.localStore.patchState({ settings: value });
    }
    @Input() public set displayMode(value: TrackDetailsDisplayMode) {
        this.localStore.patchState({ displayMode: value });
    }

    @Output() public readonly topHeightChange = new EventEmitter<number>();
    @Output() public readonly bottomHeightChange = new EventEmitter<number>();

    protected readonly settings$ = this.localStore.selectByKey("settings");
    protected readonly entity$ = this.localStore.selectByKey("entity");
    protected readonly displayMode$ = this.localStore.selectByKey("displayMode");
    protected readonly canEditCenterPoint$ = this.entity$.pipe(
        RxjsUtils.filterFalsy(),
        map((entity) => entity.type === ViewItineraryEntityType.Landing || entity.type === ViewItineraryEntityType.Takeoff)
    );
    protected readonly canEditBottomHeight$ = combineLatest([
        this.localStore.selectByKey("canEditBottomHeight"),
        this.entity$.pipe(RxjsUtils.filterFalsy()),
    ]).pipe(
        map(
            ([isEditEnabled, entity]) =>
                isEditEnabled && entity.type !== ViewItineraryEntityType.Landing && entity.type !== ViewItineraryEntityType.Takeoff
        )
    );
    protected readonly canEditTopHeight$ = this.entity$.pipe(
        RxjsUtils.filterFalsy(),
        map((entity) => entity.type !== ViewItineraryEntityType.Landing && entity.type !== ViewItineraryEntityType.Takeoff)
    );
    protected readonly isCylinder$ = this.entity$.pipe(
        RxjsUtils.filterFalsy(),
        map((entity) => entity.type !== ViewItineraryEntityType.InternalPrism && entity.type !== ViewItineraryEntityType.Prism)
    );

    protected readonly constraints$ = this.localStore.selectByKey("constraints");

    constructor(private readonly localStore: LocalComponentStore<TopBottomHeightControlsState>) {
        localStore.setState({
            canEditBottomHeight: false,
            entity: undefined,
            constraints: undefined,
            settings: undefined,
            displayMode: DEFAULT_TRACK_DETAILS_DISPLAY_MODE,
        });
    }

    protected getPointTransform(entity: ViewItineraryShapeEntity, type: "inlet" | "outlet" | "center"): string {
        let height = 0;

        switch (type) {
            case "inlet":
                height =
                    entity.type === ViewItineraryEntityType.InternalCylinder ||
                    entity.type === ViewItineraryEntityType.InternalPrism ||
                    entity.type === ViewItineraryEntityType.Landing
                        ? entity.inletPointHeight
                        : 0;
                break;
            case "outlet":
                height =
                    entity.type === ViewItineraryEntityType.InternalCylinder ||
                    entity.type === ViewItineraryEntityType.InternalPrism ||
                    entity.type === ViewItineraryEntityType.Takeoff
                        ? entity.outletPointHeight
                        : 0;
                break;
            case "center":
                height =
                    entity.type === ViewItineraryEntityType.Landing || entity.type === ViewItineraryEntityType.Takeoff
                        ? entity.centerPointHeight ?? entity.topHeight - getVerticalBufferOffset(entity)
                        : 0;
                break;
        }

        const min = entity.bottomHeight ?? 0;
        const max = entity.topHeight;
        const result = SVG_MAX_POINT_HEIGHT * ((height - min) / (max - min));

        return `translateY(-${result}px)`;
    }

    protected getVerticalBufferOffset(entity: ViewItineraryShapeEntity): number {
        return getVerticalBufferOffset(entity);
    }

    protected getPointHeightLabelTranslationKey(heightType: HeightType | undefined) {
        switch (heightType) {
            case HeightType.AGL:
                return "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.pointHeightAglLabel";
            case HeightType.AMSL:
                return "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.pointHeightAmslLabel";
            default:
                return "";
        }
    }

    protected getTopHeightLabelTranslationKey(heightType: HeightType | undefined) {
        switch (heightType) {
            case HeightType.AGL:
                return "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.topHeightAglLabel";
            case HeightType.AMSL:
                return "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.topHeightAmslLabel";
            default:
                return "";
        }
    }

    protected getBottomHeightLabelTranslationKey(heightType: HeightType | undefined) {
        switch (heightType) {
            case HeightType.AGL:
                return "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.bottomHeightAglLabel";
            case HeightType.AMSL:
                return "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.bottomHeightAmslLabel";
            default:
                return "";
        }
    }

    protected emitRoundedValue(value: number, emitEvent: EventEmitter<number>): void {
        emitEvent.emit(Math.round(value));
    }

    protected convertToRoundedValue(value: number): number {
        return Math.round(value);
    }

    protected getCenterPointHeight(entity: ViewItineraryShapeEntity, defaultValue: number): number {
        let centerPointHeight: number | undefined;

        if (entity.type === ViewItineraryEntityType.Landing || entity.type === ViewItineraryEntityType.Takeoff) {
            centerPointHeight = entity.centerPointHeight;
        }

        return centerPointHeight ?? defaultValue;
    }
}
