import { UploadedFile } from "@dtm-frontend/shared/ui";
import { PermitListItem, SpecificPermitListQueryParams } from "../services/operator-permits.models";

export namespace OperatorPermitsActions {
    export class GetOwnedSpecificPermitList {
        public static readonly type = "[Operator permits] Get owned specific permits list";
        constructor(public params: SpecificPermitListQueryParams) {}
    }

    export class GetSpecificPermitDraftsList {
        public static readonly type = "[Operator permits] Get specific permit draft list";
        constructor(public params: SpecificPermitListQueryParams) {}
    }

    export class GetOperatorPermitDetails {
        public static readonly type = "[SpecificPermits] Get operator permit details";
        constructor(public permitId: string, public operatorId: string) {}
    }

    export class GetCrossBorderPermitDetails {
        public static readonly type = "[SpecificPermits] Get cross border permit details";
        constructor(public permitId: string, public operatorId: string) {}
    }

    export class UpdatePermitName {
        public static readonly type = "[Operator permits] Update permit name";
        constructor(public permit: PermitListItem, public name: string) {}
    }

    export class GetOperatorPermitsCapabilities {
        public static readonly type = "[SpecificPermits] Get operator permit capabilities";
        constructor(public operatorId: string) {}
    }

    export class GetAssociationPermitDetails {
        public static readonly type = "[SpecificPermits] Get association permit details";
        constructor(public permitId: string, public operatorId: string) {}
    }

    export class GetKmlPreview {
        public static readonly type = "[SpecificPermits] Get kml preview";
        constructor(public file: UploadedFile) {}
    }

    export class RemoveDraft {
        public static readonly type = "[SpecificPermits] Remove draft";
        constructor(public draftId: string) {}
    }
}
