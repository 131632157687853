import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { BasicAddress } from "@dtm-frontend/shared/ui";
import {
    DEFAULT_COMPANY_NUMBER_LENGTH,
    DEFAULT_COMPANY_NUMBER_MASK,
    DEFAULT_COUNTRY_CODE,
    FunctionUtils,
    LocalComponentStore,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ImportedEnterpriseOperatorDetails, LegalOperatorFormValue } from "../../../models/operator-migration.models";
import { getImportedPostCodeFormValue } from "../../../utils/get-imported-post-code-value";

interface OperatorMigrationCompanyInfoComponentState {
    enterpriseOperatorDetails: ImportedEnterpriseOperatorDetails | undefined;
}

interface CompanyInfoForm {
    country: FormControl<string>;
    address: FormControl<BasicAddress | null>;
    companyNumber: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-migration-company-info[enterpriseOperatorDetails]",
    templateUrl: "./operator-migration-company-info.component.html",
    styleUrls: ["./operator-migration-company-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperatorMigrationCompanyInfoComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperatorMigrationCompanyInfoComponent),
            multi: true,
        },
    ],
})
export class OperatorMigrationCompanyInfoComponent implements ControlValueAccessor, Validator {
    @Input() public set enterpriseOperatorDetails(value: ImportedEnterpriseOperatorDetails | undefined) {
        this.localStore.patchState({ enterpriseOperatorDetails: value });
        if (!value) {
            return;
        }

        this.assignFormValue(value);
        this.onValidationChange();
    }

    protected readonly enterpriseOperatorDetails$ = this.localStore.selectByKey("enterpriseOperatorDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly companyInfoForm = new FormGroup<CompanyInfoForm>({
        country: new FormControl<string>(
            { value: DEFAULT_COUNTRY_CODE, disabled: true },
            { validators: Validators.required, nonNullable: true }
        ),
        address: new FormControl<BasicAddress | null>(null, Validators.required),
        companyNumber: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.minLength(DEFAULT_COMPANY_NUMBER_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
    });
    protected readonly COMPANY_NUMBER_MASK = DEFAULT_COMPANY_NUMBER_MASK;

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: LegalOperatorFormValue | null) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<OperatorMigrationCompanyInfoComponentState>) {
        localStore.setState({ enterpriseOperatorDetails: undefined });

        this.companyInfoForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            const address = this.companyInfoForm.getRawValue().address as BasicAddress;
            const { country, companyNumber } = this.companyInfoForm.getRawValue();

            this.propagateChange({
                address: { ...address, country },
                companyNumber,
            });
            this.onValidationChange();
        });
    }

    public registerOnChange(fn: (value: LegalOperatorFormValue | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: LegalOperatorFormValue | null): void {
        if (value) {
            this.companyInfoForm.setValue({
                country: value.address?.country ?? DEFAULT_COUNTRY_CODE,
                address: value.address,
                companyNumber: value.companyNumber,
            });
        }
    }

    public validate(): ValidationErrors | null {
        if (this.companyInfoForm.valid) {
            return null;
        }

        return { companyInfoFormInvalid: true };
    }

    private assignFormValue(value: ImportedEnterpriseOperatorDetails) {
        const companyNumberPattern = /^([0-9]{10})$/;

        this.companyInfoForm.setValue({
            companyNumber: companyNumberPattern.test(value.companyData.nipRegon) ? value.companyData.nipRegon : "",
            country: DEFAULT_COUNTRY_CODE,
            address: {
                streetName: value.companyData.address.streetName,
                apartmentNumber: value.companyData.address.apartmentNumber,
                houseNumber: value.companyData.address.houseNumber,
                city: value.companyData.address.city,
                postCode: getImportedPostCodeFormValue(value.companyData.address.postCode),
            },
        });
    }
}
