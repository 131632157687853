<div class="form-entry" *ngrxLet="{ hasPermits: hasPermits$ } as vm">
    <mat-slide-toggle labelPosition="before" class="spread" [formControl]="toggleFormControl">
        {{ "dtmWebAppLibMission.dataFormStep.missionPlanningType.permit" | transloco }}

        <dtm-ui-icon
            *ngIf="vm.hasPermits"
            name="information-fill"
            class="hint-icon"
            [matTooltip]="'dtmWebAppLibMission.dataFormStep.missionPlanningType.enabledHint' | transloco"
        ></dtm-ui-icon>
        <dtm-ui-icon
            *ngIf="!vm.hasPermits"
            name="information-fill"
            class="hint-icon"
            [matTooltip]="'dtmWebAppLibMission.dataFormStep.missionPlanningType.disabledHint' | transloco"
        ></dtm-ui-icon>
    </mat-slide-toggle>
</div>
