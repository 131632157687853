<dtm-ui-expandable-panel
    class="single-permission-control"
    [class.disabled]="formControl.disabled"
    [isDisabled]="formControl.disabled"
    [class.selected]="formControl.value"
    [class.error]="formControl.errors"
    [hasHeaderSeparator]="false"
    *ngrxLet="singlePermission$ as singlePermission"
>
    <dtm-ui-expandable-extended-header headerSlot [label]="singlePermission.name" (labelClick)="toggleFormValue($event)">
        <dtm-ui-checkbox-field
            [formControl]="formControl"
            class="permission-checkbox"
            (click)="$event.stopPropagation()"
            prefix
        ></dtm-ui-checkbox-field>
        <div class="permission-chips-container" suffix>
            <span class="chip light">
                <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                {{
                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.singlePermission.periodOfValidityInMonthsValue"
                        | transloco : { monthsAmount: singlePermission.grantValidityInMonths }
                }}
            </span>
            <span class="chip light" *ngIf="isPaymentFeatureAvailable$ | ngrxPush">
                <dtm-ui-icon name="coins"></dtm-ui-icon>
                {{ singlePermission.price | translocoCurrency }}
            </span>
            <span class="chip light" *ngIf="singlePermission.uavs?.length">
                <dtm-ui-icon name="drone"></dtm-ui-icon>
                {{ singlePermission.uavs?.length }}
            </span>
        </div>
    </dtm-ui-expandable-extended-header>
    <p class="description">{{ singlePermission.description }}</p>
    <dtm-ui-label-value
        *ngIf="singlePermission.areUavsRequired"
        class="additional-requirements-label"
        [label]="
            'dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.singlePermission.additionalRequirementsLabel' | transloco
        "
        [value]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.singlePermission.uavModelIndicateLabel' | transloco"
    >
    </dtm-ui-label-value>
</dtm-ui-expandable-panel>
<dtm-ui-form-errors>
    <div class="field-error" *dtmUiFieldHasError="formControl; name: 'requiredUavsMissing'">
        {{
            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.noRequiredUavsError"
                | transloco
                    : {
                          requiredClasses: formControl.errors?.requiredUavsMissing,
                          requiredClassesLength: formControl.errors?.requiredUavsMissing?.length
                      }
        }}
    </div>
</dtm-ui-form-errors>
