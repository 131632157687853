import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
    MissionCategory,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable, combineLatest, map } from "rxjs";
import {
    ENLARGED_RADIUS_WITH_STATEMENT_ACCEPTED,
    MAX_RADIUS_WITH_UAV_OBSERVER,
    MissionPlanItineraryConstraints,
    NO_LIMIT_RADIUS,
} from "../../../../../models/mission.model";

interface MissionWizardItineraryEditorMapActionsHeaderComponentState {
    editorType: ItineraryEditorType | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    isEnlargedZoneStatementAccepted: boolean;
    missionType: MissionType | undefined;
    category: MissionPlanOperationCategoryOption | undefined;
    maxEntities: number;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-itinerary-editor-step-map-actions-header[editorType][constraints]",
    templateUrl: "./map-actions-header.component.html",
    styleUrls: ["./map-actions-header.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionWizardItineraryEditorMapActionsHeaderComponent {
    protected readonly MAX_RADIUS_WITH_UAV_OBSERVER = MAX_RADIUS_WITH_UAV_OBSERVER;
    protected readonly ENLARGED_RADIUS_WITH_STATEMENT_ACCEPTED = ENLARGED_RADIUS_WITH_STATEMENT_ACCEPTED;

    protected readonly helpTextVisibility$ = this.getHelpTextVisibilityObservable();
    protected readonly maxEntities$ = this.localStore.selectByKey("maxEntities");

    @Input() public set editorType(value: ItineraryEditorType | undefined) {
        this.localStore.patchState({ editorType: value });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set isEnlargedZoneStatementAccepted(value: BooleanInput) {
        this.localStore.patchState({ isEnlargedZoneStatementAccepted: coerceBooleanProperty(value) });
    }
    @Input() public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });
    }
    @Input() public set category(value: MissionPlanOperationCategoryOption | undefined) {
        this.localStore.patchState({ category: value });
    }
    @Input() public set maxEntities(value: number | undefined) {
        this.localStore.patchState({ maxEntities: value ?? 0 });
    }

    constructor(private readonly localStore: LocalComponentStore<MissionWizardItineraryEditorMapActionsHeaderComponentState>) {
        this.localStore.setState({
            editorType: undefined,
            constraints: undefined,
            isEnlargedZoneStatementAccepted: false,
            missionType: undefined,
            category: undefined,
            maxEntities: 0,
        });
    }

    private getHelpTextVisibilityObservable(): Observable<
        { shouldShowEnlarged: boolean; shouldShowMax: boolean; defaultRadius: number } | undefined
    > {
        return combineLatest([
            this.localStore.selectByKey("editorType"),
            this.localStore.selectByKey("constraints"),
            this.localStore.selectByKey("isEnlargedZoneStatementAccepted"),
            this.localStore.selectByKey("missionType"),
            this.localStore.selectByKey("category"),
        ]).pipe(
            map(([editorType, constraints, isEnlargedZoneStatementAccepted, missionType, category]) => {
                if (editorType !== ItineraryEditorType.Standard || !constraints || constraints.max.largeZoneRadius === NO_LIMIT_RADIUS) {
                    return;
                }

                if (
                    missionType === MissionType.BVLOS ||
                    (category?.type === MissionCategory.Specific &&
                        category.specificPermitType === MissionPlanSpecificPermitType.Individual)
                ) {
                    return {
                        shouldShowEnlarged: false,
                        shouldShowMax: false,
                        defaultRadius: constraints.max.largeZoneRadius,
                    };
                }

                if (missionType === MissionType.VLOS && category?.type === MissionCategory.Open) {
                    return {
                        shouldShowEnlarged: !isEnlargedZoneStatementAccepted,
                        shouldShowMax: false,
                        defaultRadius: isEnlargedZoneStatementAccepted
                            ? constraints.max.largeZoneRadius
                            : constraints.max.regularZoneRadius,
                    };
                }

                if (missionType === MissionType.VLOS && category?.type === MissionCategory.Specific) {
                    return {
                        shouldShowEnlarged: !isEnlargedZoneStatementAccepted,
                        shouldShowMax: !isEnlargedZoneStatementAccepted || constraints.max.largeZoneRadius !== MAX_RADIUS_WITH_UAV_OBSERVER,
                        defaultRadius: isEnlargedZoneStatementAccepted
                            ? constraints.max.largeZoneRadius
                            : constraints.max.regularZoneRadius,
                    };
                }

                return {
                    shouldShowEnlarged:
                        constraints.max.largeZoneRadius !== MAX_RADIUS_WITH_UAV_OBSERVER && !isEnlargedZoneStatementAccepted,
                    shouldShowMax: constraints.max.largeZoneRadius !== MAX_RADIUS_WITH_UAV_OBSERVER,
                    defaultRadius: isEnlargedZoneStatementAccepted ? constraints.max.largeZoneRadius : constraints.max.regularZoneRadius,
                };
            })
        );
    }
}
