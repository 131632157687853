import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { PublicProfileApiService } from "../services/public-profile-api.service";
import { PublicProfileDetails, PublicProfileError } from "../services/public-profile.models";
import { PublicProfileActions } from "./public-profile.actions";

interface PublicProfileStateModel {
    operatorData: PublicProfileDetails | undefined;
    pilotData: PublicProfileDetails | undefined;
    publicProfilePilotError: PublicProfileError | undefined;
    publicProfileOperatorError: PublicProfileError | undefined;
}

const defaultState: PublicProfileStateModel = {
    operatorData: undefined,
    pilotData: undefined,
    publicProfilePilotError: undefined,
    publicProfileOperatorError: undefined,
};

@State<PublicProfileStateModel>({
    name: "publicProfile",
    defaults: defaultState,
})
@Injectable()
export class PublicProfileState {
    constructor(private readonly publicProfileApiService: PublicProfileApiService) {}

    @Selector()
    public static publicProfileOperatorData(state: PublicProfileStateModel): PublicProfileDetails | undefined {
        return state.operatorData;
    }

    @Selector()
    public static publicProfilePilotData(state: PublicProfileStateModel): PublicProfileDetails | undefined {
        return state.pilotData;
    }

    @Selector()
    public static publicProfilePilotError(state: PublicProfileStateModel): PublicProfileError | undefined {
        return state.publicProfilePilotError;
    }

    @Selector()
    public static publicProfileOperatorError(state: PublicProfileStateModel): PublicProfileError | undefined {
        return state.publicProfileOperatorError;
    }

    @Action(PublicProfileActions.GetPublicProfileOperatorData)
    public getPublicProfileOperatorData(
        context: StateContext<PublicProfileStateModel>,
        action: PublicProfileActions.GetPublicProfileOperatorData
    ) {
        return this.publicProfileApiService.getPublicProfileOperatorData(action.operatorId).pipe(
            tap((publicProfileOperatorData: PublicProfileDetails) => {
                context.patchState({
                    operatorData: publicProfileOperatorData,
                    publicProfileOperatorError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ publicProfileOperatorError: error });

                return EMPTY;
            })
        );
    }

    @Action(PublicProfileActions.GetPublicProfilePilotData)
    public getPublicProfilePilotData(
        context: StateContext<PublicProfileStateModel>,
        action: PublicProfileActions.GetPublicProfilePilotData
    ) {
        return this.publicProfileApiService.getPublicProfilePilotData(action.pilotId).pipe(
            tap((publicProfilePilotData: PublicProfileDetails) => {
                context.patchState({
                    pilotData: publicProfilePilotData,
                    publicProfilePilotError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({ publicProfilePilotError: error });

                return EMPTY;
            })
        );
    }
}
