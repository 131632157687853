import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { TrainingExamResultsFilterFormKeys, TrainingExamResultsFilterParams } from "../../models/training-exam-results.models";

interface TrainingExamResultsFiltersForm {
    [TrainingExamResultsFilterFormKeys.SearchPhrase]: FormControl<string>;
}

interface TrainingExamResultsFiltersComponentState {
    initialFilters: TrainingExamResultsFilterParams | undefined;
    appliedFiltersLength: number | undefined;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: TrainingExamResultsFilterFormKeys.SearchPhrase,
        filterLabel: "dtmWebAppLibTrainingExamResults.filters.searchPhrase",
        type: FilterType.TextEllipsis,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-training-exam-results-filters",
    templateUrl: "./training-exam-results-filters.component.html",
    styleUrls: ["./training-exam-results-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TrainingExamResultsFiltersComponent {
    @Input()
    public set initialFilters(value: TrainingExamResultsFilterParams | undefined) {
        this.localStore.patchState({ initialFilters: value });
    }

    @Output() public readonly filtersChange: EventEmitter<Partial<TrainingExamResultsFilterParams>> = new EventEmitter();

    protected readonly appliedFiltersLength$ = this.localStore.selectByKey("appliedFiltersLength");
    protected readonly TrainingExamResultsFilterFormKeys = TrainingExamResultsFilterFormKeys;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly trainingExamResultsFiltersForm = new FormGroup<TrainingExamResultsFiltersForm>({
        [TrainingExamResultsFilterFormKeys.SearchPhrase]: new FormControl("", { nonNullable: true }),
    });

    constructor(private readonly localStore: LocalComponentStore<TrainingExamResultsFiltersComponentState>) {
        this.localStore.setState({
            appliedFiltersLength: 0,
            initialFilters: undefined,
        });

        this.assignInitialFilters();
        this.watchFormAndEmitFiltersOnChange();
    }

    protected clearFilters() {
        this.trainingExamResultsFiltersForm.reset();
    }

    private watchFormAndEmitFiltersOnChange() {
        this.trainingExamResultsFiltersForm.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(() => {
                this.setActiveFiltersCount();
                this.filtersChange.emit(this.trainingExamResultsFiltersForm.getRawValue());
            });
    }

    private assignInitialFilters() {
        this.localStore
            .selectByKey("initialFilters")
            .pipe(RxjsUtils.filterFalsy(), first(), untilDestroyed(this))
            .subscribe((filters: TrainingExamResultsFilterParams) => {
                this.trainingExamResultsFiltersForm.patchValue({ ...filters });
                this.setActiveFiltersCount();
            });
    }

    private setActiveFiltersCount() {
        this.localStore.patchState({
            appliedFiltersLength: Object.values(this.trainingExamResultsFiltersForm.getRawValue()).flat().filter(FunctionUtils.isTruthy)
                .length,
        });
    }
}
