<mat-card *ngrxLet="{ operator: operator$, canManageOperator: canManageOperator$ } as vm">
    <div class="operator-number-header">
        <dtm-ui-card-header>
            {{ "dtmWebAppOperatorProfile.operatorNumber.operatorNumberHeader" | transloco }}
        </dtm-ui-card-header>
        <div class="header-badges">
            <span *ngIf="vm.operator.isEmergency" class="chip light">
                {{ "dtmWebAppOperatorProfile.operatorNumber.emergencyLabel" | transloco }}
            </span>
            <dtm-ui-operator-status-badge [status]="vm.operator.status"></dtm-ui-operator-status-badge>
        </div>
    </div>
    <div class="operator-number-details">
        <ng-container *ngrxLet="isSecretCodeVisible$ as isCodeVisible">
            <dtm-ui-label-value
                [label]="'dtmWebAppOperatorProfile.operatorNumber.operatorNumberLabel' | transloco"
                [labelHint]="'dtmWebAppOperatorProfile.operatorNumber.operatorNumberLabelHint' | transloco"
                [value]="vm.operator.operatorNumber.number"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.canManageOperator"
                [label]="'dtmWebAppOperatorProfile.operatorNumber.secureCode' | transloco"
                [labelHint]="'dtmWebAppOperatorProfile.operatorNumber.secureCodeLabelHint' | transloco"
                [value]="!isCodeVisible ? '***' : vm.operator.operatorNumber.secretCode"
            >
            </dtm-ui-label-value>
            <button
                type="button"
                class="action-button button-secondary"
                [cdkCopyToClipboard]="vm.operator.operatorNumber.number"
                (cdkCopyToClipboardCopied)="handleValueCopy($event)"
            >
                <dtm-ui-icon [name]="'file-copy'"></dtm-ui-icon>
                {{ "dtmWebAppOperatorProfile.operatorNumber.copyButtonLabel" | transloco }}
            </button>
            <button
                type="button"
                class="action-button button-secondary"
                (click)="secretCodeVisibilityChange()"
                *ngIf="vm.canManageOperator"
            >
                <dtm-ui-icon [name]="!isCodeVisible ? 'eye' : 'eye-off'"></dtm-ui-icon>
                {{
                    !isCodeVisible
                        ? ("dtmWebAppOperatorProfile.operatorNumber.showButtonLabel" | transloco)
                        : ("dtmWebAppOperatorProfile.operatorNumber.hideButtonLabel" | transloco)
                }}
            </button>
        </ng-container>
    </div>
    <ng-container *ngIf="vm.operator.documentsToDownload?.length && vm.canManageOperator">
        <h6 class="documents-to-download-title">{{ "dtmWebAppOperatorProfile.operatorNumber.documentsToDownloadLabel" | transloco }}</h6>
        <div class="documents-to-download" *ngFor="let document of vm.operator.documentsToDownload">
            <dtm-ui-icon class="diploma-icon" name="diploma"></dtm-ui-icon>

            <ng-container *ngFor="let documentType of OperatorConfirmationDocumentType | keyvalue">
                <p class="download-confirmation" *ngIf="documentType.value === document">
                    {{
                        "dtmWebAppOperatorProfile.operatorNumber.operatorConfirmationDocumentLabel"
                            | transloco : { documentType: documentType.key }
                    }}
                </p>
            </ng-container>
            <button type="button" class="button-icon" (click)="downloadFile.emit(document)">
                <dtm-ui-icon name="download"></dtm-ui-icon>
            </button>
        </div>
    </ng-container>
</mat-card>
