import { Injectable } from "@angular/core";
import { FilesGroup, MAX_UPLOADED_FILE_SIZE_BYTES } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { ChapterAttachment, ChapterAttachmentType, ChapterAttachmentsConfig } from "./operations-manual.models";

@Injectable({
    providedIn: "root",
})
export class AttachmentsHelperService {
    constructor(private readonly transloco: TranslocoService) {}

    public convertAttachmentsToFilesGroups(
        attachments: ChapterAttachment[],
        config: ChapterAttachmentsConfig[]
    ): FilesGroup<ChapterAttachmentType>[] {
        const result: FilesGroup<ChapterAttachmentType>[] = [];

        for (const configItem of config) {
            result.push({
                groupId: configItem.type,
                groupLabel: this.transloco.translate("dtmWebAppLibOpsMan.attachments.typeLabel", { type: configItem.type }),
                files: attachments
                    .filter((attachment) => attachment.type === configItem.type)
                    .map((attachment) => ({
                        id: attachment.id,
                        name: attachment.name,
                        size: attachment.size,
                        isRemovable: true,
                    })),
                validators: {
                    singleFile: configItem.maxAmount === 1,
                    allowedTypes: configItem.formats,
                    maxSize: MAX_UPLOADED_FILE_SIZE_BYTES,
                },
            });
        }

        return result;
    }

    public convertFilesGroupsToAttachments(filesGroups: FilesGroup<ChapterAttachmentType>[]): ChapterAttachment[] {
        return filesGroups.reduce<ChapterAttachment[]>(
            (result, filesGroup) => [
                ...result,
                ...filesGroup.files.map((file) => ({
                    id: file.id,
                    type: filesGroup.groupId,
                    name: file.name,
                    size: file.size ?? 0,
                })),
            ],
            []
        );
    }
}
