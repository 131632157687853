import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { saveAs } from "file-saver";
import { Observable, tap, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ForeignOperatorVerificationDetails, OperatorRegistrationCapabilities } from "../../shared/models";
import { OPERATOR_REGISTRATION_ENDPOINTS, OperatorRegistrationEndpoints } from "../operator-registration.tokens";
import { OperatorRegistrationFormValue, RegisteredOperator } from "./models";
import {
    AssociationOperatorRegistrationResponseBody,
    EnterpriseOperatorRegistrationResponseBody,
    GetOperatorRegistrationCapabilitiesResponseBody,
    convertHttpErrorResponseToForeignOperatorVerificationError,
    convertOperatorRegistrationResponseBodyToRegisteredOperator,
    transformGetOperatorRegistrationCapabilitiesResponseBodyToOperatorRegistrationCapabilities,
    transformOperatorRegistrationErrorResponse,
    transformOperatorRegistrationToAssociationOperatorRegistrationBody,
    transformOperatorRegistrationToEntepriseOperatorRegistrationBody,
} from "./operator-registration-api.converters";

@Injectable({
    providedIn: "root",
})
export class OperatorRegistrationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly transloco: TranslocoService,
        @Inject(OPERATOR_REGISTRATION_ENDPOINTS) private readonly endpoints: OperatorRegistrationEndpoints
    ) {}

    public getOperatorRegistrationCapabilities(userId: string): Observable<OperatorRegistrationCapabilities> {
        return this.httpClient
            .get<GetOperatorRegistrationCapabilitiesResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getOperatorRegistrationCapabilities, { userId })
            )
            .pipe(
                map((response) => transformGetOperatorRegistrationCapabilitiesResponseBodyToOperatorRegistrationCapabilities(response)),
                catchError((error) => throwError(() => transformOperatorRegistrationErrorResponse(error)))
            );
    }

    public verifyForeignOperator(foreignOperator: ForeignOperatorVerificationDetails): Observable<void> {
        return this.httpClient
            .put<void>(this.endpoints.verifyForeignOperatorNumber, { ...foreignOperator })
            .pipe(catchError((error) => throwError(() => convertHttpErrorResponseToForeignOperatorVerificationError(error))));
    }

    public registerOperator(operatorRegistrationFormValue: OperatorRegistrationFormValue): Observable<RegisteredOperator> {
        if (operatorRegistrationFormValue.associationNationalCourtRegister || operatorRegistrationFormValue.associationRegistrationNumber) {
            return this.registerAssociationOperator(operatorRegistrationFormValue);
        }

        return this.registerEnterpriseOperator(operatorRegistrationFormValue);
    }

    public downloadAttorneyTemplate() {
        return this.httpClient.get(this.endpoints.downloadAttorneyTemplate, { responseType: "blob" }).pipe(
            tap((blob: Blob) =>
                saveAs(
                    blob,
                    this.transloco.translate(
                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneyTemplateFileName"
                    )
                )
            ),
            catchError((error) => throwError(() => transformOperatorRegistrationErrorResponse(error)))
        );
    }

    private registerAssociationOperator(operatorRegistrationFormValue: OperatorRegistrationFormValue) {
        const body = transformOperatorRegistrationToAssociationOperatorRegistrationBody(operatorRegistrationFormValue);

        return this.httpClient
            .post<AssociationOperatorRegistrationResponseBody>(this.endpoints.registerAssociationOperator, { ...body })
            .pipe(
                map((response: AssociationOperatorRegistrationResponseBody) =>
                    convertOperatorRegistrationResponseBodyToRegisteredOperator(response)
                ),
                catchError((error) => throwError(() => transformOperatorRegistrationErrorResponse(error)))
            );
    }

    private registerEnterpriseOperator(operatorRegistrationFormValue: OperatorRegistrationFormValue) {
        const body = transformOperatorRegistrationToEntepriseOperatorRegistrationBody(operatorRegistrationFormValue);

        return this.httpClient
            .post<EnterpriseOperatorRegistrationResponseBody>(this.endpoints.registerEnterpriseOperator, { ...body })
            .pipe(
                map((response: EnterpriseOperatorRegistrationResponseBody) =>
                    convertOperatorRegistrationResponseBodyToRegisteredOperator(response)
                ),
                catchError((error) => throwError(() => transformOperatorRegistrationErrorResponse(error)))
            );
    }
}
