import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { SerializableCartographic } from "@dtm-frontend/shared/map/cesium";
import { IconName } from "@dtm-frontend/shared/ui";
import { GeographicCoordinatesDirection, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable, combineLatest, map } from "rxjs";
import { HeightType } from "../../../../../../../models/mission.model";
import { DEFAULT_TRACK_DETAILS_DISPLAY_MODE, TrackDetailsDisplayMode } from "../track-details/track-details-display-mode";

export type PointType = "inlet" | "outlet" | "waypoint";

interface PointControlsComponentState {
    label: string | undefined;
    min: number;
    max: number;
    height: number | undefined;
    amslOffset: number | undefined;
    coordinates: SerializableCartographic | undefined;
    type: PointType;
    isCoordinatesEditMode: boolean;
    heightEditMode: HeightType;
    displayMode: TrackDetailsDisplayMode;
}

@Component({
    selector: "dtm-web-app-lib-mwiesip-point-controls[displayMode]",
    templateUrl: "./point-controls.component.html",
    styleUrls: ["./point-controls.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PointControlsComponent {
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly GeographicCoordinatesDirection = GeographicCoordinatesDirection;
    protected readonly HeightType = HeightType;

    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }
    @Input() public set min(value: number) {
        this.localStore.patchState({ min: value });
    }
    @Input() public set max(value: number) {
        this.localStore.patchState({ max: value });
    }
    @Input() public set height(value: number) {
        this.localStore.patchState({ height: value });
    }
    @Input() public set amslOffset(value: number | undefined) {
        this.localStore.patchState({ amslOffset: value });
    }
    @Input() public set type(value: PointType) {
        this.localStore.patchState({ type: value });
    }
    @Input() public set isCoordinatesEditMode(value: BooleanInput) {
        this.localStore.patchState({ isCoordinatesEditMode: coerceBooleanProperty(value) });
    }
    @Input() public set coordinates(value: SerializableCartographic) {
        this.localStore.patchState({ coordinates: value });
        this.latitudeInputControl.setValue(value.latitude);
        this.longitudeInputControl.setValue(value.longitude);
    }
    @Input() public set heightEditMode(value: HeightType | undefined) {
        this.localStore.patchState({ heightEditMode: value ?? HeightType.AGL });
    }
    @Input() public set displayMode(value: TrackDetailsDisplayMode) {
        this.localStore.patchState({ displayMode: value });
    }

    @Output() public readonly pointHeightInputChange = new EventEmitter<number>();
    @Output() public readonly pointCoordinatesChange = new EventEmitter<SerializableCartographic>();

    protected readonly latitudeInputControl = new FormControl<number | null>(null, [Validators.required]);
    protected readonly longitudeInputControl = new FormControl<number | null>(null, [Validators.required]);

    protected readonly label$ = this.localStore.selectByKey("label");
    protected readonly height$ = this.localStore.selectByKey("height");
    protected readonly amslOffset$ = this.localStore.selectByKey("amslOffset");
    protected readonly isCoordinatesEditMode$ = this.localStore.selectByKey("isCoordinatesEditMode");
    protected readonly heightEditMode$ = this.localStore.selectByKey("heightEditMode");
    protected readonly displayMode$ = this.localStore.selectByKey("displayMode");
    protected readonly min$ = combineLatest([
        this.localStore.selectByKey("min").pipe(map((value) => Math.round(value))),
        this.amslOffset$,
        this.heightEditMode$,
    ]).pipe(
        map(([min, amslOffset, heightEditMode]) => {
            if (heightEditMode === HeightType.AMSL && amslOffset !== undefined) {
                return Math.round(min + amslOffset);
            }

            return min;
        })
    );
    protected readonly max$ = combineLatest([
        this.localStore.selectByKey("max").pipe(map((value) => Math.round(value))),
        this.amslOffset$,
        this.heightEditMode$,
    ]).pipe(
        map(([max, amslOffset, heightEditMode]) => {
            if (heightEditMode === HeightType.AMSL && amslOffset !== undefined) {
                return Math.round(max + amslOffset);
            }

            return max;
        })
    );
    protected readonly icon$: Observable<IconName> = this.localStore.selectByKey("type").pipe(
        map((type) => {
            switch (type) {
                case "inlet":
                    return "complex-cylinder-point-left";
                case "outlet":
                    return "complex-cylinder-point-right";
                default:
                    return "complex-waypoint-edit";
            }
        })
    );

    constructor(private readonly localStore: LocalComponentStore<PointControlsComponentState>) {
        localStore.setState({
            label: undefined,
            min: Number.MIN_VALUE,
            max: Number.MAX_VALUE,
            height: undefined,
            type: "waypoint",
            coordinates: undefined,
            isCoordinatesEditMode: false,
            amslOffset: undefined,
            heightEditMode: HeightType.AGL,
            displayMode: DEFAULT_TRACK_DETAILS_DISPLAY_MODE,
        });
    }

    protected updateCoordinates() {
        if (this.latitudeInputControl.value === null || this.longitudeInputControl.value === null) {
            return;
        }

        this.pointCoordinatesChange.emit({
            latitude: this.latitudeInputControl.value,
            longitude: this.longitudeInputControl.value,
        });
    }

    protected emitRoundedValue(value: number, emitEvent: EventEmitter<number>): void {
        emitEvent.emit(Math.round(value));
    }
}
