import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";

const MAX_NAME_LENGTH = 255;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-draft-name-dialog",
    templateUrl: "./draft-name-dialog.component.html",
    styleUrls: ["./draft-name-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraftNameDialogComponent {
    public readonly MAX_NAME_LENGTH = MAX_NAME_LENGTH;

    private setDraftNameSubject = new Subject<string>();
    public setDraftName$ = this.setDraftNameSubject.asObservable();

    public nameFormControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.pattern(/[^\s]/), Validators.maxLength(MAX_NAME_LENGTH)],
        nonNullable: true,
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: { isSaveDraftProcessing$: Observable<boolean> }) {}

    public save() {
        this.nameFormControl.markAllAsTouched();

        if (this.nameFormControl.invalid) {
            return;
        }

        this.setDraftNameSubject.next(this.nameFormControl.value);
    }
}
