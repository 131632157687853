import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface OperatorMigrationResetPasswordStepComponentState {
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-reset-password-step",
    templateUrl: "./operator-migration-reset-password-step.component.html",
    styleUrls: ["./operator-migration-reset-password-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorMigrationResetPasswordStepComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Output() public readonly resetPasswordSubmit = new EventEmitter<string>();
    @Output() public readonly passwordResetCancel = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly emailControl = new FormControl<string>("", {
        validators: [Validators.email, Validators.required],
        nonNullable: true,
    });

    constructor(private readonly localStore: LocalComponentStore<OperatorMigrationResetPasswordStepComponentState>) {
        localStore.setState({ isProcessing: false });
    }

    protected validateFormAndSave() {
        this.emailControl.markAsTouched();

        if (this.emailControl.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.resetPasswordSubmit.emit(this.emailControl.value);
    }

    protected cancelPasswordReset() {
        this.passwordResetCancel.emit();
    }
}
