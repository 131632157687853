<dtm-ui-expandable-panel *ngrxLet="operator$ as operator" [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <dtm-ui-expandable-simple-header
        headerSlot
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.operatorHeaderLabel' | transloco"
        [value]="operator.name"
    >
    </dtm-ui-expandable-simple-header>
    <div class="properties">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.operatorNumberLabel' | transloco"
            [value]="operator.number"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.companyNumberLabel' | transloco"
            [value]="operator.companyNumber | tin | falsyDash"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.streetNameLabel' | transloco"
            [value]="operator.address.streetName"
        ></dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.houseNumberLabel' | transloco">
            {{ operator.address.houseNumber }}
            <ng-container *ngIf="operator.address.apartmentNumber">/ {{ operator.address.apartmentNumber }}</ng-container>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.postCodeLabel' | transloco"
            [value]="operator.address.postCode | postCode : operator.address.country"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.cityLabel' | transloco"
            [value]="operator.address.city"
        ></dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.countryLabel' | transloco">
            <dtm-ui-country-display [country]="operator.address.country"></dtm-ui-country-display>
        </dtm-ui-label-value>
    </div>
    <h2 class="authorization-header">
        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.authorizationLabel" | transloco }}
    </h2>
    <ul class="authorizations">
        <li *ngFor="let authorization of operator.operationalAuthorizations">
            <span [ngSwitch]="authorization.operationScenario.category" class="authorization-icon">
                <dtm-ui-icon name="shield" *ngSwitchCase="MissionCategory.Open"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchDefault name="shield-cross"></dtm-ui-icon>
            </span>
            <strong class="authorization-name">{{ authorization.operationScenario.name }}</strong>
            <span *ngIf="authorization.expirationDate" class="expiration-date">
                {{
                    "dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.dateLimitLabel"
                        | transloco : { value: authorization.expirationDate | localizeDate : { dateStyle: "short" } }
                }}
            </span>
            <span *ngIf="authorization.expirationDate === null" class="expiration-date">
                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.operatorInfo.unlimitedLabel" | transloco }}
            </span>
        </li>
    </ul>
</dtm-ui-expandable-panel>
