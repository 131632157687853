import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NewThreadComponent } from "./components/new-thread/new-thread.component";
import { OperatorConversationFiltersComponent } from "./components/operator-conversation-filters/operator-conversation-filters.component";
import { OperatorConversationsComponent } from "./components/operator-conversations/operator-conversations.component";
import { OperatorConversationsRoutingModule } from "./operator-conversations-routing.module";
import { OperatorConversationsApiService } from "./services/operator-conversations-api.service";
import { OperatorConversationsState } from "./state/operator-conversations.state";

@NgModule({
    declarations: [OperatorConversationsComponent, NewThreadComponent, OperatorConversationFiltersComponent],
    imports: [
        CommonModule,
        OperatorConversationsRoutingModule,
        LetModule,
        PushModule,
        NgxsModule.forFeature([OperatorConversationsState]),
        SharedUiModule,
        MatProgressSpinnerModule,
        TranslocoModule,
        MatCardModule,
        ReactiveFormsModule,
        MatBottomSheetModule,
        MatExpansionModule,
        MatTooltipModule,
        MatDialogModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibOperatorConversations",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: OperatorConversationsApiService,
            useValue: undefined,
        },
    ],
})
export class OperatorConversationsModule {
    public static forRoot(): ModuleWithProviders<OperatorConversationsModule> {
        return {
            ngModule: OperatorConversationsModule,
            providers: [
                {
                    provide: OperatorConversationsApiService,
                    useClass: OperatorConversationsApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<OperatorConversationsModule> {
        return {
            ngModule: OperatorConversationsModule,
            providers: [
                {
                    provide: OperatorConversationsApiService,
                    useValue: null,
                },
            ],
        };
    }
}
