<dtm-ui-lib-wizard-header [wizardId]="wizardId">
    <dtm-ui-lib-wizard-step-header [stepId]="RegisterPilotWizardSteps.BasicData">
        {{ "dtmWebAppLibPilotOperatorRegistration.basicDataHeader" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="RegisterPilotWizardSteps.AdditionalData">
        {{ "dtmWebAppLibPilotOperatorRegistration.additionalDataHeader" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="RegisterPilotWizardSteps.Summary">
        {{ "dtmWebAppLibPilotOperatorRegistration.summaryHeader" | transloco }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
