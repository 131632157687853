<ng-container *ngrxLet="{ chapter: chapter$, isNested: isNested$ } as vm">
    <ng-container *ngIf="vm.chapter.content || vm.chapter.isEditable">
        <ng-container *ngIf="!(isEditMode$ | ngrxPush); else editModeTemplate">
            <section class="heading" [class.nested]="vm.isNested">
                <h2 class="header">{{ vm.chapter.name }}</h2>
                <button
                    *ngIf="vm.chapter.isEditable && vm.chapter.attachmentsFeature.isEnabled"
                    type="button"
                    class="button-secondary"
                    (click)="openAttachmentsManagement()"
                >
                    <dtm-ui-icon name="attachment"></dtm-ui-icon>
                    {{ "dtmWebAppLibOpsMan.attachments.manageButtonLabel" | transloco }}
                </button>
                <button *ngIf="vm.chapter.isEditable" type="button" class="button-secondary" (click)="openEditMode()">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmWebAppLibOpsMan.chapter.editButtonLabel" | transloco }}
                </button>
                <button *ngIf="isGuideButtonVisible$ | ngrxPush" type="button" class="button-secondary" (click)="guideShow.emit()">
                    <dtm-ui-icon name="question"></dtm-ui-icon>
                    {{ "dtmWebAppLibOpsMan.chapter.guideButtonLabel" | transloco }}
                </button>
            </section>
            <section class="content-wrapper">
                <quill-view-html *ngIf="vm.chapter.content" [content]="vm.chapter.content" [sanitize]="true"></quill-view-html>
                <p *ngIf="!vm.chapter.content && vm.chapter.isEditable" class="empty-content">
                    {{ "dtmWebAppLibOpsMan.chapter.emptyEditableContentLabel" | transloco }}
                </p>
                <dtm-web-app-lib-opsman-attachments-list [chapter]="vm.chapter"></dtm-web-app-lib-opsman-attachments-list>
            </section>
        </ng-container>

        <ng-template #editModeTemplate>
            <div class="edit-panel">
                <section class="heading" [class.nested]="vm.isNested">
                    <h3 class="header">{{ vm.chapter.name }}</h3>
                </section>
                <dtm-ui-quill-editor
                    [formControl]="contentControl"
                    [hasError]="!!contentControl.errors"
                    [placeholder]="'dtmWebAppLibOpsMan.chapter.emptyEditableContentLabel' | transloco"
                    dtmUiMarkValueAccessorControlsAsTouched
                >
                </dtm-ui-quill-editor>

                <div class="buttons">
                    <button type="button" class="button-primary" (click)="saveChanges()">
                        {{ "dtmWebAppLibOpsMan.chapter.saveButtonLabel" | transloco }}
                    </button>
                    <button type="button" class="button-secondary" (click)="closeEditMode()">
                        {{ "dtmWebAppLibOpsMan.chapter.cancelButtonLabel" | transloco }}
                    </button>
                </div>
            </div>
        </ng-template>
    </ng-container>
</ng-container>
