<dtm-ui-expandable-panel
    *ngrxLet="{
        soraSettings: soraSettings$,
        airRiskMitigation: airRiskMitigation$,
        isEditMode: isEditMode$,
        isExpanded: isExpanded$,
    } as vm"
    [hasHeaderSeparator]="false"
    [isDisabled]="vm.isEditMode"
    [isExpanded]="vm.isExpanded && (vm.soraSettings | invoke : isRiskMitigationAdded)"
>
    <div class="header" headerSlot>
        <h2 class="title">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationMeasureLabel" | transloco }}</h2>
        <button *ngIf="vm.isEditMode" type="button" class="button-secondary add-button" (click)="addOrEdit()">
            <ng-container *ngIf="vm.soraSettings | invoke : isRiskMitigationAdded; else addModeButtonTemplate">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.editButtonLabel" | transloco }}
            </ng-container>
            <ng-template #addModeButtonTemplate>
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.addButtonLabel" | transloco }}
            </ng-template>
        </button>
    </div>
    <div class="content" *ngIf="vm.soraSettings?.riskMitigations?.m1A as mitigationM1A">
        <p class="title">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationMeasureM1ALabel" | transloco }}</p>
        <div class="position" [ngSwitch]="mitigationM1A.robustnessLevel">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelLabel" | transloco }}</p>
            <span class="description" *ngSwitchCase="RobustnessLevel.Low">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel"
                        | transloco : { value: RobustnessLevel.Low }
                }}
                <dtm-ui-popover
                    [popoverText]="'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsTooltip.low' | transloco"
                ></dtm-ui-popover>
            </span>
            <span class="description" *ngSwitchCase="RobustnessLevel.Medium">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel"
                        | transloco : { value: RobustnessLevel.Medium }
                }}
                <dtm-ui-popover
                    [popoverText]="'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsTooltip.medium' | transloco"
                ></dtm-ui-popover>
            </span>
            <span class="description" *ngSwitchCase="RobustnessLevel.High">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel"
                        | transloco : { value: RobustnessLevel.High }
                }}
                <dtm-ui-popover
                    [popoverText]="'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsTooltip.high' | transloco"
                ></dtm-ui-popover>
            </span>
        </div>

        <div class="position" *ngIf="mitigationM1A.lowerPeopleDensity && mitigationM1A.lowerPeopleDensity.userStatements?.length">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label" *ngFor="let statementKey of mitigationM1A.lowerPeopleDensity.userStatements">
                {{ statementKey | transloco }}
            </p>
            <span class="description" *ngIf="mitigationM1A.lowerPeopleDensity.comment">
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.commentInfoLabel" | transloco
                }}{{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentsInfoLabel"
                        | transloco : { length: mitigationM1A.lowerPeopleDensity.attachments?.length ?? 0 }
                }}
                <dtm-ui-popover [popoverText]="mitigationM1A.lowerPeopleDensity.comment"></dtm-ui-popover>
            </span>
        </div>
        <div class="position" *ngIf="mitigationM1A.bystandersShielded && mitigationM1A.bystandersShielded.userStatements?.length">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label" *ngFor="let statementKey of mitigationM1A.bystandersShielded!.userStatements">
                {{ statementKey | transloco }}
            </p>
            <span class="description" *ngIf="mitigationM1A.bystandersShielded.comment">
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.commentInfoLabel" | transloco
                }}{{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentsInfoLabel"
                        | transloco : { length: mitigationM1A.bystandersShielded.attachments?.length ?? 0 }
                }}
                <dtm-ui-popover [popoverText]="mitigationM1A.bystandersShielded.comment"></dtm-ui-popover>
            </span>
        </div>
    </div>
    <div class="content" *ngIf="vm.soraSettings?.riskMitigations?.m1B as mitigationM1B">
        <p class="title">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.operationVLOSAvoidingFlightOverPeople" | transloco }}</p>
        <div class="position">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelLabel" | transloco }}</p>
            <span class="description">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel"
                        | transloco : { value: RobustnessLevel.Low }
                }}
            </span>
        </div>
        <div class="position" *ngIf="mitigationM1B.userStatements?.length">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label">
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.statementsLabel" | transloco }}
            </p>
            <ul class="description">
                <li *ngFor="let statementKey of mitigationM1B.userStatements">
                    {{ statementKey | transloco }}
                    <ul *ngIf="statementKey === M1BStatementsKeys.ableToReduceNumberOfBystandersAtRisk">
                        <li *ngFor="let statement of mitigationM1B.bystandersSafeDistance?.userStatements ?? []">
                            - {{ statement | transloco }}
                        </li>
                        <li *ngFor="let statement of mitigationM1B.abnormalSituationCommand?.userStatements ?? []">
                            - {{ statement | transloco }}
                        </li>
                        <li *ngFor="let statement of mitigationM1B.abnormalSituationAlert?.userStatements ?? []">
                            - {{ statement | transloco }}
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="content" *ngIf="vm.soraSettings?.riskMitigations?.m2 as mitigationM2">
        <p class="title">
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.reducingEffectOfHittingGroundM2Label" | transloco }}
        </p>
        <div class="position">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label">
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelLabel" | transloco }}
            </p>
            <span class="description">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel"
                        | transloco : { value: mitigationM2.robustnessLevel }
                }}
            </span>
        </div>
        <div class="position" *ngIf="mitigationM2.parachute">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label">
                {{ M2StatementsKeys.parachute | transloco }}
            </p>
            <div class="description">
                <div>
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.statementsLabel" | transloco }}
                    <ul class="description">
                        <ng-container *ngFor="let statement of mitigationM2.parachute?.userStatements ?? []">
                            <li *ngIf="statement !== M2StatementsKeys.parachute">{{ statement | transloco }}</li>
                        </ng-container>
                    </ul>
                </div>

                <div class="attachment" *ngIf="mitigationM2.parachute?.attachments?.length">
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentLabel" | transloco }}
                </div>
            </div>
        </div>
        <div class="position" *ngIf="mitigationM2.criticalAreaShielded">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label">
                {{ M2StatementsKeys.criticalAreaShielded | transloco }}
            </p>
            <span class="description" *ngIf="mitigationM2.criticalAreaShielded?.attachments?.length">
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentLabel" | transloco }}
            </span>
        </div>
        <div class="position" *ngIf="mitigationM2.other">
            <dtm-ui-icon name="check"></dtm-ui-icon>
            <p class="field-label">
                {{ M2StatementsKeys.other | transloco }}
            </p>
            <span class="description" *ngIf="mitigationM2.other.comment">
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.commentInfoLabel" | transloco }}
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentsInfoLabel"
                        | transloco : { length: mitigationM2.other.attachments?.length ?? 0 }
                }}
                <dtm-ui-popover [popoverText]="mitigationM2.other.comment"></dtm-ui-popover>
            </span>
        </div>
    </div>
    <div class="content" *ngIf="vm.airRiskMitigation?.length">
        <p class="title">
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.strategicMitigationsLabel" | transloco }}
        </p>
        <ng-container *ngFor="let section of vm.airRiskMitigation">
            <p class="section-title">
                {{
                    "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.airRiskMitigationStatements"
                        | transloco : { value: section.category }
                }}
            </p>
            <div class="position" *ngIf="section.designator">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <p class="field-label">
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.zonesLabel" | transloco }}
                </p>
                <span class="description"> {{ section.designator }} </span>
            </div>
            <div class="position">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <div class="block">
                    <div>
                        <p class="field-label">
                            {{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.initialRiskClassLabel" | transloco
                            }}
                        </p>
                        <p class="description">
                            {{ section.initialArc | airRiskMitigationsArcAsNumber | arcLabel : true }}
                        </p>
                    </div>
                    <dtm-ui-icon name="arrow-right-2"></dtm-ui-icon>
                    <div>
                        <p class="field-label">
                            {{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.mitigationResidualArcLabel"
                                    | transloco
                            }}
                        </p>
                        <p class="description">
                            {{ section.finalArc | airRiskMitigationsArcAsNumber | arcLabel }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="position" *ngFor="let method of section.methods">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <p class="field-label">
                    {{ method | transloco }}
                </p>
            </div>
        </ng-container>
    </div>
</dtm-ui-expandable-panel>
