<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmWebAppLibUav.serialNumbersDialog.header" | transloco : { amount: data.serialNumbers.length } }}</div>
    <button type="button" class="button-icon" matDialogClose>
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    {{ data.serialNumbers | invoke : joinWithComma }}
    <button
        class="button-icon"
        [cdkCopyToClipboard]="data.serialNumbers | invoke : joinWithComma"
        (cdkCopyToClipboardCopied)="handleValueCopy($event)"
        type="button"
        [matTooltip]="'dtmUi.copyToClipboard.hint' | transloco"
    >
        <dtm-ui-icon name="file-copy"> </dtm-ui-icon>
    </button>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-primary" matDialogClose>
        {{ "dtmWebAppLibUav.serialNumbersDialog.closeButtonLabel" | transloco }}
    </button>
</div>
