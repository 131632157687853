<dtm-ui-expandable-panel *ngrxLet="competencies$; let competencies" [hasHeaderSeparator]="false" [isExpanded]="true">
    <div class="header" headerSlot>
        <h5>{{ "dtmWebAppMembership.pilotCompetencies.header" | transloco }}</h5>
    </div>
    <div class="competencies">
        <div class="competency" *ngFor="let competency of competencies">
            <ng-container [ngSwitch]="competency.operationScenarioCategory">
                <dtm-ui-icon *ngSwitchCase="OperationScenarioCategory.Open" name="shield" class="shield"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchDefault name="shield-cross" class="shield"></dtm-ui-icon>
            </ng-container>
            <div class="competency-value">
                <h6 class="name">{{ competency.operationScenarioName }}</h6>
                <p class="details">
                    <ng-container *ngIf="competency.status === PilotsCompetencyStatus.Active; else statusTemplate">
                        {{
                            "dtmWebAppMembership.pilotCompetencies.validityTermLabel"
                                | transloco : { date: competency.expirationDate | localizeDate }
                        }}
                    </ng-container>

                    <ng-template #statusTemplate>
                        {{ "dtmWebAppMembership.pilotCompetencies.statusLabel" | transloco : { status: competency.status } }}
                    </ng-template>
                </p>
            </div>
        </div>
    </div>
</dtm-ui-expandable-panel>
