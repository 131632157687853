import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Mission } from "../../models/tactical.models";

interface TacticalMissionDetailsComponentState {
    mission: Mission | undefined;
}

@Component({
    selector: "dtm-web-app-lib-tactical-mission-details[mission]",
    templateUrl: "./tactical-mission-details.component.html",
    styleUrls: ["./tactical-mission-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TacticalMissionDetailsComponent {
    @Input()
    public set mission(value: Mission) {
        this.localStore.patchState({ mission: value });
    }

    public readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<TacticalMissionDetailsComponentState>) {
        localStore.setState({ mission: undefined });
    }
}
