import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RegisterPilotOperatorLegalGuardian } from "../../models";

export function legalGuardianFormValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.firstName && control.value.lastName && control.value.email) {
        return null;
    }

    return { invalidLegalGuardian: true };
}

const NAME_MAX_LENGTH = 100;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-pilot-operator-registration-legal-guardian-form",
    templateUrl: "./pilot-operator-registration-legal-guardian-form.component.html",
    styleUrls: ["../../styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PilotOperatorRegistrationLegalGuardianFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PilotOperatorRegistrationLegalGuardianFormComponent),
            multi: true,
        },
    ],
})
export class PilotOperatorRegistrationLegalGuardianFormComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() public set shouldMarkAsTouched(value: boolean | undefined) {
        if (value) {
            this.legalGuardianForm.markAllAsTouched();
        }
    }
    protected readonly legalGuardianFirstNameControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(NAME_MAX_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly legalGuardianLastNameControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.maxLength(NAME_MAX_LENGTH), Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly legalGuardianEmailControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
    });

    protected readonly legalGuardianForm = new FormGroup({
        firstName: this.legalGuardianFirstNameControl,
        lastName: this.legalGuardianLastNameControl,
        email: this.legalGuardianEmailControl,
    });

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: RegisterPilotOperatorLegalGuardian) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public ngOnInit() {
        this.legalGuardianForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateChange(this.legalGuardianForm.getRawValue());
        });
    }

    public registerOnChange(fn: (value: RegisterPilotOperatorLegalGuardian) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.legalGuardianForm.valid) {
            return null;
        }

        return this.legalGuardianForm.invalid ? { invalidLegalGuardian: true } : null;
    }

    public writeValue(value: RegisterPilotOperatorLegalGuardian): void {
        this.legalGuardianForm.setValue(value, { emitEvent: false });

        if (!this.validate()) {
            this.propagateChange(this.legalGuardianForm.getRawValue());
        }
        this.onValidationChange();
    }
}
