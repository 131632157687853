import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { OperatorContextActions } from "../../shared/operator-context/state/operator-context.actions";
import { MembershipApiService } from "../services/membership-api.service";
import {
    Member,
    MemberProfile,
    MembershipCapabilities,
    MembershipCapabilitiesError,
    MembershipError,
    MembershipInvitation,
    MembershipInvitationError,
    MembershipInvitationStatus,
    MembershipMission,
    MembershipPermissionsError,
    MembershipStatus,
} from "../services/membership.models";
import { MembershipActions } from "./membership.actions";

export interface MembershipStateModel {
    capabilities: MembershipCapabilities | undefined;
    capabilitiesError: MembershipCapabilitiesError | undefined;
    userInvitationError: MembershipError | undefined;
    invitation: MembershipInvitation | undefined;
    getInvitationDetailsError: MembershipInvitationError | undefined;
    invitationAcceptanceError: MembershipInvitationError | undefined;
    invitationRejectionError: MembershipInvitationError | undefined;
    membersListError: MembershipError | undefined;
    membersList: Member[] | undefined;
    membersPages: Page | undefined;
    isMembersListProcessing: boolean;
    memberProfile: MemberProfile | undefined;
    isMemberProfileProcessing: boolean;
    memberProfileError: MembershipError | undefined;
    memberProcessingError: MembershipError | undefined;
    isCapabilitiesProcessing: boolean;
    isMemberProcessing: boolean;
    removeInvitationError: MembershipInvitationError | undefined;
    missionsList: MembershipMission[] | undefined;
    missionsListPages: Page | undefined;
    isMissionsProcessing: boolean;
    missionsListError: MembershipError | undefined;
    isUserPermissionsChangeProcessing: boolean;
    userPermissionsError: MembershipPermissionsError | undefined;
}

const defaultState: MembershipStateModel = {
    capabilities: undefined,
    capabilitiesError: undefined,
    userInvitationError: undefined,
    invitation: undefined,
    getInvitationDetailsError: undefined,
    invitationAcceptanceError: undefined,
    invitationRejectionError: undefined,
    membersListError: undefined,
    membersList: undefined,
    membersPages: undefined,
    isMembersListProcessing: false,
    memberProfile: undefined,
    isMemberProfileProcessing: false,
    memberProfileError: undefined,
    memberProcessingError: undefined,
    isCapabilitiesProcessing: false,
    isMemberProcessing: false,
    removeInvitationError: undefined,
    missionsList: undefined,
    missionsListPages: undefined,
    isMissionsProcessing: false,
    missionsListError: undefined,
    isUserPermissionsChangeProcessing: false,
    userPermissionsError: undefined,
};

@State<MembershipStateModel>({
    name: "membership",
    defaults: defaultState,
})
@Injectable()
export class MembershipState {
    @Selector()
    public static capabilities(state: MembershipStateModel): MembershipCapabilities | undefined {
        return state.capabilities;
    }

    @Selector()
    public static capabilitiesError(state: MembershipStateModel): MembershipCapabilitiesError | undefined {
        return state.capabilitiesError;
    }

    @Selector()
    public static userInvitationError(state: MembershipStateModel): MembershipError | undefined {
        return state.userInvitationError;
    }

    @Selector()
    public static changeUserPermissionsError(state: MembershipStateModel): MembershipPermissionsError | undefined {
        return state.userPermissionsError;
    }

    @Selector()
    public static isUserPermissionsChangeProcessing(state: MembershipStateModel): boolean {
        return state.isUserPermissionsChangeProcessing;
    }

    @Selector()
    public static invitation(state: MembershipStateModel): MembershipInvitation | undefined {
        return state.invitation;
    }

    @Selector()
    public static invitationAcceptanceError(state: MembershipStateModel): MembershipInvitationError | undefined {
        return state.invitationAcceptanceError;
    }

    @Selector()
    public static invitationRejectionError(state: MembershipStateModel): MembershipInvitationError | undefined {
        return state.invitationRejectionError;
    }

    @Selector()
    public static getInvitationDetailsError(state: MembershipStateModel): MembershipInvitationError | undefined {
        return state.getInvitationDetailsError;
    }

    @Selector()
    public static membersList(state: MembershipStateModel): Member[] | undefined {
        return state.membersList;
    }

    @Selector()
    public static membersPages(state: MembershipStateModel): Page | undefined {
        return state.membersPages;
    }

    @Selector()
    public static memberProfile(state: MembershipStateModel): MemberProfile | undefined {
        return state.memberProfile;
    }

    @Selector()
    public static memberProfileError(state: MembershipStateModel): MembershipError | undefined {
        return state.memberProfileError;
    }

    @Selector()
    public static isMemberProfileProcessing(state: MembershipStateModel): boolean {
        return state.isMemberProfileProcessing;
    }

    @Selector()
    public static memberProcessingError(state: MembershipStateModel): MembershipError | undefined {
        return state.memberProcessingError;
    }

    @Selector()
    public static isMemberProcessing(state: MembershipStateModel): boolean {
        return state.isMemberProcessing;
    }

    @Selector()
    public static isCapabilitiesProcessing(state: MembershipStateModel): boolean {
        return state.isCapabilitiesProcessing;
    }

    @Selector()
    public static removeInvitationError(state: MembershipStateModel): MembershipInvitationError | undefined {
        return state.removeInvitationError;
    }

    @Selector()
    public static membersListError(state: MembershipStateModel): MembershipError | undefined {
        return state.membersListError;
    }

    @Selector()
    public static isMembersListProcessing(state: MembershipStateModel): boolean {
        return state.isMembersListProcessing;
    }

    @Selector()
    public static missionsList(state: MembershipStateModel): MembershipMission[] | undefined {
        return state.missionsList;
    }

    @Selector()
    public static missionsListError(state: MembershipStateModel): MembershipError | undefined {
        return state.missionsListError;
    }

    @Selector()
    public static isMissionsProcessing(state: MembershipStateModel): boolean {
        return state.isMissionsProcessing;
    }

    @Selector()
    public static missionsListPages(state: MembershipStateModel): Page | undefined {
        return state.missionsListPages;
    }

    constructor(private readonly membershipApiService: MembershipApiService) {}

    @Action(MembershipActions.GetCapabilities)
    public getCapabilities(context: StateContext<MembershipStateModel>, action: MembershipActions.GetCapabilities) {
        context.patchState({ isCapabilitiesProcessing: true });

        return this.membershipApiService.getCapabilities(action.operatorId).pipe(
            tap((capabilities: MembershipCapabilities) => {
                context.patchState({
                    capabilities,
                });
            }),
            catchError((error) => {
                context.patchState({
                    capabilities: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isCapabilitiesProcessing: false }))
        );
    }

    @Action(MembershipActions.InviteUser)
    public inviteUser(context: StateContext<MembershipStateModel>, action: MembershipActions.InviteUser) {
        context.patchState({ userInvitationError: undefined });

        return this.membershipApiService.inviteUser(action.invitation).pipe(
            catchError((error) => {
                context.patchState({
                    userInvitationError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MembershipActions.ChangeMemberUserPermissions)
    public changeMembershipUserPermissions(
        context: StateContext<MembershipStateModel>,
        action: MembershipActions.ChangeMemberUserPermissions
    ) {
        context.patchState({ userPermissionsError: undefined, isUserPermissionsChangeProcessing: true });

        return this.membershipApiService.changeMembershipUserPermissions(action.operatorId, action.membershipId, action.permissions).pipe(
            catchError((error) => {
                context.patchState({
                    userPermissionsError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isUserPermissionsChangeProcessing: false });
                const changedUser = context.getState().membersList?.find((member) => member.membershipId === action.membershipId);
                if (changedUser?.isMe) {
                    context.dispatch(OperatorContextActions.GetGlobalCapabilities);
                }
            })
        );
    }

    @Action(MembershipActions.ChangeInvitedUserPermissions)
    public changeInvitedUserPermissions(
        context: StateContext<MembershipStateModel>,
        action: MembershipActions.ChangeInvitedUserPermissions
    ) {
        context.patchState({ userPermissionsError: undefined, isUserPermissionsChangeProcessing: true });

        return this.membershipApiService.changeInvitedUserPermissions(action.operatorId, action.invitationId, action.permissions).pipe(
            catchError((error) => {
                context.patchState({
                    userPermissionsError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isUserPermissionsChangeProcessing: false });
            })
        );
    }

    @Action(MembershipActions.GetMembers)
    public getMembers(context: StateContext<MembershipStateModel>, action: MembershipActions.GetMembers) {
        context.patchState({ membersListError: undefined, isMembersListProcessing: true });

        return this.membershipApiService.getMembers(action.params).pipe(
            tap((result) =>
                context.patchState({
                    membersList: result.content,
                    membersPages: { ...result.pages },
                })
            ),
            catchError((error) => {
                context.patchState({
                    membersList: undefined,
                    membersListError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isMembersListProcessing: false });
            })
        );
    }

    @Action(MembershipActions.ClearMembersList)
    public clearMembersList(context: StateContext<MembershipStateModel>) {
        context.patchState({ membersListError: undefined, membersList: undefined, isMembersListProcessing: false });

        return EMPTY;
    }

    @Action(MembershipActions.GetInvitationDetails)
    public getInvitationDetails(context: StateContext<MembershipStateModel>, action: MembershipActions.GetInvitationDetails) {
        return this.membershipApiService.getInvitationDetails(action.invitationId).pipe(
            tap((invitation: MembershipInvitation) => {
                context.patchState({
                    invitation,
                    getInvitationDetailsError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({
                    invitation: undefined,
                    getInvitationDetailsError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MembershipActions.AcceptInvitation)
    public acceptInvitation(context: StateContext<MembershipStateModel>, action: MembershipActions.AcceptInvitation) {
        return this.membershipApiService.changeInvitationStatus(action.invitationId, MembershipInvitationStatus.Accepted).pipe(
            tap(() => {
                context.patchState({
                    invitation: undefined,
                    invitationAcceptanceError: undefined,
                });
                context.dispatch(OperatorContextActions.GetGlobalCapabilities);
            }),
            catchError((error: MembershipInvitationError) => {
                context.patchState({
                    invitationAcceptanceError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MembershipActions.RejectInvitation)
    public rejectInvitation(context: StateContext<MembershipStateModel>, action: MembershipActions.AcceptInvitation) {
        return this.membershipApiService.changeInvitationStatus(action.invitationId, MembershipInvitationStatus.Rejected).pipe(
            tap(() => {
                context.patchState({
                    invitation: undefined,
                    invitationRejectionError: undefined,
                });
            }),
            catchError((error: MembershipInvitationError) => {
                context.patchState({
                    invitationRejectionError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MembershipActions.DeactivateMember)
    public deactivateMember(context: StateContext<MembershipStateModel>, action: MembershipActions.DeactivateMember) {
        context.patchState({ memberProcessingError: undefined, isMemberProcessing: true });

        return this.membershipApiService
            .changeMemberStatus(MembershipStatus.Deactivated, action.membershipId, action.membershipOperatorId, action.note)
            .pipe(
                catchError((error: MembershipError) => {
                    context.patchState({
                        memberProcessingError: error,
                    });

                    return EMPTY;
                }),
                finalize(() => context.patchState({ isMemberProcessing: false }))
            );
    }

    @Action(MembershipActions.RemoveMember)
    public removeMember(context: StateContext<MembershipStateModel>, action: MembershipActions.RemoveMember) {
        context.patchState({ memberProcessingError: undefined, isMemberProcessing: true });

        return this.membershipApiService.removeMember(action.membershipId).pipe(
            catchError((error: MembershipError) => {
                context.patchState({
                    memberProcessingError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isMemberProcessing: false }))
        );
    }

    @Action(MembershipActions.RemoveInvitation)
    public removeInvitation(context: StateContext<MembershipStateModel>, action: MembershipActions.RemoveInvitation) {
        context.patchState({ isMemberProcessing: true, removeInvitationError: undefined });

        return this.membershipApiService.removeInvitation(action.invitationId).pipe(
            catchError((error: MembershipInvitationError) => {
                context.patchState({
                    removeInvitationError: error,
                });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isMemberProcessing: false,
                })
            )
        );
    }

    @Action(MembershipActions.SetMembershipInvitationError)
    public setInvitationError(context: StateContext<MembershipStateModel>, action: MembershipActions.SetMembershipInvitationError) {
        context.patchState({ getInvitationDetailsError: action.invitationError });

        return EMPTY;
    }

    @Action(MembershipActions.GetPilotsProfile)
    public getPilotsProfile(context: StateContext<MembershipStateModel>, action: MembershipActions.GetPilotsProfile) {
        context.patchState({ isMemberProfileProcessing: true });

        return this.membershipApiService.getPilotsProfile(action.membershipPilotId).pipe(
            tap((memberProfile: MemberProfile) => {
                context.patchState({
                    memberProfile,
                    memberProfileError: undefined,
                });
            }),
            catchError((error) => {
                context.patchState({
                    memberProfile: undefined,
                    memberProfileError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isMemberProfileProcessing: false }))
        );
    }

    @Action(MembershipActions.ResendInvitation)
    public resendInvitation(context: StateContext<MembershipStateModel>, action: MembershipActions.ResendInvitation) {
        context.patchState({ userInvitationError: undefined });

        return this.membershipApiService.resendInvitation(action.invitationId).pipe(
            catchError((error) => {
                context.patchState({
                    userInvitationError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MembershipActions.ResendInvitationToMember)
    public resendInvitationToMember(context: StateContext<MembershipStateModel>, action: MembershipActions.ResendInvitationToMember) {
        context.patchState({ userInvitationError: undefined });

        return this.membershipApiService.resendInvitationToMember(action.membershipId).pipe(
            catchError((error) => {
                context.patchState({
                    userInvitationError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MembershipActions.GetMissionsList)
    public getMissionsList(context: StateContext<MembershipStateModel>, action: MembershipActions.GetMissionsList) {
        context.patchState({ missionsListError: undefined, isMissionsProcessing: true });

        return this.membershipApiService.getMissionsList(action.params).pipe(
            tap((response) => context.patchState({ missionsList: response.content, missionsListPages: response.pages })),
            catchError((error) => {
                context.patchState({
                    missionsListError: error,
                    missionsList: undefined,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isMissionsProcessing: false }))
        );
    }
}
