import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { checkIfMissionPlanRoutePossiblyViolateMaximumHeight } from "../check-route-height-violation";
import { HeightViolationAlertDialogComponent } from "../height-violation-alert-dialog/height-violation-alert-dialog.component";

interface HeightViolationAlertDialogLinkComponentState {
    isExpanded: boolean;
    route: MissionPlanRoute | undefined;
}

@Component({
    selector: "dtm-web-app-lib-height-violation-alert-dialog-link[route]",
    templateUrl: "./height-violation-alert-dialog-link.component.html",
    styleUrls: ["./height-violation-alert-dialog-link.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.foldAnimation()],
})
export class HeightViolationAlertDialogLinkComponent {
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly shouldShowLink$ = this.localStore
        .selectByKey("route")
        .pipe(map(checkIfMissionPlanRoutePossiblyViolateMaximumHeight));

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set route(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ route: value });
    }

    constructor(
        private readonly localStore: LocalComponentStore<HeightViolationAlertDialogLinkComponentState>,
        private readonly dialog: MatDialog
    ) {
        this.localStore.setState({
            isExpanded: true,
            route: undefined,
        });
    }

    protected openDialog(): void {
        this.dialog.open(HeightViolationAlertDialogComponent);
    }
}
