import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ButtonTheme, ConfirmationDialogComponent, ContextOperator, GlobalFeatures } from "@dtm-frontend/shared/ui";
import { WizardActions, WizardState } from "@dtm-frontend/shared/ui/wizard";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, defer, firstValueFrom, switchMap } from "rxjs";
import { combineLatestWith, filter, first, map } from "rxjs/operators";
import { UserContextService } from "../../../../shared/operator-context/services/user-context.service";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import {
    AdministrativeFeeInStatement,
    OperatorPermissionsErrorType,
    SinglePermission,
    StatementsWizardSteps,
    UavsSelectedForPermission,
} from "../../../services/operator-permissions.models";
import { OperatorPermissionsActions } from "../../../state/operator-permissions.actions";
import { OperatorPermissionsState } from "../../../state/operator-permissions.state";

const STEPS_AMOUNT_WITH_UAVS = 4;
const STEPS_AMOUNT_WITHOUT_UAVS = STEPS_AMOUNT_WITH_UAVS - 1;

export const STATEMENTS_WIZARD_ID = "statements-wizard";

interface StatementsWizardContentComponentState {
    selectedPossiblePermissions: SinglePermission[] | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-permissions-statements-wizard-content",
    templateUrl: "./statements-wizard-content.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatementsWizardContentComponent implements OnInit, OnDestroy {
    protected readonly isProcessing$ = this.store.select(OperatorPermissionsState.isStatementProcessing);
    protected readonly isAttorneyPowerAdministrativeFeeRequired$ = this.store.select(
        OperatorPermissionsState.isAttorneyPowerAdministrativeFeeRequired
    );
    protected readonly availableUavs$ = this.store.select(OperatorPermissionsState.availableUavs);
    protected readonly operator$ = this.store.select(OperatorPermissionsState.operatorDetails);
    protected readonly error$ = this.store.select(OperatorPermissionsState.statementsWizardError);
    protected readonly savedStatement$ = this.store.select(OperatorPermissionsState.savedStatement);
    protected readonly possiblePermissions$ = this.store.select(OperatorPermissionsState.possiblePermissions).pipe(RxjsUtils.filterFalsy());
    protected readonly isPaymentFeatureAvailable$ = this.store.select(OperatorContextState.isFeatureAvailable(GlobalFeatures.Payments));
    protected readonly stepsAmount$ = this.store
        .select(OperatorPermissionsState.areUavsRequired)
        .pipe(map((areUavsRequired) => (areUavsRequired ? STEPS_AMOUNT_WITH_UAVS : STEPS_AMOUNT_WITHOUT_UAVS)));

    protected readonly wizardId = STATEMENTS_WIZARD_ID;
    protected readonly ApplicationWizardSteps = StatementsWizardSteps;
    protected readonly OperatorPermissionsErrorType = OperatorPermissionsErrorType;
    protected readonly selectedPossiblePermissions$ = this.localStore.selectByKey("selectedPossiblePermissions");

    private readonly contextSwitchTry$ = this.initContextSwitchTry();

    constructor(
        private readonly store: Store,
        private readonly userContextService: UserContextService,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService,
        private readonly toastrService: ToastrService,
        private readonly localStore: LocalComponentStore<StatementsWizardContentComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        @Inject(DOCUMENT) private document: Document
    ) {
        localStore.setState({ selectedPossiblePermissions: undefined });

        if (!this.route.snapshot.queryParams.statementId) {
            this.goToAndEnableStep(StatementsWizardSteps.GainPermissions);
        }
        this.watchRouterNavigation();
    }

    public ngOnInit() {
        this.handleContextSwitch();
        this.handlePermissionsLoad();
    }

    public ngOnDestroy() {
        this.store.dispatch(new WizardActions.CleanupWizard(this.wizardId));
        this.userContextService.unregisterContextSwitchTry(this.contextSwitchTry$);
    }

    protected loadAllPermissions() {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId);
        this.store.dispatch(new OperatorPermissionsActions.GetOperatorPermissionsCapabilities(operatorId));
    }

    protected goToOperationInfoStep(selectedPossiblePermissions: SinglePermission[]): void {
        this.localStore.patchState({ selectedPossiblePermissions });

        if (selectedPossiblePermissions.some((selectedPossiblePermission) => selectedPossiblePermission.areUavsRequired)) {
            this.goToAndEnableStep(StatementsWizardSteps.UavInformation);

            return;
        }

        this.goToAndEnableStep(StatementsWizardSteps.Summary);
    }

    protected updateSelectedPossiblePermissionsAndGoToSummary(selectedUavsForPermissions: UavsSelectedForPermission) {
        const selectedPossiblePermissions = this.localStore.selectSnapshotByKey("selectedPossiblePermissions");

        if (!selectedPossiblePermissions) {
            return;
        }

        const newList = selectedPossiblePermissions.map((permission) => {
            const permissionCopy = { ...permission };
            if (permissionCopy.areUavsRequired) {
                permissionCopy.uavs = selectedUavsForPermissions[permission.scenarioId] ?? [];
            }

            return permissionCopy;
        });

        this.localStore.patchState({ selectedPossiblePermissions: newList });

        this.goToAndEnableStep(StatementsWizardSteps.Summary);
    }

    protected goBackFromSummary() {
        if (this.store.selectSnapshot(OperatorPermissionsState.areUavsRequired)) {
            this.goToAndEnableStep(StatementsWizardSteps.UavInformation);

            return;
        }

        this.goToAndEnableStep(StatementsWizardSteps.GainPermissions);
    }

    protected goToAndEnableStep(step: StatementsWizardSteps) {
        this.store.dispatch([new WizardActions.SetActiveStep(this.wizardId, step), new WizardActions.EnableSteps(this.wizardId, [step])]);
    }

    protected refreshStatement() {
        const savedStatementId = this.store.selectSnapshot(OperatorPermissionsState.savedStatement)?.id;
        const selectedOperatorContextId = this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId);

        if (!savedStatementId || !selectedOperatorContextId) {
            return;
        }

        this.store.dispatch(new OperatorPermissionsActions.GetSavedStatement(selectedOperatorContextId, savedStatementId));
    }

    protected repeatPayment() {
        const savedStatementId = this.store.selectSnapshot(OperatorPermissionsState.savedStatement)?.id;
        const selectedOperatorContextId = this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId);

        if (!savedStatementId || !selectedOperatorContextId) {
            return;
        }

        this.store
            .dispatch(new OperatorPermissionsActions.CreatePaymentRedirectUrl(selectedOperatorContextId, savedStatementId))
            .pipe(
                switchMap(() => this.store.select(OperatorPermissionsState.paymentRedirectUrl)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((paymentRedirectUrl) => {
                if (paymentRedirectUrl) {
                    this.document.location.href = paymentRedirectUrl;

                    return;
                }

                this.toastrService.error(this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.genericError"));
            });
    }

    protected signAndSendStatement() {
        const savedStatementId = this.store.selectSnapshot(OperatorPermissionsState.savedStatement)?.id;
        const selectedOperatorContextId = this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId);

        if (!savedStatementId || !selectedOperatorContextId) {
            return;
        }

        this.store
            .dispatch(new OperatorPermissionsActions.CreateSignatureRedirectUrl(selectedOperatorContextId, savedStatementId))
            .pipe(
                switchMap(() => this.store.select(OperatorPermissionsState.signatureRedirectUrl)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((signatureRedirectUrl) => {
                if (signatureRedirectUrl) {
                    this.document.location.href = signatureRedirectUrl;

                    return;
                }

                this.toastrService.error(this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.genericError"));
            });
    }

    protected async saveStatementAndPay(summaryFormValue: AdministrativeFeeInStatement) {
        const operator = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );

        const isPaymentFeatureAvailable = await firstValueFrom(this.isPaymentFeatureAvailable$.pipe(untilDestroyed(this)));
        if (!isPaymentFeatureAvailable) {
            this.saveStatement(summaryFormValue, operator);

            return;
        }

        this.store
            .dispatch(
                new OperatorPermissionsActions.SaveStatementAndPay(operator.id, {
                    operationScenarios: await this.getOperationScenariosToSign(),
                    attorneyPowerAdministrativeFee: summaryFormValue
                        ? {
                              isDeclarationOfExemption: !!summaryFormValue.isDeclarationOfExemption,
                              feeConfirmationId: summaryFormValue.feeConfirmation ? summaryFormValue.feeConfirmation[0].id : undefined,
                          }
                        : undefined,
                })
            )
            .pipe(
                switchMap(() => this.store.select(OperatorPermissionsState.paymentRedirectUrl)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((paymentRedirectUrl) => {
                if (paymentRedirectUrl) {
                    this.document.location.href = paymentRedirectUrl;

                    return;
                }

                const savedStatement = this.store.selectSnapshot(OperatorPermissionsState.savedStatement);

                if (savedStatement) {
                    this.router.navigate(["statements"], {
                        queryParams: { statementId: savedStatement.id },
                    });
                }

                const saveAndPayError = this.store.selectSnapshot(OperatorPermissionsState.saveStatementError);
                let translationKey = "dtmWebAppLibOperatorPermissions.statementsWizardSteps.genericError";

                switch (saveAndPayError?.type) {
                    case OperatorPermissionsErrorType.CannotSaveStatement:
                        translationKey = "dtmWebAppLibOperatorPermissions.statementsWizardSteps.couldNotSaveStatementError";
                        break;
                    case OperatorPermissionsErrorType.PaymentServiceUnavailable:
                        translationKey = "dtmWebAppLibOperatorPermissions.statementsWizardSteps.paymentServiceUnavailableError";
                        break;
                    default:
                        break;
                }

                this.toastrService.error(this.transloco.translate(translationKey));
            });
    }

    private async saveStatement(summaryFormValue: AdministrativeFeeInStatement, operator: ContextOperator) {
        this.store
            .dispatch(
                new OperatorPermissionsActions.SaveStatement(operator.id, {
                    operationScenarios: await this.getOperationScenariosToSign(),
                    attorneyPowerAdministrativeFee: summaryFormValue
                        ? {
                              isDeclarationOfExemption: !!summaryFormValue.isDeclarationOfExemption,
                              feeConfirmationId: summaryFormValue.feeConfirmation ? summaryFormValue.feeConfirmation[0].id : undefined,
                          }
                        : undefined,
                })
            )
            .pipe(
                switchMap(() => this.store.select(OperatorPermissionsState.savedStatement)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((savedStatement) => {
                const savedStatementError = this.store.selectSnapshot(OperatorPermissionsState.saveStatementError);
                if (savedStatementError || !savedStatement) {
                    this.toastrService.error(
                        this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.couldNotSaveStatementError")
                    );

                    return;
                }

                this.router.navigate(["statements"], {
                    queryParams: { statementId: savedStatement.id },
                });
            });
    }

    protected async getStatementInPdf() {
        const operator = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );
        const fileName = this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementFileName");

        this.store
            .dispatch(
                new OperatorPermissionsActions.GetStatementInPdf(
                    operator.id,
                    {
                        operationScenarios: await this.getOperationScenariosToSign(),
                    },
                    fileName
                )
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (!this.store.selectSnapshot(OperatorPermissionsState.getStatementInPdfError)) {
                    return;
                }

                this.toastrService.error(
                    this.transloco.translate("dtmWebAppLibOperatorPermissions.statementsWizardSteps.getStatementInPdfError")
                );
            });
    }

    protected areUavsRequiredStateChange(areUavsRequired: boolean) {
        this.store.dispatch([new OperatorPermissionsActions.SetUavsRequiredState(areUavsRequired)]);
    }

    private watchRouterNavigation() {
        this.route.queryParams
            .pipe(
                filter((queryParams) => queryParams.statementId),
                combineLatestWith(this.store.select(OperatorContextState.selectedOperatorContextId).pipe(RxjsUtils.filterFalsy())),
                untilDestroyed(this)
            )
            .subscribe(([queryParams, operatorId]) => {
                this.store.dispatch(new OperatorPermissionsActions.GetSavedStatement(operatorId, queryParams.statementId));
                this.store.dispatch([
                    new WizardActions.SetActiveStep(this.wizardId, StatementsWizardSteps.StatementSign),
                    new WizardActions.DisableSteps(this.wizardId, [
                        StatementsWizardSteps.GainPermissions,
                        StatementsWizardSteps.UavInformation,
                        StatementsWizardSteps.Summary,
                    ]),
                ]);
            });
    }

    private handlePermissionsLoad(): void {
        this.store
            .select(WizardState.activeStepId(this.wizardId))
            .pipe(
                RxjsUtils.filterFalsy(),
                filter((stepId) => stepId === StatementsWizardSteps.GainPermissions),
                switchMap(() => this.store.select(OperatorPermissionsState.possiblePermissions)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleContextSwitch(): void {
        this.userContextService.registerContextSwitchTry(this.contextSwitchTry$);

        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.loadAllPermissions());
    }

    private showContextSwitchConfirmationDialog(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate(
                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.confirmUserContextSwitchDialog.titleText"
                    ),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.confirmUserContextSwitchDialog.contentText",
                        {
                            gainPermissionsStepLabel: this.transloco.translate(
                                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.header"
                            ),
                        }
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.confirmUserContextSwitchDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.confirmUserContextSwitchDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private initContextSwitchTry(): Observable<boolean> {
        return defer(() => this.showContextSwitchConfirmationDialog());
    }

    private async getOperationScenariosToSign() {
        const selectedPossiblePermissions = await firstValueFrom(
            this.localStore.selectByKey("selectedPossiblePermissions").pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );

        return selectedPossiblePermissions.map((selectedPossiblePermission) => ({
            id: selectedPossiblePermission.scenarioId,
            uavIds: selectedPossiblePermission.uavs?.map((uav) => uav.id) ?? null,
        }));
    }
}
