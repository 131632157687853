import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AvailableLanguages, EmptyStateMode, ErrorMode } from "@dtm-frontend/shared/ui";
import { LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { DashboardError, PostWithLanguages } from "../../services/dashboard.models";

interface PostsComponentState {
    posts: PostWithLanguages[] | undefined;
    error: DashboardError | undefined;
    language: LanguageCode | undefined;
}

@Component({
    selector: "dtm-web-app-lib-posts[language]",
    templateUrl: "./posts.component.html",
    styleUrls: ["./posts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PostsComponent {
    @Input() public set posts(value: PostWithLanguages[] | undefined) {
        this.localStore.patchState({ posts: value });
    }

    @Input() public set error(value: DashboardError | undefined) {
        this.localStore.patchState({ error: value });
    }

    @Input() public set language(value: LanguageCode | undefined) {
        this.localStore.patchState({ language: value });
    }

    protected readonly posts$ = this.localStore.selectByKey("posts");
    protected readonly error$ = this.localStore.selectByKey("error");
    protected readonly language$ = this.localStore.selectByKey("language").pipe(RxjsUtils.filterFalsy());
    protected readonly AvailableLanguages = AvailableLanguages;
    protected readonly ErrorMode = ErrorMode;
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(private readonly localStore: LocalComponentStore<PostsComponentState>) {
        this.localStore.setState({ posts: [], error: undefined, language: undefined });
    }
}
