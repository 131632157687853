import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { filter, map } from "rxjs/operators";
import { Area, CustomAreaLocation, OperationAreaType, PredefinedArea } from "../../../services/specific-permit-application.models";
import { AreaEditorDialogComponent } from "./area-editor-dialog/area-editor-dialog.component";

interface OperationAreaControlComponentState {
    predefinedAreas: PredefinedArea[];
    area: Area | undefined;
    isProcessing: boolean;
    customAreaInitialViewbox: GeoJSON | undefined;
    isCustomAreaDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-operation-area-control",
    templateUrl: "./operation-area-control.component.html",
    styleUrls: ["./operation-area-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OperationAreaControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperationAreaControlComponent),
            multi: true,
        },
    ],
})
export class OperationAreaControlComponent implements ControlValueAccessor, Validator {
    @Input() public set predefinedAreas(value: PredefinedArea[] | undefined) {
        this.localStore.patchState({ predefinedAreas: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isCustomAreaDisabled(value: BooleanInput) {
        this.localStore.patchState({ isCustomAreaDisabled: coerceBooleanProperty(value) });
    }

    @Input() public set area(value: Area | undefined) {
        this.localStore.patchState({ area: value });

        if (!this.validate()) {
            this.propagateChange(value as Area);
        }

        this.onValidationChange();
    }

    @Input() public set customAreaInitialViewbox(value: GeoJSON | undefined) {
        this.localStore.patchState({ customAreaInitialViewbox: value });
    }

    @Output() public readonly areaPreviewShow = new EventEmitter<Area>();

    protected readonly OperationAreaType = OperationAreaType;
    protected readonly predefinedAreas$ = this.localStore.selectByKey("predefinedAreas");
    protected readonly operationArea$ = this.localStore
        .selectByKey("area")
        .pipe(map((area) => (area?.type === OperationAreaType.Operation ? area : null)));
    protected readonly customArea$ = this.localStore
        .selectByKey("area")
        .pipe(map((area) => (area?.type === OperationAreaType.CustomArea ? area : null)));
    protected readonly predefinedArea$ = this.localStore
        .selectByKey("area")
        .pipe(map((area) => (area?.type === OperationAreaType.PredefinedArea ? area : null)));
    protected readonly isCustomAreaDefined$ = this.localStore
        .selectByKey("area")
        .pipe(map((area) => area?.type === OperationAreaType.CustomArea && !!area.area));
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isCustomAreaDisabled$ = this.localStore.selectByKey("isCustomAreaDisabled");

    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: Area) => void = () => {};

    constructor(
        private readonly localStore: LocalComponentStore<OperationAreaControlComponentState>,
        private readonly matDialog: MatDialog
    ) {
        this.localStore.setState({
            area: undefined,
            isProcessing: false,
            predefinedAreas: [],
            customAreaInitialViewbox: undefined,
            isCustomAreaDisabled: false,
        });
    }

    public registerOnChange(fn: (value: Area) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {}

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        const area = this.localStore.selectSnapshotByKey("area");

        const isCustomAreaInvalid = area?.type === OperationAreaType.CustomArea && !area.area;
        const isPredefinedAreaInvalid = area?.type === OperationAreaType.PredefinedArea && !area.predefinedAreaId;
        if (isCustomAreaInvalid || isPredefinedAreaInvalid) {
            return { areaNotSet: true };
        }

        return null;
    }

    public writeValue(value: Area): void {
        this.area = value;
    }

    protected selectType(type: OperationAreaType): void {
        const area = this.localStore.selectSnapshotByKey("area");

        if (area?.type === type) {
            return;
        }

        if (type === OperationAreaType.PredefinedArea) {
            this.area = {
                type,
                predefinedAreaId: null,
            };
        } else if (type === OperationAreaType.CustomArea) {
            this.area = {
                type,
                area: null,
            };
        } else {
            this.area = {
                type,
            };
        }
    }

    protected getPredefinedAreasNames(predefinedAreas: PredefinedArea[]): string {
        return predefinedAreas.map((predefinedArea) => predefinedArea.name).join(", ");
    }

    protected selectPredefinedArea(areaId: string): void {
        const area = this.localStore.selectSnapshotByKey("area");

        if (area?.type !== OperationAreaType.PredefinedArea) {
            return;
        }

        this.area = {
            type: area.type,
            predefinedAreaId: areaId,
        };
    }

    protected previewArea(): void {
        const area = this.localStore.selectSnapshotByKey("area");

        if (area) {
            this.areaPreviewShow.emit(area);
        }
    }

    protected defineCustomArea(area: CustomAreaLocation): void {
        const dialogRef = this.matDialog.open(AreaEditorDialogComponent, {
            data: {
                area: area.area,
                initialViewbox: this.localStore.selectSnapshotByKey("customAreaInitialViewbox"),
            },
            maxWidth: "95vw",
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((result) => result !== false),
                untilDestroyed(this)
            )
            .subscribe((newArea: GeoJSON | null) => {
                this.setCustomArea(newArea);
            });
    }

    private setCustomArea(customArea: GeoJSON | null): void {
        const area = this.localStore.selectSnapshotByKey("area");

        if (area?.type !== OperationAreaType.CustomArea) {
            return;
        }

        this.area = {
            type: area.type,
            area: customArea,
        };
    }
}
