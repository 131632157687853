<div class="mission-tile" *ngrxLet="{ missionPlan: missionPlan$ } as vm">
    <dtm-mission-badge-group
        class="badge-group"
        [missionType]="vm.missionPlan.flightType"
        [phase]="vm.missionPlan.phase"
        [isAuthorityAcceptationConfirmed]="vm.missionPlan | invoke : isAuthorityAcceptationConfirmed"
    ></dtm-mission-badge-group>

    <a [routerLink]="['.', vm.missionPlan.id]">
        <div class="header row">
            <div class="title">
                {{ vm.missionPlan.name || ("dtmWebAppLibShared.missionTile.noNameTitle" | transloco) }}
            </div>
            <button
                [matMenuTriggerFor]="missionMenu"
                class="button-icon menu-button"
                *ngIf="
                    vm.missionPlan.availableActions.editable ||
                    vm.missionPlan.availableActions.deletable ||
                    vm.missionPlan.availableActions.cloneable
                "
                type="button"
                (click)="$event.preventDefault(); $event.stopImmediatePropagation()"
            >
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
            <mat-menu #missionMenu="matMenu" xPosition="before">
                <button
                    mat-menu-item
                    [routerLink]="['.', vm.missionPlan.id]"
                    [queryParams]="{ source: currentUrl$ | ngrxPush }"
                    type="button"
                >
                    <dtm-ui-icon name="information"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.details" | transloco }}</span>
                </button>
                <button
                    mat-menu-item
                    *ngIf="vm.missionPlan.availableActions.editable"
                    [routerLink]="['/plan/edit', vm.missionPlan.id]"
                    type="button"
                >
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.edit" | transloco }}</span>
                </button>
                <button mat-menu-item *ngIf="vm.missionPlan.availableActions.cloneable" (click)="clone()" type="button">
                    <dtm-ui-icon name="file-copy"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.clone" | transloco }}</span>
                </button>
                <button mat-menu-item (click)="downloadKml()" type="button">
                    <dtm-ui-icon name="download"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.downloadKml" | transloco }}</span>
                </button>
                <button
                    mat-menu-item
                    *ngIf="vm.missionPlan.availableActions.deletable"
                    (click)="delete(vm.missionPlan.status)"
                    type="button"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.delete" | transloco }}</span>
                </button>
                <button
                    mat-menu-item
                    *ngIf="vm.missionPlan.availableActions.cancelable && vm.missionPlan.missionStatus !== MissionStatus.Activated"
                    (click)="cancel()"
                    type="button"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.cancel" | transloco }}</span>
                </button>
            </mat-menu>
        </div>
        <div class="row">
            <dtm-mission-mission-dates
                [startDate]="vm.missionPlan.flightStartAtMin"
                [finishDate]="vm.missionPlan.flightFinishAtMax"
                [isWholeDayMode]="
                    vm.missionPlan.phase === MissionProcessingPhase.CaaPermitApplication ||
                    (vm.missionPlan.operationCategoryType === MissionCategory.Specific && !!vm.missionPlan.soraSail)
                "
            ></dtm-mission-mission-dates>
        </div>
        <ng-container *ngIf="isExtendedMode$ | ngrxPush; let isExtendedMode" [class.extended]="isExtendedMode">
            <div class="row">
                <div class="column">
                    <dtm-ui-icon name="building"></dtm-ui-icon>
                    <p>{{ vm.missionPlan.operatorName ?? ("dtmWebAppLibMission.missionTile.noDataLabel" | transloco) }}</p>
                </div>
                <div class="column">
                    <dtm-ui-icon name="user"></dtm-ui-icon>
                    <p>{{ vm.missionPlan.pilotName ?? ("dtmWebAppLibMission.missionTile.noDataLabel" | transloco) }}</p>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <dtm-ui-icon name="drone"></dtm-ui-icon>
                    <p>{{ vm.missionPlan.uavName ?? ("dtmWebAppLibMission.missionTile.noDataLabel" | transloco) }}</p>
                    <p class="additional-info">{{ vm.missionPlan.uavSetupName }}</p>
                </div>
            </div>
        </ng-container>
        <div class="thumbnail"><img [dtmUiImgSecuredSrc]="vm.missionPlan.thumbnail" /></div>
    </a>
</div>
<ng-template #noDataTemplate>
    <p>{{ "dtmWebAppLibMission.missionTile.noDataLabel" | transloco }}</p>
</ng-template>
