<ng-container *ngrxLet="{ canManageUavs: canManageUavs$, canReadUavs: canReadUavs$ } as vm">
    <ng-container *ngIf="vm.canReadUavs; else notAuthorizedTemplate">
        <div class="uav-nav">
            <button type="button" class="button-secondary" routerLink="../list">
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dtmWebAppLibUav.uavCard.goBackToListButtonLabel" | transloco }}
            </button>
        </div>
        <ng-container *ngIf="error$ | ngrxPush as error; else uavCardTemplate">
            <ng-container [ngSwitch]="error.type">
                <dtm-ui-error
                    *ngSwitchCase="UavErrorType.NotFound"
                    [errorMessage]="'dtmWebAppLibUav.uavCard.uavNotFoundErrorMessage' | transloco"
                ></dtm-ui-error>
                <dtm-ui-error
                    *ngSwitchCase="UavErrorType.CannotGetTrackers"
                    [errorMessage]="'dtmWebAppLibUav.uavCard.trackersNotFoundErrorMessage' | transloco"
                ></dtm-ui-error>
                <dtm-ui-error *ngSwitchDefault [errorMessage]="'dtmWebAppLibUav.uavCard.uavUnknownErrorMessage' | transloco"></dtm-ui-error>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #uavCardTemplate>
        <dtm-ui-loader-container *ngrxLet="uav$ as uav" [isShown]="isProcessing$ | ngrxPush">
            <div class="grid">
                <dtm-web-app-lib-uav-header
                    [canManageUav]="vm.canManageUavs"
                    [canShareUav]="canShareUavs$ | ngrxPush"
                    [uav]="uav"
                    (remove)="removeUav(uav)"
                    (share)="shareUav(uav)"
                    (edit)="editUav(uav)"
                    (manageDocuments)="manageUavDocuments(uav)"
                    class="uav-header"
                ></dtm-web-app-lib-uav-header>
                <dtm-web-app-lib-uav-setups
                    [canManageUav]="vm.canManageUavs"
                    [setups]="uav.setups"
                    [trackers]="trackers$ | ngrxPush"
                    [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
                    (add)="addSetup(uav)"
                    (remove)="removeSetup($event, uav)"
                    (edit)="editSetup($event, uav)"
                    class="uav-setups"
                ></dtm-web-app-lib-uav-setups>
            </div>
        </dtm-ui-loader-container>
    </ng-template>
</ng-container>

<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
