<ng-container *ngrxLet="isCollapsed$; let isCollapsed">
    <ng-template #menuItemTemplate let-label="label" let-icon="icon" let-iconClass="iconClass" let-link="link">
        <a
            [routerLink]="link"
            routerLinkActive="active-route"
            [matTooltip]="label"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
            (click)="collapseMenu()"
        >
            <dtm-ui-icon [name]="icon" [ngClass]="iconClass"></dtm-ui-icon>
            <span class="label">{{ label }}</span>
        </a>
    </ng-template>

    <ng-container *ngIf="selectedContext$ | ngrxPush as selectedContext; else newUserTemplate">
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.dashboardMenuItemLabel' | transloco,
                        icon: 'dashboard',
                        link: '/dashboard'
                    }"
                ></ng-template>
            </li>
            <li *ngIf="selectedContext | invoke : isPermitted : GlobalOperatorPermissions.OperatorManage">
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.operatorConversationsMenuItemLabel' | transloco,
                        icon: 'mail',
                        link: '/operator-conversations'
                    }"
                ></ng-template>
            </li>
            <li
                *ngIf="
                    selectedContext
                        | invoke
                            : isPermitted
                            : [GlobalOperatorPermissions.OperatorMissionsPlan, GlobalOperatorPermissions.OperationalStatementMakeStatement]
                "
            >
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.operatorPermissionsMenuItemLabel' | transloco,
                        icon: 'community',
                        link: '/operator-permissions'
                    }"
                ></ng-template>
            </li>
            <li
                *ngIf="
                    selectedContext
                        | invoke : isPermitted : [GlobalOperatorPermissions.OperatorMissionsPlan, GlobalOperatorPermissions.OperatorManage]
                "
            >
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.operatorAuthorizationsMenuItemLabel' | transloco,
                        icon: 'file-list',
                        link: '/operator-permits'
                    }"
                ></ng-template>
            </li>
            <li *ngIf="isOperationsManualAvailable$ | ngrxPush">
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.operationsManualMenuItemLabel' | transloco,
                        icon: 'community',
                        link: '/operations-manual'
                    }"
                ></ng-template>
            </li>
        </ul>

        <h2
            class="menu-section-header"
            [matTooltip]="'webApp.menu.profileHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "webApp.menu.profileHeader" | transloco }}
        </h2>
        <ul class="menu-list" *ngrxLet="selectedContextType$ as selectedContextType">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.userProfileMenuItemLabel' | transloco,
                        icon: 'user',
                        link: '/user'
                    }"
                ></ng-template>
            </li>
            <li *ngIf="selectedContextType === OperatorType.Enterprise">
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.operatorProfileMenuItemLabel' | transloco,
                        icon: 'community',
                        link: '/operator-profile'
                    }"
                ></ng-template>
            </li>
            <li *ngIf="selectedContextType === OperatorType.Personal">
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.pilotProfileMenuItemLabel' | transloco,
                        icon: 'gamepad',
                        link: '/pilot-profile'
                    }"
                ></ng-template>
            </li>
        </ul>
        <ng-container *ngTemplateOutlet="membershipMenuTemplate; context: { $implicit: selectedContext }"></ng-container>
        <ng-container *ngIf="(selectedContextType$ | ngrxPush) === OperatorType.Personal">
            <h2
                class="menu-section-header"
                [matTooltip]="'webApp.menu.trainingsHeader' | transloco"
                matTooltipPosition="right"
                [matTooltipDisabled]="!isCollapsed"
            >
                {{ "webApp.menu.trainingsHeader" | transloco }}
                <!-- TODO: DTM-5159 - uncomment when backends and integration is added -->
                <!--     <div class="new-indicator" [class.collapsed]="isCollapsed">
                    {{ "webApp.menu.eLearningNewIndicatorLabel" | transloco : { isCollapsed } }}
                </div>-->
            </h2>
            <ul class="menu-list">
                <li>
                    <a
                        [matTooltip]="'webApp.menu.eLearningLabel' | transloco"
                        matTooltipPosition="right"
                        [matTooltipDisabled]="!isCollapsed"
                        [href]="eLearningPortalUrl$ | ngrxPush"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="training-link"
                    >
                        <p class="training-icons" *ngIf="isCollapsed">
                            <dtm-ui-icon name="booklet"></dtm-ui-icon>
                            <dtm-ui-icon name="external-link" class="external-link-icon"></dtm-ui-icon>
                        </p>
                        <dtm-ui-icon name="booklet" *ngIf="!isCollapsed"></dtm-ui-icon>
                        <span class="label">
                            {{ "webApp.menu.eLearningLabel" | transloco }}&nbsp;<dtm-ui-icon
                                name="external-link"
                                class="external-link-icon"
                            ></dtm-ui-icon>
                        </span>
                    </a>
                </li>
            </ul>
            <h2
                class="menu-section-header"
                [matTooltip]="'webApp.menu.examsHeader' | transloco"
                matTooltipPosition="right"
                [matTooltipDisabled]="!isCollapsed"
            >
                {{ "webApp.menu.examsHeader" | transloco }}
            </h2>
            <ul class="menu-list">
                <li>
                    <ng-template
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            label: 'webApp.menu.resultsLabel' | transloco,
                            icon: 'student',
                            link: '/training-exam-results'
                        }"
                    ></ng-template>
                </li>
            </ul>
        </ng-container>

        <ng-container *ngIf="selectedContext | invoke : isPermitted : [GlobalOperatorPermissions.OperatorUavsRead]">
            <h2
                class="menu-section-header"
                [matTooltip]="'webApp.menu.uavHeader' | transloco"
                matTooltipPosition="right"
                [matTooltipDisabled]="!isCollapsed"
            >
                {{ isCollapsed ? ("webApp.menu.uavHeaderShort" | transloco) : ("webApp.menu.uavHeader" | transloco) }}
            </h2>
            <ul class="menu-list">
                <li>
                    <ng-template
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            label: 'webApp.menu.uavListMenuItemLabel' | transloco,
                            icon: 'drone',
                            link: '/uav/list'
                        }"
                    ></ng-template>
                </li>
            </ul>
        </ng-container>
        <ng-container *ngrxLet="isMissionPlanningFeatureAvailable$ as isMissionPlanningFeatureAvailable">
            <h2
                class="menu-section-header"
                [matTooltip]="'webApp.menu.missionPlanningHeader' | transloco"
                matTooltipPosition="right"
                [matTooltipDisabled]="!isCollapsed"
            >
                {{ "webApp.menu.missionPlanningHeader" | transloco }}
                <dtm-ui-popover *ngIf="!isMissionPlanningFeatureAvailable">
                    <p class="mission-popover-text">{{ "webApp.menu.missionPlanningTooltip.title" | transloco }}</p>
                    <a [href]="missionPlanningCourseUrl" target="_blank" class="button-link-small">{{
                        "webApp.menu.missionPlanningTooltip.linkLabel" | transloco
                    }}</a>
                </dtm-ui-popover>
            </h2>
            <ul class="menu-list" [class.disabled]="!isMissionPlanningFeatureAvailable">
                <li
                    data-testid="create-mission-menu-item"
                    *ngIf="selectedContext | invoke : isPermitted : GlobalOperatorPermissions.OperatorMissionsPlan"
                >
                    <ng-template
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            label: 'webApp.menu.createMissionMenuItemLabel' | transloco,
                            icon: 'focus-2',
                            link: '/mission/new'
                        }"
                    ></ng-template>
                </li>
                <li *ngIf="selectedContext | invoke : isPermitted : GlobalOperatorPermissions.OperatorMissionsRead">
                    <!-- TODO: DTM-1706 label change -->
                    <ng-template
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            label: 'webApp.menu.missionListMenuItemLabel' | transloco,
                            icon: 'file-list',
                            link: '/plan/list'
                        }"
                    ></ng-template>
                </li>
            </ul>
            <div class="separator"></div>
            <ng-container *ngIf="selectedContext | invoke : isPermitted : GlobalOperatorPermissions.OperatorMissionsPilot">
                <h2
                    class="menu-section-header"
                    [matTooltip]="'webApp.menu.dtmMissionsMenuHeader' | transloco"
                    matTooltipPosition="right"
                    [matTooltipDisabled]="!isCollapsed"
                >
                    <span class="mission-menu-header" [innerHTML]="'webApp.menu.dtmMissionsMenuHeader' | transloco : { isCollapsed }"></span
                    >&nbsp;<dtm-ui-popover>
                        <div class="dtm-list">
                            <h5 class="title">{{ "webApp.menu.dtmMissionTooltip.title" | transloco }}</h5>
                            <ul [innerHTML]="'webApp.menu.dtmMissionTooltip.dtmList' | transloco"></ul>
                        </div>
                    </dtm-ui-popover>
                </h2>
                <ul class="menu-list">
                    <li>
                        <ng-template
                            [ngTemplateOutlet]="menuItemTemplate"
                            [ngTemplateOutletContext]="{
                                label: 'webApp.menu.prepareForMissionItemLabel' | transloco,
                                icon: 'loader',
                                link: '/tactical/prepare-for-mission'
                            }"
                        ></ng-template>
                    </li>
                </ul>

                <ng-container
                    *ngrxLet="{
                        activeMissions: activeMissions$,
                        activeMissionsError: activeMissionsError$,
                        isMenuMissionDataProcessing: isMenuMissionDataProcessing$
                    } as vm"
                >
                    <h2
                        class="menu-section-header"
                        [innerHTML]="'webApp.menu.activeDtmMissionMenuHeader' | transloco : { count: vm.activeMissions.length }"
                    ></h2>
                    <ul class="menu-list">
                        <ng-container *ngIf="vm.activeMissionsError; else activeMissionsTemplate">
                            <li class="error">
                                <dtm-ui-icon name="error-warning-fill" class="error-icon"></dtm-ui-icon>
                                <span class="label">{{ "webApp.menu.activeMissionErrorMenuLabel" | transloco }}</span>
                                <button
                                    type="button"
                                    class="button-icon"
                                    (click)="refreshActiveMissions($event, vm.isMenuMissionDataProcessing)"
                                >
                                    <dtm-ui-icon name="refresh" [class.loading-icon]="vm.isMenuMissionDataProcessing"></dtm-ui-icon>
                                </button>
                            </li>
                        </ng-container>
                        <ng-template #activeMissionsTemplate>
                            <ng-container *ngIf="!isCollapsed">
                                <li *ngFor="let mission of vm.activeMissions">
                                    <ng-template
                                        [ngTemplateOutlet]="menuItemTemplate"
                                        [ngTemplateOutletContext]="{
                                            label:
                                                'webApp.menu.missionMenuItem'
                                                | transloco
                                                    : {
                                                          name: mission.name,
                                                          time:
                                                              mission.realizationTime ?? mission.startTime.min
                                                              | localizeDate : { timeStyle: 'short' }
                                                      },
                                            icon: 'play-circle',
                                            iconClass: mission.status === MissionStatus.Started ? 'mission-in-progress' : 'mission-active',
                                            link: '/tactical/mission/' + mission.missionId
                                        }"
                                    ></ng-template>
                                </li>
                            </ng-container>
                        </ng-template>
                    </ul>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #newUserTemplate>
        <ng-container *ngIf="!(isRequiredRegisterNationalNodeAuthenticatedUser$ | ngrxPush)">
            <ul class="menu-list">
                <li>
                    <ng-template
                        [ngTemplateOutlet]="menuItemTemplate"
                        [ngTemplateOutletContext]="{
                            label: 'webApp.menu.dashboardMenuItemLabel' | transloco,
                            icon: 'dashboard',
                            link: '/dashboard'
                        }"
                    ></ng-template>
                </li>
            </ul>
        </ng-container>
        <h2
            class="menu-section-header"
            [matTooltip]="'webApp.menu.profileHeader' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
        >
            {{ "webApp.menu.profileHeader" | transloco }}
        </h2>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'webApp.menu.userProfileMenuItemLabel' | transloco,
                        icon: 'user',
                        link: '/user'
                    }"
                ></ng-template>
            </li>
        </ul>
        <ng-container *ngTemplateOutlet="membershipMenuTemplate"></ng-container>
    </ng-template>
    <ng-template #membershipMenuTemplate let-selectedContext>
        <ng-container *ngrxLet="operators$; let operators">
            <ng-container
                *ngIf="
                    (isMembersFeatureAvailable$ | ngrxPush) &&
                    (selectedContext
                        | invoke : isPermitted : [GlobalOperatorPermissions.OperatorMembersRead, GlobalOperatorPermissions.OperatorManage])
                "
            >
                <h2
                    class="menu-section-header"
                    [matTooltip]="'webApp.menu.membershipMenuHeader' | transloco"
                    matTooltipPosition="right"
                    [matTooltipDisabled]="!isCollapsed"
                >
                    {{ "webApp.menu.membershipMenuHeader" | transloco }}
                </h2>
                <ul class="menu-list" *ngrxLet="selectedContextType$ as selectedContextType">
                    <li *ngIf="!selectedContextType || selectedContextType === OperatorType.Personal; else usersListTemplate">
                        <ng-template
                            [ngTemplateOutlet]="menuItemTemplate"
                            [ngTemplateOutletContext]="{
                                label: 'webApp.menu.membershipOperatorsListItemLabel' | transloco,
                                icon: 'building',
                                link: '/membership/operators-list'
                            }"
                        ></ng-template>
                    </li>
                    <ng-template #usersListTemplate>
                        <li *ngIf="selectedContext | invoke : isPermitted : GlobalOperatorPermissions.OperatorMembersRead">
                            <ng-template
                                [ngTemplateOutlet]="menuItemTemplate"
                                [ngTemplateOutletContext]="{
                                    label: 'webApp.menu.membershipUsersListItemLabel' | transloco,
                                    icon: 'gamepad',
                                    link: '/membership/users-list'
                                }"
                            ></ng-template>
                        </li>
                    </ng-template>
                </ul>
            </ng-container>
        </ng-container>
    </ng-template>
</ng-container>
