import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { PANSA_UTM_URL } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-pilot-profile-connect-pansa-utm-link-dialog",
    templateUrl: "./connect-pansa-utm-link-dialog.component.html",
    styleUrls: ["./connect-pansa-utm-link-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectPansaUtmLinkDialogComponent {
    constructor(
        dialogRef: MatDialogRef<ConnectPansaUtmLinkDialogComponent>,
        @Inject(PANSA_UTM_URL) protected readonly pansaUtmUrl: string
    ) {
        dialogRef.disableClose = true;
    }
}
