import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { LocalComponentStore, PANSA_UTM_URL } from "@dtm-frontend/shared/utils";
import { PansaUtmLink, PansaUtmLinkStatus } from "../../../services/pilot-profile.models";

interface PansaUtmLinkComponentState {
    account: PansaUtmLink | undefined;
}

@Component({
    selector: "dtm-web-app-lib-pilot-profile-pansa-utm-link",
    templateUrl: "./pansa-utm-link.component.html",
    styleUrls: ["./pansa-utm-link.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PansaUtmLinkComponent {
    @Input() public set account(value: PansaUtmLink | undefined) {
        this.localStore.patchState({ account: value });
    }

    @Output() public readonly add = new EventEmitter<void>();
    @Output() public readonly edit = new EventEmitter<void>();
    @Output() public readonly delete = new EventEmitter<void>();

    protected readonly account$ = this.localStore.selectByKey("account");
    protected readonly PansaUtmLinkStatus = PansaUtmLinkStatus;

    constructor(
        private readonly localStore: LocalComponentStore<PansaUtmLinkComponentState>,
        @Inject(PANSA_UTM_URL) protected readonly pansaUtmUrl: string
    ) {
        this.localStore.setState({ account: undefined });
    }
}
