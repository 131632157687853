<div class="grid grid-maxsize-8">
    <ng-container *ngIf="getInvitationDetailsError$ | ngrxPush as invitationError; else invitationTemplate">
        <ng-container [ngSwitch]="invitationError.type">
            <dtm-ui-empty-state *ngSwitchCase="MembershipInvitationErrorType.NotFound" [mode]="EmptyStateMode.ListView">
                <ng-container titleSlot>
                    {{ "dtmWebAppMembership.invitationAcceptance.invitationNotFoundErrorHeader" | transloco }}
                </ng-container>
                <p messageSlot>
                    {{ "dtmWebAppMembership.invitationAcceptance.invitationNotFoundErrorMessage" | transloco }}
                </p>
                <img imageSlot src="assets/images/invitation-error.svg" alt="" />
                <button footerSlot type="button" class="button-primary" (click)="redirectToOperatorsList()">
                    {{ "dtmWebAppMembership.invitationAcceptance.redirectToOperatorsListLabel" | transloco }}
                </button>
            </dtm-ui-empty-state>
            <dtm-ui-error
                *ngSwitchDefault
                [mode]="ErrorMode.Secondary"
                imgSrc="assets/images/invitation-error.svg"
                [errorMessage]="'dtmWebAppMembership.invitationAcceptance.generalErrorMessage' | transloco"
            >
            </dtm-ui-error>
        </ng-container>
    </ng-container>
    <ng-template #invitationTemplate>
        <mat-card>
            <img src="assets/images/invitation.svg" alt="" />
            <div class="content">
                <h2>{{ "dtmWebAppMembership.invitationAcceptance.header" | transloco }}</h2>
                <div *ngrxLet="invitation$; let invitation">
                    <p
                        [innerHtml]="
                            'dtmWebAppMembership.invitationAcceptance.info' | transloco : { operatorName: invitation.sender.operatorName }
                        "
                    ></p>

                    <dtm-ui-info-message>
                        {{ "dtmWebAppMembership.invitationAcceptance.acceptanceResultInfoMessage" | transloco }}
                    </dtm-ui-info-message>

                    <div *ngIf="invitation.note">
                        <p class="note-header">
                            {{ "dtmWebAppMembership.invitationAcceptance.noteHeader" | transloco }}
                        </p>
                        <p class="note-text">{{ invitation.note }}</p>
                    </div>
                </div>
                <div class="actions">
                    <button type="button" class="button-secondary" (click)="rejectInvitation()">
                        <dtm-ui-icon name="close-circle" class="reject-icon"></dtm-ui-icon>
                        {{ "dtmWebAppMembership.invitationAcceptance.rejectLabel" | transloco }}
                    </button>
                    <button type="button" class="button-primary" (click)="acceptInvitation()">
                        {{ "dtmWebAppMembership.invitationAcceptance.acceptLabel" | transloco }}
                    </button>
                </div>
            </div>
        </mat-card>
    </ng-template>
</div>
