import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { BasicAddress } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    BasicCompanyInfo,
    CompanyInfo,
    ImportedPersonalOperatorDetails,
    ImportedPilotDetails,
    PilotFormValue,
} from "../../../models/operator-migration.models";
import { getImportedPostCodeFormValue } from "../../../utils/get-imported-post-code-value";

interface OperatorMigrationPilotInfoComponentState {
    personalOperatorDetails: ImportedPersonalOperatorDetails | undefined;
    pilotDetails: ImportedPilotDetails | undefined;
}

interface PilotInfoForm {
    isCompany: FormControl<boolean | null>;
    basicCompanyData: FormControl<BasicCompanyInfo | null>;
    address: FormControl<BasicAddress | null>;
    country: FormControl<string>;
    companyAddress: FormControl<BasicAddress | null>;
    companyCountry: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-migration-pilot-info[personalOperatorDetails][pilotDetails]",
    templateUrl: "./operator-migration-pilot-info.component.html",
    styleUrls: ["./operator-migration-pilot-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperatorMigrationPilotInfoComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperatorMigrationPilotInfoComponent),
            multi: true,
        },
    ],
})
export class OperatorMigrationPilotInfoComponent implements Validators, ControlValueAccessor {
    @Input() public set personalOperatorDetails(value: ImportedPersonalOperatorDetails | undefined) {
        this.localStore.patchState({ personalOperatorDetails: value });
        if (!value) {
            this.pilotInfoForm.reset();

            return;
        }

        this.assignFormValue(value);
        this.propagateFormValueChange();
    }
    @Input() public set pilotDetails(value: ImportedPilotDetails | undefined) {
        this.localStore.patchState({ pilotDetails: value });
    }

    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly personalOperatorDetails$ = this.localStore.selectByKey("personalOperatorDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly pilotDetails$ = this.localStore.selectByKey("pilotDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly pilotInfoForm = new FormGroup<PilotInfoForm>({
        isCompany: new FormControl<boolean | null>(null, Validators.required),
        basicCompanyData: new FormControl<CompanyInfo | null>({ value: null, disabled: true }, Validators.required),
        address: new FormControl<BasicAddress | null>(null, Validators.required),
        country: new FormControl<string>(DEFAULT_COUNTRY_CODE, { validators: Validators.required, nonNullable: true }),
        companyAddress: new FormControl<BasicAddress | null>({ value: null, disabled: true }, { validators: Validators.required }),
        companyCountry: new FormControl<string>(
            { value: DEFAULT_COUNTRY_CODE, disabled: true },
            { validators: Validators.required, nonNullable: true }
        ),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: PilotFormValue | null) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<OperatorMigrationPilotInfoComponentState>) {
        localStore.setState({ personalOperatorDetails: undefined, pilotDetails: undefined });

        this.pilotInfoForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.propagateFormValueChange();
        });
    }

    public registerOnChange(fn: (value: PilotFormValue | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: PilotFormValue | null): void {
        if (value) {
            this.pilotInfoForm.patchValue(value);
        }
    }

    public validate(): ValidationErrors | null {
        if (this.pilotInfoForm.valid) {
            return null;
        }

        return { personalOperatorInfoFormInvalid: true };
    }

    protected isCompanyValueChange(isCompany: boolean) {
        if (isCompany) {
            this.pilotInfoForm.controls.basicCompanyData.enable();
            this.pilotInfoForm.controls.companyAddress.enable();

            return;
        }

        this.pilotInfoForm.controls.basicCompanyData.disable();
        this.pilotInfoForm.controls.basicCompanyData.reset();
        this.pilotInfoForm.controls.companyAddress.disable();
        this.pilotInfoForm.controls.companyAddress.reset();
    }

    private assignFormValue({ personalData }: ImportedPersonalOperatorDetails) {
        this.pilotInfoForm.patchValue({
            address: {
                streetName: personalData.address.streetName,
                houseNumber: personalData.address.houseNumber,
                apartmentNumber: personalData.address.apartmentNumber,
                city: personalData.address.city,
                postCode: getImportedPostCodeFormValue(personalData.address.postCode),
            },
            country: DEFAULT_COUNTRY_CODE,
        });
    }

    private propagateFormValueChange() {
        const { basicCompanyData, address, country, companyAddress, companyCountry } = this.pilotInfoForm.getRawValue();
        this.propagateChange({
            address: { ...(address as BasicAddress), country: country ?? undefined },
            companyData: basicCompanyData
                ? { ...basicCompanyData, address: { ...(companyAddress as BasicAddress), country: companyCountry } }
                : null,
        });
        this.onValidationChange();
    }
}
