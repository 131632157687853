import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ExamResultsError } from "../../models/exam-results.models";
import {
    TrainingExamResultsListData,
    TrainingExamResultsMode,
    TrainingExamResultsTabType,
} from "../../models/training-exam-results.models";
import { TrainingResultsError } from "../../models/training-results.models";

interface TrainingExamResultsListComponentState {
    resultsList: TrainingExamResultsListData[];
    resultsListError: ExamResultsError | TrainingResultsError | undefined;
    isResultsListProcessing: boolean;
    pagination: Page | undefined;
    activeTabIndex: TrainingExamResultsTabType | undefined;
}

@Component({
    selector: "dtm-web-app-lib-training-exam-results-list[resultsList][pagination]",
    templateUrl: "./training-exam-results-list.component.html",
    styleUrls: ["./training-exam-results-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class TrainingExamResultsListComponent {
    @Input() public set resultsList(value: TrainingExamResultsListData[] | undefined) {
        this.localStore.patchState({ resultsList: value ?? [] });
    }
    @Input() public set isResultsListProcessing(value: BooleanInput) {
        this.localStore.patchState({ isResultsListProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set resultsListError(value: ExamResultsError | TrainingResultsError | undefined) {
        this.localStore.patchState({ resultsListError: value });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }
    @Input() public set activeTabIndex(value: TrainingExamResultsTabType | undefined) {
        this.localStore.patchState({ activeTabIndex: value || TrainingExamResultsTabType.Trainings });
    }

    @Output() public readonly resultsListRefresh = new EventEmitter<void>();
    @Output() public readonly pageChange = new EventEmitter<PageEvent>();

    protected readonly resultsList$ = this.localStore.selectByKey("resultsList");
    protected readonly isResultsListProcessing$ = this.localStore.selectByKey("isResultsListProcessing");
    protected readonly resultsListError$ = this.localStore.selectByKey("resultsListError");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly activeTabIndex$ = this.localStore.selectByKey("activeTabIndex");

    protected readonly TrainingExamResultsTabType = TrainingExamResultsTabType;
    protected readonly TrainingExamResultsMode = TrainingExamResultsMode;
    protected readonly displayedColumns = ["name", "date", "mode", "operatorName"];
    protected readonly ErrorMode = ErrorMode;

    constructor(private readonly localStore: LocalComponentStore<TrainingExamResultsListComponentState>) {
        this.localStore.setState({
            resultsList: [],
            isResultsListProcessing: false,
            resultsListError: undefined,
            pagination: undefined,
            activeTabIndex: TrainingExamResultsTabType.Trainings,
        });
    }
}
