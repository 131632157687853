import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest, of } from "rxjs";
import { map } from "rxjs/operators";
import { Capabilities, OperationsManualVersion } from "../../services/operations-manual.models";

interface HeaderComponentState {
    capabilities: Capabilities | undefined;
    selectedOperationsManual: OperationsManualVersion | undefined;
    areActionsBlocked: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-opsman-header[capabilities]",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HeaderComponent {
    @Input() public set capabilities(value: Capabilities | undefined) {
        this.localStore.patchState({ capabilities: value });

        const operationsManual = this.getDefaultOperationManual(value?.operationsManuals ?? []);
        this.operationManualControl.setValue(operationsManual, { emitEvent: false, onlySelf: true });
        this.localStore.patchState({ selectedOperationsManual: operationsManual ?? undefined });
        this.versionChange.emit(operationsManual ?? undefined);
    }

    @Input() public set areActionsBlocked(value: BooleanInput) {
        this.localStore.patchState({ areActionsBlocked: coerceBooleanProperty(value) });
    }

    @Output() public versionChange = new EventEmitter<OperationsManualVersion>();
    @Output() public pdfDownload = new EventEmitter<void>();
    @Output() public publish = new EventEmitter<void>();
    @Output() public newVersionCreate = new EventEmitter<void>();

    private readonly capabilities$ = this.localStore.selectByKey("capabilities").pipe(RxjsUtils.filterFalsy());
    protected readonly operationsManuals$ = this.capabilities$.pipe(map((capabilities) => capabilities.operationsManuals));
    protected readonly isPublishButtonVisible$ = combineLatest([
        this.capabilities$.pipe(map((capabilities) => capabilities.permissions.canPublish)),
        this.localStore.selectByKey("selectedOperationsManual"),
    ]).pipe(
        map(([canPublish, selectedOperationsManual]) => {
            if (!canPublish) {
                return false;
            }

            return !!(selectedOperationsManual && !selectedOperationsManual.isPublished);
        })
    );
    protected readonly isCreateNewVersionButtonVisible$ = combineLatest([
        this.capabilities$.pipe(map((capabilities) => capabilities.permissions.canCreate)),
        this.isPublishButtonVisible$,
    ]).pipe(map(([canCreate, isPublishButtonVisible]) => canCreate && !isPublishButtonVisible));

    protected readonly operationManualControl = new FormControl<OperationsManualVersion | null>(null);

    constructor(
        private readonly localStore: LocalComponentStore<HeaderComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            capabilities: undefined,
            selectedOperationsManual: undefined,
            areActionsBlocked: false,
        });

        this.operationManualControl.valueChanges.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe((operationsManual) => {
            const isOperationManualChangeAllowed$ = this.localStore.selectSnapshotByKey("areActionsBlocked")
                ? this.confirmOperationManualChange()
                : of(true);

            isOperationManualChangeAllowed$.pipe(untilDestroyed(this)).subscribe((isConfirmed) => {
                if (isConfirmed) {
                    this.localStore.patchState({ selectedOperationsManual: operationsManual });
                    this.versionChange.emit(operationsManual);
                } else {
                    this.operationManualControl.setValue(this.localStore.selectSnapshotByKey("selectedOperationsManual") ?? null, {
                        emitEvent: false,
                        onlySelf: true,
                    });
                }
            });
        });
    }

    private getDefaultOperationManual(versions: OperationsManualVersion[]): OperationsManualVersion | null {
        const notPublishedVersion = versions.find((version) => !version.isPublished);
        const highestVersion = [...versions].sort((left, right) => right.version - left.version)[0];

        return notPublishedVersion ?? highestVersion ?? null;
    }

    protected tryPdfDownload() {
        const isPdfDownloadAllowed$ = this.localStore.selectSnapshotByKey("areActionsBlocked") ? this.confirmPdfDownload() : of(true);

        isPdfDownloadAllowed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            this.pdfDownload.emit();
        });
    }

    protected tryNewVersionCreate() {
        const isNewVersionCreateAllowed$ = this.localStore.selectSnapshotByKey("areActionsBlocked")
            ? this.confirmNewVersionCreate()
            : of(true);

        isNewVersionCreateAllowed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            this.newVersionCreate.emit();
        });
    }

    protected tryPublish() {
        const isPublishAllowed$ = this.localStore.selectSnapshotByKey("areActionsBlocked") ? this.confirmPublish() : of(true);

        isPublishAllowed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            this.publish.emit();
        });
    }

    private confirmOperationManualChange(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmOperationManualChangeDialog.titleText"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibOpsMan.header.confirmOperationManualChangeDialog.dialogMessage"
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.header.confirmOperationManualChangeDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.header.confirmOperationManualChangeDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private confirmPdfDownload(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPdfDownloadDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPdfDownloadDialog.dialogMessage"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPdfDownloadDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPdfDownloadDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private confirmNewVersionCreate(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmNewVersionCreateDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmNewVersionCreateDialog.dialogMessage"),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.header.confirmNewVersionCreateDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.header.confirmNewVersionCreateDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private confirmPublish(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPublishDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPublishDialog.dialogMessage"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPublishDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.header.confirmPublishDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }
}
