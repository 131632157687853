<ng-container *ngrxLet="insurancePolicyExpirationDate$ as insurancePolicyExpirationDate">
    <div
        class="summary-section"
        [class]="{ 'single-value': insurancePolicyExpirationDate === undefined }"
        *ngrxLet="insurancePolicyNumber$ as insurancePolicyNumber"
    >
        <dtm-ui-label-value
            [label]="'dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceCheckboxText' | transloco"
            [value]="'dtmWebAppLibPilotOperatorRegistration.summary.trueFalseValueLabel' | transloco : { value: !!insurancePolicyNumber }"
        ></dtm-ui-label-value>

        <ng-container *ngIf="insurancePolicyNumber">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceNumberLabel' | transloco"
                [value]="insurancePolicyNumber"
            >
            </dtm-ui-label-value>

            <dtm-ui-label-value
                *ngIf="insurancePolicyExpirationDate"
                [label]="'dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceExpirationDateLabel' | transloco"
                [value]="insurancePolicyExpirationDate | localizeDate"
            >
            </dtm-ui-label-value>
        </ng-container>
    </div>
</ng-container>
