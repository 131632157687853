import { ChangeDetectionStrategy, Component, HostBinding, Inject } from "@angular/core";
import {
    ContextOperator,
    DeviceSize,
    DeviceSizeService,
    GlobalFeatures,
    GlobalOperatorPermissions,
    OperatorType,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { MISSION_PLANNING_COURSE_URL, RxjsUtils } from "@dtm-frontend/shared/utils";
import { OperatorContextState } from "@dtm-frontend/web-app-lib/shared";
import { MissionStatus, TacticalActions, TacticalState } from "@dtm-frontend/web-app-lib/tactical";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { combineLatestWith, skip, switchMap } from "rxjs";
import { first, map, mergeMap } from "rxjs/operators";

const MAX_MENU_OPERATORS = 5;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    @HostBinding("class.collapsed") public get isMenuCollapsed() {
        return this.store.selectSnapshot(UIState.isMenuCollapsed);
    }

    // TODO: should we display these operators at all? This is waiting for new UX changes
    protected readonly operators$ = this.store
        .select(OperatorContextState.operatorsSortedByName)
        .pipe(map((operators) => operators.slice(0, MAX_MENU_OPERATORS - 1)));
    protected readonly selectedContextType$ = this.store.select(OperatorContextState.selectedContextType);
    protected readonly isCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly isOperationsManualAvailable$ = this.store
        .select(OperatorContextState.isFeatureAvailable(GlobalFeatures.OperationsManual))
        .pipe(
            combineLatestWith(this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorOperationsManualRead))),
            map(([isFeatureAvailable, isPermitted]) => isPermitted && isFeatureAvailable)
        );
    protected readonly activeMissions$ = this.store.select(TacticalState.activeMissions);
    protected readonly activeMissionsError$ = this.store.select(TacticalState.tacticalMenuError);
    protected readonly isMenuMissionDataProcessing$ = this.store.select(TacticalState.isMenuMissionDataProcessing);
    protected readonly isMembersFeatureAvailable$ = this.store.select(OperatorContextState.isFeatureAvailable(GlobalFeatures.Members));
    protected readonly selectedContext$ = this.store.select(OperatorContextState.selectedContext);
    protected readonly isRequiredRegisterNationalNodeAuthenticatedUser$ = this.store.select(
        OperatorContextState.isRequiredRegisterNationalNodeAuthenticatedUser
    );
    protected readonly isMissionPlanningFeatureAvailable$ = this.store
        .select(OperatorContextState.isFeatureAvailable(GlobalFeatures.MissionPlanner))
        .pipe(
            combineLatestWith(
                this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorMissionsPlan)).pipe(
                    combineLatestWith(this.store.select(OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorMissionsRead))),
                    map(([isPlanningPermitted, isReadingPermitted]) => isPlanningPermitted || isReadingPermitted)
                )
            ),
            map(([isFeatureAvailable, isPermitted]) => isFeatureAvailable && isPermitted)
        );
    protected readonly eLearningPortalUrl$ = this.store.select(OperatorContextState.elearningPortalUrl);

    protected readonly MissionStatus = MissionStatus;
    protected readonly OperatorType = OperatorType;
    protected readonly GlobalOperatorPermissions = GlobalOperatorPermissions;

    constructor(
        private readonly store: Store,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly toastService: ToastrService,
        private readonly translationHelperService: TranslationHelperService,
        @Inject(MISSION_PLANNING_COURSE_URL) protected readonly missionPlanningCourseUrl: string
    ) {
        this.watchTacticalMenuErrors();
        this.startActiveMissionsUpdatesWatch();
        this.watchOperatorChangesAndUpdateTacticalMissionList();
    }

    protected collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
        }
    }

    protected isPermitted(
        selectedOperatorContext: ContextOperator | undefined,
        globalPermission: GlobalOperatorPermissions | GlobalOperatorPermissions[]
    ): boolean {
        if (!selectedOperatorContext || selectedOperatorContext.type === OperatorType.Personal) {
            return true;
        }

        if (Array.isArray(globalPermission)) {
            return globalPermission.some((permission) => selectedOperatorContext.permissions.includes(permission));
        }

        return selectedOperatorContext.permissions.includes(globalPermission);
    }

    protected refreshActiveMissions(event: Event, isProcessing: boolean): void {
        event.preventDefault();
        if (isProcessing) {
            return;
        }
        this.store.dispatch(TacticalActions.GetActiveMissions);
    }

    private watchOperatorChangesAndUpdateTacticalMissionList() {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(
                RxjsUtils.filterFalsy(),
                skip(1),
                switchMap(() => this.store.select(OperatorContextState.isFeatureAvailable(GlobalFeatures.MissionPlanner))),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe(() => this.store.dispatch(TacticalActions.GetActiveMissions));
    }

    private watchTacticalMenuErrors() {
        this.store
            .select(OperatorContextState.isFeatureAvailable(GlobalFeatures.MissionPlanner))
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.select(TacticalState.tacticalMenuError)),
                RxjsUtils.filterFalsy(),
                mergeMap(() =>
                    this.translationHelperService.waitForTranslation("webApp.errors.activeMissionLoadingErrorMessage").pipe(first())
                ),
                untilDestroyed(this)
            )
            .subscribe((errorMessage) => this.toastService.error(errorMessage));
    }

    private startActiveMissionsUpdatesWatch() {
        this.store
            .select(OperatorContextState.isFeatureAvailable(GlobalFeatures.MissionPlanner))
            .pipe(
                RxjsUtils.filterFalsy(),
                first(),
                switchMap(() => this.store.dispatch(TacticalActions.StartActiveMissionsUpdatesWatch)),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
