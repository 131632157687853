import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { LocalComponentStore, Logger } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, Store, ofActionCompleted } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { BasicUavSetup, ShareUavModel, ShareableOperator } from "../../services/uav.models";
import { UavActions } from "../../state/uav.actions";
import { UavState } from "../../state/uav.state";

interface ShareUavDialogConfig {
    uavName: string;
    isPilot: boolean;
}

interface ShareUavComponentState {
    isProcessing: boolean;
    operators: ShareableOperator[];
    setups: BasicUavSetup[];
}

interface ShareUavForm {
    operator: FormControl<ShareableOperator | null>;
    setups: FormControl<BasicUavSetup[] | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-share-uav-setup",
    templateUrl: "./share-uav-setup.component.html",
    styleUrls: ["./share-uav-setup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ShareUavSetupComponent {
    @Output() public shareSetup = new EventEmitter<ShareUavModel>();

    protected readonly setups$ = this.localStore.selectByKey("setups");
    protected readonly operators$ = this.localStore.selectByKey("operators");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly shareUavForm = new FormGroup<ShareUavForm>({
        operator: new FormControl(null, Validators.required),
        setups: new FormControl(null, Validators.required),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: ShareUavDialogConfig,
        protected readonly dialog: MatDialogRef<ShareUavDialogConfig>,
        private readonly localStore: LocalComponentStore<ShareUavComponentState>,
        private actions$: Actions,
        private store: Store
    ) {
        this.localStore.setState({
            isProcessing: true,
            operators: [],
            setups: [],
        });

        this.listenOnGetCapabilitiesAction();
    }

    protected removeSetup(setup: BasicUavSetup): void {
        const setupsControl = this.shareUavForm.controls.setups;
        const currentValue = setupsControl.value;
        if (Array.isArray(currentValue)) {
            setupsControl.patchValue(currentValue.filter((itemValue: BasicUavSetup) => itemValue.id !== setup.id));

            return;
        }

        setupsControl.setValue(null);
    }

    protected shareUavSetup(): void {
        if (this.shareUavForm.invalid) {
            this.shareUavForm.markAllAsTouched();

            return;
        }

        this.emitSetupFormValue();
    }

    private emitSetupFormValue(): void {
        const setupList = this.shareUavForm.controls.setups.value;
        const operator = this.shareUavForm.controls.operator.value;

        if (!setupList || !operator) {
            return;
        }

        this.shareSetup.emit({
            receivingOperatorId: operator?.id,
            sharedSetups: setupList.map((setup) => setup.id),
        });
    }

    private listenOnGetCapabilitiesAction(): void {
        this.actions$
            .pipe(
                ofActionCompleted(UavActions.GetShareCapabilities),
                tap(({ result }) => {
                    if (result.error) {
                        Logger.captureException(result.error);

                        return;
                    }

                    const capabilities = this.store.selectSnapshot(UavState.getCapabilitiesToShare);
                    this.localStore.patchState({
                        isProcessing: false,
                        setups: capabilities?.setups,
                        operators: capabilities?.operators,
                    });

                    this.shareUavForm.controls.setups.patchValue(
                        capabilities?.setups.filter((configuration) => configuration.isPrimary) ?? null
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
