import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EmptyStateMode, ErrorMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { DashboardError, DashboardMission } from "../../services/dashboard.models";

interface DashboardMissionsListComponentState {
    missions: DashboardMission[];
    isProcessing: boolean;
    error: DashboardError | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-dashboard-missions-list",
    templateUrl: "./dashboard-missions-list.component.html",
    styleUrls: ["./dashboard-missions-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DashboardMissionsListComponent {
    @Input() public set missions(value: DashboardMission[] | undefined) {
        this.localStore.patchState({ missions: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set error(value: DashboardError | undefined) {
        this.localStore.patchState({ error: value });
    }

    protected readonly missions$ = this.localStore.selectByKey("missions");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly error$ = this.localStore.selectByKey("error");
    protected readonly displayedColumns = ["pilot", "status", "date", "hour", "uav", "name", "actions"];
    protected readonly ErrorMode = ErrorMode;
    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(private readonly localStore: LocalComponentStore<DashboardMissionsListComponentState>) {
        localStore.setState({ missions: [], isProcessing: false, error: undefined });
    }
}
