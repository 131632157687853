import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { GeoJSON, InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { DEFAULT_LANG, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { FormType, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { combineLatest, skip } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import {
    AdditionalCrew,
    Area,
    CrewCompetencies,
    CrewMember,
    CrewTrainings,
    Operation,
    OperationAreaType,
    OperationDetailsData,
    OperationPilot,
    OperationScenario,
    PredefinedArea,
    StaffCompetencies,
    StaffTrainings,
    TimeOfDayInfo,
    TimeOfTheDay,
    Training,
} from "../../../../services/specific-permit-application.models";

interface OperationDetailsStepComponentState {
    isProcessing: boolean;
    isSaveDraftProcessing: boolean;
    initialValues: OperationDetailsData | undefined;
    operation: Operation | undefined;
    operationPilot: OperationPilot | undefined;
    predefinedAreas: PredefinedArea[];
    availableOperationScenarios: OperationScenario[];
    activeLanguage: LanguageCode;
    availableTrainings: Training[];
    requiredPilotTrainings: StaffTrainings | undefined;
    availableCrewMembers: CrewMember[];
    customAreaInitialViewbox: GeoJSON | undefined;
}

const MAX_OPERATIONS_MANUAL_VERSION_LENGTH = 100;
const MAX_MAX_WIND_SPEED = 60;
const MAX_MIN_VISIBILITY = 10000;
const MIN_TEMPERATURE = -50;
const MAX_TEMPERATURE = 50;
const MAX_IGRC_FOR_CUSTOM_AREA = 4;
const MAX_IARC_FOR_CUSTOM_AREA = 2;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-operation-details-step[operation]",
    templateUrl: "./operation-details-step.component.html",
    styleUrls: ["../../../common.scss", "./operation-details-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperationDetailsStepComponent implements OnChanges {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isSaveDraftProcessing(value: BooleanInput) {
        this.localStore.patchState({ isSaveDraftProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set initialValues(value: OperationDetailsData | undefined) {
        this.localStore.patchState({ initialValues: value });
    }

    @Input() public set operation(value: Operation | undefined) {
        this.localStore.patchState({ operation: value });
    }

    @Input() public set operationPilot(value: OperationPilot | undefined) {
        this.localStore.patchState({ operationPilot: value });
    }

    @Input() public set predefinedAreas(value: PredefinedArea[] | undefined) {
        this.localStore.patchState({ predefinedAreas: value ?? [] });
    }

    @Input() public set availableOperationScenarios(value: OperationScenario[] | undefined) {
        this.localStore.patchState({ availableOperationScenarios: value ?? [] });
    }

    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value ?? DEFAULT_LANG });
    }

    @Input() public set availableTrainings(value: Training[] | undefined) {
        this.localStore.patchState({ availableTrainings: value ?? [] });
    }

    @Input() public set requiredPilotTrainings(value: StaffTrainings | undefined) {
        this.localStore.patchState({ requiredPilotTrainings: value });
    }

    @Input() public set availableCrewMembers(value: CrewMember[] | undefined) {
        this.localStore.patchState({ availableCrewMembers: value ?? [] });
    }

    @Input() public set customAreaInitialViewbox(value: GeoJSON | undefined) {
        this.localStore.patchState({ customAreaInitialViewbox: value });
    }

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly saveDraft = new EventEmitter<OperationDetailsData>();
    @Output() public readonly next = new EventEmitter<OperationDetailsData>();
    @Output() public readonly valueChange = new EventEmitter<void>();
    @Output() public readonly areaPreviewShow = new EventEmitter<Area>();
    @Output() public readonly pilotCompetenciesChange = new EventEmitter<StaffCompetencies>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isSaveDraftProcessing$ = this.localStore.selectByKey("isSaveDraftProcessing");
    protected readonly predefinedAreas$ = this.localStore.selectByKey("predefinedAreas");
    protected readonly availableOperationScenarios$ = this.localStore.selectByKey("availableOperationScenarios");
    protected readonly availableTrainings$ = this.localStore.selectByKey("availableTrainings");
    protected readonly customAreaInitialViewbox$ = this.localStore.selectByKey("customAreaInitialViewbox");
    protected readonly isCustomAreaDisabled$ = this.localStore
        .selectByKey("operation")
        .pipe(map((operation) => this.isCustomAreaDisabled(operation)));
    protected readonly requiredPilotTrainings$ = this.localStore.selectByKey("requiredPilotTrainings");
    protected readonly optionalAvailablePilotTrainings$ = combineLatest([
        this.availableTrainings$,
        this.requiredPilotTrainings$.pipe(map((pilotTrainings) => (pilotTrainings?.basic ?? []).map((training) => training.id))),
    ]).pipe(
        map(([availableTrainings, requiredPilotTrainingsIds]) =>
            availableTrainings.filter((training) => !requiredPilotTrainingsIds.includes(training.id))
        )
    );
    protected readonly activeLanguage$ = this.localStore.selectByKey("activeLanguage");
    protected readonly availableCrewMembers$ = this.localStore.selectByKey("availableCrewMembers");

    protected readonly operationDetailsForm = new FormGroup<FormType<OperationDetailsData>>(
        {
            operationsManualVersion: new FormControl<string | null>(null, [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(MAX_OPERATIONS_MANUAL_VERSION_LENGTH),
            ]),
            operationsManualChapter: new FormControl<number | null>(null, {
                validators: [Validators.required, Validators.pattern(/^[1-9][0-9]?$/)],
            }),
            operationArea: new FormControl<Area | null>(null, [Validators.required]),
            pilotCompetencies: new FormControl<StaffCompetencies | null>(null),
            pilotOptionalTrainings: new FormControl<StaffTrainings | null>(null),
            additionalCrewMembers: new FormControl<AdditionalCrew | null>(null),
            crewCompetencies: new FormControl<CrewCompetencies>({}),
            crewTrainings: new FormControl<CrewTrainings>({}),
            timeOfDay: new FormControl<TimeOfDayInfo | null>(null, Validators.required),
            maxWindSpeed: new FormControl<number | null>(null, [
                Validators.required,
                Validators.min(0),
                Validators.max(MAX_MAX_WIND_SPEED),
            ]),
            minVisibility: new FormControl<number | null>(null, [
                Validators.required,
                Validators.min(0),
                Validators.max(MAX_MIN_VISIBILITY),
            ]),
            maxTemperature: new FormControl<number | null>(null, [
                Validators.required,
                Validators.min(MIN_TEMPERATURE),
                Validators.max(MAX_TEMPERATURE),
            ]),
            minTemperature: new FormControl<number | null>(null, [
                Validators.required,
                Validators.min(MIN_TEMPERATURE),
                Validators.max(MAX_TEMPERATURE),
            ]),
            isFlightDuringRain: new FormControl<boolean>(false, { validators: [Validators.required], nonNullable: true }),
        },
        { validators: this.temperatureValidator() }
    );

    constructor(private readonly localStore: LocalComponentStore<OperationDetailsStepComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            isSaveDraftProcessing: false,
            initialValues: undefined,
            operation: undefined,
            operationPilot: undefined,
            predefinedAreas: [],
            availableOperationScenarios: [],
            activeLanguage: DEFAULT_LANG,
            availableTrainings: [],
            requiredPilotTrainings: undefined,
            availableCrewMembers: [],
            customAreaInitialViewbox: undefined,
        });

        this.operationDetailsForm.valueChanges.pipe(distinctUntilChanged(equal), skip(1), untilDestroyed(this)).subscribe(() => {
            this.valueChange.emit();
        });

        this.operationDetailsForm.controls.pilotCompetencies.valueChanges
            .pipe(distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => {
                this.pilotCompetenciesChange.emit(value);
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.initialValues) {
            const operation = this.localStore.selectSnapshotByKey("operation") ?? changes.operation.currentValue ?? undefined;
            const operationPilot =
                this.localStore.selectSnapshotByKey("operationPilot") ?? changes.operationPilot.currentValue ?? undefined;
            this.operationDetailsForm.patchValue(
                changes.initialValues.currentValue ?? this.getDefaultOperationDetailsFormValues(operation, operationPilot)
            );
        }
    }

    protected goToNextStep(): void {
        this.operationDetailsForm.markAllAsTouched();
        this.invalidFormScrollable.scrollToFirstInvalidField();

        if (!this.operationDetailsForm.valid) {
            return;
        }

        this.next.emit(this.getOperationDetails());
    }

    protected saveDataToDraft(): void {
        this.saveDraft.emit(this.getOperationDetails());
    }

    protected setFlightDuringRain(value: boolean): void {
        this.operationDetailsForm.controls.isFlightDuringRain.setValue(value);
    }

    private getDefaultOperationDetailsFormValues(
        operation: Operation | undefined,
        operationPilot: OperationPilot | undefined
    ): OperationDetailsData {
        return {
            operationsManualVersion: null,
            operationsManualChapter: null,
            operationArea: {
                type: OperationAreaType.Operation,
            },
            pilotCompetencies: {
                basic: (operationPilot?.competencies ?? []).map((competency) => competency.operationScenario),
                additional: [],
                hasEuRegulationCompetency: false,
            },
            pilotOptionalTrainings: null,
            additionalCrewMembers: (operation?.additionalCrew ?? []).reduce<AdditionalCrew>(
                (additionalCrewMembers, crewMember) => [
                    ...additionalCrewMembers,
                    {
                        member: crewMember.type,
                        amount: crewMember.type === CrewMember.UavObserver ? crewMember.amount : null,
                        responsibilities: null,
                    },
                ],
                []
            ),
            crewCompetencies: (operation?.additionalCrew ?? []).reduce<CrewCompetencies>((additionalCrewMembers, crewMember) => {
                additionalCrewMembers[crewMember.type] = null;

                return additionalCrewMembers;
            }, {}),
            crewTrainings: (operation?.additionalCrew ?? []).reduce<CrewTrainings>((additionalCrewMembers, crewMember) => {
                additionalCrewMembers[crewMember.type] = null;

                return additionalCrewMembers;
            }, {}),
            timeOfDay: {
                timeOfDay: TimeOfTheDay.Day,
            },
            maxWindSpeed: null,
            minVisibility: null,
            minTemperature: null,
            maxTemperature: null,
            isFlightDuringRain: true,
        };
    }

    private getOperationDetails(): OperationDetailsData {
        return this.operationDetailsForm.getRawValue();
    }

    private temperatureValidator(): ValidatorFn {
        return (form: AbstractControl): ValidationErrors | null => {
            const formValue: FormType<OperationDetailsData> = form.value;

            if (formValue.maxTemperature < formValue.minTemperature) {
                return { minTemperatureLowerThanMaxTemperature: true };
            }

            return null;
        };
    }

    private isCustomAreaDisabled(operation: Operation | undefined): boolean {
        if (!operation) {
            return false;
        }

        return operation.groundRisk.intrinsicGrc > MAX_IGRC_FOR_CUSTOM_AREA || operation.airspaceRisk.initialArc > MAX_IARC_FOR_CUSTOM_AREA;
    }
}
