import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { GeoZonesActions } from "@dtm-frontend/shared/map/geo-zones";
import {
    EvaluationIssueStatus,
    MissionPlanAnalysisIssueBase,
    MissionPlanAnalysisIssueStatus,
    MissionPlanAnalysisStatus,
    MissionPlanData,
    MissionPlanDataAndCapabilities,
    MissionPlanInformation,
    MissionPlanStatus,
    MissionUtils,
} from "@dtm-frontend/shared/mission";
import { NotificationsApiService } from "@dtm-frontend/shared/notifications";
import { ItineraryEditorType, MissionPlanRoute, Page, RouteData } from "@dtm-frontend/shared/ui";
import { FunctionUtils, ObjectUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { EMPTY, Subscription, filter, firstValueFrom, forkJoin, mergeMap, of } from "rxjs";
import { catchError, finalize, first, map, switchMap, tap } from "rxjs/operators";
import { FlightPurpose, OperatorContextState } from "../../shared";
import {
    MissionPlanningNotificationTargetType,
    MissionPlanningNotificationType,
    WebAppMissionPlanningNotification,
} from "../../shared/notifications/services/web-app-notifications.models";
import {
    ActivePermit,
    MissionCapabilities,
    MissionError,
    MissionPlan,
    MissionPlanAndCapabilities,
    MissionPlanContent,
    MissionPlanItinerary,
    MissionPlanningPreferences,
    OperationalGeometryData,
    PublicMissionPlanData,
    SoraSettings,
} from "../models/mission.model";
import { MissionAPIService } from "../services/mission-api.service";
import { MissionPlanVerificationAPIService } from "../services/mission-plan-verification-api.service";
import { DEFAULT_MISSION_LIST_FILTERS_AND_SORTING } from "../services/mission.resolvers";
import { MissionActions } from "./mission.actions";

export interface MissionStateModel {
    areAvailableRoutesLoading: boolean;
    areCapabilitiesLoading: boolean;
    areOtherMissionsProcessing: boolean;
    activePermits: ActivePermit[] | undefined;
    capabilities: MissionCapabilities | undefined;
    currentPlanDataAndCapabilities: MissionPlanDataAndCapabilities | undefined;
    flightPurposes: FlightPurpose[] | undefined;
    isAnalysisInProgress: boolean;
    isEditable: boolean;
    isProcessing: boolean;
    itinerary: MissionPlanItinerary | undefined;
    missionError: MissionError | undefined;
    missionPlanContentList: MissionPlanContent[] | undefined;
    nearbyMissionsRouteData: RouteData<PublicMissionPlanData>[];
    pagination: Page | undefined;
    plan: MissionPlan | undefined;
    preferences?: MissionPlanningPreferences;
    currentPlanRoute: MissionPlanRoute | undefined;
    routeSuggestions: MissionPlanRoute[] | undefined;
    selectedEditorType: ItineraryEditorType | undefined;
    missionPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    closeSpecificPermitPlanError: MissionError | undefined;
    operationalGeometryData: OperationalGeometryData | undefined;
    isPansaUtmLinkActive: boolean;
}

const defaultState: MissionStateModel = {
    areAvailableRoutesLoading: false,
    areCapabilitiesLoading: false,
    areOtherMissionsProcessing: false,
    activePermits: undefined,
    capabilities: undefined,
    currentPlanDataAndCapabilities: undefined,
    flightPurposes: undefined,
    isAnalysisInProgress: false,
    isEditable: true,
    isProcessing: false,
    itinerary: undefined,
    missionError: undefined,
    missionPlanContentList: undefined,
    nearbyMissionsRouteData: [],
    pagination: undefined,
    plan: undefined,
    preferences: undefined,
    currentPlanRoute: undefined,
    routeSuggestions: undefined,
    selectedEditorType: undefined,
    missionPlanAnalysisStatus: undefined,
    closeSpecificPermitPlanError: undefined,
    operationalGeometryData: undefined,
    isPansaUtmLinkActive: false,
};

const ISSUES_STATUS_SORTING_ORDER = [
    MissionPlanAnalysisIssueStatus.FatalError,
    MissionPlanAnalysisIssueStatus.Error,
    MissionPlanAnalysisIssueStatus.Hold,
    MissionPlanAnalysisIssueStatus.Warning,
    MissionPlanAnalysisIssueStatus.Info,
    MissionPlanAnalysisIssueStatus.Success,
    EvaluationIssueStatus.Rejected,
    EvaluationIssueStatus.Suppressed,
    EvaluationIssueStatus.Open,
] as const;

@State<MissionStateModel>({
    name: "mission",
    defaults: defaultState,
})
@Injectable()
export class MissionState {
    private missionPlanAnalysisTopicSubscription: Subscription | undefined;
    private stopMissionStatusWatchSubscription: Subscription | undefined;

    @Selector()
    public static missionCapabilities(state: MissionStateModel): MissionCapabilities | undefined {
        return state.capabilities;
    }

    @Selector()
    public static missionPreferences(state: MissionStateModel): MissionPlanningPreferences | undefined {
        return state.preferences;
    }

    @Selector([MissionState.missionCapabilities])
    public static suggestedEditor(
        state: MissionStateModel,
        capabilities: MissionCapabilities | undefined
    ): ItineraryEditorType | undefined {
        return capabilities?.suggestedEditor;
    }

    @Selector()
    public static currentPlan(state: MissionStateModel): MissionPlan | undefined {
        return state.plan;
    }

    @Selector()
    public static currentPlanAnalysisStatus(state: MissionStateModel): MissionPlanAnalysisStatus | undefined {
        return state.missionPlanAnalysisStatus;
    }

    @Selector()
    public static currentPlanItinerary(state: MissionStateModel): MissionPlanItinerary | undefined {
        return state.itinerary;
    }

    @Selector()
    public static missionSoraSettings(state: MissionStateModel): SoraSettings | undefined {
        return state.itinerary?.soraSettings;
    }

    @Selector()
    public static missionPlanInformation(state: MissionStateModel): MissionPlanInformation | undefined {
        return state.plan?.information;
    }

    @Selector()
    public static missionError(state: MissionStateModel): MissionError | undefined {
        return state.missionError;
    }

    @Selector()
    public static closeSpecificPermitPlanError(state: MissionStateModel): MissionError | undefined {
        return state.closeSpecificPermitPlanError;
    }

    @Selector()
    public static isProcessing(state: MissionStateModel): boolean {
        return state.isProcessing || state.isAnalysisInProgress;
    }

    @Selector()
    public static areAvailableRoutesLoading(state: MissionStateModel): boolean {
        return state.areAvailableRoutesLoading;
    }

    @Selector()
    public static areCapabilitiesLoading(state: MissionStateModel): boolean {
        return state.areCapabilitiesLoading;
    }

    @Selector()
    public static currentPlanRoute(state: MissionStateModel): MissionPlanRoute | undefined {
        return state.currentPlanRoute;
    }

    @Selector()
    public static nearbyMissionsRouteData(state: MissionStateModel): RouteData<PublicMissionPlanData>[] | undefined {
        return state.nearbyMissionsRouteData;
    }

    @Selector()
    public static getMissionPlanList(state: MissionStateModel): MissionPlanContent[] | undefined {
        return state.missionPlanContentList;
    }

    @Selector()
    public static getPagination(state: MissionStateModel): Page | undefined {
        return state.pagination;
    }

    @Selector()
    public static flightPurposes(state: MissionStateModel): FlightPurpose[] | undefined {
        return state.flightPurposes;
    }

    @Selector()
    public static isEditable(state: MissionStateModel): boolean {
        return state.isEditable;
    }

    @Selector()
    public static activePermitsList(state: MissionStateModel): ActivePermit[] | undefined {
        return state.activePermits;
    }

    @Selector()
    public static selectedEditorType(state: MissionStateModel): ItineraryEditorType | undefined {
        return state.selectedEditorType;
    }

    @Selector()
    public static routeSuggestions(state: MissionStateModel): MissionPlanRoute[] | undefined {
        return state.routeSuggestions;
    }

    @Selector()
    public static currentPlanData(state: MissionStateModel): MissionPlanData | undefined {
        return state.currentPlanDataAndCapabilities?.plan;
    }

    @Selector()
    public static currentPlanDataAndCapabilities(state: MissionStateModel): MissionPlanDataAndCapabilities | undefined {
        return state.currentPlanDataAndCapabilities;
    }

    @Selector()
    public static areOtherMissionsProcessing(state: MissionStateModel): boolean {
        return state.areOtherMissionsProcessing;
    }

    @Selector()
    public static operationalGeometryData(state: MissionStateModel): OperationalGeometryData | undefined {
        return state.operationalGeometryData;
    }

    @Selector()
    public static isPansaUtmLinkActive(state: MissionStateModel): boolean {
        return state.isPansaUtmLinkActive;
    }

    constructor(
        private readonly missionApi: MissionAPIService,
        private readonly missionPlanVerificationApi: MissionPlanVerificationAPIService,
        private readonly store: Store,
        private readonly router: Router,
        private readonly notificationsApiService: NotificationsApiService
    ) {}

    @Action(MissionActions.GetMissionCapabilities)
    public getMissionCapabilities(context: StateContext<MissionStateModel>) {
        context.patchState({ isProcessing: true, capabilities: undefined, areCapabilitiesLoading: true });

        return this.store.select(OperatorContextState.selectedContextId).pipe(
            first(FunctionUtils.isTruthy),
            mergeMap((id) => this.missionApi.getMissionCapabilities(id)),
            map((capabilities: MissionCapabilities) => {
                context.patchState({
                    capabilities,
                    preferences: capabilities.preferences,
                    missionError: undefined,
                    isProcessing: false,
                    areCapabilitiesLoading: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                    areCapabilitiesLoading: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.GetMissionPlanItinerary)
    public getMissionPlanItinerary(context: StateContext<MissionStateModel>, action: MissionActions.GetMissionPlanItinerary) {
        context.patchState({ isProcessing: true });

        return this.missionApi.getMissionPlanItinerary(action.planId).pipe(
            map((itinerary: MissionPlanItinerary) => {
                context.patchState({
                    itinerary,
                    missionError: undefined,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.GetOperationalGeometryData)
    public getOperationalGeometryData(context: StateContext<MissionStateModel>, action: MissionActions.GetOperationalGeometryData) {
        context.patchState({ missionError: undefined, isProcessing: true });

        return this.missionApi.getOperationalGeometryData(action.planId).pipe(
            tap((operationalGeometryData) => {
                context.patchState({
                    operationalGeometryData,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.CreateOrUpdateMissionCapabilities)
    public createOrUpdateMissionCapabilities(
        context: StateContext<MissionStateModel>,
        action: MissionActions.CreateOrUpdateMissionCapabilities
    ) {
        context.patchState({ isProcessing: true, areCapabilitiesLoading: true });
        const state = context.getState();
        const updatedPlan$ = action.planId
            ? this.missionApi.updateMissionCapabilities(
                  action.planId,
                  action.flightType,
                  action.operatorId,
                  action.pilotId,
                  action.uavSetupId,
                  action.additionalCrew
              )
            : this.missionApi.createMissionCapabilities(
                  action.flightType,
                  action.operatorId,
                  action.pilotId,
                  action.uavSetupId,
                  action.additionalCrew
              );

        return updatedPlan$.pipe(
            map((updatedPlanAndCapabilities: MissionPlanAndCapabilities) => {
                context.patchState({
                    missionPlanAnalysisStatus: undefined,
                    plan: updatedPlanAndCapabilities.plan,
                    capabilities: {
                        ...state.capabilities,
                        flightPurposes: this.reuseInstanceIfEqual(
                            state.capabilities?.flightPurposes,
                            updatedPlanAndCapabilities.flightPurposes
                        ),
                        operationCategories: this.reuseInstanceIfEqual(
                            state.capabilities?.operationCategories,
                            updatedPlanAndCapabilities.operationCategories
                        ),
                    },
                    missionError: undefined,
                    isProcessing: false,
                    areCapabilitiesLoading: false,
                    currentPlanRoute: undefined,
                });
            }),
            catchError((error) =>
                of(
                    context.patchState({
                        missionError: error,
                        isProcessing: false,
                        areCapabilitiesLoading: false,
                    })
                )
            )
        );
    }

    private reuseInstanceIfEqual<T>(oldOptions: T[] | undefined, newOptions: T[]): T[] {
        if (!oldOptions) {
            return newOptions;
        }

        return newOptions.map((newOption) => oldOptions.find((oldOption) => equal(newOption, oldOption)) ?? newOption);
    }

    @Action(MissionActions.UpdateMissionPlanFlightPurpose)
    public updateMissionPlanFlightPurpose(context: StateContext<MissionStateModel>, action: MissionActions.UpdateMissionPlanFlightPurpose) {
        const state = context.getState();

        if (state.plan?.id !== action.planId) {
            return;
        }

        context.patchState({ isProcessing: true });
        const updatedPlan$ = this.missionApi.updateMissionPlanFlightPurpose(
            action.planId,
            action.flightPurposeId,
            action.flightPurposeDescription,
            action.loadWeightKilograms
        );

        return updatedPlan$.pipe(
            map((updatedPlanAndCapabilities: MissionPlanAndCapabilities) => {
                context.patchState({
                    plan: updatedPlanAndCapabilities.plan,
                    missionPlanAnalysisStatus: undefined,
                    missionError: undefined,
                    isProcessing: false,
                    currentPlanRoute: undefined,
                });
            }),
            catchError((error) =>
                of(
                    context.patchState({
                        missionError: error,
                        isProcessing: false,
                    })
                )
            )
        );
    }

    @Action(MissionActions.UpdateMissionPlanOperationCategory)
    public updateMissionPlanOperationCategory(
        context: StateContext<MissionStateModel>,
        action: MissionActions.UpdateMissionPlanOperationCategory
    ) {
        const state = context.getState();
        if (state.plan?.id !== action.planId) {
            return;
        }

        context.patchState({ isProcessing: true });
        const updatedPlan$ = this.missionApi.updateMissionPlanOperationCategory(action.planId, action.category);

        return updatedPlan$.pipe(
            map((updatedPlanAndCapabilities: MissionPlanAndCapabilities) => {
                context.patchState({
                    capabilities: {
                        ...state.capabilities,
                        availableEditors: updatedPlanAndCapabilities.editors.options,
                        suggestedEditor: updatedPlanAndCapabilities.editors.suggested,
                    },
                    plan: updatedPlanAndCapabilities.plan,
                    missionPlanAnalysisStatus: undefined,
                    missionError: undefined,
                    isProcessing: false,
                    currentPlanRoute: undefined,
                });
            }),
            catchError((error) =>
                of(
                    context.patchState({
                        missionError: error,
                        isProcessing: false,
                    })
                )
            )
        );
    }

    @Action(MissionActions.SetIsProcessing)
    public setIsProcessing(context: StateContext<MissionStateModel>, action: MissionActions.SetIsProcessing) {
        context.patchState({ isProcessing: action.isProcessing });
    }

    @Action(MissionActions.UpdateMissionPlanItinerary)
    public updateMissionPlanItinerary(context: StateContext<MissionStateModel>, action: MissionActions.UpdateMissionPlanItinerary) {
        const state = context.getState();
        if (state.plan?.id !== action.planId) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.missionApi.updateMissionPlanItinerary(action.planId, action.itineraryContent, action.itineraryFormData).pipe(
            map(({ itinerary, route }) => {
                context.patchState({
                    itinerary,
                    missionPlanAnalysisStatus: undefined,
                    missionError: undefined,
                    isProcessing: false,
                    currentPlanRoute: route,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.StartMissionPlanAnalysisStatusWatch)
    public startMissionPlanAnalysisStatusWatch(context: StateContext<MissionStateModel>) {
        return this.store.select(AuthState.userId).pipe(
            RxjsUtils.filterFalsy(),
            tap((userId) => {
                this.missionPlanAnalysisTopicSubscription = this.missionPlanVerificationApi
                    .startMissionPlanAnalysisStatusWatch(userId)
                    .subscribe((status) => context.dispatch(new MissionActions.UpdateMissionPlanAnalysisStatus(status)));
            }),
            catchError((error) =>
                of(
                    context.patchState({
                        missionError: error,
                    })
                )
            )
        );
    }

    @Action(MissionActions.StartMissionPlanAnalysis)
    public startMissionPlanAnalysis(context: StateContext<MissionStateModel>, action: MissionActions.StartMissionPlanAnalysis) {
        context.patchState({
            isProcessing: true,
            missionPlanAnalysisStatus: undefined,
        });

        return this.missionApi.createMissionPlanRoutes(action.planId).pipe(
            RxjsUtils.filterFalsy(),
            switchMap((routeId) => {
                const planVerification$ = this.store.dispatch(MissionActions.GetMissionPlanAnalysis);
                const route$ = this.missionApi.getMissionRoute(routeId[0]).pipe(
                    tap((route) => {
                        context.patchState({
                            currentPlanRoute: route,
                        });
                    })
                );

                return forkJoin([planVerification$, route$]).pipe(
                    tap(() => {
                        context.patchState({ isProcessing: false });
                    })
                );
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.GetMissionPlanAnalysis)
    public getMissionPlanAnalysis(context: StateContext<MissionStateModel>) {
        const planId = context.getState().plan?.id;
        if (!planId) {
            throw new Error("PlanId not specified");
        }

        context.patchState({ isProcessing: true });

        return this.missionPlanVerificationApi.getCurrentMissionPlanVerification(planId).pipe(
            tap((firstStatus) => {
                context.patchState({
                    missionError: undefined,
                    isProcessing: false,
                });
                context.dispatch(new MissionActions.UpdateMissionPlanAnalysisStatus(firstStatus));
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.ClearItinerary)
    public clearItinerary(context: StateContext<MissionStateModel>) {
        context.patchState({
            currentPlanRoute: undefined,
            missionError: undefined,
        });
    }

    @Action(MissionActions.StopMissionPlanAnalysisStatusWatch)
    public stopMissionPlanAnalysisStatusWatch() {
        return this.missionPlanAnalysisTopicSubscription?.unsubscribe();
    }

    @Action(MissionActions.UpdateMissionPlanAnalysisStatus)
    public updateMissionPlanAnalysisStatus(
        context: StateContext<MissionStateModel>,
        action: MissionActions.UpdateMissionPlanAnalysisStatus
    ) {
        const { plan, missionPlanAnalysisStatus, currentPlanRoute } = context.getState();

        if (
            !plan ||
            plan.id !== action.status.planId ||
            (missionPlanAnalysisStatus &&
                missionPlanAnalysisStatus.systemVerificationId === action.status.systemVerificationId &&
                missionPlanAnalysisStatus.version >= action.status.version)
        ) {
            return;
        }

        this.sortAnalysisIssues(action.status.airspace?.issues);
        this.sortAnalysisIssues(action.status.traffic?.issues);
        this.sortAnalysisIssues(action.status.caaPermit?.issues);
        this.sortAnalysisIssues(action.status.evaluation?.issues);

        context.dispatch([
            new MissionActions.GetNearbyMissionsRouteData(
                action.status.planId,
                action.status.traffic?.nearbyMissionsPlanIds ?? [],
                action.status.traffic?.collisionMissionsPlanIds ?? []
            ),
            MissionActions.ClearAirspaceElements,
        ]);

        const analysisInProgress = action.status.planStatus === MissionPlanStatus.Unverified;

        if (
            !analysisInProgress &&
            currentPlanRoute &&
            ((action.status.airspace.zoneIssues && Object.keys(action.status.airspace.zoneIssues).length) ||
                (action.status.evaluation.zoneIssues && Object.keys(action.status.evaluation.zoneIssues).length))
        ) {
            const waypoints = MissionUtils.convertRouteToWaypoints(currentPlanRoute);
            const timeRange = MissionUtils.getTimeRangeFromWaypointsWithSection(waypoints);
            const upperLimit = currentPlanRoute.sections.reduce((result, section) => {
                const ceiling = section.segment?.safetyArea.volume.ceiling ?? section.flightZone?.safetyArea.volume.ceiling ?? 0;

                return Math.max(result, ceiling);
            }, 0);

            context.dispatch(
                new MissionActions.SearchAirspaceElements({
                    designators: [
                        ...Object.keys(action.status.airspace.zoneIssues ?? {}),
                        ...Object.keys(action.status.evaluation.zoneIssues ?? {}),
                    ],
                    scope: {
                        startTime: timeRange.min,
                        endTime: timeRange.max,
                        lowerLimit: 0,
                        upperLimit,
                    },
                    includeInformation: true,
                })
            );
        }

        context.patchState({
            isAnalysisInProgress: analysisInProgress,
            missionPlanAnalysisStatus: action.status,
        });
    }

    @Action(MissionActions.GetNearbyMissionsRouteData)
    public getNearbyMissionsRoutes(
        context: StateContext<MissionStateModel>,
        { nearbyMissionsPlanIds, collisionMissionsPlanIds }: MissionActions.GetNearbyMissionsRouteData
    ) {
        context.patchState({
            areOtherMissionsProcessing: true,
        });

        const nearbyMissions$ = [...nearbyMissionsPlanIds, ...collisionMissionsPlanIds].map((nearbyMissionPlanId) =>
            this.missionApi.getPublicMissionData(nearbyMissionPlanId).pipe(
                map((plan) => ({
                    route: plan.route,
                    data: plan,
                    isCollision: collisionMissionsPlanIds.includes(plan.id),
                })),
                RxjsUtils.filterFalsy()
            )
        );

        if (!nearbyMissions$.length) {
            context.patchState({
                areOtherMissionsProcessing: false,
                nearbyMissionsRouteData: [],
            });

            return;
        }

        return forkJoin(nearbyMissions$).pipe(
            tap((nearbyMissions) => {
                context.patchState({
                    nearbyMissionsRouteData: nearbyMissions.map(({ route, data, isCollision }: RouteData<PublicMissionPlanData>) => ({
                        route,
                        data,
                        isCollision,
                    })),
                });
            }),
            finalize(() => context.patchState({ areOtherMissionsProcessing: false }))
        );
    }

    private sortAnalysisIssues(issues?: (MissionPlanAnalysisIssueBase & { status: string })[]) {
        issues?.sort((left, right) => {
            if (left.codename !== right.codename) {
                return left.codename.localeCompare(right.codename);
            }

            const leftWeight = ISSUES_STATUS_SORTING_ORDER.findIndex((item) => item === left.status);
            const rightWeight = ISSUES_STATUS_SORTING_ORDER.findIndex((item) => item === right.status);

            if (leftWeight !== rightWeight) {
                return leftWeight - rightWeight;
            }

            return left.translationId.localeCompare(right.translationId);
        });
    }

    @Action(MissionActions.RegisterMission)
    public registerMission(context: StateContext<MissionStateModel>, action: MissionActions.RegisterMission) {
        return this.missionApi.registerMission(action.planId).pipe(
            catchError((error) =>
                of(
                    context.patchState({
                        missionError: error,
                    })
                )
            )
        );
    }

    @Action(MissionActions.CloseSpecificPermitPlan)
    public closeSpecificPermitPlan(context: StateContext<MissionState>, action: MissionActions.CloseSpecificPermitPlan) {
        return this.missionApi.closeSpecificPermitPlan(action.planId).pipe(
            catchError((erorr) => {
                context.patchState({
                    closeSpecificPermitPlan: erorr,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.CleanupMissionWizard)
    public cleanupMissionWizard(context: StateContext<MissionStateModel>) {
        const currentState = context.getState();
        context.setState({
            ...currentState,
            missionError: defaultState.missionError,
            isProcessing: defaultState.isProcessing,
            currentPlanRoute: defaultState.currentPlanRoute,
            capabilities: defaultState.capabilities,
            isAnalysisInProgress: false,
            preferences: defaultState.preferences,
            routeSuggestions: defaultState.routeSuggestions,
            currentPlanDataAndCapabilities: defaultState.currentPlanDataAndCapabilities,
            plan: defaultState.plan,
            itinerary: defaultState.itinerary,
            missionPlanAnalysisStatus: defaultState.missionPlanAnalysisStatus,
            nearbyMissionsRouteData: defaultState.nearbyMissionsRouteData,
            isPansaUtmLinkActive: defaultState.isPansaUtmLinkActive,
        });
    }

    @Action(MissionActions.GetMissionList, { cancelUncompleted: true })
    public async getMissionPlansList(context: StateContext<MissionStateModel>, { query }: MissionActions.GetMissionList) {
        const currentOperatorContext = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContext).pipe(first(FunctionUtils.isTruthy))
        );

        query = {
            ...query,
            textSearch: query.textSearch || null,
            operatorId: currentOperatorContext.id,
            sort: query.sort || DEFAULT_MISSION_LIST_FILTERS_AND_SORTING.sort,
            pageSize: query.pageSize || DEFAULT_MISSION_LIST_FILTERS_AND_SORTING.pageSize,
        };
        context.patchState({ isProcessing: true, missionError: undefined });

        return this.missionApi.getMissionPlanList(query).pipe(
            tap(({ missionList, pagination }) => {
                context.patchState({ isProcessing: false, missionPlanContentList: missionList, pagination });
            }),
            catchError((missionError) => {
                context.patchState({
                    missionError: missionError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.CleanupMissionList)
    public cleanupMissionList(context: StateContext<MissionStateModel>) {
        const currentState = context.getState();
        context.setState({
            ...currentState,
            missionPlanContentList: defaultState.missionPlanContentList,
            pagination: defaultState.pagination,
            missionError: undefined,
        });
    }

    @Action(MissionActions.GetFlightPurposes)
    public getFlightPurposes(context: StateContext<MissionStateModel>) {
        return this.missionApi.getFlightPurposes().pipe(
            tap((purposes) => {
                context.patchState({
                    flightPurposes: purposes,
                });
            }),
            catchError((missionError) => {
                context.patchState({
                    missionError: missionError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.DeleteMissionPlan)
    public deleteMissionPlan(context: StateContext<MissionStateModel>, { id }: MissionActions.DeleteMissionPlan) {
        context.patchState({ isProcessing: true });

        return this.missionApi.deleteMissionPlan(id).pipe(
            tap(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(MissionActions.GetMissionData)
    public getMissionData(context: StateContext<MissionStateModel>, { planId, withEditorOptions }: MissionActions.GetMissionData) {
        context.patchState({ isProcessing: true });

        return this.missionApi.getMissionData(planId, withEditorOptions).pipe(
            tap((data) => this.updateMissionData(context, data)),
            switchMap((data: MissionPlanDataAndCapabilities) =>
                data.plan.route
                    ? this.missionApi.getMissionRoute(data.plan.route?.id).pipe(
                          tap((route) => {
                              context.patchState({
                                  currentPlanRoute: route,
                              });
                          })
                      )
                    : EMPTY
            ),
            catchError((missionError) => {
                context.patchState({
                    missionError: missionError,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.GetPlanData)
    public getPlanData(context: StateContext<MissionStateModel>, { planId }: MissionActions.GetPlanData) {
        context.patchState({ isProcessing: true });

        return this.missionApi.getMissionData(planId, false).pipe(
            tap((data) => {
                context.patchState({
                    currentPlanDataAndCapabilities: data,
                });
            }),
            catchError((missionError) => {
                context.patchState({
                    missionError: missionError,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({ isProcessing: false });
            })
        );
    }

    @Action(MissionActions.CloneAndEditMissionPlan)
    public cloneAndEditMissionPlan(context: StateContext<MissionStateModel>, { sourceId }: MissionActions.CloneAndEditMissionPlan) {
        context.patchState({ isProcessing: true });

        return this.missionApi.cloneMissionPlan(sourceId).pipe(
            tap((planId) => {
                context.patchState({ isProcessing: false });
                this.router.navigate(["/plan/edit", planId]);
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.CancelMission)
    public cancelMission(context: StateContext<MissionStateModel>, { missionId }: MissionActions.CancelMission) {
        context.patchState({ isProcessing: true });

        return this.missionApi.cancelMission(missionId).pipe(
            tap(() => {
                context.patchState({ isProcessing: false });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.GetActivePermitsList)
    public getActivePermitsList(context: StateContext<MissionStateModel>) {
        context.patchState({ isProcessing: true });

        return this.store.select(OperatorContextState.selectedContextId).pipe(
            first(FunctionUtils.isTruthy),
            mergeMap((id) => this.missionApi.getActivePermitsList(id)),
            tap((activePermits) =>
                context.patchState({
                    isProcessing: false,
                    activePermits,
                })
            ),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.UpdateAdditionalInformation)
    public updateAdditionalInformation(
        context: StateContext<MissionStateModel>,
        { planId, information }: MissionActions.UpdateAdditionalInformation
    ) {
        context.patchState({ isProcessing: true });

        return this.missionApi.updateAdditionalInformation(planId, information).pipe(
            tap(() => context.patchState({ missionError: undefined })),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                });

                return EMPTY;
            }),
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            })
        );
    }

    @Action(MissionActions.SetSelectedEditorType)
    public setSelectedEditorType(context: StateContext<MissionStateModel>, { editorType }: MissionActions.SetSelectedEditorType) {
        context.patchState({ selectedEditorType: editorType });
    }

    private updateCapabilities(
        capabilities: MissionCapabilities | undefined,
        updateData: MissionPlanDataAndCapabilities
    ): MissionCapabilities | undefined {
        capabilities = ObjectUtils.cloneDeep(capabilities);
        if (capabilities?.preferences?.capabilities) {
            capabilities.preferences.capabilities = {
                uavSetupId: updateData.plan.capabilities.uavSetup,
                pilotId: updateData.plan.capabilities.pilot,
                missionType: updateData.plan.capabilities.flightType,
                operatorId: updateData.plan.capabilities.operator,
            };
            capabilities.flightPurposes = updateData.flightPurposes?.map((item) => ({
                displayName: item.name,
                description: item.description,
                id: item.id,
                codename: item.codename,
                isCommentRequired: item.commentRequired,
                isLoadWeightRequired: item.loadWeightRequired,
                isEmergency: item.emergency,
            }));
            capabilities.suggestedEditor = updateData.plan.itinerary?.editorType;
            capabilities.availableEditors = updateData.editors?.options;
            capabilities.operationCategories = updateData.operationCategories;
        }

        return capabilities;
    }

    private updateMissionData(context: StateContext<MissionStateModel>, data: MissionPlanDataAndCapabilities) {
        context.patchState({
            isProcessing: false,
            capabilities: this.updateCapabilities(context.getState().capabilities, data),
            plan: {
                id: data.plan.id,
                status: data.plan.status,
                capabilities: {
                    flightType: data.plan.capabilities.flightType,
                    operatorId: data.plan.capabilities.operator,
                    pilotId: data.plan.capabilities.pilot,
                    uavSetupId: data.plan.capabilities.uavSetup,
                    additionalCrew: data.plan.capabilities.additionalCrew,
                },
                operationCategory: data.plan.category,
                flightPurpose: data.plan.flightPurpose,
                information: {
                    name: data.plan.information?.name,
                    description: data.plan.information?.description,
                    notes: data.plan.information?.notes,
                },
                flightStartDate: data.plan.flightStartAtMin ? new Date(data.plan.flightStartAtMin) : undefined,
            },
            isEditable: data.plan.availableActions.editable,
            currentPlanDataAndCapabilities: data,
        });
    }

    @Action(MissionActions.CreateAvailableMissionRoutes)
    public createAvailableMissionRoutes(
        context: StateContext<MissionStateModel>,
        { planId, type }: MissionActions.CreateAvailableMissionRoutes
    ) {
        context.patchState({ isProcessing: true });

        return this.missionApi.createMissionPlanRoutes(planId).pipe(
            switchMap(() =>
                this.missionApi.getMissionRoutes(planId, type).pipe(
                    tap((routeSuggestions) => {
                        context.patchState({
                            routeSuggestions,
                            isProcessing: false,
                        });
                    })
                )
            ),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.GetAvailableMissionRoutes)
    public getAvailableMissionRoutes(context: StateContext<MissionStateModel>, { planId, type }: MissionActions.GetAvailableMissionRoutes) {
        context.patchState({ areAvailableRoutesLoading: true, isProcessing: true });

        return this.missionApi.getMissionRoutes(planId, type).pipe(
            tap((routeSuggestions) => {
                context.patchState({
                    routeSuggestions,
                    areAvailableRoutesLoading: false,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    areAvailableRoutesLoading: false,
                    isProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.SetRoute)
    public setRoute(context: StateContext<MissionStateModel>, { routeId }: MissionActions.SetRoute) {
        const route = context.getState().routeSuggestions?.find((routeEntity) => routeEntity.routeId === routeId);

        if (route) {
            context.patchState({ currentPlanRoute: route });
        }
    }

    @Action(MissionActions.AssignSelectedRoute)
    public assignSelectedRoute(context: StateContext<MissionStateModel>) {
        const { currentPlanRoute, plan } = context.getState();

        if (!currentPlanRoute || !plan) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.missionApi.assignMissionPlanRoute(plan.id, currentPlanRoute.routeId).pipe(
            finalize(() => {
                context.patchState({
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.CreateOrUpdateFormalJustifications)
    public createOrUpdateFormalJustification(
        context: StateContext<MissionStateModel>,
        { formalJustification, planId }: MissionActions.CreateOrUpdateFormalJustifications
    ) {
        context.patchState({ isProcessing: true });

        return this.missionApi.createOrUpdateFormalJustification(formalJustification, planId).pipe(
            switchMap(() => context.dispatch(MissionActions.GetMissionPlanAnalysis)),
            finalize(() => context.patchState({ isProcessing: false })),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(MissionActions.SearchAirspaceElements)
    public searchAirspaceElements(context: StateContext<MissionStateModel>, { options }: MissionActions.SearchAirspaceElements) {
        context.patchState({ isProcessing: true });

        return this.missionApi.searchAirspaceElements(options).pipe(
            tap((elements) => {
                this.store.dispatch(new GeoZonesActions.SetCustomElements(elements));
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.ClearAirspaceElements)
    public clearAirspaceElements() {
        this.store.dispatch(new GeoZonesActions.SetCustomElements());
    }

    @Action(MissionActions.StartMissionStatusUpdateWatch)
    public startMissionStatusUpdateWatch(context: StateContext<MissionStateModel>) {
        this.stopMissionStatusWatchSubscription?.unsubscribe();

        return this.notificationsApiService
            .startIncomingNotificationsWatch<
                MissionPlanningNotificationType.MissionPlanningEvent,
                WebAppMissionPlanningNotification["payload"]
            >(this.store.selectSnapshot(AuthState.userId), [MissionPlanningNotificationType.MissionPlanningEvent])
            .pipe(
                RxjsUtils.filterFalsy(),
                filter(
                    ({ payload: { target } }) =>
                        target.type === MissionPlanningNotificationTargetType.Plan && target.id === context.getState().plan?.id
                ),
                tap(({ payload }) => {
                    context.dispatch([new MissionActions.GetMissionData(payload.target.id, false), MissionActions.GetMissionPlanAnalysis]);
                })
            );
    }

    @Action(MissionActions.StopMissionStatusUpdateWatch)
    public stopMissionStatusUpdateWatch() {
        this.stopMissionStatusWatchSubscription?.unsubscribe();
    }

    @Action(MissionActions.MissionPlanMessageAcknowledgement)
    public missionPlanMessageAcknowledgement(context: StateContext<MissionStateModel>) {
        const { missionPlanAnalysisStatus } = context.getState();

        if (!missionPlanAnalysisStatus?.systemVerificationId) {
            return;
        }
        context.patchState({ isProcessing: true });

        return this.missionApi.acknowledgePlanMessage(missionPlanAnalysisStatus?.systemVerificationId).pipe(
            switchMap(() => context.dispatch(new MissionActions.GetMissionData(missionPlanAnalysisStatus.planId, false))),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(MissionActions.GetPansaUtmLinkStatus)
    public getPansaUtmLinkStatus(context: StateContext<MissionStateModel>, { pilotId }: MissionActions.GetPansaUtmLinkStatus) {
        context.patchState({ isProcessing: true });
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            return EMPTY;
        }

        return this.missionApi.getPansaUtmLinkStatus(pilotId, operatorId).pipe(
            tap((isPansaUtmLinkActive) => {
                context.patchState({
                    isPansaUtmLinkActive,
                    isProcessing: false,
                });
            }),
            catchError((error) => {
                context.patchState({
                    missionError: error,
                    isProcessing: false,
                    isPansaUtmLinkActive: false,
                });

                return EMPTY;
            })
        );
    }
}
