import { MapEntitiesEditorContent } from "@dtm-frontend/shared/map/cesium";
import {
    AdditionalCrewMember,
    AirRiskMitigationsArc,
    AirRiskMitigationsCategory,
    AuthorityAcceptation,
    FormalJustification,
    M1AStatementsKeys,
    M1BStatementsKeys,
    M2StatementsKeys,
    MissionCategory,
    MissionPlanContentActions,
    MissionPlanFlightPurpose,
    MissionPlanInformation,
    MissionPlanOperationCategoryOption,
    MissionPlanResponseBodyCapabilitiesEntity,
    MissionPlanSpecificPermitType,
    MissionPlanStatus,
    MissionProcessingPhase,
    MissionProcessingPhaseExtended,
    MissionType,
    PartialAuthorityAcceptation,
    RobustnessLevel,
} from "@dtm-frontend/shared/mission";
import {
    GeoJSON,
    ItineraryEditorType,
    MissionPlanRoute,
    MissionPlanRouteSection,
    Page,
    PermitType,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { ISO8601TimeDuration } from "@dtm-frontend/shared/utils";
import { Feature as GeoJSONFeature, Polygon } from "@turf/helpers";
import { FailSafe, TacticalRiskMitigationMeasuresCategory } from "../../shared";
import { MissionPlanRouteResponseBody } from "../services/mission-api.converters";

export * from "../../shared/models/mission.models";

export enum MissionErrorType {
    Unknown = "MissionErrorUnknown",
    CannotGetCapabilities = "MissionErrorCannotGetCapabilities",
    CannotCreateMissionPlan = "MissionErrorCannotCreateMissionPlan",
    CannotUpdateMissionPlan = "MissionErrorCannotUpdateMissionPlan",
    CannotRegisterMission = "MissionErrorCannotRegisterMission",
    CannotGetRoute = "MissionErrorCannotGetRoute",
    NotAuthorized = "MissionErrorNotAuthorized",
    Fields = "MissionErrorFieldsError",
    CannotGetMission = "MissionErrorCannotGetMission",
    CannotCloneMission = "MissionErrorCannotCloneMission",
    CannotCancelMission = "MissionErrorCannotCancelMission",
    CannotCloseSoraPlan = "CannotCloseSoraPlan",
    CannotGetPermitDetails = "MissionCannotGetPermitDetails",
}

export interface MissionDataFormCapabilitiesData {
    missionType: MissionType;
    operator: MissionOperator;
    pilot: MissionPilot;
    uavWithSetup: MissionUAVWithSetup;
    additionalCrew: AdditionalCrewMember[];
}

export interface MissionDataFormFlightPurposeData {
    flightPurpose: MissionPlanFlightPurposeOption;
    flightPurposeDescription: string | null;
    loadWeight: number | null;
}

export interface MissionDataFormData {
    activePermits: ActivePermit;
    capabilities: MissionDataFormCapabilitiesData;
    flightPurpose: MissionDataFormFlightPurposeData;
    category: MissionPlanOperationCategoryOption;
}

export enum FlightSpeedType {
    KilometersPerHour = "km/h",
    MetersPerSecond = "m/s",
}

export enum AirspaceCharacteristics {
    Unknown = "UNKNOWN",
    Controlled = "CONTROLLED",
    Uncontrolled = "UNCONTROLLED",
    Both = "BOTH",
}

export enum HeightType {
    AMSL = "AMSL",
    AGL = "AGL",
}

export interface ItineraryEditorMissionZoneRadiusFormData {
    isEnlargedZone: boolean;
    isEnlargedZoneStatementAccepted: boolean | null;
}

export interface ItineraryEditorMissionZoneHeightFormData {
    zoneTopHeight: number | null;
    zoneBottomHeight: number | null;
    zoneHeightType: HeightType;
    isFlightAroundObstacle: boolean;
    isFlightAroundObstacleStatementAccepted: boolean | null;
}

export interface ItineraryEditorMissionPreferencesFormData {
    verticalBuffer: number | null;
    horizontalBuffer: number | null;
    flightLevel: number | null;
    flightLevelType: FlightLevelType;
}

export interface ItineraryEditorMissionSettingsFormData {
    flightSpeed: number;
    flightSpeedType: FlightSpeedType;
    pilotReactionDelaySeconds: number;
}

export type ItineraryEditorMissionParametersFormData = ItineraryEditorMissionPreferencesFormData &
    ItineraryEditorMissionSettingsFormData &
    ItineraryEditorMissionZoneHeightFormData &
    ItineraryEditorMissionZoneRadiusFormData;

export type ItineraryEditorFormData = ItineraryEditorMissionParametersFormData & {
    editorType: ItineraryEditorType;
    date?: Date;
    datetime: Date;
    durationMinutes: number | null;
    dateRangeStart: Date | null;
    dateRangeEnd: Date | null;
    itinerary?: MapEntitiesEditorContent;
    routeGenerationSettings: MissionRouteGenerationSettings;
    soraSettings?: SoraSettings;
};

export enum ArcAirspaceRiskDecreasedType {
    FlightAroundObstacle = "FlightAroundObstacle",
    FlightInSegregatedAirspace = "FlightInSegregatedAirspace",
    FlightLowAgl = "FlightLowAgl",
}

export interface GroundRiskBufferSettings {
    isIndividualBufferSetting?: boolean;
    individualGroundRiskBufferValue?: number | null;
    isFlightsOverAssemblies?: boolean;
    isFlightOverControlledArea?: boolean;
    flightsOverAssembliesDescription?: string | null;
    isFlightOverControlledAreaStatementAccepted?: boolean;
    flightOverControlledAreaDescription?: string | null;
    isArcAirspaceRiskDecreased?: boolean;
    maxOperationalSpace?: number;
    arcAirspaceRiskDecreasedType?: ArcAirspaceRiskDecreasedType | null;
    isFlightAroundObstacleStatementAccepted?: boolean;
    flightAroundObstacleDescription?: string | null;
    isFlightInSegregatedAirspaceStatementAccepted?: boolean;
    flightInSegregatedAirspaceDescription?: string | null;
    isGroupOfPeopleNearOperationArea?: boolean;
    groupOfPeopleNearOperationAreaDescription?: string | null;
    isFlightLowAgl?: boolean;
}

export interface MissionPlanningCapabilitiesPreferences {
    missionType?: MissionType;
    operatorId?: string;
    pilotId?: string;
    uavSetupId?: string;
    additionalCrew?: AdditionalCrewMember[];
}

export interface MissionPlanningPreferences {
    initialViewbox?: GeoJSON;
    capabilities?: MissionPlanningCapabilitiesPreferences;
    flightPurpose?: MissionPlanFlightPurpose;
    operationCategory?: MissionPlanOperationCategoryOption;
    information?: MissionPlanInformation;
}

export interface MissionCapabilities {
    operator?: MissionOperator;
    flightPurposes?: MissionPlanFlightPurposeOption[];
    operationCategories?: MissionPlanOperationCategoryOption[];
    suggestedEditor?: ItineraryEditorType;
    availableEditors?: ItineraryEditorType[];
    preferences?: MissionPlanningPreferences;
    additionalCrewMembersOptions?: string[];
}

export interface MissionCapabilitiesResponseBodyUAVEntity {
    id: string;
    name: string;
    setups: MissionCapabilitiesResponseBodyUAVEntitySetup[];
}

interface MissionCapabilitiesResponseBodyUAVEntitySetup {
    id: string;
    name: string;
    primary: boolean;
    technicalSpecification: {
        maxFlightSpeed: number;
        maxDroneWidth: number;
        flightSpeedLimit: number;
        failSafe: FailSafe[];
        geofencing: boolean;
        geocage: boolean;
        takeOffMass: number;
    };
}

export interface MissionOperator {
    id: string;
    displayName: string;
    pilots: MissionPilot[];
    uavs: MissionUAV[];
}

export interface MissionUAVWithSetup {
    id: string;
    displayName: string;
    setup: MissionUAVSetup;
}

export interface MissionPilot {
    id: string;
    displayName: string;
    isDisabled?: boolean;
}

export interface MissionUAV {
    id: string;
    displayName: string;
    setups: MissionUAVSetup[];
}

export interface MissionUAVSetup {
    id: string;
    displayName: string;
    isPrimary: boolean;
    technicalSpecification: {
        maxFlightSpeed: number;
        maxDroneWidth: number;
        flightSpeedLimit: number;
        failSafe: FailSafe[];
        hasGeofencing: boolean;
        hasGeocage: boolean;
        takeOffMass: number;
    };
}

export interface MissionPlanCapabilities {
    flightType: MissionType;
    operatorId: string;
    pilotId: string;
    uavSetupId: string;
    additionalCrew: AdditionalCrewMember[];
}

export interface MissionPlanFlightPurposeOption {
    id: string;
    displayName: string;
    description: string;
    codename: string;
    isLoadWeightRequired: boolean;
    isCommentRequired: boolean;
    isEmergency: boolean;
}

export interface MissionPlanOperationCategory {
    type: MissionCategory;
    specificPermit: MissionPlanSpecificPermitType;
    scenarioName: string;
    specificCaaPermitId: string;
    specificLucSkipSora: boolean;
}

export interface MissionPlan {
    id: string;
    status: MissionPlanStatus;
    capabilities: MissionPlanCapabilities;
    operationCategory: MissionPlanOperationCategoryOption | undefined;
    flightPurpose: MissionPlanFlightPurpose | undefined;
    information: MissionPlanInformation;
    flightStartDate?: Date;
}

export interface EditorConstraints {
    height: number;
    durationMinutes: number;
    size: number;
    startDelay: number;
    horizontalNavigationAccuracy: number;
    verticalNavigationAccuracy: number;
    runwayHorizontalNavigationAccuracy: number;
    runwayVerticalNavigationAccuracy: number;
    largeZoneRadius: number;
    regularZoneRadius: number;
    distinctShapes: number;
    aroundObstacleHeight: number;
    regularHeight: number;
}

export interface MissionPlanItineraryConstraints {
    default: EditorConstraints;
    min: EditorConstraints;
    max: EditorConstraints;
}

export interface ZoneHeightLimits {
    min: number;
    max: number;
}

export type MissionPlanItineraryWithoutConstraints =
    | { type: ItineraryEditorType.None }
    | MissionPlanStandardItinerary
    | MissionPlanCustomItinerary
    | MissionPlanAssistedItinerary;

export type MissionPlanItinerary = {
    constraints?: MissionPlanItineraryConstraints;
    soraSettings?: SoraSettings;
} & MissionPlanItineraryWithoutConstraints;

export interface MissionPlanItineraryWithRoute {
    route?: MissionPlanRoute;
    itinerary: MissionPlanItinerary;
}

export type MissionPlanStandardItinerary = {
    type: ItineraryEditorType.Standard;
} & MissionPlanStandardItineraryEntity;

export type MissionPlanCustomItinerary = {
    type: ItineraryEditorType.Custom;
} & MissionPlanCustomItineraryEntity;

export type MissionPlanAssistedItinerary = {
    type: ItineraryEditorType.Assisted;
} & MissionPlanAssistedItineraryEntity;

export interface MissionPlanAndCapabilities {
    plan: MissionPlan;
    flightPurposes: MissionPlanFlightPurposeOption[];
    operationCategories: MissionPlanOperationCategoryOption[];
    editors: {
        options: ItineraryEditorType[];
        suggested: ItineraryEditorType;
    };
}

export interface MissionCapabilitiesResponseBodyPreferencesEntity {
    initialViewBox: GeoJSON;
    capabilities: null | MissionPlanResponseBodyCapabilitiesEntity;
    flightPurpose: null | MissionPlanFlightPurpose;
    operationCategory: null | MissionPlanOperationCategory;
    information: null | {
        name: string;
        description: string;
    };
}

export interface MissionPlanUpdateFlightPurposeRequestEntity {
    id: string;
    loadWeight?: number;
    comment?: string;
}

export interface MissionPlanUpdateOperationCategoryRequestEntity {
    type: MissionCategory;
    specificPermit?: MissionPlanSpecificPermitType;
    scenarioName?: string;
    specificLucSkipSora?: boolean;
    specificCaaPermitId?: string;
}

export interface MissionPlanCapabilitiesResponseBodyPlanEntity {
    id: string;
    status: MissionPlanStatus;
    capabilities: MissionPlanResponseBodyCapabilitiesEntity;
    flightPurpose?: MissionPlanFlightPurpose;
    category?: MissionPlanOperationCategory;
    information?: {
        name: string;
        description: string;
        notes?: string;
    };
    itinerary?: {
        id: string;
        editorType: ItineraryEditorType;
    };
    route?: {
        id: string;
        created: string;
        modified: string;
        dtmNames: string[];
        dtmOnly: boolean;
        pathBased: boolean;
    };
    mission?: {
        id: string;
        routeId: string;
        status: MissionPlanStatus;
        startAt: string;
        rejectionJustification?: string;
        rejectedAt?: string;
        timeOverrideJustification?: string;
    };
    uav: {
        serialNumbers: string[];
        name: string;
        model: string;
        manufacturer: string;
    };
}

export interface MissionPlanCapabilitiesResponseBodyCategoryEntity {
    id: string;
    name: string;
    type: MissionCategory;
    disabled: boolean;
    issues: {
        id: string;
        args: Record<string, string>;
    }[];
}

export interface MissionPlanItineraryWithRouteResponseBody {
    itinerary?: MissionPlanItineraryEntity;
    options?: MissionPlanItineraryOptions;
    route?: MissionPlanRouteResponseBody;
}

interface MissionPlanItineraryOptionsStandardEditorLimits {
    max: number;
    min: number;
    suggested: number;
}

export interface MissionPlanItineraryOptions {
    assisted: {
        suggested: boolean;
    };
    custom: {
        suggested: boolean;
    };
    standard: {
        suggested: boolean;
        limits: {
            largeZoneRadius: MissionPlanItineraryOptionsStandardEditorLimits;
            regularZoneRadius: MissionPlanItineraryOptionsStandardEditorLimits;
            aroundObstacleHeight: MissionPlanItineraryOptionsStandardEditorLimits;
            regularHeight: MissionPlanItineraryOptionsStandardEditorLimits;
        };
    };
    navigationAccuracies: {
        horizontal: MissionPlanItineraryOptionLimitEntity;
        vertical: MissionPlanItineraryOptionLimitEntity;
        runwayHorizontal: number;
        runwayVertical: number;
    };
    runway: {
        runwayDelay: number;
        takeoffDelay: number;
    };
    limits: {
        height: MissionPlanItineraryOptionLimitEntity;
        radius: MissionPlanItineraryOptionLimitEntity;
        startDelay: MissionPlanItineraryOptionLimitEntity;
        time: MissionPlanItineraryOptionLimitEntity;
        distinctAreas: MissionPlanItineraryOptionLimitEntity;
    };
}

export interface MissionPlanItineraryOptionLimitEntity {
    suggested: number;
    min: number;
    max: number;
}

export interface MissionPlanItineraryEntity {
    planId: string;
    version: string;
    created: string;
    modified: string;
    input?: MissionPlanStandardItineraryEntity | MissionPlanCustomItineraryEntity | MissionPlanAssistedItineraryEntity;
}

export interface MissionPlanItineraryConstraintsEntity {
    standardEditor?: {
        defaultLimits: MissionPlanItineraryConstraintsEntityLimit;
        maxLimits: MissionPlanItineraryConstraintsEntityLimit;
    };
    customEditor?: {
        defaultLimits: MissionPlanItineraryConstraintsEntityLimit;
        maxLimits: MissionPlanItineraryConstraintsEntityLimit;
    };
}

export interface MissionPlanItineraryConstraintsEntityLimit {
    height: number;
    time: number;
    size: number;
}

export enum FlightLevelType {
    MinimumHeight = "MIN_HEIGHT",
    FixedHeight = "FIXED_HEIGHT",
    FixedAltitude = "FIXED_ALTITUDE",
}

export interface MissionPlanCustomItineraryEntity {
    "@type": MissionPlanItineraryEntityType.Custom;
    limits: {
        time?: ISO8601TimeDuration;
        radius?: number;
        height?: number;
        speed: number;
    };
    points: WaypointEntity[];
    flight: {
        cruisingLevel: {
            value: number;
            type: FlightLevelType;
        };
        cruisingSpeed: number;
        pilotReactionDelay: number;
    };
    navigationAccuracy?: NavigationAccuracyEntity;
    soraSettings?: SoraSettings;
}

export interface MissionPlanStandardItineraryEntity {
    "@type": MissionPlanItineraryEntityType.Standard;
    limits?: {
        speed: number;
    };
    estimatedArriveAt: string;
    time: ISO8601TimeDuration;
    elements: {
        estimatedArriveAt?: string;
        time?: ISO8601TimeDuration;
        flightZone: WaypointFlightZone;
    }[];
    aroundObstacle: boolean;
    enlargedZoneRadius: boolean;
    pilotReactionDelay: number;
    navigationAccuracy?: NavigationAccuracyEntity;
    soraSettings?: SoraSettings;
}

export interface MissionPlanAssistedItineraryEntity {
    "@type": MissionPlanItineraryEntityType.Assisted;
    limits?: {
        time: ISO8601TimeDuration;
        radius?: number;
        height?: number;
        speed?: number;
    };
    origin: WaypointEntity;
    destination: WaypointEntity;
    flight: {
        cruisingLevel: {
            value: number;
            type: FlightLevelType;
        };
        cruisingSpeed: number;
        pilotReactionDelay: number;
    };
    navigationAccuracy?: NavigationAccuracyEntity;
    soraSettings?: SoraSettings;
}

export interface NavigationAccuracyEntity {
    horizontal: number;
    vertical: number;
}

export interface WaypointEntity {
    position: {
        lon: number;
        lat: number;
        h?: number;
        alt?: number;
    };
    estimatedArriveAtMin?: string;
    flightZone?: WaypointFlightZone;
    beyondPath: boolean;
    runway: boolean;
    navigationAccuracy?: NavigationAccuracyEntity;
    stopoverMin?: ISO8601TimeDuration;
    stopoverMax?: ISO8601TimeDuration;
    segment?: {
        heightLimit?: number;
        distance: number;
        duration: {
            min: ISO8601TimeDuration;
            max: ISO8601TimeDuration;
        };
        speed: {
            min: number;
            max: number;
        };
    };
}

export interface Elevation {
    latitude: number;
    longitude: number;
    startOffset: number;
    value: number;
}

export enum MissionRouteGenerationSettings {
    Length = "LENGTH",
}

export enum MissionRoutePreference {
    Length = "LENGTH",
    Safety = "SAFETY",
}

export interface WaypointFlightZone {
    name?: string;
    area?: GeoJSONFeature;
    minH: number;
    maxH?: number;
}

export const enum MissionPlanItineraryEntityType {
    Standard = "application/vnd.pansa.mission-planner.itinerary-standard.api.v1+json",
    Custom = "application/vnd.pansa.mission-planner.itinerary-custom.api.v1+json",
    Assisted = "application/vnd.pansa.mission-planner.itinerary-assisted.api.v1+json",
}

export interface MissionFieldsError {
    type: MissionErrorType.Fields;
    fields: {
        code: string;
        fieldName: string;
        fieldValue: string;
        args?: Record<string, unknown>;
    }[];
}

export type MissionError =
    | MissionFieldsError
    | {
          type: MissionErrorType;
      };

export interface MissionPlanContent {
    id: string;
    missionId: string;
    thumbnail: string;
    created: Date;
    modified: Date;
    name: string;
    status: MissionPlanStatus;
    phase: MissionProcessingPhaseExtended;
    availableActions: MissionPlanContentActions;
    specificCaaPermitId: string;
    permitBusinessId: string;
    uavId: string;
    uavName?: string;
    uavSetupName: string;
    pilotId: string;
    pilotName?: string;
    operatorId: string;
    operatorName?: string;
    missionSubmittedAt: Date;
    flightStartAtMax: Date;
    flightStartAtMin: Date;
    flightFinishAtMin: Date;
    flightFinishAtMax: Date;
    flightType: MissionType;
    operationCategoryType: MissionCategory;
    flightPurpose: string;
    flightPurposeComment: string;
    soraSail: number;
    description: string;
    missionStatus: MissionStatus | null;
    remarks?: {
        authorityAcceptation: AuthorityAcceptation;
        justification?: FormalJustification;
        partialAcceptations?: PartialAuthorityAcceptation[];
    };
}

export interface MissionPlanList {
    missionList: MissionPlanContent[];
    pagination: Page;
}

export interface MissionPlanListFilters {
    flightType?: MissionType[] | MissionType | null;
    textSearch?: string | null;
    operatorId?: string;
    processingPhase?: MissionProcessingPhase | null;
    planStatus?: MissionPlanStatus | MissionPlanStatus[] | null;
    flightDateFrom?: Date | null;
    flightDateTo?: Date | null;
    flightPurpose?: string | string[] | null;
}

export interface MissionPlanListQuery extends MissionPlanListFilters {
    pageSize?: number;
    page?: number;
    sort?: SortOption | null;
    groupBy?: MissionGroupByPeriod | null;
}

export type SortOption = `${keyof MissionPlanContent},${"asc" | "desc"}`;

export enum MissionGroupByPeriod {
    Day = "DAY",
    Week = "WEEK",
    Month = "MONTH",
    Year = "YEAR",
}

export enum MissionStatus {
    Accepted = "ACCEPTED",
    Canceled = "CANCELED",
    Activated = "ACTIVATED",
    Rejected = "REJECTED",
    Finished = "FINISHED",
}

export type ActivePermitPilot = ActivePermitUav;
export interface ActivePermitUav {
    isDisabled: boolean;
    id: string;
}

export interface ActivePermit {
    businessId: string;
    flightPurposes: string;
    flightType: MissionType;
    id: string;
    name: string;
    operatorId: string;
    type: PermitType;
    uavSerialNumbers: string[];
    validityPeriodFinish: Date;
    validityPeriodStart: Date;
    operator: {
        id: string;
        name: string;
        uavs: ActivePermitUav[];
        pilots: ActivePermitPilot[];
    };
    location?: PermitLocation;
}

export interface PermitLocation {
    type: PermitLocationType;
    kmlFileId?: string;
    kmlFileName?: string;
    dtmDesignator?: string;
}

export enum PermitLocationType {
    Dtm = "DTM",
    Kml = "KML",
}

export interface PermitLocationData {
    kmlFile?: Blob;
    zoneGeometry?: Polygon;
}

export interface AdditionalInformationRequestPayload {
    information: MissionPlanInformation;
}

export interface FormalJustificationResponseBody {
    requestForPriority?: boolean;
    reason?: string | null;
    attachmentIds?: string[];
    attachments?: {
        contentType?: string;
        createdAt: Date;
        fileId?: string;
        name?: string;
    }[];
}

export enum AuthorityType {
    Dtm = "DTM",
    Utm = "UTM",
    Caa = "CAA",
    Dss = "DSS",
}

export interface FormalJustificationRequestPayload {
    authorityType: AuthorityType;
    formalJustification?: {
        requestForPriority: boolean;
        reason: string | null;
        attachmentIds: string[];
    } | null;
}

export const MAX_RADIUS_WITH_UAV_OBSERVER = 2000;
export const ENLARGED_RADIUS_WITH_STATEMENT_ACCEPTED = 1000;
export const NO_LIMIT_RADIUS = 40000000;

export interface SoraSettings {
    customGroundRiskBuffer?: number;
    peopleAssemblyComment?: string;
    controlledGroundAreaComment?: string;
    flightAroundObstacleComment?: string;
    flightSegregatedAirspaceComment?: string;
    flightLowHeightComment?: string;
    isArcAirspaceRiskDecreased?: boolean;
    maxOperationalSpace?: number;
    adjacentPeopleAssemblyComment?: string;
    riskMitigations?: {
        m1A?: RiskMitigationM1A;
        m1B?: RiskMitigationM1B;
        m2?: RiskMitigationM2;
    };
    airRiskMitigations?: AirRiskMitigations;
    tmpr?: TacticalMitigationPerformanceRequirements;
}

export interface RiskMitigationM1A {
    robustnessLevel: RobustnessLevel;
    userStatements?: string[];
    lowerPeopleDensity?: LowerMitigationLevelEntity<M1AStatementsKeys>;
    bystandersShielded?: LowerMitigationLevelEntity<M1AStatementsKeys>;
}

export interface RiskMitigationM1B {
    robustnessLevel: RobustnessLevel.Low;
    userStatements?: M1BStatementsKeys[];
    abnormalSituationAlert?: LowerMitigationLevelEntity<M1BStatementsKeys>;
    abnormalSituationCommand?: LowerMitigationLevelEntity<M1BStatementsKeys>;
    bystandersSafeDistance?: LowerMitigationLevelEntity<M1BStatementsKeys>;
}

export interface RiskMitigationM2 {
    robustnessLevel: RobustnessLevel;
    parachute?: LowerMitigationLevelEntity<M2StatementsKeys>;
    criticalAreaShielded?: LowerMitigationLevelEntity<M2StatementsKeys>;
    other?: LowerMitigationLevelEntity<M2StatementsKeys>;
}

export interface AirRiskMitigations {
    mitigatingMeasures: Array<{
        category: AirRiskMitigationsCategory;
        arc: AirRiskMitigationsArc;
        methods: Array<{
            title: string;
            details: string;
        }>;
        userStatements: string[];
    }>;
}

export interface TacticalMitigationPerformanceRequirements {
    [TacticalRiskMitigationMeasuresCategory.DETECT]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.DECIDE]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.COMMAND]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.EXECUTE]: { comment: string };
    [TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP]: { comment: string };
}

interface LowerMitigationLevelEntity<T extends string> {
    userStatements: T[];
    comment?: string;
    attachments?: {
        fileId: string;
        name: string;
        contentType?: string;
        length?: number;
    }[];
}

export interface WaypointWithSection {
    waypoint: Waypoint;
    parentSection: MissionPlanRouteSection;
}

export interface PublicMissionPlanCapabilities {
    flightType: MissionType;
    uavName: string;
    uavSetupName: string;
}

export interface PublicMissionPlanRoute {
    dtmNames?: string[];
    isPathBased?: boolean;
    isDtmOnly?: boolean;
    estimatedDistance?: number;
    routeId: string;
    sections: MissionPlanRouteSection[];
}

export interface PublicMissionPlanData {
    id: string;
    missionId?: string;
    capabilities?: PublicMissionPlanCapabilities;
    flightStartAtMin?: Date;
    flightFinishAtMax?: Date;
    route: PublicMissionPlanRoute;
}

export interface RiskMitigationPopoverKeys {
    arcB: string;
    arcC?: string;
}
