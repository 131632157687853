<dtm-ui-loader-container *ngrxLet="{ isProcessing: isProcessing$, missions: missions$ } as vm" [isShown]="vm.isProcessing">
    <dtm-ui-responsive-table *ngIf="vm.missions | ngrxPush as missions" [data]="missions">
        <table mat-table multiTemplateDataRows desktopTable [dataSource]="missions" class="dtm-table">
            <ng-container matColumnDef="pilot">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibDashboard.missionsList.pilotHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let mission">
                    <dtm-ui-avatar [sourceUrl]="mission.pilotAvatarUrl"></dtm-ui-avatar>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibDashboard.missionsList.statusHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let mission">
                    <dtm-mission-mission-status-badge [status]="mission.phase"></dtm-mission-mission-status-badge>
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibDashboard.missionsList.dateHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let mission">{{ mission.flightStartAt | localizeFriendlyDate }}</td>
            </ng-container>

            <ng-container matColumnDef="hour">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibDashboard.missionsList.hourHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let mission">
                    <ng-container
                        *ngIf="mission.flightStartAt && mission.flightFinishAt"
                        [ngTemplateOutlet]="hourTemplate"
                        [ngTemplateOutletContext]="{ $implicit: mission }"
                    ></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="uav">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibDashboard.missionsList.uavHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let mission">
                    <ng-container [ngTemplateOutlet]="uavTemplate" [ngTemplateOutletContext]="{ $implicit: mission }"></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibDashboard.missionsList.missionNameHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let mission">
                    <span class="mission-name">{{ mission.name }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibDashboard.missionsList.actionsHeader" | transloco }}
                </th>
                <td mat-cell *matCellDef="let mission">
                    <button
                        type="button"
                        [matMenuTriggerFor]="rowActionsMenu"
                        [matMenuTriggerData]="{ $implicit: mission }"
                        (click)="$event.stopPropagation()"
                        class="button-icon"
                        [attr.aria-label]="'dtmUi.actionButtonAriaLabel' | transloco"
                    >
                        <dtm-ui-icon name="more"></dtm-ui-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <dtm-ui-error *ngIf="error$ | ngrxPush; else emptyListTemplate" [mode]="ErrorMode.Secondary"> </dtm-ui-error>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr
                mat-footer-row
                *matFooterRowDef="['noResults']"
                [class.hide-footer]="vm.missions.length || vm.isProcessing"
                class="no-results-row"
            ></tr>
        </table>
        <div *dtmUiMobileTable="let row">
            <div class="mobile-table-actions-row">
                <dtm-ui-label-value [label]="'dtmWebAppLibDashboard.missionsList.pilotHeader' | transloco">
                    <dtm-ui-avatar [sourceUrl]="row.pilotAvatarUrl"></dtm-ui-avatar>
                </dtm-ui-label-value>
                <button
                    type="button"
                    [matMenuTriggerFor]="rowActionsMenu"
                    [matMenuTriggerData]="{ $implicit: row }"
                    (click)="$event.stopPropagation()"
                    class="button-icon"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </div>
            <div class="mobile-table-content">
                <dtm-ui-label-value [label]="'dtmWebAppLibDashboard.missionsList.statusHeader' | transloco">
                    <dtm-mission-mission-status-badge [status]="row.phase"></dtm-mission-mission-status-badge>
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmWebAppLibDashboard.missionsList.dateHeader' | transloco">
                    <span *ngIf="row.flightStartAt; else missingDataTemplate">{{ row.flightStartAt | localizeFriendlyDate }}</span>
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmWebAppLibDashboard.missionsList.hourHeader' | transloco">
                    <ng-container
                        *ngIf="row.flightStartAt && row.flightFinishAt; else missingDataTemplate"
                        [ngTemplateOutlet]="hourTemplate"
                        [ngTemplateOutletContext]="{ $implicit: row }"
                    ></ng-container>
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmWebAppLibDashboard.missionsList.uavHeader' | transloco">
                    <ng-container
                        *ngIf="row.uavName; else missingDataTemplate"
                        [ngTemplateOutlet]="uavTemplate"
                        [ngTemplateOutletContext]="{ $implicit: row }"
                    ></ng-container>
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmWebAppLibDashboard.missionsList.missionNameHeader' | transloco">
                    <span *ngIf="row.name; else missingDataTemplate">{{ row.name }}</span>
                </dtm-ui-label-value>
            </div>
        </div>
        <ng-container mobileTableNoData [ngTemplateOutlet]="emptyListTemplate"></ng-container>
    </dtm-ui-responsive-table>
</dtm-ui-loader-container>

<ng-template #emptyListTemplate>
    <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
        <ng-container titleSlot>
            {{ "dtmWebAppLibDashboard.missionsList.emptyStateHeader" | transloco }}
        </ng-container>
        <img imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
    </dtm-ui-empty-state>
</ng-template>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-mission>
        <button mat-menu-item type="button" [routerLink]="['../plan/list', mission.id]">
            <dtm-ui-icon name="information"></dtm-ui-icon>
            {{ "dtmWebAppLibDashboard.missionsList.missionDetailsLabel" | transloco }}
        </button>
    </ng-template>
</mat-menu>

<ng-template #hourTemplate let-mission>
    <div>
        {{ mission.flightStartAt | localizeDate : { timeStyle: "short" } }}
        -
        {{ mission.flightFinishAt | localizeDate : { timeStyle: "short" } }}
    </div>
    <p class="sub-value">
        <dtm-ui-time-diff-display [firstDate]="mission.flightStartAt" [secondDate]="mission.flightFinishAt"></dtm-ui-time-diff-display>
    </p>
</ng-template>

<ng-template #uavTemplate let-mission>
    <div>{{ mission.uavName }}</div>
    <div class="sub-value">{{ mission.uavSetupName }}</div>
</ng-template>

<ng-template #missingDataTemplate>
    <span class="no-data">{{ "dtmWebAppLibDashboard.missionsList.missingDataLabel" | transloco }}</span>
</ng-template>
