<dtm-ui-expandable-panel
    [hasHeaderSeparator]="false"
    [isDisabled]="!areNotesEnabledControl.value"
    [isExpanded]="areNotesEnabledControl.value"
>
    <h2 class="title" headerSlot>
        <dtm-ui-icon name="draft"></dtm-ui-icon>
        {{ "dtmWebAppLibShared.personalNotes.headerLabel" | transloco }}
        <button
            type="button"
            class="button-secondary action-button"
            (click)="$event.stopPropagation(); areNotesEnabledControl.setValue(true)"
            *ngIf="!areNotesEnabledControl.value"
        >
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibShared.personalNotes.addButtonLabel" | transloco }}
        </button>
    </h2>
    <form [formGroup]="notesFormGroup">
        <dtm-ui-textarea-field [maxLength]="MAX_NOTES_LENGTH">
            <label>{{ "dtmWebAppLibShared.personalNotes.notesLabel" | transloco }}</label>
            <textarea matInput [formControl]="notesControl"></textarea>
            <div class="field-error" *dtmUiFieldHasError="notesControl; name: 'maxlength'; error as error">
                {{
                    "dtmWebAppLibShared.maxLengthErrorHint"
                        | transloco
                            : {
                                  maxLength: error.requiredLength,
                                  exceedValue: error.actualLength - error.requiredLength
                              }
                }}
            </div>
        </dtm-ui-textarea-field>
    </form>
</dtm-ui-expandable-panel>
