import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";
import { TimeOfDayInfo, TimeOfTheDay } from "../../../services/specific-permit-application.models";

interface TimeOfTheDayForm {
    timeOfDay: FormControl<TimeOfTheDay | null>;
    timeFrom?: FormControl<Date | null>;
    timeTo?: FormControl<Date | null>;
    isNightFlightStatement?: FormControl<boolean>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-time-of-the-day-control",
    templateUrl: "./time-of-the-day-control.component.html",
    styleUrls: ["./time-of-the-day-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeOfTheDayControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeOfTheDayControlComponent),
            multi: true,
        },
    ],
})
export class TimeOfTheDayControlComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() public set timeOfDay(value: TimeOfDayInfo | null) {
        if (!this.validate()) {
            this.propagateChange(value);
        }

        this.onValidationChange();
    }
    protected timeFromControl = new FormControl<Date | null>(null, Validators.required);
    protected timeToControl = new FormControl<Date | null>(null, Validators.required);
    protected isNightFlightStatementControl = new FormControl<boolean>(false, { nonNullable: true });
    private readonly isNightStatementValidator = Validators.requiredTrue;

    protected readonly timeOfDayForm = new FormGroup<TimeOfTheDayForm>({
        timeOfDay: new FormControl<TimeOfTheDay | null>(null, { validators: Validators.required }),
    });
    protected readonly TimeOfTheDay = TimeOfTheDay;

    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: TimeOfDayInfo | null) => void = () => {};

    public ngOnInit() {
        this.timeOfDayForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe((value) => {
            this.timeOfDay = value;
        });
    }

    public writeValue(value: TimeOfDayInfo | null): void {
        if (value) {
            this.setTimeOfTheDay(value.timeOfDay);
            this.timeOfDayForm.patchValue(value);

            return;
        }
        this.timeOfDayForm.reset();
    }

    public registerOnChange(fn: (value: TimeOfDayInfo | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {}

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        return this.timeOfDayForm.valid ? null : { timeOfDayInvalid: true };
    }

    protected setTimeOfTheDay(timeOfDay: TimeOfTheDay): void {
        this.timeOfDayForm.controls.timeOfDay.setValue(timeOfDay);

        switch (timeOfDay) {
            case TimeOfTheDay.Day:
                this.removeTimeControls();
                this.removeIsNightFlightStatementControl();
                break;

            case TimeOfTheDay.Night:
                this.addIsNightFlightStatementControl(true);
                this.removeTimeControls();
                break;
            case TimeOfTheDay.DayAndNight:
                this.addIsNightFlightStatementControl(false);
                if (!this.timeOfDayForm.controls.timeTo && !this.timeOfDayForm.controls.timeFrom) {
                    this.timeOfDayForm.addControl("timeFrom", this.timeFromControl);
                    this.timeOfDayForm.addControl("timeTo", this.timeToControl);
                }
                break;
        }
    }

    protected keepOrder(): number {
        return 0;
    }

    private removeTimeControls() {
        this.timeFromControl.reset();
        this.timeOfDayForm.removeControl("timeFrom");
        this.timeToControl.reset();
        this.timeOfDayForm.removeControl("timeTo");
    }

    private removeIsNightFlightStatementControl() {
        if (!this.timeOfDayForm.controls.isNightFlightStatement) {
            return;
        }
        this.timeOfDayForm.controls.isNightFlightStatement.reset();
        this.timeOfDayForm.removeControl("isNightFlightStatement");
        this.isNightFlightStatementControl.removeValidators(this.isNightStatementValidator);
    }

    private addIsNightFlightStatementControl(isRequired: boolean) {
        if (!isRequired) {
            this.isNightFlightStatementControl.removeValidators(this.isNightStatementValidator);
        } else {
            this.isNightFlightStatementControl.setValidators(this.isNightStatementValidator);
        }

        if (this.timeOfDayForm.controls.isNightFlightStatement) {
            return;
        }

        this.isNightFlightStatementControl.reset();
        this.timeOfDayForm.addControl("isNightFlightStatement", this.isNightFlightStatementControl);
    }
}
