<ng-container
    *ngrxLet="{
        routeOutlet: routeOutlet$,
        isSidebarCollapsed: isSidebarCollapsed$,
        isRequiredToAcceptTermsOfService: isRequiredToAcceptTermsOfService$
    } as vm"
>
    <div *ngIf="vm.routeOutlet === GlobalOutlets.Default" class="main-layout" [class.sidebar-collapsed]="vm.isSidebarCollapsed">
        <div class="sidebar-overlay" (click)="toggleSidebar()"></div>
        <aside class="sidebar">
            <button
                type="button"
                class="sidebar-switcher"
                (click)="toggleSidebar()"
                *ngIf="!vm.isRequiredToAcceptTermsOfService"
                [attr.aria-label]="'webApp.mobileMenuStateAriaLabel' | transloco : { isMenuExpanded: !vm.isSidebarCollapsed }"
                [attr.aria-expanded]="!vm.isSidebarCollapsed"
            >
                <dtm-ui-icon [name]="vm.isSidebarCollapsed ? 'menu-unfold' : 'menu-fold'"></dtm-ui-icon>
            </button>
            <div class="logo">
                <a routerLink="/" (click)="collapseMenu()">
                    <img
                        [src]="vm.isSidebarCollapsed ? 'assets/images/logo-collapsed.svg' : 'assets/images/logo.svg'"
                        [alt]="'webApp.logoLinkAltText' | transloco"
                    />
                </a>
            </div>
            <nav class="menu">
                <dtm-web-app-menu *ngIf="!vm.isRequiredToAcceptTermsOfService"></dtm-web-app-menu>
            </nav>
        </aside>
        <header class="header">
            <div class="smartphone-header">
                <button
                    *ngIf="!vm.isRequiredToAcceptTermsOfService"
                    type="button"
                    class="sidebar-switcher"
                    (click)="toggleSidebar()"
                    [attr.aria-label]="'webApp.mobileMenuStateAriaLabel' | transloco : { isMenuExpanded: !vm.isSidebarCollapsed }"
                    [attr.aria-expanded]="!vm.isSidebarCollapsed"
                >
                    <dtm-ui-icon name="menu"></dtm-ui-icon>
                </button>
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo.svg" [alt]="'webApp.logoLinkAltText' | transloco" /></a>
                </div>
            </div>
            <dtm-web-app-header></dtm-web-app-header>
        </header>
        <main
            class="content"
            dtmUiDisplayVersion
            [class]="className$ | ngrxPush"
            *ngIf="!vm.isRequiredToAcceptTermsOfService; else acceptTermsTemplate"
        >
            <h1 class="route-title">{{ routeTitle$ | ngrxPush }}</h1>
            <dtm-web-app-lazy-router-outlet class="router-outlet"></dtm-web-app-lazy-router-outlet>
        </main>
    </div>
    <div *ngIf="vm.routeOutlet === GlobalOutlets.Public" class="public-layout">
        <router-outlet name="public"></router-outlet>
    </div>
</ng-container>

<ng-template #acceptTermsTemplate>
    <div class="content">
        <dtm-web-app-lib-terms-of-service-acceptance
            [isProcessing]="isProcessing$ | ngrxPush"
            (termsAccept)="acceptTerms()"
            (logout)="logout()"
        ></dtm-web-app-lib-terms-of-service-acceptance>
    </div>
</ng-template>
