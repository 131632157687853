import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ForeignOperatorVerificationDetails, PilotRegistrationCapabilities } from "../../shared/models";
import { PILOT_REGISTRATION_ENDPOINTS, PilotRegistrationEndpoints } from "../pilot-registration.tokens";
import { PilotRegistrationFormValue, RegisteredPilot } from "./models";
import {
    GetPilotRegistrationCapabilitiesResponseBody,
    convertHttpErrorResponseToForeignOperatorVerificationError,
    transformGetPilotRegistrationCapabilitiesResponseBodyToPilotRegistrationCapabilities,
    transformPilotRegistrationToOPilotRegistrationBody,
    transformPilotRegistrationsErrorResponse,
} from "./pilot-registration-api.converters";

@Injectable({
    providedIn: "root",
})
export class PilotRegistrationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(PILOT_REGISTRATION_ENDPOINTS) private readonly endpoints: PilotRegistrationEndpoints
    ) {}

    public getPilotRegistrationCapabilities(userId: string): Observable<PilotRegistrationCapabilities> {
        return this.httpClient
            .get<GetPilotRegistrationCapabilitiesResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getPilotRegistrationCapabilities, { userId })
            )
            .pipe(
                map((response) => transformGetPilotRegistrationCapabilitiesResponseBodyToPilotRegistrationCapabilities(response)),
                catchError((error) => throwError(() => transformPilotRegistrationsErrorResponse(error)))
            );
    }

    public verifyForeignOperator(foreignOperator: ForeignOperatorVerificationDetails): Observable<void> {
        return this.httpClient
            .put<void>(this.endpoints.verifyForeignOperatorNumber, { ...foreignOperator })
            .pipe(catchError((error) => throwError(() => convertHttpErrorResponseToForeignOperatorVerificationError(error))));
    }

    public registerPilot(pilotRegistrationFormValue: PilotRegistrationFormValue): Observable<RegisteredPilot> {
        const body = transformPilotRegistrationToOPilotRegistrationBody(pilotRegistrationFormValue);

        return this.httpClient
            .post<RegisteredPilot>(this.endpoints.registerPilot, { ...body })
            .pipe(catchError((error) => throwError(() => transformPilotRegistrationsErrorResponse(error))));
    }
}
