import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { FiltersMap } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { tap } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MembersListFilter, MembersListFilterFormKeys, MembershipCapabilities } from "../../../services/membership.models";

interface InvitedUsersFiltersComponentState {
    incomingFilters: MembersListFilter | undefined;
    capabilities: MembershipCapabilities | undefined;
    filtersCount: number;
}

const DEBOUNCE_TIME = 300;
const MIN_SEARCH_LENGTH = 3;

const FILTERS_MAP: FiltersMap[] = [
    {
        key: MembersListFilterFormKeys.SearchByText,
        filterLabel: "dtmWebAppMembership.filters.searchByTextLabel",
        filterValueLabel: "dtmWebAppMembership.filters.confirmLabel",
    },
    {
        key: MembersListFilterFormKeys.Status,
        filterLabel: "dtmWebAppMembership.filters.statusLabel",
        filterValueLabel: "dtmWebAppMembership.filters.statusValue",
    },
    {
        key: MembersListFilterFormKeys.Competencies,
        filterLabel: "dtmWebAppMembership.filters.qualificationsLabel",
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-invited-pilots-filters[capabilities]",
    templateUrl: "./invited-users-filters.component.html",
    styleUrls: ["./invited-users-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class InvitedUsersFiltersComponent implements OnInit {
    @Input() public set initialFilters(value: MembersListFilter | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }

    @Input() public set capabilities(value: MembershipCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }

    @Output() public filtersChange = new EventEmitter<MembersListFilter>();

    public readonly FILTERS_MAP = FILTERS_MAP;
    public readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    public readonly capabilities$ = this.localStore.selectByKey("capabilities");
    public readonly searchTextControl = new UntypedFormControl("", Validators.minLength(MIN_SEARCH_LENGTH));
    public readonly statusControl = new UntypedFormControl();
    public readonly competenciesControl = new UntypedFormControl();

    public readonly filtersForm = new UntypedFormGroup({
        [MembersListFilterFormKeys.SearchByText]: this.searchTextControl,
        [MembersListFilterFormKeys.Status]: this.statusControl,
        [MembersListFilterFormKeys.Competencies]: this.competenciesControl,
    });

    constructor(private readonly localStore: LocalComponentStore<InvitedUsersFiltersComponentState>) {
        localStore.setState({ incomingFilters: undefined, capabilities: undefined, filtersCount: 0 });
    }

    public ngOnInit() {
        this.assignInitialFiltersAndWatchChanges();
    }

    public clearFilters(): void {
        this.filtersForm.reset();
    }

    private onFormValueChanges(): void {
        this.filtersForm.valueChanges
            .pipe(
                debounceTime(DEBOUNCE_TIME),
                distinctUntilChanged(equal),
                tap((value) => {
                    this.updateAppliedFiltersCount();
                    if (this.filtersForm.invalid) {
                        return;
                    }
                    this.filtersChange.emit(value);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateAppliedFiltersCount(): void {
        if (this.filtersForm.invalid) {
            return;
        }

        const values = Object.values(this.filtersForm.value);
        const filtersCount = values.flat().filter(Boolean).length;

        this.localStore.patchState({ filtersCount });
    }

    private assignInitialFiltersAndWatchChanges() {
        this.onFormValueChanges();

        this.localStore
            .selectByKey("incomingFilters")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((filters) => {
                if (!this.isIncomingFilterDifferentThanForm(filters)) {
                    return;
                }

                this.filtersForm.setValue(filters, { emitEvent: false });
                this.updateAppliedFiltersCount();
            });
    }

    private isIncomingFilterDifferentThanForm(filters: MembersListFilter) {
        return (
            filters.searchByText !== this.searchTextControl.value ||
            this.statusControl.value?.toString() !== filters.status.toString() ||
            this.competenciesControl.value?.toString() !== filters.competencies?.toString()
        );
    }
}
