<section class="container scroll-shadows">
    <div class="header">
        <span class="active-step">
            {{
                "dtmWebAppLibMission.missionWizardSteps.activeStepLabel"
                    | transloco : { stepNumber: stepNumber$ | ngrxPush, stepsAmount: stepsAmount$ | ngrxPush }
            }}
        </span>
    </div>
    <h2 class="step-title">
        <span>{{ title$ | ngrxPush }}</span>
        <ng-content select="[stepTitleContent]"></ng-content>
    </h2>
    <ng-content></ng-content>
</section>

<ng-template #footerTemplate>
    <footer
        class="actions"
        *ngrxLet="{
            isNextButtonEnabled: isNextButtonEnabled$,
            isLoading: isLoading$,
            nextButtonLabel: nextButtonLabel$,
        } as vm"
    >
        <div class="secondary-actions">
            <button
                *ngIf="isBackButtonVisible$ | ngrxPush"
                [disabled]="!(isBackButtonEnabled$ | ngrxPush)"
                class="button-secondary"
                type="button"
                (click)="back.emit()"
            >
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dtmWebAppLibMission.missionWizardSteps.backButtonLabel" | transloco }}
            </button>
        </div>
        <div class="primary-actions">
            <button
                [disabled]="!vm.isNextButtonEnabled"
                class="button-primary next-button"
                type="button"
                (click)="next.emit()"
                data-testid="next-button"
            >
                <mat-spinner diameter="20" color="accent" *ngIf="vm.isLoading"></mat-spinner>
                <span [class.is-loading]="vm.isLoading">
                    {{ vm.nextButtonLabel }}
                </span>
            </button>
        </div>
    </footer>
</ng-template>
