import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    Alpha3CountryCode,
    ButtonTheme,
    ConfirmationDialogComponent,
    DialogService,
    IdentityDocumentType,
    InvalidFormScrollableDirective,
    PhoneNumber,
    UploadedFile,
    defaultCountryAddressFormValidator,
    foreignAddressFormValidator,
    requiredValidForSmsPhoneNumberValidator,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    AnimationUtils,
    BYTES_IN_MEGABYTE,
    DEFAULT_COMPANY_NUMBER_LENGTH,
    DEFAULT_COMPANY_NUMBER_MASK,
    DEFAULT_COUNTRY_CODE,
    DEFAULT_PHONE_COUNTRY_CODE,
    LocalComponentStore,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { firstValueFrom } from "rxjs";
import { AssociationOperatorDetails, AssociationRegistrationIdType } from "../../../../shared/models/pilot-and-operator.models";
import { optionalDefaultCountryCompanyNumberValidator } from "../../../../shared/validators/optional-default-country-company-number.validator";
import { foreignOperatorFormValidator } from "../../../shared/components/foreign-operator-validation-fields/foreign-operator-validation-fields.component";
import { ForeignOperatorValidation, ForeignOperatorVerificationDetails, PilotOperatorAddressForm } from "../../../shared/models";
import { OperatorRegistrationType, RegisterOperatorBasicData } from "../../services/models";

const COMPANY_NAME_MAX_LENGTH = 100;

// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;

interface BasicDataForm {
    email: FormControl<string>;
    phoneNumber: FormControl<PhoneNumber | null>;
    companyName: FormControl<string>;
    companyNumber: FormControl<string>;
    address: FormControl<PilotOperatorAddressForm | null>;
    foreignOperator: FormControl<ForeignOperatorVerificationDetails>;
    identityDocument: FormGroup<IdentityDocumentForm>;
    country: FormControl<Alpha3CountryCode | null>;
    associationIdDetails: FormControl<AssociationOperatorDetails | null>;
}

interface IdentityDocumentForm {
    type: FormControl<IdentityDocumentType | null>;
    identityDocumentId: FormControl<UploadedFile[]>;
    identityDocumentExpirationDate: FormControl<Date | null>;
}

interface RegisterOperatorBasicDataComponentState {
    foreignOperatorValidation: ForeignOperatorValidation | undefined;
    isIdentityDocumentRequired: boolean;
    originCountry: string;
    isCompanyNumberOptional: boolean;
    initialValues: RegisterOperatorBasicData | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-register-operator-basic-data[foreignOperatorValidation][isIdentityDocumentRequired][originCountry]",
    templateUrl: "./register-operator-basic-data.component.html",
    styleUrls: ["../../../shared/styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class RegisterOperatorBasicDataComponent {
    @Input()
    public set foreignOperatorValidation(value: ForeignOperatorValidation | undefined) {
        this.localStore.patchState({ foreignOperatorValidation: value });
    }
    @Input()
    public set originCountry(value: string | undefined) {
        this.localStore.patchState({ originCountry: value ?? DEFAULT_COUNTRY_CODE });
    }
    @Input()
    public set initialValues(value: RegisterOperatorBasicData | undefined) {
        this.localStore.patchState({ initialValues: value });
        if (value) {
            this.patchFormWithInitialValues(value);
        }
    }

    @Input()
    public set isIdentityDocumentRequired(value: boolean | undefined) {
        this.localStore.patchState({ isIdentityDocumentRequired: !!value });

        if (value) {
            this.identityDocumentFormGroup.controls.identityDocumentExpirationDate.enable();
            this.identityDocumentFormGroup.controls.type.enable();
            this.identityDocumentFormGroup.controls.identityDocumentId.enable();
        } else {
            this.identityDocumentFormGroup.controls.identityDocumentExpirationDate.disable();
            this.identityDocumentFormGroup.controls.type.disable();
            this.identityDocumentFormGroup.controls.identityDocumentId.disable();
            this.identityDocumentFormGroup.reset();
        }
    }

    protected readonly foreignOperatorValidation$ = this.localStore.selectByKey("foreignOperatorValidation");
    protected readonly addressFormControl = new FormControl<PilotOperatorAddressForm | null>(null, {
        validators: defaultCountryAddressFormValidator,
    });
    protected readonly foreignOperatorControl = new FormControl<ForeignOperatorVerificationDetails>(
        { value: { number: "", secret: "" }, disabled: true },
        { validators: foreignOperatorFormValidator, nonNullable: true }
    );
    protected readonly isRegisteredInOtherCountryControl = new FormControl<boolean | null>(null, {
        validators: Validators.required,
    });
    protected readonly registrationTypeControl = new FormControl<OperatorRegistrationType | null>(null, {
        validators: Validators.required,
    });
    protected readonly companyNameControl = new FormControl<string>("", {
        validators: [
            Validators.required,
            Validators.maxLength(COMPANY_NAME_MAX_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ],
        nonNullable: true,
    });
    protected readonly emailControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.email, Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN)],
        nonNullable: true,
    });
    protected readonly phoneNumberControl = new FormControl<PhoneNumber>(
        { countryCode: DEFAULT_PHONE_COUNTRY_CODE, number: "" },
        {
            validators: requiredValidForSmsPhoneNumberValidator,
            nonNullable: true,
        }
    );
    protected readonly companyNumberControl = new FormControl<string>("", {
        nonNullable: true,
    });
    protected readonly identityDocumentIdControl = new FormControl<UploadedFile[]>([], {
        validators: [Validators.required, Validators.maxLength(1)],
        nonNullable: true,
    });
    protected readonly identityDocumentExpirationDateControl = new FormControl<Date | null>(null, {
        validators: Validators.required,
    });
    protected readonly identityDocumentTypeControl = new FormControl<IdentityDocumentType>(IdentityDocumentType.IdCard, {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly identityDocumentFormGroup: FormGroup<IdentityDocumentForm> = new FormGroup<IdentityDocumentForm>({
        type: this.identityDocumentTypeControl,
        identityDocumentId: this.identityDocumentIdControl,
        identityDocumentExpirationDate: this.identityDocumentExpirationDateControl,
    });
    protected readonly countryControl = new FormControl<Alpha3CountryCode | null>(null, {
        validators: Validators.required,
    });
    protected readonly associationIdControl = new FormControl<AssociationOperatorDetails | null>(
        { value: null, disabled: true },
        {
            validators: Validators.required,
        }
    );
    protected readonly COMPANY_NUMBER_MASK = DEFAULT_COMPANY_NUMBER_MASK;
    protected readonly MIN_DATE = new Date();
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    protected readonly IdentityDocumentType = IdentityDocumentType;
    protected readonly OperatorRegistrationType = OperatorRegistrationType;
    protected readonly originCountry$ = this.localStore.selectByKey("originCountry");
    protected readonly isCompanyNumberOptional$ = this.localStore.selectByKey("isCompanyNumberOptional");
    protected readonly initialValues$ = this.localStore.selectByKey("initialValues");

    @Output() public basicDataAssignAndGoToNextStep = new EventEmitter<RegisterOperatorBasicData>();
    @Output() public foreignOperatorCheck = new EventEmitter<ForeignOperatorVerificationDetails>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;
    @ViewChild("foreignOperatorElement") private readonly foreignOperatorElement!: ElementRef;

    protected readonly basicDataForm: FormGroup<BasicDataForm> = new FormGroup<BasicDataForm>({
        companyName: this.companyNameControl,
        address: this.addressFormControl,
        country: this.countryControl,
        email: this.emailControl,
        phoneNumber: this.phoneNumberControl,
        identityDocument: this.identityDocumentFormGroup,
        companyNumber: this.companyNumberControl,
        foreignOperator: this.foreignOperatorControl,
        associationIdDetails: this.associationIdControl,
    });

    private readonly companyNumberRequiredValidator = [
        Validators.required,
        Validators.minLength(DEFAULT_COMPANY_NUMBER_LENGTH),
        Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
    ];
    private readonly companyNumberOptionalValidator = optionalDefaultCountryCompanyNumberValidator;

    constructor(
        private readonly localStore: LocalComponentStore<RegisterOperatorBasicDataComponentState>,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService,
        private readonly translocoHelper: TranslationHelperService
    ) {
        localStore.setState({
            foreignOperatorValidation: undefined,
            isIdentityDocumentRequired: false,
            isCompanyNumberOptional: false,
            originCountry: DEFAULT_COUNTRY_CODE,
            initialValues: undefined,
        });

        this.isRegisteredInOtherCountryControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((value) => this.otherCountryRegisteredChange(value));
    }

    protected async validateFormAndGoToNextStep() {
        this.basicDataForm.markAllAsTouched();
        this.isRegisteredInOtherCountryControl.markAsTouched();
        this.registrationTypeControl.markAsTouched();

        if (
            this.basicDataForm.controls.foreignOperator.enabled &&
            !(await firstValueFrom(this.foreignOperatorValidation$.pipe(untilDestroyed(this))))?.isValid
        ) {
            this.foreignOperatorElement.nativeElement.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });

            return;
        }

        if (this.basicDataForm.valid) {
            this.basicDataAssignAndGoToNextStep.emit(this.getBasicData());

            return;
        }

        this.invalidFormScrollable.scrollToFirstInvalidField();
    }

    protected registrationTypeChange(selectedType: OperatorRegistrationType) {
        if (!this.basicDataForm.touched && !this.isRegisteredInOtherCountryControl.value !== null) {
            this.handleOperatorRegistrationTypeChange(selectedType);

            return;
        }

        this.getChangeFormConfirmationDialog()
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed: boolean) => {
                if (isConfirmed) {
                    this.handleOperatorRegistrationTypeChange(selectedType);
                } else {
                    this.registrationTypeControl.setValue(
                        selectedType === OperatorRegistrationType.Enterprise
                            ? OperatorRegistrationType.Association
                            : OperatorRegistrationType.Enterprise,
                        { emitEvent: false }
                    );
                }
            });
    }

    protected otherCountryRegisteredChange(isRegisteredAbroad: boolean | null) {
        if (!this.basicDataForm.touched) {
            this.changeFormAfterIsRegisteredAbroadChanges(isRegisteredAbroad);

            return;
        }

        this.getChangeFormConfirmationDialog()
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed: boolean) => {
                if (isConfirmed) {
                    this.changeFormAfterIsRegisteredAbroadChanges(isRegisteredAbroad);
                } else {
                    this.isRegisteredInOtherCountryControl.setValue(!isRegisteredAbroad, { emitEvent: false });
                }
            });
    }

    private handleOperatorRegistrationTypeChange(selectedType: OperatorRegistrationType) {
        switch (selectedType) {
            case OperatorRegistrationType.Association:
                this.basicDataForm.controls.associationIdDetails.enable();
                this.companyNumberControl.removeValidators(this.companyNumberRequiredValidator);
                this.companyNumberControl.addValidators(this.companyNumberOptionalValidator);
                this.localStore.patchState({ isCompanyNumberOptional: true });
                break;
            case OperatorRegistrationType.Enterprise:
                this.basicDataForm.controls.associationIdDetails.disable();
                this.companyNumberControl.addValidators(this.companyNumberRequiredValidator);
                this.companyNumberControl.removeValidators(this.companyNumberOptionalValidator);
                this.localStore.patchState({ isCompanyNumberOptional: false });
                break;
        }
        this.basicDataForm.reset();
        this.isRegisteredInOtherCountryControl.reset();
    }

    private getChangeFormConfirmationDialog() {
        return this.dialogService.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.confirmIsRegisteredAbroadChangeTitleText"
                ),
                confirmationText: this.translocoService.translate(
                    "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.isRegisteredAbroadChangeConfirmationText"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.isRegisteredAbroadChangeCancelButtonLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.isRegisteredAbroadChangeConfirmButtonLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });
    }

    private changeFormAfterIsRegisteredAbroadChanges(isRegisteredAbroad: boolean | null) {
        const originCountry = this.localStore.selectSnapshotByKey("originCountry");
        this.basicDataForm.reset();

        if (isRegisteredAbroad) {
            this.basicDataForm.controls.foreignOperator.enable();
            this.countryControl.enable({ emitEvent: false });
            this.countryControl.reset(null, { emitEvent: false });
            this.addressFormControl.removeValidators(defaultCountryAddressFormValidator);
            this.addressFormControl.addValidators(foreignAddressFormValidator);
            this.basicDataForm.controls.companyNumber.disable();
            this.basicDataForm.controls.companyNumber.reset();
        } else {
            this.basicDataForm.controls.foreignOperator.disable();
            this.foreignOperatorControl.reset();
            this.basicDataForm.controls.companyNumber.enable();
            this.countryControl.reset(originCountry, { emitEvent: false });
            this.countryControl.disable({ emitEvent: false });
            this.addressFormControl.removeValidators(foreignAddressFormValidator);
            this.addressFormControl.addValidators(defaultCountryAddressFormValidator);
        }

        if (this.localStore.selectSnapshotByKey("isIdentityDocumentRequired") && !this.basicDataForm.controls["identityDocument"]) {
            this.identityDocumentFormGroup.controls.identityDocumentId.enable();
            this.identityDocumentFormGroup.controls.identityDocumentExpirationDate.enable();
            this.identityDocumentFormGroup.controls.type.enable();
        }
    }

    private getBasicData(): RegisterOperatorBasicData {
        return {
            address: {
                ...(this.addressFormControl.value as PilotOperatorAddressForm),
                country: this.countryControl.value as Alpha3CountryCode,
            },
            isRegisteredInOtherCountry: !!this.isRegisteredInOtherCountryControl.value,
            email: this.emailControl.value,
            phoneNumber: this.phoneNumberControl.value,
            companyName: this.companyNameControl.value,
            companyNumber: this.companyNumberControl.value ?? undefined,
            ...(this.isRegisteredInOtherCountryControl.value &&
                this.foreignOperatorControl.value && {
                    foreignOperator: this.foreignOperatorControl.value,
                }),
            identityDocument: this.basicDataForm.controls.identityDocument.enabled ? this.identityDocumentIdControl.value : undefined,
            identityDocumentExpirationDate: this.identityDocumentExpirationDateControl?.value ?? undefined,
            identityDocumentType: this.basicDataForm.controls.identityDocument.enabled ? this.identityDocumentTypeControl.value : undefined,
            associationNationalCourtRegister:
                this.basicDataForm.controls.associationIdDetails?.value?.associationRegistrationIdType ===
                AssociationRegistrationIdType.NationalCourtRegister
                    ? (this.basicDataForm.controls.associationIdDetails?.value?.associationRegistrationId as string)
                    : undefined,
            associationRegistrationNumber:
                this.basicDataForm.controls.associationIdDetails?.value?.associationRegistrationIdType ===
                AssociationRegistrationIdType.RegistrationNumber
                    ? (this.basicDataForm.controls.associationIdDetails?.value?.associationRegistrationId as string)
                    : undefined,
        };
    }

    private patchFormWithInitialValues(initialValues: RegisterOperatorBasicData) {
        const isAssociation = !!initialValues.associationNationalCourtRegister || !!initialValues.associationRegistrationNumber;
        this.registrationTypeControl.setValue(isAssociation ? OperatorRegistrationType.Association : OperatorRegistrationType.Enterprise);

        if (isAssociation) {
            this.associationIdControl.enable();
        }

        this.isRegisteredInOtherCountryControl.setValue(!!initialValues.foreignOperator);

        this.basicDataForm.setValue({
            companyName: initialValues.companyName,
            address: {
                streetName: initialValues.address.streetName,
                houseNumber: initialValues.address.houseNumber,
                apartmentNumber: initialValues.address.apartmentNumber,
                city: initialValues.address.city,
                postCode: initialValues.address.postCode,
            },
            country: initialValues.address.country,
            email: initialValues.email,
            phoneNumber: initialValues.phoneNumber,
            identityDocument: {
                type: initialValues.identityDocumentType ?? null,
                identityDocumentId: initialValues.identityDocument ?? [],
                identityDocumentExpirationDate: initialValues.identityDocumentExpirationDate ?? null,
            },
            companyNumber: initialValues.companyNumber ?? "",
            foreignOperator: initialValues.foreignOperator ?? { number: "", secret: "" },
            associationIdDetails: this.getAssociationIdDetails(initialValues),
        });
    }

    private getAssociationIdDetails(initialValues: RegisterOperatorBasicData): AssociationOperatorDetails | null {
        if (initialValues.associationNationalCourtRegister) {
            return {
                associationRegistrationId: initialValues.associationNationalCourtRegister,
                associationRegistrationIdType: AssociationRegistrationIdType.NationalCourtRegister,
            };
        }
        if (initialValues.associationRegistrationNumber) {
            return {
                associationRegistrationId: initialValues.associationRegistrationNumber,
                associationRegistrationIdType: AssociationRegistrationIdType.RegistrationNumber,
            };
        }

        return null;
    }
}
