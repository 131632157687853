<mat-dialog-content>
    <form [formGroup]="dialogForm">
        <dtm-ui-input-field [isClearable]="false">
            <dtm-ui-icon name="time" class="field-prefix"></dtm-ui-icon>
            <label>{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.stopoverLabel" | transloco }}</label>
            <input
                matInput
                dtmUiClampInput
                type="number"
                inputmode="numeric"
                [min]="0"
                step="1"
                required
                formControlName="stopoverMinutes"
            />
            <div class="field-suffix">
                {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.stopoverUnitLabel" | transloco }}
            </div>
        </dtm-ui-input-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="button-secondary" type="button" mat-dialog-close>
        {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.stopoverDialogCancelButtonLabel" | transloco }}
    </button>
    <button class="button-primary" type="button" color="primary" (click)="confirm()">
        {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.stopoverDialogConfirmButtonLabel" | transloco }}
    </button>
</mat-dialog-actions>
