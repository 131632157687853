import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith } from "rxjs/operators";
import { AvailableUav, SinglePermission, UavsSelectedForPermission } from "../../../../services/operator-permissions.models";

interface UavInformationStepComponentState {
    selectedPossiblePermissions: SinglePermission[];
    otherPossiblePermissions: SinglePermission[];
    availableUavs: AvailableUav[];
    uavsRequiredPermissions: SinglePermission[];
    stepsAmount: number;
    isPaymentFeatureAvailable: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-permissions-uav-information-step[selectedPossiblePermissions][availableUavs][stepsAmount]",
    templateUrl: "./uav-information-step.component.html",
    styleUrls: ["./uav-information-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavInformationStepComponent implements OnInit {
    @Input() public set selectedPossiblePermissions(value: SinglePermission[] | undefined) {
        this.localStore.patchState({ selectedPossiblePermissions: value ?? [] });
    }
    @Input() public set availableUavs(value: AvailableUav[] | undefined) {
        this.localStore.patchState({ availableUavs: value ?? [] });
    }
    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value ?? 0 });
    }
    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }
    @Output() public back = new EventEmitter<void>();
    @Output() public next = new EventEmitter<UavsSelectedForPermission>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly selectedPossiblePermissions$ = this.localStore
        .selectByKey("selectedPossiblePermissions")
        .pipe(RxjsUtils.filterFalsy());
    protected readonly otherPossiblePermissions$ = this.localStore.selectByKey("otherPossiblePermissions");
    protected readonly uavsRequiredPermissions$ = this.localStore.selectByKey("uavsRequiredPermissions");
    protected readonly availableUavs$ = this.localStore.selectByKey("availableUavs");
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount").pipe(RxjsUtils.filterFalsy());
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");
    protected readonly permissionsForm = new FormGroup<{ [key: string]: FormArray }>({});

    constructor(private readonly localStore: LocalComponentStore<UavInformationStepComponentState>) {
        this.localStore.setState({
            selectedPossiblePermissions: [],
            otherPossiblePermissions: [],
            uavsRequiredPermissions: [],
            availableUavs: [],
            stepsAmount: 0,
            isPaymentFeatureAvailable: false,
        });
    }

    public ngOnInit() {
        this.initUavForm();
    }

    protected addUavToPermission(scenarioId: string) {
        const permissionsFormArray = this.permissionsForm.controls[scenarioId];
        permissionsFormArray.push(this.getNewUavControl(scenarioId));
    }

    protected getAvailableUavsForPermission(uavs: AvailableUav[], permission: SinglePermission) {
        return uavs.filter(({ uavClasses }) => uavClasses?.some((uavClass) => permission.allowedUavClasses.includes(uavClass)));
    }

    private getNewUavControl(scenarioId: string, value?: AvailableUav) {
        const selectedScenario = this.localStore
            .selectSnapshotByKey("selectedPossiblePermissions")
            .find((scenario) => scenario.scenarioId === scenarioId);
        let assignValue = value ?? null;

        if (!value?.uavClasses?.some((uavClass) => selectedScenario?.allowedUavClasses.includes(uavClass))) {
            assignValue = null;
        }

        return new FormControl<AvailableUav | null>(assignValue, {
            validators: [Validators.required, (control: AbstractControl) => this.getUavUniquenessValidator(control, scenarioId)],
        });
    }

    protected removeUav(scenarioId: string, uavIndex: number) {
        const permissionsFormArray = this.permissionsForm.controls[scenarioId];
        permissionsFormArray.removeAt(uavIndex);
    }

    protected submitForm() {
        this.permissionsForm.markAllAsTouched();
        if (this.permissionsForm.invalid) {
            this.invalidFormScrollable.scrollToFirstInvalidField({
                behavior: "smooth",
                block: "center",
            });

            return;
        }

        this.next.emit(this.permissionsForm.getRawValue());
    }

    private initUavForm() {
        this.selectedPossiblePermissions$
            .pipe(combineLatestWith(this.availableUavs$.pipe(RxjsUtils.filterFalsy())), untilDestroyed(this))
            .subscribe(([selectedPossiblePermission, availableUavs]: [SinglePermission[], AvailableUav[]]) => {
                const otherPossiblePermissions: SinglePermission[] = [];
                const uavsRequiredPermissions: SinglePermission[] = [];

                selectedPossiblePermission.forEach((possiblePermission) => {
                    if (!possiblePermission.areUavsRequired) {
                        otherPossiblePermissions.push(possiblePermission);

                        return;
                    }

                    const permissionUavs = possiblePermission.uavs;

                    if (permissionUavs?.length) {
                        const availableUavsFromPermissionUavs = availableUavs.filter((availableUav) =>
                            permissionUavs.some((permissionUav) => permissionUav.id === availableUav.id)
                        );
                        const formArray = new FormArray(
                            availableUavsFromPermissionUavs.map((uav) => this.getNewUavControl(possiblePermission.scenarioId, uav))
                        );
                        this.permissionsForm.addControl(possiblePermission.scenarioId, formArray);
                    } else {
                        this.permissionsForm.addControl(
                            possiblePermission.scenarioId,
                            new FormArray([this.getNewUavControl(possiblePermission.scenarioId)])
                        );
                    }

                    uavsRequiredPermissions.push(possiblePermission);
                    this.initControlsValidityCheckAfterChange(possiblePermission.scenarioId);
                });

                this.localStore.patchState({ otherPossiblePermissions, uavsRequiredPermissions });
            });
    }

    private getUavUniquenessValidator(uavControl: AbstractControl, permissionId: string): ValidationErrors | null {
        const permissionControl = this.permissionsForm.controls[permissionId] as FormArray;

        if (uavControl?.value && permissionControl) {
            const controlsIncludingSelectedValue = permissionControl.controls.filter((control) => control.value === uavControl.value);

            if (controlsIncludingSelectedValue.length > 1) {
                return { notUnique: true };
            }

            return null;
        }

        return null;
    }

    private initControlsValidityCheckAfterChange(possiblePermissionId: string) {
        this.permissionsForm.controls[possiblePermissionId].valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.permissionsForm.controls[possiblePermissionId].controls.forEach((control) => {
                if (control.value && control.hasError("notUnique")) {
                    control.updateValueAndValidity({ emitEvent: false });
                }
            });
        });
    }
}
