import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FormType, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Sail } from "../../../../shared/models/operations-manual.models";
import { UavAdditionalInfo } from "../../../services/specific-permit-application.models";

interface UavAdditionalInfoComponentState {
    isExpanded: boolean;
    hasUavSetupBeenUsedPreviously: boolean;
    sail: Sail | undefined;
}

const MAX_DVRTC_NUMBER_LENGTH = 100;
const MAX_TECHNICAL_SERVICE_LENGTH = 250;
const MAX_C3_LINK_LENGTH = 250;
const MAX_HMI_LENGTH = 250;
const MAX_ADVERSE_ENVIRONMENTAL_CONDITIONS_LENGTH = 250;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-uav-additional-info[sail]",
    templateUrl: "./uav-additional-info.component.html",
    styleUrls: ["../../common.scss", "./uav-additional-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UavAdditionalInfoComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => UavAdditionalInfoComponent),
            multi: true,
        },
    ],
})
export class UavAdditionalInfoComponent implements ControlValueAccessor, Validator {
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set sail(value: Sail | undefined) {
        this.localStore.patchState({ sail: value });
    }

    protected readonly MAX_TECHNICAL_SERVICE_LENGTH = MAX_TECHNICAL_SERVICE_LENGTH;
    protected readonly MAX_C3_LINK_LENGTH = MAX_C3_LINK_LENGTH;
    protected readonly MAX_HMI_LENGTH = MAX_HMI_LENGTH;
    protected readonly MAX_ADVERSE_ENVIRONMENTAL_CONDITIONS_LENGTH = MAX_ADVERSE_ENVIRONMENTAL_CONDITIONS_LENGTH;

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly hasUavSetupBeenUsedPreviously$ = this.localStore.selectByKey("hasUavSetupBeenUsedPreviously");
    private readonly sail$ = this.localStore.selectByKey("sail");
    private readonly sailAndHasUavSetupBeenUsedPreviously$ = combineLatest([this.sail$, this.hasUavSetupBeenUsedPreviously$]);
    protected readonly isC3LinkOptional$ = this.sailAndHasUavSetupBeenUsedPreviously$.pipe(
        map(([sail, hasUavSetupBeenUsedPreviously]) => this.isC3LinkOptional(sail, hasUavSetupBeenUsedPreviously))
    );
    protected readonly isHmiOptional$ = this.sailAndHasUavSetupBeenUsedPreviously$.pipe(
        map(([sail, hasUavSetupBeenUsedPreviously]) => this.isHmiOptional(sail, hasUavSetupBeenUsedPreviously))
    );
    protected readonly isAdverseEnvironmentalConditionsOptional$ = this.sailAndHasUavSetupBeenUsedPreviously$.pipe(
        map(([sail, hasUavSetupBeenUsedPreviously]) => this.isAdverseEnvironmentalConditionsOptional(sail, hasUavSetupBeenUsedPreviously))
    );
    protected readonly isDvrOrTcNumberOptional$ = this.sailAndHasUavSetupBeenUsedPreviously$.pipe(
        map(([sail, hasUavSetupBeenUsedPreviously]) => this.isDvrOrTcNumberOptional(sail, hasUavSetupBeenUsedPreviously))
    );

    protected readonly uavAdditionalInfoForm = new FormGroup<FormType<UavAdditionalInfo>>({
        hasUavSetupBeenUsedPreviously: new FormControl<boolean>(true, {
            validators: [Validators.required],
            nonNullable: true,
        }),
        operationsManualChapter: new FormControl<number | null>(null, {
            validators: [Validators.required, Validators.pattern(/^[1-9][0-9]?$/)],
        }),
        technicalService: new FormControl<string>("", {
            validators: [Validators.maxLength(MAX_TECHNICAL_SERVICE_LENGTH)],
            nonNullable: true,
        }),
        c3Link: new FormControl<string | null>(null, {
            validators: Validators.maxLength(MAX_C3_LINK_LENGTH),
        }),
        hmi: new FormControl<string | null>(null, {
            validators: Validators.maxLength(MAX_HMI_LENGTH),
        }),
        adverseEnvironmentalConditions: new FormControl<string | null>(null, {
            validators: Validators.maxLength(MAX_ADVERSE_ENVIRONMENTAL_CONDITIONS_LENGTH),
        }),
        dvrOrTcNumber: new FormControl<string | null>(null, {
            validators: Validators.maxLength(MAX_DVRTC_NUMBER_LENGTH),
        }),
    });

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: UavAdditionalInfo | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<UavAdditionalInfoComponentState>) {
        this.localStore.setState({
            isExpanded: true,
            hasUavSetupBeenUsedPreviously: this.uavAdditionalInfoForm.controls.hasUavSetupBeenUsedPreviously.value,
            sail: undefined,
        });

        this.handleSailChange();
        this.handleHasUavSetupBeenUsedPreviouslyChange();

        this.uavAdditionalInfoForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (!this.validate()) {
                this.propagateChange(this.prepareResultValue(this.uavAdditionalInfoForm.getRawValue()));
            }

            this.onValidationChange();
            this.propagateTouch();
        });
    }

    public registerOnChange(fn: (value: UavAdditionalInfo | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: UavAdditionalInfo | null): void {
        if (value) {
            this.uavAdditionalInfoForm.setValue(value, { emitEvent: false });
        } else {
            this.uavAdditionalInfoForm.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.uavAdditionalInfoForm.disable();
        } else {
            this.uavAdditionalInfoForm.enable();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.uavAdditionalInfoForm.invalid) {
            return { invalid: true };
        }

        return null;
    }

    private handleSailChange(): void {
        this.sail$.pipe(untilDestroyed(this)).subscribe(() => {
            this.manageC3LinkControlValidators();
            this.manageHmiControlValidators();
            this.manageAdverseEnvironmentalConditionsControlValidators();
            this.manageDvrOrTcNumberControlValidators();
        });
    }

    private handleHasUavSetupBeenUsedPreviouslyChange(): void {
        this.uavAdditionalInfoForm.controls.hasUavSetupBeenUsedPreviously.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((hasUavSetupBeenUsedPreviously) => {
                this.localStore.patchState({ hasUavSetupBeenUsedPreviously });

                this.manageTechnicalServiceControlValidators();
                this.manageC3LinkControlValidators();
                this.manageHmiControlValidators();
                this.manageAdverseEnvironmentalConditionsControlValidators();
                this.manageDvrOrTcNumberControlValidators();
            });
    }

    private isOptionalForSail1(sail: Sail | undefined): boolean {
        if (sail === undefined) {
            return false;
        }

        return sail === Sail.Sail1;
    }

    private isTechnicalServiceOptional(hasUavSetupBeenUsedPreviously: boolean): boolean {
        return hasUavSetupBeenUsedPreviously;
    }

    private isC3LinkOptional(sail: Sail | undefined, hasUavSetupBeenUsedPreviously: boolean): boolean {
        return hasUavSetupBeenUsedPreviously || this.isOptionalForSail1(sail);
    }

    private isHmiOptional(sail: Sail | undefined, hasUavSetupBeenUsedPreviously: boolean): boolean {
        return hasUavSetupBeenUsedPreviously || this.isOptionalForSail1(sail);
    }

    private isAdverseEnvironmentalConditionsOptional(sail: Sail | undefined, hasUavSetupBeenUsedPreviously: boolean): boolean {
        return hasUavSetupBeenUsedPreviously || this.isOptionalForSail1(sail);
    }

    private isDvrOrTcNumberOptional(sail: Sail | undefined, hasUavSetupBeenUsedPreviously: boolean): boolean {
        if (hasUavSetupBeenUsedPreviously) {
            return true;
        }

        if (sail === undefined) {
            return false;
        }

        return [Sail.Sail1, Sail.Sail2, Sail.Sail3].includes(sail);
    }

    private manageTechnicalServiceControlValidators(): void {
        const hasUavSetupBeenUsedPreviously = this.localStore.selectSnapshotByKey("hasUavSetupBeenUsedPreviously");
        const validators = [Validators.maxLength(MAX_TECHNICAL_SERVICE_LENGTH)];

        if (!this.isTechnicalServiceOptional(hasUavSetupBeenUsedPreviously)) {
            validators.push(Validators.required);
        }

        this.uavAdditionalInfoForm.controls.technicalService.setValidators(validators);
        this.uavAdditionalInfoForm.controls.technicalService.updateValueAndValidity();
    }

    private manageC3LinkControlValidators(): void {
        const { sail, hasUavSetupBeenUsedPreviously } = this.localStore.selectSnapshot((state) => state);
        const validators = [Validators.maxLength(MAX_C3_LINK_LENGTH)];

        if (!this.isC3LinkOptional(sail, hasUavSetupBeenUsedPreviously)) {
            validators.push(Validators.required);
        }

        this.uavAdditionalInfoForm.controls.c3Link.setValidators(validators);
        this.uavAdditionalInfoForm.controls.c3Link.updateValueAndValidity();
    }

    private manageHmiControlValidators(): void {
        const { sail, hasUavSetupBeenUsedPreviously } = this.localStore.selectSnapshot((state) => state);
        const validators = [Validators.maxLength(MAX_HMI_LENGTH)];

        if (!this.isHmiOptional(sail, hasUavSetupBeenUsedPreviously)) {
            validators.push(Validators.required);
        }

        this.uavAdditionalInfoForm.controls.hmi.setValidators(validators);
        this.uavAdditionalInfoForm.controls.hmi.updateValueAndValidity();
    }

    private manageAdverseEnvironmentalConditionsControlValidators(): void {
        const { sail, hasUavSetupBeenUsedPreviously } = this.localStore.selectSnapshot((state) => state);
        const validators = [Validators.maxLength(MAX_ADVERSE_ENVIRONMENTAL_CONDITIONS_LENGTH)];

        if (!this.isAdverseEnvironmentalConditionsOptional(sail, hasUavSetupBeenUsedPreviously)) {
            validators.push(Validators.required);
        }

        this.uavAdditionalInfoForm.controls.adverseEnvironmentalConditions.setValidators(validators);
        this.uavAdditionalInfoForm.controls.adverseEnvironmentalConditions.updateValueAndValidity();
    }

    private manageDvrOrTcNumberControlValidators(): void {
        const { sail, hasUavSetupBeenUsedPreviously } = this.localStore.selectSnapshot((state) => state);
        const validators = [Validators.maxLength(MAX_DVRTC_NUMBER_LENGTH)];

        if (!this.isDvrOrTcNumberOptional(sail, hasUavSetupBeenUsedPreviously)) {
            validators.push(Validators.required);
        }

        this.uavAdditionalInfoForm.controls.dvrOrTcNumber.setValidators(validators);
        this.uavAdditionalInfoForm.controls.dvrOrTcNumber.updateValueAndValidity();
    }

    private prepareResultValue(value: UavAdditionalInfo): UavAdditionalInfo {
        return {
            hasUavSetupBeenUsedPreviously: value.hasUavSetupBeenUsedPreviously,
            operationsManualChapter: value.operationsManualChapter ? +value.operationsManualChapter : null,
            technicalService: value.hasUavSetupBeenUsedPreviously ? null : value.technicalService,
            c3Link: value.hasUavSetupBeenUsedPreviously ? null : value.c3Link,
            hmi: value.hasUavSetupBeenUsedPreviously ? null : value.hmi,
            adverseEnvironmentalConditions: value.hasUavSetupBeenUsedPreviously ? null : value.adverseEnvironmentalConditions,
            dvrOrTcNumber: value.hasUavSetupBeenUsedPreviously ? null : value.dvrOrTcNumber,
        };
    }
}
