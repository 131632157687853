import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SoraResult } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CERTIFIED_CATEGORY_FINAL_GRC } from "../../../../../../models/mission.model";

interface FinalSoraIssuesComponentState {
    soraResult: SoraResult | undefined;
    isExpanded: boolean;
}

@Component({
    selector: "dtm-web-app-lib-final-sora-issues[soraResult]",
    templateUrl: "./final-sora-issues.component.html",
    styleUrls: ["./final-sora-issues.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FinalSoraIssuesComponent {
    @Input() public set soraResult(value: SoraResult | undefined) {
        this.localStore.patchState({ soraResult: value });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly soraResult$ = this.localStore.selectByKey("soraResult");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<FinalSoraIssuesComponentState>) {
        this.localStore.setState({
            soraResult: undefined,
            isExpanded: false,
        });
    }

    protected isCertifiedCategory(finalGrc: number | undefined): boolean {
        return !!finalGrc && finalGrc >= CERTIFIED_CATEGORY_FINAL_GRC;
    }
}
