import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MISSION_ENDPOINTS, MissionEndpoints } from "../mission.tokens";
import { MissionState } from "../state/mission.state";

@Injectable()
export class PlanFilesUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(MISSION_ENDPOINTS)
        private readonly endpoints: MissionEndpoints,
        private readonly store: Store
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        const formData: FormData = new FormData();
        const planId = this.store.selectSnapshot(MissionState.currentPlan)?.id;

        if (!planId) {
            throw new Error("Plan ID is not defined");
        }

        formData.append("file", file);

        return this.httpClient
            .put<{ fileId: string; planId: string }>(
                StringUtils.replaceInTemplate(this.endpoints.uploadMissionAttachment, { planId }),
                formData,
                {
                    reportProgress: true,
                    responseType: "json",
                    observe: "events",
                }
            )
            .pipe(
                map((event) => {
                    if (event.type === HttpEventType.Response) {
                        return event.clone<UploadedFileBody>({
                            body: {
                                name: event.body?.fileId ?? "",
                                id: event.body?.fileId ?? "",
                            },
                        });
                    }

                    return event;
                }),
                catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error)))
            );
    }

    public getFile(fileId: string) {
        const planId = this.store.selectSnapshot(MissionState.currentPlan)?.id;

        if (!planId) {
            throw new Error("Plan ID is not defined");
        }

        return this.httpClient.get(StringUtils.replaceInTemplate(this.endpoints.getMissionAttachment, { planId, fileId }), {
            responseType: "blob",
        });
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
