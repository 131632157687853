<dtm-ui-loader-container [isShown]="data.isProcessing$ | ngrxPush">
    <div class="dialog-header">
        <div mat-dialog-title>{{ "dtmWebAppLibUav.editUav.editHeader" | transloco }}</div>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="customUavForm" dtmUiInvalidFormScrollable>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppLibUav.editUav.nameLabel" | transloco }}
                </label>
                <input matInput type="text" [formControl]="customUavForm.controls.name" required autocomplete="off" />
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: 'maxlength'; error as error">
                    {{ "dtmWebAppLibUav.editUav.nameMaxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: 'minlength'; error as error">
                    {{ "dtmWebAppLibUav.editUav.nameMinLengthValueError" | transloco : { minLength: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.name; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibUav.editUav.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppLibUav.editUav.manufacturerNameLabel" | transloco }}
                </label>
                <input matInput type="text" [formControl]="customUavForm.controls.manufacturerName" required />
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.manufacturerName; name: 'maxlength'; error as error">
                    {{ "dtmWebAppLibUav.editUav.maxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.manufacturerName; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibUav.editUav.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppLibUav.editUav.modelNameLabel" | transloco }}
                </label>
                <input matInput type="text" [formControl]="customUavForm.controls.modelName" required />
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.modelName; name: 'maxlength'; error as error">
                    {{ "dtmWebAppLibUav.editUav.maxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.modelName; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibUav.editUav.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-select-field
                [placeholder]="'dtmWebAppLibUav.editUav.uavTypePlaceholder' | transloco"
                [isClearable]="false"
                [formControl]="customUavForm.controls.type"
            >
                <label>{{ "dtmWebAppLibUav.editUav.uavTypeLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let uavType of uavTypes" [value]="uavType">
                    {{ "dtmWebAppLibUav.uavTypeValue" | transloco : { value: uavType } }}
                </dtm-ui-select-option>
                <div class="field-error" *dtmUiFieldHasError="customUavForm.controls.type; name: 'required'">
                    {{ "dtmWebAppLibUav.editUav.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-select-field>
            <dtm-ui-serial-numbers-control
                *ngIf="data.currentIsSwarm"
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="customUavForm.controls.serialNumbers"
                [isSingle]="false"
            ></dtm-ui-serial-numbers-control>
            <div class="uav-class-ce">
                <dtm-ui-select-field
                    [placeholder]="'dtmWebAppLibUav.editUav.uavClassPlaceholder' | transloco"
                    [isClearable]="false"
                    [formControl]="customUavForm.controls.uavClasses"
                    [multiple]="true"
                >
                    <label class="label-with-popover">
                        {{ "dtmWebAppLibUav.editUav.uavClassLabel" | transloco }}
                        <dtm-ui-popover [popoverText]="'dtmWebAppLibUav.editUav.uavClassHelpInfo' | transloco"></dtm-ui-popover>
                    </label>
                    <dtm-ui-select-option *ngFor="let uavClass of uavClasses" [value]="uavClass">
                        {{ "dtmWebAppLibUav.uavClassValue" | transloco : { value: uavClass } }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>
                <div class="switcher">
                    <label>
                        {{ "dtmWebAppLibUav.editUav.isCeCompliantLabel" | transloco }}
                    </label>
                    <mat-slide-toggle [formControl]="customUavForm.controls.isCeCompliant"></mat-slide-toggle>
                </div>
            </div>
            <dtm-web-app-lib-uav-image-control
                [formControl]="customUavForm.controls.image"
                (editMode)="setImageEditMode($event)"
            ></dtm-web-app-lib-uav-image-control>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="false">
            {{ "dtmWebAppLibUav.editUav.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [disabled]="isSaveButtonDisabled$ | ngrxPush" (click)="save()">
            {{ "dtmWebAppLibUav.editUav.saveButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>
