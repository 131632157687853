<ng-container *ngrxLet="definedArea$ as definedArea">
    <div class="dialog-header">
        <dtm-map-actions-panel
            [mode]="MapActionsPanelMode.CylinderAndPrism"
            [activeMapAction]="activeMapAction$ | ngrxPush"
            [entitiesCount]="drawnEntitiesCount$ | ngrxPush"
            [maxEntities]="1"
            [activeEntityStatus]="activeEntityStatus$ | ngrxPush"
            (selectMapAction)="processMapActionChange($event)"
        >
        </dtm-map-actions-panel>
    </div>
    <div mat-dialog-content>
        <dtm-web-app-lib-spec-perm-app-editor-map [initialViewbox]="initialViewbox">
            <dtm-web-app-lib-spec-perm-app-manual-update-panel
                [areaProperties]="definedArea?.properties"
                (areaPropertiesChange)="updateAreaProperties($event)"
            ></dtm-web-app-lib-spec-perm-app-manual-update-panel>
        </dtm-web-app-lib-spec-perm-app-editor-map>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="false">
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [matDialogClose]="definedArea">
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.operationArea.areaEditorDialog.saveButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
