import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MapEntity } from "@dtm-frontend/shared/map/cesium";
import { EmptyStateMode, ItineraryEditorType, MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs";
import {
    ItineraryContent,
    ItineraryContentEntity,
    ItineraryContentMetadata,
    Polyline3DItineraryEntity,
} from "../../../../../../../models/itinerary.model";
import { ItineraryEditorFormData, MissionPlanItineraryConstraints } from "../../../../../../../models/mission.model";
import {
    AssistedItineraryDataEntity,
    CustomItineraryEntity,
    StandardItineraryEntity,
} from "../../../../../../../services/itinerary.service";
import { ItineraryPanelSettings, ViewItineraryEntity } from "../../itinerary-panel.models";
import { DEFAULT_TRACK_DETAILS_DISPLAY_MODE } from "../track-details/track-details-display-mode";

interface MissionWizardItineraryEditorItineraryPanelComponentState {
    itineraryEditorFormData: ItineraryEditorFormData | undefined;
    itineraryContent: ItineraryContent;
    settings: ItineraryPanelSettings | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    currentPlanRoute: MissionPlanRoute | undefined;
    isProcessing: boolean;
    isDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector:
        "dtm-web-app-lib-mission-wizard-itinerary-editor-step-itinerary-panel[itineraryEditorFormData][itineraryContent]" +
        "[currentPlanRoute][settings][constraints]",
    templateUrl: "./itinerary-panel.component.html",
    styleUrls: ["./itinerary-panel.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionWizardItineraryEditorItineraryPanelComponent {
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly DEFAULT_TRACK_DETAILS_DISPLAY_MODE = DEFAULT_TRACK_DETAILS_DISPLAY_MODE;

    protected readonly itineraryContent$ = this.localStore.selectByKey("itineraryContent");
    protected readonly settings$ = this.localStore.selectByKey("settings");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly currentPlanRoute$ = this.localStore.selectByKey("currentPlanRoute");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly itineraryEditorFormData$ = this.localStore.selectByKey("itineraryEditorFormData");
    protected readonly itineraryEditorType$ = this.itineraryEditorFormData$.pipe(
        map((formData) => formData?.editorType ?? ItineraryEditorType.None)
    );

    @Input() public set itineraryContent(value: ItineraryContent | undefined) {
        const itineraryContent = Array.isArray(value) ? value : [];
        this.localStore.patchState({ itineraryContent });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set currentPlanRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ currentPlanRoute: value });
    }
    @Input() public set itineraryEditorFormData(value: ItineraryEditorFormData | undefined) {
        this.localStore.patchState({ itineraryEditorFormData: value });
    }
    @Input() public set settings(value: ItineraryPanelSettings | undefined) {
        this.localStore.patchState({ settings: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Output() public entityUpdate = new EventEmitter<ItineraryContentEntity>();
    @Output() public entityMetadataUpdate = new EventEmitter<ItineraryContentMetadata["value"]>();
    @Output() public entityAdd = new EventEmitter<CustomItineraryEntity | AssistedItineraryDataEntity | StandardItineraryEntity>();
    @Output() public zoomToEntity = new EventEmitter<MapEntity>();
    @Output() public entityDelete = new EventEmitter<ViewItineraryEntity["itineraryIndex"]>();
    @Output() public pointDelete = new EventEmitter<ViewItineraryEntity<Polyline3DItineraryEntity>["itineraryIndex"]>();

    constructor(private readonly localStore: LocalComponentStore<MissionWizardItineraryEditorItineraryPanelComponentState>) {
        this.localStore.setState({
            itineraryContent: [],
            itineraryEditorFormData: undefined,
            settings: undefined,
            constraints: undefined,
            currentPlanRoute: undefined,
            isProcessing: false,
            isDisabled: false,
        });
    }
}
