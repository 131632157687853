<dtm-ui-expandable-panel [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false" *ngIf="constraints$ | ngrxPush as constraints">
    <div headerSlot>
        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.header" | transloco }}
    </div>
    <form class="content" *ngIf="formControls$ | ngrxPush as formControls">
        <span class="buffers-label">
            {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.buffersLabel" | transloco }}
        </span>

        <div class="buffers-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="134" height="89" fill="none">
                <path
                    style="stroke: var(--shape-edges-color); stroke-linejoin: bevel; stroke-width: 2"
                    d="M111 75.6 58 53H1l52 23 58-.4Z"
                />
                <path
                    style="fill: var(--front-back-color); fill-opacity: 0.4; stroke: var(--shape-edges-color); stroke-width: 2"
                    d="M59 53V9H1v44z"
                />
                <path style="stroke: var(--shape-edges-color); stroke-linejoin: bevel; stroke-width: 2" d="M112 31 59.2 9H1l53.3 22H112Z" />
                <path d="M28.9999 31L84.4307 52.9324" style="stroke: var(--shape-edges-color)" />
                <path
                    class="horizontal-buffer-arrow"
                    style="fill: var(--horizontal-buffer-color)"
                    d="M52.6 84.6c-.1.2-.1.6 0 .8l3.2 3.1a.5.5 0 1 0 .7-.7L53.7 85l2.8-2.8a.5.5 0 1 0-.7-.7l-3.2 3.1Zm59.8.8c.1-.2.1-.6 0-.8l-3.2-3.1a.5.5 0 0 0-.7.7l2.8 2.8-2.8 2.8a.5.5 0 0 0 .7.7l3.2-3.1Zm-59.4.1h59v-1H53v1Z"
                />
                <path
                    class="vertical-buffer-arrow"
                    style="fill: var(--vertical-buffer-color)"
                    d="M121.4 29.6a.5.5 0 0 0-.8 0l-3.1 3.2a.5.5 0 0 0 .7.7l2.8-2.8 2.8 2.8a.5.5 0 0 0 .7-.7l-3.1-3.2Zm-.8 46.8c.2.1.6.1.8 0l3.1-3.2a.5.5 0 0 0-.7-.7l-2.8 2.8-2.8-2.8a.5.5 0 0 0-.7.7l3.1 3.2Zm-.1-46.4v46h1V30h-1Z"
                />
                <circle cx="29.5" cy="31.5" r="1.5" style="fill: var(--dots-color)" />
                <circle cx="84.5" cy="53.5" r="1.5" style="fill: var(--dots-color)" />
                <path
                    style="fill: var(--front-back-color); fill-opacity: 0.4; stroke: var(--shape-edges-color); stroke-width: 2"
                    d="M112 76V31H53v45z"
                />
            </svg>
            <dtm-ui-input-field class="vertical-buffer-input-field" [isClearable]="false">
                <dtm-ui-icon
                    name="arrow-up-down-2"
                    class="field-prefix"
                    [matTooltip]="
                        'dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.verticalBufferTooltip' | transloco
                    "
                ></dtm-ui-icon>
                <input
                    #verticalBuffer
                    matInput
                    type="number"
                    inputmode="numeric"
                    [formControl]="formControls.verticalBuffer"
                    [min]="constraints.min.verticalNavigationAccuracy * 2"
                    [max]="constraints.max.verticalNavigationAccuracy * 2"
                    step="2"
                    required
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.heightUnitLabel" | transloco }}
                </div>
            </dtm-ui-input-field>

            <dtm-ui-input-field class="horizontal-buffer-input-field" [isClearable]="false">
                <dtm-ui-icon
                    name="arrow-left-right-2"
                    class="field-prefix"
                    [matTooltip]="
                        'dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.horizontalBufferTooltip' | transloco
                    "
                ></dtm-ui-icon>
                <input
                    #horizontalBuffer
                    matInput
                    type="number"
                    inputmode="numeric"
                    [formControl]="formControls.horizontalBuffer"
                    [min]="constraints.min.horizontalNavigationAccuracy * 2"
                    [max]="constraints.max.horizontalNavigationAccuracy * 2"
                    step="2"
                    required
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.heightUnitLabel" | transloco }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <div class="field-error" *dtmUiFieldHasError="formControls.verticalBuffer">
                    {{
                        "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.verticalBufferConstraintsError"
                            | transloco : { min: verticalBuffer.min, max: verticalBuffer.max }
                    }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="formControls.horizontalBuffer">
                    {{
                        "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.horizontalBufferConstraintsError"
                            | transloco : { min: horizontalBuffer.min, max: horizontalBuffer.max }
                    }}
                </div>
            </dtm-ui-form-errors>
        </div>

        <div class="flight-level-container">
            <dtm-ui-input-field class="flight-level-input-field" [isClearable]="false">
                <label>
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.flightLevelLabel" | transloco }}
                </label>
                <input
                    #flightLevel
                    matInput
                    type="number"
                    inputmode="numeric"
                    [formControl]="formControls.flightLevel"
                    required
                    autocomplete="off"
                    dtmUiClampInput
                    [min]="
                        formControls.flightLevelType.value !== FlightLevelType.FixedAltitude
                            ? constraints.min.height
                            : POLAND_AMSL_MIN_HEIGHT
                    "
                    [max]="
                        formControls.flightLevelType.value !== FlightLevelType.FixedAltitude
                            ? constraints.max.height
                            : POLAND_AMSL_MAX_HEIGHT
                    "
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.heightUnitLabel" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="formControls.flightLevel">
                    {{
                        "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.flightLevelConstraintsError"
                            | transloco : { min: flightLevel.min, max: flightLevel.max }
                    }}
                </div>
                <dtm-ui-select-field
                    class="input-suffix flight-level-type-select-field"
                    [isClearable]="false"
                    [formControl]="formControls.flightLevelType"
                >
                    <dtm-ui-select-option *ngFor="let type of FlightLevelType | keyvalue" [value]="type.value">
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.flightLevelTypeOptionLabel"
                                | transloco : { type: type.key }
                        }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>
            </dtm-ui-input-field>
            <dtm-ui-slider
                *ngIf="formControls.flightLevelType.value !== FlightLevelType.FixedAltitude"
                [disabled]="formControls.flightLevel.disabled || isDisabled$ | ngrxPush"
                [value]="flightLevel.value"
                [min]="flightLevel.min"
                [max]="flightLevel.max"
                [suffix]="'dtmWebAppLibMission.itineraryEditorStep.missionParameters.preferencesPanel.heightUnitLabel' | transloco"
                [ticks]="5"
                [subTicks]="2"
                (valueInput)="formControls.flightLevel.setValue($event)"
            >
            </dtm-ui-slider>
        </div>

        <div class="deferred-form-actions" *ngIf="formStateController$ | ngrxPush as formStateController">
            <ng-container *ngIf="formStateController.isEditing$ | ngrxPush">
                <button class="button-secondary" type="button" (click)="formStateController.restore()">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.restoreFormButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary next-button"
                    type="button"
                    (click)="saveFormValues(formStateController, formControls)"
                    [disabled]="!formStateController.isValid || (isDisabled$ | ngrxPush)"
                >
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveFormButtonLabel" | transloco }}
                </button>
            </ng-container>
        </div>
    </form>
</dtm-ui-expandable-panel>
