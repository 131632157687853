import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { TrackDetailsDisplayMode } from "../track-details/track-details-display-mode";
import { DEFAULT_TRACK_DETAILS_DISPLAY_MODE } from "./../track-details/track-details-display-mode";

interface CorridorViewEntityCardComponentState {
    bufferWidths: number[];
    title: string | undefined;
    isProcessing: boolean;
    displayMode: TrackDetailsDisplayMode;
}

@Component({
    selector: "dtm-web-app-lib-mwiesip-corridor-view-entity-card[isProcessing][displayMode][title][bufferWidths]",
    templateUrl: "./corridor-view-entity-card.component.html",
    styleUrls: ["../itinerary-panel/itinerary-panel.component.scss", "./corridor-view-entity-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CorridorViewEntityCardComponent {
    @Input() public set bufferWidths(value: number[]) {
        this.localStore.patchState({ bufferWidths: value });

        if (value.length > 0 && this.corridorWidthFormControl.value !== value[0]) {
            this.corridorWidthFormControl.setValue(value[0], { onlySelf: true, emitEvent: false });
        }
    }
    @Input() public set title(value: string | undefined) {
        this.localStore.patchState({ title: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set displayMode(value: TrackDetailsDisplayMode) {
        this.localStore.patchState({ displayMode: value });

        if (value.canEdit.corridor.width) {
            this.corridorWidthFormControl.enable();
        } else {
            this.corridorWidthFormControl.disable();
        }
    }

    // eslint-disable-next-line no-magic-numbers
    protected readonly predefinedCorridorWidths = [30, 50, 100, 200];
    protected readonly corridorWidthFormControl = new FormControl<number>(0, { nonNullable: true });

    @Output() public readonly entityDelete = new EventEmitter<void>();
    @Output() public readonly bufferWidthsInputChange = this.corridorWidthFormControl.valueChanges.pipe(
        map((width) => this.changeBufferWidth(width)),
        RxjsUtils.filterFalsy()
    );

    protected readonly bufferWidth$ = this.localStore.selectByKey("bufferWidths").pipe(map((widths) => widths[0]));
    protected readonly title$ = this.localStore.selectByKey("title");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly displayMode$ = this.localStore.selectByKey("displayMode");

    constructor(private readonly localStore: LocalComponentStore<CorridorViewEntityCardComponentState>) {
        localStore.setState({
            bufferWidths: [],
            title: undefined,
            isProcessing: false,
            displayMode: DEFAULT_TRACK_DETAILS_DISPLAY_MODE,
        });
    }

    protected changeBufferWidth(width: number): number[] | undefined {
        const widths = this.localStore.selectSnapshotByKey("bufferWidths");

        if (
            widths.length > 0 &&
            width >= this.predefinedCorridorWidths[0] &&
            width <= this.predefinedCorridorWidths[this.predefinedCorridorWidths.length - 1]
        ) {
            return widths.map(() => width);
        }

        return;
    }
}
