<mat-card *ngrxLet="mission$; let mission" class="mission-tile mat-elevation-z1" [class.selected]="isSelected$ | ngrxPush">
    <dtm-web-app-lib-tactical-mission-status-chip
        class="chip-info"
        [status]="mission.status"
    ></dtm-web-app-lib-tactical-mission-status-chip>
    <div class="chip chip-info light flight-type" *ngIf="mission.flightType == MissionType.BVLOS">
        <dtm-ui-icon name="eye-off"></dtm-ui-icon>
        {{ "dtmWebAppLibShared.missionTypeLabel" | transloco : { value: MissionType.BVLOS } }}
    </div>
    <div class="header row">
        <mat-card-title class="section-label title">{{
            mission.name || ("dtmWebAppLibShared.missionTile.noNameTitle" | transloco)
        }}</mat-card-title>
    </div>
    <dtm-web-app-lib-mission-messages
        (click)="$event.stopPropagation()"
        [messages]="mission.timeOverrideJustification ? [{ text: mission.timeOverrideJustification }] : []"
    ></dtm-web-app-lib-mission-messages>
    <mat-card-content>
        <div class="row" *ngIf="shouldShowExtendedInfo$ | ngrxPush">
            <div class="column">
                <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                <p>
                    {{ mission.startTime.min | localizeDate : { dateStyle: "short" } }},
                    {{ mission.startTime.max | localizeDate : { weekday: "long" } }}
                </p>
            </div>
        </div>
        <dtm-web-app-lib-tactical-mission-details [mission]="mission"></dtm-web-app-lib-tactical-mission-details>
    </mat-card-content>
    <div class="actions">
        <button class="button-secondary" type="button" (click)="$event.stopPropagation(); gotoDetails(mission)">
            {{ "dtmWebAppLibTactical.missionTile.buttonsLabels.goToDetails" | transloco }}
            <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
        </button>
    </div>
</mat-card>
