import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Inject, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent, FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import {
    BYTES_IN_MEGABYTE,
    FileUploadState,
    FileUploadUtils,
    FunctionUtils,
    LocalComponentStore,
    Logger,
    RxjsUtils,
    Upload,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, Observable, of, switchMap } from "rxjs";
import { distinctUntilChanged, filter, map, scan } from "rxjs/operators";
import { CustomUavImageUploadApiService } from "../../services/custom-uav-image-upload-api.service";

interface UavImageControlComponentState {
    isEditMode: boolean;
    isDisabled: boolean;
    imageId: string | undefined;
    isUavImageUrlProcessing: boolean;
    uploadProgress: number | undefined;
    uploadError: string | undefined;
    selectedFile: File | undefined;
}

enum FileUploadErrorType {
    MaxSizeExceeded = "FileUploadErrorMaxSizeExceeded",
    InvalidType = "FileUploadErrorInvalidType",
    Unknown = "FileUploadErrorUnknown",
}

// eslint-disable-next-line no-magic-numbers
const MAX_UPLOADED_FILE_SIZE_BYTES = 5 * BYTES_IN_MEGABYTE;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-image-control",
    templateUrl: "./uav-image-control.component.html",
    styleUrls: ["./uav-image-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UavImageControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => UavImageControlComponent),
            multi: true,
        },
    ],
})
export class UavImageControlComponent implements ControlValueAccessor, Validator {
    @Input() public set imageId(value: string | null) {
        this.localStore.patchState({ imageId: value ?? undefined });
    }

    @HostBinding("class.edit-mode") protected get isEditMode() {
        return this.localStore.selectSnapshotByKey("isEditMode");
    }

    protected readonly MAX_UPLOADED_FILE_SIZE_BYTES = MAX_UPLOADED_FILE_SIZE_BYTES;

    // eslint-disable-next-line
    @Output("editMode") protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly isDisabled$ = combineLatest([
        this.localStore.selectByKey("isDisabled"),
        this.localStore.selectByKey("uploadProgress"),
    ]).pipe(map(([isDisabled, uploadProgress]) => isDisabled || uploadProgress !== undefined));
    protected readonly isImagePickerDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly uavImageUrl$ = this.localStore.selectByKey("imageId").pipe(map((imageId) => this.getImageUrl(imageId)));
    protected readonly isUavImageUrlProcessing$ = this.localStore.selectByKey("isUavImageUrlProcessing");
    protected readonly uploadProgress$ = this.localStore.selectByKey("uploadProgress");
    protected readonly uploadError$ = this.localStore.selectByKey("uploadError");

    private isValueChanged = false;
    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: string | null) => void = FunctionUtils.noop;

    constructor(
        private readonly localStore: LocalComponentStore<UavImageControlComponentState>,
        @Inject(FILES_UPLOAD_API_PROVIDER) private readonly fileUploadApi: CustomUavImageUploadApiService,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            isEditMode: false,
            isDisabled: false,
            imageId: undefined,
            isUavImageUrlProcessing: false,
            uploadProgress: undefined,
            uploadError: undefined,
            selectedFile: undefined,
        });
    }

    public registerOnChange(fn: (value: string | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.localStore.patchState({ isDisabled });
    }

    public writeValue(value: string | null): void {
        this.imageId = value;
    }

    public validate(): ValidationErrors | null {
        const error = this.localStore.selectSnapshotByKey("uploadError");

        if (error) {
            return { upload: true };
        }

        return null;
    }

    protected tryLeaveEditMode(): void {
        const isEditModeLeaveConfirmed$ = this.isValueChanged ? this.confirmLeaveEditMode() : of(true);
        isEditModeLeaveConfirmed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            this.setEditMode(false);
        });
    }

    protected setEditMode(value: boolean): void {
        this.localStore.patchState({ isEditMode: value });
        this.onTouched();

        if (!value) {
            this.isValueChanged = false;
        }
    }

    private getImageUrl(imageId: string | undefined): string | undefined {
        if (imageId) {
            return this.fileUploadApi.getFileUrl(imageId);
        }

        return undefined;
    }

    protected saveImage(): void {
        const file = this.localStore.selectSnapshotByKey("selectedFile") ?? null;

        if (!file) {
            this.imageId = null;
            this.propagateChange(null);
            this.onValidationChange();
            this.setEditMode(false);

            return;
        }

        this.uploadFile(file)
            .pipe(untilDestroyed(this))
            .subscribe({
                next: (imageId) => {
                    this.propagateChange(imageId);
                    this.onValidationChange();
                    this.setEditMode(false);
                },
                error: (error) => {
                    Logger.captureException(error);
                },
            });
    }

    protected setUavImageUrlProcessing(isProcessing: boolean) {
        this.localStore.patchState({ isUavImageUrlProcessing: isProcessing });
    }

    protected setFile(file: File | null) {
        this.isValueChanged = true;
        this.localStore.patchState({ selectedFile: file ?? undefined });
    }

    private uploadFile(file: File) {
        const initialState: Upload = {
            progress: 0,
            name: file.name,
            state: FileUploadState.InProgress,
        };

        this.updateFileUploadProgress(0);

        this.fileUploadApi
            .uploadFile(file)
            .pipe(
                scan(FileUploadUtils.calculateUploadState, initialState),
                distinctUntilChanged((update1, update2) => update1.state === update2.state && update1.progress === update2.progress),
                untilDestroyed(this)
            )
            .subscribe({
                next: (uploadUpdate) => {
                    if (uploadUpdate.state === FileUploadState.InProgress) {
                        this.updateFileUploadProgress(uploadUpdate.progress ?? 0);
                    } else if (uploadUpdate.state === FileUploadState.Done) {
                        this.finishUpload(uploadUpdate.id ?? "");
                    }
                },
                error: (error: { type: FileUploadErrorType }) => {
                    this.finishUploadWithError(file, error.type);
                },
            });

        return this.localStore.selectByKey("uploadProgress").pipe(
            filter((uploadProgress) => uploadProgress === undefined),
            switchMap(() => {
                const error = this.localStore.selectSnapshotByKey("uploadError");
                if (error) {
                    throw new Error(error);
                }

                return this.localStore.selectByKey("imageId").pipe(RxjsUtils.filterFalsy());
            })
        );
    }

    private updateFileUploadProgress(uploadProgress: number) {
        this.localStore.patchState({ uploadProgress, uploadError: undefined });
    }

    private finishUpload(id: string) {
        this.localStore.patchState({ uploadProgress: undefined });
        this.imageId = id;
    }

    private finishUploadWithError(file: File, type: FileUploadErrorType): void {
        let error!: string;

        switch (type) {
            case FileUploadErrorType.MaxSizeExceeded:
                error = this.transloco.translate("dtmUi.filesUploadField.invalidFileSizeError");
                break;
            case FileUploadErrorType.InvalidType:
                error = this.getInvalidTypeError(file);
                break;
            default:
                error = this.transloco.translate("dtmUi.filesUploadField.unknownError");
        }

        this.localStore.patchState({ uploadError: error, uploadProgress: undefined });
    }

    private getInvalidTypeError(file: File) {
        const invalidExtensionName = file.name.slice(file.name.lastIndexOf("."));

        return this.transloco.translate("dtmUi.filesUploadField.invalidFileTypeError", {
            type: file.type,
            extension: invalidExtensionName,
        });
    }

    private confirmLeaveEditMode(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.uavImageControl.confirmLeaveEditModeDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibUav.uavImageControl.confirmLeaveEditModeDialog.contentText"),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.uavImageControl.confirmLeaveEditModeDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.uavImageControl.confirmLeaveEditModeDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
