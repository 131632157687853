import { Address, Alpha3CountryCode, BasicAddress, PhoneNumber } from "@dtm-frontend/shared/ui";

export enum LegalGuardianDetailsErrorType {
    Unknown = "LegalGuardianDetailsErrorUnknown",
    CannotGeneratePdf = "CannotGeneratePdf",
    CannotSendForElectronicSignature = "CannotSendForElectronicSignature",
    CannotVerifyElectronicSignature = "CannotVerifyElectronicSignature",
}

export interface LegalGuardianDetailsError {
    type: LegalGuardianDetailsErrorType;
}

export interface LegalGuardianGeneralData {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth?: Date;
    nationality?: string;
    phoneNumber?: PhoneNumber;
    address?: BasicAddress;
    residenceCountry?: Alpha3CountryCode;
}

export interface LegalGuardianFormValue {
    dateOfBirth: string;
    nationality: Alpha3CountryCode;
    phoneNumber: PhoneNumber;
    address: Address;
}

export const NOT_FOUND_PAGE = "not-found";
export const SUCCESS_PAGE = "success";
