import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective, PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { FormType, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { skip } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { ApplicationInfoData } from "../../../../services/specific-permit-application.models";

interface ApplicationInfoStepComponentState {
    isSaveDraftProcessing: boolean;
    isProcessing: boolean;
    initialValues: ApplicationInfoData | undefined;
}

const MAX_FULL_NAME_LENGTH = 255;
const MAX_ADDITIONAL_INFO_LENGTH = 10000;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-application-info-step",
    templateUrl: "./application-info-step.component.html",
    styleUrls: ["../../../common.scss", "./application-info-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ApplicationInfoStepComponent {
    @Input() public set isSaveDraftProcessing(value: BooleanInput) {
        this.localStore.patchState({ isSaveDraftProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set initialValues(value: ApplicationInfoData | undefined) {
        this.localStore.patchState({ initialValues: value });

        this.applicationInfoForm.patchValue(value ?? this.getDefaultApplicationInfoFormValues());
    }

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly saveDraft = new EventEmitter<ApplicationInfoData>();
    @Output() public readonly applicationGenerate = new EventEmitter<ApplicationInfoData>();
    @Output() public readonly valueChange = new EventEmitter<void>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isSaveDraftProcessing$ = this.localStore.selectByKey("isSaveDraftProcessing");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    protected readonly MAX_ADDITIONAL_INFO_LENGTH = MAX_ADDITIONAL_INFO_LENGTH;

    protected readonly applicationInfoForm = new FormGroup<FormType<ApplicationInfoData>>({
        responsibleManager: new FormControl<string | null>(null, [
            Validators.required,
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            Validators.maxLength(MAX_FULL_NAME_LENGTH),
        ]),
        contactPersonFullName: new FormControl<string | null>(null, [
            Validators.required,
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            Validators.maxLength(MAX_FULL_NAME_LENGTH),
        ]),
        contactPersonPhone: new FormControl<PhoneNumber | null>(null, requiredValidForSmsPhoneNumberValidator),
        contactPersonEmail: new FormControl<string | null>(null, [Validators.required, Validators.email]),
        additionalNote: new FormControl<string | null>(null, [Validators.maxLength(MAX_ADDITIONAL_INFO_LENGTH)]),
    });

    constructor(private readonly localStore: LocalComponentStore<ApplicationInfoStepComponentState>) {
        this.localStore.setState({
            isSaveDraftProcessing: false,
            isProcessing: false,
            initialValues: undefined,
        });

        this.applicationInfoForm.valueChanges.pipe(distinctUntilChanged(equal), skip(1), untilDestroyed(this)).subscribe(() => {
            this.valueChange.emit();
        });
    }

    protected generateApplication(): void {
        this.applicationInfoForm.markAllAsTouched();
        this.invalidFormScrollable.scrollToFirstInvalidField();

        if (!this.applicationInfoForm.valid) {
            return;
        }

        this.applicationGenerate.emit(this.getApplicationInfo());
    }

    protected saveDataToDraft(): void {
        this.saveDraft.emit(this.getApplicationInfo());
    }

    private getApplicationInfo(): ApplicationInfoData {
        return this.applicationInfoForm.getRawValue();
    }

    private getDefaultApplicationInfoFormValues(): ApplicationInfoData {
        return {
            responsibleManager: null,
            contactPersonFullName: null,
            contactPersonPhone: null,
            contactPersonEmail: null,
            additionalNote: null,
        };
    }
}
