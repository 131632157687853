<div
    *ngrxLet="{
        activeZones: activeZones$,
        waitingZones: waitingZones$,
        additionalZones: additionalZones$,
        isProcessing: isProcessing$,
        airspaceAnalysis: airspaceAnalysis$,
        evaluationIssues: evaluationIssues$,
        doesAupExist: doesAupExist$,
        selectedZoneId: selectedZoneId$,
        airacEndTime: airacEndTime$,
    } as vm"
    [class.processing]="vm.isProcessing"
>
    <dtm-ui-expandable-panel [isExpanded]="false" [hasHeaderSeparator]="true" #panel>
        <h2
            headerSlot
            class="title"
            [ngSwitch]="
                [vm.activeZones, vm.waitingZones, vm.additionalZones]
                    | invoke : getZonesAnalysisStatus : vm.isProcessing : vm.evaluationIssues
            "
        >
            <dtm-ui-icon name="checkbox-circle-fill" *ngSwitchCase="ZoneAnalysisStatus.Success"> </dtm-ui-icon>
            <dtm-ui-icon name="information-fill" class="information" *ngSwitchCase="ZoneAnalysisStatus.Information"></dtm-ui-icon>
            <dtm-ui-icon name="error-warning-fill" class="warning" *ngSwitchCase="ZoneAnalysisStatus.Warning"></dtm-ui-icon>
            <dtm-ui-icon name="error-warning-fill" class="error" *ngSwitchCase="ZoneAnalysisStatus.Error"></dtm-ui-icon>
            {{ "dtmWebAppLibShared.mission.analysis.zone.airspaceAnalysisLabel" | transloco }}
            <dtm-ui-loader [isShown]="vm.isProcessing" [diameter]="30" *ngIf="!panel.expanded"></dtm-ui-loader>
        </h2>
        <div class="content">
            <dtm-ui-loader-container [isShown]="vm.isProcessing && panel.expanded">
                <ng-container *ngIf="vm.activeZones.length || vm.waitingZones.length || !vm.isProcessing">
                    <h2 class="section-title" [innerHTML]="'dtmWebAppLibShared.mission.analysis.zone.activeZonesLabel' | transloco"></h2>
                    <ng-container *ngIf="vm.activeZones.length; else noCollisionInfoTemplate">
                        <dtm-mission-zone-info
                            *ngFor="let zone of vm.activeZones"
                            [zone]="zone"
                            [analysisIssues]="zone | invoke : getAirspaceWarningForZone : vm.airspaceAnalysis"
                            [evaluationIssues]="zone | invoke : getEvaluationIssuesForZone : vm.evaluationIssues"
                            [isZoneSelected]="vm.selectedZoneId === zone.id"
                            [doesAupExist]="vm.doesAupExist"
                            [airacEndTime]="vm.airacEndTime"
                            (zoneSelectionChange)="zoneSelect.emit($event)"
                        ></dtm-mission-zone-info>
                    </ng-container>
                    <h2 class="section-title" [innerHTML]="'dtmWebAppLibShared.mission.analysis.zone.waitingZonesLabel' | transloco"></h2>
                    <ng-container *ngIf="vm.waitingZones.length; else noCollisionInfoTemplate">
                        <div class="info-container" *ngIf="!vm.doesAupExist">
                            <dtm-ui-icon name="information-fill"></dtm-ui-icon>
                            <p [innerHTML]="'dtmWebAppLibShared.mission.analysis.zone.waitingZonesInfoLabel' | transloco"></p>
                        </div>
                        <dtm-mission-zone-info
                            *ngFor="let zone of vm.waitingZones"
                            [zone]="zone"
                            [analysisIssues]="zone | invoke : getAirspaceWarningForZone : vm.airspaceAnalysis"
                            [evaluationIssues]="zone | invoke : getEvaluationIssuesForZone : vm.evaluationIssues"
                            [isZoneSelected]="vm.selectedZoneId === zone.id"
                            [doesAupExist]="vm.doesAupExist"
                            [airacEndTime]="vm.airacEndTime"
                            (zoneSelectionChange)="zoneSelect.emit($event)"
                        ></dtm-mission-zone-info>
                    </ng-container>
                </ng-container>
            </dtm-ui-loader-container>
        </div>
        <ng-content></ng-content>
    </dtm-ui-expandable-panel>
    <ng-template #noCollisionInfoTemplate>
        <div class="chip-container">
            <div class="chip success">
                <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                <p>{{ "dtmWebAppLibShared.mission.analysis.zone.noCollisionLabel" | transloco }}</p>
            </div>
        </div>
    </ng-template>
</div>
