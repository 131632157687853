import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { AssociationPermitDetailsComponent } from "./components/operator-permits-container/components/association-permit-details/association-permit-details.component";
import { ChangeNameDialogComponent } from "./components/operator-permits-container/components/change-name-dialog/change-name-dialog.component";
import { KmlPreviewDialogComponent } from "./components/operator-permits-container/components/kml-preview-dialog/kml-preview-dialog.component";
import { OwnedPermitsContainerComponent } from "./components/operator-permits-container/components/owned-permits-container/owned-permits-container.component";
import { PermitPreviewComponent } from "./components/operator-permits-container/components/permit-preview/permit-preview.component";
import { PermitsDraftsContainerComponent } from "./components/operator-permits-container/components/permits-drafts-container/permits-drafts-container.component";
import { PermitsOwnedFiltersComponent } from "./components/operator-permits-container/components/permits-owned-filters/permits-owned-filters.component";
import { PermitsOwnedListComponent } from "./components/operator-permits-container/components/permits-owned-list/permits-owned-list.component";
import { SpecificPermitDraftsFiltersComponent } from "./components/operator-permits-container/components/specific-permit-drafts-filters/specific-permit-drafts-filters.component";
import { SpecificPermitDraftsListComponent } from "./components/operator-permits-container/components/specific-permit-drafts-list/specific-permit-drafts-list.component";
import { OperatorPermitsContainerComponent } from "./components/operator-permits-container/operator-permits-container.component";
import { AssociationPermitsResolver } from "./services/association-permits.resolver";
import { OperatorPermitsApiService } from "./services/operator-permits.api.service";
import { CrossBorderPermitsResolver, OperatorPermitsResolver } from "./services/operator-permits.resolver";
import { OperatorPermitsState } from "./state/operator-permits.state";

@NgModule({
    declarations: [
        OperatorPermitsContainerComponent,
        PermitsOwnedListComponent,
        SpecificPermitDraftsListComponent,
        PermitsOwnedFiltersComponent,
        SpecificPermitDraftsFiltersComponent,
        ChangeNameDialogComponent,
        PermitPreviewComponent,
        AssociationPermitDetailsComponent,
        KmlPreviewDialogComponent,
        OwnedPermitsContainerComponent,
        PermitsDraftsContainerComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        NgxsModule.forFeature([OperatorPermitsState]),
        RouterModule,
        SharedUiModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedAuthModule,
        SharedWebAppModule,
        MatCardModule,
        MatChipsModule,
        MatTabsModule,
        MatTableModule,
        MatMenuModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatDialogModule,
    ],
    providers: [
        OperatorPermitsResolver,
        AssociationPermitsResolver,
        CrossBorderPermitsResolver,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLibOperatorPermits",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: OperatorPermitsApiService,
            useValue: undefined,
        },
    ],
    exports: [
        PermitsOwnedListComponent,
        SpecificPermitDraftsListComponent,
        PermitsOwnedFiltersComponent,
        SpecificPermitDraftsFiltersComponent,
    ],
})
export class OperatorPermitsModule {
    public static forRoot(): ModuleWithProviders<OperatorPermitsModule> {
        return {
            ngModule: OperatorPermitsModule,
            providers: [
                {
                    provide: OperatorPermitsApiService,
                    useClass: OperatorPermitsApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<OperatorPermitsModule> {
        return {
            ngModule: OperatorPermitsModule,
            providers: [
                {
                    provide: OperatorPermitsApiService,
                    useValue: null,
                },
            ],
        };
    }
}
