import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../../../../shared/operator-context/state/operator-context.state";
import { OperatorPermissionsActions } from "../../../../state/operator-permissions.actions";
import { OperatorPermissionsState } from "../../../../state/operator-permissions.state";

interface SignStatementStatusComponentState {
    isDataMissingError: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-sign-statement-status",
    templateUrl: "./sign-statement-status.component.html",
    styleUrls: ["./sign-statement-status.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SignStatementStatusComponent {
    protected readonly isSuccess$ = this.store.select(OperatorPermissionsState.isStatementSignSuccess);
    protected readonly isDataMissingError$ = this.localStore.selectByKey("isDataMissingError");
    protected readonly statementId$ = this.route.queryParams.pipe(map((queryParams) => queryParams.statementId));

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly localStore: LocalComponentStore<SignStatementStatusComponentState>
    ) {
        localStore.setState({ isDataMissingError: false });
        this.getStatus();
    }

    private async getStatus() {
        const statementId = this.route.snapshot.queryParams.statementId;
        const selectedContextId = await firstValueFrom(
            this.store.select(OperatorContextState.selectedContextId).pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
        );

        if (!statementId || !selectedContextId) {
            this.localStore.patchState({ isDataMissingError: true });

            return;
        }

        this.store.dispatch(new OperatorPermissionsActions.GetPermissionSignatureStatus(statementId, selectedContextId));
    }
}
