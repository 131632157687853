<dtm-ui-label-value class="tiles-wrapper" [label]="'dtmWebAppLibSpecPermApp.operationDetailsStep.timeOfDay.timeOfDayLabel' | transloco">
    <div class="tiles">
        <div
            *ngFor="let timeOfDay of TimeOfTheDay | keyvalue : keepOrder"
            class="selectable-tile"
            tabindex="0"
            [class.selected]="timeOfDayForm.controls.timeOfDay.value === timeOfDay.value"
            (click)="setTimeOfTheDay(timeOfDay.value)"
            (keyup.enter)="setTimeOfTheDay(timeOfDay.value)"
        >
            <span class="selectable-tile-label">
                <ng-container [ngSwitch]="timeOfDay.value">
                    <dtm-ui-icon *ngSwitchCase="TimeOfTheDay.Day" name="sun"></dtm-ui-icon>
                    <dtm-ui-icon *ngSwitchCase="TimeOfTheDay.Night" name="moon"></dtm-ui-icon>
                    <dtm-ui-icon *ngSwitchCase="TimeOfTheDay.DayAndNight" name="time"></dtm-ui-icon>
                </ng-container>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.timeOfDay.timeOfDayValue" | transloco : { value: timeOfDay.value } }}
            </span>
        </div>
    </div>
</dtm-ui-label-value>

<div class="time-range" *ngIf="timeOfDayForm.controls.timeFrom && timeOfDayForm.controls.timeTo">
    <dtm-ui-time-field [formControl]="timeOfDayForm.controls.timeFrom" required>
        <label>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.timeOfDay.timeFromLabel" | transloco }}</label>
        <div
            class="field-error"
            *dtmUiFieldHasError="timeOfDayForm.controls.timeFrom; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-time-field>

    <dtm-ui-time-field [formControl]="timeOfDayForm.controls.timeTo" required>
        <label>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.timeOfDay.timeToLabel" | transloco }}</label>
        <div
            class="field-error"
            *dtmUiFieldHasError="timeOfDayForm.controls.timeTo; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-time-field>
</div>

<div *ngIf="timeOfDayForm.controls.isNightFlightStatement">
    <dtm-ui-checkbox-field
        [formControl]="timeOfDayForm.controls.isNightFlightStatement"
        [required]="timeOfDayForm.controls.timeOfDay.value === TimeOfTheDay.Night"
    >
        <span class="label">{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.timeOfDay.statementLabel" | transloco }}</span>
        <dtm-ui-popover
            [popoverText]="'dtmWebAppLibSpecPermApp.operationDetailsStep.timeOfDay.statementPopoverText' | transloco"
            (click)="$event.preventDefault()"
        ></dtm-ui-popover>
    </dtm-ui-checkbox-field>
    <dtm-ui-form-errors class="preserve-error-space">
        <div
            class="field-error"
            *dtmUiFieldHasError="timeOfDayForm.controls.isNightFlightStatement; name: 'required'"
            [ngTemplateOutlet]="requiredErrorTemplate"
        ></div>
    </dtm-ui-form-errors>
</div>
<ng-template #requiredErrorTemplate>
    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.timeOfDay.requiredFieldError" | transloco }}
</ng-template>
