import { Address, OperatorAndPilotQualification, OperatorStatus, PhoneNumber } from "@dtm-frontend/shared/ui";
import { OperatorPilotOtherInformation } from "../../shared/models/pilot-and-operator.models";

export enum OperatorProfileErrorType {
    Unknown = "OperatorProfileErrorErrorUnknown",
    NotFound = "OperatorProfileErrorErrorNotFound",
    NotAuthorized = "OperatorProfileErrorNotAuthorized",
    CannotSaveOperatorCompanyInfo = "CannotSaveOperatorCompanyInfo",
    CannotSaveOperatorAvatar = "CannotSaveOperatorAvatar",
    CannotSaveOperatorInsurancePolicy = "CannotSaveOperatorInsurancePolicy",
    CannotDownloadOperatorConfirmationDocument = "CannotDownloadOperatorConfirmationDocument",
    CannotDeleteOperatorInsurancePolicy = "CannotDeleteOperatorInsurancePolicy",
    CannotDeleteOperatorAvatar = "CannotDeleteOperatorAvatar",
    CannotGetAttorneyPowerCapabilities = "CannotGetAttorneyPowerCapabilities",
    CannotGetAttorneyPowerModelDocument = "CannotGetAttorneyPowerModelDocument",
    CannotAddAttorneyPower = "CannotAddAttorneyPower",
    CannotRemoveAttorneyPower = "CannotRemoveAttorneyPower",
    CompanyNumberConflicted = "CompanyNumberConflicted",
}

export interface OperatorProfileError {
    type: OperatorProfileErrorType;
}

export interface Owner {
    userId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    nationality: string;
    phoneNumber: PhoneNumber;
    email: string;
    isLegalAge: boolean;
}

export interface OperatorNumber {
    secretCode: string;
    number: string;
}

export interface Operator {
    id: string;
    status: OperatorStatus;
    suspensionReason: string;
    operatorNumber: OperatorNumber;
    email: string;
    phoneNumber: PhoneNumber;
    type: string;
    name: string;
    companyNumber?: string;
    insurancePolicyNumber: string | undefined;
    insurancePolicyExpirationDate: string | undefined;
    address: Address;
    owner: Owner;
    isPersonOfLegalAge: boolean;
    pilotId: string;
    qualifications: OperatorAndPilotQualification[];
    isAttorneyPowerRequired: boolean;
    attorneyPowers?: OperatorAttorneyPower[];
    isAssociation: boolean;
    associationRegistration?: OperatorAssociationRegistration;
    isEmergency: boolean;
    documentsToDownload: OperatorConfirmationDocumentType[];
    otherInformation: OperatorPilotOtherInformation[];
}

export interface CompanyInfo {
    name: string;
    companyNumber?: string;
    address: Address;
    phoneNumber: PhoneNumber;
    email: string;
    associationRegistration?: OperatorAssociationRegistration;
}

export interface OperatorAttorneyPower {
    id: string;
    grantee: OperatorAttorneyPowerGrantee;
    type: AttorneyPowerType;
    status: AttorneyPowerStatus;
    nationalCourtRegister?: string;
    documentId?: string;
    feeDocumentId?: string;
    permissions: OperatorAttorneyPowerPermission[];
}

export enum OperatorAttorneyPowerPermission {
    SpecificPermitApply = "SPECIFIC_PERMIT_APPLY",
    MakingStatements = "MAKING_STATEMENTS",
}

export type OperatorAttorneyPowerMember = OperatorAttorneyPowerGrantee;

export interface OperatorAttorneyPowerCapabilities {
    members: OperatorAttorneyPowerMember[];
}

export interface OperatorAttorneyPowerGrantee {
    userId: string;
    firstName: string;
    lastName: string;
}

export enum AttorneyPowerType {
    Owner = "OWNER",
    NationalCourtNumber = "NATIONAL_COURT_NUMBER",
    ConfirmatoryDocument = "CONFIRMATORY_DOCUMENT",
}

export enum AttorneyPowerStatus {
    PendingApproval = "PENDING_APPROVAL",
    Approved = "APPROVED",
}

export interface NewAttorneyPower {
    granteeUserId: string;
    type: AttorneyPowerType;
    nationalCourtRegister?: string;
    documentId?: string;
    feeConfirmationId?: string;
}

export interface OperatorAssociationRegistration {
    nationalCourtRegister?: string;
    registrationNumber?: string;
}

export enum OperatorConfirmationDocumentType {
    RegistrationConfirmation = "registration-confirmation",
}
