import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { Address, BasicAddress } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged } from "rxjs/operators";
import { BasicCompanyInfo, ImportedPersonalOperatorDetails, PersonalOperatorFormValue } from "../../../models/operator-migration.models";
import { getImportedPostCodeFormValue } from "../../../utils/get-imported-post-code-value";

interface OperatorMigrationPersonalOperatorInfoComponentState {
    personalOperatorDetails: ImportedPersonalOperatorDetails | undefined;
}
interface PersonalOperatorInfoForm {
    country: FormControl<string | null>;
    address: FormControl<BasicAddress | null>;
    companyData: FormControl<BasicCompanyInfo | null>;
    isCompany: FormControl<boolean | null>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-migration-personal-operator-info[personalOperatorDetails]",
    templateUrl: "./operator-migration-personal-operator-info.component.html",
    styleUrls: ["./operator-migration-personal-operator-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OperatorMigrationPersonalOperatorInfoComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => OperatorMigrationPersonalOperatorInfoComponent),
            multi: true,
        },
    ],
})
export class OperatorMigrationPersonalOperatorInfoComponent implements ControlValueAccessor, Validator {
    @Input() public set personalOperatorDetails(value: ImportedPersonalOperatorDetails | undefined) {
        this.localStore.patchState({ personalOperatorDetails: value });
        if (!value) {
            this.personalOperatorInfoForm.reset();

            return;
        }

        this.assignFormValues(value);
        this.propagateFormValueChange();
    }

    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly personalOperatorDetails$ = this.localStore.selectByKey("personalOperatorDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly personalOperatorInfoForm = new FormGroup<PersonalOperatorInfoForm>({
        address: new FormControl<Address | null>(null, Validators.required),
        companyData: new FormControl<BasicCompanyInfo | null>({ value: null, disabled: true }, Validators.required),
        country: new FormControl<string | null>(null, Validators.required),
        isCompany: new FormControl<boolean | null>(null, Validators.required),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: PersonalOperatorFormValue | null) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<OperatorMigrationPersonalOperatorInfoComponentState>) {
        localStore.setState({ personalOperatorDetails: undefined });

        this.personalOperatorInfoForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            this.propagateFormValueChange();
        });
    }

    public registerOnChange(fn: (value: PersonalOperatorFormValue | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: PersonalOperatorFormValue | null): void {
        if (value) {
            this.personalOperatorInfoForm.patchValue(value);
        }
    }

    public validate(): ValidationErrors | null {
        if (this.personalOperatorInfoForm.valid) {
            return null;
        }

        return { personalOperatorInfoFormInvalid: true };
    }

    public isCompanyValueChange(isCompany: boolean) {
        if (isCompany) {
            this.personalOperatorInfoForm.controls.companyData.enable();
            this.personalOperatorInfoForm.controls.country.setValue(DEFAULT_COUNTRY_CODE);
            this.personalOperatorInfoForm.controls.country.disable();

            return;
        }

        this.personalOperatorInfoForm.controls.companyData.disable();
        this.personalOperatorInfoForm.controls.companyData.reset();
        this.personalOperatorInfoForm.controls.country.enable();
    }

    private propagateFormValueChange() {
        const { address, country, companyData } = this.personalOperatorInfoForm.getRawValue();

        this.propagateChange({
            address: { ...(address as BasicAddress), country: country ?? undefined },
            companyData,
        });
        this.onValidationChange();
    }

    private assignFormValues({ personalData }: ImportedPersonalOperatorDetails) {
        this.personalOperatorInfoForm.patchValue({
            address: {
                streetName: personalData.address.streetName,
                houseNumber: personalData.address.houseNumber,
                apartmentNumber: personalData.address.apartmentNumber,
                city: personalData.address.city,
                postCode: getImportedPostCodeFormValue(personalData.address.postCode),
            },
            companyData: null,
            country: DEFAULT_COUNTRY_CODE,
        });
    }
}
