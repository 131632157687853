import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AnimationUtils, ArrayUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { AttorneyPowerStatus, AttorneyPowerType, OperatorAttorneyPower } from "../../models/operator.models";

interface AttorneyPowerListComponentState {
    attorneyPowers: OperatorAttorneyPower[];
    companyName: string | undefined;
}

@Component({
    selector: "dtm-web-app-lib-attorney-power-list[attorneyPowers][companyName]",
    templateUrl: "./attorney-power-list.component.html",
    styleUrls: ["./attorney-power-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AttorneyPowerListComponent {
    @Input() public set attorneyPowers(value: OperatorAttorneyPower[] | undefined) {
        this.localStore.patchState({ attorneyPowers: value ?? [] });
    }
    @Input() public set companyName(value: string | undefined) {
        this.localStore.patchState({ companyName: value });
    }
    @Output() public formInit = new EventEmitter<void>();
    @Output() public remove = new EventEmitter<string>();

    protected readonly AttorneyPowerStatus = AttorneyPowerStatus;
    protected readonly AttorneyPowerType = AttorneyPowerType;
    protected readonly companyName$ = this.localStore.selectByKey("companyName").pipe(RxjsUtils.filterFalsy());
    public readonly slides$ = this.localStore
        .selectByKey("attorneyPowers")
        .pipe(map((slides) => ArrayUtils.groupByCount(slides, 1).map((cards) => ({ cards }))));

    constructor(private readonly localStore: LocalComponentStore<AttorneyPowerListComponentState>) {
        localStore.setState({
            attorneyPowers: [],
            companyName: undefined,
        });
    }
}
