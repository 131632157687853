<div class="dialog-header">
    <div mat-dialog-title>
        <h4>{{ "dtmWebAppLibOperatorPermissions.permissionUavDetails.header" | transloco }}</h4>
        <p class="permission">
            {{
                "dtmWebAppLibOperatorPermissions.permissionUavDetails.forPermissionNameHeaderSubValue"
                    | transloco : { permissionName: permissionUavDetails.operationalScenarioName }
            }}
        </p>
    </div>
    <button type="button" class="button-icon" [matDialogClose]="null">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<mat-dialog-content>
    <dtm-web-app-lib-detached-serial-numbers
        *ngIf="permissionUavDetails.detachedSerialNumbers?.length"
        [detachedSerialNumbers]="permissionUavDetails.detachedSerialNumbers"
    ></dtm-web-app-lib-detached-serial-numbers>

    <ul class="uavs-list-container" *ngIf="permissionUavDetails.uavs.length">
        <li *ngFor="let uav of permissionUavDetails.uavs">
            <div class="uav-header">
                {{ uav.name }}
                <span
                    *ngIf="uav.isCustom"
                    class="chip light"
                    [matTooltip]="'dtmWebAppLibOperatorPermissions.permissionUavDetails.customUavInfo' | transloco"
                >
                    <dtm-ui-icon name="pencil-ruler"></dtm-ui-icon>
                </span>
            </div>
            <div class="uav-details">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.permissionUavDetails.manufacturerAndModelLabel' | transloco"
                    [value]="uav.manufacturer"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.permissionUavDetails.typeLabel' | transloco"
                    [value]="uav.type"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.permissionUavDetails.ownerLabel' | transloco"
                    [value]="uav.ownerName"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [canCopy]="true"
                    [label]="
                        'dtmWebAppLibOperatorPermissions.permissionUavDetails.serialNumberLabel'
                            | transloco : { numberOfSerialNumbers: uav.serialNumbers.length }
                    "
                >
                    <span *ngFor="let serialNumber of uav.serialNumbers" class="serial-number-item">{{ serialNumber }}</span>
                </dtm-ui-label-value>
            </div>
        </li>
    </ul>
</mat-dialog-content>

<mat-dialog-actions class="actions">
    <button type="button" [mat-dialog-close]="null" class="button-primary">
        {{ "dtmWebAppLibOperatorPermissions.permissionUavDetails.closeDialogLabel" | transloco }}
    </button>
</mat-dialog-actions>
