<form [formGroup]="additionalCrewForm">
    <dtm-ui-select-field
        [multiple]="true"
        [formControl]="additionalCrewForm.controls.members"
        [placeholder]="'dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.selectMembersPlaceholder' | transloco"
    >
        <label>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.selectMembersLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let member of availableCrewMembers$ | ngrxPush" [value]="member">
            {{ "mission.additional_crew_members." + member + ".name" | systemTranslation }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <p class="responsibilities-info">
        {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.responsibilitiesInfoText" | transloco }}
    </p>
    <ul class="details-list">
        <li *ngFor="let detailsForm of additionalCrewForm.controls.details.controls" class="item">
            <h2 class="details-header">
                {{ "mission.additional_crew_members." + detailsForm.value.member + ".name" | systemTranslation }}
            </h2>
            <dtm-ui-expanded-number-input
                *ngIf="detailsForm.value.member! | invoke : hasMemberAmount"
                [formControl]="detailsForm.controls.amount"
                [minValue]="1"
                [stepValue]="1"
            >
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.uavObserversAmountLabel" | transloco }}
                </label>
                <div class="field-error" *dtmUiFieldHasError="detailsForm.controls.amount; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="detailsForm.controls.amount; name: 'min'">
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.uavObserversMinAmountErrorLabel" | transloco }}
                </div>
            </dtm-ui-expanded-number-input>
            <dtm-ui-textarea-field [maxLength]="MAX_RESPONSIBILITIES_LENGTH">
                <label>
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.responsibilitiesLabel"
                            | transloco
                                : { role: "mission.additional_crew_members." + detailsForm.value.member + ".name" | systemTranslation }
                    }}
                </label>
                <textarea [rows]="5" matInput [formControl]="detailsForm.controls.responsibilities"></textarea>
                <div class="field-error" *dtmUiFieldHasError="detailsForm.controls.responsibilities; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="detailsForm.controls.responsibilities; name: 'maxlength'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.responsibilitiesMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
        </li>
    </ul>
</form>
