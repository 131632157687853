<form [formGroup]="standardUavForm">
    <dtm-ui-select-field
        #uavModelSelect
        [placeholder]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.modelPlaceholder' | transloco"
        [isClearable]="false"
        [formControl]="standardUavForm.controls.uavModel"
        (panelClose)="uavModelSearchControl.reset('')"
        (openedChange)="uavModelsSearch?.focus()"
        [shouldApplyOffsetTop]="true"
        *ngrxLet="uavModels$ as uavModels"
    >
        <label>{{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.modelLabel" | transloco }}</label>
        <dtm-ui-input-field class="model-search">
            <input
                #uavModelsSearch
                matInput
                [formControl]="uavModelSearchControl"
                [placeholder]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.modelSearchLabel' | transloco"
                (keydown.space)="$event.stopPropagation()"
                (keydown.enter)="selectActiveUavModel()"
            />
        </dtm-ui-input-field>
        <dtm-ui-select-option *ngFor="let uavModel of uavModels" [value]="uavModel">
            {{ uavModel.name }}
        </dtm-ui-select-option>
        <p *ngIf="!uavModels.length" class="empty-models-info">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.modelSearchEmptyResultsLabel" | transloco }}
        </p>
        <div class="field-error" *dtmUiFieldHasError="standardUavForm.controls.uavModel; name: 'required'">
            {{ "dtmWebAppLibUav.newUavWizardSteps.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-select-field>
    <dtm-web-app-lib-uav-swarm-control [formControl]="standardUavForm.controls.isSwarm"></dtm-web-app-lib-uav-swarm-control>
    <dtm-ui-serial-numbers-control
        dtmUiMarkValueAccessorControlsAsTouched
        [formControl]="standardUavForm.controls.serialNumbers"
        [isSingle]="!standardUavForm.controls.isSwarm.value"
    ></dtm-ui-serial-numbers-control>
    <dtm-ui-select-field
        [placeholder]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavClassPlaceholder' | transloco"
        [isClearable]="false"
        [formControl]="standardUavForm.controls.uavClasses"
        [multiple]="true"
    >
        <label class="label-with-popover">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavClassLabel" | transloco }}
            <dtm-ui-popover [popoverText]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavClassHelpInfo' | transloco"></dtm-ui-popover>
        </label>
        <dtm-ui-select-option *ngFor="let uavClass of uavClasses" [value]="uavClass">
            {{ "dtmWebAppLibUav.uavClassValue" | transloco : { value: uavClass } }}
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <dtm-ui-input-field>
        <label class="label-with-popover">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.nameLabel" | transloco }}
            <dtm-ui-popover [popoverText]="'dtmWebAppLibUav.newUavWizardSteps.basicInfo.namePopover' | transloco"></dtm-ui-popover>
        </label>
        <input matInput type="text" [formControl]="standardUavForm.controls.name" required autocomplete="off" />
        <div class="field-error" *dtmUiFieldHasError="standardUavForm.controls.name; name: 'maxlength'; error as error">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.nameMaxLengthValueError" | transloco : { maxLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="standardUavForm.controls.name; name: 'minlength'; error as error">
            {{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.nameMinLengthValueError" | transloco : { minLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="standardUavForm.controls.name; name: ['required', 'pattern']">
            {{ "dtmWebAppLibUav.newUavWizardSteps.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-input-field>
    <ng-container *ngrxLet="uavModelImageUrl$ as uavModelImageUrl">
        <dtm-ui-loader-container
            class="uav-image"
            *ngrxLet="isModelImageUrlProcessing$ as isModelImageUrlProcessing"
            [isShown]="isModelImageUrlProcessing"
        >
            <img
                *ngIf="uavModelImageUrl"
                [dtmUiImgSecuredSrc]="uavModelImageUrl"
                (loadingChange)="setModelImageUrlProcessing($event)"
                alt=""
            />
            <img *ngIf="!uavModelImageUrl" src="assets/images/uav-no-photo.svg" alt="" />
        </dtm-ui-loader-container>
    </ng-container>
</form>
