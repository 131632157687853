import { METERS_IN_KILOMETER, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { Communication } from "../../../../services/uav.models";

export type EditableCommunication = Communication;

export class CommunicationsUtils {
    public static getFormValuesBySetupDefaults(communications: EditableCommunication[]): EditableCommunication[] {
        return communications.map((communication) => ({
            ...communication,
            linkDelay: CommunicationsUtils.convertSecondsToMilliseconds(communication.linkDelay),
            transmissionLinkRange: CommunicationsUtils.convertMetersToKilometers(communication.transmissionLinkRange),
        }));
    }

    public static prepareResultCommunicationsSetup(communications: EditableCommunication[]): EditableCommunication[] {
        return communications.map((communication) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const result: EditableCommunication = {
                type: communication.type,
                linkType: communication.linkType,
                linkDelay: CommunicationsUtils.convertMillisecondsToSeconds(communication.linkDelay),
                transmissionLinkRange: CommunicationsUtils.convertKilometersToMeters(communication.transmissionLinkRange),
                frequencies: communication.frequencies,
                isEmbedded: communication.isEmbedded,
            };

            if (communication.id) {
                result.id = communication.id;
            }

            return result;
        });
    }

    private static convertSecondsToMilliseconds(value: number | null) {
        return value === null ? null : value * MILLISECONDS_IN_SECOND;
    }

    private static convertMillisecondsToSeconds(value: number | null) {
        return value === null ? null : value / MILLISECONDS_IN_SECOND;
    }

    private static convertMetersToKilometers(value: number) {
        return value / METERS_IN_KILOMETER;
    }

    private static convertKilometersToMeters(value: number) {
        return value * METERS_IN_KILOMETER;
    }
}
