import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import { UavClass } from "../../shared/models/uav.models";
import {
    AvailableUav,
    OperationScenarioToSign,
    OperatorPermissionsCapabilities,
    OperatorPermissionsError,
    OperatorPermissionsErrorType,
    OperatorPermissionsOperatorDetails,
    OperatorPermissionsStatementToSign,
    OperatorStatement,
    OperatorStatementListWithPages,
    OwnedOperatorPermission,
    PaymentStatusInfoCode,
    PermissionUavDetails,
    SavedStatement,
    UavDetails,
} from "./operator-permissions.models";

interface UavDetailsResponseBody extends Omit<UavDetails, "isCustom"> {
    custom: boolean;
}

export interface PermissionUavDetailsResponseBody extends Omit<PermissionUavDetails, "uavs"> {
    uavs: UavDetailsResponseBody[];
}

export interface OperatorPermissionsStatementToSignRequest {
    operationScenarios: OperationScenarioToSign[];
    attorneyPowerAdministrativeFee?: {
        feeConfirmationId?: string;
        declarationOfExemption: boolean;
    };
}

export interface OperatorPermissionsListResponseBody extends Omit<OwnedOperatorPermission, "expirationDate"> {
    expirationDate: string;
}

export interface SinglePermissionResponseBody {
    id: string;
    name: string;
    description: string;
    grantValidityInMonths: number;
    price: number;
    allowedUavClasses?: UavClass[];
}

export interface OperationalAuthorizationResponseBody extends SinglePermissionResponseBody {
    uavs: AvailableUav[];
    scenarioId: string;
}

export interface AvailableOperationScenarioResponseBody extends SinglePermissionResponseBody {
    uavsRequired: boolean;
}

export interface OperatorPermissionsCapabilitiesResponseBody {
    operator: OperatorPermissionsOperatorDetails;
    operatorUavs: AvailableUav[];
    attorneyPowerAdministrativeFeeRequired: boolean;
    availableOperationScenarios?: AvailableOperationScenarioResponseBody[];
    operationalAuthorizations?: OperationalAuthorizationResponseBody[];
}

interface OperatorStatementResponseBody extends Omit<OperatorStatement, "createdAt" | "attorneyPowerAdministrativeFee"> {
    createdAt: string;
    attorneyPowerAdministrativeFee: {
        feeDocumentId?: string;
        declarationOfExemption: boolean;
    };
}

export type OperatorStatementsListResponseBody = PageResponseBody<OperatorStatementResponseBody>;

export interface SavedStatementResponseBody extends Omit<SavedStatement, "createdAt"> {
    createdAt: string;
}

export interface PayForStatementResponseBody {
    redirectUrl: string;
    hashtrans: string;
}

export type RepeatPaymentResponseBody = PayForStatementResponseBody;

export function convertOperatorPermissionsListResponseBodyToOwnedOperatorPermissions(
    response: OperatorPermissionsListResponseBody[]
): OwnedOperatorPermission[] {
    return response.map((operatorPermissionBodyRow) => ({
        ...operatorPermissionBodyRow,
        expirationDate: DateUtils.convertStringDateToDateWithoutTimeZone(operatorPermissionBodyRow.expirationDate),
    }));
}

export function convertPossiblePermissionsResponseBodyToOperatorPermissionsCapabilities(
    response: OperatorPermissionsCapabilitiesResponseBody
): OperatorPermissionsCapabilities {
    return {
        availableOperationScenarios:
            response.availableOperationScenarios?.map((availableOperationalScenario) => ({
                scenarioId: availableOperationalScenario.id,
                name: availableOperationalScenario.name,
                description: availableOperationalScenario.description,
                grantValidityInMonths: availableOperationalScenario.grantValidityInMonths,
                price: availableOperationalScenario.price,
                areUavsRequired: availableOperationalScenario.uavsRequired,
                allowedUavClasses: availableOperationalScenario.allowedUavClasses ?? [],
            })) ?? [],
        operationalAuthorizations:
            response.operationalAuthorizations?.map((operationalAuthorization) => ({
                scenarioId: operationalAuthorization.scenarioId,
                name: operationalAuthorization.name,
                description: operationalAuthorization.description,
                grantValidityInMonths: operationalAuthorization.grantValidityInMonths,
                price: operationalAuthorization.price,
                uavs: operationalAuthorization.uavs,
                areUavsRequired: !!operationalAuthorization.uavs?.length,
                allowedUavClasses: operationalAuthorization.allowedUavClasses ?? [],
            })) ?? [],
        operator: response.operator,
        operatorUavs: response.operatorUavs,
        isAttorneyPowerAdministrativeFeeRequired: response.attorneyPowerAdministrativeFeeRequired,
    };
}

function getStatementsPaymentInfoCode(info?: string): PaymentStatusInfoCode {
    switch (info) {
        case "user_directory.payment.service_unavailable":
            return PaymentStatusInfoCode.ServiceUnavailable;
        case "user_directory.payment.transaction_rejected":
            return PaymentStatusInfoCode.TransactionRejected;
        case "user_directory.payment.transaction_timeout":
            return PaymentStatusInfoCode.TransactionTimeout;
        default:
            return PaymentStatusInfoCode.Unknown;
    }
}

export function convertOperatorStatementsListResponseBodyToOperatorStatementListWithPages(
    response: OperatorStatementsListResponseBody
): OperatorStatementListWithPages {
    return {
        content: response.content.map(
            (operatorStatementResponse: OperatorStatementResponseBody): OperatorStatement => ({
                id: operatorStatementResponse.id,
                operatorId: operatorStatementResponse.operatorId,
                createdAt: new Date(operatorStatementResponse.createdAt),
                payment: {
                    status: operatorStatementResponse.payment.status,
                    info: getStatementsPaymentInfoCode(operatorStatementResponse.payment.info),
                },
                signature: operatorStatementResponse.signature,
                totalCost: operatorStatementResponse.totalCost,
                operatorNumber: operatorStatementResponse.operatorNumber,
                operationScenarios: operatorStatementResponse.operationScenarios,
                operationScenarioNames: operatorStatementResponse.operationScenarioNames,
                attorneyPowerAdministrativeFee: operatorStatementResponse.attorneyPowerAdministrativeFee
                    ? {
                          feeDocumentId: operatorStatementResponse.attorneyPowerAdministrativeFee?.feeDocumentId,
                          isDeclarationOfExemption: operatorStatementResponse.attorneyPowerAdministrativeFee?.declarationOfExemption,
                      }
                    : undefined,
            })
        ),
        pages: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

export function convertSavedStatementResponseBodyToSavedStatement(response: SavedStatementResponseBody): SavedStatement {
    return {
        id: response.id,
        operatorId: response.operatorId,
        createdAt: new Date(response.createdAt),
        payment: response.payment,
        signature: response.signature,
        totalCost: response.totalCost,
        operationScenarios: response.operationScenarios,
    };
}
export function convertOperatorPermissionsStatementToSignToOperatorPermissionsStatementToSignRequest(
    statement: OperatorPermissionsStatementToSign
): OperatorPermissionsStatementToSignRequest {
    return {
        operationScenarios: statement.operationScenarios,
        attorneyPowerAdministrativeFee: statement.attorneyPowerAdministrativeFee
            ? {
                  declarationOfExemption: statement.attorneyPowerAdministrativeFee.isDeclarationOfExemption,
                  feeConfirmationId: statement.attorneyPowerAdministrativeFee.feeConfirmationId,
              }
            : undefined,
    };
}

export function convertPayForStatementErrorResponseToOperatorPermissionsError(error: HttpErrorResponse): OperatorPermissionsError {
    if (error.status === HttpStatusCode.ServiceUnavailable) {
        return { type: OperatorPermissionsErrorType.PaymentServiceUnavailable };
    }

    return { type: OperatorPermissionsErrorType.Unknown };
}

export function convertPermissionUavDetailsResponseBodyToPermissionUavDetails(
    uavDetails: PermissionUavDetailsResponseBody
): PermissionUavDetails {
    return {
        ...uavDetails,
        uavs: uavDetails.uavs.map((uav) => ({
            id: uav.id,
            ownerName: uav.ownerName,
            manufacturer: uav.manufacturer,
            type: uav.type,
            name: uav.name,
            serialNumbers: uav.serialNumbers,
            isCustom: uav.custom,
        })),
    };
}
