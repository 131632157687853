import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperatorStatement, PaymentStatusInfoCode, StatementStatus } from "../../services/operator-permissions.models";

interface StatementsListComponentState {
    statementsList: OperatorStatement[];
    expandedElement: OperatorStatement | undefined;
    isPaymentFeatureAvailable: boolean;
    pagination: Page | undefined;
}

@Component({
    selector: "dtm-web-app-lib-statements-list[statementsList][pagination]",
    templateUrl: "./statements-list.component.html",
    styleUrls: ["./statements-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class StatementsListComponent {
    @Input() public set statementsList(value: OperatorStatement[] | undefined) {
        this.localStore.patchState({ statementsList: value ?? [] });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }
    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }
    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Output() public signatureRedirect = new EventEmitter<string>();
    @Output() public administrativeFeeDocumentDownload = new EventEmitter<string>();

    protected readonly displayedColumns = ["expandHandle", "status", "statementDate", "operatorNumber", "attorneyPower", "actions"];
    protected readonly statementsList$ = this.localStore.selectByKey("statementsList");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");
    protected readonly StatementStatus = StatementStatus;
    protected readonly PaymentStatusInfoCode = PaymentStatusInfoCode;

    constructor(private readonly localStore: LocalComponentStore<StatementsListComponentState>) {
        localStore.setState({
            expandedElement: undefined,
            statementsList: [],
            pagination: undefined,
            isPaymentFeatureAvailable: false,
        });
    }

    protected toggleExpandableRow(row: OperatorStatement) {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }

    protected joinOperationScenarioNames(operationScenarioNames: string[]): string {
        return operationScenarioNames.join(", ");
    }
}
