import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OperationScenarioCategory } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ImportedQualification } from "../../models/operator-migration.models";

interface OperatorMigrationOperationalAuthorizationsComponentState {
    operationalAuthorizations: ImportedQualification[];
    header: string | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-operational-authorizations[operationalAuthorizations]",
    templateUrl: "./operator-migration-operational-authorizations.component.html",
    styleUrls: ["./operator-migration-operational-authorizations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorMigrationOperationalAuthorizationsComponent {
    @Input() public set operationalAuthorizations(value: ImportedQualification[] | undefined) {
        this.localStore.patchState({ operationalAuthorizations: value ?? [] });
    }
    @Input() public set header(value: string | undefined) {
        this.localStore.patchState({ header: value });
    }

    protected readonly OperationScenarioCategory = OperationScenarioCategory;
    protected readonly operationalAuthorizations$ = this.localStore.selectByKey("operationalAuthorizations");
    protected readonly header$ = this.localStore.selectByKey("header");

    constructor(private readonly localStore: LocalComponentStore<OperatorMigrationOperationalAuthorizationsComponentState>) {
        localStore.setState({ operationalAuthorizations: [], header: undefined });
    }
}
