<dtm-ui-expandable-panel *ngrxLet="operation$ as operation" [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <div class="panel-header" headerSlot>
        <strong>
            {{ "dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.basicOperationDataHeaderLabel" | transloco }}
        </strong>
        <span class="chip light">
            <dtm-ui-icon [name]="operation.flight.flightType === MissionType.BVLOS ? 'eye-off' : 'eye'"></dtm-ui-icon>
            {{
                "dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.operationTypeLabel"
                    | transloco : { value: operation.flight.flightType }
            }}
        </span>
    </div>
    <div class="properties">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.flightStartAtLabel' | transloco"
            [value]="operation.flightStartAt | localizeDate : { dateStyle: 'short' }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.flightFinishAtLabel' | transloco"
            [value]="operation.flightFinishAt | localizeDate : { dateStyle: 'short' }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.categoryLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.categoryValueLabel'
                    | transloco : { value: operation.category.type }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.operationExecutionBasisLabel' | transloco"
            [value]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.operationExecutionBasisValue' | transloco"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.maxPlannedSpeedLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.maxPlannedSpeedValue'
                    | transloco : { value: operation.flight.maxSpeed | localizeNumber }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.maxReactionDelayLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.maxReactionDelayValue'
                    | transloco : { value: operation.flight.pilotReactionDelay | localizeNumber }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.purposeLabel' | transloco"
            [value]="purposeName$ | ngrxPush | systemTranslation | falsyDash"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            class="whole-line-property"
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.operationInfo.descriptionLabel' | transloco"
            [value]="operation.flight.purposeComment | falsyDash"
        ></dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
