import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MissionType } from "@dtm-frontend/shared/mission";
import { MissionData, MissionStatus, MissionViewSettings } from "@dtm-frontend/shared/ui/tactical";
import { AnimationUtils, FormType, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AllGeoJSON, Polygon, Properties, feature, featureCollection } from "@turf/helpers";
import { firstValueFrom, pairwise, startWith } from "rxjs";
import { NearbyMissionsSettings } from "../../services/tactical-api.service";

interface TacticalNearbyMissionsComponentState {
    selectedNearbyMission: MissionData | undefined;
    isNearbyMissionDataProcessing: boolean;
    nearbyMissions: MissionData[];
    mainMission: MissionData | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-tactical-nearby-missions",
    templateUrl: "./tactical-nearby-missions.component.html",
    styleUrls: ["./tactical-nearby-missions.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class TacticalNearbyMissionsComponent {
    protected areAllNearbyMissionsEnabledControl = new FormControl(true, { nonNullable: true });
    protected areOnlyActiveNearbyMissionsControl = new FormControl(false, { nonNullable: true });

    protected nearbyMissionSettingFormGroup = new FormGroup({
        dataSettings: new FormGroup<FormType<MissionViewSettings>>({
            areAllEnabled: this.areAllNearbyMissionsEnabledControl,
            areOnlyActive: this.areOnlyActiveNearbyMissionsControl,
        }),
    });

    @Output() public readonly zoomToGeometry = new EventEmitter<AllGeoJSON>();
    @Output() public readonly nearbyMissionSelect = new EventEmitter<MissionData>();
    @Output() public readonly nearbyMissionsRefresh = new EventEmitter<NearbyMissionsSettings>();

    @Input() public set isNearbyMissionDataProcessing(value: BooleanInput) {
        const isNearbyMissionDataProcessing = coerceBooleanProperty(value);
        this.localStore.patchState({ isNearbyMissionDataProcessing });

        if (isNearbyMissionDataProcessing) {
            this.nearbyMissionSettingFormGroup.disable({ emitEvent: false });
        } else {
            this.nearbyMissionSettingFormGroup.enable({ emitEvent: false });
        }
    }
    @Input() public set nearbyMissions(value: MissionData[] | undefined) {
        this.localStore.patchState({ nearbyMissions: value ?? [] });
    }
    @Input() public set mainMission(value: MissionData | undefined) {
        this.localStore.patchState({ mainMission: value });
    }

    protected readonly isNearbyMissionDataProcessing$ = this.localStore.selectByKey("isNearbyMissionDataProcessing");
    protected readonly nearbyMissions$ = this.localStore.selectByKey("nearbyMissions");
    protected readonly selectedNearbyMission$ = this.localStore.selectByKey("selectedNearbyMission");

    private zoomOnMissionId: string | undefined;

    protected readonly MissionType = MissionType;
    protected readonly MissionStatus = MissionStatus;

    constructor(private readonly localStore: LocalComponentStore<TacticalNearbyMissionsComponentState>) {
        localStore.setState({
            selectedNearbyMission: undefined,
            isNearbyMissionDataProcessing: false,
            nearbyMissions: [],
            mainMission: undefined,
        });
        this.watchAndUpdateSettings();
    }

    private watchAndUpdateSettings() {
        this.refreshNearbyMissionList();

        this.nearbyMissionSettingFormGroup.controls.dataSettings.valueChanges
            .pipe(startWith(this.nearbyMissionSettingFormGroup.controls.dataSettings.value), pairwise(), untilDestroyed(this))
            .subscribe(([previousSettings, currentSettings]) => {
                if (currentSettings.areAllEnabled && !previousSettings.areAllEnabled) {
                    this.areOnlyActiveNearbyMissionsControl.setValue(false);
                    this.refreshNearbyMissionList();
                }
                if (currentSettings.areOnlyActive && !previousSettings.areOnlyActive) {
                    this.areAllNearbyMissionsEnabledControl.setValue(false);
                    this.refreshNearbyMissionList();
                }
                if (!currentSettings.areAllEnabled && !currentSettings.areOnlyActive) {
                    this.refreshNearbyMissionList();
                }
            });
    }

    protected toggleSelectionOnNearbyMission(mission: MissionData) {
        const selectedMission = this.localStore.selectSnapshotByKey("selectedNearbyMission");

        this.localStore.patchState({ selectedNearbyMission: selectedMission?.missionId === mission?.missionId ? undefined : mission });
        this.nearbyMissionSelect.emit(selectedMission?.missionId === mission?.missionId ? undefined : mission);
    }

    protected zoomOnSelectedMission(mission: MissionData) {
        const mainMission = this.localStore.selectSnapshotByKey("mainMission");

        if (mission.missionId === this.zoomOnMissionId) {
            this.zoomOn(mainMission);
            this.zoomOnMissionId = undefined;

            return;
        }

        this.zoomOn(mission);
        this.zoomOnMissionId = mission.missionId;
    }

    protected zoomOn(mission?: MissionData) {
        const missionData = mission ?? this.localStore.selectSnapshotByKey("mainMission");

        if (!missionData) {
            return;
        }

        const zoomArea = featureCollection<Polygon, Properties>(
            missionData.route.sections
                .map((section) => section.flightZone?.safetyArea.volume.area ?? section.segment?.safetyArea.volume.area)
                .filter(FunctionUtils.isTruthy)
                .map((polygon) => feature(polygon))
        );

        this.zoomToGeometry.emit(zoomArea);
    }

    protected trackByMissionId(_: number, mission: MissionData) {
        return mission.missionId;
    }

    private async refreshNearbyMissionList() {
        const currentMission = await firstValueFrom(this.localStore.selectByKey("mainMission").pipe(RxjsUtils.filterFalsy()));

        const { areAllEnabled, areOnlyActive } = this.nearbyMissionSettingFormGroup.controls.dataSettings.value;
        this.nearbyMissionsRefresh.emit({
            missionId: currentMission.missionId,
            areOnlyActive,
            areAllEnabled,
        });
    }
}
