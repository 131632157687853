<dtm-ui-responsive-table
    *ngrxLet="{
        ownedPermits: ownedPermits$,
        expandedElement: expandedElement$,
        isPermittedToCreateApplication: isPermittedToCreateApplication$
    } as vm"
    [data]="vm.ownedPermits"
>
    <table
        class="dtm-table no-box-shadow"
        *ngIf="vm.ownedPermits.length; else emptyListTemplate"
        multiTemplateDataRows
        desktopTable
        mat-table
        [dataSource]="vm.ownedPermits"
    >
        <ng-container matColumnDef="expandHandle">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button
                    type="button"
                    class="button-icon"
                    (click)="$event.stopPropagation(); toggleExpandableRow(element)"
                    [attr.aria-label]="'dtmUi.expandButtonArialLabel' | transloco : { isExpanded: element === vm.expandedElement }"
                    [attr.aria-expanded]="element === vm.expandedElement"
                >
                    <dtm-ui-icon name="arrow-right" class="expand-handle" [class.expanded]="element === vm.expandedElement"></dtm-ui-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.statusColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container [ngTemplateOutlet]="statusTemplate" [ngTemplateOutletContext]="{ $implicit: element.status }"></ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="permitNumber">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.permitNumberColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.permitNumber }}
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.nameColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.name }}
            </td>
        </ng-container>
        <ng-container matColumnDef="submissionDate">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.submissionDateColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.createdAt | localizeDate }}
            </td>
        </ng-container>
        <ng-container matColumnDef="periodOfValidity">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.periodOfValidityColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.validityPeriodStart | localizeDate }} - {{ element.validityPeriodFinish | localizeDate }}
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.actionsColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                <button
                    [matMenuTriggerFor]="actions"
                    [matMenuTriggerData]="{ $implicit: element }"
                    class="button-icon"
                    type="button"
                    [attr.aria-label]="'dtmUi.actionButtonAriaLabel' | transloco"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="expanded-row-content" *ngIf="element === vm.expandedElement" @slideIn>
                    <ng-container [ngTemplateOutlet]="additionalInfo" [ngTemplateOutletContext]="{ $implicit: element }"></ng-container>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [class.expanded]="vm.expandedElement === row"
            (click)="toggleExpandableRow(row)"
        ></tr>
        <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" class="pagination-row"></tr>
    </table>
    <div *dtmUiMobileTable="let row">
        <div class="mobile-table-actions-row">
            <dtm-ui-label-value [label]="'dtmWebAppLibOperatorPermits.permitsLists.statusColumnLabel' | transloco">
                <ng-container [ngTemplateOutlet]="statusTemplate" [ngTemplateOutletContext]="{ $implicit: row.status }"></ng-container>
            </dtm-ui-label-value>
            <button
                [matMenuTriggerFor]="actions"
                [matMenuTriggerData]="{ $implicit: row }"
                (click)="$event.stopPropagation()"
                class="button-icon"
                type="button"
                [attr.aria-label]="'dtmUi.actionButtonAriaLabel' | transloco"
            >
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
        </div>
        <div class="mobile-table-content">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorPermits.permitsLists.permitNumberColumnLabel' | transloco"
                [value]="row.permitNumber"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmWebAppLibOperatorPermits.permitsLists.nameColumnLabel' | transloco" [value]="row.name">
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorPermits.permitsLists.submissionDateColumnLabel' | transloco"
                [value]="row.createdAt | localizeDate"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value [label]="'dtmWebAppLibOperatorPermits.permitsLists.periodOfValidityColumnLabel' | transloco">
                {{ row.validityPeriodStart | localizeDate }} - {{ row.validityPeriodFinish | localizeDate }}
            </dtm-ui-label-value>
            <ng-container [ngTemplateOutlet]="additionalInfo" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
        </div>
    </div>
    <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"> </ng-container>
    <ng-container mobileTableNoData [ngTemplateOutlet]="emptyListTemplate"></ng-container>
    <mat-menu #actions="matMenu">
        <ng-template matMenuContent let-element>
            <button
                type="button"
                mat-menu-item
                [routerLink]="[element.type | invoke : getPermitDetailsRoute, element.id, element.operatorId]"
            >
                <dtm-ui-icon name="eye"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibOperatorPermits.permitsLists.permitDetailsActionLabel" | transloco }}</span>
            </button>
            <button (click)="permitNameChange.emit(element)" type="button" mat-menu-item *ngIf="vm.isPermittedToCreateApplication">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibOperatorPermits.permitsLists.changeNameButtonLabel" | transloco }}</span>
            </button>
        </ng-template>
    </mat-menu>
</dtm-ui-responsive-table>

<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>

<ng-template #paginationTemplate>
    <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
    </dtm-ui-pagination>
</ng-template>

<ng-template #statusTemplate let-status>
    <ng-container [ngSwitch]="status">
        <span class="chip success" *ngSwitchCase="OwnedPermitStatus.Active">
            <dtm-ui-icon name="checkbox-circle" class="status-icon"></dtm-ui-icon>
            <p class="status-label">
                {{ "dtmWebAppLibOperatorPermits.permitsOwned.statusValueLabel" | transloco : { value: status } }}
            </p>
        </span>
        <span class="chip warning" *ngSwitchCase="OwnedPermitStatus.Suspended">
            <dtm-ui-icon name="pause-circle" class="status-icon"></dtm-ui-icon>
            <p class="status-label">
                {{ "dtmWebAppLibOperatorPermits.permitsOwned.statusValueLabel" | transloco : { value: status } }}
            </p>
        </span>
        <span class="chip error" *ngSwitchCase="OwnedPermitStatus.Withdrawn">
            <dtm-ui-icon name="error-warning" class="status-icon"></dtm-ui-icon>
            <p class="status-label">
                {{ "dtmWebAppLibOperatorPermits.permitsOwned.statusValueLabel" | transloco : { value: status } }}
            </p>
        </span>
    </ng-container>
</ng-template>

<ng-template #additionalInfo let-element>
    <dtm-ui-label-value
        class="sub-cell"
        [label]="'dtmWebAppLibOperatorPermits.permitsOwned.operationTargetLabel' | transloco"
        [value]="element.flightPurposes"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        class="sub-cell"
        [label]="'dtmWebAppLibOperatorPermits.permitsOwned.permitTypeLabel' | transloco"
        [value]="'dtmWebAppLibOperatorPermits.permitsOwned.permitTypeValue' | transloco : { value: element.type }"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        class="sub-cell"
        *ngIf="element.statusReason"
        [label]="'dtmWebAppLibOperatorPermits.permitsOwned.withdrawReasonLabel' | transloco"
        [value]="element.statusReason"
    >
    </dtm-ui-label-value>
</ng-template>
