<dtm-ui-loader-container [isShown]="data.isProcessing$ | ngrxPush">
    <div class="dialog-header">
        <div mat-dialog-title>{{ "dtmWebAppLibOpsMan.attachments.manageDialog.manageHeader" | transloco }}</div>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form [formGroup]="attachmentsForm">
            <dtm-ui-files-groups-upload
                [formControl]="attachmentsForm.controls.attachments"
                [additionalPathParams]="data.additionalPathParams"
                [addButtonLabel]="'dtmWebAppLibOpsMan.attachments.manageDialog.addAttachmentButtonLabel' | transloco"
                [uploadFieldLabel]="'dtmWebAppLibOpsMan.attachments.manageDialog.uploadFieldLabel' | transloco"
            ></dtm-ui-files-groups-upload>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [matDialogClose]="false">
            {{ "dtmWebAppLibOpsMan.attachments.manageDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [disabled]="isSaveButtonDisabled$ | ngrxPush" (click)="save()">
            {{ "dtmWebAppLibOpsMan.attachments.manageDialog.saveButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>
