<dtm-ui-loader-container
    *ngrxLet="{
        isOwnedPermitsProcessing: isOwnedSpecificPermitsListProcessing$,
        isSpecificPermitsApplicationDraftListProcessing: isSpecificPermitDraftsListProcessing$,
        selectedTab: selectedTab$,
        ownedPermits: ownedSpecificPermitsList$,
        areFiltersApplied: areFiltersApplied$,
        specificPermitDraftsList: specificPermitDraftsList$,
        isSpecificPermitDraftsAvailable: areSpecificPermitDraftsAvailable$,
        isPermittedToCreateApplication: isPermittedToCreateApplication$,
        isPermittedToPlanMissions: isPermittedToPlanMissions$,
    } as vm"
    [isShown]="vm.isOwnedPermitsProcessing || vm.isSpecificPermitsApplicationDraftListProcessing"
>
    <div
        class="grid"
        *ngIf="
            vm.isPermittedToPlanMissions || (vm.isSpecificPermitDraftsAvailable && vm.isPermittedToCreateApplication);
            else notAuthorizedTemplate
        "
    >
        <mat-card *ngIf="vm.selectedTab">
            <dtm-ui-card-header
                [buttonLabel]="'dtmWebAppLibOperatorPermits.permitsContainer.gainPermitCardHeaderButtonLabel' | transloco"
                [isEditButtonVisible]="
                    vm.isSpecificPermitDraftsAvailable &&
                    vm.isPermittedToCreateApplication &&
                    ((vm.selectedTab.path === TabPaths.OWNED_PERMIT && (!!vm.ownedPermits?.length || vm.areFiltersApplied)) ||
                        (vm.selectedTab.path === TabPaths.DRAFTS && (!!vm.specificPermitDraftsList?.length || vm.areFiltersApplied)))
                "
                icon="add"
                (edit)="navigateToSpecificPermits()"
            >
                <p class="header">
                    {{
                        (vm.isSpecificPermitDraftsAvailable && vm.isPermittedToCreateApplication
                            ? "dtmWebAppLibOperatorPermits.permitsContainer.permitsAndApplicationsCardHeaderTitle"
                            : "dtmWebAppLibOperatorPermits.permitsContainer.permitsCardHeaderTitle"
                        ) | transloco
                    }}
                </p>
            </dtm-ui-card-header>
            <mat-card-content>
                <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="dtm-tabs" color="accent">
                    <a
                        *ngIf="vm.isPermittedToPlanMissions"
                        mat-tab-link
                        [routerLink]="['/operator-permits', tabs[TabIndex.OWNED_PERMIT].path]"
                        queryParamsHandling="preserve"
                        [state]="{ skipLoading: true }"
                        routerLinkActive
                        #permits="routerLinkActive"
                        [active]="permits.isActive"
                    >
                        {{ tabs[TabIndex.OWNED_PERMIT].labelKey | transloco }}
                    </a>
                    <a
                        *ngIf="vm.isSpecificPermitDraftsAvailable && vm.isPermittedToCreateApplication"
                        mat-tab-link
                        [routerLink]="['/operator-permits', tabs[TabIndex.DRAFTS].path]"
                        queryParamsHandling="preserve"
                        [state]="{ skipLoading: true }"
                        routerLinkActive
                        #drafts="routerLinkActive"
                        [active]="drafts.isActive"
                    >
                        {{ tabs[TabIndex.DRAFTS].labelKey | transloco }}
                    </a>
                </nav>

                <mat-tab-nav-panel #tabPanel>
                    <router-outlet></router-outlet>
                </mat-tab-nav-panel>
            </mat-card-content>
        </mat-card>
    </div>
</dtm-ui-loader-container>

<ng-template #notAuthorizedTemplate>
    <dtm-ui-not-authorized></dtm-ui-not-authorized>
</ng-template>
