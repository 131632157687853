import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { TemplatePortal } from "@angular/cdk/portal";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS, MatLegacyDateFormats as MatDateFormats } from "@angular/material/legacy-core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MapActionType, MapEntity, MapEntityType, Polyline3DEntity } from "@dtm-frontend/shared/map/cesium";
import {
    MissionCategory,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionType,
} from "@dtm-frontend/shared/mission";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    InvalidFormScrollableDirective,
    ItineraryEditorType,
    MissionPlanRoute,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    ArrayElementType,
    DateUtils,
    FormStateController,
    FormType,
    FunctionUtils,
    LocalComponentStore,
    MILLISECONDS_IN_DAY,
    MILLISECONDS_IN_HOUR,
    MILLISECONDS_IN_MINUTE,
    RxjsUtils,
    SECONDS_IN_MINUTE,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions } from "@ngxs/store";
import equal from "fast-deep-equal";
import { combineLatest, distinctUntilChanged, lastValueFrom } from "rxjs";
import { combineLatestWith, first, map, startWith, tap } from "rxjs/operators";
import { ItineraryContent, ItineraryContentEntity } from "../../../../models/itinerary.model";
import {
    ActivePermit,
    FlightLevelType,
    FlightSpeedType,
    HeightType,
    ItineraryEditorFormData,
    ItineraryEditorMissionParametersFormData,
    ItineraryEditorMissionPreferencesFormData,
    ItineraryEditorMissionSettingsFormData,
    ItineraryEditorMissionZoneHeightFormData,
    ItineraryEditorMissionZoneRadiusFormData,
    MissionDataFormCapabilitiesData,
    MissionPlanItinerary,
    MissionPlanItineraryConstraints,
    MissionPlanItineraryWithoutConstraints,
    MissionRouteGenerationSettings,
    SoraSettings,
    ZoneHeightLimits,
} from "../../../../models/mission.model";
import { AdditionalInformationSettings } from "../../../mission-notes-and-description/personal-notes.component";
import { MissionWizardSteps } from "../../content/mission-wizard-content.component";

interface MissionWizardItineraryEditorStepComponentState {
    itineraryContent: ItineraryContent;
    availableEditors: ItineraryEditorType[];
    minimumDateTime: Date;
    isProcessing: boolean;
    currentPlanItinerary: MissionPlanItineraryWithoutConstraints | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    additionalInformation: AdditionalInformationSettings | undefined;
    capabilities: MissionDataFormCapabilitiesData | undefined;
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    enabledSteps: string[] | undefined;
    operationCategory: MissionPlanOperationCategoryOption | undefined;
    activeMapAction: MapActionType;
    currentPlanRoute: MissionPlanRoute | undefined;
    isEditMode: boolean;
    selectedCaaPermit: ActivePermit | undefined;
    isBoundaryViolated: boolean;
    areEditorsDisabled: boolean;
    heightConstraints: ZoneHeightLimits | undefined;
    isHeightEditing: boolean;
    isRadiusEditing: boolean;
    isSettingsEditing: boolean;
    isItinerarySettingsEditing: boolean;
}

type SettingsPanelFlag = Pick<MissionWizardItineraryEditorStepComponentState, "isRadiusEditing" | "isHeightEditing" | "isSettingsEditing">;

const INTL_DATE_INPUT_FORMAT = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

const DEFAULT_PILOT_REACTION_DELAY_SECONDS = 3;
const DEFAULT_ZONE_TOP_HEIGHT_METERS = 30;
const DEFAULT_FLIGHT_SPEED_FACTOR = 0.7;
const DEFAULT_FLIGHT_SPEED = 10;
const MAX_TOP_HEIGHT_LOW_LEVEL_FLIGHT = 30;
const MAX_OPERATION_AIRSPACE_HEIGHT = 50;
const MAXIMUM_DATE_DAYS = 30;

const DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: INTL_DATE_INPUT_FORMAT,
    },
    display: {
        dateInput: INTL_DATE_INPUT_FORMAT,
        monthYearLabel: { year: "numeric", month: "short" },
        dateA11yLabel: { year: "numeric", month: "long", day: "numeric" },
        monthYearA11yLabel: { year: "numeric", month: "long" },
    },
};

enum ItineraryEditorActiveTab {
    BasicMissionParameters = 0,
    ItineraryPanel = 1,
}

const customCaaPeriodValidator =
    (periods?: { startDate: Date; endDate: Date }): ValidatorFn =>
    (form: AbstractControl): ValidationErrors | null => {
        const formValue = form.value;

        if (formValue && periods && (formValue < periods.startDate || formValue > periods.endDate)) {
            return {
                missionOutsidePermitPeriod: {
                    validityPeriodStart: periods.startDate,
                    validityPeriodFinish: periods.endDate,
                },
            };
        }

        return null;
    };

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-itinerary-editor-step[availableEditors][currentPlanItinerary][constraints][currentPlanRoute]",
    templateUrl: "./itinerary-editor-step.component.html",
    styleUrls: ["./itinerary-editor-step.component.scss", "../step-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }],
})
export class MissionWizardItineraryEditorStepComponent implements AfterViewInit, OnInit {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;
    @ViewChild("routeSideViewTemplate", { read: TemplateRef }) public routeSideViewTemplate: TemplateRef<unknown> | undefined;

    protected readonly FlightLevelType = FlightLevelType;
    protected readonly FlightSpeedType = FlightSpeedType;
    protected readonly ItineraryEditorType = ItineraryEditorType;
    protected readonly MapEntityType = MapEntityType;
    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly MissionRouteGenerationSettings = MissionRouteGenerationSettings;
    protected readonly MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;
    protected readonly MissionType = MissionType;
    protected readonly ItineraryEditorActiveTab = ItineraryEditorActiveTab;
    protected readonly MissionCategory = MissionCategory;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;

    protected readonly dateFormControl = new FormControl(this.getMinimumDateTime(MILLISECONDS_IN_HOUR / 2), {
        validators: [Validators.required],
        nonNullable: true,
    });
    protected readonly datetimeFormControl = new FormControl(new Date(this.dateFormControl.value), {
        validators: [Validators.required],
        nonNullable: true,
    });
    protected readonly dateRangeStartFormControl = new FormControl<Date | null>(null);
    protected readonly dateRangeEndFormControl = new FormControl<Date | null>(null);
    protected readonly flightLevelTypeFormControl = new FormControl(FlightLevelType.MinimumHeight, { nonNullable: true });
    protected readonly flightLevelFormControl: FormControl<number | null> = new FormControl(null);
    protected readonly flightSpeedTypeFormControl = new FormControl(FlightSpeedType.MetersPerSecond, { nonNullable: true });
    protected readonly flightSpeedFormControl = new FormControl(DEFAULT_FLIGHT_SPEED, { nonNullable: true });
    protected readonly verticalBufferFormControl: FormControl<number | null> = new FormControl(null);
    protected readonly horizontalBufferFormControl: FormControl<number | null> = new FormControl(null);
    protected readonly durationFormControl: FormControl<number | null> = new FormControl(null, { validators: [Validators.required] });
    protected readonly userSelectedEditorTypeFormControl = new FormControl<ItineraryEditorType>(ItineraryEditorType.Standard, {
        validators: [Validators.required],
        nonNullable: true,
    });
    protected readonly currentEditorTypeFormControl = new FormControl<ItineraryEditorType>(ItineraryEditorType.Standard, {
        nonNullable: true,
    });
    protected readonly itineraryFormControl = new UntypedFormControl({});
    protected readonly routeGenerationSettings = new FormControl(MissionRouteGenerationSettings.Length, { nonNullable: true });
    protected readonly soraSettingsFormControl = new FormControl<SoraSettings>({}, { nonNullable: true });

    protected readonly missionParametersZoneRadiusFormControls: FormType<ItineraryEditorMissionZoneRadiusFormData> = {
        isEnlargedZone: new FormControl<boolean>(false, { nonNullable: true }),
        isEnlargedZoneStatementAccepted: new FormControl<boolean | null>(false),
    };
    protected readonly missionParametersZoneHeightFormControls: FormType<ItineraryEditorMissionZoneHeightFormData> = {
        isFlightAroundObstacle: new FormControl<boolean>(false, { nonNullable: true }),
        isFlightAroundObstacleStatementAccepted: new FormControl<boolean>(false, { nonNullable: true }),
        zoneTopHeight: new FormControl<number | null>(DEFAULT_ZONE_TOP_HEIGHT_METERS),
        zoneHeightType: new FormControl<HeightType>(HeightType.AGL, { nonNullable: true }),
        zoneBottomHeight: new FormControl<number | null>(0),
    };
    protected readonly missionPreferencesFormControls: FormType<ItineraryEditorMissionPreferencesFormData> = {
        flightLevel: this.flightLevelFormControl,
        flightLevelType: this.flightLevelTypeFormControl,
        verticalBuffer: this.verticalBufferFormControl,
        horizontalBuffer: this.horizontalBufferFormControl,
    };
    protected readonly missionSettingsFormControls: FormType<ItineraryEditorMissionSettingsFormData> = {
        flightSpeed: this.flightSpeedFormControl,
        flightSpeedType: this.flightSpeedTypeFormControl,
        pilotReactionDelaySeconds: new FormControl<number>(DEFAULT_PILOT_REACTION_DELAY_SECONDS, { nonNullable: true }),
    };

    private readonly missionParametersFormControls = {
        ...this.missionParametersZoneHeightFormControls,
        ...this.missionPreferencesFormControls,
        ...this.missionSettingsFormControls,
        ...this.missionParametersZoneRadiusFormControls,
    };
    protected readonly itineraryFormGroup = new FormGroup<FormType<ItineraryEditorFormData>>({
        editorType: this.currentEditorTypeFormControl,
        date: this.dateFormControl,
        datetime: this.datetimeFormControl,
        dateRangeStart: this.dateRangeStartFormControl,
        dateRangeEnd: this.dateRangeEndFormControl,
        durationMinutes: this.durationFormControl,
        itinerary: this.itineraryFormControl,
        routeGenerationSettings: this.routeGenerationSettings,
        soraSettings: this.soraSettingsFormControl,
        ...this.missionParametersFormControls,
    });

    protected readonly itineraryFormGroupStateController = new FormStateController(this.itineraryFormGroup);

    protected readonly minimumDateTime$ = this.localStore.selectByKey("minimumDateTime");
    protected readonly maximumDateTime$ = this.minimumDateTime$.pipe(
        map((date) => new Date(date.getTime() + MAXIMUM_DATE_DAYS * MILLISECONDS_IN_DAY))
    );
    protected readonly itineraryContent$ = this.localStore.selectByKey("itineraryContent");
    protected readonly availableEditors$ = this.localStore.selectByKey("availableEditors");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly heightConstraints$ = this.localStore.selectByKey("heightConstraints");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly additionalInformation$ = this.localStore.selectByKey("additionalInformation");
    protected readonly stepNumber$ = this.localStore.selectByKey("stepNumber");
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount");
    protected readonly operationCategory$ = this.localStore.selectByKey("operationCategory");
    protected readonly isAnyMapActionActive$ = this.localStore
        .selectByKey("activeMapAction")
        .pipe(map((action) => action !== MapActionType.None));
    protected readonly currentPlanRoute$ = this.localStore.selectByKey("currentPlanRoute");
    protected readonly isOutsideDtm$ = this.currentPlanRoute$.pipe(map((route) => route && !route.stats?.flight.dtmNames.length));
    protected readonly isOverHeightOrSafetyArea$ = this.missionParametersZoneHeightFormControls.zoneTopHeight.valueChanges.pipe(
        combineLatestWith(this.localStore.selectByKey("currentPlanRoute")),
        map(([topHeight, route]) => {
            if (!topHeight) {
                return;
            }

            const safetySpaceValue = route?.stats?.operation.maxDeclaredHeight;

            return topHeight > MAX_TOP_HEIGHT_LOW_LEVEL_FLIGHT || !!(safetySpaceValue && safetySpaceValue >= MAX_OPERATION_AIRSPACE_HEIGHT);
        })
    );

    protected readonly uavWithSetup$ = this.localStore.selectByKey("capabilities").pipe(map((capabilities) => capabilities?.uavWithSetup));
    protected readonly missionType$ = this.localStore.selectByKey("capabilities").pipe(map((capabilities) => capabilities?.missionType));
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly isStepZoneRadiusVisible$ = combineLatest([this.missionType$, this.operationCategory$]).pipe(
        map(
            ([missionType, operationCategory]) =>
                missionType === MissionType.VLOS &&
                (operationCategory?.type === MissionCategory.Open ||
                    (operationCategory?.type === MissionCategory.Specific &&
                        operationCategory.specificPermitType === MissionPlanSpecificPermitType.Sts))
        )
    );
    protected readonly minGroundRiskBuffer$ = this.prepareMinGroundRiskBuffer();
    protected readonly selectedCaaPermit$ = this.localStore.selectByKey("selectedCaaPermit");
    protected readonly isBoundaryViolated$ = this.localStore.selectByKey("isBoundaryViolated");
    protected readonly areEditorsDisabled$ = this.localStore.selectByKey("areEditorsDisabled");

    @Input() public set suggestedEditor(value: ItineraryEditorType | undefined) {
        if (
            value &&
            this.localStore.selectSnapshotByKey("itineraryContent").length === 0 &&
            this.localStore.selectSnapshotByKey("availableEditors").includes(value)
        ) {
            this.userSelectedEditorTypeFormControl.setValue(value);
            this.currentEditorTypeFormControl.setValue(value);
        }
    }

    @Input() public set capabilities(value: MissionDataFormCapabilitiesData | undefined) {
        this.localStore.patchState({ capabilities: value });

        const uavWithSetup = value?.uavWithSetup;
        const maxFlightSpeed = uavWithSetup?.setup.technicalSpecification.maxFlightSpeed ?? DEFAULT_FLIGHT_SPEED;

        this.flightSpeedTypeFormControl.setValue(FlightSpeedType.MetersPerSecond);
        this.flightSpeedFormControl.setValue(Math.round(maxFlightSpeed * DEFAULT_FLIGHT_SPEED_FACTOR));
    }

    @Input() public set availableEditors(value: ItineraryEditorType[] | undefined) {
        this.localStore.patchState({ availableEditors: value ?? [] });

        if (value?.length && !value.includes(this.userSelectedEditorTypeFormControl.value)) {
            this.userSelectedEditorTypeFormControl.setValue(value[0]);
            this.currentEditorTypeFormControl.setValue(value[0]);
        }
    }

    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Input() public set itineraryContent(value: ItineraryContent | undefined) {
        // TODO: somehow this value is not set with every cylinder change event - there is some throttling mechanism in between!
        // it is probably related to the fact that changing height on the map sends bazillion events per second
        this.localStore.patchState({ itineraryContent: value ?? [] });
        this.itineraryFormControl.setValue(value);
    }

    @Input() public set currentPlanItinerary(value: MissionPlanItineraryWithoutConstraints | undefined) {
        this.localStore.patchState({ currentPlanItinerary: value });
    }

    @Input() public set enabledSteps(value: string[] | undefined) {
        this.localStore.patchState({ enabledSteps: value ?? [] });
    }

    @Input() public set additionalInformation(value: AdditionalInformationSettings | undefined) {
        this.localStore.patchState({ additionalInformation: value });
    }

    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }

    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }

    @Input() public set heightConstraints(value: ZoneHeightLimits | undefined) {
        this.localStore.patchState({ heightConstraints: value });
    }

    @Input() public set operationCategory(value: MissionPlanOperationCategoryOption | undefined) {
        this.localStore.patchState({ operationCategory: value });

        if (this.checkIfIsSoraApplicationCategory(value)) {
            this.dateRangeStartFormControl.setValidators([Validators.required]);
            this.dateRangeEndFormControl.setValidators([Validators.required]);
            this.dateFormControl.removeValidators([Validators.required]);
            this.datetimeFormControl.removeValidators([Validators.required]);
            this.durationFormControl.disable();
            this.localStore.patchState({ minimumDateTime: this.getMinimumDateTime(MILLISECONDS_IN_DAY) });
        } else {
            this.dateRangeStartFormControl.removeValidators([Validators.required]);
            this.dateRangeEndFormControl.removeValidators([Validators.required]);
            this.dateFormControl.setValidators([Validators.required]);
            this.datetimeFormControl.setValidators([Validators.required]);
            this.durationFormControl.enable();
            this.localStore.patchState({ minimumDateTime: this.getMinimumDateTime() });
        }
    }

    @Input() public set activeMapAction(value: MapActionType | undefined) {
        this.localStore.patchState({ activeMapAction: value ?? MapActionType.None });
    }

    @Input() public set currentPlanRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ currentPlanRoute: value });
    }

    @Input() public initialItineraryFormData: ItineraryEditorFormData | undefined;

    @Input() public set isEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }

    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }

    @Input() public set selectedCaaPermit(value: ActivePermit | undefined) {
        this.localStore.patchState({ selectedCaaPermit: value });
    }

    @Input() public set isBoundaryViolated(value: BooleanInput) {
        this.localStore.patchState({ isBoundaryViolated: coerceBooleanProperty(value) });
    }

    @Input() public set areEditorsDisabled(value: BooleanInput) {
        this.localStore.patchState({ areEditorsDisabled: coerceBooleanProperty(value) });
    }

    @Output() public readonly itineraryClear = new EventEmitter<void>();
    @Output() public readonly editorTypeChange = new EventEmitter<ItineraryEditorType>();
    @Output() public readonly itinerarySubmit = new EventEmitter<ItineraryEditorFormData>();
    @Output() public readonly formUpdate = new EventEmitter<ItineraryEditorFormData>();
    @Output() public readonly entityUpdate = new EventEmitter<ItineraryContentEntity>();
    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly next = new EventEmitter<void>();
    @Output() public readonly additionalInformationChange = new EventEmitter<AdditionalInformationSettings>();
    @Output() public readonly missionParametersChange = new EventEmitter<Partial<ItineraryEditorMissionParametersFormData>>();
    @Output() public readonly heightPanelParametersChange = new EventEmitter<Partial<ItineraryEditorMissionParametersFormData>>();

    @Output() public readonly missionParametersFormsValidationStatusChange = this.itineraryFormGroup.statusChanges.pipe(
        startWith(this.itineraryFormGroup.status),
        map(() => !Object.values(this.missionParametersFormControls).some((control) => control.invalid)),
        distinctUntilChanged()
    );

    private templatePortal: TemplatePortal | undefined;
    @Output() public readonly sideViewTemplate = new EventEmitter<TemplatePortal | undefined>();

    constructor(
        private readonly localStore: LocalComponentStore<MissionWizardItineraryEditorStepComponentState>,
        private readonly matDialog: MatDialog,
        private readonly translocoService: TranslocoService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly actions$: Actions,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            itineraryContent: [],
            availableEditors: [],
            minimumDateTime: this.getMinimumDateTime(),
            isProcessing: false,
            currentPlanItinerary: undefined,
            constraints: undefined,
            additionalInformation: undefined,
            capabilities: undefined,
            stepNumber: undefined,
            stepsAmount: undefined,
            enabledSteps: undefined,
            operationCategory: undefined,
            activeMapAction: MapActionType.None,
            currentPlanRoute: undefined,
            isEditMode: false,
            selectedCaaPermit: undefined,
            isBoundaryViolated: false,
            areEditorsDisabled: false,
            isHeightEditing: false,
            isRadiusEditing: false,
            isSettingsEditing: false,
            isItinerarySettingsEditing: false,
            heightConstraints: undefined,
        });

        this.watchForItineraryFormChanges();
        this.localStore
            .selectByKey("currentPlanItinerary")
            .pipe(first(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe((value) => this.setFormDefaultValuesAndConstraints(value));

        this.localStore
            .selectByKey("selectedCaaPermit")
            .pipe(untilDestroyed(this))
            .subscribe((permit) => {
                if (permit) {
                    this.dateFormControl.addValidators([
                        customCaaPeriodValidator({ startDate: permit.validityPeriodStart, endDate: permit.validityPeriodFinish }),
                    ]);

                    return;
                }

                this.dateFormControl.removeValidators([customCaaPeriodValidator()]);
            });

        this.watchOnMissionParametersChanges();

        combineLatest([this.areEditorsDisabled$, this.isAnyMapActionActive$])
            .pipe(untilDestroyed(this))
            .subscribe(([areEditorsDisabled, isAnyMapActionActive]) => {
                if (areEditorsDisabled || isAnyMapActionActive) {
                    this.itineraryFormGroup.disable();
                    this.userSelectedEditorTypeFormControl.disable();
                } else {
                    this.itineraryFormGroup.enable();
                    this.userSelectedEditorTypeFormControl.enable();
                }
            });
    }

    public ngOnInit(): void {
        if (this.initialItineraryFormData) {
            this.itineraryFormGroup.patchValue(this.initialItineraryFormData);

            return;
        }

        const currentPlanItinerary = this.localStore.selectSnapshotByKey("currentPlanItinerary");
        const isEditMode = this.localStore.selectSnapshotByKey("isEditMode");

        if (currentPlanItinerary && isEditMode) {
            this.setItineraryEditorFormValuesFromCurrentPlanItinerary(currentPlanItinerary);
        }
    }

    private setItineraryEditorFormValuesFromCurrentPlanItinerary(currentPlanItinerary: MissionPlanItineraryWithoutConstraints) {
        const setValueOptions: Parameters<FormControl["setValue"]>[1] = {
            emitEvent: false,
        };

        if (currentPlanItinerary.type === ItineraryEditorType.Standard) {
            this.missionParametersZoneHeightFormControls.isFlightAroundObstacle.setValue(
                currentPlanItinerary.aroundObstacle ?? false,
                setValueOptions
            );
            this.missionParametersZoneHeightFormControls.isFlightAroundObstacleStatementAccepted.setValue(
                currentPlanItinerary.aroundObstacle ?? false,
                setValueOptions
            );
            this.missionParametersZoneRadiusFormControls.isEnlargedZone.setValue(
                currentPlanItinerary.enlargedZoneRadius ?? false,
                setValueOptions
            );
            this.missionParametersZoneRadiusFormControls.isEnlargedZoneStatementAccepted.setValue(
                currentPlanItinerary.enlargedZoneRadius ?? false,
                setValueOptions
            );
            if (currentPlanItinerary.elements[0].flightZone.maxH) {
                this.missionParametersZoneHeightFormControls.zoneTopHeight.setValue(
                    Math.floor(currentPlanItinerary.elements[0].flightZone.maxH),
                    setValueOptions
                );
            }
            this.missionParametersZoneHeightFormControls.zoneBottomHeight.setValue(
                Math.floor(currentPlanItinerary.elements[0].flightZone.minH),
                setValueOptions
            );
            if (currentPlanItinerary.limits?.speed) {
                this.missionSettingsFormControls.flightSpeedType.setValue(FlightSpeedType.MetersPerSecond, setValueOptions);
                this.missionSettingsFormControls.flightSpeed.setValue(Math.floor(currentPlanItinerary.limits?.speed), setValueOptions);
            }
            this.missionSettingsFormControls.pilotReactionDelaySeconds.setValue(
                Math.floor(currentPlanItinerary.pilotReactionDelay),
                setValueOptions
            );

            const durationSeconds = DateUtils.convertISO8601DurationToSeconds(currentPlanItinerary.time);
            if (durationSeconds) {
                this.durationFormControl.setValue(Math.floor(durationSeconds / SECONDS_IN_MINUTE), setValueOptions);
            }
        }

        if (currentPlanItinerary.type === ItineraryEditorType.Custom || currentPlanItinerary.type === ItineraryEditorType.Assisted) {
            this.missionSettingsFormControls.flightSpeedType.setValue(FlightSpeedType.MetersPerSecond, setValueOptions);
            this.missionSettingsFormControls.flightSpeed.setValue(Math.floor(currentPlanItinerary.flight.cruisingSpeed), setValueOptions);
            this.missionSettingsFormControls.pilotReactionDelaySeconds.setValue(
                Math.floor(currentPlanItinerary.flight.pilotReactionDelay),
                setValueOptions
            );
            this.missionPreferencesFormControls.flightLevelType.setValue(currentPlanItinerary.flight.cruisingLevel.type, setValueOptions);
            this.missionPreferencesFormControls.flightLevel.setValue(currentPlanItinerary.flight.cruisingLevel.value, setValueOptions);
        }

        if ("soraSettings" in currentPlanItinerary && currentPlanItinerary.soraSettings) {
            this.soraSettingsFormControl.setValue(currentPlanItinerary.soraSettings, setValueOptions);
        }
    }

    public ngAfterViewInit(): void {
        if (this.routeSideViewTemplate) {
            this.templatePortal = new TemplatePortal(this.routeSideViewTemplate, this.viewContainerRef);
            this.sideViewTemplate.emit(this.templatePortal);
        }
    }

    private watchForItineraryFormChanges() {
        this.itineraryFormGroup.valueChanges
            .pipe(
                map(() => this.itineraryFormGroup.getRawValue()),
                distinctUntilChanged(equal),
                untilDestroyed(this)
            )
            .subscribe((rawValue) => {
                if (this.itineraryFormGroup.enabled) {
                    this.formUpdate.emit(rawValue);
                }
            });
    }

    private watchOnMissionParametersChanges(): void {
        combineLatest([
            this.localStore.selectByKey("isHeightEditing"),
            this.localStore.selectByKey("isRadiusEditing"),
            this.localStore.selectByKey("isSettingsEditing"),
        ])
            .pipe(
                tap(([isHeightEditing, isRadiusEditing, isSettingsEditing]) => {
                    this.localStore.patchState({ isItinerarySettingsEditing: isHeightEditing || isRadiusEditing || isSettingsEditing });
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected changeEditingState(state: boolean, settingsPanelFlag: keyof SettingsPanelFlag): void {
        this.localStore.patchState({ [settingsPanelFlag]: state });
    }

    protected async completeStep() {
        this.itineraryFormGroup?.markAllAsTouched();
        this.invalidFormScrollable?.scrollToFirstInvalidField();

        const itineraryContent = this.localStore.selectSnapshotByKey("itineraryContent");
        const isItinerarySettingsEditing = this.localStore.selectSnapshotByKey("isItinerarySettingsEditing");

        if (
            this.itineraryFormGroup.invalid ||
            !this.isItineraryValid(itineraryContent, this.currentEditorTypeFormControl.value) ||
            (isItinerarySettingsEditing && !(await this.getUnsavedOperationHeightConfirmation()))
        ) {
            return;
        }

        const enabledStep = this.localStore.selectSnapshotByKey("enabledSteps");
        const isNextStepEnabled = enabledStep?.includes(
            this.itineraryFormGroup.value.editorType === ItineraryEditorType.Assisted
                ? MissionWizardSteps.RouteSelector
                : MissionWizardSteps.Analysis
        );

        if (this.itineraryFormGroupStateController.hasChanged || !isNextStepEnabled) {
            this.saveItineraryCurrentState();
            this.itinerarySubmit.emit(this.itineraryFormGroup.getRawValue() as ItineraryEditorFormData);
        } else {
            this.next.emit();
        }
    }

    protected async clearItinerary() {
        if (await this.getClearItineraryConfirmationFromDialog()) {
            this.itineraryClear.emit();
        }
    }

    protected async changeEditorType(newEditorType: ItineraryEditorType) {
        const itineraryContentLength = this.localStore.selectSnapshotByKey("itineraryContent").length;
        const currentPlanItinerary = this.localStore.selectSnapshotByKey("currentPlanItinerary");

        if (!currentPlanItinerary || (itineraryContentLength > 0 && !(await this.getClearItineraryConfirmationFromDialog()))) {
            this.userSelectedEditorTypeFormControl.reset(this.currentEditorTypeFormControl.value);

            return;
        }

        this.restoreItineraryEditorStepState();
        this.currentEditorTypeFormControl.setValue(newEditorType);
        this.setFormDefaultValuesAndConstraints(currentPlanItinerary);
        this.editorTypeChange.emit(newEditorType);
    }

    private async getClearItineraryConfirmationFromDialog(): Promise<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                confirmationText: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.clearItineraryConfirmDialog.dialogText"
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.clearItineraryConfirmDialog.cancelButtonLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.clearItineraryConfirmDialog.confirmButtonLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });

        return lastValueFrom(dialogRef.afterClosed().pipe(map(Boolean), untilDestroyed(this)));
    }

    public async getUnsavedOperationHeightConfirmation(): Promise<boolean> {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmWebAppLibMission.itineraryEditorStep.unsavedParametersDialogHeader"),
                confirmationText: "",
                declineButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.clearItineraryConfirmDialog.cancelButtonLabel"
                ),
                confirmButtonLabel: this.translocoService.translate("dtmWebAppLibMission.itineraryEditorStep.dialogConfirmButtonLabel"),
            },
        });

        return lastValueFrom(dialogRef.afterClosed().pipe(map(Boolean), untilDestroyed(this)));
    }

    protected updateEntity<T extends ItineraryContentEntity>(entityId: string, update: Partial<T>) {
        const currentContent = this.localStore.selectSnapshotByKey("itineraryContent");
        let updatedEntityIndex = currentContent.findIndex((lookup) => lookup.id === entityId);
        let updatedEntity: ItineraryContentEntity;

        if (updatedEntityIndex === -1) {
            updatedEntityIndex = currentContent.findIndex(
                (lookup) => lookup.type === MapEntityType.Polyline3D && lookup.childEntities[entityId]
            );
            updatedEntity = (currentContent[updatedEntityIndex] as Polyline3DEntity).childEntities[entityId].entity;
        } else {
            updatedEntity = currentContent[updatedEntityIndex];
        }

        this.entityUpdate.emit({
            ...updatedEntity,
            ...update,
        });
    }

    protected updateArrayPropertyOfEntity<
        EntityType extends MapEntity,
        ArrayPropertyKey extends keyof EntityType,
        ValueType extends ArrayElementType<EntityType[ArrayPropertyKey]>
    >(entity: EntityType, arrayPropertyName: ArrayPropertyKey, index: number, value: ValueType) {
        const clonedArray = [...(entity[arrayPropertyName] as unknown as ValueType[])];
        clonedArray[index] = value;

        this.updateEntity(entity.id, { [arrayPropertyName]: clonedArray });
    }

    protected isItineraryEmpty(itineraryContent: ItineraryContent): boolean {
        return itineraryContent.length === 0;
    }

    protected setRouteGenerationSettings(settings: MissionRouteGenerationSettings) {
        this.routeGenerationSettings.setValue(settings);
    }

    private getMinimumDateTime(offsetMilliseconds: number = 0): Date {
        const result = new Date();

        result.setSeconds(0);
        result.setTime(result.getTime() + MILLISECONDS_IN_MINUTE + offsetMilliseconds);

        return result;
    }

    protected restoreItineraryEditorStepState() {
        this.itineraryFormGroupStateController.restore();
        this.itineraryFormControl.setValue([]);
        this.saveItineraryCurrentState();

        this.formUpdate.emit(this.itineraryFormGroup.getRawValue());
    }

    protected tryToGoBack() {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate("dtmUi.wizardStepChangeConfirmation.title"),
                confirmationText: this.translocoService.translate("dtmUi.wizardStepChangeConfirmation.content"),
                confirmButtonLabel: this.translocoService.translate("dtmUi.wizardStepChangeConfirmation.confirmationButtonLabel"),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.restoreItineraryEditorStepState();
                this.itineraryClear.emit();
                this.editorTypeChange.emit(this.itineraryFormGroup.value.editorType);
                this.back.emit();
            });
    }

    private saveItineraryCurrentState() {
        this.itineraryFormGroupStateController.save();
    }

    private setFormDefaultValuesAndConstraints(value: MissionPlanItinerary) {
        switch (value.type) {
            case ItineraryEditorType.Standard:
                if (value.estimatedArriveAt) {
                    this.dateFormControl.setValue(new Date(value.estimatedArriveAt));
                    this.datetimeFormControl.setValue(new Date(value.estimatedArriveAt));
                }

                if (value.time) {
                    const duration = DateUtils.convertISO8601DurationToSeconds(value.time);
                    this.durationFormControl.setValue(duration ? duration / SECONDS_IN_MINUTE : null);
                }

                break;

            case ItineraryEditorType.Custom:
                if (value.points[0]?.estimatedArriveAtMin) {
                    this.dateFormControl.setValue(new Date(value.points[0].estimatedArriveAtMin));
                    this.datetimeFormControl.setValue(new Date(value.points[0].estimatedArriveAtMin));
                }

                break;

            default:
                break;
        }

        if (!value.constraints) {
            return;
        }

        this.durationFormControl.setValidators([
            Validators.required,
            Validators.min(value.constraints.min.durationMinutes),
            Validators.max(value.constraints.max.durationMinutes),
        ]);
        if (!this.durationFormControl.value) {
            this.durationFormControl.setValue(value.constraints.default.durationMinutes);
        }
        this.durationFormControl.updateValueAndValidity();

        if (!this.horizontalBufferFormControl.value) {
            const horizontalBuffer = (value.type !== ItineraryEditorType.None && value.navigationAccuracy?.horizontal) || undefined;
            this.horizontalBufferFormControl.setValue((horizontalBuffer ?? value.constraints.default.horizontalNavigationAccuracy) * 2);
        }

        if (!this.verticalBufferFormControl.value) {
            const verticalBuffer = (value.type !== ItineraryEditorType.None && value.navigationAccuracy?.vertical) || undefined;
            this.verticalBufferFormControl.setValue((verticalBuffer ?? value.constraints.default.verticalNavigationAccuracy) * 2);
        }

        if (!this.flightLevelFormControl.value) {
            this.flightLevelFormControl.setValue(value.constraints.default.height);
        }

        this.formUpdate.emit(this.itineraryFormGroup.getRawValue());
        this.saveItineraryCurrentState();
    }

    protected isItineraryValid(itineraryContent: ItineraryContent, editorType: ItineraryEditorType): boolean {
        if (editorType === ItineraryEditorType.Assisted) {
            return itineraryContent?.length === 2;
        }

        return itineraryContent?.length > 0;
    }

    protected checkIfIsSoraApplicationCategory(category?: MissionPlanOperationCategoryOption): boolean {
        return (
            category?.type === MissionCategory.Specific &&
            category.specificPermitType === MissionPlanSpecificPermitType.Individual &&
            !category.specificCaaPermitId
        );
    }

    protected getSameDateInNextYear(startDate: Date | null): Date {
        const maxDate = startDate ? new Date(startDate) : new Date();
        maxDate.setFullYear(maxDate.getFullYear() + 1);

        return maxDate;
    }

    protected shouldShowItinerarySettingsPanel({
        category,
        missionType,
    }: {
        category?: MissionPlanOperationCategoryOption;
        missionType?: MissionType;
    }): boolean {
        if (!category) {
            return false;
        }

        return (
            category.type !== MissionCategory.Open &&
            (missionType === MissionType.BVLOS ||
                (category.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Individual))
        );
    }

    private prepareMinGroundRiskBuffer() {
        return this.itineraryFormGroup.valueChanges.pipe(
            startWith(null),
            map(() => this.itineraryFormGroup.getRawValue()),
            combineLatestWith(this.constraints$.pipe(RxjsUtils.filterFalsy()), this.currentPlanRoute$),
            map(([formData, constraints, planRoute]) =>
                this.calculateMinGroundRiskBuffer(formData as ItineraryEditorFormData, constraints, planRoute)
            ),
            map(Math.round)
        );
    }

    private calculateMinGroundRiskBuffer(
        formData: ItineraryEditorFormData,
        constraints: MissionPlanItineraryConstraints,
        planRoute: MissionPlanRoute | undefined
    ): number {
        if (formData.editorType === ItineraryEditorType.Standard && formData.zoneTopHeight) {
            return formData.zoneTopHeight;
        }

        if (formData.editorType === ItineraryEditorType.Custom) {
            return (
                planRoute?.sections.reduce((currentHeight, section) => {
                    let height = 0;
                    if (section.flightZone) {
                        height = section.flightZone.flightArea.volume.ceiling - section.flightZone.flightArea.volume.floor;
                    }

                    if (section.segment) {
                        const fromPointHeight = section.segment.fromWaypoint.point.height;
                        const toPointHeight = section.segment.toWaypoint.point.height;
                        height = Math.max(fromPointHeight, toPointHeight);
                    }

                    return Math.max(currentHeight, height);
                }, 0) ?? 0
            );
        }

        if (constraints) {
            return constraints.default.height;
        }

        return DEFAULT_ZONE_TOP_HEIGHT_METERS;
    }
}
