import { ChangeDetectionStrategy, Component } from "@angular/core";
import { GlobalFeatures } from "@dtm-frontend/shared/ui";
import { WizardState } from "@dtm-frontend/shared/ui/wizard";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import { StatementsWizardSteps } from "../../../services/operator-permissions.models";
import { OperatorPermissionsState } from "../../../state/operator-permissions.state";
import { STATEMENTS_WIZARD_ID } from "../content/statements-wizard-content.component";

@Component({
    selector: "dtm-web-app-lib-operator-permissions-statements-wizard-header",
    templateUrl: "./statements-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementsWizardHeaderComponent {
    protected readonly wizardId = STATEMENTS_WIZARD_ID;
    protected readonly StatementsWizardSteps = StatementsWizardSteps;
    protected readonly areUavsRequired$ = this.store.select(OperatorPermissionsState.areUavsRequired);
    protected readonly isPaymentFeatureAvailable$ = this.store.select(OperatorContextState.isFeatureAvailable(GlobalFeatures.Payments));
    protected readonly enabledSteps$ = this.store.select(WizardState.enabledSteps(this.wizardId)).pipe(
        RxjsUtils.filterFalsy(),
        map((enabledSteps) => ({
            isGainPermissionsEnabled: enabledSteps.some((step) => step === StatementsWizardSteps.GainPermissions),
            isUavInformationEnabled: enabledSteps.some((step) => step === StatementsWizardSteps.UavInformation),
            isSummaryEnabled: enabledSteps.some((step) => step === StatementsWizardSteps.Summary),
            isStatementSignEnabled: enabledSteps.some((step) => step === StatementsWizardSteps.StatementSign),
        }))
    );

    constructor(private readonly store: Store) {}
}
