<dtm-ui-editable-card
    *ngrxLet="{ operator: operator$, isEditMode: isEditModeOn$, canManageOperator: canManageOperator$ } as vm"
    [label]="
        (vm.operator.isAssociation
            ? 'dtmWebAppOperatorProfile.companyInfo.associationDetailsHeader'
            : 'dtmWebAppOperatorProfile.companyInfo.companyDetailsHeader'
        ) | transloco
    "
    (editModeChange)="manageFormState($event)"
    (actionSave)="saveForm()"
    [canLeaveEditModeOnSave]="false"
    [isEditButtonVisible]="vm.canManageOperator"
    [isEditModeOn]="vm.isEditMode"
>
    <ng-container previewContent>
        <div class="company-details">
            <dtm-ui-label-value
                [label]="
                    (vm.operator.isAssociation
                        ? 'dtmWebAppOperatorProfile.companyInfo.associationNameLabel'
                        : 'dtmWebAppOperatorProfile.companyInfo.companyNameLabel'
                    ) | transloco
                "
                [value]="vm.operator.name"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                class="email-value"
                [label]="'dtmWebAppOperatorProfile.companyInfo.emailLabel' | transloco"
                [value]="vm.operator.email"
                [canCopy]="true"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmWebAppOperatorProfile.companyInfo.companyNumberLabel' | transloco"
                [value]="vm.operator.companyNumber | tin | falsyDash"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmWebAppOperatorProfile.companyInfo.phoneNumberLabel' | transloco"
                [value]="vm.operator.phoneNumber | formatPhoneNumber"
                [canCopy]="true"
            >
            </dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.operator.associationRegistration?.nationalCourtRegister as nationalCourtRegister"
                [label]="'dtmWebAppOperatorProfile.companyInfo.nationalCourtRegisterLabel' | transloco"
                [value]="nationalCourtRegister"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                *ngIf="vm.operator.associationRegistration?.registrationNumber as registrationNumber"
                [label]="'dtmWebAppOperatorProfile.companyInfo.registrationNumberLabel' | transloco"
                [value]="registrationNumber"
            >
            </dtm-ui-label-value>
        </div>
        <dtm-ui-address-label-value [isCountryFlagVisible]="true" [address]="vm.operator.address"></dtm-ui-address-label-value>
    </ng-container>
    <div editableContent *ngIf="vm.isEditMode" class="company-info-form" [formGroup]="companyInfoFormGroup">
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppOperatorProfile.companyInfo.companyNumberLabel" | transloco : { isOptional: vm.operator.isAssociation } }}
            </label>
            <input matInput formControlName="companyNumber" [mask]="COMPANY_NUMBER_MASK" [showMaskTyped]="true" />
            <div
                class="field-error"
                *dtmUiFieldHasError="companyInfoFormGroup.controls.companyNumber; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>

            <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.companyNumber; name: 'minlength'">
                {{ "dtmWebAppOperatorProfile.companyInfo.companyNumberTooShortError" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-web-app-lib-association-id-details-control
            *ngIf="companyInfoFormGroup.controls.associationId.enabled"
            [formControl]="companyInfoFormGroup.controls.associationId"
            dtmUiMarkValueAccessorControlsAsTouched
        ></dtm-web-app-lib-association-id-details-control>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppOperatorProfile.companyInfo.companyNameLabel" | transloco }}
            </label>
            <input matInput formControlName="name" />
            <div
                class="field-error"
                *dtmUiFieldHasError="companyInfoFormGroup.controls.name; name: ['required', 'pattern']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.name; name: 'maxlength'; error as error">
                {{ "dtmWebAppOperatorProfile.companyInfo.companyNameLengthError" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppOperatorProfile.companyInfo.emailLabel" | transloco }}
            </label>
            <input matInput formControlName="email" />
            <div
                class="field-error"
                *dtmUiFieldHasError="companyInfoFormGroup.controls.email; name: ['required', 'pattern']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.email; name: 'email'">
                {{ "dtmWebAppOperatorProfile.companyInfo.invalidEmailError" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-phone-number-field formControlName="phoneNumber">
            <div
                class="field-error"
                *dtmUiFieldHasError="companyInfoFormGroup.controls.phoneNumber; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.phoneNumber; name: 'invalidNumber'">
                {{ "dtmWebAppOperatorProfile.companyInfo.invalidPhoneNumberErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.phoneNumber; name: 'invalidNumberType'">
                {{ "dtmWebAppOperatorProfile.companyInfo.invalidNumberTypeErrorHint" | transloco }}
            </div>
        </dtm-ui-phone-number-field>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppOperatorProfile.companyInfo.streetLabel" | transloco }}
            </label>
            <input matInput formControlName="streetName" />
            <div
                class="field-error"
                *dtmUiFieldHasError="companyInfoFormGroup.controls.streetName; name: ['required', 'pattern']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.streetName; name: 'maxlength'; error as error">
                {{ "dtmWebAppOperatorProfile.companyInfo.streetMaxLengthError" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <div class="split-fields-container">
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppOperatorProfile.companyInfo.houseNumberLabel" | transloco }}
                </label>
                <input matInput formControlName="houseNumber" />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="companyInfoFormGroup.controls.houseNumber; name: ['required', 'pattern']"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>

                <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.houseNumber; name: 'maxlength'; error as error">
                    {{ "dtmWebAppOperatorProfile.companyInfo.houseNumberLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppOperatorProfile.companyInfo.apartmentNumberLabel" | transloco }}
                </label>
                <input matInput formControlName="apartmentNumber" />
                <div
                    class="field-error"
                    *dtmUiFieldHasError="companyInfoFormGroup.controls.apartmentNumber; name: 'maxlength'; error as error"
                >
                    {{ "dtmWebAppOperatorProfile.companyInfo.apartmentNumberLengthError" | transloco : { max: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>
        </div>

        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppOperatorProfile.companyInfo.postCodeLabel" | transloco }}
            </label>
            <input matInput formControlName="postCode" [mask]="POST_CODE_MASK" [showMaskTyped]="true" />
            <div
                class="field-error"
                *dtmUiFieldHasError="companyInfoFormGroup.controls.postCode; name: ['required', 'mask']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>{{ "dtmWebAppOperatorProfile.companyInfo.cityLabel" | transloco }}</label>
            <input matInput type="text" formControlName="city" />
            <div
                class="field-error"
                *dtmUiFieldHasError="companyInfoFormGroup.controls.city; name: ['required', 'pattern']"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="companyInfoFormGroup.controls.city; name: 'maxlength'; error as error">
                {{ "dtmWebAppOperatorProfile.companyInfo.cityLengthError" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
    </div>
</dtm-ui-editable-card>
<ng-template #requiredErrorTemplate>
    {{ "dtmWebAppOperatorProfile.companyInfo.requiredFieldError" | transloco }}
</ng-template>
