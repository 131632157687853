import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { EmergencyType } from "@dtm-frontend/shared/ui/tactical";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface TacticalEmergencyComponentState {
    emergencyModalOpened: EmergencyType | undefined;
    activeEmergency: EmergencyType | undefined;
    isLoading: boolean;
}

interface EmergencyReport {
    emergencyType: EmergencyType;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-tactical-emergency",
    templateUrl: "./tactical-emergency.component.html",
    styleUrls: ["./tactical-emergency.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.fadeAnimation()],
})
export class TacticalEmergencyComponent {
    @Input()
    public set isLoading(value: BooleanInput) {
        this.localStore.patchState({ isLoading: coerceBooleanProperty(value) });
    }
    @Input()
    public set activeEmergency(value: EmergencyType | undefined) {
        this.localStore.patchState({ activeEmergency: value });
    }

    @Output()
    public emergencyReport = new EventEmitter<EmergencyReport | null>();

    protected readonly emergencyModalOpened$ = this.localStore.selectByKey("emergencyModalOpened");
    protected readonly activeEmergency$ = this.localStore.selectByKey("activeEmergency");
    protected readonly isLoading$ = this.localStore.selectByKey("isLoading");

    protected readonly EmergencyType = EmergencyType;

    @HostListener("document:keydown.escape", ["$event"])
    public onEscapeKey(): void {
        if (this.localStore.selectSnapshotByKey("emergencyModalOpened")) {
            this.closeEmergencyModal();
        }
    }
    constructor(private readonly localStore: LocalComponentStore<TacticalEmergencyComponentState>) {
        this.localStore.setState({ emergencyModalOpened: undefined, activeEmergency: undefined, isLoading: false });
    }

    protected openEmergencyModal(emergencyType: EmergencyType): void {
        this.localStore.patchState({ emergencyModalOpened: emergencyType });
    }

    protected closeEmergencyModal(): void {
        this.localStore.patchState({ emergencyModalOpened: undefined });
    }

    protected reportEmergency(emergencyType: EmergencyType): void {
        this.localStore.patchState({ activeEmergency: emergencyType });
        this.emergencyReport.emit({ emergencyType });
        this.closeEmergencyModal();
    }

    protected cancelEmergency() {
        this.emergencyReport.next(null);
    }

    protected getEmergencyButtonTooltipKey(emergencyType?: EmergencyType): string {
        switch (emergencyType) {
            case EmergencyType.TechnicalIssue:
                return "dtmWebAppLibTactical.emergency.technicalProblems.buttonTooltip";
            case EmergencyType.LostControl:
                return "dtmWebAppLibTactical.emergency.communicationProblems.buttonTooltip";
            case EmergencyType.EmergencyLanding:
                return "dtmWebAppLibTactical.emergency.flightToEmr.buttonTooltip";
            default:
                return "dtmWebAppLibTactical.emergency.emergencyButtonTooltip";
        }
    }
}
