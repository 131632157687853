<div class="dialog-header">
    <h4 mat-dialog-title>{{ "dtmWebAppMembership.deactivateMemberDialog.deactivateMemberTitle" | transloco }}</h4>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <p class="information">{{ data.deactivateMemberInformationText }}</p>
    <dtm-ui-textarea-field [maxLength]="MAX_REASON_LENGTH">
        <label>{{ data.deactivateMemberNoteLabel }}</label>
        <textarea matInput [formControl]="reasonControl" [rows]="4"></textarea>
        <div class="field-error" *dtmUiFieldHasError="reasonControl; name: 'maxlength'; error as error">
            {{
                "dtmWebAppMembership.deactivateMemberDialog.deactivateNoteMaxLengthValueError"
                    | transloco : { maxLength: error.requiredLength, exceedValue: error.actualLength - error.requiredLength }
            }}
        </div>
    </dtm-ui-textarea-field>
</div>
<div mat-dialog-actions align="end" *ngrxLet="data.isResignProcessing$; let isResignProcessing">
    <button type="button" class="button-secondary" [mat-dialog-close]="false">
        {{ "dtmWebAppMembership.deactivateMemberDialog.cancel" | transloco | transloco }}
    </button>
    <button type="button" class="button-primary" [disabled]="isResignProcessing" (click)="confirm()">
        {{ "dtmWebAppMembership.deactivateMemberDialog.save" | transloco }}
    </button>
</div>
