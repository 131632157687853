import { formatDate } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MissionProcessingPhaseExtended } from "@dtm-frontend/shared/mission";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { tap } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MembershipCapabilities, MembershipMissionsFilter } from "../../../services/membership.models";

interface MembershipMissionsFilterComponentState {
    filtersCount: number;
    incomingFilters: MembershipMissionsFilter | undefined;
    capabilities: MembershipCapabilities | undefined;
}

interface FiltersForm {
    status: FormControl<MissionProcessingPhaseExtended[] | null>;
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;
    name: FormControl<string | null>;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "dateFrom",
        filterLabel: "dtmWebAppMembership.missionsListFilters.dateFromLabel",
        type: FilterType.Date,
    },
    {
        key: "dateTo",
        filterLabel: "dtmWebAppMembership.missionsListFilters.dateToLabel",
        type: FilterType.Date,
    },
    {
        key: "name",
        filterLabel: "dtmWebAppMembership.missionsListFilters.searchByTextLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: "status",
        filterLabel: "dtmWebAppMembership.missionsListFilters.statusFieldLabel",
        filterValueLabel: "dtmWebAppMembership.missionsListFilters.statusLabel",
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-membership-missions-filter[capabilities]",
    templateUrl: "./membership-missions-filter.component.html",
    styleUrls: ["./membership-missions-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembershipMissionsFilterComponent implements OnInit {
    @Input() public set capabilities(value: MembershipCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }
    @Input() public set incomingFilters(value: MembershipMissionsFilter | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }

    protected readonly nameControl = new FormControl<string | null>(null);
    protected readonly dateFromControl = new FormControl<Date | null>(null);
    protected readonly dateToControl = new FormControl<Date | null>(null);
    protected readonly statusControl = new FormControl<MissionProcessingPhaseExtended[] | null>(null);

    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    protected readonly capabilities$ = this.localStore.selectByKey("capabilities").pipe(RxjsUtils.filterFalsy());
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly filtersForm = new FormGroup<FiltersForm>({
        dateFrom: this.dateFromControl,
        dateTo: this.dateToControl,
        name: this.nameControl,
        status: this.statusControl,
    });

    @Output() public filtersChange = new EventEmitter<MembershipMissionsFilter>();

    constructor(
        private readonly localStore: LocalComponentStore<MembershipMissionsFilterComponentState>,
        @Inject(LOCALE_ID) private readonly locale: string,
        private readonly translocoHelper: TranslationHelperService
    ) {
        localStore.setState({ filtersCount: 0, incomingFilters: undefined, capabilities: undefined });
    }

    public ngOnInit(): void {
        this.assignInitialFiltersAndWatchChanges();
    }

    protected clearFilters() {
        this.filtersForm.reset();
    }

    private onFormValueChanges(): void {
        this.filtersForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(equal),
                tap(() => {
                    this.updateAppliedFiltersCount();
                    if (this.filtersForm.invalid) {
                        return;
                    }
                    this.filtersChange.emit(this.prepareFormValueToEmit());
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private prepareFormValueToEmit(): MembershipMissionsFilter {
        const dateFromFormValue = this.dateFromControl.getRawValue();
        const dateToFormValue = this.dateToControl.getRawValue();
        let dateFrom: string | null = null;
        let dateTo: string | null = null;

        if (dateFromFormValue) {
            dateFrom = this.convertDateToString(dateFromFormValue);
        }

        if (dateToFormValue) {
            dateTo = this.convertDateToString(dateToFormValue);
        }

        return {
            status: this.statusControl.value,
            dateFrom: dateFrom,
            dateTo: dateTo,
            name: this.nameControl.value,
        };
    }

    private convertDateToString(value: Date): string | null {
        return formatDate(value as Date, "yyyy-MM-dd", this.locale);
    }

    private updateAppliedFiltersCount(): void {
        if (this.filtersForm.invalid) {
            return;
        }

        const values = Object.values(this.filtersForm.value);
        const filtersCount = values.flat().filter((value) => FunctionUtils.isTruthy(value)).length;

        this.localStore.patchState({ filtersCount });
    }

    private assignInitialFiltersAndWatchChanges() {
        this.onFormValueChanges();

        this.localStore
            .selectByKey("incomingFilters")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((filters: MembershipMissionsFilter) => {
                this.filtersForm.setValue(
                    {
                        ...filters,
                        dateFrom: filters.dateFrom ? new Date(filters.dateFrom) : null,
                        dateTo: filters.dateTo ? new Date(filters.dateTo) : null,
                    },
                    { emitEvent: false }
                );
                this.updateAppliedFiltersCount();
            });
    }
}
