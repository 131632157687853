<ng-container *ngrxLet="{ queries: queries$, isProcessing: isProcessing$ } as vm">
    <div class="grid">
        <dtm-web-app-lib-mission-plan-list-filter
            [filters]="vm.queries"
            [flightPurposes]="flightPurposes$ | ngrxPush"
            (filtersQueryChange)="handleFilterQuery($event)"
            (groupByChange)="handleGroupByChange($event)"
            (filtersAppliedChange)="handleFilterAppliedChange($event)"
        ></dtm-web-app-lib-mission-plan-list-filter>
        <dtm-ui-error
            class="error"
            *ngIf="error$ | ngrxPush; else noErrorTemplate"
            [errorMessage]="'dtmWebAppLibMission.genericErrorMessage' | transloco"
            (reload)="refreshListWithCurrentFilters()"
        ></dtm-ui-error>
    </div>

    <ng-template #noErrorTemplate>
        <div class="list-container dtm-tabs">
            <mat-tab-group
                color="accent"
                (selectedIndexChange)="handleFilterQuery({ processingPhase: PHASE_FILTERS[$event] })"
                [selectedIndex]="vm.queries | invoke : getMissionPhaseIndexFromQuery"
                class="phase-tabs"
            >
                <mat-tab
                    [label]="'dtmWebAppLibMission.missionFilters.phaseFiltersLabels' | transloco : { value: phase }"
                    *ngFor="let phase of PHASE_FILTERS"
                >
                    <div class="tab-empty-content"></div>
                </mat-tab>
            </mat-tab-group>
            <dtm-web-app-lib-mission-plan-list
                [missionPlanList]="missionPlanList$ | ngrxPush"
                [isProcessing]="isProcessing$ | ngrxPush"
                [isExtendedMode]="isMoreThanOneOperator$ | ngrxPush"
                [groupBy]="groupBy$ | ngrxPush"
                [areFiltersApplied]="areFiltersApplied$ | ngrxPush"
                (missionPlanDelete)="deleteMissionPlan($event)"
                (missionPlanClone)="cloneMissionPlan($event)"
                (missionCancel)="cancelMission($event)"
                (missionPlanDownloadKml)="downloadMissionPlanKml($event)"
            ></dtm-web-app-lib-mission-plan-list>
            <dtm-ui-pagination
                *ngIf="pagination$ | ngrxPush as pagination"
                [pagination]="pagination"
                [pageSizeOptions]="pageSizeOptions"
                (page)="handlePageChange($event)"
            ></dtm-ui-pagination>
        </div>
    </ng-template>
</ng-container>
