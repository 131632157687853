<dtm-web-app-lib-public-profile-details
    *ngIf="operatorData$ | ngrxPush as operatorData"
    [type]="PublicProfileType.Operator"
    [details]="operatorData"
>
</dtm-web-app-lib-public-profile-details>

<div *ngIf="error$ | ngrxPush as error" class="error-container">
    <ng-container [ngSwitch]="error.type">
        <dtm-web-app-lib-public-profile-not-found
            *ngSwitchCase="PublicProfileErrorType.NotFound"
            [type]="PublicProfileType.Operator"
        ></dtm-web-app-lib-public-profile-not-found>
        <dtm-ui-error
            *ngSwitchDefault
            [errorMessage]="'dtmWebAppLibPublicProfile.errors.cannotGetOperatorData' | transloco"
            (reload)="refreshOperatorDetails()"
        ></dtm-ui-error>
    </ng-container>
</div>
