import { Page, UavModel, UavType } from "@dtm-frontend/shared/ui";
import { ISO8601TimeDuration } from "@dtm-frontend/shared/utils";
import { FailSafe, UavClass } from "../../shared";

export enum OperatorType {
    Personal = "PERSONAL",
    Enterprise = "ENTERPRISE",
}

export enum DriveType {
    ELECTRIC = "ELECTRIC",
    COMBUSTION = "COMBUSTION",
    HYBRID = "HYBRID",
    JET = "JET",
    SOLAR = "SOLAR",
}

export enum CommunicationType {
    RADIO = "RADIO",
    GSM = "GSM",
    SATELLITE = "SATELLITE",
}

export enum CommunicationLinkType {
    C2 = "C2",
    C3 = "C3",
    Unknown = "Unknown",
}

export enum TrackerType {
    ADSB = "ADSB",
    HOD = "HOD",
}

export enum NavigationAccuracyClass {
    Class1 = "CLASS_1",
    Class2 = "CLASS_2",
    Class3 = "CLASS_3",
}

export enum NavigationAccuracyType {
    HorizontalFlight = "HORIZONTAL_FLIGHT",
    TakeoffAndLanding = "TAKEOFF_AND_LANDING",
}

export enum EquipmentType {
    Camera = "CAMERA",
    Parachute = "PARACHUTE",
    PropellersGuards = "PROPELLERS_GUARDS",
    NavigationLighting = "NAVIGATION_LIGHTING",
    StrobeLighting = "STROBE_LIGHTING",
    NightLighting = "NIGHT_LIGHTING",
    Fts = "FTS",
}

export enum UavModelDocumentType {
    Manual = "MANUAL",
    ProjectDocumentation = "PROJECT_DOCUMENTATION",
}

export enum UavSetupDocumentType {
    ParachuteManual = "PARACHUTE_MANUAL",
    ParachutePhoto = "PARACHUTE_PHOTO",
    CheckList = "CHECK_LIST",
    TechnicalSupportProgram = "TECHNICAL_SUPPORT_PROGRAM",
    Fts = "FTS",
    Dvr = "DVR",
    TestsResults = "TESTS_RESULTS",
}

export enum UavErrorType {
    Unknown = "UavErrorUnknown",
    NotFound = "UavErrorNotFound",
    CannotGetTrackers = "CannotGetTrackers",
    CannotGetCapabilities = "CannotGetCapabilities",
    CannotGetManufacturers = "CannotGetManufacturers",
    CannotGetModel = "CannotGetModel",
    CannotCreateUav = "CannotCreateUav",
    CannotCreateUavSerialNumberExists = "CannotCreateUavSerialNumberExists",
    CannotUpdateUavSerialNumberExists = "CannotUpdateUavSerialNumberExists",
    CannotGetUavs = "CannotGetUavs",
    CannotGetUav = "CannotGetUav",
    CannotGetSetup = "CannotGetSetup",
    CannotGetCapabilitiesForShareSetup = "CannotGetCapabilitiesForShareSetup",
    CannotShareSetupForOperator = "CannotShareSetupForOperator",
    CannotDeleteUav = "CannotDeleteUav",
    CannotUpdateUav = "CannotUpdateUav",
    CannotCreateUavSetup = "CannotCreateUavSetup",
    CannotUpdateUavSetup = "CannotUpdateUavSetup",
    CannotDeleteUavSetup = "CannotDeleteUavSetup",
    CannotAttachUavDocuments = "CannotAttachUavDocuments",
    UavAlreadySharedToOperator = "UavAlreadySharedToOperator",
}

export interface UavError {
    type: UavErrorType;
}

export type SortParam = `name,${"asc" | "desc"}`;

export const MAX_UAV_NAME_LENGTH = 100;
export const MIN_UAV_NAME_LENGTH = 2;
export const MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS = 3;

export interface UavListParams {
    operatorId: string;
    size: number;
    page: number;
    textSearch: string | undefined;
    sortBy: SortParam;
}

export interface UavListItem {
    id: string;
    name: string;
    manufacturerName: string;
    modelName: string;
    modelType: UavType;
    serialNumbers: string[];
    uavClasses: UavClass[];
    imageId?: string;
    imageUrl?: string;
    ownerName: string;
    isShared: boolean;
    isCustom: boolean;
    isSwarm: boolean;
    isCeCompliant: boolean;
}

export interface UavListWithPages {
    pages: Page;
    content: UavListItem[];
}

export interface UavCapabilities {
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    canShareUavs: boolean;
}

export interface Manufacturer {
    name: string;
    models: ManufacturerModel[];
}

export interface ManufacturerModel {
    id: string;
    name: string;
    isCertificationRequired: boolean;
    uavClasses: UavClass[];
    imageUrl?: string;
}

export interface Operator {
    id: string;
    name: string;
    type: OperatorType;
}

export type ShareableOperator = Pick<Operator, "id" | "name">;

export interface UavProperties {
    technicalSpecification: TechnicalSpecification;
    communications: Communication[];
    trackings?: Tracking[];
    equipment: Equipment;
    documents: UavSetupDocuments;
}

export interface TechnicalSpecification {
    numberOfEngines: number | null;
    driveType: DriveType;
    minRecommendedAmbientTemperature: number | null;
    maxRecommendedAmbientTemperature: number | null;
    takeOffMass: number;
    maxTakeOffMass: number;
    maxDroneWidth: number;
    maxFlightTime: ISO8601TimeDuration;
    hasFlightSpeedLimit: boolean;
    maxFlightSpeed: number;
    minFlightSpeed?: number | null;
    maxClimbSpeed: number | null;
    maxDescentSpeed: number | null;
    maxWind: number | null;
    maxFlightAltitude: number | null;
    hasRainFlightPossibility: boolean;
    failSafe: FailSafe[];
    hasGeofencing: boolean;
    hasDetectAndAvoid: boolean;
    hasProximitySensors: boolean;
    hasModuleRedundancy: boolean;
    hasGeocage: boolean;
}

export interface Communication {
    id?: string;
    type: CommunicationType;
    transmissionLinkRange: number;
    linkType: CommunicationLinkType;
    linkDelay: number | null;
    isEmbedded: boolean;
    frequencies: CommunicationFrequency[];
}

export interface CommunicationFrequency {
    minGhz: number;
    maxGhz: number;
}

export interface Equipment {
    camera: EquipmentItem[];
    parachute: EquipmentParachute[];
    propellersGuards: EquipmentItem[];
    navigationLighting: EquipmentItem[];
    strobeLighting: EquipmentItem[];
    nightLighting: EquipmentItem[];
    fts: EquipmentItem[];
}

export interface EquipmentItem {
    id?: string;
    name?: string;
    weight: number;
    isEmbedded: boolean;
}

export interface EquipmentParachute extends EquipmentItem {
    minHeight: number;
    hasCeCertificate: boolean;
    descentSpeed: number;
    maxWindSpeed: number;
    minOperatingTemperature: number;
    maxOperatingTemperature: number;
    isAstmF332218Compliant: boolean;
}

export interface EditableUavSetup extends Omit<UavSetup, "id" | "isPrimary" | "technicalSpecification" | "communications"> {
    id?: string;
    technicalSpecification: Pick<
        TechnicalSpecification,
        "maxFlightTime" | "hasFlightSpeedLimit" | "maxFlightSpeed" | "minFlightSpeed" | "failSafe" | "hasGeofencing" | "hasGeocage"
    >;
    communications: Communication[];
}

export interface EditableCustomUavSetup extends Omit<EditableUavSetup, "technicalSpecification"> {
    id?: string;
    technicalSpecification: TechnicalSpecification;
}

export interface EditableCustomUavInitialSetup extends Omit<EditableCustomUavSetup, "technicalSpecification" | "equipment"> {
    technicalSpecification: TechnicalSpecification | null;
    equipment: Equipment | null;
}

export interface Tracking {
    id?: string;
    identifier: string;
    weight: number;
    trackerId: string;
    flightNavigationAccuracy: NavigationAccuracyClass;
    takeoffAndLandingNavigationAccuracy: NavigationAccuracyClass;
    isEmbedded: boolean;
}

export interface Tracker {
    id: string;
    type: TrackerType;
    name: string;
}

export interface UavDocumentFile {
    id: string;
    name: string;
    size: number;
    isEditable: boolean;
}

export type UavModelDocuments = Partial<Record<UavModelDocumentType, UavDocumentFile>>;
export type UavSetupDocuments = Partial<Record<UavSetupDocumentType, UavDocumentFile[]>>;

export interface Uav {
    id: string;
    name: string;
    model: UavModel;
    operator: Operator;
    serialNumbers: string[];
    uavClasses: UavClass[];
    setups: UavSetup[];
    ownerName: string;
    isShared: boolean;
    isSwarm: boolean;
    isCustom: boolean;
    isCeCompliant: boolean;
    documents: UavModelDocuments;
}

export interface UavModelProperties extends UavModel, Omit<UavProperties, "documents"> {
    uavClasses: UavClass[];
}

export type BasicUavSetup = Pick<UavSetup, "name" | "id" | "isPrimary">;
export interface UavSetup extends Required<UavProperties> {
    id: string;
    name: string;
    isPrimary: boolean;
}

export interface NewUav {
    operatorId: string;
    name: string;
    isSwarm: boolean;
    serialNumbers: string[];
    uavClasses: UavClass[];
    manufacturer: Manufacturer;
    uavModel: ManufacturerModel;
    setup: EditableUavSetup;
}

export interface NewCustomUav {
    operatorId: string;
    name: string;
    isSwarm: boolean;
    serialNumbers: string[];
    uavClasses: UavClass[];
    isCeCompliant: boolean;
    manufacturerName: string;
    modelName: string;
    type: UavType;
    imageId: string | null;
    setup: EditableCustomUavSetup;
}

export interface CapabilitiesToShare {
    operators: ShareableOperator[];
    setups: BasicUavSetup[];
}

export interface ShareUavModel {
    receivingOperatorId: string;
    sharedSetups: string[];
}

export interface UavInfo
    extends Pick<Uav, "name" | "serialNumbers" | "uavClasses" | "ownerName" | "isShared" | "isCustom" | "isCeCompliant" | "isSwarm"> {
    manufacturerName: string;
    modelName: string;
    modelType: UavType;
    imageUrl?: string;
}

export interface NavigationAccuracy {
    classValue: NavigationAccuracyClass;
    type: NavigationAccuracyType;
    horizontal: string;
    vertical: string;
}

export interface EditableUav {
    name: string;
    serialNumbers: string[];
    uavClasses: UavClass[];
}

export interface EditableCustomUav extends EditableUav {
    manufacturerName: string;
    modelName: string;
    type: UavType;
    isCeCompliant: boolean;
    imageId: string | null;
}
