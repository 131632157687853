<dtm-ui-lib-wizard-header [wizardId]="wizardId" *ngrxLet="enabledSteps$ as enabledSteps">
    <dtm-ui-lib-wizard-step-header [stepId]="StatementsWizardSteps.GainPermissions" [isEnabled]="enabledSteps.isGainPermissionsEnabled">
        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header
        [stepId]="StatementsWizardSteps.UavInformation"
        *ngIf="areUavsRequired$ | ngrxPush"
        [isEnabled]="enabledSteps.isUavInformationEnabled"
    >
        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.uavInformation.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="StatementsWizardSteps.Summary" [isEnabled]="enabledSteps.isSummaryEnabled">
        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.header" | transloco }}
    </dtm-ui-lib-wizard-step-header>
    <dtm-ui-lib-wizard-step-header [stepId]="StatementsWizardSteps.StatementSign" [isEnabled]="enabledSteps.isStatementSignEnabled">
        {{
            ((isPaymentFeatureAvailable$ | ngrxPush)
                ? "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.header"
                : "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.headerWithoutPayment"
            ) | transloco
        }}
    </dtm-ui-lib-wizard-step-header>
</dtm-ui-lib-wizard-header>
