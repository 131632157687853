<dtm-ui-expandable-panel [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <div headerSlot>
        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneRadius.header" | transloco }}
    </div>
    <form class="content" *ngIf="formControls$ | ngrxPush as formControls">
        <mat-slide-toggle labelPosition="before" [formControl]="formControls.isEnlargedZone">
            {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneRadius.enlargedRadiusLabel" | transloco }}
            <dtm-ui-popover
                (click)="$event.preventDefault()"
                [popoverText]="'dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneRadius.enlargedRadiusPopover' | transloco"
            ></dtm-ui-popover>
        </mat-slide-toggle>
        <div [class.hidden]="!formControls.isEnlargedZone.value">
            <dtm-ui-checkbox-field
                [formControl]="formControls.isEnlargedZoneStatementAccepted"
                class="conditions-content"
                [required]="formControls.isEnlargedZone.value"
            >
                <span>
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.zoneRadius.enlargedRadiusStatementText" | transloco }}
                </span>
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors>
                <div class="field-error" *dtmUiFieldHasError="formControls.isEnlargedZoneStatementAccepted; name: 'required'">
                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </div>

        <div class="deferred-form-actions" *ngIf="formStateController$ | ngrxPush as formStateController">
            <ng-container *ngIf="formStateController.isEditing$ | ngrxPush">
                <button class="button-secondary" type="button" (click)="formStateController.restore()">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.restoreFormButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary next-button"
                    type="button"
                    (click)="saveFormValues(formStateController, formControls)"
                    [disabled]="!formStateController.isValid || (isDisabled$ | ngrxPush)"
                >
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveFormButtonLabel" | transloco }}
                </button>
            </ng-container>
        </div>
    </form>
</dtm-ui-expandable-panel>
