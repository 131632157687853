import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";
import { firstValueFrom, lastValueFrom } from "rxjs";
import { filter } from "rxjs/operators";
import { OperatorContextState } from "../../../shared";
import { PilotRegistrationActions } from "../../index";

@Injectable()
export class PilotRegistrationWizardResolver {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public async resolve(): Promise<void | boolean> {
        await firstValueFrom(this.store.select(OperatorContextState.isProcessing).pipe(filter((val) => val === false)));

        const isAllowedToRegisterOperator = this.store.selectSnapshot(OperatorContextState.isAllowedToRegisterOperator);
        const pilot = await firstValueFrom(this.store.select(OperatorContextState.pilot));

        if (pilot) {
            return this.router.navigate(["pilot"]);
        } else if (isAllowedToRegisterOperator) {
            return await lastValueFrom(
                this.store.dispatch(
                    new PilotRegistrationActions.GetPilotRegistrationCapabilities(this.store.selectSnapshot(AuthState.userId))
                )
            );
        } else {
            return this.router.navigate(["dashboard"]);
        }
    }
}
