<div class="header">
    <dtm-ui-icon name="question"></dtm-ui-icon>
    <p>{{ "dtmWebAppLibSpecPermApp.manual.header" | transloco }}</p>
</div>
<ol class="main-content">
    <li>
        <h2>{{ "dtmWebAppLibSpecPermApp.manual.firstManualItem.header" | transloco }}</h2>
        <p [innerHtml]="'dtmWebAppLibSpecPermApp.manual.firstManualItem.content' | transloco"></p>
        <button type="button" routerLink="/mission/new" class="button-link-small" [tabindex]="0">
            {{ "dtmWebAppLibSpecPermApp.manual.firstManualItem.planMissionLink" | transloco }}
        </button>
    </li>
    <li>
        <h2>{{ "dtmWebAppLibSpecPermApp.manual.secondManualItem.header" | transloco }}</h2>
        <p>{{ "dtmWebAppLibSpecPermApp.manual.secondManualItem.content" | transloco }}</p>
    </li>
    <li>
        <h2>{{ "dtmWebAppLibSpecPermApp.manual.thirdManualItem.header" | transloco }}</h2>
        <p>{{ "dtmWebAppLibSpecPermApp.manual.thirdManualItem.content1" | transloco }}</p>
        <button type="button" (click)="operationsManualTemplateDownload.emit()" class="button-link-small" [tabindex]="0">
            {{ "dtmWebAppLibSpecPermApp.manual.thirdManualItem.downloadOperationsManualTemplateLink" | transloco }}
        </button>
        <button type="button" (click)="operationsManualGuideOpen.emit()" class="button-link-small" [tabindex]="0">
            {{ "dtmWebAppLibSpecPermApp.manual.thirdManualItem.goToOperationsManualLink" | transloco }}
        </button>
        <p class="additional-info">{{ "dtmWebAppLibSpecPermApp.manual.thirdManualItem.content2" | transloco }}</p>
        <button type="button" (click)="osoGuideOpen.emit()" class="button-link-small" [tabindex]="0">
            {{ "dtmWebAppLibSpecPermApp.manual.thirdManualItem.goToOsoGuideLink" | transloco }}
        </button>
    </li>
    <li>
        <h2>{{ "dtmWebAppLibSpecPermApp.manual.fourthManualItem.header" | transloco }}</h2>
        <p>{{ "dtmWebAppLibSpecPermApp.manual.fourthManualItem.content" | transloco }}</p>
    </li>
    <li>
        <h2>{{ "dtmWebAppLibSpecPermApp.manual.fifthManualItem.header" | transloco }}</h2>
        <div [innerHtml]="'dtmWebAppLibSpecPermApp.manual.fifthManualItem.content' | transloco"></div>
    </li>
    <li>
        <h2>{{ "dtmWebAppLibSpecPermApp.manual.sixthManualItem.header" | transloco }}</h2>
        <p>{{ "dtmWebAppLibSpecPermApp.manual.sixthManualItem.content1" | transloco }}</p>
        <ul>
            <li>{{ "dtmWebAppLibSpecPermApp.manual.sixthManualItem.attachment1Content" | transloco }}</li>
            <li>
                {{ "dtmWebAppLibSpecPermApp.manual.sixthManualItem.attachment2Content" | transloco }}
                <button type="button" (click)="attorneyPowerTemplateDownload.emit()" class="button-link-small" [tabindex]="0">
                    {{ "dtmWebAppLibSpecPermApp.manual.sixthManualItem.downloadAttorneyPowerTemplateLink" | transloco }}
                </button>
            </li>
            <li>{{ "dtmWebAppLibSpecPermApp.manual.sixthManualItem.attachment3Content" | transloco }}</li>
        </ul>
        <div [innerHtml]="'dtmWebAppLibSpecPermApp.manual.sixthManualItem.content2' | transloco"></div>
    </li>
    <li>
        <h2>{{ "dtmWebAppLibSpecPermApp.manual.seventhManualItem.header" | transloco }}</h2>
        <div [innerHtml]="'dtmWebAppLibSpecPermApp.manual.seventhManualItem.content' | transloco"></div>
    </li>
</ol>
