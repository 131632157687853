import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { ConfirmationDialogComponent, GlobalOperatorPermissions, OperatorType } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, switchMap } from "rxjs/operators";
import { OPERATIONAL_AUTHORIZATIONS } from "../../../shared/models/mission.models";
import { OperatorInsurancePolicy } from "../../../shared/models/pilot-and-operator.models";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { CompanyInfo, NewAttorneyPower, OperatorConfirmationDocumentType, OperatorProfileErrorType } from "../../models/operator.models";
import { OperatorProfileActions } from "../../state/operator-profile.actions";
import { OperatorProfileState } from "../../state/operator-profile.state";

enum OperatorQualificationsType {
    Operator = "OPERATOR",
}

const OPERATOR_CONFIRMATION_DOCUMENT_EXTENSION = ".pdf";

interface OperatorProfileContainerComponentState {
    qualificationsType: OperatorQualificationsType | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-profile-container",
    templateUrl: "./operator-profile-container.component.html",
    styleUrls: ["./operator-profile-container.component.scss", "../../../shared/styles/qualifications-sidenav.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.foldAnimation()],
})
export class OperatorProfileContainerComponent implements OnDestroy {
    protected readonly operatorProfile$ = this.store.select(OperatorProfileState.operator).pipe(RxjsUtils.filterFalsy());
    protected readonly isOperatorReadPermission$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorRead)
    );
    protected readonly isOperatorManagePermission$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorManage)
    );
    protected readonly error$ = this.store.select(OperatorProfileState.operatorProfileError);
    protected readonly avatarBase64$ = this.store.select(OperatorProfileState.operatorAvatarBase64);
    protected readonly isOperatorProfileProcessing$ = this.store.select(OperatorProfileState.isOperatorProfileProcessing);
    protected readonly isAvatarProcessing$ = this.store.select(OperatorProfileState.isAvatarProcessing);
    protected readonly isAttorneyPowerCapabilitiesProcessing$ = this.store.select(
        OperatorProfileState.isAttorneyPowerCapabilitiesProcessing
    );
    protected readonly userId$ = this.store.select(AuthState.userId);
    protected readonly attorneyPowerCapabilities$ = this.store.select(OperatorProfileState.attorneyPowerCapabilities);
    protected readonly attorneyPowerCapabilitiesError$ = this.store.select(OperatorProfileState.attorneyPowerCapabilitiesError);
    protected readonly isAttorneyPowerProcessing$ = this.store.select(OperatorProfileState.isAttorneyPowerProcessing);
    protected readonly qualificationsType$ = this.localStore.selectByKey("qualificationsType");

    protected readonly OPERATIONAL_AUTHORIZATIONS = OPERATIONAL_AUTHORIZATIONS;
    protected readonly OperatorQualificationsType = OperatorQualificationsType;

    constructor(
        private readonly store: Store,
        private readonly transloco: TranslocoService,
        private readonly toastService: ToastrService,
        private readonly router: Router,
        private readonly matDialog: MatDialog,
        private readonly localStore: LocalComponentStore<OperatorProfileContainerComponentState>
    ) {
        localStore.setState({ qualificationsType: undefined });
        this.watchForOperatorContextChanges();
    }

    public ngOnDestroy(): void {
        this.store.dispatch(new OperatorProfileActions.ClearOperatorState());
    }

    protected setOperatorQualificationsType(qualificationsType?: OperatorQualificationsType | undefined) {
        this.localStore.patchState({ qualificationsType });
    }

    protected reloadOperatorProfile(): void {
        const selectedContext = this.store.selectSnapshot(OperatorContextState.selectedContext);
        if (selectedContext?.id) {
            this.store.dispatch([
                new OperatorProfileActions.GetOperatorProfile(selectedContext.id),
                new OperatorProfileActions.GetOperatorAvatar(selectedContext.id),
            ]);
        }
    }

    protected editCompanyInfo(companyInfo: CompanyInfo): void {
        this.store
            .dispatch(new OperatorProfileActions.EditOperatorCompanyInfo(companyInfo))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorProfileState.editOperatorAddressError);

                if (!error) {
                    this.toastService.success(
                        this.transloco.translate("dtmWebAppOperatorProfile.companyInfo.operatorCompanyInfoSuccessSaveMessage")
                    );

                    return;
                }

                switch (error.type) {
                    case OperatorProfileErrorType.CompanyNumberConflicted:
                        this.toastService.error(
                            this.transloco.translate("dtmWebAppOperatorProfile.companyInfo.companyNumberConflictedError")
                        );
                        break;
                    default:
                        this.toastService.error(
                            this.transloco.translate("dtmWebAppOperatorProfile.companyInfo.cannotSaveOperatorCompanyInfoMessage")
                        );
                }
            });
    }

    protected editOperatorInsurancePolicy(policy: OperatorInsurancePolicy): void {
        this.store
            .dispatch(new OperatorProfileActions.EditOperatorInsurancePolicy(policy))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorProfileState.editOperatorInsurancePolicyError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppOperatorProfile.insurancePolicyEdit.cannotSaveOperatorInsurancePolicyMessage")
                    );

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmWebAppOperatorProfile.insurancePolicyEdit.operatorInsurancePolicySuccessSaveMessage")
                );
            });
    }

    protected operatorAvatarSave(croppedBase64Image: string): void {
        this.store
            .dispatch(new OperatorProfileActions.SaveOperatorAvatar(croppedBase64Image))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorProfileState.editOperatorAvatarError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppOperatorProfile.operatorAvatar.cannotSaveOperatorAvatarErrorMessage")
                    );

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppOperatorProfile.operatorAvatar.operatorAvatarSuccessSave"));
            });
    }

    protected deleteInsurancePolicy(): void {
        this.store
            .dispatch(new OperatorProfileActions.DeleteInsurancePolicy())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorProfileState.editOperatorInsurancePolicyError);
                if (error) {
                    this.toastService.error(
                        this.transloco.translate(
                            "dtmWebAppOperatorProfile.insurancePolicyEdit.cannotDeleteOperatorInsurancePolicyErrorMessage"
                        )
                    );
                } else {
                    this.toastService.success(
                        this.transloco.translate("dtmWebAppOperatorProfile.insurancePolicyEdit.deleteOperatorInsurancePolicySuccessMessage")
                    );
                }
            });
    }

    protected deleteOperatorAvatar(): void {
        this.store
            .dispatch(new OperatorProfileActions.DeleteAvatar())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorProfileState.editOperatorAvatarError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppOperatorProfile.operatorAvatar.cannotDeleteOperatorAvatarErrorMessage")
                    );
                } else {
                    this.toastService.success(
                        this.transloco.translate("dtmWebAppOperatorProfile.operatorAvatar.deleteOperatorAvatarSuccessMessage")
                    );
                }
            });
    }

    protected getAttorneyPowerCapabilities() {
        this.store.dispatch(new OperatorProfileActions.GetAttorneyPowerCapabilities());
    }

    protected saveAttorneyPowerForm(formValue: NewAttorneyPower) {
        this.store
            .dispatch(new OperatorProfileActions.AddAttorneyPower(formValue))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(OperatorProfileState.addAttorneyPowerError)) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppOperatorProfile.attorneyPowers.couldNotAddAttorneyPowerErrorMessage")
                    );

                    return;
                }
                this.reloadOperatorProfile();
                this.toastService.success(this.transloco.translate("dtmWebAppOperatorProfile.attorneyPowers.attorneyPowerAddedMessage"));
            });
    }

    protected removeAttorneyPower(attorneyPowerId: string) {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppOperatorProfile.attorneyPowers.removeAttorneyPowerConfirmationTitle"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppOperatorProfile.attorneyPowers.removeAttorneyPowerConfirmationText"
                    ),
                    declineButtonLabel: this.transloco.translate("dtmWebAppOperatorProfile.attorneyPowers.cancelAttorneyPowerRemovalLabel"),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppOperatorProfile.attorneyPowers.confirmAttorneyPowerRemovalLabel"
                    ),
                },
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new OperatorProfileActions.RemoveAttorneyPower(attorneyPowerId))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.store.selectSnapshot(OperatorProfileState.removeAttorneyPowerError)) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppOperatorProfile.attorneyPowers.couldNotRemoveAttorneyPowerErrorMessage")
                    );

                    return;
                }
                this.reloadOperatorProfile();
                this.toastService.success(
                    this.transloco.translate("dtmWebAppOperatorProfile.attorneyPowers.attorneyPowerRemovalSuccessMessage")
                );
            });
    }

    protected downloadAttorneyPowerTemplate() {
        this.store
            .dispatch(new OperatorProfileActions.DownloadAttorneyTemplate())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorProfileState.downloadAttorneyTemplateError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate(
                            "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.cannotDownloadAttorneyTemplate"
                        )
                    );
                }
            });
    }

    protected downloadOperatorDocument(documentType: OperatorConfirmationDocumentType): void {
        switch (documentType) {
            case OperatorConfirmationDocumentType.RegistrationConfirmation:
                this.downloadOperatorRegistrationConfirmation();
                break;
        }
    }

    private downloadOperatorRegistrationConfirmation() {
        const documentTypeKey =
            Object.keys(OperatorConfirmationDocumentType)[
                Object.values(OperatorConfirmationDocumentType).indexOf(OperatorConfirmationDocumentType.RegistrationConfirmation)
            ];

        const fileName = this.transloco.translate("dtmWebAppOperatorProfile.container.downloadOperatorConfirmationDocumentFileName", {
            documentType: documentTypeKey,
        });

        this.store
            .dispatch(
                new OperatorProfileActions.DownloadOperatorRegistrationConfirmation(
                    `${fileName}${OPERATOR_CONFIRMATION_DOCUMENT_EXTENSION}`
                )
            )
            .pipe(
                switchMap(() => this.store.select(OperatorProfileState.downloadOperatorRegistrationConfirmationError)),
                untilDestroyed(this)
            )
            .subscribe((error) => {
                if (!error) {
                    return;
                }

                this.toastService.error(
                    this.transloco.translate("dtmWebAppOperatorProfile.container.cannotDownloadOperatorConfirmationDocument", {
                        documentType: documentTypeKey,
                    })
                );
            });
    }

    private watchForOperatorContextChanges(): void {
        this.store
            .select(OperatorContextState.selectedContext)
            .pipe(RxjsUtils.filterFalsy(), distinctUntilChanged(), untilDestroyed(this))
            .subscribe((selectedContext) => {
                if (selectedContext?.type === OperatorType.Personal) {
                    this.router.navigateByUrl("/pilot-profile");
                } else {
                    this.store.dispatch([
                        new OperatorProfileActions.GetOperatorProfile(selectedContext.id),
                        new OperatorProfileActions.GetOperatorAvatar(selectedContext.id),
                    ]);
                }
            });
    }
}
