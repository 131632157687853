import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MissionPlanFlightPurposeOption } from "../../../../../models/mission.model";

interface FlightPurposeFormControlComponentState {
    flightPurposes: MissionPlanFlightPurposeOption[];
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-flight-purpose-form-control[flightPurposes]",
    templateUrl: "./flight-purpose-form-control.component.html",
    styleUrls: ["../mission-data-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FlightPurposeFormControlComponent),
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FlightPurposeFormControlComponent),
            multi: true,
        },
    ],
})
export class FlightPurposeFormControlComponent implements ControlValueAccessor, Validator {
    @Input()
    public set flightPurposes(value: MissionPlanFlightPurposeOption[] | undefined) {
        this.localStore.patchState({ flightPurposes: value });
    }

    public readonly flightPurposes$ = this.localStore.selectByKey("flightPurposes");

    public readonly flightPurposeFormControl = new FormControl<MissionPlanFlightPurposeOption | null>(null, Validators.required);

    constructor(private readonly localStore: LocalComponentStore<FlightPurposeFormControlComponentState>) {
        this.localStore.setState({ flightPurposes: [] });

        this.flightPurposeFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.flightPurposeFormControl.updateValueAndValidity({ emitEvent: false });
            this.onChange(value);
            this.onValidationChange();
        });
    }

    public onChange: (lightPurpose: MissionPlanFlightPurposeOption | null) => void = FunctionUtils.noop;
    public onTouched: () => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public registerOnChange(onChange: (value: MissionPlanFlightPurposeOption | null) => void) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    public validate(): ValidationErrors | null {
        return this.flightPurposeFormControl.errors;
    }

    public writeValue(flightPurpose: MissionPlanFlightPurposeOption): void {
        this.flightPurposeFormControl.setValue(flightPurpose, { emitEvent: false });
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.flightPurposeFormControl.disable();

            return;
        }

        this.flightPurposeFormControl.enable();
    }
}
