import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import {
    MapActionType,
    MapActionWithPayload,
    MapActionsPanelMode,
    MapEntity,
    MapEntityType,
    MapUtils,
} from "@dtm-frontend/shared/map/cesium";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, Logger } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Feature as GeoJSONFeature } from "@turf/helpers/dist/js/lib/geojson";
import { map } from "rxjs";
import { AreaEditorMapService, AreaGeoJSON, CylinderAreaProperties, PrismAreaProperties } from "./area-editor-map.service";

// NOTE: just a fallback if backend do not send initial viewbox
const FALLBACK_INITIAL_VIEWBOX: GeoJSON = {
    type: "Polygon",
    coordinates: [
        [
            /* eslint-disable no-magic-numbers */
            [14.069638889, 49.0020432310001],
            [14.069638889, 55.849716667],
            [24.150833333, 55.849716667],
            [24.150833333, 49.0020432310001],
            [14.069638889, 49.0020432310001],
            /* eslint-enable no-magic-numbers */
        ],
    ],
};

export interface AreaEditorDialogData {
    area: AreaGeoJSON | null;
    initialViewbox: GeoJSON | undefined;
}

interface AreaEditorDialogComponentState {
    definedArea: AreaGeoJSON | null;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-area-editor-dialog",
    templateUrl: "./area-editor-dialog.component.html",
    styleUrls: ["./area-editor-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, AreaEditorMapService],
})
export class AreaEditorDialogComponent implements OnDestroy, AfterViewInit {
    protected readonly MapActionsPanelMode = MapActionsPanelMode;
    protected readonly MapActionType = MapActionType;

    protected readonly initialViewbox = this.data.initialViewbox ?? FALLBACK_INITIAL_VIEWBOX;
    protected readonly definedArea$ = this.localStore.selectByKey("definedArea");
    protected readonly drawnEntitiesCount$ = this.areaEditorMapService.editorContent$.pipe(map((content) => content?.length ?? 0));
    protected readonly activeMapAction$ = this.areaEditorMapService.activeMapAction$;
    protected readonly activeEntityStatus$ = this.areaEditorMapService.activeEntityStatus$;

    constructor(
        private readonly localStore: LocalComponentStore<AreaEditorDialogComponentState>,
        @Inject(MAT_DIALOG_DATA) protected data: AreaEditorDialogData,
        private readonly areaEditorMapService: AreaEditorMapService
    ) {
        this.localStore.setState({
            definedArea: data.area,
        });

        this.areaEditorMapService.editorContent$
            .pipe(
                map((entities) => entities[0]),
                untilDestroyed(this)
            )
            .subscribe((entity: MapEntity | undefined) => {
                const area = this.convertEntityToGeoJSONFeature(entity) as AreaGeoJSON;
                this.setDefinedArea(area ?? null);

                if (area) {
                    this.areaEditorMapService.zoomToArea();
                }
            });
    }

    public async ngAfterViewInit() {
        // NOTE: some editor updates are not available in this cycle, so we will need to wait for next cycle
        await this.waitForNextCycle();

        this.areaEditorMapService.mapEntitiesReady$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.data.area) {
                this.areaEditorMapService.createEditableArea(this.data.area);
                this.areaEditorMapService.zoomToArea();
            }
        });
    }

    public ngOnDestroy(): void {
        this.areaEditorMapService.clearMap();
    }

    protected setDefinedArea(definedArea: AreaGeoJSON | null) {
        this.localStore.patchState({ definedArea });
    }

    protected processMapActionChange(action: MapActionWithPayload) {
        try {
            this.areaEditorMapService.processMapActionChange(action);
        } catch (error) {
            Logger.captureException(error);
        }
    }

    private convertEntityToGeoJSONFeature(entity: MapEntity | undefined): GeoJSONFeature | undefined {
        if (entity?.type === MapEntityType.Cylinder) {
            return MapUtils.convertCylinderEntityToGeoJSONFeature(entity);
        }
        if (entity?.type === MapEntityType.Prism) {
            const result = MapUtils.convertPrismEntityToGeoJSONFeature(entity);

            if (result.properties && entity.center) {
                const centerPoint = MapUtils.convertCartesian3ToSerializableCartographic(entity.center);
                result.properties.center = [centerPoint.longitude, centerPoint.latitude];
            }

            return result;
        }

        return undefined;
    }

    private async waitForNextCycle(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve);
        });
    }

    protected updateAreaProperties(areaProperties: CylinderAreaProperties | PrismAreaProperties): void {
        this.areaEditorMapService.updateAreaProperties(areaProperties);
    }
}
