<div class="selectable-tile horizontal" (click)="setIsSwarm(false)" [class.selected]="!isSwarm">
    <dtm-ui-icon name="drone"></dtm-ui-icon>
    <div class="selectable-tile-wrapper">
        <h6 class="selectable-tile-label">{{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavSingleLabel" | transloco }}</h6>
    </div>
</div>
<div class="selectable-tile horizontal" (click)="setIsSwarm(true)" [class.selected]="isSwarm">
    <dtm-ui-icon name="drone-swarm"></dtm-ui-icon>
    <div class="selectable-tile-wrapper">
        <h6 class="selectable-tile-label">{{ "dtmWebAppLibUav.newUavWizardSteps.basicInfo.uavSwarmLabel" | transloco }}</h6>
    </div>
</div>
