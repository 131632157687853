<form [formGroup]="setupForm">
    <section>
        <dtm-ui-input-field class="setup-name" [isClearable]="setupForm.controls.name.enabled">
            <label>
                {{ "dtmWebAppLibUav.setupForm.nameLabel" | transloco }}
            </label>
            <input matInput type="text" [formControl]="setupForm.controls.name" required autocomplete="off" />
            <div class="field-error" *dtmUiFieldHasError="setupForm.controls.name; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="setupForm.controls.name; name: 'maxlength'; error as error">
                {{ "dtmWebAppLibUav.uavProperties.maxNameLengthError" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="true">
            <h4 class="section-title" headerSlot>
                {{ "dtmWebAppLibUav.uavProperties.flightTechnicalProperties.sectionHeader" | transloco }}
            </h4>
            <dtm-web-app-lib-flight-technical-properties-control
                class="properties"
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="setupForm.controls.flightTechnicalProperties"
                [isMinFlightSpeedVisible]="isMinFlightSpeedVisible$ | ngrxPush"
            ></dtm-web-app-lib-flight-technical-properties-control>
        </dtm-ui-expandable-panel>
        <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="true">
            <h4 class="section-title" headerSlot>
                {{ "dtmWebAppLibUav.uavProperties.generalTechnicalProperties.sectionHeader" | transloco }}
            </h4>
            <dtm-web-app-lib-general-technical-properties-control
                class="properties"
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="setupForm.controls.generalTechnicalProperties"
            ></dtm-web-app-lib-general-technical-properties-control>
        </dtm-ui-expandable-panel>
        <dtm-web-app-lib-communications-control
            #communicationsControl
            dtmUiMarkValueAccessorControlsAsTouched
            [formControl]="setupForm.controls.communications"
            [factoryCommunicationTypes]="factoryCommunicationTypes$ | ngrxPush"
        ></dtm-web-app-lib-communications-control>
        <dtm-ui-expandable-panel [hasHeaderSeparator]="false" [isExpanded]="true">
            <h2 class="section-title" headerSlot>
                {{ "dtmWebAppLibUav.uavDocuments.setup.sectionHeader" | transloco }}
            </h2>
            <dtm-ui-files-groups-upload
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="setupForm.controls.documents"
                [addButtonLabel]="'dtmWebAppLibUav.uavDocuments.addDocumentButtonLabel' | transloco"
                [uploadFieldLabel]="'dtmWebAppLibUav.uavDocuments.uploadFieldLabel' | transloco"
            ></dtm-ui-files-groups-upload>
        </dtm-ui-expandable-panel>
    </section>
    <section>
        <button [matMenuTriggerFor]="accessoriesMenu" type="button" class="button-secondary add-accessory-button">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibUav.setupForm.accessories.addButtonLabel" | transloco }}
        </button>
        <mat-menu #accessoriesMenu="matMenu" xPosition="before">
            <div class="accessories-menu">
                <button mat-menu-item type="button" (click)="communicationsControl.addCommunication()">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavProperties.communicationProperties.sectionHeader" | transloco }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addTracking()">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavProperties.trackings.sectionHeader" | transloco }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addSimpleEquipment(EquipmentType.Camera)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.Camera } }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addSimpleEquipment(EquipmentType.PropellersGuards)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{
                        "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.PropellersGuards }
                    }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addSimpleEquipment(EquipmentType.NavigationLighting)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{
                        "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.NavigationLighting }
                    }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addSimpleEquipment(EquipmentType.NightLighting)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{
                        "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.NightLighting }
                    }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addSimpleEquipment(EquipmentType.StrobeLighting)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{
                        "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.StrobeLighting }
                    }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addParachute()">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.Parachute } }}</span>
                </button>
                <button mat-menu-item type="button" (click)="accessoriesControl.addSimpleEquipment(EquipmentType.Fts)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.Fts } }}</span>
                </button>
            </div>
        </mat-menu>
        <dtm-web-app-lib-accessories-control
            #accessoriesControl
            dtmUiMarkValueAccessorControlsAsTouched
            [trackers]="trackers$ | ngrxPush"
            [navigationAccuracyItems]="navigationAccuracyItems$ | ngrxPush"
            [parachuteMaxWindSpeedParameters]="uavTechnicalSpecification$ | ngrxPush"
            [formControl]="setupForm.controls.accessories"
        ></dtm-web-app-lib-accessories-control>
    </section>
</form>
