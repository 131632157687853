import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import {
    MissionCategory,
    MissionPlanData,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionProcessingPhase,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { FormUtils, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface MissionBasicDetailsComponentState {
    missionPlan: MissionPlanData | undefined;
    isDetailsMode: boolean;
}

const MAX_NAME_LENGTH = 50;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-basic-details[missionPlan]",
    templateUrl: "./mission-basic-details.component.html",
    styleUrls: ["./mission-basic-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MissionBasicDetailsComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MissionBasicDetailsComponent),
            multi: true,
        },
    ],
})
export class MissionBasicDetailsComponent implements ControlValueAccessor, Validator {
    @Input() public set missionPlan(value: MissionPlanData | undefined) {
        this.localStore.patchState({ missionPlan: value });
    }
    @Input() public set isDetailsMode(value: BooleanInput) {
        this.localStore.patchState({ isDetailsMode: coerceBooleanProperty(value) });
    }

    @Output()
    public readonly mainMissionZoom = new EventEmitter<void>();

    protected readonly missionPlan$ = this.localStore.selectByKey("missionPlan");
    protected readonly isDetailsMode$ = this.localStore.selectByKey("isDetailsMode");

    protected readonly missionNameFormControl = new FormControl<string>("", [
        FormUtils.trimmedRequiredValidator,
        FormUtils.trimmedMaxLengthValidator(MAX_NAME_LENGTH),
    ]);

    protected readonly MissionType = MissionType;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;

    constructor(private readonly localStore: LocalComponentStore<MissionBasicDetailsComponentState>) {
        localStore.setState({
            missionPlan: undefined,
            isDetailsMode: false,
        });

        this.missionNameFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.missionNameFormControl.updateValueAndValidity({ emitEvent: false });
            this.onChange(value);
            this.onValidationChange();
        });
    }

    public onChange: (value: string | null) => void = FunctionUtils.noop;
    public onTouched: () => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public registerOnChange(onChange: (value: string | null) => void) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched;
    }

    public validate(): ValidationErrors | null {
        return this.missionNameFormControl.errors;
    }

    public writeValue(value: string): void {
        this.missionNameFormControl.setValue(value, { emitEvent: false });
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.missionNameFormControl.disable();

            return;
        }

        this.missionNameFormControl.enable();
    }

    protected isSoraApplication(category: MissionPlanOperationCategoryOption | undefined): boolean {
        return category?.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Individual;
    }
}
