<ng-container *ngIf="shouldShowWizardHeader$ | ngrxPush">
    <dtm-ui-lib-wizard-header [wizardId]="wizardId">
        <!-- TODO: DTM-3989 - isManualChangeAllowed=false should be removed when task is done -->
        <dtm-ui-lib-wizard-step-header [stepId]="MissionWizardSteps.MissionData" [isManualChangeAllowed]="false">
            {{ "dtmWebAppLibMission.missionWizardSteps.missionData.header" | transloco }}
        </dtm-ui-lib-wizard-step-header>
        <dtm-ui-lib-wizard-step-header [stepId]="MissionWizardSteps.ItineraryEditor">
            {{ "dtmWebAppLibMission.missionWizardSteps.itineraryEditor.header" | transloco }}
        </dtm-ui-lib-wizard-step-header>
        <dtm-ui-lib-wizard-step-header [stepId]="MissionWizardSteps.RouteSelector" *ngIf="shouldShowRouteSelector$ | ngrxPush">
            {{ "dtmWebAppLibMission.missionWizardSteps.routeSelector.header" | transloco }}
        </dtm-ui-lib-wizard-step-header>
        <dtm-ui-lib-wizard-step-header [stepId]="MissionWizardSteps.Analysis">
            {{ "dtmWebAppLibMission.missionWizardSteps.analysis.header" | transloco }}
        </dtm-ui-lib-wizard-step-header>
        <dtm-ui-lib-wizard-step-header [stepId]="MissionWizardSteps.Summary">
            {{ "dtmWebAppLibMission.missionWizardSteps.summary.header" | transloco }}
        </dtm-ui-lib-wizard-step-header>
    </dtm-ui-lib-wizard-header>
</ng-container>
