import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MissionPlanInformation } from "@dtm-frontend/shared/mission";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable } from "rxjs";
import { audit, debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";

const FORM_UPDATES_DEBOUNCE_TIME = DEFAULT_DEBOUNCE_TIME;

export interface AdditionalInformationSettings extends MissionPlanInformation {
    origin?: PersonalNotesComponent;
    areNotesEnabled?: boolean;
}

export interface PersonalNotesComponentState {
    isProcessing: boolean;
    additionalInformation: AdditionalInformationSettings | undefined;
}

const MAX_NOTES_LENGTH = 200;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-personal-notes",
    templateUrl: "./personal-notes.component.html",
    styleUrls: ["./personal-notes.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PersonalNotesComponent {
    protected readonly areNotesEnabledControl = new FormControl(false, { nonNullable: true });
    protected readonly notesControl = new FormControl<string | null>(null, Validators.maxLength(MAX_NOTES_LENGTH));

    protected readonly notesFormGroup = new FormGroup({
        notes: this.notesControl,
        areNotesEnabled: this.areNotesEnabledControl,
    });

    @Input() public set information(value: AdditionalInformationSettings | undefined) {
        if (value?.origin === this) {
            return;
        }

        this.localStore.patchState({ additionalInformation: value });

        this.notesFormGroup.setValue(
            {
                areNotesEnabled: !!value?.notes,
                notes: value?.notes ?? null,
            },
            { emitEvent: false }
        );
    }
    @Input() public set readonly(value: boolean) {
        if (value) {
            this.notesFormGroup.disable({ emitEvent: false });

            return;
        }
        this.notesFormGroup.enable({ emitEvent: false });
    }
    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }

    @Output() public readonly valueChanges: Observable<AdditionalInformationSettings> = this.notesFormGroup.valueChanges.pipe(
        audit(() => this.isProcessing$.pipe(filter((isProcessing) => !isProcessing))),
        debounceTime(FORM_UPDATES_DEBOUNCE_TIME),
        filter(() => this.notesFormGroup.valid),
        map((value) => {
            const information: AdditionalInformationSettings = {
                notes: value.areNotesEnabled && value.notes ? value.notes : undefined,
                areNotesEnabled: value.areNotesEnabled,
                origin: this,
            };

            return { ...this.localStore.selectSnapshotByKey("additionalInformation"), ...information };
        }),
        distinctUntilChanged(equal),
        untilDestroyed(this)
    );

    protected MAX_NOTES_LENGTH = MAX_NOTES_LENGTH;

    private readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(private readonly localStore: LocalComponentStore<PersonalNotesComponentState>) {
        this.localStore.setState({ isProcessing: false, additionalInformation: undefined });
    }
}
