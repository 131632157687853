<div class="form-entry">
    <dtm-ui-select-field [isClearable]="false" [formControl]="operatorFormControl" (selectionChange)="onChange($event.value)">
        <label>{{ "dtmWebAppLibMission.dataFormStep.operatorLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let operator of validOperators$ | ngrxPush" [value]="operator">
            {{ operator.displayName }}
        </dtm-ui-select-option>
        <ng-container *ngrxLet="operatorsWithoutPilots$; let operatorsWithoutPilots">
            <ng-container *ngIf="operatorsWithoutPilots.length">
                <p class="option-group-title">
                    {{ "dtmWebAppLibMission.dataFormStep.operatorsWithoutPilotsGroupLabel" | transloco }}
                </p>
                <dtm-ui-select-option *ngFor="let operator of operatorsWithoutPilots" [value]="operator" disabled>
                    {{ operator.displayName }}
                </dtm-ui-select-option>
            </ng-container>
        </ng-container>

        <ng-container *ngrxLet="operatorsWithoutUavs$; let operatorsWithoutUavs">
            <ng-container *ngIf="operatorsWithoutUavs.length">
                <p class="option-group-title">
                    {{ "dtmWebAppLibMission.dataFormStep.operatorsWithoutUavsGroupLabel" | transloco }}
                </p>
                <dtm-ui-select-option *ngFor="let operator of operatorsWithoutUavs" [value]="operator" disabled>
                    {{ operator.displayName }}
                </dtm-ui-select-option>
            </ng-container>
        </ng-container>
        <div class="field-error" *dtmUiFieldHasError="operatorFormControl; name: 'required'">
            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
        </div>
    </dtm-ui-select-field>
</div>
