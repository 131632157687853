<ng-container *ngrxLet="{ currentTime: currentTime$ } as vm">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ "dtmWebAppLibTactical.missionActivation.modalTitle" | transloco }}</h2>
        <button type="button" class="button-icon" [matDialogClose]="false">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <mat-dialog-content>
        <p>{{ "dtmWebAppLibTactical.missionActivation.modalContentText" | transloco }}</p>
        <div class="time-frame-section" *ngIf="mission.startTime.min | invoke : getMinStartTime : vm.currentTime as missionStartMin">
            <p class="section-label">{{ "dtmWebAppLibTactical.missionActivation.earliestStartTimeLabel" | transloco }}</p>
            <dtm-ui-time-setter [minTime]="missionStartMin" [maxTime]="maxEndTime" [formControl]="selectedTimeControl"></dtm-ui-time-setter>
            <div class="error-message">
                <ng-container *ngIf="!selectedTimeControl.value || selectedTimeControl.errors?.notInRange">
                    {{ "dtmWebAppLibTactical.missionActivation.notInRangeError" | transloco }}
                    <dtm-ui-time-range-display [startTime]="missionStartMin" [endTime]="maxEndTime"></dtm-ui-time-range-display>
                </ng-container>
            </div>
        </div>
        <div class="max-time-section">
            <p class="section-label">{{ "dtmWebAppLibTactical.missionActivation.latestStartTimeLabel" | transloco }}</p>
            <p>{{ maxEndTime | localizeDate : { timeStyle: "short" } }}</p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false">
            {{ "dtmWebAppLibTactical.missionActivation.actionButtons.cancelLabel" | transloco }}
        </button>
        <button
            type="button"
            class="button-primary"
            cdkFocusInitial
            [mat-dialog-close]="selectedTimeControl.value"
            [disabled]="!selectedTimeControl.value || selectedTimeControl.errors?.notInRange"
        >
            <dtm-ui-icon name="time"></dtm-ui-icon>
            {{ "dtmWebAppLibTactical.missionActivation.actionButtons.setTimeLabel" | transloco }}
        </button>
    </mat-dialog-actions>
</ng-container>
