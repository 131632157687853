<dtm-ui-expandable-panel [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false" (toggleChange)="isExpanded = $event">
    <dtm-ui-expandable-simple-header
        headerSlot
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.headerLabel' | transloco"
    >
    </dtm-ui-expandable-simple-header>
    <form [formGroup]="uavAdditionalInfoForm" *ngrxLet="hasUavSetupBeenUsedPreviously$ as hasUavSetupBeenUsedPreviously">
        <dtm-ui-radio-tile-group [formControl]="uavAdditionalInfoForm.controls.hasUavSetupBeenUsedPreviously">
            <label>
                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.hasUavSetupBeenUsedPreviously.label" | transloco }}
            </label>
            <dtm-ui-radio-tile [value]="true">
                <div class="tile">
                    <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                    <p class="selectable-tile-label">
                        {{
                            "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.hasUavSetupBeenUsedPreviously.yesLabel" | transloco
                        }}
                    </p>
                </div>
            </dtm-ui-radio-tile>
            <dtm-ui-radio-tile [value]="false">
                <div class="tile">
                    <dtm-ui-icon name="close-circle"></dtm-ui-icon>
                    <p class="selectable-tile-label">
                        {{
                            "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.hasUavSetupBeenUsedPreviously.noLabel" | transloco
                        }}
                    </p>
                </div>
            </dtm-ui-radio-tile>
        </dtm-ui-radio-tile-group>
        <div class="operations-manual-chapter">
            <label *ngIf="hasUavSetupBeenUsedPreviously">
                {{
                    "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.operationsManualChapter.existingSubchapterLabel"
                        | transloco
                }}
            </label>
            <label *ngIf="!hasUavSetupBeenUsedPreviously">
                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.operationsManualChapter.newSubchapterLabel" | transloco }}
            </label>
            <span class="prefix">
                {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.operationsManualChapter.prefixLabel" | transloco }}
            </span>
            <dtm-ui-input-field
                [isClearable]="false"
                [hasErrors]="
                    uavAdditionalInfoForm.controls.operationsManualChapter.touched &&
                    uavAdditionalInfoForm.controls.operationsManualChapter.invalid
                "
            >
                <input
                    matInput
                    type="number"
                    inputmode="numeric"
                    [formControl]="uavAdditionalInfoForm.controls.operationsManualChapter"
                    [placeholder]="
                        'dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.operationsManualChapter.placeholder' | transloco
                    "
                />
            </dtm-ui-input-field>
            <dtm-ui-form-errors>
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.operationsManualChapter; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.operationsManualChapter; name: 'pattern'">
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.operationsManualChapter.invalidValueError" | transloco
                    }}
                </div>
            </dtm-ui-form-errors>
        </div>
        <ng-container *ngIf="!hasUavSetupBeenUsedPreviously">
            <dtm-ui-textarea-field [maxLength]="MAX_TECHNICAL_SERVICE_LENGTH">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.technicalServiceLabel" | transloco }}
                    <dtm-ui-popover
                        [popoverText]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.technicalServiceTooltip' | transloco"
                    ></dtm-ui-popover>
                </label>
                <textarea matInput [formControl]="uavAdditionalInfoForm.controls.technicalService"></textarea>
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.technicalService; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="uavAdditionalInfoForm.controls.technicalService; name: 'maxlength'; error as error"
                >
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.textValueMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
            <dtm-ui-textarea-field [maxLength]="MAX_C3_LINK_LENGTH">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.c3LinkLabel" | transloco }}
                    <ng-container *ngIf="isC3LinkOptional$ | ngrxPush">
                        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.optionalLabel" | transloco }}
                    </ng-container>
                    <dtm-ui-popover
                        [popoverText]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.c3LinkTooltip' | transloco"
                    ></dtm-ui-popover>
                </label>
                <textarea matInput [formControl]="uavAdditionalInfoForm.controls.c3Link"></textarea>
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.c3Link; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.c3Link; name: 'maxlength'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.textValueMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
            <dtm-ui-textarea-field [maxLength]="MAX_HMI_LENGTH">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.hmiLabel" | transloco }}
                    <ng-container *ngIf="isHmiOptional$ | ngrxPush">
                        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.optionalLabel" | transloco }}
                    </ng-container>
                    <dtm-ui-popover
                        [popoverText]="'dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.hmiTooltip' | transloco"
                    ></dtm-ui-popover>
                </label>
                <textarea matInput [formControl]="uavAdditionalInfoForm.controls.hmi"></textarea>
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.hmi; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.hmi; name: 'maxlength'; error as error">
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.textValueMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
            <dtm-ui-textarea-field [maxLength]="MAX_ADVERSE_ENVIRONMENTAL_CONDITIONS_LENGTH">
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.adverseEnvironmentalConditionsLabel" | transloco }}
                    <ng-container *ngIf="isAdverseEnvironmentalConditionsOptional$ | ngrxPush">
                        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.optionalLabel" | transloco }}
                    </ng-container>
                    <dtm-ui-popover
                        [popoverText]="
                            'dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.adverseEnvironmentalConditionsTooltip' | transloco
                        "
                    ></dtm-ui-popover>
                </label>
                <textarea matInput [formControl]="uavAdditionalInfoForm.controls.adverseEnvironmentalConditions"></textarea>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="uavAdditionalInfoForm.controls.adverseEnvironmentalConditions; name: 'required'"
                >
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="uavAdditionalInfoForm.controls.adverseEnvironmentalConditions; name: 'maxlength'; error as error"
                >
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.textValueMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.typeCertificateNumberOrDvrReportLabel" | transloco }}
                    <ng-container *ngIf="isDvrOrTcNumberOptional$ | ngrxPush">
                        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.optionalLabel" | transloco }}
                    </ng-container>
                </label>
                <input matInput type="text" [formControl]="uavAdditionalInfoForm.controls.dvrOrTcNumber" autocomplete="off" />
                <div class="field-error" *dtmUiFieldHasError="uavAdditionalInfoForm.controls.dvrOrTcNumber; name: 'required'">
                    {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
                </div>
                <div
                    class="field-error"
                    *dtmUiFieldHasError="uavAdditionalInfoForm.controls.dvrOrTcNumber; name: 'maxlength'; error as error"
                >
                    {{
                        "dtmWebAppLibSpecPermApp.operationInfoStep.uavAdditionalInfo.typeCertificateNumberOrDvrReportMaxLengthValueError"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
        </ng-container>
    </form>
</dtm-ui-expandable-panel>
