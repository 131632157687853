<form [formGroup]="trainingsForm" *ngrxLet="activeLanguage$ as activeLanguage">
    <dtm-ui-select-field
        [multiple]="true"
        [formControl]="trainingsForm.controls.basic"
        [placeholder]="'dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.selectTrainingsPlaceholder' | transloco"
    >
        <label>{{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.selectTrainingsLabel" | transloco }}</label>
        <dtm-ui-select-option
            *ngFor="let selectableTraining of selectableTrainings$ | ngrxPush"
            [value]="selectableTraining"
            [ngSwitch]="selectableTraining.type"
        >
            <ng-container *ngSwitchCase="TrainingOptionType.Training">
                {{ $any(selectableTraining).name }}
            </ng-container>
            <ng-container *ngSwitchCase="TrainingOptionType.Additional">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.additionalTrainingsOptionLabel" | transloco }}
            </ng-container>
        </dtm-ui-select-option>
    </dtm-ui-select-field>
    <section *ngIf="isAdditionalTrainingsOptionSelected$ | ngrxPush">
        <dtm-web-app-lib-spec-perm-app-names-control
            [formControl]="trainingsForm.controls.additional"
            [inputFieldLabel]="'dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.additionalTrainingNameLabel' | transloco"
            [addButtonNameLabel]="'dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.addAdditionalTrainingButtonLabel' | transloco"
        >
        </dtm-web-app-lib-spec-perm-app-names-control>
    </section>
</form>
