import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { filter } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { StatementStatus, StatementsFilters } from "../../services/operator-permissions.models";

interface StatementsFilterComponentState {
    filtersCount: number;
    isPaymentFeatureAvailable: boolean;
}

interface FiltersFormGroup {
    paymentStatus: FormControl<StatementStatus[] | null>;
    signStatus: FormControl<StatementStatus[] | null>;
}

const FILTERS_MAP: {
    key: string;
    filterLabel: string;
    filterValueLabel?: string;
}[] = [
    {
        key: "paymentStatus",
        filterLabel: "dtmWebAppLibOperatorPermissions.statementsList.paymentStatusLabel",
        filterValueLabel: "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus",
    },
    {
        key: "signStatus",
        filterLabel: "dtmWebAppLibOperatorPermissions.statementsList.signatureStatusLabel",
        filterValueLabel: "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus",
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-statements-filter[initialFilters]",
    templateUrl: "./statements-filter.component.html",
    styleUrls: ["./statements-filter.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatementsFilterComponent {
    @Input() public set initialFilters(value: StatementsFilters | undefined) {
        this.signStatusControl.setValue(value?.signStatus ?? []);
        this.paymentStatusControl.setValue(value?.paymentStatus ?? []);
    }

    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }

    @Output() public filtersChange = new EventEmitter<StatementsFilters>();
    @Output() public areFiltersAppliedChange = new EventEmitter<boolean>();

    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly StatementStatus = StatementStatus;
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");
    protected readonly paymentStatusControl = new FormControl<StatementStatus[] | null>(null);
    protected readonly signStatusControl = new FormControl<StatementStatus[] | null>(null);
    protected readonly filtersFormGroup = new FormGroup<FiltersFormGroup>({
        paymentStatus: this.paymentStatusControl,
        signStatus: this.signStatusControl,
    });
    constructor(private readonly localStore: LocalComponentStore<StatementsFilterComponentState>) {
        localStore.setState({ filtersCount: 0, isPaymentFeatureAvailable: false });

        this.isPaymentFeatureAvailable$
            .pipe(
                filter((isPaymentFeatureAvailable) => !isPaymentFeatureAvailable),
                untilDestroyed(this)
            )
            .subscribe(() => this.paymentStatusControl.disable());

        this.watchFormChanges();
    }

    protected clearFilters() {
        this.filtersFormGroup.reset();
    }

    private watchFormChanges() {
        this.filtersFormGroup.valueChanges
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => {
                this.updateFiltersCount();
                this.filtersChange.emit(value);
            });
    }

    private updateFiltersCount() {
        if (this.filtersFormGroup.valid) {
            const filtersCount = Object.values(this.filtersFormGroup.value).flat().filter(Boolean).length;

            this.localStore.patchState({ filtersCount });
            this.areFiltersAppliedChange.emit(!!filtersCount);
        }
    }
}
