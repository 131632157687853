<dtm-ui-loader-container *ngrxLet="qualificationsType$ as qualificationsType" [isShown]="isPilotProfileProcessing$ | ngrxPush">
    <div class="qualifications-sidenav" [@foldFarRight]="qualificationsType ? 'open' : 'closed'">
        <dtm-web-app-lib-pilot-operator-qualifications
            class="scroll-shadows"
            [title]="
                qualificationsType === PilotOperatorQualificationsType.Pilot
                    ? ('dtmWebAppLibPilotProfile.pilotQualifications.pilotCompetenciesHeader' | transloco)
                    : ('dtmWebAppLibShared.operatorQualifications.operatorQualificationsHeader' | transloco)
            "
            [qualifications]="
                qualificationsType === PilotOperatorQualificationsType.Pilot ? PILOT_COMPETENCIES : OPERATIONAL_AUTHORIZATIONS
            "
            (drawerClose)="setPilotOperatorQualificationsType()"
        ></dtm-web-app-lib-pilot-operator-qualifications>
    </div>

    <ng-container *ngrxLet="pilotProfile$ as pilotProfile">
        <ng-container *ngrxLet="pilotProfileError$ as pilotProfileError">
            <dtm-ui-error
                (reload)="reloadPilotProfile()"
                *ngIf="pilotProfileError; else pilotProfileTemplate"
                [errorMessage]="'dtmWebAppLibPilotProfile.container.pilotProfileErrorMessage' | transloco"
            ></dtm-ui-error>
        </ng-container>
        <ng-template #pilotProfileTemplate>
            <div class="grid">
                <div class="grid-col-6 column">
                    <dtm-web-app-lib-pilot-profile-operator-pilot-number
                        [pilot]="pilotProfile"
                        (documentDownload)="downloadDocument($event)"
                    ></dtm-web-app-lib-pilot-profile-operator-pilot-number>
                    <dtm-web-app-lib-pilot-profile-pansa-utm-link
                        *ngIf="isMissionPlanningFeatureAvailable$ | ngrxPush"
                        [account]="pilotProfile.pansaUtmLink"
                        (add)="addPansaUtmLink()"
                        (edit)="addPansaUtmLink(pilotProfile.pansaUtmLink)"
                        (delete)="deletePansaUtmLink()"
                    ></dtm-web-app-lib-pilot-profile-pansa-utm-link>
                    <dtm-web-app-lib-address-details
                        [address]="pilotProfile.operator.address"
                        (save)="editAddress($event)"
                        [label]="'dtmWebAppLibPilotProfile.container.addressLabel' | transloco"
                    ></dtm-web-app-lib-address-details>
                </div>
                <div class="grid-col-6 column">
                    <dtm-web-app-lib-pilot-profile-pilot-qualifications
                        [qualifications]="pilotProfile.competencies"
                        (getQualificationsRedirect)="redirectToGetQualifications()"
                        (pilotQualificationsShow)="setPilotOperatorQualificationsType(PilotOperatorQualificationsType.Pilot)"
                    ></dtm-web-app-lib-pilot-profile-pilot-qualifications>
                    <dtm-web-app-lib-operator-qualifications
                        [qualifications]="pilotProfile.operationalAuthorizations"
                        canManageOperator
                        (operatorQualificationsShow)="setPilotOperatorQualificationsType(PilotOperatorQualificationsType.Operator)"
                    ></dtm-web-app-lib-operator-qualifications>
                    <dtm-web-app-lib-pilot-operator-other-information-tile
                        *ngIf="pilotProfile.otherInformation?.length"
                        [otherInformationList]="pilotProfile.otherInformation"
                    ></dtm-web-app-lib-pilot-operator-other-information-tile>
                    <dtm-web-app-lib-insurance-policy-edit
                        [expirationDate]="pilotProfile.operator.insurancePolicyExpirationDate"
                        [insuranceNumber]="pilotProfile.operator.insurancePolicyNumber"
                        (save)="editOperatorInsurancePolicy($event)"
                        (delete)="deleteInsurancePolicy()"
                    ></dtm-web-app-lib-insurance-policy-edit>
                    <dtm-web-app-lib-foreign-competencies
                        *ngIf="pilotProfile.operator.isRegisteredInOtherCountry"
                        (foreignCompetencySave)="saveForeignCompetency($event)"
                        (delete)="deleteForeignCompetency($event)"
                        [competencies]="pilotProfile.competencyConfirmations"
                    >
                    </dtm-web-app-lib-foreign-competencies>
                </div>
            </div>
        </ng-template>
    </ng-container>
</dtm-ui-loader-container>
