import { DecimalPipe } from "@angular/common";
import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ConvertToKilometersPipe, DurationPipe } from "@dtm-frontend/shared/ui";
import { LocalizeNumberPipe } from "@dtm-frontend/shared/ui/i18n";
import { ISO8601TimeDuration } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";

@Directive({
    selector: "[dtmWebAppLibMwiesipDistanceAndTimeDisplay]",
    providers: [ConvertToKilometersPipe, LocalizeNumberPipe, DurationPipe, DecimalPipe],
})
export class DistanceAndTimeDisplayDirective implements OnChanges {
    @Input() public distance: number | undefined;
    @Input() public time: ISO8601TimeDuration | undefined;

    constructor(
        private readonly element: ElementRef,
        private readonly translocoService: TranslocoService,
        private readonly convertToKilometersPipe: ConvertToKilometersPipe,
        private readonly localizeNumberPipe: LocalizeNumberPipe,
        private readonly durationPipe: DurationPipe,
        private readonly numberPipe: DecimalPipe
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    public ngOnChanges(changes: SimpleChanges): void {
        this.updateText();
    }

    private updateText(): void {
        let distanceText: string | null = null;
        let timeText: string | null = null;

        if (!this.distance && !this.time) {
            (this.element.nativeElement as HTMLElement).textContent = "";

            return;
        }

        if (this.distance) {
            distanceText =
                this.distance < 100
                    ? "min"
                    : this.localizeNumberPipe.transform(
                          this.numberPipe.transform(this.convertToKilometersPipe.transform(this.distance), "1.1-1")
                      );
            distanceText = this.translocoService.translate("dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.distanceOnlyLabel", {
                distance: distanceText,
            });
        }

        if (this.time) {
            timeText = this.durationPipe.transform(this.time, "short") ?? null;
        }

        const distanceAndTimeText = this.translocoService.translate(
            "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.distanceAndTimeLabel",
            { distanceOnlyLabel: distanceText, time: timeText }
        );

        (this.element.nativeElement as HTMLElement).textContent = this.distance
            ? this.time
                ? distanceAndTimeText
                : distanceText
            : timeText;
    }
}
