import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";

interface WelcomeViewComponentState {
    isPansaUtmLinkInfoVisible: boolean;
}

@Component({
    selector: "dtm-web-app-lib-welcome-view",
    templateUrl: "./welcome-view.component.html",
    styleUrls: ["./welcome-view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class WelcomeViewComponent {
    @Input() public set isPansaUtmLinkInfoVisible(value: BooleanInput) {
        this.localStore.patchState({ isPansaUtmLinkInfoVisible: coerceBooleanProperty(value) });
    }

    protected readonly isPansaUtmLinkInfoVisible$ = this.localStore.selectByKey("isPansaUtmLinkInfoVisible");

    constructor(
        private readonly localStore: LocalComponentStore<WelcomeViewComponentState>,
        private readonly router: Router,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService
    ) {
        localStore.setState({ isPansaUtmLinkInfoVisible: false });
    }

    protected tryAddingPilot() {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate(
                        "dtmWebAppLibDashboard.welcomeView.confirmPilotRegistrationDialog.titleText"
                    ),
                    confirmationHtml: this.translocoService.translate(
                        "dtmWebAppLibDashboard.welcomeView.confirmPilotRegistrationDialog.contentHtmlText"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "dtmWebAppLibDashboard.welcomeView.confirmPilotRegistrationDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "dtmWebAppLibDashboard.welcomeView.confirmPilotRegistrationDialog.confirmButtonLabel"
                    ),
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result === true) {
                    this.router.navigate(["/operator-migration"]);
                } else if (result === false) {
                    this.router.navigate(["/register/pilot"]);
                }
            });
    }
}
