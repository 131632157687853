import { Injectable } from "@angular/core";

import { AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";
import { Observable, switchMap } from "rxjs";
import { first } from "rxjs/operators";
import { UserProfileActions } from "../state/user-profile.actions";

@Injectable()
export class NationalNodeUserResolver {
    constructor(private readonly store: Store) {}

    public resolve(): Observable<void> {
        return this.store.select(AuthState.userId).pipe(
            first(),
            switchMap((userId) => this.store.dispatch(new UserProfileActions.GetNationalNodeUserRegistration(userId)))
        );
    }
}
