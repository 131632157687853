import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Observable, Subject } from "rxjs";

const MAX_REASON_LENGTH = 255;

@Component({
    selector: "dtm-web-app-lib-resign-dialog",
    templateUrl: "./resign-dialog.component.html",
    styleUrls: ["./resign-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResignDialogComponent {
    protected readonly reasonControl = new UntypedFormControl("", Validators.maxLength(MAX_REASON_LENGTH));
    private readonly setNoteSubject = new Subject<string>();
    public readonly setNote$ = this.setNoteSubject.asObservable();

    protected readonly MAX_REASON_LENGTH = MAX_REASON_LENGTH;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            isResignProcessing$: Observable<boolean>;
            deactivateMemberNoteLabel: string;
            deactivateMemberInformationText: string;
        }
    ) {}

    protected confirm() {
        if (this.reasonControl.invalid) {
            return;
        }

        this.setNoteSubject.next(this.reasonControl.value);
    }
}
