<dtm-ui-filters-container>
    <form [formGroup]="filtersForm">
        <div class="filters-container">
            <dtm-ui-input-field>
                <input
                    matInput
                    type="text"
                    formControlName="name"
                    [placeholder]="'dtmWebAppMembership.missionsListFilters.searchByTextPlaceholder' | transloco"
                />
            </dtm-ui-input-field>
        </div>
        <div class="filters-container" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
            <dtm-ui-select-field multiple formControlName="status" class="control-status">
                <label>{{ "dtmWebAppMembership.filters.statusLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let status of (capabilities$ | ngrxPush)?.missionPlanPhases" [value]="status">
                    {{ "dtmWebAppMembership.missionsListFilters.statusLabel" | transloco : { value: status } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <dtm-ui-date-field>
                <label>{{ "dtmWebAppMembership.missionsListFilters.dateFromLabel" | transloco }}</label>
                <input matInput formControlName="dateFrom" [matDatepicker]="pickerFrom" [placeholder]="datePickerPlaceholder" />
                <mat-datepicker #pickerFrom></mat-datepicker>
            </dtm-ui-date-field>

            <dtm-ui-date-field>
                <label>{{ "dtmWebAppMembership.missionsListFilters.dateToLabel" | transloco }}</label>
                <input matInput formControlName="dateTo" [matDatepicker]="pickerTo" [placeholder]="datePickerPlaceholder" />
                <mat-datepicker #pickerTo></mat-datepicker>
            </dtm-ui-date-field>
        </div>
    </form>
</dtm-ui-filters-container>

<dtm-ui-filter-chips-display
    [filtersCount]="filtersCount$ | ngrxPush"
    [filtersMap]="FILTERS_MAP"
    [formGroup]="filtersForm"
    [filtersValue]="filtersForm.value"
    (allFiltersReset)="clearFilters()"
>
</dtm-ui-filter-chips-display>
