import { FilesGroup } from "@dtm-frontend/shared/ui";
import {
    Capabilities,
    Chapter,
    ChapterAttachmentType,
    ChapterType,
    OperationsManualVersion,
    TableOfContents,
    TableOfContentsChapter,
} from "./operations-manual.models";

export interface OperationsManualInfoResponseBody {
    id: string;
    version: number;
    published: boolean;
    lastUpdate: string;
}

export interface CapabilitiesResponseBody {
    allowedToCreateOperationsManual: boolean;
    allowedToEditOperationsManual: boolean;
    allowedToPublishOperationsManual: boolean;
    availableContentsTableFilters: string[];
    operationsManualInfos: OperationsManualInfoResponseBody[];
}

export interface TableOfContentsResponseBody {
    chapters: TableOfContentsChapter[];
}

export interface ChapterResponseBody {
    id: string;
    name: string;
    guide: string;
    attacheFileEnable: boolean;
    allowedFilesToAttache: Array<{
        type: ChapterAttachmentType;
        allowedFormats: string[];
        maxFiles: number;
    }>;
    content: {
        type: ChapterType;
        editable: boolean;
        value: string;
    };
    attachments: Array<{
        id: string;
        type: ChapterAttachmentType;
        name: string;
        size: number;
    }>;
    subchapters: ChapterResponseBody[];
}

export interface SaveChapterResponseBody {
    lastUpdate: string;
}

export interface AttachmentsRequestPayload {
    attachments: Partial<Record<ChapterAttachmentType, string[]>>;
}

export interface SetAttachmentsResponseBody {
    lastUpdate: string;
}

export interface AddSubchapterResponseBody {
    lastUpdate: string;
    newContentsTable: {
        chapters: TableOfContentsChapter[];
    };
    newChapter: ChapterResponseBody;
}

export interface RemoveSubchapterResponseBody {
    lastUpdate: string;
    newContentsTable: {
        chapters: TableOfContentsChapter[];
    };
}

export function convertCreateOperationsManualResponseBodyToOperationsManualVersion(
    response: OperationsManualInfoResponseBody
): OperationsManualVersion {
    return {
        id: response.id,
        version: response.version,
        isPublished: response.published,
        lastUpdate: new Date(response.lastUpdate),
    };
}

export function convertCapabilitiesResponseBodyToCapabilities(response: CapabilitiesResponseBody): Capabilities {
    return {
        permissions: {
            canCreate: response.allowedToCreateOperationsManual,
            canEdit: response.allowedToEditOperationsManual,
            canPublish: response.allowedToPublishOperationsManual,
        },
        availableContentsTableFilters: response.availableContentsTableFilters,
        operationsManuals: response.operationsManualInfos
            .map(convertCreateOperationsManualResponseBodyToOperationsManualVersion)
            .sort((left, right) => right.version - left.version),
    };
}

export function convertTableOfContentsResponseBodyToTableOfContents(
    response: TableOfContentsResponseBody,
    operationsManual: OperationsManualVersion
): TableOfContents {
    return {
        lastUpdateDate: operationsManual.lastUpdate,
        chapters: response.chapters,
    };
}

export function convertChapterResponseBodyToChapter(response: ChapterResponseBody): Chapter {
    const chapter: Chapter = {
        id: response.id,
        name: response.name,
        guide: response.guide,
        type: response.content.type,
        content: undefined,
        subchapters: [],
        isEditable: response.content.editable,
        attachmentsFeature: {
            isEnabled: response.attacheFileEnable,
            config: response.allowedFilesToAttache.map((item) => ({
                type: item.type,
                formats: item.allowedFormats,
                maxAmount: item.maxFiles,
            })),
        },
        attachments: response.attachments.map((attachment) => ({
            id: attachment.id,
            name: attachment.name,
            type: attachment.type,
            size: attachment.size,
        })),
    };

    if (response.content.type === ChapterType.Html) {
        chapter.content = response.content.value;
    } else if (response.content.type === ChapterType.SubchaptersContent) {
        chapter.subchapters = response.subchapters.map((subchapter) => convertChapterResponseBodyToChapter(subchapter));
    } else if (response.content.type === ChapterType.ExtendableSubchaptersList) {
        chapter.subchapters = response.subchapters.map((subchapter) => convertChapterResponseBodyToChapter(subchapter));
    }

    return chapter;
}

export function convertSaveChapterResponseBodyToDate(response: SaveChapterResponseBody): Date {
    return new Date(response.lastUpdate);
}

export function convertChapterAttachmentsToAttachmentsRequestPayload(
    attachments: FilesGroup<ChapterAttachmentType>[]
): AttachmentsRequestPayload {
    return {
        attachments: attachments.reduce<Partial<Record<ChapterAttachmentType, string[]>>>(
            (result, attachmentsGroup) => ({ ...result, [attachmentsGroup.groupId]: attachmentsGroup.files.map((file) => file.id) }),
            {}
        ),
    };
}

export function convertSetChapterAttachmentsResponseBodyToDate(response: SetAttachmentsResponseBody): Date {
    return new Date(response.lastUpdate);
}

export function convertAddSubchapterResponseBodyToTableOfContents(response: AddSubchapterResponseBody): TableOfContents {
    return {
        lastUpdateDate: new Date(response.lastUpdate),
        chapters: response.newContentsTable.chapters,
    };
}

export function convertRemoveSubchapterResponseBodyToTableOfContents(response: RemoveSubchapterResponseBody): TableOfContents {
    return {
        lastUpdateDate: new Date(response.lastUpdate),
        chapters: response.newContentsTable.chapters,
    };
}
