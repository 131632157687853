<div
    class="container"
    dtmUiInvalidFormScrollable
    [formGroup]="tacticalRiskMitigationMeasuresFormGroup"
    *ngrxLet="tacticalMitigationPerformanceRequirementProperty$ as tacticalMitigationPerformanceRequirementProperty"
>
    <mat-dialog-content>
        <h2 class="header" mat-dialog-title>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.header" | transloco }}
        </h2>

        <ng-container *ngFor="let categoryKey of categoryKeys">
            <dtm-ui-expandable-panel
                *ngIf="tacticalRiskMitigationMeasuresFormGroup.controls[categoryKey].controls as formGroup"
                [isExpanded]="formGroup.isExpanded.value"
                (toggleChange)="formGroup.isExpanded.setValue($event)"
                [hasHeaderSeparator]="false"
                [formGroupName]="categoryKey"
            >
                <div class="section-title" headerSlot>
                    {{
                        "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.tacticalRiskMitigationMeasuresStatements"
                            | transloco : { value: categoryKey }
                    }}
                    <dtm-ui-popover
                        *ngIf="
                            categoryKey === TacticalRiskMitigationMeasuresCategory.DETECT &&
                            tacticalMitigationPerformanceRequirementProperty !== TacticalMitigationPerformanceRequirement.D
                        "
                        (click)="$event.stopPropagation()"
                        [popoverText]="
                            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.headerTooltip'
                                | transloco
                        "
                    ></dtm-ui-popover>
                </div>

                <div class="content">
                    <div class="text-description" [class.hidden]="!formGroup.isLongDescriptionVisible.value">
                        <ng-container [ngSwitch]="tacticalMitigationPerformanceRequirementProperty">
                            <ng-container
                                *ngSwitchCase="TacticalMitigationPerformanceRequirement.B"
                                [ngTemplateOutlet]="arcBTemplate"
                                [ngTemplateOutletContext]="{ sectionKey: categoryKey }"
                            ></ng-container>
                            <ng-container
                                *ngSwitchCase="TacticalMitigationPerformanceRequirement.C"
                                [ngTemplateOutlet]="arcCTemplate"
                                [ngTemplateOutletContext]="{ sectionKey: categoryKey }"
                            ></ng-container>
                            <ng-container
                                *ngSwitchCase="TacticalMitigationPerformanceRequirement.D"
                                [ngTemplateOutlet]="arcDTemplate"
                                [ngTemplateOutletContext]="{ sectionKey: categoryKey }"
                            ></ng-container>
                        </ng-container>
                    </div>

                    <div class="expand">
                        <button
                            class="button-tertiary"
                            type="button"
                            (click)="formGroup.isLongDescriptionVisible.setValue(!formGroup.isLongDescriptionVisible.value)"
                        >
                            <span>{{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.expandLabel"
                                    | transloco : { value: formGroup.isLongDescriptionVisible.value }
                            }}</span>
                            <dtm-ui-icon [name]="formGroup.isLongDescriptionVisible.value ? 'arrow-up' : 'arrow-down'"></dtm-ui-icon>
                        </button>
                    </div>

                    <dtm-ui-textarea-field [maxLength]="MAX_TEXT_LENGTH">
                        <label
                            >{{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.requirementsMetDescriptionLabel"
                                    | transloco
                            }}
                            <span class="required">*</span>
                        </label>
                        <textarea required [formControl]="formGroup.riskMitigationTextarea" matInput></textarea>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="formGroup.riskMitigationTextarea; name: ['pattern', 'required']"
                            [ngTemplateOutlet]="requiredErrorTemplate"
                        ></div>
                        <div class="field-error" *dtmUiFieldHasError="formGroup.riskMitigationTextarea; name: 'maxlength'; error as error">
                            {{
                                "dtmAdminLibPermits.additionalAccidentType.maxLengthErrorLabel"
                                    | transloco : { maxLength: error.requiredLength }
                            }}
                        </div>
                    </dtm-ui-textarea-field>
                </div>
            </dtm-ui-expandable-panel>
        </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button class="button-secondary" type="button" (click)="close()">
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.cancelButtonLabel" | transloco }}
        </button>
        <button class="button-primary" type="button" (click)="saveAndRefresh()">
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.saveButtonLabel" | transloco }}
        </button>
    </mat-dialog-actions>
</div>

<ng-template #requiredErrorTemplate>
    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.requiredFieldErrorHint" | transloco }}
</ng-template>

<ng-template #arcBTemplate let-sectionKey="sectionKey">
    <ng-container [ngSwitch]="sectionKey">
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.DETECT" [ngTemplateOutlet]="arcBDetection"></ng-container>
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.DECIDE" [ngTemplateOutlet]="arcBDecisionMaking"></ng-container>
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.COMMAND">
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.commandIssuingFirstText"
                    | transloco
            }}
        </ng-container>
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.EXECUTE">
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.executionFirstText"
                    | transloco
            }}
        </ng-container>
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP">
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.effectsControlFirstText"
                    | transloco
            }}
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #arcCTemplate let-sectionKey="sectionKey">
    <div [ngSwitch]="sectionKey">
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.DETECT" [ngTemplateOutlet]="arcCDetection"></ng-container>
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.DECIDE" [ngTemplateOutlet]="arcCDecisionMaking"></ng-container>
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.COMMAND">
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.commandIssuingFirstText"
                    | transloco
            }}
        </ng-container>
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.EXECUTE" [ngTemplateOutlet]="arcCExecution"></ng-container>
        <ng-container
            *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.FEEDBACK_LOOP"
            [ngTemplateOutlet]="arcCEffectsControl"
        ></ng-container>
    </div>
</ng-template>

<ng-template #arcDTemplate let-sectionKey="sectionKey">
    <ng-container [ngSwitch]="sectionKey">
        <ng-container *ngSwitchCase="TacticalRiskMitigationMeasuresCategory.DETECT">
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDFirstText"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDFirstTextTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDSecondText"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDSecondTextTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDThirdText"
                    | transloco
            }}
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDFirstText"
                    | transloco
            }}
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDSecondText"
                    | transloco
            }}
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcD.arcDThirdText"
                    | transloco
            }}
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #arcBDetection>
    {{
        "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionFirstText"
            | transloco
    }}
    <dtm-ui-popover
        (click)="$event.stopPropagation()"
        [popoverText]="
            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionFirstPopoverText'
                | transloco
        "
    ></dtm-ui-popover>
    <div>
        {{
            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionSecondText"
                | transloco
        }}
    </div>
    <ul>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionFirstPoint"
                    | transloco
            }}
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionSecondPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionSecondPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionThirdPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionThirdPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionFourthPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.detectionFourthPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
    </ul>
</ng-template>
<ng-template #arcBDecisionMaking>
    {{
        "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.decisionMakingFirstText"
            | transloco
    }}

    <ul>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.decisionMakingFirstPoint"
                    | transloco
            }}
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcB.decisionMakingSecondPoint"
                    | transloco
            }}
        </li>
    </ul>
</ng-template>

<ng-template #arcCDetection>
    {{
        "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionFirstText"
            | transloco
    }}
    <dtm-ui-popover
        (click)="$event.stopPropagation()"
        [popoverText]="
            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionFirstPopoverText'
                | transloco
        "
    ></dtm-ui-popover>
    <div>
        {{
            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionSecondText"
                | transloco
        }}
    </div>
    <ul>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionFirstPoint"
                    | transloco
            }}
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionSecondPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionSecondPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionThirdPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionThirdPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionFourthPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionFourthPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionFifthPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionFifthPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionSixthPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionSixthPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionSeventhPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionSeventhPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionEightPoint"
                    | transloco
            }}
            <dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionEightPointTooltip'
                        | transloco
                "
            ></dtm-ui-popover>
        </li>
    </ul>
    <div class="section">
        {{
            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.detectionThirdText"
                | transloco
        }}
    </div>
</ng-template>
<ng-template #arcCDecisionMaking>
    {{
        "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.decisionMakingFirstText"
            | transloco
    }}
    <ul>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.decisionMakingFirstPointText"
                    | transloco
            }}
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.decisionMakingSecondPointText"
                    | transloco
            }}
        </li>
    </ul>

    <div class="section">
        {{
            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.andLabel"
                | transloco
        }}
    </div>

    <ol>
        <li>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.decisionMakingFirstNumberText"
                    | transloco
            }}
        </li>
        <li>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.decisionMakingSecondNumberText"
                    | transloco
            }}
        </li>
    </ol>
</ng-template>
<ng-template #arcCExecution>
    {{
        "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.executionFirstText"
            | transloco
    }}
    <dtm-ui-popover
        (click)="$event.stopPropagation()"
        [popoverText]="
            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.executionFirstTextTooltip'
                | transloco
        "
    ></dtm-ui-popover>
    <ul>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.executionFirstPointText"
                    | transloco
            }}
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.executionSecondPointText"
                    | transloco
            }}
        </li>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.executionThirdPointText"
                    | transloco
            }}
        </li>
    </ul>
</ng-template>
<ng-template #arcCEffectsControl>
    {{
        "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.effectsControlFirstText"
            | transloco
    }}
    <div class="section">
        {{
            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.effectsControlSecondText"
                | transloco
        }}
    </div>
    <ul>
        <li>
            <dtm-ui-icon name="check"></dtm-ui-icon>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.tacticalRiskMitigationMeasures.modal.descriptions.arcC.effectsControlFirstPointText"
                    | transloco
            }}
        </li>
    </ul>
</ng-template>
