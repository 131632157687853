import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement, GeoZoneType, GeoZonesUtils } from "@dtm-frontend/shared/map/geo-zones";
import {
    EvaluationIssueStatus,
    MissionPlanAirspaceAnalysisOption,
    MissionPlanAnalysisEvaluationIssue,
    MissionPlanAnalysisIssue,
    MissionPlanEvaluationOption,
    PartitionedZones,
} from "@dtm-frontend/shared/mission";
import { ArrayUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

interface ZoneAnalysisComponentState {
    activeZones: AirspaceElement[];
    waitingZones: AirspaceElement[];
    additionalZones: AirspaceElement[];
    selectedZoneId: string | undefined;
    airspaceAnalysis: MissionPlanAirspaceAnalysisOption | undefined;
    evaluationIssues: MissionPlanEvaluationOption | undefined;
    issues: Record<string, MissionPlanAirspaceAnalysisOption> | undefined;
    doesAupExist: boolean;
    airacEndTime: Date | undefined;
}

const ISSUE_CODENAME_IGNORE_LIST = ["analysis.airspace.element.issue.fa.message"];
enum ZoneAnalysisStatus {
    Success = "SUCCESS",
    Information = "INFO",
    Warning = "WARNING",
    Processing = "PROCESSING",
    Error = "ERROR",
}

@Component({
    selector: "dtm-web-app-lib-zone-analysis",
    templateUrl: "./zone-analysis.component.html",
    styleUrls: ["./zone-analysis.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneAnalysisComponent {
    @Input() public set zones(value: PartitionedZones | undefined) {
        const [activeZones, waitingZones] = ArrayUtils.partition(
            GeoZonesUtils.sortZones(value?.collisionZones ?? []),
            (zone) => zone.isActive
        );

        this.localStore.patchState({
            activeZones,
            waitingZones,
            additionalZones: value?.additionalZones ?? [],
            doesAupExist: !!value?.doesAupExist,
            airacEndTime: value?.airacEndTime,
        });
    }

    @Input() public set airspaceAnalysis(value: MissionPlanAirspaceAnalysisOption | undefined) {
        this.localStore.patchState({ airspaceAnalysis: value });
    }
    @Input() public set evaluationIssues(value: MissionPlanEvaluationOption | undefined) {
        this.localStore.patchState({ evaluationIssues: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly activeZones$ = this.localStore.selectByKey("activeZones");
    protected readonly waitingZones$ = this.localStore.selectByKey("waitingZones");
    protected readonly additionalZones$ = this.localStore.selectByKey("additionalZones");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly airspaceAnalysis$ = this.localStore.selectByKey("airspaceAnalysis");
    protected readonly evaluationIssues$ = this.localStore.selectByKey("evaluationIssues");
    protected readonly doesAupExist$ = this.localStore.selectByKey("doesAupExist");
    protected readonly isProcessing$ = combineLatest([
        this.airspaceAnalysis$,
        this.activeZones$,
        this.waitingZones$,
        this.additionalZones$,
    ]).pipe(
        map(
            ([airspaceElements, activeZones, waitingZones, additionalZones]) =>
                !!(airspaceElements?.zoneIssues && Object.keys(airspaceElements.zoneIssues).length) &&
                !(activeZones.length || waitingZones.length || additionalZones.length)
        )
    );
    protected readonly airacEndTime$ = this.localStore.selectByKey("airacEndTime");

    protected readonly ZoneAnalysisStatus = ZoneAnalysisStatus;

    constructor(private readonly localStore: LocalComponentStore<ZoneAnalysisComponentState>) {
        localStore.setState({
            activeZones: [],
            waitingZones: [],
            additionalZones: [],
            selectedZoneId: undefined,
            airspaceAnalysis: undefined,
            evaluationIssues: undefined,
            issues: undefined,
            doesAupExist: false,
            airacEndTime: undefined,
        });
    }

    protected getAirspaceWarningForZone(
        zone: AirspaceElement,
        airspaceAnalysis?: MissionPlanAirspaceAnalysisOption
    ): MissionPlanAnalysisIssue[] {
        return zone.designator && airspaceAnalysis?.zoneIssues
            ? airspaceAnalysis.zoneIssues[zone.designator]?.filter(({ codename }) => !ISSUE_CODENAME_IGNORE_LIST.includes(codename)) ?? []
            : [];
    }

    protected getEvaluationIssuesForZone(
        zone: AirspaceElement,
        evaluationIssues?: MissionPlanEvaluationOption
    ): MissionPlanAnalysisEvaluationIssue[] {
        return zone.designator && evaluationIssues?.issues ? evaluationIssues.zoneIssues[zone.designator] ?? [] : [];
    }

    protected getZonesAnalysisStatus(
        zoneGroups: AirspaceElement[][],
        isProcessing: boolean,
        zoneIssues?: MissionPlanEvaluationOption
    ): ZoneAnalysisStatus {
        if (isProcessing) {
            return ZoneAnalysisStatus.Processing;
        }

        if (zoneIssues?.issues.some((issue) => issue.status === EvaluationIssueStatus.Rejected)) {
            return ZoneAnalysisStatus.Error;
        }

        const zones = zoneGroups.flat();
        if (!zones.length) {
            return ZoneAnalysisStatus.Success;
        }

        const hasWarnings = zones.some(
            (zone) => zone.geoZoneType !== GeoZoneType.Local && zone.geoZoneType !== GeoZoneType.DroneAirspaceInformation
        );

        if (hasWarnings) {
            return ZoneAnalysisStatus.Warning;
        }

        return ZoneAnalysisStatus.Information;
    }
}
