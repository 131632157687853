<div class="row">
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceNumberLabel" | transloco }}
        </label>
        <input matInput [formControl]="insuranceNumberControl" />
        <div class="field-error" *dtmUiFieldHasError="insuranceNumberControl; name: ['required', 'pattern']">
            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="insuranceNumberControl; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceNumberLengthError"
                    | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>

    <dtm-ui-date-field>
        <label>
            {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceExpirationDateLabel" | transloco }}
        </label>
        <input
            matInput
            [formControl]="insuranceExpirationDateControl"
            [matDatepicker]="fromPicker"
            [min]="todayDate"
            *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
            [placeholder]="datePickerPlaceholder"
        />
        <mat-datepicker #fromPicker></mat-datepicker>
        <div class="field-error" *dtmUiFieldHasError="insuranceExpirationDateControl; name: 'required'">
            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *ngIf="insuranceExpirationDateControl.errors?.matDatepickerMin">
            {{
                "dtmWebAppLibPilotOperatorRegistration.additionalData.minimalInsuranceExpirationDateError"
                    | transloco : { minDate: todayDate | localizeDate }
            }}
        </div>
    </dtm-ui-date-field>
</div>
