import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from "@angular/material/legacy-tabs";
import { Router } from "@angular/router";
import { DialogService, EmptyStateMode, GlobalFeatures, GlobalOperatorPermissions, MIN_PAGE_SIZE_VALUE } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import equal from "fast-deep-equal";
import { ToastrService } from "ngx-toastr";
import { combineLatestWith, distinctUntilChanged } from "rxjs/operators";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { AdministrativeFeeInStatementApiService } from "../../services/administrative-fee-in-statement-api.service";
import { StatementsFilters, StatementsListQueryParams } from "../../services/operator-permissions.models";
import { OperatorPermissionsActions } from "../../state/operator-permissions.actions";
import { OperatorPermissionsState } from "../../state/operator-permissions.state";
import { PermissionUavDetailsComponent } from "../permission-uav-details/permission-uav-details.component";

interface PermissionsContainerComponentComponentState {
    selectedTab: ListType;
    statementsPage: number;
    statementsSize: number;
    currentFilters: StatementsFilters | undefined;
    areFiltersApplied: boolean;
}

enum ListType {
    PERMISSIONS = 0,
    STATEMENTS = 1,
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-permissions-and-statements-container",
    templateUrl: "./permissions-and-statements-container.component.html",
    styleUrls: ["./permissions-and-statements-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PermissionsAndStatementsContainerComponent {
    protected readonly isPaymentFeatureAvailable$ = this.store.select(OperatorContextState.isFeatureAvailable(GlobalFeatures.Payments));
    protected readonly isStatementsFeatureAvailable$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.Statements)
    );
    protected readonly ownedPermissions$ = this.store.select(OperatorPermissionsState.ownedPermissionsList);
    protected readonly isPermissionsPermissionAvailable$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorMissionsPlan)
    );
    protected readonly isStatementsPermissionAvailable$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperationalStatementMakeStatement)
    );
    protected readonly isOwnedPermissionsProcessing$ = this.store.select(OperatorPermissionsState.isOwnedPermissionsListProcessing);
    protected readonly ownedPermissionsListError$ = this.store.select(OperatorPermissionsState.ownedPermissionsListError);
    protected readonly statementsList$ = this.store.select(OperatorPermissionsState.statementsList);
    protected readonly statementsListPagination$ = this.store.select(OperatorPermissionsState.statementsListPagination);
    protected readonly statementsListError$ = this.store.select(OperatorPermissionsState.statementsListError);
    protected readonly isStatementsListProcessing$ = this.store.select(OperatorPermissionsState.isStatementsListProcessing);
    protected readonly selectedTab$ = this.localStore.selectByKey("selectedTab");
    protected readonly currentFilters$ = this.localStore.selectByKey("currentFilters");
    protected readonly areFiltersApplied$ = this.localStore.selectByKey("areFiltersApplied");
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly ListType = ListType;

    private readonly selectedOperatorContextId$ = this.store
        .select(OperatorContextState.selectedOperatorContextId)
        .pipe(RxjsUtils.filterFalsy());

    constructor(
        private readonly store: Store,
        private readonly router: Router,
        private readonly localStore: LocalComponentStore<PermissionsContainerComponentComponentState>,
        private readonly administrativeFeeInStatementApiService: AdministrativeFeeInStatementApiService,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly dialogService: DialogService
    ) {
        this.localStore.setState({
            selectedTab: ListType.PERMISSIONS,
            statementsPage: 0,
            statementsSize: MIN_PAGE_SIZE_VALUE,
            currentFilters: undefined,
            areFiltersApplied: false,
        });
        this.watchSelectedContextAndTabChange();
        this.watchFiltersChange();
    }

    protected changeFilters(filters: StatementsFilters | undefined) {
        this.localStore.patchState({ currentFilters: filters });
    }

    protected navigateToGainingPermissions(): void {
        this.router.navigateByUrl("/statements");
    }

    protected changeTab(tab: MatTabChangeEvent): void {
        const currentFilters = this.localStore.selectSnapshotByKey("currentFilters");
        this.localStore.patchState({
            selectedTab: tab.index === ListType.PERMISSIONS ? ListType.PERMISSIONS : ListType.STATEMENTS,
            currentFilters: tab.index === ListType.STATEMENTS ? currentFilters : undefined,
        });
    }

    protected reloadList(): void {
        const currentList = this.localStore.selectSnapshotByKey("selectedTab");
        if (currentList === ListType.STATEMENTS) {
            this.initStatementsLists();
        } else {
            this.fetchOwnedPermissionsList();
        }
    }

    protected watchFiltersChange() {
        this.currentFilters$
            .pipe(distinctUntilChanged(equal), combineLatestWith(this.selectedTab$), untilDestroyed(this))
            .subscribe(([filters, selectedListType]) => {
                if (selectedListType !== ListType.STATEMENTS) {
                    return;
                }

                this.localStore.patchState({ statementsPage: 0 });
                this.initStatementsLists(filters);
            });
    }

    protected changeStatementsPage({ pageIndex, pageSize }: PageEvent): void {
        this.localStore.patchState({ statementsPage: pageIndex, statementsSize: pageSize });
        this.fetchStatementsList({
            operatorId: this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId),
            page: pageIndex,
            size: pageSize,
            ...this.localStore.selectSnapshotByKey("currentFilters"),
        });
    }

    protected redirectToSignature(statementId: string): void {
        this.router.navigate(["statements"], {
            queryParams: { statementId },
        });
    }

    protected showUavDetailsForPermission(permissionId: string): void {
        const selectedOperatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);
        if (!selectedOperatorId) {
            return;
        }

        this.store
            .dispatch(new OperatorPermissionsActions.GetUavDetailsForPermission(selectedOperatorId, permissionId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(OperatorPermissionsState.permissionUavDetailsError)) {
                    this.toastrService.error(
                        this.translocoService.translate(
                            "dtmWebAppLibOperatorPermissions.permissionsContainer.getUavDetailsForPermissionError"
                        )
                    );

                    return;
                }

                this.dialogService.open(PermissionUavDetailsComponent, {
                    data: this.store.selectSnapshot(OperatorPermissionsState.permissionUavDetails),
                });
            });
    }

    protected changeAreFiltersApplied(value: boolean) {
        this.localStore.patchState({ areFiltersApplied: value });
    }

    private watchSelectedContextAndTabChange(): void {
        this.selectedOperatorContextId$
            .pipe(distinctUntilChanged(), combineLatestWith(this.selectedTab$), untilDestroyed(this))
            .subscribe(([contextId, selectedTab]) => {
                switch (selectedTab) {
                    case ListType.PERMISSIONS:
                        this.fetchOwnedPermissionsList();
                        this.changeFilters(undefined);
                        break;
                    case ListType.STATEMENTS:
                        this.localStore.patchState({ statementsPage: 0 });
                        this.initStatementsLists();
                        break;
                }
            });
    }

    private initStatementsLists(filters?: StatementsFilters): void {
        const params = {
            operatorId: this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId),
            page: this.localStore.selectSnapshotByKey("statementsPage"),
            size: this.localStore.selectSnapshotByKey("statementsSize"),
            ...filters,
        };
        this.fetchStatementsList(params);
    }

    private fetchOwnedPermissionsList(): void {
        this.store.dispatch(
            new OperatorPermissionsActions.GetOwnedPermissionsList(
                this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId)
            )
        );
    }

    private fetchStatementsList(params: StatementsListQueryParams): void {
        this.store.dispatch(new OperatorPermissionsActions.GetStatementsList(params));
    }

    protected getOperatorStatementAdministrativeFeeDocument(feeDocumentId: string) {
        this.store
            .dispatch(new OperatorPermissionsActions.GetOperatorStatementAdministrativeFeeDocument(feeDocumentId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorPermissionsState.operatorStatementAdministrativeFeeDocumentError);
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate(
                            "dtmWebAppLibOperatorPermissions.statementsList.downloadAdministrativeFeeDocumentErrorLabel"
                        )
                    );
                }
            });
    }
}
