import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { finalize } from "rxjs/operators";
import { GlobalOutlets } from "../../../shared/models/router.models";
import { LegalGuardianFormValue, NOT_FOUND_PAGE } from "../../services/legal-guardian.models";
import { LegalGuardianDetailsActions } from "../../state/legal-guardian-details.actions";
import { LegalGuardianDetailsState } from "../../state/legal-guardian-details.state";

interface LegalGuardianContainerComponentState {
    requestId: string;
    isPostSignature: boolean;
    isSignatureSuccess: boolean | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-legal-guardian-container",
    templateUrl: "./legal-guardian-container.component.html",
    styleUrls: ["./legal-guardian-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LegalGuardianContainerComponent {
    protected readonly legalGuardianDetails$ = this.store.select(LegalGuardianDetailsState.legalGuardianGeneralData);
    protected readonly legalGuardianGeneralDataError$ = this.store.select(LegalGuardianDetailsState.legalGuardianGeneralDataError);
    protected readonly isPostSignature$ = this.localStore.selectByKey("isPostSignature");
    protected readonly isSignatureSuccess$ = this.localStore.selectByKey("isSignatureSuccess");

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly translocoHelper: TranslationHelperService,
        private readonly localStore: LocalComponentStore<LegalGuardianContainerComponentState>,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        const requestId = route.snapshot.queryParams.requestId;
        const postSigned = route.snapshot.queryParams.postSigned;

        if (!requestId) {
            this.router.navigate([{ outlets: { [GlobalOutlets.Public]: [NOT_FOUND_PAGE] } }]);

            return;
        }
        this.localStore.setState({ requestId, isPostSignature: !!postSigned, isSignatureSuccess: undefined });

        if (postSigned) {
            this.validateSignature(requestId);

            return;
        }

        this.fetchLegalGuardianGeneralData(requestId);
    }

    public generatePdf(legalGuardianFormValue: LegalGuardianFormValue): void {
        this.store
            .dispatch(new LegalGuardianDetailsActions.GeneratePdf(legalGuardianFormValue))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(LegalGuardianDetailsState.generatePdfError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppLegalGuardian.legalGuardianDetails.cannotGeneratePdfErrorMessage")
                    );
                }
            });
    }

    public sendDataForSignature(legalGuardianFormValue: LegalGuardianFormValue): void {
        const requestId = this.localStore.selectSnapshotByKey("requestId");
        this.store
            .dispatch(new LegalGuardianDetailsActions.SignLegalGuardianData(legalGuardianFormValue, requestId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(LegalGuardianDetailsState.signLegalGuardianDataError);
                const signatureRedirectUrl = this.store.selectSnapshot(LegalGuardianDetailsState.signatureRedirectUrl);

                if (error || !signatureRedirectUrl) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppLegalGuardian.legalGuardianDetails.cannotSendDataToSignErrorMessage")
                    );

                    return;
                }

                this.store.dispatch(new LegalGuardianDetailsActions.RedirectToLegalGuardianSignature(signatureRedirectUrl));
            });
    }

    protected goBackToForm() {
        const requestId = this.localStore.selectSnapshotByKey("requestId");
        this.localStore.patchState({ isSignatureSuccess: undefined, isPostSignature: false });
        this.router.navigate([{ outlets: { [GlobalOutlets.Public]: "legal-guardian-details" } }], {
            queryParams: { requestId },
            queryParamsHandling: "",
        });
        this.fetchLegalGuardianGeneralData(requestId);
    }

    private fetchLegalGuardianGeneralData(requestId: string): void {
        this.store
            .dispatch(new LegalGuardianDetailsActions.GetLegalGuardianGeneralData(requestId))
            .pipe(
                untilDestroyed(this),
                finalize(() => this.removeLoader())
            )
            .subscribe();
    }

    private validateSignature(requestId: string) {
        this.store
            .dispatch(new LegalGuardianDetailsActions.VerifyLegalGuardianSignature(requestId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.localStore.patchState({
                    isSignatureSuccess: !!this.store.selectSnapshot(LegalGuardianDetailsState.isLegalGuardianFormSigned),
                });
                this.removeLoader();
            });
    }

    private removeLoader() {
        this.document.getElementById("loader")?.remove();
    }
}
