<div *ngIf="error$ | ngrxPush as error; else noErrorTemplate" [ngSwitch]="error.type">
    <dtm-ui-error
        *ngSwitchCase="OperatorPermissionsErrorType.CannotGetAllPossiblePermissions"
        (reload)="loadAllPermissions()"
        [errorMessage]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.cannotGetAllPossiblePermissionsErrorMessage' | transloco"
    >
    </dtm-ui-error>
</div>

<ng-template #noErrorTemplate>
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush" *ngrxLet="stepsAmount$ as stepsAmount">
        <dtm-ui-lib-wizard-content
            [wizardId]="wizardId"
            *ngrxLet="{ availableUavs: availableUavs$, isPaymentFeatureAvailable: isPaymentFeatureAvailable$ } as vm"
        >
            <dtm-ui-lib-wizard-step-content [stepId]="ApplicationWizardSteps.GainPermissions">
                <dtm-web-app-lib-operator-permissions-gaining-permissions-step
                    *ngrxLet="possiblePermissions$ as possiblePermissions"
                    [possiblePermissions]="possiblePermissions"
                    [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
                    [stepsAmount]="stepsAmount"
                    [areUavsAvailable]="!!vm.availableUavs?.length"
                    [availableUavs]="vm.availableUavs"
                    (next)="goToOperationInfoStep($event)"
                    (areUavsRequiredStateChange)="areUavsRequiredStateChange($event)"
                ></dtm-web-app-lib-operator-permissions-gaining-permissions-step>
            </dtm-ui-lib-wizard-step-content>
            <dtm-ui-lib-wizard-step-content [stepId]="ApplicationWizardSteps.UavInformation">
                <dtm-web-app-lib-operator-permissions-uav-information-step
                    [selectedPossiblePermissions]="selectedPossiblePermissions$ | ngrxPush"
                    [availableUavs]="vm.availableUavs"
                    [stepsAmount]="stepsAmount"
                    [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
                    (back)="goToAndEnableStep(ApplicationWizardSteps.GainPermissions)"
                    (next)="updateSelectedPossiblePermissionsAndGoToSummary($event)"
                ></dtm-web-app-lib-operator-permissions-uav-information-step>
            </dtm-ui-lib-wizard-step-content>
            <dtm-ui-lib-wizard-step-content [stepId]="ApplicationWizardSteps.Summary">
                <dtm-web-app-lib-operator-permissions-summary
                    [stepsAmount]="stepsAmount"
                    [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
                    [isAttorneyPowerAdministrativeFeeRequired]="isAttorneyPowerAdministrativeFeeRequired$ | ngrxPush"
                    [selectedPossiblePermissions]="selectedPossiblePermissions$ | ngrxPush"
                    [operator]="operator$ | ngrxPush"
                    (back)="goBackFromSummary()"
                    (getStatementInPdf)="getStatementInPdf()"
                    (next)="saveStatementAndPay($event)"
                ></dtm-web-app-lib-operator-permissions-summary>
            </dtm-ui-lib-wizard-step-content>
            <dtm-ui-lib-wizard-step-content [stepId]="ApplicationWizardSteps.StatementSign">
                <dtm-web-app-lib-operator-statement-signature
                    [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
                    [savedStatement]="savedStatement$ | ngrxPush"
                    (refresh)="refreshStatement()"
                    (paymentRepeat)="repeatPayment()"
                    (statementSignAndSend)="signAndSendStatement()"
                ></dtm-web-app-lib-operator-statement-signature>
            </dtm-ui-lib-wizard-step-content>
        </dtm-ui-lib-wizard-content>
    </dtm-ui-loader-container>
</ng-template>
