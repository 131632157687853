import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { FormType, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { OperationUav, Uav, UavInfo } from "../../../services/specific-permit-application.models";

interface UavInfoComponentState {
    uav: OperationUav | undefined;
    availableUavs: Uav[];
    loadWeight: number | undefined;
    isExpanded: boolean;
    isEditMode: boolean;
    currentUavInfo: UavInfo;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-uav-info[uav]",
    templateUrl: "./uav-info.component.html",
    styleUrls: ["../../common.scss", "./uav-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UavInfoComponent), multi: true }],
})
export class UavInfoComponent implements ControlValueAccessor {
    @Input() public set uav(value: OperationUav | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set availableUavs(value: Uav[] | undefined) {
        this.localStore.patchState({ availableUavs: value });
    }

    @Input() public set loadWeight(value: number | undefined) {
        this.localStore.patchState({ loadWeight: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly uav$ = this.localStore.selectByKey("uav").pipe(RxjsUtils.filterFalsy());
    protected readonly selectableUavs$ = this.localStore.selectByKey("availableUavs").pipe(
        combineLatestWith(this.uav$),
        map(([availableUavs, operationUav]) => availableUavs.filter((uav) => this.isUavSelectable(uav, operationUav)))
    );
    protected readonly loadWeight$ = this.localStore.selectByKey("loadWeight");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly allSerialNumbers$ = combineLatest([this.uav$, this.localStore.selectByKey("currentUavInfo")]).pipe(
        map(([uav, currentUavInfo]) => [
            ...uav.serialNumbers,
            ...currentUavInfo.uavs.reduce<string[]>((allSerialNumbers, { serialNumbers }) => [...allSerialNumbers, ...serialNumbers], []),
        ])
    );

    protected readonly uavInfoForm = new FormGroup<FormType<UavInfo>>({
        uavs: new FormControl<Uav[]>([], {
            nonNullable: true,
        }),
        isStatementChecked: new FormControl<boolean>(false, {
            nonNullable: true,
        }),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: UavInfo | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<UavInfoComponentState>) {
        this.localStore.setState({
            uav: undefined,
            availableUavs: [],
            loadWeight: undefined,
            isExpanded: true,
            isEditMode: false,
            currentUavInfo: { uavs: [], isStatementChecked: false },
        });

        this.uavInfoForm.controls.uavs.valueChanges.pipe(untilDestroyed(this)).subscribe((uavsList) => {
            if (this.isUavsListNotEmpty(uavsList)) {
                this.uavInfoForm.controls.isStatementChecked.setValidators(Validators.requiredTrue);
            } else {
                this.uavInfoForm.controls.isStatementChecked.clearValidators();
            }

            this.uavInfoForm.controls.isStatementChecked.updateValueAndValidity({ emitEvent: false });
        });
    }

    public registerOnChange(fn: (value: UavInfo | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: UavInfo | null): void {
        if (value) {
            this.uavInfoForm.setValue(this.convertUavInfoToFormValues(value), { emitEvent: false });
        } else {
            this.uavInfoForm.reset();
        }

        this.persistCurrentUavInfo();
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.uavInfoForm.controls.uavs.disable();
            this.uavInfoForm.controls.isStatementChecked.disable();
        } else {
            this.uavInfoForm.controls.uavs.enable();
            this.uavInfoForm.controls.isStatementChecked.enable();
        }
    }

    protected setEditMode(isEditMode: boolean) {
        this.localStore.patchState(({ isExpanded }) => ({ isEditMode, isExpanded: isEditMode ? true : isExpanded }));
    }

    protected cancel() {
        const currentUavInfo = this.localStore.selectSnapshotByKey("currentUavInfo");
        this.uavInfoForm.reset(currentUavInfo);
        this.setEditMode(false);
    }

    protected save() {
        this.uavInfoForm.markAllAsTouched();

        if (this.uavInfoForm.invalid) {
            return;
        }

        const currentUavInfo = this.uavInfoForm.getRawValue();
        this.persistCurrentUavInfo();
        if (this.isUavsListNotEmpty(currentUavInfo.uavs)) {
            this.propagateChange(currentUavInfo);
        } else {
            this.propagateChange(null);
            this.uavInfoForm.reset();
        }

        this.setEditMode(false);
    }

    protected removeAdditionalUavs() {
        this.propagateChange(null);
        this.uavInfoForm.reset();
        this.persistCurrentUavInfo();
        this.setEditMode(false);
    }

    protected isUavsListNotEmpty(uavsList: Uav[] | null) {
        return (uavsList ?? []).length !== 0;
    }

    private isUavSelectable(uav: Uav, operationUav: OperationUav): boolean {
        return uav.id !== operationUav.id && uav.model.id === operationUav.model.id;
    }

    private persistCurrentUavInfo() {
        const value = this.uavInfoForm.getRawValue();
        this.localStore.patchState({
            currentUavInfo: {
                uavs: value.uavs ?? [],
                isStatementChecked: value.isStatementChecked,
            },
        });
    }

    private convertUavInfoToFormValues(uavInfo: UavInfo): UavInfo {
        const availableUavs = this.localStore.selectSnapshotByKey("availableUavs");
        const uavIds = uavInfo.uavs.map((uav) => uav.id);

        return {
            ...uavInfo,
            uavs: availableUavs.filter((availableUav) => uavIds.includes(availableUav.id)),
        };
    }
}
