import {
    AirRiskMitigationsArc,
    AirRiskMitigationsCategory,
    DensityBox,
    M1BStatementsKeys,
    M2StatementsKeys,
    MissionCategory,
    MissionPlanSpecificPermitType,
    MissionType,
    RobustnessLevel,
} from "@dtm-frontend/shared/mission";
import { Alpha3CountryCode, GeoJSON, OperatorType, UavType } from "@dtm-frontend/shared/ui";
import { DateUtils, Logger } from "@dtm-frontend/shared/utils";
import { GroundRiskBufferType, OperationalGeometryData, PilotCompetencies } from "../../shared";
import {
    ApplicationCreatorWizardSteps,
    ApplicationData,
    ApplicationDraft,
    Capabilities,
    CrewMember,
    FailSafe,
    MitigationMeasureStatement,
    MitigationMeasureStatementComment,
    OperationAreaType,
    OperationBaseInformation,
    TacticalMitigationPerformanceRequirement,
    TimeOfTheDay,
    Training,
    UavClass,
} from "./specific-permit-application.models";

export interface CapabilitiesResponseBody {
    operationsManualGuides: Array<{
        id: string;
        name: string;
        guide: string;
    }>;
    uavs: Array<{
        id: string;
        name: string;
        serialNumbers: string[];
        model: {
            id: string;
            name: string;
            manufacturer: string;
            type: UavType;
            uavClass?: UavClass[];
        };
        swarm: boolean;
    }>;
    flightPurposes: Array<{
        id: string;
        codename: string;
        name: string;
        description: string;
        loadWeightRequired: boolean;
        commentRequired: boolean;
    }>;
    availableAreas: Array<{
        id: string;
        name: string;
    }>;
    operationScenarios: Array<{
        id: string;
        name: string;
        category: MissionCategory;
        description: string;
        expirationDate: string;
    }>;
    trainings: TrainingResponseBody[];
    additionalCrewMembers: CrewMember[];
    crewMemberTypes: CrewMember[];
    osoStatements: {
        [sail: number]: Array<{
            id: string;
            osoNumber: number;
            sail: number;
            nativeContent: string;
            englishContent: string;
        }>;
    };
    initialViewBox: GeoJSON;
}

export interface TrainingResponseBody {
    id: string;
    code: string;
    nativeName: string;
    englishName: string;
    requiredForOpenCategory: boolean;
    requiredForSpecificCategory: boolean;
    requiredForMultiPersonCrew: boolean;
    sails: number[];
}

export interface SaveDraftResponseBody {
    id: string;
    name: string;
    createdAt: string;
    modifiedAt: string;
    jsonContent: string;
}

export interface OperationResponseBody {
    operator: {
        id: string;
        operatorNumber: string;
        type: OperatorType;
        name: string;
        companyNumber: string;
        insurancePolicyNumber: string;
        insurancePolicyExpirationDate: string;
        address: AddressResponseBody;
        operationalAuthorizations: CompetencyResponseBody[];
    };
    pilot: {
        id: string;
        pilotNumber: string;
        firstName: string;
        lastName: string;
        insurancePolicyNumber: string;
        address: AddressResponseBody;
        competencies: CompetencyResponseBody[];
    };
    uav: {
        id: string;
        name: string;
        serialNumbers: string[];
        model: {
            id: string;
            name: string;
            manufacturer: string;
            type: UavType;
            uavClass?: UavClass[];
        };
        technicalSpecification: {
            maxTakeOffMass: number;
            maxDroneWidth: number;
            maxFlightSpeed: number;
        };
        certificate: {
            noiseCertificateNumber?: string;
            airworthinessCertificateNumber?: string;
            typeCertificateNumberOrDvrReport?: string;
        };
        swarm: boolean;
    };
    missionPlan: {
        id: string;
        name: string;
        description?: string;
        category: {
            type: MissionCategory;
            subtype: MissionPlanSpecificPermitType;
            scenarioName: string;
            specificLucSkipSora: boolean;
            specificCaaPermitId: string;
        };
        flightStartAt: string;
        flightFinishAt: string;
        sail: number;
        flight: {
            flightType: MissionType;
            purposeCode: string;
            purposeComment: string;
            loadWeight?: number;
            dangerousGoods: boolean;
            maxSpeed: number;
            pilotReactionDelay: number;
        };
        flightArea: {
            estimatedDistance: number;
            maxWidth: number;
            maxHeight: number;
            safetyAreaWidth: number;
            safetyAreaHeight: number;
            groundRiskBuffer: number;
        };
        adjacentArea: {
            bufferHorizontal: number;
            height: number;
            size: number;
        };
        groundRisk: {
            populationDensity: {
                boxes?: Partial<Record<"GREEN" | "RED" | "UNKNOWN" | "VIOLET" | "YELLOW" | "BLUE", number>>;
                peoplePerSquareKilometerAvg?: number;
                peoplePerSquareKilometerMax?: number;
            };
            controlledGroundAreaComment: string | undefined;
            peopleAssemblyComment: string | undefined;
            finalGrl: number;
            intrinsicGrc: number;
            finalGrc: number;
            groundRiskMitigations: {
                m1A?: {
                    robustnessLevel: RobustnessLevel;
                    userStatements?: string[];
                    lowerPeopleDensity?: MitigatingMeasureResponseBody;
                    bystandersShielded?: MitigatingMeasureResponseBody;
                };
                m1B?: {
                    robustnessLevel: RobustnessLevel;
                    userStatements?: string[];
                    bystandersSafeDistance?: MitigatingMeasureResponseBody;
                    abnormalSituationCommand?: MitigatingMeasureResponseBody;
                    abnormalSituationAlert?: MitigatingMeasureResponseBody;
                };
                m2?: {
                    robustnessLevel: RobustnessLevel;
                    userStatements?: string[];
                    other?: MitigatingMeasureResponseBody;
                    parachute?: MitigatingMeasureResponseBody;
                    criticalAreaShielded?: MitigatingMeasureResponseBody;
                    fragility?: MitigatingMeasureResponseBody;
                    emergencyDrag?: MitigatingMeasureResponseBody;
                    autorotation?: MitigatingMeasureResponseBody;
                };
            };
        };
        airspaceRisk: {
            uncontrolledAirspace: boolean;
            controlledAirspace: boolean;
            aroundObstacleOnly: boolean;
            segregatedAirspaceOnly: boolean;
            flightLowAgl: boolean;
            initialArc: number;
            residualArc: number;
            airRiskMitigations?: {
                mitigatingMeasures: Array<{
                    designators?: string[];
                    category: AirRiskMitigationsCategory;
                    initialArc: AirRiskMitigationsArc;
                    residualArc: AirRiskMitigationsArc;
                    methods: Array<{
                        title: string;
                        details: string;
                    }>;
                }>;
            };
        };
        adjacentAreaRisk: {
            peoplePerSquareKilometerAvg: number;
            intrinsicGrc: number;
            peopleAssembly: boolean;
            intrinsicGrl: number;
            initialArc: number;
            flightTerminationSystemAvailable: boolean;
            failSafe?: FailSafe[];
            geofencing: boolean;
            geocage: boolean;
        };
        additionalCrew: Array<{
            type: CrewMember;
            amount: number;
        }>;
    };
}

interface MitigatingMeasureResponseBody {
    userStatements: string[];
    comment?: string;
    attachments: Array<{
        fileId: string;
        name: string;
        contentType?: string;
        length: number;
    }>;
}

interface AddressResponseBody {
    streetName: string;
    houseNumber: string;
    apartmentNumber?: string;
    city: string;
    postCode: string;
    country: Alpha3CountryCode;
}

interface CompetencyResponseBody {
    id: string;
    operationScenario: {
        id: string;
        name: PilotCompetencies;
        category: MissionCategory;
    };
    expirationDate: string | null;
}

export interface PredefinedAreaResponseBody {
    id: string;
    name: string;
    description: string;
    area: GeoJSON;
}

export interface DraftResponseBody {
    id: string;
    name: string;
    createdAt: string;
    modifiedAt: string;
    jsonContent: string;
    operatorId: string;
    userId: string;
}

export interface ApplicationDataRequestPayload {
    operatorId: string;
    operationInfo: {
        operationId: string;
        additionalUavIds: string[];
        additionalUavStatement: boolean;
        hasUavSetupBeenUsedPreviously: boolean;
        operationsManualChapter: string;
        technicalService: string;
        c3Link: string;
        hmi: string;
        adverseEnvironmentalConditions: string;
        dvrOrTcNumber: string;
    };
    operationDetails: {
        operationsManualVersion: string;
        operationsManualChapter: string;
        operationArea: {
            type: string;
            areaId?: string;
            geoJsonForCustomArea?: string;
        };
        pilotCompetenciesToScenarioIds: string[];
        pilotOtherCompetencies: string[];
        pilotCompetenciesDefinedInEuRegulation: boolean;
        pilotTrainingIds: string[];
        pilotOtherTrainings: string[];
        additionalCrew: Array<{
            type: CrewMember;
            amount: number;
            responsibilities: string | null;
            competenciesToScenarioIds: string[];
            otherCompetencies: string[];
            competenciesDefinedInEuRegulation: boolean;
            trainingIds: string[];
            otherTrainings: string[];
        }>;
        timeOfDay: TimeOfTheDay;
        timeRange: {
            start: string;
            finish: string;
        };
        nightFlightStatement: boolean;
        maxWindSpeed: number;
        minVisibility: number;
        minTemperature: number;
        maxTemperature: number;
        flightDuringRain: boolean;
    };
    statements: {
        osoNumberStatements: number[];
    };
    applicationInfo: {
        responsibleManager: string;
        contactPerson: {
            fullName: string;
            phoneNumber: {
                countryCode: string;
                number: string;
            };
            email: string;
        };
        additionalNote: string;
        specificPermitNumber: string | null;
    };
}

export interface OperationalGeometryDataResponseBody {
    id: string;
    soraSail: number;
    flight: {
        flightType: MissionType;
        purposeComment: string;
        dangerousGoods: boolean;
        maxSpeed: number;
    };
    operationArea: {
        maxHeight: number;
        size: number;
        maxRadius: number;
        maxWidth: number;
        maxDeclaredHeight: number;
    };
    flightArea: {
        estimatedDistance: number;
        maxRadius: number;
        maxDeclaredHeight: number;
        maxHeight: number;
        maxWidth: number;
        size: number;
    };
    safetyArea: {
        height: number;
        width: number;
    };
    adjacentArea: {
        bufferHorizontal: number;
        height: number;
        size: number;
    };
    groundRisk: {
        areaSize: number;
        finalGrl: number;
        adjFinalGrl: number;
        buffer?: number;
        bufferType: GroundRiskBufferType | null;
    };
    airspace: {
        operationalControlled: false;
        operationalUncontrolled: true;
        adjacentControlled: true;
        adjacentUncontrolled: true;
    };
    aroundObstacleOnly: false;
    segregatedAirspaceOnly: false;
    version: number;
}

export function convertTrainingResponseBodiesToTrainings(response: TrainingResponseBody[]): Training[] {
    return response
        .filter((training) => training.code !== "OTHER")
        .map((training) => ({
            id: training.id,
            name: {
                pl: training.nativeName,
                en: training.englishName,
            },
        }));
}

export function convertCapabilitiesResponseBodyToCapabilities(response: CapabilitiesResponseBody): Capabilities {
    return {
        guideChapterItems: response.operationsManualGuides.map((guide) => ({
            chapterId: guide.id,
            title: guide.name,
            content: guide.guide,
        })),
        availableUavs: response.uavs.map((responseUav) => ({
            id: responseUav.id,
            name: responseUav.name,
            serialNumbers: responseUav.serialNumbers,
            model: {
                id: responseUav.model.id,
                name: responseUav.model.name,
                manufacturer: responseUav.model.manufacturer,
                type: responseUav.model.type,
                uavClass: responseUav.model.uavClass ?? [],
            },
            isSwarm: responseUav.swarm,
        })),
        flightPurposes: response.flightPurposes.map((flightPurpose) => ({
            id: flightPurpose.id,
            codename: flightPurpose.codename,
            name: flightPurpose.name,
            description: flightPurpose.description,
            isLoadWeightRequired: flightPurpose.loadWeightRequired,
            isCommentRequired: flightPurpose.commentRequired,
        })),
        predefinedAreas: response.availableAreas,
        operationScenarios: response.operationScenarios.map((operationScenario) => ({
            id: operationScenario.id,
            name: operationScenario.name as PilotCompetencies,
            category: operationScenario.category,
        })),
        trainings: convertTrainingResponseBodiesToTrainings(response.trainings),
        crewMembers: response.crewMemberTypes,
        initialViewbox: response.initialViewBox,
    };
}

function convertM1AMitigationsToStatements(m1A: {
    lowerPeopleDensity?: MitigatingMeasureResponseBody;
    bystandersShielded?: MitigatingMeasureResponseBody;
}): MitigationMeasureStatement[] {
    const result: MitigationMeasureStatement[] = [];

    if (m1A.lowerPeopleDensity) {
        result.push(...m1A.lowerPeopleDensity.userStatements.map((statement) => ({ name: statement, comments: [] })));
    }

    if (m1A.bystandersShielded) {
        result.push(...m1A.bystandersShielded.userStatements.map((statement) => ({ name: statement, comments: [] })));
    }

    return result;
}

function convertM1BMitigationsToStatements(m1B: {
    userStatements?: string[];
    bystandersSafeDistance?: MitigatingMeasureResponseBody;
    abnormalSituationCommand?: MitigatingMeasureResponseBody;
    abnormalSituationAlert?: MitigatingMeasureResponseBody;
}): MitigationMeasureStatement[] {
    const comments: MitigationMeasureStatementComment[] = [];

    for (const statement of m1B.userStatements ?? []) {
        if (statement === M1BStatementsKeys.ableToReduceNumberOfBystandersAtRisk) {
            const subItems: string[] = [];

            subItems.push(...(m1B.abnormalSituationAlert?.userStatements ?? []));
            subItems.push(...(m1B.abnormalSituationCommand?.userStatements ?? []));
            subItems.push(...(m1B.bystandersSafeDistance?.userStatements ?? []));

            comments.push({ content: statement, subItems });
        } else {
            comments.push({ content: statement });
        }
    }

    return [
        {
            name: "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.mitigations.m1BStatementsLabel",
            comments,
        },
    ];
}

function convertM2MitigationsToStatements(m2: {
    userStatements?: string[];
    other?: MitigatingMeasureResponseBody;
    parachute?: MitigatingMeasureResponseBody;
    criticalAreaShielded?: MitigatingMeasureResponseBody;
    fragility?: MitigatingMeasureResponseBody;
    emergencyDrag?: MitigatingMeasureResponseBody;
    autorotation?: MitigatingMeasureResponseBody;
}): MitigationMeasureStatement[] {
    const result: MitigationMeasureStatement[] = [];

    if (m2.parachute) {
        result.push({ name: M2StatementsKeys.parachute, comments: [] });
    }

    if (m2.criticalAreaShielded) {
        result.push({ name: M2StatementsKeys.criticalAreaShielded, comments: [] });
    }

    if (m2.fragility) {
        result.push(...m2.fragility.userStatements.map((statement) => ({ name: statement, comments: [] })));
    }

    if (m2.emergencyDrag) {
        result.push(...m2.emergencyDrag.userStatements.map((statement) => ({ name: statement, comments: [] })));
    }

    if (m2.autorotation) {
        result.push(...m2.autorotation.userStatements.map((statement) => ({ name: statement, comments: [] })));
    }

    if (m2.other) {
        result.push(
            ...m2.other.userStatements.map((statement) => ({
                name: statement,
                comments: m2.other?.comment ? [{ content: m2.other?.comment }] : [],
            }))
        );
    }

    return result;
}

function convertMitigationArcToArc(arc: AirRiskMitigationsArc): number | null {
    if (arc === AirRiskMitigationsArc.B) {
        return 2;
    } else if (arc === AirRiskMitigationsArc.C) {
        // eslint-disable-next-line no-magic-numbers
        return 3;
    } else if (arc === AirRiskMitigationsArc.D) {
        // eslint-disable-next-line no-magic-numbers
        return 4;
    } else {
        return null;
    }
}

function calculateTmpr(flightType: MissionType, residualArc: number): TacticalMitigationPerformanceRequirement {
    if (flightType === MissionType.VLOS) {
        return TacticalMitigationPerformanceRequirement.VLOS;
    }

    switch (residualArc) {
        case 1:
            return TacticalMitigationPerformanceRequirement.A;
        case 2:
            return TacticalMitigationPerformanceRequirement.B;
        // eslint-disable-next-line no-magic-numbers
        case 3:
            return TacticalMitigationPerformanceRequirement.C;
        // eslint-disable-next-line no-magic-numbers
        case 4:
            return TacticalMitigationPerformanceRequirement.D;
    }

    return TacticalMitigationPerformanceRequirement.Unknown;
}

export function convertOperationResponseBodyToOperationBaseInformation(response: OperationResponseBody): OperationBaseInformation {
    return {
        operator: {
            id: response.operator.id,
            name: response.operator.name,
            number: response.operator.operatorNumber,
            type: response.operator.type,
            companyNumber: response.operator.companyNumber ?? undefined,
            insurancePolicy:
                response.operator.insurancePolicyNumber && response.operator.insurancePolicyExpirationDate
                    ? {
                          number: response.operator.insurancePolicyNumber,
                          expirationDate: new Date(response.operator.insurancePolicyExpirationDate),
                      }
                    : undefined,
            address: {
                streetName: response.operator.address.streetName,
                houseNumber: response.operator.address.houseNumber,
                apartmentNumber: response.operator.address.apartmentNumber ?? null,
                city: response.operator.address.city,
                postCode: response.operator.address.postCode,
                country: response.operator.address.country,
            },
            operationalAuthorizations: response.operator.operationalAuthorizations.map((operationalAuthorization) => ({
                id: operationalAuthorization.id,
                operationScenario: operationalAuthorization.operationScenario,
                expirationDate: operationalAuthorization.expirationDate ? new Date(operationalAuthorization.expirationDate) : null,
            })),
        },
        pilot: {
            id: response.pilot.id,
            number: response.pilot.pilotNumber,
            firstName: response.pilot.firstName,
            lastName: response.pilot.lastName,
            insurancePolicyNumber: response.pilot.insurancePolicyNumber ?? null,
            address: {
                streetName: response.pilot.address.streetName,
                houseNumber: response.pilot.address.houseNumber,
                apartmentNumber: response.pilot.address.apartmentNumber ?? null,
                city: response.pilot.address.city,
                postCode: response.pilot.address.postCode,
                country: response.pilot.address.country,
            },
            competencies: response.pilot.competencies.map((competency) => ({
                id: competency.id,
                operationScenario: competency.operationScenario,
                expirationDate: competency.expirationDate ? new Date(competency.expirationDate) : null,
            })),
        },
        uav: {
            id: response.uav.id,
            name: response.uav.name,
            serialNumbers: response.uav.serialNumbers,
            model: {
                id: response.uav.model.id,
                name: response.uav.model.name,
                manufacturer: response.uav.model.manufacturer,
                type: response.uav.model.type,
                uavClass: response.uav.model.uavClass ?? [],
            },
            technicalSpecification: {
                maxTakeOffMass: response.uav.technicalSpecification.maxTakeOffMass,
                maxDroneWidth: response.uav.technicalSpecification.maxDroneWidth,
                maxFlightSpeed: response.uav.technicalSpecification.maxFlightSpeed,
            },
            certificate: {
                noiseCertificateNumber: response.uav.certificate.noiseCertificateNumber,
                airworthinessCertificateNumber: response.uav.certificate.airworthinessCertificateNumber,
                typeCertificateNumberOrDvrReport: response.uav.certificate.typeCertificateNumberOrDvrReport,
            },
            isSwarm: response.uav.swarm,
        },
        operation: {
            id: response.missionPlan.id,
            name: response.missionPlan.name,
            description: response.missionPlan.description,
            flightStartAt: new Date(response.missionPlan.flightStartAt),
            flightFinishAt: new Date(response.missionPlan.flightFinishAt),
            category: {
                type: response.missionPlan.category.type,
                subtype: response.missionPlan.category.subtype,
                scenarioName: response.missionPlan.category.scenarioName as PilotCompetencies,
                isSpecificLucSkipSora: response.missionPlan.category.specificLucSkipSora,
                specificCaaPermitId: response.missionPlan.category.specificCaaPermitId,
            },
            sail: response.missionPlan.sail,
            flight: {
                flightType: response.missionPlan.flight.flightType,
                purposeCode: response.missionPlan.flight.purposeCode,
                purposeComment: response.missionPlan.flight.purposeComment,
                loadWeight: response.missionPlan.flight.loadWeight ?? null,
                areDangerousGoods: response.missionPlan.flight.dangerousGoods,
                maxSpeed: response.missionPlan.flight.maxSpeed,
                pilotReactionDelay: response.missionPlan.flight.pilotReactionDelay,
            },
            flightArea: {
                estimatedDistance: response.missionPlan.flightArea.estimatedDistance,
                maxWidth: response.missionPlan.flightArea.maxWidth,
                maxHeight: response.missionPlan.flightArea.maxHeight,
                safetyAreaWidth: response.missionPlan.flightArea.safetyAreaWidth,
                safetyAreaHeight: response.missionPlan.flightArea.safetyAreaHeight,
                groundRiskBuffer: response.missionPlan.flightArea.groundRiskBuffer,
            },
            adjacentArea: {
                bufferHorizontal: response.missionPlan.adjacentArea.bufferHorizontal,
                bufferVertical: response.missionPlan.adjacentArea.height,
                size: response.missionPlan.adjacentArea.size,
            },
            groundRisk: {
                populationDensity: {
                    boxes: {
                        [DensityBox.Lowest]: response.missionPlan.groundRisk.populationDensity.boxes?.GREEN,
                        [DensityBox.Low]: response.missionPlan.groundRisk.populationDensity.boxes?.YELLOW,
                        [DensityBox.Medium]: response.missionPlan.groundRisk.populationDensity.boxes?.RED,
                        [DensityBox.High]: response.missionPlan.groundRisk.populationDensity.boxes?.VIOLET,
                        [DensityBox.Highest]: response.missionPlan.groundRisk.populationDensity.boxes?.BLUE,
                        [DensityBox.Unknown]: response.missionPlan.groundRisk.populationDensity.boxes?.UNKNOWN,
                    },
                    peoplePerSquareKilometerAvg: response.missionPlan.groundRisk.populationDensity.peoplePerSquareKilometerAvg,
                    peoplePerSquareKilometerMax: response.missionPlan.groundRisk.populationDensity.peoplePerSquareKilometerMax,
                },
                controlledGroundAreaComment: response.missionPlan.groundRisk.controlledGroundAreaComment ?? null,
                peopleAssemblyComment: response.missionPlan.groundRisk.peopleAssemblyComment ?? null,
                finalGrl: response.missionPlan.groundRisk.finalGrl,
                intrinsicGrc: response.missionPlan.groundRisk.intrinsicGrc,
                finalGrc: response.missionPlan.groundRisk.finalGrc,
                mitigations: {
                    m1A: !response.missionPlan.groundRisk.groundRiskMitigations?.m1A
                        ? undefined
                        : {
                              robustnessLevel: response.missionPlan.groundRisk.groundRiskMitigations.m1A.robustnessLevel,
                              statements: convertM1AMitigationsToStatements(response.missionPlan.groundRisk.groundRiskMitigations.m1A),
                          },
                    m1B: !response.missionPlan.groundRisk.groundRiskMitigations?.m1B
                        ? undefined
                        : {
                              robustnessLevel: response.missionPlan.groundRisk.groundRiskMitigations.m1B.robustnessLevel,
                              statements: convertM1BMitigationsToStatements(response.missionPlan.groundRisk.groundRiskMitigations.m1B),
                          },
                    m2: !response.missionPlan.groundRisk.groundRiskMitigations?.m2
                        ? undefined
                        : {
                              robustnessLevel: response.missionPlan.groundRisk.groundRiskMitigations.m2.robustnessLevel,
                              statements: convertM2MitigationsToStatements(response.missionPlan.groundRisk.groundRiskMitigations.m2),
                          },
                },
            },
            airspaceRisk: {
                isUncontrolledAirspace: response.missionPlan.airspaceRisk.uncontrolledAirspace,
                isControlledAirspace: response.missionPlan.airspaceRisk.controlledAirspace,
                isAroundObstacleOnly: response.missionPlan.airspaceRisk.aroundObstacleOnly,
                isSegregatedAirspaceOnly: response.missionPlan.airspaceRisk.segregatedAirspaceOnly,
                isFlightLowAgl: !!response.missionPlan.airspaceRisk.flightLowAgl,
                initialArc: response.missionPlan.airspaceRisk.initialArc,
                residualArc: response.missionPlan.airspaceRisk.residualArc,
                mitigations: (response.missionPlan.airspaceRisk.airRiskMitigations?.mitigatingMeasures ?? []).map((measure) => ({
                    category: measure.category,
                    initialArc: convertMitigationArcToArc(measure.initialArc),
                    residualArc: convertMitigationArcToArc(measure.residualArc),
                    methods: measure.methods,
                    designators: measure.designators ?? [],
                })),
                tmpr: calculateTmpr(response.missionPlan.flight.flightType, response.missionPlan.airspaceRisk.residualArc),
            },
            adjacentAreaRisk: {
                peoplePerSquareKilometerAvg: response.missionPlan.adjacentAreaRisk.peoplePerSquareKilometerAvg,
                intrinsicGrc: response.missionPlan.adjacentAreaRisk.intrinsicGrc,
                isPeopleAssembly: response.missionPlan.adjacentAreaRisk.peopleAssembly,
                intrinsicGrl: response.missionPlan.adjacentAreaRisk.intrinsicGrl,
                initialArc: response.missionPlan.adjacentAreaRisk.initialArc,
                isFlightTerminationSystemAvailable: response.missionPlan.adjacentAreaRisk.flightTerminationSystemAvailable ?? false,
                failSafe: response.missionPlan.adjacentAreaRisk.failSafe ?? [],
                hasGeofencing: response.missionPlan.adjacentAreaRisk.geofencing ?? false,
                hasGeocage: response.missionPlan.adjacentAreaRisk.geocage ?? false,
            },
            additionalCrew: response.missionPlan.additionalCrew,
        },
    };
}

export function convertDraftResponseBodyToApplicationDraft(response: DraftResponseBody): ApplicationDraft {
    let data;

    try {
        data = JSON.parse(response.jsonContent);
    } catch (error) {
        Logger.captureException(error);
    }

    const timeOfDay = data[ApplicationCreatorWizardSteps.OperationDetails]?.timeOfDay;
    if (timeOfDay?.timeFrom) {
        data[ApplicationCreatorWizardSteps.OperationDetails].timeOfDay.timeFrom = new Date(timeOfDay.timeFrom);
    }
    if (timeOfDay?.timeTo) {
        data[ApplicationCreatorWizardSteps.OperationDetails].timeOfDay.timeTo = new Date(timeOfDay.timeTo);
    }

    return {
        id: response.id,
        name: response.name,
        data: data ?? {},
    };
}

export function convertApplicationDataToApplicationDataRequestPayload(
    operatorId: string,
    data: ApplicationData
): ApplicationDataRequestPayload {
    const [operationInfo, operationDetails, statements, applicationInfo] = [
        data[ApplicationCreatorWizardSteps.OperationInfo],
        data[ApplicationCreatorWizardSteps.OperationDetails],
        data[ApplicationCreatorWizardSteps.Statements],
        data[ApplicationCreatorWizardSteps.ApplicationInfo],
    ];

    return {
        operatorId,
        operationInfo: {
            operationId: operationInfo.operation.id,
            additionalUavIds: (operationInfo.uavInfo?.uavs ?? []).map((uav) => uav.id),
            additionalUavStatement: !!operationInfo.uavInfo?.isStatementChecked,
            hasUavSetupBeenUsedPreviously: operationInfo.uavAdditionalInfo?.hasUavSetupBeenUsedPreviously ?? true,
            operationsManualChapter: operationInfo.uavAdditionalInfo?.operationsManualChapter
                ? `${operationInfo.uavAdditionalInfo?.operationsManualChapter}`
                : "",
            technicalService: operationInfo.uavAdditionalInfo?.technicalService ?? "",
            c3Link: operationInfo.uavAdditionalInfo?.c3Link ?? "",
            hmi: operationInfo.uavAdditionalInfo?.hmi ?? "",
            adverseEnvironmentalConditions: operationInfo.uavAdditionalInfo?.adverseEnvironmentalConditions ?? "",
            dvrOrTcNumber: operationInfo.uavAdditionalInfo?.dvrOrTcNumber ?? "",
        },
        operationDetails: {
            operationsManualVersion: operationDetails.operationsManualVersion,
            operationsManualChapter: operationDetails.operationsManualChapter ? `${operationDetails.operationsManualChapter}` : "",
            operationArea: {
                type: operationDetails.operationArea.type,
                areaId:
                    operationDetails.operationArea.type === OperationAreaType.PredefinedArea &&
                    operationDetails.operationArea.predefinedAreaId
                        ? operationDetails.operationArea.predefinedAreaId
                        : undefined,
                geoJsonForCustomArea:
                    operationDetails.operationArea.type === OperationAreaType.CustomArea && operationDetails.operationArea.area
                        ? JSON.stringify(operationDetails.operationArea.area)
                        : undefined,
            },
            pilotCompetenciesToScenarioIds: operationDetails.pilotCompetencies.basic.map((competency) => competency.id),
            pilotOtherCompetencies: operationDetails.pilotCompetencies.additional,
            pilotCompetenciesDefinedInEuRegulation: operationDetails.pilotCompetencies.hasEuRegulationCompetency,
            pilotTrainingIds: (operationDetails.pilotOptionalTrainings?.basic ?? []).map((training) => training.id),
            pilotOtherTrainings: operationDetails.pilotOptionalTrainings?.additional ?? [],
            additionalCrew: (operationDetails.additionalCrewMembers ?? []).map((member) => ({
                type: member.member,
                amount: member.member === CrewMember.UavObserver && member.amount ? member.amount : 1,
                responsibilities: member.responsibilities,
                competenciesToScenarioIds: (operationDetails.crewCompetencies?.[member.member]?.basic ?? []).map(
                    (competency) => competency.id
                ),
                otherCompetencies: operationDetails.crewCompetencies?.[member.member]?.additional ?? [],
                competenciesDefinedInEuRegulation: !!operationDetails.crewCompetencies?.[member.member]?.hasEuRegulationCompetency,
                trainingIds: (operationDetails.crewTrainings?.[member.member]?.basic ?? []).map((training) => training.id),
                otherTrainings: operationDetails.crewTrainings?.[member.member]?.additional ?? [],
            })),
            timeOfDay: operationDetails.timeOfDay.timeOfDay,
            timeRange: {
                start:
                    operationDetails.timeOfDay.timeFrom && operationDetails.timeOfDay.timeOfDay === TimeOfTheDay.DayAndNight
                        ? DateUtils.getISOStringTime(operationDetails.timeOfDay.timeFrom.toISOString())
                        : "",
                finish:
                    operationDetails.timeOfDay.timeTo && operationDetails.timeOfDay.timeOfDay === TimeOfTheDay.DayAndNight
                        ? DateUtils.getISOStringTime(operationDetails.timeOfDay.timeTo.toISOString())
                        : "",
            },
            nightFlightStatement: !!operationDetails.timeOfDay.isNightFlightStatement,
            maxWindSpeed: operationDetails.maxWindSpeed,
            minVisibility: operationDetails.minVisibility,
            minTemperature: operationDetails.minTemperature,
            maxTemperature: operationDetails.maxTemperature,
            flightDuringRain: operationDetails.isFlightDuringRain,
        },
        statements: {
            osoNumberStatements: statements.osoNumbers,
        },
        applicationInfo: {
            responsibleManager: applicationInfo.responsibleManager ?? "",
            contactPerson: {
                fullName: applicationInfo.contactPersonFullName,
                phoneNumber: {
                    countryCode: applicationInfo.contactPersonPhone.countryCode ?? "PL",
                    number: applicationInfo.contactPersonPhone.number,
                },
                email: applicationInfo.contactPersonEmail,
            },
            additionalNote: applicationInfo.additionalNote ?? "",
            specificPermitNumber: operationInfo.specificPermitNumber,
        },
    };
}

export const convertOperationalGeometryDataResponseBodyToOperationalGeometryData = (
    response: OperationalGeometryDataResponseBody
): OperationalGeometryData => ({
    safetyAreaWidth: response.safetyArea.width,
    safetyAreaHeight: response.safetyArea.height,
    groundRiskBuffer: response.groundRisk.buffer,
    groundRiskBufferType: response.groundRisk.bufferType,
    adjacentAreaBufferHorizontal: response.adjacentArea.bufferHorizontal,
    adjacentAreaHeight: response.adjacentArea.height,
    flightAreaMaxRadius: response.flightArea.maxRadius,
    flightAreaMaxDeclaredHeight: response.flightArea.maxDeclaredHeight,
    operationAreaMaxRadius: response.operationArea.maxRadius,
    flightAreaMaxHeight: response.flightArea.maxHeight,
    operationAreaMaxHeight: response.operationArea.maxHeight,
    flightAreaEstimatedDistance: response.flightArea.estimatedDistance,
    flightAreaMaxWidth: response.flightArea.maxWidth,
    operationAreaMaxWidth: response.operationArea.maxWidth,
    flightAreaSize: response.flightArea.size,
    operationAreaMaxDeclaredHeight: response.operationArea.maxDeclaredHeight,
    operationAreaSize: response.operationArea.size,
});
