import { LegalGuardianFormValue } from "../services/legal-guardian.models";

export namespace LegalGuardianDetailsActions {
    export class GetLegalGuardianGeneralData {
        public static readonly type = "[Legal Guardian Details] Get legal guardian general data";
        constructor(public requestId: string) {}
    }

    export class GeneratePdf {
        public static readonly type = "[Legal Guardian Details] Generate legal guardian confirmation PDF";
        constructor(public formValue: LegalGuardianFormValue) {}
    }

    export class SignLegalGuardianData {
        public static readonly type = "[Legal Guardian Details] Send for electronic signature";
        constructor(public formValue: LegalGuardianFormValue, public requestId: string) {}
    }

    export class VerifyLegalGuardianSignature {
        public static readonly type = "[Legal Guardian Details] Validate legal guardian signature";
        constructor(public requestId: string) {}
    }

    export class RedirectToLegalGuardianSignature {
        public static readonly type = "[Legal Guardian Details] Redirect to legal guardian signature";
        constructor(public redirectUrl: string) {}
    }
}
