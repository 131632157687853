import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ExamResultListWithPages, ExamResultsErrorType } from "../models/exam-results.models";
import { TrainingExamResultsFilterParams } from "../models/training-exam-results.models";
import { TrainingResultListWithPages, TrainingResultsErrorType } from "../models/training-results.models";
import { TRAINING_EXAM_RESULTS_ENDPOINTS, TrainingExamResultsEndpoints } from "../training-exam-results.tokens";
import {
    GetExamResultsListResponseBody,
    GetTrainingResultsListResponseBody,
    convertGetExamResultListResponseBodyToExamResultListWithPages,
    convertGetTrainingResultListResponseBodyToTrainingResultListWithPages,
    getTrainingExamResultsParams,
} from "./training-exam-results-api.converters";

@Injectable({
    providedIn: "root",
})
export class TrainingExamResultsApiService {
    constructor(
        @Inject(TRAINING_EXAM_RESULTS_ENDPOINTS) private readonly endpoints: TrainingExamResultsEndpoints,
        private readonly httpClient: HttpClient
    ) {}

    public getExamResultsList(filters: TrainingExamResultsFilterParams, operatorId: string): Observable<ExamResultListWithPages> {
        const params: HttpParams = getTrainingExamResultsParams(filters);

        return this.httpClient
            .get<GetExamResultsListResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getExamResultsList, { operatorId }), {
                params,
            })
            .pipe(
                map((response) => convertGetExamResultListResponseBodyToExamResultListWithPages(response)),
                catchError(() => throwError(() => ({ type: ExamResultsErrorType.CannotGetExamResultsList })))
            );
    }

    public getTrainingResultsList(filters: TrainingExamResultsFilterParams, operatorId: string): Observable<TrainingResultListWithPages> {
        const params: HttpParams = getTrainingExamResultsParams(filters);

        return this.httpClient
            .get<GetTrainingResultsListResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getTrainingResultsList, { operatorId }), {
                params,
            })
            .pipe(
                map((response) => convertGetTrainingResultListResponseBodyToTrainingResultListWithPages(response)),
                catchError(() => throwError(() => ({ type: TrainingResultsErrorType.CannotGetTrainingResultsList })))
            );
    }
}
