import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ErrorMode, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MembershipError, MembershipMission } from "../../../services/membership.models";

interface MembershipMissionsListComponentState {
    missionsList: MembershipMission[];
    isMissionsProcessing: boolean;
    missionsListError: MembershipError | undefined;
    pagination: Page | undefined;
}

@Component({
    selector: "dtm-web-app-lib-membership-missions-list[missionsList][isMissionsProcessing]",
    templateUrl: "./membership-missions-list.component.html",
    styleUrls: ["./membership-missions-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembershipMissionsListComponent {
    @Input() public set missionsList(value: MembershipMission[] | undefined) {
        this.localStore.patchState({ missionsList: value ?? [] });
    }

    @Input() public set isMissionsProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isMissionsProcessing: !!value });
    }

    @Input() public set missionsListError(value: MembershipError | undefined) {
        this.localStore.patchState({ missionsListError: value });
    }

    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public missionsListRefresh = new EventEmitter<void>();
    @Output() public pageChange = new EventEmitter<PageEvent>();

    protected readonly missionsList$ = this.localStore.selectByKey("missionsList");
    protected readonly isMissionsProcessing$ = this.localStore.selectByKey("isMissionsProcessing");
    protected readonly missionsListError$ = this.localStore.selectByKey("missionsListError");
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly displayedColumns = ["status", "date", "hour", "uav", "name", "weather", "actions"];
    protected readonly ErrorMode = ErrorMode;

    constructor(private readonly localStore: LocalComponentStore<MembershipMissionsListComponentState>) {
        localStore.setState({ missionsList: [], isMissionsProcessing: false, missionsListError: undefined, pagination: undefined });
    }
}
