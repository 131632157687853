<dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
    <div class="dialog-header">
        <h4 mat-dialog-title>{{ "dtmWebAppLibUav.shareUav.shareHeaderLabel" | transloco }} {{ this.data.uavName }}</h4>
        <button type="button" class="button-icon" [matDialogClose]="null"><dtm-ui-icon name="close"></dtm-ui-icon></button>
    </div>
    <div mat-dialog-content class="share-content" [formGroup]="shareUavForm">
        <div class="top">
            <span class="share-step">{{ "dtmWebAppLibUav.shareUav.choosePilotStep" | transloco : { value: data.isPilot } }}</span>
        </div>
        <dtm-ui-select-field
            [isClearable]="true"
            [placeholder]="'dtmWebAppLibUav.shareUav.choosePlaceholder' | transloco"
            [formControl]="shareUavForm.controls.operator"
        >
            <label>{{ "dtmWebAppLibUav.shareUav.contextTypeLabel" | transloco : { value: data.isPilot } }}</label>
            <dtm-ui-select-option *ngFor="let operator of operators$ | ngrxPush" [value]="operator">
                {{ operator.name }}
            </dtm-ui-select-option>
            <div class="field-error" *dtmUiFieldHasError="shareUavForm.controls.operator; name: 'required'">
                {{ "dtmWebAppLibUav.shareUav.requiredField" | transloco }}
            </div>
        </dtm-ui-select-field>
        <span class="share-step">{{ "dtmWebAppLibUav.shareUav.chooseConfigurationStep" | transloco }}</span>
        <dtm-ui-select-field
            [isClearable]="true"
            [formControl]="shareUavForm.controls.setups"
            [placeholder]="'dtmWebAppLibUav.shareUav.choosePlaceholder' | transloco"
            multiple
        >
            <label>{{ "dtmWebAppLibUav.shareUav.configurationLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let setup of setups$ | ngrxPush" [value]="setup"> {{ setup.name }} </dtm-ui-select-option>
            <div class="field-error" *dtmUiFieldHasError="shareUavForm.controls.setups; name: 'required'">
                {{ "dtmWebAppLibUav.shareUav.requiredField" | transloco }}
            </div>
        </dtm-ui-select-field>

        <mat-chip-list>
            <mat-chip class="chip removable" *ngFor="let setup of shareUavForm.controls.setups.value"
                >{{ setup.name }}<dtm-ui-icon name="close-circle-fill" matChipRemove (click)="removeSetup(setup)"></dtm-ui-icon
            ></mat-chip>
        </mat-chip-list>
    </div>
    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" (click)="dialog.close()">
            {{ "dtmWebAppLibUav.shareUav.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary save-button" (click)="shareUavSetup()">
            {{ "dtmWebAppLibUav.shareUav.shareButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>
