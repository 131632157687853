import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent, UploadedFile } from "@dtm-frontend/shared/ui";
import { AnimationUtils, BYTES_IN_MEGABYTE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import {
    AttorneyPowerType,
    NewAttorneyPower,
    OperatorAttorneyPower,
    OperatorAttorneyPowerCapabilities,
    OperatorAttorneyPowerMember,
} from "../../models/operator.models";

const NATIONAL_COURT_REGISTRATION_MASK = "0 0 0 0 0 0 0 0 0 0";
const NATIONAL_COURT_REGISTRATION_LENGTH = 10;

// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;
const ATTORNEY_FILE_ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "application/pdf"];

interface AttorneyPowerFormComponentState {
    attorneyPowers: OperatorAttorneyPower[] | undefined;
    capabilities: OperatorAttorneyPowerCapabilities | undefined;
    userId: string | undefined;
    isCapabilitiesProcessing: boolean;
}

interface AttorneyPowerForm {
    isCompanyOwner: FormControl<boolean | null>;
    member: FormControl<OperatorAttorneyPowerMember | null>;
    type?: FormControl<AttorneyPowerType | null>;
    nationalCourtRegister?: FormControl<string>;
    attorneyPowerDocument?: FormControl<UploadedFile[]>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-attorney-power-form",
    templateUrl: "./attorney-power-form.component.html",
    styleUrls: ["./attorney-power-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class AttorneyPowerFormComponent {
    @Input() public set attorneyPowers(value: OperatorAttorneyPower[] | undefined) {
        this.localStore.patchState({ attorneyPowers: value });
    }
    @Input() public set capabilities(value: OperatorAttorneyPowerCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }
    @Input() public set userId(value: string | undefined) {
        this.localStore.patchState({ userId: value });
    }
    @Input() public set isCapabilitiesProcessing(value: BooleanInput) {
        this.localStore.patchState({ isCapabilitiesProcessing: coerceBooleanProperty(value) });
    }
    @Output() public formSave = new EventEmitter<NewAttorneyPower>();
    @Output() public attorneyPowerTemplateDownload = new EventEmitter<void>();
    @Output() public formClose = new EventEmitter<void>();

    protected readonly AttorneyPowerType = AttorneyPowerType;
    protected readonly NATIONAL_COURT_REGISTRATION_MASK = NATIONAL_COURT_REGISTRATION_MASK;
    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    protected readonly ATTORNEY_FILE_ALLOWED_MIME_TYPES = ATTORNEY_FILE_ALLOWED_MIME_TYPES;

    protected readonly isCapabilitiesProcessing$ = this.localStore.selectByKey("isCapabilitiesProcessing");
    protected readonly capabilities$ = this.localStore.selectByKey("capabilities").pipe(RxjsUtils.filterFalsy());
    protected readonly userId$ = this.localStore.selectByKey("userId").pipe(RxjsUtils.filterFalsy());

    protected readonly nationalCourtRegisterControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(NATIONAL_COURT_REGISTRATION_LENGTH)],
        nonNullable: true,
    });
    protected readonly memberControl = new FormControl<OperatorAttorneyPowerMember | null>(null, Validators.required);
    protected readonly typeControl = new FormControl<AttorneyPowerType | null>(null, Validators.required);
    protected readonly attorneyPowerDocumentControl = new FormControl<UploadedFile[]>([], {
        validators: [Validators.required, Validators.maxLength(1)],
        nonNullable: true,
    });
    protected readonly isCompanyOwnerControl = new FormControl<boolean | null>(null, Validators.required);
    protected readonly attorneyPowerForm = new FormGroup<AttorneyPowerForm>({
        isCompanyOwner: this.isCompanyOwnerControl,
        member: this.memberControl,
    });

    constructor(
        private readonly localStore: LocalComponentStore<AttorneyPowerFormComponentState>,
        private readonly matDialog: MatDialog,
        private readonly translocoService: TranslocoService
    ) {
        localStore.setState({
            attorneyPowers: undefined,
            capabilities: undefined,
            userId: undefined,
            isCapabilitiesProcessing: false,
        });
    }

    protected saveForm() {
        this.attorneyPowerForm.markAllAsTouched();

        if (this.attorneyPowerForm.invalid) {
            return;
        }

        const formSaveValue = this.getNewAttorneyPowerValue();
        if (!formSaveValue) {
            return;
        }
        this.formSave.emit(formSaveValue);
    }

    protected closeForm() {
        if (this.attorneyPowerForm.touched) {
            this.confirmFormClose();

            return;
        }

        this.formClose.emit();
    }

    private removeAttorneyDocuments() {
        if (this.attorneyPowerForm.controls.attorneyPowerDocument) {
            this.attorneyPowerForm.removeControl("attorneyPowerDocument");
            this.attorneyPowerDocumentControl.reset();
        }
    }

    private removeNationalCourtNumberControl() {
        if (!this.attorneyPowerForm.controls.nationalCourtRegister) {
            return;
        }

        this.attorneyPowerForm.removeControl("nationalCourtRegister");
        this.nationalCourtRegisterControl.reset();
    }

    private getNewAttorneyPowerValue(): NewAttorneyPower | undefined {
        const type = this.attorneyPowerForm.controls.isCompanyOwner.value
            ? AttorneyPowerType.Owner
            : this.attorneyPowerForm.controls.type?.value;

        const granteeUserId = this.attorneyPowerForm.controls.member.value?.userId;

        if (!type || !granteeUserId) {
            return;
        }

        let newAttorneyPowerValue: NewAttorneyPower = {
            type,
            granteeUserId,
        };

        if (this.attorneyPowerForm.controls.nationalCourtRegister?.value) {
            newAttorneyPowerValue = {
                ...newAttorneyPowerValue,
                nationalCourtRegister: this.attorneyPowerForm.controls.nationalCourtRegister.value,
            };
        }

        if (!this.attorneyPowerForm.controls.isCompanyOwner.value) {
            newAttorneyPowerValue = {
                ...newAttorneyPowerValue,
                documentId: this.attorneyPowerForm.controls.attorneyPowerDocument?.value[0]?.id,
            };
        }

        return newAttorneyPowerValue;
    }

    protected getAttorneyPowerModelDocument() {
        this.attorneyPowerTemplateDownload.emit();
    }

    protected changeCompanyOwnerValue(value: boolean) {
        if (value && this.attorneyPowerForm.controls.type) {
            this.attorneyPowerForm.removeControl("type");
            this.typeControl.reset();

            return;
        } else if (!value) {
            this.attorneyPowerForm.addControl("type", this.typeControl);

            return;
        }
    }

    protected changeTypeValue(value: AttorneyPowerType) {
        if (value === AttorneyPowerType.NationalCourtNumber) {
            this.removeAttorneyDocuments();
            this.attorneyPowerForm.addControl("nationalCourtRegister", this.nationalCourtRegisterControl);

            return;
        }
        this.removeNationalCourtNumberControl();
        this.attorneyPowerForm.addControl("attorneyPowerDocument", this.attorneyPowerDocumentControl);
    }

    private confirmFormClose() {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("dtmWebAppOperatorProfile.attorneyPowers.form.closeFormTitleText"),
                    confirmationText: this.translocoService.translate(
                        "dtmWebAppOperatorProfile.attorneyPowers.form.closeFormConfirmationText"
                    ),
                    declineButtonLabel: this.translocoService.translate("dtmWebAppOperatorProfile.attorneyPowers.form.cancelLabel"),
                    confirmButtonLabel: this.translocoService.translate("dtmWebAppOperatorProfile.attorneyPowers.form.confirmCloseLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.attorneyPowerForm.reset();
                this.closeForm();
            });
    }
}
