<ng-container *ngIf="equipmentProperties$ | ngrxPush as properties">
    <ng-template [ngTemplateOutlet]="weightPropertyTemplate" [ngTemplateOutletContext]="{ $implicit: properties }"></ng-template>
</ng-container>
<ng-container *ngIf="parachuteProperties$ | ngrxPush as properties">
    <ng-template [ngTemplateOutlet]="weightPropertyTemplate" [ngTemplateOutletContext]="{ $implicit: properties }"></ng-template>
    <dtm-web-app-lib-uav-property [label]="'dtmWebAppLibUav.uavProperties.equipment.parachute.minHeightLabel' | transloco">
        {{
            "dtmWebAppLibUav.uavProperties.equipment.parachute.minHeightValue"
                | transloco : { value: properties.minHeight | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.equipment.parachute.descentSpeedLabel' | transloco"
        [isEmpty]="!properties.descentSpeed"
    >
        {{
            "dtmWebAppLibUav.uavProperties.equipment.parachute.descentSpeedValue"
                | transloco : { value: properties.descentSpeed | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.equipment.parachute.maxWindSpeedLabel' | transloco"
        [isEmpty]="!properties.maxWindSpeed"
    >
        {{
            "dtmWebAppLibUav.uavProperties.equipment.parachute.maxWindSpeedValue"
                | transloco : { value: properties.maxWindSpeed | convertToKmPerH | localizeNumber : { maximumFractionDigits: 0 } }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.equipment.parachute.operatingTemperatureLabel' | transloco"
        [isEmpty]="!properties.minOperatingTemperature && !properties.maxOperatingTemperature"
    >
        {{
            "dtmWebAppLibUav.uavProperties.equipment.parachute.operatingTemperatureValue"
                | transloco : { value: properties.minOperatingTemperature }
        }}
        -
        {{
            "dtmWebAppLibUav.uavProperties.equipment.parachute.operatingTemperatureValue"
                | transloco : { value: properties.maxOperatingTemperature }
        }}
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.equipment.parachute.hasCeCertificateLabel' | transloco"
        [value]="properties.hasCeCertificate"
    >
    </dtm-web-app-lib-uav-property-boolean>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.equipment.parachute.isAstmF332218CompliantLabel' | transloco"
        [value]="properties.isAstmF332218Compliant"
    >
    </dtm-web-app-lib-uav-property-boolean>
</ng-container>

<ng-template #weightPropertyTemplate let-properties>
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.equipment.weightLabel' | transloco"
        [isEmpty]="properties.weight | invoke : isEmpty"
    >
        {{
            "dtmWebAppLibUav.uavProperties.equipment.weightValue"
                | transloco : { value: properties.weight | convertToGrams | localizeNumber }
        }}
    </dtm-web-app-lib-uav-property>
</ng-template>
