import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
    ChildEntitySubtype,
    MapEntity,
    MapEntityType,
    MapUtils,
    Polyline3DGeoJSONProperties,
    SerializableCartographic,
} from "@dtm-frontend/shared/map/cesium";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    EmptyStateMode,
    MissionPlanRoute,
    MissionPlanRouteSection,
    Waypoint,
} from "@dtm-frontend/shared/ui";
import { ArrayElementType, DateUtils, LocalComponentStore, MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Properties } from "@turf/helpers";
import equal from "fast-deep-equal";
import { Observable, combineLatest, firstValueFrom, map, pairwise, startWith } from "rxjs";
import {
    ItineraryContent,
    ItineraryContentEntity,
    ItineraryContentMetadata,
    Polyline3DEntityMetadata,
    Polyline3DItineraryEntity,
} from "../../../../../../../models/itinerary.model";
import { HeightType, ItineraryEditorFormData, MissionPlanItineraryConstraints } from "../../../../../../../models/mission.model";
import { CustomItineraryEntity } from "../../../../../../../services/itinerary.service";
import {
    ItineraryPanelSettings,
    ViewItineraryCorridorEntity,
    ViewItineraryCylinderEntity,
    ViewItineraryEntity,
    ViewItineraryEntityCommon,
    ViewItineraryEntityType,
    ViewItineraryInternalCylinderEntity,
    ViewItineraryInternalPrismEntity,
    ViewItineraryLandingEntity,
    ViewItineraryPointEntity,
    ViewItineraryPrismEntity,
    ViewItinerarySegmentEntity,
    ViewItineraryShapeEntity,
    ViewItineraryTakeoffEntity,
} from "../../itinerary-panel.models";
import { ItineraryPanelStopoverDialogComponent } from "../stopover-dialog/stopover-dialog.component";
import {
    DEFAULT_TRACK_DETAILS_DISPLAY_MODE,
    DISABLED_TRACK_DETAILS_DISPLAY_MODE_CAN_EDIT,
    TrackDetailsDisplayMode,
} from "./track-details-display-mode";

interface TrackDetailsComponentState {
    itineraryEditorFormData: Partial<ItineraryEditorFormData> | undefined;
    itineraryContent: ItineraryContent;
    currentPlanRouteWaypoints: WaypointWithSection[] | undefined;
    entityInEditMode: ViewItineraryEntity | ViewItineraryShapeEntity | undefined;
    settings: ItineraryPanelSettings | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    isProcessing: boolean;
    displayMode: TrackDetailsDisplayMode;
    countPolylinesAsMultipleWaypoints: boolean;
    isDisabled: boolean;
}

interface WaypointWithSection {
    waypoint: Waypoint;
    parentSection: MissionPlanRouteSection;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-track-details[itineraryContent][constraints][currentPlanRoute][displayMode][isProcessing]",
    templateUrl: "./track-details.component.html",
    styleUrls: ["../itinerary-panel/itinerary-panel.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackDetailsComponent {
    protected readonly ViewItineraryEntityType = ViewItineraryEntityType;
    protected readonly MapEntityType = MapEntityType;
    protected readonly HeightType = HeightType;
    protected readonly EmptyStateMode = EmptyStateMode;

    protected readonly itineraryContent$ = this.localStore.selectByKey("itineraryContent");
    protected readonly entityInEditMode$ = this.localStore.selectByKey("entityInEditMode");
    protected readonly settings$ = this.localStore.selectByKey("settings");
    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly displayMode$ = combineLatest([
        this.localStore.selectByKey("displayMode"),
        this.localStore.selectByKey("isDisabled"),
    ]).pipe(
        map(
            ([displayMode, isDisabled]): TrackDetailsDisplayMode =>
                !isDisabled
                    ? displayMode
                    : {
                          ...displayMode,
                          canEdit: DISABLED_TRACK_DETAILS_DISPLAY_MODE_CAN_EDIT,
                          canDelete: {
                              point: false,
                              shape: false,
                              corridor: false,
                          },
                      }
        )
    );
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly entities$ = combineLatest([
        this.itineraryContent$,
        this.localStore.selectByKey("isProcessing"),
        this.localStore.selectByKey("currentPlanRouteWaypoints"),
    ]).pipe(
        map(([itineraryContent]) => this.generateEntitiesFromItineraryContent(itineraryContent)),
        startWith(new Array<ViewItineraryEntity>()),
        pairwise(),
        map(([previousEntities, currentEntities]) =>
            currentEntities.map((currentEntity) => {
                const previousEntity = previousEntities.find((entity) => entity.id === currentEntity.id);

                // TODO: fix contentHash calculation to make it work DTM-3800
                return currentEntity.contentHash === previousEntity?.contentHash ? previousEntity : currentEntity;
            })
        )
    );

    @Input() public set itineraryContent(value: ItineraryContent | undefined) {
        const itineraryContent = Array.isArray(value) ? value : [];
        this.localStore.patchState({ itineraryContent });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set currentPlanRoute(value: MissionPlanRoute | undefined) {
        if (!value) {
            return;
        }

        this.localStore.patchState({ currentPlanRouteWaypoints: this.convertRouteToWaypoints(value) });
    }
    @Input() public set itineraryEditorFormData(value: Partial<ItineraryEditorFormData> | undefined) {
        this.localStore.patchState({ itineraryEditorFormData: value });
    }
    @Input() public set settings(value: ItineraryPanelSettings | undefined) {
        this.localStore.patchState({ settings: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set displayMode(value: TrackDetailsDisplayMode) {
        this.localStore.patchState({ displayMode: value });
    }
    @Input() public set countPolylinesAsMultipleWaypoints(value: BooleanInput) {
        this.localStore.patchState({ countPolylinesAsMultipleWaypoints: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Output() public entityUpdate = new EventEmitter<ItineraryContentEntity>();
    @Output() public entityMetadataUpdate = new EventEmitter<ItineraryContentMetadata["value"]>();
    @Output() public entityAdd = new EventEmitter<CustomItineraryEntity>();
    @Output() public entityDelete = new EventEmitter<ViewItineraryEntity["itineraryIndex"]>();
    @Output() public pointDelete = new EventEmitter<ViewItineraryEntity<Polyline3DItineraryEntity>["itineraryIndex"]>();

    constructor(
        private readonly localStore: LocalComponentStore<TrackDetailsComponentState>,
        private readonly dialog: MatDialog,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            itineraryContent: [],
            currentPlanRouteWaypoints: undefined,
            itineraryEditorFormData: undefined,
            entityInEditMode: undefined,
            settings: undefined,
            constraints: undefined,
            isProcessing: false,
            displayMode: DEFAULT_TRACK_DETAILS_DISPLAY_MODE,
            countPolylinesAsMultipleWaypoints: true,
            isDisabled: false,
        });
    }

    private convertRouteToWaypoints(route: MissionPlanRoute) {
        const waypoints = route.sections.reduce<WaypointWithSection[]>((result, section, sectionIndex) => {
            if (section.flightZone) {
                const estimateArrivalTime = new Date(section.flightZone.center.estimatedArriveAt.max).getTime();
                const durationTime =
                    (DateUtils.convertISO8601DurationToSeconds(section.flightZone.stopover.max) ?? 0) * MILLISECONDS_IN_SECOND;

                const estimatedArriveAtMax = new Date(estimateArrivalTime + durationTime);

                if (!this.arePolyline3DGeoJSONProperties(section.flightZone.flightArea.properties)) {
                    result.push({
                        waypoint: {
                            ...section.flightZone.center,
                            estimatedArriveAt: {
                                min: section.flightZone.center.estimatedArriveAt.min,
                                max: estimatedArriveAtMax,
                            },
                            point: {
                                ...section.flightZone.center.point,
                                altitude: section.flightZone.center.point.altitude,
                            },
                        },
                        parentSection: section,
                    });
                } else {
                    const flightZone = section.flightZone;
                    section.flightZone.flightArea.properties.points.forEach(([longitude, latitude]) => {
                        result.push({
                            waypoint: {
                                name: flightZone.center.name,
                                estimatedArriveAt: {
                                    min: flightZone.center.estimatedArriveAt.min,
                                    max: estimatedArriveAtMax,
                                },
                                point: {
                                    height: flightZone.flightArea.volume.ceiling,
                                    longitude,
                                    latitude,
                                    altitude: 0,
                                },
                            },
                            parentSection: section,
                        });
                    });
                }
            } else if (section.segment) {
                if (sectionIndex === 0 || route.sections[sectionIndex - 1].flightZone) {
                    result.push({
                        waypoint: section.segment.fromWaypoint,
                        parentSection: section,
                    });
                }

                result.push({
                    waypoint: section.segment.toWaypoint,
                    parentSection: section,
                });
            }

            return result;
        }, []);

        return waypoints;
    }

    protected arePolyline3DGeoJSONProperties(properties: Properties): properties is Polyline3DGeoJSONProperties {
        return properties?.type === MapEntityType.Polyline3D && Array.isArray(properties.bufferWidths) && Array.isArray(properties.points);
    }

    protected trackByEntity(index: number, entity: ViewItineraryEntity) {
        return entity.id;
    }

    protected checkIfIsInletOrOutletPoint(index: number, parent: Polyline3DItineraryEntity): boolean {
        return parent.childEntities
            ? !!Object.values(parent.childEntities).find(
                  (entity) => entity.waypointIndex === index - 1 || entity.waypointIndex === index + 1
              )
            : false;
    }

    protected checkIfIsInletOrParentPoint(index: number, parent: Polyline3DItineraryEntity): boolean {
        return parent.childEntities
            ? !!Object.values(parent.childEntities).find((entity) => entity.waypointIndex === index || entity.waypointIndex === index + 1)
            : false;
    }

    protected updateEntity<T extends ItineraryContentEntity>(entityId: string, update: Partial<T>) {
        const entityToUpdate = this.getItineraryEntityFromViewEntity(entityId);

        const updatedEntity = {
            ...entityToUpdate,
            ...update,
        };

        if (!equal(entityToUpdate, updatedEntity)) {
            this.entityUpdate.emit(updatedEntity);
        }
    }

    private getItineraryEntityFromViewEntity(entityId: string): ItineraryContentEntity {
        const currentContent = this.localStore.selectSnapshotByKey("itineraryContent");
        let updatedEntityIndex = currentContent.findIndex((lookup) => lookup.id === entityId);
        let entityToUpdate: ItineraryContentEntity;

        if (updatedEntityIndex === -1) {
            updatedEntityIndex = currentContent.findIndex(
                (lookup) => lookup.type === MapEntityType.Polyline3D && lookup.childEntities[entityId]
            );
            entityToUpdate = (currentContent[updatedEntityIndex] as Polyline3DItineraryEntity).childEntities[entityId].entity;
        } else {
            entityToUpdate = currentContent[updatedEntityIndex];
        }

        return entityToUpdate;
    }

    protected updateArrayPropertyOfEntity<
        EntityType extends ItineraryContentEntity,
        ArrayPropertyKey extends keyof EntityType,
        ValueType extends ArrayElementType<EntityType[ArrayPropertyKey]>
    >(entity: EntityType, arrayPropertyName: ArrayPropertyKey, index: number, value: ValueType) {
        const clonedArray = [...(entity[arrayPropertyName] as unknown as ValueType[])];
        clonedArray[index] = value;

        this.updateEntity(entity.id, { [arrayPropertyName]: clonedArray });
    }

    protected getEntityObservableLabel(entityId: string, subIndex?: number, type?: "segment" | "point"): Observable<string> {
        const countPolylinesAsMultipleWaypoints = this.localStore.selectSnapshotByKey("countPolylinesAsMultipleWaypoints");

        return this.itineraryContent$.pipe(
            map((content) => {
                let result = 1;
                let entity: MapEntity | undefined;

                for (let index = 0; index < content.length; index++) {
                    entity = content[index];

                    if (entity.id === entityId) {
                        break;
                    }

                    if (entity.type === MapEntityType.Polyline3D && countPolylinesAsMultipleWaypoints) {
                        result += entity.positions.length;
                    } else {
                        result += 1;
                    }
                }

                if (!entity) {
                    return "";
                }

                if (subIndex === undefined) {
                    return entity.type !== MapEntityType.Polyline3D || !countPolylinesAsMultipleWaypoints
                        ? `A${result}`
                        : `A${result} - A${result + entity.positions.length - 1}`;
                }

                if (
                    entity.type === MapEntityType.Polyline3D &&
                    Object.values(entity.childEntities).find((childEntity) => childEntity.waypointIndex === subIndex)
                ) {
                    const lowerIndex = Math.max(0, subIndex - 1);
                    const upperIndex = Math.min(entity.positions.length - 1, subIndex + 1);

                    switch (type) {
                        case "segment":
                            return `A${result + upperIndex} - A${result + upperIndex + 1}`;
                        case "point":
                            return `A${result + subIndex}`;
                        default:
                            return `A${result + lowerIndex} - A${result + upperIndex}`;
                    }
                }

                if (type !== "segment") {
                    return countPolylinesAsMultipleWaypoints ? `A${result + subIndex}` : `${result + subIndex}`;
                }

                return `A${result + subIndex} - A${result + subIndex + 1}`;
            })
        );
    }

    private getRouteWaypointWithSectionForMapEntity(
        content: ItineraryContent,
        entityId: string,
        subIndex?: number
    ): WaypointWithSection | undefined {
        const currentPlanRouteWaypoints = this.localStore.selectSnapshotByKey("currentPlanRouteWaypoints");

        if (this.localStore.selectSnapshotByKey("isProcessing") || !currentPlanRouteWaypoints) {
            return undefined;
        }

        let resultIndex = 0;
        let entity: MapEntity | undefined;

        for (let index = 0; index < content.length; index++) {
            entity = content[index];

            if (entity.id === entityId) {
                break;
            }

            if (entity.type === MapEntityType.Polyline3D) {
                resultIndex += entity.positions.length;
            } else {
                resultIndex += 1;
            }
        }

        if (!entity) {
            return undefined;
        }

        resultIndex += subIndex ?? 0;

        return currentPlanRouteWaypoints[resultIndex];
    }

    private getAmslOffsetForRouteWaypoint(waypoint?: Waypoint) {
        if (!waypoint || waypoint.point.altitude === undefined) {
            return undefined;
        }

        return waypoint.point.altitude - waypoint.point.height;
    }

    private getStopoverMinutesForItineraryContentEntity(
        entity: ItineraryContentEntity,
        childEntity?: ChildEntitySubtype
    ): number | undefined {
        let stopoverSeconds: number | null = null;

        if (entity.type !== MapEntityType.Polyline3D && entity.metadata?.stopover) {
            stopoverSeconds = DateUtils.convertISO8601DurationToSeconds(entity.metadata.stopover);
        } else if (entity.type === MapEntityType.Polyline3D && childEntity && entity.metadata?.stopovers[childEntity.id]) {
            stopoverSeconds = DateUtils.convertISO8601DurationToSeconds(entity.metadata.stopovers[childEntity.id]);
        }

        if (stopoverSeconds === null) {
            return undefined;
        }

        return Math.round(stopoverSeconds / SECONDS_IN_MINUTE);
    }

    private generateEntitiesFromItineraryContent(itineraryContent: ItineraryContent): ViewItineraryEntity[] {
        return itineraryContent.reduce<ViewItineraryEntity[]>((result, entity, index) => {
            const routeWaypointWithSection = this.getRouteWaypointWithSectionForMapEntity(itineraryContent, entity.id);
            const amslOffset = this.getAmslOffsetForRouteWaypoint(routeWaypointWithSection?.waypoint);
            const stopoverMinutes = this.getStopoverMinutesForItineraryContentEntity(entity);

            switch (entity.type) {
                case MapEntityType.Polyline3D: {
                    const corridor: ViewItineraryCorridorEntity = {
                        id: entity.id,
                        contentHash: `${entity.id}-corridor`,
                        type: ViewItineraryEntityType.Corridor,
                        bufferWidths: entity.bufferWidths,
                        itineraryIndex: [entity, -1],
                        label$: this.getEntityObservableLabel(entity.id),
                    };

                    result.push(corridor);

                    entity.positions.forEach((_, positionIndex) => {
                        this.generateChildViewEntitiesForPolyline3D(itineraryContent, positionIndex, entity, result);
                    });

                    break;
                }
                case MapEntityType.Prism: {
                    const label$ = this.getEntityObservableLabel(entity.id);
                    const prism: ViewItineraryPrismEntity = {
                        id: entity.id,
                        contentHash: `${entity.id}-prism-${amslOffset?.toFixed(2)}`,
                        type: ViewItineraryEntityType.Prism,
                        itineraryIndex: [entity, index],
                        topHeight: entity.topHeight ?? 0,
                        amslOffset,
                        bottomHeight: 0,
                        label$,
                        pointLabel$: label$,
                        stopoverMinutes: stopoverMinutes ?? 0,
                        centerCoordinates: entity.center ? MapUtils.convertCartesian3ToSerializableCartographic(entity.center) : undefined,
                    };

                    result.push(prism);

                    break;
                }
                case MapEntityType.Cylinder: {
                    const label$ = this.getEntityObservableLabel(entity.id);
                    const cylinder: ViewItineraryCylinderEntity = {
                        id: entity.id,
                        contentHash: `${entity.id}-cylinder-${amslOffset?.toFixed(2)}`,
                        type: ViewItineraryEntityType.Cylinder,
                        itineraryIndex: [entity, index],
                        radius: entity.radius,
                        topHeight: entity.topHeight ?? 0,
                        amslOffset,
                        bottomHeight: 0,
                        label$,
                        pointLabel$: label$,
                        stopoverMinutes: stopoverMinutes ?? 0,
                        centerCoordinates: MapUtils.convertCartesian3ToSerializableCartographic(entity.center),
                    };

                    result.push(cylinder);

                    break;
                }
            }

            return result;
        }, []);
    }

    private generateChildViewEntitiesForPolyline3D(
        itineraryContent: ItineraryContent,
        positionIndex: number,
        entity: Polyline3DItineraryEntity,
        resultsArray: ViewItineraryEntity[]
    ) {
        const routeWaypoint = this.getRouteWaypointWithSectionForMapEntity(itineraryContent, entity.id, positionIndex);
        const inletRouteWaypoint = this.getRouteWaypointWithSectionForMapEntity(itineraryContent, entity.id, positionIndex - 1);
        const outletRouteWaypoint = this.getRouteWaypointWithSectionForMapEntity(itineraryContent, entity.id, positionIndex + 1);
        const amslOffset = this.getAmslOffsetForRouteWaypoint(routeWaypoint?.waypoint);
        const inletAmslOffset = this.getAmslOffsetForRouteWaypoint(inletRouteWaypoint?.waypoint);
        const outletAmslOffset = this.getAmslOffsetForRouteWaypoint(outletRouteWaypoint?.waypoint);
        const childEntity = this.getChildViewEntity(positionIndex, entity, amslOffset, inletAmslOffset, outletAmslOffset);

        if (childEntity) {
            resultsArray.push(childEntity);
        } else if (!this.checkIfIsInletOrOutletPoint(positionIndex, entity)) {
            const point: ViewItineraryPointEntity = {
                id: `${entity.id}-${positionIndex}`,
                contentHash: `${entity.id}-${positionIndex}-${amslOffset?.toFixed(2)}`,
                type: ViewItineraryEntityType.Point,
                itineraryIndex: [entity, positionIndex],
                label$: this.getEntityObservableLabel(entity.id, positionIndex),
                height: entity.heights[positionIndex] ?? 0,
                amslOffset,
                coordinates: MapUtils.convertCartesian3ToSerializableCartographic(entity.positions[positionIndex]),
                canDelete: entity.positions.length > 2,
            };

            resultsArray.push(point);
        }

        if (
            (!childEntity || childEntity.type !== ViewItineraryEntityType.Landing) &&
            !this.checkIfIsInletOrParentPoint(positionIndex, entity)
        ) {
            const distance = routeWaypoint?.parentSection.segment?.distance;
            const speed = routeWaypoint?.parentSection.segment?.speed?.max;
            const segment: ViewItinerarySegmentEntity = {
                id: `${entity.id}-${positionIndex}-segment`,
                contentHash:
                    `${entity.id}-${positionIndex}-segment-${distance}-` +
                    `${speed}-${amslOffset?.toFixed(2)}-${outletAmslOffset?.toFixed(2)}`,
                type: ViewItineraryEntityType.Segment,
                itineraryIndex: [entity, positionIndex],
                label$: this.getEntityObservableLabel(entity.id, positionIndex, "segment"),
                distance,
                inletPointHeightAmsl: amslOffset ? (entity.heights[positionIndex] ?? 0) + amslOffset : undefined,
                inletPointLabel$: this.getEntityObservableLabel(entity.id, positionIndex),
                outletPointHeightAmsl: outletAmslOffset ? (entity.heights[positionIndex + 1] ?? 0) + outletAmslOffset : undefined,
                outletPointLabel$: this.getEntityObservableLabel(entity.id, positionIndex + 1),
                horizontalBuffer: entity.bufferWidths[positionIndex],
                verticalBuffer: entity.bufferHeights[positionIndex],
                speed,
            };

            resultsArray.push(segment);
        }
    }

    private getChildViewEntity(
        index: number,
        parent: Polyline3DItineraryEntity,
        amslOffset: number | undefined,
        inletAmslOffset: number | undefined,
        outletAmslOffset: number | undefined
    ): ViewItineraryEntity | undefined {
        const constraints = this.localStore.selectSnapshotByKey("constraints");
        const result = parent.childEntities
            ? Object.values(parent.childEntities).find((entity) => entity.waypointIndex === index)?.entity
            : undefined;

        const stopoverMinutes = this.getStopoverMinutesForItineraryContentEntity(parent, result);

        if (!result) {
            return undefined;
        }

        const commonProperties: Omit<ViewItineraryEntityCommon, "type"> = {
            label$: this.getEntityObservableLabel(parent.id, index),
            id: result.id,
            contentHash: `${result.id}-${amslOffset?.toFixed(2)}`,
            itineraryIndex: [parent, index],
        };

        if (result.type === MapEntityType.Prism) {
            const childEntity: ViewItineraryInternalPrismEntity = {
                ...commonProperties,
                type: ViewItineraryEntityType.InternalPrism,
                bottomHeight: result.bottomHeight,
                topHeight: result.topHeight ?? 0,
                amslOffset,
                inletPointHeight: parent.heights[index - 1] ?? 0,
                inletPointAmslOffset: inletAmslOffset,
                outletPointHeight: parent.heights[index + 1] ?? 0,
                outletPointAmslOffset: outletAmslOffset,
                inletPointLabel$: this.getEntityObservableLabel(parent.id, index - 1),
                outletPointLabel$: this.getEntityObservableLabel(parent.id, index + 1),
                inletVerticalBuffer: parent.bufferHeights[index - 1],
                outletVerticalBuffer: parent.bufferHeights[index + 1],
                pointLabel$: this.getEntityObservableLabel(parent.id, index, "point"),
                stopoverMinutes: stopoverMinutes ?? 0,
                centerCoordinates: result.center ? MapUtils.convertCartesian3ToSerializableCartographic(result.center) : undefined,
            };

            return childEntity;
        }

        if (index === 0) {
            const childEntity: ViewItineraryTakeoffEntity = {
                ...commonProperties,
                type: ViewItineraryEntityType.Takeoff,
                radius: result.radius,
                topHeight: result.topHeight ?? 0,
                amslOffset,
                bottomHeight: 0,
                outletPointHeight: parent.heights[index + 1] ?? 0,
                outletPointAmslOffset: outletAmslOffset,
                outletPointLabel$: this.getEntityObservableLabel(parent.id, index + 1),
                outletVerticalBuffer: Math.max(
                    (constraints?.min.runwayVerticalNavigationAccuracy ?? 0) * 2,
                    parent.bufferHeights[index + 1]
                ),
                pointLabel$: this.getEntityObservableLabel(parent.id, index, "point"),
                centerPointHeight: result.centerPointHeight,
                centerCoordinates: MapUtils.convertCartesian3ToSerializableCartographic(result.center),
            };

            return childEntity;
        }

        if (index === parent.positions.length - 1) {
            const childEntity: ViewItineraryLandingEntity = {
                ...commonProperties,
                type: ViewItineraryEntityType.Landing,
                radius: result.radius,
                topHeight: result.topHeight ?? 0,
                amslOffset,
                bottomHeight: 0,
                inletPointHeight: result.inletPointHeight ?? parent.heights[index - 1] ?? 0,
                inletPointAmslOffset: inletAmslOffset,
                inletPointLabel$: this.getEntityObservableLabel(parent.id, index - 1),
                inletVerticalBuffer: Math.max(
                    (constraints?.min.runwayVerticalNavigationAccuracy ?? 0) * 2,
                    parent.bufferHeights[index - 1]
                ),
                pointLabel$: this.getEntityObservableLabel(parent.id, index, "point"),
                centerPointHeight: result.centerPointHeight,
                centerCoordinates: MapUtils.convertCartesian3ToSerializableCartographic(result.center),
            };

            return childEntity;
        }

        const childEntityResult: ViewItineraryInternalCylinderEntity = {
            ...commonProperties,
            type: ViewItineraryEntityType.InternalCylinder,
            radius: result.radius,
            topHeight: result.topHeight ?? 0,
            amslOffset,
            bottomHeight: result.bottomHeight,
            inletPointHeight: parent.heights[index - 1] ?? 0,
            inletPointAmslOffset: inletAmslOffset,
            outletPointHeight: result.outletPointHeight ?? parent.heights[index + 1] ?? 0,
            outletPointAmslOffset: outletAmslOffset,
            inletPointLabel$: this.getEntityObservableLabel(parent.id, index - 1),
            outletPointLabel$: this.getEntityObservableLabel(parent.id, index + 1),
            inletVerticalBuffer: parent.bufferHeights[index - 1],
            outletVerticalBuffer: parent.bufferHeights[index + 1],
            pointLabel$: this.getEntityObservableLabel(parent.id, index, "point"),
            stopoverMinutes: stopoverMinutes ?? 0,
            centerCoordinates: MapUtils.convertCartesian3ToSerializableCartographic(result.center),
        };

        return childEntityResult;
    }

    protected showPointCoordinatesEditForm(entity: ViewItineraryEntity | ViewItineraryShapeEntity) {
        this.localStore.patchState({ entityInEditMode: entity });
    }

    protected updatePointCoordinates(entity: ViewItineraryPointEntity, coordinates: SerializableCartographic | undefined) {
        if (coordinates) {
            this.updateArrayPropertyOfEntity(
                entity.itineraryIndex[0] as Polyline3DItineraryEntity,
                "positions",
                entity.itineraryIndex[1],
                MapUtils.convertSerializableCartographicToCartesian3(coordinates)
            );
        }

        this.localStore.patchState({ entityInEditMode: undefined });
    }

    protected updateShapeCenterCoordinates(entity: ViewItineraryShapeEntity, coordinates: SerializableCartographic | undefined) {
        if (coordinates) {
            this.updateEntity(entity.id, {
                center: MapUtils.convertSerializableCartographicToCartesian3(coordinates),
            });
        }

        this.localStore.patchState({ entityInEditMode: undefined });
    }

    protected updateStopover(entity: ViewItineraryShapeEntity, stopoverMinutes: number) {
        const stopover = DateUtils.convertSecondsToISO8601Duration(stopoverMinutes * SECONDS_IN_MINUTE);

        if (entity.itineraryIndex[0].type === MapEntityType.Polyline3D) {
            const childEntity = Object.values(entity.itineraryIndex[0].childEntities).find(
                (lookup) => lookup.waypointIndex === entity.itineraryIndex[1]
            )?.entity;

            const result: Polyline3DEntityMetadata = {
                type: MapEntityType.Polyline3D,
                entityId: entity.itineraryIndex[0].id,
                stopovers: {
                    ...entity.itineraryIndex[0].metadata?.stopovers,
                },
            };

            if (childEntity) {
                result.stopovers[childEntity.id] = stopover;
            }

            this.entityMetadataUpdate.emit(result);
        } else {
            this.entityMetadataUpdate.emit({
                type: entity.itineraryIndex[0].type,
                entityId: entity.itineraryIndex[0].id,
                stopover,
            });
        }
    }

    protected tryAddInternalEntity(entity: Partial<CustomItineraryEntity>) {
        const dialogRef = this.dialog.open(ItineraryPanelStopoverDialogComponent);

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (
                    data &&
                    (entity.type === MapEntityType.Cylinder || entity.type === MapEntityType.Prism) &&
                    entity.polyline3DEntityId &&
                    entity.waypointIndex !== undefined
                ) {
                    this.entityAdd.emit({
                        type: entity.type,
                        polyline3DEntityId: entity.polyline3DEntityId,
                        waypointIndex: entity.waypointIndex,
                        stopover: data.stopover,
                    });
                }
            });
    }

    protected async tryDeleteEntity(entity: ViewItineraryEntity | ViewItineraryShapeEntity) {
        await this.tryDeleteEntityOrPoint(entity, this.entityDelete);
    }

    protected async tryDeletePoint(entity: ViewItineraryPointEntity) {
        await this.tryDeleteEntityOrPoint(entity, this.pointDelete);
    }

    private async tryDeleteEntityOrPoint<T extends ViewItineraryEntityCommon>(entity: T, eventEmitter: EventEmitter<T["itineraryIndex"]>) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.deleteEntityConfirmText",
                    { entityLabel: await firstValueFrom(entity.label$), entityType: entity.type }
                ),
                declineButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.deleteEntityCancelLabel"
                ),
                confirmButtonLabel: this.translocoService.translate(
                    "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.deleteEntityConfirmLabel"
                ),
                theme: ButtonTheme.Warn,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((isConfirmed) => {
                if (!isConfirmed) {
                    return;
                }

                eventEmitter.emit(entity.itineraryIndex);
            });
    }

    protected ensureIsShapeEntity(entity: ViewItineraryEntity): ViewItineraryShapeEntity | undefined {
        if (entity.itineraryIndex[0]) {
            return entity as ViewItineraryShapeEntity;
        }

        return;
    }

    protected getCorridorPointEntities(
        entities: ViewItineraryEntity[],
        corridorEntity: ViewItineraryCorridorEntity
    ): ViewItineraryPointEntity[] {
        const noSegmentEntities = entities.filter((entity) => entity.type !== ViewItineraryEntityType.Segment);
        const corridorEntityIndex = noSegmentEntities.findIndex((entity) => entity === corridorEntity);

        if (corridorEntityIndex === -1) {
            return [];
        }

        const lastCorridorPointEntityIndex = noSegmentEntities.findIndex((entity, index) => {
            if (index <= corridorEntityIndex) {
                return false;
            }

            return entity.type === ViewItineraryEntityType.Point && noSegmentEntities[index + 1]?.type !== ViewItineraryEntityType.Point;
        });
        const sliceEnd = lastCorridorPointEntityIndex === -1 ? undefined : lastCorridorPointEntityIndex + 1;

        return noSegmentEntities.slice(corridorEntityIndex + 1, sliceEnd) as ViewItineraryPointEntity[];
    }
}
