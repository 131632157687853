import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { OperatorContextActions } from "../../shared/operator-context/state/operator-context.actions";
import {
    Operator,
    OperatorAttorneyPowerCapabilities,
    OperatorConfirmationDocumentType,
    OperatorProfileError,
} from "../models/operator.models";
import { OperatorProfileApiService } from "../services/operator-profile.api.service";
import { OperatorProfileActions } from "./operator-profile.actions";

interface OperatorProfileStateModel {
    operator: Operator | undefined;
    operatorAvatarBase64: string | undefined;
    operatorProfileError: OperatorProfileError | undefined;
    editOperatorAvatarError: OperatorProfileError | undefined;
    editOperatorAddressError: OperatorProfileError | undefined;
    editOperatorInsurancePolicyError: OperatorProfileError | undefined;
    operatorDiploma: Blob | undefined;
    currentUserContextId: string | undefined;
    isOperatorProfileProcessing: boolean;
    isAvatarProcessing: boolean;
    isAttorneyPowerCapabilitiesProcessing: boolean;
    attorneyPowerCapabilities: OperatorAttorneyPowerCapabilities | undefined;
    attorneyPowerCapabilitiesError: OperatorProfileError | undefined;
    downloadAttorneyTemplateError: OperatorProfileError | undefined;
    isAttorneyPowerProcessing: boolean;
    addAttorneyPowerError: OperatorProfileError | undefined;
    removeAttorneyPowerError: OperatorProfileError | undefined;
    downloadOperatorRegistrationConfirmationError: OperatorProfileError | undefined;
}

const defaultState: OperatorProfileStateModel = {
    operator: undefined,
    operatorAvatarBase64: undefined,
    operatorProfileError: undefined,
    editOperatorInsurancePolicyError: undefined,
    editOperatorAvatarError: undefined,
    editOperatorAddressError: undefined,
    operatorDiploma: undefined,
    currentUserContextId: undefined,
    isOperatorProfileProcessing: false,
    isAvatarProcessing: false,
    isAttorneyPowerCapabilitiesProcessing: false,
    attorneyPowerCapabilities: undefined,
    attorneyPowerCapabilitiesError: undefined,
    downloadAttorneyTemplateError: undefined,
    isAttorneyPowerProcessing: false,
    addAttorneyPowerError: undefined,
    removeAttorneyPowerError: undefined,
    downloadOperatorRegistrationConfirmationError: undefined,
};

@State<OperatorProfileStateModel>({
    name: "operatorProfile",
    defaults: defaultState,
})
@Injectable()
export class OperatorProfileState {
    constructor(private readonly operatorProfileApi: OperatorProfileApiService) {}

    @Selector()
    public static operator(state: OperatorProfileStateModel): Operator | undefined {
        return state.operator;
    }

    @Selector()
    public static operatorProfileError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.operatorProfileError;
    }

    @Selector()
    public static editOperatorAddressError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.editOperatorAddressError;
    }

    @Selector()
    public static editOperatorInsurancePolicyError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.editOperatorInsurancePolicyError;
    }

    @Selector()
    public static editOperatorAvatarError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.editOperatorAvatarError;
    }

    @Selector()
    public static operatorDiploma(state: OperatorProfileStateModel): Blob | undefined {
        return state.operatorDiploma;
    }

    @Selector()
    public static operatorAvatarBase64(state: OperatorProfileStateModel): string | undefined {
        return state.operatorAvatarBase64;
    }

    @Selector()
    public static isOperatorProfileProcessing(state: OperatorProfileStateModel): boolean {
        return state.isOperatorProfileProcessing;
    }

    @Selector()
    public static isAvatarProcessing(state: OperatorProfileStateModel): boolean {
        return state.isAvatarProcessing;
    }

    @Selector()
    public static isAttorneyPowerCapabilitiesProcessing(state: OperatorProfileStateModel): boolean | undefined {
        return state.isAttorneyPowerCapabilitiesProcessing;
    }

    @Selector()
    public static attorneyPowerCapabilities(state: OperatorProfileStateModel): OperatorAttorneyPowerCapabilities | undefined {
        return state.attorneyPowerCapabilities;
    }

    @Selector()
    public static attorneyPowerCapabilitiesError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.attorneyPowerCapabilitiesError;
    }

    @Selector()
    public static downloadAttorneyTemplateError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.downloadAttorneyTemplateError;
    }

    @Selector()
    public static isAttorneyPowerProcessing(state: OperatorProfileStateModel): boolean {
        return state.isAttorneyPowerProcessing;
    }

    @Selector()
    public static addAttorneyPowerError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.addAttorneyPowerError;
    }

    @Selector()
    public static downloadOperatorRegistrationConfirmationError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.downloadOperatorRegistrationConfirmationError;
    }

    @Selector()
    public static removeAttorneyPowerError(state: OperatorProfileStateModel): OperatorProfileError | undefined {
        return state.removeAttorneyPowerError;
    }

    @Action(OperatorProfileActions.GetOperatorProfile)
    public getOperatorProfile(context: StateContext<OperatorProfileStateModel>, action: OperatorProfileActions.GetOperatorProfile) {
        context.patchState({ isOperatorProfileProcessing: true, operatorProfileError: undefined });

        return this.operatorProfileApi.getOperatorProfile(action.operatorId).pipe(
            tap((operator: Operator) => {
                context.patchState({
                    operator,
                    currentUserContextId: action.operatorId,
                    isOperatorProfileProcessing: false,
                });
            }),
            catchError((error: OperatorProfileError) => {
                context.patchState({ operatorProfileError: error, isOperatorProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.EditOperatorCompanyInfo)
    public editOperatorCompanyInfo(
        context: StateContext<OperatorProfileStateModel>,
        action: OperatorProfileActions.EditOperatorCompanyInfo
    ) {
        context.patchState({ editOperatorAddressError: undefined, isOperatorProfileProcessing: true });
        const operator = context.getState().operator;

        if (!operator?.id) {
            return;
        }

        return this.operatorProfileApi.editOperatorCompanyInfo(operator?.id, action.companyInfo).pipe(
            tap(() => {
                context.patchState({
                    operator: {
                        ...operator,
                        ...action.companyInfo,
                    },
                });

                context.dispatch(new OperatorContextActions.GetGlobalCapabilities());
            }),
            catchError((editOperatorAddressError) => {
                context.patchState({ editOperatorAddressError, isOperatorProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.GetOperatorAvatar)
    public getOperatorAvatar(context: StateContext<OperatorProfileStateModel>, action: OperatorProfileActions.GetOperatorAvatar) {
        context.patchState({ isAvatarProcessing: true });

        return this.operatorProfileApi.getOperatorAvatar(action.operatorId).pipe(
            tap((operatorAvatarBase64: string) => context.patchState({ operatorAvatarBase64, isAvatarProcessing: false })),
            catchError(() => {
                context.patchState({ operatorAvatarBase64: undefined, isAvatarProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.SaveOperatorAvatar)
    public saveOperatorAvatar(context: StateContext<OperatorProfileStateModel>, action: OperatorProfileActions.SaveOperatorAvatar) {
        context.patchState({ editOperatorAvatarError: undefined, isOperatorProfileProcessing: true });
        const operatorId = context.getState().operator?.id;

        if (!operatorId) {
            return;
        }

        return this.operatorProfileApi.requestAvatarChange(operatorId, action.base64Image).pipe(
            tap(() =>
                context.dispatch([
                    new OperatorProfileActions.GetOperatorAvatar(operatorId),
                    new OperatorContextActions.GetGlobalCapabilities(),
                ])
            ),
            catchError((error) => {
                context.patchState({ editOperatorAvatarError: error, isOperatorProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.EditOperatorInsurancePolicy)
    public editOperatorInsurancePolicy(
        context: StateContext<OperatorProfileStateModel>,
        action: OperatorProfileActions.EditOperatorInsurancePolicy
    ) {
        context.patchState({ editOperatorInsurancePolicyError: undefined, isOperatorProfileProcessing: true });
        const operator = context.getState().operator;

        if (!operator?.id) {
            return;
        }

        return this.operatorProfileApi.editOperatorInsurancePolicy(operator.id, action.policy).pipe(
            tap(() => {
                context.patchState({
                    operator: {
                        ...operator,
                        insurancePolicyNumber: action.policy.number,
                        insurancePolicyExpirationDate: action.policy.expirationDate,
                    },
                    isOperatorProfileProcessing: false,
                });
            }),
            catchError((editOperatorInsurancePolicyError) => {
                context.patchState({ editOperatorInsurancePolicyError, isOperatorProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.DeleteInsurancePolicy)
    public deleteInsurancePolicy(context: StateContext<OperatorProfileStateModel>) {
        context.patchState({ editOperatorInsurancePolicyError: undefined, isOperatorProfileProcessing: true });
        const operator = context.getState().operator;

        if (!operator?.id) {
            return;
        }

        return this.operatorProfileApi.deleteOperatorInsurancePolicy(operator.id).pipe(
            tap(() => {
                const newOperator = {
                    ...operator,
                    insurancePolicyNumber: undefined,
                    insurancePolicyExpirationDate: undefined,
                };
                context.patchState({
                    operator: newOperator,
                    isOperatorProfileProcessing: false,
                });
            }),
            catchError((editOperatorInsurancePolicyError) => {
                context.patchState({ editOperatorInsurancePolicyError, isOperatorProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.DeleteAvatar)
    public deleteOperatorLogo(context: StateContext<OperatorProfileStateModel>) {
        context.patchState({ editOperatorAvatarError: undefined, isOperatorProfileProcessing: true });
        const operator = context.getState().operator;

        if (!operator?.id) {
            return;
        }

        return this.operatorProfileApi.deleteOperatorAvatar(operator.id).pipe(
            tap(() => {
                context.dispatch(new OperatorContextActions.GetGlobalCapabilities());
                context.patchState({ operatorAvatarBase64: undefined });
            }),
            catchError((editOperatorAvatarError) => {
                context.patchState({ editOperatorAvatarError, isOperatorProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.ClearOperatorState)
    public clearOperatorState(context: StateContext<OperatorProfileStateModel>) {
        context.patchState({ ...defaultState });
    }

    @Action(OperatorProfileActions.DownloadOperatorRegistrationConfirmation)
    public downloadOperatorRegistrationConfirmation(
        context: StateContext<OperatorProfileStateModel>,
        action: OperatorProfileActions.DownloadOperatorRegistrationConfirmation
    ) {
        context.patchState({ downloadOperatorRegistrationConfirmationError: undefined });

        const operatorId = context.getState().operator?.id;

        if (!operatorId) {
            return;
        }

        return this.operatorProfileApi
            .downloadOperatorConfirmationDocument(operatorId, action.fileName, OperatorConfirmationDocumentType.RegistrationConfirmation)
            .pipe(
                catchError((downloadOperatorRegistrationConfirmationError) => {
                    context.patchState({ downloadOperatorRegistrationConfirmationError });

                    return EMPTY;
                })
            );
    }

    @Action(OperatorProfileActions.GetAttorneyPowerCapabilities)
    public getAttorneyPowerCapabilities(context: StateContext<OperatorProfileStateModel>) {
        context.patchState({ attorneyPowerCapabilitiesError: undefined, isAttorneyPowerCapabilitiesProcessing: true });
        const operatorId = context.getState().operator?.id;

        if (!operatorId) {
            return;
        }

        return this.operatorProfileApi.getAttorneyPowerCapabilities(operatorId).pipe(
            tap((capabilities) => context.patchState({ attorneyPowerCapabilities: capabilities })),
            catchError((attorneyPowerCapabilitiesError) => {
                context.patchState({ attorneyPowerCapabilitiesError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAttorneyPowerCapabilitiesProcessing: false }))
        );
    }

    @Action(OperatorProfileActions.DownloadAttorneyTemplate)
    public downloadAttorneyTemplate(
        context: StateContext<OperatorProfileStateModel>,
        action: OperatorProfileActions.DownloadAttorneyTemplate
    ) {
        context.patchState({ downloadAttorneyTemplateError: undefined });

        return this.operatorProfileApi.downloadAttorneyTemplate().pipe(
            catchError((error) => {
                context.patchState({
                    downloadAttorneyTemplateError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorProfileActions.AddAttorneyPower)
    public addAttorneyPower(context: StateContext<OperatorProfileStateModel>, action: OperatorProfileActions.AddAttorneyPower) {
        const operatorId = context.getState().operator?.id;

        if (!operatorId) {
            return;
        }

        context.patchState({ addAttorneyPowerError: undefined });

        return this.operatorProfileApi.addAttorneyPower(operatorId, action.value).pipe(
            catchError((error) => {
                context.patchState({
                    addAttorneyPowerError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAttorneyPowerProcessing: false }))
        );
    }

    @Action(OperatorProfileActions.RemoveAttorneyPower)
    public removeAttorneyPower(context: StateContext<OperatorProfileStateModel>, action: OperatorProfileActions.RemoveAttorneyPower) {
        const operatorId = context.getState().operator?.id;

        if (!operatorId) {
            return;
        }

        context.patchState({ removeAttorneyPowerError: undefined, isAttorneyPowerProcessing: true });

        return this.operatorProfileApi.removeAttorneyPower(operatorId, action.attorneyPowerId).pipe(
            catchError((error) => {
                context.patchState({
                    removeAttorneyPowerError: error,
                });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isAttorneyPowerProcessing: false }))
        );
    }
}
