import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { MissionErrorType } from "../../../models/mission.model";
import { MissionState } from "../../../state/mission.state";
import { MISSION_WIZARD_ID, MissionWizardSteps } from "../content/mission-wizard-content.component";

@Component({
    selector: "dtm-web-app-lib-mission-wizard-header",
    templateUrl: "./mission-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissionWizardHeaderComponent {
    public readonly wizardId = MISSION_WIZARD_ID;
    public readonly MissionWizardSteps = MissionWizardSteps;

    public readonly shouldShowWizardHeader$ = this.store
        .select(MissionState.missionError)
        .pipe(map((error) => error?.type !== MissionErrorType.CannotGetCapabilities));

    public shouldShowRouteSelector$ = this.store
        .select(MissionState.selectedEditorType)
        .pipe(map((editorType) => editorType === ItineraryEditorType.Assisted));

    constructor(private readonly store: Store) {}
}
