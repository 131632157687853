import { HttpParams } from "@angular/common/http";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { ExamResult, ExamResultListWithPages } from "../models/exam-results.models";
import { TrainingExamResultsFilterFormKeys, TrainingExamResultsFilterParams } from "../models/training-exam-results.models";
import { TrainingResult, TrainingResultListWithPages } from "../models/training-results.models";

export type GetExamResultsListResponseBody = PageResponseBody<ExamResult>;

export function convertGetExamResultListResponseBodyToExamResultListWithPages(
    response: GetExamResultsListResponseBody
): ExamResultListWithPages {
    return {
        content: response.content,
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export type GetTrainingResultsListResponseBody = PageResponseBody<TrainingResult>;

export function convertGetTrainingResultListResponseBodyToTrainingResultListWithPages(
    response: GetTrainingResultsListResponseBody
): TrainingResultListWithPages {
    return {
        content: response.content,
        totalElements: response.totalElements,
        pageNumber: response.number,
        pageSize: response.size,
    };
}

export function getTrainingExamResultsParams(filters: TrainingExamResultsFilterParams): HttpParams {
    let params = new HttpParams().set("page", `${filters?.page ?? 0}`);

    if (filters.size) {
        params = params.append("size", filters.size);
    }

    const searchPhrase = filters[TrainingExamResultsFilterFormKeys.SearchPhrase];

    if (searchPhrase) {
        params = params.append(TrainingExamResultsFilterFormKeys.SearchPhrase, searchPhrase);
    }

    return params;
}
