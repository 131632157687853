import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FILES_UPLOAD_API_PROVIDER, SerialNumbersControlComponent, UavType } from "@dtm-frontend/shared/ui";
import { UavClass } from "../../../../../../shared";
import { CustomUavImageUploadApiService } from "../../../../../services/custom-uav-image-upload-api.service";
import { CustomUavForm } from "../basic-info-step.component";

@Component({
    selector: "dtm-web-app-lib-new-uav-wizard-basic-info-step-custom-uav[formGroup]",
    templateUrl: "./custom-uav.component.html",
    styleUrls: ["./custom-uav.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: FILES_UPLOAD_API_PROVIDER,
            useClass: CustomUavImageUploadApiService,
        },
    ],
})
export class NewUavWizardBasicInfoStepCustomUavComponent {
    @Input("formGroup") public customUavForm!: FormGroup<CustomUavForm>;
    @Output() public readonly isImageEditMode = new EventEmitter<boolean>();

    @ViewChild(SerialNumbersControlComponent) private readonly serialNumbersControl!: SerialNumbersControlComponent;

    protected readonly uavTypes: UavType[] = Object.values(UavType);
    protected readonly uavClasses: UavClass[] = Object.values(UavClass);

    public focusOnSerialNumbersControl() {
        this.serialNumbersControl.focus();
    }

    protected setImageEditMode(isEditMode: boolean): void {
        this.isImageEditMode.emit(isEditMode);
    }
}
