import { HttpClient, HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { PILOT_PROFILE_ENDPOINTS, PilotProfileEndpoints } from "../pilot-profile.tokens";

@Injectable()
export class ForeignCompetenciesUploadApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(PILOT_PROFILE_ENDPOINTS) private readonly endpoints: PilotProfileEndpoints,
        private readonly store: Store
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        const pilotId = this.store.selectSnapshot(OperatorContextState.pilot)?.id;
        if (!pilotId) {
            return EMPTY;
        }

        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(
                StringUtils.replaceInTemplate(this.endpoints.uploadForeignCompetency, {
                    pilotId,
                }),
                formData,
                {
                    reportProgress: true,
                    responseType: "json",
                    observe: "events",
                }
            )
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }

    public getFile(fileId: string) {
        const pilotId = this.store.selectSnapshot(OperatorContextState.pilot)?.id;
        if (!pilotId) {
            return EMPTY;
        }

        return this.httpClient.get(
            StringUtils.replaceInTemplate(this.endpoints.downloadForeignCompetency, {
                fileId,
                pilotId,
            }),
            {
                responseType: "blob",
            }
        );
    }

    public getFilesCompressed(fileIds: string[], fileName: string) {
        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }
}
