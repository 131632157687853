import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgxsModule } from "@ngxs/store";
import { FlightTrackingApiService } from "./services/flight-tracking-api.service";
import { FlightTrackingState } from "./state/flight-tracking.state";

@NgModule({
    declarations: [],
    imports: [NgxsModule.forFeature([FlightTrackingState])],
})
export class FlightTrackingModule {
    public static forRoot(): ModuleWithProviders<FlightTrackingModule> {
        return {
            ngModule: FlightTrackingModule,
            providers: [
                {
                    provide: FlightTrackingApiService,
                    useClass: FlightTrackingApiService,
                },
            ],
        };
    }
    public static forTest(): ModuleWithProviders<FlightTrackingModule> {
        return {
            ngModule: FlightTrackingModule,
            providers: [
                {
                    provide: FlightTrackingApiService,
                    useValue: null,
                },
            ],
        };
    }
}
