import { Injectable } from "@angular/core";
import { AssociationPermit, Competency, DtmLocations, Page, Permit } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, finalize } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { OperatorPermitsApiService } from "../services/operator-permits.api.service";
import {
    OperatorPermitsError,
    OperatorPermitsErrorType,
    OwnedSpecificPermitListItem,
    SpecificPermitDraftsListItem,
} from "../services/operator-permits.models";
import { getPermitWithCapabilities } from "../services/operator-permits.utils";
import { OperatorPermitsActions } from "./operator-permits.actions";

export interface OperatorPermitsStateModel {
    ownedSpecificPermitsListError: OperatorPermitsError | undefined;
    ownedSpecificPermitsList: OwnedSpecificPermitListItem[] | undefined;
    ownedSpecificPermitsListPages: Page | undefined;
    isOwnedSpecificPermitsListProcessing: boolean;
    isNameChangeProcessing: boolean;
    updateNameError: OperatorPermitsError | undefined;
    specificPermitDraftsListError: OperatorPermitsError | undefined;
    specificPermitDraftsList: SpecificPermitDraftsListItem[] | undefined;
    specificPermitDraftsListPages: Page | undefined;
    isSpecificPermitDraftsListProcessing: boolean;
    permit: Permit | undefined;
    dtmLocations: DtmLocations[] | undefined;
    competencies: Competency[] | undefined;
    permitError: OperatorPermitsError | undefined;
    isProcessing: boolean;
    associationPermit: AssociationPermit | undefined;
    kmlFileForPreviewError: OperatorPermitsError | undefined;
    kmlFileForPreview: Blob | undefined;
    draftRemoveError: OperatorPermitsError | undefined;
}

const defaultState: OperatorPermitsStateModel = {
    ownedSpecificPermitsListError: undefined,
    ownedSpecificPermitsList: undefined,
    ownedSpecificPermitsListPages: undefined,
    isOwnedSpecificPermitsListProcessing: false,
    isNameChangeProcessing: false,
    updateNameError: undefined,
    specificPermitDraftsListError: undefined,
    specificPermitDraftsList: undefined,
    specificPermitDraftsListPages: undefined,
    isSpecificPermitDraftsListProcessing: false,
    permit: undefined,
    dtmLocations: undefined,
    competencies: undefined,
    permitError: undefined,
    isProcessing: false,
    associationPermit: undefined,
    kmlFileForPreviewError: undefined,
    kmlFileForPreview: undefined,
    draftRemoveError: undefined,
};

@State<OperatorPermitsStateModel>({
    name: "operatorPermits",
    defaults: defaultState,
})
@Injectable()
export class OperatorPermitsState {
    @Selector()
    public static ownedSpecificPermitsListError(state: OperatorPermitsStateModel): OperatorPermitsError | undefined {
        return state.ownedSpecificPermitsListError;
    }

    @Selector()
    public static ownedSpecificPermitsList(state: OperatorPermitsStateModel): OwnedSpecificPermitListItem[] | undefined {
        return state.ownedSpecificPermitsList;
    }

    @Selector()
    public static ownedSpecificPermitsListPages(state: OperatorPermitsStateModel): Page | undefined {
        return state.ownedSpecificPermitsListPages;
    }

    @Selector()
    public static isOwnedSpecificPermitsListProcessing(state: OperatorPermitsStateModel): boolean {
        return state.isOwnedSpecificPermitsListProcessing;
    }

    @Selector()
    public static specificPermitDraftsListError(state: OperatorPermitsStateModel): OperatorPermitsError | undefined {
        return state.specificPermitDraftsListError;
    }

    @Selector()
    public static updateNameError(state: OperatorPermitsStateModel): OperatorPermitsError | undefined {
        return state.updateNameError;
    }

    @Selector()
    public static specificPermitDraftsList(state: OperatorPermitsStateModel): SpecificPermitDraftsListItem[] | undefined {
        return state.specificPermitDraftsList;
    }

    @Selector()
    public static specificPermitDraftsListPages(state: OperatorPermitsStateModel): Page | undefined {
        return state.specificPermitDraftsListPages;
    }

    @Selector()
    public static isSpecificPermitDraftsListProcessing(state: OperatorPermitsStateModel): boolean {
        return state.isSpecificPermitDraftsListProcessing;
    }

    @Selector()
    public static isNameChangeProcessing(state: OperatorPermitsStateModel): boolean {
        return state.isNameChangeProcessing;
    }

    @Selector()
    public static permit(state: OperatorPermitsStateModel): Permit | undefined {
        return state.permit;
    }

    @Selector()
    public static permitError(state: OperatorPermitsStateModel): OperatorPermitsError | undefined {
        return state.permitError;
    }

    @Selector()
    public static associationPermit(state: OperatorPermitsStateModel): AssociationPermit | undefined {
        return state.associationPermit;
    }

    @Selector()
    public static isProcessing(state: OperatorPermitsStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static kmlFileForPreviewError(state: OperatorPermitsStateModel): OperatorPermitsError | undefined {
        return state.kmlFileForPreviewError;
    }

    @Selector()
    public static kmlFileForPreview(state: OperatorPermitsStateModel): Blob | undefined {
        return state.kmlFileForPreview;
    }

    @Selector()
    public static draftRemoveError(state: OperatorPermitsStateModel): OperatorPermitsError | undefined {
        return state.draftRemoveError;
    }

    constructor(private readonly operatorPermitsApi: OperatorPermitsApiService, private readonly store: Store) {
        if (operatorPermitsApi === undefined) {
            throw new Error("Initialize OperatorPermitsModule with .forRoot()");
        }
    }

    @Action(OperatorPermitsActions.GetOwnedSpecificPermitList, { cancelUncompleted: true })
    public getOwnedSpecificPermitsList(
        context: StateContext<OperatorPermitsStateModel>,
        action: OperatorPermitsActions.GetOwnedSpecificPermitList
    ) {
        context.patchState({
            isOwnedSpecificPermitsListProcessing: true,
            ownedSpecificPermitsListPages: undefined,
        });

        return this.operatorPermitsApi.getSpecificPermitsList(action.params).pipe(
            tap((result) =>
                context.patchState({
                    ownedSpecificPermitsList: result.content,
                    ownedSpecificPermitsListPages: {
                        totalElements: result.totalElements,
                        pageSize: result.pageSize,
                        pageNumber: result.pageNumber,
                    },
                    ownedSpecificPermitsListError: undefined,
                    isOwnedSpecificPermitsListProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({
                    ownedSpecificPermitsListError: error,
                    isOwnedSpecificPermitsListProcessing: false,
                    ownedSpecificPermitsList: undefined,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorPermitsActions.GetSpecificPermitDraftsList, { cancelUncompleted: true })
    public getSpecificPermitsDraftsList(
        context: StateContext<OperatorPermitsStateModel>,
        action: OperatorPermitsActions.GetSpecificPermitDraftsList
    ) {
        context.patchState({
            isSpecificPermitDraftsListProcessing: true,
            specificPermitDraftsListPages: undefined,
        });

        return this.operatorPermitsApi.getSpecificPermitsDraftList(action.params).pipe(
            tap((result) =>
                context.patchState({
                    specificPermitDraftsList: result.content,
                    specificPermitDraftsListPages: {
                        totalElements: result.totalElements,
                        pageSize: result.pageSize,
                        pageNumber: result.pageNumber,
                    },
                    specificPermitDraftsListError: undefined,
                    isSpecificPermitDraftsListProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({
                    specificPermitDraftsList: undefined,
                    specificPermitDraftsListError: error,
                    isSpecificPermitDraftsListProcessing: false,
                });

                return EMPTY;
            })
        );
    }

    @Action(OperatorPermitsActions.GetOperatorPermitsCapabilities)
    public getOperatorPermitsCapabilities(
        context: StateContext<OperatorPermitsStateModel>,
        action: OperatorPermitsActions.GetOperatorPermitsCapabilities
    ) {
        const { dtmLocations } = context.getState();
        context.patchState({ permitError: undefined });

        return (
            !dtmLocations &&
            this.operatorPermitsApi.getOperatorPermitsCapabilities(action.operatorId).pipe(
                tap((capabilities) => {
                    context.patchState({
                        dtmLocations: capabilities.dtmLocations,
                        competencies: capabilities.competencies,
                    });
                }),
                catchError((error) => {
                    context.patchState({ permitError: error });

                    return EMPTY;
                })
            )
        );
    }

    @Action(OperatorPermitsActions.GetOperatorPermitDetails)
    public getOperatorPermitDetails(
        context: StateContext<OperatorPermitsStateModel>,
        action: OperatorPermitsActions.GetOperatorPermitDetails
    ) {
        const { dtmLocations, competencies } = context.getState();
        context.patchState({ isProcessing: true, permitError: undefined });

        if (!dtmLocations || !competencies) {
            context.patchState({ permitError: { type: OperatorPermitsErrorType.CannotGetCapabilities }, isProcessing: false });

            return;
        }

        return this.operatorPermitsApi.getSpecificPermitDetails(action.permitId, action.operatorId).pipe(
            tap((response) => {
                context.patchState({ permit: getPermitWithCapabilities(response, competencies, dtmLocations) });
            }),
            catchError((error) => {
                context.patchState({ permitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorPermitsActions.GetCrossBorderPermitDetails)
    public getCrossBorderPermitDetails(
        context: StateContext<OperatorPermitsStateModel>,
        action: OperatorPermitsActions.GetCrossBorderPermitDetails
    ) {
        const { dtmLocations, competencies } = context.getState();
        context.patchState({ isProcessing: true, permitError: undefined });

        if (!dtmLocations || !competencies) {
            context.patchState({ permitError: { type: OperatorPermitsErrorType.CannotGetCapabilities }, isProcessing: false });

            return;
        }

        return this.operatorPermitsApi.getCrossBorderPermitDetails(action.permitId, action.operatorId).pipe(
            tap((response) => {
                context.patchState({ permit: getPermitWithCapabilities(response, competencies, dtmLocations) });
            }),
            catchError((error) => {
                context.patchState({ permitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorPermitsActions.GetAssociationPermitDetails)
    public getAssociationPermitDetails(
        context: StateContext<OperatorPermitsStateModel>,
        action: OperatorPermitsActions.GetAssociationPermitDetails
    ) {
        context.patchState({ isProcessing: true, permitError: undefined });

        return this.operatorPermitsApi.getAssociationPermitDetails(action.permitId, action.operatorId).pipe(
            tap((response) => {
                context.patchState({ associationPermit: response });
            }),
            catchError((error) => {
                context.patchState({ permitError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorPermitsActions.UpdatePermitName)
    public updatePermitName(context: StateContext<OperatorPermitsStateModel>, action: OperatorPermitsActions.UpdatePermitName) {
        context.patchState({ isNameChangeProcessing: true, updateNameError: undefined });

        return this.operatorPermitsApi.updatePermitName(action.permit, action.name).pipe(
            catchError((error) => {
                context.patchState({ updateNameError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isNameChangeProcessing: false }))
        );
    }

    @Action(OperatorPermitsActions.GetKmlPreview)
    public getKmlPreview(context: StateContext<OperatorPermitsStateModel>, action: OperatorPermitsActions.GetKmlPreview) {
        context.patchState({ isProcessing: true, kmlFileForPreviewError: undefined });
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ kmlFileForPreviewError: { type: OperatorPermitsErrorType.CannotGetKmlFile } });

            return EMPTY;
        }

        return this.operatorPermitsApi.getKmlFile(action.file, operatorId).pipe(
            tap((kmlFileForPreview) => context.patchState({ kmlFileForPreview })),
            catchError((error) => {
                context.patchState({ kmlFileForPreviewError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(OperatorPermitsActions.RemoveDraft)
    public removeDraft(context: StateContext<OperatorPermitsStateModel>, action: OperatorPermitsActions.RemoveDraft) {
        context.patchState({ isProcessing: true, draftRemoveError: undefined });
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            context.patchState({ draftRemoveError: { type: OperatorPermitsErrorType.CannotRemoveDraft } });

            return EMPTY;
        }

        return this.operatorPermitsApi.removeDraft(action.draftId, operatorId).pipe(
            catchError((error) => {
                context.patchState({ draftRemoveError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
