import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";

export const OPEN_ALERT_IMAGE_DIALOG_ACTION = "openAlertImageDialog";

@Component({
    templateUrl: "height-violation-alert-toast.component.html",
    styleUrls: ["height-violation-alert-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeightViolationAlertToastComponent extends Toast {
    constructor(protected readonly toastrService: ToastrService, public readonly toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }

    protected openAlertImageDialog(): void {
        this.toastPackage.triggerAction(OPEN_ALERT_IMAGE_DIALOG_ACTION);
    }
}
