import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { combineLatestWith, map } from "rxjs/operators";
import { NavigationAccuracy, NavigationAccuracyClass, NavigationAccuracyType } from "../../../services/uav.models";

interface NavigationAccuracyClassDescriptionComponentState {
    classValue: NavigationAccuracyClass | undefined;
    type: NavigationAccuracyType | undefined;
    items: NavigationAccuracy[];
}

@Component({
    selector: "dtm-web-app-lib-navigation-accuracy-class-description[items][classValue][type]",
    templateUrl: "./navigation-accuracy-class-description.component.html",
    styles: [":host { display: block; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class NavigationAccuracyClassDescriptionComponent {
    protected readonly NavigationAccuracyType = NavigationAccuracyType;

    @Input() public set classValue(value: NavigationAccuracyClass) {
        this.localStore.patchState({ classValue: value });
    }

    @Input() public set type(value: NavigationAccuracyType) {
        this.localStore.patchState({ type: value });
    }

    @Input() public set items(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ items: value ?? [] });
    }

    protected readonly distanceValues$ = this.localStore.selectByKey("classValue").pipe(
        RxjsUtils.filterFalsy(),
        combineLatestWith(this.localStore.selectByKey("type").pipe(RxjsUtils.filterFalsy())),
        map(([classValue, type]) => this.getDistanceValues(classValue, type))
    );
    protected readonly type$ = this.localStore.selectByKey("type").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<NavigationAccuracyClassDescriptionComponentState>) {
        this.localStore.setState({
            classValue: undefined,
            type: undefined,
            items: [],
        });
    }

    private getDistanceValues(classValue: NavigationAccuracyClass, type: NavigationAccuracyType): { horizontal: string; vertical: string } {
        const navigationAccuracy = this.localStore
            .selectSnapshotByKey("items")
            .find((item) => item.classValue === classValue && item.type === type);

        return {
            horizontal: navigationAccuracy?.horizontal ?? "?",
            vertical: navigationAccuracy?.vertical ?? "?",
        };
    }
}
