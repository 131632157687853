import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DEFAULT_LANG, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { FormType, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { distinctUntilChanged, map } from "rxjs/operators";
import { CrewMember, CrewTrainings, StaffTrainings, Training } from "../../../services/specific-permit-application.models";

interface CrewTrainingsFormComponentState {
    availableTrainings: Training[];
    activeLanguage: LanguageCode;
}

interface CrewMemberTrainingsFormValues {
    role: CrewMember;
    areTrainingsSet: boolean;
    trainings: StaffTrainings | null;
}

type CrewMemberTrainingsForm = FormGroup<FormType<CrewMemberTrainingsFormValues>>;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-crew-trainings-form[availableTrainings]",
    templateUrl: "./crew-trainings-form.component.html",
    styleUrls: ["./crew-trainings-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CrewTrainingsFormComponent),
            multi: true,
        },
    ],
})
export class CrewTrainingsFormComponent implements ControlValueAccessor {
    @Input() public set availableTrainings(value: Training[] | undefined) {
        this.localStore.patchState({ availableTrainings: value ?? [] });
    }

    @Input() public set activeLanguage(value: LanguageCode | undefined) {
        this.localStore.patchState({ activeLanguage: value ?? DEFAULT_LANG });
    }

    protected readonly availableTrainings$ = this.localStore.selectByKey("availableTrainings");
    protected readonly activeLanguage$ = this.localStore.selectByKey("activeLanguage");

    protected readonly crewFormArray = new FormArray<CrewMemberTrainingsForm>([]);
    protected readonly crewTrainingsForm = new FormGroup<{ crewMembers: FormArray<CrewMemberTrainingsForm> }>({
        crewMembers: this.crewFormArray,
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: CrewTrainings | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<CrewTrainingsFormComponentState>) {
        this.localStore.setState({
            availableTrainings: [],
            activeLanguage: DEFAULT_LANG,
        });

        this.crewFormArray.valueChanges
            .pipe(
                map((values) => this.prepareCrewTrainingsValue(values as CrewMemberTrainingsFormValues[])),
                distinctUntilChanged(equal),
                untilDestroyed(this)
            )
            .subscribe((values) => {
                this.propagateChange(values);
            });
    }

    public registerOnChange(fn: (value: CrewTrainings | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: CrewTrainings | null): void {
        this.initCrewTrainings(value);
    }

    protected addTrainings(index: number): void {
        const trainingsForm = this.crewFormArray.at(index);
        trainingsForm.controls.areTrainingsSet.setValue(true);
        trainingsForm.controls.trainings.setValue({
            basic: [],
            additional: [],
        });
    }

    protected removeTrainings(index: number): void {
        const trainingsForm = this.crewFormArray.at(index);
        trainingsForm.controls.areTrainingsSet.setValue(false);
        trainingsForm.controls.trainings.setValue(null);
    }

    private initCrewTrainings(crewTrainings: CrewTrainings | null): void {
        this.crewFormArray.clear({ emitEvent: false });

        if (!crewTrainings) {
            return;
        }

        for (const [role, crewMemberTrainings] of Object.entries(crewTrainings)) {
            this.crewFormArray.push(this.createCrewMemberTrainingsForm(role as CrewMember, crewMemberTrainings), {
                emitEvent: false,
            });
        }
    }

    private createCrewMemberTrainingsForm(role: CrewMember, trainings: StaffTrainings | null): CrewMemberTrainingsForm {
        return new FormGroup({
            role: new FormControl<CrewMember>(role, { nonNullable: true }),
            areTrainingsSet: new FormControl<boolean>(!!trainings, { nonNullable: true }),
            trainings: new FormControl<StaffTrainings | null>(trainings),
        });
    }

    private prepareCrewTrainingsValue(values: CrewMemberTrainingsFormValues[]): CrewTrainings {
        const result: CrewTrainings = {};

        for (const crewMemberTrainings of values) {
            result[crewMemberTrainings.role] = crewMemberTrainings.areTrainingsSet ? crewMemberTrainings.trainings : null;
        }

        return result;
    }
}
