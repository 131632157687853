<dtm-ui-expandable-panel
    [hasHeaderSeparator]="false"
    [isExpanded]="isRiskMitigationEnabledControl.value"
    [isDisabled]="true"
    [formGroup]="riskMitigationFormGroup"
    *ngrxLet="{ isFormDisabled: isFormDisabled$ } as vm"
    [class.disabled]="vm.isFormDisabled"
>
    <dtm-web-app-lib-mission-risk-mitigation-level-header
        headerSlot
        [isSelected]="isRiskMitigationEnabledControl.value"
        (isSelectedChange)="isRiskMitigationEnabledControl.setValue($event)"
        [isDisabled]="vm.isFormDisabled"
    >
        <ng-container titleSlot>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.operationVLOSAvoidingFlightOverPeople" | transloco }}
            <dtm-ui-popover
                *ngIf="vm.isFormDisabled"
                (click)="$event.preventDefault()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m1DisabledBecauseFlightOverControlledAreaTooltip' | transloco
                "
            ></dtm-ui-popover>
        </ng-container>
    </dtm-web-app-lib-mission-risk-mitigation-level-header>

    <div class="mitigation-settings" *ngIf="isRiskMitigationEnabledControl.value" @slideIn>
        <p class="field-label">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelLabel" | transloco }}</p>
        <p>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsLabel"
                    | transloco : { value: RobustnessLevel.Low }
            }}
        </p>
        <p class="field-label">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.statementLabel" | transloco }}</p>
        <div class="statement">
            <dtm-ui-checkbox-field
                [formControl]="riskMitigationStatementsFormGroup.controls[M1BStatementsKeys.ableToIdentifyRiskOnTheGround]"
                class="with-validation"
                required
            >
                {{ M1BStatementsKeys.ableToIdentifyRiskOnTheGround | transloco }}
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <p
                    *dtmUiFieldHasError="
                        riskMitigationStatementsFormGroup.controls[M1BStatementsKeys.ableToIdentifyRiskOnTheGround];
                        name: 'required'
                    "
                >
                    {{ "dtmWebAppLibMission.requiredCheckedFieldErrorHint" | transloco }}
                </p>
            </dtm-ui-form-errors>
        </div>
        <div class="statement no-checkbox">
            <div class="conditions-content">
                <span class="required">*</span>
                <span>
                    {{ M1BStatementsKeys.ableToReduceNumberOfBystandersAtRisk | transloco }}
                </span>
            </div>
        </div>
        <div class="statement sub" [formGroup]="isBystandersSafeDistanceFormGroup">
            <dtm-ui-checkbox-field [formControlName]="M1BStatementsKeys.bystandersSafeDistance">
                {{ M1BStatementsKeys.bystandersSafeDistance | transloco }}
                <dtm-ui-popover
                    (click)="$event.preventDefault()"
                    [popoverText]="
                        'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m1BStatementsTooltips.bystandersSafeDistance' | transloco
                    "
                ></dtm-ui-popover>
            </dtm-ui-checkbox-field>
        </div>
        <div class="statement sub" [formGroup]="isAbnormalSituationCommandFormGroup">
            <dtm-ui-checkbox-field [formControlName]="M1BStatementsKeys.abnormalSituationCommand">
                <span>{{ M1BStatementsKeys.abnormalSituationCommand | transloco }}</span>
            </dtm-ui-checkbox-field>
        </div>
        <div class="statement sub" [formGroup]="isAbnormalSituationAlertFormGroup">
            <dtm-ui-checkbox-field [formControlName]="M1BStatementsKeys.abnormalSituationAlert">
                <span>{{ M1BStatementsKeys.abnormalSituationAlert | transloco }}</span>
            </dtm-ui-checkbox-field>
        </div>
        <dtm-ui-form-errors class="preserve-error-space">
            <p
                *ngIf="
                    riskMitigationFormGroup.controls.ableToReduceNumberOfBystandersAtRisk.touched &&
                    riskMitigationFormGroup.controls.ableToReduceNumberOfBystandersAtRisk.errors?.oneRequired
                "
            >
                <span>{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}</span>
            </p>
        </dtm-ui-form-errors>
        <div class="statement">
            <dtm-ui-checkbox-field
                [formControl]="riskMitigationStatementsFormGroup.controls[M1BStatementsKeys.operationalProceduresDocumented]"
                class="with-validation"
                required
            >
                {{ M1BStatementsKeys.operationalProceduresDocumented | transloco }}
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <p
                    *dtmUiFieldHasError="
                        riskMitigationStatementsFormGroup.controls[M1BStatementsKeys.operationalProceduresDocumented];
                        name: 'required'
                    "
                >
                    {{ "dtmWebAppLibMission.requiredCheckedFieldErrorHint" | transloco }}
                </p>
            </dtm-ui-form-errors>
        </div>
    </div>
</dtm-ui-expandable-panel>
