import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { ArrayUtils, FormType, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { combineLatest, skip } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { Sail } from "../../../../../shared/models/operations-manual.models";
import {
    OsoStatementCriteriaGroup,
    OsoStatementCriterionItem,
    OsoStatementCriterionItemType,
    OsoStatementGroup,
    StatementsData,
} from "../../../../services/specific-permit-application.models";

interface OsoStatementGroupPrepared extends OsoStatementGroup {
    criteriaGroups: OsoStatementCriteriaGroupPrepared[];
}

interface OsoStatementCriteriaGroupPrepared extends OsoStatementCriteriaGroup {
    criteria: OsoStatementCriterionItemPrepared[];
}

interface OsoStatementCriterionItemPrepared extends OsoStatementCriterionItem {
    content: ContentItem[];
}

interface ContentItem {
    content?: string;
    comment?: string;
}

interface OperationsManualChapterNumbers {
    uavInfoOperationsManualChapterNumber: number | undefined;
    operationInfoOperationsManualChapterNumber: number | undefined;
}

const COMMENT_REG_EXP = new RegExp("(<comment id=['\"]([^'\"]+)['\"]>)");

interface StatementsStepComponentState {
    isSaveDraftProcessing: boolean;
    initialValues: StatementsData | undefined;
    sail: Sail | undefined;
    osoStatementGroups: OsoStatementGroup[];
    uavInfoOperationsManualChapterNumber: number | undefined;
    operationInfoOperationsManualChapterNumber: number | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-statements-step[sail][osoStatementGroups]",
    templateUrl: "./statements-step.component.html",
    styleUrls: ["../../../common.scss", "./statements-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class StatementsStepComponent {
    @Input() public set isSaveDraftProcessing(value: BooleanInput) {
        this.localStore.patchState({ isSaveDraftProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set initialValues(value: StatementsData | undefined) {
        this.localStore.patchState({ initialValues: value });

        this.statementsForm.patchValue(value ?? { isStatementChecked: false });
    }

    @Input() public set sail(value: Sail | undefined) {
        this.localStore.patchState({ sail: value });
    }

    @Input() public set osoStatementGroups(value: OsoStatementGroup[] | undefined) {
        this.localStore.patchState({ osoStatementGroups: value ?? [] });
    }

    @Input() public set uavInfoOperationsManualChapterNumber(value: number | undefined) {
        this.localStore.patchState({ uavInfoOperationsManualChapterNumber: value });
    }

    @Input() public set operationInfoOperationsManualChapterNumber(value: number | undefined) {
        this.localStore.patchState({ operationInfoOperationsManualChapterNumber: value });
    }

    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly saveDraft = new EventEmitter<StatementsData>();
    @Output() public readonly next = new EventEmitter<StatementsData>();
    @Output() public readonly valueChange = new EventEmitter<void>();

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly OsoStatementCriterionItemType = OsoStatementCriterionItemType;
    protected readonly isSaveDraftProcessing$ = this.localStore.selectByKey("isSaveDraftProcessing");
    protected readonly sail$ = this.localStore.selectByKey("sail");
    protected readonly osoStatementGroups$ = this.localStore
        .selectByKey("osoStatementGroups")
        .pipe(map((osoStatementGroups) => this.prepareOsoStatementsGroups(osoStatementGroups)));
    protected readonly operationsManualChapterNumbers$ = combineLatest([
        this.localStore.selectByKey("uavInfoOperationsManualChapterNumber"),
        this.localStore.selectByKey("operationInfoOperationsManualChapterNumber"),
    ]).pipe(
        map(([uavInfoOperationsManualChapterNumber, operationInfoOperationsManualChapterNumber]) => ({
            uavInfoOperationsManualChapterNumber,
            operationInfoOperationsManualChapterNumber,
        }))
    );

    protected readonly statementsForm = new FormGroup<FormType<Omit<StatementsData, "osoNumbers">>>({
        isStatementChecked: new FormControl<boolean>(false, { validators: Validators.requiredTrue, nonNullable: true }),
    });

    constructor(
        private readonly localStore: LocalComponentStore<StatementsStepComponentState>,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            isSaveDraftProcessing: false,
            initialValues: undefined,
            sail: undefined,
            osoStatementGroups: [],
            uavInfoOperationsManualChapterNumber: undefined,
            operationInfoOperationsManualChapterNumber: undefined,
        });

        this.statementsForm.valueChanges.pipe(distinctUntilChanged(equal), skip(1), untilDestroyed(this)).subscribe(() => {
            this.valueChange.emit();
        });
    }

    protected goToNextStep(): void {
        this.statementsForm.markAllAsTouched();
        this.invalidFormScrollable.scrollToFirstInvalidField();

        if (!this.statementsForm.valid) {
            return;
        }

        this.next.emit(this.getStatements());
    }

    protected saveDataToDraft(): void {
        this.saveDraft.emit(this.getStatements());
    }

    private getStatements(): StatementsData {
        const osoStatementGroups = this.localStore.selectSnapshotByKey("osoStatementGroups");

        return {
            ...this.statementsForm.getRawValue(),
            osoNumbers: ArrayUtils.unique(osoStatementGroups.reduce<number[]>((result, group) => [...result, ...group.osoNumbers], [])),
        };
    }

    private prepareOsoStatementsGroups(osoStatementGroups: OsoStatementGroup[]): OsoStatementGroupPrepared[] {
        const operationsManualChapterNumbers: OperationsManualChapterNumbers = {
            uavInfoOperationsManualChapterNumber: this.localStore.selectSnapshotByKey("uavInfoOperationsManualChapterNumber"),
            operationInfoOperationsManualChapterNumber: this.localStore.selectSnapshotByKey("operationInfoOperationsManualChapterNumber"),
        };

        return osoStatementGroups.map((osoStatementGroup) => ({
            ...osoStatementGroup,
            criteriaGroups: osoStatementGroup.criteriaGroups.map((criteriaGroup) => ({
                ...criteriaGroup,
                criteria: criteriaGroup.criteria.map((criterion) => ({
                    ...criterion,
                    content: this.createCriterionContentArray(criterion, operationsManualChapterNumbers),
                })),
            })),
        }));
    }

    private createCriterionContentArray(
        criterion: OsoStatementCriterionItem,
        operationsManualChapterNumbers: OperationsManualChapterNumbers
    ): ContentItem[] {
        const fullContent = this.transloco.translate(criterion.contentHtmlKey, operationsManualChapterNumbers);
        const parts = fullContent.split(COMMENT_REG_EXP);
        const result: ContentItem[] = [];

        for (let index = 0; index < parts.length; index++) {
            if (COMMENT_REG_EXP.test(parts[index])) {
                const commentId = parts[++index];
                const commentItem = criterion.comments?.find((comment) => comment.id === commentId);
                if (commentItem) {
                    result.push({ comment: this.transloco.translate(commentItem.contentHtmlKey) });
                }
            } else {
                result.push({ content: parts[index] });
            }
        }

        return result;
    }
}
