import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoModule } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { LegalGuardianContainerComponent } from "./components/legal-guardian-container/legal-guardian-container.component";
import { LegalGuardianFormComponent } from "./components/legal-guardian-form/legal-guardian-form.component";
import { LegalGuardianSignatureStatusComponent } from "./components/legal-guardian-signature-status/legal-guardian-signature-status.component";
import { LegalGuardianDetailsRoutingModule } from "./legal-guardian-details-routing.module";
import { LegalGuardianDetailsApiService } from "./services/legal-guardian-details-api.service";
import { LegalGuardianDetailsState } from "./state/legal-guardian-details.state";

@NgModule({
    declarations: [LegalGuardianContainerComponent, LegalGuardianFormComponent, LegalGuardianSignatureStatusComponent],
    imports: [
        CommonModule,
        LegalGuardianDetailsRoutingModule,
        NgxsModule.forFeature([LegalGuardianDetailsState]),
        TranslocoModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        MatCardModule,
        SharedUiModule,
        MatDatepickerModule,
        SharedWebAppModule,
        MatInputModule,
        SharedI18nModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppLegalGuardian",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: LegalGuardianDetailsApiService,
            useValue: undefined,
        },
    ],
    exports: [LegalGuardianFormComponent, LegalGuardianSignatureStatusComponent],
})
export class LegalGuardianDetailsModule {
    public static forRoot(): ModuleWithProviders<LegalGuardianDetailsModule> {
        return {
            ngModule: LegalGuardianDetailsModule,
            providers: [LegalGuardianDetailsApiService],
        };
    }

    public static forTest(): ModuleWithProviders<LegalGuardianDetailsModule> {
        return {
            ngModule: LegalGuardianDetailsModule,
            providers: [
                {
                    provide: LegalGuardianDetailsApiService,
                    useValue: null,
                },
            ],
        };
    }
}
