<dtm-ui-expandable-panel *ngrxLet="groundRisk$ as groundRisk" [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="false">
    <dtm-ui-expandable-simple-header
        headerSlot
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.groundRiskHeaderLabel' | transloco"
    >
    </dtm-ui-expandable-simple-header>
    <div class="properties">
        <dtm-mission-population-density
            class="whole-line-property"
            [populationDensity]="groundRisk.populationDensity"
            [isAveragePopulationDensityVisible]="false"
            [maxPopulationDensityLabel]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.maxPopulationDensityLabel' | transloco"
            [populationDensityShareLabel]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.populationDensityShareLabel' | transloco
            "
        ></dtm-mission-population-density>
        <dtm-ui-label-value
            *ngIf="groundRisk.peopleAssemblyComment || groundRisk.controlledGroundAreaComment"
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.groundAreaCharacteristicLabel' | transloco"
            class="whole-line-property"
        >
            <div class="feature-item" *ngIf="groundRisk.peopleAssemblyComment !== null">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <span class="feature-value">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.flightOverAssembliesLabel" | transloco }}
                </span>
                <span class="feature-description">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.groundAreaCharacteristicDescriptionLabel" | transloco }}
                    <dtm-ui-popover [popoverText]="groundRisk.peopleAssemblyComment"></dtm-ui-popover>
                </span>
            </div>
            <div class="feature-item" *ngIf="groundRisk.controlledGroundAreaComment !== null">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <span class="feature-value">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.flightOverControlledAreaLabel" | transloco }}
                </span>
                <span class="feature-description">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.groundAreaCharacteristicDescriptionLabel" | transloco }}
                    <dtm-ui-popover [popoverText]="groundRisk.controlledGroundAreaComment"></dtm-ui-popover>
                </span>
            </div>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.areaTypeLabel' | transloco"
            [value]="groundRisk.finalGrl | grlLevel"
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.intrinsicGrcLabel' | transloco"
            [value]="groundRisk.intrinsicGrc | grcLevel : true"
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.finalGrcLabel' | transloco"
            [value]="groundRisk.finalGrc | grcLevel"
            class="whole-line-property"
        ></dtm-ui-label-value>
    </div>
    <h2 class="mitigation-header" *ngIf="groundRisk.mitigations.m1A || groundRisk.mitigations.m1B || groundRisk.mitigations.m2">
        {{ "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.mitigations.header" | transloco }}
    </h2>
    <dtm-ui-label-value
        class="mitigation-measure"
        *ngIf="groundRisk.mitigations.m1A"
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.mitigations.m1AMeasureLabel' | transloco"
    >
        <ng-template
            [ngTemplateOutlet]="mitigationMeasureTemplate"
            [ngTemplateOutletContext]="{
                measure: groundRisk.mitigations.m1A
            }"
        ></ng-template>
    </dtm-ui-label-value>
    <dtm-ui-label-value
        class="mitigation-measure"
        *ngIf="groundRisk.mitigations.m1B"
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.mitigations.m1BMeasureLabel' | transloco"
    >
        <ng-template
            [ngTemplateOutlet]="mitigationMeasureTemplate"
            [ngTemplateOutletContext]="{
                measure: groundRisk.mitigations.m1B
            }"
        ></ng-template>
    </dtm-ui-label-value>
    <dtm-ui-label-value
        class="mitigation-measure"
        *ngIf="groundRisk.mitigations.m2"
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.mitigations.m2MeasureLabel' | transloco"
    >
        <ng-template
            [ngTemplateOutlet]="mitigationMeasureTemplate"
            [ngTemplateOutletContext]="{
                measure: groundRisk.mitigations.m2
            }"
        ></ng-template>
    </dtm-ui-label-value>
</dtm-ui-expandable-panel>

<ng-template #mitigationMeasureTemplate let-measure="measure">
    <div class="feature-item">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        <span class="feature-value">
            {{ "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.mitigations.robustnessLevelLabel" | transloco }}
        </span>
        <span class="feature-description">
            {{
                "dtmWebAppLibSpecPermApp.operationInfoStep.groundRiskInfo.mitigations.robustnessLevelValue"
                    | transloco : { value: measure.robustnessLevel }
            }}
        </span>
    </div>
    <div class="feature-item" *ngFor="let statement of measure.statements">
        <dtm-ui-icon name="check"></dtm-ui-icon>
        <span class="feature-value">
            {{ statement.name | transloco }}
        </span>
        <span class="feature-description" *ngIf="statement.comments.length === 1">
            {{ statement.comments[0].content | transloco }}
        </span>
        <ul class="feature-description statement-comments" *ngIf="statement.comments.length > 1">
            <li *ngFor="let comment of statement.comments">
                {{ comment.content | transloco }}
                <ul *ngIf="comment.subItems?.length">
                    <li *ngFor="let item of comment.subItems">
                        {{ item | transloco }}
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</ng-template>
