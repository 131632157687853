import { Pipe, PipeTransform } from "@angular/core";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { CountryCode, formatIncompletePhoneNumber, formatNumber, isValidNumberForRegion, parse } from "libphonenumber-js";

@Pipe({
    name: "formatImportedPhoneNumber",
})
export class FormatImportedPhoneNumberPipe implements PipeTransform {
    public transform(phone: PhoneNumber | undefined): string | undefined {
        if (!phone) {
            return undefined;
        }

        const countryCode: CountryCode = phone.countryCode ?? "PL";

        if (isValidNumberForRegion(phone.number, countryCode)) {
            return formatNumber(parse(`${phone.number}`, countryCode), "INTERNATIONAL");
        }

        return formatIncompletePhoneNumber(phone.number, countryCode);
    }
}
