import { InjectionToken } from "@angular/core";

export interface LegalGuardianDetailsEndpoints {
    getLegalGuardianGeneralData: string;
    generatePdf: string;
    sendForSignature: string;
    verifyLegalGuardianSignature: string;
}

export const LEGAL_GUARDIAN_DETAILS_ENDPOINTS = new InjectionToken<LegalGuardianDetailsEndpoints>("Legal guardian details endpoints");
