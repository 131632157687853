import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AdditionalCrew, CrewMember } from "../../../services/specific-permit-application.models";

interface AdditionalCrewFormComponentState {
    availableCrewMembers: CrewMember[];
}

interface AdditionalCrewForm {
    members: FormControl<CrewMember[]>;
    details: FormArray<FormGroup<MemberDetailsForm>>;
}

interface MemberDetailsForm {
    member: FormControl<CrewMember>;
    amount: FormControl<number | null>;
    responsibilities: FormControl<string | null>;
}

interface AdditionalCrewFormValues {
    members: CrewMember[];
    details: Array<{
        member: CrewMember;
        amount: number | null;
        responsibilities: string | null;
    }>;
}

const MAX_RESPONSIBILITIES_LENGTH = 500;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-additional-crew-form[availableCrewMembers]",
    templateUrl: "./additional-crew-form.component.html",
    styleUrls: ["./additional-crew-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AdditionalCrewFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AdditionalCrewFormComponent),
            multi: true,
        },
    ],
})
export class AdditionalCrewFormComponent implements ControlValueAccessor, Validator {
    @Input() public set availableCrewMembers(value: CrewMember[] | undefined) {
        this.localStore.patchState({ availableCrewMembers: value ?? [] });
    }

    protected MAX_RESPONSIBILITIES_LENGTH = MAX_RESPONSIBILITIES_LENGTH;

    protected readonly additionalCrewForm = new FormGroup<AdditionalCrewForm>({
        members: new FormControl<CrewMember[]>([], {
            nonNullable: true,
        }),
        details: new FormArray<FormGroup<MemberDetailsForm>>([]),
    });

    protected readonly availableCrewMembers$ = this.localStore.selectByKey("availableCrewMembers");

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: AdditionalCrew | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<AdditionalCrewFormComponentState>) {
        this.localStore.setState({
            availableCrewMembers: [],
        });

        this.handleMembersChange();

        this.additionalCrewForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            const additionalCrew = (values.members ?? []).map((member) => {
                const details = values.details?.find((item) => item.member === member);

                return {
                    member,
                    amount: details?.amount ?? null,
                    responsibilities: details?.responsibilities ?? null,
                };
            });

            this.propagateChange(additionalCrew);
        });
    }

    public registerOnChange(fn: (value: AdditionalCrew | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: AdditionalCrew | null): void {
        if (value) {
            const formValue: AdditionalCrewFormValues = {
                members: [],
                details: [],
            };
            this.additionalCrewForm.controls.details.clear({ emitEvent: false });

            for (const crewMember of value) {
                formValue.members.push(crewMember.member);

                const hasAmount = this.hasMemberAmount(crewMember.member);
                const hasResponsibilities = this.hasMemberResponsibilities(crewMember.member);

                if (hasAmount || hasResponsibilities) {
                    this.additionalCrewForm.controls.details.push(
                        this.createDetailsForm(crewMember.member, crewMember.amount, crewMember.responsibilities)
                    );
                    formValue.details.push({ ...crewMember });
                }
            }

            this.additionalCrewForm.setValue(formValue, { emitEvent: false });
        } else {
            this.additionalCrewForm.reset();
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.additionalCrewForm.disable();
        } else {
            this.additionalCrewForm.enable();
        }
    }

    public validate(): ValidationErrors | null {
        if (this.additionalCrewForm.invalid) {
            return { required: true };
        }

        return null;
    }

    protected hasMemberAmount(role: CrewMember) {
        return role === CrewMember.UavObserver;
    }

    private hasMemberResponsibilities(role: CrewMember) {
        return [CrewMember.TechnicalStaff, CrewMember.AuxiliaryStaff, CrewMember.AirspaceObserver, CrewMember.UavObserver].includes(role);
    }

    private handleMembersChange(): void {
        this.additionalCrewForm.controls.members.valueChanges.pipe(untilDestroyed(this)).subscribe((members) => {
            const currentDetails = this.additionalCrewForm.controls.details.getRawValue();
            const currentMembers = currentDetails.map((detailsItem) => detailsItem.member);
            const membersToRemove = currentMembers.filter((item) => !members.includes(item));
            const membersToAdd = members.filter(
                (item) => !currentMembers.includes(item) && (this.hasMemberAmount(item) || this.hasMemberResponsibilities(item))
            );

            for (const member of membersToRemove) {
                const currentDetailsItemIndex = currentDetails.findIndex((item) => item.member === member);

                if (currentDetailsItemIndex >= 0) {
                    this.additionalCrewForm.controls.details.removeAt(currentDetailsItemIndex);
                }
            }

            for (const member of membersToAdd) {
                this.additionalCrewForm.controls.details.push(this.createDetailsForm(member));
            }
        });
    }

    private createDetailsForm(
        member: CrewMember,
        amount: number | null = 1,
        responsibilities: string | null = null
    ): FormGroup<MemberDetailsForm> {
        return new FormGroup<MemberDetailsForm>({
            member: new FormControl<CrewMember>(member, { nonNullable: true }),
            amount: new FormControl<number | null>(amount, this.hasMemberAmount(member) ? [Validators.required, Validators.min(1)] : null),
            responsibilities: new FormControl<string | null>(
                responsibilities,
                this.hasMemberResponsibilities(member)
                    ? [
                          Validators.required,
                          Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                          Validators.maxLength(MAX_RESPONSIBILITIES_LENGTH),
                      ]
                    : null
            ),
        });
    }
}
