<mat-card class="main-container">
    <div class="img"></div>
    <div>
        <h1 class="title">404</h1>
        <h2 class="header">{{ "dtmWebAppLibPublicProfile.publicProfileNotFound.header" | transloco : { type: type$ | ngrxPush } }}</h2>
        <button class="button-primary" type="button" routerLink="/">
            {{ "dtmWebAppLibPublicProfile.publicProfileNotFound.backToDashboardLabel" | transloco }}
        </button>
    </div>
</mat-card>
