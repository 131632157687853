<dtm-ui-expandable-panel
    *ngrxLet="{
        isFormDisabled: isFormDisabled$,
        operationalHeight: operationalHeight$,
        sectionsKeysZones: sectionsKeysZones$,
        airRiskElements: airRiskElements$,
        airRisk: airRisk$
    } as vm"
    [class.disabled]="vm.isFormDisabled"
    [hasHeaderSeparator]="false"
    [isExpanded]="airRiskMitigationMeasureFormControl.value"
    [isDisabled]="true"
    [formGroup]="airRiskMitigationFormGroup"
>
    <dtm-web-app-lib-mission-risk-mitigation-level-header
        headerSlot
        [isSelected]="airRiskMitigationMeasureFormControl.value"
        [isDisabled]="vm.isFormDisabled || airRiskMitigationFormGroup.disabled"
        (isSelectedChange)="riskMitigationChange($event)"
    >
        <ng-container titleSlot>
            {{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.strategicAirspaceRiskMitigationMeasuresLabel"
                    | transloco
            }}
            <dtm-ui-popover (click)="$event.preventDefault()">
                <p
                    class="risk-mitigation-tooltip"
                    [innerHTML]="
                        'dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.strategicAirspaceRiskMitigationMeasuresPopover'
                            | transloco
                    "
                ></p>
            </dtm-ui-popover>
        </ng-container>
    </dtm-web-app-lib-mission-risk-mitigation-level-header>

    <div class="mitigation-settings" *ngIf="airRiskMitigationMeasureFormControl.value" @slideIn dtmUiInvalidFormScrollable>
        <ng-container *ngFor="let sectionKey of sectionsKeys">
            <div [formGroupName]="sectionKey" class="section" *ngIf="airRiskMitigationFormGroup.controls[sectionKey].controls as fields">
                <mat-slide-toggle
                    labelPosition="before"
                    class="spread allow-wrap toggle-bar-center"
                    [formControl]="fields.isSelected"
                    (change)="!$event.checked && airRiskMitigationFormGroup.controls[sectionKey].reset()"
                >
                    <p class="section-title">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.airRiskMitigationStatements"
                                | transloco : { value: sectionKey }
                        }}
                    </p>
                    <span
                        *ngIf="
                            sectionKey
                                | invoke : hasSectionSubtitleText : [AirRiskMitigationsCategory.AtzCore, AirRiskMitigationsCategory.Atz]
                        "
                        class="section-subtitle"
                    >
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.airRiskMitigationSubtitles.aeroClubAirportZonesLabel"
                                | transloco
                        }}
                    </span>

                    <span
                        *ngIf="
                            sectionKey
                                | invoke
                                    : hasSectionSubtitleText
                                    : [
                                          AirRiskMitigationsCategory.CtrCore,
                                          AirRiskMitigationsCategory.Ctr,
                                          AirRiskMitigationsCategory.MctrCore,
                                          AirRiskMitigationsCategory.Mctr
                                      ]
                        "
                        class="section-subtitle"
                    >
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.airRiskMitigationSubtitles.controlledZonesOfCivilAirportsLabel"
                                | transloco
                        }}
                    </span>
                </mat-slide-toggle>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="airRiskMitigationFormGroup; name: 'oneRequired'">
                        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}
                    </div>
                </dtm-ui-form-errors>

                <div *ngIf="fields.isSelected.value" @slideIn class="content">
                    <div class="basic-data">
                        <dtm-ui-label-value
                            *ngIf="sectionKey !== AirRiskMitigationsCategory.EmptyAirspace"
                            [label]="'dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.zonesLabel' | transloco"
                            [value]="vm.sectionsKeysZones[sectionKey] | invoke : getZones"
                        ></dtm-ui-label-value>
                        <dtm-ui-label-value
                            *ngIf="vm.airRisk"
                            [label]="
                                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.initialRiskClassLabel' | transloco
                            "
                            [value]="
                                sectionKey | invoke : getAirRiskMitigationLevel : vm.airRisk | airRiskMitigationsArcAsNumber | arcLabel
                            "
                        ></dtm-ui-label-value>
                    </div>

                    <div
                        formGroupName="airRiskClassMitigation"
                        *ngIf="sectionKey | invoke : getInitialArc : vm.airRisk : vm.airRiskElements as airInitialRiskMitigationValue"
                    >
                        <dtm-web-app-lib-mission-air-risk-mitigation-levels
                            [airInitialRiskMitigationValue]="airInitialRiskMitigationValue"
                            [airRiskMitigationsPopoverKeys]="
                                sectionKey | invoke : getAirRiskMitigationPopoverKeys : mitigationLevelPopoverMap
                            "
                            [airRiskMitigationValue]="fields.airRiskClassMitigation.value"
                            (airRiskMitigationSet)="fields.airRiskClassMitigation.setValue($event)"
                        >
                            <ng-container titleSlot>{{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.airRskMitigationLabel" | transloco
                            }}</ng-container>
                        </dtm-web-app-lib-mission-air-risk-mitigation-levels>

                        <dtm-ui-form-errors>
                            <div class="field-error" *dtmUiFieldHasError="fields.airRiskClassMitigation; name: 'required'">
                                {{ "dtmWebAppLibMission.requiredCheckedFieldErrorHint" | transloco }}
                            </div>
                        </dtm-ui-form-errors>
                    </div>

                    <div>
                        <ng-container
                            *ngIf="fields.isSelected"
                            [ngTemplateOutlet]="checkboxTextareaSectionTemplate"
                            [ngTemplateOutletContext]="{
                                sectionFields: fields.methods.controls,
                                sectionKey: sectionKey,
                                operationalHeight: vm.operationalHeight
                            }"
                        ></ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</dtm-ui-expandable-panel>

<ng-template
    #checkboxTextareaSectionTemplate
    let-sectionKey="sectionKey"
    let-sectionFields="sectionFields"
    let-operationalHeight="operationalHeight"
>
    <div *ngIf="sectionKey | invoke : getMethodFormGroup as methodFormGroup" [formGroup]="methodFormGroup">
        <span class="field-label">
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.airRiskMitigation.usedMethodsLabel" | transloco }}
        </span>
        <ng-container *ngFor="let methodKey of methodsKeys; let index = index">
            <ng-container *ngIf="sectionKey | invoke : shouldShowMethod : operationalHeight : index">
                <div class="statement" *ngIf="sectionFields[methodKey].controls as checkboxTextareaFields">
                    <dtm-ui-checkbox-field
                        class="with-validation"
                        [formControl]="checkboxTextareaFields.methodCheckbox"
                        (change)="checkboxTextareaFields.methodTextarea.updateValueAndValidity()"
                    >
                        {{ methodKey | transloco }}
                        <dtm-ui-popover (click)="$event.preventDefault()">
                            <p [innerHTML]="methodKey | invoke : getMethodPopoverKey : methodsKeysPopoverMap | transloco"></p>
                        </dtm-ui-popover>
                    </dtm-ui-checkbox-field>
                    <dtm-ui-textarea-field
                        *ngIf="checkboxTextareaFields.methodCheckbox.value"
                        class="comment"
                        [maxLength]="MAX_COMMENT_LENGTH"
                    >
                        <label>{{ methodKey | transloco }}</label>
                        <textarea matInput [rows]="4" [formControl]="checkboxTextareaFields.methodTextarea"></textarea>
                        <p class="field-error" *dtmUiFieldHasError="checkboxTextareaFields.methodTextarea; name: 'required'">
                            {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                        </p>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="checkboxTextareaFields.methodTextarea; name: 'maxlength'; error as error"
                        >
                            {{
                                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.commentMaxLengthError"
                                    | transloco
                                        : {
                                              maxLength: error.requiredLength,
                                              exceedValue: error.actualLength - error.requiredLength
                                          }
                            }}
                        </div>
                    </dtm-ui-textarea-field>
                </div>
            </ng-container>
        </ng-container>

        <dtm-ui-form-errors class="preserve-error-space">
            <div class="field-error" *dtmUiFieldHasError="methodFormGroup; name: 'oneRequired'">
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}
            </div>
        </dtm-ui-form-errors>
    </div>
</ng-template>
