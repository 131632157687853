<dtm-ui-expandable-panel
    *ngrxLet="{ isExpanded: isExpanded$, maxDeclaredHeight: maxDeclaredHeight$ } as vm"
    [isExpanded]="vm.isExpanded"
    [hasHeaderSeparator]="false"
>
    <div headerSlot>
        {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.header" | transloco }}
    </div>
    <form class="content" *ngIf="formControls$ | ngrxPush as formControls">
        <div class="flight-speed-container" *ngrxLet="maxFlightSpeed$ | ngrxPush as maxFlightSpeed">
            <dtm-ui-input-field class="flight-speed-input-field" [isClearable]="false">
                <label>
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.flightSpeedLabel" | transloco }}
                </label>
                <dtm-ui-icon name="dashboard-2" class="field-prefix"></dtm-ui-icon>
                <input
                    #flightSpeed
                    matInput
                    type="number"
                    inputmode="numeric"
                    [formControl]="formControls.flightSpeed"
                    required
                    autocomplete="off"
                    [min]="1"
                    [max]="maxFlightSpeed !== undefined ? maxFlightSpeed : null"
                />
                <div class="field-error" *dtmUiFieldHasError="formControls.flightSpeed">
                    {{
                        "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.flightSpeedConstraintsError"
                            | transloco : { min: flightSpeed.min, max: flightSpeed.max }
                    }}
                </div>
                <dtm-ui-select-field
                    class="input-suffix flight-speed-type-select-field"
                    [isClearable]="false"
                    [formControl]="formControls.flightSpeedType"
                >
                    <dtm-ui-select-option [value]="FlightSpeedType.KilometersPerHour">
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.kilometersPerHourOptionLabel"
                                | transloco
                        }}
                    </dtm-ui-select-option>
                    <dtm-ui-select-option [value]="FlightSpeedType.MetersPerSecond">
                        {{
                            "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.metersPerSecondOptionLabel" | transloco
                        }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>
            </dtm-ui-input-field>
        </div>
        <dtm-ui-input-field class="reaction-time-input-field" [isClearable]="false">
            <label>
                {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.pilotReactionDelayLabel" | transloco }}
                <dtm-ui-popover
                    [popoverText]="
                        'dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.pilotReactionDelayWarningMessage'
                            | transloco
                    "
                ></dtm-ui-popover>
            </label>
            <dtm-ui-icon name="map-pin-time" class="field-prefix"></dtm-ui-icon>
            <input
                #pilotReactionDelay
                matInput
                type="number"
                inputmode="numeric"
                [formControl]="formControls.pilotReactionDelaySeconds"
                required
                autocomplete="off"
                [min]="1"
                [max]="MAX_PILOT_REACTION_DELAY_SECONDS"
            />
            <div class="field-suffix">
                {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.pilotReactionDelayUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="formControls.pilotReactionDelaySeconds">
                {{
                    "dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.pilotReactionDelayConstraintsError"
                        | transloco : { min: pilotReactionDelay.min, max: pilotReactionDelay.max }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-label-value
            *ngIf="vm.maxDeclaredHeight"
            [label]="'dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.maxDeclaredHeightLabel' | transloco"
            [value]="
                'dtmWebAppLibMission.itineraryEditorStep.missionParameters.settingsPanel.maxDeclaredHeightValue'
                    | transloco : { height: vm.maxDeclaredHeight | localizeNumber : { maximumFractionDigits: 0 } }
            "
        ></dtm-ui-label-value>
        <div class="deferred-form-actions" *ngIf="formStateController$ | ngrxPush as formStateController">
            <ng-container *ngIf="formStateController.isEditing$ | ngrxPush">
                <button class="button-secondary" type="button" (click)="formStateController.restore()">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.restoreFormButtonLabel" | transloco }}
                </button>
                <button
                    class="button-primary next-button"
                    type="button"
                    (click)="saveFormValues(formStateController, formControls)"
                    [disabled]="!formStateController.isValid || (isDisabled$ | ngrxPush)"
                >
                    {{ "dtmWebAppLibMission.itineraryEditorStep.missionParameters.saveFormButtonLabel" | transloco }}
                </button>
            </ng-container>
        </div>
    </form>
</dtm-ui-expandable-panel>
