<div class="operational-authorizations">
    <p class="authorizations-header">
        {{ (header$ | ngrxPush) ?? ("dtmWebAppLibOperatorMigration.operationalAuthorizations.header" | transloco) }}
    </p>
    <div class="authorizations-container">
        <div *ngFor="let operationalAuthorization of operationalAuthorizations$ | ngrxPush" class="authorizations">
            <span [ngSwitch]="operationalAuthorization.category">
                <dtm-ui-icon *ngSwitchCase="OperationScenarioCategory.Open" name="shield-cross" class="category-open-icon"></dtm-ui-icon>
                <dtm-ui-icon
                    *ngSwitchCase="OperationScenarioCategory.Specific"
                    name="shield-cross"
                    class="category-specific-icon"
                ></dtm-ui-icon>
                <dtm-ui-icon
                    *ngSwitchCase="OperationScenarioCategory.Certified"
                    name="shield-cross"
                    class="category-certified-icon"
                ></dtm-ui-icon>
            </span>
            <div>
                <p class="authorization-name">{{ operationalAuthorization.name }}</p>
                <p class="authorization-expiration-date">{{ operationalAuthorization.expirationDate | localizeDate }}</p>
            </div>
        </div>
    </div>
</div>
