import { ChangeDetectionStrategy, Component, Input, NgZone } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";

interface SailAlertToastComponentState {
    sail: number | undefined;
}

@Component({
    selector: "dtm-web-app-lib-sail-toast",
    templateUrl: "./sail-alert-toast.component.html",
    styleUrls: ["./sail-alert-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SailAlertToastComponent extends Toast {
    @Input() public set sail(value: number | undefined) {
        this.localStore.patchState({ sail: value });
    }

    protected readonly sail$ = this.localStore.selectByKey("sail");

    constructor(
        private readonly localStore: LocalComponentStore<SailAlertToastComponentState>,
        toastrService: ToastrService,
        toastPackage: ToastPackage,
        ngZone: NgZone
    ) {
        super(toastrService, toastPackage, ngZone);
        this.localStore.setState({
            sail: undefined,
        });
    }
}
