import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { NewUserGuard } from "../../guards/new-user.guard";
import { ApplicationCreatorWizardContentComponent } from "./components/application-creator-wizard/content/application-creator-wizard-content.component";
import { ApplicationCreatorWizardHeaderComponent } from "./components/application-creator-wizard/header/application-creator-wizard-header.component";
import { SpecificPermitApplicationGuard } from "./services/specific-permit-application.guard";
import { ApplicationCapabilitiesResolver, ApplicationDraftResolver } from "./services/specific-permit-application.resolvers";

const routes: Routes = [
    {
        path: "specific-permit/application-creator",
        component: ApplicationCreatorWizardContentComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, SpecificPermitApplicationGuard],
        resolve: {
            init: ApplicationCapabilitiesResolver,
        },
        data: {
            titleTranslationKey: "dtmWebAppLibSpecPermApp.routeTitles.applicationCreatorWizard",
            headerComponent: ApplicationCreatorWizardHeaderComponent,
            className: "no-padding",
        },
        children: [
            {
                path: "draft/:draftId",
                component: ApplicationCreatorWizardContentComponent,
                resolve: {
                    draft: ApplicationDraftResolver,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ApplicationCapabilitiesResolver, ApplicationDraftResolver],
})
export class SpecificPermitApplicationRoutingModule {}
