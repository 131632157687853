import { Injectable } from "@angular/core";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { ExamResult, ExamResultListWithPages, ExamResultsError } from "../models/exam-results.models";
import { TrainingResult, TrainingResultListWithPages, TrainingResultsError } from "../models/training-results.models";
import { TrainingExamResultsApiService } from "../services/training-exam-results-api.service";
import { TrainingExamResultsActions } from "./training-exam-results.actions";

interface TrainingExamResultsStateModel {
    examResultsList: ExamResult[] | undefined;
    examResultsListError: ExamResultsError | undefined;
    isExamResultsListProcessing: boolean;
    examResultsPages: Page | undefined;
    trainingResultsList: TrainingResult[] | undefined;
    trainingResultsListError: TrainingResultsError | undefined;
    isTrainingResultsListProcessing: boolean;
    trainingResultsPages: Page | undefined;
}

const defaultState: TrainingExamResultsStateModel = {
    examResultsList: undefined,
    examResultsListError: undefined,
    isExamResultsListProcessing: false,
    examResultsPages: undefined,
    trainingResultsList: undefined,
    trainingResultsListError: undefined,
    isTrainingResultsListProcessing: false,
    trainingResultsPages: undefined,
};

@State<TrainingExamResultsStateModel>({
    name: "trainingExamResults",
    defaults: defaultState,
})
@Injectable()
export class TrainingExamResultsState {
    constructor(private readonly resultsApiService: TrainingExamResultsApiService) {}

    @Selector()
    public static examResultsList(state: TrainingExamResultsStateModel): ExamResult[] | undefined {
        return state.examResultsList;
    }

    @Selector()
    public static examResultsListError(state: TrainingExamResultsStateModel): ExamResultsError | undefined {
        return state.examResultsListError;
    }

    @Selector()
    public static examResultsPages(state: TrainingExamResultsStateModel): Page | undefined {
        return state.examResultsPages;
    }

    @Selector()
    public static isExamResultsListProcessing(state: TrainingExamResultsStateModel): boolean {
        return state.isExamResultsListProcessing;
    }

    @Selector()
    public static trainingResultsList(state: TrainingExamResultsStateModel): TrainingResult[] | undefined {
        return state.trainingResultsList;
    }

    @Selector()
    public static trainingResultsListError(state: TrainingExamResultsStateModel): TrainingResultsError | undefined {
        return state.trainingResultsListError;
    }

    @Selector()
    public static trainingResultsPages(state: TrainingExamResultsStateModel): Page | undefined {
        return state.trainingResultsPages;
    }

    @Selector()
    public static isTrainingResultsListProcessing(state: TrainingExamResultsStateModel): boolean {
        return state.isTrainingResultsListProcessing;
    }

    @Action(TrainingExamResultsActions.GetTrainingResultsList)
    public getTrainingResultsList(
        context: StateContext<TrainingExamResultsStateModel>,
        action: TrainingExamResultsActions.GetTrainingResultsList
    ) {
        context.patchState({ isTrainingResultsListProcessing: true, trainingResultsListError: undefined });

        return this.resultsApiService.getTrainingResultsList(action.filtersQuery, action.contextId).pipe(
            tap(({ content, pageSize, pageNumber, totalElements }: TrainingResultListWithPages) =>
                context.patchState({
                    trainingResultsList: content,
                    trainingResultsPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((trainingResultsListError) => {
                context.patchState({ trainingResultsListError, trainingResultsList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isTrainingResultsListProcessing: false,
                })
            )
        );
    }

    @Action(TrainingExamResultsActions.GetExamResultsList)
    public getExamResultsList(context: StateContext<TrainingExamResultsStateModel>, action: TrainingExamResultsActions.GetExamResultsList) {
        context.patchState({ isExamResultsListProcessing: true, examResultsListError: undefined });

        return this.resultsApiService.getExamResultsList(action.filtersQuery, action.contextId).pipe(
            tap(({ content, pageSize, pageNumber, totalElements }: ExamResultListWithPages) =>
                context.patchState({
                    examResultsList: content,
                    examResultsPages: {
                        pageSize,
                        pageNumber,
                        totalElements,
                    },
                })
            ),
            catchError((examResultsListError) => {
                context.patchState({ examResultsListError, examResultsList: [] });

                return EMPTY;
            }),
            finalize(() =>
                context.patchState({
                    isExamResultsListProcessing: false,
                })
            )
        );
    }
}
