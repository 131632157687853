<mat-card *ngrxLet="legalGuardianDetails$ as legalGuardian">
    <div class="details-container" [formGroup]="legalGuardianForm">
        <div class="header">
            <dtm-ui-card-header>
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.mainHeader" | transloco }}
            </dtm-ui-card-header>
        </div>
        <div>
            <dtm-ui-info-message type="info">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.info" | transloco
                }}<a href="https://drony.gov.pl" target="_blank" rel="noopener" class="button-link">drony.gov.pl</a>
            </dtm-ui-info-message>
        </div>
        <dtm-ui-label-value
            class="name-details"
            [label]="'dtmWebAppLegalGuardian.legalGuardianDetails.nameLabel' | transloco"
            [value]="legalGuardian.firstName + ' ' + legalGuardian.lastName"
        ></dtm-ui-label-value>
        <dtm-ui-date-field class="form-field">
            <label for="birthDateControl">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.birthDateLabel" | transloco }}
            </label>
            <input
                id="birthDateControl"
                [max]="MIN_LEGAL_AGE_YEARS"
                [min]="MIN_DATE_OF_BIRTH"
                formControlName="dateOfBirth"
                matInput
                [matDatepicker]="birthDatePicker"
                *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                [placeholder]="datePickerPlaceholder"
            />
            <mat-datepicker #birthDatePicker></mat-datepicker>
            <div
                class="field-error"
                *dtmUiFieldHasError="legalGuardianForm.controls.dateOfBirth; name: ['required', 'requiredTouched']; error as error"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="legalGuardianForm.controls.dateOfBirth; name: 'matDatepickerMax'">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.minAgeErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="legalGuardianForm.controls.dateOfBirth; name: 'matDatepickerMin'; error as error">
                {{
                    "dtmWebAppLegalGuardian.legalGuardianDetails.minDateOfBirthErrorHint"
                        | transloco : { minDate: error.min | localizeDate }
                }}
            </div>
        </dtm-ui-date-field>
        <dtm-ui-country-select-field formControlName="nationality" class="form-field">
            <label>
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.citizenshipLabel" | transloco }}
            </label>
        </dtm-ui-country-select-field>

        <h5 class="sub-header contact-header">{{ "dtmWebAppLegalGuardian.legalGuardianDetails.contactDataHeader" | transloco }}</h5>
        <dtm-ui-phone-number-field formControlName="phoneNumber" class="form-field">
            <div
                class="field-error"
                *dtmUiFieldHasError="legalGuardianForm.controls.phoneNumber; name: 'required'"
                [ngTemplateOutlet]="requiredErrorTemplate"
            ></div>
            <div class="field-error" *dtmUiFieldHasError="legalGuardianForm.controls.phoneNumber; name: 'invalidNumber'">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.invalidPhoneNumberError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="legalGuardianForm.controls.phoneNumber; name: 'invalidNumberType'">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.invalidNumberTypeError" | transloco }}
            </div>
        </dtm-ui-phone-number-field>
        <dtm-ui-label-value
            class="email-address"
            [label]="'dtmWebAppLegalGuardian.legalGuardianDetails.emailAddressLabel' | transloco"
            [value]="legalGuardian.email"
        ></dtm-ui-label-value>
        <dtm-ui-country-select-field formControlName="residenceCountry" class="form-field">
            <label>
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.residenceCountryLabel" | transloco }}
            </label>
        </dtm-ui-country-select-field>
        <dtm-ui-address-form
            formControlName="address"
            dtmUiMarkValueAccessorControlsAsTouched
            [isSystemDefaultCountrySelected]="legalGuardianForm.controls.residenceCountry.value === DEFAULT_COUNTRY_CODE"
        ></dtm-ui-address-form>
        <div class="checkbox">
            <dtm-ui-checkbox-field [formControl]="legalGuardianForm.controls.consent">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.consentValue" | transloco }}
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianForm.controls.consent; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-form-errors>
        </div>
        <div class="checkbox" *ngrxLet="isLongGeneralDataProtectionRegulationVisible$ as isLongGeneralDataProtectionRegulationVisible">
            <dtm-ui-checkbox-field [formControl]="legalGuardianForm.controls.generalDataProtectionRegulation">
                <span>{{ "dtmWebAppLegalGuardian.legalGuardianDetails.generalDataProtectionRegulationLabel" | transloco }}</span>
                <div
                    [innerHtml]="'dtmWebAppLegalGuardian.legalGuardianDetails.generalDataProtectionRegulationValueShort' | transloco"
                ></div>
                <div
                    *ngIf="isLongGeneralDataProtectionRegulationVisible"
                    @slideIn
                    [innerHtml]="'dtmWebAppLegalGuardian.legalGuardianDetails.generalDataProtectionRegulationValueExtension' | transloco"
                ></div>
            </dtm-ui-checkbox-field>
            <button class="toggle-button" (click)="toggleGeneralDataProtectionRegulationText()" type="button">
                <ng-container *ngIf="isLongGeneralDataProtectionRegulationVisible">
                    {{ "dtmWebAppLegalGuardian.legalGuardianDetails.hideLabel" | transloco }}
                    <dtm-ui-icon name="arrow-up"></dtm-ui-icon>
                </ng-container>
                <ng-container *ngIf="!isLongGeneralDataProtectionRegulationVisible">
                    {{ "dtmWebAppLegalGuardian.legalGuardianDetails.expandLabel" | transloco }}
                    <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
                </ng-container>
            </button>
            <dtm-ui-form-errors class="preserve-error-space">
                <div
                    class="field-error"
                    *dtmUiFieldHasError="legalGuardianForm.controls.generalDataProtectionRegulation; name: 'required'"
                    [ngTemplateOutlet]="requiredErrorTemplate"
                ></div>
            </dtm-ui-form-errors>
        </div>

        <div class="actions">
            <button type="button" class="button-primary" (click)="manageFormAction(LegalGuardianFormActions.SendForSignature)">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.sendButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-secondary" (click)="manageFormAction(LegalGuardianFormActions.GeneratePDF)">
                {{ "dtmWebAppLegalGuardian.legalGuardianDetails.downloadFormButtonLabel" | transloco }}
            </button>
        </div>
    </div>
</mat-card>
<ng-template #requiredErrorTemplate>
    {{ "dtmWebAppLegalGuardian.legalGuardianDetails.requiredFieldError" | transloco }}
</ng-template>
<dtm-ui-error
    *ngIf="error$ | ngrxPush"
    [mode]="ErrorMode.Secondary"
    [errorMessage]="'dtmWebAppLegalGuardian.legalGuardianDetails.detailsFormUnknownErrorMessage' | transloco"
></dtm-ui-error>
