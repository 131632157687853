import { ForeignOperatorVerificationDetails } from "../../shared/models";
import { OperatorRegistrationFormValue } from "../services/models";

export namespace OperatorRegistrationActions {
    export class GetOperatorRegistrationCapabilities {
        public static readonly type = "[Operator registration] Get operator registration capabilities";

        constructor(public userId: string) {}
    }

    export class VerifyForeignOperator {
        public static readonly type = "[Operator registration] Verify foreign operator";

        constructor(public foreignOperator: ForeignOperatorVerificationDetails) {}
    }

    export class RegisterOperator {
        public static readonly type = "[Operator registration] Register operator";

        constructor(public operatorRegistrationFormValue: OperatorRegistrationFormValue) {}
    }

    export class DownloadAttorneyTemplate {
        public static readonly type = "[Operator registration] Download attorney template";

        constructor() {}
    }
}
