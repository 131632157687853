<dtm-ui-icon name="error-warning-fill" class="sail-alert-icon"></dtm-ui-icon>
<section>
    <p>
        {{ "dtmWebAppLibMission.sailAlertToast.toastText" | transloco : { value: sail$ | ngrxPush | sailLevel } }}
    </p>
    <button type="button" class="button-tertiary link" routerLink="/operator-conversations">
        {{ "dtmWebAppLibMission.sailAlertToast.buttonLabel" | transloco }}
    </button>
</section>
<button type="button" class="toast-close-button" (click)="remove()"><dtm-ui-icon name="close"></dtm-ui-icon></button>
