<ng-container *ngrxLet="filtersCount$; let filtersCount">
    <dtm-ui-filters-container [filtersCount]="filtersCount">
        <div class="filters-container">
            <dtm-ui-search-thread [isLabelVisible]="true" [formControl]="textSearchControl"></dtm-ui-search-thread>
            <dtm-ui-conversations-category-multi-select
                class="field"
                [formControl]="categoryControl"
                [categories]="conversationCategories$ | ngrxPush"
            ></dtm-ui-conversations-category-multi-select>
        </div>

        <dtm-ui-filter-chips-display
            *ngIf="filtersCount"
            selectedFiltersDisplaySlot
            [filtersMap]="FILTERS_MAP"
            [filtersCount]="filtersCount"
            [formGroup]="filtersFormGroup"
            [filtersValue]="filtersFormGroup.value"
            (allFiltersReset)="clearFilters()"
        >
        </dtm-ui-filter-chips-display>
    </dtm-ui-filters-container>
</ng-container>
