import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest, first, map } from "rxjs";
import { DashboardError, DashboardMission, DashboardMissionsFilters } from "../../services/dashboard.models";

interface DashboardMissionsComponentState {
    missions: DashboardMission[];
    isProcessing: boolean;
    error: DashboardError | undefined;
    incomingFilters: DashboardMissionsFilters | undefined;
    totalMissionElements: number;
    filters: DashboardMissionsFilters | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-dashboard-missions[missions]",
    templateUrl: "./dashboard-missions.component.html",
    styleUrls: ["./dashboard-missions.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DashboardMissionsComponent {
    @Input() public set missions(value: DashboardMission[] | undefined) {
        this.localStore.patchState({ missions: value ?? [] });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set error(value: DashboardError | undefined) {
        this.localStore.patchState({ error: value });
    }

    @Input() public set incomingFilters(value: DashboardMissionsFilters | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }

    @Input() public set totalMissionElements(value: number | undefined) {
        this.localStore.patchState({ totalMissionElements: value ?? 0 });
    }

    @Output() public filtersChange = new EventEmitter<DashboardMissionsFilters>();

    protected readonly missions$ = this.localStore.selectByKey("missions");
    protected readonly totalMissionElements$ = this.localStore.selectByKey("totalMissionElements");
    protected readonly filters$ = this.localStore.selectByKey("filters");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly error$ = this.localStore.selectByKey("error");
    protected readonly incomingFilters$ = this.localStore.selectByKey("incomingFilters");
    protected readonly shouldExpandFilters$ = combineLatest([this.incomingFilters$, this.missions$]).pipe(
        first(),
        map(([incomingFilters, missions]) => !(FunctionUtils.isNullOrUndefined(incomingFilters?.searchByText) && missions.length === 0))
    );

    constructor(private readonly localStore: LocalComponentStore<DashboardMissionsComponentState>, private readonly router: Router) {
        localStore.setState({
            missions: [],
            isProcessing: false,
            error: undefined,
            incomingFilters: undefined,
            totalMissionElements: 0,
            filters: undefined,
        });
    }

    protected changeFilters(filters: DashboardMissionsFilters) {
        this.localStore.patchState({ filters });
        this.filtersChange.emit(filters);
    }

    protected navigateToNewMission() {
        this.router.navigate(["mission/new"]);
    }
}
