import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { RegisterPilotOperatorLegalGuardian } from "../../../models";

interface PilotOperatorRegistrationLegalGuardianSummaryComponentState {
    legalGuardian: RegisterPilotOperatorLegalGuardian | undefined;
}

@Component({
    selector: "dtm-web-app-lib-pilot-operator-registration-legal-guardian-summary[legalGuardian]",
    templateUrl: "./pilot-operator-registration-legal-guardian-summary.component.html",
    styleUrls: ["../../../styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotOperatorRegistrationLegalGuardianSummaryComponent {
    @Input()
    public set legalGuardian(value: RegisterPilotOperatorLegalGuardian | undefined) {
        this.localStore.patchState({ legalGuardian: value });
    }

    protected readonly legalGuardian$ = this.localStore.selectByKey("legalGuardian").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<PilotOperatorRegistrationLegalGuardianSummaryComponentState>) {
        localStore.setState({ legalGuardian: undefined });
    }
}
