import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormGroup,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    EmptyStateMode,
    InvalidFormScrollableDirective,
    ScrollHookDirective,
} from "@dtm-frontend/shared/ui";
import {
    AnimationUtils,
    FunctionUtils,
    GRAMS_IN_KILOGRAM,
    LocalComponentStore,
    METERS_IN_KILOMETER,
    ONLY_WHITE_SPACES_VALIDATION_PATTERN,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, combineLatest, of, switchMap } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
    EquipmentType,
    NavigationAccuracy,
    NavigationAccuracyClass,
    NavigationAccuracyType,
    Tracker,
} from "../../../../services/uav.models";
import { Accessories, EditableEquipment, EditableParachuteEquipment, EditableTracking } from "./accessories-utils";

enum AccessoryType {
    Equipment = "EQUIPMENT",
    Parachute = "PARACHUTE",
    Tracking = "TRACKING",
}

interface SimpleEquipmentForm {
    id: FormControl<string | null>;
    type: FormControl<EquipmentType>;
    name: FormControl<string>;
    isEmbedded: FormControl<boolean>;
    weight: FormControl<number | null>;
}

interface ParachuteEquipmentForm {
    id: FormControl<string | null>;
    name: FormControl<string>;
    isEmbedded: FormControl<boolean>;
    weight: FormControl<number | null>;
    hasCeCertificate: FormControl<boolean>;
    minHeight: FormControl<number | null>;
    descentSpeed: FormControl<number | null>;
    maxWindSpeed: FormControl<number | null>;
    minOperatingTemperature: FormControl<number | null>;
    maxOperatingTemperature: FormControl<number | null>;
    isAstmF332218Compliant: FormControl<boolean>;
}

interface TrackingForm {
    id: FormControl<string | null>;
    trackerId: FormControl<string>;
    identifier: FormControl<string>;
    isEmbedded: FormControl<boolean>;
    weight: FormControl<number | null>;
    flightNavigationAccuracy: FormControl<NavigationAccuracyClass>;
    takeoffAndLandingNavigationAccuracy: FormControl<NavigationAccuracyClass>;
}

interface AccessoryForm {
    accessoryType: FormControl<AccessoryType>;
    group: UntypedFormGroup; // SimpleEquipmentForm or ParachuteEquipmentForm or TrackingForm
}

interface AccessoryFormValues {
    accessoryType: AccessoryType;
    group: EditableEquipment | EditableParachuteEquipment | EditableTracking;
}

interface ParachuteMaxWindSpeedParameters {
    maxTakeOffMass: number;
    maxDroneWidth: number;
}

const MAX_NAME_LENGTH = 50;
const MAX_TRACKING_IDENTIFIER_LENGTH = 100;
const MIN_PARACHUTE_MIN_HEIGHT_VALUE = 1;
const MAX_PARACHUTE_MIN_HEIGHT_VALUE = 100 * METERS_IN_KILOMETER;
const MAX_WEIGHT_VALUE = 100 * GRAMS_IN_KILOGRAM;
const MIN_DESCENT_SPEED_VALUE = 0.01;
const MAX_DESCENT_SPEED_VALUE = 10;
const LARGE_UAV_MAX_DESCENT_SPEED_VALUE = 8;
const MIN_TEMPERATURE_VALUE = -274;
const MAX_TEMPERATURE_VALUE = 2000;

interface AccessoriesControlComponentState {
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    parachuteMaxWindSpeedParameters: ParachuteMaxWindSpeedParameters | undefined;
    isParachuteInfoMessageVisible: boolean;
    isCustomUavMode: boolean;
}

const SLIDE_ANIMATION_DURATION = 150;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-accessories-control[trackers][navigationAccuracyItems]",
    templateUrl: "./accessories-control.component.html",
    styleUrls: ["./accessories-control.component.scss", "../setup-form-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccessoriesControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AccessoriesControlComponent),
            multi: true,
        },
    ],
    animations: [AnimationUtils.fadeAnimation(), AnimationUtils.slideInAnimation(SLIDE_ANIMATION_DURATION)],
})
export class AccessoriesControlComponent implements ControlValueAccessor, Validator {
    protected readonly AccessoryType = AccessoryType;
    protected readonly NavigationAccuracyType = NavigationAccuracyType;
    protected readonly EquipmentType = EquipmentType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly MAX_NAME_LENGTH = MAX_NAME_LENGTH;
    protected readonly MAX_TRACKING_IDENTIFIER_LENGTH = MAX_TRACKING_IDENTIFIER_LENGTH;
    protected readonly MIN_WEIGHT_VALUE = 0;
    protected readonly MAX_WEIGHT_VALUE = MAX_WEIGHT_VALUE;
    protected readonly MIN_PARACHUTE_MIN_HEIGHT_VALUE = MIN_PARACHUTE_MIN_HEIGHT_VALUE;
    protected readonly MAX_PARACHUTE_MIN_HEIGHT_VALUE = MAX_PARACHUTE_MIN_HEIGHT_VALUE;
    protected readonly MIN_DESCENT_SPEED_VALUE = MIN_DESCENT_SPEED_VALUE;
    protected readonly MAX_DESCENT_SPEED_VALUE = MAX_DESCENT_SPEED_VALUE;
    protected readonly LARGE_UAV_MAX_DESCENT_SPEED_VALUE = LARGE_UAV_MAX_DESCENT_SPEED_VALUE;
    protected readonly MIN_TEMPERATURE_VALUE = MIN_TEMPERATURE_VALUE;
    protected readonly MAX_TEMPERATURE_VALUE = MAX_TEMPERATURE_VALUE;

    @Input() public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input() public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Input() public set parachuteMaxWindSpeedParameters(value: ParachuteMaxWindSpeedParameters | undefined) {
        this.localStore.patchState({ parachuteMaxWindSpeedParameters: value });
    }

    @Input() public set isCustomUavMode(value: BooleanInput) {
        this.localStore.patchState({ isCustomUavMode: coerceBooleanProperty(value) });
    }

    @ViewChildren("accessoryPanel", { read: ElementRef }) private readonly accessoriesPanels!: QueryList<ElementRef<HTMLElement>>;
    @ViewChild(ScrollHookDirective) private parachutePanelHook!: ScrollHookDirective;
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly accessoriesControls$ = new BehaviorSubject<FormGroup<AccessoryForm>[]>([]);
    protected readonly trackers$ = this.localStore.selectByKey("trackers");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly isParachuteInfoMessageVisible$ = this.localStore.selectByKey("isParachuteInfoMessageVisible");
    protected readonly isCustomUavMode$ = this.localStore.selectByKey("isCustomUavMode");
    protected readonly navigationAccuracyClasses: NavigationAccuracyClass[] = Object.values(NavigationAccuracyClass);
    protected readonly itemsArray = new FormArray<FormGroup<AccessoryForm>>([]);
    protected readonly accessoriesForm = new FormGroup<{ items: FormArray }>({
        items: this.itemsArray,
    });
    protected readonly maxWindSpeed$ = this.initMaxWindSpeedObservable();

    private onTouched = FunctionUtils.noop;
    private propagateChange: (value: Accessories) => void = FunctionUtils.noop;

    constructor(
        private readonly localStore: LocalComponentStore<AccessoriesControlComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            trackers: [],
            navigationAccuracyItems: [],
            parachuteMaxWindSpeedParameters: undefined,
            isParachuteInfoMessageVisible: false,
            isCustomUavMode: false,
        });

        this.itemsArray.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.propagateChange(this.prepareResultAccessories(values as AccessoryFormValues[]));
        });
    }

    public registerOnChange(fn: (value: Accessories | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: Accessories | undefined): void {
        this.initAccessoriesForm(value ?? { equipment: [], parachuteEquipment: null, trackings: [] });
    }

    public validate(): ValidationErrors | null {
        if (this.itemsArray.invalid) {
            return { accessories: true };
        }

        return null;
    }

    public addSimpleEquipment(type: EquipmentType): void {
        this.itemsArray.insert(0, this.createSimpleEquipmentItemForm(type));
        this.updateAccessoriesControls();

        // NOTE: setTimeout needed to highlight new panel after render is finished
        setTimeout(() => this.highlightPanel(0));
    }

    public addParachute(): void {
        if (this.isParachuteCurrentlyAdded()) {
            this.showParachuteInfoMessage();
            this.scrollToAndHighlightParachutePanel();

            return;
        }

        this.itemsArray.insert(0, this.createParachuteEquipmentForm());
        this.updateAccessoriesControls();

        // NOTE: setTimeout needed to highlight new panel after render is finished
        setTimeout(() => this.highlightPanel(0));
    }

    public addTracking(): void {
        this.itemsArray.insert(0, this.createTrackingForm());
        this.updateAccessoriesControls();

        // NOTE: setTimeout needed to highlight new panel after render is finished
        setTimeout(() => this.highlightPanel(0));
    }

    public scrollToFirstInvalidField() {
        this.invalidFormScrollable.scrollToFirstInvalidField({
            behavior: "smooth",
            block: "center",
        });
    }

    protected tryToRemoveAccessoriesItem(index: number): void {
        const accessoryItem = this.itemsArray.at(index);

        this.confirmAccessoriesItemRemoval(this.getAccessoryLabel(accessoryItem))
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.removeAccessoriesItem(index);
            });
    }

    protected hideParachuteInfoMessage(): void {
        this.localStore.patchState({ isParachuteInfoMessageVisible: false });
    }

    private initAccessoriesForm(accessories: Accessories): void {
        this.itemsArray.clear({ emitEvent: false });

        for (const tracking of accessories.trackings) {
            this.itemsArray.push(this.createTrackingForm(tracking), { emitEvent: false });
        }

        for (const item of accessories.equipment) {
            this.itemsArray.push(this.createSimpleEquipmentItemForm(item.type, item), { emitEvent: false });
        }

        if (accessories.parachuteEquipment) {
            this.itemsArray.push(this.createParachuteEquipmentForm(accessories.parachuteEquipment), { emitEvent: false });
        }

        this.updateAccessoriesControls();
    }

    private initMaxWindSpeedObservable(): Observable<number | null> {
        const descentSpeed$: Observable<number | null> = this.accessoriesControls$.pipe(
            map((accessoriesControls) => {
                const parachutePanelIndex = this.getParachutePanelIndex();
                if (parachutePanelIndex === null) {
                    return null;
                }

                const parachutePanel = accessoriesControls?.[parachutePanelIndex];
                if (parachutePanel.controls.accessoryType.value !== AccessoryType.Parachute) {
                    return null;
                }

                return parachutePanel.controls.group.controls.descentSpeed;
            }),
            switchMap((descentSpeedControl) => {
                if (descentSpeedControl === null) {
                    return of(null);
                }

                return descentSpeedControl.valueChanges.pipe(
                    startWith(isNaN(descentSpeedControl.value) ? null : descentSpeedControl.value)
                );
            })
        );
        const parachuteMaxWindSpeedParameters$ = this.localStore.selectByKey("parachuteMaxWindSpeedParameters");

        return combineLatest([descentSpeed$, parachuteMaxWindSpeedParameters$]).pipe(
            map(([descentSpeed, parachuteMaxWindSpeedParameters]) =>
                this.calculateMaxWindSpeed(descentSpeed, parachuteMaxWindSpeedParameters ?? null)
            )
        );
    }

    private createTrackingForm(tracking?: EditableTracking): FormGroup<AccessoryForm> {
        const trackingGroup = new FormGroup<TrackingForm>({
            id: new FormControl<string | null>(tracking?.id ?? null),
            trackerId: new FormControl<string>(tracking?.trackerId ?? "", {
                validators: [Validators.required],
                nonNullable: true,
            }),
            identifier: new FormControl<string>(tracking?.identifier ?? "", {
                validators: [
                    Validators.required,
                    Validators.maxLength(this.MAX_TRACKING_IDENTIFIER_LENGTH),
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                ],
                nonNullable: true,
            }),
            isEmbedded: new FormControl<boolean>(tracking?.isEmbedded ?? false, {
                nonNullable: true,
            }),
            weight: new FormControl<number | null>(tracking?.weight ?? null, {
                nonNullable: true,
            }),
            flightNavigationAccuracy: new FormControl<NavigationAccuracyClass>(
                tracking?.flightNavigationAccuracy ?? NavigationAccuracyClass.Class2,
                {
                    validators: [Validators.required],
                    nonNullable: true,
                }
            ),
            takeoffAndLandingNavigationAccuracy: new FormControl<NavigationAccuracyClass>(
                tracking?.takeoffAndLandingNavigationAccuracy ?? NavigationAccuracyClass.Class2,
                {
                    validators: [Validators.required],
                    nonNullable: true,
                }
            ),
        });

        this.manageWeightValidators(trackingGroup.controls.isEmbedded.value, trackingGroup);
        trackingGroup.controls.isEmbedded.valueChanges.pipe(untilDestroyed(this)).subscribe((isEmbedded) => {
            this.manageWeightValidators(isEmbedded, trackingGroup);
        });

        return new FormGroup<AccessoryForm>({
            accessoryType: new FormControl<AccessoryType>(AccessoryType.Tracking, {
                nonNullable: true,
            }),
            group: trackingGroup,
        });
    }

    private manageWeightValidators(
        isEmbedded: boolean,
        group: FormGroup<TrackingForm> | FormGroup<SimpleEquipmentForm> | FormGroup<ParachuteEquipmentForm>
    ): void {
        if (isEmbedded) {
            group.controls.weight.clearValidators();
        } else {
            group.controls.weight.setValidators([
                Validators.required,
                Validators.min(this.MIN_WEIGHT_VALUE),
                Validators.max(this.MAX_WEIGHT_VALUE),
            ]);
        }
        group.controls.weight.updateValueAndValidity();
    }

    private createSimpleEquipmentItemForm(type: EquipmentType, equipment?: EditableEquipment): FormGroup<AccessoryForm> {
        const equipmentGroup = new FormGroup<SimpleEquipmentForm>({
            id: new FormControl<string | null>(equipment?.id ?? null),
            type: new FormControl<EquipmentType>(type, {
                nonNullable: true,
            }),
            name: new FormControl<string>(equipment?.name ?? "", {
                validators: [
                    Validators.required,
                    Validators.maxLength(this.MAX_NAME_LENGTH),
                    Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                ],
                nonNullable: true,
            }),
            isEmbedded: new FormControl<boolean>(equipment?.isEmbedded ?? false, {
                nonNullable: true,
            }),
            weight: new FormControl<number | null>(equipment?.weight ?? null, {
                validators: [Validators.required, Validators.min(this.MIN_WEIGHT_VALUE), Validators.max(this.MAX_WEIGHT_VALUE)],
                nonNullable: true,
            }),
        });

        return new FormGroup<AccessoryForm>({
            accessoryType: new FormControl<AccessoryType>(AccessoryType.Equipment, {
                nonNullable: true,
            }),
            group: equipmentGroup,
        });
    }

    private createParachuteEquipmentForm(parachute?: EditableParachuteEquipment): FormGroup<AccessoryForm> {
        const parachuteGroup = new FormGroup<ParachuteEquipmentForm>(
            {
                id: new FormControl<string | null>(parachute?.id ?? null),
                name: new FormControl<string>(parachute?.name ?? "", {
                    validators: [
                        Validators.required,
                        Validators.maxLength(this.MAX_NAME_LENGTH),
                        Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                    ],
                    nonNullable: true,
                }),
                isEmbedded: new FormControl<boolean>(parachute?.isEmbedded ?? false, {
                    nonNullable: true,
                }),
                weight: new FormControl<number | null>(parachute?.weight ?? null, {
                    validators: [Validators.required, Validators.min(this.MIN_WEIGHT_VALUE), Validators.max(this.MAX_WEIGHT_VALUE)],
                    nonNullable: true,
                }),
                hasCeCertificate: new FormControl<boolean>(parachute?.hasCeCertificate ?? false, {
                    nonNullable: true,
                }),
                minHeight: new FormControl<number | null>(parachute?.minHeight ?? null, {
                    validators: [
                        Validators.required,
                        Validators.min(this.MIN_PARACHUTE_MIN_HEIGHT_VALUE),
                        Validators.max(this.MAX_PARACHUTE_MIN_HEIGHT_VALUE),
                    ],
                    nonNullable: true,
                }),
                descentSpeed: new FormControl<number | null>(parachute?.descentSpeed ?? null, {
                    validators: [Validators.required],
                    nonNullable: true,
                }),
                maxWindSpeed: new FormControl<number | null>(parachute?.maxWindSpeed ?? null, {
                    nonNullable: true,
                }),
                minOperatingTemperature: new FormControl<number | null>(parachute?.minOperatingTemperature ?? null, {
                    validators: [
                        Validators.required,
                        Validators.min(this.MIN_TEMPERATURE_VALUE),
                        Validators.max(this.MAX_TEMPERATURE_VALUE),
                    ],
                    nonNullable: true,
                }),
                maxOperatingTemperature: new FormControl<number | null>(parachute?.maxOperatingTemperature ?? null, {
                    validators: [
                        Validators.required,
                        Validators.min(this.MIN_TEMPERATURE_VALUE),
                        Validators.max(this.MAX_TEMPERATURE_VALUE),
                    ],
                    nonNullable: true,
                }),
                isAstmF332218Compliant: new FormControl<boolean>(parachute?.isAstmF332218Compliant ?? false, {
                    nonNullable: true,
                }),
            },
            this.getParachuteOperatingTemperatureRangeValidator()
        );

        this.manageParachuteDescentSpeedValidators(this.localStore.selectSnapshotByKey("parachuteMaxWindSpeedParameters"), parachuteGroup);
        this.localStore
            .selectByKey("parachuteMaxWindSpeedParameters")
            .pipe(untilDestroyed(this))
            .subscribe((parachuteMaxWindSpeedParameters) => {
                this.manageParachuteDescentSpeedValidators(parachuteMaxWindSpeedParameters, parachuteGroup);
            });

        return new FormGroup<AccessoryForm>({
            accessoryType: new FormControl<AccessoryType>(AccessoryType.Parachute, {
                nonNullable: true,
            }),
            group: parachuteGroup,
        });
    }

    private manageParachuteDescentSpeedValidators(
        parachuteMaxWindSpeedParameters: ParachuteMaxWindSpeedParameters | undefined,
        parachuteForm: FormGroup<ParachuteEquipmentForm>
    ): void {
        const validators: ValidatorFn[] = [Validators.required, Validators.min(this.MIN_DESCENT_SPEED_VALUE)];

        if (parachuteMaxWindSpeedParameters?.maxDroneWidth && parachuteMaxWindSpeedParameters?.maxTakeOffMass) {
            if (this.isLargeUav(parachuteMaxWindSpeedParameters.maxDroneWidth, parachuteMaxWindSpeedParameters.maxTakeOffMass)) {
                validators.push(Validators.max(this.LARGE_UAV_MAX_DESCENT_SPEED_VALUE));
            } else {
                validators.push(Validators.max(this.MAX_DESCENT_SPEED_VALUE));
            }
        }

        parachuteForm.controls.descentSpeed.setValidators(validators);
        parachuteForm.controls.descentSpeed.updateValueAndValidity();
    }

    private confirmAccessoriesItemRemoval(typeLabel: string | undefined): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.setupForm.accessories.removalConfirmationDialog.titleText"),
                    confirmationText: typeLabel
                        ? this.transloco.translate(
                              "dtmWebAppLibUav.setupForm.accessories.removalConfirmationDialog.dialogMessageWithType",
                              {
                                  type: typeLabel,
                              }
                          )
                        : this.transloco.translate("dtmWebAppLibUav.setupForm.accessories.removalConfirmationDialog.dialogMessage"),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.setupForm.accessories.removalConfirmationDialog.declineButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibUav.setupForm.accessories.removalConfirmationDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private removeAccessoriesItem(index: number): void {
        this.itemsArray.removeAt(index);
        this.updateAccessoriesControls();
    }

    private updateAccessoriesControls() {
        this.accessoriesControls$.next(this.itemsArray.controls);
    }

    private highlightPanel(index: number): void {
        const panel = this.accessoriesPanels.get(index);

        if (panel) {
            panel.nativeElement.classList.add("highlight");
            setTimeout(() => panel.nativeElement.classList.remove("highlight"), 1000);
        }
    }

    private getAccessoryLabel(accessory: FormGroup<AccessoryForm>): string | undefined {
        if (accessory.controls.accessoryType.value === AccessoryType.Equipment) {
            const equipmentType = accessory.controls.group.controls.type.value;

            return this.transloco.translate("dtmWebAppLibUav.uavProperties.equipment.typeValue", { value: equipmentType });
        }

        if (accessory.controls.accessoryType.value === AccessoryType.Parachute) {
            return this.transloco.translate("dtmWebAppLibUav.uavProperties.equipment.typeValue", { value: EquipmentType.Parachute });
        }

        if (accessory.controls.accessoryType.value === AccessoryType.Tracking) {
            return this.transloco.translate("dtmWebAppLibUav.uavProperties.trackings.sectionHeader");
        }

        return undefined;
    }

    private prepareResultAccessories(values: AccessoryFormValues[]): Accessories {
        const accessories: Accessories = {
            equipment: [],
            parachuteEquipment: null,
            trackings: [],
        };

        for (const accessoryItem of values) {
            if (accessoryItem.accessoryType === AccessoryType.Equipment) {
                accessories.equipment.push(accessoryItem.group as EditableEquipment);
            } else if (accessoryItem.accessoryType === AccessoryType.Parachute) {
                accessories.parachuteEquipment = accessoryItem.group as EditableParachuteEquipment;
            } else if (accessoryItem.accessoryType === AccessoryType.Tracking) {
                accessories.trackings.push(accessoryItem.group as EditableTracking);
            }
        }

        return accessories;
    }

    private getParachutePanelIndex(): number | null {
        const parachutePanelIndex = this.itemsArray.controls.findIndex(
            (accessoryFormGroup) => accessoryFormGroup.controls.accessoryType.value === AccessoryType.Parachute
        );

        return parachutePanelIndex >= 0 ? parachutePanelIndex : null;
    }

    private isParachuteCurrentlyAdded(): boolean {
        return this.getParachutePanelIndex() !== null;
    }

    private highlightParachutePanel(): void {
        const parachutePanelIndex = this.getParachutePanelIndex();

        if (parachutePanelIndex !== null) {
            this.highlightPanel(parachutePanelIndex);
        }
    }

    private showParachuteInfoMessage(): void {
        this.localStore.patchState({ isParachuteInfoMessageVisible: true });
    }

    private scrollToAndHighlightParachutePanel(): void {
        const parachutePanelElement = this.parachutePanelHook.getElement();
        const intersectionObserver = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                this.highlightParachutePanel();
                intersectionObserver.unobserve(parachutePanelElement);
            }
        });

        intersectionObserver.observe(parachutePanelElement);

        this.parachutePanelHook.scrollTo();
    }

    private getParachuteOperatingTemperatureRangeValidator(): ValidatorFn {
        return (parachuteFormGroup): ValidationErrors | null => {
            const minTemperature = (parachuteFormGroup as FormGroup<ParachuteEquipmentForm>).controls.minOperatingTemperature.value;
            const maxTemperature = (parachuteFormGroup as FormGroup<ParachuteEquipmentForm>).controls.maxOperatingTemperature.value;

            if (minTemperature === null || maxTemperature === null || minTemperature <= maxTemperature) {
                return null;
            }

            return { temperatureRange: true };
        };
    }

    private calculateMaxWindSpeed(descentSpeed: number | null, parameters: ParachuteMaxWindSpeedParameters | null): number | null {
        if (!descentSpeed || !parameters?.maxDroneWidth || !parameters?.maxTakeOffMass) {
            return null;
        }

        const { maxDroneWidth, maxTakeOffMass } = parameters;

        let maxWindSpeedInMetersPerSecond = 14;

        if (!this.isLargeUav(maxDroneWidth, maxTakeOffMass)) {
            // NOTE: Maximum wind speed combined with descent speed cannot be greater than 10 m/s
            // eslint-disable-next-line no-magic-numbers
            maxWindSpeedInMetersPerSecond = Math.sqrt(10 ** 2 - descentSpeed ** 2);
        }

        return isNaN(maxWindSpeedInMetersPerSecond) ? null : maxWindSpeedInMetersPerSecond;
    }

    private isLargeUav(maxDroneWidth: number, maxTakeOffMass: number): boolean {
        // eslint-disable-next-line no-magic-numbers
        return maxDroneWidth > 3 || maxTakeOffMass > 25;
    }
}
