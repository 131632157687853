import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, combineLatest, defer, of, switchMap } from "rxjs";
import { map, tap } from "rxjs/operators";
import { GuideChapterItem } from "../../../shared/models/operations-manual.models";
import { UserContextService } from "../../../shared/operator-context/services/user-context.service";
import { OperatorContextState } from "../../../shared/operator-context/state/operator-context.state";
import { AttachmentsHelperService } from "../../services/attachments-helper.service";
import {
    Chapter,
    ChapterType,
    ChapterWithSubchapters,
    ContentEditData,
    OperationsManualError,
    OperationsManualErrorType,
    OperationsManualVersion,
    TableOfContentsChapter,
} from "../../services/operations-manual.models";
import { OperationsManualActions } from "../../state/operations-manual.actions";
import { OperationsManualState } from "../../state/operations-manual.state";
import { SubchaptersComponent } from "../chapters/subchapters/subchapters.component";
import { ManageAttachmentsDialogComponent } from "../manage-attachments-dialog/manage-attachments-dialog.component";

interface MainComponentState {
    editedChapterId: string | null;
    areActionsBlocked: boolean;
    selectedChapterId: string | null;
    isGuidePanelVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-opsman-main",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.foldAnimation()],
})
export class MainComponent implements OnDestroy {
    protected readonly OperationsManualErrorType = OperationsManualErrorType;
    protected readonly capabilities$ = this.store.select(OperationsManualState.capabilities).pipe(RxjsUtils.filterFalsy());
    protected readonly capabilitiesError$ = this.store.select(OperationsManualState.capabilitiesError);
    protected readonly isProcessing$ = this.store.select(OperationsManualState.isProcessing);
    protected readonly lastUpdateDate$ = this.store.select(OperationsManualState.lastUpdateDate);
    protected readonly tableOfContentsChapters$ = this.store.select(OperationsManualState.tableOfContentsChapters);
    protected readonly isChapterProcessing$ = this.store.select(OperationsManualState.isChapterProcessing);
    protected readonly chapter$ = this.store.select(OperationsManualState.chapter);
    protected readonly editedChapterId$ = this.localStore.selectByKey("editedChapterId");
    protected readonly areActionsBlocked$ = this.localStore.selectByKey("areActionsBlocked");
    protected readonly isGuidePanelVisible$ = this.localStore.selectByKey("isGuidePanelVisible");
    protected readonly guideChapterItems$: Observable<GuideChapterItem[]> = this.chapter$.pipe(
        map((chapter) => this.prepareGuideItems(chapter))
    );
    protected readonly highlightedChapterId$: Observable<string | undefined> = combineLatest([
        this.editedChapterId$,
        this.localStore.selectByKey("selectedChapterId"),
    ]).pipe(map(([editedChapterId, selectedChapterId]) => editedChapterId ?? selectedChapterId ?? undefined));
    protected readonly ChapterType = ChapterType;

    @ViewChild(SubchaptersComponent) private chapterWithSubchapters: SubchaptersComponent | undefined;
    private contextSwitchTry$ = this.initContextSwitchTry();

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<MainComponentState>,
        private readonly toastService: ToastrService,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService,
        private readonly userContextService: UserContextService,
        private readonly attachmentsHelperService: AttachmentsHelperService
    ) {
        this.localStore.setState({
            editedChapterId: null,
            areActionsBlocked: false,
            selectedChapterId: null,
            isGuidePanelVisible: false,
        });

        this.handleOperatorContextChange();
        this.watchCurrentOperationsManualChange();
    }

    public ngOnDestroy() {
        this.store.dispatch(new OperationsManualActions.ResetData());
        this.userContextService.unregisterContextSwitchTry(this.contextSwitchTry$);
    }

    protected refresh() {
        this.refreshView();
    }

    protected generateNewVersion() {
        const capabilities = this.store.selectSnapshot(OperationsManualState.capabilities);
        const newVersionGenerationConfirmed$ = !capabilities?.operationsManuals.length
            ? of(true)
            : this.confirmNewVersionGeneration().pipe(RxjsUtils.filterFalsy());

        this.leaveEditMode();

        newVersionGenerationConfirmed$
            .pipe(
                switchMap(() => this.store.dispatch(new OperationsManualActions.CreateOperationsManual())),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.createOperationsManualError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibOpsMan.createOperationsManual.genericErrorMessage")
                    );

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibOpsMan.createOperationsManual.successMessage"));
                this.refreshView();
            });
    }

    protected setCurrentOperationsManual(operationsManual: OperationsManualVersion) {
        this.leaveEditMode();
        this.store.dispatch(new OperationsManualActions.SetCurrentOperationsManual(operationsManual));
    }

    protected publish() {
        const currentOperationsManual = this.getCurrentOperationsManual();
        if (!currentOperationsManual) {
            return;
        }

        this.leaveEditMode();

        this.store
            .dispatch(new OperationsManualActions.Publish())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.publishError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibOpsMan.publishOperationsManual.genericErrorMessage")
                    );

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmWebAppLibOpsMan.publishOperationsManual.successMessage", {
                        version: currentOperationsManual.version,
                    })
                );
                this.refreshView();
            });
    }

    protected downloadPdf() {
        const currentOperationsManual = this.getCurrentOperationsManual();
        if (!currentOperationsManual) {
            return;
        }

        this.leaveEditMode();

        this.store
            .dispatch(new OperationsManualActions.DownloadPdf(this.getPdfFileName(currentOperationsManual)))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.downloadPdfError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibOpsMan.downloadOperationsManual.genericErrorMessage")
                    );
                }
            });
    }

    protected selectChapter({ id, name }: TableOfContentsChapter | Chapter) {
        this.leaveEditMode();

        const currentSelectedChapter = this.store.selectSnapshot(OperationsManualState.chapter);

        if (
            currentSelectedChapter?.type === ChapterType.SubchaptersContent &&
            this.isSubchapterOfChapter(currentSelectedChapter as ChapterWithSubchapters, id)
        ) {
            this.scrollToSubchapter(id);
            this.localStore.patchState({ selectedChapterId: id });
        } else {
            this.loadChapter(id, name);
        }
    }

    protected setEditedChapterId(editedChapterId: string | null) {
        this.localStore.patchState({ editedChapterId });
    }

    protected setActionsBlock(areActionsBlocked: boolean) {
        this.localStore.patchState({ areActionsBlocked });
    }

    protected saveChapterContent(data: ContentEditData) {
        const currentOperationsManual = this.getCurrentOperationsManual();
        if (!currentOperationsManual) {
            return;
        }

        this.store
            .dispatch(new OperationsManualActions.SaveChapter(data.chapter.id, data.content))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.saveChapterError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ?? this.transloco.translate("dtmWebAppLibOpsMan.chapter.saveChapterErrorMessage")
                    );
                } else {
                    this.leaveEditMode();

                    const currentSelectedChapter = this.store.selectSnapshot(OperationsManualState.chapter);
                    if (currentSelectedChapter) {
                        this.loadChapter(currentSelectedChapter.id, currentSelectedChapter.name);
                    }
                }
            });
    }

    protected openAttachmentsManagementDialog(chapter: Chapter) {
        const currentOperationsManual = this.getCurrentOperationsManual();
        if (!currentOperationsManual) {
            return;
        }

        this.leaveEditMode();

        const dialogRef = this.dialogService.open(ManageAttachmentsDialogComponent, {
            data: {
                attachments: this.attachmentsHelperService.convertAttachmentsToFilesGroups(
                    chapter.attachments,
                    chapter.attachmentsFeature.config
                ),
                additionalPathParams: { chapterId: chapter.id },
                isProcessing$: this.isProcessing$,
            },
            disableClose: true,
        });

        dialogRef.componentInstance.setAttachments$
            .pipe(
                switchMap((attachments) => this.store.dispatch(new OperationsManualActions.SetChapterAttachments(chapter.id, attachments))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.setAttachmentsError);

                if (error) {
                    this.toastService.error(this.transloco.translate("dtmWebAppLibOpsMan.attachments.genericErrorMessage"));
                    dialogRef.close();

                    return;
                }

                this.toastService.success(this.transloco.translate("dtmWebAppLibOpsMan.attachments.successMessage"));
                dialogRef.close();

                const currentSelectedChapter = this.store.selectSnapshot(OperationsManualState.chapter);
                if (currentSelectedChapter) {
                    this.loadChapter(currentSelectedChapter.id, currentSelectedChapter.name);
                }
            });
    }

    protected addSubchapter(name: string) {
        const currentSelectedChapter = this.store.selectSnapshot(OperationsManualState.chapter);
        if (!currentSelectedChapter) {
            return;
        }

        this.store
            .dispatch(new OperationsManualActions.AddSubchapter(currentSelectedChapter.id, name))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.addSubchapterError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibOpsMan.chapter.addSubchapterErrorMessage")
                    );
                } else {
                    this.loadChapter(currentSelectedChapter.id, currentSelectedChapter.name);
                }
            });
    }

    protected removeSubchapter(subchapter: Chapter) {
        const currentSelectedChapter = this.store.selectSnapshot(OperationsManualState.chapter);
        if (!currentSelectedChapter) {
            return;
        }

        this.store
            .dispatch(new OperationsManualActions.RemoveSubchapter(currentSelectedChapter.id, subchapter.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.removeSubchapterError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibOpsMan.chapter.removeSubchapterErrorMessage")
                    );
                } else {
                    this.loadChapter(currentSelectedChapter.id, currentSelectedChapter.name);
                }
            });
    }

    protected setGuidePanelVisibility(isVisible: boolean) {
        this.localStore.patchState({ isGuidePanelVisible: isVisible });
    }

    private initContextSwitchTry(): Observable<boolean> {
        return defer(() =>
            this.localStore.selectSnapshotByKey("editedChapterId")
                ? this.confirmContextSwitch().pipe(
                      tap((isConfirmed) => {
                          if (isConfirmed) {
                              this.leaveEditMode();
                          }
                      })
                  )
                : of(true)
        );
    }

    private handleOperatorContextChange() {
        this.userContextService.registerContextSwitchTry(this.contextSwitchTry$);

        this.store
            .select(OperatorContextState.selectedContextId)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.refreshView());
    }

    private watchCurrentOperationsManualChange() {
        this.store
            .select(OperationsManualState.currentOperationsManual)
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new OperationsManualActions.GetTableOfContents(this.getContentsTableFilters()))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.getDataError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibOpsMan.tableOfContents.loadingErrorMessage")
                    );

                    return;
                }
            });
    }

    private refreshView() {
        this.store.dispatch(new OperationsManualActions.GetCapabilities());
    }

    private loadChapter(chapterId: string, chapterName: string) {
        const currentOperationsManual = this.getCurrentOperationsManual();
        if (!currentOperationsManual) {
            return;
        }

        this.store
            .dispatch(new OperationsManualActions.GetChapter(chapterId, this.getContentsTableFilters()))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationsManualState.getChapterError);

                if (error) {
                    this.toastService.error(
                        this.getGeneralErrorMessage(error) ??
                            this.transloco.translate("dtmWebAppLibOpsMan.chapter.genericErrorMessage", { chapterName })
                    );

                    return;
                }

                this.localStore.patchState({ selectedChapterId: chapterId });
            });
    }

    private getGeneralErrorMessage(error: OperationsManualError): string | undefined {
        switch (error.type) {
            case OperationsManualErrorType.InvalidOperator:
                return this.transloco.translate("dtmWebAppLibOpsMan.invalidOperatorErrorMessage");
            case OperationsManualErrorType.InvalidOperationsManual:
                return this.transloco.translate("dtmWebAppLibOpsMan.invalidOperationsManualErrorMessage");
            default:
                return undefined;
        }
    }

    private confirmContextSwitch(): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.confirmUserContextSwitchDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibOpsMan.confirmUserContextSwitchDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.confirmUserContextSwitchDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.confirmUserContextSwitchDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private confirmNewVersionGeneration(): Observable<boolean> {
        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.createOperationsManual.confirmationDialog.titleText"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibOpsMan.createOperationsManual.confirmationDialog.dialogMessage"
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.createOperationsManual.confirmationDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.createOperationsManual.confirmationDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private getContentsTableFilters(): string[] {
        return this.store.selectSnapshot(OperationsManualState.capabilities)?.availableContentsTableFilters ?? [];
    }

    private getPdfFileName(operationsManual: OperationsManualVersion): string {
        return this.transloco.translate("dtmWebAppLibOpsMan.downloadOperationsManual.pdfFileName", {
            version: operationsManual.version,
            isPublished: operationsManual.isPublished,
        });
    }

    private getCurrentOperationsManual(): OperationsManualVersion | undefined {
        const currentOperationsManual = this.store.selectSnapshot(OperationsManualState.currentOperationsManual);

        if (!currentOperationsManual) {
            this.toastService.error(this.getGeneralErrorMessage({ type: OperationsManualErrorType.InvalidOperationsManual }));
        }

        return currentOperationsManual;
    }

    private isSubchapterOfChapter(chapter: ChapterWithSubchapters, subchapterId: string): boolean {
        return chapter.subchapters.some((subchapter) => subchapter.id === subchapterId);
    }

    private scrollToSubchapter(subchapterId: string) {
        this.chapterWithSubchapters?.scrollToSubchapter(subchapterId);
    }

    private leaveEditMode() {
        this.setEditedChapterId(null);
        this.setActionsBlock(false);
    }

    private prepareGuideItems(chapter: Chapter | undefined): GuideChapterItem[] {
        const result: GuideChapterItem[] = [];

        if (!chapter) {
            return result;
        }

        if (chapter.guide) {
            result.push({
                chapterId: chapter.id,
                title: chapter.name,
                content: chapter.guide,
            });
        }

        if (chapter.type === ChapterType.SubchaptersContent) {
            for (const subchapter of chapter.subchapters) {
                result.push({
                    chapterId: subchapter.id,
                    title: subchapter.name,
                    content: subchapter.guide,
                });
            }
        }

        return result;
    }
}
