<div class="grid grid-maxsize-8">
    <dtm-ui-lib-wizard-content
        [wizardId]="OPERATOR_MIGRATION_WIZARD_ID"
        *ngrxLet="{ isProcessing: isProcessing$, importedDetails: importedDetails$ } as vm"
    >
        <dtm-web-app-lib-operator-migration-authentication-step
            *dtmUiLibWizardStepContent="OperatorMigrationWizardSteps.Authentication"
            (authenticationDetailsSave)="authenticate($event)"
            [isProcessing]="vm.isProcessing"
            [initialFormValue]="authenticationDetails$ | ngrxPush"
            [authenticationError]="authenticationError$ | ngrxPush"
            (resetPasswordStepRedirect)="goToResetPasswordStep()"
        ></dtm-web-app-lib-operator-migration-authentication-step>
        <dtm-web-app-lib-operator-migration-reset-password-step
            *dtmUiLibWizardStepContent="OperatorMigrationWizardSteps.ResetPassword"
            [isProcessing]="vm.isProcessing"
            (resetPasswordSubmit)="submitResetPasswordForm($event)"
            (passwordResetCancel)="cancelPasswordReset()"
        ></dtm-web-app-lib-operator-migration-reset-password-step>
        <dtm-web-app-lib-operator-migration-details-step
            *dtmUiLibWizardStepContent="OperatorMigrationWizardSteps.DataImport"
            [importedDetails]="vm.importedDetails"
            (operatorMigrationDetailsSave)="saveDetailsStep($event)"
            (back)="goToAuthenticationStep()"
        ></dtm-web-app-lib-operator-migration-details-step>
        <dtm-web-app-lib-operator-migration-summary-step
            *dtmUiLibWizardStepContent="OperatorMigrationWizardSteps.Summary"
            [migrationDetails]="migrationDetails$ | ngrxPush"
            [importedDetails]="vm.importedDetails"
            [isProcessing]="vm.isProcessing"
            (save)="saveMigrationDetails()"
            (back)="goToDataImportStep()"
        ></dtm-web-app-lib-operator-migration-summary-step>
    </dtm-ui-lib-wizard-content>
</div>
