<div class="summary-section" *ngrxLet="address$ as address">
    <dtm-ui-label-value [label]="'dtmWebAppLibPilotOperatorRegistration.summary.countryLabel' | transloco">
        <dtm-ui-country-display [country]="address.country"></dtm-ui-country-display>
    </dtm-ui-label-value>
    <dtm-ui-label-value [label]="'dtmWebAppLibPilotOperatorRegistration.summary.streetLabel' | transloco">
        {{ address.streetName }}
        <ng-container *ngIf="address.apartmentNumber; else houseNumberTemplate">
            {{ address.houseNumber }}&nbsp;/&nbsp;{{ address.apartmentNumber }}
        </ng-container>
        <ng-template #houseNumberTemplate>{{ address.houseNumber }}</ng-template>
    </dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibPilotOperatorRegistration.summary.postCodeLabel' | transloco"
        [value]="address.postCode | postCode : address.country"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        [label]="'dtmWebAppLibPilotOperatorRegistration.summary.cityLabel' | transloco"
        [value]="address.city"
    ></dtm-ui-label-value>
</div>
