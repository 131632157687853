<ng-container *ngrxLet="post$ as post">
    <div class="header">
        <section>
            <div class="post-date">{{ post.date | localizeDate }}</div>
            <div class="post-header">{{ post.title }}</div>
        </section>
        <a *ngIf="post.link" rel="noopener noreferrer" [href]="post.link" target="_blank" class="button-secondary">
            {{ "dtmWebAppLibDashboard.postItem.moreInfoButtonLabel" | transloco }}
            <dtm-ui-icon name="external-link"></dtm-ui-icon>
        </a>
    </div>
    <p class="post-content" [innerHtml]="post.content"></p>
</ng-container>
