import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi, UploadedFile, isUploadedFileInfectedError } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ATTORNEY_DOCUMENT_ENDPOINTS, AttorneyDocumentEndpoints } from "./attorney-documents-api.token";

@Injectable()
export class AttorneyDocumentsApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(ATTORNEY_DOCUMENT_ENDPOINTS)
        private readonly attorneyDocument: AttorneyDocumentEndpoints
    ) {}

    public getFile(fileId: string): Observable<Blob> {
        return this.httpClient.get(StringUtils.replaceInTemplate(this.attorneyDocument.downloadFile, { id: fileId }), {
            responseType: "blob",
            context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true),
        });
    }

    public getFilesCompressed(fileIds: string[], fileName: string): Observable<Blob> {
        console.error("Not implemented");

        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        if (error.status === HttpStatusCode.PayloadTooLarge) {
            return { type: FileUploadErrorType.MaxSizeExceeded };
        }
        if (isUploadedFileInfectedError(error)) {
            return { type: FileUploadErrorType.InfectedFile };
        }

        return { type: FileUploadErrorType.Unknown };
    }

    public uploadFile(file: File): Observable<HttpEvent<UploadedFile>> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.httpClient
            .post<UploadedFileBody>(this.attorneyDocument.uploadFile, formData, {
                reportProgress: true,
                responseType: "json",
                observe: "events",
            })
            .pipe(catchError((error: HttpErrorResponse) => throwError(() => this.manageUploadError(error))));
    }
}
