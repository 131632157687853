<ng-container *ngrxLet="isEditModeOn$ as isEditModeOn">
    <ng-container *ngrxLet="legalGuardian$ as legalGuardian">
        <ng-container *ngrxLet="legalGuardianConfirmationRequest$ as legalGuardianConfirmationRequest">
            <dtm-ui-empty-state *ngIf="!legalGuardian && !legalGuardianConfirmationRequest && !isEditModeOn; else editableCardTemplate">
                <ng-container titleSlot>
                    {{ "dtmWebAppUserProfile.legalGuardian.emptyStateHeader" | transloco }}
                </ng-container>
                <img imageSlot src="assets/images/legal-guardian-empty-state.svg" />
                <p messageSlot>
                    {{ "dtmWebAppUserProfile.legalGuardian.emptyStateText" | transloco }}
                </p>
                <button footerSlot type="button" class="button-primary" (click)="manageFormState(true)">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    {{ "dtmWebAppUserProfile.legalGuardian.addDetailsForConsentLabel" | transloco }}
                </button>
            </dtm-ui-empty-state>

            <ng-template #editableCardTemplate>
                <dtm-ui-editable-card
                    [label]="'dtmWebAppUserProfile.legalGuardian.legalGuardianHeader' | transloco"
                    [isEditButtonVisible]="isEditButtonVisible$ | ngrxPush"
                    (editModeChange)="manageFormState($event)"
                    [isEditModeOn]="isEditModeOn"
                    (actionSave)="save()"
                    [canLeaveEditModeOnSave]="false"
                >
                    <div previewContent>
                        <ng-container *ngIf="legalGuardianConfirmationRequest">
                            <div class="legal-guardian-status">
                                <span class="chip secondary">
                                    <dtm-ui-icon name="markup"></dtm-ui-icon>
                                    {{ "dtmWebAppUserProfile.legalGuardian.waitingForApproval" | transloco }}
                                </span>
                                <button type="button" class="button-secondary" (click)="resend.emit()">
                                    <dtm-ui-icon name="refresh"></dtm-ui-icon>
                                    {{ "dtmWebAppUserProfile.legalGuardian.resendEmailAddress" | transloco }}
                                </button>
                            </div>
                            <div class="details">
                                <ng-template
                                    [ngTemplateOutlet]="legalGuardianRequestTemplate"
                                    [ngTemplateOutletContext]="{ $implicit: legalGuardianConfirmationRequest }"
                                ></ng-template>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="legalGuardian">
                            <span class="chip success">
                                <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                                {{ "dtmWebAppUserProfile.legalGuardian.approvedLabel" | transloco }}
                            </span>
                            <div class="details">
                                <dtm-ui-label-value
                                    class="wrapped-value"
                                    [label]="'dtmWebAppUserProfile.legalGuardian.firstNameLabel' | transloco"
                                    [value]="legalGuardian.firstName"
                                >
                                </dtm-ui-label-value>
                                <dtm-ui-label-value
                                    class="wrapped-value"
                                    [label]="'dtmWebAppUserProfile.legalGuardian.lastNameLabel' | transloco"
                                    [value]="legalGuardian.lastName"
                                >
                                </dtm-ui-label-value>
                                <dtm-ui-label-value
                                    [label]="'dtmWebAppUserProfile.legalGuardian.dateOfBirthLabel' | transloco"
                                    [value]="legalGuardian.dateOfBirth | localizeDate"
                                >
                                </dtm-ui-label-value>
                                <dtm-ui-label-value
                                    class="wrapped-value"
                                    [label]="'dtmWebAppUserProfile.legalGuardian.emailLabel' | transloco"
                                    [value]="legalGuardian.email"
                                    [canCopy]="true"
                                >
                                </dtm-ui-label-value>
                                <dtm-ui-address-label-value
                                    [address]="legalGuardian.address"
                                    [isCountryFlagVisible]="true"
                                ></dtm-ui-address-label-value>
                                <dtm-ui-label-value
                                    [label]="'dtmWebAppUserProfile.legalGuardian.phoneNumberLabel' | transloco"
                                    [value]="legalGuardian.phoneNumber | formatPhoneNumber"
                                    [canCopy]="true"
                                >
                                </dtm-ui-label-value>
                                <dtm-ui-label-value [label]="'dtmWebAppUserProfile.legalGuardian.nationalityLabel' | transloco">
                                    <dtm-ui-country-display [country]="legalGuardian.nationality"></dtm-ui-country-display>
                                </dtm-ui-label-value>
                            </div>
                        </ng-container>
                    </div>
                    <div editableContent>
                        <div class="legal-guardian-form" [formGroup]="legalGuardianForm">
                            <dtm-ui-input-field>
                                <label>{{ "dtmWebAppUserProfile.legalGuardian.firstNameLabel" | transloco }}</label>
                                <input matInput formControlName="firstName" />
                                <div
                                    class="field-error"
                                    *dtmUiFieldHasError="legalGuardianForm.controls.firstName; name: ['required', 'pattern']"
                                    [ngTemplateOutlet]="requiredErrorTemplate"
                                ></div>
                                <div
                                    class="field-error"
                                    *dtmUiFieldHasError="legalGuardianForm.controls.firstName; name: 'maxlength'; error as error"
                                >
                                    {{
                                        "dtmWebAppUserProfile.legalGuardian.firstNameMaxLengthError"
                                            | transloco : { max: error.requiredLength }
                                    }}
                                </div>
                            </dtm-ui-input-field>
                            <dtm-ui-input-field>
                                <label>{{ "dtmWebAppUserProfile.legalGuardian.lastNameLabel" | transloco }}</label>
                                <input matInput formControlName="lastName" />
                                <div
                                    class="field-error"
                                    *dtmUiFieldHasError="legalGuardianForm.controls.lastName; name: ['required', 'pattern']"
                                    [ngTemplateOutlet]="requiredErrorTemplate"
                                ></div>
                                <div
                                    class="field-error"
                                    *dtmUiFieldHasError="legalGuardianForm.controls.lastName; name: 'maxlength'; error as error"
                                >
                                    {{
                                        "dtmWebAppUserProfile.legalGuardian.lastNameMaxLengthError"
                                            | transloco : { max: error.requiredLength }
                                    }}
                                </div>
                            </dtm-ui-input-field>
                            <dtm-ui-input-field>
                                <label>{{ "dtmWebAppUserProfile.legalGuardian.emailLabel" | transloco }}</label>
                                <input matInput formControlName="email" />
                                <div
                                    class="field-error"
                                    *dtmUiFieldHasError="legalGuardianForm.controls.email; name: ['required', 'pattern']"
                                    [ngTemplateOutlet]="requiredErrorTemplate"
                                ></div>
                                <div class="field-error" *dtmUiFieldHasError="legalGuardianForm.controls.email; name: 'email'">
                                    {{ "dtmWebAppUserProfile.legalGuardian.invalidEmailError" | transloco }}
                                </div>
                            </dtm-ui-input-field>
                        </div>
                    </div>
                </dtm-ui-editable-card>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #legalGuardianRequestTemplate let-legalGuardian>
    <dtm-ui-label-value
        class="wrapped-value"
        [label]="'dtmWebAppUserProfile.legalGuardian.firstNameLabel' | transloco"
        [value]="legalGuardian.firstName"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        class="wrapped-value"
        [label]="'dtmWebAppUserProfile.legalGuardian.lastNameLabel' | transloco"
        [value]="legalGuardian.lastName"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        class="wrapped-value"
        [label]="'dtmWebAppUserProfile.legalGuardian.emailLabel' | transloco"
        [value]="legalGuardian.email"
        [canCopy]="true"
    >
    </dtm-ui-label-value>
</ng-template>
<ng-template #requiredErrorTemplate>
    {{ "dtmWebAppUserProfile.legalGuardian.requiredFieldError" | transloco }}
</ng-template>
