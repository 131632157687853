import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest, of, skip, switchMap } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { Chapter, ContentEditData, HtmlChapter } from "../../../services/operations-manual.models";

interface SimpleComponentState {
    chapter: HtmlChapter | undefined;
    isGuideButtonVisible: boolean;
    isNested: boolean;
    isEditMode: boolean;
    isContentChanged: boolean;
    areActionsBlocked: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-opsman-chapter-simple[chapter]",
    templateUrl: "./simple.component.html",
    styleUrls: ["../chapter.scss", "./simple.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class SimpleComponent {
    @Input() public set chapter(value: Chapter | undefined) {
        this.localStore.patchState({ chapter: value as HtmlChapter });
    }

    @Input() public set isGuideButtonVisible(value: BooleanInput) {
        this.localStore.patchState({ isGuideButtonVisible: coerceBooleanProperty(value) });
    }

    @Input() public set isNested(value: BooleanInput) {
        this.localStore.patchState({ isNested: coerceBooleanProperty(value) });
    }

    @Input() public set isEditMode(value: BooleanInput) {
        const isEditMode = coerceBooleanProperty(value);

        this.localStore.patchState(isEditMode ? { isEditMode } : { isEditMode, isContentChanged: false });
    }

    @Input() public set areActionsBlocked(value: BooleanInput) {
        this.localStore.patchState({ areActionsBlocked: coerceBooleanProperty(value) });
    }

    @Output() public readonly editModeChange = new EventEmitter<string | null>();
    @Output() public readonly otherActionsBlock = new EventEmitter<boolean>();
    @Output() public readonly contentSave = new EventEmitter<ContentEditData>();
    @Output() public readonly attachmentsManage = new EventEmitter<Chapter>();
    @Output() public readonly guideShow = new EventEmitter<void>();

    protected readonly chapter$ = this.localStore.selectByKey("chapter").pipe(RxjsUtils.filterFalsy());
    protected readonly isGuideButtonVisible$ = combineLatest([this.localStore.selectByKey("isGuideButtonVisible"), this.chapter$]).pipe(
        map(([isGuideButtonVisible, chapter]) => isGuideButtonVisible && !!chapter.guide)
    );
    protected readonly isNested$ = this.localStore.selectByKey("isNested");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    protected readonly contentControl = new FormControl<string>("");

    constructor(
        private readonly localStore: LocalComponentStore<SimpleComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            chapter: undefined,
            isGuideButtonVisible: true,
            isNested: false,
            isEditMode: false,
            isContentChanged: false,
            areActionsBlocked: false,
        });

        this.localStore
            .selectByKey("isEditMode")
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.contentControl.valueChanges.pipe(skip(1))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.localStore.patchState({ isContentChanged: true });
            });
        this.watchIfContentIsDirty();
    }

    protected openEditMode() {
        const isEditModeOpenAllowed$ = this.localStore.selectSnapshotByKey("areActionsBlocked") ? this.confirmEditModeOpen() : of(true);

        isEditModeOpenAllowed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            const chapter = this.localStore.selectSnapshotByKey("chapter");

            this.contentControl.reset(chapter?.content ?? "", { emitEvent: false });
            this.isEditMode = true;
            this.editModeChange.emit(chapter?.id ?? null);
        });
    }

    protected closeEditMode() {
        const isEditModeCloseAllowed$ = this.localStore.selectSnapshotByKey("isContentChanged") ? this.confirmEditModeClose() : of(true);

        isEditModeCloseAllowed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            this.isEditMode = false;
            this.editModeChange.emit(null);
        });
    }

    protected openAttachmentsManagement() {
        const isAttachmentsManagementOpenAllowed$ = this.localStore.selectSnapshotByKey("areActionsBlocked")
            ? this.confirmAttachmentsManagementOpen()
            : of(true);

        isAttachmentsManagementOpenAllowed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            const chapter = this.localStore.selectSnapshotByKey("chapter");

            this.attachmentsManage.emit(chapter);
        });
    }

    protected saveChanges() {
        const chapter = this.localStore.selectSnapshotByKey("chapter");
        if (!chapter) {
            return;
        }

        this.contentSave.emit({ chapter, content: this.contentControl.value });
    }

    private watchIfContentIsDirty() {
        return this.localStore
            .selectByKey("isEditMode")
            .pipe(
                switchMap((isEditMode) =>
                    isEditMode
                        ? this.contentControl.valueChanges.pipe(
                              skip(1),
                              map(() => true)
                          )
                        : of(false)
                ),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((value) => this.otherActionsBlock.emit(value));
    }

    private confirmEditModeOpen(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmEditModeOpenDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmEditModeOpenDialog.dialogMessage"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmEditModeOpenDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmEditModeOpenDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private confirmEditModeClose(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmEditModeCloseDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmEditModeCloseDialog.dialogMessage"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmEditModeCloseDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.chapter.confirmEditModeCloseDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private confirmAttachmentsManagementOpen(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.chapter.confirmAttachmentsManagementOpenDialog.titleText"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibOpsMan.chapter.confirmAttachmentsManagementOpenDialog.dialogMessage"
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.chapter.confirmAttachmentsManagementOpenDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.chapter.confirmAttachmentsManagementOpenDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }
}
