import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Address, OperationScenarioCategory } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { ImportedDetails, PersonalOperatorFormValue } from "../../../models/operator-migration.models";

interface PersonalOperatorSummaryComponentState {
    editedOperatorDetails: PersonalOperatorFormValue | undefined;
    importedDetails: ImportedDetails | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-personal-operator-summary[editedOperatorDetails][importedDetails]",
    templateUrl: "./personal-operator-summary.component.html",
    styleUrls: ["./personal-operator-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PersonalOperatorSummaryComponent {
    @Input() public set editedOperatorDetails(value: PersonalOperatorFormValue | undefined) {
        this.localStore.patchState({ editedOperatorDetails: value });
    }
    @Input() public set importedDetails(value: ImportedDetails | undefined) {
        this.localStore.patchState({ importedDetails: value });
    }

    protected readonly OperationScenarioCategory = OperationScenarioCategory;
    protected readonly editedOperatorDetails$ = this.localStore.selectByKey("editedOperatorDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly importedDetails$ = this.localStore.selectByKey("importedDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly importedOperatorDetails$ = this.importedDetails$.pipe(
        map((details) => {
            if (!details?.migrationData.personalOperator) {
                return;
            }
            const { type, isApproved, number, secret, isForeign, operationalAuthorizations } = details.migrationData.personalOperator;

            return { type, isApproved, number, secret, isForeign, operationalAuthorizations };
        }),
        RxjsUtils.filterFalsy()
    );

    protected readonly companyInfo$ = this.editedOperatorDetails$.pipe(
        map((details) => {
            if (!details?.companyData) {
                return;
            }

            return { ...details.companyData, address: details.address as Address };
        })
    );

    protected readonly operatorAddress$ = this.editedOperatorDetails$.pipe(
        map((details) => {
            if (!details?.address) {
                return;
            }

            return details.address as Address;
        })
    );

    constructor(private readonly localStore: LocalComponentStore<PersonalOperatorSummaryComponentState>) {
        localStore.setState({ editedOperatorDetails: undefined, importedDetails: undefined });
    }
}
