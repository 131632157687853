import { OperationScenarioCategory, Page, QualificationStatus, UploadedFile } from "@dtm-frontend/shared/ui";
import { UavClass } from "../../shared/models/uav.models";

export enum OperatorPermissionsErrorType {
    CannotGetOwnedPermissions = "CannotGetPermissions",
    CannotGetAllPossiblePermissions = "CannotGetAllPossiblePermissions",
    CannotGetAllOperationalScenarios = "CannotGetAllOperationalScenarios",
    CannotGetStatementsList = "CannotGetStatementsList",
    CannotSaveStatement = "CannotSaveStatement",
    PaymentServiceUnavailable = "PaymentServiceUnavailable",
    CannotGetSavedStatement = "CannotGetSavedStatement",
    CannotGetOperatorStatementAdministrativeFeeDocument = "CannotGetOperatorStatementAdministrativeFeeDocument",
    Unknown = "Unknown",
}

export enum StatementsWizardSteps {
    GainPermissions = "GainPermissions",
    UavInformation = "UavInformation",
    Summary = "Summary",
    StatementSign = "StatementSign",
}

export interface OperatorPermissionsError {
    type: OperatorPermissionsErrorType;
}

export interface OwnedOperatorPermission {
    id: string;
    name: string;
    expirationDate: Date;
    description: string;
    status: QualificationStatus;
    statusReason?: string;
    numberOfUavs: number;
}

export interface SinglePermission {
    scenarioId: string;
    name: string;
    description: string;
    grantValidityInMonths: number;
    price: number;
    areUavsRequired: boolean;
    uavs?: AvailableUav[];
    allowedUavClasses: UavClass[];
}

export interface PossiblePermissions {
    availableOperationScenarios: SinglePermission[];
    operationalAuthorizations: SinglePermission[];
}

export interface OperatorPermissionsCapabilities extends PossiblePermissions {
    operator: OperatorPermissionsOperatorDetails;
    operatorUavs: AvailableUav[];
    isAttorneyPowerAdministrativeFeeRequired: boolean;
}

export interface AvailableUav {
    id: string;
    name: string;
    serialNumber: string;
    uavClasses?: UavClass[];
}

export interface OperatorPermissionsOperatorDetails {
    name: string;
    number: string;
}

export interface OperatorStatement {
    id: string;
    operatorId: string;
    createdAt: Date;
    payment: {
        status: StatementStatus;
        info: PaymentStatusInfoCode;
    };
    signature: {
        status: StatementStatus;
        info: string;
    };
    totalCost: number;
    operatorNumber: string;
    operationScenarios: OperatorStatementOperationScenario[];
    operationScenarioNames: string[];
    attorneyPowerAdministrativeFee?: AdministrativeFeeInStatement;
}

export interface OperatorStatementOperationScenario {
    id: string;
    scenarioId: string;
    scenarioName: string;
    scenarioCategory: OperationScenarioCategory;
}

export enum StatementStatus {
    Waiting = "WAITING",
    Completed = "COMPLETED",
    Error = "ERROR",
    NotPaid = "NOT_PAID",
    NotSigned = "NOT_SIGN",
}

export enum PaymentStatusInfoCode {
    ServiceUnavailable = "ServiceUnavailable",
    TransactionRejected = "TransactionRejected",
    TransactionTimeout = "TransactionTimeout",
    Unknown = "Unknown",
}

export interface OperatorStatementListWithPages {
    content: OperatorStatement[];
    pages: Page;
}

export interface UavsSelectedForPermission {
    [permissionId: string]: AvailableUav[];
}

export interface OperatorPermissionsStatementToSign {
    operationScenarios: OperationScenarioToSign[];
    attorneyPowerAdministrativeFee?: {
        feeConfirmationId?: string;
        isDeclarationOfExemption: boolean;
    };
}

export interface OperationScenarioToSign {
    id: string;
    uavIds: string[] | null;
}

export interface SavedStatement {
    id: string;
    operatorId: string;
    createdAt: Date;
    payment: {
        status: StatementStatus;
        info?: string;
    };
    signature: {
        status: StatementStatus;
        info?: string;
    };
    totalCost: number;
    operationScenarios: SavedOperationScenario[];
}

export interface SavedOperationScenario {
    scenarioId: string;
    scenarioName: string;
    description: string;
    grantValidityInMonths: number;
    scenarioCategory: OperationScenarioCategory;
    uavs?: AvailableUav[];
}

export interface StatementsFilters {
    paymentStatus: StatementStatus[] | null;
    signStatus: StatementStatus[] | null;
}

export interface PermissionUavDetails {
    operationalScenarioName: string;
    detachedSerialNumbers: string[];
    uavs: UavDetails[];
}

export interface UavDetails {
    id: string;
    ownerName: string;
    manufacturer: string;
    type: string;
    name: string;
    serialNumbers: string[];
    isCustom: boolean;
}

export interface AdministrativeFeeInStatement {
    isDeclarationOfExemption?: boolean;
    feeConfirmation?: UploadedFile[];
    feeDocumentId?: string;
}

export interface StatementsListQueryParams {
    page: number;
    size: number;
    operatorId: string;
    paymentStatus?: StatementStatus[] | null;
    signStatus?: StatementStatus[] | null;
}
