<dtm-ui-wizard-step-wrapper
    (next)="validateFormAndSave()"
    (back)="back.emit()"
    [title]="'dtmWebAppLibOperatorMigration.detailsStep.header' | transloco"
    [stepsAmount]="3"
    [stepNumber]="2"
    [isBackButtonVisible]="true"
    dtmUiInvalidFormScrollable
>
    <div class="form" [formGroup]="operatorMigrationForm" *ngrxLet="importedDetails$ as importedDetails">
        <dtm-web-app-lib-operator-migration-company-info
            *ngIf="operatorMigrationForm.controls.legalOperator.enabled"
            dtmUiMarkValueAccessorControlsAsTouched
            formControlName="legalOperator"
            [enterpriseOperatorDetails]="importedDetails.migrationData.legalOperator"
        ></dtm-web-app-lib-operator-migration-company-info>
        <dtm-web-app-lib-operator-migration-personal-operator-info
            *ngIf="operatorMigrationForm.controls.personalOperator.enabled"
            dtmUiMarkValueAccessorControlsAsTouched
            formControlName="personalOperator"
            [personalOperatorDetails]="importedDetails.migrationData.personalOperator"
        >
            <ng-template [ngTemplateOutlet]="legalGuardianInfoTemplate"></ng-template>
        </dtm-web-app-lib-operator-migration-personal-operator-info>
        <dtm-web-app-lib-operator-migration-pilot-info
            *ngIf="operatorMigrationForm.controls.pilot.enabled"
            dtmUiMarkValueAccessorControlsAsTouched
            formControlName="pilot"
            [personalOperatorDetails]="importedDetails.migrationData.personalOperator"
            [pilotDetails]="importedDetails.migrationData.pilot"
        >
            <ng-template [ngTemplateOutlet]="legalGuardianInfoTemplate"></ng-template>
        </dtm-web-app-lib-operator-migration-pilot-info>

        <ng-template #legalGuardianInfoTemplate>
            <dtm-web-app-lib-operator-migration-legal-guardian-info
                dtmUiMarkValueAccessorControlsAsTouched
                formControlName="legalGuardianAddress"
                *ngIf="importedDetails.migrationData.legalGuardian && operatorMigrationForm.controls.legalGuardianAddress.enabled"
                [legalGuardian]="importedDetails.migrationData.legalGuardian"
            ></dtm-web-app-lib-operator-migration-legal-guardian-info>
        </ng-template>
    </div>
</dtm-ui-wizard-step-wrapper>
