<ng-container *ngrxLet="userProfile$ as userProfile">
    <dtm-ui-expandable-panel *ngrxLet="isExpanded$ as isExpanded" [hasHeaderSeparator]="false" [isExpanded]="isExpanded">
        <div class="header" headerSlot>
            <h2>
                {{
                    (userProfile.pilotNumber
                        ? "dtmWebAppMembership.userProfileDetails.pilotHeader"
                        : "dtmWebAppMembership.userProfileDetails.userHeader"
                    ) | transloco
                }}
            </h2>
        </div>
        <div class="details" *ngIf="userProfile.pilotNumber; else simpleUserDetailsTemplate">
            <ng-container [ngTemplateOutlet]="nameTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="emailTemplate"></ng-container>
            <dtm-ui-label-value
                [label]="'dtmWebAppMembership.userProfileDetails.pilotsNumber' | transloco"
                [value]="userProfile.pilotNumber"
            ></dtm-ui-label-value>
            <ng-container [ngTemplateOutlet]="phoneNumberTemplate"></ng-container>
        </div>

        <ng-template #simpleUserDetailsTemplate>
            <div class="details">
                <ng-container [ngTemplateOutlet]="nameTemplate"></ng-container>
                <ng-container [ngTemplateOutlet]="emailTemplate"></ng-container>
                <ng-container [ngTemplateOutlet]="phoneNumberTemplate"></ng-container>
            </div>
        </ng-template>

        <ng-template #nameTemplate>
            <dtm-ui-label-value
                [label]="'dtmWebAppMembership.userProfileDetails.fullName' | transloco"
                [value]="userProfile.firstName + ' ' + userProfile.lastName"
            ></dtm-ui-label-value>
        </ng-template>
        <ng-template #emailTemplate>
            <dtm-ui-label-value
                [label]="'dtmWebAppMembership.userProfileDetails.emailAddress' | transloco"
                [value]="userProfile.email"
            ></dtm-ui-label-value>
        </ng-template>
        <ng-template #phoneNumberTemplate>
            <dtm-ui-label-value
                [label]="'dtmWebAppMembership.userProfileDetails.phoneNumber' | transloco"
                [value]="userProfile.phoneNumber | formatPhoneNumber"
            ></dtm-ui-label-value>
        </ng-template>
    </dtm-ui-expandable-panel>
</ng-container>
