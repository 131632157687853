<dtm-ui-expandable-panel
    *ngrxLet="{
        availableTrainings: availableTrainings$,
        currentTrainings: currentTrainings$,
        isEditMode: isEditMode$,
        isEditable: isEditable$,
        activeLanguage: activeLanguage$,
    } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [isDisabled]="vm.isEditMode || (!vm.currentTrainings.basic.length && !vm.currentTrainings.additional.length)"
    [hasHeaderSeparator]="false"
>
    <div class="panel-header" headerSlot>
        <div class="wrapper">
            <h2>{{ headerLabel$ | ngrxPush }}</h2>
            <span>{{ subheaderLabel$ | ngrxPush }}</span>
        </div>
        <ng-container *ngIf="vm.isEditable">
            <button
                *ngIf="!vm.isEditMode"
                type="button"
                class="button-secondary"
                [class]="vm.currentTrainings.basic.length || vm.currentTrainings.additional.length ? 'edit-button' : 'add-button'"
                (click)="$event.stopPropagation(); setEditMode(true)"
            >
                <ng-container *ngIf="vm.currentTrainings.basic.length || vm.currentTrainings.additional.length">
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.editButtonLabel" | transloco }}
                </ng-container>
                <ng-container *ngIf="!vm.currentTrainings.basic.length && !vm.currentTrainings.additional.length">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.addButtonLabel" | transloco }}
                </ng-container>
            </button>
            <button *ngIf="vm.isEditMode" type="button" class="button-icon close-button" (click)="$event.stopPropagation(); cancel()">
                <dtm-ui-icon name="close"></dtm-ui-icon>
            </button>
        </ng-container>
    </div>
    <section *ngIf="!vm.isEditMode">
        <dtm-web-app-lib-spec-perm-app-trainings
            [basicTrainings]="vm.currentTrainings.basic"
            [additionalTrainings]="vm.currentTrainings.additional"
            [activeLanguage]="vm.activeLanguage"
        >
        </dtm-web-app-lib-spec-perm-app-trainings>
    </section>
    <section class="edit-mode-container" *ngIf="vm.isEditMode">
        <ng-container [formGroup]="pilotTrainingsForm">
            <dtm-web-app-lib-spec-perm-app-trainings-form
                formControlName="trainings"
                [availableTrainings]="vm.availableTrainings"
                [activeLanguage]="vm.activeLanguage"
            >
            </dtm-web-app-lib-spec-perm-app-trainings-form>
        </ng-container>
        <footer class="actions">
            <button type="button" class="button-secondary" (click)="cancel()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="save()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.trainings.saveButtonLabel" | transloco }}
            </button>
        </footer>
    </section>
</dtm-ui-expandable-panel>
