import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ConversationCategoryCode, FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OperatorConversationsFileUploadApiService } from "../../services/operator-conversations-file-upload-api.service";
import { ConversationFilterFormKeys, OperatorConversationFilters } from "../../services/operator-conversations.model";

interface OperatorConversationFiltersComponentState {
    filtersCount: number;
    isExpanded: boolean;
    conversationCategories: ConversationCategoryCode[];
    incomingFilters: OperatorConversationFilters | undefined;
}

const DEBOUNCE_TIME = 300;
const MIN_SEARCH_LENGTH = 3;

const FILTERS_MAP: {
    key: string;
    filterLabel: string;
    filterValueLabel?: string;
}[] = [
    {
        key: ConversationFilterFormKeys.SearchByText,
        filterLabel: "dtmWebAppLibOperatorConversations.operatorConversationsContainer.filters.searchLabel",
        filterValueLabel: "dtmWebAppLibOperatorConversations.operatorConversationsContainer.filters.textSearchFilterValue",
    },
    {
        key: ConversationFilterFormKeys.Categories,
        filterLabel: "dtmWebAppLibOperatorConversations.operatorConversationsContainer.filters.categoryLabel",
        filterValueLabel: "dtmWebAppLibOperatorConversations.operatorConversationsContainer.filters.categoryValue",
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-conversation-filters[conversationCategories][initialFilters]",
    templateUrl: "./operator-conversation-filters.component.html",
    styleUrls: ["./operator-conversation-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: OperatorConversationsFileUploadApiService }],
})
export class OperatorConversationFiltersComponent implements OnInit {
    @Input() public set conversationCategories(value: ConversationCategoryCode[] | undefined) {
        this.localStore.patchState({ conversationCategories: value ?? [] });
    }
    @Input() public set initialFilters(value: OperatorConversationFilters | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }
    @Output() public filtersChange = new EventEmitter<OperatorConversationFilters>();
    @Output() public filtersAppliedChange: EventEmitter<boolean> = new EventEmitter();

    public readonly textSearchControl = new UntypedFormControl("", Validators.minLength(MIN_SEARCH_LENGTH));
    public readonly categoryControl = new UntypedFormControl(null);
    public readonly filtersFormGroup = new UntypedFormGroup({
        [ConversationFilterFormKeys.SearchByText]: this.textSearchControl,
        [ConversationFilterFormKeys.Categories]: this.categoryControl,
    });

    public readonly FILTERS_MAP = FILTERS_MAP;
    public readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    public readonly conversationCategories$ = this.localStore.selectByKey("conversationCategories");

    constructor(private localStore: LocalComponentStore<OperatorConversationFiltersComponentState>) {
        localStore.setState({ filtersCount: 0, isExpanded: true, conversationCategories: [], incomingFilters: undefined });
    }

    public ngOnInit() {
        this.assignFormValueAndInitChangesWatch();
    }

    public clearFilters() {
        this.filtersFormGroup.reset({
            [ConversationFilterFormKeys.Categories]: [],
            [ConversationFilterFormKeys.SearchByText]: "",
        });
    }

    private watchFormChanges() {
        this.filtersFormGroup.valueChanges
            .pipe(debounceTime(DEBOUNCE_TIME), distinctUntilChanged(equal), untilDestroyed(this))
            .subscribe((value) => {
                this.updateFiltersCount();

                if (this.textSearchControl.invalid) {
                    return;
                }

                this.filtersChange.emit(value);
            });
    }

    private updateFiltersCount() {
        if (this.filtersFormGroup.valid) {
            const filtersCount = Object.values(this.filtersFormGroup.value).flat().filter(Boolean).length;

            this.localStore.patchState({ filtersCount });
            this.filtersAppliedChange.emit(!!filtersCount);
        }
    }

    private assignFormValueAndInitChangesWatch() {
        this.watchFormChanges();

        this.localStore
            .selectByKey("incomingFilters")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((filters) => {
                if (!this.isIncomingFiltersDifferentThanFormValue(filters)) {
                    return;
                }
                this.filtersFormGroup.setValue(filters);
            });
    }

    private isIncomingFiltersDifferentThanFormValue(filters: OperatorConversationFilters) {
        return (
            filters[ConversationFilterFormKeys.SearchByText] !== this.textSearchControl.value ||
            this.categoryControl.value?.toString() !== filters[ConversationFilterFormKeys.Categories]?.toString()
        );
    }
}
