import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { NestedTreeControl } from "@angular/cdk/tree";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, of } from "rxjs";
import { TableOfContentsChapter, TableOfContentsChapters } from "../../services/operations-manual.models";

interface TableOfContentsComponentState {
    lastUpdateDate: Date | undefined;
    chapters: TableOfContentsChapter[];
    selectedChapter: TableOfContentsChapter | undefined;
    areActionsBlocked: boolean;
}

const EXPAND_COLLAPSE_ANIMATION_TIME_MS = 150;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-opsman-table-of-contents[lastUpdateDate][chapters]",
    templateUrl: "./table-of-contents.component.html",
    styleUrls: ["./table-of-contents.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation(EXPAND_COLLAPSE_ANIMATION_TIME_MS)],
})
export class TableOfContentsComponent {
    @Input() public set lastUpdateDate(value: Date | undefined) {
        this.localStore.patchState({ lastUpdateDate: value });
    }

    @Input() public set chapters(value: TableOfContentsChapters | undefined) {
        this.localStore.patchState({ chapters: value?.chapters ?? [] });

        const chapterToSelect = this.getChapterToSelect(value?.chapters ?? [], value?.initiallySelectedChapterId);
        if (chapterToSelect) {
            this.selectChapter(chapterToSelect);
            this.treeControl.expand(chapterToSelect);
        } else {
            this.localStore.patchState({ selectedChapter: undefined });
        }
    }

    @Input() public set areActionsBlocked(value: BooleanInput) {
        this.localStore.patchState({ areActionsBlocked: coerceBooleanProperty(value) });
    }

    @Output() public chapterSelect = new EventEmitter<TableOfContentsChapter>();

    protected readonly lastUpdateDate$ = this.localStore.selectByKey("lastUpdateDate").pipe(RxjsUtils.filterFalsy());
    protected readonly treeDataSource$: Observable<TableOfContentsChapter[]> = this.localStore.selectByKey("chapters");

    protected readonly treeControl = new NestedTreeControl<TableOfContentsChapter>((node) => node.subchapters);

    constructor(
        private readonly localStore: LocalComponentStore<TableOfContentsComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            lastUpdateDate: undefined,
            chapters: [],
            selectedChapter: undefined,
            areActionsBlocked: false,
        });
    }

    protected hasSubchapters(index: number, chapter: TableOfContentsChapter) {
        return !!chapter.subchapters && chapter.subchapters.length > 0;
    }

    protected isChapterSelected(chapter: TableOfContentsChapter): boolean {
        const selectedChapter = this.localStore.selectSnapshotByKey("selectedChapter");

        return chapter.id === selectedChapter?.id;
    }

    protected trySelectChapter(chapter: TableOfContentsChapter) {
        const isChapterSelectAllowed$ = this.localStore.selectSnapshotByKey("areActionsBlocked") ? this.confirmChapterSelect() : of(true);

        isChapterSelectAllowed$.pipe(RxjsUtils.filterFalsy(), untilDestroyed(this)).subscribe(() => {
            this.selectChapter(chapter);
        });
    }

    private selectChapter(chapter: TableOfContentsChapter) {
        const selectedChapter = this.localStore.selectSnapshotByKey("selectedChapter");
        if (selectedChapter?.id === chapter.id) {
            return;
        }

        this.collapseOtherChapters(chapter);
        this.treeControl.expand(chapter);

        this.localStore.patchState({ selectedChapter: chapter });
        this.chapterSelect.emit(chapter);
    }

    private confirmChapterSelect(): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.tableOfContents.confirmChapterSelectDialog.titleText"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibOpsMan.tableOfContents.confirmChapterSelectDialog.dialogMessage"
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.tableOfContents.confirmChapterSelectDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.tableOfContents.confirmChapterSelectDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Primary,
                },
            })
            .afterClosed();
    }

    private getMainChapters(): TableOfContentsChapter[] {
        return this.localStore.selectSnapshotByKey("chapters");
    }

    private getMainChapter(subchapter: TableOfContentsChapter): TableOfContentsChapter | null {
        const mainChapters = this.getMainChapters();

        for (const mainChapter of mainChapters) {
            if (mainChapter.id === subchapter.id || this.isSubchapterOfChapter(subchapter, mainChapter)) {
                return mainChapter;
            }
        }

        return null;
    }

    private isSubchapterOfChapter(subchapter: TableOfContentsChapter, chapter: TableOfContentsChapter): boolean {
        for (const subchaptersItem of chapter.subchapters) {
            if (subchaptersItem.id === subchapter.id || this.isSubchapterOfChapter(subchapter, subchaptersItem)) {
                return true;
            }
        }

        return false;
    }

    private collapseOtherChapters(chapter: TableOfContentsChapter) {
        const mainChapterOfChapter = this.getMainChapter(chapter);

        for (const mainChapter of this.getMainChapters()) {
            if (mainChapter.id !== mainChapterOfChapter?.id && this.treeControl.isExpanded(mainChapter)) {
                this.treeControl.collapseDescendants(mainChapter);
            }
        }
    }

    private getChapterToSelect(
        chapters: TableOfContentsChapter[],
        initiallySelectedChapterId?: string
    ): TableOfContentsChapter | undefined {
        if (!initiallySelectedChapterId && chapters.length) {
            return chapters[0];
        }

        for (const chapter of chapters) {
            if (chapter.id === initiallySelectedChapterId) {
                return chapter;
            }

            const selectedSubchapter = this.getChapterToSelect(chapter.subchapters, initiallySelectedChapterId);
            if (selectedSubchapter) {
                return selectedSubchapter;
            }
        }

        return undefined;
    }
}
