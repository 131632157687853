<dtm-ui-expandable-panel [hasHeaderSeparator]="false" *ngrxLet="importedOperatorDetails$ as importedOperatorDetails" isExpanded>
    <h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.detailsStep.operatorDetails.header" | transloco }}</h2>
    <div class="chip success">
        <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
        {{ "dtmWebAppLibOperatorMigration.detailsStep.operatorDetails.approvedOperatorValue" | transloco }}
    </div>
    <div class="details-container">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.operatorDetails.typeLabel' | transloco"
            [value]="
                'dtmWebAppLibOperatorMigration.detailsStep.operatorDetails.typeValue' | transloco : { value: importedOperatorDetails.type }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.operatorDetails.iRegisteredInOtherCountryLabel' | transloco"
            [value]="
                'dtmWebAppLibOperatorMigration.detailsStep.operatorDetails.iRegisteredInOtherCountryValue'
                    | transloco : { value: importedOperatorDetails.isForeign }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.operatorDetails.operatorNumberLabel' | transloco"
            [value]="importedOperatorDetails.number"
        ></dtm-ui-label-value>
    </div>
    <dtm-web-app-lib-operator-migration-operational-authorizations
        *ngIf="importedOperatorDetails.operationalAuthorizations.length"
        [operationalAuthorizations]="importedOperatorDetails.operationalAuthorizations"
    >
    </dtm-web-app-lib-operator-migration-operational-authorizations>
</dtm-ui-expandable-panel>
