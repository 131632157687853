import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent, OperatorType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Operator } from "../../models/operator.models";

interface OperatorAvatarComponentState {
    avatarBase64: string | undefined;
    operator: Operator | undefined;
    canManageOperator: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-avatar[operator][avatarBase64]",
    templateUrl: "./operator-avatar.component.html",
    styleUrls: ["./operator-avatar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OperatorAvatarComponent {
    @Input() public set operator(value: Operator | undefined) {
        this.localStore.patchState({ operator: value });
    }
    @Input() public set avatarBase64(value: string | undefined) {
        this.localStore.patchState({ avatarBase64: value });
    }

    @Input() public set canManageOperator(value: BooleanInput) {
        this.localStore.patchState({ canManageOperator: coerceBooleanProperty(value) });
    }

    @Output() public saveAvatar = new EventEmitter<string>();
    @Output() public delete = new EventEmitter<void>();

    protected readonly avatarBase64$ = this.localStore.selectByKey("avatarBase64");
    protected readonly operator$ = this.localStore.selectByKey("operator").pipe(RxjsUtils.filterFalsy());
    protected readonly canManageOperator$ = this.localStore.selectByKey("canManageOperator");
    protected readonly OperatorType = OperatorType;

    constructor(
        private readonly localStore: LocalComponentStore<OperatorAvatarComponentState>,
        private readonly dialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            avatarBase64: undefined,
            operator: undefined,
            canManageOperator: false,
        });
    }

    protected tryDeleteOperatorAvatar(): void {
        this.dialog
            .open(ConfirmationDialogComponent, this.getDialogConfig())
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.delete.emit());
    }

    private getDialogConfig(): MatDialogConfig {
        return {
            data: {
                titleText: this.transloco.translate("dtmWebAppOperatorProfile.operatorAvatar.dialogTitleText"),
                confirmationText: this.transloco.translate("dtmWebAppOperatorProfile.operatorAvatar.dialogConfirmationText"),
                confirmButtonLabel: this.transloco.translate("dtmWebAppOperatorProfile.operatorAvatar.dialogConfirmationButtonLabel"),
                theme: ButtonTheme.Warn,
            },
        };
    }
}
