import {
    Address,
    OperationScenarioCategory,
    OperatorAndPilotQualification,
    OperatorStatus,
    PhoneNumber,
    UploadedFile,
} from "@dtm-frontend/shared/ui";
import { OperatorPilotOtherInformation } from "../../shared/models/pilot-and-operator.models";

export enum PilotProfileErrorType {
    Unknown = "PilotProfileErrorErrorUnknown",
    NotFound = "PilotProfileErrorErrorNotFound",
    NotAuthorized = "PilotProfileErrorNotAuthorized",
    CannotEditOperatorInsurancePolicy = "CannotEditOperatorInsurancePolicy",
    CannotDeleteOperatorInsurancePolicy = "CannotDeleteOperatorInsurancePolicy",
    CannotSavePilotAddress = "CannotSavePilotAddress",
}

export enum PilotStatus {
    Active = "ACTIVE",
    Suspended = "SUSPENDED",
}

export interface PilotProfileError {
    type: PilotProfileErrorType;
}

export interface Owner {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: Date;
    nationality: string;
    phoneNumber: PhoneNumber;
    email: string;
}

export interface OperatorNumber {
    secret: string;
    number: string;
}

export interface Operator {
    id: string;
    operatorNumber: OperatorNumber;
    name: string;
    insurancePolicyNumber: string | undefined;
    insurancePolicyExpirationDate: string | undefined;
    address: Address;
    status: OperatorStatus;
    isRegisteredInOtherCountry: boolean;
}

export interface OperationScenario {
    id: string;
    name: string;
    category: OperationScenarioCategory;
}

export interface OperationalAuthorization extends OperatorAndPilotQualification {
    operationScenario: OperationScenario;
}

export interface PilotProfile {
    id: string;
    status: PilotStatus;
    suspensionReason: string;
    pilotNumber: string;
    firstName: string;
    lastName: string;
    name: string;
    operator: Operator;
    competencies: OperatorAndPilotQualification[];
    operationalAuthorizations: OperatorAndPilotQualification[];
    owner: Owner;
    pansaUtmLink?: PansaUtmLink;
    isSuperPilot: boolean;
    documentsToDownload: PilotConfirmationDocumentType[];
    otherInformation: OperatorPilotOtherInformation[];
    competencyConfirmations: ForeignCompetencyDocument[];
}

export interface PansaUtmLink {
    status: PansaUtmLinkStatus;
}

export enum PansaUtmLinkStatus {
    Active = "ACTIVE",
    Error = "ERROR",
}

export interface PansaUtmLinkError {
    type: PansaUtmLinkErrorType;
}

export enum PansaUtmLinkErrorType {
    WrongCredentials = "wrongCredentials",
    Unknown = "unknown",
}

export interface PansaUtmCredentials {
    login: string;
    password: string;
}

export enum PilotConfirmationDocumentType {
    RegistrationConfirmation = "registration-confirmation",
    ConfirmationCompletingA1A3 = "confirmation-completing-a1a3",
    CertificateA2 = "certificate-a2",
    CertificateSts = "certificate-sts",
}

export interface ForeignCompetencyDocument {
    id: string;
    status: string;
    statusReason?: string;
    type: ForeignCompetencyType;
    expirationDate: Date;
    file: UploadedFile;
    reason?: string;
}

export enum ForeignCompetencyStatus {
    Accepted = "ACCEPTED",
    Waiting = "WAITING",
    Rejected = "REJECTED",
}

export interface ForeignCompetencyDocumentDraft {
    type: ForeignCompetencyType;
    expirationDate: Date;
    file: UploadedFile[];
}

export interface ForeignCompetencyDocumentEditOrAdd {
    draft: ForeignCompetencyDocumentDraft;
    competencyId?: string;
}

export enum ForeignCompetencyType {
    A1_A3 = "A1A3",
    A2 = "A2",
}
