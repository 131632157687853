import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Address } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { ImportedLegalGuardianDetails } from "../../../models/operator-migration.models";

interface LegalGuardianSummaryComponentState {
    legalGuardianDetails: ImportedLegalGuardianDetails | undefined;
    legalGuardianAddress: Address | undefined;
}

@Component({
    selector: "dtm-web-app-lib-operator-migration-legal-guardian-summary",
    templateUrl: "./legal-guardian-summary.component.html",
    styleUrls: ["./legal-guardian-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LegalGuardianSummaryComponent {
    @Input() public set legalGuardianDetails(value: ImportedLegalGuardianDetails | undefined) {
        this.localStore.patchState({ legalGuardianDetails: value });
    }
    @Input() public set legalGuardianAddress(value: Address | undefined) {
        this.localStore.patchState({ legalGuardianAddress: value });
    }

    protected readonly legalGuardianDetails$ = this.localStore.selectByKey("legalGuardianDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly legalGuardianAddress$ = this.localStore.selectByKey("legalGuardianAddress").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<LegalGuardianSummaryComponentState>) {
        localStore.setState({ legalGuardianAddress: undefined, legalGuardianDetails: undefined });
    }
}
