import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TechnicalSpecification } from "../../../../services/uav.models";

export interface OtherTechnicalPropertiesComponentState {
    properties: TechnicalSpecification | undefined;
}

@Component({
    selector: "dtm-web-app-lib-other-technical-properties",
    templateUrl: "./other-technical-properties.component.html",
    styleUrls: ["./other-technical-properties.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OtherTechnicalPropertiesComponent {
    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(RxjsUtils.filterFalsy());

    @Input() public set properties(value: TechnicalSpecification | undefined) {
        this.localStore.patchState({ properties: value });
    }

    constructor(private readonly localStore: LocalComponentStore<OtherTechnicalPropertiesComponentState>) {
        this.localStore.setState({
            properties: undefined,
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }
}
