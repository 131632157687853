<dtm-ui-filters-container *ngrxLet="filtersCount$ as filtersCount" [filtersCount]="filtersCount">
    <form [formGroup]="filtersForm" *ngIf="capabilities$ | ngrxPush as capabilities">
        <div class="filters-container">
            <dtm-ui-input-field class="control-search">
                <input
                    matInput
                    type="text"
                    [formControl]="searchTextControl"
                    [placeholder]="'dtmWebAppMembership.filters.pilotSearchByTextPlaceholder' | transloco"
                />
            </dtm-ui-input-field>
        </div>
        <div class="filters-container">
            <dtm-ui-select-field multiple [formControl]="statusControl" class="control-status">
                <label>{{ "dtmWebAppMembership.filters.statusLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let status of capabilities.membershipStatuses" [value]="status">
                    {{ "dtmWebAppMembership.filters.statusValue" | transloco : { value: status } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <dtm-ui-select-field class="control-competencies" multiple [formControl]="competenciesControl">
                <label>{{ "dtmWebAppMembership.filters.qualificationsLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let competency of capabilities.operationScenarios" [value]="competency.name">
                    {{ competency.name }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
        </div>
    </form>

    <dtm-ui-filter-chips-display
        selectedFiltersDisplaySlot
        [formGroup]="filtersForm"
        [filtersValue]="filtersForm.value"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="filtersCount"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</dtm-ui-filters-container>
