import { Directive } from "@angular/core";
import { FILES_UPLOAD_API_PROVIDER } from "@dtm-frontend/shared/ui";
import { AttorneyDocumentsApiService } from "../../../shared/services/attorney-documents/attorney-documents-api.service";
import {
    ATTORNEY_DOCUMENT_ENDPOINTS,
    AttorneyDocumentEndpoints,
} from "../../../shared/services/attorney-documents/attorney-documents-api.token";
import { OPERATOR_REGISTRATION_ENDPOINTS, OperatorRegistrationEndpoints } from "../operator-registration.tokens";

function feeConfirmationApiFactory(endpoints: OperatorRegistrationEndpoints): AttorneyDocumentEndpoints {
    return {
        uploadFile: endpoints.uploadFeeConfirmation,
        downloadFile: endpoints.downloadAttorneyPowerFile,
    };
}

@Directive({
    selector: "[dtmWebAppLibOperatorRegistrationAttorneyFeeConfirmationUpload]",
    providers: [
        { provide: FILES_UPLOAD_API_PROVIDER, useClass: AttorneyDocumentsApiService },
        {
            provide: ATTORNEY_DOCUMENT_ENDPOINTS,
            useFactory: feeConfirmationApiFactory,
            deps: [OPERATOR_REGISTRATION_ENDPOINTS],
        },
    ],
})
export class OperatorRegistrationAttorneyFeeConfirmationUploadDirective {}
