import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ButtonTheme, ConfirmationDialogComponent, DeviceSize, DeviceSizeService, DialogService, Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SpecificPermitDraftsListItem } from "../../../../services/operator-permits.models";

interface SpecificPermitDraftsListComponentState {
    permitDrafts: SpecificPermitDraftsListItem[];
    pagination: Page | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-specific-permit-drafts-list[permitDrafts][pagination]",
    templateUrl: "./specific-permit-drafts-list.component.html",
    styleUrls: ["./specific-permit-drafts-list.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificPermitDraftsListComponent {
    @Input() public set permitDrafts(value: SpecificPermitDraftsListItem[] | undefined) {
        this.localStore.patchState({ permitDrafts: value ?? [] });
    }
    @Input() public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Output() public draftRemove = new EventEmitter<string>();

    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly permitDrafts$ = this.localStore.selectByKey("permitDrafts").pipe(RxjsUtils.filterFalsy());
    protected readonly isTablet$ = this.deviceService.getSizeObservable(DeviceSize.Tablet);
    protected readonly displayedColumns: string[] = ["draftName", "operationName", "actions"];

    constructor(
        private readonly localStore: LocalComponentStore<SpecificPermitDraftsListComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService,
        private readonly deviceService: DeviceSizeService
    ) {
        localStore.setState({
            permitDrafts: [],
            pagination: undefined,
        });
    }

    protected removeDraft(draft: SpecificPermitDraftsListItem): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.removeDraft.contentText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.removeDraft.confirmationText", {
                        name: draft.name,
                        operationName: draft.operationName,
                    }),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.removeDraft.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.removeDraft.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.draftRemove.emit(draft.id));
    }
}
