import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface PaymentForStatementComponentState {
    totalCost: number;
}

@Component({
    selector: "dtm-web-app-lib-payment-for-statement",
    templateUrl: "./payment-for-statement.component.html",
    styleUrls: ["./payment-for-statement.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PaymentForStatementComponent {
    @Input() public set totalCost(value: number | undefined) {
        this.localStore.patchState({ totalCost: value ?? 0 });
    }

    protected readonly totalCost$ = this.localStore.selectByKey("totalCost");

    constructor(private readonly localStore: LocalComponentStore<PaymentForStatementComponentState>) {
        localStore.setState({ totalCost: 0 });
    }
}
