import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { GroundRisk } from "../../../services/specific-permit-application.models";

interface GroundRiskInfoComponentState {
    groundRisk: GroundRisk | undefined;
    isExpanded: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-ground-risk-info[groundRisk]",
    templateUrl: "./ground-risk-info.component.html",
    styleUrls: ["../../common.scss", "./ground-risk-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GroundRiskInfoComponent {
    @Input() public set groundRisk(value: GroundRisk | undefined) {
        this.localStore.patchState({ groundRisk: value });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    protected readonly groundRisk$ = this.localStore.selectByKey("groundRisk").pipe(RxjsUtils.filterFalsy());
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    constructor(private readonly localStore: LocalComponentStore<GroundRiskInfoComponentState>) {
        this.localStore.setState({
            groundRisk: undefined,
            isExpanded: false,
        });
    }
}
