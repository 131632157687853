import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { MembershipActions } from "../state/membership.actions";

@Injectable({
    providedIn: "root",
})
export class InitPilotProfileResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
        return this.store.dispatch([
            new MembershipActions.GetPilotsProfile(route.params.pilotId),
            new MembershipActions.GetCapabilities(route.params.membershipOperatorId),
        ]);
    }
}
