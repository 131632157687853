import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { merge, tap } from "rxjs";
import { PublicProfileErrorType, PublicProfileType } from "../../services/public-profile.models";
import { PublicProfileActions } from "../../state/public-profile.actions";
import { PublicProfileState } from "../../state/public-profile.state";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-public-profile-pilot-container",
    templateUrl: "./public-profile-pilot-container.component.html",
    styleUrls: ["./public-profile-pilot-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicProfilePilotContainerComponent {
    protected readonly pilotData$ = this.store.select(PublicProfileState.publicProfilePilotData);
    protected readonly error$ = this.store.select(PublicProfileState.publicProfilePilotError);
    protected readonly PublicProfileType = PublicProfileType;
    protected readonly PublicProfileErrorType = PublicProfileErrorType;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.handleLoader();
    }

    private handleLoader(): void {
        merge([this.pilotData$, this.error$])
            .pipe(
                tap(() => {
                    this.document.getElementById("loader")?.remove();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected refreshPilotDetails() {
        const pilotId = this.route.snapshot.params.pilotId;

        if (pilotId) {
            this.store.dispatch(new PublicProfileActions.GetPublicProfilePilotData(pilotId));
        }
    }
}
