import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ButtonTheme, ConfirmationDialogComponent, EmptyStateMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Chapter, ExtendableChapter } from "../../../services/operations-manual.models";

interface ExtendableComponentState {
    chapter: ExtendableChapter | undefined;
    isEditMode: boolean;
    isAddSubchapterMode: boolean;
}

interface SubchapterForm {
    name: FormControl<string>;
}

export const MAX_SUBCHAPTER_TITLE_LENGTH = 200;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-opsman-chapter-extendable[chapter]",
    templateUrl: "./extendable.component.html",
    styleUrls: ["../chapter.scss", "./extendable.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ExtendableComponent {
    @Input() public set chapter(value: Chapter | undefined) {
        this.localStore.patchState({ chapter: value as ExtendableChapter });
    }

    @Input() public set isEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }

    @Output() public readonly editModeChange = new EventEmitter<string | null>();
    @Output() public readonly guideShow = new EventEmitter<void>();
    @Output() public readonly subchapterAdd = new EventEmitter<string>();
    @Output() public readonly subchapterRemove = new EventEmitter<Chapter>();
    @Output() public readonly subchapterSelect = new EventEmitter<Chapter>();

    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly chapter$ = this.localStore.selectByKey("chapter").pipe(RxjsUtils.filterFalsy());
    protected readonly isAddSubchapterMode$ = this.localStore.selectByKey("isAddSubchapterMode");
    protected readonly isEmptyStateVisible$ = combineLatest([
        this.chapter$.pipe(map((chapter) => !chapter.subchapters.length)),
        this.isAddSubchapterMode$,
    ]).pipe(map(([isChapterEmpty, isAddSubchapterMode]) => isChapterEmpty && !isAddSubchapterMode));

    protected readonly subchapterForm = new FormGroup<SubchapterForm>({
        name: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.maxLength(MAX_SUBCHAPTER_TITLE_LENGTH),
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
            ],
            nonNullable: true,
        }),
    });

    constructor(
        private readonly localStore: LocalComponentStore<ExtendableComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            chapter: undefined,
            isEditMode: false,
            isAddSubchapterMode: false,
        });
    }

    protected setAddSubchapterMode(isEnabled: boolean) {
        this.localStore.patchState({ isAddSubchapterMode: isEnabled });
    }

    protected tryToRemoveSubchapter(chapter: Chapter) {
        this.confirmSubchapterRemoval(chapter)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.subchapterRemove.emit(chapter));
    }

    protected charterSelect(chapter: Chapter) {
        this.subchapterSelect.emit(chapter);
    }

    protected addSubchapter() {
        this.subchapterForm.markAllAsTouched();

        if (!this.subchapterForm.controls.name.valid) {
            return;
        }

        this.subchapterAdd.emit(this.subchapterForm.controls.name.value);

        this.subchapterForm.controls.name.reset();
        this.setAddSubchapterMode(false);
    }

    private confirmSubchapterRemoval(chapter: Chapter): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibOpsMan.chapter.extendable.removalConfirmationDialog.titleText"),
                    confirmationText: this.transloco.translate(
                        "dtmWebAppLibOpsMan.chapter.extendable.removalConfirmationDialog.dialogMessage",
                        { chapterName: chapter.name }
                    ),
                    declineButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.chapter.extendable.removalConfirmationDialog.declineButtonLabel"
                    ),
                    confirmButtonLabel: this.transloco.translate(
                        "dtmWebAppLibOpsMan.chapter.extendable.removalConfirmationDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
