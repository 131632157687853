<dtm-ui-loader-container
    *ngrxLet="{
        isProcessing: isMembersListProcessing$,
        operatorsList: operatorsList$,
        areFiltersApplied: areFiltersApplied$,
        operatorsListError: operatorsListError$
    } as vm"
    [isShown]="vm.isProcessing"
>
    <div class="grid" *ngIf="vm.operatorsList?.length || vm.areFiltersApplied || vm.operatorsListError; else emptyStateTemplate">
        <dtm-web-app-lib-membership-operators-filters
            (filtersChange)="changeFilters($event)"
            [initialFilters]="initialFilters$ | ngrxPush"
            (filtersAppliedChange)="handleFiltersAppliedChange($event)"
        >
        </dtm-web-app-lib-membership-operators-filters>
        <dtm-web-app-lib-membership-operators-list
            [operatorsList]="operatorsList$ | ngrxPush"
            [pagination]="pagination$ | ngrxPush"
            (pageChange)="changePage($event)"
            (invitationAccept)="acceptInvitation($event)"
            (invitationReject)="rejectInvitation($event)"
            (redirectToOperatorDetails)="redirectToOperatorDetails($event)"
            (goToMissionPlanning)="startMissionWizard($event)"
            (membershipResignation)="resignFromMembership($event)"
            [operatorsListError]="operatorsListError$ | ngrxPush"
            (operatorsListRefresh)="operatorsListRefresh()"
        >
        </dtm-web-app-lib-membership-operators-list>
    </div>
    <ng-template #emptyStateTemplate>
        <div *ngIf="!vm.isProcessing" class="grid grid-maxsize-6">
            <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
                <ng-container titleSlot>
                    {{ "dtmWebAppMembership.operatorsList.emptyStateTitle" | transloco }}
                </ng-container>
                <img imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
            </dtm-ui-empty-state>
        </div>
    </ng-template>
</dtm-ui-loader-container>
