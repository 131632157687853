import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OperationScenarioCategory, PilotMemberCompetency, PilotsCompetencyStatus } from "../../../services/membership.models";

interface PilotMemberCompetenciesComponentState {
    competencies: PilotMemberCompetency[];
}

@Component({
    selector: "dtm-web-app-lib-pilot-member-competencies[competencies]",
    templateUrl: "./pilot-member-competencies.component.html",
    styleUrls: ["./pilot-member-competencies.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotMemberCompetenciesComponent {
    @Input() public set competencies(value: PilotMemberCompetency[] | undefined) {
        this.localStore.patchState({ competencies: value ?? [] });
    }

    public readonly competencies$ = this.localStore.selectByKey("competencies");
    public readonly OperationScenarioCategory = OperationScenarioCategory;
    public readonly PilotsCompetencyStatus = PilotsCompetencyStatus;

    constructor(private readonly localStore: LocalComponentStore<PilotMemberCompetenciesComponentState>) {
        localStore.setState({ competencies: [] });
    }
}
