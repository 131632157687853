import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { tap } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MembersListFilter, MembersListFilterFormKeys, MembershipStatus } from "../../../services/membership.models";

interface MembershipOperatorsFiltersComponentState {
    incomingFilters: MembersListFilter | undefined;
    filtersCount: number;
}

interface MembershipFilterForm {
    [MembersListFilterFormKeys.SearchByText]: FormControl<string | null>;
    [MembersListFilterFormKeys.Status]: FormControl<MembershipStatus[] | null>;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: MembersListFilterFormKeys.SearchByText,
        filterLabel: "dtmWebAppMembership.filters.searchByTextLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: MembersListFilterFormKeys.Status,
        filterLabel: "dtmWebAppMembership.filters.statusLabel",
        filterValueLabel: "dtmWebAppMembership.filters.statusValue",
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-membership-operators-filters",
    templateUrl: "./membership-operators-filters.component.html",
    styleUrls: ["./membership-operators-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MembershipOperatorsFiltersComponent implements OnInit {
    @Input() public set initialFilters(value: MembersListFilter | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }

    @Output() public filtersChange = new EventEmitter<MembersListFilter>();
    @Output() public filtersAppliedChange: EventEmitter<boolean> = new EventEmitter();

    public readonly FILTERS_MAP = FILTERS_MAP;
    public readonly MembershipStatus = MembershipStatus;
    public readonly statusControl = new FormControl<MembershipStatus[] | null>(null);
    public readonly searchTextControl = new FormControl<string | null>(null);
    public readonly filtersCount$ = this.localStore.selectByKey("filtersCount");

    protected readonly filtersForm = new FormGroup<MembershipFilterForm>({
        [MembersListFilterFormKeys.SearchByText]: this.searchTextControl,
        [MembersListFilterFormKeys.Status]: this.statusControl,
    });

    constructor(private readonly localStore: LocalComponentStore<MembershipOperatorsFiltersComponentState>) {
        localStore.setState({ incomingFilters: undefined, filtersCount: 0 });
    }

    public ngOnInit() {
        this.assignInitialFiltersAndWatchChanges();
    }

    protected clearFilters(): void {
        this.filtersForm.reset();
    }

    private onFormValueChanges(): void {
        this.filtersForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(equal),
                tap((value) => {
                    this.updateAppliedFiltersCount();
                    if (this.filtersForm.invalid) {
                        return;
                    }
                    this.filtersChange.emit(value);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateAppliedFiltersCount(): void {
        if (this.filtersForm.invalid) {
            return;
        }

        const values = Object.values(this.filtersForm.value);
        const filtersCount = values.flat().filter(Boolean).length;

        this.localStore.patchState({ filtersCount });
        this.filtersAppliedChange.emit(!!filtersCount);
    }

    private assignInitialFiltersAndWatchChanges() {
        this.onFormValueChanges();

        this.localStore
            .selectByKey("incomingFilters")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((filters) => {
                if (!this.isIncomingFilterDifferentThanForm(filters)) {
                    return;
                }

                this.filtersForm.setValue(filters, { emitEvent: false });
                this.updateAppliedFiltersCount();
            });
    }

    private isIncomingFilterDifferentThanForm(filters: MembersListFilter) {
        return filters.searchByText !== this.searchTextControl.value || this.statusControl.value?.toString() !== filters.status.toString();
    }
}
