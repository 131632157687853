import { Address, OperationScenarioCategory, OperatorType, PhoneNumber } from "@dtm-frontend/shared/ui";
import { DateUtils } from "@dtm-frontend/shared/utils";
import {
    CompanyInfo,
    ImportedDetails,
    ImportedDetailsAddress,
    ImportedEnterpriseOperatorDetails,
    ImportedLegalGuardianDetails,
    ImportedPersonalOperatorDetails,
    ImportedPilotDetails,
    OperatorMigrationDetails,
} from "../models/operator-migration.models";

interface ImportedQualificationResponseBody {
    name: string;
    expirationDate: string;
    category: OperationScenarioCategory;
}

interface ImportedLegalGuardianDetailsResponseBody extends Omit<ImportedLegalGuardianDetails, "dateOfBirth"> {
    dateOfBirth: string;
}

interface ImportedPilotDetailsResponseBody extends Omit<ImportedPilotDetails, "competencies" | "isApproved"> {
    competencies?: ImportedQualificationResponseBody[];
    approved: boolean;
}

export interface MigrateOperatorResponseBody {
    operatorId: string;
}

interface ImportedEnterpriseOperatorDetailsResponseBody {
    approved: boolean;
    foreign: boolean;
    operationalAuthorizations?: ImportedQualificationResponseBody[];
    number: string;
    secret: string;
    companyData: {
        name: string;
        nipregon: string;
        email: string;
        phone: PhoneNumber;
        address: ImportedDetailsAddress;
    };
}

interface ImportedPersonalOperatorDetailsResponseBody {
    number: string;
    approved: boolean;
    secret: string;
    foreign: boolean;
    operationalAuthorizations?: ImportedQualificationResponseBody[];
    personalData: {
        name: string;
        lastName: string;
        dateOfBirth: string;
        email: string;
        phone: PhoneNumber;
        address: ImportedDetailsAddress;
    };
}

export interface ImportDetailsResponseBody {
    id: string;
    migrated: boolean;
    migrationData: {
        legalOperator?: ImportedEnterpriseOperatorDetailsResponseBody;
        personalOperator?: ImportedPersonalOperatorDetailsResponseBody;
        pilot?: ImportedPilotDetailsResponseBody;
        legalGuardian?: ImportedLegalGuardianDetailsResponseBody;
    };
}

interface MigratePersonalOperatorWithCompanyPayload {
    companyData: CompanyInfo;
    legalGuardianAddress: Address | null;
}

interface MigratePersonalOperatorWithoutCompanyPayload {
    address: Address;
    legalGuardianAddress: Address | null;
}

function getLegalGuardianDetails(response?: ImportedLegalGuardianDetailsResponseBody): ImportedLegalGuardianDetails | undefined {
    if (!response) {
        return;
    }

    return {
        name: response.name,
        lastName: response.lastName,
        dateOfBirth: DateUtils.convertStringDateToDateWithoutTimeZone(response.dateOfBirth),
        email: response.email,
        phone: response.phone,
        address: response.address,
    };
}

function getLegalOperatorDetails(response?: ImportedEnterpriseOperatorDetailsResponseBody): ImportedEnterpriseOperatorDetails | undefined {
    if (!response) {
        return;
    }

    return {
        isApproved: response.approved,
        type: OperatorType.Enterprise,
        number: response.number,
        secret: response.secret,
        isForeign: response.foreign,
        operationalAuthorizations:
            response.operationalAuthorizations?.map((operationalAuthorization) => ({
                name: operationalAuthorization.name,
                expirationDate: DateUtils.convertStringDateToDateWithoutTimeZone(operationalAuthorization.expirationDate),
                category: operationalAuthorization.category,
            })) ?? [],
        companyData: {
            name: response.companyData.name,
            nipRegon: response.companyData.nipregon,
            email: response.companyData.email,
            phone: response.companyData.phone,
            address: response.companyData.address,
        },
    };
}

function getPilotDetails(response?: ImportedPilotDetailsResponseBody): ImportedPilotDetails | undefined {
    if (!response) {
        return;
    }

    return {
        number: response.number,
        isApproved: response.approved,
        competencies:
            response.competencies?.map((competency) => ({
                name: competency.name,
                expirationDate: DateUtils.convertStringDateToDateWithoutTimeZone(competency.expirationDate),
                category: competency.category,
            })) ?? [],
    };
}

function getPersonalOperatorDetails(response?: ImportedPersonalOperatorDetailsResponseBody): ImportedPersonalOperatorDetails | undefined {
    if (!response) {
        return;
    }

    return {
        type: OperatorType.Personal,
        isApproved: response.approved,
        number: response.number,
        secret: response.secret,
        isForeign: response.foreign,
        operationalAuthorizations:
            response.operationalAuthorizations?.map((operationalAuthorization) => ({
                name: operationalAuthorization.name,
                expirationDate: DateUtils.convertStringDateToDateWithoutTimeZone(operationalAuthorization.expirationDate),
                category: operationalAuthorization.category,
            })) ?? [],
        personalData: {
            name: response.personalData.name,
            lastName: response.personalData.lastName,
            dateOfBirth: DateUtils.convertStringDateToDateWithoutTimeZone(response.personalData.dateOfBirth),
            email: response.personalData.email,
            phone: response.personalData.phone,
            address: response.personalData.address,
        },
    };
}

export function convertImportDetailsResponseBodyToImportedDetails(response: ImportDetailsResponseBody): ImportedDetails {
    return {
        id: response.id,
        isMigrated: response.migrated,
        migrationData: {
            legalOperator: getLegalOperatorDetails(response.migrationData.legalOperator),
            personalOperator: getPersonalOperatorDetails(response.migrationData.personalOperator),
            pilot: getPilotDetails(response.migrationData.pilot),
            legalGuardian: getLegalGuardianDetails(response.migrationData.legalGuardian),
        },
    };
}

export function convertPersonalOperatorFormValueToMigratePersonalOperatorPayload(
    migrationDetails: OperatorMigrationDetails
): MigratePersonalOperatorWithCompanyPayload | MigratePersonalOperatorWithoutCompanyPayload {
    if (migrationDetails.personalOperator?.companyData) {
        return {
            companyData: {
                ...migrationDetails.personalOperator.companyData,
                address: migrationDetails.personalOperator.address as Address,
            },
            legalGuardianAddress: (migrationDetails.legalGuardianAddress?.address as Address) ?? null,
        };
    }

    if (migrationDetails.pilot?.companyData) {
        return {
            companyData: migrationDetails.pilot.companyData,
            address: migrationDetails.pilot.address as Address,
            legalGuardianAddress: (migrationDetails.legalGuardianAddress?.address as Address) ?? null,
        };
    }

    return {
        address: (migrationDetails.personalOperator?.address as Address) ?? (migrationDetails.pilot?.address as Address),
        legalGuardianAddress: (migrationDetails.legalGuardianAddress?.address as Address) ?? null,
    };
}
