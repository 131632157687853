import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "@dtm-frontend/shared/ui";
import { NOT_FOUND_PAGE } from "@dtm-frontend/shared/utils";
import { GlobalOutlets } from "../shared/models/router.models";
import { PublicProfileOperatorContainerComponent } from "./components/public-profile-operator-container/public-profile-operator-container.component";
import { PublicProfilePilotContainerComponent } from "./components/public-profile-pilot-container/public-profile-pilot-container.component";
import { OPERATOR_ID, PILOT_ID, PublicProfileOperatorResolver, PublicProfilePilotResolver } from "./services/public-profile.resolvers";

const routes: Routes = [
    {
        path: `operator/:${OPERATOR_ID}`,
        component: PublicProfileOperatorContainerComponent,
        outlet: GlobalOutlets.Public,
        resolve: {
            init: PublicProfileOperatorResolver,
        },
    },
    {
        path: `pilot/:${PILOT_ID}`,
        component: PublicProfilePilotContainerComponent,
        outlet: GlobalOutlets.Public,
        resolve: {
            init: PublicProfilePilotResolver,
        },
    },
    {
        path: NOT_FOUND_PAGE,
        component: PageNotFoundComponent,
        outlet: GlobalOutlets.Public,
    },

    {
        path: "**",
        pathMatch: "full",
        redirectTo: NOT_FOUND_PAGE,
        outlet: GlobalOutlets.Public,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [PublicProfileOperatorResolver, PublicProfilePilotResolver],
})
export class PublicProfileRoutingModule {}
