import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MissionCategory } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { OperationScenario } from "../../../services/specific-permit-application.models";

interface CompetenciesComponentState {
    basicCompetencies: OperationScenario[];
    additionalCompetencies: string[];
    hasEuRegulationCompetency: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-competencies",
    templateUrl: "./competencies.component.html",
    styleUrls: ["../../common.scss", "./competencies.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CompetenciesComponent {
    @Input() public set basicCompetencies(value: OperationScenario[] | undefined) {
        this.localStore.patchState({ basicCompetencies: value ?? [] });
    }

    @Input() public set additionalCompetencies(value: string[] | undefined) {
        this.localStore.patchState({ additionalCompetencies: value ?? [] });
    }

    @Input() public set hasEuRegulationCompetency(value: BooleanInput) {
        this.localStore.patchState({ hasEuRegulationCompetency: coerceBooleanProperty(value) });
    }

    protected readonly MissionCategory = MissionCategory;
    protected readonly basicCompetencies$ = this.localStore.selectByKey("basicCompetencies");
    protected readonly additionalCompetencies$ = this.localStore.selectByKey("additionalCompetencies");
    protected readonly hasEuRegulationCompetency$ = this.localStore.selectByKey("hasEuRegulationCompetency");

    constructor(private readonly localStore: LocalComponentStore<CompetenciesComponentState>) {
        this.localStore.setState({
            basicCompetencies: [],
            additionalCompetencies: [],
            hasEuRegulationCompetency: false,
        });
    }
}
