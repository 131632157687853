import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { UavInfo } from "../../services/uav.models";
import { SerialNumbersDialogComponent } from "../serial-numbers-dialog/serial-numbers-dialog.component";

interface UavInfoComponentState {
    uav: UavInfo | undefined;
    canShareUav: boolean;
    isShortMode: boolean;
    isActionsButtonVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-info[uav]",
    templateUrl: "./uav-info.component.html",
    styleUrls: ["./uav-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavInfoComponent implements OnInit {
    @Input() public set uav(value: UavInfo | undefined) {
        this.localStore.patchState({ uav: value });
    }

    @Input() public set canShareUav(value: BooleanInput) {
        this.localStore.patchState({ canShareUav: coerceBooleanProperty(value) });
    }

    @Input() public set isShortMode(value: BooleanInput) {
        this.localStore.patchState({ isShortMode: coerceBooleanProperty(value) });
    }

    @Output() public edit = new EventEmitter<void>();
    @Output() public share = new EventEmitter<void>();
    @Output() public remove = new EventEmitter<void>();

    @HostBinding("class.uav-info-short") protected get isShortModeEnabled() {
        return this.localStore.selectSnapshotByKey("isShortMode");
    }

    protected readonly uav$ = this.localStore.selectByKey("uav").pipe(RxjsUtils.filterFalsy());
    protected readonly canShareUav$ = this.localStore.selectByKey("canShareUav");
    protected readonly isShortMode$ = this.localStore.selectByKey("isShortMode");
    protected readonly isActionsButtonVisible$ = this.localStore.selectByKey("isActionsButtonVisible");

    constructor(
        private readonly localStore: LocalComponentStore<UavInfoComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            uav: undefined,
            canShareUav: false,
            isShortMode: false,
            isActionsButtonVisible: false,
        });
    }

    public ngOnInit(): void {
        this.localStore.patchState({
            isActionsButtonVisible: this.edit.observed || this.share.observed || this.remove.observed,
        });
    }

    protected tryToRemoveUav() {
        const uav = this.localStore.selectSnapshotByKey("uav");

        if (!uav) {
            Logger.captureMessage("UavInfoComponent.tryToRemoveUav: uav is empty", {
                level: "warning",
                extra: { uav },
            });

            return;
        }

        this.confirmUavRemoval(uav)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.remove.emit());
    }

    protected showSerialNumbers(uav: UavInfo) {
        return this.dialogService.open(SerialNumbersDialogComponent, {
            data: {
                serialNumbers: uav.serialNumbers,
            },
        });
    }

    private confirmUavRemoval(uav: UavInfo): Observable<boolean> {
        const confirmationText = uav.isSwarm
            ? this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.swarmDialogMessage", {
                  uavName: uav.name,
                  uavSwarmSize: uav.serialNumbers.length,
              })
            : this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.dialogMessage", {
                  uavName: uav.name,
                  uavSerialNumber: uav.serialNumbers[0],
              });

        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.titleText"),
                    confirmationText,
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.declineButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibUav.uavRemoval.confirmationDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
