import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService, FILES_UPLOAD_API_PROVIDER, PermitType, UploadedFile } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { OperatorPermitLocationFileApiService } from "../../../../services/operator-permit-location-file-api.service";
import { OperatorPermitsErrorType } from "../../../../services/operator-permits.models";
import { OperatorPermitsActions } from "../../../../state/operator-permits.actions";
import { OperatorPermitsState } from "../../../../state/operator-permits.state";
import { KmlPreviewDialogComponent } from "../kml-preview-dialog/kml-preview-dialog.component";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-permit-preview",
    templateUrl: "./permit-preview.component.html",
    styleUrls: ["./permit-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FILES_UPLOAD_API_PROVIDER, useClass: OperatorPermitLocationFileApiService }],
})
export class PermitPreviewComponent {
    protected readonly permit$ = this.store.select(OperatorPermitsState.permit);
    protected readonly permitError$ = this.store.select(OperatorPermitsState.permitError);
    protected readonly isProcessing$ = this.store.select(OperatorPermitsState.isProcessing);
    protected readonly operatorPermitType = this.route.snapshot.data.permitType;
    protected readonly PermitType = PermitType;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly router: Router,
        private readonly dialogService: DialogService
    ) {}

    protected retryFetchData(): void {
        const isCapabilitiesError =
            this.store.selectSnapshot(OperatorPermitsState.permitError)?.type === OperatorPermitsErrorType.CannotGetCapabilities;

        if (isCapabilitiesError) {
            this.store
                .dispatch(new OperatorPermitsActions.GetOperatorPermitsCapabilities(this.route.snapshot.params.operatorId))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    if (!this.store.selectSnapshot(OperatorPermitsState.permitError)) {
                        this.getPermitDetails();
                    }
                });

            return;
        }

        this.getPermitDetails();
    }

    private getPermitDetails() {
        if (this.operatorPermitType === PermitType.CrossBorder) {
            this.store.dispatch(
                new OperatorPermitsActions.GetCrossBorderPermitDetails(
                    this.route.snapshot.params.permitId,
                    this.route.snapshot.params.operatorId
                )
            );

            return;
        }
        this.store.dispatch(
            new OperatorPermitsActions.GetOperatorPermitDetails(this.route.snapshot.params.permitId, this.route.snapshot.params.operatorId)
        );
    }

    protected previewKmlFile(file: UploadedFile): void {
        this.store
            .dispatch(new OperatorPermitsActions.GetKmlPreview(file))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.store.selectSnapshot(OperatorPermitsState.kmlFileForPreviewError)) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppLibOperatorPermits.permitPreview.cannotGetKmlForPreviewErrorMessage")
                    );

                    return;
                }

                this.dialogService.open(KmlPreviewDialogComponent, {
                    data: this.store.selectSnapshot(OperatorPermitsState.kmlFileForPreview),
                });
            });
    }
}
