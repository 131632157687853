<ng-container *ngrxLet="{ chapter: chapter$, isAddSubchapterMode: isAddSubchapterMode$ } as vm">
    <section class="heading">
        <h2 class="header">{{ vm.chapter.name }}</h2>
        <button type="button" class="button-secondary" (click)="guideShow.emit()">
            <dtm-ui-icon name="question"></dtm-ui-icon>
            {{ "dtmWebAppLibOpsMan.chapter.guideButtonLabel" | transloco }}
        </button>
    </section>
    <section class="content-wrapper">
        <dtm-web-app-lib-opsman-attachments-list [chapter]="vm.chapter"></dtm-web-app-lib-opsman-attachments-list>
        <dtm-ui-empty-state *ngIf="isEmptyStateVisible$ | ngrxPush; else subchaptersListTemplate" [mode]="EmptyStateMode.Secondary">
            <ng-container messageSlot>
                {{ "dtmWebAppLibOpsMan.chapter.extendable.emptyStateMessage" | transloco }}
            </ng-container>
            <img imageSlot src="assets/images/extendable-chapter-empty.svg" alt="" />
            <button footerSlot type="button" class="button-secondary" (click)="setAddSubchapterMode(true)">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibOpsMan.chapter.extendable.addSubchapterButtonLabel" | transloco }}
            </button>
        </dtm-ui-empty-state>

        <ng-template #subchaptersListTemplate>
            <ul class="subchapters">
                <li *ngFor="let subchapter of vm.chapter.subchapters">
                    <span class="subchapter-name">{{ subchapter.name }}</span>
                    <button
                        type="button"
                        class="button-secondary"
                        (click)="tryToRemoveSubchapter(subchapter)"
                        *ngIf="vm.chapter.isEditable"
                    >
                        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                        {{ "dtmWebAppLibOpsMan.chapter.extendable.removeButtonLabel" | transloco }}
                    </button>
                    <button type="button" class="button-secondary" (click)="charterSelect(subchapter)">
                        {{ "dtmWebAppLibOpsMan.chapter.extendable.goToButtonLabel" | transloco }}
                        <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
                    </button>
                </li>
            </ul>
            <button *ngIf="!vm.isAddSubchapterMode" type="button" class="button-secondary add-button" (click)="setAddSubchapterMode(true)">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibOpsMan.chapter.extendable.addSubchapterButtonLabel" | transloco }}
            </button>
            <section *ngIf="vm.isAddSubchapterMode" class="add-subchapter-section" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
                <h2 class="header">
                    {{ "dtmWebAppLibOpsMan.chapter.extendable.subchapterFormHeader" | transloco }}
                </h2>
                <form [formGroup]="subchapterForm">
                    <dtm-ui-input-field>
                        <input
                            matInput
                            type="text"
                            [formControl]="subchapterForm.controls.name"
                            autocomplete="off"
                            (keydown.enter)="addSubchapter()"
                            cdkFocusInitial
                        />
                        <div class="field-error" *dtmUiFieldHasError="subchapterForm.controls.name; name: 'maxlength'; error as error">
                            {{
                                "dtmWebAppLibOpsMan.chapter.extendable.subchapterFormMaxLengthValueError"
                                    | transloco : { maxLength: error.requiredLength }
                            }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="subchapterForm.controls.name; name: ['requiredTouched', 'pattern']">
                            {{ "dtmWebAppLibOpsMan.chapter.extendable.subchapterFormRequiredErrorHint" | transloco }}
                        </div>
                    </dtm-ui-input-field>
                </form>
                <div class="actions">
                    <button type="button" class="button-secondary" (click)="setAddSubchapterMode(false)">
                        {{ "dtmWebAppLibOpsMan.chapter.extendable.subchapterFormCancelButtonLabel" | transloco }}
                    </button>
                    <button type="button" class="button-primary" (click)="addSubchapter()">
                        {{ "dtmWebAppLibOpsMan.chapter.extendable.subchapterFormAddButtonLabel" | transloco }}
                    </button>
                </div>
            </section>
        </ng-template>
    </section>
</ng-container>
