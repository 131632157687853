import { HttpClient, HttpContext, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
    AuthenticationDetails,
    ImportedDetails,
    LegalOperatorFormValue,
    OperatorMigrationDetails,
    OperatorMigrationError,
    OperatorMigrationErrorType,
} from "../models/operator-migration.models";
import { OPERATOR_MIGRATION_ENDPOINTS, OperatorMigrationEndpoints } from "../operator-migration.tokens";
import {
    ImportDetailsResponseBody,
    MigrateOperatorResponseBody,
    convertImportDetailsResponseBodyToImportedDetails,
    convertPersonalOperatorFormValueToMigratePersonalOperatorPayload,
} from "./operator-migration-api.converters";

const UNAPPROVED_OPERATOR_ERROR_MESSAGE = "unapproved_operator";
const PERSONAL_OPERATOR_ALREADY_EXISTS_ERROR = "Personal Operator already registered with a given userId";
const COMPANY_NUMBER_ALREADY_USED_ERROR = "Company number already used";

@Injectable({
    providedIn: "root",
})
export class OperatorMigrationApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(OPERATOR_MIGRATION_ENDPOINTS) private readonly endpoints: OperatorMigrationEndpoints
    ) {}

    public importDetails(credentials: AuthenticationDetails): Observable<ImportedDetails> {
        return this.httpClient
            .post<ImportDetailsResponseBody>(this.endpoints.importOperatorDetails, credentials, {
                context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true).set(SKIP_AUTHENTICATION_HTTP_INTERCEPTOR, true),
            })
            .pipe(
                map((response) => convertImportDetailsResponseBodyToImportedDetails(response)),
                catchError((errorResponse) =>
                    throwError(() => {
                        switch (errorResponse.status) {
                            case HttpStatusCode.Conflict:
                                return { type: OperatorMigrationErrorType.OperatorAlreadyImported };
                            case HttpStatusCode.Unauthorized:
                                return { type: OperatorMigrationErrorType.WrongCredentials };
                            case HttpStatusCode.Forbidden:
                                return { type: OperatorMigrationErrorType.DetailsAlreadyImportedByOtherUser };
                            case HttpStatusCode.BadRequest:
                                return this.getBadRequestErrorType(errorResponse);
                            case HttpStatusCode.NotFound:
                                return { type: OperatorMigrationErrorType.NotFound };
                            default: {
                                return { type: OperatorMigrationErrorType.Unknown };
                            }
                        }
                    })
                )
            );
    }

    public resetPassword(email: string): Observable<void> {
        return this.httpClient
            .post<void>(this.endpoints.resetPassword, { email })
            .pipe(catchError(() => throwError(() => ({ type: OperatorMigrationErrorType.ResetPasswordError }))));
    }

    public migrateEnterpriseOperator(migrationDetails: LegalOperatorFormValue, importedOperatorId: string): Observable<string> {
        return this.httpClient
            .post<MigrateOperatorResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.migrateEnterpriseOperator, { operatorId: importedOperatorId }),
                migrationDetails
            )
            .pipe(
                map((response: MigrateOperatorResponseBody) => response.operatorId),
                catchError((error) => throwError(() => this.getMigrationError(error)))
            );
    }

    public migratePersonalOperator(migrationDetails: OperatorMigrationDetails, importedOperatorId: string): Observable<string> {
        return this.httpClient
            .post<MigrateOperatorResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.migratePersonalOperator, { operatorId: importedOperatorId }),
                convertPersonalOperatorFormValueToMigratePersonalOperatorPayload(migrationDetails)
            )
            .pipe(
                map((response: MigrateOperatorResponseBody) => response.operatorId),
                catchError((error) => throwError(() => this.getMigrationError(error)))
            );
    }

    private getBadRequestErrorType(error: HttpErrorResponse): OperatorMigrationError {
        if (error.message.includes(UNAPPROVED_OPERATOR_ERROR_MESSAGE)) {
            return { type: OperatorMigrationErrorType.UnapprovedOperator };
        }

        return { type: OperatorMigrationErrorType.Unknown };
    }

    private getMigrationError(errorResponse: HttpErrorResponse): OperatorMigrationError {
        if (errorResponse.error.generalMessage.includes(PERSONAL_OPERATOR_ALREADY_EXISTS_ERROR)) {
            return { type: OperatorMigrationErrorType.PersonalOperatorAlreadyExists };
        }

        if (errorResponse.error.generalMessage.includes(COMPANY_NUMBER_ALREADY_USED_ERROR)) {
            return { type: OperatorMigrationErrorType.CompanyNumberAlreadyUsed };
        }

        return { type: OperatorMigrationErrorType.Unknown };
    }
}
