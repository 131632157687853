import { Sail } from "../../shared/models/operations-manual.models";
import {
    ApplicationInfoData,
    OperationDetailsData,
    OperationInfoData,
    StaffCompetencies,
    StatementsData,
} from "../services/specific-permit-application.models";

export namespace SpecificPermitApplicationActions {
    export class GetCapabilities {
        public static readonly type = "[Specific Permit Application] Get application capabilities";

        constructor(public operatorId: string) {}
    }

    export class MarkAsDirty {
        public static readonly type = "[Specific Permit Application] Mark form as dirty (has unsaved changes)";

        constructor(public isDirty: boolean = true) {}
    }

    export class GetOperations {
        public static readonly type = "[Specific Permit Application] Get available operations";

        constructor(public query: string) {}
    }

    export class GetOperation {
        public static readonly type = "[Specific Permit Application] Get operation";

        constructor(public operationId: string) {}
    }

    export class GetOperationalGeometryData {
        public static readonly type = "[Specific Permit Application] Get operational geometry data";

        constructor(public operationId: string) {}
    }

    export class ClearForm {
        public static readonly type = "[Specific Permit Application] Clear application data";
    }

    export class GetOperationArea {
        public static readonly type = "[Specific Permit Application] Get operation area";

        constructor(public operationId: string) {}
    }

    export class GetPredefinedArea {
        public static readonly type = "[Specific Permit Application] Get predefined area";

        constructor(public areaId: string) {}
    }

    export class GetRequiredPilotTrainings {
        public static readonly type = "[Specific Permit Application] Get required pilot trainings";

        constructor(public sail: Sail, public pilotCompetencies: StaffCompetencies) {}
    }

    export class SetDraftName {
        public static readonly type = "[Specific Permit Application] Set application draft name";

        constructor(public name: string) {}
    }

    export class SaveDraft {
        public static readonly type = "[Specific Permit Application] Save application draft";
    }

    export class SetOperationInfoData {
        public static readonly type = "[Specific Permit Application] Set operation info";

        constructor(public data: OperationInfoData) {}
    }

    export class SetOperationDetailsData {
        public static readonly type = "[Specific Permit Application] Set operation details";

        constructor(public data: OperationDetailsData) {}
    }

    export class SetStatementsData {
        public static readonly type = "[Specific Permit Application] Set statements";

        constructor(public data: StatementsData) {}
    }

    export class SetApplicationInfoData {
        public static readonly type = "[Specific Permit Application] Set application info";

        constructor(public data: ApplicationInfoData) {}
    }

    export class GetDraft {
        public static readonly type = "[Specific Permit Application] Get draft";

        constructor(public draftId: string) {}
    }

    export class GenerateApplication {
        public static readonly type = "[Specific Permit Application] Generate application";
    }

    export class DownloadOperationsManualTemplate {
        public static readonly type = "[Specific Permit Application] Download Operations Manual Template";

        constructor(public fileName: string) {}
    }

    export class DownloadAttorneyPowerTemplate {
        public static readonly type = "[Specific Permit Application] Download Attorney Power Template";

        constructor(public fileName: string) {}
    }
}
