import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { tap } from "rxjs";
import { debounceTime, distinctUntilChanged, first } from "rxjs/operators";
import { DashboardMissionsFilters } from "../../services/dashboard.models";

const MIN_SEARCH_TEXT_LENGTH = 3;

interface DashboardMissionsFiltersComponentState {
    filtersCount: number;
    incomingFilters: DashboardMissionsFilters | undefined;
    isInitiallyExpanded: boolean;
}

interface FiltersForm {
    searchByText: FormControl<string | null>;
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "searchByText",
        filterLabel: "dtmWebAppLibDashboard.missionsListFilters.searchByTextLabel",
        type: FilterType.TextEllipsis,
    },
];

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-dashboard-missions-filters",
    templateUrl: "./dashboard-missions-filters.component.html",
    styleUrls: ["./dashboard-missions-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DashboardMissionsFiltersComponent implements OnInit {
    protected readonly filtersCount$ = this.localStore.selectByKey("filtersCount");
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly searchByTextControl = new FormControl<string>("", {
        validators: Validators.minLength(MIN_SEARCH_TEXT_LENGTH),
        nonNullable: true,
    });
    protected readonly filtersForm = new FormGroup<FiltersForm>({
        searchByText: this.searchByTextControl,
    });
    protected readonly isInitiallyExpanded$ = this.localStore.selectByKey("isInitiallyExpanded").pipe(first());

    @Input() public set incomingFilters(value: DashboardMissionsFilters | undefined) {
        this.localStore.patchState({ incomingFilters: value });
    }

    @Input() public set isInitiallyExpanded(value: BooleanInput) {
        this.localStore.patchState({ isInitiallyExpanded: coerceBooleanProperty(value) });
    }

    @Output() public filtersChange = new EventEmitter<DashboardMissionsFilters>();

    constructor(private readonly localStore: LocalComponentStore<DashboardMissionsFiltersComponentState>) {
        this.localStore.setState({ filtersCount: 0, incomingFilters: undefined, isInitiallyExpanded: true });
    }

    public ngOnInit() {
        this.watchFormValueChanges();
        this.watchIncomingFilters();
    }

    private updateAppliedFiltersCount() {
        if (this.filtersForm.invalid) {
            return;
        }

        const values = Object.values(this.filtersForm.value);
        const filtersCount = values.flat().filter((value) => FunctionUtils.isTruthy(value)).length;

        this.localStore.patchState({ filtersCount });
    }

    private watchFormValueChanges() {
        this.filtersForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(equal),
                tap(() => {
                    this.updateAppliedFiltersCount();
                    if (this.filtersForm.invalid) {
                        return;
                    }
                    this.filtersChange.emit({ searchByText: this.searchByTextControl.value });
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private watchIncomingFilters() {
        this.localStore
            .selectByKey("incomingFilters")
            .pipe(untilDestroyed(this))
            .subscribe((filters) => {
                this.filtersForm.setValue({ searchByText: filters?.searchByText ?? "" }, { emitEvent: false });
                this.updateAppliedFiltersCount();
            });
    }
}
