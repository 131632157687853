import { DOCUMENT } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, Inject, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthState, KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { FLIGHT_TRACKING_ENDPOINTS, FlightTrackingModule } from "@dtm-frontend/shared/map/flight-tracking";
import { WEATHER_ENDPOINTS, WeatherModule } from "@dtm-frontend/shared/map/geo-weather";
import { GEO_ZONES_ENDPOINTS, GeoZonesModule } from "@dtm-frontend/shared/map/geo-zones";
import { NOTIFICATIONS_ENDPOINTS, SharedNotificationsModule } from "@dtm-frontend/shared/notifications";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TRANSLATION_ENDPOINTS,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { VERSION_DATA_ENDPOINTS, VersionModule } from "@dtm-frontend/shared/ui/version";
import {
    ACCESSIBILITY_STATEMENT_URL,
    FunctionUtils,
    Logger,
    LoggerModule,
    MISSION_PLANNING_COURSE_URL,
    PANSA_UTM_URL,
    RxjsUtils,
    TERMS_OF_USE_URL,
} from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import { WebAppLibModule } from "@dtm-frontend/web-app-lib";
import { DASHBOARD_ENDPOINTS, DashboardModule } from "@dtm-frontend/web-app-lib/dashboard";
import { HelpCenterModule } from "@dtm-frontend/web-app-lib/help-center";
import { LEGAL_GUARDIAN_DETAILS_ENDPOINTS, LegalGuardianDetailsModule } from "@dtm-frontend/web-app-lib/legal-guardian-details";
import { MEMBERSHIP_ENDPOINTS, MembershipModule } from "@dtm-frontend/web-app-lib/membership";
import { MISSION_ENDPOINTS, MissionModule } from "@dtm-frontend/web-app-lib/mission";
import { OPERATIONS_MANUAL_ENDPOINTS, OperationsManualModule } from "@dtm-frontend/web-app-lib/operations-manual";
import { OPERATOR_CONVERSATIONS_ENDPOINTS, OperatorConversationsModule } from "@dtm-frontend/web-app-lib/operator-conversations";
import { OPERATOR_MIGRATION_ENDPOINTS, OperatorMigrationModule } from "@dtm-frontend/web-app-lib/operator-migration";
import { OPERATOR_PERMISSIONS_ENDPOINTS, OperatorPermissionsModule } from "@dtm-frontend/web-app-lib/operator-permissions";
import { OPERATOR_PERMITS_ENDPOINTS, OperatorPermitsModule } from "@dtm-frontend/web-app-lib/operator-permits";
import { OPERATOR_PROFILE_ENDPOINTS, OperatorProfileModule } from "@dtm-frontend/web-app-lib/operator-profile";
import {
    OPERATOR_REGISTRATION_ENDPOINTS,
    PILOT_REGISTRATION_ENDPOINTS,
    PilotOperatorRegistrationModule,
} from "@dtm-frontend/web-app-lib/pilot-operator-registration";
import { PILOT_PROFILE_ENDPOINTS, PilotProfileModule } from "@dtm-frontend/web-app-lib/pilot-profile";
import { PUBLIC_PROFILE_ENDPOINTS, PublicProfileModule } from "@dtm-frontend/web-app-lib/public-profile";
import {
    FILES_MANAGEMENT_ENDPOINTS,
    GLOBAL_CAPABILITIES_ENDPOINTS,
    OperatorContextActions,
    SharedWebAppModule,
} from "@dtm-frontend/web-app-lib/shared";
import {
    SPECIFIC_PERMIT_APPLICATION_ENDPOINTS,
    SpecificPermitApplicationModule,
} from "@dtm-frontend/web-app-lib/specific-permit-application";
import { TACTICAL_ENDPOINTS, TacticalModule } from "@dtm-frontend/web-app-lib/tactical";
import { TRAINING_EXAM_RESULTS_ENDPOINTS, TrainingExamResultsModule } from "@dtm-frontend/web-app-lib/training-exam-results";
import { UAV_ENDPOINTS, UavModule } from "@dtm-frontend/web-app-lib/uav";
import { USER_PROFILE_ENDPOINTS, UserProfileModule } from "@dtm-frontend/web-app-lib/user-profile";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { finalize, forkJoin, lastValueFrom } from "rxjs";
import { filter, first, switchMap, tap } from "rxjs/operators";
import { WebAppEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { LazyRouterOutletComponent } from "./components/lazy-router-outlet/lazy-router-outlet.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://7df3053b660426731c68a0b5289ce39f@sentry.pansa.cloud/2");

export function createAppModule(environment: WebAppEnvironment) {
    @NgModule({
        declarations: [AppComponent, LazyRouterOutletComponent, HeaderComponent, MenuComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            HttpClientModule,
            WebAppLibModule,
            MatButtonModule,
            MatCardModule,
            MatIconModule,
            MatMenuModule,
            MatProgressSpinnerModule,
            MatTooltipModule,
            MissionModule.forRoot(),
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "WebApp",
            }),
            PilotOperatorRegistrationModule.forRoot(),
            LetModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            SharedUiModule,
            SharedWebsocketModule.forRoot(),
            UavModule.forRoot(),
            SpecificPermitApplicationModule.forRoot(),
            VersionModule.forRoot(),
            TacticalModule.forRoot(),
            OperatorConversationsModule.forRoot(),
            SharedWebAppModule.forRoot(),
            SharedNotificationsModule.forRoot(),
            MembershipModule.forRoot(),
            LegalGuardianDetailsModule.forRoot(),
            UserProfileModule.forRoot(),
            OperatorProfileModule.forRoot(),
            OperatorPermitsModule.forRoot(),
            PilotProfileModule.forRoot(),
            OperatorPermissionsModule.forRoot(),
            PublicProfileModule.forRoot(),
            OperationsManualModule.forRoot(),
            GeoZonesModule.forRoot(),
            OperatorMigrationModule.forRoot(),
            TrainingExamResultsModule.forRoot(),
            DashboardModule.forRoot(),
            WeatherModule.forRoot(),
            HelpCenterModule,
            FlightTrackingModule.forRoot(),
        ],
        providers: [
            {
                provide: GLOBAL_CAPABILITIES_ENDPOINTS,
                useValue: environment.globalCapabilities,
            },
            {
                provide: PILOT_REGISTRATION_ENDPOINTS,
                useValue: environment.pilotRegistrationEndpoints,
            },
            {
                provide: OPERATOR_REGISTRATION_ENDPOINTS,
                useValue: environment.operatorRegistrationEndpoints,
            },
            {
                provide: MISSION_ENDPOINTS,
                useValue: environment.missionEndpoints,
            },
            {
                provide: UAV_ENDPOINTS,
                useValue: environment.uavEndpoints,
            },
            {
                provide: SPECIFIC_PERMIT_APPLICATION_ENDPOINTS,
                useValue: environment.specificPermitApplicationEndpoints,
            },
            {
                provide: TACTICAL_ENDPOINTS,
                useValue: environment.tacticalEndpoints,
            },
            {
                provide: FILES_MANAGEMENT_ENDPOINTS,
                useValue: environment.filesManagementEndpoints,
            },
            {
                provide: OPERATOR_CONVERSATIONS_ENDPOINTS,
                useValue: environment.operatorConversationsEndpoints,
            },
            {
                provide: VERSION_DATA_ENDPOINTS,
                useValue: environment.versionDataEndpoints,
            },
            {
                provide: TRANSLATION_ENDPOINTS,
                useValue: environment.translationEndpoints,
            },
            {
                provide: NOTIFICATIONS_ENDPOINTS,
                useValue: environment.notificationEndpoints,
            },
            {
                provide: SHARED_MAP_ENDPOINTS,
                useValue: environment.sharedMapEndpoints,
            },
            {
                provide: MEMBERSHIP_ENDPOINTS,
                useValue: environment.membershipEndpoints,
            },
            {
                provide: OPERATOR_MIGRATION_ENDPOINTS,
                useValue: environment.operatorMigrationEndpoints,
            },
            {
                provide: USER_PROFILE_ENDPOINTS,
                useValue: environment.userProfileEndpoints,
            },
            {
                provide: OPERATOR_PROFILE_ENDPOINTS,
                useValue: environment.operatorProfileEndpoints,
            },
            {
                provide: OPERATOR_PERMITS_ENDPOINTS,
                useValue: environment.operatorPermitsEndpoints,
            },
            {
                provide: PILOT_PROFILE_ENDPOINTS,
                useValue: environment.pilotProfileEndpoints,
            },
            {
                provide: OPERATOR_PERMISSIONS_ENDPOINTS,
                useValue: environment.operatorPermissionsEndpoints,
            },
            {
                provide: LEGAL_GUARDIAN_DETAILS_ENDPOINTS,
                useValue: environment.legalGuardianDetailsEndpoints,
            },
            {
                provide: PUBLIC_PROFILE_ENDPOINTS,
                useValue: environment.publicProfileEndpoints,
            },
            {
                provide: OPERATIONS_MANUAL_ENDPOINTS,
                useValue: environment.operationsManualEndpoints,
            },
            {
                provide: GEO_ZONES_ENDPOINTS,
                useValue: environment.geoZonesEndpoints,
            },
            {
                provide: WEATHER_ENDPOINTS,
                useValue: environment.weatherEndpoints,
            },
            {
                provide: TRAINING_EXAM_RESULTS_ENDPOINTS,
                useValue: environment.trainingExamResults,
            },
            {
                provide: DASHBOARD_ENDPOINTS,
                useValue: environment.dashboardEndpoints,
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: TERMS_OF_USE_URL,
                useValue: environment.routes.termsOfUseUrl,
            },
            {
                provide: ACCESSIBILITY_STATEMENT_URL,
                useValue: environment.routes.accessibilityStatementUrl,
            },
            {
                provide: PANSA_UTM_URL,
                useValue: environment.routes.pansaUtmUrl,
            },
            {
                provide: MISSION_PLANNING_COURSE_URL,
                useValue: environment.routes.missionPlanningCourseUrl,
            },
            { provide: FLIGHT_TRACKING_ENDPOINTS, useValue: environment.flightTrackingEndpoints },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (store: Store): SharedWebsocketModuleConfig => ({
                    endpoint: environment.webSocketEndpoint,
                    authorizationTokenProvider: async () =>
                        await lastValueFrom(store.select(AuthState.token).pipe(RxjsUtils.filterFalsy(), first())),
                }),
                deps: [Store],
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "webApp",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(
            keycloakService: KeycloakService,
            store: Store,
            translocoHelper: TranslationHelperService,
            titleService: Title,
            @Inject(DOCUMENT) document: Document
        ) {
            // TODO: is this a good place for some initializing tasks? Maybe it should be extracted somewhere, use APP_INITIALIZER?
            const gotGlobalCapabilities$ = keycloakService.keycloakEvents$.pipe(
                filter((event) => event?.type === KeycloakEventType.OnAuthSuccess),
                first(),
                switchMap(() => store.dispatch([new OperatorContextActions.GetGlobalCapabilities()]))
            );

            const gotTitle$ = translocoHelper
                .waitForTranslation("webApp.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));

            forkJoin([gotGlobalCapabilities$, gotTitle$])
                .pipe(
                    first(),
                    finalize(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
