import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, ViewChild, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, MINUTES_IN_HOUR } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EditableFlightTechnicalProperties } from "./flight-technical-properties-utils";

interface FlightTechnicalPropertiesControlComponentState {
    isMinFlightSpeedVisible: boolean;
}

interface PropertiesForm {
    maxFlightTime: FormControl<number>;
    hasFlightSpeedLimit: FormControl<boolean>;
    maxFlightSpeed: FormControl<number>;
    minFlightSpeed: FormControl<number | null>;
}

const MAX_FLIGHT_TIME_VALUE_IN_HOURS = 24;
const MAX_FLIGHT_SPEED_VALUE_IN_KILOMETERS_PER_HOUR = 500;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-flight-technical-properties-control",
    templateUrl: "./flight-technical-properties-control.component.html",
    styleUrls: ["../setup-form-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FlightTechnicalPropertiesControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FlightTechnicalPropertiesControlComponent),
            multi: true,
        },
    ],
})
export class FlightTechnicalPropertiesControlComponent implements ControlValueAccessor, Validator {
    protected readonly MIN_MAX_FLIGHT_TIME_VALUE = 1;
    protected readonly MAX_MAX_FLIGHT_TIME_VALUE = Math.round(MAX_FLIGHT_TIME_VALUE_IN_HOURS * MINUTES_IN_HOUR);
    protected readonly MIN_MAX_FLIGHT_SPEED_VALUE = 1;
    protected readonly MAX_MAX_FLIGHT_SPEED_VALUE = MAX_FLIGHT_SPEED_VALUE_IN_KILOMETERS_PER_HOUR;
    protected readonly MIN_MIN_FLIGHT_SPEED_VALUE = 1;

    @Input() public set properties(value: EditableFlightTechnicalProperties | undefined) {
        if (!value) {
            return;
        }

        this.initPropertiesForm(value);
    }

    @Input() public set isMinFlightSpeedVisible(value: BooleanInput) {
        const isMinFlightSpeedVisible = coerceBooleanProperty(value);

        this.localStore.patchState({ isMinFlightSpeedVisible });

        this.manageMinFlightSpeedControl(isMinFlightSpeedVisible);
    }

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly propertiesForm = new FormGroup<PropertiesForm>({
        maxFlightTime: new FormControl<number>(this.MIN_MAX_FLIGHT_TIME_VALUE, {
            nonNullable: true,
            validators: [
                Validators.required,
                Validators.min(this.MIN_MAX_FLIGHT_TIME_VALUE),
                Validators.max(this.MAX_MAX_FLIGHT_TIME_VALUE),
            ],
        }),
        hasFlightSpeedLimit: new FormControl<boolean>(false, { nonNullable: true }),
        maxFlightSpeed: new FormControl<number>(this.MIN_MAX_FLIGHT_SPEED_VALUE, {
            nonNullable: true,
            validators: [
                Validators.required,
                Validators.min(this.MIN_MAX_FLIGHT_SPEED_VALUE),
                Validators.max(this.MAX_MAX_FLIGHT_SPEED_VALUE),
            ],
        }),
        minFlightSpeed: new FormControl<number | null>(null),
    });
    protected readonly isMinFlightSpeedVisible$ = this.localStore.selectByKey("isMinFlightSpeedVisible");

    private onTouched = FunctionUtils.noop;
    private propagateChange: (value: EditableFlightTechnicalProperties | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<FlightTechnicalPropertiesControlComponentState>) {
        this.localStore.setState({
            isMinFlightSpeedVisible: false,
        });

        this.propertiesForm.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
            this.propagateChange(values as EditableFlightTechnicalProperties);
        });
    }

    public registerOnChange(fn: (value: EditableFlightTechnicalProperties | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: EditableFlightTechnicalProperties | undefined): void {
        this.properties = value;
    }

    public validate(): ValidationErrors | null {
        if (this.propertiesForm.valid) {
            return null;
        }

        return { required: true };
    }

    public scrollToFirstInvalidField() {
        this.invalidFormScrollable.scrollToFirstInvalidField({
            behavior: "smooth",
            block: "center",
        });
    }

    private initPropertiesForm(properties: EditableFlightTechnicalProperties): void {
        this.propertiesForm.setValue(
            {
                maxFlightTime: properties.maxFlightTime,
                hasFlightSpeedLimit: properties.hasFlightSpeedLimit,
                maxFlightSpeed: properties.maxFlightSpeed,
                minFlightSpeed: properties.minFlightSpeed ?? null,
            },
            { emitEvent: false }
        );
    }

    private manageMinFlightSpeedControl(isMinFlightSpeedVisible: boolean): void {
        if (isMinFlightSpeedVisible) {
            this.propertiesForm.controls.minFlightSpeed.setValidators([
                Validators.required,
                Validators.min(this.MIN_MIN_FLIGHT_SPEED_VALUE),
            ]);
            this.propertiesForm.controls.minFlightSpeed.setValue(this.MIN_MIN_FLIGHT_SPEED_VALUE, { emitEvent: false });
        } else {
            this.propertiesForm.controls.minFlightSpeed.setValidators([]);
            this.propertiesForm.controls.minFlightSpeed.setValue(null, { emitEvent: false });
        }
    }
}
