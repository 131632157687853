import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { map } from "rxjs/operators";
import { AdditionalCrew, CrewMember } from "../../../services/specific-permit-application.models";

interface AdditionalCrewComponentState {
    availableCrewMembers: CrewMember[];
    isExpanded: boolean;
    isEditMode: boolean;
    currentAdditionalCrew: AdditionalCrew;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-additional-crew[availableCrewMembers]",
    templateUrl: "./additional-crew.component.html",
    styleUrls: ["../../common.scss", "./additional-crew.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AdditionalCrewComponent), multi: true }],
})
export class AdditionalCrewComponent implements ControlValueAccessor {
    @Input() public set availableCrewMembers(value: CrewMember[] | undefined) {
        this.localStore.patchState({ availableCrewMembers: value ?? [] });
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }

    @Input() public set value(value: AdditionalCrew | undefined) {
        this.localStore.patchState({ currentAdditionalCrew: value ?? [] });
    }

    protected readonly availableCrewMembers$ = this.localStore.selectByKey("availableCrewMembers");
    protected readonly currentAdditionalCrew$ = this.localStore.selectByKey("currentAdditionalCrew");
    protected readonly currentAdditionalCrewSize$ = this.currentAdditionalCrew$.pipe(
        map((currentAdditionalCrew) =>
            currentAdditionalCrew.reduce((size, member) => {
                if (member.member === CrewMember.UavObserver) {
                    return size + (member.amount ?? 0);
                }

                return size + 1;
            }, 0)
        )
    );
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");

    protected readonly CrewMember = CrewMember;

    protected readonly additionalCrewForm = new FormGroup<{ additionalCrew: FormControl<AdditionalCrew | null> }>({
        additionalCrew: new FormControl<AdditionalCrew | null>(null, {
            nonNullable: true,
        }),
    });

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: AdditionalCrew | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<AdditionalCrewComponentState>) {
        this.localStore.setState({
            availableCrewMembers: [],
            isExpanded: true,
            isEditMode: false,
            currentAdditionalCrew: [],
        });
    }

    public registerOnChange(fn: (value: AdditionalCrew | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: AdditionalCrew | null): void {
        if (value) {
            this.additionalCrewForm.setValue({ additionalCrew: value }, { emitEvent: false });
        } else {
            this.additionalCrewForm.reset();
        }

        this.persistCurrentAdditionalCrew(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.additionalCrewForm.disable();
        } else {
            this.additionalCrewForm.enable();
        }
    }

    protected setEditMode(isEditMode: boolean): void {
        this.localStore.patchState(({ isExpanded }) => ({ isEditMode, isExpanded: isEditMode ? true : isExpanded }));
    }

    protected cancel(): void {
        const currentAdditionalCrew = this.localStore.selectSnapshotByKey("currentAdditionalCrew");
        this.additionalCrewForm.reset({ additionalCrew: currentAdditionalCrew }, { emitEvent: false });
        this.setEditMode(false);
    }

    protected save(): void {
        this.additionalCrewForm.markAllAsTouched();

        if (this.additionalCrewForm.invalid) {
            return;
        }

        const currentAdditionalCrew = this.additionalCrewForm.controls.additionalCrew.getRawValue();
        this.persistCurrentAdditionalCrew(currentAdditionalCrew);
        this.propagateChange(currentAdditionalCrew);
        this.setEditMode(false);
    }

    private persistCurrentAdditionalCrew(value: AdditionalCrew | null): void {
        this.localStore.patchState({
            currentAdditionalCrew: value ?? [],
        });
    }
}
