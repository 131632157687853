import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS, TechnicalSpecification } from "../../../../services/uav.models";

export interface FlightTechnicalPropertiesComponentState {
    properties: TechnicalSpecification | undefined;
}

@Component({
    selector: "dtm-web-app-lib-flight-technical-properties",
    templateUrl: "./flight-technical-properties.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class FlightTechnicalPropertiesComponent {
    protected readonly MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS = MAXIMUM_KILOMETERS_PER_HOUR_FRACTION_DIGITS;
    protected readonly properties$ = this.localStore.selectByKey("properties").pipe(RxjsUtils.filterFalsy());

    @Input() public set properties(value: TechnicalSpecification | undefined) {
        this.localStore.patchState({ properties: value });
    }

    constructor(private readonly localStore: LocalComponentStore<FlightTechnicalPropertiesComponentState>) {
        this.localStore.setState({
            properties: undefined,
        });
    }

    protected isEmpty(value: unknown) {
        return value === null || value === undefined;
    }
}
