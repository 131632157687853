import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { Store } from "@ngxs/store";
import { lastValueFrom, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { TacticalActions } from "../state/tactical.actions";
import { TacticalState } from "../state/tactical.state";

export const MISSION_ID_PARAM = "missionId";

export const tacticalAcceptedMissionListResolver: ResolveFn<void> = async () => {
    const store = inject(Store);
    await lastValueFrom(store.dispatch([TacticalActions.CleanupMissionRoute, TacticalActions.GetInitialAcceptedMissions]));
};

export const tacticalMissionResolver: ResolveFn<void> = async (route: ActivatedRouteSnapshot) => {
    const missionId = route.paramMap.get(MISSION_ID_PARAM);
    if (!missionId) {
        return;
    }

    const store = inject(Store);
    await lastValueFrom(
        store.dispatch([new TacticalActions.GetMissionWithRoute(missionId), new TacticalActions.GetMissionEmergency(missionId)]).pipe(
            switchMap(() => {
                const planId = store.selectSnapshot(TacticalState.missionData)?.planId;
                if (!planId) {
                    return of(null);
                }

                return store
                    .dispatch([new TacticalActions.GetMissionPlanData(planId), TacticalActions.GetCheckinList])
                    .pipe(map(() => missionId));
            })
        )
    );
};
