<div class="grid" *ngIf="isSelectedContextPersonal$ | ngrxPush; else pageNotFoundTemplate">
    <dtm-web-app-lib-membership-missions
        [operatorType]="OperatorType.Enterprise"
        (missionsListRefresh)="refreshMissionsList()"
        (filtersChange)="changeFilters($event)"
        (pageChange)="changePage($event)"
        [missionsList]="missionsList$ | ngrxPush"
        [isMissionsProcessing]="isMissionsProcessing$ | ngrxPush"
        [missionsListError]="missionsListError$ | ngrxPush"
        [capabilities]="capabilities$ | ngrxPush"
        [pagination]="missionsListPages$ | ngrxPush"
        [incomingFilters]="filtersQuery$ | ngrxPush"
        (missionWizardStart)="startMissionWizard()"
    ></dtm-web-app-lib-membership-missions>
</div>

<ng-template #pageNotFoundTemplate>
    <dtm-ui-page-not-found></dtm-ui-page-not-found>
</ng-template>
