<dtm-ui-expandable-panel
    *ngrxLet="{
        availableOperationScenarios: availableOperationScenarios$,
        currentCompetencies: currentCompetencies$,
        isEditMode: isEditMode$,
    } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [isDisabled]="vm.isEditMode"
    [hasHeaderSeparator]="false"
>
    <div class="panel-header" headerSlot>
        <h2>
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.headerLabel" | transloco }}
        </h2>
        <button
            *ngIf="!vm.isEditMode"
            type="button"
            class="button-secondary edit-button"
            (click)="$event.stopPropagation(); setEditMode(true)"
        >
            <dtm-ui-icon name="edit"></dtm-ui-icon>
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.editButtonLabel" | transloco }}
        </button>
        <button *ngIf="vm.isEditMode" type="button" class="button-icon close-button" (click)="$event.stopPropagation(); cancel()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <section *ngIf="!vm.isEditMode">
        <dtm-web-app-lib-spec-perm-app-competencies
            [basicCompetencies]="vm.currentCompetencies.basic"
            [additionalCompetencies]="vm.currentCompetencies.additional"
            [hasEuRegulationCompetency]="vm.currentCompetencies.hasEuRegulationCompetency"
        >
            <h3 additionalHeaderSlot class="additional-header">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.additionalCompetenciesLabel" | transloco }}
            </h3>
        </dtm-web-app-lib-spec-perm-app-competencies>
    </section>
    <section class="edit-mode-container" *ngIf="vm.isEditMode">
        <ng-container [formGroup]="pilotCompetenciesForm">
            <dtm-web-app-lib-spec-perm-app-competencies-form
                formControlName="competencies"
                [availableOperationScenarios]="vm.availableOperationScenarios"
                isEuRegulationCompetencyEnabled
            >
            </dtm-web-app-lib-spec-perm-app-competencies-form>
        </ng-container>
        <footer class="actions">
            <button type="button" class="button-secondary" (click)="cancel()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="save()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.competencies.saveButtonLabel" | transloco }}
            </button>
        </footer>
    </section>
</dtm-ui-expandable-panel>
