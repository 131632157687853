import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { AirRisk, MissionType, PopulationDensity } from "@dtm-frontend/shared/mission";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { FormType, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import {
    AirRiskMitigations,
    MissionUAVSetup,
    RiskMitigationM1A,
    RiskMitigationM1B,
    RiskMitigationM2,
    SoraSettings,
} from "../../../../../../../models/mission.model";

export interface AnalysisSoraMitigation {
    riskMitigationM1A: RiskMitigationM1A | null;
    riskMitigationM1B: RiskMitigationM1B | null;
    riskMitigationM2: RiskMitigationM2 | null;
    airRiskMitigations: AirRiskMitigations | null;
}

export interface AnalysisSoraSettingsModalComponentData {
    soraSettings: SoraSettings | undefined;
    missionType: MissionType | undefined;
    airRiskInfoData: AirRisk | undefined;
    populationDensity: PopulationDensity | undefined;
    criticalArea: number | undefined;
    shouldDisabledAirRiskMitigation: boolean;
    missionUAVSetup: MissionUAVSetup | undefined;
}

interface AnalysisSoraSettingsModalComponentState {
    missionType: MissionType | undefined;
    missionUAVSetup: MissionUAVSetup | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-analysis-sora-settings-modal",
    templateUrl: "./analysis-sora-settings-modal.component.html",
    styleUrls: ["./analysis-sora-settings-modal.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisSoraSettingsModalComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    protected riskMitigationM1AFormControl = new FormControl<RiskMitigationM1A | null>(null);
    protected riskMitigationM1BFormControl = new FormControl<RiskMitigationM1B | null>(null);
    protected riskMitigationM2FormControl = new FormControl<RiskMitigationM2 | null>(null);
    protected airRiskMitigationFormControl = new FormControl<AirRiskMitigations | null>({ disabled: true, value: null });

    protected soraSettingsFormGroup = new FormGroup<FormType<AnalysisSoraMitigation>>({
        riskMitigationM1A: this.riskMitigationM1AFormControl,
        riskMitigationM1B: this.riskMitigationM1BFormControl,
        riskMitigationM2: this.riskMitigationM2FormControl,
        airRiskMitigations: this.airRiskMitigationFormControl,
    });

    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly missionUAVSetup$ = this.localStore.selectByKey("missionUAVSetup");

    protected readonly MissionType = MissionType;

    constructor(
        public dialogRef: MatDialogRef<AnalysisSoraSettingsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: AnalysisSoraSettingsModalComponentData,
        private readonly localStore: LocalComponentStore<AnalysisSoraSettingsModalComponentState>
    ) {
        const { soraSettings, missionType, missionUAVSetup } = data;

        this.localStore.setState({ missionType, missionUAVSetup });

        this.soraSettingsFormGroup.setValue({
            riskMitigationM1A: soraSettings?.riskMitigations?.m1A ?? null,
            riskMitigationM1B: soraSettings?.riskMitigations?.m1B ?? null,
            riskMitigationM2: soraSettings?.riskMitigations?.m2 ?? null,
            airRiskMitigations: soraSettings?.airRiskMitigations ?? null,
        });

        if (data.shouldDisabledAirRiskMitigation) {
            this.airRiskMitigationFormControl.disable({ emitEvent: false });

            return;
        }

        this.airRiskMitigationFormControl.enable({ emitEvent: false });
    }

    protected saveAndRefresh() {
        this.soraSettingsFormGroup.markAllAsTouched();
        this.invalidFormScrollable?.scrollToFirstInvalidField();

        if (!this.soraSettingsFormGroup.valid) {
            return;
        }

        const value: SoraSettings = {
            riskMitigations: {
                m1A: this.soraSettingsFormGroup.value.riskMitigationM1A ?? undefined,
                m1B: this.soraSettingsFormGroup.value.riskMitigationM1B ?? undefined,
                m2: this.soraSettingsFormGroup.value.riskMitigationM2 ?? undefined,
            },
            airRiskMitigations: this.soraSettingsFormGroup.value.airRiskMitigations ?? undefined,
        };

        this.dialogRef.close(value);
    }

    protected close() {
        this.dialogRef.close();
    }
}
