import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, map } from "rxjs";

interface MissionPlanningTypeFormControlComponentState {
    hasPermits: boolean;
    isDisabled: boolean;
}

export enum MissionPlanningType {
    CaaPermit = "CaaPermit",
    Other = "Other",
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-planning-type-form-control[hasPermits]",
    templateUrl: "./mission-planning-type-form-control.component.html",
    styleUrls: ["../mission-data-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionPlanningTypeFormControlComponent {
    @Input() public set type(value: MissionPlanningType | undefined) {
        this.toggleFormControl.setValue(value === MissionPlanningType.CaaPermit, { emitEvent: false });
    }
    @Input() public set hasPermits(value: BooleanInput) {
        this.localStore.patchState({ hasPermits: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    protected readonly toggleFormControl = new FormControl<boolean | null>(false);

    @Output()
    public typeChange = this.toggleFormControl.valueChanges.pipe(
        map((value) => (value ? MissionPlanningType.CaaPermit : MissionPlanningType.Other))
    );

    protected readonly hasPermits$ = this.localStore.selectByKey("hasPermits");

    constructor(private readonly localStore: LocalComponentStore<MissionPlanningTypeFormControlComponentState>) {
        this.localStore.setState({ hasPermits: false, isDisabled: false });

        combineLatest([this.hasPermits$, this.localStore.selectByKey("isDisabled")])
            .pipe(untilDestroyed(this))
            .subscribe(([hasPermits, isDisabled]) => {
                if (isDisabled || !hasPermits) {
                    this.toggleFormControl.disable();
                } else {
                    this.toggleFormControl.enable();
                }
            });
    }
}
