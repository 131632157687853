import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { PilotRegistrationCapabilities } from "../../../shared/models";
import { PilotRegistrationFormValue, RegisterPilotSummaryData } from "../../services/models";

interface PilotRegistrationSummaryComponentState {
    pilotRegistrationFormValue: PilotRegistrationFormValue | undefined;
    capabilities: PilotRegistrationCapabilities | undefined;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-pilot-registration-summary[pilotRegistrationFormValue][capabilities]",
    templateUrl: "./pilot-registration-summary.component.html",
    styleUrls: ["../../../shared/styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PilotRegistrationSummaryComponent {
    @Input()
    public set pilotRegistrationFormValue(value: PilotRegistrationFormValue | undefined) {
        this.localStore.patchState({ pilotRegistrationFormValue: value });
    }

    @Input()
    public set capabilities(value: PilotRegistrationCapabilities | undefined) {
        this.localStore.patchState({ capabilities: value });
    }
    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public summaryDataSave = new EventEmitter<RegisterPilotSummaryData>();
    @Output() public goToBasicDataStep = new EventEmitter<void>();
    @Output() public goToAdditionalDataStep = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly pilotRegistrationFormValue$ = this.localStore
        .selectByKey("pilotRegistrationFormValue")
        .pipe(RxjsUtils.filterFalsy());

    protected readonly capabilities$ = this.localStore.selectByKey("capabilities").pipe(RxjsUtils.filterFalsy());
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    protected readonly authenticityOfDataControl = new FormControl<boolean>(false, Validators.requiredTrue);
    protected readonly generalDataProtectionRegulationControl = new FormControl<boolean>(false, Validators.requiredTrue);

    protected readonly statementsForm = new FormGroup({
        authenticityOfData: this.authenticityOfDataControl,
        generalDataProtectionRegulation: this.generalDataProtectionRegulationControl,
    });

    constructor(private readonly localStore: LocalComponentStore<PilotRegistrationSummaryComponentState>) {
        localStore.setState({ pilotRegistrationFormValue: undefined, capabilities: undefined, isProcessing: false });
    }

    protected saveSummaryData() {
        this.statementsForm.markAllAsTouched();

        if (this.statementsForm.valid) {
            this.summaryDataSave.emit({
                isAuthenticityOfData: !!this.authenticityOfDataControl.value,
                isGeneralDataProtectionRegulation: !!this.generalDataProtectionRegulationControl.value,
            });

            return;
        }

        this.invalidFormScrollable.scrollToFirstInvalidField();
    }
}
