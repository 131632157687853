<dtm-ui-wizard-step-wrapper
    [title]="'dtmWebAppLibPilotOperatorRegistration.basicDataHeader' | transloco"
    [stepNumber]="1"
    [stepsAmount]="3"
    [isBackButtonVisible]="false"
    (next)="validateFormAndGoToNextStep()"
    dtmUiInvalidFormScrollable
>
    <form [formGroup]="basicDataForm">
        <div class="section">
            <h4>
                {{ "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInformationHeader" | transloco }}
            </h4>
            <dtm-ui-radio-group [formControl]="registrationTypeControl" (change)="registrationTypeChange($event.value)">
                <label class="label">{{
                    "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.registrationTypeLabel" | transloco
                }}</label>

                <dtm-ui-radio-field [value]="OperatorRegistrationType.Enterprise">
                    {{
                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.registrationTypeValue"
                            | transloco : { value: OperatorRegistrationType.Enterprise }
                    }}
                </dtm-ui-radio-field>
                <dtm-ui-radio-field [value]="OperatorRegistrationType.Association">
                    {{
                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.registrationTypeValue"
                            | transloco : { value: OperatorRegistrationType.Association }
                    }}
                </dtm-ui-radio-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="registrationTypeControl; name: 'required'">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>

            <dtm-ui-radio-group *ngIf="registrationTypeControl.value" [formControl]="isRegisteredInOtherCountryControl" @slideIn>
                <label class="label">
                    {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.registrationInOtherCountryQuestion" | transloco }}
                    <dtm-ui-popover>
                        <div class="popover-content">
                            <p>
                                {{
                                    "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInOtherCountryPopover.registeredInUE"
                                        | transloco
                                }}
                            </p>
                            <p>
                                {{
                                    "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInOtherCountryPopover.registeredInOtherCountry"
                                        | transloco
                                }}
                            </p>
                            <p>
                                {{
                                    "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInOtherCountryPopover.warning" | transloco
                                }}
                            </p>
                        </div>
                    </dtm-ui-popover>
                </label>

                <div class="row-radio-content">
                    <dtm-ui-radio-field [value]="true">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.yesLabel" | transloco }}
                    </dtm-ui-radio-field>
                    <dtm-ui-radio-field [value]="false">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.noLabel" | transloco }}
                    </dtm-ui-radio-field>
                </div>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="isRegisteredInOtherCountryControl; name: ['required', 'requiredTouched']">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>

            <div class="form-section" *ngIf="basicDataForm.controls.foreignOperator.enabled" #foreignOperatorElement @slideIn>
                <dtm-web-app-lib-foreign-operator-validation-fields
                    dtmUiMarkValueAccessorControlsAsTouched
                    [formControl]="foreignOperatorControl"
                    [foreignOperatorValidation]="foreignOperatorValidation$ | ngrxPush"
                    (foreignOperatorCheck)="foreignOperatorCheck.emit($event)"
                ></dtm-web-app-lib-foreign-operator-validation-fields>
            </div>
        </div>
        <div *ngIf="isRegisteredInOtherCountryControl.value !== null" @slideIn>
            <div class="section" *ngIf="basicDataForm.controls.identityDocument.enabled">
                <h4>
                    {{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityConfirmationSectionHeader" | transloco }}
                </h4>
                <dtm-ui-radio-group [formControl]="identityDocumentTypeControl">
                    <label class="label">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityDocumentTypeLabel" | transloco }}
                    </label>

                    <div class="radio-content">
                        <dtm-ui-radio-field [value]="IdentityDocumentType.IdCard">
                            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.idCardLabel" | transloco }}
                        </dtm-ui-radio-field>
                        <dtm-ui-radio-field [value]="IdentityDocumentType.Passport">
                            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.passportLabel" | transloco }}
                        </dtm-ui-radio-field>
                    </div>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <div class="field-error" *dtmUiFieldHasError="identityDocumentTypeControl; name: ['required', 'requiredTouched']">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </dtm-ui-radio-group>
                <div class="id-fields">
                    <dtm-ui-date-field>
                        <label>{{ "dtmWebAppLibPilotOperatorRegistration.basicData.expirationDateLabel" | transloco }}</label>
                        <input
                            matInput
                            [formControl]="identityDocumentExpirationDateControl"
                            [matDatepicker]="picker"
                            [min]="MIN_DATE"
                            *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                            [placeholder]="datePickerPlaceholder"
                        />
                        <mat-datepicker #picker></mat-datepicker>
                        <div class="field-error" *dtmUiFieldHasError="identityDocumentExpirationDateControl; name: 'matDatepickerMin'">
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.basicData.expirationDateMinDate"
                                    | transloco : { minDate: MIN_DATE | localizeDate }
                            }}
                        </div>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="identityDocumentExpirationDateControl; name: ['required', 'requiredTouched']"
                        >
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                    </dtm-ui-date-field>
                    <dtm-ui-files-upload-field
                        [isDownloadAllButtonVisible]="false"
                        [formControl]="identityDocumentIdControl"
                        [maxFileSize]="MAX_FILE_SIZE_BYTES"
                    >
                        <label>{{
                            (identityDocumentTypeControl.value === IdentityDocumentType.IdCard
                                ? "dtmWebAppLibPilotOperatorRegistration.basicData.idCardUploadLabel"
                                : "dtmWebAppLibPilotOperatorRegistration.basicData.passportUploadLabel"
                            ) | transloco
                        }}</label>
                        <div class="field-error" *dtmUiFieldHasError="identityDocumentIdControl; name: 'maxlength'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityFileUploadMultipleFilesError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="identityDocumentIdControl; name: 'required'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-hint">
                            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityDocumentAllowedExtensionsHint" | transloco }}
                        </div>
                    </dtm-ui-files-upload-field>
                </div>
            </div>

            <div class="section">
                <h4 *ngIf="isRegisteredInOtherCountryControl.value; else originCountryCompanyDetailsHeadersTemplate">
                    {{
                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.headquartersDetailsHeader"
                            | transloco : { registrationType: this.registrationTypeControl.value }
                    }}
                </h4>
                <div class="form-section">
                    <dtm-ui-input-field *ngIf="basicDataForm.controls.companyNumber.enabled">
                        <label>
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyNumberLabel"
                                    | transloco : { isOptional: isCompanyNumberOptional$ | ngrxPush }
                            }}
                        </label>
                        <input matInput [formControl]="companyNumberControl" [mask]="COMPANY_NUMBER_MASK" [showMaskTyped]="true" />
                        <div class="field-error" *dtmUiFieldHasError="companyNumberControl; name: 'required'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>

                        <div class="field-error" *dtmUiFieldHasError="companyNumberControl; name: 'minlength'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyNumberTooShortError" | transloco }}
                        </div>
                    </dtm-ui-input-field>
                    <dtm-ui-input-field>
                        <label>{{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyNameLabel" | transloco }}</label>
                        <input matInput type="text" [formControl]="companyNameControl" />
                        <div class="field-error" *dtmUiFieldHasError="companyNameControl; name: ['required', 'pattern']">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="companyNameControl; name: 'maxlength'; error as error">
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyNameMaxLengthError"
                                    | transloco : { max: error.requiredLength }
                            }}
                        </div>
                    </dtm-ui-input-field>
                    <dtm-ui-input-field>
                        <label>{{
                            "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyEmailLabel" | transloco
                        }}</label>
                        <input matInput type="text" [formControl]="emailControl" />
                        <div class="field-error" *dtmUiFieldHasError="emailControl; name: ['required', 'pattern']">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="emailControl; name: 'email'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.companyEmailError" | transloco }}
                        </div>
                    </dtm-ui-input-field>
                    <dtm-ui-phone-number-field [formControl]="phoneNumberControl">
                        <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'required'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumber'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.invalidPhoneNumberErrorHint" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumberType'">
                            {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.invalidNumberTypeErrorHint" | transloco }}
                        </div>
                    </dtm-ui-phone-number-field>

                    <dtm-web-app-lib-association-id-details-control
                        *ngIf="registrationTypeControl.value === OperatorRegistrationType.Association"
                        [formControl]="associationIdControl"
                        dtmUiMarkValueAccessorControlsAsTouched
                    ></dtm-web-app-lib-association-id-details-control>

                    <dtm-ui-country-select-field [formControl]="countryControl">
                        <label>
                            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.countryLabel" | transloco }}
                        </label>
                        <div class="field-error" *dtmUiFieldHasError="countryControl; name: ['required', 'requiredTouched']">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                    </dtm-ui-country-select-field>

                    <dtm-ui-address-form
                        dtmUiMarkValueAccessorControlsAsTouched
                        [formControl]="addressFormControl"
                        [isSystemDefaultCountrySelected]="countryControl.value === DEFAULT_COUNTRY_CODE"
                    >
                    </dtm-ui-address-form>
                </div>
            </div>
        </div>
    </form>
</dtm-ui-wizard-step-wrapper>

<ng-template #originCountryCompanyDetailsHeadersTemplate>
    <h4 *ngrxLet="originCountry$ as originCountry">
        {{
            "dtmWebAppLibPilotOperatorRegistration.registerOperator.basicData.originCountryDetailsHeader"
                | transloco : { registrationType: this.registrationTypeControl.value, originCountry: originCountry }
        }}
    </h4>
</ng-template>
