import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MapEntityType } from "@dtm-frontend/shared/map/cesium";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { GeographicCoordinatesDirection, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { AnimationUtils, FormType, LocalComponentStore, Logger, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CylinderAreaProperties, MAX_RADIUS, PrismAreaProperties } from "../area-editor-map.service";

interface ManualUpdatePanelComponentState {
    isOpen: boolean;
    isEditMode: boolean;
    currentAreaProperties: CylinderAreaProperties | PrismAreaProperties | undefined;
}

interface AreaPropertiesFormValue {
    latitude: number;
    longitude: number;
    radius: number;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-spec-perm-app-manual-update-panel",
    templateUrl: "./manual-update-panel.component.html",
    styleUrls: ["./manual-update-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ManualUpdatePanelComponent {
    @Input() public set areaProperties(value: CylinderAreaProperties | PrismAreaProperties | undefined) {
        this.localStore.patchState(({ isEditMode }) => ({ currentAreaProperties: value, isEditMode: value ? isEditMode : false }));
    }

    @Output() public readonly areaPropertiesChange = new EventEmitter<CylinderAreaProperties | PrismAreaProperties>();

    protected readonly MapEntityType = MapEntityType;
    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly GeographicCoordinatesDirection = GeographicCoordinatesDirection;

    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly currentAreaProperties$ = this.localStore.selectByKey("currentAreaProperties");

    protected readonly areaPropertiesForm = new FormGroup<FormType<AreaPropertiesFormValue>>({
        latitude: new FormControl<number>(0, { nonNullable: true, validators: Validators.required }),
        longitude: new FormControl<number>(0, { nonNullable: true, validators: Validators.required }),
        radius: new FormControl<number>(0, { nonNullable: true }),
    });

    constructor(private readonly localStore: LocalComponentStore<ManualUpdatePanelComponentState>) {
        this.localStore.setState({
            isOpen: false,
            isEditMode: false,
            currentAreaProperties: undefined,
        });

        this.listenOnEditModeChange();
    }

    protected togglePanel() {
        this.localStore.patchState(({ isOpen, isEditMode }) => ({ isOpen: isEditMode ? true : !isOpen }));
    }

    protected setEditMode(isEditMode: boolean) {
        this.localStore.patchState({ isEditMode });
    }

    protected save() {
        if (!this.areaPropertiesForm.valid) {
            return;
        }

        const currentAreaProperties = this.localStore.selectSnapshotByKey("currentAreaProperties");
        const { longitude, latitude, radius } = this.areaPropertiesForm.getRawValue();

        if (currentAreaProperties?.type === MapEntityType.Cylinder) {
            this.areaPropertiesChange.emit({
                type: MapEntityType.Cylinder,
                center: [longitude, latitude],
                radius: radius,
            });
        } else if (currentAreaProperties?.type === MapEntityType.Prism) {
            this.areaPropertiesChange.emit({
                type: MapEntityType.Prism,
                center: [longitude, latitude],
            });
        }

        this.setEditMode(false);
    }

    private listenOnEditModeChange(): void {
        this.localStore
            .selectByKey("isEditMode")
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                const currentAreaProperties = this.localStore.selectSnapshotByKey("currentAreaProperties");
                if (!currentAreaProperties?.center) {
                    Logger.captureMessage("ManualUpdatePanelComponent.listenOnEditModeChange: current area has no `center` property", {
                        level: "warning",
                        extra: { currentAreaProperties },
                    });

                    return;
                }

                this.areaPropertiesForm.controls.latitude.setValue(currentAreaProperties.center[1], { emitEvent: false });
                this.areaPropertiesForm.controls.longitude.setValue(currentAreaProperties.center[0], { emitEvent: false });

                if (currentAreaProperties.type === MapEntityType.Cylinder) {
                    this.areaPropertiesForm.controls.radius.setValidators([
                        Validators.required,
                        Validators.min(1),
                        Validators.max(MAX_RADIUS),
                    ]);
                    this.areaPropertiesForm.controls.radius.setValue(Math.round(currentAreaProperties.radius * 100) / 100, {
                        emitEvent: false,
                    });
                } else if (currentAreaProperties.type === MapEntityType.Prism) {
                    this.areaPropertiesForm.controls.radius.clearValidators();
                }
            });
    }
}
