import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, filter } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../modules/shared/operator-context/state/operator-context.state";

@Injectable({
    providedIn: "root",
})
export class NationalNodeUserVerifiedGuard {
    constructor(private router: Router, private readonly store: Store) {}
    public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store.select(OperatorContextState.isRequiredRegisterNationalNodeAuthenticatedUser).pipe(
            filter((doesUserRequireAuthentication) => doesUserRequireAuthentication !== undefined),
            map((doesUserRequireAuthentication) => {
                if (doesUserRequireAuthentication) {
                    return this.router.parseUrl("national-node-user");
                }

                return true;
            })
        );
    }
}
