import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { ActivatedRoute, Router } from "@angular/router";
import {
    DialogService,
    EmptyStateMode,
    ErrorMode,
    GlobalFeatures,
    GlobalOperatorPermissions,
    MIN_PAGE_SIZE_VALUE,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../../../../../shared/operator-context/state/operator-context.state";
import { PermitListItem, PermitsFilters } from "../../../../services/operator-permits.models";
import { OperatorPermitsActions } from "../../../../state/operator-permits.actions";
import { OperatorPermitsState } from "../../../../state/operator-permits.state";
import { ChangeNameDialogComponent } from "../change-name-dialog/change-name-dialog.component";

interface OwnedPermitsContainerComponentState {
    ownedPermitsFilters: PermitsFilters;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-owned-permits-container",
    templateUrl: "./owned-permits-container.component.html",
    styleUrls: ["./owned-permits-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OwnedPermitsContainerComponent {
    protected readonly ownedSpecificPermitListFilters$ = this.localStore.selectByKey("ownedPermitsFilters");
    protected readonly ownedSpecificPermitsListPages$ = this.store.select(OperatorPermitsState.ownedSpecificPermitsListPages);
    protected readonly ownedSpecificPermitsListError$ = this.store.select(OperatorPermitsState.ownedSpecificPermitsListError);
    protected readonly ownedSpecificPermitsList$ = this.store.select(OperatorPermitsState.ownedSpecificPermitsList);
    protected readonly isOwnedPermitsProcessing$ = this.store.select(OperatorPermitsState.isOwnedSpecificPermitsListProcessing);
    protected readonly areOwnedPermitsFiltersApplied$ = this.localStore
        .selectByKey("ownedPermitsFilters")
        .pipe(map((filters) => !!filters.searchPhrase));
    protected readonly isNameChangeProcessing$ = this.store.select(OperatorPermitsState.isNameChangeProcessing);
    protected readonly areSpecificPermitDraftsAvailable$ = this.store.select(
        OperatorContextState.isFeatureAvailable(GlobalFeatures.SpecificPermitApplication)
    );
    protected readonly isPermittedToCreateApplication$ = this.store.select(
        OperatorContextState.isPermitted(GlobalOperatorPermissions.OperatorSpecificPermitsCreateApplication)
    );
    private readonly selectedOperatorContext$ = this.store.select(OperatorContextState.selectedContext).pipe(RxjsUtils.filterFalsy());

    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly ErrorMode = ErrorMode;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<OwnedPermitsContainerComponentState>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService,
        private readonly dialogService: DialogService,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {
        localStore.setState({
            ownedPermitsFilters: {
                searchPhrase: route.snapshot.queryParams.searchPhrase ?? null,
                pageNumber: 0,
                pageSize: MIN_PAGE_SIZE_VALUE,
            },
        });
        this.watchSelectedOperatorContextChange();
        this.ownedSpecificPermitListFilters$.pipe(untilDestroyed(this)).subscribe((filters) => {
            this.router.navigate(["operator-permits/owned-permits"], {
                queryParams: { searchPhrase: filters.searchPhrase ?? null },
                queryParamsHandling: "merge",
            });
            this.fetchOwnedSpecificPermitsList();
        });
    }

    protected ownedFiltersChange(filters: PermitsFilters) {
        this.localStore.patchState({ ownedPermitsFilters: { ...filters, pageNumber: 0 } });
    }

    protected ownedPageChange(pageEvent: PageEvent) {
        this.localStore.patchState(({ ownedPermitsFilters }) => ({
            ownedPermitsFilters: { ...ownedPermitsFilters, pageNumber: pageEvent.pageIndex, pageSize: pageEvent.pageSize },
        }));
    }

    protected navigateToSpecificPermits(): void {
        this.router.navigateByUrl("/specific-permit/application-creator");
    }

    protected changePermitName(permit: PermitListItem): void {
        const dialogRef = this.dialogService.open(ChangeNameDialogComponent, {
            data: {
                permitName: permit.name,
                titleText: this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.dialogTitleText"),
                isNameProcessing$: this.isNameChangeProcessing$,
            },
        });

        dialogRef.componentInstance.setName$
            .pipe(
                switchMap((name: string) => this.store.dispatch(new OperatorPermitsActions.UpdatePermitName(permit, name))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperatorPermitsState.updateNameError);

                if (error) {
                    this.toastService.error(
                        this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.updatePermitNameErrorMessage")
                    );

                    return;
                }

                this.toastService.success(
                    this.transloco.translate("dtmWebAppLibOperatorPermits.permitsLists.updatePermitNameSuccessMessage")
                );
                this.fetchOwnedSpecificPermitsList();
                dialogRef.close();
            });
    }

    private clearFilters(): void {
        const ownedPermitsFilters = {
            searchPhrase: null,
            pageNumber: 0,
            pageSize: MIN_PAGE_SIZE_VALUE,
        };
        this.localStore.patchState({ ownedPermitsFilters });
    }

    private fetchOwnedSpecificPermitsList(): void {
        const ownedPermitsFilters = this.localStore.selectSnapshotByKey("ownedPermitsFilters");
        const params = {
            ...ownedPermitsFilters,
            operatorId: this.store.selectSnapshot(OperatorContextState.selectedOperatorContextId),
        };
        this.store.dispatch(new OperatorPermitsActions.GetOwnedSpecificPermitList({ ...params }));
    }

    private watchSelectedOperatorContextChange(): void {
        this.selectedOperatorContext$.pipe(untilDestroyed(this)).subscribe(() => {
            this.clearFilters();
            this.fetchOwnedSpecificPermitsList();
        });
    }
}
