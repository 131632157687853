import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { SharedWebAppModule } from "../shared/shared-web-app.module";
import { IdentityCardComponent } from "./components/identity-card/identity-card.component";
import { LegalGuardianComponent } from "./components/legal-guardian/legal-guardian.component";
import { NationalNodeUserProfileComponent } from "./components/national-node-user-profile/national-node-user-profile.component";
import { PersonalDataComponent } from "./components/personal-data/personal-data.component";
import { UserAttorneyPowersComponent } from "./components/user-attorney-powers/user-attorney-powers.component";
import { UserContactComponent } from "./components/user-contact/user-contact.component";
import { UserProfileContainerComponent } from "./components/user-profile-container/user-profile-container.component";
import { UserProfileApiService } from "./services/user-profile-api-service";
import { UserProfileState } from "./state/user-profile.state";

@NgModule({
    declarations: [
        UserProfileContainerComponent,
        PersonalDataComponent,
        UserContactComponent,
        IdentityCardComponent,
        LegalGuardianComponent,
        UserAttorneyPowersComponent,
        NationalNodeUserProfileComponent,
    ],
    imports: [
        CommonModule,
        SharedI18nModule,
        LetModule,
        PushModule,
        NgxsModule.forFeature([UserProfileState]),
        SharedUiModule,
        MatCardModule,
        SharedWebAppModule,
        MatInputModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        MatDatepickerModule,
    ],
    exports: [LegalGuardianComponent, IdentityCardComponent, UserAttorneyPowersComponent, NationalNodeUserProfileComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmWebAppUserProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: UserProfileApiService,
            useValue: undefined,
        },
    ],
})
export class UserProfileModule {
    public static forRoot(): ModuleWithProviders<UserProfileModule> {
        return {
            ngModule: UserProfileModule,
            providers: [
                {
                    provide: UserProfileApiService,
                    useClass: UserProfileApiService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<UserProfileModule> {
        return {
            ngModule: UserProfileModule,
            providers: [
                {
                    provide: UserProfileApiService,
                    useValue: null,
                },
            ],
        };
    }
}
