import { TemplatePortal } from "@angular/cdk/portal";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
} from "@angular/core";
import { CameraHelperService } from "@dtm-frontend/shared/map/cesium";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import distance from "@turf/distance";
import { tap } from "rxjs";
import { combineLatestWith, map } from "rxjs/operators";
import { MissionRoutePreference } from "../../../../models/mission.model";
import { MissionWizardSteps } from "../../content/mission-wizard-content.component";

interface MissionWizardRouteSelectorStepComponentState {
    routes: MissionPlanRouteWithOrder[] | undefined;
    selectedRouteId: string | undefined;
    isProcessing: boolean;
    areAvailableRoutesLoading: boolean;
    previewRouteId: string | undefined;
    isStepActive: boolean;
    sorting: Sorting;
    isAfterInit: boolean;
}

interface MissionPlanRouteWithOrder extends MissionPlanRoute {
    orderIndex?: number;
}

enum Sorting {
    DistanceAscending = "DistanceAscending",
    DistanceDescending = "DistanceDescending",
    SafetyAscending = "SafetyAscending",
    SafetyDescending = "SafetyDescending",
}

const CAPITAL_LETTERS_SPLIT_REGEXP = /(?=[A-Z])/;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-route-selector-step[routes]",
    templateUrl: "./route-selector-step.component.html",
    styleUrls: ["./route-selector-step.component.scss", "../step-common.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardRouteSelectorStepComponent implements AfterViewInit {
    @ViewChildren("routeTile") private tileElements: QueryList<ElementRef<HTMLElement>> | undefined;
    @ViewChild("routeSideViewTemplate", { read: TemplateRef }) public routeSideViewTemplate: TemplateRef<unknown> | undefined;

    @Input() public set routes(value: MissionPlanRoute[] | undefined) {
        const routes = Array.isArray(value) ? value?.map((route, index) => ({ ...route, orderIndex: index + 1 })) : undefined;
        this.localStore.patchState({
            routes,
        });
    }
    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }
    @Input() public set areAvailableRoutesLoading(value: boolean) {
        this.localStore.patchState({ areAvailableRoutesLoading: value });
    }
    @Input() public set activeStepId(value: string | undefined) {
        this.localStore.patchState({ isStepActive: value === MissionWizardSteps.RouteSelector });
    }
    @Input() public set selectedRouteId(value: string | undefined) {
        this.localStore.patchState({ selectedRouteId: value });
        this.scrollSelectedElementIntoView(value);
    }

    @Input() public set previewRouteId(value: string | undefined) {
        this.localStore.patchState({ previewRouteId: value });
    }

    @Output() public readonly next = new EventEmitter();
    @Output() public readonly routeSelected = new EventEmitter<string>();
    @Output() public readonly previewRoute = new EventEmitter<string>();
    @Output() public readonly back = new EventEmitter();
    @Output() public readonly flyToRoute = new EventEmitter<MissionPlanRoute>();
    @Output() public readonly refreshRoutes = new EventEmitter<MissionRoutePreference>();

    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly Sorting = Sorting;

    protected readonly sorting$ = this.localStore.selectByKey("sorting");
    protected readonly routes$ = this.localStore.selectByKey("routes").pipe(
        combineLatestWith(this.localStore.selectByKey("sorting")),
        map(([routes, sorting]) => this.sort(routes, sorting)),
        tap(() => this.selectRoute())
    );
    protected readonly selectedRouteId$ = this.localStore.selectByKey("selectedRouteId");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly areAvailableRoutesLoading$ = this.localStore.selectByKey("areAvailableRoutesLoading");
    protected readonly previewRouteId$ = this.localStore.selectByKey("previewRouteId");
    protected readonly isStepActive$ = this.localStore.selectByKey("isStepActive");

    private templatePortal: TemplatePortal | undefined;
    private cameraRestoreState: ReturnType<CameraHelperService["getCameraRestoreState"]>;

    @Output() public readonly sideViewTemplate = this.isStepActive$.pipe(
        combineLatestWith(this.localStore.selectByKey("isAfterInit").pipe(RxjsUtils.filterFalsy())),
        map(([isActive]) => {
            if (isActive && this.routeSideViewTemplate) {
                return this.templatePortal;
            }

            return undefined;
        })
    );

    constructor(
        private readonly localStore: LocalComponentStore<MissionWizardRouteSelectorStepComponentState>,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly cameraHelperService: CameraHelperService
    ) {
        this.localStore.setState({
            routes: undefined,
            selectedRouteId: undefined,
            isProcessing: false,
            areAvailableRoutesLoading: false,
            previewRouteId: undefined,
            isStepActive: false,
            sorting: Sorting.DistanceAscending,
            isAfterInit: false,
        });
    }

    public ngAfterViewInit(): void {
        if (this.routeSideViewTemplate) {
            this.templatePortal = new TemplatePortal(this.routeSideViewTemplate, this.viewContainerRef);
        }
        this.localStore.patchState({ isAfterInit: true });
    }

    protected selectRoute(routeId?: string) {
        const selectedRouteId =
            routeId ??
            this.localStore.selectSnapshotByKey("selectedRouteId") ??
            this.localStore.selectSnapshotByKey("routes")?.[0]?.routeId;

        this.routeSelected.emit(selectedRouteId);
        this.localStore.patchState({ selectedRouteId });
    }

    protected selectPreviewRoute(routeId?: string) {
        this.previewRoute.emit(routeId);
    }

    protected getTakeoffTimeRange(route: MissionPlanRoute) {
        return route.sections[0].flightZone?.center.estimatedArriveAt;
    }

    protected getLandingTimeRange(route: MissionPlanRoute) {
        return route.sections[route.sections.length - 1].flightZone?.center.estimatedArriveAt;
    }

    protected getDuration = (route: MissionPlanRoute) => {
        const start = this.getTakeoffTimeRange(route)?.min;
        const end = this.getLandingTimeRange(route)?.min;

        if (!start || !end) {
            return undefined;
        }

        return {
            start,
            end,
        };
    };

    protected getDistance(route: MissionPlanRoute) {
        return route.sections.reduce((calculatedDistance, { segment }) => {
            const firstPoint = segment?.fromWaypoint.point;
            const secondPoint = segment?.toWaypoint.point;

            if (!firstPoint || !secondPoint) {
                return calculatedDistance;
            }

            return (
                calculatedDistance +
                distance([firstPoint.longitude, firstPoint.latitude], [secondPoint.longitude, secondPoint.latitude], {
                    units: "kilometers",
                })
            );
        }, 0);
    }

    protected getRouteById(routeId?: string, routes?: MissionPlanRouteWithOrder[]): MissionPlanRouteWithOrder | undefined {
        return routes?.find((element) => element.routeId === routeId);
    }

    protected updateSorting(sorting: Sorting) {
        const currentSorting = this.localStore.selectSnapshotByKey("sorting");

        const [sortBy] = sorting.split(CAPITAL_LETTERS_SPLIT_REGEXP);
        const [currentSortBy] = currentSorting.split(CAPITAL_LETTERS_SPLIT_REGEXP);

        if (sortBy !== currentSortBy) {
            switch (sorting) {
                case Sorting.SafetyAscending:
                case Sorting.SafetyDescending:
                    this.refreshRoutes.next(MissionRoutePreference.Safety);
                    break;
                case Sorting.DistanceAscending:
                case Sorting.DistanceDescending:
                    this.refreshRoutes.next(MissionRoutePreference.Length);
            }
        }

        this.localStore.patchState({ sorting });
    }

    protected toggleZoom(route: MissionPlanRoute) {
        if (this.cameraRestoreState?.id === route.routeId) {
            this.cameraRestoreState.restore();
            this.cameraRestoreState = undefined;

            return;
        }

        this.cameraRestoreState = this.cameraHelperService.getCameraRestoreState(route.routeId);
        this.flyToRoute.emit(route);
    }

    private sort(routes: MissionPlanRouteWithOrder[] | undefined, sorting: Sorting) {
        if (!routes) {
            return;
        }

        switch (sorting) {
            case Sorting.DistanceDescending:
            case Sorting.SafetyDescending:
                return [...routes].reverse();
            default:
                return routes;
        }
    }

    private scrollSelectedElementIntoView(routeId?: string) {
        // NOTE: setTimeout is needed to make sure that view is updated
        setTimeout(() =>
            this.tileElements
                ?.find((element) => element.nativeElement.getAttribute("data-routeId") === routeId)
                ?.nativeElement.scrollIntoView({
                    block: "nearest",
                })
        );
    }
}
