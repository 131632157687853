import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute } from "@angular/router";
import { ButtonTheme, ConfirmationDialogComponent, DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { NavigationAccuracy, Tracker, UavSetup } from "../../../services/uav.models";
import { SELECTED_SETUP_QUERY_PARAM_NAME } from "../../../services/uav.resolvers";

interface UavSetupsComponentState {
    setups: UavSetup[];
    trackers: Tracker[];
    navigationAccuracyItems: NavigationAccuracy[];
    selectedSetup: UavSetup | undefined;
    isNarrowMode: boolean;
    canManageUav: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-uav-setups[setups][trackers][navigationAccuracyItems]",
    templateUrl: "./uav-setups.component.html",
    styleUrls: ["./uav-setups.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavSetupsComponent {
    protected readonly setups$ = this.localStore.selectByKey("setups");
    protected readonly trackers$ = this.localStore.selectByKey("trackers");
    protected readonly navigationAccuracyItems$ = this.localStore.selectByKey("navigationAccuracyItems");
    protected readonly selectedSetup$ = this.localStore.selectByKey("selectedSetup").pipe(RxjsUtils.filterFalsy());
    protected readonly isNarrowMode$ = this.localStore.selectByKey("isNarrowMode");
    protected readonly canManageUav$ = this.localStore.selectByKey("canManageUav");

    @Input() public set setups(value: UavSetup[]) {
        // NOTE: || used (instead of ??) only because of Storybook behavior - passing input value as an empty string, not `undefined`
        this.localStore.patchState({ setups: value, selectedSetup: this.getDefaultSelectedSetup(value || []) });
    }
    @Input() public set canManageUav(value: BooleanInput) {
        this.localStore.patchState({ canManageUav: coerceBooleanProperty(value) });
    }

    @Input() public set trackers(value: Tracker[] | undefined) {
        this.localStore.patchState({ trackers: value ?? [] });
    }

    @Input() public set navigationAccuracyItems(value: NavigationAccuracy[] | undefined) {
        this.localStore.patchState({ navigationAccuracyItems: value ?? [] });
    }

    @Output() public add = new EventEmitter<void>();
    @Output() public edit = new EventEmitter<UavSetup>();
    @Output() public remove = new EventEmitter<UavSetup>();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly localStore: LocalComponentStore<UavSetupsComponentState>,
        private readonly matDialog: MatDialog,
        private readonly transloco: TranslocoService,
        private readonly deviceSizeService: DeviceSizeService
    ) {
        this.localStore.setState({
            setups: [],
            trackers: [],
            navigationAccuracyItems: [],
            selectedSetup: undefined,
            isNarrowMode: false,
            canManageUav: false,
        });

        this.observeOnDeviceResize();
    }

    protected showSetupPreview(setup: UavSetup) {
        this.localStore.patchState({ selectedSetup: setup });
    }

    protected tryToRemoveSetup(setup: UavSetup) {
        this.confirmSetupRemoval(setup)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.remove.emit(setup));
    }

    private confirmSetupRemoval(setup: UavSetup): Observable<boolean> {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.titleText"),
                    confirmationText: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.dialogMessage", {
                        setupName: setup.name,
                    }),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.declineButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibUav.setupRemoval.confirmationDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }

    private observeOnDeviceResize() {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Smartphone, DeviceSize.SmartphoneWide)
            .pipe(untilDestroyed(this))
            .subscribe((isSmartphoneDevice) => {
                this.localStore.patchState({
                    isNarrowMode: isSmartphoneDevice,
                });
            });
    }

    private getDefaultSelectedSetup(setups: UavSetup[] = []): UavSetup | undefined {
        const selectedSetupId = this.route.snapshot.queryParams[SELECTED_SETUP_QUERY_PARAM_NAME];
        const selectedSetupIndex = setups.findIndex((setup) => setup.id === selectedSetupId);

        return setups[Math.max(selectedSetupIndex, 0)];
    }
}
