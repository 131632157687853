import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MissionPlanAnalysisOptionStatus, MissionPlanData, MissionType } from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { PublicMissionPlanData } from "../../../../../../models/mission.model";

interface TrafficAnalysisComponentState {
    collisionMissions: PublicMissionPlanData[];
    hasHeaderSeparator: boolean;
    isExpanded: boolean;
    isProcessing: boolean;
    nearbyMissions: PublicMissionPlanData[];
    selectedOtherMission: MissionPlanData | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-traffic-analysis",
    templateUrl: "./traffic-analysis.component.html",
    styleUrls: ["./traffic-analysis.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrafficAnalysisComponent {
    @Input() public set collisionMission(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ collisionMissions: value ?? [] });
    }
    @Input() public set hasHeaderSeparator(value: BooleanInput) {
        this.localStore.patchState({ hasHeaderSeparator: coerceBooleanProperty(value) });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set nearbyMissions(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ nearbyMissions: value ?? [] });
    }

    @Output() public readonly flyToMainMissionRoute = new EventEmitter<void>();
    @Output() public readonly flyToRoute = new EventEmitter<string>();
    @Output() public readonly otherMissionSelected = this.localStore.selectByKey("selectedOtherMission");

    protected readonly collisionMissions$ = this.localStore.selectByKey("collisionMissions");
    protected readonly hasHeaderSeparator$ = this.localStore.selectByKey("hasHeaderSeparator");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly nearbyMissions$ = this.localStore.selectByKey("nearbyMissions");
    protected readonly selectedOtherMission$ = this.localStore.selectByKey("selectedOtherMission");

    protected readonly MissionPlanAnalysisOptionStatus = MissionPlanAnalysisOptionStatus;
    protected readonly MissionType = MissionType;

    private cameraStateId: string | undefined;

    constructor(private readonly localStore: LocalComponentStore<TrafficAnalysisComponentState>) {
        localStore.setState({
            collisionMissions: [],
            hasHeaderSeparator: false,
            isExpanded: false,
            isProcessing: false,
            nearbyMissions: [],
            selectedOtherMission: undefined,
        });
    }

    protected toggleZoom(event: Event, routeId?: string) {
        event.stopPropagation();

        if (this.cameraStateId === routeId) {
            this.flyToMainMissionRoute.emit();
            this.cameraStateId = undefined;

            return;
        }

        this.cameraStateId = routeId;
        this.flyToRoute.emit(routeId);
    }

    protected toggleSelection(mission: MissionPlanData) {
        const selectedMission = this.localStore.selectSnapshotByKey("selectedOtherMission");

        this.localStore.patchState({ selectedOtherMission: selectedMission?.id === mission?.id ? undefined : mission });
    }
}
