import { InjectionToken } from "@angular/core";

export interface PilotRegistrationEndpoints {
    getPilotRegistrationCapabilities: string;
    verifyForeignOperatorNumber: string;
    uploadIdentityFile: string;
    downloadIdentityFile: string;
    registerPilot: string;
}

export const PILOT_REGISTRATION_ENDPOINTS = new InjectionToken<PilotRegistrationEndpoints>("Pilot registration endpoints");
