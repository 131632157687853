<div
    class="point-height-controls"
    [class.edit-mode]="vm.isCoordinatesEditMode && vm.displayMode.canEdit.point.coordinates"
    *ngrxLet="{
        min: min$,
        max: max$,
        icon: icon$,
        height: height$,
        heightEditMode: heightEditMode$,
        isCoordinatesEditMode: isCoordinatesEditMode$,
        displayMode: displayMode$,
    } as vm"
>
    <h6 *ngIf="label$ | ngrxPush as label">{{ label }}</h6>
    <ng-container *ngIf="!(vm.isCoordinatesEditMode && vm.displayMode.canEdit.point.coordinates); else editCoordinatesTemplate">
        <ng-container *ngIf="vm.displayMode.canShow.point.height">
            <dtm-ui-input-field
                class="point-height agl"
                *ngIf="vm.heightEditMode === HeightType.AGL && vm.displayMode.canEdit.point.height; else aglReadonlyTemplate"
            >
                <dtm-ui-icon
                    [name]="vm.icon"
                    class="field-prefix"
                    [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightAglLabel' | transloco"
                ></dtm-ui-icon>
                <input
                    matInput
                    dtmUiClampInput
                    type="number"
                    inputmode="numeric"
                    [value]="vm.height | roundNumber"
                    [min]="vm.min"
                    [max]="vm.max"
                    step="1"
                    required
                    #pointHeightAglInput
                    (clampedInput)="pointHeightInputChange.emit(pointHeightAglInput.valueAsNumber)"
                />
                <div class="field-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightUnitLabel" | transloco }}
                </div>
                <div class="input-suffix">
                    {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.aglLabel" | transloco }}
                </div>
            </dtm-ui-input-field>
            <ng-template #aglReadonlyTemplate>
                <dtm-web-app-lib-mwiesip-readonly-card-value
                    class="point-height agl"
                    [suffix]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.aglLabel' | transloco"
                    [value]="
                        'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightWithUnitLabel'
                            | transloco : { value: (vm.height | number : '1.0-0') }
                    "
                ></dtm-web-app-lib-mwiesip-readonly-card-value>
            </ng-template>
            <ng-container *ngrxLet="amslOffset$ as amslOffset">
                <dtm-ui-input-field
                    class="point-height amsl with-input"
                    *ngIf="vm.heightEditMode === HeightType.AMSL && vm.displayMode.canEdit.point.height; else amslReadonlyTemplate"
                >
                    <dtm-ui-icon
                        [name]="vm.icon"
                        class="field-prefix"
                        [matTooltip]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightAmslLabel' | transloco"
                    ></dtm-ui-icon>
                    <input
                        matInput
                        dtmUiClampInput
                        type="number"
                        *ngIf="amslOffset !== undefined && vm.height !== undefined; else amslOffsetInputLoadingTemplate"
                        inputmode="numeric"
                        [value]="vm.height + amslOffset | roundNumber"
                        [min]="vm.min"
                        [max]="vm.max"
                        step="1"
                        required
                        #pointHeightAmslInput
                        (clampedInput)="emitRoundedValue(pointHeightAmslInput.valueAsNumber - amslOffset, pointHeightInputChange)"
                    />

                    <ng-template #amslOffsetInputLoadingTemplate>
                        <input matInput value="ładuję..." disabled />
                        <!-- TODO: DTM-4845 implement loaders when designed -->
                    </ng-template>
                    <div class="field-suffix">
                        {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightUnitLabel" | transloco }}
                    </div>
                    <div class="input-suffix">
                        {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.amslLabel" | transloco }}
                    </div>
                </dtm-ui-input-field>
                <ng-template #amslReadonlyTemplate>
                    <dtm-web-app-lib-mwiesip-readonly-card-value
                        class="point-height amsl"
                        *ngIf="amslOffset && vm.height !== undefined; else amslOffsetReadonlyLoadingTemplate"
                        [suffix]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.amslLabel' | transloco"
                        [value]="
                            'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.heightWithUnitLabel'
                                | transloco : { value: (vm.height + amslOffset | number : '1.0-0') }
                        "
                    ></dtm-web-app-lib-mwiesip-readonly-card-value>
                    <ng-template #amslOffsetReadonlyLoadingTemplate>
                        <div matInput class="point-height amsl">
                            ładuję...
                            <!-- TODO: DTM-4845 implement loaders when designed -->
                        </div>
                    </ng-template>
                </ng-template>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #editCoordinatesTemplate>
    <dtm-ui-dms-coordinates-input
        [inputLabel]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.pointLatitudeLabel' | transloco"
        [formControl]="latitudeInputControl"
        [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
        [fixedDirection]="GeographicCoordinatesDirection.North"
    ></dtm-ui-dms-coordinates-input>

    <dtm-ui-dms-coordinates-input
        [inputLabel]="'dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.pointLongitudeLabel' | transloco"
        [formControl]="longitudeInputControl"
        [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
        [fixedDirection]="GeographicCoordinatesDirection.East"
    ></dtm-ui-dms-coordinates-input>

    <div class="coordinate-edit-buttons">
        <button type="button" class="button-secondary" (click)="pointCoordinatesChange.emit(undefined)">
            {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.cancelCoordinatesEditButtonLabel" | transloco }}
        </button>
        <button
            type="button"
            class="button-primary"
            (click)="updateCoordinates()"
            [disabled]="latitudeInputControl.invalid || longitudeInputControl.invalid"
        >
            {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.saveCoordinatesEditButtonLabel" | transloco }}
        </button>
    </div>
</ng-template>
