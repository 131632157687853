<ng-container *ngrxLet="personalOperatorDetails$ as personalOperatorDetails" [formGroup]="pilotInfoForm">
    <dtm-ui-info-message>{{
        "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.infoMessage" | transloco
    }}</dtm-ui-info-message>

    <h2 class="header">{{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.header" | transloco }}</h2>

    <div class="details-container">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.nameLabel' | transloco"
            [value]="personalOperatorDetails.personalData.name + ' ' + personalOperatorDetails.personalData.lastName"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.emailLabel' | transloco"
            [value]="personalOperatorDetails.personalData.email"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.birthDateLabel' | transloco"
            [value]="personalOperatorDetails.personalData.dateOfBirth | localizeDate"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.phoneNumberLabel' | transloco"
            [value]="personalOperatorDetails.personalData.phone | formatImportedPhoneNumber"
        ></dtm-ui-label-value>
    </div>

    <div class="form">
        <dtm-ui-country-select-field formControlName="country">
            <label>
                {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.countryLabel" | transloco }}
            </label>
            <div class="field-error" *dtmUiFieldHasError="pilotInfoForm.controls.country; name: ['required', 'requiredTouched']">
                {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.requiredFieldError" | transloco }}
            </div>
        </dtm-ui-country-select-field>

        <dtm-ui-address-form
            formControlName="address"
            dtmUiMarkValueAccessorControlsAsTouched
            [isSystemDefaultCountrySelected]="pilotInfoForm.controls.country.value == DEFAULT_COUNTRY_CODE"
        >
        </dtm-ui-address-form>

        <dtm-web-app-lib-operator-details-preview
            [importedOperatorDetails]="personalOperatorDetails"
        ></dtm-web-app-lib-operator-details-preview>
    </div>

    <dtm-ui-expandable-panel [hasHeaderSeparator]="false" isExpanded *ngrxLet="pilotDetails$ as pilotDetails">
        <h2 class="header" headerSlot>
            {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.pilotDetailsHeader" | transloco }}
        </h2>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.pilotNumberLabel' | transloco"
            [value]="pilotDetails.number"
        ></dtm-ui-label-value>
        <dtm-web-app-lib-operator-migration-operational-authorizations
            [operationalAuthorizations]="pilotDetails.competencies"
            [header]="'dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.competenciesHeader' | transloco"
        ></dtm-web-app-lib-operator-migration-operational-authorizations>
    </dtm-ui-expandable-panel>

    <ng-content></ng-content>

    <dtm-ui-info-message type="warning">
        <div class="warning-message">
            <p>{{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.companyChangeWarningPart1" | transloco }}</p>
            <p>{{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.companyChangeWarningPart2" | transloco }}</p>
            <p>{{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.companyChangeWarningPart3" | transloco }}</p>
        </div>
    </dtm-ui-info-message>

    <h2 class="header">{{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.companyInfoHeader" | transloco }}</h2>

    <div class="form">
        <dtm-ui-radio-group formControlName="isCompany" (change)="isCompanyValueChange($event.value)">
            <label class="label">{{
                "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.companyInfoControlLabel" | transloco
            }}</label>

            <div class="row-radio-content">
                <dtm-ui-radio-field [value]="true">
                    {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.yesNoValue" | transloco : { value: true } }}
                </dtm-ui-radio-field>
                <dtm-ui-radio-field [value]="false">
                    {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.yesNoValue" | transloco : { value: false } }}
                </dtm-ui-radio-field>
            </div>
            <dtm-ui-form-errors class="preserve-error-space">
                <div class="field-error" *dtmUiFieldHasError="pilotInfoForm.controls.isCompany; name: 'required'">
                    {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </dtm-ui-radio-group>

        <ng-container *ngIf="pilotInfoForm.controls.isCompany.value">
            <dtm-web-app-lib-operator-migration-basic-company-info
                *ngIf="pilotInfoForm.controls.basicCompanyData.enabled"
                formControlName="basicCompanyData"
                dtmUiMarkValueAccessorControlsAsTouched
            ></dtm-web-app-lib-operator-migration-basic-company-info>

            <dtm-ui-country-select-field formControlName="companyCountry">
                <label>
                    {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.countryLabel" | transloco }}
                </label>
                <div class="field-error" *dtmUiFieldHasError="pilotInfoForm.controls.country; name: ['required', 'requiredTouched']">
                    {{ "dtmWebAppLibOperatorMigration.detailsStep.personalOperatorInfo.requiredFieldError" | transloco }}
                </div>
            </dtm-ui-country-select-field>

            <dtm-ui-address-form
                formControlName="companyAddress"
                dtmUiMarkValueAccessorControlsAsTouched
                [isSystemDefaultCountrySelected]="pilotInfoForm.controls.companyCountry.value == DEFAULT_COUNTRY_CODE"
            >
            </dtm-ui-address-form>
        </ng-container>
    </div>
</ng-container>
