import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, combineLatest, filter } from "rxjs";
import { map } from "rxjs/operators";
import { OperatorContextState } from "../modules/shared/operator-context/state/operator-context.state";

@Injectable({
    providedIn: "root",
})
export class NewUserGuard {
    constructor(private router: Router, private readonly store: Store) {}

    public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const isNewUser$ = combineLatest([
            this.store.select(OperatorContextState.operatorsSortedByName),
            this.store.select(OperatorContextState.pilot),
            this.store
                .select(OperatorContextState.isRequiredRegisterNationalNodeAuthenticatedUser)
                .pipe(filter((doesUserRequireAuthentication) => doesUserRequireAuthentication !== undefined)),
        ]).pipe(
            map(([operators, pilot, doesUserRequireAuthentication]) => (operators?.length === 0 && !pilot) || doesUserRequireAuthentication)
        );

        return isNewUser$.pipe(
            map((isNewUser) => {
                if (isNewUser) {
                    return this.router.parseUrl("dashboard");
                }

                return true;
            })
        );
    }
}
