import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { dtmOperatorPermissionGuard } from "../../guards/dtm-operator-permission.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { TrainingExamResultsContainerComponent } from "./components/training-exam-results-container/training-exam-results-container.component";

const routes: Routes = [
    {
        path: "training-exam-results",
        component: TrainingExamResultsContainerComponent,
        data: { titleTranslationKey: "dtmWebAppLibTrainingExamResults.routeTitles.trainingExamResults" },
        canActivate: [DtmRoleGuard, NewUserGuard, dtmOperatorPermissionGuard([])],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TrainingExamResultsRoutingModule {}
