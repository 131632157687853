import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { dtmOperatorPermissionGuard } from "../../guards/dtm-operator-permission.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { PermissionsAndStatementsContainerComponent } from "./components/permissions-and-statements-container/permissions-and-statements-container.component";
import { StatementsWizardContentComponent } from "./components/statements-wizard/content/statements-wizard-content.component";
import { StatementsWizardHeaderComponent } from "./components/statements-wizard/header/statements-wizard-header.component";
import { SignStatementStatusComponent } from "./components/statements-wizard/steps/sign-statement-status/sign-statement-status.component";
import { StatementWizardGuard } from "./services/statement-wizard.guard";

const routes: Routes = [
    {
        path: "operator-permissions",
        component: PermissionsAndStatementsContainerComponent,
        canActivate: [
            DtmRoleGuard,
            NewUserGuard,
            dtmOperatorPermissionGuard([
                GlobalOperatorPermissions.OperatorMissionsPlan,
                GlobalOperatorPermissions.OperationalStatementMakeStatement,
            ]),
        ],
        data: {
            titleTranslationKey: "dtmWebAppLibOperatorPermissions.routeTitles.operatorPermissionsContainer",
        },
    },
    {
        path: "statements",
        component: StatementsWizardContentComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, StatementWizardGuard],
        data: {
            titleTranslationKey: "dtmWebAppLibOperatorPermissions.routeTitles.gainingPermissionsContainer",
            headerComponent: StatementsWizardHeaderComponent,
        },
    },
    {
        path: "sign-statement-status",
        component: SignStatementStatusComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, StatementWizardGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperatorPermissionsRoutingModule {}
