<ng-container [formGroup]="accessoriesForm">
    <ng-container formArrayName="items" dtmUiInvalidFormScrollable *ngrxLet="isCustomUavMode$ as isCustomUavMode">
        <!-- async used because ngrxPush didn't work properly (is executed too late) -->
        <dtm-ui-expandable-panel
            #accessoryPanel
            *ngFor="let accessoryItem of accessoriesControls$ | async; index as itemIndex"
            [formGroupName]="itemIndex"
            [hasHeaderSeparator]="false"
            [isExpanded]="true"
        >
            <div class="section-header" headerSlot>
                <h4 class="section-title" [ngSwitch]="accessoryItem.controls.accessoryType.value">
                    <ng-container *ngSwitchCase="AccessoryType.Equipment">
                        {{
                            "dtmWebAppLibUav.uavProperties.equipment.typeValue"
                                | transloco : { value: accessoryItem.controls.group.controls.type.value }
                        }}
                        <dtm-ui-popover
                            *ngIf="accessoryItem.controls.group.controls.type.value === EquipmentType.Fts"
                            (click)="$event.stopPropagation()"
                        >
                            <div
                                class="fts-tooltip"
                                [innerHTML]="'dtmWebAppLibUav.uavProperties.equipment.fts.ftsTooltip' | transloco"
                            ></div>
                        </dtm-ui-popover>
                    </ng-container>
                    <ng-container *ngSwitchCase="AccessoryType.Parachute">
                        {{ "dtmWebAppLibUav.uavProperties.equipment.typeValue" | transloco : { value: EquipmentType.Parachute } }}
                    </ng-container>
                    <ng-container *ngSwitchCase="AccessoryType.Tracking">
                        {{ "dtmWebAppLibUav.uavProperties.trackings.sectionHeader" | transloco }}
                    </ng-container>
                </h4>
                <button type="button" class="button-icon" (click)="tryToRemoveAccessoriesItem(itemIndex); $event.stopPropagation()">
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                </button>
            </div>
            <ng-container [ngSwitch]="accessoryItem.controls.accessoryType.value">
                <ng-container *ngSwitchCase="AccessoryType.Equipment">
                    <ng-template
                        [ngTemplateOutlet]="equipmentTemplate"
                        [ngTemplateOutletContext]="{
                            equipmentGroup: accessoryItem.controls.group,
                            isCustomUavMode: isCustomUavMode
                        }"
                    ></ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="AccessoryType.Parachute">
                    <ng-template
                        [ngTemplateOutlet]="parachuteEquipmentTemplate"
                        [ngTemplateOutletContext]="{
                            parachuteGroup: accessoryItem.controls.group,
                            isCustomUavMode: isCustomUavMode
                        }"
                    ></ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="AccessoryType.Tracking">
                    <ng-template
                        [ngTemplateOutlet]="trackingTemplate"
                        [ngTemplateOutletContext]="{
                            trackingGroup: accessoryItem.controls.group
                        }"
                    ></ng-template>
                </ng-container>
            </ng-container>
        </dtm-ui-expandable-panel>

        <dtm-ui-empty-state *ngIf="!accessoriesForm.controls.items.length" [mode]="EmptyStateMode.Secondary">
            <ng-container messageSlot>
                {{ "dtmWebAppLibUav.setupForm.accessories.noAccessoriesLabel" | transloco }}
            </ng-container>
            <img imageSlot src="assets/images/empty-accessories.svg" alt="" />
        </dtm-ui-empty-state>
    </ng-container>
</ng-container>

<ng-template #equipmentTemplate let-equipmentGroup="equipmentGroup" let-isCustomUavMode="isCustomUavMode">
    <div class="properties narrow">
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.nameLabel" | transloco }}
            </label>
            <input matInput type="text" [formControl]="equipmentGroup.controls.name" autocomplete="off" />
            <div class="field-error" *dtmUiFieldHasError="equipmentGroup.controls.name; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="equipmentGroup.controls.name; name: 'maxlength'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.weightLabel" | transloco }}
            </label>
            <input matInput type="number" [formControl]="equipmentGroup.controls.weight" inputmode="numeric" autocomplete="off" />
            <div class="field-suffix">
                {{ "dtmWebAppLibUav.setupForm.accessories.weightUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="equipmentGroup.controls.weight; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="equipmentGroup.controls.weight; name: 'min'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="equipmentGroup.controls.weight; name: 'max'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
            </div>
        </dtm-ui-input-field>
    </div>
</ng-template>

<ng-template #parachuteEquipmentTemplate let-parachuteGroup="parachuteGroup" let-isCustomUavMode="isCustomUavMode">
    <div class="properties narrow" dtmUiScrollHook>
        <div class="parachute-info-message" *ngIf="isParachuteInfoMessageVisible$ | ngrxPush" @fade>
            <dtm-ui-info-message type="info">
                <div>
                    <p>{{ "dtmWebAppLibUav.setupForm.accessories.parachuteInfoMessageLabel" | transloco }}</p>
                    <button type="button" class="close-parachute-info-message-button button-icon" (click)="hideParachuteInfoMessage()">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                </div>
            </dtm-ui-info-message>
        </div>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.nameLabel" | transloco }}
            </label>
            <input matInput type="text" [formControl]="parachuteGroup.controls.name" autocomplete="off" />
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.name; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.name; name: 'maxlength'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <div class="switcher" *ngIf="isCustomUavMode">
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.isEmbeddedLabel" | transloco : { type: AccessoryType.Parachute } }}
            </label>
            <mat-slide-toggle [formControl]="parachuteGroup.controls.isEmbedded"></mat-slide-toggle>
        </div>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.weightLabel" | transloco }}
            </label>
            <input matInput type="number" [formControl]="parachuteGroup.controls.weight" inputmode="numeric" autocomplete="off" />
            <div class="field-suffix">
                {{ "dtmWebAppLibUav.setupForm.accessories.weightUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.weight; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.weight; name: 'min'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.weight; name: 'max'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.minHeightLabel" | transloco }}
            </label>
            <input matInput type="number" [formControl]="parachuteGroup.controls.minHeight" inputmode="numeric" autocomplete="off" />
            <div class="field-suffix">
                {{ "dtmWebAppLibUav.setupForm.accessories.minHeightUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.minHeight; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.minHeight; name: 'min'; error as error">
                {{
                    "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint"
                        | transloco
                            : {
                                  min: error.min | localizeNumber
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.minHeight; name: 'max'; error as error">
                {{
                    "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint"
                        | transloco
                            : {
                                  max: error.max | localizeNumber
                              }
                }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.descentSpeedLabel" | transloco }}
            </label>
            <input matInput type="number" [formControl]="parachuteGroup.controls.descentSpeed" inputmode="decimal" />
            <div class="field-suffix">
                {{ "dtmWebAppLibUav.setupForm.accessories.descentSpeedUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.descentSpeed; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.descentSpeed; name: 'min'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.descentSpeed; name: 'max'; error as error">
                {{ "dtmWebAppLibUav.setupForm.accessories.descentSpeedMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
            </div>
        </dtm-ui-input-field>
        <div class="parachute-max-wind-speed">
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.maxWindSpeedLabel" | transloco }}
            </label>
            <span *ngrxLet="maxWindSpeed$ as maxWindSpeed">
                {{
                    "dtmWebAppLibUav.setupForm.accessories.maxWindSpeedValue"
                        | transloco
                            : {
                                  value:
                                      maxWindSpeed === null
                                          ? "-"
                                          : (maxWindSpeed | convertToKmPerH | localizeNumber : { maximumFractionDigits: 0 })
                              }
                }}
            </span>
        </div>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.minOperatingTemperatureLabel" | transloco }}
            </label>
            <input matInput type="number" [formControl]="parachuteGroup.controls.minOperatingTemperature" inputmode="decimal" />
            <div class="field-suffix">
                {{ "dtmWebAppLibUav.setupForm.accessories.operatingTemperatureUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.minOperatingTemperature; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.minOperatingTemperature; name: 'min'; error as error">
                {{
                    "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint"
                        | transloco
                            : {
                                  min: error.min | localizeNumber
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.minOperatingTemperature; name: 'max'; error as error">
                {{
                    "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint"
                        | transloco
                            : {
                                  max: error.max | localizeNumber
                              }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup; name: 'temperatureRange'">
                {{ "dtmWebAppLibUav.setupForm.accessories.invalidTemperatureRangeErrorHint" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.maxOperatingTemperatureLabel" | transloco }}
            </label>
            <input matInput type="number" [formControl]="parachuteGroup.controls.maxOperatingTemperature" inputmode="decimal" />
            <div class="field-suffix">
                {{ "dtmWebAppLibUav.setupForm.accessories.operatingTemperatureUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.maxOperatingTemperature; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.maxOperatingTemperature; name: 'min'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup.controls.maxOperatingTemperature; name: 'max'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="parachuteGroup; name: 'temperatureRange'">
                {{ "dtmWebAppLibUav.setupForm.accessories.invalidTemperatureRangeErrorHint" | transloco }}
            </div>
        </dtm-ui-input-field>
        <div class="switcher">
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.hasCeCertificateLabel" | transloco }}
            </label>
            <mat-slide-toggle [formControl]="parachuteGroup.controls.hasCeCertificate"></mat-slide-toggle>
        </div>
        <div class="switcher">
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.isAstmF332218CompliantLabel" | transloco }}
            </label>
            <mat-slide-toggle [formControl]="parachuteGroup.controls.isAstmF332218Compliant"></mat-slide-toggle>
        </div>
    </div>
</ng-template>

<ng-template #trackingTemplate let-trackingGroup="trackingGroup">
    <div class="properties narrow" *ngrxLet="navigationAccuracyItems$ as navigationAccuracyItems">
        <dtm-ui-select-field
            [formControl]="trackingGroup.controls.trackerId"
            [isClearable]="false"
            [placeholder]="'dtmWebAppLibUav.setupForm.trackings.trackerPlaceholder' | transloco"
        >
            <label>
                {{ "dtmWebAppLibUav.setupForm.trackings.trackerLabel" | transloco }}
            </label>
            <dtm-ui-select-option *ngFor="let tracker of trackers$ | ngrxPush" [value]="tracker.id">
                {{ tracker.name }}
            </dtm-ui-select-option>
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.trackerId; name: ['required']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-select-field>
        <dtm-ui-input-field>
            <label>
                {{ "dtmWebAppLibUav.setupForm.trackings.identifierLabel" | transloco }}
            </label>
            <input matInput type="text" [formControl]="trackingGroup.controls.identifier" autocomplete="off" />
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.identifier; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.identifier; name: 'maxlength'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLongerThanMaxLengthErrorHint" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>
        <div class="switcher">
            <label>
                {{ "dtmWebAppLibUav.setupForm.trackings.isEmbeddedLabel" | transloco }}
            </label>
            <mat-slide-toggle [formControl]="trackingGroup.controls.isEmbedded"></mat-slide-toggle>
        </div>
        <dtm-ui-input-field *ngIf="!trackingGroup.controls.isEmbedded.value" @slideIn>
            <label>
                {{ "dtmWebAppLibUav.setupForm.accessories.weightLabel" | transloco }}
            </label>
            <input matInput type="number" [formControl]="trackingGroup.controls.weight" inputmode="numeric" autocomplete="off" />
            <div class="field-suffix">
                {{ "dtmWebAppLibUav.setupForm.accessories.weightUnitLabel" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.weight; name: ['required', 'pattern']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.weight; name: 'min'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueLessThanMinErrorHint" | transloco : { min: error.min | localizeNumber } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.weight; name: 'max'; error as error">
                {{ "dtmWebAppLibUav.setupForm.valueGreaterThanMaxErrorHint" | transloco : { max: error.max | localizeNumber } }}
            </div>
        </dtm-ui-input-field>
        <strong class="tracking-accuracy-header">
            {{ "dtmWebAppLibUav.setupForm.trackings.uavPositionAccuracyLabel" | transloco }}
            <dtm-ui-popover [popoverText]="'dtmWebAppLibUav.setupForm.trackings.uavPositionAccuracyTooltip' | transloco"></dtm-ui-popover>
        </strong>
        <dtm-ui-select-field [formControl]="trackingGroup.controls.flightNavigationAccuracy" [isClearable]="false">
            <label>
                {{ "dtmWebAppLibUav.setupForm.trackings.flightNavigationAccuracyLabel" | transloco }}
                <dtm-ui-popover>
                    <ul class="navigation-accuracy-list">
                        <li *ngFor="let navigationAccuracyClass of navigationAccuracyClasses">
                            <strong>
                                {{
                                    "dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.value"
                                        | transloco : { value: navigationAccuracyClass }
                                }}
                            </strong>
                            <dtm-web-app-lib-navigation-accuracy-class-description
                                [items]="navigationAccuracyItems"
                                [classValue]="navigationAccuracyClass"
                                [type]="NavigationAccuracyType.HorizontalFlight"
                            ></dtm-web-app-lib-navigation-accuracy-class-description>
                        </li>
                    </ul>
                </dtm-ui-popover>
            </label>
            <dtm-ui-select-option *ngFor="let navigationAccuracyClass of navigationAccuracyClasses" [value]="navigationAccuracyClass">
                {{ "dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.value" | transloco : { value: navigationAccuracyClass } }}
            </dtm-ui-select-option>
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.flightNavigationAccuracy; name: ['required']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-select-field>
        <dtm-ui-select-field [formControl]="trackingGroup.controls.takeoffAndLandingNavigationAccuracy" [isClearable]="false">
            <label>
                {{ "dtmWebAppLibUav.setupForm.trackings.takeoffAndLandingNavigationAccuracyLabel" | transloco }}
                <dtm-ui-popover>
                    <ul class="navigation-accuracy-list">
                        <li *ngFor="let navigationAccuracyClass of navigationAccuracyClasses">
                            <strong>
                                {{
                                    "dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.value"
                                        | transloco : { value: navigationAccuracyClass }
                                }}
                            </strong>
                            <dtm-web-app-lib-navigation-accuracy-class-description
                                [items]="navigationAccuracyItems"
                                [classValue]="navigationAccuracyClass"
                                [type]="NavigationAccuracyType.TakeoffAndLanding"
                            ></dtm-web-app-lib-navigation-accuracy-class-description>
                        </li>
                    </ul>
                </dtm-ui-popover>
            </label>
            <dtm-ui-select-option *ngFor="let navigationAccuracyClass of navigationAccuracyClasses" [value]="navigationAccuracyClass">
                {{ "dtmWebAppLibUav.uavProperties.trackings.navigationAccuracy.value" | transloco : { value: navigationAccuracyClass } }}
            </dtm-ui-select-option>
            <div class="field-error" *dtmUiFieldHasError="trackingGroup.controls.takeoffAndLandingNavigationAccuracy; name: ['required']">
                {{ "dtmWebAppLibUav.setupForm.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-select-field>
    </div>
</ng-template>
