import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewContainerRef } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
    AirRisk,
    AirRiskElement,
    AirRiskMitigationsArc,
    AirRiskMitigationsCategory,
    MissionType,
    PopulationDensity,
} from "@dtm-frontend/shared/mission";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { AIR_RISK_MITIGATIONS_CATEGORIES, M1BStatementsKeys, M2StatementsKeys, RobustnessLevel } from "../../../../../../../shared";
import { AirRiskMitigations, MissionUAVSetup, SoraSettings } from "../../../../../../models/mission.model";
import {
    AnalysisSoraSettingsModalComponent,
    AnalysisSoraSettingsModalComponentData,
} from "./sora-settings-modal/analysis-sora-settings-modal.component";

interface AirRiskData {
    designator?: string;
    initialArc: AirRiskMitigationsArc;
    finalArc: AirRiskMitigationsArc;
    category: AirRiskMitigationsCategory;
    methods: string[];
}

export interface AnalysisSoraSettingsComponentState {
    soraSettings: SoraSettings | undefined;
    airRiskInfoData: AirRisk | undefined;
    missionType: MissionType | undefined;
    populationDensity: PopulationDensity | undefined;
    criticalArea: number | undefined;
    isEditMode: boolean;
    isExpanded: boolean;
    shouldDisabledAirRiskMitigation: boolean;
    missionUAVSetup: MissionUAVSetup | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-analysis-sora-settings",
    templateUrl: "./analysis-sora-settings.component.html",
    styleUrls: ["./analysis-sora-settings.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisSoraSettingsComponent {
    @Input() public set airRiskInfoData(value: AirRisk | undefined) {
        this.localStore.patchState({ airRiskInfoData: value });
    }
    @Input() public set populationDensity(value: PopulationDensity | undefined) {
        this.localStore.patchState({ populationDensity: value });
    }
    @Input() public set criticalArea(value: number | undefined) {
        this.localStore.patchState({ criticalArea: value });
    }
    @Input() public set soraSettings(value: SoraSettings | undefined) {
        if (!!value?.controlledGroundAreaComment && (value.riskMitigations?.m1A || value.riskMitigations?.m1B)) {
            value = {
                ...value,
                riskMitigations: {
                    ...value.riskMitigations,
                    m1A: undefined,
                    m1B: undefined,
                },
            };
            this.soraSettingsChange.next(value);
        }

        this.localStore.patchState({ soraSettings: value, shouldDisabledAirRiskMitigation: !!value && this.hasArcAMitigation(value) });
    }
    @Input() public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });

        const soraSettings = this.localStore.selectSnapshotByKey("soraSettings");
        if (value !== MissionType.VLOS && soraSettings?.riskMitigations?.m1B) {
            this.soraSettingsChange.next({
                ...soraSettings,
                riskMitigations: {
                    ...soraSettings.riskMitigations,
                    m1B: undefined,
                },
            });
        }

        if (
            soraSettings?.airRiskMitigations &&
            Object.keys(soraSettings.airRiskMitigations).length &&
            (soraSettings?.flightAroundObstacleComment ||
                soraSettings.flightLowHeightComment ||
                soraSettings.flightSegregatedAirspaceComment)
        ) {
            this.soraSettingsChange.next({
                ...soraSettings,
                airRiskMitigations: undefined,
            });
        }
    }
    @Input() public set isEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }
    @Input() public set isExpanded(value: BooleanInput) {
        this.localStore.patchState({ isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set missionUAVSetup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ missionUAVSetup: value });
    }

    @Output() public readonly soraSettingsChange = new EventEmitter<SoraSettings>();

    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly airRiskMitigation$ = combineLatest([this.soraSettings$, this.localStore.selectByKey("airRiskInfoData")]).pipe(
        map(([airRiskMitigations, airRiskInfoData]) => this.getAirRiskMitigation(airRiskMitigations?.airRiskMitigations, airRiskInfoData))
    );
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");

    protected readonly RobustnessLevel = RobustnessLevel;
    protected readonly M1BStatementsKeys = M1BStatementsKeys;
    protected readonly M2StatementsKeys = M2StatementsKeys;

    constructor(
        private readonly dialog: MatDialog,
        private readonly localStore: LocalComponentStore<AnalysisSoraSettingsComponentState>,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        localStore.setState({
            soraSettings: undefined,
            airRiskInfoData: undefined,
            missionType: undefined,
            populationDensity: undefined,
            criticalArea: undefined,
            isEditMode: false,
            isExpanded: false,
            shouldDisabledAirRiskMitigation: false,
            missionUAVSetup: undefined,
        });
    }

    protected addOrEdit() {
        this.dialog
            .open<AnalysisSoraSettingsModalComponent, AnalysisSoraSettingsModalComponentData>(AnalysisSoraSettingsModalComponent, {
                data: {
                    soraSettings: this.localStore.selectSnapshotByKey("soraSettings"),
                    missionType: this.localStore.selectSnapshotByKey("missionType"),
                    airRiskInfoData: this.localStore.selectSnapshotByKey("airRiskInfoData"),
                    populationDensity: this.localStore.selectSnapshotByKey("populationDensity"),
                    criticalArea: this.localStore.selectSnapshotByKey("criticalArea"),
                    shouldDisabledAirRiskMitigation: this.localStore.selectSnapshotByKey("shouldDisabledAirRiskMitigation"),
                    missionUAVSetup: this.localStore.selectSnapshotByKey("missionUAVSetup"),
                },
                viewContainerRef: this.viewContainerRef,
            })
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                if (!value) {
                    return;
                }

                this.soraSettingsChange.next(value);
            });
    }

    protected isRiskMitigationAdded(settings?: SoraSettings) {
        return !!(
            settings?.riskMitigations?.m1A ??
            settings?.riskMitigations?.m1B ??
            settings?.riskMitigations?.m2 ??
            settings?.airRiskMitigations?.mitigatingMeasures?.length
        );
    }

    private getAirRiskMitigation(airRiskMitigations: AirRiskMitigations | undefined, airRiskInfoData: AirRisk | undefined): AirRiskData[] {
        const airRiskElements: AirRiskElement[] = airRiskInfoData?.elements ?? [];
        const availableMitigations = airRiskMitigations?.mitigatingMeasures.map(({ category }) => category);
        const areaAirspaceRiskData = new Map<AirRiskMitigationsCategory, AirRiskData>();
        let mitigation;

        airRiskElements
            .filter(
                ({ designator, category }) =>
                    (!!designator || category === AirRiskMitigationsCategory.Other) &&
                    AIR_RISK_MITIGATIONS_CATEGORIES.includes(category) &&
                    availableMitigations?.includes(category)
            )
            .forEach(({ category, designator, initialArc }) => {
                mitigation = airRiskMitigations?.mitigatingMeasures.find((data) => data.category === category);
                areaAirspaceRiskData.set(category, {
                    designator:
                        (areaAirspaceRiskData.get(category)?.designator ? `${areaAirspaceRiskData.get(category)?.designator}, ` : "") +
                        designator,
                    finalArc: mitigation?.arc ?? initialArc,
                    initialArc: initialArc,
                    methods: mitigation?.methods.map(({ title }) => title) ?? [],
                    category,
                });
            });

        if (
            airRiskInfoData?.operationalEmpty?.initialArc &&
            airRiskInfoData.operationalEmpty.residualArc &&
            !airRiskInfoData.elements.length
        ) {
            areaAirspaceRiskData.set(AirRiskMitigationsCategory.EmptyAirspace, {
                finalArc: airRiskInfoData.operationalEmpty.residualArc,
                initialArc: airRiskInfoData.operationalEmpty.initialArc,
                methods: airRiskMitigations?.mitigatingMeasures[0].methods.map(({ title }) => title) ?? [],
                category: AirRiskMitigationsCategory.EmptyAirspace,
            });
        }

        return [...areaAirspaceRiskData.values()];
    }

    private hasArcAMitigation(settings: SoraSettings): boolean {
        return !!(settings.flightAroundObstacleComment || settings.flightSegregatedAirspaceComment || settings.flightLowHeightComment);
    }
}
