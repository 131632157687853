<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmWebAppLibSpecPermApp.draftNameDialog.saveDraftLabel" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-input-field>
        <label>{{ "dtmWebAppLibSpecPermApp.draftNameDialog.draftNameLabel" | transloco }}</label>
        <input matInput type="text" [formControl]="nameFormControl" [maxlength]="MAX_NAME_LENGTH" required autocomplete="off" />
        <div class="field-error" *ngIf="nameFormControl.touched && nameFormControl.errors?.maxlength">
            {{
                "dtmWebAppLibSpecPermApp.draftNameDialog.draftNameMaxLengthValueError"
                    | transloco : { maxLength: nameFormControl.errors?.maxlength.requiredLength }
            }}
        </div>
        <div class="field-error" *ngIf="nameFormControl.touched && (nameFormControl.errors?.required || nameFormControl.errors?.pattern)">
            {{ "dtmWebAppLibSpecPermApp.draftNameDialog.draftNameRequiredValueError" | transloco }}
        </div>
    </dtm-ui-input-field>
</div>
<div mat-dialog-actions align="end" *ngrxLet="data.isSaveDraftProcessing$ as isSaveDraftProcessing">
    <button type="button" class="button-secondary" [matDialogClose]="false">
        {{ "dtmWebAppLibSpecPermApp.draftNameDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" [disabled]="isSaveDraftProcessing" class="button-primary" (click)="save()">
        <mat-spinner diameter="20" *ngIf="isSaveDraftProcessing" color="accent"></mat-spinner>
        <dtm-ui-icon name="save" *ngIf="!isSaveDraftProcessing"></dtm-ui-icon>
        {{ "dtmWebAppLibSpecPermApp.draftNameDialog.saveButtonLabel" | transloco }}
    </button>
</div>
