<ng-container *ngrxLet="enterpriseOperatorDetails$ as enterpriseOperatorDetails">
    <dtm-ui-expandable-panel [hasHeaderSeparator]="false" isExpanded>
        <h2 class="header" headerSlot>{{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.header" | transloco }}</h2>
        <div class="details-container">
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorMigration.detailsStep.companyInfo.nameLabel' | transloco"
                [value]="enterpriseOperatorDetails.companyData.name"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorMigration.detailsStep.companyInfo.emailLabel' | transloco"
                [value]="enterpriseOperatorDetails.companyData.email"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyNumberLabel' | transloco"
                [value]="enterpriseOperatorDetails.companyData.nipRegon"
            ></dtm-ui-label-value>
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorMigration.detailsStep.companyInfo.phoneNumberLabel' | transloco"
                [value]="enterpriseOperatorDetails.companyData.phone | formatImportedPhoneNumber"
            ></dtm-ui-label-value>
        </div>
        <form class="form-container" [formGroup]="companyInfoForm">
            <dtm-ui-country-select-field
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="companyInfoForm.controls.country"
            ></dtm-ui-country-select-field>
            <dtm-ui-address-form
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="companyInfoForm.controls.address"
                [isSystemDefaultCountrySelected]="true"
            ></dtm-ui-address-form>
            <dtm-ui-info-message type="warning">
                {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyNumberWarningMessage" | transloco }}
            </dtm-ui-info-message>
            <dtm-ui-input-field>
                <label>
                    {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyNumberLabel" | transloco }}
                </label>
                <input matInput formControlName="companyNumber" [mask]="COMPANY_NUMBER_MASK" [showMaskTyped]="true" />
                <div class="field-error" *dtmUiFieldHasError="companyInfoForm.controls.companyNumber; name: 'required'">
                    {{ "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.requiredFieldError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="companyInfoForm.controls.companyNumber; name: 'minlength'; error as error">
                    {{
                        "dtmWebAppLibOperatorMigration.detailsStep.companyInfo.companyNumberTooShortError"
                            | transloco : { minLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
        </form>
    </dtm-ui-expandable-panel>

    <dtm-web-app-lib-operator-details-preview
        [importedOperatorDetails]="enterpriseOperatorDetails"
    ></dtm-web-app-lib-operator-details-preview>
</ng-container>
