<dtm-ui-loader-container *ngrxLet="data.isProcessing$ as isProcessing" [isShown]="isProcessing">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ "dtmWebAppMembership.managePermissions.header" | transloco }}</h2>
        <button type="button" class="button-icon" [matDialogClose]="null" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <p class="info-label">
            {{
                "dtmWebAppMembership.managePermissions.fullNameLabel"
                    | transloco : { firstName: data.user.firstName, lastName: data.user.lastName }
            }}
        </p>
        <form [formGroup]="permissionsForm" dtmUiInvalidFormScrollable>
            <div class="permissions-container">
                <p class="role-label">{{ "dtmWebAppMembership.managePermissions.permissionFieldLabel" | transloco }}</p>
                <div *ngFor="let permission of MembershipPermission | keyvalue : keepOrder">
                    <div class="role">
                        {{ "dtmWebAppMembership.managePermissions.permissionLabel" | transloco : { permission: permission.value } }}
                        <mat-slide-toggle [formControlName]="permission.value"></mat-slide-toggle>
                    </div>

                    <ng-container [ngSwitch]="permission.value">
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.OperatorManage">
                            <li *ngFor="let operatorManageDescriptionItem of OperatorManageDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.managePermissions.operatorManageDescriptionItemValue"
                                        | transloco : { value: operatorManageDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.MissionPlanning">
                            <li *ngFor="let missionPlanningDescriptionItem of MissionPlanningDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.managePermissions.missionPlanningDescriptionItemValue"
                                        | transloco : { value: missionPlanningDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.UavPiloting">
                            <li *ngFor="let uavPilotingDescriptionItem of UavPilotingDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.managePermissions.uavPilotingDescriptionItemValue"
                                        | transloco : { value: uavPilotingDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                        <ul class="role-description" *ngSwitchCase="MembershipPermission.FleetManagement">
                            <li *ngFor="let fleetManagementDescriptionItem of FleetManagementDescriptionItem | keyvalue : keepOrder">
                                <dtm-ui-icon name="check"></dtm-ui-icon>
                                {{
                                    "dtmWebAppMembership.managePermissions.fleetManagementDescriptionItemValue"
                                        | transloco : { value: fleetManagementDescriptionItem.key }
                                }}
                            </li>
                        </ul>
                    </ng-container>
                </div>

                <div class="required-one-error" *dtmUiFieldHasError="permissionsForm; name: 'requiredAtLeastOnePermission'">
                    <dtm-ui-icon name="error-warning"></dtm-ui-icon>
                    {{ "dtmWebAppMembership.managePermissions.permissionRequiredError" | transloco }}
                </div>
            </div>
        </form>
    </div>

    <div class="action-buttons" mat-dialog-actions>
        <button type="button" class="button-secondary" (click)="cancel()" [disabled]="isProcessing">
            {{ "dtmWebAppMembership.managePermissions.cancelLabel" | transloco }}
        </button>
        <button type="submit" class="button-primary" (click)="saveChanges()" [disabled]="isProcessing">
            {{ "dtmWebAppMembership.managePermissions.saveChangesLabel" | transloco }}
        </button>
    </div>
</dtm-ui-loader-container>
