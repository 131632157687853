<dtm-ui-expandable-panel
    *ngrxLet="adjacentAreaRisk$ as adjacentAreaRisk"
    [isExpanded]="isExpanded$ | ngrxPush"
    [hasHeaderSeparator]="false"
>
    <dtm-ui-expandable-simple-header
        headerSlot
        [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.adjacentAreaRiskHeaderLabel' | transloco"
    >
    </dtm-ui-expandable-simple-header>
    <div class="properties">
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.averagePeopleDensityInAdjacentAreaLabel' | transloco"
            class="whole-line-property"
        >
            <span
                [innerHtml]="
                    'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.averagePeopleDensityInAdjacentAreaValue'
                        | transloco : { value: adjacentAreaRisk.peoplePerSquareKilometerAvg }
                "
            ></span>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.groundRiskClassInAdjacentAreaLabel' | transloco"
            [value]="(adjacentAreaRisk.intrinsicGrc | grcLevel) ?? '-'"
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.adjacentAreaTypeLabel' | transloco"
            [value]="
                adjacentAreaRisk.isPeopleAssembly
                    ? ('dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.peopleGatheringLabel' | transloco)
                    : (adjacentAreaRisk.intrinsicGrl | grlLevel)
            "
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.airspaceRiskClassInAdjacentAreaLabel' | transloco"
            [value]="(adjacentAreaRisk.initialArc | arcLabel) ?? '-'"
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.technicalRequirementsLabel' | transloco"
            [value]="
                'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.technicalRequirementsValue'
                    | transloco : { value: adjacentAreaRisk.isFlightTerminationSystemAvailable }
            "
            class="whole-line-property"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.securityTypeLabel' | transloco"
            class="whole-line-property"
        >
            <ul class="security-types">
                <li *ngIf="adjacentAreaRisk.hasGeofencing">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.geofencingLabel" | transloco }}
                </li>
                <li *ngIf="adjacentAreaRisk.hasGeocage">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.geocageLabel" | transloco }}
                </li>
                <li *ngFor="let item of adjacentAreaRisk.failSafe">
                    {{ "dtmWebAppLibSpecPermApp.operationInfoStep.adjacentAreaRiskInfo.failSafeValue" | transloco : { value: item } }}
                </li>
            </ul>
        </dtm-ui-label-value>
    </div>
</dtm-ui-expandable-panel>
