import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
    Address,
    Alpha3CountryCode,
    BasicAddress,
    ErrorMode,
    PhoneNumber,
    requiredValidForSmsPhoneNumberValidator,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    AnimationUtils,
    DEFAULT_COUNTRY_CODE,
    DateUtils,
    LEGAL_AGE,
    LocalComponentStore,
    MIN_DATE_OF_BIRTH,
    RxjsUtils,
} from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LegalGuardianDetailsError, LegalGuardianFormValue, LegalGuardianGeneralData } from "../../services/legal-guardian.models";

const MIN_LEGAL_AGE_YEARS = new Date();
MIN_LEGAL_AGE_YEARS.setFullYear(MIN_LEGAL_AGE_YEARS.getFullYear() - LEGAL_AGE);

export enum LegalGuardianFormActions {
    GeneratePDF = "GeneratePDF",
    SendForSignature = "SendForSignature",
}

interface LegalGuardianFormComponentState {
    isLongGeneralDataProtectionRegulationVisible: boolean;
    legalGuardianDetails: LegalGuardianGeneralData | undefined;
    legalGuardianDetailsError: LegalGuardianDetailsError | undefined;
}

interface LegalGuardianForm {
    dateOfBirth: FormControl<Date | null>;
    nationality: FormControl<Alpha3CountryCode>;
    phoneNumber: FormControl<PhoneNumber | null>;
    address: FormControl<BasicAddress | null>;
    residenceCountry: FormControl<Alpha3CountryCode>;
    consent: FormControl<boolean>;
    generalDataProtectionRegulation: FormControl<boolean>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-legal-guardian-details-form[legalGuardianDetails]",
    templateUrl: "./legal-guardian-form.component.html",
    styleUrls: ["./legal-guardian-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class LegalGuardianFormComponent {
    @Input() public set legalGuardianDetails(value: LegalGuardianGeneralData | undefined) {
        this.localStore.patchState({ legalGuardianDetails: value });
        if (!value) {
            return;
        }
        this.legalGuardianForm.patchValue(value);
    }
    @Input() public set legalGuardianDetailsError(value: LegalGuardianDetailsError | undefined) {
        this.localStore.patchState({ legalGuardianDetailsError: value });
    }
    protected readonly MIN_LEGAL_AGE_YEARS = MIN_LEGAL_AGE_YEARS;
    protected readonly MIN_DATE_OF_BIRTH = MIN_DATE_OF_BIRTH;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly isLongGeneralDataProtectionRegulationVisible$ = this.localStore.selectByKey(
        "isLongGeneralDataProtectionRegulationVisible"
    );
    protected readonly LegalGuardianFormActions = LegalGuardianFormActions;
    protected readonly legalGuardianDetails$ = this.localStore.selectByKey("legalGuardianDetails").pipe(RxjsUtils.filterFalsy());
    protected readonly error$ = this.localStore.selectByKey("legalGuardianDetailsError").pipe(RxjsUtils.filterFalsy());
    protected readonly legalGuardianForm = new FormGroup<LegalGuardianForm>({
        dateOfBirth: new FormControl(null, Validators.required),
        nationality: new FormControl(DEFAULT_COUNTRY_CODE, { validators: Validators.required, nonNullable: true }),
        phoneNumber: new FormControl(null, { validators: requiredValidForSmsPhoneNumberValidator }),
        address: new FormControl(null, Validators.required),
        residenceCountry: new FormControl(DEFAULT_COUNTRY_CODE, { validators: Validators.required, nonNullable: true }),
        consent: new FormControl(false, { validators: Validators.requiredTrue, nonNullable: true }),
        generalDataProtectionRegulation: new FormControl(false, { validators: Validators.requiredTrue, nonNullable: true }),
    });
    protected readonly ErrorMode = ErrorMode;

    @Output() public readonly dataForSignatureSend = new EventEmitter<LegalGuardianFormValue>();
    @Output() public readonly pdfGenerate = new EventEmitter<LegalGuardianFormValue>();

    constructor(
        private readonly localStore: LocalComponentStore<LegalGuardianFormComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        localStore.setState({
            isLongGeneralDataProtectionRegulationVisible: false,
            legalGuardianDetails: undefined,
            legalGuardianDetailsError: undefined,
        });
    }

    protected toggleGeneralDataProtectionRegulationText() {
        this.localStore.patchState(({ isLongGeneralDataProtectionRegulationVisible }) => ({
            isLongGeneralDataProtectionRegulationVisible: !isLongGeneralDataProtectionRegulationVisible,
        }));
    }

    protected manageFormAction(action: LegalGuardianFormActions): void {
        if (this.legalGuardianForm.invalid) {
            this.legalGuardianForm.markAllAsTouched();

            return;
        }

        const valueToEmit = this.getValueToEmit();

        if (!valueToEmit) {
            return;
        }

        if (action === LegalGuardianFormActions.GeneratePDF) {
            this.pdfGenerate.emit(valueToEmit);

            return;
        }

        if (action === LegalGuardianFormActions.SendForSignature) {
            this.dataForSignatureSend.emit(valueToEmit);
        }
    }

    private getValueToEmit(): LegalGuardianFormValue | undefined {
        const formValue = this.legalGuardianForm.getRawValue();

        if (!formValue.dateOfBirth || !formValue.address || !formValue.residenceCountry) {
            return;
        }
        const address: Address = { ...formValue.address, country: formValue.residenceCountry };

        return {
            dateOfBirth: DateUtils.getISOStringDate(formValue.dateOfBirth.toDateString()),
            nationality: formValue.nationality,
            phoneNumber: formValue.phoneNumber as PhoneNumber,
            address,
        };
    }
}
