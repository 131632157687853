<ng-container *ngrxLet="properties$ as properties">
    <dtm-web-app-lib-uav-property
        [label]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.failSafeLabel' | transloco"
        [isEmpty]="properties.failSafe | invoke : isEmpty"
    >
        <span class="fail-safe-item" *ngFor="let failSafe of properties.failSafe">{{
            "dtmWebAppLibUav.uavProperties.generalTechnicalProperties.failSafeValue" | transloco : { value: failSafe }
        }}</span>
        <span *ngIf="properties.failSafe?.length === 0">
            {{ "dtmWebAppLibUav.uavProperties.generalTechnicalProperties.noFailSafeLabel" | transloco }}
        </span>
    </dtm-web-app-lib-uav-property>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.geofencingLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.geofencingDescription' | transloco"
        [value]="properties.hasGeofencing"
    >
    </dtm-web-app-lib-uav-property-boolean>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.detectAndAvoidLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.detectAndAvoidDescription' | transloco"
        [value]="properties.hasDetectAndAvoid"
    >
    </dtm-web-app-lib-uav-property-boolean>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.geocageLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.geocageDescription' | transloco"
        [value]="properties.hasGeocage"
    >
    </dtm-web-app-lib-uav-property-boolean>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.moduleRedundancyLabel' | transloco"
        [description]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.moduleRedundancyDescription' | transloco"
        [value]="properties.hasModuleRedundancy"
    >
    </dtm-web-app-lib-uav-property-boolean>
    <dtm-web-app-lib-uav-property-boolean
        [label]="'dtmWebAppLibUav.uavProperties.generalTechnicalProperties.proximitySensorsLabel' | transloco"
        [value]="properties.hasProximitySensors"
    >
    </dtm-web-app-lib-uav-property-boolean>
</ng-container>
