<ng-container *ngIf="capabilitiesError$ | ngrxPush as error; else mainTemplate">
    <ng-container [ngSwitch]="error.type">
        <dtm-ui-error
            *ngSwitchCase="OperationsManualErrorType.CannotGetCapabilities"
            [errorMessage]="'dtmWebAppLibOpsMan.capabilitiesNotFoundErrorMessage' | transloco"
            (reload)="refresh()"
        ></dtm-ui-error>
        <dtm-ui-error
            *ngSwitchCase="OperationsManualErrorType.InvalidOperator"
            [errorMessage]="'dtmWebAppLibOpsMan.invalidOperatorErrorMessage' | transloco"
        ></dtm-ui-error>
        <dtm-ui-error
            *ngSwitchDefault
            [errorMessage]="'dtmWebAppLibOpsMan.unknownErrorMessage' | transloco"
            (reload)="refresh()"
        ></dtm-ui-error>
    </ng-container>
</ng-container>

<ng-template #mainTemplate>
    <dtm-ui-loader-container
        [isShown]="isProcessing$ | ngrxPush"
        *ngrxLet="{
            capabilities: capabilities$,
            editedChapterId: editedChapterId$,
            areActionsBlocked: areActionsBlocked$,
            chapter: chapter$
        } as vm"
    >
        <div *ngIf="vm.capabilities.operationsManuals.length; else emptyStateTemplate" class="grid main-container">
            <mat-card class="container">
                <dtm-ui-loader-container [isShown]="isChapterProcessing$ | ngrxPush" class="wrapper">
                    <dtm-web-app-lib-opsman-header
                        class="header"
                        [capabilities]="vm.capabilities"
                        [areActionsBlocked]="vm.areActionsBlocked"
                        (versionChange)="setCurrentOperationsManual($event)"
                        (pdfDownload)="downloadPdf()"
                        (newVersionCreate)="generateNewVersion()"
                        (publish)="publish()"
                    ></dtm-web-app-lib-opsman-header>
                    <dtm-web-app-lib-opsman-table-of-contents
                        class="table-of-contents"
                        [lastUpdateDate]="lastUpdateDate$ | ngrxPush"
                        [chapters]="tableOfContentsChapters$ | ngrxPush"
                        [areActionsBlocked]="vm.areActionsBlocked"
                        (chapterSelect)="selectChapter($event)"
                    ></dtm-web-app-lib-opsman-table-of-contents>
                    <section [ngSwitch]="vm.chapter.type" *ngIf="vm.chapter" class="content">
                        <dtm-web-app-lib-opsman-chapter-simple
                            *ngSwitchCase="ChapterType.Html"
                            [chapter]="vm.chapter"
                            [isEditMode]="vm.editedChapterId === vm.chapter.id"
                            [areActionsBlocked]="vm.areActionsBlocked && vm.editedChapterId !== vm.chapter.id"
                            (editModeChange)="setEditedChapterId($event)"
                            (otherActionsBlock)="setActionsBlock($event)"
                            (contentSave)="saveChapterContent($event)"
                            (attachmentsManage)="openAttachmentsManagementDialog($event)"
                            (guideShow)="setGuidePanelVisibility(true)"
                        ></dtm-web-app-lib-opsman-chapter-simple>
                        <dtm-web-app-lib-opsman-chapter-subchapters
                            *ngSwitchCase="ChapterType.SubchaptersContent"
                            [chapter]="vm.chapter"
                            [editedChapterId]="vm.editedChapterId"
                            [areActionsBlocked]="vm.areActionsBlocked"
                            (editModeChange)="setEditedChapterId($event)"
                            (otherActionsBlock)="setActionsBlock($event)"
                            (contentSave)="saveChapterContent($event)"
                            (attachmentsManage)="openAttachmentsManagementDialog($event)"
                            (guideShow)="setGuidePanelVisibility(true)"
                        ></dtm-web-app-lib-opsman-chapter-subchapters>
                        <dtm-web-app-lib-opsman-chapter-extendable
                            *ngSwitchCase="ChapterType.ExtendableSubchaptersList"
                            [chapter]="vm.chapter"
                            [isEditMode]="vm.editedChapterId === vm.chapter.id"
                            (editModeChange)="setEditedChapterId($event)"
                            (subchapterSelect)="selectChapter($event)"
                            (subchapterAdd)="addSubchapter($event)"
                            (subchapterRemove)="removeSubchapter($event)"
                            (guideShow)="setGuidePanelVisibility(true)"
                        ></dtm-web-app-lib-opsman-chapter-extendable>
                    </section>
                </dtm-ui-loader-container>
            </mat-card>
        </div>
        <div
            class="guide-panel"
            *ngrxLet="isGuidePanelVisible$ as isGuidePanelVisible"
            [@foldFarRight]="isGuidePanelVisible ? 'open' : 'closed'"
        >
            <header>
                <button
                    type="button"
                    class="button-icon close-button"
                    (click)="setGuidePanelVisibility(false)"
                    [attr.tabindex]="isGuidePanelVisible ? undefined : -1"
                >
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
                <h2>{{ "dtmWebAppLibOpsMan.guide.headerLabel" | transloco }}</h2>
            </header>
            <dtm-web-app-lib-opsman-guide
                class="guide-content"
                [guideChapterItems]="guideChapterItems$ | ngrxPush"
                [highlightedChapterId]="highlightedChapterId$ | ngrxPush"
                [isGuidePanelVisible]="isGuidePanelVisible"
            ></dtm-web-app-lib-opsman-guide>
        </div>
    </dtm-ui-loader-container>
</ng-template>

<ng-template #emptyStateTemplate>
    <div class="grid grid-maxsize-8 grid-maxsize-tablet-8">
        <dtm-ui-empty-state>
            <ng-container titleSlot>
                {{ "dtmWebAppLibOpsMan.emptyState.headerLabel" | transloco }}
            </ng-container>
            <p messageSlot>
                {{ "dtmWebAppLibOpsMan.emptyState.descriptionLabel" | transloco }}
                <dtm-ui-popover [popoverText]="'dtmWebAppLibOpsMan.emptyState.descriptionPopoverLabel' | transloco"></dtm-ui-popover>
            </p>
            <img imageSlot src="assets/images/operations-manual.svg" alt="" />
            <button footerSlot type="button" class="button-primary" (click)="generateNewVersion()">
                {{ "dtmWebAppLibOpsMan.emptyState.generateButtonLabel" | transloco }}
            </button>
        </dtm-ui-empty-state>
    </div>
</ng-template>
