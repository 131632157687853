<dtm-ui-wizard-step-wrapper
    [title]="'dtmWebAppLibPilotOperatorRegistration.basicDataHeader' | transloco"
    [stepNumber]="1"
    [stepsAmount]="3"
    [isBackButtonVisible]="false"
    (next)="save()"
    [isNextButtonEnabled]="true"
    dtmUiInvalidFormScrollable
    *ngrxLet="capabilities$ as capabilities"
>
    <dtm-ui-info-message type="info" class="pansa-utm-link-info" *ngIf="isPansaUtmLinkInfoVisible$ | ngrxPush">
        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.pansaUtmLinkInfoMessage" | transloco }}
    </dtm-ui-info-message>
    <form [formGroup]="basicDataForm">
        <div class="section">
            <h4>
                {{ "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInformationHeader" | transloco }}
            </h4>

            <dtm-ui-radio-group [formControl]="isRegisteredInOtherCountryControl" (change)="otherCountryRegisteredChange($event.value)">
                <label class="label">
                    {{ "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.registrationInOtherCountryQuestion" | transloco }}
                    <dtm-ui-popover>
                        <div class="popover-content">
                            <p>
                                {{
                                    "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInOtherCountryPopover.registeredInUE"
                                        | transloco
                                }}
                            </p>
                            <p>
                                {{
                                    "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInOtherCountryPopover.registeredInOtherCountry"
                                        | transloco
                                }}
                            </p>
                            <p>
                                {{
                                    "dtmWebAppLibPilotOperatorRegistration.basicData.registrationInOtherCountryPopover.warning" | transloco
                                }}
                            </p>
                        </div>
                    </dtm-ui-popover>
                </label>

                <div class="row-radio-content">
                    <dtm-ui-radio-field [value]="true">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.yesLabel" | transloco }}
                    </dtm-ui-radio-field>
                    <dtm-ui-radio-field [value]="false">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.noLabel" | transloco }}
                    </dtm-ui-radio-field>
                </div>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="isRegisteredInOtherCountryControl; name: 'required'">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>
        </div>
        <div class="form-section" *ngIf="isRegisteredInOtherCountryControl.value" #foreignOperatorElement @slideIn>
            <dtm-web-app-lib-foreign-operator-validation-fields
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="foreignOperatorControl"
                [foreignOperatorValidation]="foreignOperatorValidation$ | ngrxPush"
                (foreignOperatorCheck)="foreignOperatorCheck.emit($event)"
            ></dtm-web-app-lib-foreign-operator-validation-fields>
        </div>
        <div class="section" *ngIf="capabilities.isIdentityDocumentRequired">
            <h4>{{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityConfirmationSectionHeader" | transloco }}</h4>
            <dtm-ui-radio-group [formControl]="identityDocumentTypeControl">
                <label class="label">
                    {{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityDocumentTypeLabel" | transloco }}
                </label>

                <div class="radio-content">
                    <dtm-ui-radio-field [value]="IdentityDocumentType.IdCard">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.idCardLabel" | transloco }}
                    </dtm-ui-radio-field>
                    <dtm-ui-radio-field [value]="IdentityDocumentType.Passport">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.passportLabel" | transloco }}
                    </dtm-ui-radio-field>
                </div>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="identityDocumentTypeControl; name: 'required'">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>
            <div class="id-fields">
                <dtm-ui-date-field>
                    <label>{{ "dtmWebAppLibPilotOperatorRegistration.basicData.expirationDateLabel" | transloco }}</label>
                    <input
                        matInput
                        [formControl]="identityDocumentExpirationDateControl"
                        [matDatepicker]="picker"
                        [min]="MIN_DATE"
                        *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
                        [placeholder]="datePickerPlaceholder"
                    />
                    <mat-datepicker #picker></mat-datepicker>
                    <div class="field-error" *dtmUiFieldHasError="identityDocumentExpirationDateControl; name: 'matDatepickerMin'">
                        {{
                            "dtmWebAppLibPilotOperatorRegistration.basicData.expirationDateMinDate"
                                | transloco : { minDate: MIN_DATE | localizeDate }
                        }}
                    </div>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="identityDocumentExpirationDateControl; name: ['required', 'requiredTouched']"
                    >
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-date-field>
                <dtm-ui-files-upload-field
                    [isDownloadAllButtonVisible]="false"
                    [formControl]="identityDocumentIdControl"
                    [maxFileSize]="MAX_FILE_SIZE_BYTES"
                >
                    <label>
                        {{
                            (identityDocumentTypeControl.value === IdentityDocumentType.IdCard
                                ? "dtmWebAppLibPilotOperatorRegistration.basicData.idCardUploadLabel"
                                : "dtmWebAppLibPilotOperatorRegistration.basicData.passportUploadLabel"
                            ) | transloco
                        }}
                    </label>
                    <div class="field-error" *dtmUiFieldHasError="identityDocumentIdControl; name: 'maxlength'">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityFileUploadMultipleFilesError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="identityDocumentIdControl; name: 'required'">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                    <div class="field-hint">
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.identityDocumentAllowedExtensionsHint" | transloco }}
                    </div>
                </dtm-ui-files-upload-field>
            </div>
        </div>
        <div class="section">
            <h4>{{ "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.personalDataHeaderHeader" | transloco }}</h4>
            <div class="row">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.firstNameLabel' | transloco"
                    [value]="capabilities.firstName"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.lastNameLabel' | transloco"
                    [value]="capabilities.lastName"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.dateOfBirthLabel' | transloco"
                    [value]="capabilities.dateOfBirth | localizeDate"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.nationalityLabel' | transloco"
                >
                    <dtm-ui-country-display [country]="capabilities.nationality"></dtm-ui-country-display>
                </dtm-ui-label-value>
            </div>
        </div>
        <div class="section">
            <h4>{{ "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.addressHeader" | transloco }}</h4>
            <div class="form-section">
                <dtm-ui-country-select-field [formControl]="countryControl">
                    <label>
                        {{ "dtmWebAppLibPilotOperatorRegistration.basicData.countryLabel" | transloco }}
                    </label>
                    <div class="field-error" *dtmUiFieldHasError="countryControl; name: ['required', 'requiredTouched']">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                    <!-- NOTE *ngIf is used because *dtmUiFieldHasError does not detect when error is removed by other field change ie. in function otherCountryRegisteredChange -->
                    <div class="field-error" *ngIf="countryControl.errors?.countryInEasaGroup">
                        {{ "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.countryInEasaGrupError" | transloco }}
                    </div>
                </dtm-ui-country-select-field>

                <dtm-ui-radio-group
                    [formControl]="isOriginCountryFlyingDestinationControl"
                    *ngIf="this.basicDataForm.controls.isOriginCountryFlyingDestination.enabled"
                    @slideIn
                >
                    <label class="label">
                        {{
                            "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.flyingDestinationCountryLabel"
                                | transloco : { originCountry: capabilities.originCountry }
                        }}
                    </label>

                    <div class="row-radio-content">
                        <dtm-ui-radio-field [value]="true">
                            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.yesLabel" | transloco }}
                        </dtm-ui-radio-field>
                        <dtm-ui-radio-field [value]="false">
                            {{ "dtmWebAppLibPilotOperatorRegistration.basicData.noLabel" | transloco }}
                        </dtm-ui-radio-field>
                    </div>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="isOriginCountryFlyingDestinationControl; name: ['required', 'requiredTouched']"
                        >
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                    </dtm-ui-form-errors>
                </dtm-ui-radio-group>

                <!--TODO change validation presentation with UX-165-->
                <dtm-ui-info-message *ngIf="basicDataForm.controls.country.errors?.countryInEasaGroup" @slideIn>
                    <p>
                        {{
                            "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.basicData.registerInFlyingDestinationCountryMessage"
                                | transloco
                        }}
                    </p>
                </dtm-ui-info-message>

                <dtm-ui-address-form
                    dtmUiMarkValueAccessorControlsAsTouched
                    [formControl]="addressFormControl"
                    [isSystemDefaultCountrySelected]="countryControl.value === DEFAULT_COUNTRY_CODE"
                >
                </dtm-ui-address-form>
            </div>
        </div>
    </form>
</dtm-ui-wizard-step-wrapper>
