import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ManualCoordinatesInputEntityType, MapEntityType } from "@dtm-frontend/shared/map/cesium";
import { EmptyStateMode, MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { combineLatest, map } from "rxjs";
import { ItineraryPanelSettings, ViewItineraryEntity } from "../../itinerary-panel.models";
import { TrackDetailsDisplayMode } from "../track-details/track-details-display-mode";
import { ItineraryContent, ItineraryContentEntity, Polyline3DItineraryEntity } from "./../../../../../../../models/itinerary.model";
import { ItineraryEditorFormData, MissionPlanItineraryConstraints } from "./../../../../../../../models/mission.model";
import { StandardItineraryEntity } from "./../../../../../../../services/itinerary.service";

interface StandardEditorItineraryCardComponentState {
    manualCoordinatesInputType: ManualCoordinatesInputEntityType | undefined;
    constraints: MissionPlanItineraryConstraints | undefined;
    itineraryEditorFormData: ItineraryEditorFormData | undefined;
    itineraryContent: ItineraryContent;
    settings: ItineraryPanelSettings | undefined;
    currentPlanRoute: MissionPlanRoute | undefined;
    isProcessing: boolean;
    isDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector:
        // eslint-disable-next-line max-len
        "dtm-web-app-lib-standard-editor-itinerary-card[constraints][itineraryEditorFormData][itineraryContent][currentPlanRoute][isProcessing][isDisabled]",
    templateUrl: "./standard-editor-itinerary-card.component.html",
    styleUrls: ["./standard-editor-itinerary-card.component.scss", "../itinerary-panel/itinerary-panel.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardEditorItineraryCardComponent {
    protected readonly displayMode: TrackDetailsDisplayMode = {
        canEdit: {
            cylinder: {
                centerPoint: false,
                radius: true,
            },
            shape: {
                topHeight: false,
                bottomHeight: false,
                coordinates: true,
            },
            point: {
                coordinates: true,
                height: false,
            },
            corridor: {
                points: true,
                width: true,
            },
            segment: {
                bufferHeight: false,
                bufferWidth: false,
                addShape: false,
            },
        },
        canShow: {
            segment: false,
            shape: {
                stopover: false,
            },
            point: {
                height: false,
            },
            corridor: true,
        },
        canDelete: {
            point: true,
            shape: true,
            corridor: true,
        },
    };

    protected readonly manualCoordinatesInputType$ = this.localStore.selectByKey("manualCoordinatesInputType");
    protected readonly constraints$ = this.getConstraintsObservable();
    protected readonly itineraryContent$ = this.localStore.selectByKey("itineraryContent");
    protected readonly settings$ = this.localStore.selectByKey("settings");
    protected readonly currentPlanRoute$ = this.localStore.selectByKey("currentPlanRoute");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");

    protected readonly MapEntityType = MapEntityType;
    protected readonly EmptyStateMode = EmptyStateMode;

    @Input() public set itineraryContent(value: ItineraryContent | undefined) {
        const itineraryContent = Array.isArray(value) ? value : [];
        this.localStore.patchState({ itineraryContent });
    }
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set currentPlanRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ currentPlanRoute: value });
    }
    @Input() public set settings(value: ItineraryPanelSettings | undefined) {
        this.localStore.patchState({ settings: value });
    }
    @Input() public set itineraryEditorFormData(value: ItineraryEditorFormData | undefined) {
        this.localStore.patchState({ itineraryEditorFormData: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Output() public entityAdd = new EventEmitter<StandardItineraryEntity>();
    @Output() public entityDelete = new EventEmitter<ViewItineraryEntity["itineraryIndex"]>();
    @Output() public pointDelete = new EventEmitter<ViewItineraryEntity<Polyline3DItineraryEntity>["itineraryIndex"]>();
    @Output() public entityUpdate = new EventEmitter<ItineraryContentEntity>();

    constructor(private readonly localStore: LocalComponentStore<StandardEditorItineraryCardComponentState>) {
        this.localStore.setState({
            manualCoordinatesInputType: undefined,
            constraints: undefined,
            itineraryEditorFormData: undefined,
            itineraryContent: [],
            settings: undefined,
            currentPlanRoute: undefined,
            isProcessing: false,
            isDisabled: false,
        });
    }

    private getConstraintsObservable() {
        return combineLatest([this.localStore.selectByKey("constraints"), this.localStore.selectByKey("itineraryEditorFormData")]).pipe(
            map(([constraints, itineraryEditorFormData]): MissionPlanItineraryConstraints | undefined => {
                if (!constraints || !itineraryEditorFormData) {
                    return undefined;
                }

                return {
                    ...constraints,
                    max: {
                        ...constraints.max,
                        size: itineraryEditorFormData?.isEnlargedZoneStatementAccepted
                            ? constraints.max.largeZoneRadius
                            : constraints.max.regularZoneRadius,
                    },
                };
            })
        );
    }
}
