import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import {
    MissionCategory,
    MissionPlanAnalysisStatus,
    MissionPlanData,
    MissionPlanOperationCategoryOption,
    MissionPlanSpecificPermitType,
    MissionType,
    PlanVerificationStatus,
    TacticalMitigationPerformanceRequirementProperty,
} from "@dtm-frontend/shared/mission";
import { GeoJSON, InvalidFormScrollableDirective, ItineraryEditorType } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ToastrService } from "ngx-toastr";
import {
    CERTIFIED_CATEGORY_FINAL_GRC,
    MissionDataFormData,
    MissionPlanItineraryWithoutConstraints,
    MissionUAVSetup,
    OperationalGeometryData,
    PublicMissionPlanData,
    SoraSettings,
} from "../../../../models/mission.model";
import { AdditionalInformationSettings } from "../../../mission-notes-and-description/personal-notes.component";
import { MissionWizardSteps } from "../../content/mission-wizard-content.component";

interface MissionWizardSummaryStepComponentState {
    analysisStatus: MissionPlanAnalysisStatus | undefined;
    isProcessing: boolean;
    stepNumber: number | undefined;
    stepsAmount: number | undefined;
    nearbyMissions: PublicMissionPlanData[];
    collisionMission: PublicMissionPlanData[];
    areOtherMissionsProcessing: boolean;
    missionDataFormData: Partial<MissionDataFormData> | undefined;
    soraSettings: SoraSettings | undefined;
    collisionZones: AirspaceElementsInfo | undefined;
    missionType: MissionType | undefined;
    selectedZoneId: string | undefined;
    setup: MissionUAVSetup | undefined;
    areSoraSettingsEnabled: boolean;
    operationalGeometryData: OperationalGeometryData | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-wizard-analysis-step[analysisStatus]",
    templateUrl: "./analysis-step.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionWizardAnalysisStepComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    @Input() public set analysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({
            analysisStatus: value,
        });
    }
    @Input() public set isProcessing(value: boolean) {
        this.localStore.patchState({ isProcessing: value });
    }
    @Input() public set stepNumber(value: number | undefined) {
        this.localStore.patchState({ stepNumber: value });
    }
    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value });
    }
    @Input() public set nearbyMissions(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ nearbyMissions: value ?? [] });
    }
    @Input() public set collisionMission(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ collisionMission: value ?? [] });
    }
    @Input() public set areOtherMissionsProcessing(value: BooleanInput) {
        this.localStore.patchState({ areOtherMissionsProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set missionDataFormData(value: Partial<MissionDataFormData> | undefined) {
        this.localStore.patchState({
            missionDataFormData: value,
            areSoraSettingsEnabled: this.getAreSoraSettingsEnabled(value?.category),
        });
    }
    @Input() public set currentPlanItinerary(value: MissionPlanItineraryWithoutConstraints | undefined) {
        if (value?.type !== ItineraryEditorType.None) {
            this.localStore.patchState({ soraSettings: value?.soraSettings });
        }
    }
    @Input() public set collisionZones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ collisionZones: value });
    }
    @Input() public set missionType(value: MissionType | undefined) {
        this.localStore.patchState({ missionType: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set setup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ setup: value });
    }
    @Input() public set operationalGeometryData(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometryData: value });
    }
    @Output() public readonly back = new EventEmitter<void>();
    @Output() public readonly next = new EventEmitter<void>();
    @Output() public readonly flyToRoute = new EventEmitter<string>();
    @Output() public readonly flyToMainMissionRoute = new EventEmitter<void>();
    @Output() public readonly flyToZone = new EventEmitter<GeoJSON>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    @Output() public readonly additionalInformationChange = new EventEmitter<AdditionalInformationSettings>();
    @Output() public readonly otherMissionSelected = new EventEmitter<MissionPlanData>();
    @Output() public readonly soraSettingsUpdate = new EventEmitter<SoraSettings>();

    protected readonly MissionWizardSteps = MissionWizardSteps;
    protected readonly analysisStatus$ = this.localStore.selectByKey("analysisStatus");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly stepNumber$ = this.localStore.selectByKey("stepNumber");
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount");
    protected readonly nearbyMissions$ = this.localStore.selectByKey("nearbyMissions");
    protected readonly collisionMission$ = this.localStore.selectByKey("collisionMission");
    protected readonly areOtherMissionsProcessing$ = this.localStore.selectByKey("areOtherMissionsProcessing");
    protected readonly missionDataFormData$ = this.localStore.selectByKey("missionDataFormData");
    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly collisionZones$ = this.localStore.selectByKey("collisionZones");
    protected readonly missionType$ = this.localStore.selectByKey("missionType");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly setup$ = this.localStore.selectByKey("setup");
    protected readonly areSoraSettingsEnabled$ = this.localStore.selectByKey("areSoraSettingsEnabled");
    protected readonly operationalGeometryData$ = this.localStore.selectByKey("operationalGeometryData");
    protected readonly MissionCategory = MissionCategory;
    protected readonly MissionPlanSpecificPermitType = MissionPlanSpecificPermitType;
    protected readonly MissionType = MissionType;
    protected readonly TacticalMitigationPerformanceRequirement = TacticalMitigationPerformanceRequirementProperty;
    protected readonly PlanVerificationStatus = PlanVerificationStatus;

    constructor(
        private readonly localStore: LocalComponentStore<MissionWizardSummaryStepComponentState>,
        private readonly toastrService: ToastrService
    ) {
        this.localStore.setState({
            analysisStatus: undefined,
            isProcessing: false,
            stepNumber: undefined,
            stepsAmount: undefined,
            nearbyMissions: [],
            collisionMission: [],
            areOtherMissionsProcessing: false,
            missionDataFormData: undefined,
            soraSettings: undefined,
            collisionZones: undefined,
            missionType: undefined,
            selectedZoneId: undefined,
            setup: undefined,
            areSoraSettingsEnabled: false,
            operationalGeometryData: undefined,
        });
    }

    protected goToSummaryStep() {
        const analysisStatus = this.localStore.selectSnapshotByKey("analysisStatus");
        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");

        if (
            !analysisStatus ||
            isProcessing ||
            (analysisStatus.sora?.result?.finalGrc && analysisStatus.sora.result.finalGrc >= CERTIFIED_CATEGORY_FINAL_GRC)
        ) {
            return;
        }

        const { applyForSpecificPermit, isPlanSubmittable } = analysisStatus;

        if (applyForSpecificPermit || isPlanSubmittable) {
            this.next.emit();
        }
    }

    protected getAreSoraSettingsEnabled(category: MissionPlanOperationCategoryOption | undefined): boolean {
        return category?.type === MissionCategory.Specific && category.specificPermitType === MissionPlanSpecificPermitType.Individual;
    }
}
