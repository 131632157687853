import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY } from "rxjs";
import { catchError } from "rxjs/operators";
import { FlightTrackingApiService, FlightTrackingErrorType } from "../services/flight-tracking-api.service";
import { FlightTrackingActions } from "./flight-tracking.actions";

interface FlightTrackingStateModel {
    error: { type: FlightTrackingErrorType } | null;
}

const defaultState: FlightTrackingStateModel = {
    error: null,
};

@State<FlightTrackingStateModel>({
    name: "flightTracking",
    defaults: defaultState,
})
@Injectable()
export class FlightTrackingState {
    constructor(private readonly flightTrackingApiService: FlightTrackingApiService) {}

    @Selector()
    public static getError(state: FlightTrackingStateModel) {
        return state.error;
    }

    @Action(FlightTrackingActions.EnrichFlightPositionUpdates)
    public enrichFlightPositionUpdates(
        context: StateContext<FlightTrackingStateModel>,
        { identifier }: FlightTrackingActions.EnrichFlightPositionUpdates
    ) {
        context.patchState({ error: null });

        return this.flightTrackingApiService.enrichFlightPositionUpdates(identifier).pipe(
            catchError((error) => {
                context.patchState({
                    error,
                });

                return EMPTY;
            })
        );
    }

    @Action(FlightTrackingActions.CleanUpFlightTrackingState)
    public cleanUpFlightTrackingState(context: StateContext<FlightTrackingStateModel>) {
        context.patchState(defaultState);
    }
}
