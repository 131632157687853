<section *ngFor="let nameControl of namesFormArray.controls; index as nameIndex" class="item">
    <dtm-ui-input-field>
        <label>
            {{ inputFieldLabel$ | ngrxPush }}
        </label>
        <input matInput type="text" [formControl]="nameControl" />
        <div class="field-error" *dtmUiFieldHasError="nameControl; name: ['required', 'pattern']">
            {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="nameControl; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibSpecPermApp.operationDetailsStep.namesControlMaxLengthValueError"
                    | transloco : { maxLength: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>
    <button type="button" class="button-icon" (click)="removeFrequency(nameIndex)" [disabled]="namesFormArray.controls.length === 1">
        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
    </button>
</section>

<div class="add-name">
    <button type="button" class="button-secondary" (click)="addName()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ addButtonNameLabel$ | ngrxPush }}
    </button>
</div>
