<div class="mission-tile" *ngrxLet="{ missionPlan: missionPlan$ } as vm">
    <!-- TODO replace it with <dtm-mission-badge-group> component - REJ-2976 -->
    <dtm-web-app-lib-plan-badge-group
        class="badge-group"
        [remarks]="vm.missionPlan.remarks"
        [missionType]="vm.missionPlan.flightType"
        [phase]="vm.missionPlan.phase"
    ></dtm-web-app-lib-plan-badge-group>

    <a [routerLink]="['.', vm.missionPlan.id]">
        <div class="header row">
            <div class="title">
                {{ vm.missionPlan.name || ("dtmWebAppLibShared.missionTile.noNameTitle" | transloco) }}
            </div>
            <button
                [matMenuTriggerFor]="missionMenu"
                class="button-icon menu-button"
                *ngIf="
                    vm.missionPlan.availableActions.editable ||
                    vm.missionPlan.availableActions.deletable ||
                    vm.missionPlan.availableActions.cloneable
                "
                type="button"
                (click)="$event.preventDefault(); $event.stopImmediatePropagation()"
            >
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
            <mat-menu #missionMenu="matMenu" xPosition="before">
                <button
                    mat-menu-item
                    [routerLink]="['.', vm.missionPlan.id]"
                    [queryParams]="{ source: currentUrl$ | ngrxPush }"
                    type="button"
                >
                    <dtm-ui-icon name="information"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.details" | transloco }}</span>
                </button>
                <button
                    mat-menu-item
                    *ngIf="vm.missionPlan.availableActions.editable"
                    [routerLink]="['/plan/edit', vm.missionPlan.id]"
                    type="button"
                >
                    <dtm-ui-icon name="edit"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.edit" | transloco }}</span>
                </button>
                <button mat-menu-item *ngIf="vm.missionPlan.availableActions.cloneable" (click)="clone()" type="button">
                    <dtm-ui-icon name="file-copy"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.clone" | transloco }}</span>
                </button>
                <button mat-menu-item (click)="downloadKml()" type="button">
                    <dtm-ui-icon name="download"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.downloadKml" | transloco }}</span>
                </button>
                <button
                    mat-menu-item
                    *ngIf="vm.missionPlan.availableActions.deletable"
                    (click)="delete(vm.missionPlan.status)"
                    type="button"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.delete" | transloco }}</span>
                </button>
                <button
                    mat-menu-item
                    *ngIf="vm.missionPlan.availableActions.cancelable && vm.missionPlan.missionStatus !== MissionStatus.Activated"
                    (click)="cancel()"
                    type="button"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibMission.missionTile.menuLabels.cancel" | transloco }}</span>
                </button>
            </mat-menu>
        </div>
        <div class="row">
            <ng-container
                *ngTemplateOutlet="
                    vm.missionPlan.phase === MissionProcessingPhase.CaaPermitApplication ||
                    (vm.missionPlan.operationCategoryType === MissionCategory.Specific && vm.missionPlan.soraSail)
                        ? caaPermitTemplate
                        : defaultTemplate
                "
            ></ng-container>
            <ng-template #caaPermitTemplate>
                <div class="column">
                    <dtm-web-app-lib-mission-date-range [missionDates]="missionDates$ | ngrxPush"></dtm-web-app-lib-mission-date-range>
                </div>
            </ng-template>
            <ng-template #defaultTemplate>
                <div class="column">
                    <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
                    <ng-container *ngIf="vm.missionPlan.flightStartAtMin; else noDataTemplate">
                        <p>{{ vm.missionPlan.flightStartAtMin | localizeDate : { dateStyle: "short" } }}</p>
                        <p class="additional-info">{{ vm.missionPlan.flightStartAtMin | localizeDate : { weekday: "long" } }}</p>
                    </ng-container>
                </div>
                <div class="column">
                    <dtm-ui-icon name="time"></dtm-ui-icon>
                    <ng-container *ngIf="vm.missionPlan.flightStartAtMin; else noDataTemplate">
                        {{ vm.missionPlan.flightStartAtMin | localizeDate : { timeStyle: "short" } }}
                        -
                        {{ vm.missionPlan.flightFinishAtMax | localizeDate : { timeStyle: "short" } }}
                        <p class="additional-info">
                            <dtm-ui-time-diff-display
                                [firstDate]="vm.missionPlan.flightStartAtMin"
                                [secondDate]="vm.missionPlan.flightFinishAtMax"
                            ></dtm-ui-time-diff-display>
                        </p>
                    </ng-container>
                </div>
            </ng-template>
        </div>
        <ng-container *ngIf="isExtendedMode$ | ngrxPush; let isExtendedMode" [class.extended]="isExtendedMode">
            <div class="row">
                <div class="column">
                    <dtm-ui-icon name="building"></dtm-ui-icon>
                    <p>{{ vm.missionPlan.operatorName ?? ("dtmWebAppLibMission.missionTile.noDataLabel" | transloco) }}</p>
                </div>
                <div class="column">
                    <dtm-ui-icon name="user"></dtm-ui-icon>
                    <p>{{ vm.missionPlan.pilotName ?? ("dtmWebAppLibMission.missionTile.noDataLabel" | transloco) }}</p>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <dtm-ui-icon name="drone"></dtm-ui-icon>
                    <p>{{ vm.missionPlan.uavName ?? ("dtmWebAppLibMission.missionTile.noDataLabel" | transloco) }}</p>
                    <p class="additional-info">{{ vm.missionPlan.uavSetupName }}</p>
                </div>
            </div>
        </ng-container>
        <div class="thumbnail"><img [dtmUiImgSecuredSrc]="vm.missionPlan.thumbnail" /></div>
    </a>
</div>
<ng-template #noDataTemplate>
    <p>{{ "dtmWebAppLibMission.missionTile.noDataLabel" | transloco }}</p>
</ng-template>
