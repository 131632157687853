<mat-card class="details-card" *ngrxLet="type$ as type">
    <div class="details-content" *ngrxLet="details$ as details">
        <img
            class="img"
            alt=""
            [src]="type === publicProfileType.Pilot ? 'assets/images/pilot-profile.svg' : 'assets/images/operator-profile.svg'"
        />
        <h1 class="details-header">
            {{
                (type === publicProfileType.Pilot
                    ? "dtmWebAppLibPublicProfile.pilotProfileTitle"
                    : "dtmWebAppLibPublicProfile.operatorProfileTitle"
                ) | transloco
            }}
        </h1>
        <div class="section-wrapper section-grid section-status">
            <span class="section-label">{{ "dtmWebAppLibPublicProfile.statusLabel" | transloco }}</span>
            <dtm-ui-operator-status-badge [status]="details?.status"></dtm-ui-operator-status-badge>
        </div>
        <div class="section-wrapper section-grid">
            <span class="section-label">{{
                (type === publicProfileType.Pilot
                    ? "dtmWebAppLibPublicProfile.pilotNumberLabel"
                    : "dtmWebAppLibPublicProfile.operatorNumberLabel"
                ) | transloco
            }}</span>
            <span class="section-value">{{ details?.number }}</span>
        </div>
        <div class="section-wrapper">
            <div class="section-label qualifications-label">
                {{
                    (type === publicProfileType.Pilot
                        ? "dtmWebAppLibPublicProfile.pilotQualificationsLabel"
                        : "dtmWebAppLibPublicProfile.operatorQualificationsLabel"
                    ) | transloco
                }}
            </div>
            <div class="qualifications" *ngIf="details.qualifications?.length; else noQualificationsTemplate">
                <dtm-ui-qualification-badge *ngFor="let qualification of details.qualifications" [qualification]="qualification">
                </dtm-ui-qualification-badge>
            </div>
        </div>
        <div class="section-wrapper" *ngIf="details.certificates && details.certificates.length">
            <div class="section-label qualifications-label">{{ "dtmWebAppLibPublicProfile.certificatesLabel" | transloco }}</div>
            <div class="certificates">
                <div class="certificate" *ngFor="let certificate of details.certificates">
                    <dtm-ui-icon name="contract"></dtm-ui-icon>
                    <div class="certificate-details">
                        <div class="certificate-name">{{ certificate.name }}</div>
                        <div class="certificate-expiration-date">
                            {{
                                "dtmWebAppLibPublicProfile.expirationDateValue"
                                    | transloco : { expirationDate: certificate.expirationDate | localizeDate }
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <dtm-web-app-lib-pilot-operator-other-information-tile
            *ngIf="details.otherInformation.length"
            [otherInformationList]="details.otherInformation"
        ></dtm-web-app-lib-pilot-operator-other-information-tile>
    </div>
    <ng-template #noQualificationsTemplate>
        {{ "dtmWebAppLibPublicProfile.noQualificationInfo" | transloco }}
    </ng-template>
</mat-card>
