import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { User } from "../../models/user-profile.models";

interface PersonalDataComponentState {
    userProfile: User | undefined;
}

@Component({
    selector: "dtm-web-app-lib-personal-data[userProfile]",
    templateUrl: "./personal-data.component.html",
    styleUrls: ["./personal-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class PersonalDataComponent {
    @Input() public set userProfile(value: User | undefined) {
        this.localStore.patchState({ userProfile: value });
    }

    protected readonly userProfile$ = this.localStore.selectByKey("userProfile").pipe(RxjsUtils.filterFalsy());

    constructor(private readonly localStore: LocalComponentStore<PersonalDataComponentState>) {
        this.localStore.setState({
            userProfile: undefined,
        });
    }
}
