<div class="grid grid-maxsize-8 grid-maxsize-tablet-10">
    <dtm-ui-wizard-step-wrapper
        [stepNumber]="stepNumber$ | ngrxPush"
        [stepsAmount]="stepsAmount$ | ngrxPush"
        [title]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.header' | transloco"
        [nextButtonLabel]="
            (vm.isPaymentFeatureAvailable
                ? 'dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.nextButtonLabel'
                : 'dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.goToSignatureButtonLabel'
            ) | transloco
        "
        (back)="back.emit()"
        (next)="submit()"
        dtmUiInvalidFormScrollable
        *ngrxLet="{
            selectedPossiblePermissions: selectedPossiblePermissions$,
            isPaymentFeatureAvailable: isPaymentFeatureAvailable$
        } as vm"
    >
        <div class="permissions-container">
            <h4 class="card-header">{{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.permissionsHeader" | transloco }}</h4>
            <dtm-web-app-lib-operator-permission-summary-permission
                *ngFor="let permission of vm.selectedPossiblePermissions"
                [permission]="permission"
                [isPaymentFeatureAvailable]="vm.isPaymentFeatureAvailable"
            ></dtm-web-app-lib-operator-permission-summary-permission>
        </div>
        <div *ngrxLet="operator$ as operator">
            <h4 class="card-header">
                {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.operatorDetailsHeader" | transloco }}
            </h4>

            <div class="two-columns-container">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.operatorDetailsNameLabel' | transloco"
                    [value]="operator.name"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.operatorDetailsOperatorNumberLabel' | transloco"
                    [value]="operator.number"
                ></dtm-ui-label-value>
            </div>
        </div>
        <div *ngIf="isAttorneyPowerAdministrativeFeeRequired$ | ngrxPush">
            <h2 class="card-header">
                {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.administrativeFeeHeader" | transloco }}
                <dtm-ui-popover (click)="$event.stopPropagation()">
                    <div class="popover-title">
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.administrativeFeePopoverTitle" | transloco }}
                    </div>
                    <p class="popover-body">
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.administrativeFeePopoverBody" | transloco }}
                    </p>
                </dtm-ui-popover>
            </h2>
            <form [formGroup]="administrativeFeeForm">
                <dtm-ui-checkbox-field formControlName="isDeclarationOfExemption" class="declaration-of-exception-checkbox-label">
                    <span>{{
                        "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.declarationOfExceptionLabel" | transloco
                    }}</span>
                </dtm-ui-checkbox-field>

                <div *ngIf="administrativeFeeForm.controls.feeConfirmation" @slideIn>
                    <dtm-ui-files-upload-field
                        [isDownloadAllButtonVisible]="false"
                        [formControl]="feeConfirmationControl"
                        [maxFileSize]="MAX_FILE_SIZE_BYTES"
                        [allowedTypes]="FEE_CONFIRMATION_FILE_ALLOWED_MIME_TYPES"
                    >
                        <label>{{
                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.feeConfirmationLabel" | transloco
                        }}</label>
                        <div class="field-error" *dtmUiFieldHasError="feeConfirmationControl; name: 'maxlength'">
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.maxFeeConfirmationFilesError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="feeConfirmationControl; name: ['required', 'requiredTouched']">
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.maxFeeConfirmationFilesError" | transloco }}
                        </div>
                        <div class="field-hint">
                            {{
                                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.feeConfirmationHint"
                                    | transloco : { maxFileSize: MAX_FILE_SIZE_BYTES | formatFileSize }
                            }}
                        </div>
                    </dtm-ui-files-upload-field>
                </div>
            </form>
        </div>
        <div *ngIf="vm.isPaymentFeatureAvailable">
            <h2 class="card-header">
                {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.paymentHeader" | transloco }}
            </h2>

            <dtm-web-app-lib-payment-for-statement
                [totalCost]="vm.selectedPossiblePermissions | invoke : permissionCosts"
            ></dtm-web-app-lib-payment-for-statement>
        </div>
        <dtm-ui-info-message>
            {{
                (vm.isPaymentFeatureAvailable
                    ? "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.nextStepInfo"
                    : "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.nextStepInfoWithoutPayments"
                ) | transloco
            }}
        </dtm-ui-info-message>

        <button type="button" (click)="getStatementInPdf.emit()" class="button-secondary download-button">
            <dtm-ui-icon name="download"></dtm-ui-icon>
            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.summary.getStatementInPdfLabel" | transloco }}
        </button>
    </dtm-ui-wizard-step-wrapper>
</div>
