import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import {
    MissionPlanAnalysisIssueStatus,
    MissionPlanAnalysisOptionStatus,
    MissionPlanAnalysisStatus,
    MissionPlanData,
    MissionUtils,
} from "@dtm-frontend/shared/mission";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs/operators";
import { OperationalGeometryData, PublicMissionPlanData, SoraSettings } from "../../../../../models/mission.model";

interface MissionPlanAnalysisPanelComponentState {
    analysisStatus: MissionPlanAnalysisStatus | undefined;
    nearbyMissions: PublicMissionPlanData[];
    collisionMission: PublicMissionPlanData[];
    areOtherMissionsProcessing: boolean;
    collisionZones: AirspaceElementsInfo | undefined;
    selectedZoneId: string | undefined;
    soraSettings: SoraSettings | undefined;
    operationalGeometryData: OperationalGeometryData | undefined;
}

@Component({
    selector: "dtm-web-app-lib-mission-plan-analysis-panel[analysisStatus]",
    templateUrl: "./mission-plan-analysis-panel.component.html",
    styleUrls: ["./mission-plan-analysis-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionPlanAnalysisPanelComponent {
    @Input() public set analysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ analysisStatus: value });
    }
    @Input() public set nearbyMissions(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ nearbyMissions: value ?? [] });
    }
    @Input() public set collisionMission(value: PublicMissionPlanData[] | undefined) {
        this.localStore.patchState({ collisionMission: value ?? [] });
    }
    @Input() public set areOtherMissionsProcessing(value: BooleanInput) {
        this.localStore.patchState({ areOtherMissionsProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set collisionZones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ collisionZones: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set soraSettings(value: SoraSettings | undefined) {
        this.localStore.patchState({ soraSettings: value });
    }
    @Input() public set operationalGeometryData(value: OperationalGeometryData | undefined) {
        this.localStore.patchState({ operationalGeometryData: value });
    }

    @Output() public readonly flyToRoute = new EventEmitter<string>();
    @Output() public readonly flyToMainMissionRoute = new EventEmitter<void>();
    @Output() public readonly otherMissionSelected = new EventEmitter<MissionPlanData>();
    @Output() public readonly flyToZone = new EventEmitter<GeoJSON>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly MissionPlanAnalysisIssueStatus = MissionPlanAnalysisIssueStatus;
    protected readonly MissionPlanAnalysisOptionStatus = MissionPlanAnalysisOptionStatus;
    protected readonly analysisStatus$ = this.localStore.selectByKey("analysisStatus");
    protected readonly nearbyMissions$ = this.localStore.selectByKey("nearbyMissions");
    protected readonly collisionMission$ = this.localStore.selectByKey("collisionMission");
    protected readonly areOtherMissionsProcessing$ = this.localStore.selectByKey("areOtherMissionsProcessing");
    protected readonly zones$ = this.localStore.selectByKey("collisionZones").pipe(map(MissionUtils.partitionCollisionZones));
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly soraSettings$ = this.localStore.selectByKey("soraSettings");
    protected readonly operationalGeometryData$ = this.localStore.selectByKey("operationalGeometryData");

    constructor(private readonly localStore: LocalComponentStore<MissionPlanAnalysisPanelComponentState>) {
        this.localStore.setState({
            analysisStatus: undefined,
            nearbyMissions: [],
            collisionMission: [],
            areOtherMissionsProcessing: false,
            collisionZones: undefined,
            selectedZoneId: undefined,
            soraSettings: undefined,
            operationalGeometryData: undefined,
        });
    }
}
