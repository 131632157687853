import { HttpClient, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { FileUploadErrorType, FilesUploadApi } from "@dtm-frontend/shared/ui";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { EMPTY, Observable } from "rxjs";
import { OperatorContextState } from "../../shared/operator-context/state/operator-context.state";
import { OPERATOR_PERMITS_ENDPOINTS, OperatorPermitsEndpoints } from "../operator-permits.tokens";

@Injectable({
    providedIn: "root",
})
export class OperatorPermitLocationFileApiService implements FilesUploadApi {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly store: Store,
        @Inject(OPERATOR_PERMITS_ENDPOINTS)
        private readonly endpoints: OperatorPermitsEndpoints
    ) {}

    public uploadFile(file: File): Observable<HttpEvent<UploadedFileBody>> {
        console.error("Not implemented");

        return EMPTY;
    }

    public getFile(fileId: string): Observable<Blob> {
        const operatorId = this.store.selectSnapshot(OperatorContextState.selectedContextId);

        if (!operatorId) {
            return EMPTY;
        }

        return this.httpClient.get(
            StringUtils.replaceInTemplate(this.endpoints.downloadKmlLocationFile, {
                kmlFileId: fileId,
                operatorId,
            }),
            {
                responseType: "blob",
            }
        );
    }

    public getFilesCompressed() {
        console.error("Not implemented");

        return EMPTY;
    }

    public manageUploadError(error: HttpErrorResponse): { type: FileUploadErrorType } {
        return { type: FileUploadErrorType.Unknown };
    }
}
