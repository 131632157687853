<div
    *ngrxLet="{ collisionMissions: collisionMissions$, nearbyMissions: nearbyMissions$, isProcessing: isProcessing$ } as vm"
    [class.processing]="vm.isProcessing"
>
    <dtm-ui-expandable-panel [isExpanded]="isExpanded$ | ngrxPush" [hasHeaderSeparator]="hasHeaderSeparator$ | ngrxPush" #panel>
        <h2 headerSlot class="title" [ngSwitch]="true">
            <ng-container *ngIf="!vm.isProcessing">
                <dtm-ui-icon
                    name="information"
                    class="warning"
                    *ngSwitchCase="!!vm.nearbyMissions.length && !vm.collisionMissions.length"
                ></dtm-ui-icon>
                <dtm-ui-icon name="error-warning-fill" class="error" *ngSwitchCase="!!vm.collisionMissions.length"></dtm-ui-icon>
                <dtm-ui-icon
                    name="checkbox-circle-fill"
                    *ngSwitchCase="!vm.collisionMissions.length && !vm.nearbyMissions.length"
                ></dtm-ui-icon>
            </ng-container>
            {{ "dtmWebAppLibShared.mission.analysis.trafficIssuesTitle" | transloco }}
            <dtm-ui-loader [isShown]="vm.isProcessing" [diameter]="30" *ngIf="!panel.expanded"></dtm-ui-loader>
        </h2>
        <div class="content">
            <dtm-ui-loader-container [isShown]="vm.isProcessing && panel.expanded">
                <ng-container *ngIf="!vm.isProcessing">
                    <p class="label" *ngIf="vm.collisionMissions.length">
                        {{ "dtmWebAppLibShared.mission.analysis.traffic.flightsInConflictLabel" | transloco }}
                    </p>
                    <ng-container
                        *ngFor="let mission of vm.collisionMissions; let index = index"
                        [ngTemplateOutlet]="missionTileTemplate"
                        [ngTemplateOutletContext]="{ $implicit: mission, isConflicted: true, index: index + 1 }"
                    ></ng-container>
                    <p class="label" *ngIf="vm.nearbyMissions.length">
                        {{ "dtmWebAppLibShared.mission.analysis.traffic.otherFlightsLabel" | transloco }}
                    </p>
                    <ng-container
                        *ngFor="let mission of vm.nearbyMissions; let index = index"
                        [ngTemplateOutlet]="missionTileTemplate"
                        [ngTemplateOutletContext]="{ $implicit: mission, index: vm.collisionMissions.length + index + 1 }"
                    ></ng-container>

                    <div class="chip-container" *ngIf="!vm.collisionMissions?.length && !vm.nearbyMissions?.length">
                        <div class="chip success">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            <p>
                                {{ "dtmWebAppLibShared.mission.analysis.traffic.noOtherFlightsInAreaLabel" | transloco }}
                            </p>
                        </div>
                    </div>
                </ng-container>
            </dtm-ui-loader-container>
        </div>
    </dtm-ui-expandable-panel>
</div>

<ng-template #missionTileTemplate let-mission let-isConflicted="isConflicted" let-index="index">
    <div
        *ngrxLet="selectedOtherMission$ as selectedNearbyMission"
        class="mission-details"
        [class.conflicted]="isConflicted"
        (click)="toggleSelection(mission)"
        [class.selected]="mission.id === selectedNearbyMission?.id"
    >
        <div class="number">#{{ index }}</div>
        <div
            class="type"
            [matTooltip]="
                (mission.capabilities?.flightType === MissionType.VLOS ? 'dtmUi.missionType.vlosLabel' : 'dtmUi.missionType.bvlosLabel')
                    | transloco
            "
        >
            <dtm-ui-icon [name]="mission.capabilities?.flightType === MissionType.VLOS ? 'eye' : 'eye-off'"></dtm-ui-icon>
        </div>
        <div class="details">
            <p class="date">{{ mission.flightStartAtMin | localizeFriendlyDate }}</p>
            <p>
                {{ mission.flightStartAtMin | localizeDate : { timeStyle: "short" } }} -
                {{ mission.flightFinishAtMax | localizeDate : { timeStyle: "short" } }}, {{ mission.capabilities?.uavName }}
            </p>
        </div>
        <button type="button" class="button-icon zoom-button" (click)="toggleZoom($event, mission.route?.routeId)">
            <dtm-ui-icon name="road-map"></dtm-ui-icon>
        </button>
    </div>
</ng-template>
