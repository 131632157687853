<dtm-ui-expandable-panel
    [hasHeaderSeparator]="false"
    [class.disabled]="!vm.isEditorMode"
    [isDisabled]="!vm.isEditorMode"
    [isExpanded]="vm.isEditorMode"
    [formGroup]="justificationFormGroup"
    *ngrxLet="{ isEditorMode: isEditorMode$, planId: planId$, isPriorityEnabled: isPriorityEnabled$ } as vm"
>
    <div class="header" headerSlot>
        <dtm-ui-icon name="mail-send"></dtm-ui-icon>
        <h2 class="title">
            {{ "dtmWebAppLibMission.summaryStep.messageToManager.header" | transloco
            }}<dtm-ui-popover
                (click)="$event.stopPropagation()"
                [popoverText]="'dtmWebAppLibMission.summaryStep.messageToManager.headerTooltip' | transloco"
            ></dtm-ui-popover>
        </h2>
        <button
            type="button"
            class="button-secondary add-button"
            *ngIf="!vm.isEditorMode"
            (click)="$event.stopPropagation(); switchToEditorMode()"
        >
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmWebAppLibMission.summaryStep.messageToManager.addButtonLabel" | transloco }}
        </button>
    </div>
    <mat-slide-toggle
        labelPosition="before"
        class="spread allow-wrap priority-toggle"
        [formControl]="dtmPriorityFormControl"
        *ngIf="vm.isPriorityEnabled"
    >
        <p class="toggle-content">
            <span class="label">
                {{ "dtmWebAppLibMission.summaryStep.messageToManager.prioritySwitchLabel" | transloco
                }}<dtm-ui-popover
                    (click)="$event.preventDefault()"
                    [popoverText]="'dtmWebAppLibMission.summaryStep.messageToManager.prioritySwitchTooltip' | transloco"
                ></dtm-ui-popover
            ></span>
        </p>
    </mat-slide-toggle>
    <dtm-ui-textarea-field [maxLength]="MAX_MESSAGE_LENGTH" class="message-field">
        <label>{{ "dtmWebAppLibMission.summaryStep.messageToManager.enterMessageLabel" | transloco }}</label>
        <textarea matInput [formControl]="dtmReasonFormControl"></textarea>
        <div class="field-error" *dtmUiFieldHasError="dtmReasonFormControl; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibMission.summaryStep.messageToManager.messageMaxLengthError"
                    | transloco
                        : {
                              maxLength: error.requiredLength,
                              exceedValue: error.actualLength - error.requiredLength
                          }
            }}
        </div>
    </dtm-ui-textarea-field>

    <dtm-ui-files-upload-field
        class="attachments"
        [allowedTypes]="ALLOWED_FILES_EXTENSIONS"
        [formControl]="dtmAttachmentsFormControl"
        [isDownloadAllButtonVisible]="false"
        *ngIf="!dtmAttachmentsFormControl.disabled"
        [maxFileSize]="MAX_FILE_SIZE_IN_BYTES"
    >
        <label>{{ "dtmWebAppLibMission.summaryStep.messageToManager.attachmentsLabel" | transloco }}</label>
        <div class="field-hint">
            {{
                "dtmWebAppLibMission.summaryStep.messageToManager.attachmentInfoText"
                    | transloco : { size: MAX_FILE_SIZE_IN_BYTES | formatFileSize }
            }}
        </div>
    </dtm-ui-files-upload-field>
    <dtm-ui-uploaded-files-display
        *ngIf="dtmAttachmentsFormControl.disabled && dtmAttachmentsFormControl.value?.length"
        [uploadedFiles]="dtmAttachmentsFormControl.value"
        [additionalPathParams]="{ planId: vm.planId }"
    >
        <label>{{ "dtmWebAppLibMission.summaryStep.messageToManager.attachmentsLabel" | transloco }}</label>
    </dtm-ui-uploaded-files-display>
    <div class="actions" *ngIf="justificationFormGroupController.isEditing$ | ngrxPush">
        <button type="button" class="button-secondary" (click)="justificationFormGroupController.restore()">
            {{ "dtmWebAppLibMission.summaryStep.messageToManager.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="save()" [disabled]="!justificationFormGroup.valid">
            {{ "dtmWebAppLibMission.summaryStep.messageToManager.saveButtonLabel" | transloco }}
        </button>
    </div>
</dtm-ui-expandable-panel>
