import { ForeignOperatorVerificationDetails } from "../../shared/models";
import { PilotRegistrationFormValue } from "../services/models";

export namespace PilotRegistrationActions {
    export class GetPilotRegistrationCapabilities {
        public static readonly type = "[Pilot registration] Get pilot registration capabilities";

        constructor(public userId: string) {}
    }

    export class VerifyForeignOperator {
        public static readonly type = "[Pilot registration] Verify foreign operator";

        constructor(public foreignOperator: ForeignOperatorVerificationDetails) {}
    }

    export class RegisterPilot {
        public static readonly type = "[Pilot registration] Register pilot";

        constructor(public pilotRegistrationFormValue: PilotRegistrationFormValue) {}
    }
}
