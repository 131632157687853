import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { PopulationDensity } from "@dtm-frontend/shared/mission";
import { UploadedFile } from "@dtm-frontend/shared/ui";
import { AnimationUtils, BYTES_IN_MEGABYTE, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { M1AStatementsKeys, RobustnessLevel } from "../../../../../../../../shared";
import { MissionUAVSetup, RiskMitigationM1A } from "../../../../../../../models/mission.model";

const ALLOWED_FILE_EXTENSION = [".pdf", ".docx", ".doc", ".jpg", ".jpeg", ".png"];
const MAX_FILE_SIZE_MB = 20;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * BYTES_IN_MEGABYTE;
const MAX_COMMENT_LENGTH = 1000;

interface RiskMitigationThreshold {
    min: number;
    max: number;
}

interface RiskMitigationM1AComponentState {
    isFlightOverControlledArea: boolean;
    populationDensity: PopulationDensity | undefined;
    thresholds: RiskMitigationThreshold[];
    disabledRobustnessLevels: RobustnessLevel[];
    missionUavSetup: MissionUAVSetup | undefined;
}

const riskMitigationThresholds: RiskMitigationThreshold[] = [
    { min: 25001, max: 250000 },
    { min: 2501, max: 25000 },
    { min: 251, max: 2500 },
    { min: 26, max: 250 },
    { min: 0, max: 25 },
];

const DEFAULT_ROBUSTNESS_LEVELS = [RobustnessLevel.Low, RobustnessLevel.Medium, RobustnessLevel.High];

export const MAX_TAKE_OFF_MASS_FOR_BYSTANDERS_SHIELDED_STATEMENT = 25;
export const MAX_FLIGHT_SPEED_FOR_BYSTANDERS_SHIELDED_STATEMENT = 35;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-risk-mitigation-m1a[populationDensity]",
    templateUrl: "./risk-mitigation-m1-a.component.html",
    styleUrls: ["./risk-mitigation-m1-a.component.scss"],
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RiskMitigationM1AComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RiskMitigationM1AComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
})
export class RiskMitigationM1AComponent implements ControlValueAccessor, Validator {
    @Input() public set isFlightOverControlledArea(value: BooleanInput) {
        this.localStore.patchState({ isFlightOverControlledArea: coerceBooleanProperty(value) });
    }
    @Input() public set populationDensity(value: PopulationDensity | undefined) {
        const thresholds = this.getRiskMitigationThresholds(value);
        const disabledRobustnessLevels = DEFAULT_ROBUSTNESS_LEVELS.filter((_, index) => index >= thresholds.length);
        this.localStore.patchState({ populationDensity: value, thresholds, disabledRobustnessLevels });
    }
    @Input() public set missionUavSetup(value: MissionUAVSetup | undefined) {
        this.localStore.patchState({ missionUavSetup: value });
        this.setDisableLevelState(this.robustnessLevelFormControl.value);
    }

    protected readonly riskMitigationMeasureFormControl = new FormControl(false, { nonNullable: true });
    protected readonly robustnessLevelFormControl = new FormControl<RobustnessLevel | null>(null, {
        validators: this.requiredForControlValidator(this.riskMitigationMeasureFormControl),
    });

    protected readonly lowerPeopleDensityFormGroup = new FormGroup({
        [M1AStatementsKeys.lowerPeopleDensity]: new FormControl(false, { nonNullable: true }),
    });
    protected readonly lowerDensityReasonUserStatementFormControl = new FormControl<string | null>(null, [
        this.requiredForControlValidator(this.lowerPeopleDensityFormGroup.controls[M1AStatementsKeys.lowerPeopleDensity]),
        Validators.maxLength(MAX_COMMENT_LENGTH),
    ]);
    protected readonly lowerPeopleDensityAttachmentsFormControl = new FormControl<UploadedFile[] | undefined>(
        undefined,
        this.requiredForControlValues(Object.values(this.lowerPeopleDensityFormGroup.controls), this.robustnessLevelFormControl, [
            RobustnessLevel.Medium,
            RobustnessLevel.High,
        ])
    );

    protected readonly bystandersShieldedUserStatementsFormGroup = new FormGroup({
        [M1AStatementsKeys.bystandersShielded]: new FormControl(false, { nonNullable: true }),
    });
    protected readonly bystandersShieldedUserCommentFormControl = new FormControl<string | null>(null, [
        this.requiredForControlValidator(this.bystandersShieldedUserStatementsFormGroup.controls[M1AStatementsKeys.bystandersShielded]),
        Validators.maxLength(MAX_COMMENT_LENGTH),
    ]);
    protected readonly bystandersShieldedAttachmentsFormControl = new FormControl<UploadedFile[] | undefined>(
        undefined,
        this.requiredForControlValues(
            Object.values(this.bystandersShieldedUserStatementsFormGroup.controls),
            this.robustnessLevelFormControl,
            [RobustnessLevel.Medium, RobustnessLevel.High]
        )
    );

    protected readonly riskMitigationFormGroup = new FormGroup(
        {
            riskMitigationMeasure: this.riskMitigationMeasureFormControl,
            robustnessLevel: this.robustnessLevelFormControl,
            lowerDensityReasonUserStatements: this.lowerPeopleDensityFormGroup,
            lowerDensityReasonUserComments: this.lowerDensityReasonUserStatementFormControl,
            lowerPeopleDensityAttachments: this.lowerPeopleDensityAttachmentsFormControl,
            bystandersShieldedUserStatements: this.bystandersShieldedUserStatementsFormGroup,
            bystandersShieldedUserComment: this.bystandersShieldedUserCommentFormControl,
            bystandersShieldedAttachments: this.bystandersShieldedAttachmentsFormControl,
        },
        this.requiredOneForControlValidator(
            this.riskMitigationMeasureFormControl,
            this.lowerPeopleDensityFormGroup,
            this.bystandersShieldedUserStatementsFormGroup
        )
    );

    protected readonly RobustnessLevel = RobustnessLevel;
    protected readonly M1AStatementsKeys = M1AStatementsKeys;
    protected readonly ALLOWED_FILE_EXTENSION = ALLOWED_FILE_EXTENSION;
    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    protected readonly MAX_COMMENT_LENGTH = MAX_COMMENT_LENGTH;
    protected readonly DEFAULT_ROBUSTNESS_LEVELS = DEFAULT_ROBUSTNESS_LEVELS;

    protected readonly isFormDisabled$ = this.localStore.selectByKey("isFlightOverControlledArea");
    protected readonly populationDensity$ = this.localStore.selectByKey("populationDensity");
    protected readonly thresholds$ = this.localStore.selectByKey("thresholds");
    protected readonly disabledRobustnessLevels$ = this.localStore.selectByKey("disabledRobustnessLevels");

    private propagateTouch = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: RiskMitigationM1A | null) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<RiskMitigationM1AComponentState>) {
        this.localStore.setState({
            isFlightOverControlledArea: false,
            populationDensity: undefined,
            thresholds: [],
            disabledRobustnessLevels: [],
            missionUavSetup: undefined,
        });
        this.setupRiskMitigationChangesWatch();
        this.setDisableLevelState(this.robustnessLevelFormControl.value);

        this.robustnessLevelFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.setDisableLevelState(value);
        });
    }

    private setupRiskMitigationChangesWatch() {
        this.riskMitigationFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            if (!value.riskMitigationMeasure) {
                this.propagateChange(null);

                return;
            }

            Object.values(this.riskMitigationFormGroup.controls).forEach((control) => {
                control.updateValueAndValidity({ emitEvent: false });
            });

            if (this.riskMitigationFormGroup.invalid) {
                this.onValidationChange();

                return;
            }

            if (!value.riskMitigationMeasure || !value.robustnessLevel) {
                this.propagateChange(null);

                return;
            }

            const riskMitigationM1aValue: RiskMitigationM1A = {
                robustnessLevel: value.robustnessLevel,
            };

            const lowerDensityStatements =
                value.lowerDensityReasonUserStatements &&
                (Object.entries(value.lowerDensityReasonUserStatements)
                    .filter(([, isStated]) => isStated)
                    .map(([statementKey]) => statementKey) as M1AStatementsKeys[]);

            if (lowerDensityStatements) {
                riskMitigationM1aValue.lowerPeopleDensity = {
                    userStatements: lowerDensityStatements,
                    comment: value.lowerDensityReasonUserComments ?? undefined,
                    attachments: value.lowerPeopleDensityAttachments?.map(({ name, id }) => ({
                        name,
                        fileId: id,
                    })),
                };
            }

            const bystandersShieldedStatements =
                value.bystandersShieldedUserStatements &&
                (Object.entries(value.bystandersShieldedUserStatements)
                    .filter(([, isStated]) => isStated)
                    .map(([statementKey]) => statementKey) as M1AStatementsKeys[]);

            if (bystandersShieldedStatements) {
                riskMitigationM1aValue.bystandersShielded = {
                    userStatements: bystandersShieldedStatements,
                    comment: value.bystandersShieldedUserComment ?? undefined,
                    attachments: value.bystandersShieldedAttachments?.map(({ name, id }) => ({
                        name,
                        fileId: id,
                    })),
                };
            }

            this.propagateChange(riskMitigationM1aValue);
        });
    }

    private setDisableLevelState(robustnessLevel: RobustnessLevel | null): void {
        if (!robustnessLevel) {
            this.lowerPeopleDensityFormGroup.controls[M1AStatementsKeys.lowerPeopleDensity].disable({ emitEvent: false });
            this.bystandersShieldedUserStatementsFormGroup.controls[M1AStatementsKeys.bystandersShielded].disable({ emitEvent: false });

            return;
        }

        this.lowerPeopleDensityFormGroup.controls[M1AStatementsKeys.lowerPeopleDensity].enable({ emitEvent: false });
        this.bystandersShieldedUserStatementsFormGroup.controls[M1AStatementsKeys.bystandersShielded].enable({ emitEvent: false });
        this.checkAndDisableStatementsBasedOnUavSetup();
    }

    public writeValue(value: RiskMitigationM1A | null): void {
        if (!value) {
            this.riskMitigationFormGroup.reset(undefined, { emitEvent: false });

            return;
        }

        this.riskMitigationFormGroup.reset(
            {
                riskMitigationMeasure: true,
                bystandersShieldedUserStatements: value.bystandersShielded?.userStatements.reduce(
                    (statements, key) => ({ ...statements, [key]: true }),
                    {}
                ),
                lowerDensityReasonUserComments: value.lowerPeopleDensity?.comment ?? null,
                bystandersShieldedUserComment: value.bystandersShielded?.comment ?? null,
                lowerDensityReasonUserStatements: value.lowerPeopleDensity?.userStatements.reduce(
                    (statements, key) => ({ ...statements, [key]: true }),
                    {}
                ),
                bystandersShieldedAttachments: this.getAttachmentInfo(value, "bystandersShielded"),
                lowerPeopleDensityAttachments: this.getAttachmentInfo(value, "lowerPeopleDensity"),
                robustnessLevel: value.robustnessLevel,
            },
            { emitEvent: false }
        );

        this.setDisableLevelState(this.robustnessLevelFormControl.value);
    }

    public registerOnChange(fn: (value: RiskMitigationM1A | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (!this.riskMitigationMeasureFormControl.value || this.riskMitigationFormGroup.valid) {
            return null;
        }

        return {
            riskMitigationM1A: true,
        };
    }

    protected requiredForControlValidator(forControls: FormControl): ValidatorFn {
        return (control) => (forControls.value ? Validators.required(control) : null);
    }

    protected requiredOneForControlValidator(forControl: FormControl, ...formGroup: FormGroup[]): ValidatorFn {
        return () => {
            if (forControl.value && !formGroup.some((form) => Object.values(form.value).some(FunctionUtils.isTruthy))) {
                return {
                    oneRequired: true,
                };
            }

            return null;
        };
    }

    protected setRobustnessLevel(level: RobustnessLevel) {
        this.robustnessLevelFormControl.setValue(level);
        this.lowerPeopleDensityAttachmentsFormControl.updateValueAndValidity();
        this.bystandersShieldedAttachmentsFormControl.updateValueAndValidity();
    }

    protected requiredForControlValues<T>(forControls: FormControl[], forControlValue: FormControl<T>, values: T[]): ValidatorFn {
        return (control) =>
            forControls.map(({ value }) => value).some(FunctionUtils.isTruthy) && values.includes(forControlValue.value)
                ? Validators.required(control)
                : null;
    }

    private getAttachmentInfo(mitigationValue: RiskMitigationM1A, key: "bystandersShielded" | "lowerPeopleDensity"): UploadedFile[] {
        return (
            mitigationValue[key]?.attachments?.map((file) => ({
                name: file.name,
                id: file.fileId,
            })) ?? []
        );
    }

    private getRiskMitigationThresholds(value: PopulationDensity | undefined): RiskMitigationThreshold[] {
        const peoplePerSquareKilometerMax = value?.peoplePerSquareKilometerMax;
        const numberOfThresholds = 3;

        if (peoplePerSquareKilometerMax === undefined) {
            return [];
        }

        const thresholdIndex = riskMitigationThresholds.findIndex(
            ({ min, max }) => peoplePerSquareKilometerMax >= min && peoplePerSquareKilometerMax <= max
        );

        if (thresholdIndex === -1) {
            return riskMitigationThresholds.slice(0, numberOfThresholds);
        }

        return riskMitigationThresholds.slice(thresholdIndex + 1, thresholdIndex + 1 + numberOfThresholds);
    }

    private checkAndDisableStatementsBasedOnUavSetup() {
        const uavSetup = this.localStore.selectSnapshotByKey("missionUavSetup");
        if (!uavSetup) {
            return;
        }
        const bystandersShieldedUserStatements =
            this.bystandersShieldedUserStatementsFormGroup.controls[M1AStatementsKeys.bystandersShielded];

        if (
            uavSetup.technicalSpecification.takeOffMass >= MAX_TAKE_OFF_MASS_FOR_BYSTANDERS_SHIELDED_STATEMENT ||
            uavSetup.technicalSpecification.maxFlightSpeed >= MAX_FLIGHT_SPEED_FOR_BYSTANDERS_SHIELDED_STATEMENT
        ) {
            bystandersShieldedUserStatements.reset(false, { emitEvent: false });
            this.bystandersShieldedUserCommentFormControl.reset(undefined, { emitEvent: false });
            bystandersShieldedUserStatements.disable({ emitEvent: false });
        }
    }
}
