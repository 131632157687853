import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { GlobalOperatorPermissions } from "@dtm-frontend/shared/ui";
import { dtmOperatorPermissionGuard } from "../../guards/dtm-operator-permission.guard";
import { NewUserGuard } from "../../guards/new-user.guard";
import { PrepareForMissionComponent } from "./components/prepare-for-mission/prepare-for-mission.component";
import { TacticalMissionViewComponent } from "./components/tactical-mission-view/tactical-mission-view.component";
import { TacticalViewsComponent } from "./components/tactical-views/tactical-views.component";
import { tacticalAcceptedMissionListResolver, tacticalMissionResolver } from "./services/tactical.resolvers";

const routes: Routes = [
    {
        path: "tactical",
        component: TacticalViewsComponent,
        canActivate: [DtmRoleGuard, NewUserGuard, dtmOperatorPermissionGuard([GlobalOperatorPermissions.OperatorMissionsPilot])],
        data: {
            className: "no-padding",
        },
        children: [
            {
                path: "prepare-for-mission",
                component: PrepareForMissionComponent,
                resolve: {
                    init: tacticalAcceptedMissionListResolver,
                },
                data: {
                    titleTranslationKey: "dtmWebAppLibTactical.routeTitles.prepareForMission",
                },
            },
            {
                path: "mission/:missionId",
                component: TacticalMissionViewComponent,
                resolve: {
                    missionId: tacticalMissionResolver,
                },
                data: {
                    isDetailsView: true,
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TacticalRoutingModule {}
