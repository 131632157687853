<div class="dialog-header">
    <div mat-dialog-title>{{ "dtmWebAppLibOperatorPermits.permitPreview.kmlPreviewDialogTitle" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-loader [isShown]="isMapLoading$ | ngrxPush" [shouldSetParentPositionRelative]="true"></dtm-ui-loader>
    <div id="kmlPreviewContainer" class="map" [class.map-hidden]="isMapLoading$ | ngrxPush"></div>
</div>
