<ng-container *ngrxLet="isShown$ as isShown">
    <ng-container *ngrxLet="is3DEnabled$ as is3DEnabled">
        <ng-container *ngrxLet="is2DEnabled$ as is2DEnabled">
            <ac-layer acFor="let entity of flightAreaEntities$" [context]="this" #flightAreaEntitiesLayer [show]="isShown && is2DEnabled">
                <ac-polygon-desc
                    props="{
                        hierarchy: entity.positions,
                        material: ROUTES_VISUAL_DATA[entity.state].fill,
                        zIndex: entity.zIndex
                  }"
                >
                </ac-polygon-desc>
                <ac-polyline-desc
                    props="{
                        positions: entity.positions,
                      width: ROUTES_VISUAL_DATA[entity.state].outlineWidth,
                      material: ROUTES_VISUAL_DATA[entity.state].outlineMaterial,
                       zIndex: entity.zIndex
                    }"
                >
                </ac-polyline-desc>
            </ac-layer>
            <ac-layer acFor="let entity of flightSpaceEntities$" [context]="this" #flightSpaceEntitiesLayer [show]="isShown && is3DEnabled">
                <ac-polygon-desc
                    props="{
                        hierarchy: entity.positions,
                        outlineWidth: 2,
                        outline: true,
                        outlineColor: ROUTES_VISUAL_DATA[entity.state].outlineColor,
                        extrudedHeight: entity.ceiling,
                        material: ROUTES_VISUAL_DATA[entity.state].wallColor.withAlpha(0.5),
                        height: entity.floor,
                        fill: true,
                        zIndex: entity.zIndex
                   }"
                >
                </ac-polygon-desc>
            </ac-layer>
            <ng-container *ngrxLet="areLabelsVisible$ as areLabelVisible">
                <ac-layer acFor="let label of labels$" [context]="this" #labelViewer [show]="isShown">
                    <ac-html-desc
                        props="{
                           position: label.position,
                           show: true
                       }"
                    >
                        <ng-template let-label>
                            <ng-container *ngIf="isShown && areLabelVisible">
                                <svg width="100" height="100" viewBox="-50 -50 100 100" class="line-connector">
                                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="-50" />
                                    <circle r="4" cx="0" cy="0" />
                                </svg>
                                <div class="waypoint-label label-pin">
                                    {{ label.name }}
                                    <ng-container *ngIf="label.shouldShowHeight">
                                        {{
                                            "dtmWebAppLibMission.routeSelectorStep.pointAltitudeLabel"
                                                | transloco : { altitude: label.altitude, height: label.height }
                                        }}
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-template>
                    </ac-html-desc>
                </ac-layer>
                <ac-layer acFor="let label of segmentLabels$" [context]="this" #segmentLabelViewer [show]="isShown">
                    <ac-html-desc
                        props="{
                            position: label.position,
                            show: true
                        }"
                    >
                        <ng-template let-label>
                            <ng-container *ngIf="isShown && areLabelVisible">
                                <svg
                                    width="100"
                                    height="100"
                                    viewBox="-50 -50 100 100"
                                    class="segment line-connector"
                                    [class.zone-label]="label.isZoneLabel"
                                >
                                    <line [attr.x1]="0" [attr.y1]="0" [attr.x2]="0" [attr.y2]="0" />
                                </svg>
                                <div class="segment-label label-pin" [class.zone-label]="label.isZoneLabel">
                                    <div *ngIf="label.isZoneLabel" class="header">
                                        <dtm-ui-icon name="flight-takeoff" *ngIf="label.isTakeoff"></dtm-ui-icon>
                                        <dtm-ui-icon name="flight-land" *ngIf="!label.isTakeoff"></dtm-ui-icon>

                                        {{
                                            "dtmWebAppLibMission.routeSelectorStep.groundLevelAltitudeLabel"
                                                | transloco : { value: label.altitude }
                                        }}
                                    </div>
                                    <div class="content">
                                        <dtm-ui-icon name="time" *ngIf="label.isZoneLabel"></dtm-ui-icon>
                                        {{ label.fromTime | localizeDatePure : { timeStyle: "short" } }} -
                                        {{ label.toTime | localizeDatePure : { timeStyle: "short" } }}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-template>
                    </ac-html-desc>
                </ac-layer>
            </ng-container>
            <div class="controls" *ngIf="isShown">
                <mat-slide-toggle
                    labelPosition="before"
                    (change)="toggle3D($event.checked)"
                    [ngModel]="is3DEnabled"
                    [matTooltip]="'dtmWebAppLibMission.routeSelectorStep.3dModeTooltip' | transloco"
                    [matTooltipPosition]="'above'"
                >
                    {{ "dtmWebAppLibMission.routeSelectorStep.3dModeLabel" | transloco }}
                </mat-slide-toggle>
                <mat-slide-toggle labelPosition="before" (change)="toggle2D($event.checked)" [ngModel]="is2DEnabled">
                    {{ "dtmWebAppLibMission.routeSelectorStep.2dModeLabel" | transloco }}
                </mat-slide-toggle>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
