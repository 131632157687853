import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { SinglePermission } from "../../../../services/operator-permissions.models";

interface ScenarioItemComponentState {
    scenario: SinglePermission | undefined;
    isPaymentFeatureAvailable: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-scenario-item[scenario]",
    templateUrl: "./scenario-item.component.html",
    styleUrls: ["./scenario-item.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioItemComponent {
    @Input() public set scenario(value: SinglePermission | undefined) {
        this.localStore.patchState({ scenario: value });
    }

    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }

    protected readonly scenario$ = this.localStore.selectByKey("scenario").pipe(RxjsUtils.filterFalsy());
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");

    constructor(private readonly localStore: LocalComponentStore<ScenarioItemComponentState>) {
        localStore.setState({ scenario: undefined, isPaymentFeatureAvailable: false });
    }
}
