<div class="grid grid-maxsize-8 grid-maxsize-tablet-10">
    <div class="card-box" *ngrxLet="savedStatement$ as savedStatement">
        <div class="container">
            <div class="section" *ngIf="isPaymentFeatureAvailable$ | ngrxPush">
                <h4 class="section-header">
                    {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.header" | transloco }}
                </h4>

                <dtm-web-app-lib-payment-for-statement
                    *ngIf="
                        savedStatement.payment.status === StatementStatus.Error || savedStatement.payment.status === StatementStatus.NotPaid
                    "
                    [totalCost]="savedStatement.totalCost"
                ></dtm-web-app-lib-payment-for-statement>

                <ng-container [ngSwitch]="savedStatement.payment.status">
                    <div class="status-container success" *ngSwitchCase="StatementStatus.Completed">
                        <div class="status">
                            <dtm-ui-icon name="checkbox-circle-fill" class="success-icon"></dtm-ui-icon>
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.paymentSuccessLabel" | transloco }}
                        </div>
                    </div>
                    <ng-container *ngSwitchCase="StatementStatus.Waiting">
                        <div class="status-container waiting">
                            <div class="status">
                                <dtm-ui-icon name="time" class="warning-icon"></dtm-ui-icon>
                                {{
                                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.waitingForPaymentLabel"
                                        | transloco
                                }}
                            </div>

                            <button type="button" class="button-secondary" (click)="refresh.emit()">
                                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                                {{
                                    "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.refreshPaymentStatus"
                                        | transloco
                                }}
                            </button>
                        </div>
                        <p class="payment-subtext">
                            {{
                                "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.waitingForPaymentInfo" | transloco
                            }}
                        </p>
                    </ng-container>

                    <div class="status-container error" *ngSwitchDefault>
                        <div class="status">
                            <dtm-ui-icon name="close-circle-fill" class="error-icon"></dtm-ui-icon>
                            {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.paymentFailLabel" | transloco }}
                        </div>

                        <button type="button" class="button-secondary" (click)="paymentRepeat.emit()">
                            <dtm-ui-icon name="refresh" *ngIf="savedStatement.payment.status === StatementStatus.Error"></dtm-ui-icon>
                            {{
                                (savedStatement.payment.status === StatementStatus.Error
                                    ? "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.replayPaymentLabel"
                                    : "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.goToPaymentLabel"
                                ) | transloco
                            }}
                        </button>
                    </div>
                </ng-container>
            </div>
            <div class="section">
                <h4 class="section-header">
                    {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.permissionsHeader" | transloco }}
                </h4>
                <dtm-web-app-lib-operator-permission-signature-permission
                    *ngFor="let permission of savedStatement.operationScenarios"
                    [permission]="permission"
                >
                </dtm-web-app-lib-operator-permission-signature-permission>
            </div>
        </div>
        <footer class="actions">
            <button class="button-primary" type="button" (click)="goToSignAndSend()">
                {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignature.signAndSendLabel" | transloco }}
            </button>
        </footer>
    </div>
</div>
