import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { PansaUtmCredentials, PansaUtmLinkError, PansaUtmLinkErrorType } from "../../../services/pilot-profile.models";

interface EditPansaUtmLinkDialogData {
    isProcessing$: Observable<boolean>;
    error$: Observable<PansaUtmLinkError>;
}

interface LinkForm {
    login: FormControl<string>;
    password: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-pilot-profile-edit-pansa-utm-link-dialog",
    templateUrl: "./edit-pansa-utm-link-dialog.component.html",
    styleUrls: ["./edit-pansa-utm-link-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditPansaUtmLinkDialogComponent implements OnInit {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    private readonly newValueSubject = new Subject<PansaUtmCredentials>();
    public readonly newValue$ = this.newValueSubject.asObservable();
    protected readonly form = new FormGroup<LinkForm>({
        login: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
        password: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
    });
    protected readonly PansaUtmLinkErrorType = PansaUtmLinkErrorType;

    constructor(@Inject(MAT_DIALOG_DATA) protected readonly data: EditPansaUtmLinkDialogData) {}

    public ngOnInit() {
        this.data.error$
            .pipe(
                filter((error) => error?.type === PansaUtmLinkErrorType.WrongCredentials),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.form.controls.login.setErrors({
                    [PansaUtmLinkErrorType.WrongCredentials]: true,
                });
            });

        this.form.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe(() => {
            const control = this.form.controls.login;
            if (control.errors && control.errors[PansaUtmLinkErrorType.WrongCredentials]) {
                control.setErrors(null);
            }
        });
    }

    protected validateAndSave() {
        this.form.markAllAsTouched();
        if (this.form.invalid) {
            return;
        }

        this.newValueSubject.next(this.form.getRawValue());
    }
}
