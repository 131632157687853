<dtm-ui-expandable-panel
    [hasHeaderSeparator]="false"
    [isExpanded]="riskMitigationMeasureFormControl.value"
    [isDisabled]="true"
    [formGroup]="riskMitigationFormGroup"
    *ngrxLet="{
        criticalArea: criticalArea$,
        thresholds: thresholds$
    } as vm"
>
    <dtm-web-app-lib-mission-risk-mitigation-level-header
        headerSlot
        [isSelected]="riskMitigationMeasureFormControl.value"
        (isSelectedChange)="riskMitigationMeasureFormControl.setValue($event)"
    >
        <ng-container titleSlot>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.reducingEffectOfHittingGroundM2Label" | transloco }}
            <dtm-ui-popover
                (click)="$event.preventDefault()"
                [popoverText]="'dtmWebAppLibMission.summaryStep.analysis.soraSettings.reducingEffectOfHittingGroundM2Tooltip' | transloco"
            ></dtm-ui-popover>
        </ng-container>
    </dtm-web-app-lib-mission-risk-mitigation-level-header>

    <ng-container *ngIf="vm.criticalArea">
        <p class="sub-section-title">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.nominalCriticalAreaLabel" | transloco }}</p>
        <span
            class="sub-section-value"
            [innerHTML]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.nominalCriticalAreaValueUnit'
                    | transloco : { value: vm.criticalArea }
            "
        >
        </span>
    </ng-container>

    <div class="mitigation-settings" *ngIf="riskMitigationMeasureFormControl.value && vm.criticalArea" @slideIn>
        <dtm-web-app-lib-mission-risk-mitigation-levels
            [robustnessLevels]="DEFAULT_ROBUSTNESS_LEVELS"
            [robustnessLevelValue]="robustnessLevelFormControl.value"
            [robustnessLevelFirstPopupText]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m2StatementsTooltips.mediumMitigationLevelTooltip'
                    | transloco : { value: RobustnessLevel.Medium }
            "
            [robustnessLevelSecondPopupText]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m2StatementsTooltips.highMitigationLevelTooltip'
                    | transloco : { value: RobustnessLevel.High }
            "
            [robustnessLevelThirdPopupText]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m2StatementsTooltips.highPlusMitigationLevelTooltip'
                    | transloco : { value: RobustnessLevel.HighPlus }
            "
            [robustnessLevelFirstDescription]="
                vm.thresholds[0]
                    ? ('dtmWebAppLibMission.summaryStep.analysis.soraSettings.criticalAreaMitigationMeasureLevelDescription'
                      | transloco : { value: vm.thresholds[0].criticalArea })
                    : undefined
            "
            [robustnessLevelSecondDescription]="
                vm.thresholds[1]
                    ? ('dtmWebAppLibMission.summaryStep.analysis.soraSettings.criticalAreaMitigationMeasureLevelDescription'
                      | transloco : { value: vm.thresholds[1].criticalArea })
                    : undefined
            "
            [robustnessLevelThirdDescription]="
                vm.thresholds[2]
                    ? ('dtmWebAppLibMission.summaryStep.analysis.soraSettings.criticalAreaMitigationMeasureLevelDescription'
                      | transloco : { value: vm.thresholds[2].criticalArea })
                    : undefined
            "
            [disabledLevels]="vm.thresholds.length | invoke : getDisabledRobustnessLevels"
            (robustnessLevelSet)="robustnessLevelFormControl.setValue($event)"
        >
            <ng-container titleSlot>{{
                "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelLabel" | transloco
            }}</ng-container>
        </dtm-web-app-lib-mission-risk-mitigation-levels>

        <div class="section" [formGroup]="parachuteUserStatementsFormGroup">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap toggle-bar-center" [formControl]="isParachuteFormControl">
                <p class="section-title">
                    {{ M2StatementsKeys.parachute | transloco }}
                    <dtm-ui-popover
                        (click)="$event.preventDefault()"
                        [popoverText]="'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m2StatementsTooltips.parachute' | transloco"
                    ></dtm-ui-popover>
                </p>
            </mat-slide-toggle>
            <dtm-ui-form-errors>
                <div class="field-error" *ngIf="riskMitigationFormGroup.touched && riskMitigationFormGroup.errors?.oneRequired">
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}
                </div>
            </dtm-ui-form-errors>

            <div class="content" *ngIf="isParachuteFormControl.value">
                <div class="statement">
                    <dtm-ui-checkbox-field
                        [formControl]="parachuteUserStatementsFormGroup.controls[M2StatementsKeys.operatorHasDevelopedProcedures]"
                        required
                        class="with-validation"
                    >
                        {{ M2StatementsKeys.operatorHasDevelopedProcedures | transloco }}
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <p
                            *dtmUiFieldHasError="
                                parachuteUserStatementsFormGroup.controls[M2StatementsKeys.operatorHasDevelopedProcedures];
                                name: 'required'
                            "
                        >
                            {{ "dtmWebAppLibMission.requiredCheckedFieldErrorHint" | transloco }}
                        </p>
                    </dtm-ui-form-errors>
                </div>
                <div class="statement">
                    <dtm-ui-checkbox-field
                        [formControl]="
                            parachuteUserStatementsFormGroup.controls[M2StatementsKeys.operatorProvidesPracticalAndTheoreticalStaffTraining]
                        "
                        required
                        class="with-validation"
                    >
                        {{ M2StatementsKeys.operatorProvidesPracticalAndTheoreticalStaffTraining | transloco }}
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <p
                            *dtmUiFieldHasError="
                                parachuteUserStatementsFormGroup.controls[
                                    M2StatementsKeys.operatorProvidesPracticalAndTheoreticalStaffTraining
                                ];
                                name: 'required'
                            "
                        >
                            {{ "dtmWebAppLibMission.requiredCheckedFieldErrorHint" | transloco }}
                        </p>
                    </dtm-ui-form-errors>
                </div>
                <div class="statement">
                    <dtm-ui-checkbox-field
                        [formControl]="parachuteUserStatementsFormGroup.controls[M2StatementsKeys.parachuteOperatingInstructionsIsAttached]"
                        required
                        class="with-validation"
                    >
                        {{ M2StatementsKeys.parachuteOperatingInstructionsIsAttached | transloco }}
                    </dtm-ui-checkbox-field>
                    <dtm-ui-form-errors class="preserve-error-space">
                        <p
                            *dtmUiFieldHasError="
                                parachuteUserStatementsFormGroup.controls[M2StatementsKeys.parachuteOperatingInstructionsIsAttached];
                                name: 'required'
                            "
                        >
                            {{ "dtmWebAppLibMission.requiredCheckedFieldErrorHint" | transloco }}
                        </p>
                    </dtm-ui-form-errors>
                </div>

                <dtm-ui-files-upload-field
                    class="attachment"
                    [formControl]="parachuteAttachmentFormControl"
                    [allowedTypes]="ALLOWED_FILE_EXTENSION"
                    [maxFileSize]="MAX_FILE_SIZE_BYTES"
                    [isMultiple]="false"
                >
                    <label>
                        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentLabel" | transloco }}
                        <span class="required">*</span>
                    </label>
                    <div class="field-hint">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentInfoText"
                                | transloco : { size: MAX_FILE_SIZE_BYTES | formatFileSize, format: ALLOWED_FILE_EXTENSION | join }
                        }}
                    </div>
                </dtm-ui-files-upload-field>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="parachuteAttachmentFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredAttachmentErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="parachuteAttachmentFormControl; name: 'maxlength'; error as error">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.hintSingleFileLabel"
                                | transloco : { value: MAX_FILES_LENGTH }
                        }}
                    </div>
                </dtm-ui-form-errors>
            </div>
        </div>

        <div class="section">
            <mat-slide-toggle
                labelPosition="before"
                class="spread allow-wrap toggle-bar-center"
                [formControl]="isCriticalAreaShieldedFormControl"
            >
                <p class="section-title">
                    {{ M2StatementsKeys.criticalAreaShielded | transloco }}
                    <dtm-ui-popover
                        (click)="$event.preventDefault()"
                        [popoverText]="
                            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m2StatementsTooltips.criticalAreaShielded' | transloco
                        "
                    ></dtm-ui-popover>
                </p>
            </mat-slide-toggle>
            <dtm-ui-form-errors>
                <div class="field-error" *ngIf="riskMitigationFormGroup.touched && riskMitigationFormGroup.errors?.oneRequired">
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}
                </div>
            </dtm-ui-form-errors>
            <dtm-ui-form-errors>
                <div class="field-error" *dtmUiFieldHasError="riskMitigationFormGroup; name: 'maxlength'; error as error">
                    {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                </div>
            </dtm-ui-form-errors>

            <div class="content" *ngIf="isCriticalAreaShieldedFormControl.value">
                <div class="suggested-calculation">
                    <p class="field-label">
                        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.suggestedCalculationTool" | transloco }}:&nbsp;
                    </p>
                    <a class="link" target="_blank" rel="noopener noreferrer" [href]="OBSTACLES_LINK">{{ OBSTACLES_LINK }}</a>
                </div>
                <dtm-ui-files-upload-field
                    class="attachment"
                    [formControl]="criticalAreaShieldedAttachmentFormControl"
                    [allowedTypes]="ALLOWED_FILE_EXTENSION"
                    [maxFileSize]="MAX_FILE_SIZE_BYTES"
                    [isMultiple]="false"
                >
                    <label>
                        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentLabel" | transloco }}
                        <span class="required">*</span>
                    </label>
                    <div class="field-hint">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentInfoText"
                                | transloco : { size: MAX_FILE_SIZE_BYTES | formatFileSize, format: ALLOWED_FILE_EXTENSION | join }
                        }}
                    </div>
                </dtm-ui-files-upload-field>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="criticalAreaShieldedAttachmentFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredAttachmentErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
                <dtm-ui-form-errors>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="criticalAreaShieldedAttachmentFormControl; name: 'maxlength'; error as error"
                    >
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.hintSingleFileLabel"
                                | transloco : { value: MAX_FILES_LENGTH }
                        }}
                    </div>
                </dtm-ui-form-errors>
            </div>
        </div>

        <div class="section">
            <mat-slide-toggle labelPosition="before" class="spread allow-wrap toggle-bar-center" [formControl]="isOtherFormControl">
                <p class="section-title">
                    {{ M2StatementsKeys.other | transloco }}
                    <dtm-ui-popover
                        (click)="$event.preventDefault()"
                        [popoverText]="'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m2StatementsTooltips.other' | transloco"
                    ></dtm-ui-popover>
                </p>
            </mat-slide-toggle>
            <dtm-ui-form-errors>
                <div class="field-error" *ngIf="riskMitigationFormGroup.touched && riskMitigationFormGroup.errors?.oneRequired">
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}
                </div>
            </dtm-ui-form-errors>

            <div class="content" *ngIf="isOtherFormControl.value">
                <dtm-ui-textarea-field class="comment" [maxLength]="MAX_COMMENT_LENGTH">
                    <label>{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.mitigationDescriptionLabel" | transloco }}</label>
                    <textarea matInput [rows]="4" [formControl]="otherComment"></textarea>
                    <p class="field-error" *dtmUiFieldHasError="otherComment; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </p>
                    <div class="field-error" *dtmUiFieldHasError="otherComment; name: 'maxlength'; error as error">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.commentMaxLengthError"
                                | transloco
                                    : {
                                          maxLength: error.requiredLength,
                                          exceedValue: error.actualLength - error.requiredLength
                                      }
                        }}
                    </div>
                </dtm-ui-textarea-field>
                <dtm-ui-files-upload-field
                    class="attachment"
                    [formControl]="otherAttachment"
                    [allowedTypes]="ALLOWED_FILE_EXTENSION"
                    [maxFileSize]="MAX_FILE_SIZE_BYTES"
                    [isMultiple]="false"
                >
                    <label>
                        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentLabel" | transloco }}
                        <span class="required">*</span>
                    </label>
                    <div class="field-hint">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentInfoText"
                                | transloco : { size: MAX_FILE_SIZE_BYTES | formatFileSize, format: ALLOWED_FILE_EXTENSION | join }
                        }}
                    </div>
                </dtm-ui-files-upload-field>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="otherAttachment; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredAttachmentErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="otherAttachment; name: 'maxlength'; error as error">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.hintSingleFileLabel"
                                | transloco : { value: MAX_FILES_LENGTH }
                        }}
                    </div>
                </dtm-ui-form-errors>
            </div>
        </div>
    </div>
</dtm-ui-expandable-panel>
