<ng-container *ngrxLet="filtersCount$ as filtersCount">
    <dtm-ui-filters-container [filtersCount]="filtersCount" class="filters-container">
        <div class="filters-content">
            <dtm-ui-select-field [multiple]="true" [formControl]="paymentStatusControl" *ngIf="isPaymentFeatureAvailable$ | ngrxPush">
                <label>{{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatusLabel" | transloco }}</label>
                <dtm-ui-select-option [value]="StatementStatus.Error">
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus" | transloco : { value: StatementStatus.Error } }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="StatementStatus.Completed">
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus" | transloco : { value: StatementStatus.Completed } }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="StatementStatus.Waiting">
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus" | transloco : { value: StatementStatus.Waiting } }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="StatementStatus.NotPaid">
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.paymentStatus" | transloco : { value: StatementStatus.NotPaid } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <dtm-ui-select-field [multiple]="true" [formControl]="signStatusControl">
                <label>{{ "dtmWebAppLibOperatorPermissions.statementsList.signatureStatusLabel" | transloco }}</label>
                <dtm-ui-select-option [value]="StatementStatus.Error">
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus" | transloco : { value: StatementStatus.Error } }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="StatementStatus.Completed">
                    {{
                        "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus" | transloco : { value: StatementStatus.Completed }
                    }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="StatementStatus.Waiting">
                    {{ "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus" | transloco : { value: StatementStatus.Waiting } }}
                </dtm-ui-select-option>
                <dtm-ui-select-option [value]="StatementStatus.NotSigned">
                    {{
                        "dtmWebAppLibOperatorPermissions.statementsList.signatureStatus" | transloco : { value: StatementStatus.NotSigned }
                    }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
        </div>
    </dtm-ui-filters-container>
    <dtm-ui-filter-chips-display
        *ngIf="filtersCount"
        class="filters-chips"
        [filtersMap]="FILTERS_MAP"
        [filtersCount]="filtersCount"
        [formGroup]="filtersFormGroup"
        [filtersValue]="filtersFormGroup.value"
        (allFiltersReset)="clearFilters()"
    >
    </dtm-ui-filter-chips-display>
</ng-container>
