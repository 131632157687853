import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CameraHelperService, CylinderEntity, MapEntity, MapEntityType, MapUtils } from "@dtm-frontend/shared/map/cesium";
import { GeographicCoordinatesDirection, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore, ObjectUtils, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { ItineraryContent, ItineraryContentEntity } from "../../../../../../../models/itinerary.model";
import { MissionPlanItineraryConstraints } from "../../../../../../../models/mission.model";
import { AssistedItineraryDataEntity } from "../../../../../../../services/itinerary.service";
import { AssistedEntityId } from "../../../../../../../services/mission-api.converters";

interface AssistedEditorTrackCardComponentState {
    constraints: MissionPlanItineraryConstraints | undefined;
    itineraryContent: ItineraryContent;
    isTakeOffAdded: boolean;
    isLandingAdded: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-assisted-editor-itinerary-card",
    templateUrl: "./assisted-editor-itinerary-card.component.html",
    styleUrls: ["./assisted-editor-itinerary-card.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssistedEditorItineraryCardComponent {
    @Input() public set constraints(value: MissionPlanItineraryConstraints | undefined) {
        this.localStore.patchState({ constraints: value });
    }
    @Input() public set itineraryContent(value: ItineraryContent | undefined) {
        this.localStore.patchState({
            itineraryContent: value ?? [],
            isTakeOffAdded: !!value?.find(({ id }) => id === AssistedEntityId.Takeoff),
            isLandingAdded: !!value?.find(({ id }) => id === AssistedEntityId.Landing),
        });
    }

    @Output() public entityUpdate = new EventEmitter<ItineraryContentEntity>();
    @Output() public entityAdd = new EventEmitter<AssistedItineraryDataEntity>();
    @Output() public zoomToEntity = new EventEmitter<MapEntity>();

    protected readonly constraints$ = this.localStore.selectByKey("constraints");
    protected readonly isTakeOffAdded$ = this.localStore.selectByKey("isTakeOffAdded");
    protected readonly isLandingAdded$ = this.localStore.selectByKey("isLandingAdded");

    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly GeographicCoordinatesDirection = GeographicCoordinatesDirection;

    protected readonly landingRadiusControl = new FormControl<number | null>(null);
    protected readonly takeoffRadiusControl = new FormControl<number | null>(null);

    protected readonly AssistedEntityId = AssistedEntityId;

    private cameraRestoreState: ReturnType<CameraHelperService["getCameraRestoreState"]>;

    protected readonly landingFormGroup = new FormGroup({
        latitude: new FormControl<number | null>(null, Validators.required),
        longitude: new FormControl<number | null>(null, Validators.required),
        radius: this.landingRadiusControl,
    });

    protected readonly takeoffFormGroup = new FormGroup({
        latitude: new FormControl<number | null>(null, Validators.required),
        longitude: new FormControl<number | null>(null, Validators.required),
        radius: this.takeoffRadiusControl,
    });

    constructor(
        private readonly localStore: LocalComponentStore<AssistedEditorTrackCardComponentState>,
        private readonly cameraHelperService: CameraHelperService
    ) {
        localStore.setState({ constraints: undefined, itineraryContent: [], isTakeOffAdded: false, isLandingAdded: false });

        this.setupFormsFromConstraints();
        this.createChangesListener(this.takeoffFormGroup, AssistedEntityId.Takeoff);
        this.createChangesListener(this.landingFormGroup, AssistedEntityId.Landing);

        this.localStore
            .selectByKey("itineraryContent")
            // NOTE: debounceTime(0) prevents overwriting form values when multiple entities are updated sequentially
            .pipe(debounceTime(0), untilDestroyed(this))
            .subscribe((itineraryContent) => {
                this.updateExistingEntityFromItinerary(AssistedEntityId.Takeoff, this.takeoffFormGroup, itineraryContent);
                this.updateExistingEntityFromItinerary(AssistedEntityId.Landing, this.landingFormGroup, itineraryContent);
            });
    }

    protected toggleZoom(entityId: AssistedEntityId) {
        const entity = this.localStore.selectSnapshotByKey("itineraryContent").find((mapEntity) => mapEntity.id === entityId);

        if (this.cameraRestoreState?.id === entityId) {
            this.cameraRestoreState.restore();
            this.cameraRestoreState = undefined;

            return;
        }

        if (entity) {
            this.cameraRestoreState = this.cameraHelperService.getCameraRestoreState(entityId);
            this.zoomToEntity.emit(entity);
        }
    }

    private createChangesListener(formGroup: FormGroup, entityId: AssistedEntityId) {
        formGroup.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                filter(
                    ({ longitude, latitude, radius }) =>
                        !FunctionUtils.isNullOrUndefined(longitude) &&
                        !FunctionUtils.isNullOrUndefined(latitude) &&
                        !FunctionUtils.isNullOrUndefined(radius)
                ),
                untilDestroyed(this)
            )
            .subscribe(({ longitude, latitude, radius }) => {
                const constraints = ObjectUtils.cloneDeep(this.localStore.selectSnapshotByKey("constraints"));
                const itineraryContent = this.localStore.selectSnapshotByKey("itineraryContent");

                if (formGroup.invalid || !constraints) {
                    return;
                }

                let existingEntity = itineraryContent.find(({ id }) => id === entityId) as CylinderEntity;

                if (existingEntity) {
                    existingEntity = { ...existingEntity };

                    const cylinderCenter = MapUtils.convertSerializableCartographicToCartesian3({
                        height: 0,
                        latitude,
                        longitude,
                    });

                    existingEntity.radius = radius;
                    existingEntity.center = cylinderCenter;

                    this.entityUpdate.emit(existingEntity);

                    return;
                }

                constraints.min.size = constraints.min.runwayHorizontalNavigationAccuracy;
                constraints.min.height = constraints.default.height;
                constraints.max.height = constraints.default.height;
                constraints.default.size = radius;

                this.entityAdd.emit({
                    type: MapEntityType.Cylinder,
                    entityId,
                    centerLatitude: latitude,
                    centerLongitude: longitude,
                    constraints,
                });
            });
    }

    private updateExistingEntityFromItinerary(entityId: AssistedEntityId, formGroup: FormGroup, itineraryContent?: ItineraryContent) {
        const existingEntity = itineraryContent?.find(({ id }) => id === entityId) as CylinderEntity;

        if (existingEntity) {
            const { radius, center } = existingEntity;
            const { latitude, longitude } = MapUtils.convertCartesian3ToSerializableCartographic(center);
            formGroup.setValue({ radius: Math.round(radius), latitude, longitude }, { emitEvent: false });
            Object.values(formGroup.controls).forEach((control) => control.updateValueAndValidity({ onlySelf: true }));

            return;
        }

        const constraints = this.localStore.selectSnapshotByKey("constraints");

        formGroup.reset({ radius: constraints?.default.horizontalNavigationAccuracy });
    }

    private setupFormsFromConstraints() {
        this.localStore
            .selectByKey("constraints")
            .pipe(
                RxjsUtils.filterFalsy(),
                distinctUntilChanged(
                    (previous, current) =>
                        previous.min.runwayHorizontalNavigationAccuracy === current.min.runwayHorizontalNavigationAccuracy
                ),
                untilDestroyed(this)
            )
            .subscribe((constraints) => {
                this.takeoffRadiusControl.setValidators([Validators.min(constraints?.min.runwayHorizontalNavigationAccuracy ?? 0)]);
                this.landingRadiusControl.setValidators([Validators.min(constraints?.min.runwayHorizontalNavigationAccuracy ?? 0)]);

                const takeoffRadius =
                    constraints.min.runwayHorizontalNavigationAccuracy > (this.takeoffRadiusControl.value ?? 0)
                        ? constraints.min.runwayHorizontalNavigationAccuracy
                        : this.takeoffRadiusControl.value;
                const landingRadius =
                    constraints.min.runwayHorizontalNavigationAccuracy > (this.landingRadiusControl.value ?? 0)
                        ? constraints.min.runwayHorizontalNavigationAccuracy
                        : this.landingRadiusControl.value;

                this.takeoffRadiusControl.setValue(takeoffRadius, { emitEvent: false });
                this.landingRadiusControl.setValue(landingRadius, { emitEvent: false });

                this.takeoffRadiusControl.updateValueAndValidity();
                this.landingRadiusControl.updateValueAndValidity();
            });
    }
}
