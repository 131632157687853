<dtm-ui-wizard-step-wrapper
    [stepNumber]="3"
    [stepsAmount]="4"
    [title]="'dtmWebAppLibSpecPermApp.statementsStep.header' | transloco : { sail: vm.sail | sailLevel }"
    [isSaveDraftProcessing]="isSaveDraftProcessing$ | ngrxPush"
    [nextButtonLabel]="'dtmWebAppLibSpecPermApp.nextButtonLabel' | transloco"
    (next)="goToNextStep()"
    (saveDraft)="saveDataToDraft()"
    (back)="back.emit()"
    dtmUiInvalidFormScrollable
    *ngrxLet="{
        sail: sail$,
        operationsManualChapterNumbers: operationsManualChapterNumbers$,
        osoStatementGroups: osoStatementGroups$
    } as vm"
>
    <div class="no-statements" *ngIf="!vm.osoStatementGroups.length">
        {{ "dtmWebAppLibSpecPermApp.statementsStep.noStatementsLabel" | transloco }}
    </div>
    <dtm-ui-expandable-panel *ngFor="let group of vm.osoStatementGroups" [isExpanded]="true" [hasHeaderSeparator]="false">
        <div headerSlot class="statement-header">
            <div class="oso-list">
                <ng-container *ngFor="let osoNumber of group.osoNumbers; let isLast = last">
                    {{ ("dtmWebAppLibSpecPermApp.statementsStep.osoHeader" | transloco : { number: osoNumber }) + (isLast ? "." : ", ") }}
                </ng-container>
                {{ "dtmWebAppLibSpecPermApp.statementsStep.robustnessLabel" | transloco : { value: group.robustnessLevel } }}
            </div>
            <h2 class="title">{{ group.titleKey | transloco }}</h2>
            <div class="description" *ngIf="group.descriptionKey">{{ group.descriptionKey | transloco }}</div>
        </div>
        <ul class="criteria-groups">
            <li *ngFor="let criteriaGroup of group.criteriaGroups">
                <h3 class="criteria-group-title">{{ criteriaGroup.titleKey | transloco }}</h3>
                <ul class="criteria">
                    <li *ngFor="let criterion of criteriaGroup.criteria">
                        <div
                            *ngIf="criterion.type === OsoStatementCriterionItemType.Annotation"
                            class="criterion-annotation styled-content"
                            [innerHtml]="criterion.contentHtmlKey | transloco : vm.operationsManualChapterNumbers"
                        ></div>
                        <div *ngIf="criterion.type !== OsoStatementCriterionItemType.Annotation" class="feature-item">
                            <dtm-ui-icon
                                [name]="criterion.type === OsoStatementCriterionItemType.Note ? 'error-warning' : 'check'"
                            ></dtm-ui-icon>
                            <div class="feature-value">
                                <div class="styled-content">
                                    <ng-container *ngFor="let contentItem of criterion.content">
                                        <div class="content-part" *ngIf="contentItem.content" [innerHtml]="contentItem.content"></div>
                                        <dtm-ui-popover *ngIf="contentItem.comment">
                                            <div class="styled-content comment" [innerHtml]="contentItem.comment"></div>
                                        </dtm-ui-popover>
                                    </ng-container>
                                </div>
                                <div
                                    class="criterion-annotation styled-content"
                                    *ngIf="criterion.annotationHtmlKey"
                                    [innerHtml]="criterion.annotationHtmlKey | transloco : vm.operationsManualChapterNumbers"
                                ></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </dtm-ui-expandable-panel>
    <ng-container *ngIf="vm.osoStatementGroups.length">
        <dtm-ui-checkbox-field [formControl]="statementsForm.controls.isStatementChecked" required>
            <span class="label">{{ "dtmWebAppLibSpecPermApp.statementsStep.statementLabel" | transloco }}</span>
        </dtm-ui-checkbox-field>
        <dtm-ui-form-errors class="preserve-error-space">
            <div class="field-error" *dtmUiFieldHasError="statementsForm.controls.isStatementChecked; name: 'required'">
                {{ "dtmWebAppLibSpecPermApp.requiredValueError" | transloco }}
            </div>
        </dtm-ui-form-errors>
    </ng-container>
</dtm-ui-wizard-step-wrapper>
