<div *ngrxLet="scenario$ as scenario" class="scenario">
    <p class="category-name">{{ scenario.name }}</p>
    <span class="chip light">
        <dtm-ui-icon name="calendar-event"></dtm-ui-icon>
        {{
            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.gainPermissions.singlePermission.periodOfValidityInMonthsValue"
                | transloco : { monthsAmount: scenario.grantValidityInMonths }
        }}
    </span>
    <span class="chip light" *ngIf="isPaymentFeatureAvailable$ | ngrxPush">
        <dtm-ui-icon name="coins"></dtm-ui-icon>
        {{ scenario.price | translocoCurrency }}
    </span>
</div>

<div class="content">
    <ng-content></ng-content>
</div>
