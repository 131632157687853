<div class="form-section">
    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianFirstNameLabel" | transloco }}
        </label>
        <input matInput [formControl]="legalGuardianFirstNameControl" />
        <div class="field-error" *dtmUiFieldHasError="legalGuardianFirstNameControl; name: ['required', 'pattern']">
            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="legalGuardianFirstNameControl; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianFirstNameLengthError"
                    | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>

    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianLastNameLabel" | transloco }}
        </label>
        <input matInput [formControl]="legalGuardianLastNameControl" />
        <div class="field-error" *dtmUiFieldHasError="legalGuardianLastNameControl; name: ['required', 'pattern']">
            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="legalGuardianLastNameControl; name: 'maxlength'; error as error">
            {{
                "dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianLastNameLengthError"
                    | transloco : { max: error.requiredLength }
            }}
        </div>
    </dtm-ui-input-field>

    <dtm-ui-input-field>
        <label>
            {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianEmailLabel" | transloco }}
        </label>
        <input matInput [formControl]="legalGuardianEmailControl" />
        <div class="field-error" *dtmUiFieldHasError="legalGuardianEmailControl; name: 'required'">
            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="legalGuardianEmailControl; name: 'email'">
            {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.emailError" | transloco }}
        </div>
    </dtm-ui-input-field>
</div>
