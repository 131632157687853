<ng-container *ngrxLet="{ uav: uav$, isShortMode: isShortMode$ } as vm">
    <div class="image">
        <img *ngIf="vm.uav.imageUrl" [dtmUiImgSecuredSrc]="vm.uav.imageUrl" [alt]="vm.uav.manufacturerName + ' ' + vm.uav.modelName" />
        <img *ngIf="!vm.uav.imageUrl" src="assets/images/uav-no-photo.svg" alt="" />
    </div>
    <div class="name">{{ vm.uav.name }}</div>
    <div class="chips-list">
        <span *ngIf="vm.uav.isCustom" class="chip light">
            <dtm-ui-icon name="pencil-ruler"></dtm-ui-icon>
            {{ "dtmWebAppLibUav.uavInfo.customUavLabel" | transloco }}
        </span>
        <span *ngIf="vm.uav.isSwarm" class="chip light">
            <dtm-ui-icon name="drone-swarm"></dtm-ui-icon>
            {{ "dtmWebAppLibUav.uavInfo.swarmLabel" | transloco : { amount: vm.uav.serialNumbers.length } }}
        </span>
    </div>
    <div class="actions" *ngIf="!vm.isShortMode">
        <button [matMenuTriggerFor]="uavMenu" class="button-icon" type="button" *ngIf="isActionsButtonVisible$ | ngrxPush">
            <dtm-ui-icon name="more"></dtm-ui-icon>
        </button>
        <mat-menu #uavMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="edit.emit()" type="button" *ngIf="edit.observed">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibUav.uavInfo.uavActions.editUav" | transloco }}</span>
            </button>
            <button
                *ngIf="!vm.uav.isShared && (canShareUav$ | ngrxPush) && share.observed"
                mat-menu-item
                (click)="share.emit()"
                type="button"
            >
                <dtm-ui-icon name="share"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibUav.uavInfo.uavActions.share" | transloco }}</span>
            </button>
            <button mat-menu-item (click)="tryToRemoveUav()" type="button" *ngIf="remove.observed">
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibUav.uavInfo.uavActions.delete" | transloco }}</span>
            </button>
        </mat-menu>
    </div>
    <div class="info" *ngIf="!vm.isShortMode">
        <dtm-ui-label-value
            class="overflow-wrap"
            [label]="'dtmWebAppLibUav.uavInfo.manufacturerAndModelLabel' | transloco"
            [value]="vm.uav.manufacturerName + ' ' + vm.uav.modelName"
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppLibUav.uavInfo.uavTypeLabel' | transloco"
            [value]="'dtmWebAppLibUav.uavTypeValue' | transloco : { value: vm.uav.modelType }"
        ></dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmWebAppLibUav.uavInfo.ceCompliantLabel' | transloco">
            <div class="value-with-icon">
                <dtm-ui-icon [name]="vm.uav.isCeCompliant ? 'checkbox-circle' : 'close-circle'"></dtm-ui-icon>
                {{ "dtmWebAppLibUav.uavInfo.booleanValue" | transloco : { value: vm.uav.isCeCompliant } }}
            </div>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            class="overflow-wrap"
            *ngIf="!vm.uav.isSwarm"
            [label]="'dtmWebAppLibUav.uavInfo.serialNumberLabel' | transloco"
            [value]="vm.uav.serialNumbers[0]"
            [canCopy]="true"
        ></dtm-ui-label-value>
        <dtm-ui-label-value *ngIf="vm.uav.isSwarm" [label]="'dtmWebAppLibUav.uavInfo.serialNumbersLabel' | transloco">
            <a class="serial-numbers-button" (click)="showSerialNumbers(vm.uav)">
                {{ "dtmWebAppLibUav.uavInfo.showSerialNumbersButtonLabel" | transloco }}
            </a>
        </dtm-ui-label-value>
        <dtm-ui-label-value
            *ngIf="vm.uav.isShared"
            [label]="'dtmWebAppLibUav.uavInfo.ownerLabel' | transloco"
            [value]="vm.uav.ownerName"
        ></dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmWebAppLibUav.uavInfo.uavClassLabel' | transloco">
            <ng-container *ngIf="!vm.uav.uavClasses.length">{{ "dtmWebAppLibUav.uavInfo.noUavClassesLabel" | transloco }}</ng-container>
            <span class="uav-class" *ngFor="let uavClass of vm.uav.uavClasses">{{
                "dtmWebAppLibUav.uavClassValue" | transloco : { value: uavClass }
            }}</span>
        </dtm-ui-label-value>
    </div>
    <ul class="info-short" *ngIf="vm.isShortMode">
        <li>{{ vm.uav.manufacturerName + " " + vm.uav.modelName }}</li>
        <li>{{ "dtmWebAppLibUav.uavTypeValue" | transloco : { value: vm.uav.modelType } }}</li>
        <li *ngIf="!vm.uav.isSwarm">{{ vm.uav.serialNumbers[0] }}</li>
        <li *ngIf="vm.uav.isSwarm">
            <a class="serial-numbers-button" (click)="showSerialNumbers(vm.uav)">
                {{ "dtmWebAppLibUav.uavInfo.showSerialNumbersButtonLabel" | transloco }}
            </a>
        </li>
    </ul>
</ng-container>
