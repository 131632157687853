<dtm-ui-wizard-step-wrapper
    [title]="'dtmWebAppLibPilotOperatorRegistration.additionalDataHeader' | transloco"
    [stepNumber]="2"
    [stepsAmount]="3"
    [isBackButtonVisible]="true"
    (next)="validateFormAndSave()"
    (back)="goToPreviousStep.emit()"
    [isNextButtonEnabled]="true"
    dtmUiInvalidFormScrollable
>
    <form [formGroup]="additionalDataForm">
        <div class="section" *ngIf="isLegalGuardianRequired$ | ngrxPush">
            <h4>{{ "dtmWebAppLibPilotOperatorRegistration.additionalData.legalGuardianSectionHeader" | transloco }}</h4>
            <p class="section-description">
                {{ "dtmWebAppLibPilotOperatorRegistration.pilotRegistration.additionalData.legalGuardianSectionHint" | transloco }}
            </p>
            <dtm-web-app-lib-pilot-operator-registration-legal-guardian-form
                [formControl]="legalGuardianControl"
                dtmUiMarkValueAccessorControlsAsTouched
            >
            </dtm-web-app-lib-pilot-operator-registration-legal-guardian-form>
        </div>

        <div class="section">
            <h4>{{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceSectionHeader" | transloco }}</h4>
            <dtm-ui-checkbox-field [formControl]="isInsuranceOwnerControl" (change)="updateInsuranceDetailsControl($event)">
                {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceCheckboxText" | transloco }}

                <ng-container checkboxFieldHint>
                    {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceCheckboxHint" | transloco }}
                </ng-container>
            </dtm-ui-checkbox-field>

            <dtm-web-app-lib-pilot-operator-registration-insurance-form
                *ngIf="isInsuranceOwnerControl.value"
                @slideIn
                dtmUiMarkValueAccessorControlsAsTouched
                [formControl]="insuranceDetailsControl"
            ></dtm-web-app-lib-pilot-operator-registration-insurance-form>
        </div>
    </form>
</dtm-ui-wizard-step-wrapper>
