import { MapUtils } from "@dtm-frontend/shared/map/cesium";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

export interface VisualSettings {
    fill?: unknown;
    outlineWidth?: number;
    outlineMaterial?: any;
    outlineColor?: string;
    wallColor?: string;
}

const SELECTED_COLOR = "#0849a4"; // $color-secondary-900
const DEFAULT_COLOR = "#43577e"; // $color-gray-400
const ZONE_COLOR = "#1477d5"; // $color-secondary-700
const PREVIEW_COLOR = "#061636"; // $color-gray-900:
const PREVIEW_ALPHA = 0.2;
const DEFAULT_OUTLINE_WIDTH = 2;
const DEFAULT_OUTLINE_DASH_LENGTH = 15;
const DEFAULT_OUTLINE_DASH_PATTERN = MapUtils.createCesiumDashPattern("------------");
const DEFAULT_ALPHA = 0.5;

export enum RouteVisualState {
    Default = "default",
    Selected = "selected",
    TakeOff = "takeOff",
    Landing = "landing",
    Preview = "preview",
}

export const ROUTES_VISUAL_DATA: Record<RouteVisualState, VisualSettings> = {
    default: {
        outlineWidth: DEFAULT_OUTLINE_WIDTH,
        outlineMaterial: new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(DEFAULT_COLOR),
            dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
            dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
        }),
        fill: Cesium.Color.WHITE.withAlpha(0), // NOTE: allows element to be clickable
        outlineColor: Cesium.Color.WHITE,
        wallColor: Cesium.Color.fromCssColorString(DEFAULT_COLOR),
    },
    selected: {
        outlineWidth: DEFAULT_OUTLINE_WIDTH,
        outlineMaterial: new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(SELECTED_COLOR),
            dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
            dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
        }),
        fill: Cesium.Color.WHITE.withAlpha(DEFAULT_ALPHA),
        outlineColor: Cesium.Color.fromCssColorString(SELECTED_COLOR),
        wallColor: Cesium.Color.WHITE,
    },
    takeOff: {
        outlineWidth: DEFAULT_OUTLINE_WIDTH,
        outlineMaterial: new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(SELECTED_COLOR),
            dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
            dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
        }),
        fill: Cesium.Color.fromCssColorString(ZONE_COLOR).withAlpha(DEFAULT_ALPHA),
        outlineColor: Cesium.Color.WHITE,
        wallColor: Cesium.Color.fromCssColorString(ZONE_COLOR).withAlpha(DEFAULT_ALPHA),
    },
    landing: {
        outlineWidth: DEFAULT_OUTLINE_WIDTH,
        outlineMaterial: new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(SELECTED_COLOR),
            dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
            dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
        }),
        fill: Cesium.Color.fromCssColorString(ZONE_COLOR).withAlpha(DEFAULT_ALPHA),
        outlineColor: Cesium.Color.WHITE,
        wallColor: Cesium.Color.fromCssColorString(ZONE_COLOR).withAlpha(DEFAULT_ALPHA),
    },
    preview: {
        outlineWidth: DEFAULT_OUTLINE_WIDTH,
        outlineMaterial: new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(PREVIEW_COLOR),
            dashLength: DEFAULT_OUTLINE_DASH_LENGTH,
            dashPattern: DEFAULT_OUTLINE_DASH_PATTERN,
        }),
        fill: Cesium.Color.fromCssColorString(PREVIEW_COLOR).withAlpha(PREVIEW_ALPHA),
    },
};
