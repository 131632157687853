<dtm-ui-loader-container
    *ngrxLet="{ isResultsListProcessing: isResultsListProcessing$, activeTabIndex: activeTabIndex$, resultsList: resultsList$ } as vm"
    [isShown]="vm.isResultsListProcessing"
>
    <dtm-ui-responsive-table [data]="vm.resultsList">
        <table mat-table [dataSource]="vm.resultsList" multiTemplateDataRows desktopTable class="dtm-table">
            <ng-container [matColumnDef]="displayedColumns[0]">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibTrainingExamResults.list.nameLabel" | transloco : { value: vm.activeTabIndex } }}
                </th>
                <td mat-cell *matCellDef="let result">
                    <ng-container [ngTemplateOutlet]="nameTemplate" [ngTemplateOutletContext]="{ $implicit: result }"></ng-container>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[1]">
                <th mat-header-cell *matHeaderCellDef>
                    {{
                        (vm.activeTabIndex === TrainingExamResultsTabType.Trainings
                            ? "dtmWebAppLibTrainingExamResults.list.timeDurationLabel"
                            : "dtmWebAppLibTrainingExamResults.list.passDateLabel"
                        ) | transloco
                    }}
                </th>
                <td mat-cell *matCellDef="let result">
                    <ng-container [ngTemplateOutlet]="dateTemplate" [ngTemplateOutletContext]="{ $implicit: result }"></ng-container>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[2]">
                <th mat-header-cell *matHeaderCellDef>
                    {{ "dtmWebAppLibTrainingExamResults.list.modeLabel" | transloco : { value: vm.activeTabIndex } }}
                </th>
                <td mat-cell *matCellDef="let result">
                    <ng-container [ngTemplateOutlet]="modeTemplate" [ngTemplateOutletContext]="{ $implicit: result }"></ng-container>
                </td>
            </ng-container>

            <ng-container [matColumnDef]="displayedColumns[3]">
                <th mat-header-cell *matHeaderCellDef>
                    {{
                        (vm.activeTabIndex === TrainingExamResultsTabType.Trainings
                            ? "dtmWebAppLibTrainingExamResults.list.trainingOperatorLabel"
                            : "dtmWebAppLibTrainingExamResults.list.examEntityLabel"
                        ) | transloco
                    }}
                </th>
                <td mat-cell *matCellDef="let result">
                    <ng-container [ngTemplateOutlet]="entityTemplate" [ngTemplateOutletContext]="{ $implicit: result }"></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <ng-container
                        *ngIf="resultsListError$ | ngrxPush; else emptyListTemplate"
                        [ngTemplateOutlet]="errorTemplate"
                    ></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="pagination">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr
                mat-footer-row
                *matFooterRowDef="['noResults']"
                [class.hide-footer]="vm.resultsList.length || vm.isResultsListProcessing"
                class="no-results-row"
            >
                <dtm-ui-no-results></dtm-ui-no-results>
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="['pagination']" [class.hide-footer]="!vm.resultsList.length" class="pagination-row"></tr>
        </table>
        <div *dtmUiMobileTable="let row">
            <div class="mobile-table-content">
                <dtm-ui-label-value [label]="'dtmWebAppLibTrainingExamResults.list.nameLabel' | transloco : { value: vm.activeTabIndex }">
                    <ng-container [ngTemplateOutlet]="nameTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="
                        (vm.activeTabIndex === TrainingExamResultsTabType.Trainings
                            ? 'dtmWebAppLibTrainingExamResults.list.timeDurationLabel'
                            : 'dtmWebAppLibTrainingExamResults.list.passDateLabel'
                        ) | transloco
                    "
                >
                    <ng-container [ngTemplateOutlet]="dateTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                </dtm-ui-label-value>
                <dtm-ui-label-value [label]="'dtmWebAppLibTrainingExamResults.list.modeLabel' | transloco : { value: vm.activeTabIndex }">
                    <ng-container [ngTemplateOutlet]="modeTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="
                        (vm.activeTabIndex === TrainingExamResultsTabType.Trainings
                            ? 'dtmWebAppLibTrainingExamResults.list.trainingOperatorLabel'
                            : 'dtmWebAppLibTrainingExamResults.list.examEntityLabel'
                        ) | transloco
                    "
                >
                    <ng-container [ngTemplateOutlet]="entityTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                </dtm-ui-label-value>
            </div>
        </div>
        <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
        <ng-container
            mobileTableNoData
            *ngIf="resultsListError$ | ngrxPush; else emptyListTemplate"
            [ngTemplateOutlet]="errorTemplate"
        ></ng-container>
    </dtm-ui-responsive-table>

    <ng-template #nameTemplate let-result>
        {{ vm.activeTabIndex === TrainingExamResultsTabType.Trainings ? result.trainingNativeName : result.examNativeName }}
    </ng-template>

    <ng-template #dateTemplate let-result>
        <ng-container *ngIf="vm.activeTabIndex === TrainingExamResultsTabType.Trainings">
            {{ result.startDate | localizeDate }} - {{ result.endDate | localizeDate }}
        </ng-container>
        <ng-container *ngIf="vm.activeTabIndex === TrainingExamResultsTabType.Exams">
            {{ result.passDate | localizeDate }}
        </ng-container>
    </ng-template>

    <ng-template #modeTemplate let-result>
        <div class="mode-container">
            <ng-container *ngIf="vm.activeTabIndex === TrainingExamResultsTabType.Trainings && result.trainingMode">
                <dtm-ui-icon [name]="result.trainingMode === TrainingExamResultsMode.Stationary ? 'home' : 'global'"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibTrainingExamResults.list.modeValueLabel" | transloco : { value: result.trainingMode } }}</span>
            </ng-container>

            <ng-container *ngIf="vm.activeTabIndex === TrainingExamResultsTabType.Exams && result.examMode">
                <dtm-ui-icon [name]="result.examMode === TrainingExamResultsMode.Stationary ? 'home' : 'global'"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibTrainingExamResults.list.modeValueLabel" | transloco : { value: result.examMode } }}</span>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #entityTemplate let-result>
        {{ vm.activeTabIndex === TrainingExamResultsTabType.Trainings ? result.trainingOperatorName : result.examiningCenterName }}
    </ng-template>
</dtm-ui-loader-container>

<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>

<ng-template #errorTemplate>
    <dtm-ui-error [mode]="ErrorMode.Secondary" (reload)="resultsListRefresh.emit()"></dtm-ui-error>
</ng-template>

<ng-template #paginationTemplate>
    <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
    </dtm-ui-pagination>
</ng-template>
