import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { GlobalCapabilitiesErrorType } from "../../../../shared/operator-context/models/operator-context.models";
import { OperatorContextState } from "../../../../shared/operator-context/state/operator-context.state";
import {
    REGISTER_OPERATOR_WIZARD_ID,
    RegisterOperatorWizardSteps,
} from "../register-operator-wizard-content/register-operator-wizard-content.component";

@Component({
    selector: "dtm-web-app-lib-register-operator-wizard-header",
    templateUrl: "./register-operator-wizard-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterOperatorWizardHeaderComponent {
    public readonly wizardId = REGISTER_OPERATOR_WIZARD_ID;
    public readonly RegisterOperatorWizardSteps = RegisterOperatorWizardSteps;
    public readonly isProcessing$ = this.store.select(OperatorContextState.isProcessing);

    public readonly shouldShowWizardHeader$ = combineLatest([this.store.select(OperatorContextState.operatorContextError)]).pipe(
        map(([error]) => error?.type !== GlobalCapabilitiesErrorType.CannotGetUserGlobalCapabilities)
    );

    constructor(private readonly store: Store) {}
}
