<mat-card>
    <dtm-ui-card-header>
        {{ "dtmWebAppUserProfile.attorneyPowers.header" | transloco }}
    </dtm-ui-card-header>
    <ng-container *ngrxLet="slides$ as slides" [ngSwitch]="true">
        <ng-container
            *ngSwitchCase="slides.length > 1"
            [ngTemplateOutlet]="attorneyPowersCarouselTemplate"
            [ngTemplateOutletContext]="{ $implicit: slides }"
        ></ng-container>
        <ng-container
            *ngSwitchDefault
            [ngTemplateOutlet]="singleAttorneyPowerTemplate"
            [ngTemplateOutletContext]="{ $implicit: slides[0].cards[0] }"
        ></ng-container>
    </ng-container>

    <ng-template #attorneyPowersCarouselTemplate let-slides>
        <dtm-ui-carousel>
            <ng-container *ngFor="let slide of slides">
                <div class="slide" *dtmUiCarouselSlides>
                    <ng-template
                        *ngFor="let attorneyPowers of slide.cards"
                        [ngTemplateOutlet]="singleAttorneyPowerTemplate"
                        [ngTemplateOutletContext]="{ $implicit: attorneyPowers }"
                    ></ng-template>
                </div>
            </ng-container>
        </dtm-ui-carousel>
    </ng-template>

    <ng-template #singleAttorneyPowerTemplate let-attorneyPowers>
        <div class="attorney-tile">
            <div class="attorney-power-tile-header">
                <dtm-ui-label-value
                    [label]="'dtmWebAppUserProfile.attorneyPowers.operatorLabel' | transloco"
                    [value]="attorneyPowers.operatorName"
                ></dtm-ui-label-value>
                <div class="attorney-power-tile-header">
                    <ng-container [ngSwitch]="attorneyPowers.status">
                        <span *ngSwitchCase="AttorneyPowerStatus.Approved" class="chip success">
                            <dtm-ui-icon name="checkbox-circle"></dtm-ui-icon>
                            {{
                                "dtmWebAppUserProfile.attorneyPowers.attorneyPowersStatusValue"
                                    | transloco : { status: AttorneyPowerStatus.Approved }
                            }}
                        </span>
                        <span *ngSwitchCase="AttorneyPowerStatus.PendingApproval" class="chip warning">
                            <dtm-ui-icon name="rest-time"></dtm-ui-icon>
                            {{
                                "dtmWebAppUserProfile.attorneyPowers.attorneyPowersStatusValue"
                                    | transloco : { status: AttorneyPowerStatus.PendingApproval }
                            }}
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="attorney-power-documents" [ngSwitch]="attorneyPowers.type">
                <dtm-ui-label-value
                    [label]="'dtmWebAppUserProfile.attorneyPowers.isOwnerLabel' | transloco"
                    [value]="
                        'dtmWebAppUserProfile.attorneyPowers.isOwnerValue'
                            | transloco : { isOwner: attorneyPowers.type === AttorneyPowerType.Owner }
                    "
                ></dtm-ui-label-value>

                <ng-container *ngSwitchCase="AttorneyPowerType.NationalCourtNumber">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppUserProfile.attorneyPowers.nationalCourtRegisterLabel' | transloco"
                        [value]="attorneyPowers.nationalCourtRegister"
                    ></dtm-ui-label-value>
                </ng-container>

                <ng-container *ngSwitchCase="AttorneyPowerType.ConfirmatoryDocument">
                    <dtm-ui-label-value
                        [label]="'dtmWebAppUserProfile.attorneyPowers.attachmentsLabel' | transloco"
                        *ngIf="attorneyPowers.documentId"
                    >
                        <dtm-ui-uploaded-files-display
                            [uploadedFiles]="[
                                {
                                    id: attorneyPowers.documentId,
                                    name: 'dtmWebAppUserProfile.attorneyPowers.documentFileName' | transloco
                                }
                            ]"
                        ></dtm-ui-uploaded-files-display>
                    </dtm-ui-label-value>
                </ng-container>
            </div>
            <dtm-ui-label-value class="permissions" [label]="'dtmWebAppUserProfile.attorneyPowers.permissionsLabel' | transloco">
                <ng-container *ngIf="attorneyPowers.permissions.length; else emptyPermissionsTemplate">
                    <span *ngFor="let permission of attorneyPowers.permissions" class="permission">{{
                        "dtmWebAppUserProfile.attorneyPowers.permissionsValue" | transloco : { permission }
                    }}</span>
                </ng-container>

                <ng-template #emptyPermissionsTemplate>
                    <span class="permission">{{ "dtmWebAppUserProfile.attorneyPowers.emptyPermissionsValue" | transloco }}</span>
                </ng-template>
            </dtm-ui-label-value>
        </div>
    </ng-template>
</mat-card>
