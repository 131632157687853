import { Page } from "@dtm-frontend/shared/ui";
import { TrainingExamResultsMode } from "./training-exam-results.models";

export enum TrainingResultsErrorType {
    CannotGetTrainingResultsList = "CannotGetTrainingResultsList",
}

export interface TrainingResultListWithPages extends Page {
    content: TrainingResult[];
}

export interface TrainingResultsError {
    type: TrainingResultsErrorType;
}

export interface TrainingResult {
    name: string;
    operatorNumber: string;
    trainingNativeName: string;
    trainingEnglishName: string;
    startDate: Date;
    endDate: Date;
    trainingMode: TrainingExamResultsMode;
    trainingOperatorName: string;
}
