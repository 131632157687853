import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable, switchMap } from "rxjs";
import { OperatorPermitsActions } from "../state/operator-permits.actions";

@Injectable()
export class OperatorPermitsResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<void> {
        return this.store
            .dispatch(new OperatorPermitsActions.GetOperatorPermitsCapabilities(route.params.operatorId))
            .pipe(
                switchMap(() =>
                    this.store.dispatch(new OperatorPermitsActions.GetOperatorPermitDetails(route.params.permitId, route.params.operatorId))
                )
            );
    }
}

@Injectable()
export class CrossBorderPermitsResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<void> {
        return this.store
            .dispatch(new OperatorPermitsActions.GetOperatorPermitsCapabilities(route.params.operatorId))
            .pipe(
                switchMap(() =>
                    this.store.dispatch(
                        new OperatorPermitsActions.GetCrossBorderPermitDetails(route.params.permitId, route.params.operatorId)
                    )
                )
            );
    }
}
