import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { PilotProfileApiService } from "../services/pilot-profile.api.service";
import {
    PansaUtmCredentials,
    PansaUtmLinkError,
    PilotConfirmationDocumentType,
    PilotProfile,
    PilotProfileError,
} from "../services/pilot-profile.models";
import { PilotProfileActions } from "./pilot-profile.actions";

export interface PilotProfileStateModel {
    pilotProfile: PilotProfile | undefined;
    pilotProfileError: PilotProfileError | undefined;
    isPilotProfileProcessing: boolean;
    editPilotInsurancePolicyError: PilotProfileError | undefined;
    editPilotAddressError: PilotProfileError | undefined;
    registrationConfirmationError: PilotProfileError | undefined;
    pansaUtmLinkError: PansaUtmLinkError | undefined;
    downloadRegistrationConfirmationError: PilotProfileError | undefined;
    downloadConfirmationCompletingA1A3Error: PilotProfileError | undefined;
    downloadCertificateA2DocumentError: PilotProfileError | undefined;
    downloadCertificateStsDocumentError: PilotProfileError | undefined;
    addForeignCompetencySaveError: PilotProfileError | undefined;
    deleteForeignCompetencyError: PilotProfileError | undefined;
}

const defaultState: PilotProfileStateModel = {
    pilotProfile: undefined,
    pilotProfileError: undefined,
    isPilotProfileProcessing: false,
    editPilotInsurancePolicyError: undefined,
    editPilotAddressError: undefined,
    registrationConfirmationError: undefined,
    pansaUtmLinkError: undefined,
    downloadRegistrationConfirmationError: undefined,
    downloadConfirmationCompletingA1A3Error: undefined,
    downloadCertificateA2DocumentError: undefined,
    downloadCertificateStsDocumentError: undefined,
    addForeignCompetencySaveError: undefined,
    deleteForeignCompetencyError: undefined,
};

@State<PilotProfileStateModel>({
    name: "pilotProfile",
    defaults: defaultState,
})
@Injectable()
export class PilotProfileState {
    constructor(private readonly pilotProfileApi: PilotProfileApiService) {}

    @Selector()
    public static pilotProfile(state: PilotProfileStateModel): PilotProfile | undefined {
        return state.pilotProfile;
    }

    @Selector()
    public static isPilotProfileProcessing(state: PilotProfileStateModel): boolean {
        return state.isPilotProfileProcessing;
    }

    @Selector()
    public static pilotProfileError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.pilotProfileError;
    }

    @Selector()
    public static editPilotInsurancePolicyError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.editPilotInsurancePolicyError;
    }

    @Selector()
    public static registrationConfirmationError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.registrationConfirmationError;
    }

    @Selector()
    public static editPilotAddressError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.editPilotAddressError;
    }

    @Selector()
    public static pansaUtmLinkError(state: PilotProfileStateModel): PansaUtmLinkError | undefined {
        return state.pansaUtmLinkError;
    }

    @Selector()
    public static downloadRegistrationConfirmationError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.downloadRegistrationConfirmationError;
    }

    @Selector()
    public static downloadConfirmationCompletingA1A3Error(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.downloadConfirmationCompletingA1A3Error;
    }

    @Selector()
    public static downloadCertificateA2DocumentError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.downloadCertificateA2DocumentError;
    }

    @Selector()
    public static downloadCertificateStsDocumentError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.downloadCertificateStsDocumentError;
    }

    @Selector()
    public static addForeignCompetencySaveError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.addForeignCompetencySaveError;
    }

    @Selector()
    public static deleteForeignCompetencyError(state: PilotProfileStateModel): PilotProfileError | undefined {
        return state.deleteForeignCompetencyError;
    }

    @Action(PilotProfileActions.GetPilotProfile)
    public getPilotProfile(context: StateContext<PilotProfileStateModel>, action: PilotProfileActions.GetPilotProfile) {
        context.patchState({
            isPilotProfileProcessing: true,
        });

        return this.pilotProfileApi.getPilotProfile(action.pilotId).pipe(
            tap((pilotProfile: PilotProfile) =>
                context.patchState({
                    pilotProfile,
                    pilotProfileError: undefined,
                    isPilotProfileProcessing: false,
                })
            ),
            catchError((error) => {
                context.patchState({ pilotProfileError: error, pilotProfile: undefined, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.EditPilotInsurancePolicy)
    public editPilotInsurancePolicy(context: StateContext<PilotProfileStateModel>, action: PilotProfileActions.EditPilotInsurancePolicy) {
        context.patchState({ editPilotInsurancePolicyError: undefined, isPilotProfileProcessing: true });
        const pilotProfile = context.getState().pilotProfile;
        const operator = pilotProfile?.operator;

        if (!operator?.id || !pilotProfile?.id) {
            return;
        }

        return this.pilotProfileApi.editPilotInsurancePolicy(pilotProfile.id, action.policy).pipe(
            tap(() => {
                const newPilotProfile = {
                    ...pilotProfile,
                    operator: {
                        ...operator,
                        insurancePolicyNumber: action.policy.number,
                        insurancePolicyExpirationDate: action.policy.expirationDate,
                    },
                };
                context.patchState({
                    pilotProfile: newPilotProfile,
                    isPilotProfileProcessing: false,
                });
            }),
            catchError((editPilotInsurancePolicyError) => {
                context.patchState({ editPilotInsurancePolicyError: editPilotInsurancePolicyError, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.DeleteInsurancePolicy)
    public deleteInsurancePolicy(context: StateContext<PilotProfileStateModel>) {
        context.patchState({ editPilotInsurancePolicyError: undefined, isPilotProfileProcessing: true });
        const pilotProfile = context.getState().pilotProfile;
        const operator = pilotProfile?.operator;

        if (!operator?.id || !pilotProfile?.id) {
            return;
        }

        return this.pilotProfileApi.deletePilotInsurancePolicy(pilotProfile.id).pipe(
            tap(() => {
                const newPilotProfile = {
                    ...pilotProfile,
                    operator: {
                        ...operator,
                        insurancePolicyNumber: undefined,
                        insurancePolicyExpirationDate: undefined,
                    },
                };
                context.patchState({
                    pilotProfile: newPilotProfile,
                    isPilotProfileProcessing: false,
                });
            }),
            catchError((editPilotInsurancePolicyError) => {
                context.patchState({ editPilotInsurancePolicyError: editPilotInsurancePolicyError, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.EditPilotAddress)
    public editPilotAddress(context: StateContext<PilotProfileStateModel>, action: PilotProfileActions.EditPilotAddress) {
        context.patchState({ editPilotAddressError: undefined, isPilotProfileProcessing: true });
        const pilotProfile = context.getState().pilotProfile;

        if (!pilotProfile?.id) {
            return;
        }

        return this.pilotProfileApi.editPilotAddress(pilotProfile?.id, action.address).pipe(
            tap(() => {
                context.patchState({
                    isPilotProfileProcessing: false,
                    pilotProfile: {
                        ...pilotProfile,
                        operator: {
                            ...pilotProfile.operator,
                            address: action.address,
                        },
                    },
                });
            }),
            catchError((editPilotAddressError) => {
                context.patchState({ editPilotAddressError, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.DownloadRegistrationConfirmationDocument)
    public downloadRegistrationConfirmationDocument(
        context: StateContext<PilotProfileStateModel>,
        action: PilotProfileActions.DownloadRegistrationConfirmationDocument
    ) {
        context.patchState({ downloadRegistrationConfirmationError: undefined });
        const pilotId = context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.pilotProfileApi
            .downloadPilotsDocument(pilotId, PilotConfirmationDocumentType.RegistrationConfirmation, action.fileName)
            .pipe(
                catchError((downloadRegistrationConfirmationError) => {
                    context.patchState({ downloadRegistrationConfirmationError });

                    return EMPTY;
                })
            );
    }

    @Action(PilotProfileActions.DownloadConfirmationCompletingA1A3Document)
    public downloadConfirmationCompletingA1A3Document(
        context: StateContext<PilotProfileStateModel>,
        action: PilotProfileActions.DownloadConfirmationCompletingA1A3Document
    ) {
        context.patchState({ downloadConfirmationCompletingA1A3Error: undefined });
        const pilotId = context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.pilotProfileApi
            .downloadPilotsDocument(pilotId, PilotConfirmationDocumentType.ConfirmationCompletingA1A3, action.fileName)
            .pipe(
                catchError((downloadConfirmationCompletingA1A3Error) => {
                    context.patchState({ downloadConfirmationCompletingA1A3Error });

                    return EMPTY;
                })
            );
    }

    @Action(PilotProfileActions.DownloadCertificateA2Document)
    public downloadCertificateA2Document(
        context: StateContext<PilotProfileStateModel>,
        action: PilotProfileActions.DownloadCertificateA2Document
    ) {
        context.patchState({ downloadCertificateA2DocumentError: undefined });
        const pilotId = context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.pilotProfileApi.downloadPilotsDocument(pilotId, PilotConfirmationDocumentType.CertificateA2, action.fileName).pipe(
            catchError((downloadCertificateA2DocumentError) => {
                context.patchState({ downloadCertificateA2DocumentError });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.DownloadCertificateStsDocument)
    public downloadCertificateStsDocument(
        context: StateContext<PilotProfileStateModel>,
        action: PilotProfileActions.DownloadCertificateStsDocument
    ) {
        context.patchState({ downloadCertificateStsDocumentError: undefined });
        const pilotId = context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.pilotProfileApi.downloadPilotsDocument(pilotId, PilotConfirmationDocumentType.CertificateSts, action.fileName).pipe(
            catchError((downloadCertificateStsDocumentError) => {
                context.patchState({ downloadCertificateStsDocumentError });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.AddPansaUtmLink)
    public addPansaUtmLink(context: StateContext<PilotProfileStateModel>, action: PilotProfileActions.AddPansaUtmLink) {
        context.patchState({ pansaUtmLinkError: undefined, isPilotProfileProcessing: true });
        const pilotId = action.pilotId ?? context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.getPansaUtmLinkApiMethod(pilotId, action.credentials, !!action.existingLink).pipe(
            tap(() => {
                context.patchState({
                    isPilotProfileProcessing: false,
                });
            }),
            catchError((pansaUtmLinkError) => {
                context.patchState({ pansaUtmLinkError, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.AddOrEditForeignCompetency)
    public addOrEditForeignCompetency(
        context: StateContext<PilotProfileStateModel>,
        action: PilotProfileActions.AddOrEditForeignCompetency
    ) {
        context.patchState({ addForeignCompetencySaveError: undefined, isPilotProfileProcessing: true });
        const pilotId = context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.pilotProfileApi.addForeignCompetency(pilotId, action.competency).pipe(
            tap(() => {
                context.patchState({
                    isPilotProfileProcessing: false,
                });
            }),
            catchError((addForeignCompetencySaveError) => {
                context.patchState({ addForeignCompetencySaveError, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }
    @Action(PilotProfileActions.DeleteForeignCompetency)
    public deleteForeignCompetency(context: StateContext<PilotProfileStateModel>, action: PilotProfileActions.DeleteForeignCompetency) {
        context.patchState({ deleteForeignCompetencyError: undefined, isPilotProfileProcessing: true });
        const pilotId = context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.pilotProfileApi.deleteForeignCompetencyError(pilotId, action.competencyId).pipe(
            tap(() => {
                context.patchState({
                    isPilotProfileProcessing: false,
                });
            }),
            catchError((deleteForeignCompetencyError) => {
                context.patchState({ deleteForeignCompetencyError, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    @Action(PilotProfileActions.DeletePansaUtmLink)
    public deletePansaUtmLink(context: StateContext<PilotProfileStateModel>) {
        context.patchState({ pansaUtmLinkError: undefined, isPilotProfileProcessing: true });
        const pilotId = context.getState().pilotProfile?.id;

        if (!pilotId) {
            return;
        }

        return this.pilotProfileApi.deletePansaUtmLink(pilotId).pipe(
            tap(() => {
                context.patchState({
                    isPilotProfileProcessing: false,
                });
            }),
            catchError((pansaUtmLinkError) => {
                context.patchState({ pansaUtmLinkError, isPilotProfileProcessing: false });

                return EMPTY;
            })
        );
    }

    private getPansaUtmLinkApiMethod(pilotId: string, credentials: PansaUtmCredentials, hasIntegration: boolean): Observable<void> {
        if (hasIntegration) {
            return this.pilotProfileApi.updatePansaUtmLink(pilotId, credentials);
        }

        return this.pilotProfileApi.addPansaUtmLink(pilotId, credentials);
    }
}
