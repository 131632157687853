<dtm-ui-responsive-table *ngrxLet="permitDrafts$ as drafts" [data]="drafts">
    <table
        class="dtm-table no-box-shadow"
        *ngIf="drafts.length; else emptyListTemplate"
        mat-table
        desktopTable
        [dataSource]="drafts"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="draftName">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.draftNameColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.name }}
            </td>
        </ng-container>
        <ng-container matColumnDef="operationName">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.operationNameColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.operationName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                {{ "dtmWebAppLibOperatorPermits.permitsLists.actionsColumnLabel" | transloco }}
            </th>
            <td mat-cell *matCellDef="let element">
                <button
                    [matMenuTriggerFor]="actions"
                    [matMenuTriggerData]="{ $implicit: element }"
                    class="button-icon"
                    type="button"
                    (click)="$event.stopPropagation()"
                    [attr.aria-label]="'dtmUi.actionButtonAriaLabel' | transloco"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="pagination">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="['pagination']" class="pagination-row"></tr>
    </table>
    <div *dtmUiMobileTable="let row">
        <ng-container *ngIf="isTablet$ | ngrxPush; else mobileTemplate">
            <div class="tablet-view">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermits.permitsLists.draftNameColumnLabel' | transloco"
                    [value]="row.name"
                >
                </dtm-ui-label-value>
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermits.permitsLists.operationNameColumnLabel' | transloco"
                    [value]="row.operationName"
                >
                </dtm-ui-label-value>
                <button
                    [matMenuTriggerFor]="actions"
                    [matMenuTriggerData]="{ $implicit: row }"
                    (click)="$event.stopPropagation()"
                    class="button-icon tablet"
                    type="button"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </div>
        </ng-container>
        <ng-template #mobileTemplate>
            <div class="mobile-table-actions-row">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermits.permitsLists.draftNameColumnLabel' | transloco"
                    [value]="row.name"
                >
                </dtm-ui-label-value>
                <button
                    [matMenuTriggerFor]="actions"
                    [matMenuTriggerData]="{ $implicit: row }"
                    (click)="$event.stopPropagation()"
                    class="button-icon"
                    type="button"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </div>
            <dtm-ui-label-value
                [label]="'dtmWebAppLibOperatorPermits.permitsLists.operationNameColumnLabel' | transloco"
                [value]="row.operationName"
            >
            </dtm-ui-label-value>
        </ng-template>
    </div>
    <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
    <ng-container mobileTableNoData [ngTemplateOutlet]="emptyListTemplate"></ng-container>
</dtm-ui-responsive-table>

<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>

<ng-template #paginationTemplate>
    <dtm-ui-pagination *ngIf="pagination$ | ngrxPush as pagination" [pagination]="pagination" (page)="pageChange.emit($event)">
    </dtm-ui-pagination>
</ng-template>

<mat-menu #actions="matMenu">
    <ng-template matMenuContent let-element>
        <button type="button" mat-menu-item [routerLink]="'/specific-permit/application-creator/draft/' + element.id">
            <dtm-ui-icon name="edit"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibOperatorPermits.permitsLists.draftEditionActionLabel" | transloco }}</span>
        </button>
        <button type="button" (click)="removeDraft(element)" mat-menu-item>
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibOperatorPermits.permitsLists.removeActionLabel" | transloco }}</span>
        </button>
    </ng-template>
</mat-menu>
