import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { DEFAULT_COUNTRY_CODE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { attorneyPowerDocumentsProviders } from "../../providers/attorney-document-upload.providers";
import {
    AttorneyPowerType,
    OperatorRegistrationFormValue,
    OperatorRegistrationType,
    RegisterOperatorSummaryData,
} from "../../services/models";

interface RegisterOperatorSummaryComponentState {
    operatorRegistrationFormValue: OperatorRegistrationFormValue | undefined;
    originCountry: string;
    isProcessing: boolean;
}

@Component({
    selector: "dtm-web-app-lib-register-operator-summary[operatorRegistrationFormValue]",
    templateUrl: "./register-operator-summary.component.html",
    styleUrls: ["../../../shared/styles.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, ...attorneyPowerDocumentsProviders],
})
export class RegisterOperatorSummaryComponent {
    @Input()
    public set operatorRegistrationFormValue(value: OperatorRegistrationFormValue | undefined) {
        this.localStore.patchState({ operatorRegistrationFormValue: value });
    }

    @Input()
    public set originCountry(value: string | undefined) {
        this.localStore.patchState({ originCountry: value ?? DEFAULT_COUNTRY_CODE });
    }

    @Input()
    public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public summaryDataSave = new EventEmitter<RegisterOperatorSummaryData>();
    @Output() public goToBasicDataStep = new EventEmitter<void>();
    @Output() public goToAdditionalDataStep = new EventEmitter<void>();
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly operatorRegistrationFormValue$ = this.localStore
        .selectByKey("operatorRegistrationFormValue")
        .pipe(RxjsUtils.filterFalsy());
    protected readonly originCountry$ = this.localStore.selectByKey("originCountry");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    protected readonly authenticityOfDataControl = new FormControl<boolean | null>(null, Validators.requiredTrue);
    protected readonly generalDataProtectionRegulationControl = new FormControl<boolean | null>(null, Validators.requiredTrue);
    protected readonly DEFAULT_COUNTRY_CODE = DEFAULT_COUNTRY_CODE;
    protected readonly AttorneyPowerType = AttorneyPowerType;
    protected readonly OperatorRegistrationType = OperatorRegistrationType;

    protected readonly statementsForm = new FormGroup({
        authenticityOfData: this.authenticityOfDataControl,
        generalDataProtectionRegulation: this.generalDataProtectionRegulationControl,
    });

    constructor(private readonly localStore: LocalComponentStore<RegisterOperatorSummaryComponentState>) {
        localStore.setState({ operatorRegistrationFormValue: undefined, originCountry: DEFAULT_COUNTRY_CODE, isProcessing: false });
    }

    protected saveSummaryData() {
        this.statementsForm.markAllAsTouched();

        if (this.statementsForm.valid) {
            this.summaryDataSave.emit({
                isAuthenticityOfData: this.authenticityOfDataControl.value as boolean,
                isGeneralDataProtectionRegulation: this.generalDataProtectionRegulationControl.value as boolean,
            });

            return;
        }

        this.invalidFormScrollable.scrollToFirstInvalidField();
    }
}
