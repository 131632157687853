<dtm-ui-expandable-panel
    *ngrxLet="{
        availableCrewMembers: availableCrewMembers$,
        currentAdditionalCrew: currentAdditionalCrew$,
        currentAdditionalCrewSize: currentAdditionalCrewSize$,
        isEditMode: isEditMode$,
    } as vm"
    [isExpanded]="isExpanded$ | ngrxPush"
    [isDisabled]="vm.isEditMode || !vm.currentAdditionalCrewSize"
    [hasHeaderSeparator]="false"
>
    <div class="panel-header" headerSlot>
        <h2>
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.headerLabel" | transloco }}
            <span> ({{ vm.currentAdditionalCrewSize }}) </span>
        </h2>
        <button
            *ngIf="!vm.isEditMode"
            type="button"
            class="button-secondary"
            [class]="vm.currentAdditionalCrew.length ? 'edit-button' : 'add-button'"
            (click)="$event.stopPropagation(); setEditMode(true)"
        >
            <ng-container *ngIf="vm.currentAdditionalCrewSize">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.editButtonLabel" | transloco }}
            </ng-container>
            <ng-container *ngIf="!vm.currentAdditionalCrewSize">
                <dtm-ui-icon name="add"></dtm-ui-icon>
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.addButtonLabel" | transloco }}
            </ng-container>
        </button>
        <button *ngIf="vm.isEditMode" type="button" class="button-icon close-button" (click)="$event.stopPropagation(); cancel()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>
    <section *ngIf="!vm.isEditMode">
        <ul>
            <li class="feature-item" *ngFor="let member of vm.currentAdditionalCrew">
                <dtm-ui-icon name="check"></dtm-ui-icon>
                <div class="feature-value">
                    <strong>
                        {{ "mission.additional_crew_members." + member.member + ".name" | systemTranslation }}
                        <ng-container *ngIf="member.member === CrewMember.UavObserver"> ({{ member.amount ?? 0 }}) </ng-container>
                    </strong>
                    <p *ngIf="member.responsibilities" class="member-responsibilities">{{ member.responsibilities }}</p>
                </div>
            </li>
        </ul>
        <div class="no-members" *ngIf="!vm.currentAdditionalCrewSize">
            {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.noAdditionalCrewMembersSelectedLabel" | transloco }}
        </div>
    </section>
    <section class="edit-mode-container" *ngIf="vm.isEditMode">
        <ng-container [formGroup]="additionalCrewForm">
            <dtm-web-app-lib-spec-perm-app-additional-crew-form
                dtmUiMarkValueAccessorControlsAsTouched
                formControlName="additionalCrew"
                [availableCrewMembers]="vm.availableCrewMembers"
            >
            </dtm-web-app-lib-spec-perm-app-additional-crew-form>
        </ng-container>
        <footer class="actions">
            <button type="button" class="button-secondary" (click)="cancel()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.cancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" (click)="save()">
                {{ "dtmWebAppLibSpecPermApp.operationDetailsStep.additionalCrew.saveButtonLabel" | transloco }}
            </button>
        </footer>
    </section>
</dtm-ui-expandable-panel>
