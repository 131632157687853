<dtm-ui-wizard-step-wrapper
    [title]="'dtmWebAppLibPilotOperatorRegistration.additionalDataHeader' | transloco"
    [stepNumber]="2"
    [stepsAmount]="3"
    [isBackButtonVisible]="true"
    (next)="validateFormAndSave()"
    (back)="back.emit()"
    dtmUiInvalidFormScrollable
>
    <form [formGroup]="additionalDataForm">
        <div *ngIf="additionalDataForm.controls.legalGuardian.enabled" class="section">
            <p class="section-description">
                {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.legalGuardianSectionHint" | transloco }}
            </p>
            <dtm-web-app-lib-pilot-operator-registration-legal-guardian-form
                [formControl]="legalGuardianControl"
                dtmUiMarkValueAccessorControlsAsTouched
            >
            </dtm-web-app-lib-pilot-operator-registration-legal-guardian-form>
        </div>
        <div class="section">
            <h4>{{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneySectionHeader" | transloco }}</h4>
            <dtm-ui-radio-group [formControl]="isCompanyOwnerControl" (change)="changeCompanyOwnerValue($event)">
                <label class="label">
                    {{
                        ((isAssociation$ | ngrxPush)
                            ? "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.isAssociationRepresentativeLabel"
                            : "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.isCompanyOwnerLabel"
                        ) | transloco
                    }}
                </label>
                <div class="radio-content">
                    <dtm-ui-radio-field [value]="true">
                        {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.yesLabel" | transloco }}
                    </dtm-ui-radio-field>
                    <dtm-ui-radio-field [value]="false">
                        {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.noLabel" | transloco }}
                    </dtm-ui-radio-field>
                </div>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="isCompanyOwnerControl; name: 'required'">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>
        </div>

        <div class="section" *ngIf="additionalDataForm.controls.powerOfAttorney.enabled" @slideIn>
            <h4>
                {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.powerOfAttorneySectionHeader" | transloco }}
            </h4>
            <dtm-ui-radio-group [formControl]="attorneyPowerControl" (change)="changeNationalCourtRegisterAttorney($event.value)">
                <div class="radio-content">
                    <dtm-ui-radio-field [value]="AttorneyPowerType.NationalCourtNumber">
                        {{
                            "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.nationalCourtRegisterPowerOfAttorneyLabel"
                                | transloco
                        }}
                    </dtm-ui-radio-field>
                    <dtm-ui-radio-field [value]="AttorneyPowerType.ConfirmatoryDocument">
                        <span
                            [innerHtml]="
                                'dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.confirmatoryDocumentPowerOfAttorneyLabel'
                                    | transloco
                            "
                        ></span>
                    </dtm-ui-radio-field>
                </div>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="attorneyPowerControl; name: 'required'">
                        {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>

            <button
                class="button-secondary download-attorney-model-document"
                type="button"
                (click)="downloadPowerOfAttorneyModelDocument()"
                *ngIf="attorneyPowerControl.value === AttorneyPowerType.ConfirmatoryDocument"
            >
                <dtm-ui-icon name="download"></dtm-ui-icon>
                {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.getPowerOfAttorneyModelDocument" | transloco }}
            </button>

            <div *ngIf="attorneyPowerControl.value === AttorneyPowerType.ConfirmatoryDocument" @slideIn class="attorney-upload-container">
                <div *ngIf="additionalDataForm.controls.attorneyPowerDocument.enabled">
                    <dtm-ui-files-upload-field
                        [isDownloadAllButtonVisible]="false"
                        [formControl]="attorneyPowerDocumentControl"
                        [maxFileSize]="MAX_FILE_SIZE_BYTES"
                        [allowedTypes]="ATTORNEY_FILE_ALLOWED_MIME_TYPES"
                    >
                        <label>{{
                            "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneyConfirmatoryDocumentLabel"
                                | transloco
                        }}</label>
                        <div class="field-error" *dtmUiFieldHasError="attorneyPowerDocumentControl; name: 'maxlength'">
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneyMultipleFilesError"
                                    | transloco
                            }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="attorneyPowerDocumentControl; name: ['required', 'requiredTouched']">
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-hint">
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneyDocumentUploadHint"
                                    | transloco : { maxFileSize: MAX_FILE_SIZE_BYTES | formatFileSize }
                            }}
                        </div>
                    </dtm-ui-files-upload-field>
                </div>

                <dtm-ui-checkbox-field [formControl]="isDeclarationOfExemptionControl">
                    {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemption" | transloco }}
                    <dtm-ui-popover (click)="$event.preventDefault()">
                        <div class="popover-content">
                            <p>
                                {{
                                    "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionInfo"
                                        | transloco
                                }}
                            </p>
                            <ol>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionFamily"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionAttorneyPower"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionCountries"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionBudgetUnits"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionSelfGovernmentUnits"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionCharityUnits"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionPovertyException"
                                            | transloco
                                    }}
                                </li>
                                <li>
                                    {{
                                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.declarationOfExemptionPopover.declarationOfExemptionDangeredSpeciesProtection"
                                            | transloco
                                    }}
                                </li>
                            </ol>
                        </div>
                    </dtm-ui-popover>
                </dtm-ui-checkbox-field>

                <div *ngIf="additionalDataForm.controls.feeConfirmation.enabled" @slideIn>
                    <dtm-ui-files-upload-field
                        [isDownloadAllButtonVisible]="false"
                        [formControl]="attorneyFeeConfirmationDocumentControl"
                        [maxFileSize]="MAX_FILE_SIZE_BYTES"
                        [allowedTypes]="ATTORNEY_FILE_ALLOWED_MIME_TYPES"
                        dtmWebAppLibOperatorRegistrationAttorneyFeeConfirmationUpload
                    >
                        <label>{{
                            "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneyFeeConfirmationDocumentLabel"
                                | transloco
                        }}</label>
                        <div class="field-error" *dtmUiFieldHasError="attorneyFeeConfirmationDocumentControl; name: 'maxlength'">
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneyMultipleFilesError"
                                    | transloco
                            }}
                        </div>
                        <div
                            class="field-error"
                            *dtmUiFieldHasError="attorneyFeeConfirmationDocumentControl; name: ['required', 'requiredTouched']"
                        >
                            {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                        </div>
                        <div class="field-hint">
                            {{
                                "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.attorneyDocumentUploadHint"
                                    | transloco : { maxFileSize: MAX_FILE_SIZE_BYTES | formatFileSize }
                            }}
                        </div>
                    </dtm-ui-files-upload-field>
                </div>
            </div>

            <dtm-ui-input-field *ngIf="attorneyPowerControl.value === AttorneyPowerType.NationalCourtNumber" @slideIn>
                <label>
                    {{ "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.nationalCourtRegistrationLabel" | transloco }}
                </label>
                <input
                    matInput
                    [formControl]="nationalCourtRegisterControl"
                    [mask]="NATIONAL_COURT_REGISTRATION_MASK"
                    [showMaskTyped]="true"
                />
                <div class="field-error" *dtmUiFieldHasError="nationalCourtRegisterControl; name: ['required', 'pattern']">
                    {{ "dtmWebAppLibPilotOperatorRegistration.requiredFieldError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="nationalCourtRegisterControl; name: 'minlength'; error as error">
                    {{
                        "dtmWebAppLibPilotOperatorRegistration.registerOperator.additionalData.nationalCourtRegistrationMinLengthError"
                            | transloco : { min: error.requiredLength }
                    }}
                </div>
            </dtm-ui-input-field>
        </div>

        <div class="section">
            <h4>{{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceSectionHeader" | transloco }}</h4>
            <dtm-ui-checkbox-field [formControl]="isInsuranceOwnerControl" (change)="updateInsuranceDetailsControl($event)">
                {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceCheckboxText" | transloco }}

                <ng-container checkboxFieldHint>
                    {{ "dtmWebAppLibPilotOperatorRegistration.additionalData.insuranceCheckboxHint" | transloco }}
                </ng-container>
            </dtm-ui-checkbox-field>

            <dtm-web-app-lib-pilot-operator-registration-insurance-form
                *ngIf="isInsuranceOwnerControl.value"
                @slideIn
                [formControl]="insuranceDetailsControl"
                dtmUiMarkValueAccessorControlsAsTouched
            ></dtm-web-app-lib-pilot-operator-registration-insurance-form>
        </div>
    </form>
</dtm-ui-wizard-step-wrapper>
