<ng-container
    *ngrxLet="{
        constraints: constraints$,
        itineraryContent: itineraryContent$,
        currentPlanRoute: currentPlanRoute$,
        settings: settings$,
        isProcessing: isProcessing$,
        isDisabled: isDisabled$
    } as vm"
>
    <dtm-web-app-lib-track-details
        *ngIf="!(vm.itineraryContent | isEmptyArray) && vm.constraints; else emptyTemplate"
        [itineraryContent]="vm.itineraryContent"
        [constraints]="vm.constraints"
        [currentPlanRoute]="vm.currentPlanRoute"
        [settings]="vm.settings"
        [isProcessing]="vm.isProcessing"
        [displayMode]="displayMode"
        [isDisabled]="vm.isDisabled"
        [countPolylinesAsMultipleWaypoints]="false"
        (entityDelete)="entityDelete.emit($event)"
        (pointDelete)="pointDelete.emit($event)"
        (entityUpdate)="entityUpdate.emit($event)"
    ></dtm-web-app-lib-track-details>

    <ng-template #emptyTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.ListView">
            <img imageSlot src="assets/images/itinerary-panel-empty.svg" alt="" />
            <p messageSlot>
                {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.emptyStateMessage" | transloco }}
            </p>

            <button footerSlot [matMenuTriggerFor]="actionsMenu" type="button" class="button-primary" [disabled]="vm.isDisabled">
                {{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.manualPointCreationButtonLabel" | transloco }}
                <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
            </button>
            <mat-menu #actionsMenu="matMenu" class="wide-panel">
                <button (click)="entityAdd.emit({ type: MapEntityType.Cylinder, isManual: true })" mat-menu-item type="button">
                    <span>{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.cylinderLabel" | transloco }}</span>
                </button>
                <button (click)="entityAdd.emit({ type: MapEntityType.Polyline3D, isManual: true })" mat-menu-item type="button">
                    <span>{{ "dtmWebAppLibMission.itineraryEditorStep.itineraryPanel.corridorLabel" | transloco }}</span>
                </button>
            </mat-menu>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>
