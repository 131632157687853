<div class="grid grid-maxsize-8 grid-maxsize-tablet-8" *ngrxLet="{ isSuccess: isSuccess$, isDataMissingError: isDataMissingError$ } as vm">
    <dtm-ui-error *ngIf="vm.isDataMissingError; else loaderTemplate"></dtm-ui-error>
    <ng-template #loaderTemplate>
        <dtm-ui-loader-container *ngIf="vm.isSuccess === undefined; else statusCardTemplate" [isShown]="true">
            <mat-card class="processing"></mat-card>
        </dtm-ui-loader-container>
    </ng-template>
    <ng-template #statusCardTemplate>
        <ng-container [ngSwitch]="vm.isSuccess">
            <mat-card *ngSwitchCase="true" class="status success">
                <section>
                    <h3 class="header">
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignatureStatus.success.header" | transloco }}
                    </h3>
                    <p class="message">
                        {{
                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignatureStatus.success.infoMessage" | transloco
                        }}
                    </p>
                    <button type="button" class="button-primary" routerLink="/operator-permissions">
                        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                        {{
                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignatureStatus.success.goBackToPermissionsList"
                                | transloco
                        }}
                    </button>
                </section>
            </mat-card>
            <mat-card *ngSwitchDefault class="status failure">
                <section>
                    <h3 class="header">
                        {{ "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignatureStatus.failure.header" | transloco }}
                    </h3>
                    <p class="message">
                        {{
                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignatureStatus.failure.infoMessage" | transloco
                        }}
                    </p>
                    <button
                        type="button"
                        class="button-primary"
                        routerLink="/statements"
                        [queryParams]="{ statementId: statementId$ | ngrxPush }"
                    >
                        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                        {{
                            "dtmWebAppLibOperatorPermissions.statementsWizardSteps.statementSignatureStatus.failure.goBackToForm"
                                | transloco
                        }}
                    </button>
                </section>
            </mat-card>
        </ng-container>
    </ng-template>
</div>
