import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ConfirmationDialogComponent, MissionSegmentStatus } from "@dtm-frontend/shared/ui";
import { Mission } from "@dtm-frontend/shared/ui/tactical";
import { MILLISECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { Observable, Subject, interval } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";
import { MissionActivationModalComponent } from "../components/prepare-for-mission/accepted-mission-tile/mission-activation-modal/mission-activation-modal.component";

@Injectable({
    providedIn: "root",
})
export class TacticalMissionService {
    // TODO: DTM-1659 - time should be synchronized with backend
    public readonly currentTime$ = interval(MILLISECONDS_IN_SECOND).pipe(
        startWith(null),
        map(() => new Date())
    );

    private readonly segmentStatuses: Subject<MissionSegmentStatus[]> = new Subject();
    public segmentStatuses$: Observable<MissionSegmentStatus[]> = this.segmentStatuses.asObservable();

    constructor(private readonly matDialog: MatDialog, private readonly transloco: TranslocoService) {}

    public openMissionStartTimeModal(mission: Mission) {
        return this.matDialog
            .open(MissionActivationModalComponent, {
                data: mission,
            })
            .afterClosed()
            .pipe(filter((missionStartTime) => missionStartTime && mission));
    }

    public getMinutesBeforeActivationTime(mission: Mission): number {
        if (!mission.earlyActivationTime) {
            return 0;
        }

        return Math.floor((mission.startTime.min.getTime() - mission.earlyActivationTime.getTime()) / MILLISECONDS_IN_MINUTE);
    }

    public isActivationDisabled(time: Date, mission: Mission): boolean {
        if (!mission.earlyActivationTime) {
            return false;
        }

        return mission.earlyActivationTime.getTime() >= time.getTime();
    }

    public openDeactivateMissionModal() {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibTactical.deactivateConfirmationDialog.title"),
                    confirmationText: this.transloco.translate("dtmWebAppLibTactical.deactivateConfirmationDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibTactical.deactivateConfirmationDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibTactical.deactivateConfirmationDialog.confirmButtonLabel"),
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy());
    }

    public openCancelMissionModal() {
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.transloco.translate("dtmWebAppLibTactical.cancelConfirmationDialog.title"),
                    confirmationText: this.transloco.translate("dtmWebAppLibTactical.cancelConfirmationDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmWebAppLibTactical.cancelConfirmationDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmWebAppLibTactical.cancelConfirmationDialog.confirmButtonLabel"),
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy());
    }

    public publishSegmentStatuses(segmentStatuses: MissionSegmentStatus[]) {
        this.segmentStatuses.next(segmentStatuses);
    }
}
