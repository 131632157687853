import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MissionType } from "@dtm-frontend/shared/mission";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-mission-type-select",
    templateUrl: "./mission-type-select.component.html",
    styleUrls: ["./mission-type-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MissionTypeSelectComponent),
            multi: true,
        },
    ],
})
export class MissionTypeSelectComponent implements ControlValueAccessor {
    protected readonly missionTypeFormGroup = new FormGroup({
        [MissionType.VLOS]: new FormControl<boolean>(false),
        [MissionType.BVLOS]: new FormControl<boolean>(false),
    });

    protected readonly MissionType = MissionType;

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: MissionType[]) => void = FunctionUtils.noop;

    constructor() {
        this.missionTypeFormGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            const missionTypes: MissionType[] = [];

            Object.entries(value).reduce((result, [key, val]) => {
                if (val) {
                    result.push(key as MissionType);
                }

                return result;
            }, missionTypes);

            this.propagateChange(missionTypes);
        });
    }

    public registerOnChange(fn: (value: MissionType[]) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: MissionType[] | null): void {
        const formValue = {
            [MissionType.VLOS]: !!value?.includes(MissionType.VLOS),
            [MissionType.BVLOS]: !!value?.includes(MissionType.BVLOS),
        };

        this.missionTypeFormGroup.setValue(formValue, { emitEvent: false });
    }
}
