<ng-container
    *ngrxLet="{
        operatorContexts: operatorContexts$,
        isHeaderUserButtonShortMode: isHeaderUserButtonShortMode$,
        isLoggedIn: isLoggedIn$,
        userName: userName$,
        avatarUrl: avatarUrl$,
    } as vm"
>
    <dtm-ui-header
        *ngIf="!(isRequiredToAcceptTermsOfService$ | ngrxPush); else emptyHeaderTemplate"
        [routeTitle]="routeTitle$ | ngrxPush"
        [componentPortalItem]="componentPortalItem$ | ngrxPush"
    >
        <dtm-notifications-notifications
            notificationsSlot
            *ngIf="userId$ | ngrxPush as userId"
            [userId]="userId"
            (notificationsListChange)="changeNotificationsList($event)"
            [allowedNotificationTypes]="allowedNotificationTypes"
        >
            <dtm-web-app-lib-notifications
                *ngIf="notificationsList$ | ngrxPush as notificationsList"
                [notificationsList]="notificationsList"
            ></dtm-web-app-lib-notifications>
        </dtm-notifications-notifications>
        <ng-container userButtonSlot>
            <dtm-web-app-lib-operator-context-button
                *ngIf="!!vm.operatorContexts.length; else defaultUserButtonTemplate"
                [operatorContexts]="vm.operatorContexts"
                [selectedContext]="selectedContext$ | ngrxPush"
                [pilot]="pilot$ | ngrxPush"
                [isAllowedToRegisterOperator]="isAllowedToRegisterOperator$ | ngrxPush"
                [isAllowedToRegisterPilot]="isAllowedToRegisterPilot$ | ngrxPush"
                [isShortMode]="vm.isHeaderUserButtonShortMode"
                (operatorSelect)="selectContext($event)"
                (logout)="logout()"
                (redirect)="redirect($event)"
            ></dtm-web-app-lib-operator-context-button>
        </ng-container>
        <ng-template #defaultUserButtonTemplate>
            <dtm-ui-header-user-button
                [isUserLoggedIn]="!!vm.isLoggedIn"
                [isShortMode]="vm.isHeaderUserButtonShortMode"
                [userName]="vm.userName"
                [avatarUrl]="vm.avatarUrl"
                (logout)="logout()"
                (goToUserProfilePage)="redirect('/user')"
            >
                <button
                    mat-menu-item
                    class="action-button"
                    type="button"
                    *ngIf="isAllowedToRegisterOperator$ | ngrxPush"
                    (click)="redirect('/register/operator')"
                >
                    <dtm-ui-icon name="building"></dtm-ui-icon>
                    <span>{{ "webApp.header.addOperatorLabel" | transloco }}</span>
                </button>
                <button
                    mat-menu-item
                    class="action-button"
                    type="button"
                    *ngIf="isAllowedToRegisterPilot$ | ngrxPush"
                    (click)="redirect('/register/pilot')"
                >
                    <dtm-ui-icon name="gamepad"></dtm-ui-icon>
                    <span>{{ "webApp.header.registerPilotProfileLabel" | transloco }}</span>
                </button>
                <button
                    *ngIf="!(isRequiredRegisterNationalNodeAuthenticatedUser$ | ngrxPush)"
                    mat-menu-item
                    class="action-button"
                    type="button"
                    (click)="redirect('/operator-migration')"
                >
                    <dtm-ui-icon name="download"></dtm-ui-icon>
                    <span>{{ "webApp.header.operatorMigrationLabel" | transloco }}</span>
                </button>
                <button mat-menu-item class="action-button" type="button" (click)="redirect('/help-center')">
                    <dtm-ui-icon name="lifebuoy"></dtm-ui-icon>
                    <span>{{ "webApp.header.helpCenterLabel" | transloco }}</span>
                </button>
            </dtm-ui-header-user-button>
        </ng-template>
    </dtm-ui-header>

    <ng-template #emptyHeaderTemplate>
        <dtm-ui-header [routeTitle]="undefined">
            <dtm-ui-header-user-button
                userButtonSlot
                [isUserLoggedIn]="!!vm.isLoggedIn"
                [isShortMode]="vm.isHeaderUserButtonShortMode"
                [userName]="vm.userName"
                [avatarUrl]="vm.avatarUrl"
                (logout)="logout()"
            ></dtm-ui-header-user-button>
        </dtm-ui-header>
    </ng-template>
</ng-container>
