<div
    class="emergency-container"
    *ngrxLet="{ activeEmergency: activeEmergency$, emergencyModalOpened: emergencyModalOpened$, isLoading: isLoading$ } as vm"
>
    <div class="actions">
        <button
            [matMenuTriggerFor]="actionsMenu"
            type="button"
            [ngSwitch]="vm.emergencyModalOpened ?? vm.activeEmergency"
            [disabled]="vm.emergencyModalOpened || vm.isLoading"
            [matTooltip]="vm.emergencyModalOpened ?? vm.activeEmergency | invoke : getEmergencyButtonTooltipKey | transloco"
        >
            <div class="icon-container">
                <dtm-ui-icon name="tools" *ngSwitchCase="EmergencyType.TechnicalIssue" @fade></dtm-ui-icon>
                <dtm-ui-icon name="wifi-off" *ngSwitchCase="EmergencyType.LostControl" @fade></dtm-ui-icon>
                <dtm-ui-icon name="hems-emr" *ngSwitchCase="EmergencyType.EmergencyLanding" @fade></dtm-ui-icon>
                <dtm-ui-icon name="alarm-warning-fill" *ngSwitchDefault @fade></dtm-ui-icon>
                <dtm-ui-loader [isShown]="vm.isLoading"></dtm-ui-loader>
            </div>
        </button>
    </div>

    <mat-menu #actionsMenu="matMenu" [hidden]="vm.emergencyModalOpened" [ngSwitch]="vm.activeEmergency" class="wide-panel">
        <ng-container *ngSwitchDefault>
            <button mat-menu-item type="button" (click)="openEmergencyModal(EmergencyType.TechnicalIssue)">
                <dtm-ui-icon name="tools"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibTactical.emergency.technicalProblemsButtonLabel" | transloco }}</span>
            </button>
            <!-- TODO: DTM-4215 remove disabled when different EMR types will be handled -->
            <!--    <button mat-menu-item type="button" (click)="openEmergencyModal(EmergencyType.LostControl)">
                <dtm-ui-icon name="wifi-off"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibTactical.emergency.communicationProblemsButtonLabel" | transloco }}</span>
            </button>
            <button mat-menu-item type="button" (click)="openEmergencyModal(EmergencyType.EmergencyLanding)">
                <dtm-ui-icon name="hems-emr"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibTactical.emergency.flightToEmrButtonLabel" | transloco }}</span>
            </button>-->
        </ng-container>
        <button mat-menu-item type="button" (click)="cancelEmergency()" *ngSwitchCase="EmergencyType.TechnicalIssue">
            <dtm-ui-icon name="tools"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibTactical.emergency.technicalProblems.cancelEmergencyButtonText" | transloco }}</span>
        </button>
        <button mat-menu-item type="button" (click)="cancelEmergency()" *ngSwitchCase="EmergencyType.LostControl">
            <dtm-ui-icon name="wifi-off"></dtm-ui-icon>
            <span>{{ "dtmWebAppLibTactical.emergency.communicationProblems.cancelEmergencyButtonText" | transloco }}</span>
        </button>
        <ng-container *ngSwitchCase="EmergencyType.EmergencyLanding">
            <button mat-menu-item type="button" (click)="cancelEmergency()">
                <dtm-ui-icon name="hems-emr"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibTactical.emergency.flightToEmr.cancelEmergencyButtonText" | transloco }}</span>
            </button>
            <!-- TODO: DTM-4215 remove disabled when support to change EMR is added -->
            <button mat-menu-item type="button" disabled>
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                <span>{{ "dtmWebAppLibTactical.emergency.flightToEmr.changeEmrButtonText" | transloco }}</span>
            </button>
        </ng-container>
    </mat-menu>

    <div class="modal-container" *ngIf="vm.emergencyModalOpened === EmergencyType.TechnicalIssue">
        <h2>{{ "dtmWebAppLibTactical.emergency.technicalProblems.title" | transloco }}</h2>
        <p class="text">{{ "dtmWebAppLibTactical.emergency.technicalProblems.text" | transloco }}</p>
        <div class="button-container">
            <button class="button-secondary" type="button" (click)="closeEmergencyModal()">
                {{ "dtmWebAppLibTactical.emergency.cancelButtonLabel" | transloco }}
            </button>
            <button class="button-warn" type="button" (click)="reportEmergency(EmergencyType.TechnicalIssue)">
                {{ "dtmWebAppLibTactical.emergency.reportButtonLabel" | transloco }}
            </button>
        </div>
    </div>

    <div class="modal-container" *ngIf="vm.emergencyModalOpened === EmergencyType.LostControl">
        <h2>{{ "dtmWebAppLibTactical.emergency.communicationProblems.title" | transloco }}</h2>
        <p class="text">{{ "dtmWebAppLibTactical.emergency.communicationProblems.text" | transloco }}</p>
        <div class="button-container">
            <button class="button-secondary" type="button" (click)="closeEmergencyModal()">
                {{ "dtmWebAppLibTactical.emergency.cancelButtonLabel" | transloco }}
            </button>
            <button class="button-warn" type="button" (click)="reportEmergency(EmergencyType.LostControl)">
                {{ "dtmWebAppLibTactical.emergency.reportButtonLabel" | transloco }}
            </button>
        </div>
    </div>

    <div class="modal-container" *ngIf="vm.emergencyModalOpened === EmergencyType.EmergencyLanding">
        <h2>{{ "dtmWebAppLibTactical.emergency.flightToEmr.title" | transloco }}</h2>
        <p class="text">{{ "dtmWebAppLibTactical.emergency.flightToEmr.text" | transloco }}</p>
        <div class="button-container">
            <button class="button-secondary" type="button" (click)="closeEmergencyModal()">
                {{ "dtmWebAppLibTactical.emergency.cancelButtonLabel" | transloco }}
            </button>
            <button class="button-warn" type="button" (click)="reportEmergency(EmergencyType.EmergencyLanding)">
                {{ "dtmWebAppLibTactical.emergency.reportButtonLabel" | transloco }}
            </button>
        </div>
    </div>
</div>
