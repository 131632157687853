import { Injectable } from "@angular/core";
import { MISSION_PLANNER_MAX_HOURS_AFTER, MISSION_PLANNER_MIN_HOURS_BEFORE } from "@dtm-frontend/shared/map";
import {
    CameraHelperService,
    ChildEntity,
    CylinderEditorLabelProviders,
    CylinderPointProps,
    EntityEditorConstraints,
    EntityManualUpdateOptions,
    HeightEntityType,
    ManualCoordinatesInputEntityType,
    MapActionType,
    MapEntitiesEditorService,
    MapEntity,
    MapEntityType,
    MapUtils,
    Polyline3DEditorLabelProviders,
    Polyline3DEntity,
    PrismEditorLabelProviders,
    SegmentLabelType,
    SerializableCartographic,
} from "@dtm-frontend/shared/map/cesium";
import { WeatherActions, WeatherViewMode } from "@dtm-frontend/shared/map/geo-weather";
import { ItineraryEditorType, MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { ISO8601TimeDuration, StringUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, lastValueFrom, skip } from "rxjs";
import { distinctUntilChanged, first, map, shareReplay, takeUntil, tap } from "rxjs/operators";
import { ItineraryContent, ItineraryContentEntity, ItineraryContentMetadata, Polyline3DItineraryEntity } from "../models/itinerary.model";
import { MissionActions } from "../state/mission.actions";
import { MissionState } from "../state/mission.state";
import {
    ItineraryEditorFormData,
    ItineraryEditorMissionParametersFormData,
    MissionPlanAssistedItinerary,
    MissionPlanCustomItineraryEntity,
    MissionPlanItinerary,
    MissionPlanItineraryConstraints,
    MissionPlanStandardItinerary,
    WaypointEntity,
    WaypointFlightZone,
} from "./../models/mission.model";
import { AssistedEntityId, convertMissionPlanItineraryConstraintsToAssistedEntityEditorConstraints } from "./mission-api.converters";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Cesium: any; // TODO: DTM-966

const SINGLE_ENTITY_ASSISTED_EDITOR_ZOOM_SCALE = 50;

const CUSTOM_EDITOR_CYLINDER_POINT_PROPS: CylinderPointProps = {
    color: Cesium.Color.fromCssColorString("#061636"), // $color-gray-900
    outlineWidth: 0,
    pixelSize: 8,
    virtualPointPixelSize: 6,
    radiusColor: Cesium.Color.fromCssColorString("#637292"), // $color-gray-300
    radiusOutlineWidth: 0,
    radiusPixelSize: 6,
    disableDepthTestDistance: Number.POSITIVE_INFINITY,
};

interface UpdatedMapEntity {
    entity: MapEntity;
    startIndex?: number;
    updateOptions?: EntityManualUpdateOptions;
}

export type StandardItineraryEntity =
    | { type: MapEntityType; isManual: false }
    | {
          type: ManualCoordinatesInputEntityType;
          isManual: true;
      };

export type CustomItineraryEntity =
    | { type: MapEntityType; waypointIndex?: never; polyline3DEntityId?: never; stopover?: never }
    | {
          type: MapEntityType.Cylinder | MapEntityType.Prism;
          waypointIndex: number;
          polyline3DEntityId: string;
          stopover: ISO8601TimeDuration;
      };

export interface AssistedItineraryEntity {
    type: MapEntityType.Cylinder;
    entityId: AssistedEntityId;
}

export interface AssistedItineraryDataEntity extends AssistedItineraryEntity {
    centerLatitude: number;
    centerLongitude: number;
    constraints: MissionPlanItineraryConstraints;
}

export type ItineraryEntity = CustomItineraryEntity | AssistedItineraryDataEntity | StandardItineraryEntity;

@UntilDestroy()
@Injectable()
export class ItineraryService {
    private watchForNumberOfWaypointsSubscription: Subscription | undefined;
    private assistedEditorDrawingSubscription: Subscription | undefined;

    private readonly editorTypeChangedSubject = new Subject<void>();
    private readonly metadataSubject = new BehaviorSubject<ItineraryContentMetadata>({});

    public readonly itineraryContent$ = this.prepareItineraryContent();
    public readonly activeMapAction$ = this.entitiesEditorService.activeMapAction$;
    public readonly activeEntityStatus$ = this.entitiesEditorService.activeEntityStatus$;
    public readonly areEditorsDisabled$ = this.entitiesEditorService.areEditorsDisabled$;

    private readonly customEditorCylinderLabelProviders: CylinderEditorLabelProviders = {
        radius: this.cylinderEditorRadiusLabelProvider.bind(this),
        topHeight: this.heightBoundariesLabelProvider.bind(this, HeightEntityType.Top),
        bottomHeight: this.heightBoundariesLabelProvider.bind(this, HeightEntityType.Bottom),
        waypoint: (entityId) => this.getWaypointLabel(entityId),
    };
    private readonly standardEditorCylinderLabelProviders: CylinderEditorLabelProviders = {
        radius: this.cylinderEditorRadiusLabelProvider.bind(this),
        waypoint: (entityId) => this.getWaypointLabel(entityId, undefined, false),
    };
    private readonly standardEditorPrismLabelProviders: PrismEditorLabelProviders = {
        waypoint: (entityId) => this.getWaypointLabel(entityId, undefined, false),
    };
    private readonly standardEditorPolyline3DLabelProviders: Polyline3DEditorLabelProviders = {
        waypoint: ({ entityId, index }) => (index === 0 ? this.getWaypointLabel(entityId, 0, false) : ""),
    };
    private readonly customEditorPolyline3DLabelProviders: Polyline3DEditorLabelProviders = {
        pointHeight: ({ entityId, index, height }) => this.getPointHeightLabel(entityId, index, height),
        waypoint: ({ entityId, index }) => this.getWaypointLabel(entityId, index),
        segment: ({ entityId, index }) => this.getSegmentLabel(entityId, index),
    };
    private readonly customEditorPrismLabelProviders: PrismEditorLabelProviders = {
        topHeight: this.heightBoundariesLabelProvider.bind(this, HeightEntityType.Top),
        bottomHeight: this.heightBoundariesLabelProvider.bind(this, HeightEntityType.Bottom),
        waypoint: (entityId) => this.getWaypointLabel(entityId),
    };
    public readonly assistedEditorCylinderLabelProviders: CylinderEditorLabelProviders = {
        radius: this.cylinderEditorRadiusLabelProvider.bind(this),
        topHeight: this.heightBoundariesLabelProvider.bind(this, HeightEntityType.Top),
        bottomHeight: this.heightBoundariesLabelProvider.bind(this, HeightEntityType.Bottom),
    };

    constructor(
        private readonly entitiesEditorService: MapEntitiesEditorService,
        private readonly cameraHelperService: CameraHelperService,
        private readonly translationHelper: TranslationHelperService,
        private readonly store: Store
    ) {}

    private zoomOnFirstDrawSignal() {
        this.itineraryContent$
            .pipe(
                first((content) => content.length > 0),
                tap(() => this.showEntireContent()),
                untilDestroyed(this),
                takeUntil(this.editorTypeChangedSubject)
            )
            .subscribe();
    }

    private refreshLabelsWhenNumberOfWaypointChanges() {
        if (this.watchForNumberOfWaypointsSubscription?.closed === false) {
            return;
        }

        this.watchForNumberOfWaypointsSubscription = this.itineraryContent$
            .pipe(
                map((content) => content.length > 0 && this.getWaypointLabel(content[content.length - 1].id)),
                distinctUntilChanged(),
                untilDestroyed(this),
                takeUntil(this.editorTypeChangedSubject)
            )
            .subscribe(() => this.entitiesEditorService.refreshLabels());
    }

    private heightBoundariesLabelProvider(type: HeightEntityType, height: number) {
        const unitTranslation = this.translationHelper.tryTranslate("dtmWebAppLibMission.heightAndRadiusUnitLabel");
        const heightTranslation = this.translationHelper.tryTranslate(
            type === HeightEntityType.Top ? "dtmWebAppLibMission.topHeightLabel" : "dtmWebAppLibMission.bottomHeightLabel"
        );

        return unitTranslation && heightTranslation && `${heightTranslation}: ${Math.round(height)} ${unitTranslation}`;
    }

    private cylinderEditorRadiusLabelProvider(radius: number) {
        const unitTranslation = this.translationHelper.tryTranslate("dtmWebAppLibMission.heightAndRadiusUnitLabel");
        const radiusTranslation = this.translationHelper.tryTranslate("dtmWebAppLibMission.cylinderRadiusLabel");

        return unitTranslation && radiusTranslation && `${radiusTranslation}: ${Math.round(radius)} ${unitTranslation}`;
    }

    public async addStandardItineraryEntity(entity: StandardItineraryEntity, constraints: EntityEditorConstraints) {
        const standardEntityId = StringUtils.generateId();

        switch (entity.type) {
            case MapEntityType.Cylinder:
                this.entitiesEditorService.startCylinderEditor(standardEntityId, constraints, this.standardEditorCylinderLabelProviders);

                break;

            case MapEntityType.Prism:
                this.entitiesEditorService.startPrismEditor(standardEntityId, constraints, this.standardEditorPrismLabelProviders);

                break;

            case MapEntityType.Polyline3D:
                this.entitiesEditorService.startPolylines3DEditor(
                    standardEntityId,
                    constraints,
                    this.standardEditorPolyline3DLabelProviders,
                    {
                        customMapAction: MapActionType.DrawPolylineCorridor,
                    }
                );
        }
    }

    public async createStandardItineraryEntity(
        entity: StandardItineraryEntity,
        constraints: MissionPlanItineraryConstraints,
        coordinates: SerializableCartographic[],
        size: number
    ) {
        const standardEntityId = StringUtils.generateId();

        switch (entity.type) {
            case MapEntityType.Cylinder:
                this.createEditableCylinder(
                    coordinates[0].latitude,
                    coordinates[0].longitude,
                    standardEntityId,
                    {
                        ...constraints,
                        default: {
                            ...constraints.default,
                            size,
                        },
                    },
                    this.standardEditorCylinderLabelProviders
                );

                break;

            case MapEntityType.Polyline3D: {
                const transformedConstraints = this.transformConstraints(constraints);
                const positions = coordinates.map(MapUtils.convertSerializableCartographicToCartesian3);
                const heights = coordinates.map(() => transformedConstraints.default.topHeight ?? 1);
                const bufferWidths = coordinates.map(() => size);
                const bufferHeights = coordinates.map(() => transformedConstraints.default.verticalNavigationAccuracy ?? 0);

                this.entitiesEditorService.createEditablePolyline3D(
                    positions,
                    heights,
                    bufferWidths,
                    bufferHeights,
                    standardEntityId,
                    transformedConstraints,
                    this.standardEditorPolyline3DLabelProviders
                );
                break;
            }

            default:
                throw new Error(`Invalid entity type: ${entity.type}`);
        }
    }

    private recreateChildEntitiesAfterHeightChange(
        polyline: Polyline3DItineraryEntity,
        newHeights: number[],
        constraints: EntityEditorConstraints
    ) {
        const entitiesToRecreate = Object.values(polyline.childEntities)
            .filter((childEntity) => childEntity.waypointIndex !== polyline.heights.length - 1 && childEntity.waypointIndex !== 0)
            .map((childEntity) => {
                const halfVerticalBuffer = polyline.bufferHeights[childEntity.waypointIndex] / 2;
                const height = newHeights[childEntity.waypointIndex];

                if (Number.isNaN(halfVerticalBuffer) || height === undefined) {
                    return childEntity;
                }

                const topHeight = height + halfVerticalBuffer;
                const bottomHeight = height - halfVerticalBuffer;

                return {
                    ...childEntity,
                    entity: {
                        ...childEntity.entity,
                        topHeight,
                        bottomHeight,
                    },
                } satisfies ChildEntity;
            });

        entitiesToRecreate.sort((left, right) => -(left.waypointIndex - right.waypointIndex));
        entitiesToRecreate.forEach((childEntity) => this.entitiesEditorService.delete(polyline, childEntity.waypointIndex));
        entitiesToRecreate.reverse();
        entitiesToRecreate.forEach((childEntity) => {
            switch (childEntity.entity.type) {
                case MapEntityType.Cylinder:
                    this.entitiesEditorService.createEditableCylinder(
                        childEntity.entity.center,
                        childEntity.entity.id,
                        constraints,
                        this.customEditorCylinderLabelProviders,
                        childEntity.entity.radius,
                        childEntity.entity.topHeight,
                        {
                            bottomHeight: childEntity.entity.bottomHeight,
                            parentEntityId: polyline.id,
                            parentWaypointIndex: childEntity.waypointIndex - 2,
                        }
                    );

                    break;

                case MapEntityType.Prism:
                    this.entitiesEditorService.createEditablePrism(
                        childEntity.entity.positions,
                        childEntity.entity.id,
                        constraints,
                        this.customEditorPrismLabelProviders,
                        {
                            topHeight: childEntity.entity.topHeight,
                            bottomHeight: childEntity.entity.bottomHeight,
                            parentEntityId: polyline.id,
                            parentWaypointIndex: childEntity.waypointIndex - 2,
                        }
                    );

                    break;
            }
        });

        const updatedPolyline = this.entitiesEditorService.editorContentValue.find(
            (entity) => entity.id === polyline.id && entity.type === MapEntityType.Polyline3D
        ) as Polyline3DItineraryEntity | undefined;

        if (updatedPolyline) {
            this.entitiesEditorService.update({
                ...updatedPolyline,
                positions: polyline.positions,
            });
        }
    }

    public async addCustomItineraryEntity(entity: CustomItineraryEntity, constraints: EntityEditorConstraints) {
        const customEntityId = StringUtils.generateId();
        let topHeight;
        let bottomHeight;

        if (entity.polyline3DEntityId) {
            const content = await lastValueFrom(this.itineraryContent$.pipe(first(), untilDestroyed(this)));
            const polyline = content.find(
                (lookup) => lookup.id === entity.polyline3DEntityId && lookup.type === MapEntityType.Polyline3D
            ) as Polyline3DItineraryEntity;

            if (polyline) {
                this.cameraHelperService.flyToSegment(polyline, entity.waypointIndex);

                this.updateEntityMetadata({
                    entityId: entity.polyline3DEntityId,
                    type: MapEntityType.Polyline3D,
                    stopovers: {
                        ...polyline.metadata?.stopovers,
                        [customEntityId]: entity.stopover,
                    },
                });

                const halfVerticalBuffer = polyline.bufferHeights[entity.waypointIndex] / 2;
                const height = polyline.heights[entity.waypointIndex];

                if (!Number.isNaN(halfVerticalBuffer) && height !== undefined) {
                    topHeight = height + halfVerticalBuffer;
                    bottomHeight = height - halfVerticalBuffer;
                }
            }
        }

        switch (entity.type) {
            case MapEntityType.Cylinder:
                this.entitiesEditorService.startCylinderEditor(customEntityId, constraints, this.customEditorCylinderLabelProviders, {
                    parentEntityId: entity.polyline3DEntityId,
                    parentWaypointIndex: entity.waypointIndex,
                    pointProps: CUSTOM_EDITOR_CYLINDER_POINT_PROPS,
                    topHeight,
                    bottomHeight,
                });

                break;

            case MapEntityType.Prism:
                this.entitiesEditorService.startPrismEditor(customEntityId, constraints, this.customEditorPrismLabelProviders, {
                    parentEntityId: entity.polyline3DEntityId,
                    parentWaypointIndex: entity.waypointIndex,
                    pointProps: CUSTOM_EDITOR_CYLINDER_POINT_PROPS,
                    topHeight,
                    bottomHeight,
                });

                break;

            case MapEntityType.Polyline3D: {
                this.entitiesEditorService.startPolylines3DEditorWithRunways(
                    customEntityId,
                    constraints,
                    {
                        pointHeight: ({ entityId, index, height }) => this.getPointHeightLabel(entityId, index, height),
                        waypoint: ({ entityId, index }) => this.getWaypointLabel(entityId, index),
                        segment: ({ entityId, index }) => this.getSegmentLabel(entityId, index),
                    },
                    {
                        radius: this.cylinderEditorRadiusLabelProvider.bind(this),
                    },
                    CUSTOM_EDITOR_CYLINDER_POINT_PROPS,
                    CUSTOM_EDITOR_CYLINDER_POINT_PROPS
                );

                break;
            }
        }
    }

    public addAssistedItineraryEntity(entity: AssistedItineraryEntity, constraints: EntityEditorConstraints, mapAction?: MapActionType) {
        this.entitiesEditorService.startCylinderEditor(entity.entityId, constraints, this.assistedEditorCylinderLabelProviders, {
            radius: constraints.default.radius,
            customMapAction: mapAction,
        });

        this.updateAssistedDrawingAndViewingMode(entity, constraints);
    }

    private updateAssistedDrawingAndViewingMode(entity: AssistedItineraryEntity, constraints: EntityEditorConstraints) {
        this.assistedEditorDrawingSubscription?.unsubscribe();
        this.assistedEditorDrawingSubscription = this.itineraryContent$
            .pipe(
                skip(1),
                first((contentItems) => !!contentItems.find((item) => item.id === entity.entityId)),
                takeUntil(this.editorTypeChangedSubject),
                untilDestroyed(this)
            )
            .subscribe((content) => {
                // NOTE: if first entity is added we want to see bigger part of map
                this.cameraHelperService.flyToContent(content, content.length === 1 ? SINGLE_ENTITY_ASSISTED_EDITOR_ZOOM_SCALE : undefined);

                const takeoffEntity = content.find((item) => item.id === AssistedEntityId.Takeoff);
                const landingEntity = content.find((item) => item.id === AssistedEntityId.Landing);

                // NOTE: if other assisted entity is missing we want to switch drawing to this entity
                if (!takeoffEntity || !landingEntity) {
                    this.addAssistedItineraryEntity(
                        { ...entity, entityId: !takeoffEntity ? AssistedEntityId.Takeoff : AssistedEntityId.Landing },
                        constraints,
                        !takeoffEntity ? MapActionType.DrawAssistedTakeoffRunway : MapActionType.DrawAssistedLandingRunway
                    );
                }
            });
    }

    public getWaypointLabel(entityId: string, subWaypointIndex = 0, countPolylinesAsMultipleWaypoints = true): string {
        const waypointIndex = this.getWaypointIndex(entityId, subWaypointIndex, countPolylinesAsMultipleWaypoints);

        return "A" + (waypointIndex + 1).toString();
    }

    private getWaypointIndex(entityId: string, subWaypointIndex: number, countPolylinesAsMultipleWaypoints = true) {
        const content = this.entitiesEditorService.editorContentValue;
        let result = 0;

        for (let index = 0; index < content.length; index++) {
            const entity = content[index];

            if (entity.id === entityId) {
                break;
            }

            if (entity.type === MapEntityType.Polyline3D && countPolylinesAsMultipleWaypoints) {
                result += entity.positions.length;
            } else {
                result += 1;
            }
        }

        result += subWaypointIndex;

        return result;
    }

    public getPointHeightLabel(entityId: string, subWaypointIndex: number, height: number) {
        return {
            height,
            waypointIndex: this.getWaypointIndex(entityId, subWaypointIndex),
            waypointLabel: this.getWaypointLabel(entityId, subWaypointIndex),
        };
    }

    private getSegmentLabel(entityId: string, subWaypointIndex: number) {
        const content = this.entitiesEditorService.editorContentValue;
        const waypointIndex = this.getWaypointIndex(entityId, subWaypointIndex);
        const polylineEntity = content.find((entity) => entity.id === entityId && entity.type === MapEntityType.Polyline3D) as
            | Polyline3DEntity
            | undefined;

        if (!polylineEntity) {
            return {
                waypointIndex,
                type: SegmentLabelType.Segment,
            };
        }

        const childEntity = Object.values(polylineEntity.childEntities).find((entity) => entity.waypointIndex === subWaypointIndex);

        let type = SegmentLabelType.Segment;
        if ((polylineEntity?.positions.length ?? 0) - 1 === subWaypointIndex) {
            type = SegmentLabelType.LastChild;
        } else if (polylineEntity && subWaypointIndex === 0) {
            type = SegmentLabelType.FirstChild;
        } else if (childEntity) {
            type = childEntity.entity.type === MapEntityType.Cylinder ? SegmentLabelType.InternalCylinder : SegmentLabelType.InternalPrism;
        }

        return {
            waypointIndex: this.getWaypointIndex(entityId, subWaypointIndex),
            type,
            topHeight: childEntity?.entity.topHeight,
            bottomHeight: childEntity?.entity.bottomHeight,
        };
    }

    public startOrEnableEditor(editorToStart: ItineraryEditorType, planConstraints: MissionPlanItineraryConstraints): void {
        if (this.entitiesEditorService.areEditorsDisabled) {
            this.entitiesEditorService.enableEditors();

            return;
        }

        this.editorTypeChangedSubject.next();

        const constraints = this.transformConstraints(planConstraints);

        switch (editorToStart) {
            case ItineraryEditorType.Standard:
                constraints.min.bottomHeight = 0;
                constraints.max.bottomHeight = 0; // NOTE: lock bottom height

                this.zoomOnFirstDrawSignal();

                return;

            case ItineraryEditorType.Assisted:
                constraints.min.radius = constraints.min.runwayHorizontalNavigationAccuracy ?? 1;
                constraints.default.radius = constraints.default.runwayHorizontalNavigationAccuracy ?? constraints.default.radius;
                constraints.min.bottomHeight = 0;
                constraints.max.bottomHeight = 0; // NOTE: lock bottom height
                constraints.min.topHeight = constraints.default.topHeight;
                constraints.max.topHeight = constraints.default.topHeight; // NOTE: lock top height

                return;

            case ItineraryEditorType.Custom:
                this.refreshLabelsWhenNumberOfWaypointChanges();
                this.zoomOnFirstDrawSignal();

                return;

            default:
                throw new Error(`Invalid editor type: ${editorToStart}`);
        }
    }

    public clearItinerary() {
        this.entitiesEditorService.stopEditors();
        this.store.dispatch(new MissionActions.ClearItinerary());
    }

    public finishActiveEntityDrawing() {
        this.entitiesEditorService.finishActiveEntityDrawing();
    }

    public removeLastPointFromActiveEntity() {
        this.entitiesEditorService.removeLastPointFromActiveEntity();
    }

    public cancelActiveEntityDrawing() {
        this.entitiesEditorService.cancelActiveEntityDrawing();
    }

    public async disableEditors() {
        if ((await lastValueFrom(this.itineraryContent$.pipe(first(), untilDestroyed(this)))).length === 0) {
            this.entitiesEditorService.stopEditors();
        }

        this.entitiesEditorService.disableEditors();
    }

    public async enableEditors() {
        this.entitiesEditorService.enableEditors();
    }

    public update(entity: ItineraryContentEntity) {
        this.entitiesEditorService.update(entity);
    }

    public deleteItineraryEntity(entity: ItineraryContentEntity, index: number) {
        this.entitiesEditorService.delete(entity, index);
    }

    public deletePolyline3DWaypoint(entity: Polyline3DItineraryEntity, index: number) {
        this.entitiesEditorService.deletePolyline3DWaypoint(entity, index);
    }

    public async showEntireContent() {
        const content = await lastValueFrom(this.itineraryContent$.pipe(first(), untilDestroyed(this)));

        this.cameraHelperService.flyToContent(content);
    }

    private createEditableCylinder(
        centerLatitude: number,
        centerLongitude: number,
        entityId: string,
        constraints: MissionPlanItineraryConstraints,
        labelProviders: CylinderEditorLabelProviders,
        flightZone?: WaypointFlightZone
    ) {
        const cylinderCenter = MapUtils.convertSerializableCartographicToCartesian3({
            height: 0,
            latitude: centerLatitude,
            longitude: centerLongitude,
        });

        const transformedConstraints = this.transformConstraints(constraints, flightZone);

        this.entitiesEditorService.createEditableCylinder(
            cylinderCenter,
            entityId,
            transformedConstraints,
            labelProviders,
            transformedConstraints.default.radius,
            transformedConstraints.default.topHeight
        );
    }

    private createEditablePrismFromWaypointFlightZone(
        flightZone: WaypointFlightZone,
        constraints: MissionPlanItineraryConstraints,
        index: number,
        labelProviders: PrismEditorLabelProviders
    ) {
        if (flightZone.area?.geometry?.type !== "Polygon" || !flightZone.area.properties) {
            return;
        }

        this.entitiesEditorService.createEditablePrism(
            MapUtils.convertGeoJsonPolygonGeometryToCartesian3(flightZone.area.geometry),
            `${flightZone.area.properties.type}_${index}`,
            this.transformConstraints(constraints, flightZone),
            labelProviders
        );
    }

    private createEditablePolyline3DForStandardEditor(
        points: number[][],
        flightZone: WaypointFlightZone,
        constraints: MissionPlanItineraryConstraints,
        index: number
    ) {
        const transformedConstraints = this.transformConstraints(constraints, flightZone);
        const positions = points.map((point) =>
            MapUtils.convertSerializableCartographicToCartesian3({
                height: 0,
                latitude: point[1],
                longitude: point[0],
            })
        );
        const heights = points.map(() => flightZone.maxH ?? 1);
        const bufferWidths =
            flightZone.area?.properties?.bufferWidths ?? points.map(() => transformedConstraints.default.horizontalNavigationAccuracy ?? 0);
        const bufferHeights = points.map(() => transformedConstraints.default.verticalNavigationAccuracy ?? 0);

        const polylineEntityId = `${MapEntityType.Polyline3D}_${index}`;

        this.entitiesEditorService.createEditablePolyline3D(
            positions,
            heights,
            bufferWidths,
            bufferHeights,
            polylineEntityId,
            transformedConstraints,
            {}
        );
    }

    private createEditablePolyline3DFromWaypoints(
        waypoints: WaypointEntity[],
        constraints: MissionPlanItineraryConstraints,
        entityIndex: number
    ) {
        const positions = waypoints.map((waypoint) =>
            MapUtils.convertSerializableCartographicToCartesian3({
                height: 0,
                latitude: waypoint.position.lat,
                longitude: waypoint.position.lon,
            })
        );
        const heights = waypoints.map((waypoint) => waypoint.position.h);
        const bufferWidths = waypoints.map((waypoint) => (waypoint.navigationAccuracy?.horizontal ?? 0) * 2);
        const bufferHeights = waypoints.map((waypoint) => (waypoint.navigationAccuracy?.vertical ?? 0) * 2);

        const polylineEntityId = `${MapEntityType.Polyline3D}_${entityIndex}`;
        const transformedConstraints = this.transformConstraints(constraints);

        this.entitiesEditorService.createEditablePolyline3D(
            positions,
            heights,
            bufferWidths,
            bufferHeights,
            polylineEntityId,
            transformedConstraints,
            this.customEditorPolyline3DLabelProviders
        );

        waypoints.forEach((waypoint, index) => {
            if (!waypoint.flightZone?.area?.properties?.type) {
                return;
            }

            const horizontalNavigationAccuracy = transformedConstraints.min.horizontalNavigationAccuracy;
            const waypointConstraints: EntityEditorConstraints = {
                ...transformedConstraints,
                min: {
                    ...transformedConstraints.min,
                    radius:
                        waypoint.runway && horizontalNavigationAccuracy !== undefined
                            ? horizontalNavigationAccuracy
                            : transformedConstraints.min.radius,
                },
            };

            switch (waypoint.flightZone.area.properties.type) {
                case MapEntityType.Cylinder: {
                    this.entitiesEditorService.setChildEntityCylinderForPolyline3D(
                        positions[index],
                        waypoint.flightZone.area.properties.radius,
                        waypoint.flightZone.maxH ?? 0,
                        waypoint.flightZone.minH,
                        `${polylineEntityId}_cylinder_${index}`,
                        waypointConstraints,
                        this.customEditorCylinderLabelProviders,
                        polylineEntityId,
                        index
                    );
                    break;
                }

                case MapEntityType.Prism: {
                    this.entitiesEditorService.setChildEntityPrismForPolyline3D(
                        MapUtils.convertGeoJsonPolygonGeometryToCartesian3(waypoint.flightZone.area.geometry),
                        waypoint.flightZone.maxH ?? 0,
                        waypoint.flightZone.minH,
                        `${polylineEntityId}_prism_${index}`,
                        transformedConstraints,
                        this.customEditorPrismLabelProviders,
                        polylineEntityId,
                        index
                    );
                    break;
                }
            }
        });
    }

    public createEditorsFromItinerary(itinerary: MissionPlanItinerary, constraints: MissionPlanItineraryConstraints) {
        switch (itinerary.type) {
            case ItineraryEditorType.Standard: {
                this.createStandardItineraryEditorFromItinerary(itinerary, constraints);

                break;
            }

            case ItineraryEditorType.Custom: {
                this.createCustomItineraryEditorFromItinerary(itinerary, constraints);

                break;
            }

            case ItineraryEditorType.Assisted: {
                this.createAssistedItineraryEditorFromItinerary(itinerary, constraints);
                break;
            }

            default: {
                throw new Error(`Unknown itinerary type: ${itinerary.type}`);
            }
        }
    }

    private createCustomItineraryEditorFromItinerary(
        itinerary: MissionPlanCustomItineraryEntity,
        constraints: MissionPlanItineraryConstraints
    ) {
        let processingPolyline = false;

        const individualEntities = itinerary.points.reduce<{ type: MapEntityType; waypoints: WaypointEntity[] }[]>((result, waypoint) => {
            let isRunway = false;
            if (waypoint.flightZone && waypoint.runway === true) {
                processingPolyline = !processingPolyline;
                isRunway = true;
            }

            if (!isRunway && !processingPolyline && waypoint.flightZone?.area?.properties?.type) {
                result.push({
                    type: waypoint.flightZone.area.properties.type as MapEntityType,
                    waypoints: [waypoint],
                });

                return result;
            }

            const polyline = result[result.length - 1];
            if (polyline?.type !== MapEntityType.Polyline3D) {
                result.push({
                    type: MapEntityType.Polyline3D,
                    waypoints: [waypoint],
                });
            } else {
                polyline.waypoints.push(waypoint);
            }

            return result;
        }, []);

        individualEntities.forEach(({ type, waypoints }, index) => {
            if (type === MapEntityType.Cylinder && waypoints[0].flightZone?.area?.properties?.center) {
                this.createEditableCylinder(
                    waypoints[0].flightZone.area.properties.center[1],
                    waypoints[0].flightZone.area.properties.center[0],
                    `${type}_${index}`,
                    constraints,
                    this.customEditorCylinderLabelProviders,
                    waypoints[0].flightZone
                );
            } else if (type === MapEntityType.Prism && waypoints[0].flightZone) {
                this.createEditablePrismFromWaypointFlightZone(
                    waypoints[0].flightZone,
                    constraints,
                    index,
                    this.customEditorPrismLabelProviders
                );
            } else if (type === MapEntityType.Polyline3D) {
                this.createEditablePolyline3DFromWaypoints(waypoints, constraints, index);
            }
        });
    }

    private createStandardItineraryEditorFromItinerary(
        itinerary: MissionPlanStandardItinerary,
        constraints: MissionPlanItineraryConstraints
    ) {
        itinerary.elements.forEach((element, index) => {
            if (!element.flightZone?.area?.properties) {
                console.warn("Invalid element: ", element);

                return;
            }

            const properties = element.flightZone.area.properties;
            const type = properties.type as MapEntityType | undefined;

            if (type === MapEntityType.Cylinder && properties.center) {
                this.createEditableCylinder(
                    properties.center[1],
                    properties.center[0],
                    StringUtils.generateId(),
                    constraints,
                    this.standardEditorCylinderLabelProviders,
                    element.flightZone
                );
            } else if (type === MapEntityType.Prism) {
                this.createEditablePrismFromWaypointFlightZone(element.flightZone, constraints, index, {});
            } else if (type === MapEntityType.Polyline3D) {
                this.createEditablePolyline3DForStandardEditor(properties.points, element.flightZone, constraints, index);
            }
        });
    }

    private createAssistedItineraryEditorFromItinerary(
        itinerary: MissionPlanAssistedItinerary,
        constraints: MissionPlanItineraryConstraints
    ) {
        const { origin, destination } = itinerary;

        if (!origin || !destination) {
            return;
        }

        this.createAssistedEditorItineraryEntity({
            entityId: AssistedEntityId.Takeoff,
            type: MapEntityType.Cylinder,
            constraints,
            centerLatitude: origin.position.lat,
            centerLongitude: origin.position.lon,
        });

        this.createAssistedEditorItineraryEntity({
            entityId: AssistedEntityId.Landing,
            type: MapEntityType.Cylinder,
            constraints,
            centerLatitude: destination.position.lat,
            centerLongitude: destination.position.lon,
        });
    }

    private transformConstraints(constraints: MissionPlanItineraryConstraints, flightZone?: WaypointFlightZone): EntityEditorConstraints {
        return {
            default: {
                topHeight: flightZone?.maxH ?? constraints.default.height,
                bottomHeight: flightZone?.minH ?? 0,
                radius: flightZone?.area?.properties?.radius ?? constraints.default.size,
                startDelay: constraints.default.startDelay,
                horizontalNavigationAccuracy: constraints.default.horizontalNavigationAccuracy,
                verticalNavigationAccuracy: constraints.default.verticalNavigationAccuracy,
                runwayHorizontalNavigationAccuracy: constraints.default.runwayHorizontalNavigationAccuracy,
                runwayVerticalNavigationAccuracy: constraints.default.runwayVerticalNavigationAccuracy,
                largeZoneRadius: constraints.default.largeZoneRadius,
                regularZoneRadius: constraints.default.regularZoneRadius,
            },
            min: {
                topHeight: constraints.min.height,
                bottomHeight: 0,
                radius: constraints.min.size,
                startDelay: constraints.min.startDelay,
                horizontalNavigationAccuracy: constraints.min.horizontalNavigationAccuracy,
                verticalNavigationAccuracy: constraints.min.verticalNavigationAccuracy,
                runwayHorizontalNavigationAccuracy: constraints.min.runwayHorizontalNavigationAccuracy,
                runwayVerticalNavigationAccuracy: constraints.min.runwayVerticalNavigationAccuracy,
                largeZoneRadius: constraints.min.largeZoneRadius,
                regularZoneRadius: constraints.min.regularZoneRadius,
            },
            max: {
                topHeight: constraints.max.height,
                bottomHeight: constraints.max.height,
                radius: constraints.max.size,
                startDelay: constraints.max.startDelay,
                horizontalNavigationAccuracy: constraints.max.horizontalNavigationAccuracy,
                verticalNavigationAccuracy: constraints.max.verticalNavigationAccuracy,
                runwayHorizontalNavigationAccuracy: constraints.max.runwayHorizontalNavigationAccuracy,
                runwayVerticalNavigationAccuracy: constraints.max.runwayVerticalNavigationAccuracy,
                largeZoneRadius: constraints.max.largeZoneRadius,
                regularZoneRadius: constraints.max.regularZoneRadius,
            },
        };
    }

    public async createAssistedEditorItineraryEntity({
        entityId,
        constraints,
        centerLongitude,
        centerLatitude,
    }: AssistedItineraryDataEntity) {
        this.createEditableCylinder(centerLatitude, centerLongitude, entityId, constraints, this.assistedEditorCylinderLabelProviders);

        this.updateAssistedDrawingAndViewingMode(
            { entityId, type: MapEntityType.Cylinder },
            convertMissionPlanItineraryConstraintsToAssistedEntityEditorConstraints(constraints)
        );
    }

    private updateCustomItineraryContent(
        previousItineraryContent: ItineraryContent,
        itineraryContent: ItineraryContent,
        missionParameterChanges: Partial<ItineraryEditorMissionParametersFormData>,
        runwayVerticalNavigationAccuracy: number
    ) {
        const updatedCustomEntities: UpdatedMapEntity[] = [];
        let waypointIndex = 0;

        // NOTE: mechanism that removes height from new polylines in order to get them from the backend
        const updatedCustomItineraryContent = itineraryContent.map((entity) => {
            if (entity.type !== MapEntityType.Polyline3D) {
                waypointIndex++;

                return entity;
            }

            const shouldRecalculateHeight =
                !previousItineraryContent.some(({ id }) => id === entity.id) ||
                missionParameterChanges.flightLevel !== undefined ||
                missionParameterChanges.flightLevelType !== undefined;
            const shouldRecalculateVerticalBuffer = missionParameterChanges.verticalBuffer !== undefined;
            const shouldRecalculateHorizontalBuffer = missionParameterChanges.horizontalBuffer !== undefined;

            let updatedEntity = entity;

            if (!shouldRecalculateHeight) {
                // NOTE: mechanism for all child entities height adjustment before posting to the backend. This is needed because backend
                // wants to get height of the center point of the entity at <naviagtionAccuracy> distance from the top of the shape.

                const childEntityIndices = Object.values(entity.childEntities).map((childEntity) => childEntity.waypointIndex);

                updatedEntity = {
                    ...updatedEntity,
                    heights: updatedEntity.heights.map((height, index) => {
                        if (!childEntityIndices.includes(index) || height === undefined) {
                            return height;
                        }

                        if (index === 0 || index === updatedEntity.heights.length - 1) {
                            return height - runwayVerticalNavigationAccuracy;
                        }

                        return (
                            height -
                            Math.round(Math.max(updatedEntity.bufferHeights[index - 1], updatedEntity.bufferHeights[index + 1]) / 2)
                        );
                    }),
                };
            }

            updatedEntity = {
                ...updatedEntity,
                heights: shouldRecalculateHeight ? updatedEntity.heights.map(() => undefined) : updatedEntity.heights,
                bufferHeights: shouldRecalculateVerticalBuffer
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      updatedEntity.bufferHeights.map(() => missionParameterChanges.verticalBuffer!)
                    : updatedEntity.bufferHeights,
                bufferWidths: shouldRecalculateHorizontalBuffer
                    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      updatedEntity.bufferWidths.map(() => missionParameterChanges.horizontalBuffer!)
                    : updatedEntity.bufferWidths,
                childEntities: Object.fromEntries(
                    Object.entries(updatedEntity.childEntities).map(([key, childEntity]) => [
                        key,
                        {
                            ...childEntity,
                            entity: {
                                ...childEntity.entity,
                                topHeight: shouldRecalculateHeight ? undefined : childEntity.entity.topHeight,
                            },
                        },
                    ])
                ),
            };

            if (shouldRecalculateHeight || shouldRecalculateVerticalBuffer || shouldRecalculateHorizontalBuffer) {
                updatedCustomEntities.push({
                    entity: updatedEntity,
                    startIndex: waypointIndex,
                });
            }

            waypointIndex += updatedEntity.heights.length;

            return updatedEntity;
        });

        return { updatedCustomItineraryContent, updatedCustomEntities };
    }

    private updateStandardItineraryContent(
        itineraryContent: ItineraryContent,
        missionParameterChanges: Partial<ItineraryEditorMissionParametersFormData>,
        constraints: EntityEditorConstraints,
        isEnlargedZoneStatementAccepted: boolean | null
    ) {
        const updatedStandardEntities: UpdatedMapEntity[] = [];
        const updatedStandardItineraryContent = itineraryContent.map((entity) => {
            const shouldRecalculateHeight =
                missionParameterChanges.zoneBottomHeight !== undefined ||
                missionParameterChanges.zoneHeightType !== undefined ||
                missionParameterChanges.zoneTopHeight !== undefined;

            let updatedEntity = entity;

            if (shouldRecalculateHeight) {
                if (updatedEntity.type === MapEntityType.Prism) {
                    updatedEntity = {
                        ...updatedEntity,
                        topHeight: missionParameterChanges.zoneTopHeight ?? updatedEntity.topHeight,
                        bottomHeight: missionParameterChanges.zoneBottomHeight ?? updatedEntity.bottomHeight,
                    };

                    updatedStandardEntities.push({
                        entity: updatedEntity,
                        updateOptions: {
                            prismProps: {
                                maxBottomHeight: updatedEntity.bottomHeight,
                                maxTopHeight: updatedEntity.topHeight,
                                minBottomHeight: updatedEntity.bottomHeight,
                                minTopHeight: updatedEntity.topHeight,
                            },
                        },
                    });
                } else if (updatedEntity.type === MapEntityType.Polyline3D) {
                    updatedEntity = {
                        ...updatedEntity,
                        heights: updatedEntity.heights.map((height) => missionParameterChanges.zoneTopHeight ?? height),
                    };

                    updatedStandardEntities.push({
                        entity: updatedEntity,
                        updateOptions: {
                            polylineProps: {
                                maxBottomHeight: missionParameterChanges.zoneBottomHeight ?? updatedEntity.heights[0],
                                maxTopHeight: missionParameterChanges.zoneTopHeight ?? updatedEntity.heights[0],
                                minBottomHeight: missionParameterChanges.zoneBottomHeight ?? updatedEntity.heights[0],
                                minTopHeight: missionParameterChanges.zoneTopHeight ?? updatedEntity.heights[0],
                            },
                        },
                    });
                }
            }

            if (updatedEntity.type !== MapEntityType.Cylinder) {
                return updatedEntity;
            }

            const shouldRecalculateRadius = missionParameterChanges.isEnlargedZoneStatementAccepted !== undefined;
            const maxRadius =
                (isEnlargedZoneStatementAccepted ? constraints.max.largeZoneRadius : constraints.max.regularZoneRadius) ??
                constraints.max.radius;

            if (updatedEntity.radius > maxRadius) {
                updatedEntity = {
                    ...updatedEntity,
                    radius: maxRadius,
                };
            }

            if (shouldRecalculateHeight) {
                updatedEntity = {
                    ...updatedEntity,
                    topHeight: missionParameterChanges.zoneTopHeight ?? updatedEntity.topHeight,
                    bottomHeight: missionParameterChanges.zoneBottomHeight ?? updatedEntity.bottomHeight,
                };
            }

            if (shouldRecalculateHeight || shouldRecalculateRadius) {
                updatedStandardEntities.push({
                    entity: updatedEntity,
                    updateOptions: {
                        cylinderProps: {
                            maxBottomHeight: updatedEntity.bottomHeight,
                            maxTopHeight: updatedEntity.topHeight,
                            maxRadius,
                            minBottomHeight: updatedEntity.bottomHeight,
                            minTopHeight: updatedEntity.topHeight,
                        },
                    },
                });
            }

            return updatedEntity;
        });

        return { updatedStandardItineraryContent, updatedStandardEntities };
    }

    public updateMissionPlanItinerary(
        previousItineraryContent: ItineraryContent,
        itineraryContent: ItineraryContent,
        planId: string,
        itineraryData: ItineraryEditorFormData,
        runwayVerticalNavigationAccuracy: number,
        missionParameterChanges: Partial<ItineraryEditorMissionParametersFormData>,
        constraints: EntityEditorConstraints
    ): Observable<void> {
        let updatedItineraryContent: ItineraryContent = itineraryContent;
        let updatedEntities: UpdatedMapEntity[] = [];

        if (itineraryData.editorType === ItineraryEditorType.Custom) {
            const { updatedCustomEntities, updatedCustomItineraryContent } = this.updateCustomItineraryContent(
                previousItineraryContent,
                itineraryContent,
                missionParameterChanges,
                runwayVerticalNavigationAccuracy
            );
            updatedItineraryContent = updatedCustomItineraryContent;
            updatedEntities = updatedCustomEntities;
        } else if (itineraryData.editorType === ItineraryEditorType.Standard) {
            const { updatedStandardEntities, updatedStandardItineraryContent } = this.updateStandardItineraryContent(
                itineraryContent,
                missionParameterChanges,
                constraints,
                itineraryData.isEnlargedZoneStatementAccepted
            );
            updatedItineraryContent = updatedStandardItineraryContent;
            updatedEntities = updatedStandardEntities;
        }

        return this.store
            .dispatch([
                new WeatherActions.ChangeWeatherVisibility(WeatherViewMode.Tabs),
                new MissionActions.UpdateMissionPlanItinerary(planId, updatedItineraryContent, itineraryData),
            ])
            .pipe(
                tap(() => {
                    const route = this.store.selectSnapshot(MissionState.currentPlanRoute);
                    const error = this.store.selectSnapshot(MissionState.missionError);

                    if (!route) {
                        return;
                    }

                    this.store.dispatch(
                        new WeatherActions.GetMissionPlanWeatherRange({
                            route,
                            missionStartTime: itineraryData.dateRangeStart ?? itineraryData.datetime,
                            hoursRangeBefore: MISSION_PLANNER_MIN_HOURS_BEFORE,
                            hoursRangeAfter: MISSION_PLANNER_MAX_HOURS_AFTER,
                        })
                    );

                    if (updatedEntities.length > 0 && !error) {
                        this.updateItineraryEntitiesOnMap(route, updatedEntities, runwayVerticalNavigationAccuracy, constraints);
                    }
                })
            );
    }

    private updateItineraryEntitiesOnMap(
        route: MissionPlanRoute,
        updatedEntities: UpdatedMapEntity[],
        runwayVerticalNavigationAccuracy: number,
        constraints: EntityEditorConstraints
    ) {
        const heights = this.getHeightsFromRoute(route);

        updatedEntities.forEach(({ entity, startIndex, updateOptions }) => {
            if (entity.type !== MapEntityType.Polyline3D || startIndex === undefined) {
                this.entitiesEditorService.update(entity, updateOptions);

                return;
            }

            const newHeights = heights.slice(startIndex, entity.heights.length + startIndex);

            newHeights[0] += runwayVerticalNavigationAccuracy;
            newHeights[newHeights.length - 1] += runwayVerticalNavigationAccuracy;

            this.entitiesEditorService.update(
                {
                    ...entity,
                    heights: newHeights,
                },
                updateOptions
            );

            const takeoffChildEntity = Object.values(entity.childEntities).find((childEntity) => childEntity.waypointIndex === 0);
            const landingChildEntity = Object.values(entity.childEntities).find(
                (childEntity) => childEntity.waypointIndex === entity.heights.length - 1
            );

            if (takeoffChildEntity?.entity.type === MapEntityType.Cylinder) {
                this.entitiesEditorService.update(
                    {
                        ...takeoffChildEntity.entity,
                        topHeight: newHeights[0],
                    },
                    updateOptions
                );
            }

            if (landingChildEntity?.entity.type === MapEntityType.Cylinder) {
                this.entitiesEditorService.update(
                    {
                        ...landingChildEntity.entity,
                        topHeight: newHeights[newHeights.length - 1],
                    },
                    updateOptions
                );
            }

            this.recreateChildEntitiesAfterHeightChange(entity, newHeights, constraints);
        });
    }

    private getHeightsFromRoute(route: MissionPlanRoute): number[] {
        return route.sections.reduce<number[]>((result, section, sectionIndex) => {
            if (section.flightZone) {
                result.push(section.flightZone.center.point.height);
            } else if (section.segment) {
                if (sectionIndex === 0 || route.sections[sectionIndex - 1].flightZone) {
                    result.push(section.segment.fromWaypoint.point.height);
                }

                result.push(section.segment.toWaypoint.point.height);
            }

            return result;
        }, []);
    }

    private prepareItineraryContent(): Observable<ItineraryContent> {
        return combineLatest([this.entitiesEditorService.editorContent$, this.metadataSubject]).pipe(
            map(([editorContent, metadata]) =>
                editorContent.map((entity): ItineraryContentEntity => {
                    const result: ItineraryContentEntity = {
                        ...entity,
                        metadata: undefined,
                    };

                    if (metadata[entity.id]?.type === entity.type) {
                        result.metadata = metadata[entity.id];
                    }

                    return result;
                })
            ),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    public updateEntityMetadata(metadata: ItineraryContentMetadata["value"]) {
        this.metadataSubject.next({
            ...this.metadataSubject.value,
            [metadata.entityId]: metadata,
        });
    }
}
