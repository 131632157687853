<mat-card *ngrxLet="{ operator: operator$, avatarBase64: avatarBase64$, canManageOperator: canManageOperator$ } as vm">
    <dtm-ui-card-header>
        {{ "dtmWebAppOperatorProfile.operatorAvatar.avatarHeader" | transloco }}
    </dtm-ui-card-header>
    <dtm-ui-avatar-editor
        *ngIf="vm.canManageOperator; else viewTemplate"
        [avatar]="vm.avatarBase64"
        [canRemove]="!!vm.avatarBase64"
        [label]="'dtmWebAppOperatorProfile.operatorAvatar.editAvatarLabel' | transloco"
        [context]="'dtmWebAppOperatorProfile.operatorAvatar.operatorTypeValue' | transloco : { value: vm.operator.type }"
        [fullName]="vm.operator.name"
        (saveAvatar)="saveAvatar.emit($event)"
        (delete)="tryDeleteOperatorAvatar()"
    ></dtm-ui-avatar-editor>

    <ng-template #viewTemplate>
        <dtm-ui-avatar
            [picture]="vm.avatarBase64"
            [fullName]="vm.operator.name"
            [label]="'dtmWebAppOperatorProfile.operatorAvatar.operatorTypeValue' | transloco : { value: vm.operator.type }"
        ></dtm-ui-avatar>
    </ng-template>
</mat-card>
