<ng-container *ngrxLet="{ ownedPermissions: ownedPermissions$, isProcessing: isProcessing$ } as vm">
    <dtm-ui-responsive-table [data]="vm.ownedPermissions">
        <table
            class="dtm-table"
            *ngIf="vm.ownedPermissions.length; else emptyListTemplate"
            mat-table
            multiTemplateDataRows
            desktopTable
            [dataSource]="vm.ownedPermissions"
        >
            <ng-container *ngrxLet="expandedElement$ as expandedElement">
                <ng-container matColumnDef="expandHandle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            type="button"
                            class="button-icon"
                            (click)="$event.stopPropagation(); toggleExpandableRow(element)"
                            [attr.aria-label]="'dtmUi.expandButtonArialLabel' | transloco : { isExpanded: element === expandedElement }"
                            [attr.aria-expanded]="element === expandedElement"
                        >
                            <dtm-ui-icon
                                name="arrow-right"
                                class="expand-handle"
                                [class.expanded]="element === expandedElement"
                            ></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.permissionsList.statusColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container
                            [ngTemplateOutlet]="statusTemplate"
                            [ngTemplateOutletContext]="{ $implicit: element.status }"
                        ></ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="permissions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.permissionsList.permissionNameColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="applicationSubmitted">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.permissionsList.permissionExpirationDateColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.expirationDate | localizeDate }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="uavs">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.permissionsList.uavsColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container
                            [ngTemplateOutlet]="uavsTemplate"
                            [ngTemplateOutletContext]="{ $implicit: element.numberOfUavs }"
                        ></ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "dtmWebAppLibOperatorPermissions.permissionsList.actionsColumnLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            [matMenuTriggerFor]="actions"
                            class="button-icon"
                            type="button"
                            (click)="$event.stopPropagation()"
                            *ngIf="element.numberOfUavs"
                            [matMenuTriggerData]="{ $implicit: element }"
                            [attr.aria-label]="'dtmUi.actionButtonAriaLabel' | transloco"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="expanded-row-content" *ngIf="element === expandedElement" @slideIn>
                            <ng-container
                                [ngTemplateOutlet]="additionalInfoTemplate"
                                [ngTemplateOutletContext]="{ $implicit: element }"
                            ></ng-container>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [class.expanded]="expandedElement === row"
                    (click)="toggleExpandableRow(row)"
                ></tr>
                <tr mat-row *matRowDef="let expandedRow; columns: ['expandedDetail']" class="expansion-row"></tr>
            </ng-container>
        </table>
        <div *dtmUiMobileTable="let row">
            <div class="mobile-table-actions-row">
                <dtm-ui-label-value [label]="'dtmWebAppLibOperatorPermissions.permissionsList.statusColumnLabel' | transloco">
                    <ng-container [ngTemplateOutlet]="statusTemplate" [ngTemplateOutletContext]="{ $implicit: row.status }"></ng-container>
                </dtm-ui-label-value>
                <button
                    *ngIf="row.numberOfUavs"
                    type="button"
                    class="button-icon"
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="actions"
                    [matMenuTriggerData]="{ $implicit: row }"
                >
                    <dtm-ui-icon name="more"></dtm-ui-icon>
                </button>
            </div>
            <div class="mobile-table-content">
                <dtm-ui-label-value
                    [label]="'dtmWebAppLibOperatorPermissions.permissionsList.permissionExpirationDateColumnLabel' | transloco"
                    [value]="row.expirationDate | localizeDate"
                ></dtm-ui-label-value>
                <dtm-ui-label-value
                    *ngIf="row.numberOfUavs"
                    [label]="'dtmWebAppLibOperatorPermissions.permissionsList.uavsColumnLabel' | transloco"
                >
                    <ng-container
                        [ngTemplateOutlet]="uavsTemplate"
                        [ngTemplateOutletContext]="{ $implicit: row.numberOfUavs }"
                    ></ng-container>
                </dtm-ui-label-value>
                <ng-container [ngTemplateOutlet]="additionalInfoTemplate" [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
            </div>
        </div>
        <ng-container mobileTableNoData [ngTemplateOutlet]="emptyListTemplate"></ng-container>
    </dtm-ui-responsive-table>

    <ng-template #emptyListTemplate>
        <div *ngIf="!vm.isProcessing" class="grid-maxsize-6">
            <dtm-ui-empty-state [mode]="EmptyStateMode.ListView" *ngrxLet="isMakingStatementAvailable$ as isMakingStatementAvailable">
                <ng-container titleSlot>
                    {{ "dtmWebAppLibOperatorPermissions.permissionsList.emptyState.title" | transloco }}
                </ng-container>
                <p class="empty-state-message" messageSlot>
                    {{
                        (isMakingStatementAvailable
                            ? "dtmWebAppLibOperatorPermissions.permissionsList.emptyState.message"
                            : "dtmWebAppLibOperatorPermissions.permissionsList.emptyState.disabledMakingStatementMessage"
                        ) | transloco
                    }}
                </p>
                <img imageSlot src="assets/images/no-results.svg" [alt]="'dtmUi.noResults.imageAltText' | transloco" />
                <button *ngIf="isMakingStatementAvailable" footerSlot type="button" class="button-primary" (click)="permissionGet.emit()">
                    <dtm-ui-icon name="add"></dtm-ui-icon>
                    <span>{{ "dtmWebAppLibOperatorPermissions.permissionsList.emptyState.buttonLabel" | transloco }}</span>
                </button>
            </dtm-ui-empty-state>
        </div>
    </ng-template>
</ng-container>

<mat-menu #actions="matMenu">
    <ng-template matMenuContent let-element>
        <button type="button" mat-menu-item (click)="uavDetailsShow.emit(element.id)">
            <dtm-ui-icon name="information"></dtm-ui-icon>
            {{ "dtmWebAppLibOperatorPermissions.permissionsList.showUavDetailsActionLabel" | transloco }}
        </button>
    </ng-template>
</mat-menu>

<ng-template #statusTemplate let-status>
    <ng-container [ngSwitch]="status">
        <span *ngSwitchCase="QualificationStatus.Active" class="chip success">
            <dtm-ui-icon name="checkbox-circle" class="status-icon"></dtm-ui-icon>
            <p class="status-label">
                {{ "dtmWebAppLibOperatorPermissions.permissionsList.statusValueLabel" | transloco : { value: status } }}
            </p>
        </span>
        <span *ngSwitchCase="QualificationStatus.Suspended" class="chip warning">
            <dtm-ui-icon name="pause-circle" class="status-icon"></dtm-ui-icon>
            <p class="status-label">
                {{ "dtmWebAppLibOperatorPermissions.permissionsList.statusValueLabel" | transloco : { value: status } }}
            </p>
        </span>
    </ng-container>
</ng-template>

<ng-template #uavsTemplate let-numberOfUavs>
    <div class="uavs-info" *ngIf="numberOfUavs"><dtm-ui-icon name="drone"></dtm-ui-icon> {{ numberOfUavs }}</div>
</ng-template>

<ng-template #additionalInfoTemplate let-element>
    <dtm-ui-label-value
        class="sub-cell wide-content"
        [label]="'dtmWebAppLibOperatorPermissions.permissionsList.descriptionHeader' | transloco"
        [value]="element.description"
    >
    </dtm-ui-label-value>
    <dtm-ui-label-value
        *ngIf="element.status === QualificationStatus.Suspended"
        class="sub-cell wide-content"
        [label]="'dtmWebAppLibOperatorPermissions.permissionsList.suspendReasonHeader' | transloco"
        [value]="element.statusReason"
    >
    </dtm-ui-label-value>
</ng-template>
