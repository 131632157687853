import { MissionProcessingPhaseExtended } from "@dtm-frontend/shared/mission";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import {
    DashboardMissionAcceptanceStatus,
    DashboardMissionStatus,
    DashboardMissionsWithTotalElementsNumber,
    PostWithLanguages,
} from "./dashboard.models";

export interface PostResponseBody {
    id: string;
    date: string;
    nativeTitle: string;
    englishTitle: string;
    nativeContent: string;
    englishContent: string;
    nativeLink?: string;
    englishLink?: string;
}

export interface DashboardMissionResponseBody {
    id: string;
    missionId: string;
    name: string;
    status: DashboardMissionStatus;
    missionStatus: DashboardMissionAcceptanceStatus;
    phase: MissionProcessingPhaseExtended;
    flightStartAt: Date;
    flightFinishAt: Date;
    uavId: string;
    uavName: string;
    uavSetupName: string;
    pilotId: string;
    pilotName: string;
    pilotAvatarUrl: string;
}

export interface DashboardMissionResponseBodyWithPages extends PageResponseBody<DashboardMissionResponseBody> {
    content: DashboardMissionResponseBody[];
}

export function convertPostResponseBodyToPostWithLanguages(response: PostResponseBody): PostWithLanguages {
    return {
        englishContent: {
            id: response.id,
            date: response.date,
            title: response.englishTitle,
            content: response.englishContent,
            link: response.englishLink,
        },
        nativeContent: {
            id: response.id,
            date: response.date,
            title: response.nativeTitle,
            content: response.nativeContent,
            link: response.nativeLink,
        },
    };
}

export function convertDashboardMissionResponseBodyToDashboardMissionWithTotalElementsNumber(
    responseBody: DashboardMissionResponseBodyWithPages
): DashboardMissionsWithTotalElementsNumber {
    return {
        missions: responseBody.content.map((response: DashboardMissionResponseBody) => ({
            id: response.id,
            missionId: response.missionId,
            name: response.name,
            status: response.status,
            missionAcceptanceStatus: response.missionStatus,
            phase: response.phase,
            flightStartAt: response.flightStartAt,
            flightFinishAt: response.flightFinishAt,
            uavId: response.uavId,
            uavName: response.uavName,
            uavSetupName: response.uavSetupName,
            pilotId: response.pilotId,
            pilotName: response.pilotName,
            pilotAvatarUrl: response.pilotAvatarUrl,
        })),
        totalElements: responseBody.totalElements,
    };
}
