<mat-card *ngrxLet="userProfile$ as userProfile">
    <dtm-ui-card-header>
        {{ "dtmWebAppUserProfile.personalData.userDataHeader" | transloco }}
    </dtm-ui-card-header>
    <div class="user-details">
        <dtm-ui-label-value
            [label]="'dtmWebAppUserProfile.personalData.nameLabel' | transloco"
            [value]="userProfile.firstName + ' ' + userProfile.lastName"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'dtmWebAppUserProfile.personalData.dateOfBirthInputLabel' | transloco"
            [value]="userProfile.dateOfBirth | localizeDate : { dateStyle: 'short' }"
        >
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'dtmWebAppUserProfile.personalData.nationalityInputLabel' | transloco">
            <dtm-ui-country-display [country]="userProfile.nationality"></dtm-ui-country-display>
        </dtm-ui-label-value>
    </div>
</mat-card>
