import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { DataChangeActionType, DataUpdateAction, PhoneNumber } from "@dtm-frontend/shared/ui";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { UserProfileError, UserProfileErrorType } from "../../models/user-profile.models";
import { UserProfileActions } from "../../state/user-profile.actions";
import { UserProfileState } from "../../state/user-profile.state";
import { getI18nUpdateProfileError } from "../../utils/update-error.translations";

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-user-contact",
    templateUrl: "./user-contact.component.html",
    styleUrls: ["./user-contact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserContactComponent implements OnDestroy {
    protected readonly isRequestedEmailChange$ = this.store.select(UserProfileState.isRequestedEmailChange);
    protected readonly hasRequestEmailChangeError$ = this.store
        .select(UserProfileState.requestEmailChangeError)
        .pipe(map((error) => error?.type === UserProfileErrorType.EmailConflict));
    protected readonly hasRequestPhoneChangeError$ = this.store
        .select(UserProfileState.requestPhoneNumberChangeError)
        .pipe(map((error) => error?.type === UserProfileErrorType.PhoneConflict));
    protected readonly isRequestedPhoneChange$ = this.store.select(UserProfileState.isRequestedPhoneChange);
    protected readonly userProfile$ = this.store.select(UserProfileState.user);

    constructor(
        private readonly store: Store,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {}

    protected userProfileDataUpdate({ payload, actionType }: DataUpdateAction): void {
        switch (actionType) {
            case DataChangeActionType.RequestEmailChange:
                this.requestEmailAddressChange(payload as string);
                break;
            case DataChangeActionType.RequestPhoneNumberChange:
                this.requestPhoneNumberChange(payload as PhoneNumber);
                break;
            case DataChangeActionType.SaveNewEmailAddress:
                this.saveNewEmailAddress(payload as string);
                break;
            case DataChangeActionType.SaveNewPhoneNumber:
                this.saveNewPhoneNumber(payload as string);
                break;
        }
    }

    protected clearEmailAddressChange(): void {
        this.store.dispatch(new UserProfileActions.ClearEmailAddressRequestChange());
    }

    protected clearPhoneNumberChange(): void {
        this.store.dispatch(new UserProfileActions.ClearPhoneRequestChange());
    }

    private requestPhoneNumberChange(payload: PhoneNumber): void {
        this.store
            .dispatch(new UserProfileActions.RequestPhoneNumberChange(payload))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.requestPhoneNumberChangeError);

                if (error?.type !== UserProfileErrorType.PhoneConflict) {
                    this.displayNotification(error);
                }
            });
    }

    private requestEmailAddressChange(payload: string): void {
        this.store
            .dispatch(new UserProfileActions.RequestEmailAddressChange(payload))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.requestEmailChangeError);

                if (error?.type !== UserProfileErrorType.EmailConflict) {
                    this.displayNotification(error);
                }
            });
    }

    private saveNewEmailAddress(payload: string): void {
        this.store
            .dispatch(new UserProfileActions.SaveNewEmailAddress(payload))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.displayNotification(
                    this.store.selectSnapshot(UserProfileState.emailAddressSaveError),
                    "dtmWebAppUserProfile.notifications.saveNewAddressSuccess"
                );
            });
    }

    private saveNewPhoneNumber(payload: string): void {
        this.store
            .dispatch(new UserProfileActions.SaveNewPhoneNumber(payload))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.displayNotification(
                    this.store.selectSnapshot(UserProfileState.phoneNumberSaveError),
                    "dtmWebAppUserProfile.notifications.saveNewPhoneNumberSuccess"
                );
            });
    }

    private displayNotification(error: UserProfileError | undefined, successTranslationKey?: string): void {
        if (error) {
            this.toastService.error(this.transloco.translate(getI18nUpdateProfileError(error.type)));

            return;
        }

        if (successTranslationKey) {
            this.toastService.success(this.transloco.translate(successTranslationKey));
        }
    }

    public ngOnDestroy(): void {
        this.clearEmailAddressChange();
        this.clearPhoneNumberChange();
    }
}
