<dtm-ui-expandable-panel
    [hasHeaderSeparator]="false"
    [isExpanded]="riskMitigationMeasureFormControl.value"
    [isDisabled]="true"
    [formGroup]="riskMitigationFormGroup"
    *ngrxLet="{
        isFormDisabled: isFormDisabled$,
        populationDensity: populationDensity$,
        thresholds: thresholds$,
        disabledRobustnessLevels: disabledRobustnessLevels$
    } as vm"
    [class.disabled]="vm.isFormDisabled"
>
    <dtm-web-app-lib-mission-risk-mitigation-level-header
        headerSlot
        [isSelected]="riskMitigationMeasureFormControl.value"
        (isSelectedChange)="riskMitigationMeasureFormControl.setValue($event)"
        [isDisabled]="vm.isFormDisabled"
    >
        <ng-container titleSlot>
            {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationMeasureM1ALabel" | transloco }}
            <dtm-ui-popover
                *ngIf="vm.isFormDisabled"
                (click)="$event.preventDefault()"
                [popoverText]="
                    'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m1DisabledBecauseFlightOverControlledAreaTooltip' | transloco
                "
            ></dtm-ui-popover>
        </ng-container>
    </dtm-web-app-lib-mission-risk-mitigation-level-header>

    <ng-container *ngIf="vm.populationDensity">
        <p class="sub-section-title">{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.populationDensityLabel" | transloco }}</p>
        <span
            class="sub-section-value"
            [innerHTML]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.populationDensityValueUnit'
                    | transloco : { value: vm.populationDensity.peoplePerSquareKilometerMax }
            "
        >
        </span>
    </ng-container>

    <div class="mitigation-settings" *ngIf="riskMitigationMeasureFormControl.value && vm.populationDensity" @slideIn>
        <dtm-web-app-lib-mission-risk-mitigation-levels
            [robustnessLevels]="DEFAULT_ROBUSTNESS_LEVELS"
            [robustnessLevelValue]="robustnessLevelFormControl.value"
            [robustnessLevelFirstPopupText]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsTooltip.low' | transloco
            "
            [robustnessLevelSecondPopupText]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsTooltip.medium' | transloco
            "
            [robustnessLevelThirdPopupText]="
                'dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelsTooltip.high' | transloco
            "
            [robustnessLevelFirstDescription]="
                vm.thresholds[0]
                    ? ('dtmWebAppLibMission.summaryStep.analysis.soraSettings.peopleDensityMitigationMeasureLevelDescription'
                      | transloco : { value: vm.thresholds[0].max })
                    : undefined
            "
            [robustnessLevelSecondDescription]="
                vm.thresholds[1]
                    ? ('dtmWebAppLibMission.summaryStep.analysis.soraSettings.peopleDensityMitigationMeasureLevelDescription'
                      | transloco : { value: vm.thresholds[1].max })
                    : undefined
            "
            [robustnessLevelThirdDescription]="
                vm.thresholds[2]
                    ? ('dtmWebAppLibMission.summaryStep.analysis.soraSettings.peopleDensityMitigationMeasureLevelDescription'
                      | transloco : { value: vm.thresholds[2].max })
                    : undefined
            "
            [disabledLevels]="vm.disabledRobustnessLevels"
            (robustnessLevelSet)="setRobustnessLevel($event)"
        >
            <ng-container titleSlot>
                {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.riskMitigationLevelLabel" | transloco }}
            </ng-container>
        </dtm-web-app-lib-mission-risk-mitigation-levels>
        <dtm-ui-form-errors>
            <div class="field-error" *dtmUiFieldHasError="robustnessLevelFormControl; name: 'required'">
                {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
            </div>
        </dtm-ui-form-errors>

        <div class="section" [formGroup]="lowerPeopleDensityFormGroup">
            <mat-slide-toggle
                labelPosition="before"
                class="spread allow-wrap toggle-bar-center"
                [formControlName]="M1AStatementsKeys.lowerPeopleDensity"
            >
                <p class="section-title">
                    {{ M1AStatementsKeys.lowerPeopleDensity | transloco }}
                    <dtm-ui-popover
                        (click)="$event.preventDefault()"
                        [popoverText]="
                            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m1AStatementsTooltips.lowerPeopleDensity' | transloco
                        "
                    ></dtm-ui-popover>
                </p>
            </mat-slide-toggle>
            <dtm-ui-form-errors>
                <div class="field-error" *dtmUiFieldHasError="riskMitigationFormGroup; name: 'oneRequired'">
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}
                </div>
            </dtm-ui-form-errors>
            <ng-container *ngIf="lowerPeopleDensityFormGroup.controls[M1AStatementsKeys.lowerPeopleDensity].value">
                <dtm-ui-textarea-field class="comment" [maxLength]="MAX_COMMENT_LENGTH">
                    <label>{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.mitigationDescriptionLabel" | transloco }}</label>
                    <textarea matInput [rows]="4" [formControl]="lowerDensityReasonUserStatementFormControl"></textarea>
                    <p class="field-error" *dtmUiFieldHasError="lowerDensityReasonUserStatementFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </p>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="lowerDensityReasonUserStatementFormControl; name: 'maxlength'; error as error"
                    >
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.commentMaxLengthError"
                                | transloco
                                    : {
                                          maxLength: error.requiredLength,
                                          exceedValue: error.actualLength - error.requiredLength
                                      }
                        }}
                    </div>
                    <p class="field-hint">
                        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.lowerPeopleDensityReasonLabel" | transloco }}
                    </p>
                </dtm-ui-textarea-field>
                <dtm-ui-files-upload-field
                    class="attachments"
                    [formControl]="lowerPeopleDensityAttachmentsFormControl"
                    [allowedTypes]="ALLOWED_FILE_EXTENSION"
                    [maxFileSize]="MAX_FILE_SIZE_BYTES"
                >
                    <label>{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentsLabel" | transloco }}</label>
                    <div class="field-hint">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentInfoText"
                                | transloco : { size: MAX_FILE_SIZE_BYTES | formatFileSize, format: ALLOWED_FILE_EXTENSION | join }
                        }}
                    </div>
                </dtm-ui-files-upload-field>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="lowerPeopleDensityAttachmentsFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </ng-container>
        </div>
        <div class="section" [formGroup]="bystandersShieldedUserStatementsFormGroup">
            <mat-slide-toggle
                labelPosition="before"
                class="spread allow-wrap toggle-bar-center"
                [formControlName]="M1AStatementsKeys.bystandersShielded"
            >
                <p class="section-title">
                    {{ M1AStatementsKeys.bystandersShielded | transloco }}
                    <dtm-ui-popover
                        (click)="$event.preventDefault()"
                        [popoverText]="
                            'dtmWebAppLibMission.summaryStep.analysis.soraSettings.m1AStatementsTooltips.bystandersShielded' | transloco
                        "
                    ></dtm-ui-popover>
                </p>
            </mat-slide-toggle>
            <dtm-ui-form-errors>
                <div class="field-error" *dtmUiFieldHasError="riskMitigationFormGroup; name: 'oneRequired'">
                    {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.oneIsRequiredErrorLabel" | transloco }}
                </div>
            </dtm-ui-form-errors>
            <ng-container *ngIf="bystandersShieldedUserStatementsFormGroup.controls[M1AStatementsKeys.bystandersShielded].value">
                <dtm-ui-textarea-field class="comment" [maxLength]="MAX_COMMENT_LENGTH">
                    <label>{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.mitigationDescriptionLabel" | transloco }}</label>
                    <textarea matInput [rows]="4" [formControl]="bystandersShieldedUserCommentFormControl"></textarea>
                    <p class="field-error" *dtmUiFieldHasError="bystandersShieldedUserCommentFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </p>
                    <div
                        class="field-error"
                        *dtmUiFieldHasError="bystandersShieldedUserCommentFormControl; name: 'maxlength'; error as error"
                    >
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.commentMaxLengthError"
                                | transloco
                                    : {
                                          maxLength: error.requiredLength,
                                          exceedValue: error.actualLength - error.requiredLength
                                      }
                        }}
                    </div>
                    <p class="field-hint">
                        {{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.bystandersShieldedReasonLabel" | transloco }}
                    </p>
                </dtm-ui-textarea-field>
                <dtm-ui-files-upload-field
                    class="attachments"
                    [formControl]="bystandersShieldedAttachmentsFormControl"
                    [allowedTypes]="ALLOWED_FILE_EXTENSION"
                    [maxFileSize]="MAX_FILE_SIZE_BYTES"
                >
                    <label>{{ "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentsLabel" | transloco }}</label>
                    <div class="field-hint">
                        {{
                            "dtmWebAppLibMission.summaryStep.analysis.soraSettings.attachmentInfoText"
                                | transloco : { size: MAX_FILE_SIZE_BYTES | formatFileSize, format: ALLOWED_FILE_EXTENSION | join }
                        }}
                    </div>
                </dtm-ui-files-upload-field>
                <dtm-ui-form-errors>
                    <div class="field-error" *dtmUiFieldHasError="bystandersShieldedAttachmentsFormControl; name: 'required'">
                        {{ "dtmWebAppLibMission.requiredFieldErrorHint" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </ng-container>
        </div>
    </div>
</dtm-ui-expandable-panel>
