import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ALLOWED_IMAGE_UPLOAD_MIME_TYPES, FILES_UPLOAD_API_PROVIDER, UploadedFile } from "@dtm-frontend/shared/ui";
import { AnimationUtils, BYTES_IN_MEGABYTE, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AdministrativeFeeInStatementApiService } from "../../../../services/administrative-fee-in-statement-api.service";
import {
    AdministrativeFeeInStatement,
    OperatorPermissionsOperatorDetails,
    SinglePermission,
} from "../../../../services/operator-permissions.models";

interface SummaryComponentState {
    selectedPossiblePermissions: SinglePermission[] | undefined;
    operator: OperatorPermissionsOperatorDetails | undefined;
    stepsAmount: number;
    stepNumber: number;
    isAttorneyPowerAdministrativeFeeRequired: boolean;
    isPaymentFeatureAvailable: boolean;
}

interface AdministrativeFeeForm {
    isDeclarationOfExemption?: FormControl<boolean>;
    feeConfirmation?: FormControl<UploadedFile[]>;
}

// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE_BYTES = 10 * BYTES_IN_MEGABYTE;
const FEE_CONFIRMATION_FILE_ALLOWED_MIME_TYPES = [...ALLOWED_IMAGE_UPLOAD_MIME_TYPES, "application/pdf"];

const SUMMARY_STEP_NUMBER_INCLUDING_UAV_INFORMATION_STEP = 3;
const SUMMARY_STEP_NUMBER_WITHOUT_UAV_INFORMATION_STEP = SUMMARY_STEP_NUMBER_INCLUDING_UAV_INFORMATION_STEP - 1;

@UntilDestroy()
@Component({
    selector: "dtm-web-app-lib-operator-permissions-summary[selectedPossiblePermissions][operator][stepsAmount]",
    templateUrl: "./summary.component.html",
    styleUrls: ["./summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, { provide: FILES_UPLOAD_API_PROVIDER, useClass: AdministrativeFeeInStatementApiService }],
    animations: [AnimationUtils.slideInAnimation()],
})
export class SummaryComponent {
    @Input() public set selectedPossiblePermissions(value: SinglePermission[] | undefined) {
        let stepNumber = SUMMARY_STEP_NUMBER_WITHOUT_UAV_INFORMATION_STEP;
        if (value?.length && value?.some((permission) => permission.areUavsRequired)) {
            stepNumber = SUMMARY_STEP_NUMBER_INCLUDING_UAV_INFORMATION_STEP;
        }
        this.localStore.patchState({ selectedPossiblePermissions: value, stepNumber });
    }
    @Input() public set operator(value: OperatorPermissionsOperatorDetails | undefined) {
        this.localStore.patchState({ operator: value });
    }
    @Input() public set stepsAmount(value: number | undefined) {
        this.localStore.patchState({ stepsAmount: value ?? 0 });
    }
    @Input() public set isAttorneyPowerAdministrativeFeeRequired(value: BooleanInput) {
        this.localStore.patchState({ isAttorneyPowerAdministrativeFeeRequired: coerceBooleanProperty(value) });

        if (value) {
            this.administrativeFeeForm.addControl("feeConfirmation", this.feeConfirmationControl);
            this.administrativeFeeForm.addControl("isDeclarationOfExemption", this.isDeclarationOfExemptionControl);
        }
    }
    @Input() public set isPaymentFeatureAvailable(value: BooleanInput) {
        this.localStore.patchState({ isPaymentFeatureAvailable: coerceBooleanProperty(value) });
    }
    @Output() public back = new EventEmitter<void>();
    @Output() public getStatementInPdf = new EventEmitter<void>();
    @Output() public next = new EventEmitter<AdministrativeFeeInStatement>();

    protected readonly MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_BYTES;
    protected readonly FEE_CONFIRMATION_FILE_ALLOWED_MIME_TYPES = FEE_CONFIRMATION_FILE_ALLOWED_MIME_TYPES;
    protected readonly selectedPossiblePermissions$ = this.localStore
        .selectByKey("selectedPossiblePermissions")
        .pipe(RxjsUtils.filterFalsy());
    protected readonly operator$ = this.localStore.selectByKey("operator").pipe(RxjsUtils.filterFalsy());
    protected readonly stepsAmount$ = this.localStore.selectByKey("stepsAmount").pipe(RxjsUtils.filterFalsy());
    protected readonly stepNumber$ = this.localStore.selectByKey("stepNumber").pipe(RxjsUtils.filterFalsy());
    protected readonly isAttorneyPowerAdministrativeFeeRequired$ = this.localStore.selectByKey("isAttorneyPowerAdministrativeFeeRequired");
    protected readonly isPaymentFeatureAvailable$ = this.localStore.selectByKey("isPaymentFeatureAvailable");
    protected readonly feeConfirmationControl = new FormControl<UploadedFile[]>([], {
        nonNullable: true,
        validators: [Validators.required, Validators.maxLength(1)],
    });
    protected readonly isDeclarationOfExemptionControl = new FormControl<boolean>(false, { nonNullable: true });

    protected readonly administrativeFeeForm = new FormGroup<AdministrativeFeeForm>({});

    constructor(private localStore: LocalComponentStore<SummaryComponentState>) {
        localStore.setState({
            selectedPossiblePermissions: undefined,
            operator: undefined,
            stepsAmount: 0,
            stepNumber: 0,
            isAttorneyPowerAdministrativeFeeRequired: false,
            isPaymentFeatureAvailable: false,
        });

        this.isDeclarationOfExemptionControl.valueChanges.pipe(untilDestroyed(this)).subscribe((isDeclarationOfExemption) => {
            if (!isDeclarationOfExemption && !this.administrativeFeeForm.controls.feeConfirmation) {
                this.administrativeFeeForm.addControl("feeConfirmation", this.feeConfirmationControl);

                return;
            }

            if (isDeclarationOfExemption && this.administrativeFeeForm.controls.feeConfirmation) {
                this.administrativeFeeForm.removeControl("feeConfirmation");
                this.feeConfirmationControl.reset();

                return;
            }
        });
    }

    protected permissionCosts(permissions: SinglePermission[]): number {
        return permissions.reduce((sum, permission) => sum + permission.price, 0);
    }

    protected submit() {
        if (this.administrativeFeeForm.invalid) {
            this.administrativeFeeForm.markAllAsTouched();

            return;
        }

        this.next.emit(this.administrativeFeeForm.getRawValue());
    }
}
