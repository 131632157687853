import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { PublicProfileActions } from "../state/public-profile.actions";

export const OPERATOR_ID = "operatorId";
export const PILOT_ID = "pilotId";

@Injectable()
export class PublicProfileOperatorResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<void> {
        const operatorId = route.paramMap.get(OPERATOR_ID) ?? "";

        return this.store.dispatch([new PublicProfileActions.GetPublicProfileOperatorData(operatorId)]);
    }
}

@Injectable()
export class PublicProfilePilotResolver {
    constructor(private readonly store: Store) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<void> {
        const pilotId = route.paramMap.get(PILOT_ID) ?? "";

        return this.store.dispatch([new PublicProfileActions.GetPublicProfilePilotData(pilotId)]);
    }
}
