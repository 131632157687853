import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Router } from "@angular/router";
import { Page } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Member, MemberType, MembershipStatus } from "../../../services/membership.models";

interface InvitedPilotsListComponentState {
    invitedUsers: Member[];
    pagination: Page | undefined;
    isMemberProcessing: boolean;
    expandedElement: Member | undefined;
    isOperatorPermittedToManage: boolean;
}

@Component({
    selector: "dtm-web-app-lib-invited-users-list[invitedUsers][pagination]",
    templateUrl: "./invited-users-list.component.html",
    styleUrls: ["./invited-users-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class InvitedUsersListComponent {
    @Input()
    public set invitedUsers(value: Member[] | undefined) {
        this.localStore.patchState({ invitedUsers: value ?? [] });
    }

    @Input()
    public set pagination(value: Page | undefined) {
        this.localStore.patchState({ pagination: value });
    }

    @Input()
    public set isOperatorPermittedToManage(value: BooleanInput) {
        this.localStore.patchState({ isOperatorPermittedToManage: coerceBooleanProperty(value) });
    }

    @Input()
    public set isMemberProcessing(value: BooleanInput) {
        this.localStore.patchState({ isMemberProcessing: coerceBooleanProperty(value) });
    }

    @Output() public memberDeactivation = new EventEmitter<Member>();
    @Output() public invitationRemove = new EventEmitter<string>();
    @Output() public invitationResend = new EventEmitter<string>();
    @Output() public pageChange = new EventEmitter<PageEvent>();
    @Output() public memberRemove = new EventEmitter<Member>();
    @Output() public manageUserPermissions = new EventEmitter<Member>();
    @Output() public invitationToMemberResend = new EventEmitter<Member>();

    protected readonly isMemberProcessing$ = this.localStore.selectByKey("isMemberProcessing");
    protected readonly expandedElement$ = this.localStore.selectByKey("expandedElement");
    protected readonly invitedUsers$ = this.localStore.selectByKey("invitedUsers").pipe(RxjsUtils.filterFalsy());
    protected readonly pagination$ = this.localStore.selectByKey("pagination");
    protected readonly isOperatorPermittedToManage$ = this.localStore.selectByKey("isOperatorPermittedToManage");
    protected readonly displayedColumns: string[] = ["expandHandle", "status", "invitationDate", "name", "phoneNumber", "actions"];
    protected readonly MembershipStatus = MembershipStatus;
    protected readonly MemberType = MemberType;

    constructor(private readonly localStore: LocalComponentStore<InvitedPilotsListComponentState>, private readonly router: Router) {
        localStore.setState({
            invitedUsers: [],
            pagination: undefined,
            isMemberProcessing: false,
            expandedElement: undefined,
            isOperatorPermittedToManage: false,
        });
    }

    protected redirectToPilotDetails({ membershipPilotId, membershipOperatorId, membershipId }: Member) {
        if (!membershipPilotId || !membershipOperatorId || !membershipId) {
            return;
        }

        this.router.navigate([`membership/pilot-profile/${membershipPilotId}/${membershipOperatorId}/${membershipId}`]);
    }

    protected redirectToUsersDetails({ userId }: Member) {
        this.router.navigate(["membership/user-profile", userId]);
    }

    protected toggleExpandableRow(row: Member): void {
        this.localStore.patchState((state) => ({
            expandedElement: state.expandedElement === row ? undefined : row,
        }));
    }
}
