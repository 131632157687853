import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { HelpCenterContainerComponent } from "./help-center-container/help-center-container.component";

const routes: Routes = [
    {
        path: "help-center",
        component: HelpCenterContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            titleTranslationKey: "dtmWebAppLibHelpCentre.routeTitles.helpCenter",
            className: "no-padding",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpCenterRoutingModule {}
