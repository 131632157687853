import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface UavPropertyTextComponentState {
    description: string | undefined;
    label: string | undefined;
    isEmpty: boolean;
}

@Component({
    selector: "dtm-web-app-lib-uav-property[label]",
    templateUrl: "./uav-property.component.html",
    styleUrls: ["./uav-property.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UavPropertyComponent {
    public readonly label$ = this.localStore.selectByKey("label");
    public readonly description$ = this.localStore.selectByKey("description");
    public readonly isEmpty$ = this.localStore.selectByKey("isEmpty");

    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set description(value: string | undefined) {
        this.localStore.patchState({ description: value });
    }

    @Input() public set isEmpty(value: BooleanInput) {
        this.localStore.patchState({ isEmpty: coerceBooleanProperty(value) });
    }

    constructor(private readonly localStore: LocalComponentStore<UavPropertyTextComponentState>) {
        this.localStore.setState({
            description: undefined,
            label: undefined,
            isEmpty: false,
        });
    }
}
