import { ChangeDetectionStrategy, Component } from "@angular/core";
import { OPERATOR_MIGRATION_WIZARD_ID, OperatorMigrationWizardSteps } from "../../models/operator-migration.models";

@Component({
    selector: "dtm-web-app-lib-operator-migration-header",
    templateUrl: "./operator-migration-header.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorMigrationHeaderComponent {
    protected readonly OPERATOR_MIGRATION_WIZARD_ID = OPERATOR_MIGRATION_WIZARD_ID;
    protected readonly OperatorMigrationWizardSteps = OperatorMigrationWizardSteps;
}
